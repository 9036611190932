import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Row,
  Col,
  Typography,
  Table,
  Alert,
  message,
  Select,
  Input,
  Button,
} from "antd";
import { getClientEmailCustomReport } from "../../../redux/actions/commonActions/actions";
import SendEmailNew from "../Modals/SendEmailNew";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import DateRangePicker from "../../../functions/commonFunctions/dateRangePicker";

const { Option } = Select;
const { Content } = Layout;
const { Title, Text } = Typography;

const downloadReport = (data) => {
  window.open(data?.file, "_blank");
};

const ClientEmailCustomReport = () => {
  const dispatch = useDispatch();

  const getClientEmailCustomReportStatus = useSelector(
    (state) => state.clientEmailCustomReport.getClientEmailCustomReportStatus
  );
  const saveClientEmailCustomReportStatus = useSelector(
    (state) => state.clientEmailCustomReport.saveClientEmailCustomReportStatus
  );
  const { clientEmailCustomReport, filters: filterData } =
    useSelector((state) => state.clientEmailCustomReport) || [];

  const { userDetails, userTeam } =
    useSelector((state) => state.userSubscriptions) || [];

  const [modalsVisibility, setModalVisibility] = useState({
    visible: false,
    sendEmail: false,
    attachment: null,
    mailContent: "",
    report_tag: {},
  });

  const [dateObj, setDateObj] = useState({
    range: null,
    date_startValue: "",
    date_endValue: "",
    date: "",
  });
  const [selectedOrigin, setSelectedOrigin] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(
      getClientEmailCustomReport({
        filters: {},
        pageNumber: 1,
        filters_required_flag: false,
      })
    );
    dispatch(
      getClientEmailCustomReport({
        filters_required_flag: true,
      })
    );
  }, [dispatch]);

  const handleNextpage = (pageNumber) => {
    dispatch(
      getClientEmailCustomReport({
        filters: {},
        pageNumber,
        filters_required_flag: false,
      })
    );
  };

  const handleEmail = async (value, url, data) => {
    let title = checkValue(data.file_name) ? data.file_name : "";
    let mailContent = `<p>Attached is the report fot Client Email (${data.creation_origin}) created on ${data.date} .</p>`;
    setModalVisibility({
      ...modalsVisibility,
      attachment: url,
      title,
      visible: value,
      report_tag: {
        core: true,
        data: data,
      },
      mailContent,
    });
  };

  const applyFilter = () => {
    dispatch(
      getClientEmailCustomReport({
        filterFlag: true,
        creationOrigin: selectedOrigin,
        containsTerm: searchTerm,
        DOC: dateObj.date,
      })
    );
  };
  const getColumnFilter = (dataIndex) => ({
    filtered:
      (dataIndex === "origin" && selectedOrigin !== "") ||
      (dataIndex === "date" && dateObj.range !== null),
    filterDropdown: (data) => (
      <div style={{ padding: 8 }}>
        {dataIndex === "origin" && (
          <Select
            placeholder="Filter"
            style={{ width: "280px", margin: "0px 8px" }}
            onChange={(val) => {
              setSelectedOrigin(val);
              dispatch(
                getClientEmailCustomReport({
                  filterFlag: true,
                  creationOrigin: val,
                  containsTerm: searchTerm,
                  DOC: dateObj.date,
                })
              );
            }}
          >
            {filterData.map((obj) => (
              <Option value={obj.key}>{obj.label}</Option>
            ))}
          </Select>
        )}

        {dataIndex === "date" && (
          <DateRangePicker
            type={"Application Date"}
            startValue={dateObj.date_startValue}
            endValue={dateObj.date_endValue}
            setDate={(val) => {
              setDateObj({ ...dateObj, range: val });
            }}
            range={dateObj.range}
            handleDateString={(startValue, endValue, dateString) => {
              setDateObj({
                ...dateObj,
                date_startValue: startValue,
                date_endValue: endValue,
                date:
                  startValue !== null || endValue !== null ? dateString : null,
              });
              dispatch(
                getClientEmailCustomReport({
                  filterFlag: true,
                  creationOrigin: selectedOrigin,
                  containsTerm: searchTerm,
                  DOC: dateString,
                })
              );
            }}
          />
        )}
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: "Origin",
      dataIndex: "creation_origin",
      key: "origin",
      align: "left",
      width: "15%",
      ...getColumnFilter("origin"),
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "left",
      width: "15%",
      ...getColumnFilter("date"),
    },
    {
      title: "File Name",
      // dataIndex: "file_name",
      key: "file_name",
      align: "left",
      width: "15%",
      render: (text, custom_record) => {
        return custom_record && custom_record?.file_name ? (
          <Button
            style={{ cursor: "pointer" }}
            type="link"
            onClick={() => downloadReport(custom_record)}
          >
            {custom_record.file_name}
          </Button>
        ) : (
          <div style={{ marginLeft: "16px" }}>N.A.</div>
        );
      },
    },
    // {
    //   title: "Download",
    //   key: "download",
    //   align: "center",
    //   width: "8%",
    //   render: (text, custom_record) => {
    //     return custom_record && custom_record?.file ? (
    //       <span key={custom_record.id}>
    //         <Button
    //           type="primary"
    //           ghost
    //           onClick={() => downloadReport(custom_record)}
    //           style={{ width: "65px", height: "30px", margin: "0px 10px" }}
    //         >
    //           FILE
    //         </Button>
    //       </span>
    //     ) : (
    //       ""
    //     );
    //   },
    // },

    {
      title: "Send Mail",
      key: "send",
      align: "center",
      width: "8%",
      render: (custom_record) => {
        return custom_record ? (
          <span key={custom_record.id}>
            <Button
              type="primary"
              ghost
              // className={checkValue(custom_record.file) ? "" : "display_none"}
              onClick={() =>
                handleEmail(true, [custom_record.file], custom_record)
              }
            >
              Send
            </Button>
          </span>
        ) : (
          ""
        );
      },
    },
  ];

  const filters = (
    <Content style={{ margin: "0px 0px 16px 0px" }}>
      <Row>
        <Col lg={6} md={24} style={{ marginTop: "8px" }}>
          <Input
            placeholder={"Search Filename"}
            value={searchTerm}
            onChange={(event) => {
              if (event.target.value === "") {
                // input is cleared.
                dispatch(
                  getClientEmailCustomReport({
                    filterFlag: true,
                    creationOrigin: selectedOrigin,
                    containsTerm: "",
                    DOC: dateObj.date,
                  })
                );
                setSearchTerm(event.target.value);
              } else {
                setSearchTerm(event.target.value);
              }
            }}
            allowClear
          />
        </Col>
        <Col lg={18} md={24} style={{ marginTop: "8px" }}>
          <Button
            style={{ marginLeft: "8px" }}
            type="primary"
            onClick={() => {
              if (
                selectedOrigin !== "" ||
                searchTerm !== "" ||
                dateObj.date !== ""
              ) {
                applyFilter();
              } else {
                message.info("No Filters selected");
              }
            }}
          >
            <i className="fa fa-filter" aria-hidden="true" />
            <Text style={{ color: "#fff", marginLeft: "5px" }}>
              Apply Filter
            </Text>
          </Button>
          <Button
            style={{ marginLeft: "8px" }}
            // type="link"
            onClick={() => {
              if (
                selectedOrigin !== "" ||
                searchTerm !== "" ||
                dateObj.range !== null
              ) {
                dispatch(
                  getClientEmailCustomReport({
                    filters: {},
                    pageNumber: 1,
                    filters_required_flag: false,
                  })
                );

                setSelectedOrigin("");
                setSearchTerm("");
                setDateObj({
                  range: null,
                  date_startValue: "",
                  date_endValue: "",
                  date: "",
                });
              } else {
                message.info("No Filters selected");
              }
            }}
          >
            <Text style={{ marginLeft: "5px" }}>Reset Filters</Text>
          </Button>
        </Col>
      </Row>
    </Content>
  );

  return (
    <Layout
      style={{ background: "white", padding: "24px", maxHeight: "100vh" }}
    >
      <div style={{ margin: "0px" }}>
        <Row>
          <Col xs={{ span: 12 }} lg={{ span: 22 }}>
            <Title level={3}>Client Email Reports</Title>
          </Col>
        </Row>
        {getClientEmailCustomReportStatus &&
        getClientEmailCustomReportStatus === "ERROR" ? (
          <div style={{ margin: "16px 0px" }}>
            <Alert
              message={
                <>
                  There was some issue while getting your reports.
                  <p>
                    Please try again after sometime or reach out to{" "}
                    <a href="mailto:team@Mikelegal.com">team@Mikelegal.com</a>
                  </p>
                </>
              }
              type="error"
              showIcon
            />
          </div>
        ) : (
          <Row>
            {filters}
            <Table
              className="total-tm"
              dataSource={clientEmailCustomReport?.reports || []}
              columns={columns}
              pagination={{
                pageSize: 100,
                showQuickJumper: true,
                showSizeChanger: false,
                defaultCurrent: 1,
                total:
                  clientEmailCustomReport && clientEmailCustomReport?.count,
                onChange: handleNextpage,
              }}
              scroll={{ y: "calc(70vh - 6em)" }}
              bordered
              loading={getClientEmailCustomReportStatus === "LOADING"}
            ></Table>
          </Row>
        )}
      </div>
      <SendEmailNew
        visible={modalsVisibility.visible}
        onCloseModal={() => handleEmail(false, [], {})}
        sendFrom={userDetails && userDetails.communication_email}
        sendCC={userTeam && userTeam.watch}
        mailContent={modalsVisibility.mailContent}
        product={"SEARCH"}
        report_tag={modalsVisibility.report_tag}
        attachment={modalsVisibility.attachment}
        core={true}
      />
    </Layout>
  );
};

export default ClientEmailCustomReport;
