//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Modal,
  Button,
  Input,
  Alert,
  message,
  Typography,
  notification,
  Select,
  List,
} from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE FUNCTIONS--------
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

//--------ACTIONS--------
import {
  clientEmailUpload,
  setStoreData,
} from "../../../redux/actions/commonActions/actions";

const { Text } = Typography;
const { Option } = Select;

const TRADEMARK = "TRADEMARK";
const CUSTOM_TRADEMARK = "CUSTOM_TRADEMARK";
const INTERNATIONAL_TRADEMARK = "INTERNATIONAL_TRADEMARK";
const PATENT = "PATENT";
const CUSTOM_PATENT = "CUSTOM_PATENT";
const INTERNATIONAL_PATENT = "INTERNATIONAL_PATENT";
const JOURNAL_WATCH = "JOURNAL_WATCH";
const TM_PROTECT = "TM_PROTECT";

const NOFILE = "NO_FILE_SELECTED";
const PFMARK = "Portfolio trademark";
const TPMARK = "Third party Opposed trademark";
const STMARK = "Status tracker";
class AddClientEmails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "tm",
      file: "",
      fileValue: "",
      uploadFileError: false,
      subscription:
        this.props.userSubscriptions.manager.length > 0 &&
        (this.props?.userSubscriptions?.manager?.includes("TRADEMARK") ||
          this.props?.userSubscriptions?.manager?.includes(
            "CUSTOM_TRADEMARK"
          ) ||
          this.props?.userSubscriptions?.manager?.includes(
            "INTERNATIONAL_TRADEMARK"
          ))
          ? TRADEMARK
          : this.props.userSubscriptions.manager.length > 0 &&
            (this.props?.userSubscriptions?.manager?.includes("PATENT") ||
              this.props?.userSubscriptions?.manager?.includes(
                "CUSTOM_PATENT"
              ) ||
              this.props?.userSubscriptions?.manager?.includes(
                "INTERNATIONAL_PATENT"
              ))
          ? PATENT
          : this.props?.userSubscriptions?.watch?.includes("TM Protect")
          ? TM_PROTECT
          : JOURNAL_WATCH,
      markType: "Portfolio trademark",
    };
  }

  componentDidMount() {
    this.props.setStoreData({
      clientEmailUploadData: {
        message: "",
        errors: [],
      },
    });
  }

  onUploadDocument(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const isExcel = file?.name?.includes(".xlsx", ".xls");
    if (!isExcel) {
      message.error("Excel files are accepted only");
    }
    const isFileSize = file.size / (1024 * 1024) < 5;
    if (!isFileSize) {
      message.error("Excel file must be smaller than 5MB");
    }
    if (isExcel && isFileSize) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          uploadFileError: false,
        });
      };
      reader.readAsDataURL(file);
      this.setState({
        fileValue: e.target.value,
      });
    } else {
      e.target.value = "";
      this.setState({
        file: "",
        fileValue: "",
        uploadFileError: true,
      });
    }
  }

  onClose() {
    this.props.setStoreData({
      clientEmailUploadData: {
        message: "",
        errors: [],
      },
    });
    this.props.onCloseModal();
    this.props.form.resetFields();
    this.setState({
      file: "",
      fileValue: "",
      uploadFileError: false,
    });
  }

  async handleAddClientEmail(e) {
    e.preventDefault();

    if (this.state.file === "") {
      this.uploadMarkMessage(NOFILE);
    }
    let uploaded_file = checkValue(this.state.file) ? this.state.file : "";
    if (uploaded_file === "") {
      this.setState({
        uploadFileError: true,
      });
    } else {
      let response = await this.props.clientEmailUpload({
        platform: this.props.product,
        uploaded_file: uploaded_file,
        subscription: this.state?.subscription,
        mmType: this.state?.markType,
      });
      if (response === SUCCESS) {
        this.uploadMarkMessage(SUCCESS);
        this.props.onConfirm();
        this.onClose();
      } else {
        this.uploadMarkMessage(ERROR);
      }
    }
  }

  uploadMarkMessage(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Emails Uploaded",
          description: "Client Emails linked successfully.",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Uploading",
          description:
            "Unfortunately, an error occured while uploading. Please, try again later.",
        });
        break;

      case NOFILE:
        notification["error"]({
          message: "File is Required",
          description: "Please upload Valid File to add emails.",
        });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <>
        <Modal
          title={
            <div className="alignC">
              <Text strong style={{ fontSize: "16px" }}>
                ADD EMAILS
              </Text>
            </div>
          }
          open={this.props.visible}
          bodyStyle={{ lineHeight: "2.5" }}
          closable={false}
          width={600}
          footer={[
            <Button type="default" onClick={() => this.onClose()}>
              Cancel
            </Button>,
            <Button
              type="primary"
              loading={
                this.props.createClientEmailStatus === LOADING ? true : false
              }
              onClick={(e) => this.handleAddClientEmail(e)}
            >
              Add
            </Button>,
          ]}
        >
          <>
            {this.props?.createClientEmailStatus === ERROR &&
              this.props?.clientEmailUploadData?.message && (
                <>
                  <Form.Item>
                    <Alert
                      message={this.props?.clientEmailUploadData?.message}
                      type="error"
                      showIcon
                    />
                    <div style={{ maxHeight: "160px", overflow: "auto" }}>
                      {this.props?.clientEmailUploadData?.errors &&
                        this.props?.clientEmailUploadData?.errors?.length >
                          0 && (
                          <List
                            size="small"
                            bordered
                            dataSource={
                              this.props?.clientEmailUploadData?.errors
                            }
                            renderItem={(item, index) => (
                              <Alert
                                message={`${index + 1}. ${item}`}
                                style={{ margin: "16px" }}
                                banner
                              />
                            )}
                          />
                        )}
                    </div>
                  </Form.Item>
                </>
              )}
            <Form.Item
              style={{
                display: "inline-block",
                width:
                  this.state.subscription === "TRADEMARK"
                    ? "calc(50% - 32px)"
                    : "calc(100% - 32px)",
                margin: "0 8px",
              }}
            >
              {this.props.product === "tm-manager" && (
                <Select
                  defaultValue={
                    this.props.userSubscriptions.manager.length > 0 &&
                    (this.props?.userSubscriptions?.manager?.includes(
                      "TRADEMARK"
                    ) ||
                      this.props?.userSubscriptions?.manager?.includes(
                        "CUSTOM_TRADEMARK"
                      ) ||
                      this.props?.userSubscriptions?.manager?.includes(
                        "INTERNATIONAL_TRADEMARK"
                      ))
                      ? TRADEMARK
                      : this.props.userSubscriptions.manager.length > 0 &&
                        (this.props?.userSubscriptions?.manager?.includes(
                          "PATENT"
                        ) ||
                          this.props?.userSubscriptions?.manager?.includes(
                            "CUSTOM_PATENT"
                          ) ||
                          this.props?.userSubscriptions?.manager?.includes(
                            "INTERNATIONAL_PATENT"
                          ))
                      ? PATENT
                      : this.props?.userSubscriptions?.watch?.includes(
                          "TM Protect"
                        )
                      ? TM_PROTECT
                      : JOURNAL_WATCH
                  }
                  style={{ width: "100%" }}
                  onChange={(val) => this.setState({ subscription: val })}
                >
                  {this.props?.userSubscriptions?.manager?.includes(
                    TRADEMARK
                  ) && <Option value={TRADEMARK}>Trademark</Option>}
                  {this.props?.userSubscriptions?.manager?.includes(
                    CUSTOM_TRADEMARK
                  ) && (
                    <Option width={100} value={CUSTOM_TRADEMARK}>
                      Custom trademark
                    </Option>
                  )}
                  {this.props?.userSubscriptions?.manager?.includes(
                    INTERNATIONAL_TRADEMARK
                  ) && (
                    <Option value={INTERNATIONAL_TRADEMARK}>
                      International trademark
                    </Option>
                  )}
                  {this.props?.userSubscriptions?.manager?.includes(PATENT) && (
                    <Option value={PATENT}>Patent</Option>
                  )}
                  {this.props?.userSubscriptions?.manager?.includes(
                    CUSTOM_PATENT
                  ) && <Option value={CUSTOM_PATENT}>Custom Patent</Option>}
                  {this.props?.userSubscriptions?.manager?.includes(
                    INTERNATIONAL_PATENT
                  ) && (
                    <Option value={INTERNATIONAL_PATENT}>
                      International Patent
                    </Option>
                  )}
                  {this.props?.userSubscriptions?.watch?.includes(
                    "Journal Watch"
                  ) && <Option value={JOURNAL_WATCH}>Journal Watch</Option>}
                  {this.props?.userSubscriptions?.watch?.includes(
                    "TM Protect"
                  ) && <Option value={TM_PROTECT}>TM Protect</Option>}
                </Select>
              )}
            </Form.Item>

            {this.props.product === "tm-manager" &&
              this.state.subscription === TRADEMARK &&
              this.props?.userSubscriptions?.manager?.includes(TRADEMARK) && (
                <Form.Item
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 32px)",
                    margin: "0 8px",
                  }}
                >
                  <Select
                    defaultValue="Portfolio trademark"
                    style={{ width: "100%" }}
                    onChange={(val) => this.setState({ markType: val })}
                  >
                    <Option value="Portfolio trademark">PORTFOLIO TM</Option>
                    <Option value="Third party Opposed trademark">
                      THIRD PARTY TM
                    </Option>
                    <Option value="Status tracker">STATUS TRACKER</Option>
                  </Select>
                </Form.Item>
              )}
            <Form.Item style={{ display: "inline-block", margin: "8px 12px" }}>
              <Text>Excel Format: </Text>
              {this.state.subscription === TM_PROTECT ? (
                <a
                  href={
                    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/TMProtectEmailUploadSample.xlsx"
                  }
                >
                  Download Sample Excel(TM Protect)
                </a>
              ) : (
                <a
                  href={
                    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/EmailUploadSample.xlsx"
                  }
                >
                  Download Sample Excel
                </a>
              )}
              <div>
                <Text strong>Upload an excel in correct format:</Text>
                <div>
                  <Input
                    type="file"
                    id="input-tradmark-File"
                    className={`choose-input-file ${
                      this.state.uploadFileError ? "error" : ""
                    }`}
                    value={this.state.fileValue}
                    onChange={(e) => this.onUploadDocument(e)}
                    style={{ backgroundColor: "#ffffff" }}
                  />
                  <Text
                    danger
                    className={
                      this.state.uploadFileError ? "visible" : "not-visible"
                    }
                  >
                    Please upload only Excel File
                  </Text>
                </div>
              </div>
            </Form.Item>
          </>
        </Modal>
      </>
    );
  }

  componentWillUnmount() {}
}

const mapDispatchToProps = {
  clientEmailUpload,
  // setStoreData,
  setStoreData,
};

function mapStateToProps(state) {
  const {
    //     selectedSubscription,
    //     mmType,
    clientEmailUploadData,
    createClientEmailStatus,
  } = state.clientEmailState;

  const { userSubscriptions, userSubscriptionStatus } = state.userSubscriptions;

  return deepFreeze({
    clientEmailUploadData,
    createClientEmailStatus,
    userSubscriptions,
    userSubscriptionStatus,
    // selectedSubscription,

    // mmType,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AddClientEmails));
