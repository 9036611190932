//--------LIBRARIES--------
import Axios from "axios";

//--------ROOT_CONFIG--------
import { MGR_RT_URL } from "../../../../configs/rootConfigs";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------TYPES--------
import {
  TM_DASHBOARD,
  TM_DASHBOARD_ANALYTICS,
  OPPOSED_TM_DASHBOARD_ANALYTICS,
  PATENT_DASHBOARD_ANALYTICS,
  OPPOSED_TM_DASHBOARD,
  PATENT_DASHBOARD,
  TM_NOTIFICATION,
  PATENT_NOTIFICATION,
  CUSTOM_NOTIFICATION,
  TM_CUSTOM,
  TM_INTERNATIONAL,
  PATENT_CUSTOM,
  PATENT_INTERNATIONAL,
  DELETE_TM_CUSTOM,
  DELETE_TM_INTERNATIONAL,
  DELETE_PATENT_CUSTOM,
  DELETE_PATENT_INTERNATIONAL,
  DOCUMENT_UPLOAD,
} from "./types";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../../commonActions/actions";

//--------Manager: Dashboard--------
export function getDashboard(type, mm_type) {
  return async (dispatch) => {
    switch (type) {
      case "trademark":
        switch (mm_type) {
          case "trademark":
            dispatch({ type: TM_DASHBOARD, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/dashboard/`, {
                params: {
                  type,
                  mm_type,
                  required_field: "TOTAL_COUNT",
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: TM_DASHBOARD,
                status: SUCCESS,
                payload: response?.data,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: TM_DASHBOARD, status: ERROR });
            }
            break;

          case "opposed":
            dispatch({ type: OPPOSED_TM_DASHBOARD, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/dashboard/`, {
                params: {
                  type,
                  mm_type,
                  required_field: "TOTAL_COUNT",
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: OPPOSED_TM_DASHBOARD,
                status: SUCCESS,
                payload: response?.data,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: OPPOSED_TM_DASHBOARD, status: ERROR });
            }
            break;

          default:
            break;
        }
        break;

      case "patent":
        dispatch({ type: PATENT_DASHBOARD, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${MGR_RT_URL}/dashboard/`, {
            params: {
              type,
              required_field: "TOTAL_COUNT",
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: PATENT_DASHBOARD,
            status: SUCCESS,
            payload: response?.data,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: PATENT_DASHBOARD, status: ERROR });
        }
        break;

      default:
        break;
    }
  };
}

export function getDashboardAnalytics(type, mm_type) {
  return async (dispatch) => {
    switch (type) {
      case "trademark":
        switch (mm_type) {
          case "trademark":
            dispatch({ type: TM_DASHBOARD_ANALYTICS, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/dashboard/`, {
                params: {
                  type,
                  mm_type,
                  required_field: "ANALYTICS",
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: TM_DASHBOARD_ANALYTICS,
                status: SUCCESS,
                payload: response?.data,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: TM_DASHBOARD_ANALYTICS, status: ERROR });
            }
            break;

          case "opposed":
            dispatch({ type: OPPOSED_TM_DASHBOARD_ANALYTICS, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/dashboard/`, {
                params: {
                  type,
                  mm_type,
                  required_field: "ANALYTICS",
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: OPPOSED_TM_DASHBOARD_ANALYTICS,
                status: SUCCESS,
                payload: response?.data,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: OPPOSED_TM_DASHBOARD_ANALYTICS, status: ERROR });
            }
            break;

          default:
            break;
        }
        break;

      case "patent":
        dispatch({ type: PATENT_DASHBOARD_ANALYTICS, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${MGR_RT_URL}/dashboard/`, {
            params: {
              type,
              required_field: "ANALYTICS",
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: PATENT_DASHBOARD_ANALYTICS,
            status: SUCCESS,
            payload: response?.data,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: PATENT_DASHBOARD_ANALYTICS, status: ERROR });
        }
        break;

      default:
        break;
    }
  };
}

//--------Manager: Notification--------
export function getNotification({
  type,
  mmType,
  filterFlag,
  filters,
  pageNumber,
}) {
  const fields = `${filters?.notificationFields || []}`;
  const proprietors = `${filters.proprietor}`;
  const opponent = `${filters.opponent}`;
  const applicant = `${filters.applicant}`;
  const inventor = `${filters.inventor}`;
  const date_of_notification = `${filters.date_of_notification}`;
  const contains_term = `${filters.containsTerm}`;
  return async (dispatch) => {
    switch (type) {
      case "trademark":
        dispatch({ type: TM_NOTIFICATION, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${MGR_RT_URL}/notifications/`, {
            params: {
              type: type,
              mm_type: mmType,
              filter_flag: filterFlag ? filterFlag : null,
              fields: filterFlag ? fields : null,
              proprietor: filterFlag ? proprietors : null,
              opponent: filterFlag ? opponent : null,
              applicant: filterFlag ? applicant : null,
              inventor: filterFlag ? inventor : null,
              date_of_notification: filterFlag ? date_of_notification : null,
              contains_term: filterFlag ? contains_term : null,
              page: pageNumber,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: TM_NOTIFICATION,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            notificationType: type === "patent",
            tmNotificationType: mmType,
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: TM_NOTIFICATION, status: ERROR });
        }
        break;

      case "patent":
        dispatch({ type: PATENT_NOTIFICATION, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${MGR_RT_URL}/notifications/`, {
            params: {
              type: type,
              filter_flag: filterFlag ? filterFlag : null,
              fields: filterFlag ? fields : null,
              proprietor: filterFlag ? proprietors : null,
              opponent: filterFlag ? opponent : null,
              applicant: filterFlag ? applicant : null,
              inventor: filterFlag ? inventor : null,
              date_of_notification: filterFlag ? date_of_notification : null,
              contains_term: filterFlag ? contains_term : null,
              page: pageNumber,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: PATENT_NOTIFICATION,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            notificationType: type === "patent",
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: PATENT_NOTIFICATION, status: ERROR });
        }
        break;

      default:
        break;
    }
  };
}

//--------Manager: Custom-Notification--------
export function getCustomNotification({ filterFlag, filters, pageNumber }) {
  const fields = `${filters?.notificationFields || []}`;
  const date_of_notification = `${filters.date_of_notification}`;
  const contains_term = `${filters.containsTerm}`;
  return async (dispatch) => {
    dispatch({ type: CUSTOM_NOTIFICATION, status: LOADING });
    try {
      let response;
      response = await Axios.get(
        `${MGR_RT_URL}/trademarks/notifications_custom/`,
        {
          params: {
            filter_flag: filterFlag ? filterFlag : null,
            fields: filterFlag ? fields : null,
            date_of_notification: filterFlag ? date_of_notification : null,
            contains_term: filterFlag ? contains_term : null,
            page: pageNumber,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: CUSTOM_NOTIFICATION,
        status: SUCCESS,
        payload: response?.data,
        lastAppliedFilters: {
          ...filters,
          filterFlag,
        },
        pageNumber,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: CUSTOM_NOTIFICATION, status: ERROR });
    }
  };
}

//--------Manager: Custom List --------
export function getCustomList({
  operation_type,
  object_type,
  filterFlag,
  filters,
  pageNumber,
}) {
  const classes = `${filters.trademarkClass}`;
  const trademark_status = `${filters.trademarkStatus}`;
  const trademark_type = `${filters.trademarkType}`;
  const trademark_office = `${filters.trademarkOffice}`;
  const country = `${filters.trademarkCountry}`;
  const state = `${filters.trademarkStates}`;
  const proprietor = `${filters.trademarkProprietors}`;
  const reference_number = `${filters.trademarkReferenceNo}`;
  const date_of_application = `${filters.date_of_application}`;
  const valid_upto_date = `${filters.valid_upto_date}`;

  const applicant = `${filters.applicant}`;
  const inventor = `${filters.inventor}`;
  const application_type = `${filters.applicationType}`;
  const field_of_invention = `${filters.fieldOfInvention}`;
  const status = filters.applicationStatus
    ? filters.applicationStatus.join("; ")
    : `${filters.applicationStatus}`;
  const legal_status = `${filters.legalStatus}`;
  const classification_ipc = `${filters.classificationIPC}`;
  const patentFilingCountry = `${filters.filingCountry}`;

  const contains_term = `${filters.containsTerm}`;

  return async (dispatch) => {
    switch (operation_type) {
      case "trademark":
        switch (object_type) {
          case "custom":
            dispatch({ type: TM_CUSTOM, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/custom_objects/`, {
                params: {
                  operation_type,
                  object_type,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  trademark_status: filterFlag ? trademark_status : null,
                  trademark_type: filterFlag ? trademark_type : null,
                  trademark_office: filterFlag ? trademark_office : null,
                  country: filterFlag ? country : null,
                  state: filterFlag ? state : null,
                  proprietor: filterFlag ? proprietor : null,
                  reference_number: filterFlag ? reference_number : null,
                  date_of_application: filterFlag ? date_of_application : null,
                  valid_upto_date: filterFlag ? valid_upto_date : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: TM_CUSTOM,
                status: SUCCESS,
                payload: response?.data,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: TM_CUSTOM, status: ERROR });
            }
            break;

          case "international":
            dispatch({ type: TM_INTERNATIONAL, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/custom_objects/`, {
                params: {
                  operation_type,
                  object_type,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  trademark_status: filterFlag ? trademark_status : null,
                  trademark_type: filterFlag ? trademark_type : null,
                  trademark_office: filterFlag ? trademark_office : null,
                  country: filterFlag ? country : null,
                  state: filterFlag ? state : null,
                  proprietor: filterFlag ? proprietor : null,
                  reference_number: filterFlag ? reference_number : null,
                  date_of_application: filterFlag ? date_of_application : null,
                  valid_upto_date: filterFlag ? valid_upto_date : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: TM_INTERNATIONAL,
                status: SUCCESS,
                payload: response?.data,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: TM_INTERNATIONAL, status: ERROR });
            }
            break;

          default:
            break;
        }

        break;

      case "patent":
        switch (object_type) {
          case "custom":
            dispatch({ type: PATENT_CUSTOM, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/custom_objects/`, {
                params: {
                  operation_type,
                  object_type,
                  filter_flag: filterFlag ? filterFlag : null,
                  applicant: filterFlag ? applicant : null,
                  inventor: filterFlag ? inventor : null,
                  application_type: filterFlag ? application_type : null,
                  field_of_invention: filterFlag ? field_of_invention : null,
                  status: filterFlag ? status : null,
                  legal_status: filterFlag ? legal_status : null,
                  classification_ipc: filterFlag ? classification_ipc : null,
                  filing_country: filterFlag ? patentFilingCountry : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: PATENT_CUSTOM,
                status: SUCCESS,
                payload: response?.data,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: PATENT_CUSTOM, status: ERROR });
            }
            break;

          case "international":
            dispatch({ type: PATENT_INTERNATIONAL, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/custom_objects/`, {
                params: {
                  operation_type,
                  object_type,
                  filter_flag: filterFlag ? filterFlag : null,
                  applicant: filterFlag ? applicant : null,
                  inventor: filterFlag ? inventor : null,
                  application_type: filterFlag ? application_type : null,
                  field_of_invention: filterFlag ? field_of_invention : null,
                  status: filterFlag ? status : null,
                  legal_status: filterFlag ? legal_status : null,
                  classification_ipc: filterFlag ? classification_ipc : null,
                  filing_country: filterFlag ? patentFilingCountry : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: PATENT_INTERNATIONAL,
                status: SUCCESS,
                payload: response?.data,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: PATENT_INTERNATIONAL, status: ERROR });
            }
            break;

          default:
            break;
        }

        break;

      default:
        break;
    }
  };
}

//--------Manager: DELETE Custom List --------
export function deleteCustomList({
  operation_type,
  object_type,
  selected_marks,
}) {
  return async (dispatch) => {
    switch (operation_type) {
      case "trademark":
        let trademarks;
        if (selected_marks && selected_marks.length === 0) {
          trademarks = null;
        } else {
          trademarks = selected_marks;
        }
        switch (object_type) {
          case "custom":
            dispatch({ type: DELETE_TM_CUSTOM, status: LOADING });
            try {
              let response;
              response = await Axios.delete(`${MGR_RT_URL}/custom_objects/`, {
                data: { operation_type, object_type, trademarks },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: DELETE_TM_CUSTOM,
                status: SUCCESS,
                payload: response?.data,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: DELETE_TM_CUSTOM, status: ERROR });
            }
            break;

          case "international":
            dispatch({ type: DELETE_TM_INTERNATIONAL, status: LOADING });
            try {
              let response;
              response = await Axios.delete(`${MGR_RT_URL}/custom_objects/`, {
                data: { operation_type, object_type, trademarks },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: DELETE_TM_INTERNATIONAL,
                status: SUCCESS,
                payload: response?.data,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: DELETE_TM_INTERNATIONAL, status: ERROR });
            }
            break;

          default:
            break;
        }
        break;

      case "patent":
        let patents;
        if (selected_marks && selected_marks.length === 0) {
          patents = null;
        } else {
          patents = selected_marks;
        }
        switch (object_type) {
          case "custom":
            dispatch({ type: DELETE_PATENT_CUSTOM, status: LOADING });
            try {
              let response;
              response = await Axios.delete(`${MGR_RT_URL}/custom_objects/`, {
                data: { operation_type, object_type, patents },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: DELETE_PATENT_CUSTOM,
                status: SUCCESS,
                payload: response?.data,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: DELETE_PATENT_CUSTOM, status: ERROR });
            }
            break;

          case "international":
            dispatch({ type: DELETE_PATENT_INTERNATIONAL, status: LOADING });
            try {
              let response;
              response = await Axios.delete(`${MGR_RT_URL}/custom_objects/`, {
                data: { operation_type, object_type, patents },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: DELETE_PATENT_INTERNATIONAL,
                status: SUCCESS,
                payload: response?.data,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: DELETE_PATENT_INTERNATIONAL, status: ERROR });
            }
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  };
}

//--------Manager: Custom List --------
export function getCustomListProfile({
  customActionType,
  id,
  operation_type,
  object_type,
}) {
  return async (dispatch) => {
    dispatch({ type: customActionType, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/custom_object/${id}/`, {
        params: { operation_type, object_type },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: customActionType,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: customActionType, status: ERROR });
    }
  };
}

export function getManagePatentProfile({
  customActionType,
  id,
  operation_type,
  object_type,
}) {
  return async (dispatch) => {
    dispatch({ type: customActionType, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/refined_patent/${id}/`, {
        // params: { operation_type, object_type },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      console.log("response of profile is::", response);
      dispatch({
        type: customActionType,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: customActionType, status: ERROR });
    }
  };
}

//--------Manager: Edit-Custom-List--------
export function editCustomProfile({
  customActionType,
  id,
  operation_type,
  object_type,
  custom_data,
}) {
  return async (dispatch) => {
    dispatch({ type: customActionType, status: LOADING });
    try {
      let response;
      response = await Axios.patch(
        `${MGR_RT_URL}/custom_object/${id}/`,
        { operation_type, object_type, custom_data },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: customActionType,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: customActionType, status: ERROR });
    }
  };
}

//--------Mark: Document-Upload--------
export function documentUpload({ fileName, applicationNumber }) {
  return async (dispatch) => {
    let formData = new FormData();
    if (fileName && fileName !== undefined) {
      formData.append("file_name", fileName);
    }
    dispatch({ type: DOCUMENT_UPLOAD, status: LOADING });
    try {
      let response;
      response = await Axios.post(`${MGR_RT_URL}/signing/`, formData, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: DOCUMENT_UPLOAD,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      switch (err.response.status) {
        case 400:
          dispatch({
            type: DOCUMENT_UPLOAD,
            status: ERROR,
            payload: err.response?.data.non_field_errors[0],
          });
          return ERROR;

        case 403:
          checkAuthorisation(dispatch, err);
          dispatch({
            type: DOCUMENT_UPLOAD,
            status: ERROR,
            payload: err.response?.data.message,
          });
          return ERROR;

        default:
          dispatch({
            type: DOCUMENT_UPLOAD,
            status: ERROR,
            payload: err.response?.data,
          });
          return ERROR;
      }
    }
  };
}
