import React, { useEffect } from "react";
import { Dropdown, Button, Space, Checkbox } from "antd";
import { DownOutlined } from "@ant-design/icons";
import DateRangePicker from "../../../../functions/commonFunctions/dateRangePicker";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  updateDateProposedValues,
  updateDateValues,
} from "../../../../redux/actions/watchActions/watchActions";

const DateFilter = ({
  menu_label = "",
  subtitle1 = "",
  subtitle2 = "",
  value1 = {
    from: "",
    to: "",
    dateString: "",
  },
  setValue1,
  value2 = {
    from: "",
    to: "",
    dateString: "",
  },
  setValue2,
  proposed = false,
  setProposed,
  tableView = false,
  filter_key_1 = "",
  filter_key_2 = "",
  shouldUpdateStore = true,
}) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const dispatch = useDispatch();
  const [range1, setRange1] = React.useState([
    value1?.from ? dayjs(value1.from) : "",
    value1?.to ? dayjs(value1.to) : "",
  ]);
  const [range2, setRange2] = React.useState([
    value2?.from ? dayjs(value2.from) : "",
    value2?.to ? dayjs(value2.to) : "",
  ]);

  useEffect(() => {
    setRange1([
      value1?.from ? dayjs(value1.from) : "",
      value1?.to ? dayjs(value1.to) : "",
    ]);
    setRange2([
      value2?.from ? dayjs(value2.from) : "",
      value2?.to ? dayjs(value2.to) : "",
    ]);
  }, [value1, value2]);

  const dateBox = () => (
    <>
      <div id="date-empty-div-id">
        <span style={{ fontWeight: "600" }}>{subtitle1}</span>
        <DateRangePicker
          startValue={value1.from}
          endValue={value1.to}
          setDate={(val) => setRange1(val)}
          range={range1}
          // id={`watch_filter_id_${filter_key_1 ?? 'date_1'}`}
          handleDateString={(startValue, endValue, dateString) => {
            let tempDate = {
              ...value1,
              from: startValue,
              to: endValue,
              dateString:
                startValue !== null || endValue !== null ? dateString : null,
            };
            setValue1(tempDate);
            shouldUpdateStore &&
              filter_key_1 &&
              dispatch(updateDateValues(filter_key_1, tempDate));
          }}
        />
      </div>
      <div>
        <span style={{ fontWeight: "600" }}>{subtitle2}</span>
        <DateRangePicker
          startValue={value2.from}
          endValue={value2.to}
          setDate={(val) => setRange2(val)}
          range={range2}
          id={`watch_filter_id_${filter_key_2 ?? "date_2"}`}
          handleDateString={(startValue, endValue, dateString) => {
            let tempDate = {
              ...value2,
              from: startValue,
              to: endValue,
              dateString:
                startValue !== null || endValue !== null ? dateString : null,
            };
            setValue2(tempDate);
            shouldUpdateStore &&
              filter_key_2 &&
              dispatch(updateDateValues(filter_key_2, tempDate));
          }}
        />
        <div style={{ textAlign: "center", lineHeight: "32px" }}>
          <Checkbox
            checked={proposed}
            id="id_proposed-to-checkbox"
            tagName="tagname_proposed-to-checkbox"
            onChange={(event) => {
              setProposed(event.target.checked);
              shouldUpdateStore &&
                filter_key_2 &&
                dispatch(
                  updateDateProposedValues(filter_key_2, event.target.checked),
                );
            }}
          >
            Proposed
          </Checkbox>
        </div>
      </div>
    </>
  );

  return tableView ? (
    dateBox()
  ) : (
    <Dropdown
      dropdownRender={() => dateBox()}
      overlayClassName="Filters-Menu"
      open={openFilter}
      onOpenChange={(event) => setOpenFilter(event)}
    >
      <Button
        className={`${
          value1.dateString.length > 0 ||
          value2.dateString.length > 0 ||
          proposed
            ? "Filters-Button-Selected"
            : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
      >
        <Space>
          {menu_label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DateFilter;
