export const finalReduxCachedState = (
  state,
  layout,
  urlKey,
  newState,
  componentRenderedKey,
  componentRendered,
) => {
  if (!layout || !urlKey) {
    return state;
  }
  return {
    ...state,
    [layout]: {
      ...state?.[layout],
      [urlKey]: {
        ...state?.[layout]?.[urlKey],
        config: {
          ...state?.[layout]?.[urlKey]?.config,
          ...newState,
        },
        [componentRenderedKey]: componentRendered,
      },
    },
  };
};
