import React, { useEffect, useState } from "react";
import { Drawer, Button, Spin } from "antd";
import { ROOT_URL } from "../../../../configs/rootConfigs";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "../../../../utils/pdf.worker.min.js",
  import.meta.url,
).toString();

const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
};

export default function TrademarkJournalPage({
  journalNumber,
  applicationNumber,
  closeDrawer,
}) {
  const [numPages, setNumPages] = useState();

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  let url = `${ROOT_URL}/watch/api/journal_threat_pdf/?journal_number=${journalNumber}&application_number=${applicationNumber}`;
  return (
    <Drawer
      title={
        <h5 style={{ marginTop: "10px" }}>
          Trademark's Journal Page
          <Button
            style={{ marginLeft: "10px" }}
            type="primary"
            ghost
            rootStyle={{ marginLeft: "20px" }}
            onClick={() => window.open(url, "_blank")}
          >
            Download
          </Button>
        </h5>
      }
      placement="right"
      width={800}
      closable={true}
      open={applicationNumber ? true : false}
      onClose={() => closeDrawer()}
      bodyStyle={{
        padding: 0,
      }}
    >
      <div className="trademark-journal-page vertical_scroll">
        {applicationNumber && (
          <div rootStyle={{ border: "2px solid #000000" }}>
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              loading={<Spin />}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        )}
      </div>
    </Drawer>
  );
}
