import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import {
  ClockCircleOutlined,
  DownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Button,
  Input,
  Table,
  Tooltip,
  message,
  Checkbox,
  Typography,
  Modal,
} from "antd";

//--------COMMON ACTIONS--------
import { getEmailDashboard } from "../../redux/actions/managerActions/commonAction/action";
import { logOut } from "../../redux/actions/commonActions/actions";
import {
  LOADING,
  SUCCESS,
} from "../../constants/commonConstants/generalConstants";
import {
  defaultSelectedFilter,
  defaultSearchFilter,
} from "../../constants/commonConstants/emailDashboard";
import { changeStateValue_L1 } from "../../functions/commonFunctions/generalFunctions";
import { checkValue } from "../../functions/commonFunctions/checkValueFunctions";
import DateRangePicker from "../../functions/commonFunctions/dateRangePicker";
import FilterSearchBasic from "./FilterComponents/FilterSearchBasic";

const { Content } = Layout;
const { Text } = Typography;
const { Search } = Input;

class EmailDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: null,
      searchFilters: defaultSearchFilter,
      selectedFilters: this.props.lastAppliedFilters,
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.setDate = this.setDate.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.getEmailDashboard({
        filters: defaultSelectedFilter,
      });
    } else {
      this.props.logOut();
    }
  }

  setDate(val) {
    this.setState({ range: val });
  }

  resetFilter() {
    this.setState({ range: null });
    const { emailSources, date_of_sending, containsTerm } =
      this.props.lastAppliedFilters;

    if (
      emailSources.length > 0 ||
      date_of_sending.length > 0 ||
      containsTerm.length > 0
    ) {
      this.props.getEmailDashboard({
        filters: defaultSelectedFilter,
        pageNumber: 1,
      });
      this.setState({
        selectedFilters: defaultSelectedFilter,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Applied");
    }
  }

  applyFilter() {
    const { emailSources, date_of_sending, containsTerm } =
      this.state.selectedFilters;

    if (
      emailSources.length > 0 ||
      date_of_sending.length > 0 ||
      containsTerm.length > 0
    ) {
      this.props.getEmailDashboard({
        filters: this.state.selectedFilters,
        pageNumber: 1,
      });
      this.setState({
        pageNumber: 1,
      });
    } else {
      message.error("please select filter!");
    }
  }

  handleNextpage = (pageNumber) => {
    this.props.getEmailDashboard({
      filters: this.state.selectedFilters,
      page: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  showMailBody(data) {
    Modal.info({
      title: "Email Body",
      width: "50%",
      content: <div dangerouslySetInnerHTML={{ __html: data }}></div>,
      onOk() {},
    });
  }

  render() {
    const { searchFilters, selectedFilters } = this.state;
    const {
      managerEmailData,
      managerEmailDataStatus,
      watchAlert,
      searchAlert,
      managerAlert,
    } = this.props;

    let height = "calc(100vh - 0px)";
    if (
      watchAlert.length > 0 ||
      searchAlert.length > 0 ||
      managerAlert.length > 0
    ) {
      height = "calc(100vh - 40px)";
    } else {
      height = "calc(100vh - 0px)";
    }

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let sourceMenu = noFilterData;

    if (managerEmailDataStatus === SUCCESS) {
      const { sources } = managerEmailData.filters;

      if (sources !== null && sources.length > 0) {
        let filteredSource = sources.filter((eachSource) =>
          checkValue(eachSource)
            ? eachSource.toLowerCase().includes(searchFilters.sourceSearch)
            : ""
        );

        sourceMenu = (
          <FilterSearchBasic
            data={filteredSource}
            searchFilters={searchFilters}
            search_key={"sourceSearch"}
            filter_key={"emailSources"}
            selectedFilters={selectedFilters}
            switchValue={this.state.hearingType}
            onSelect={this.changeStateValue_L1}
          />
        );
      }
    }

    const emailDashboardColumns = [
      {
        title: "SOURCE",
        render: (record) => {
          return (
            <div>
              <span>
                {checkValue(record.source) ? record.source : "N.A."} -{" "}
              </span>
              <p>
                (
                {checkValue(record.source_title) ? record.source_title : "N.A."}
                )
              </p>
            </div>
          );
        },
      },

      {
        title: "SEND FROM | SEND TO",
        render: (record) => {
          return (
            <div>
              <span>
                {checkValue(record.from_email) ? record.from_email : "N.A."}{" "}
              </span>
              <p>{checkValue(record.to_email) ? record.to_email : "N.A."}</p>
            </div>
          );
        },
      },

      {
        title: "CC ( EMAIL | STATUS | LOCATION | DATE AND TIME )",
        width: "20%",
        render: (record) => {
          return (
            <div
              className="vertical_scroll"
              style={{ maxHeight: 200, height: 200, overflow: "auto" }}
            >
              {record.send_emails &&
                record.send_emails.length > 0 &&
                record.send_emails.map((eachMail, key) => (
                  <div
                    style={{
                      marginBottom: 20,
                      border: "1px solid #ddd",
                      padding: 5,
                    }}
                  >
                    <span>
                      <strong>
                        {checkValue(eachMail.email) ? eachMail.email : "N.A."}
                      </strong>
                    </span>
                    {eachMail.events &&
                      eachMail.events.length > 0 &&
                      eachMail.events.map((eachEvents, key) => (
                        <>
                          <div>
                            <div style={{ marginBottom: 5 }}>
                              <span>
                                {checkValue(eachEvents.event_status)
                                  ? eachEvents.event_status
                                  : "N.A."}
                              </span>
                              <Tooltip title="Event Status">
                                <InfoCircleOutlined
                                  style={{
                                    float: "right",
                                    color: "#ff5500",
                                    fontSize: "18px",
                                    marginRight: 5,
                                  }}
                                />
                              </Tooltip>
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              <span>
                                {checkValue(eachEvents.geo_location)
                                  ? eachEvents.geo_location
                                  : "N.A."}
                              </span>
                              <Tooltip title="Location">
                                <i
                                  className="fa fa-map-marker"
                                  aria-hidden="true"
                                  style={{
                                    float: "right",
                                    color: "#40a9ff",
                                    fontSize: "20px",
                                    marginRight: 5,
                                  }}
                                ></i>
                              </Tooltip>
                            </div>
                            <div style={{ marginBottom: 5 }}>
                              <span>
                                {checkValue(eachEvents.date_time_of_event)
                                  ? eachEvents.date_time_of_event
                                  : "N.A."}
                              </span>
                              <Tooltip title="Date and Time of Event">
                                <ClockCircleOutlined
                                  style={{
                                    float: "right",
                                    color: "",
                                    fontSize: "18px",
                                    marginRight: 5,
                                  }}
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <hr style={{ marginTop: 5, marginBottom: 5 }} />
                        </>
                      ))}
                  </div>
                ))}
              {record.send_emails.length === 0 && (
                <div>
                  <p>N.A.</p>
                </div>
              )}
            </div>
          );
        },
      },

      {
        title: "SUBJECT",
        dataIndex: "email_subject",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
      },

      {
        title: "MAIL BODY",
        render: (record) => {
          return (
            <>
              {record.email_body && (
                <>
                  <div
                    style={{ maxHeight: 200, height: 200, overflow: "hidden" }}
                    dangerouslySetInnerHTML={{ __html: record.email_body }}
                  ></div>
                  {record.email_body.length > 300 && (
                    <span
                      className="application-text alignC"
                      onClick={() => this.showMailBody(record.email_body)}
                    >
                      See more
                    </span>
                  )}
                </>
              )}
            </>
          );
        },
      },

      {
        title: "DATE OF SENDING",
        render: (record) => {
          return (
            <div>
              <Tooltip title="Date of Sending">
                <span>
                  {checkValue(record.date_of_sending)
                    ? record.date_of_sending
                    : "N.A."}
                </span>
              </Tooltip>
              <Tooltip title="Sending Status">
                <LegacyIcon
                  style={{
                    fontSize: "20px",
                    float: "right",
                    color: `${record.is_failed ? "#f81d22" : "#52c41a"}`,
                  }}
                  type={`${record.is_failed ? "warning" : "check-circle"}`}
                />
              </Tooltip>
            </div>
          );
        },
      },

      {
        title: "ATTACHMENT",
        render: (record) => {
          return (
            <div>
              <p
                className={
                  checkValue(record.file_name) ? "application-text" : ""
                }
                onClick={
                  checkValue(record.file_name)
                    ? () => window.open(record.file_attachment, "_blank")
                    : ""
                }
              >
                {checkValue(record.file_name) ? record.file_name : "N.A."}
              </p>
            </div>
          );
        },
      },
    ];

    return (
      <Layout>
        <Content style={{ margin: "0px" }}>
          <div
            className="content-container vertical_scroll"
            style={{ height: `${height}` }}
          >
            {/* email dashboard filter */}
            <Row justify="center" style={{ borderBottom: "1px solid #E9E9E9" }}>
              <Col span={14} className="m-top-10 alignC">
                <Menu
                  selectedKeys={[
                    selectedFilters?.emailSources.length > 0
                      ? "sourceMenu"
                      : "",
                  ]}
                  className="filter-list"
                  mode="horizontal"
                  style={{ alignItems: "baseline" }}
                >
                  <Menu.Item key="sourceMenu" className="filter-list-item">
                    <Dropdown
                      overlay={sourceMenu}
                      overlayClassName="filter-menu"
                    >
                      <div className="ant-dropdown-link">
                        <span>Source </span>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </Menu.Item>
                  <Menu.Item key="sendingDate" className="filter-list-item">
                    <Dropdown
                      overlay={
                        <DateRangePicker
                          type={"Date of Sending"}
                          startValue={
                            selectedFilters.date_of_sending_startValue
                          }
                          endValue={selectedFilters.date_of_sending_endValue}
                          setDate={(val) => this.setDate(val)}
                          range={this.state.range}
                          handleDateString={(
                            startValue,
                            endValue,
                            dateString
                          ) =>
                            this.setState({
                              selectedFilters: {
                                ...this.state.selectedFilters,
                                date_of_sending_startValue: startValue,
                                date_of_sending_endValue: endValue,
                                date_of_sending:
                                  startValue !== null || endValue !== null
                                    ? dateString
                                    : null,
                              },
                            })
                          }
                        />
                      }
                      overlayClassName="filter-menu"
                    >
                      <div className="ant-dropdown-link">
                        <Text>Date of Sending </Text>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </Menu.Item>
                  <Menu.Item
                    key="contentTerm"
                    className="filter-list-item apply-filter"
                  >
                    <Search
                      placeholder="search..."
                      onChange={(event) =>
                        this.changeStateValue_L1(
                          "selectedFilters",
                          "containsTerm",
                          event.target.value
                        )
                      }
                      value={selectedFilters?.containsTerm}
                      allowClear
                      style={{ width: 200, display: "block" }}
                    />
                  </Menu.Item>
                  <Menu.Item
                    key="applyFilter"
                    className="filter-list-item apply-filter"
                  >
                    <Button type="primary" onClick={() => this.applyFilter()}>
                      <i className="fa fa-filter" aria-hidden="true" />
                      <Text style={{ color: "#fff", marginLeft: "5px" }}>
                        Apply Filter
                      </Text>
                    </Button>
                  </Menu.Item>
                  <Menu.Item
                    key="resetFilter"
                    className="filter-list-item apply-filter"
                  >
                    <span
                      onClick={() => this.resetFilter()}
                      style={{ padding: "0px 5px" }}
                    >
                      RESET
                    </span>
                  </Menu.Item>
                </Menu>
              </Col>
            </Row>
            {/* email dashboard filter */}

            {/* email dashboard Table */}
            <Row>
              <Col span={24} className="m-top-10">
                <Table
                  bordered
                  rowKey={(record) => record.id}
                  dataSource={managerEmailData && managerEmailData.emails}
                  columns={emailDashboardColumns}
                  pagination={{
                    pageSize: 100,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    defaultCurrent: 1,
                    total: managerEmailData && managerEmailData.count,
                    onChange: this.handleNextpage,
                  }}
                  loading={managerEmailDataStatus === LOADING}
                  scroll={{ y: "calc(100vh - 220px)" }}
                />
              </Col>
            </Row>
            {/* email dashboard Table */}
          </div>
        </Content>
      </Layout>
    );
  }
}

const mapDispatchToProps = {
  getEmailDashboard,
  logOut,
};

function mapStateToProps(state) {
  const {
    managerEmailData,
    managerEmailDataStatus,
    lastAppliedFilters,
    pageNumber,
  } = state.managerState.managerEmailState;
  return deepFreeze({
    managerEmailData,
    managerEmailDataStatus,
    lastAppliedFilters,
    pageNumber,
    watchAlert: state.alertMessages.watch,
    searchAlert: state.alertMessages.search,
    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailDashboard);
