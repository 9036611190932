import React from "react";
import { Button, Drawer, Row, Col, Space, Popconfirm } from "antd";
import { CloseOutlined, FilterFilled } from "@ant-design/icons";
import {
  date_format,
  default_template_page,
  default_template_page_size,
} from "../../../utils/utils";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import FilterSection from "./FilterSection";
import TemplateSection from "./template/TemplateSection";
import {
  getWatchFilterTemplate,
  changeTemplateId,
  updateWatchTemplate,
  createWatchTemplate,
  temporarySaveThreatFilters,
  updateLastWeekThreats,
} from "../../../../../../redux/actions/watchActions/watchActions";
import {
  USER_TEMPLATE_TYPE,
  RECOMMENDED_TEMPLATE_TYPE,
} from "../../../../../../redux/actions/watchActions/types";
import { getGridLayoutThreatData } from "../../../../../../redux/actions/watchActions/gridDashboardActions";

const MoreFilterSectionDrawer = (props) => {
  const {
    open,
    setOpen,
    journal,
    setThreatFilterApplied,
    isAnyFilterSelected,
    getFilterPath,
  } = props;
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = React.useState([]);
  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3];

  const { USER, RECOMMENDED, current_template_id, template_status } =
    useSelector(
      (state) => state.watchState.watchTemplate[dashboardLayout][urlKey].config,
    );

  // Extra state to achieve data from template and later on store it on real parent filter.

  const [searchType, setSearchType] = React.useState("Application No/Name");
  const [searchValue, setSearchValue] = React.useState("");
  const [excludeReported, setExcludeReported] = React.useState(false);

  // ---------------------------

  // Advanced filter state ------------------------------------------------------------
  const [threatAttorneyValue, setThreatAttorneyValue] = React.useState([]);
  const [threatProprietors, setThreatProprietors] = React.useState([]);
  const [excludeThreatProprietors, setExcludeThreatProprietors] =
    React.useState([]);
  const [threatTMClass, setThreatClass] = React.useState([]);
  const [similarity, setSimilarity] = React.useState({
    from: 0,
    to: 100,
  });
  const [threatDateOfApplication, setThreatDateOfApplication] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [threatDateOfUsage, setThreatDateOfUsage] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [threatDateOfUsageProposed, setThreatDateOfUsageProposed] =
    React.useState(false);
  const [sameClassInfringement, setSameClassInfringement] =
    React.useState(false);
  const [excludeSameClassInfringement, setExcludeSameClassInfringement] =
    React.useState(false);

  // Dashboard filter state ------------------------------------------------------------
  const [threatTMTypes, setThreatTMTypes] = React.useState([]);
  const [threatTMCategories, setThreatTMCategories] = React.useState([]);
  const [threatTMOffices, setThreatTMOffices] = React.useState([]);
  const [infringement, setInfringement] = React.useState([]);
  const [shortlisted, setShortlisted] = React.useState(false);
  const [excludeShortlisted, setExcludeShortlisted] = React.useState(false);
  const [threatLabel, setThreatLabel] = React.useState([]);

  // AND - OR  states
  const [andOrThreatLabel, setAndOrThreatLabel] = React.useState([]);
  const [andOrThreatClass, setAndOrThreatClass] = React.useState(false);

  const getValueFromParentProps = () => {
    setThreatTMTypes(props.threatTMTypes);
    setThreatTMCategories(props.threatTMCategories);
    setThreatTMOffices(props.threatTMOffices);
    setInfringement(props.infringement);
    setSearchType(props.searchType);
    setSearchValue(props.searchValue);
    setExcludeReported(props.excludeReported);
    setThreatAttorneyValue(props.threatAttorneyValue);
    setThreatProprietors(props.threatProprietors);
    setExcludeThreatProprietors(props.excludeThreatProprietors);
    setThreatClass(props.threatTMClass);
    setSimilarity(props.similarity);
    setThreatDateOfApplication(props.threatDateOfApplication);
    setThreatDateOfUsage(props.threatDateOfUsage);
    setThreatDateOfUsageProposed(props.threatDateOfUsageProposed);
    setSameClassInfringement(props.sameClassInfringement);
    setExcludeSameClassInfringement(props.excludeSameClassInfringement);
    setShortlisted(props.shortlisted);
    setExcludeShortlisted(props.excludeShortlisted);
    setThreatLabel(props.threatLabel);
    setAndOrThreatLabel(props.andOrThreatLabel);
    setAndOrThreatClass(props.andOrThreatClass);
  };

  React.useEffect(() => {
    // to fetch all the values from dashboard whenever props changes
    getValueFromParentProps();
  }, [
    props.threatTMTypes,
    props.threatTMCategories,
    props.threatTMOffices,
    props.infringement,
    props.searchType,
    props.searchValue,
    props.excludeReported,
    props.threatAttorneyValue,
    props.threatProprietors,
    props.excludeThreatProprietors,
    props.threatTMClass,
    props.similarity,
    props.threatDateOfApplication,
    props.threatDateOfUsage,
    props.threatDateOfUsageProposed,
    props.sameClassInfringement,
    props.excludeSameClassInfringement,
    props.shortlisted,
    props.excludeShortlisted,
    props.threatLabel,
    props.andOrThreatLabel,
    props.andOrThreatClass,
  ]);

  React.useState(() => {
    // get template data ----- generic
    dispatch(
      getWatchFilterTemplate(
        USER_TEMPLATE_TYPE,
        `${getFilterPath()}/`,
        props?.markDetails?.application_number,
        default_template_page,
      ),
    );
    dispatch(
      getWatchFilterTemplate(
        RECOMMENDED_TEMPLATE_TYPE,
        `${getFilterPath()}/`,
        props?.markDetails?.application_number,
        default_template_page,
      ),
    );
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const getRealSearchParameters = () => {
    const defaultSearchParameters = {
      contains_term: {
        value: null,
      },
      threat_desc_contains_term: {
        value: null,
      },
    };

    switch (searchType) {
      case "Application No/Name":
        return Object.assign({}, defaultSearchParameters, {
          contains_term: {
            value: searchValue,
          },
        });
      case "Your Threat Description Search":
        return Object.assign({}, defaultSearchParameters, {
          threat_desc_contains_term: {
            value: searchValue,
          },
        });
      default:
        return defaultSearchParameters;
    }
  };

  const updateParentStates = () => {
    props.setThreatTMTypes(threatTMTypes);
    props.setThreatTMCategories(threatTMCategories);
    props.setThreatTMOffices(threatTMOffices);
    props.setInfringement(infringement);
    props.setSearchType(searchType);
    props.setSearchValue(searchValue);
    props.setExcludeReported(excludeReported);
    props.setThreatAttorneyValue(threatAttorneyValue);
    props.setThreatProprietors(threatProprietors);
    props.setExcludeThreatProprietors(excludeThreatProprietors);
    props.setThreatClass(threatTMClass);
    props.setSimilarity(similarity);
    props.setThreatDateOfApplication(threatDateOfApplication);
    props.setThreatDateOfUsage(threatDateOfUsage);
    props.setThreatDateOfUsageProposed(threatDateOfUsageProposed);
    props.setSameClassInfringement(sameClassInfringement);
    props.setExcludeSameClassInfringement(excludeSameClassInfringement);
    props.setAndOrThreatClass(andOrThreatClass);
    props.setShortlisted(shortlisted);
    props.setExcludeShortlisted(excludeShortlisted);
    props.setExcludeReported(excludeReported);
    props.setThreatLabel(threatLabel);
    props.setAndOrThreatLabel(andOrThreatLabel);
  };

  const generateFilterObject = () => {
    const filterObject = {
      threat_classes: {
        value: threatTMClass,
        logic: andOrThreatClass ? "AND" : "OR",
      },
      threat_types: {
        value: threatTMTypes,
      },
      threat_proprietors: {
        value: threatProprietors,
      },
      threat_categories: {
        value: threatTMCategories,
      },
      threat_offices: {
        value: threatTMOffices,
      },
      threat_date_of_application: {
        value: {
          from: threatDateOfApplication.from
            ? dayjs(threatDateOfApplication.from).format(date_format)
            : "",
          to: threatDateOfApplication.to
            ? dayjs(threatDateOfApplication.to).format(date_format)
            : "",
        },
      },
      threat_date_of_usage: {
        value: {
          from: threatDateOfUsage.from
            ? dayjs(threatDateOfUsage.from).format(date_format)
            : "",
          to: threatDateOfUsage.to
            ? dayjs(threatDateOfUsage.to).format(date_format)
            : "",
        },
      },
      threat_date_of_usage_proposed: {
        value: threatDateOfUsageProposed,
      },
      red_band: {
        value: sameClassInfringement,
      },
      exclude_same_class: {
        value: excludeSameClassInfringement,
      },
      exclude_marks_wrt_custom_report: {
        value: excludeReported,
      },
      shortlisted: {
        value: shortlisted,
      },
      exclude_shortlisted: {
        value: excludeShortlisted,
      },
      exclude_threat_proprietors: {
        value: excludeThreatProprietors,
      },
      threat_attorney: {
        value: threatAttorneyValue,
      },
      ...getRealSearchParameters(),
    };
    if (urlKey === "image_label") {
      filterObject.threat_labels = {
        value: threatLabel,
        logic: andOrThreatLabel ? "AND" : "OR",
      };
    } else {
      filterObject.threat_sources = {
        value: infringement,
      };
      filterObject.threat_similarity = {
        value: {
          to: similarity.to,
          from: similarity.from,
        },
      };
    }
    return filterObject;
  };

  const isSelectAllChecked =
    props.selectedData.data?.[props.markDetails.application_number]?.select_all;

  const handleUpdateThreadCount = (newCount) => {
    dispatch(updateLastWeekThreats(props.markDetails.id, newCount));
    if (isSelectAllChecked) {
      props.setSelectedData({
        ...props.selectedData,
        threatsCount:
          Math.max(
            props.selectedData.threatsCount -
              props.markDetails.last_week_threats,
            0,
          ) + newCount,
      });
    }
  };

  const handleApplyFilter = () => {
    setThreatFilterApplied(true);
    updateParentStates();
    onClose();
    const filters = generateFilterObject();
    dispatch(
      temporarySaveThreatFilters(
        urlKey,
        props?.markDetails?.application_number,
        filters,
      ),
    );
    dispatch(
      getGridLayoutThreatData(
        props?.markDetails?.application_number,
        journal,
        1,
        true,
        filters,
        window?.location,
        (resStatus, res) => {
          if (resStatus === "SUCCESS") {
            handleUpdateThreadCount(res?.data?.metadata?.count);
          }
        },
      ),
    );
  };

  const handleResetFilterAndTemplate = () => {
    dispatch(changeTemplateId(0));
    getValueFromParentProps();
  };

  const parentProps = {
    journal: props.journal,
  };

  const localProps = {
    threatTMTypes,
    setThreatTMTypes,
    threatTMCategories,
    setThreatTMCategories,
    threatTMOffices,
    setThreatTMOffices,
    infringement,
    setInfringement,
    threatAttorneyValue,
    setThreatAttorneyValue,
    threatProprietors,
    setThreatProprietors,
    threatTMClass,
    setThreatClass,
    similarity,
    setSimilarity,
    threatDateOfApplication,
    setThreatDateOfApplication,
    threatDateOfUsage,
    setThreatDateOfUsage,
    threatDateOfUsageProposed,
    setThreatDateOfUsageProposed,
    excludeThreatProprietors,
    setExcludeThreatProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    shortlisted,
    setShortlisted,
    excludeShortlisted,
    setExcludeShortlisted,
    threatLabel,
    setThreatLabel,
    andOrThreatLabel,
    setAndOrThreatLabel,
    andOrThreatClass,
    setAndOrThreatClass,
    excludeReported,
    setExcludeReported,
  };

  const handleTemplateSave = async (fileName, optionSelected, setFileName) => {
    let body = {
      name: fileName,
      template_key: `${getFilterPath()}/`,
      template_type: "USER",
      template_json: generateFilterObject(),
      mark_id: props.markDetails.application_number,
    };
    if (optionSelected === 2) {
      // Update
      const modifiedBody = {
        ...body,
        pk: current_template_id,
      };
      await dispatch(
        updateWatchTemplate(
          modifiedBody,
          USER_TEMPLATE_TYPE,
          `${getFilterPath()}/`,
          USER.pageNumber,
        ),
      );
    } else {
      // Save New
      await dispatch(
        createWatchTemplate(
          body,
          USER_TEMPLATE_TYPE,
          `${getFilterPath()}/`,
          USER.pageNumber,
        ),
      );
      setFileName("");
    }
  };

  const generateDateObject = (item) => {
    // Split the date string by '-' and rearrange it to YYYY-MM-DD for dayjs better functionality.
    const fromDateParts = item.value.from.split("-");
    const fromDate = `${fromDateParts[2]}-${fromDateParts[1]}-${fromDateParts[0]}`;

    const toDateParts = item.value.to.split("-");
    const toDate = `${toDateParts[2]}-${toDateParts[1]}-${toDateParts[0]}`;

    return {
      from: fromDate.length ? dayjs(fromDate) : "",
      to: toDate.length ? dayjs(toDate) : "",
      dateString:
        fromDate.length && toDate.length
          ? `${dayjs(fromDate).format(date_format)}to${dayjs(toDate).format(
              date_format,
            )}`
          : "",
    };
  };

  const getSearchTerm = (template) => {
    if (template.contains_term.value) {
      setSearchValue(template.contains_term.value);
      setSearchType("Application No/Name");
    } else if (template.threat_desc_contains_term.value) {
      setSearchValue(template.threat_desc_contains_term.value);
      setSearchType("Your Threat Description Search");
    } else {
      setSearchType("Application No/Name");
      setSearchValue("");
    }
  };

  const handleUseTemplate = (template) => {
    const template_json = template.template_json;
    getSearchTerm(template_json);

    setExcludeSameClassInfringement(
      template_json?.exclude_same_class?.value
        ? template_json.exclude_same_class.value
        : false,
    );
    setSameClassInfringement(
      template_json?.red_band?.value ? template_json.red_band.value : false,
    );
    setExcludeReported(
      template_json?.exclude_marks_wrt_custom_report?.value
        ? template_json.exclude_marks_wrt_custom_report.value
        : false,
    );
    setSimilarity(
      template_json?.threat_similarity?.value
        ? template_json.threat_similarity.value
        : {
            from: 0,
            to: 100,
          },
    );
    setThreatAttorneyValue(
      template_json?.threat_attorney?.value.length
        ? template_json.threat_attorney.value
        : [],
    );
    setThreatTMCategories(
      template_json?.threat_categories?.value.length
        ? template_json.threat_categories.value
        : [],
    );
    setThreatClass(
      template_json?.threat_classes?.value.length
        ? template_json.threat_classes.value
        : [],
    );
    setThreatDateOfApplication(
      template_json?.threat_date_of_application?.value?.from.length ||
        template_json?.threat_date_of_application?.value?.to.length
        ? generateDateObject(template_json.threat_date_of_application)
        : { from: "", to: "", dateString: "" },
    );
    setThreatDateOfUsage(
      template_json?.threat_date_of_usage?.value?.from.length ||
        template_json?.threat_date_of_usage?.value?.to.length
        ? generateDateObject(template_json.threat_date_of_usage)
        : { from: "", to: "", dateString: "" },
    );
    setThreatDateOfUsageProposed(
      template_json?.threat_date_of_usage_proposed?.value
        ? template_json.threat_date_of_usage_proposed.value
        : false,
    );
    setThreatTMOffices(
      template_json?.threat_offices?.value.length
        ? template_json.threat_offices.value
        : [],
    );
    setThreatProprietors(
      template_json?.threat_proprietors?.value.length
        ? template_json.threat_proprietors.value
        : [],
    );
    setInfringement(
      template_json?.threat_sources?.value.length
        ? template_json.threat_sources.value
        : [],
    );
    setThreatTMTypes(
      template_json?.threat_types?.value.length
        ? template_json.threat_types.value
        : [],
    );
    setAndOrThreatClass(
      template_json?.threat_classes?.logic === "AND" ? true : false,
    );
    setExcludeThreatProprietors(
      template_json?.exclude_threat_proprietors?.value.length
        ? template_json.exclude_threat_proprietors.value
        : [],
    );
    setThreatLabel(
      template_json?.threat_labels?.value.length
        ? template_json.threat_labels.value
        : [],
    );
    setAndOrThreatLabel(
      template_json?.threat_labels?.logic === "AND" ? true : false,
    );
  };

  let filterSelected = isAnyFilterSelected(generateFilterObject());

  return (
    <Drawer
      title="Filters & Templates"
      placement="right"
      width={"80%"}
      style={{ overflowY: "inherit" }}
      closeIcon={
        filterSelected ? (
          <Popconfirm
            title="Want to apply current selected filters?"
            description="There are unapplied filters. Are you sure you want to leave without applying these filters?"
            okText="Apply"
            cancelText="Leave"
            onCancel={() => {
              handleResetFilterAndTemplate();
              onClose();
            }}
            onConfirm={handleApplyFilter}
          >
            <CloseOutlined />
          </Popconfirm>
        ) : (
          <CloseOutlined onClick={onClose} />
        )
      }
      open={open}
      extra={
        <Space>
          <Button
            type="text"
            danger
            onClick={() => props?.handleResetFilters(journal)}
            disabled={!filterSelected}
          >
            Reset All
          </Button>
          <Button
            type="primary"
            icon={<FilterFilled />}
            onClick={handleApplyFilter}
            disabled={!filterSelected}
          >
            Apply Filters
          </Button>
        </Space>
      }
    >
      <Row>
        <Col
          sm={24}
          md={14}
          style={{ padding: "0px 10px", borderRight: "1px solid lightgrey" }}
        >
          <FilterSection
            {...localProps}
            {...parentProps}
            current_template_id={current_template_id}
            threatFilterSelected={filterSelected}
            getFilterPath={getFilterPath}
            markDetails={props.markDetails}
          />
        </Col>
        <Col
          sm={24}
          md={10}
          style={{ padding: "0px 10px" }}
          className="tmapplied_ll_filter_template"
        >
          <TemplateSection
            selected={selectedTemplate}
            setSelected={setSelectedTemplate}
            handleTemplateSave={handleTemplateSave}
            handleUseTemplate={handleUseTemplate}
            handleReset={handleResetFilterAndTemplate}
            USER={USER}
            RECOMMENDED={RECOMMENDED}
            current_template_id={current_template_id}
            template_status={template_status}
            filterSelected={filterSelected}
            getTemplatePath={getFilterPath}
            markDetails={props.markDetails}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default MoreFilterSectionDrawer;
