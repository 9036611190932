import {
  W_GRID_GET_DATA,
  W_GRID_GET_THREAT_DATA,
  W_COMBINE_PDF,
  W_UPDATE_LAST_WEEK_THREATS,
  W_GRID_INSERT_SAMPLE_DATA,
} from "../../../actions/watchActions/types";
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import { finalReduxCachedState } from "../../../../functions/commonFunctions/finalCachedState";

const defaultState = {
  data: [],
  isDataPresent: false,
  count: null,
  pageNumber: 1,
  status: null,
  report_columns: [],
  bookmarkTemplate: null,
  bookmarkStatus: null,
  message: "",
  combinePdf: {
    loading: false,
  },
  journalNumber: {
    image_label: null,
    image_text: null,
    tm_applied: null,
    tm_protect: null,
    image_text_new: null,
  },
  threats: {
    data: [],
    count: null,
    pageNumber: 1,
    status: null,
    message: "",
  },
};

const newDefaultConfig = {
  list_layout: {
    image_label: {
      config: defaultState,
      isComponentRendered: false,
    },
    image_text: {
      config: defaultState,
      isComponentRendered: false,
    },
    image_text_new: {
      config: defaultState,
      isComponentRendered: false,
    },
    tm_protect: {
      config: defaultState,
      isComponentRendered: false,
    },
    tm_applied: {
      config: defaultState,
      isComponentRendered: false,
    },
    journals: {
      config: defaultState,
      isComponentRendered: false,
    },
    image_similarity: {
      config: defaultState,
      isComponentRendered: false,
    },
  },
  grid_layout: {
    image_label: {
      config: defaultState,
      isComponentRendered: false,
    },
    image_text: {
      config: defaultState,
      isComponentRendered: false,
    },
    image_text_new: {
      config: defaultState,
      isComponentRendered: false,
    },
    tm_protect: {
      config: defaultState,
      isComponentRendered: false,
    },
    tm_applied: {
      config: defaultState,
      isComponentRendered: false,
    },
    journals: {
      config: defaultState,
      isComponentRendered: false,
    },
    image_similarity: {
      config: defaultState,
      isComponentRendered: false,
    },
  },
};

export default function (state = newDefaultConfig, action) {
  switch (action.type) {
    case W_GRID_GET_DATA: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const updatedPayloadData = action?.payload?.data?.length
        ? action.payload.data.map((obj) => ({
            ...obj,
            constant_last_week_threats: obj.last_week_threats,
            application_number:
              urlKey === "tm_protect"
                ? obj.tm_protect_mark_id
                : obj.application_number,
          }))
        : [];
      switch (action.status) {
        case SUCCESS:
          const newSuccessState = {
            data: updatedPayloadData,
            isDataPresent: updatedPayloadData.length ? true : false,
            status: action.status,
            count: action.payload?.metadata?.count,
            pageNumber: action.pageNumber,
            report_columns: action.payload?.metadata?.report_columns,
            journalNumber: {
              ...state?.[layout]?.[urlKey]?.journalNumber,
              [`${action?.urlKey}`]: action.journalNumber,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newSuccessState,
            "isComponentRendered",
            true,
          );

        case LOADING:
          const newLoadingState = {
            status: action.status,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isComponentRendered",
            false,
          );

        case ERROR:
          const newErrorState = {
            status: action.status,
            count: 0,
            message: action.payload,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isComponentRendered",
            false,
          );

        default:
          return state;
      }
    }
    case W_GRID_GET_THREAT_DATA: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      switch (action.status) {
        case SUCCESS:
          let newSuccessState = {
            threats: {
              ...state?.[layout]?.[urlKey]?.threats,
              data: action.payload.data,
              status: action.status,
              count: action.payload?.metadata?.count,
              pageNumber: action.pageNumber,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newSuccessState,
            "isComponentRendered",
            true,
          );
        case LOADING:
          const newLoadingState = {
            threats: {
              ...state?.[layout]?.[urlKey]?.config?.threats,
              status: action.status,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isComponentRendered",
            false,
          );

        case ERROR:
          const newErrorState = {
            threats: {
              ...state?.[layout]?.[urlKey]?.config?.threats,
              status: action.status,
              count: 0,
              message: action.payload,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isComponentRendered",
            false,
          );
        default:
          return state;
      }
    }

    case W_COMBINE_PDF: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      switch (action.status) {
        case SUCCESS:
          const newSuccessState = {
            combinePdf: {
              ...state?.[layout]?.[urlKey]?.config?.combinePdf,
              loading: false,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newSuccessState,
            "isComponentRendered",
            true,
          );

        case LOADING:
          const newLoadingState = {
            combinePdf: {
              ...state?.[layout]?.[urlKey]?.config?.combinePdf,
              loading: false,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isComponentRendered",
            false,
          );

        case ERROR:
          const newErrorState = {
            combinePdf: {
              ...state?.[layout]?.[urlKey]?.config?.combinePdf,
              loading: false,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isComponentRendered",
            false,
          );

        default:
          return state;
      }
    }

    case W_UPDATE_LAST_WEEK_THREATS: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const updatedPayloadData = state?.[layout]?.[urlKey]?.config?.data?.length
        ? state?.[layout]?.[urlKey]?.config.data.map((obj) => ({
            ...obj,
            last_week_threats:
              obj.id === action.threatId ? action.count : obj.last_week_threats,
          }))
        : [];
      const newSuccessState = {
        ...state?.[layout]?.[urlKey]?.config,
        data: updatedPayloadData,
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newSuccessState,
        "isComponentRendered",
        true,
      );
    }

    case W_GRID_INSERT_SAMPLE_DATA: {
      const layout = action.layout;
      const urlKey = action.urlKey;
      const newState = {
        data: action.sampleObject ? [action.sampleObject] : [],
        isDataPresent: false,
        count: action.sampleObject ? 1 : 0,
        threats: {
          data: action.sampleObject ? [action.sampleThreatObject] : [],
          count: null,
          pageNumber: 1,
          status: null,
          message: "",
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isSampleThreatSampleData",
        true,
      );
    }

    default:
      return state;
  }
}
