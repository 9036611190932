//--------WATCH TYPES--------
import { SET_CURRENT_JOURNAL_DATE } from "../../actions/watchActions/types";

export default function(state = null, action){
    switch (action.type) {
        case SET_CURRENT_JOURNAL_DATE:
            return action.payload;

        default:
            return state;
    }
}