import React, { useEffect, useState } from "react";
import { Popconfirm, Tooltip, notification } from "antd";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TabContent } from "../../commonComponents/TabContent";
import { useDispatch, useSelector } from "react-redux";
import ClientNotesAddComponent from "./ClientNotesAddComponent";
import {
  deleteClientNotesrData,
  getClientNotesListData,
  updateSingleClientNotesData,
} from "../../../../../redux/actions/commonActions/masterActions/clientNotes";

export const NotesComponent = (props) => {
  const { parent, activeTab, key_value } = props;
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const dispatch = useDispatch();
  const tabHeight = 720;

  const { data, loading, metadata } = useSelector(
    (state) => state.masters.clientMaster.clientNotes,
  );
  const { count, pageNumber } = metadata;

  const getClientNotesData = () => {
    dispatch(getClientNotesListData(pageNumber, parent));
  };

  useEffect(() => {
    if (parent && activeTab === key_value) {
      getClientNotesData();
    }
  }, [parent, activeTab, dispatch]);

  const handleAddNotes = () => {
    setOpenAddForm(true);
  };

  const handleNotesNextPage = (pageNumber) => {
    dispatch(getClientNotesListData(pageNumber, parent));
  };

  const handleEditNotes = (client) => {
    dispatch(updateSingleClientNotesData(client));
    setOpenEditForm(true);
  };

  const handleDeleteNotes = (client) => {
    const body = {
      client_id: client?.client_id ?? "",
      id: client?.id ?? "",
    };
    dispatch(deleteClientNotesrData(body, pageNumber));
  };

  const renderColumns = [
    {
      className: "tm-protect-column-1",
      title: "S.No",
      width: 50,

      render: (text, record, index) => {
        return {
          children: (
            <div>
              <p>
                <span>{index + 1 + (pageNumber - 1) * 100}. </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Notes",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div style={{ maxHeight: "150px", overflow: "auto" }}>
              <div dangerouslySetInnerHTML={{ __html: record?.note }} />
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Modified",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.modified_date)
                    ? record?.modified_date
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Actions",
      width: 80,
      render: (text, record) => {
        return (
          <div
            className="iconTable tmapplied_ll_actions"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              padding: "8px",
            }}
          >
            <Tooltip title="Edit Note">
              <EditOutlined
                style={{ cursor: "pointer", color: "#1677ff" }}
                onClick={() => handleEditNotes(record)}
              />
            </Tooltip>
            <Popconfirm
              title="Delete Client Note"
              description="Are you sure to delete this note?"
              onConfirm={() => handleDeleteNotes(record)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Note">
                <DeleteOutlined style={{ cursor: "pointer", color: "red" }} />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const propsForTabContent = {
    addButtonLabel: "Add a Note",
    handleAddButton: handleAddNotes,
    childrenType: "table",
    loading: loading,
    tablePageNumber: pageNumber,
    dataCount: count,
    handleTableNextPage: handleNotesNextPage,
    renderColumns: renderColumns,
    tableData: data,
    isSelectable: false,
    tableHeight: tabHeight - 210,
  };

  const filters = {
    client_id: parent ?? "",
  };

  const propsForAddNotesPage = {
    open: openAddForm,
    setOpen: setOpenAddForm,
    type: "add",
    filters: filters,
  };

  const propsForEditNotesPage = {
    open: openEditForm,
    setOpen: setOpenEditForm,
    type: "edit",
    filters: filters,
  };

  return (
    <div
      style={{
        height: tabHeight,
        overflowY: "auto",
      }}
    >
      {openAddForm && <ClientNotesAddComponent {...propsForAddNotesPage} />}
      {openEditForm && <ClientNotesAddComponent {...propsForEditNotesPage} />}
      <TabContent {...propsForTabContent} />
    </div>
  );
};
