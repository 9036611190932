import { Button, Typography, Row, Col, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROOT_URL } from "../../../configs/rootConfigs";
import axios from "axios";
const { Title } = Typography;

const ButtonBlock = ({ title, description, button }) => (
  <Row>
    {title && (
      <Col xs={{ span: 12 }} lg={{ span: 22 }}>
        <Title level={4}>{title}</Title>
      </Col>
    )}
    {description && <Col span={24}>{description}</Col>}

    {button && (
      <Col span={24} style={{ margin: "16px 0px" }}>
        {button}
      </Col>
    )}
  </Row>
);
const SMTPMicrosoftAuth = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { is_microsoft_auth_visible } = useSelector(
    (state) =>
      state?.profile?.userProfile || { is_microsoft_auth_visible: false },
  );

  const handleMicrosoftAuth = async () => {
    setLoading(true);
    try {
      axios
        .get(`${ROOT_URL}/microsoft_auth/`, {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          const authUrl = response.data.auth_url;
          window.open(authUrl, "_blank");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error(error);
      message.error("API call failed!");
    } finally {
      setLoading(false);
    }
  };
  if (is_microsoft_auth_visible)
    return (
      <>
        <hr />

        <ButtonBlock
          title="Microsoft Office 365 SMTP Authentication"
          button={
            <Button
              type="primary"
              loading={loading}
              onClick={handleMicrosoftAuth}
            >
              Authorize
            </Button>
          }
        />
      </>
    );
  else return null;
};

export default SMTPMicrosoftAuth;
