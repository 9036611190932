//--------LIBRARIES--------
import { combineReducers } from "redux";

//--------REDUCERS--------
import signInReducer from "./signInReducer";
import forgotReducer from "./forgotReducer";
import watchReducer from "./watchReducers";
import searchReducer from "./searchReducers";
import managerReducer from "./managerReducers";
import userSubscriptions from "./userSubscriptions";
import profile from "./profile";
import whatsNewReducer from "./whatsNewReducer";
import getInTouchReducer from "./getInTouchReducer";
import sendEmailReducer from "./sendEmailReducer";
import alertMessagesReducer from "./alertMessages";
import commentReducer from "./comment";
import emailTemplate from "./emailTemplate";
import productSubscription from "./productSubscriptions";
import clientEmailReducer from "./clientEmailReducer";
import emailTemplateData from "./emailTemplateData";
import emailVariablesReducer from "./emailVariables";
import emailPreference from "./emailPreference";
import clientEmailCustomReport from "./clientEmailCustomReport";
//--------IP-SUITE TYPES--------
import { CLEAR_REDUX } from "../actions/commonActions/types";
import masterReducers from "./masterReducers";

const rootReducer = combineReducers({
  signInState: signInReducer,
  forgotState: forgotReducer,
  watchState: watchReducer,
  searchState: searchReducer,
  managerState: managerReducer,
  userSubscriptions,
  profile,
  whatsNewState: whatsNewReducer,
  getInTouchState: getInTouchReducer,
  sendEmailState: sendEmailReducer,
  alertMessages: alertMessagesReducer,
  commentState: commentReducer,
  emailTemplateState: emailTemplate,
  emailTemplateDataState: emailTemplateData,
  clientEmailState: clientEmailReducer,
  productSubscription,
  emailVariables: emailVariablesReducer,
  emailPreference,
  clientEmailCustomReport: clientEmailCustomReport,
  masters: masterReducers,
});

export const rootReducerWrapper = (state, action) => {
  let finalState = state;
  if (action.type === CLEAR_REDUX) {
    finalState = undefined;
  }

  return rootReducer(finalState, action);
};
