//--------LIBRARIES--------
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { history } from "./history";

//--------COMPONENTS--------
import App from "./router/App";

//--------STYLESHEETS--------
import "./index.css";
import "./stylesheets/main.scss";

//--------SERVICE WORKERS--------
import * as serviceWorker from "./serviceWorker";

//--------STORE--------
import store from "./redux/store";
//--------Sentry--------
import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import { ROOT_URL } from "./configs/rootConfigs";

Sentry.init({
  dsn: "https://d00fa4492df74c308eb7c354ee1e6c47@sentry.io/1541748",
  environment: "Staging",
  release: "ip-suite@dev",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0,
  //ant-design async validator version issue
  ignoreErrors: ["Non-Error promise rejection captured"],
});
Sentry.setTag(
  "userName",
  localStorage.getItem("userName") !== null ||
    localStorage.getItem("userName") !== undefined
    ? localStorage.getItem("userName")
    : "NA",
);
Sentry.setTag(
  "userEmail",
  localStorage.getItem("userEmail") !== null ||
    localStorage.getItem("userEmail") !== undefined
    ? localStorage.getItem("userEmail")
    : "NA",
);

/* Mixpanel Initialization */
if (ROOT_URL === "https://mikeapi-staging.mikelegal.com/api") {
  // staging init

  mixpanel.init("f549f129ebfd19796a07da48daddb584", { debug: true });
} else {
  // prod init
  mixpanel.init("d20a43b4db72df0cc16276d071d9e3d2", { debug: false });
}

const rootElement = document.getElementById("IP-Suite");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
