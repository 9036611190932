import React, { Component } from "react";
import { Alert, Button, message, Col } from "antd";
import SignInSider from "./SignInSider";
import "../../stylesheets/loginstylesheets/login.css";
import { connect } from "react-redux";
import { history } from "../../history";

class RecoveryCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { recoveryCodes } = this.props;

    return (
      <div class="container-fluid">
        <div class="row login-row">
          <SignInSider />
          <div class="col-lg-6 col-sm-12">
            <Col sm={24} md={22} className="SignInForm">
              <div className="Recovery-Body">
                <h1 style={{ fontWeight: "bold" }}>
                  Setup Two Factor Authentication
                </h1>

                <Alert
                  style={{ margin: "16px 0px" }}
                  message="Please save these codes, it’ll help you to recover your account in case you loose your device."
                  type="warning"
                  showIcon
                />
                <p style={{ color: "rgb(61 58 58 / 80%)" }}>
                  Treat your recovery codes with the same level of attention as
                  you would your password! We recommend saving them with a
                  password manager.
                </p>

                <div
                  id="recovery-code"
                  style={{ display: "flex", marginBottom: "16px" }}
                >
                  <section style={{ marginRight: "28px" }}>
                    {recoveryCodes &&
                      recoveryCodes.length > 0 &&
                      recoveryCodes?.map((item, idx) => {
                        if (idx < recoveryCodes?.length / 2)
                          return (
                            <p
                              key={item?.code}
                              style={
                                !item?.used
                                  ? { marginBottom: "8px" }
                                  : {
                                      marginBottom: "8px",
                                      textDecoration: "line-through",
                                    }
                              }
                            >
                              {item?.code}
                            </p>
                          );
                      })}
                  </section>
                  <section>
                    {recoveryCodes &&
                      recoveryCodes.length > 0 &&
                      recoveryCodes?.map((item, idx) => {
                        if (idx >= recoveryCodes?.length / 2)
                          return (
                            <p
                              key={item?.code}
                              style={
                                !item?.used
                                  ? { marginBottom: "8px" }
                                  : {
                                      marginBottom: "8px",
                                      textDecoration: "line-through",
                                    }
                              }
                            >
                              {item?.code}
                            </p>
                          );
                      })}
                  </section>
                </div>

                <div style={{ display: "flex" }}>
                  <Button
                    style={{ width: "115px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(
                        [...recoveryCodes.map((item) => item?.code)].join(", ")
                      );
                      message.success({
                        content: "Recovery codes copied successfully.",
                        style: {
                          marginLeft: "23px",
                          marginTop: "80vh",
                        },
                      });
                    }}
                  >
                    Copy Codes
                  </Button>
                  <Button
                    style={{ width: "115px", marginLeft: "10px" }}
                    onClick={(e) => {
                      e.preventDefault();

                      var mywindow = window.open(
                        "",
                        "new div",
                        "height=400,width=600"
                      );
                      mywindow.document.write("<html><head><title></title>");
                      mywindow.document.write(
                        '<link rel="stylesheet" href="style.css" type="text/css"/>'
                      );
                      mywindow.document.write("</head><body >");
                      mywindow.document.write(
                        document.getElementById("recovery-code").innerHTML
                      );
                      mywindow.document.write("</body></html>");
                      mywindow.print();
                      // window.print();
                    }}
                  >
                    Print Codes
                  </Button>
                  <Button
                    // href="/"
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/sign-in");
                    }}
                    style={{ marginLeft: "auto" }}
                  >
                    Finish Setup
                  </Button>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  const { recoveryCodes, authCodeStatus, authVerification } = state.signInState;

  return {
    recoveryCodes,
    authCodeStatus,
    authVerification,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryCodes);
