const gridSampleObject = {
  id: 181691,
  application_number: 4179149,
  applied_for: "Sample Marina",
  associated_image: "https://example.com/image1.png",
  associated_class: [9],
  last_week_threats: 1802,
  constant_last_week_threats: 1802,
  proprietor: [
    {
      id: 5182199,
      name: "Sample Proprietor",
      address: "123 Sample Address, Sample City, Sample Country",
    },
  ],
  date_of_usage: "Sample Proposed",
  date_of_application: "Sample Date",
  tags: [
    {
      id: 3808,
      name: "Sample Tag",
      color_code: "#0000a0",
    },
  ],
  tm_protect_mark_id: 5517,
  term: "Sample Term",
  focus_classes: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 99,
  ],
  last_week_threats: 19,
  image_text: "Sample Max",
  focus_labels: ["child", "man", "umbrella", "woman"],
};

const gridThreatSampleObject = {
  id: 199329,
  threat: {
    id: 5416613,
    application_number: 1118519,
    applied_for: "SWAD",
    associated_image:
      "https://miketm-staging.s3.amazonaws.com/media/TrademarkImages/1118519.png",
    associated_class: [30],
    proprietor: [
      {
        id: 4740715,
        name: "Vimal Agro Products Pvt. Ltd.",
        address: "G. I. D. C. TEN ROAD, BARDOLI - 394 601, GUJARAT.",
      },
    ],
    date_of_usage: "18-11-1988",
    date_of_application: "12-07-2002",
    description:
      "[CLASS : 30] coffee,tea,cocoa,sugar,rice,topioca,sago,artificial coffee,flour and preparation made from cereals,bread,pastry and confectionery ices,honey,treacle,yeast,baking powder,salt,mistard,vinegar,sauces,spices,ice,papad,",
  },
  similarity: {
    score: 100,
    algorithm: "LABEL_COMPARISON",
    image_labels: ["cartoon", "man", "face", "cap"],
    focus_word: "man",
  },
  created_custom_report: true,
  is_shortlist: false,
};

const gridSampleTemplateVariableObject = {
  current_date: "21-05-2024",
  journal_date: "20-11-2023",
  journal_number: 2131,
  last_date_of_filing_opposition: "19-03-2024",
  tags: [
    {
      color_code: "#0000a0",
      name: "Tag_1398",
    },
    {
      color_code: "#0000a0",
      name: "Tag_1399",
    },
    {
      color_code: "#0000a0",
      name: "Tag_1394",
    },
    {
      color_code: "#0000a0",
      name: "Tag_1391",
    },
    {
      color_code: "#0000a0",
      name: "Tag_1390",
    },
    {
      color_code: "#0000a0",
      name: "Tag_1397",
    },
    {
      color_code: "#0000a0",
      name: "Tag_1395",
    },
    {
      color_code: "#0000a0",
      name: "Tag_1393",
    },
    {
      color_code: "#0000a0",
      name: "Tag_1392",
    },
    {
      color_code: "#0000a0",
      name: "Tag_1396",
    },
  ],
  threat_application_number: 5979907,
  threat_attorney_address:
    "1061, B/13, Ward 7 Mehrauli, New Delhi- 110030, India",
  threat_attorney_name: "MARK SHIELD",
  threat_date_of_application: "15-06-2023",
  threat_date_of_usage: "Proposed",
  threat_formatted_proprietor_addresses: [
    "Pura Veeru Rai",
    "Keyari Tola",
    "Astupura",
    "Mau",
    "Uttar Pradesh – 275101",
    "India",
  ],
  threat_g_and_s_description: "[CLASS : 25] Clothing, footwear, headwear.",
  threat_proprietor_addresses:
    "Pura Veeru Rai, Keyari Tola, Astupura, Mau, Uttar Pradesh – 275101, India",
  threat_proprietor_names: "Dilnawaz",
  threat_proprietor_trading_as: "None",
  threat_trademark_classes: "25",
  threat_trademark_images: [],
  threat_trademark_name: "HAREEM COLLECTION",
  your_application_number: 3766419,
  your_attorney_address:
    "Dhumal Nivas, Behind Kamla Nehru Hospital, Near Barane School, 104, Mangalwar peth, Pune - 411 011",
  your_attorney_name: "LAW PROTECTOR",
  your_date_of_application: "28-02-2018",
  your_date_of_usage: "09-04-2014",
  your_formatted_proprietor_addresses: [
    "5011/B",
    "Sadar Bazar",
    "Satara – 415 001",
  ],
  your_g_and_s_description:
    "[CLASS : 41] FILM & DRAMA PRODUCTION, TELEVISION PRODUCTION & ENTERTAINMENT BY FILM",
  your_proprietor_addresses: "5011/B, Sadar Bazar, Satara – 415 001",
  your_proprietor_names: "Mr. Girdhar Karashan Odedara",
  your_proprietor_trading_as: "Shree Production",
  your_trademark_classes: "41",
  your_trademark_images: [
    "https://miketm-staging.s3.amazonaws.com/media/TrademarkImages/3766419.png",
  ],
  your_trademark_name: "SHREE PRODUCTION",
};

export {
  gridSampleObject,
  gridThreatSampleObject,
  gridSampleTemplateVariableObject,
};
