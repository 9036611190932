import React from "react";
import { Tag, Tooltip } from "antd";
const { CheckableTag } = Tag;

const CheckableTagComponent = ({
  value = false,
  setValue,
  label = "Click Me",
  activeTooltip = "",
  disableTooltip = "",
  id = "chekable-tag",
}) => {
  return (
    <React.Fragment>
      <Tooltip title={value ? activeTooltip : disableTooltip}>
        <CheckableTag
          id={id ? id : "checkable-tag"}
          // id="chekable-tag"
          style={{
            border: "1px solid",
            height: "30px",
            padding: "5px",
            fontWeight: "600",
            minWidth: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          checked={value}
          onChange={setValue}
          disabled={!value}
        >
          {label}
        </CheckableTag>
      </Tooltip>
    </React.Fragment>
  );
};

export default CheckableTagComponent;
