import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import { debounce } from "lodash";
import { DownOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  Input,
  Select,
  notification,
  Pagination,
  Menu,
  Slider,
  Button,
  Dropdown,
  Checkbox,
  Badge,
  Spin,
  Alert,
  Empty,
  Tooltip,
  message,
  Col,
  Row,
} from "antd";

//--------WATCH ACTIONS--------
import {
  getDateRangeList,
  postTMProtectCustomReport,
} from "../../../../redux/actions/watchActions/commonActions";

//--------GRID LAYOUT ACTIONS--------
import {
  GL_getTMProtect,
  GL_getTMProtectTrademarks,
} from "../../../../redux/actions/watchActions/gridLayoutActions";
import { logOut } from "../../../../redux/actions/commonActions/actions";

//--------WATCH TYPES--------
import { GL_POST_TM_PROTECT_CUSTOM_REPORT } from "../../../../redux/actions/watchActions/types";

//--------IP-SUITE CONSTANTS--------
import {
  ERROR,
  SUCCESS,
  LOADING,
  PROGRESSING,
} from "../../../../constants/commonConstants/generalConstants";

//--------WATCH GENERAL CONSTANTS--------
import { GRID_LAYOUT } from "../../../../constants/watchConstants/generalConstants";

//--------GRID LAYOUT CONSTANTS--------
import {
  GL_defaultCustomReportData,
  GL_defaultSecondaryFiltersSearchTerms,
  GL_defaultSecondaryFiltersVisibility,
  GL_defaultSecondarySelectedFilters,
  GL_defaultInitialReport,
  GL_defaultSecondarySelectedFilters_BEK,
  GL_defaultSearchSelectedFilters,
} from "../../../../constants/watchConstants/gridLayoutConstants";

//--------IP-SUITE GENERAL FUNCTIONS--------
import {
  changeStateValue_L0,
  changeStateValue_L1,
  changeStateValue_L2,
  compareArrays,
} from "../../../../functions/commonFunctions/generalFunctions";

//--------IP-SUITE DATE FUNCTIONS--------
import DateRangePicker from "../../../../functions/commonFunctions/dateRangePicker";

//--------WATCH SUB-COMPONENTS DRAWERS--------
import CustomReport from "../../subComponents/Drawers/CustomReport";

//--------WATCH SUB-COMPONENTS--------
import ThreatsGrid from "../../subComponents/ThreatsGrid";

//--------STYLESHEETS--------
import "../../../../stylesheets/watchStylesheets/gridLayout.css";

const { Search } = Input;
const { Option } = Select;

class GridLayoutTMProtect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandRecord: false,
      searchPrimaryInput: this.props.lastAppliedFilters,
      primarySelectedFilters: {},
      secondaryFiltersVisibility: GL_defaultSecondaryFiltersVisibility,
      secondarySelectedFilters: GL_defaultSecondarySelectedFilters,
      secondaryFiltersSearchTerms: GL_defaultSecondaryFiltersSearchTerms,
      drawersVisibility: {
        journalPage: false,
        customReport: false,
      },
      profile_id: null,
      range1: null,
      range2: null,
      customReportData: GL_defaultCustomReportData,
    };

    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L1_Debounced = debounce(
      this.changeStateValue_L1,
      400,
    );
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.setDate1 = this.setDate1.bind(this);
    this.setDate2 = this.setDate2.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.current_date_range === null) {
        await this.props.getDateRangeList("tm_protect", true);
      }

      if (
        this.props.current_date_range !== null &&
        this.props.current_date_range !== this.props.dateRange
      ) {
        this.props.GL_getTMProtect({
          profileReportID: this.props.current_profile_report_id,
          dateRange: this.props.current_date_range,
          pageNumber: 1,
          searchTerm: "",
          filters: GL_defaultSecondarySelectedFilters,
        });
      }
    } else {
      this.props.logOut();
    }
  }

  handleDateChange(dateRange, option) {
    switch (this.props.userTrademarks.trademarksDataStatus) {
      case SUCCESS:
      case ERROR:
      case PROGRESSING:
        this.props.GL_getTMProtect(
          {
            profileReportID: option.props.id,
            dateRange: dateRange,
            pageNumber: 1,
            filters: GL_defaultSecondarySelectedFilters,
            searchTerm: "",
          },
          true,
        );
        this.setState({
          profile_id: option.props.id,
          expandRecord: false,
          primarySelectedFilters: {},
          secondarySelectedFilters: GL_defaultSecondarySelectedFilters,
          secondaryFiltersSearchTerms: GL_defaultSecondaryFiltersSearchTerms,
          customReportData: GL_defaultCustomReportData,
        });
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      default:
        notification["error"]({
          message: "Unable to Change Journal! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handlePageChange(pageNumber) {
    switch (this.props.userTrademarks.trademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        this.props.GL_getTMProtect({
          profileReportID: this.state.profile_id,
          dateRange: this.props.dateRange,
          pageNumber,
          filters: this.props?.lastAppliedFilters,
        });
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        notification["error"]({
          message: "Unexpected Error! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleApplyPrimaryFilters() {
    switch (this.props.userTrademarks.trademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        if (
          this.state?.searchPrimaryInput?.searchTerm !==
          this.props?.lastAppliedFilters?.searchTerm
        ) {
          this.props.GL_getTMProtect({
            profileReportID: this.props.profileReportID,
            dateRange: this.props.dateRange,
            pageNumber: 1,
            filters: this.state?.searchPrimaryInput,
          });
        } else {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  handleResetPrimaryFilters() {
    switch (this.props.userTrademarks.trademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        if (this.props?.lastAppliedFilters?.searchTerm !== "") {
          this.props.GL_getTMProtect({
            profileReportID: this.props.profileReportID,
            dateRange: this.props.dateRange,
            pageNumber: 1,
            filters: GL_defaultSearchSelectedFilters,
          });
          this.setState({
            searchPrimaryInput: GL_defaultSearchSelectedFilters,
          });
        } else {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  async handleExpandRecord(expandRecord) {
    if (this.props.gridState.gridTrademarksDataStatus === LOADING) {
      notification["warning"]({
        message: "Request Under Process",
        description:
          "A request is under process. Kindly, wait for its completion.",
      });
    } else {
      if (expandRecord === false) {
        this.setState({ expandRecord: false });
      } else {
        if (expandRecord.threat_count > 0) {
          if (!this.state.customReportData.data[expandRecord.id]) {
            this.setState({
              secondarySelectedFilters: GL_defaultSecondarySelectedFilters,
            });
            this.changeStateValue_L2(
              "customReportData",
              "data",
              `${expandRecord.id}`,
              {
                ...GL_defaultInitialReport,
                filteredThreatsCount: expandRecord.threat_count,
              },
            );
            this.props.GL_getTMProtectTrademarks(
              this.props.profileReportID,
              expandRecord.id,
              1,
              false,
              GL_defaultSecondarySelectedFilters,
            );
          } else {
            let response = await this.props.GL_getTMProtectTrademarks(
              this.props.profileReportID,
              expandRecord.id,
              1,
              this.state.customReportData.data[`${expandRecord.id}`]
                .filter_flag,
              {
                trademarkTypes:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .threat_types,
                trademarkClasses:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .threat_classes,
                trademarkCategory:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .threat_categories,
                trademarkOffices:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .threat_offices,
                proprietors:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .threat_proprietors,
                infringementTypes:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .infringement_types,
                threatsSimilarity:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .threat_similarity,
                date_of_application:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .date_of_application,
                date_of_usage:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .date_of_usage,
                date_of_usage_proposed:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .date_of_usage_proposed,
                searchTerm:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .contains_term,
                secondarySearchType:
                  this.state.customReportData.data[`${expandRecord.id}`].filters
                    .secondarySearchType,
              },
            );
            if (response.status === SUCCESS) {
              this.setState({
                secondarySelectedFilters: {
                  trademarkTypes:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.threat_types,
                  trademarkClasses:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.threat_classes,
                  trademarkCategory:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.threat_categories,
                  trademarkOffices:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.threat_offices,
                  proprietors:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.threat_proprietors,
                  infringementTypes:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.infringement_types,
                  threatsSimilarity:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.threat_similarity,
                  date_of_application:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.date_of_application,
                  date_of_usage:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.date_of_usage,
                  date_of_usage_proposed:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.date_of_usage_proposed,
                  searchTerm:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.contains_term,
                  secondarySearchType:
                    this.state.customReportData.data[`${expandRecord.id}`]
                      .filters.secondarySearchType,
                },
              });
            }
          }
          this.setState({ expandRecord: expandRecord.id });
        } else {
          notification["warning"]({
            message: "0 Threats",
            description: "There are no threats against this trademark.",
          });
        }
      }
    }
  }

  handleThreatsSimilarityChange(value) {
    this.setState({
      secondarySelectedFilters: {
        ...this.state.secondarySelectedFilters,
        threatsSimilarity: {
          lowerLimit: value[0],
          upperLimit: value[1],
        },
      },
    });
  }

  handleThreatsSimilarityAfterChange(value) {
    this.setState({
      secondarySelectedFilters: {
        ...this.state.secondarySelectedFilters,
        threatsSimilarity: {
          lowerLimit: value[0],
          upperLimit: value[1],
        },
      },
    });
  }

  handleThreatsSimilarityInputChange(limitKey, event) {
    if (Number.isInteger(parseInt(event.target.value))) {
      if (event.target.value.includes("-")) {
        notification["warning"]({
          message: "Lowest Limit is 0",
          description: "Similarity cannot be less than 0.",
        });
        return;
      }

      if (parseInt(event.target.value) > 100) {
        notification["warning"]({
          message: "Highest Limit is 100",
          description: "Similarity cannot be more than 100.",
        });
        return;
      }

      this.setState({
        secondarySelectedFilters: {
          ...this.state.secondarySelectedFilters,
          threatsSimilarity: {
            ...this.state.secondarySelectedFilters?.threatsSimilarity,
            [limitKey]:
              event.target.value.length === 0
                ? null
                : parseInt(event.target.value),
          },
        },
      });
      return;
    }

    if (event.target.value.length === 0) {
      switch (limitKey) {
        case "lowerLimit":
          notification["warning"]({
            message: "Lower Limit's default value is 0",
            description:
              "If no value is given then the lower limit will be considered as 0.",
          });
          break;

        case "upperLimit":
          notification["warning"]({
            message: "Upper Limit's default value is 100",
            description:
              "If no value is given then the upper limit will be considered as 100.",
          });
          break;

        default:
          break;
      }

      this.setState({
        secondarySelectedFilters: {
          ...this.state.secondarySelectedFilters,
          threatsSimilarity: {
            ...this.state.secondarySelectedFilters?.threatsSimilarity,
            [limitKey]: null,
          },
        },
      });
      return;
    }
  }

  async handleApplySecondaryFilters() {
    switch (this.props.gridState.gridTrademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        const {
          trademarkTypes,
          trademarkClasses,
          trademarkCategory,
          trademarkOffices,
          proprietors,
          infringementTypes,
          threatsSimilarity,
          date_of_application,
          date_of_usage,
          date_of_usage_proposed,
          redBand,
          excludeClass,
          searchTerm,
          threat_desc_contains_term,
        } = this.props.gridState.gridLastAppliedFilters;

        let filterFlag = false;

        let { secondarySelectedFilters } = this.state;

        secondarySelectedFilters = {
          ...secondarySelectedFilters,
          // searchTerm: searchInput,
        };

        if (
          !compareArrays(
            secondarySelectedFilters?.trademarkTypes,
            GL_defaultSecondarySelectedFilters.trademarkTypes,
          ) ||
          !compareArrays(
            secondarySelectedFilters?.trademarkClasses,
            GL_defaultSecondarySelectedFilters.trademarkClasses,
          ) ||
          !compareArrays(
            secondarySelectedFilters?.trademarkCategory,
            GL_defaultSecondarySelectedFilters.trademarkCategory,
          ) ||
          !compareArrays(
            secondarySelectedFilters?.trademarkOffices,
            GL_defaultSecondarySelectedFilters.trademarkOffices,
          ) ||
          !compareArrays(
            secondarySelectedFilters?.proprietors,
            GL_defaultSecondarySelectedFilters.proprietors,
          ) ||
          !compareArrays(
            secondarySelectedFilters?.infringementTypes,
            GL_defaultSecondarySelectedFilters.infringementTypes,
          ) ||
          secondarySelectedFilters?.threatsSimilarity.lowerLimit !==
            GL_defaultSecondarySelectedFilters.threatsSimilarity.lowerLimit ||
          secondarySelectedFilters?.threatsSimilarity.upperLimit !==
            GL_defaultSecondarySelectedFilters.threatsSimilarity.upperLimit ||
          secondarySelectedFilters?.date_of_application !==
            GL_defaultSecondarySelectedFilters.date_of_application ||
          secondarySelectedFilters?.date_of_usage !==
            GL_defaultSecondarySelectedFilters.date_of_usage ||
          secondarySelectedFilters?.date_of_usage_proposed !==
            GL_defaultSecondarySelectedFilters.date_of_usage_proposed ||
          secondarySelectedFilters?.redBand !==
            GL_defaultSecondarySelectedFilters.redBand ||
          secondarySelectedFilters?.excludeClass !==
            GL_defaultSecondarySelectedFilters.excludeClass ||
          secondarySelectedFilters?.searchTerm !==
            GL_defaultSecondarySelectedFilters.searchTerm ||
          secondarySelectedFilters?.threat_desc_contains_term !==
            GL_defaultSecondarySelectedFilters.threat_desc_contains_term
        ) {
          filterFlag = true;
        } else {
          filterFlag = false;
        }

        if (
          !compareArrays(
            trademarkTypes,
            secondarySelectedFilters?.trademarkTypes,
          ) ||
          !compareArrays(
            trademarkClasses,
            secondarySelectedFilters?.trademarkClasses,
          ) ||
          !compareArrays(
            trademarkCategory,
            secondarySelectedFilters?.trademarkCategory,
          ) ||
          !compareArrays(
            trademarkOffices,
            secondarySelectedFilters?.trademarkOffices,
          ) ||
          !compareArrays(proprietors, secondarySelectedFilters?.proprietors) ||
          !compareArrays(
            infringementTypes,
            secondarySelectedFilters?.infringementTypes,
          ) ||
          threatsSimilarity.lowerLimit !==
            secondarySelectedFilters?.threatsSimilarity.lowerLimit ||
          threatsSimilarity.upperLimit !==
            secondarySelectedFilters?.threatsSimilarity.upperLimit ||
          date_of_application !==
            secondarySelectedFilters?.date_of_application ||
          date_of_usage !== secondarySelectedFilters?.date_of_usage ||
          date_of_usage_proposed !==
            secondarySelectedFilters?.date_of_usage_proposed ||
          redBand !== secondarySelectedFilters?.redBand ||
          excludeClass !== secondarySelectedFilters?.excludeClass ||
          searchTerm !== secondarySelectedFilters?.searchTerm ||
          threat_desc_contains_term !==
            secondarySelectedFilters?.threat_desc_contains_term
        ) {
          let response = await this.props.GL_getTMProtectTrademarks(
            this.props.profileReportID,
            this.state.expandRecord,
            1,
            filterFlag,
            secondarySelectedFilters,
          );

          if (response?.status === SUCCESS) {
            this.setState({
              customReportData: {
                ...this.state.customReportData,
                threatsCount: this.state.customReportData.data[
                  this.state.expandRecord
                ].select_all
                  ? this.state.customReportData.threatsCount -
                    this.state.customReportData.data[this.state.expandRecord]
                      .filteredThreatsCount +
                    response.filteredThreatsCount
                  : this.state.customReportData.threatsCount,
                data: {
                  ...this.state.customReportData.data,
                  [this.state.expandRecord]: {
                    ...this.state.customReportData.data[
                      this.state.expandRecord
                    ],
                    filter_flag: filterFlag,
                    filters: {
                      threat_types: secondarySelectedFilters?.trademarkTypes,
                      threat_classes:
                        secondarySelectedFilters?.trademarkClasses,
                      threat_categories:
                        secondarySelectedFilters?.trademarkCategory,
                      threat_offices:
                        secondarySelectedFilters?.trademarkOffices,
                      threat_proprietors: secondarySelectedFilters?.proprietors,
                      infringement_types:
                        secondarySelectedFilters?.infringementTypes,
                      threat_similarity: {
                        lowerLimit: `${secondarySelectedFilters?.threatsSimilarity.lowerLimit}`,
                        upperLimit: `${secondarySelectedFilters?.threatsSimilarity.upperLimit}`,
                      },
                      date_of_application:
                        secondarySelectedFilters?.date_of_application,
                      date_of_usage: secondarySelectedFilters?.date_of_usage,
                      date_of_usage_proposed:
                        secondarySelectedFilters?.date_of_usage_proposed,
                      red_band: secondarySelectedFilters?.redBand,
                      exclude_same_class:
                        secondarySelectedFilters?.excludeClass,
                      contains_term:
                        secondarySelectedFilters?.secondarySearchType ===
                        "Application No/Name"
                          ? secondarySelectedFilters?.searchTerm
                          : "",
                      threat_desc_contains_term:
                        secondarySelectedFilters?.secondarySearchType ===
                        "Your Threat Description Search"
                          ? secondarySelectedFilters?.threat_desc_contains_term
                          : "",
                      secondarySearchType:
                        secondarySelectedFilters?.secondarySearchType,
                    },
                    filteredThreatsCount: response.filteredThreatsCount,
                  },
                },
              },
            });
          }
        } else {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  setDate1(val) {
    this.setState({ range1: val });
  }

  setDate2(val) {
    this.setState({ range2: val });
  }

  async handleResetSecondaryFilters() {
    this.setState({ range1: null, range2: null });
    switch (this.props.gridState.gridTrademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        const {
          trademarkTypes,
          trademarkClasses,
          trademarkCategory,
          trademarkOffices,
          proprietors,
          infringementTypes,
          threatsSimilarity,
          date_of_application,
          date_of_usage,
          date_of_usage_proposed,
          redBand,
          excludeClass,
          searchTerm,
          threat_desc_contains_term,
        } = this.props.gridState.gridLastAppliedFilters;

        if (
          compareArrays(
            trademarkTypes,
            GL_defaultSecondarySelectedFilters.trademarkTypes,
          ) &&
          compareArrays(
            trademarkClasses,
            GL_defaultSecondarySelectedFilters.trademarkClasses,
          ) &&
          compareArrays(
            trademarkCategory,
            GL_defaultSecondarySelectedFilters.trademarkCategory,
          ) &&
          compareArrays(
            trademarkOffices,
            GL_defaultSecondarySelectedFilters.trademarkOffices,
          ) &&
          compareArrays(
            proprietors,
            GL_defaultSecondarySelectedFilters.proprietors,
          ) &&
          compareArrays(
            infringementTypes,
            GL_defaultSecondarySelectedFilters.infringementTypes,
          ) &&
          threatsSimilarity.lowerLimit ===
            GL_defaultSecondarySelectedFilters.threatsSimilarity.lowerLimit &&
          threatsSimilarity.upperLimit ===
            GL_defaultSecondarySelectedFilters.threatsSimilarity.upperLimit &&
          date_of_application ===
            GL_defaultSecondarySelectedFilters.date_of_application &&
          date_of_usage === GL_defaultSecondarySelectedFilters.date_of_usage &&
          date_of_usage_proposed ===
            GL_defaultSecondarySelectedFilters.date_of_usage_proposed &&
          redBand === GL_defaultSecondarySelectedFilters.redBand &&
          excludeClass === GL_defaultSecondarySelectedFilters.excludeClass &&
          searchTerm === GL_defaultSecondarySelectedFilters.searchTerm &&
          threat_desc_contains_term ===
            GL_defaultSecondarySelectedFilters.threat_desc_contains_term
        ) {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        } else {
          let response = await this.props.GL_getTMProtectTrademarks(
            this.props.profileReportID,
            this.state.expandRecord,
            1,
            false,
            GL_defaultSecondarySelectedFilters,
          );

          if (response.status === SUCCESS) {
            this.setState({
              secondarySelectedFilters: GL_defaultSecondarySelectedFilters,
              secondaryFiltersSearchTerms:
                GL_defaultSecondaryFiltersSearchTerms,
              customReportData: {
                ...this.state.customReportData,
                threatsCount: this.state.customReportData.data[
                  this.state.expandRecord
                ].select_all
                  ? this.state.customReportData.threatsCount -
                    this.state.customReportData.data[this.state.expandRecord]
                      .filteredThreatsCount +
                    response.filteredThreatsCount
                  : this.state.customReportData.threatsCount,
                data: {
                  ...this.state.customReportData.data,
                  [this.state.expandRecord]: {
                    ...this.state.customReportData.data[
                      this.state.expandRecord
                    ],
                    filters: GL_defaultSecondarySelectedFilters_BEK,
                    filter_flag: false,
                    filteredThreatsCount: response.filteredThreatsCount,
                  },
                },
              },
            });
          }
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        notification["error"]({
          message: "Unable to Reset! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleGridPageChange(pageNumber) {
    switch (this.props.gridState.gridTrademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        this.props.GL_getTMProtectTrademarks(
          this.props.profileReportID,
          this.state.expandRecord,
          pageNumber,
          this.props.gridState.gridLastAppliedFilters.filterFlag,
          this.props.gridState.gridLastAppliedFilters,
        );
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        notification["error"]({
          message: "Unexpected Error! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleCreateReport() {
    switch (this.props.gridState.gridTrademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        if (
          this.state.customReportData?.select_all &&
          this.props.lastAppliedFilters?.searchTerm?.length === 0
        ) {
          message.info(
            "You can access this report from 'Automated Report' section in 'Reports'",
          );
        } else {
          this.changeStateValue_L1("drawersVisibility", "customReport", true);
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  async handleCreateReportConfirmation() {
    switch (this.props.gridState.gridTrademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        await this.props.postTMProtectCustomReport(
          {
            ...this.state.customReportData,
            cease_desist_report_id: this.props.profileReportID,
            source: GRID_LAYOUT,
            filter_flag:
              this.props?.lastAppliedFilters?.searchTerm?.length > 0
                ? true
                : false,
            filters: {
              contains_term: this.props?.lastAppliedFilters?.searchTerm.trim(),
            },
          },
          GL_POST_TM_PROTECT_CUSTOM_REPORT,
        );
        this.changeStateValue_L1("drawersVisibility", "customReport", false);
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  render() {
    const headerButton = {
      margin: "0px 5px 0px 0px",
      boxShadow: "none",
      border: "1px solid #1890ff",
      padding: "0px 5px",
      fontSize: "16px",
      color: "#1890ff",
    };

    const headerButtonSelected = { ...headerButton, color: "#4285f4" };
    const searchTypeMenu = (
      <>
        <Menu
          selectable
          selectedKeys={[
            this.state?.secondarySelectedFilters?.secondarySearchType,
          ]}
          onClick={({ key }) =>
            this.changeStateValue_L1(
              "secondarySelectedFilters",
              "secondarySearchType",
              key,
            )
          }
          style={{
            maxHeight: "200px",
            width: "250px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {["Application No/Name", "Your Threat Description Search"].map(
            (e) => (
              <Menu.Item key={e} style={{ fontSize: "16px" }}>
                {e}
              </Menu.Item>
            ),
          )}
        </Menu>
      </>
    );
    let noFilterData = (
      <Menu>
        <Menu.Item>No Filters Data Found</Menu.Item>
      </Menu>
    );
    let infringementTypesMenu = noFilterData;
    let trademarkClassesMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    let trademarkCategoryMenu = noFilterData;
    let trademarkOfficesMenu = noFilterData;
    let trademarkTypesMenu = noFilterData;
    let threatsSimilarityMenu = noFilterData;

    if (this.props.gridState.gridFilters !== null) {
      const {
        infringement_types,
        trademark_classes,
        proprietors,
        trademark_category,
        trademark_offices,
        trademark_types,
      } = this.props.gridState.gridFilters;

      if (infringement_types !== null && infringement_types.length > 0) {
        infringementTypesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={
              this.state.secondarySelectedFilters?.infringementTypes
            }
            onSelect={(event) =>
              this.changeStateValue_L1(
                "secondarySelectedFilters",
                "infringementTypes",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "secondarySelectedFilters",
                "infringementTypes",
                event.selectedKeys,
              )
            }
          >
            {infringement_types.map((eachType) => (
              <Menu.Item key={eachType}>{eachType.replace("_", " ")}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (trademark_classes !== null && trademark_classes.length > 0) {
        trademarkClassesMenu = (
          <>
            <Menu
              selectable
              multiple
              selectedKeys={
                this.state.secondarySelectedFilters?.trademarkClasses
              }
              onSelect={(event) =>
                this.changeStateValue_L1(
                  "secondarySelectedFilters",
                  "trademarkClasses",
                  event.selectedKeys,
                )
              }
              onDeselect={(event) =>
                this.changeStateValue_L1(
                  "secondarySelectedFilters",
                  "trademarkClasses",
                  event.selectedKeys,
                )
              }
              style={{ height: "200px", overflow: "auto" }}
            >
              {trademark_classes.map((eachClass) => (
                <Menu.Item key={eachClass.id}>{eachClass.number}</Menu.Item>
              ))}
            </Menu>
            <div style={{ padding: "10px" }}>
              <Checkbox
                disabled={this.state.secondarySelectedFilters?.excludeClass}
                style={{ margin: "0px 5px", fontWeight: "500" }}
                checked={this.state.secondarySelectedFilters?.redBand}
                onChange={(event) =>
                  this.changeStateValue_L1(
                    "secondarySelectedFilters",
                    "redBand",
                    event.target.checked,
                  )
                }
              >
                Same Class Infringements
              </Checkbox>
            </div>
            <div style={{ textAlign: "left", padding: "10px" }}>
              <Checkbox
                disabled={this.state.secondarySelectedFilters?.redBand}
                style={{ margin: "0px 5px", fontWeight: "500" }}
                checked={this.state.secondarySelectedFilters?.excludeClass}
                onChange={(event) =>
                  this.changeStateValue_L1(
                    "secondarySelectedFilters",
                    "excludeClass",
                    event.target.checked,
                  )
                }
              >
                Exclude Same Class Infringements
              </Checkbox>
            </div>
          </>
        );
      }

      if (proprietors !== null && proprietors.length > 0) {
        let filteredProprietors = proprietors.filter(
          (eachProprietor) =>
            eachProprietor.name
              .toLowerCase()
              .indexOf(
                `${this.state.secondaryFiltersSearchTerms.proprietors}`,
              ) !== -1,
        );
        proprietorsMenu = (
          <>
            <Input
              placeholder="Search proprietors"
              onChange={(event) =>
                this.changeStateValue_L1_Debounced(
                  "secondaryFiltersSearchTerms",
                  "proprietors",
                  event.target.value.toLowerCase(),
                )
              }
              allowClear
            />

            <Menu
              style={{
                position: "initial",
                height: "200px",
                overflowY: "scroll",
                width: "250px",
              }}
              selectable
              multiple
              selectedKeys={this.state.secondarySelectedFilters?.proprietors}
              onSelect={(event) =>
                this.changeStateValue_L1(
                  "secondarySelectedFilters",
                  "proprietors",
                  event.selectedKeys,
                )
              }
              onDeselect={(event) =>
                this.changeStateValue_L1(
                  "secondarySelectedFilters",
                  "proprietors",
                  event.selectedKeys,
                )
              }
            >
              {filteredProprietors.map((eachCategory) => (
                <Menu.Item key={eachCategory.id}>{eachCategory.name}</Menu.Item>
              ))}
            </Menu>
          </>
        );
      }

      if (trademark_category !== null && trademark_category.length > 0) {
        trademarkCategoryMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={
              this.state.secondarySelectedFilters?.trademarkCategory
            }
            onSelect={(event) =>
              this.changeStateValue_L1(
                "secondarySelectedFilters",
                "trademarkCategory",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "secondarySelectedFilters",
                "trademarkCategory",
                event.selectedKeys,
              )
            }
          >
            {trademark_category.map((eachCategory) => (
              <Menu.Item key={eachCategory.id}>{eachCategory.name}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (trademark_offices !== null && trademark_offices.length > 0) {
        trademarkOfficesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.secondarySelectedFilters?.trademarkOffices}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "secondarySelectedFilters",
                "trademarkOffices",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "secondarySelectedFilters",
                "trademarkOffices",
                event.selectedKeys,
              )
            }
          >
            {trademark_offices.map((eachOffice) => (
              <Menu.Item key={eachOffice.id}>{eachOffice.name}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (trademark_types !== null && trademark_types.length > 0) {
        trademarkTypesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.secondarySelectedFilters?.trademarkTypes}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "secondarySelectedFilters",
                "trademarkTypes",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "secondarySelectedFilters",
                "trademarkTypes",
                event.selectedKeys,
              )
            }
          >
            {trademark_types.map((eachType) => (
              <Menu.Item key={eachType.id}>{eachType.name}</Menu.Item>
            ))}
          </Menu>
        );
      }

      threatsSimilarityMenu = (
        <>
          <div className="Filters-Similarity-Menu">
            <span className="Filters-Similarity-Menu-Input">
              <Input
                type="number"
                min={0}
                max={100}
                placeholder="0"
                value={
                  this.state.secondarySelectedFilters?.threatsSimilarity
                    .lowerLimit
                }
                onChange={(event) =>
                  this.handleThreatsSimilarityInputChange("lowerLimit", event)
                }
              />
            </span>
            <Slider
              range
              defaultValue={[
                this.state.secondarySelectedFilters?.threatsSimilarity
                  .lowerLimit,
                this.state.secondarySelectedFilters?.threatsSimilarity
                  .upperLimit,
              ]}
              onAfterChange={(value) =>
                this.handleThreatsSimilarityAfterChange(value)
              }
              value={[
                this.state.secondarySelectedFilters?.threatsSimilarity
                  .lowerLimit,
                this.state.secondarySelectedFilters?.threatsSimilarity
                  .upperLimit,
              ]}
              onChange={(value) => this.handleThreatsSimilarityChange(value)}
              min={0}
              max={100}
              style={{ minWidth: "150px" }}
            />
            <span className="Filters-Similarity-Menu-Input">
              <Input
                type="number"
                min={0}
                max={100}
                placeholder="100"
                value={
                  this.state.secondarySelectedFilters?.threatsSimilarity
                    .upperLimit
                }
                onChange={(event) =>
                  this.handleThreatsSimilarityInputChange("upperLimit", event)
                }
              />
            </span>
          </div>
          {this.state.secondarySelectedFilters?.threatsSimilarity.upperLimit !==
            null &&
            this.state.secondarySelectedFilters?.threatsSimilarity.lowerLimit >
              this.state.secondarySelectedFilters?.threatsSimilarity
                .upperLimit && (
              <Alert
                className="trademark-alert-message"
                style={{ margin: "5px", textAlign: "center" }}
                message={"Lower Limit cannot exceed Upper Limit"}
                type="error"
              />
            )}
        </>
      );
    }

    return (
      <>
        <header className="General-Header">
          <Row align="middle" justify="space-between">
            <Col span={20}>
              <Menu
                mode="horizontal"
                className="filter-list"
                style={{ alignItems: "baseline" }}
              >
                <Menu.Item
                  style={{ backgroundColor: "white" }}
                  key="searchterm"
                  className="filter-list-item apply-filter"
                >
                  <Input
                    style={{ width: "15vw", minWidth: "150px" }}
                    placeholder="Search application name"
                    onChange={(event) =>
                      this.changeStateValue_L1(
                        "searchPrimaryInput",
                        "searchTerm",
                        event.target.value,
                      )
                    }
                    value={this.state?.searchPrimaryInput?.searchTerm}
                    allowClear
                  />
                </Menu.Item>
                <Menu.Item
                  // style={{  backgroundColor: "white" }}
                  key="apply"
                  className="filter-list-item apply-filter"
                >
                  <Button
                    style={{
                      marginLeft: "5px",
                      backgroundColor: "#4285f4",
                      color: "#ffffff",
                      fontWeight: "600",
                    }}
                    onClick={() => this.handleApplyPrimaryFilters()}
                  >
                    Apply
                  </Button>
                </Menu.Item>
                <Menu.Item
                  // style={{  backgroundColor: "white" }}
                  key="reset"
                  className="filter-list-item apply-filter"
                >
                  <Button
                    type="link"
                    onClick={() => this.handleResetPrimaryFilters()}
                  >
                    Reset
                  </Button>
                </Menu.Item>
                <Menu.Item
                  // style={{  backgroundColor: "white" }}
                  key="select-all"
                  className="filter-list-item apply-filter"
                >
                  <Checkbox
                    style={{ fontWeight: "100" }}
                    checked={this.state.customReportData.select_all}
                    disabled={
                      !this.props.userTrademarks.filteredTrademarksCount ||
                      this.props.userTrademarks.trademarksDataStatus ===
                        PROGRESSING ||
                      this.props.userTrademarks.trademarksDataStatus === LOADING
                        ? true
                        : false
                    }
                    onClick={(event) =>
                      this.changeStateValue_L1(
                        "customReportData",
                        "select_all",
                        event.target.checked,
                      )
                    }
                  >
                    Select All
                  </Checkbox>
                </Menu.Item>
                {(this.state.customReportData.threatsCount > 0 ||
                  this.state.customReportData.select_all) && (
                  <Menu.Item
                    key="create-report"
                    className="filter-list-item apply-filter"
                  >
                    <Badge
                      overflowCount={9999}
                      count={
                        this.state.customReportData.select_all
                          ? "All"
                          : this.state.customReportData.threatsCount
                      }
                      style={{ marginTop: "5px" }}
                    >
                      <Button onClick={() => this.handleCreateReport()}>
                        Create Report
                      </Button>
                    </Badge>
                    {!this.state.customReportData.select_all && (
                      <MinusCircleOutlined
                        style={{ marginLeft: "10px", fontSize: "14px" }}
                        onClick={() => {
                          if (this.state.expandRecord) {
                            this.setState({
                              customReportData: {
                                ...GL_defaultCustomReportData,
                                data: {
                                  [this.state.expandRecord]: {
                                    ...GL_defaultInitialReport,
                                    filter_flag:
                                      this.state.customReportData.data[
                                        this.state.expandRecord
                                      ].filter_flag,
                                    filters:
                                      this.state.customReportData.data[
                                        this.state.expandRecord
                                      ].filters,
                                    filteredThreatsCount:
                                      this.state.customReportData.data[
                                        this.state.expandRecord
                                      ].filteredThreatsCount,
                                  },
                                },
                              },
                            });
                          } else {
                            this.setState({
                              customReportData: GL_defaultCustomReportData,
                            });
                          }
                        }}
                      />
                    )}
                  </Menu.Item>
                )}
              </Menu>
            </Col>
            <Col span={4}>
              <Select
                style={{ float: "right", width: 240 }}
                defaultValue={this.props.current_date_range}
                value={
                  this.props.dateRange ? this.props.dateRange : "Date Range"
                }
                onChange={(dateRange, option) =>
                  this.handleDateChange(dateRange, option)
                }
                loading={!this.props.dateRange}
              >
                {this.props.dateRangeList &&
                  this.props.dateRangeList.map((eachRange) => (
                    <Option
                      id={eachRange.id}
                      key={eachRange.id}
                      value={eachRange.date_range}
                    >
                      {`${eachRange.date_range.split("to").join(" to ")}`}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
        </header>
        <div
          className="General-Content vertical_scroll"
          style={{
            height: `${
              this.props.watchAlert.length > 0
                ? "calc(100vh - 140px)"
                : "calc(100vh - 95px)"
            }`,
          }}
        >
          {/* When user trademarks are received successfully then this screen will appear.*/}
          {this.props.userTrademarks.trademarksDataStatus === SUCCESS &&
            this.props.userTrademarks.trademarksTotalCount > 0 &&
            this.props.userTrademarks.trademarksData.map((eachTrademark) => (
              <React.Fragment key={`${eachTrademark.id}`}>
                <div id={eachTrademark.id} className="Record-Row">
                  <div className="Record-Row-Cell" style={{ width: "20px" }}>
                    <Checkbox
                      disabled={this.state.customReportData.select_all}
                      checked={
                        this.state.customReportData.data[eachTrademark.id]
                          ? this.state.customReportData.data[eachTrademark.id]
                              .select_all ||
                            this.state.customReportData.select_all
                          : false || this.state.customReportData.select_all
                      }
                      onClick={(event) => {
                        if (
                          !this.state.customReportData.data[eachTrademark.id]
                        ) {
                          this.setState({
                            customReportData: {
                              ...this.state.customReportData,
                              threatsCount:
                                this.state.customReportData.threatsCount +
                                eachTrademark.threat_count,
                              selectAllCount:
                                this.state.customReportData.selectAllCount + 1,
                              data: {
                                ...this.state.customReportData.data,
                                [eachTrademark.id]: {
                                  ...this.state.customReportData.data[
                                    eachTrademark.id
                                  ],
                                  ...GL_defaultInitialReport,
                                  select_all: event.target.checked,
                                  filteredThreatsCount:
                                    eachTrademark.threat_count,
                                },
                              },
                            },
                          });
                        } else {
                          this.setState({
                            customReportData: {
                              ...this.state.customReportData,
                              threatsCount: event.target.checked
                                ? this.state.customReportData.threatsCount -
                                  this.state.customReportData.data[
                                    eachTrademark.id
                                  ].threatsCount +
                                  this.state.customReportData.data[
                                    eachTrademark.id
                                  ].filteredThreatsCount
                                : this.state.customReportData.threatsCount +
                                  this.state.customReportData.data[
                                    eachTrademark.id
                                  ].threatsCount -
                                  this.state.customReportData.data[
                                    eachTrademark.id
                                  ].filteredThreatsCount,
                              selectAllCount: event.target.checked
                                ? this.state.customReportData.selectAllCount + 1
                                : this.state.customReportData.selectAllCount -
                                  1,
                              data: {
                                ...this.state.customReportData.data,
                                [eachTrademark.id]: {
                                  ...this.state.customReportData.data[
                                    eachTrademark.id
                                  ],
                                  select_all: event.target.checked,
                                  filteredThreatsCount:
                                    this.state.customReportData.data[
                                      eachTrademark.id
                                    ].filteredThreatsCount,
                                },
                              },
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="Record-Row-Cell">
                    <Tooltip
                      placement={
                        eachTrademark.term && eachTrademark.term.length > 25
                          ? "topLeft"
                          : "top"
                      }
                      title={`Term:- ${
                        eachTrademark.term ? eachTrademark.term : "N/A"
                      }`}
                    >
                      {eachTrademark.term
                        ? eachTrademark.term.toUpperCase()
                        : "N/A"}
                    </Tooltip>
                  </div>
                  <div className="Record-Row-Cell" style={{ width: "200px" }}>
                    <Tooltip
                      placement={
                        eachTrademark.focus_classes &&
                        eachTrademark.focus_classes.length > 25
                          ? "topLeft"
                          : "top"
                      }
                      title={`Focus Classes:- ${`${eachTrademark.focus_classes}`}`}
                    >
                      {`${eachTrademark.focus_classes}`}
                    </Tooltip>
                  </div>
                  <div className="Record-Row-Cell">
                    <a
                      href={`#${eachTrademark.id}`}
                      onClick={() => {
                        this.handleExpandRecord(
                          this.state.expandRecord === eachTrademark.id
                            ? false
                            : eachTrademark,
                        );
                      }}
                    >
                      {`${eachTrademark.threat_count} Threats`}
                      <DownOutlined />
                    </a>
                  </div>
                  <div className="Record-Row-Cell" style={{ width: "50px" }}>
                    {this.state.customReportData.data[eachTrademark.id] &&
                      (this.state.customReportData.data[eachTrademark.id]
                        .select_all ||
                        this.state.customReportData.data[eachTrademark.id]
                          .threats.length > 0) && (
                        <>
                          <Badge
                            overflowCount={999}
                            count={
                              this.state.customReportData.data[eachTrademark.id]
                                .select_all
                                ? this.state.customReportData.data[
                                    eachTrademark.id
                                  ].filteredThreatsCount
                                : this.state.customReportData.data[
                                    eachTrademark.id
                                  ].threatsCount
                            }
                          />
                          {!this.state.customReportData.data[eachTrademark.id]
                            .select_all && (
                            <MinusCircleOutlined
                              style={{ marginLeft: "5px", fontSize: "14px" }}
                              onClick={() =>
                                this.setState({
                                  customReportData: {
                                    ...this.state.customReportData,
                                    threatsCount:
                                      this.state.customReportData.threatsCount -
                                      this.state.customReportData.data[
                                        eachTrademark.id
                                      ].threatsCount,
                                    data: {
                                      ...this.state.customReportData.data,
                                      [eachTrademark.id]: {
                                        ...this.state.customReportData.data[
                                          eachTrademark.id
                                        ],
                                        threats: [],
                                        threatsCount: 0,
                                      },
                                    },
                                  },
                                })
                              }
                            />
                          )}
                        </>
                      )}
                  </div>
                </div>
                {this.state.expandRecord === eachTrademark.id && (
                  <div
                    style={{
                      height: "calc(100% - 70px)",
                      overflow: "auto",
                      margin: "0px 5px",
                      borderRadius: "5px",
                      border: "1px solid #c9c9c9",
                    }}
                  >
                    <header className="General-Header Box-Shadow">
                      <Row justify="center">
                        <Col span={15} className="alignC">
                          <Dropdown
                            overlay={trademarkClassesMenu}
                            overlayClassName="Filters-Menu"
                            open={
                              this.state.secondaryFiltersVisibility
                                .trademarkClassesMenu
                            }
                            onVisibleChange={(event) =>
                              this.changeStateValue_L1(
                                "secondaryFiltersVisibility",
                                "trademarkClassesMenu",
                                event,
                              )
                            }
                          >
                            <Button
                              className={`${
                                this.state.secondarySelectedFilters
                                  ?.trademarkClasses.length > 0 ||
                                this.state.secondarySelectedFilters?.redBand ||
                                this.state.secondarySelectedFilters
                                  ?.excludeClass
                                  ? "Filters-Button-Selected"
                                  : "Filters-Button-Empty"
                              }`}
                            >
                              Classes
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Dropdown
                            overlay={proprietorsMenu}
                            overlayClassName="Filters-Menu"
                            open={
                              this.state.secondaryFiltersVisibility
                                .proprietorsMenu
                            }
                            onVisibleChange={(event) =>
                              this.changeStateValue_L1(
                                "secondaryFiltersVisibility",
                                "proprietorsMenu",
                                event,
                              )
                            }
                          >
                            <Button
                              className={`${
                                this.state.secondarySelectedFilters?.proprietors
                                  .length > 0
                                  ? "Filters-Button-Selected"
                                  : "Filters-Button-Empty"
                              }`}
                            >
                              Proprietors
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Dropdown
                            overlay={trademarkCategoryMenu}
                            overlayClassName="Filters-Menu"
                            open={
                              this.state.secondaryFiltersVisibility
                                .trademarkCategoryMenu
                            }
                            onVisibleChange={(event) =>
                              this.changeStateValue_L1(
                                "secondaryFiltersVisibility",
                                "trademarkCategoryMenu",
                                event,
                              )
                            }
                          >
                            <Button
                              className={`${
                                this.state.secondarySelectedFilters
                                  ?.trademarkCategory.length > 0
                                  ? "Filters-Button-Selected"
                                  : "Filters-Button-Empty"
                              }`}
                            >
                              Categories
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Dropdown
                            overlay={trademarkTypesMenu}
                            overlayClassName="Filters-Menu"
                            open={
                              this.state.secondaryFiltersVisibility
                                .trademarkTypesMenu
                            }
                            onVisibleChange={(event) =>
                              this.changeStateValue_L1(
                                "secondaryFiltersVisibility",
                                "trademarkTypesMenu",
                                event,
                              )
                            }
                          >
                            <Button
                              className={`${
                                this.state.secondarySelectedFilters
                                  ?.trademarkTypes.length > 0
                                  ? "Filters-Button-Selected"
                                  : "Filters-Button-Empty"
                              }`}
                            >
                              TM Types
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Dropdown
                            overlay={trademarkOfficesMenu}
                            overlayClassName="Filters-Menu"
                            open={
                              this.state.secondaryFiltersVisibility
                                .trademarkOfficesMenu
                            }
                            onVisibleChange={(event) =>
                              this.changeStateValue_L1(
                                "secondaryFiltersVisibility",
                                "trademarkOfficesMenu",
                                event,
                              )
                            }
                          >
                            <Button
                              className={`${
                                this.state.secondarySelectedFilters
                                  ?.trademarkOffices.length > 0
                                  ? "Filters-Button-Selected"
                                  : "Filters-Button-Empty"
                              }`}
                            >
                              Offices
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Dropdown
                            overlay={infringementTypesMenu}
                            overlayClassName="Filters-Menu-Scroll"
                            open={
                              this.state.secondaryFiltersVisibility
                                .infringementTypesMenu
                            }
                            onVisibleChange={(event) =>
                              this.changeStateValue_L1(
                                "secondaryFiltersVisibility",
                                "infringementTypesMenu",
                                event,
                              )
                            }
                          >
                            <Button
                              className={`${
                                this.state.secondarySelectedFilters
                                  ?.infringementTypes.length > 0
                                  ? "Filters-Button-Selected"
                                  : "Filters-Button-Empty"
                              }`}
                            >
                              Infringement Types
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Dropdown
                            overlay={
                              <DateRangePicker
                                type={"Date of Application"}
                                startValue={
                                  this.state.secondarySelectedFilters
                                    ?.date_of_application_startValue
                                }
                                endValue={
                                  this.state.secondarySelectedFilters
                                    ?.date_of_application_endValue
                                }
                                setDate={(val) => this.setDate1(val)}
                                range={this.state.range1}
                                handleDateString={(
                                  startValue,
                                  endValue,
                                  dateString,
                                ) =>
                                  this.setState({
                                    secondarySelectedFilters: {
                                      ...this.state.secondarySelectedFilters,
                                      date_of_application_startValue:
                                        startValue,
                                      date_of_application_endValue: endValue,
                                      date_of_application:
                                        startValue !== null || endValue !== null
                                          ? dateString
                                          : null,
                                    },
                                  })
                                }
                              />
                            }
                            overlayClassName="filter-menu"
                          >
                            <Button
                              className={`${
                                this.state.secondarySelectedFilters
                                  ?.date_of_application.length > 0
                                  ? "Filters-Button-Selected"
                                  : "Filters-Button-Empty"
                              }`}
                            >
                              DoA
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Dropdown
                            overlay={
                              <>
                                <DateRangePicker
                                  type={"Date of Usage"}
                                  startValue={
                                    this.state.secondarySelectedFilters
                                      ?.date_of_usage_startValue
                                  }
                                  endValue={
                                    this.state.secondarySelectedFilters
                                      ?.date_of_usage_endValue
                                  }
                                  setDate={(val) => this.setDate2(val)}
                                  range={this.state.range2}
                                  handleDateString={(
                                    startValue,
                                    endValue,
                                    dateString,
                                  ) =>
                                    this.setState({
                                      secondarySelectedFilters: {
                                        ...this.state.secondarySelectedFilters,
                                        date_of_usage_startValue: startValue,
                                        date_of_usage_endValue: endValue,
                                        date_of_usage:
                                          startValue !== null ||
                                          endValue !== null
                                            ? dateString
                                            : null,
                                      },
                                    })
                                  }
                                />
                                <div
                                  style={{
                                    textAlign: "center",
                                    lineHeight: "32px",
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      this.state.secondarySelectedFilters
                                        ?.date_of_usage_proposed
                                    }
                                    onChange={(event) =>
                                      this.changeStateValue_L1(
                                        "secondarySelectedFilters",
                                        "date_of_usage_proposed",
                                        event.target.checked,
                                      )
                                    }
                                  >
                                    Proposed
                                  </Checkbox>
                                </div>
                              </>
                            }
                            overlayClassName="filter-menu"
                          >
                            <Button
                              className={`${
                                this.state.secondarySelectedFilters
                                  ?.date_of_usage.length > 0 ||
                                this.state.secondarySelectedFilters
                                  ?.date_of_usage_proposed
                                  ? "Filters-Button-Selected"
                                  : "Filters-Button-Empty"
                              }`}
                            >
                              DoU
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Dropdown
                            overlay={threatsSimilarityMenu}
                            overlayClassName="Filters-Menu"
                            open={
                              this.state.secondaryFiltersVisibility
                                .threatsSimilarityMenu
                            }
                            onVisibleChange={(event) =>
                              this.changeStateValue_L1(
                                "secondaryFiltersVisibility",
                                "threatsSimilarityMenu",
                                event,
                              )
                            }
                          >
                            <Button
                              className={`${
                                (this.state.secondarySelectedFilters
                                  ?.threatsSimilarity.lowerLimit > 0 ||
                                  this.state.secondarySelectedFilters
                                    ?.threatsSimilarity.upperLimit < 100) &&
                                this.state.secondarySelectedFilters
                                  ?.threatsSimilarity.upperLimit !== null
                                  ? "Filters-Button-Selected"
                                  : "Filters-Button-Empty"
                              }`}
                            >
                              Similarity
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col span={14} className="m-top-10 alignC">
                          <Menu className="filter-list" mode="horizontal">
                            <Menu.Item
                              key="searchDropdown"
                              className="filter-list-item apply-filter"
                            >
                              <Dropdown overlay={searchTypeMenu}>
                                <Button style={headerButtonSelected}>
                                  {
                                    this.state?.secondarySelectedFilters
                                      ?.secondarySearchType
                                  }
                                  <DownOutlined style={{ fontSize: "12px" }} />
                                </Button>
                              </Dropdown>
                            </Menu.Item>
                            {this.state?.secondarySelectedFilters
                              ?.secondarySearchType ===
                              "Application No/Name" && (
                              <Menu.Item
                                style={{ backgroundColor: "white" }}
                                key="searchterm"
                                className="filter-list-item apply-filter"
                              >
                                <Input
                                  placeholder="Application No/Name"
                                  onChange={(event) =>
                                    this.changeStateValue_L1(
                                      "secondarySelectedFilters",
                                      "searchTerm",
                                      event.target.value,
                                    )
                                  }
                                  value={
                                    this.state?.secondarySelectedFilters
                                      ?.searchTerm
                                  }
                                  allowClear
                                  style={{ width: 250 }}
                                />
                              </Menu.Item>
                            )}
                            {this.state?.secondarySelectedFilters
                              ?.secondarySearchType ===
                              "Your Threat Description Search" && (
                              <Menu.Item
                                style={{ backgroundColor: "white" }}
                                key="mark"
                                className="filter-list-item apply-filter"
                              >
                                <Input
                                  placeholder="Your Threat Description Search"
                                  onChange={(event) =>
                                    this.changeStateValue_L1(
                                      "secondarySelectedFilters",
                                      "threat_desc_contains_term",
                                      event.target.value,
                                    )
                                  }
                                  value={
                                    this.state?.secondarySelectedFilters
                                      ?.threat_desc_contains_term
                                  }
                                  allowClear
                                  style={{ width: 250 }}
                                />
                              </Menu.Item>
                            )}
                            <Menu.Item
                              key="apply"
                              className="filter-list-item apply-filter"
                            >
                              <Button
                                style={{
                                  backgroundColor: "#4285f4",
                                  color: "#ffffff",
                                  fontWeight: "600",
                                }}
                                onClick={() =>
                                  this.handleApplySecondaryFilters()
                                }
                              >
                                Apply
                              </Button>
                            </Menu.Item>
                            <Menu.Item
                              key="reset"
                              className="filter-list-item apply-filter"
                            >
                              <Button
                                type="link"
                                onClick={() =>
                                  this.handleResetSecondaryFilters()
                                }
                              >
                                Reset
                              </Button>
                            </Menu.Item>
                          </Menu>
                        </Col>
                      </Row>
                    </header>

                    {/* This will show the grid of trademarks/threats. */}
                    <ThreatsGrid
                      gridTrademarksData={
                        this.props.gridState.gridTrademarksData
                          ? this.props.gridState.gridTrademarksData
                          : []
                      }
                      gridTrademarksDataStatus={
                        this.props.gridState.gridTrademarksDataStatus
                      }
                      userTrademarkClasses={eachTrademark.focus_classes}
                      changeStateValue_L0={this.changeStateValue_L0}
                      changeStateValue_L1={this.changeStateValue_L1}
                      expandRecord={this.state.expandRecord}
                      customReportData={this.state.customReportData}
                      tmProtect
                      algorithm
                    />
                    <footer className="General-Footer Box-Shadow">
                      <Pagination
                        showQuickJumper
                        defaultCurrent={1}
                        current={this.props.gridState.pageNumber}
                        total={this.props.gridState.gridFilteredTrademarksCount}
                        showTotal={(total, range) =>
                          `${range[0]}-${range[1]} of ${total} `
                        }
                        pageSize={100}
                        showSizeChanger={false}
                        onChange={(pageNumber) =>
                          this.handleGridPageChange(pageNumber)
                        }
                      />
                    </footer>
                  </div>
                )}
              </React.Fragment>
            ))}

          {/* When user trademarks are received successfully but it is 0, just like you LOL. */}
          {this.props.userTrademarks.trademarksDataStatus === SUCCESS &&
            this.props.userTrademarks.trademarksTotalCount === 0 && (
              <div style={{ display: "table", height: "50vh", width: "100%" }}>
                <Empty
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                />
              </div>
            )}

          {/* This loader will show when Grid Layout is Loading or you can say when trademarksDataStatus is equal to LOADING*/}
          {this.props.userTrademarks.trademarksDataStatus === LOADING && (
            <div
              style={{
                display: "table",
                height: "50vh",
                width: "100%",
                verticalAlign: "middle",
              }}
            >
              <Spin size="large" style={{ display: "table-cell" }} />
            </div>
          )}

          {/* If there is an occurrence of an error while fetching the data this will appear */}
          {this.props.userTrademarks.trademarksDataStatus === ERROR && (
            <div style={{ display: "table", height: "50vh", width: "100%" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <h1>{this.props.userTrademarks.trademarksDataMessage}</h1>
              </div>
            </div>
          )}

          {/* In case, if the report has not yet been generated this will appear */}
          {this.props.userTrademarks.trademarksDataStatus === PROGRESSING && (
            <div style={{ display: "table", height: "50vh", width: "100%" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <h3>
                  Your marks are being analyzed for{" "}
                  {
                    <Select
                      style={{ fontSize: "20px", width: 200 }}
                      defaultValue={this.props.current_date_range}
                      value={
                        this.props.dateRange
                          ? this.props.dateRange
                          : "Date Range"
                      }
                      onChange={(dateRange, option) =>
                        this.handleDateChange(dateRange, option)
                      }
                      loading={!this.props.dateRange}
                    >
                      {this.props.dateRangeList &&
                        this.props.dateRangeList.map((eachRange) => (
                          <Option
                            id={eachRange.id}
                            key={eachRange.id}
                            value={eachRange.date_range}
                          >
                            {eachRange.date_range.split("to").join(" to ")}
                          </Option>
                        ))}
                    </Select>
                  }{" "}
                  Date Range.
                </h3>
                <h3>
                  Meanwhile, you can access reports of previous Date Range.
                </h3>
                <h3>You can also change it from the top right corner.</h3>
              </div>
            </div>
          )}

          {/* When user trademarks are received successfully but it is 0, just like you LOL. */}
          {this.props.userTrademarks.trademarksDataStatus === SUCCESS &&
            this.props.userTrademarks.filteredTrademarksCount === 0 && (
              <div style={{ display: "table", height: "50vh", width: "100%" }}>
                <Empty
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                />
              </div>
            )}
        </div>
        <footer className="General-Footer">
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            current={this.props.userTrademarks.pageNumber}
            total={this.props.userTrademarks.filteredTrademarksCount}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showSizeChanger={false}
            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
          />
        </footer>

        {/* When creat report is clicked, this drawer will be opened */}
        <CustomReport
          visible={this.state.drawersVisibility.customReport}
          customReportData={this.state.customReportData}
          customReportStatus={this.props.customReportStatus}
          changeStateValue_L1={this.changeStateValue_L1}
          handleCreateReportConfirmation={() =>
            this.handleCreateReportConfirmation()
          }
          closeDrawer={() =>
            this.changeStateValue_L1("drawersVisibility", "customReport", false)
          }
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  getDateRangeList,
  GL_getTMProtect,
  GL_getTMProtectTrademarks,
  postTMProtectCustomReport,
  logOut,
};

function mapStateToProps(state) {
  return deepFreeze({
    dateRangeList: state.watchState.dateRange.date_range_list,
    current_date_range: state.watchState.currentDateRange.current_date_range,
    current_profile_report_id:
      state.watchState.currentDateRange.current_profile_report_id,

    dateRange: state.watchState.gridLayout.tm_protect.dateRange,
    profileReportID: state.watchState.gridLayout.tm_protect.profileReportID,
    customReportStatus: state.watchState.gridLayout.customReportStatus,
    userTrademarks: state.watchState.gridLayout.tm_protect.userTrademarks,
    gridState: state.watchState.gridLayout.tm_protect.gridState,
    lastAppliedFilters:
      state.watchState.gridLayout.tm_protect.lastAppliedFilters,
    watchAlert: state.alertMessages.watch,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GridLayoutTMProtect);
