//--------LIBRARIES--------
import React from "react";
import { Modal, Button } from "antd";

export default function InfringementTypeFilterInfo({ visible, onClose }) {
  return (
    <Modal
      title={<h2>Infringement Types</h2>}
      open={visible}
      onOk={onClose}
      footer={
        <Button type="primary" onClick={onClose}>
          OK
        </Button>
      }
      maskClosable={true}
      onCancel={onClose}
    >
      <div style={{ fontSize: "16px" }}>
        <strong>Prefix -</strong> Use this filter to see all the marks with your
        mark as prefix. So, if your mark is ‘Legal’ and there is a potentially
        similar mark ‘LegalExpo’ published in this Journal then this will be
        shown here.
        <br />
        <br />
        <strong>Suffix -</strong> Use this filter to see all the marks with your
        mark as suffix. So, if your mark is ‘Legal’ and there is a potentially
        similar mark ‘MikeLegal’ published in this Journal then this will be
        shown here.
        <br />
        <br />
        <strong>Contains Full -</strong> Use this filter to see all the marks
        which contains your mark. So, if your mark is ‘Legal’ and there is a
        similar mark ‘Your Legal Buddy’ published in this Journal then this will
        be shown here.
        <br />
        <br />
        <strong>Contains Partial -</strong> Use this filter to see all the marks
        which contains an element or sub-string of your mark. So, if your mark
        is ‘Legal’ and there is a similar mark ‘LegaLogix’ published in this
        Journal then this will be shown here.
        <br />
        <br />
        <strong>Mike Pro -</strong> Use this filter to see all the marks
        identified by the complex algorithms created by MikeLegal to help you
        identify marks similar to yours.
        <br />
        <br />
        <strong>Identical -</strong> Use this filter to see 100% identical
        marks. Not to be confused with contains full or contains partial. If
        your mark is ‘Legal’ then it is not identical to ‘The Legal’ as it has
        an extra ‘The’ in it.
      </div>
    </Modal>
  );
}
