import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Row,
  Col,
  Divider,
  Tooltip,
  Checkbox,
  notification,
  Image,
} from "antd";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import {
  FlagFilled,
  FilterFilled,
  EyeFilled,
  StarFilled,
} from "@ant-design/icons";
import SimilarityFilter from "../../../watchFilter/newCommonComponent/SimilarityFilter";
import SelectFilter from "../../../watchFilter/newCommonComponent/SelectFilter";
import DateFilter from "../../../watchFilter/newCommonComponent/DateFilter";
import ProprietorFilterComponent from "../../../watchFilter/newCommonComponent/ProprietorFilterComponent";

import { getEmailTemplateData } from "../../../../../redux/actions/commonActions/actions";
import { dateFormat } from "../../../../../functions/commonFunctions/dateFunctions";
import { ROOT_URL } from "../../../../../configs/rootConfigs";

const ThreatList = (props) => {
  const { threatData } = props;
  const {
    markDetails,
    infringement,
    setInfringement,
    excludeReported,
    setExcludeReported,
    shortlisted,
    setShortlisted,
    showDescription,
    bookmark,
    setBookmark,
    threatAttorneyValue,
    setThreatAttorneyValue,
    threatProprietors,
    setThreatProprietors,
    threatTMClass,
    setThreatClass,
    similarity,
    setSimilarity,
    threatDateOfApplication,
    setThreatDateOfApplication,
    threatDateOfUsage,
    setThreatDateOfUsage,
    threatDateOfUsageProposed,
    setThreatDateOfUsageProposed,
    excludeThreatProprietors,
    setExcludeThreatProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    publishedDate,
    setPublishedDate,
    excludeShortlisted,
    setExcludeShortlisted,
    threatFilterApplied,
    setThreatFilterApplied,
    andOrThreatClass,
    setAndOrThreatClass,
    generateFilterObject,
    isAnyFilterSelected,
    threatLabel,
    setThreatLabel,
    andOrThreatLabel,
    setAndOrThreatLabel,
    getRealSearchParameters,
    showSelectIcons,
    journal,
    setMailObj,
  } = props;

  const dispatch = useDispatch();
  let urlKey = window.location.pathname.split("/")[2] || "";
  let dashboardLayout = window.location.pathname.split("/")[3] || "";

  const { currentJournalDate } =
    useSelector(
      (state) =>
        state.watchState.watchFilters[dashboardLayout][urlKey].config
          ?.journalConfig,
    ) || [];

  const getPrimaryFilterPath = () => {
    // for grid layout only...

    let templatePath = "applied/list_layout";

    if (urlKey === "tm-applied" || urlKey === "tm_applied") {
      templatePath = "applied/list_layout";
    } else if (urlKey === "tm-protect" || urlKey === "tm_protect") {
      templatePath = "tm_protect/list_layout";
    } else if (
      urlKey === "image-text" ||
      urlKey === "image_text" ||
      urlKey === "image_text_new"
    ) {
      templatePath = "image_text/list_layout";
    } else if (urlKey === "image_label") {
      templatePath = "image_label/list_layout";
    } else {
      templatePath = "applied/list_layout";
    }
    return templatePath;
  };

  let filterPath = getPrimaryFilterPath();

  const handleEmail = async (data) => {
    let journalNumber = journal;
    let mailContent = "<p><strong>Trademark Watch</strong></p>";

    let applicationNumber = checkValue(data.threat)
      ? data.threat?.application_number
      : "";

    let mark_type = checkValue(markDetails) ? markDetails?.term : "";

    let journalDate = currentJournalDate;

    let FilingDate = new Date(journalDate);
    let d = FilingDate.getDate();
    FilingDate.setMonth(FilingDate.getMonth() + 4);
    if (FilingDate.getDate() !== d) {
      FilingDate.setDate(0);
    }

    let tempDate = new Date();
    let currDate =
      tempDate.getFullYear() +
      "-" +
      (tempDate.getMonth() + 1) +
      "-" +
      tempDate.getDate() +
      " " +
      tempDate.getHours() +
      ":" +
      tempDate.getMinutes() +
      ":" +
      tempDate.getSeconds();
    let currSubscription = "DASHBOARD";

    if (urlKey === "image_label") {
      currSubscription = "IMAGE_RECOGNITION";
    } else if (urlKey === "tm_applied") {
      currSubscription = "JOURNAL WATCH";
    } else if (urlKey === "image_text" || urlKey === "image_text_new") {
      currSubscription = "IMAGE_RECOGNITION";
    } else if (urlKey === "tm_protect") {
      currSubscription = "TM PROTECT";
    }
    setMailObj({
      visible: true,
      mailContent: mailContent,
      product: "WATCH",
      subscription: currSubscription,
      report_tag: {
        report_name: "",
        journal_number: journalNumber,
        journal_date: dateFormat(journalDate, "-"),
        journal_copy: `${ROOT_URL}/watch/api/journal_threat_pdf/?journal_number=${journalNumber}&application_number=${applicationNumber}`,
        opposition: dateFormat(FilingDate, "-"),
        your_mark: checkValue(markDetails) ? markDetails?.applied_for : "",
        your_application: checkValue(markDetails)
          ? markDetails?.application_number
          : "",
        your_proprietor: checkValue(markDetails.proprietor)
          ? markDetails?.proprietor?.map((propData) => propData.name).join(", ")
          : "",
        your_DOA: checkValue(markDetails)
          ? markDetails?.date_of_application
          : "",
        your_DOU: checkValue(markDetails) ? markDetails?.date_of_usage : "",
        your_class: checkValue(markDetails)
          ? markDetails?.associated_class?.join(", ")
          : "",
        your_mark_image: checkValue(markDetails)
          ? markDetails?.associated_image
          : "",
        threat_mark: checkValue(data.threat) ? data?.threat?.applied_for : "",
        threat_application: checkValue(data.threat)
          ? data?.threat?.application_number
          : "",
        threat_proprietor: checkValue(data.threat)
          ? data?.threat?.proprietor
              ?.map((propData) => propData.name)
              .join(", ")
          : "",
        threat_address: checkValue(data.threat)
          ? data?.threat?.proprietor
              ?.map((propData) => propData.address)
              .join(", ")
          : "",
        threat_DOA: checkValue(data.threat)
          ? data?.threat?.date_of_application
          : "",
        threat_DOU: checkValue(data.threat) ? data?.threat?.date_of_usage : "",
        threat_class: checkValue(data.threat)
          ? data?.threat?.associated_class?.join(", ")
          : "",
        threat_mark_image: checkValue(data.threat)
          ? data.threat?.associated_image
          : "",
        current_date: checkValue(currDate) ? dateFormat(currDate, "-") : "",
      },
      journal_copy: `${ROOT_URL}/watch/api/journal_threat_pdf/?journal_number=${journalNumber}&application_number=${applicationNumber}`,
      objectType: "trademark",
      // applicationNumber: checkValue(markDetails)
      //   ? markDetails.application_number
      //   : "",
      applicationNumber:
        urlKey === "tm_protect"
          ? checkValue(data.mark)
            ? data.mark?.term
            : ""
          : checkValue(data.mark)
            ? data.mark?.application_number
            : "",
      mark_type,
    });

    if (urlKey === "image_label") {
      await dispatch(
        getEmailTemplateData({
          subscription: "IMAGE_RECOGNITION",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
          image_recognition_type: "IMAGE_LABEL_RECOGNITION_TYPE",
        }),
      );
    } else if (urlKey === "tm_applied") {
      await dispatch(
        getEmailTemplateData({
          subscription: "Journal Watch",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
        }),
      );
    } else if (urlKey === "image_text" || urlKey === "image_text_new") {
      await dispatch(
        getEmailTemplateData({
          subscription: "IMAGE_RECOGNITION",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
          image_recognition_type: "IMAGE_TEXT_RECOGNITION_TYPE",
        }),
      );
    } else if (urlKey === "tm_protect") {
      await dispatch(
        getEmailTemplateData({
          subscription: "TM Protect",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
        }),
      );
    }
  };

  const { threatproprietorConfig, threatClassConfig } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const { current_template_id } = useSelector(
    (state) => state.watchState.watchTemplate,
  );

  const { pageNumber } = useSelector(
    (state) =>
      state.watchState.gridLayoutData[dashboardLayout][urlKey].config.threats,
  );

  const extraClassChildren = (
    <>
      <div style={{ textAlign: "left", padding: "5px" }}>
        <Checkbox
          disabled={excludeSameClassInfringement}
          checked={sameClassInfringement}
          onChange={(event) => setSameClassInfringement(event.target.checked)}
        >
          Same Class Infringements
        </Checkbox>
      </div>
      <div style={{ textAlign: "left", padding: "5px" }}>
        <Checkbox
          disabled={sameClassInfringement}
          checked={excludeSameClassInfringement}
          onChange={(event) =>
            setExcludeSameClassInfringement(event.target.checked)
          }
        >
          Exclude Same Class Infringements
        </Checkbox>
      </div>
    </>
  );

  const sameClassIcon = (threat) =>
    markDetails.associated_class?.filter((element) =>
      threat.associated_class.includes(element),
    ).length > 0 && (
      <span
        style={{
          padding: "4px 4px 4px 4px",
          color: "white",
          background: "red",
          borderRadius: "4px",
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        <Tooltip title={"Same Class"}>
          <FlagFilled />
        </Tooltip>
      </span>
    );

  const getColumnFilter = (dataIndex) => ({
    filterIcon: () => {
      let filtered = false;
      filtered =
        (dataIndex === "similarity" &&
          (similarity?.from > 0 || similarity?.to < 100)) ||
        (dataIndex === "threat_doa" &&
          (threatDateOfApplication?.dateString !== "" ||
            threatDateOfUsage?.dateString !== "" ||
            threatDateOfUsageProposed)) ||
        (dataIndex === "threat_classes" &&
          (threatTMClass?.length ||
            sameClassInfringement ||
            excludeSameClassInfringement)) ||
        (dataIndex === "threat_proprietor" &&
          (threatProprietors?.length || excludeThreatProprietors?.length));
      return (
        <FilterFilled
          style={{
            color: filtered ? "#1677ff" : "black",
            marginRight: dataIndex === "threat_proprietor" ? "4px" : "",
          }}
        />
      );
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => (
      <>
        {dataIndex === "similarity" && visible && (
          <SimilarityFilter
            key={"similarity"}
            value={similarity}
            setValue={setSimilarity}
            tableView
          />
        )}
        {dataIndex === "threat_proprietor" && visible && (
          <ProprietorFilterComponent
            filteredData={threatproprietorConfig}
            key={threatproprietorConfig.key}
            value={threatProprietors}
            setValue={setThreatProprietors}
            loading={threatproprietorConfig.loading}
            journal_number={journal}
            exclude={excludeThreatProprietors}
            setExclude={setExcludeThreatProprietors}
            tableView
            current_template_id={current_template_id}
            filterPath={filterPath}
          />
        )}
        {dataIndex === "threat_classes" && visible && (
          <SelectFilter
            filteredData={threatClassConfig}
            key={threatClassConfig.key}
            value={threatTMClass}
            setValue={setThreatClass}
            loading={threatClassConfig.loading}
            journal_number={journal}
            extraChildren={urlKey !== "tm_protect" && extraClassChildren}
            tableView
            showSwitch
            andOr={andOrThreatClass}
            setAndOr={setAndOrThreatClass}
            current_template_id={current_template_id}
            filterPath={filterPath}
          />
        )}
        {dataIndex === "threat_doa" && visible && (
          <DateFilter
            menu_label="Threat's TM DoA|DoU"
            subtitle1="Threat's TM Date of Application"
            subtitle2="Threat's TM Date of Usage"
            key={"threat_date"}
            value1={threatDateOfApplication}
            setValue1={setThreatDateOfApplication}
            value2={threatDateOfUsage}
            setValue2={setThreatDateOfUsage}
            proposed={threatDateOfUsageProposed}
            setProposed={setThreatDateOfUsageProposed}
            tableView
          />
        )}
      </>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const onSelectChange = (record, selected) => {
    const threatSelected =
      props?.selectedData?.data[props.markDetails.application_number]
        ?.threats || [];
    if (selected) {
      props.setSelectedData({
        ...props.selectedData,
        data: {
          ...props.selectedData.data,
          [props.markDetails.application_number]: {
            ...props.selectedData.data[props.markDetails.application_number],
            threats: [...threatSelected, record?.id],
            select_all: false,
          },
        },
        threatsCount: props.selectedData.threatsCount + 1,
      });
    } else {
      props.setSelectedData({
        ...props.selectedData,
        data: {
          ...props.selectedData.data,
          [props.markDetails.application_number]: {
            ...props.selectedData.data[props.markDetails.application_number],
            threats: threatSelected.filter((item) => item !== record.id),
            select_all: false,
          },
        },
        threatsCount: props.selectedData.threatsCount - 1,
      });
    }
  };

  const rowSelection = {
    renderCell: function (checked, record, index, originNode) {
      return (
        <div
          className="tmapplied_selection_cols"
          id="threatlist_selection_cols"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "4px" }}>
              {" "}
              {index + 1 + (pageNumber - 1) * 100}. {/* {index + 1}.{" "} */}
            </div>
            <div> {originNode}</div>
          </div>
          {(record?.created_custom_report || showSelectIcons) && (
            <Tooltip title="Custom Report Created">
              <EyeFilled
                style={{
                  margin: "8px 8px 8px 28px",
                  color: "#1890FF",
                  fontSize: "18px",
                }}
              />
            </Tooltip>
          )}
          {(record?.is_shortlist || showSelectIcons) && (
            <Tooltip title="Shortlisted">
              <StarFilled
                style={{
                  margin: "8px 8px 8px 28px",
                  color: "#FFA701",
                  fontSize: "18px",
                }}
              />
            </Tooltip>
          )}
        </div>
      );
    },
    selectedRowKeys:
      props.selectedData.data[props.markDetails.application_number]?.threats,
    onSelect: onSelectChange,
    selections: null,
    onSelectAll: (selected, selectedRows) => {
      props.setSelectedData({
        ...props.selectedData,
        data: {
          ...props.selectedData?.data,
          [props.markDetails.application_number]: {
            ...props.selectedData.data[props.markDetails.application_number],
            select_all: true,
          },
        },
        threatsCount:
          props.selectedData.threatsCount +
          props.markDetails.last_week_threats -
          (props.selectedData.data[props.markDetails.application_number]
            ?.threats?.length || 0),
      });
      if (selected) {
        notification.open({
          message: "Deselect All Mark",
          description:
            "Click on the arrow near the select all checkbox to deselect all marks",
        });
      }
    },
    selections: props?.selectedData?.data[props.markDetails.application_number]
      ?.select_all
      ? [
          {
            key: "all-data",
            text: "Deselect All Data",
            onSelect: (changeableRowKeys) => {
              props.setSelectedData({
                ...props.selectedData,
                data: {
                  ...props.selectedData?.data,
                  [props.markDetails.application_number]: {
                    ...props.selectedData?.data[
                      props.markDetails.application_number
                    ],
                    select_all: false,
                  },
                },
                threatsCount:
                  props.selectedData?.threatsCount >= 0
                    ? props.selectedData.threatsCount -
                      props.markDetails.last_week_threats +
                      (props.selectedData?.data[
                        props.markDetails.application_number
                      ]?.threats?.length || 0)
                    : 0,
              });
            },
          },
        ]
      : false,
    getCheckboxProps: (record) => ({
      disabled:
        props?.selectedData?.data[props.markDetails.application_number]
          ?.select_all || props?.selectedData?.select_all,
    }),
  };

  const columns = [
    {
      title: "Application No.",
      dataIndex: "threat",
      key: "application_number",
      render: (item) => (
        <p
          className={urlKey !== "tm_protect" ? "application-text" : ""}
          style={{ margin: "0px 8px" }}
          onClick={() =>
            urlKey !== "tm_protect" &&
            props?.setJournalPageVisible(item?.application_number)
          }
        >
          <span>
            {checkValue(item?.application_number)
              ? item?.application_number
              : "N.A."}{" "}
          </span>
        </p>
      ),
    },
    {
      title: "Image",
      dataIndex: "threat",
      key: "associated_image",
      render: (item) =>
        checkValue(item?.associated_image) &&
        item?.associated_image !== null &&
        item?.associated_image !== "N.A." && (
          <p>
            <Image
              width={100}
              src={
                checkValue(item?.associated_image) ? item?.associated_image : ""
              }
            />
          </p>
        ),
    },
    {
      title: "Applied for",
      dataIndex: "threat",
      key: "applied_for",
      render: (item) => checkValue(item?.applied_for) && item.applied_for,
    },
    {
      title: "Proprietor",
      dataIndex: "threat",
      key: "proprietor",
      ...getColumnFilter("threat_proprietor"),
      render: (item) =>
        item?.proprietor &&
        item?.proprietor.length &&
        item.proprietor.map((singleProprietor) => (
          <ul>
            <li key={singleProprietor.id}>{singleProprietor.name}</li>
          </ul>
        )),
    },
    {
      title: "Class",
      dataIndex: "threat",
      key: "associated_class",
      ...getColumnFilter("threat_classes"),
      width: 100,
      render: (item) =>
        checkValue(item?.associated_class) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <>{sameClassIcon(item)}</>
            {item?.associated_class.map((eachClass, index, allClasses) =>
              allClasses.length - 1 === index ? (
                <>{eachClass}</>
              ) : (
                <>{eachClass}, </>
              ),
            )}
          </div>
        ),
    },
    {
      title: "DoA | DoU",
      dataIndex: "threat",
      key: "date",
      // ...getColumnFilter("threat_doa"),
      render: (item) => (
        <>
          <li>
            DoA:{" "}
            {checkValue(item?.date_of_application) && item.date_of_application}
          </li>
          <li>DoU: {checkValue(item?.date_of_usage) && item.date_of_usage}</li>
        </>
      ),
    },
    ...(urlKey === "image_text" ||
    urlKey === "image_label" ||
    urlKey === "image_text_new"
      ? [
          {
            title: urlKey === "image_label" ? "Threat Labels" : "Image Text",
            dataIndex: "similarity",
            key: "focus_word",
            children: [
              {
                title: "Similarity",
                dataIndex: "similarity",
                key: "similarity",
                render: (item) => (
                  <>
                    <Tooltip
                      title={
                        (item?.comparison === "IMAGE_FW_TO_APPLIED_FOR_FW" ||
                          item?.comparison === "IMAGE_FW_TO_IMAGE_FW") &&
                        item?.is_custom
                          ? `Custom Focus Text: ${item?.focus_word}`
                          : `${urlKey === "image_label" ? "Focus Label" : "Focus Text"} : ${
                              checkValue(item?.focus_word)
                                ? item.focus_word
                                : ""
                            }`
                      }
                    >
                      {urlKey === "image_label"
                        ? checkValue(item?.image_labels?.length) &&
                          item?.image_labels.join(", ")
                        : checkValue(item?.image_text) && item?.image_text}
                    </Tooltip>
                    <Divider />
                    {checkValue(item?.score) && item.score}%
                  </>
                ),
              },
            ],
          },
        ]
      : []),
    ...(urlKey === "tm_applied" || urlKey === "tm_protect"
      ? [
          {
            title: "Focus Word",
            dataIndex: "similarity",
            key: "focus_word",
            children: [
              {
                title: "Similarity",
                dataIndex: "similarity",
                key: "similarity",
                render: (item) => (
                  <>
                    {checkValue(item?.focus_word) && item?.focus_word}
                    <Divider />
                    {checkValue(item?.score) && item.score}%
                  </>
                ),
              },
            ],
          },
        ]
      : []),
    {
      title: "Actions",
      key: "actions",
      render: (item) => {
        return (
          <div
            className="iconTable tmapplied_ll_actions"
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
              padding: "8px",
            }}
          >
            <Tooltip placement="top" title={"Send email"}>
              <p
                className="alignC"
                id="threat-table-sendmail"
                onClick={() => handleEmail(item)}
              >
                <i
                  className="fa fa-paper-plane"
                  style={{
                    padding: "0px 5px",
                    color: "#1890ff",
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                  aria-hidden="true"
                ></i>
              </p>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const table = (
    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ padding: "16px" }}>
        <Table
          rowKey={(record) => record.id}
          expandedRowKeys={showDescription ? threatData?.map((x) => x.id) : []}
          columns={columns}
          className="tm-grid-table"
          bordered
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  <div style={{ width: "30%" }}>
                    {record?.threat?.description}
                  </div>
                </div>
              );
            },
            showExpandColumn: false,
            expandIcon: undefined,
            rowExpandable: (record) => true,
          }}
          dataSource={threatData}
          scroll={
            { y: "calc(80vh - 360px)" }
            //   watchAlert === ""
            //     ? { y: "calc(80vh - 150px)" }
            //     : { y: "calc(80vh - 180px)" }
          }
          // loading={status === LOADING ? true : false}
          rowSelection={rowSelection}
          pagination={false}
        />
      </Col>
    </Row>
  );

  return <>{table}</>;
};

export default ThreatList;
