import React from "react";
import {
  Dropdown,
  Button,
  Space,
  Select,
  Switch,
  Divider,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import {
  getWatchAllFilters,
  changeListSelectedDropdownValues,
  changeListDropdownSearchValues,
  changeFilterAndOr,
} from "../../../../redux/actions/watchActions/watchActions";
import { default_filter_page, default_filter_page_size } from "../utils/utils";
import { getFilters } from "../../../../redux/actions/watchActions/brandCompareActions";

const { Text } = Typography;

const SelectFilter = ({
  filteredData,
  extraChildren,
  value = [],
  setValue,
  loading = false,
  journal_number = "",
  tableView = false,
  showSwitch = false,
  andOr = false,
  setAndOr,
  current_template_id = 0,
  extraHighlight = false,
  filterPath = "",
  application_number = 0,
  rename = "",
  shouldUpdateStore = true,
  searchTerm = "",
  handleSearchTerm,
  threatFlag = false,
}) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (tableView) {
      setOpenFilter(true);
    }
  }, []);

  const handleChange = (newValue, newOption) => {
    setValue(newValue);
    const tempObject = newOption.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    shouldUpdateStore &&
      dispatch(changeListSelectedDropdownValues(filteredData.key, tempObject));
  };

  const handleSwitch = (event) => {
    setAndOr?.(event);
    shouldUpdateStore && dispatch(changeFilterAndOr(filteredData.key, event));
  };

  const handleSearchDebounced = React.useCallback(
    debounce((newValue) => {
      if (filteredData.key === "brand_classes") {
        dispatch(
          getFilters(
            threatFlag,
            filteredData.key,
            newValue,
            default_filter_page,
            default_filter_page_size,
          ),
        );
      } else {
        journal_number &&
          dispatch(
            getWatchAllFilters(
              journal_number,
              filteredData.key,
              newValue,
              default_filter_page,
              default_filter_page_size,
              current_template_id,
              filterPath,
              application_number,
            ),
          );
      }
    }, 500),
    [journal_number, current_template_id],
  );

  const handleSearch = (newValue) => {
    if (filteredData.key === "brand_classes") {
      handleSearchTerm(newValue);
    } else {
      dispatch(changeListDropdownSearchValues(filteredData.key, newValue));
    }
    handleSearchDebounced(newValue);
  };

  const placeholder = `Search ${rename ? rename : filteredData.menu_label}`;

  const tagRender = (props) => {
    return null;
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (filteredData.data.length === filteredData.metadata.count) {
      return;
    }
    if (
      !loading &&
      scrollTop + clientHeight >= scrollHeight - 20 &&
      filteredData.data.length < filteredData.metadata.count
    ) {
      if (filteredData.key === "brand_classes") {
        dispatch(
          getFilters(
            threatFlag,
            filteredData.key,
            filteredData.searchValue,
            filteredData.metadata.page + 1,
            default_filter_page_size,
          ),
        );
      } else {
        journal_number &&
          dispatch(
            getWatchAllFilters(
              journal_number,
              filteredData.key,
              filteredData.searchValue,
              filteredData.metadata.page + 1,
              default_filter_page_size,
              current_template_id,
              filterPath,
              application_number,
            ),
          );
      }
    }
  };

  const selectMenu = () => (
    <Select
      mode="multiple"
      style={{
        minWidth: "320px",
      }}
      dropdownStyle={{
        minWidth: "320px",
      }}
      id={`watch_filter_id_${filteredData.key}`}
      onPopupScroll={handleScroll}
      loading={loading}
      placeholder={placeholder}
      open={openFilter}
      value={value}
      options={filteredData.data}
      onChange={handleChange}
      showSearch
      searchValue={
        filteredData.key === "brand_classes"
          ? searchTerm
          : filteredData.searchValue
      }
      onSearch={handleSearch}
      className="select-filter-dropdown"
      optionFilterProp="children"
      filterOption={(input, option) =>
        String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      tagRender={tagRender}
      suffixIcon={
        showSwitch && (
          <Switch
            style={{ pointerEvents: "all", background: "#1677FF" }}
            defaultChecked
            checked={andOr}
            onChange={handleSwitch}
            checkedChildren={
              <Text strong>
                <span style={{ color: "white" }}>AND</span>
              </Text>
            }
            unCheckedChildren={
              <Text strong>
                <span style={{ color: "white" }}>OR</span>
              </Text>
            }
          />
        )
      }
      dropdownRender={(menu) => (
        <>
          {menu}
          {extraChildren && (
            <React.Fragment>
              <Divider style={{ margin: "8px 0" }} />
              {extraChildren}
            </React.Fragment>
          )}
        </>
      )}
    />
  );

  return tableView ? (
    selectMenu()
  ) : (
    <Dropdown
      dropdownRender={() => selectMenu()}
      open={openFilter}
      onOpenChange={(event) => setOpenFilter(event)}
      selectable
      multiple={filteredData.multiple ? true : false}
    >
      <Button
        className={`${
          value.length || extraHighlight
            ? "Filters-Button-Selected"
            : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
        loading={loading}
      >
        <Space>
          {rename ? rename : filteredData.menu_label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default SelectFilter;
