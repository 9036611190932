import {
  Col,
  Row,
  Space,
  Button,
  Badge,
  Grid,
  Tooltip,
  notification,
  Checkbox,
  Radio,
} from "antd";
import React from "react";
import { FilterFilled, MinusCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  default_filter_page,
  default_filter_page_size,
} from "../../utils/utils";
import { LOADING } from "../../../../../constants/commonConstants/generalConstants";
import SearchTypeDropdown from "./../SearchTypeDropdown";
import {
  getWatchAllFilters,
  removeTemporaryThreatFilters,
  temporarySaveThreatFilters,
  updateLastWeekThreats,
} from "../../../../../redux/actions/watchActions/watchActions";
import MenuFilter from "./../MenuFilter";
import SelectFilter from "../SelectFilter";
import DateFilter from "./../DateFilter";
import MultipleCheckboxComponents from "./../MultipleCheckboxComponents";
import MoreFilterSectionDrawer from "./moreFiltersSection/MoreFilterSectionDrawer";
import { changeTemplateId } from "../../../../../redux/actions/watchActions/watchActions";
import {
  APPLIED_GRID_LAYOUT_F,
  IMAGE_TEXT_GRID_LAYOUT_F,
  IMAGE_LABEL_GRID_LAYOUT_F,
  TM_PROTECT_GRID_LAYOUT_F,
} from "../../../../../redux/actions/watchActions/types";
import dayjs from "dayjs";
import { date_format } from "../../utils/utils";
import CheckableTagComponent from "./../CheckableTagComponent";
import { getGridLayoutThreatData } from "../../../../../redux/actions/watchActions/gridDashboardActions";
import UpdateGridShortlistedComponent from "./UpdateGridShortlistedComponent";

const { useBreakpoint } = Grid;

const SecondaryFilter = (props) => {
  const {
    threatTMTypes,
    setThreatTMTypes,
    threatTMCategories,
    setThreatTMCategories,
    threatTMOffices,
    setThreatTMOffices,
    infringement,
    setInfringement,
    searchType,
    setSearchType,
    searchValue,
    setSearchValue,
    excludeReported,
    setExcludeReported,
    shortlisted,
    setShortlisted,
    showDescription,
    setShowDescription,
    bookmark,
    setBookmark,
    threatAttorneyValue,
    setThreatAttorneyValue,
    threatProprietors,
    setThreatProprietors,
    threatTMClass,
    setThreatClass,
    similarity,
    setSimilarity,
    threatDateOfApplication,
    setThreatDateOfApplication,
    threatDateOfUsage,
    setThreatDateOfUsage,
    threatDateOfUsageProposed,
    setThreatDateOfUsageProposed,
    excludeThreatProprietors,
    setExcludeThreatProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    publishedDate,
    setPublishedDate,
    excludeShortlisted,
    setExcludeShortlisted,
    threatFilterApplied,
    setThreatFilterApplied,
    andOrThreatClass,
    setAndOrThreatClass,
    generateFilterObject,
    isAnyFilterSelected,
    journal,
    markDetails,
    threatLabel,
    setThreatLabel,
    andOrThreatLabel,
    setAndOrThreatLabel,
    getRealSearchParameters,
    threatView,
    setThreatView,
    actionDropDown,
    showMoreFilter,
  } = props;

  const [openMoreFilters, setOpenMoreFilters] = React.useState(false);

  const dispatch = useDispatch();
  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3] || "";

  const {
    threatTypeConfig,
    threatCategoryConfig,
    threatOfficeConfig,
    threatLabelConfig,
    temp_threat_filters,
    infringementConfig,
  } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const { current_template_id } = useSelector(
    (state) => state.watchState.watchTemplate[dashboardLayout][urlKey].config,
  );

  const { status, pageNumber } = useSelector(
    (state) =>
      state.watchState.gridLayoutData[dashboardLayout][urlKey].config.threats,
  );

  const { isDataPresent } = useSelector(
    (state) => state.watchState.gridLayoutData[dashboardLayout][urlKey].config,
  );

  const generateDateObject = (item) => {
    // Split the date string by '-' and rearrange it to YYYY-MM-DD for dayjs better functionality.
    const fromDateParts = item.value.from.split("-");
    const fromDate = `${fromDateParts[2]}-${fromDateParts[1]}-${fromDateParts[0]}`;

    const toDateParts = item.value.to.split("-");
    const toDate = `${toDateParts[2]}-${toDateParts[1]}-${toDateParts[0]}`;

    return {
      from: fromDate.length ? dayjs(fromDate) : "",
      to: toDate.length ? dayjs(toDate) : "",
      dateString:
        fromDate.length && toDate.length
          ? `${dayjs(fromDate).format(date_format)}to${dayjs(toDate).format(
              date_format,
            )}`
          : "",
    };
  };

  const getSearchTerm = (filters) => {
    if (filters.contains_term.value) {
      setSearchValue(filters.contains_term.value);
      setSearchType("Application No/Name");
    } else if (filters.threat_desc_contains_term.value) {
      setSearchValue(filters.threat_desc_contains_term.value);
      setSearchType("Your Threat Description Search");
    } else {
      setSearchType("Application No/Name");
      setSearchValue("");
    }
  };

  const getCreationOrigin = () => {
    let imageLabel = "IMAGE_LABEL";

    if (urlKey === "tm-applied" || urlKey === "tm_applied") {
      imageLabel = "APPLIED_FOR";
    } else if (urlKey === "tm-protect" || urlKey === "tm_protect") {
      imageLabel = "TM_PROTECT";
    } else if (
      urlKey === "image-text" ||
      urlKey === "image_text" ||
      urlKey === "image_text_new"
    ) {
      imageLabel = "IMAGE_TEXT";
    } else if (urlKey === "image_label") {
      imageLabel = "IMAGE_LABEL";
    } else {
      imageLabel = "IMAGE_LABEL";
    }
    return imageLabel;
  };

  const fetchTemporarySaveData = () => {
    if (
      temp_threat_filters[urlKey] &&
      temp_threat_filters[urlKey][markDetails.application_number]
    ) {
      setThreatFilterApplied(true);
      let filters = temp_threat_filters[urlKey][markDetails.application_number];
      getSearchTerm(filters);

      setExcludeSameClassInfringement(
        filters?.exclude_same_class?.value
          ? filters.exclude_same_class.value
          : false,
      );
      setSameClassInfringement(
        filters?.red_band?.value ? filters.red_band.value : false,
      );
      setExcludeReported(
        filters?.exclude_marks_wrt_custom_report?.value
          ? filters.exclude_marks_wrt_custom_report.value
          : false,
      );
      setSimilarity(
        filters?.threat_similarity?.value
          ? filters.threat_similarity.value
          : {
              from: 0,
              to: 100,
            },
      );
      setThreatAttorneyValue(
        filters?.threat_attorney?.value.length
          ? filters.threat_attorney.value
          : [],
      );
      setThreatTMCategories(
        filters?.threat_categories?.value.length
          ? filters.threat_categories.value
          : [],
      );
      setThreatClass(
        filters?.threat_classes?.value.length
          ? filters.threat_classes.value
          : [],
      );
      setThreatDateOfApplication(
        filters?.threat_date_of_application?.value?.from.length ||
          filters?.threat_date_of_application?.value?.to.length
          ? generateDateObject(filters.threat_date_of_application)
          : { from: "", to: "", dateString: "" },
      );
      setThreatDateOfUsage(
        filters?.threat_date_of_usage?.value?.from.length ||
          filters?.threat_date_of_usage?.value?.to.length
          ? generateDateObject(filters.threat_date_of_usage)
          : { from: "", to: "", dateString: "" },
      );
      setThreatDateOfUsageProposed(
        filters?.threat_date_of_usage_proposed?.value
          ? filters.threat_date_of_usage_proposed.value
          : false,
      );
      setThreatTMOffices(
        filters?.threat_offices?.value.length
          ? filters.threat_offices.value
          : [],
      );
      setThreatProprietors(
        filters?.threat_proprietors?.value.length
          ? filters.threat_proprietors.value
          : [],
      );
      setInfringement(
        filters?.threat_sources?.value.length
          ? filters.threat_sources.value
          : [],
      );
      setThreatTMTypes(
        filters?.threat_types?.value.length ? filters.threat_types.value : [],
      );
      setAndOrThreatClass(
        filters?.threat_classes?.logic === "AND" ? true : false,
      );
      setExcludeThreatProprietors(
        filters?.exclude_threat_proprietors?.value.length
          ? filters.exclude_threat_proprietors.value
          : [],
      );
      setThreatLabel(
        filters?.threat_labels?.value.length ? filters.threat_labels.value : [],
      );
      dispatch(
        getGridLayoutThreatData(
          markDetails.application_number,
          journal,
          1,
          true,
          filters,
          window?.location,
        ),
      );
    } else {
      dispatch(
        getGridLayoutThreatData(
          markDetails.application_number,
          journal,
          1,
          false,
          {},
          window?.location,
        ),
      );
    }
  };

  React.useEffect(() => {
    if (isDataPresent) {
      fetchTemporarySaveData();
    }
  }, []);
  React.useEffect(() => {
    setOpenMoreFilters(showMoreFilter);
  }, [showMoreFilter]);

  const getFilterPath = () => {
    // for grid layout only...

    let templatePath = APPLIED_GRID_LAYOUT_F;

    if (urlKey === "tm-applied" || urlKey === "tm_applied") {
      templatePath = APPLIED_GRID_LAYOUT_F;
    } else if (urlKey === "tm-protect" || urlKey === "tm_protect") {
      templatePath = TM_PROTECT_GRID_LAYOUT_F;
    } else if (
      urlKey === "image-text" ||
      urlKey === "image_text" ||
      urlKey === "image_text_new"
    ) {
      templatePath = IMAGE_TEXT_GRID_LAYOUT_F;
    } else if (urlKey === "image_label") {
      templatePath = IMAGE_LABEL_GRID_LAYOUT_F;
    } else {
      templatePath = APPLIED_GRID_LAYOUT_F;
    }
    return templatePath;
  };

  React.useEffect(() => {
    let dashboardUrl = getFilterPath();

    if (journal) {
      const filterKeys = [
        "threat_proprietors",
        "threat_classes",
        "threat_types",
        "threat_categories",
        "threat_offices",
        "threat_attorney",
        "threat_sources",
      ];
      if (urlKey === "image_label") {
        filterKeys.push("threat_labels");
      }
      filterKeys.map((item) =>
        dispatch(
          getWatchAllFilters(
            journal,
            item,
            null,
            default_filter_page,
            default_filter_page_size,
            current_template_id,
            dashboardUrl,
            markDetails.application_number,
          ),
        ),
      );
    }
  }, [journal, props.markDetails.id]);

  const screenWidth = useBreakpoint();

  const isSelectAllChecked =
    props.selectedData.data?.[props.markDetails.application_number]?.select_all;

  const handleUpdateThreadCount = (newCount) => {
    dispatch(updateLastWeekThreats(props.markDetails.id, newCount));
    if (isSelectAllChecked) {
      props.setSelectedData({
        ...props.selectedData,
        threatsCount:
          Math.max(
            props.selectedData.threatsCount -
              props.markDetails.last_week_threats,
            0,
          ) + newCount,
      });
    }
  };

  const handleApplyFilters = () => {
    setThreatFilterApplied(true);
    const filters = generateFilterObject();
    dispatch(
      temporarySaveThreatFilters(
        urlKey,
        markDetails.application_number,
        filters,
      ),
    );
    dispatch(
      getGridLayoutThreatData(
        markDetails.application_number,
        journal,
        1,
        true,
        filters,
        window?.location,
        (resStatus, res) => {
          if (resStatus === "SUCCESS") {
            handleUpdateThreadCount(res?.data?.metadata?.count);
          }
        },
      ),
    );
  };

  const handleResetFilters = (journalNumber) => {
    setThreatFilterApplied(false);
    setSearchValue("");
    setExcludeReported("");
    setThreatTMTypes([]);
    setThreatTMCategories([]);
    setThreatTMOffices([]);
    setInfringement([]);
    setSearchType("Application No/Name");
    setExcludeReported(false);
    setShortlisted(false);
    setBookmark(false);
    setThreatAttorneyValue([]);
    setThreatProprietors([]);
    setExcludeThreatProprietors([]);
    setThreatClass([]);
    setSimilarity({
      from: 0,
      to: 100,
    });
    setThreatDateOfApplication({
      from: "",
      to: "",
      dateString: "",
    });
    setThreatDateOfUsage({
      from: "",
      to: "",
      dateString: "",
    });
    setSameClassInfringement(false);
    setExcludeSameClassInfringement(false);
    setThreatDateOfUsageProposed(false);
    setExcludeShortlisted(false);
    setThreatLabel([]);
    setAndOrThreatLabel(false);
    dispatch(changeTemplateId(0));
    dispatch(
      removeTemporaryThreatFilters(urlKey, markDetails.application_number),
    );
    dispatch(
      getGridLayoutThreatData(
        markDetails.application_number,
        journalNumber,
        1,
        false,
        {},
        window?.location,
        (resStatus, res) => {
          if (resStatus === "SUCCESS") {
            handleUpdateThreadCount(res?.data?.metadata?.count);
          }
        },
      ),
    );
  };

  const useMemoizedComponent = (Component, deps) => {
    return React.useMemo(() => Component, deps);
  };

  let listOfSearchType = [
    "Application No/Name",
    "Your Threat Description Search",
  ];

  let secondaryFilterSelected = isAnyFilterSelected(generateFilterObject());

  const firstRow = (
    <Row style={{ width: "100%" }}>
      <Col lg={16} md={24} style={{ marginBottom: "16px" }}>
        <Space>
          {useMemoizedComponent(
            <SearchTypeDropdown
              value={searchType}
              setValue={setSearchType}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              listOfSearchType={listOfSearchType}
            />,
            [searchType, searchValue],
          )}
          <Button
            type="primary"
            icon={<FilterFilled />}
            disabled={
              !secondaryFilterSelected || (status === LOADING ? true : false)
            }
            onClick={handleApplyFilters}
            loading={status === LOADING ? true : false}
          >
            Apply
          </Button>
          <Button
            type="text"
            danger
            onClick={() => handleResetFilters(journal)}
            disabled={!threatFilterApplied}
          >
            Reset All
          </Button>
        </Space>
      </Col>
      <Col
        lg={8}
        md={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent:
            screenWidth.xl || screenWidth.xxl || screenWidth.lg
              ? "right"
              : "left",
          marginBottom: "16px",
        }}
      >
        <UpdateGridShortlistedComponent
          source={"GRID_LAYOUT"}
          creationOrigin={getCreationOrigin()}
          journalNumber={journal}
          selected={
            props?.selectedData?.data[markDetails?.application_number]
              ?.threats || []
          }
          filterFlag={threatFilterApplied}
          filter={generateFilterObject()}
          applicationNumber={markDetails?.application_number}
          selectAll={
            props?.selectedData?.data[markDetails?.application_number]
              ?.select_all
          }
          disabled={
            !(
              props?.selectedData?.data[markDetails?.application_number]
                ?.threats?.length ||
              props?.selectedData?.data[markDetails?.application_number]
                ?.select_all
            )
          }
          pageNumber={pageNumber}
        />
      </Col>
    </Row>
  );
  const secondRow = (
    <Row style={{ width: "100%" }}>
      <Col lg={20} md={24}>
        <Space>
          {useMemoizedComponent(
            <MultipleCheckboxComponents
              excludedReport={excludeReported}
              setExcludedReport={setExcludeReported}
              shortlist={shortlisted}
              setShortlist={setShortlisted}
              excludeShortlisted={excludeShortlisted}
              setExcludeShortlisted={setExcludeShortlisted}
              open={actionDropDown}
            />,
            [excludeReported, shortlisted, excludeShortlisted, actionDropDown],
          )}
          {useMemoizedComponent(
            <MenuFilter
              filteredData={threatTypeConfig}
              value={threatTMTypes}
              setValue={setThreatTMTypes}
              loading={threatTypeConfig.loading}
              rename="TM Types"
            />,
            [threatTMTypes, threatTypeConfig],
          )}
          {useMemoizedComponent(
            <MenuFilter
              filteredData={threatCategoryConfig}
              value={threatTMCategories}
              setValue={setThreatTMCategories}
              loading={threatCategoryConfig.loading}
              rename="Categories"
            />,
            [threatTMCategories, threatCategoryConfig],
          )}
          {useMemoizedComponent(
            <MenuFilter
              filteredData={threatOfficeConfig}
              value={threatTMOffices}
              setValue={setThreatTMOffices}
              loading={threatOfficeConfig.loading}
              rename="Offices"
            />,
            [threatTMOffices, threatOfficeConfig],
          )}
          {urlKey === "image_label" ? (
            <SelectFilter
              filteredData={threatLabelConfig}
              value={threatLabel}
              setValue={setThreatLabel}
              journal_number={journal}
              loading={threatLabelConfig.loading}
              showSwitch
              andOr={andOrThreatLabel}
              setAndOr={setAndOrThreatLabel}
              current_template_id={current_template_id}
              filterPath={getFilterPath()}
              application_number={markDetails.application_number}
              rename="Focus Label"
            />
          ) : null}
          {/* (
            <MenuFilter
              filteredData={infringementConfig}
              value={infringement}
              setValue={setInfringement}
              infrigementInfo={true}
              loading={infringementConfig.loading}
            />
          ) */}
          {useMemoizedComponent(
            <DateFilter
              menu_label="DoA | DoU"
              subtitle1="Threat Date of Application"
              subtitle2="Threat Date of Usage"
              key={"tm_date"}
              value1={threatDateOfApplication}
              setValue1={setThreatDateOfApplication}
              value2={threatDateOfUsage}
              setValue2={setThreatDateOfUsage}
              proposed={threatDateOfUsageProposed}
              setProposed={setThreatDateOfUsageProposed}
            />,
            [
              threatDateOfApplication,
              threatDateOfUsage,
              threatDateOfUsageProposed,
            ],
          )}
          <Button
            id="more_filters_btn"
            type="link"
            onClick={() => setOpenMoreFilters(true)}
          >
            More Filters
          </Button>
        </Space>
      </Col>
      <Col
        lg={4}
        md={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: screenWidth.xl || screenWidth.xxl ? "right" : "left",
        }}
      >
        <Space>
          <Radio.Group
            id="grid-card-table-view-switch"
            size="small"
            value={threatView}
            onChange={(e) => setThreatView(e.target.value)}
          >
            <Radio.Button value="card">Card</Radio.Button>
            <Radio.Button value="table">Table</Radio.Button>
          </Radio.Group>
          {threatView === "table" && (
            <CheckableTagComponent
              label="G&S"
              id="grid_good_and_services_btn"
              value={showDescription}
              setValue={setShowDescription}
              disableTooltip="Show Descriptions"
              activeTooltip="Hide Descriptions"
            />
          )}
        </Space>
      </Col>
    </Row>
  );

  return (
    <>
      <header className="General-Header">
        <Row style={{ padding: "8px 10px" }}>
          {firstRow}
          {secondRow}
        </Row>
      </header>
      {openMoreFilters && (
        <MoreFilterSectionDrawer
          open={openMoreFilters}
          setOpen={setOpenMoreFilters}
          getRealSearchParameters={getRealSearchParameters}
          handleParentApplyFilters={handleApplyFilters}
          handleResetFilters={handleResetFilters}
          getFilterPath={getFilterPath}
          {...props}
        />
      )}
    </>
  );
};

export default SecondaryFilter;
