//--------LIBRARIES--------
import React from "react";
import { DeleteOutlined, EditOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Upload,
  message,
  Select,
  Typography,
  Popconfirm,
  Button,
  List,
  Input,
  Alert,
  Tooltip,
  Row,
  Col,
} from "antd";

//--------RT_CONFIGS--------
import { MGR_RT_URL } from "../../../configs/rootConfigs";
import { SUCCESS } from "../../../constants/commonConstants/generalConstants";
import { standardizeDate } from "../../../functions/commonFunctions/dateFunctions";

const { Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

export default function UploadDocuments({
  applicationNumber,
  documentFor,
  teamMembers,
  defaultEmail,
  postUploadFile,
  deleteUploadedFile,
  putUploadedFile,
  uploadedDocuments,
}) {
  let selectedTeamMember = defaultEmail;
  let renameValue = "";
  const parameters = {
    accept:
      ".doc,.docx, .xlx, .xlsx, .pdf, .png, .jpeg, .jpg, .ppt, .pptx, .csv, .xlmb, .json, .zip, .rar, .MSG",
    multiple: true,
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: false,
      showRemoveIcon: false,
    },
    action: (file) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(
          "GET",
          `${MGR_RT_URL}/signing/?file_name=${file.name}&file_size=${file.size}&application_number=${applicationNumber}&document_for=${documentFor}`,
          true
        );
        xhr.setRequestHeader(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        xhr.send();
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(JSON.parse(this.responseText));
          } else {
            message.error(`${JSON.parse(xhr.response).error}`);
            reject();
          }
        };
      });
    },
    customRequest: (parameters) => {
      const formData = new FormData();
      Object.keys(parameters.action.fields).forEach((key) => {
        formData.append(key, parameters.action.fields[key]);
      });
      formData.append("file", parameters.file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", parameters.action.url, true);
      xhr.send(formData);
      xhr.onload = async function () {
        if (xhr.status === 204) {
          let response = await postUploadFile({
            applicationNumber,
            documentFor,
            selectedTeamMember,
            fileName: parameters.file.name,
            fileSize: parameters.file.size,
          });
          if (response === SUCCESS) {
            parameters.onSuccess();
          } else {
            parameters.onError("s");
          }
        } else {
          parameters.onError("s");
        }
      };
    },
  };
  return (
    <>
      <Row className="m-top-20">
        <Col span={24}>
          <Text strong>Uploaded by:</Text>
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <Select
            style={{ width: "100%" }}
            onChange={(teamMemberEmail) =>
              (selectedTeamMember = teamMemberEmail)
            }
            defaultValue={selectedTeamMember}
          >
            {teamMembers &&
              teamMembers.map((eachTeamMember) => (
                <Option key={eachTeamMember.email} value={eachTeamMember.email}>
                  {eachTeamMember.email}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <Alert
            message="Note: Files having same name as previously uploaded files will replace them."
            type="warning"
          />
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <Dragger {...parameters}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <Text strong>Uploaded documents:</Text>
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <List
            dataSource={uploadedDocuments}
            size="small"
            bordered
            renderItem={(item) => (
              <List.Item style={{ padding: "10px 5px" }}>
                <Tooltip
                  placement="left"
                  title={
                    <>
                      <div>
                        <strong>Uploaded By:</strong> {item.uploaded_by}
                      </div>
                      <div>
                        <strong>Last Modified:</strong>
                        <br />
                        {standardizeDate(item.modified)}
                      </div>
                    </>
                  }
                >
                  <span style={{ width: "75%" }}>{item.title}</span>
                </Tooltip>
                <span style={{ width: "25%" }}>
                  <Popconfirm
                    title={
                      <>
                        Rename:
                        <br />
                        <Alert
                          type="warning"
                          message="Valid characters: A-Z a-z 0-9 ! ? _ () @ # , : ; <> [] {} | * $ -"
                        />
                        <br />
                        <Input
                          placeholder={item.title}
                          defaultValue={item.title}
                          onChange={(event) =>
                            (renameValue = event.target.value)
                          }
                        />
                      </>
                    }
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                    onConfirm={() => {
                      if (
                        /^[ \w!?:_\-()@#,.;<>[\]{}|]*$/g.test(renameValue) &&
                        renameValue !== ""
                      ) {
                        putUploadedFile({
                          documentFor,
                          fileName: item.title,
                          applicationNumber,
                          renameValue,
                        });
                      } else {
                        message.error(
                          `Invalid characters or empty string or no change`
                        );
                      }
                      renameValue = "";
                    }}
                  >
                    <Tooltip placement="top" title="Rename">
                      <Button
                        icon={<EditOutlined />}
                        type="primary"
                        ghost
                        style={{ border: "none", padding: "0px" }}
                      />
                    </Tooltip>
                  </Popconfirm>
                  <Popconfirm
                    title={`Are you sure you want to delete "${item.title}"？`}
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                    onConfirm={() =>
                      deleteUploadedFile({
                        documentFor,
                        fileName: item.title,
                        applicationNumber,
                      })
                    }
                  >
                    <Tooltip placement="top" title="Delete">
                      <Button
                        icon={<DeleteOutlined />}
                        danger
                        ghost
                        style={{ border: "none", padding: "0px" }}
                      />
                    </Tooltip>
                  </Popconfirm>
                </span>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}
