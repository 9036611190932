//--------IP-SUITE CONSTANTS--------
import { PDF } from "../commonConstants/generalConstants";

//--------Default filters--------
export const LL_defaultSelectedFilters = {
  tags: [],
  NOtags: [],
  markClasses: [],
  threatClasses: [],
  threatSources: [],
  searchTerm: "",
  mark_desc_contains_term: "",
  threat_desc_contains_term: "",
  threatsSimilarity: {
    lowerLimit: 0,
    upperLimit: 100,
  },
  markTypes: [],
  threatTypes: [],
  markProprietors: [],
  markCategories: [],
  threatCategories: [],
  markOffices: [],
  threatOffices: [],
  redBand: false,
  excludeClass: false,
  mark_date_of_application: "",
  threat_date_of_application: "",
  mark_date_of_usage: "",
  mark_date_of_usage_proposed: false,
  threat_date_of_usage: "",
  threat_date_of_usage_proposed: false,
  exclude_mark_added_to_report: false,
  searchType: "Application No/Name",
};

//--------Default Filters Visibility--------
export const LL_defaultFiltersVisibility = {
  tagsMenu: false,
  classesMenu: false,
  markProprietorsMenu: false,
  typesMenu: false,
  threatsSimilarityMenu: false,
  threatSourcesMenu: false,
  categoriesMenu: false,
  officesMenu: false,
};

//--------Default Fiters Search Terms--------
export const LL_defaultFiltersSearchTerms = {
  tags: "",
  markProprietors: "",
};

//--------Default Custom Report Data--------
export const LL_defaultCustomReportData = {
  selectAll: false,
  selectedRecords: {},
  selectedRecordsLength: 0,
  report_format: PDF,
  report_type: "CUSTOM",
  report_name: "",
};

//--------Default Bookmark Data--------
export const LL_defaultBookmarkData = {
  present: false,
  journalNumber: null,
  profileReportID: null,
  bookmarkType: null,
  pageNumber: null,
  markApplicationNumber: null,
  threatApplicationNumber: null,
  filterFlag: false,
  ...LL_defaultSelectedFilters,
};

//--------Default Bookmark Data with backend keys--------
export const LL_defaultBookmarkData_BEK = {
  present: false,
  journal_number: null,
  cease_desist_report_id: null,
  bookmark_type: null,
  page: null,
  mark_application_number: null,
  threat_application_number: null,
  filter_flag: false,
  tags: [],
  mark_classes: [],
  threat_classes: [],
  threat_sources: [],
  search_term: "",
  threat_similarity: "0to100",
  mark_type: [],
  threat_type: [],
  mark_proprietor: [],
  mark_categories: [],
  threat_categories: [],
  mark_offices: [],
  threat_offices: [],
  red_band: false,
  exclude_same_class: false,
  mark_date_of_application: "",
  threat_date_of_application: "",
  mark_date_of_usage: "",
  mark_date_of_usage_proposed: false,
  threat_date_of_usage: "",
  threat_date_of_usage_proposed: false,
  exclude_mark_added_to_report: false,
};
