import { GET_COMMENT } from '../actions/commonActions/types';

const defaultWhatsNewState = {
    comment: null,
    commentStatus: null,
};

export default function(state = defaultWhatsNewState, action){
    switch (action.type) {
        case GET_COMMENT:
            return {
                ...state,
                comment: action.payload,
                commentStatus: action.status,
            };

        default:
            return state;
    }
}