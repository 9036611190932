import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Row, Col, Modal, Button } from "antd";

//--------COMMON CONSTANTS--------
import { LOADING } from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import { replyFiledToCorrespondence } from "../../../redux/actions/managerActions/trademarkDashboard/actions";

class ReplyFiledModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  async confirmReplyFiled() {
    const {
      actionType,
      mm_type,
      pending_reply,
      application_number,
      pseudo,
      filter_flag,
      evidence_filter,
      trademark_status,
      mark_type,
      selectAll,
      reRender,
      filters,
    } = this.props;
    // await this.props.replyFiledToCorrespondence(actionType, mm_type, pending_reply, application_number, pseudo, filter_flag, evidence_filter, trademark_status, mark_type)
    await this.props.replyFiledToCorrespondence(
      actionType,
      mm_type,
      pending_reply,
      application_number,
      pseudo,
      filter_flag,
      evidence_filter,
      trademark_status,
      mark_type,
      selectAll,
      filters
    );
    await this.props.onCloseModal();

    reRender();
  }

  render() {
    const { dashboardReplyFiledStatus, replyFiledStatus } = this.props;
    return (
      <div>
        <Modal
          title="Confirm Remove"
          open={this.props.visible}
          closable={false}
          footer={false}
        >
          <div>
            <Row gutter={16}>
              <Col span={24}>
                {this.props.applied_for ? (
                  <p>
                    Are you sure you want to remove{" "}
                    <span>
                      <strong>{this.props.applied_for}</strong>
                    </span>{" "}
                    from this table?
                  </p>
                ) : (
                  <p>
                    Are you sure you want to remove selected (
                    <span>
                      <strong>{this.props.count}</strong>
                    </span>
                    ) marks from this table?
                  </p>
                )}
              </Col>
              <Col span={24} className="m-top-20 alignR">
                <div>
                  <ul className="report-footer">
                    <li>
                      <Button type="default" onClick={this.props.onCloseModal}>
                        Cancel
                      </Button>
                    </li>
                    <li>
                      <Button
                        danger
                        loading={
                          replyFiledStatus === LOADING ||
                          dashboardReplyFiledStatus === LOADING
                        }
                        onClick={() => this.confirmReplyFiled()}
                      >
                        Confirm
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  replyFiledToCorrespondence,
};

function mapStateToProps(state) {
  const { replyFiledMessage, replyFiledStatus } =
    state.managerState.trademarkDashboardState;
  const { dashboardReplyFiledStatus } = state.managerState.correspondanceState;
  return deepFreeze({
    replyFiledMessage,
    replyFiledStatus,
    dashboardReplyFiledStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplyFiledModal);
