import React from "react";
import {
  Dropdown,
  Button,
  Space,
  Select,
  Switch,
  Divider,
  Typography,
} from "antd";
import { DownOutlined, TagOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  getWatchAllFilters,
  changeListSelectedDropdownValues,
  changeListDropdownSearchValues,
  changeFilterAndOr,
} from "../../../../redux/actions/watchActions/watchActions";
import { default_filter_page, default_filter_page_size } from "../utils/utils";
import { debounce } from "lodash";

const { Text } = Typography;
const { Option } = Select;

const TagFilter = ({
  filteredData,
  extraChildren,
  value = [],
  setValue,
  menuItemDisabled = false,
  loading = false,
  tableView = false,
  current_template_id = 0,
  journal_number,
  andOrTags = false,
  setAndOrTags,
  filterPath = "",
  application_number = 0,
  rename = "",
  shouldUpdateStore = true,
}) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (tableView) {
      setOpenFilter(true);
    }
  }, []);

  const handleChange = (newValue, newOption) => {
    setValue(newValue);
    const tempObject = newOption.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    shouldUpdateStore &&
      dispatch(changeListSelectedDropdownValues(filteredData.key, tempObject));
  };

  const handleSwitch = (event) => {
    setAndOrTags(event);
    shouldUpdateStore && dispatch(changeFilterAndOr(filteredData.key, event));
  };

  const handleSearchDebounced = React.useCallback(
    debounce((newValue) => {
      journal_number &&
        dispatch(
          getWatchAllFilters(
            journal_number,
            filteredData.key,
            newValue,
            default_filter_page,
            default_filter_page_size,
            current_template_id,
            filterPath,
            application_number,
          ),
        );
    }, 500),
    [journal_number, current_template_id],
  );

  const handleSearch = (newValue) => {
    dispatch(changeListDropdownSearchValues(filteredData.key, newValue));
    handleSearchDebounced(newValue);
  };

  const placeholder = `Search ${filteredData.menu_label}`;

  const tagRender = (props) => {
    return null;
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (filteredData.data.length === filteredData.metadata.count) {
      return;
    }
    if (
      !loading &&
      scrollTop + clientHeight >= scrollHeight - 20 &&
      filteredData.data.length < filteredData.metadata.count
    ) {
      journal_number &&
        dispatch(
          getWatchAllFilters(
            journal_number,
            filteredData.key,
            filteredData.searchValue,
            filteredData.metadata.page + 1,
            default_filter_page_size,
            current_template_id,
            filterPath,
            application_number,
          ),
        );
    }
  };

  const selectMenu = () => (
    <Select
      mode="multiple"
      style={{
        minWidth: "320px",
      }}
      dropdownStyle={{
        minWidth: "320px",
      }}
      id={`watch_filter_id_${filteredData.key}`}
      onPopupScroll={handleScroll}
      placeholder={placeholder}
      open={openFilter}
      value={value}
      onChange={handleChange}
      loading={loading}
      showSearch
      searchValue={filteredData.searchValue}
      onSearch={handleSearch}
      className="select-filter-dropdown"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      tagRender={tagRender}
      suffixIcon={
        <Switch
          style={{ pointerEvents: "all", background: "#1677FF" }}
          defaultChecked
          checked={andOrTags}
          onChange={handleSwitch}
          checkedChildren={
            <Text strong>
              <span style={{ color: "white" }}>AND</span>
            </Text>
          }
          unCheckedChildren={
            <Text strong>
              <span style={{ color: "white" }}>OR</span>
            </Text>
          }
        />
      }
      dropdownRender={(menu) => (
        <>
          {menu}
          {extraChildren && (
            <React.Fragment>
              <Divider style={{ margin: "8px 0" }} />
              {extraChildren}
            </React.Fragment>
          )}
        </>
      )}
    >
      {filteredData.data.map((item, index) => (
        <Option
          value={item.value}
          label={item.label}
          disabled={menuItemDisabled}
        >
          <Space>
            <span role="img" aria-label="tag">
              <TagOutlined
                style={{
                  color: item?.meta_data?.color_code
                    ? item.meta_data.color_code
                    : "black",
                }}
              />
            </span>
            {item.label}
          </Space>
        </Option>
      ))}
    </Select>
  );

  return tableView ? (
    selectMenu()
  ) : (
    <Dropdown
      dropdownRender={() => selectMenu()}
      open={openFilter}
      onOpenChange={(event) => setOpenFilter(event)}
      selectable
      multiple={filteredData.multiple ? true : false}
    >
      <Button
        className={`${
          value.length ? "Filters-Button-Selected" : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
        loading={loading}
      >
        <Space>
          {filteredData.menu_label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default TagFilter;
