import React, { Component } from "react";
import { connect } from "react-redux";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Form, Icon as LegacyIcon } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Input, Button, Alert, Tooltip, Typography } from "antd";

//--------COMMON ACTIONS--------
import { signUpUser } from "../../redux/actions/commonActions/actions";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../constants/commonConstants/generalConstants";
import "../../stylesheets/loginstylesheets/login.css";

const { Text } = Typography;
class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pwdVisible: true,
      cnfpwdVisible: true,
      pwdValue: "",
      cnfpwdValue: "",
    };
  }

  passwordValidation(data, event) {
    let id = event.target.id;
    this.setState({
      [data]: event.target.value,
    });
    setTimeout(() => {
      if (id === "sign_up_confirm_password") {
        let element = document.getElementById("sign_up_confirm_password");
        if (this.state.pwdValue === this.state.cnfpwdValue) {
          element.classList.remove("cnfpwd_error_input");
        } else {
          element.classList.add("cnfpwd_error_input");
        }
      }
    }, 500);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.props.signUpUser(values);
        this.setState({
          pwdVisible: true,
          cnfpwdVisible: true,
          pwdValue: "",
          cnfpwdValue: "",
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { signUpMessage, signUpStatus } = this.props;
    const { pwdVisible, cnfpwdVisible, pwdValue, cnfpwdValue } = this.state;
    return (
      <div className="login-form">
        <Form
          onSubmit={(event) => this.handleSubmit(event)}
          className="login-form"
        >
          {(signUpStatus === SUCCESS || signUpStatus === ERROR) && (
            <Form.Item className="Sign-In-Input-Field">
              <Alert
                message={signUpMessage}
                type={signUpStatus.toLowerCase()}
                showIcon
              />
            </Form.Item>
          )}
          <Form.Item className="Sign-In-Input-Field">
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Please enter your email!" }],
            })(
              <Input
                type="text"
                prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="email"
              />
            )}
          </Form.Item>
          <Form.Item className="Sign-In-Input-Field">
            <Tooltip
              key="password"
              placement="topLeft"
              title="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
            >
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    pattern:
                      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                    message: "Please enter your correct password pattern!",
                  },
                ],
              })(
                <Input
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  suffix={
                    <LegacyIcon
                      type={pwdVisible ? "eye-invisible" : "eye"}
                      style={{ color: "rgba(0,0,0,.25)" }}
                      onClick={() =>
                        this.setState({ pwdVisible: !this.state.pwdVisible })
                      }
                    />
                  }
                  type={pwdVisible ? "password" : "text"}
                  placeholder="Password"
                  onChange={(event) =>
                    this.passwordValidation("pwdValue", event)
                  }
                />
              )}
            </Tooltip>
          </Form.Item>
          <Form.Item className="Sign-In-Input-Field">
            {getFieldDecorator("confirm_password", {
              rules: [
                {
                  required: true,
                  message: "Please enter your correct password pattern!",
                },
              ],
            })(
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                suffix={
                  <LegacyIcon
                    type={cnfpwdVisible ? "eye-invisible" : "eye"}
                    style={{ color: "rgba(0,0,0,.25)" }}
                    onClick={() =>
                      this.setState({
                        cnfpwdVisible: !this.state.cnfpwdVisible,
                      })
                    }
                  />
                }
                type={cnfpwdVisible ? "password" : "text"}
                placeholder="Confirm Password"
                onChange={(event) =>
                  this.passwordValidation("cnfpwdValue", event)
                }
              />
            )}
          </Form.Item>
          {cnfpwdValue.length > 0 && pwdValue !== cnfpwdValue && (
            <Form.Item className="cnfpwd_error_box">
              <Text className="cnfpwd_error_text">
                your password is not match correctly.
              </Text>
            </Form.Item>
          )}
          <Form.Item className="Sign-In-Input-Field Log-In-btn">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button w-100"
              loading={signUpStatus === LOADING}
            >
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  signUpUser,
};

function mapStateToProps(state) {
  const { signUpMessage, signUpStatus } = state.signInState;
  return {
    signUpMessage,
    signUpStatus,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "sign_up" })(SignUpForm));
