import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";

const InterestPopup = () => {
  const [visible, setVisible] = useState(false);

  const { userDetails } = useSelector((state) => state?.userSubscriptions);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 10000); // 15 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleOk = () => {
    const email = userDetails?.email;
    const name = userDetails?.name;
    mixpanel.identify(email);
    mixpanel.alias(email);
    mixpanel.register({
      name: name,
      email: email,
    });
    mixpanel.people.set({
      name: name,
      email: email,
    });
    mixpanel.track("Image_Search_Interest_Form_Click");

    const url = "https://forms.gle/qd1oyPCBpEA6cHkY8";
    window.open(url, "_blank");

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Interested in this update?"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p>
        If you wish to avail this feature on entire trademark search database
        fill up the form below and our team will reach out to you.
      </p>
    </Modal>
  );
};

export default InterestPopup;
