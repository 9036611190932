import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Row, Col, Modal, Select } from "antd";

const { Option } = Select;

class SampleTemplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: window.location.pathname.split("/")[1].toUpperCase(),
    };
  }

  render() {
    const { productSubscription } = this.props;
    return (
      <Modal
        open={this.props.visible}
        onOk={this.props.onCloseModal}
        onCancel={this.props.onCloseModal}
        footer={false}
        maskClosable={false}
        className="custom-send-email-modal"
      >
        <div
          className="custom-send-email-container vertical_scroll"
          style={{ height: "calc(100vh - 50px)" }}
        >
          <Row className="custom-send-email">
            <Col span={24} className="custom-send-email-form">
              <div className="custom-send-email-Input-Field">
                <Select
                  value={this.state.product}
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ product: value })}
                >
                  {productSubscription &&
                    productSubscription.map((data) => (
                      <Option key={data.value} value={data.value}>
                        {data.label}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className="custom-send-email-Input-Field">
                <div
                  style={{
                    padding: 15,
                    border: "1px solid #ddd",
                    borderRadius: 5,
                    minHeight: 420,
                  }}
                >
                  {this.state.product === "MANAGER" && (
                    <div>
                      <p>Dear Sir/Ma'am,</p>
                      <p>
                        Attached is the report for your portfolio marks
                        currently handled by us
                      </p>
                      <p>
                        <strong>Regards</strong>
                      </p>
                      <p>MikeLegal</p>
                    </div>
                  )}
                  {this.state.product === "SEARCH" && (
                    <div>
                      <p>Dear Sir/Ma'am,</p>
                      <p>
                        Attached is the Public Search Report created for your
                        mark{" "}
                        <a href="mark_name" target="_blank">
                          {" "}
                          @mark_name
                        </a>{" "}
                        on{" "}
                        <a href="created_date" target="_blank">
                          @created_date
                        </a>{" "}
                      </p>
                      <p>
                        Kindly advise whether you wish to proceed with the
                        filing of this mark or want us to check for the
                        availability of other marks.
                      </p>
                      <p>
                        <strong>Regards</strong>
                      </p>
                      <p>MikeLegal</p>
                    </div>
                  )}
                  {this.state.product === "WATCH" && (
                    <div>
                      <p>Dear Sir/Ma'am,</p>
                      <p>
                        the mark published in{" "}
                        <a href="journal_number" target="_blank">
                          {" "}
                          @journal_number
                        </a>{" "}
                        potentially infringing your trademark{" "}
                        <a href="your_mark" target="_blank">
                          {" "}
                          @your_mark
                        </a>
                        .{" "}
                      </p>
                      <p>
                        The last date of filing an opposition against the above
                        mark is{" "}
                        <a href="opposition" target="_blank">
                          @opposition
                        </a>{" "}
                      </p>
                      <p>
                        <strong>Regards</strong>
                      </p>
                      <p>MikeLegal</p>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return deepFreeze({
    productSubscription: state.productSubscription.productSubscription,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleTemplete);
