const date_format = "DD-MM-YYYY";
const default_filter_page = 1;
const default_filter_page_size = 1000;
const default_template_page = 1;
const default_template_page_size = 100;

export {
  date_format,
  default_filter_page,
  default_filter_page_size,
  default_template_page,
  default_template_page_size,
};
