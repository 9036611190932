import Axios from "axios";
import { notification } from "antd";
import { ROOT_URL } from "../../../configs/rootConfigs";
import {
  W_F_JOURNAL,
  W_F_UPDATE_SELECTED_VALUES,
  W_F_UPDATE_SEARCH_VALUES,
  W_F_EMPTY_SEARCH_VALUES,
  W_F_UPDATE_EXCLUDE_VALUES,
  W_CHANGE_TEMPLATE_ID,
  USER_TEMPLATE_TYPE,
  APPLIED_LIST_LAYOUT,
  W_TEMPLATE,
  W_CREATE_TEMPLATE,
  W_UPDATE_TEMPLATE,
  W_DELETE_TEMPLATE,
  W_TEMPORARY_THREAT_FILTER_SAVE,
  W_TEMPORARY_THREAT_FILTER_REMOVE,
  W_CREATE_REPORT,
  W_COMBINE_PDF,
  W_F_UPDATE_DATE,
  W_F_UPDATE_DATE_PROPOSED,
  W_F_SEARCH_TYPE,
  W_F_SEARCH_VALUE,
  W_F_FILTER_APPLIED,
  W_F_RESET_ALL,
  W_F_CHANGE_AND_OR,
  W_UPDATE_LAST_WEEK_THREATS,
  W_TEMPORARY_THREAT_FILTER_DEFAULT_VALUE,
  W_LIST_BOOLEAN_FILTER_UPDATE,
  W_F_UPDATE_no_tags,
  W_F_UPDATE_similarity,
  W_F_UPDATE_WHOLE_CONFIG,
  W_F_ANALYSIS,
  W_F_UPDATE_IMAGE_TEXT_PERCENTAGE,
  W_F_UPDATE_THREAT_IMAGE_TEXT_PERCENTAGE,
} from "./types";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import { onResponseError } from "./../../../functions/commonFunctions/interceptor";
import {
  default_filter_page,
  default_filter_page_size,
  default_template_page,
  // default_template_page_size,
} from "../../../components/Watch/watchFilter/utils/utils";
import { checkAuthorisation } from "../commonActions/actions";

export function getWatchJournal(dashboard_type = "TM_APPLIED") {
  return async (dispatch) => {
    const layout = window.location.pathname.split("/")[3];
    const urlKey = window.location.pathname.split("/")[2];
    dispatch({ type: W_F_JOURNAL, status: LOADING, layout, urlKey });
    try {
      var params = {
        dashboard_type,
      };

      let response = await Axios.get(`${ROOT_URL}/watch/new/journals/`, {
        params,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: W_F_JOURNAL,
        status: SUCCESS,
        payload: response?.data,
        layout,
        urlKey,
      });
    } catch (error) {
      checkAuthorisation(dispatch, error);
      dispatch({
        type: W_F_JOURNAL,
        status: ERROR,
        payload: error,
        layout,
        urlKey,
      });
    }
  };
}

export function getWatchAllFilters(
  journal_number,
  filter_type,
  search_term = null,
  page = default_filter_page,
  page_size = default_filter_page_size,
  filter_template_id = 0,
  dashboardUrl = "applied/list_layout",
  application_number = 0,
) {
  return async (dispatch) => {
    const layout = window.location.pathname.split("/")[3];
    const urlKey = window.location.pathname.split("/")[2];
    dispatch({
      type: `W_F_${filter_type}`,
      status: LOADING,
      filter_key: filter_type,
      layout,
      urlKey,
    });
    try {
      var params = {
        page,
        page_size,
        // journal_number,
        filter_type,
        search_term,
      };
      if (dashboardUrl.includes("tm_protect")) {
        params.profile_report_id = journal_number;
      } else {
        params.journal_number = journal_number;
      }
      if (filter_template_id && page === 1) {
        params.filter_template_id = filter_template_id;
      }
      if (application_number) {
        if (dashboardUrl.includes("tm_protect")) {
          params.tm_protect_mark_id = application_number;
        } else {
          params.application_number = application_number;
        }
      }
      if (urlKey === "brand_compare") {
        delete params.journal_number;
        params.analysis_id = journal_number;
      }
      let response = await Axios.get(
        `${ROOT_URL}/watch/${dashboardUrl}/filters`,
        {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: `W_F_${filter_type}`,
        status: SUCCESS,
        payload: response?.data,
        filter_key: filter_type,
        page: page,
        layout,
        urlKey,
      });
    } catch (error) {
      onResponseError(error);
      // checkAuthorisation(dispatch, error);
      dispatch({
        type: `W_F_${filter_type}`,
        status: ERROR,
        payload: error,
        filter_key: filter_type,
        layout,
        urlKey,
      });
    }
  };
}

export function changeTemplateId(template_id) {
  return async (dispatch) => {
    dispatch({
      type: W_CHANGE_TEMPLATE_ID,
      template_id: template_id,
    });
  };
}

export function temporarySaveThreatFilters(
  urlKey,
  application_number,
  filters,
) {
  return async (dispatch) => {
    dispatch({
      type: W_TEMPORARY_THREAT_FILTER_SAVE,
      urlKey: urlKey,
      application_number: application_number,
      filters: filters,
    });
  };
}

export function removeTemporaryThreatFilters(urlKey, application_number) {
  return async (dispatch) => {
    dispatch({
      type: W_TEMPORARY_THREAT_FILTER_REMOVE,
      urlKey: urlKey,
      application_number: application_number,
    });
  };
}

export function initiateDefaultTemporaryThreatFilters() {
  return async (dispatch) => {
    dispatch({
      type: W_TEMPORARY_THREAT_FILTER_DEFAULT_VALUE,
    });
  };
}

export function changeListSelectedDropdownValues(filter_key, values) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_SELECTED_VALUES,
      filter_key,
      values,
    });
  };
}

export function changeListDropdownSearchValues(filter_key, searchValue) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_SEARCH_VALUES,
      filter_key,
      searchValue,
    });
  };
}

export function emptyListDropdownSearchValues() {
  return async (dispatch) => {
    dispatch({
      type: W_F_EMPTY_SEARCH_VALUES,
    });
  };
}

export function changeListExcludeDropdownValues(filter_key, values) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_EXCLUDE_VALUES,
      filter_key,
      values,
    });
  };
}

export function updateDateValues(filter_key, values) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_DATE,
      filter_key,
      values,
    });
  };
}

export function updateDateProposedValues(filter_key, values) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_DATE_PROPOSED,
      filter_key,
      values,
    });
  };
}

export function changeSearchType(value) {
  return async (dispatch) => {
    dispatch({
      type: W_F_SEARCH_TYPE,
      value,
    });
  };
}

export function changeSearchValue(value) {
  return async (dispatch) => {
    dispatch({
      type: W_F_SEARCH_VALUE,
      value,
    });
  };
}

export function changeFilterApplied(value) {
  return async (dispatch) => {
    dispatch({
      type: W_F_FILTER_APPLIED,
      value,
    });
  };
}

export function changeFilterAndOr(filter_key, value) {
  return async (dispatch) => {
    dispatch({
      type: W_F_CHANGE_AND_OR,
      filter_key,
      value,
    });
  };
}

export function resetAllFilter() {
  return async (dispatch) => {
    dispatch({
      type: W_F_RESET_ALL,
    });
  };
}

export function getWatchFilterTemplate(
  template_type = USER_TEMPLATE_TYPE,
  template_key = APPLIED_LIST_LAYOUT,
  application_number = 0,
  pageNumber = default_template_page,
) {
  return async (dispatch) => {
    dispatch({ type: W_TEMPLATE, status: LOADING, template_type });
    try {
      var params = {
        template_key,
        template_type,
        page: pageNumber,
        page_size: 100,
      };

      if (application_number) {
        if (template_key.includes("tm_protect")) {
          params.cease_desist_mark_id = application_number;
        } else {
          params.application_number = application_number;
        }
      }

      let response = await Axios.get(`${ROOT_URL}/watch/filter_template/`, {
        params,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: W_TEMPLATE,
        status: SUCCESS,
        payload: response?.data,
        template_type,
        pageNumber,
      });
    } catch (error) {
      checkAuthorisation(dispatch, error);
      dispatch({
        type: W_TEMPLATE,
        status: ERROR,
        payload: error,
        template_type,
        pageNumber,
      });
    }
  };
}

export function createWatchTemplate(
  body,
  template_type = USER_TEMPLATE_TYPE,
  template_key = APPLIED_LIST_LAYOUT,
  pageNumber = default_template_page,
) {
  return async (dispatch) => {
    dispatch({ type: W_CREATE_TEMPLATE, status: LOADING });
    if (template_key.includes("tm_protect")) {
      body.tm_protect_mark_id = body.mark_id;
      delete body.mark_id;
    }
    try {
      let response = await Axios.post(
        `${ROOT_URL}/watch/filter_template/`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: "Template Created",
        duration: 2,
      });
      dispatch({
        type: W_CREATE_TEMPLATE,
        status: SUCCESS,
        payload: response?.data,
      });
      dispatch(
        getWatchFilterTemplate(
          template_type,
          template_key,
          body?.mark_id ? body.mark_id : 0,
          pageNumber,
        ),
      );
    } catch (error) {
      checkAuthorisation(dispatch, error);
      notification["error"]({
        message: error,
      });
      dispatch({
        type: W_CREATE_TEMPLATE,
        status: ERROR,
        payload: error,
      });
    }
  };
}

export function deleteWatchTemplate(
  body,
  template_type = USER_TEMPLATE_TYPE,
  template_key = APPLIED_LIST_LAYOUT,
  application_number = 0,
  pageNumber = default_template_page,
) {
  return async (dispatch) => {
    dispatch({ type: W_DELETE_TEMPLATE, status: LOADING });
    try {
      let response = await Axios.delete(`${ROOT_URL}/watch/filter_template/`, {
        data: body,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      notification["success"]({
        message: "Template Deleted Successfully",
        duration: 2,
      });
      dispatch({
        type: W_DELETE_TEMPLATE,
        status: SUCCESS,
        payload: response?.data,
      });
      dispatch(
        getWatchFilterTemplate(
          template_type,
          template_key,
          application_number ? application_number : 0,
          pageNumber,
        ),
      );
    } catch (error) {
      checkAuthorisation(dispatch, error);
      notification["error"]({
        message: error.payload.detail,
      });
      dispatch({
        type: W_DELETE_TEMPLATE,
        status: ERROR,
        payload: error,
      });
    }
  };
}

export function updateWatchTemplate(
  body,
  template_type = USER_TEMPLATE_TYPE,
  template_key = APPLIED_LIST_LAYOUT,
  pageNumber = default_template_page,
) {
  return async (dispatch) => {
    dispatch({ type: W_UPDATE_TEMPLATE, status: LOADING });
    if (template_key.includes("tm_protect")) {
      body.tm_protect_mark_id = body.mark_id;
      delete body.mark_id;
    }
    try {
      let response = await Axios.put(
        `${ROOT_URL}/watch/filter_template/`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: "Template Updated Successfully",
        duration: 2,
      });
      dispatch({
        type: W_UPDATE_TEMPLATE,
        status: SUCCESS,
        payload: response?.data,
      });
      dispatch(
        getWatchFilterTemplate(
          template_type,
          template_key,
          body?.mark_id ? body.mark_id : 0,
          pageNumber,
        ),
      );
    } catch (error) {
      checkAuthorisation(dispatch, error);
      notification["error"]({
        message: error.payload?.detail,
      });
      dispatch({
        type: W_UPDATE_TEMPLATE,
        status: ERROR,
        payload: error,
      });
    }
  };
}

export function createCustomReport(body) {
  let urlKey = window.location.pathname.split("/")[2] || "";
  return async (dispatch) => {
    dispatch({ type: W_CREATE_REPORT, status: LOADING });
    notification["warning"]({
      message: "Request Under Process",
      description:
        "A request is under process. Kindly, wait for its completion.",
      duration: 2,
    });
    try {
      let reportUrl =
        urlKey === "brand_compare"
          ? `${ROOT_URL}/watch/brand_compare/report/`
          : `${ROOT_URL}/watch/new/report/`;
      let response = await Axios.post(reportUrl, body, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      notification["success"]({
        message: response.data.message,
        duration: 2,
      });
      dispatch({ type: W_CREATE_REPORT, status: SUCCESS });
    } catch (error) {
      notification["error"]({
        message:
          error?.response?.data?.message ??
          "Something went wrong while generating report...",
        description: error?.response?.data?.payload?.detail ?? "",
        duration: 2,
      });
      dispatch({ type: W_CREATE_REPORT, status: ERROR });
    }
  };
}

export function createCombinePdf(body) {
  return async (dispatch) => {
    dispatch({ type: W_COMBINE_PDF, status: LOADING });
    notification["warning"]({
      message: "Request Under Process",
      description:
        "A request is under process. Kindly, wait for its completion.",
      duration: 2,
    });
    try {
      let response = await Axios.put(`${ROOT_URL}/watch/combine_pdf/`, body, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      let pdfName = `${new Date().getTime()}.pdf`;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = pdfName;
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);

      notification["success"]({
        message: "PDF Generated Successfully...",
        duration: 2,
      });
      dispatch({ type: W_COMBINE_PDF, status: SUCCESS });
    } catch (error) {
      if (error?.response?.status === 400) {
        notification["error"]({
          message: "Provide Less records.",
          description: "Number of records have more than 100 threats.",
          duration: 2,
        });
      } else {
        checkAuthorisation(dispatch, error);
        notification["error"]({
          message: "Something went wrong...",
          duration: 2,
        });
      }
      dispatch({ type: W_COMBINE_PDF, status: ERROR });
    }
  };
}

//shortlist
export function updateWatchShortlist({
  source,
  creationOrigin,
  shortlistFlag,
  journalNumber,
  selected,
  filterFlag,
  filters,
  selectAll,
  applicationNumber,
}) {
  let urlKey = window.location.pathname.split("/")[2] || "";
  let body = {};
  if (selectAll) {
    body = {
      source,
      creation_origin: creationOrigin,
      shortlist_flag: shortlistFlag,
      ...(urlKey === "tm_protect"
        ? { cease_desist_report_id: journalNumber }
        : { journal_number: journalNumber }),
      selected_ids: [],
      filter_flag: selectAll,
      filters,
      // application_number: applicationNumber,
    };
  } else {
    body = {
      source,
      creation_origin: creationOrigin,
      shortlist_flag: shortlistFlag,
      ...(urlKey === "tm_protect"
        ? { cease_desist_report_id: journalNumber }
        : { journal_number: journalNumber }),
      selected_ids: selected,
      filter_flag: selected?.length > 0 ? false : filterFlag,
      // application_number: applicationNumber,
    };
  }
  if (applicationNumber) {
    body.application_number = applicationNumber;
  }
  if (creationOrigin === "TM_PROTECT") {
    body.tm_protect_mark_id = applicationNumber;
    body.cease_desist_report_id = journalNumber;
    delete body.application_number;
    delete body.journal_number;
  }
  if (urlKey === "brand_compare") {
    delete body.journalNumber;
    body.analysis_id = journalNumber;
  }
  return async (dispatch) => {
    let shortlistUrl =
      urlKey === "brand_compare"
        ? `${ROOT_URL}/watch/brand_compare/shortlist/`
        : `${ROOT_URL}/watch/shortlist/`;
    try {
      let response = await Axios.put(shortlistUrl, body, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
    } catch (error) {
      checkAuthorisation(dispatch, error);
      notification["error"]({
        message: error,
      });
    }
  };
}

export function updateLastWeekThreats(threatId, count) {
  return async (dispatch) => {
    dispatch({
      type: W_UPDATE_LAST_WEEK_THREATS,
      threatId,
      count,
    });
  };
}

export function updateBooleanFilters(key, value) {
  return async (dispatch) => {
    dispatch({
      type: W_LIST_BOOLEAN_FILTER_UPDATE,
      key,
      value,
    });
  };
}

export function updateNoTags(value) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_no_tags,
      value,
    });
  };
}

export function updateSimilarityConfig(value) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_similarity,
      value,
    });
  };
}

export function updateimageTextPercentageConfig(value) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_IMAGE_TEXT_PERCENTAGE,
      value,
    });
  };
}

export function updatethreatImageTextPercentageConfig(value) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_THREAT_IMAGE_TEXT_PERCENTAGE,
      value,
    });
  };
}

export function updateWholeFilterConfig(newConfig) {
  return async (dispatch) => {
    dispatch({
      type: W_F_UPDATE_WHOLE_CONFIG,
      newConfig,
    });
  };
}

export function getWatchBrandAnalysis() {
  return async (dispatch) => {
    const layout = window.location.pathname.split("/")[3];
    const urlKey = window.location.pathname.split("/")[2];
    dispatch({ type: W_F_ANALYSIS, status: LOADING, layout, urlKey });
    try {
      var params = {
        // dashboard_type,
      };

      let response = await Axios.get(
        `${ROOT_URL}/watch/brand_compare/list_layout/analysis_names/`,
        {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: W_F_ANALYSIS,
        status: SUCCESS,
        payload: response?.data,
        layout,
        urlKey,
      });
    } catch (error) {
      checkAuthorisation(dispatch, error);
      dispatch({
        type: W_F_ANALYSIS,
        status: ERROR,
        payload: error,
        layout,
        urlKey,
      });
    }
  };
}
