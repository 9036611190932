//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Link } from "react-router-dom";

import {
  DownOutlined,
  FolderOpenOutlined,
  GlobalOutlined,
  LogoutOutlined,
  MailOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  SmileOutlined,
  TableOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import {
  Layout,
  Menu,
  Avatar,
  Tooltip,
  Dropdown,
  Button,
  Input,
  notification,
  InputNumber,
  Badge,
  Drawer,
  Radio,
  Checkbox,
  Alert,
  Spin,
  Upload,
} from "antd";
import { debounce, each } from "lodash";
import { FixedSizeList } from "react-window";
import deepFreeze from "deep-freeze";
import Axios from "axios";
import introJs from "intro.js";

//--------IP-SUITE GENERAL FUNCTIONS--------
import {
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../functions/commonFunctions/generalFunctions";

//--------IP-SUITE CONSTANTS--------
import {
  SUCCESS,
  LOADING,
} from "../../constants/commonConstants/generalConstants";

//--------IP-SUITE ACTIONS--------
import {
  logOut,
  getUserSubscriptions,
  getProfile,
  TPAction,
  getAlertMessage,
  SetFileAction,
} from "../../redux/actions/commonActions/actions";

//--------IP-SUITE COMPONENTS--------
import NoMatch from "../commonComponents/NoMatch";

//--------Routes--------
import SearchTips from "./Routes/SearchTips";
import TrademarksSearch, {
  resetTrademarksSearchState,
} from "./Routes/TrademarksSearch";
import ProprietorsSearch, {
  resetProprietorsSearchState,
} from "./Routes/ProprietorsSearch";
import TrademarkProfile from "./Routes/TrademarkProfile";
import Profile from "./Routes/Profile";
import ReportManagement from "./Routes/ReportManagement";
import ReportDownload from "./Routes/ReportDownload";
import ProprietorProfile from "./Routes/ProprietorProfile";
import WhatsNew from "../commonComponents/Drawers/Whats-New";
import EmailTemplate from "../commonComponents/EmailTemplates";
import ProfileDashboard from "../commonComponents/ProfileDetails/ProfileDashboard";

import ImageSearchDashboard from "../Watch/Pages/ImageSearchDashboard/ImageSearch";

//--------IP-SUITE PROFILE-COMPONENTS--------
import UploadProfileLogo from "../commonComponents/ProfileDetails/UploadProfileLogo";

//--------IP-SUITE COMMON COMPONENTS--------
import SurveyModal from "../commonComponents/Modals/SurveyModal";
import ImageRecognition from "../commonComponents/Modals/ImageRecognition";

//--------IP-SUITE STYLE CONSTANTS--------
import {
  ipSuiteMenuStyle,
  searchMenuStyle,
} from "../../constants/commonConstants/styleConstants";

//--------SEARCH SUB-COMPONENTS--------
import TrademarksAlgosInfo from "./subComponents/TrademarksAlgosInfo";

//--------SEARCH ACTIONS--------
import {
  getUserTrademarksInSearch,
  getSearchResults,
  handleSearchInfoDispatch,
  postUserTrademark,
  getTrademarkProfileData,
  getImageSearchResults,
} from "../../redux/actions/searchActions/commonActions";

//--------SEARCH ACTION TYPES--------
import {
  SET_FILE,
  SET_SEARCH_PATH,
  SRCH_GET_REPORT_AVAILABILITY,
} from "../../redux/actions/searchActions/types";

//--------SEARCH CONSTANTS--------
import {
  allClasses,
  refreshPeriod,
} from "../../constants/searchConstants/generalConstants";

//--------SEARCH STYLESHEETS--------
import "../../stylesheets/searchStyleSheets/search.css";

//--------PROPRIETOR SEARCH ACTIONS--------
import { getProprietorsSearch } from "../../redux/actions/searchActions/proprietorsSearchAction";
import { searchPageTours } from "../../constants/searchConstants/searchConstants";
import { getTrademarkReportStatus } from "../../redux/actions/searchActions/trademarksSearchActions";
import { GET_SEARCH_ALERT } from "../../redux/actions/commonActions/types";
import { checkValue } from "../../functions/commonFunctions/checkValueFunctions";
import Faqs from "../managerComponents/user-maual/Faqs";
import Data from "../managerComponents/user-maual/SearchData";
import * as Sentry from "@sentry/browser";
import EmailPreferences from "../managerComponents/profile/EmailPreferences";
import "../../stylesheets/searchStyleSheets/sidebar.css";
import mixpanel from "mixpanel-browser";
import ImgSearchInput from "./subComponents/ImgSearchInput";

import search_anim from "../../assets/lottie/search_anim.json";
import Lottie from "react-lottie";

import MikeTyped from "./subComponents/MikeTyped";
import { ClientMaster } from "../commonComponents/MasterComponents/ClientMaster/ClientMaster";
import ImageSearch from "./Routes/ImageSearch";
const { Sider } = Layout;
const { SubMenu } = Menu;

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      selectedKeys: [window.location.pathname],
      collapsed: false,
      searchTypeOptions: [],
      modalsVisibility: {
        uploadLogo: false,
        trademarksAlgosInfo: false,
        CSSurvey: !(
          document.cookie &&
          document.cookie
            .split("; ")
            .find((row) => row.startsWith("Search_CSSurvey"))
        ),
        suggestions: false,
        complaints: false,
        queriesSurvey: false,
        imageRecognition: true,
      },
      selectedFilters: {
        proprietorsSearchType: "Standard",
        searchType: "",
        trademarksSearchAlgo: "Flash",
        classes: [],
        queries: {
          Fuzzy: null,
          Boolean: "",
          Wildcard: "",
        },
        application: null,
        proprietor: "",
        imageSearch: null,
      },
      filtersVisibility: {
        trademarksSearchAlgos: false,
        classes: false,
        userTrademarks: false,
      },
      filtersSearchTerms: {
        classes: "",
        userTrademarks: "",
      },
      drawersVisibility: {
        whatsNew: false,
        ipIndiaReport: false,
      },
      ipIndiaReportObject: {
        operation: "search",
        flash_flag: false,
        clear: false,
        mark_id: "",
        search_classes: "",
        search_type: "ipIndia",
        report_file_name: "",
        report_logic: [],
        report_download: false,
      },
      imageSearch: {
        fileList: [],
      },
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.onFilterPaginate = this.onFilterPaginate.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.setSearchFile = this.setSearchFile.bind(this);
    this.changeStateValue_L1_Debounced = debounce(
      this.changeStateValue_L1,
      400,
    );
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);

    this.handleConfirmReport_Debounced = debounce(
      this.handleConfirmReport,
      400,
    );
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.userSubscriptions === null) {
        await this.props.getUserSubscriptions();

        if (this.props.userSubscriptionStatus === SUCCESS) {
          this.handleSubscriptionActions(this.props.userSubscriptions);
        }
      } else {
        this.handleSubscriptionActions(this.props.userSubscriptions);
      }
    } else {
      this.props.logOut();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.userDetails?.name !== null && props?.userDetails?.email) {
      localStorage.setItem("userName", props?.userDetails?.name);
      localStorage.setItem("userEmail", props?.userDetails?.email);
      Sentry.setTag("userName", props?.userDetails?.name || "NA");
      Sentry.setTag("userEmail", props?.userDetails?.email || "NA");
    }
  }

  handleSubscriptionActions(subscriptions) {
    let searchTypeOptions = [];
    if (subscriptions.search.length > 0) {
      if (subscriptions.search.includes("MARK")) {
        this.props.getUserTrademarksInSearch({ page: 1 });
        // this.props.getSearchTrademarks();
        searchTypeOptions.push("Trademarks");
        searchTypeOptions.push("Application");
      }
      if (subscriptions.search.includes("PROPRIETOR")) {
        searchTypeOptions.push("Proprietors");
      }
      if (subscriptions.search.includes("IMAGE_SEARCH")) {
        searchTypeOptions.push("Image_Search");
      }

      let currentType = "Trademarks";
      if (window.location.pathname === "/search/image_search") {
        currentType = "Image_Search";
      }
      this.setState({
        searchTypeOptions,
        selectedFilters: {
          ...this.state.selectedFilters,
          searchType: currentType,
        },
      });
      this.props.getProfile();
      this.props.getAlertMessage(GET_SEARCH_ALERT, "search");
    } else if (subscriptions.manager.length > 0 || subscriptions.watch) {
      this.props.history.push("/");
    } else {
      this.props.logOut();
    }
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  async handleSearch() {
    switch (this.state.selectedFilters.searchType) {
      case "Trademarks":
        let userTrademarkInput = checkValue(
          this.state.filtersSearchTerms.userTrademarks,
        )
          ? this.state.filtersSearchTerms.userTrademarks.trim().toLowerCase()
          : "NA";
        if (userTrademarkInput === "NA") {
          notification["warning"]({
            message: "Search input empty",
            description: "Please enter a search term",
          });
          break;
        }
        let selectedTrademark =
          (this.props.userTrademarks &&
            this.props.userTrademarks.find(
              (eachTrademark) =>
                eachTrademark.term.trim().toLowerCase() === userTrademarkInput,
            )) ||
          "";
        let responseStatus = SUCCESS;
        let multiwordTrademark = userTrademarkInput.includes(" ")
          ? true
          : false;

        if (!selectedTrademark) {
          const response =
            await this.props.postUserTrademark(userTrademarkInput);
          responseStatus = response.status;
          if (response.status === SUCCESS) {
            selectedTrademark = response?.data?.user_searched_marks?.data?.find(
              (eachTrademark) =>
                eachTrademark.term.trim().toLowerCase() === userTrademarkInput,
            );
            this.setState({
              selectedFilters: {
                ...this.state.selectedFilters,
                queries: {
                  ...this.state.selectedFilters.queries,
                  Fuzzy: Math.round(
                    (selectedTrademark?.term?.length || 0) * 0.4,
                  ),
                  Boolean: selectedTrademark?.term
                    ? selectedTrademark.term
                        .replace(/[&|!]/g, "")
                        .replace(/ {1,}/g, " ")
                        .trim()
                        .replace(/ /g, "[[&]]")
                    : "",
                  Wildcard: `[[*]]${
                    selectedTrademark?.term
                      ? selectedTrademark.term
                          .replace(/[%_]/g, "")
                          .replace(/ {1,}/g, " ")
                          .trim()
                          .replace(/ /g, "[[*]]")
                      : ""
                  }[[*]]`,
                },
              },
              filtersSearchTerms: {
                ...this.state.filtersSearchTerms,
                userTrademarks: selectedTrademark?.term
                  ? selectedTrademark.term
                  : "",
              },
            });
          } else {
            // notification['error']({
            //     message: 'Error! Unable To Add Trademark',
            //     description: 'The server is unable to add more trademarks. Quota is full please reach out to team@mikelegal.com',
            // });
            break;
          }
        }

        if (
          (this.state.selectedFilters.trademarksSearchAlgo === "Boolean" ||
            this.state.selectedFilters.trademarksSearchAlgo === "Initials") &&
          !multiwordTrademark
        ) {
          notification["warning"]({
            message: "Single Contains Trademark",
            description: "Your trademark must have more than 1 word.",
          });
          break;
        }

        if (
          this.state.selectedFilters.trademarksSearchAlgo === "Boolean" &&
          this.state.selectedFilters.queries.Boolean.replace(/ {1,}/g, " ")
            .replace(/\[\[&\]\]/g, "")
            .replace(/\[\[\|\]\]/g, "")
            .replace(/\[\[!\]\]/g, "")
            .search(/[&|!'"()$%_^]/g) !== -1
        ) {
          notification["warning"]({
            message: "Invalid Boolean Characters",
            description:
              "Your query cannot have & ' \" ( ) $ %_^ | and ! apart from the boolean operators.",
          });
          break;
        }

        if (
          this.state.selectedFilters.trademarksSearchAlgo === "Boolean" &&
          !this.state.selectedFilters.queries.Boolean.replace(/ {1,}/g, " ")
            .replace(/\[\[&\]\]/g, " ")
            .replace(/\[\[\|\]\]/g, " ")
            .replace(/\[\[!\]\]/g, "")
            .trim()
            .includes(" ")
        ) {
          notification["warning"]({
            message: "Single Contains Query",
            description: "Your query must have more than 1 word.",
          });
          break;
        }

        if (
          this.state.selectedFilters.trademarksSearchAlgo === "Fuzzy" &&
          this.state.selectedFilters.queries.Fuzzy &&
          (this.state.selectedFilters.queries.Fuzzy < 2 ||
            this.state.selectedFilters.queries.Fuzzy >
              (selectedTrademark?.term?.length || 0))
        ) {
          notification["warning"]({
            message: "Fuzz Depth Too Low or High",
            description:
              "Your fuzz depth cannot be lower than 2 and higher than the length of the selected trademark.",
          });
          break;
        }

        if (responseStatus === SUCCESS) {
          if (this.state.selectedFilters.trademarksSearchAlgo !== "IP India") {
            this.props.TPAction(SET_SEARCH_PATH, "/search/trademarks-search");
          }
          if (window.location.pathname === "/search/trademarks-search") {
            resetTrademarksSearchState();
          }

          // Object.keys(this.props.axiosCancelSearches).map(function () {
          //   return "Search Requests Cancelled!!!";
          // });
          Object.keys(this.props.axiosCancelSearches).forEach((key) => {
            this.props.axiosCancelSearches[key]();
          });
          // Object.keys(this.props.axiosCancelAnalysis).map(function () {
          //   return "Analysis Requests Cancelled!!!";
          // });
          Object.keys(this.props.axiosCancelAnalysis).forEach((key) => {
            this.props.axiosCancelAnalysis[key]();
          });
          if (this.props.axiosCancelFilters) {
            this.props.axiosCancelFilters("Filters Request Cancelled");
          }

          let fuzzyCancelToken = Axios.CancelToken;
          let fuzzyAxiosSource = fuzzyCancelToken.source();

          let wildcardCancelToken = Axios.CancelToken;
          let wildcardAxiosSource = wildcardCancelToken.source();

          let standardCancelToken = Axios.CancelToken;
          let standardAxiosSource = standardCancelToken.source();

          let initialsCancelToken = Axios.CancelToken;
          let initialsAxiosSource = initialsCancelToken.source();

          let phoneticCancelToken = Axios.CancelToken;
          let phoneticAxiosSource = phoneticCancelToken.source();

          let booleanCancelToken = Axios.CancelToken;
          let booleanAxiosSource = booleanCancelToken.source();

          let containsCancelToken = Axios.CancelToken;
          let containsAxiosSource = containsCancelToken.source();

          switch (this.state.selectedFilters.trademarksSearchAlgo) {
            case "Flash":
              this.props.handleSearchInfoDispatch({
                trademarksSearchAlgo:
                  this.state.selectedFilters.trademarksSearchAlgo,
                activeTab: "Fuzzy",
                userTrademark: selectedTrademark,
                multiwordTrademark,
                axiosCancelSearches: {
                  Fuzzy: fuzzyAxiosSource.cancel,
                  Wildcard: wildcardAxiosSource.cancel,
                  Standard: standardAxiosSource.cancel,
                  Initials: initialsAxiosSource.cancel,
                  Phonetic: phoneticAxiosSource.cancel,
                  Boolean: booleanAxiosSource.cancel,
                  Contains: containsAxiosSource.cancel,
                },
                searchCounter: multiwordTrademark ? 7 : 5,
                reminderStamp: new Date().getTime() + refreshPeriod,
                searchStamp: new Date().getTime() + refreshPeriod,
              });

              if (multiwordTrademark) {
                this.props.getSearchResults(
                  {
                    operation: "search",
                    flash_flag: true,
                    mark_id: selectedTrademark.id,
                    search_classes: `${this.state.selectedFilters.classes}`,
                    page: 1,
                    search_type: "boolean",
                    query: selectedTrademark?.term
                      ? selectedTrademark.term
                          .replace(/[&|!'"()$%_^]/g, "")
                          .replace(/ {1,}/g, " ")
                          .trim()
                          .replace(/ /g, "[[&]]")
                      : "",
                  },
                  "Boolean",
                  booleanAxiosSource.token,
                  true,
                );

                this.props.getSearchResults(
                  {
                    operation: "search",
                    flash_flag: true,
                    mark_id: selectedTrademark.id,
                    search_classes: `${this.state.selectedFilters.classes}`,
                    page: 1,
                    search_type: "initials",
                    query: selectedTrademark?.term
                      ? selectedTrademark.term
                          .split(" ")
                          .map((a) => a[0])
                          .join("")
                          .replace(/[&|!%_]/g, "")
                      : "",
                  },
                  "Initials",
                  initialsAxiosSource.token,
                  true,
                );
              }

              this.props.getSearchResults(
                {
                  operation: "search",
                  flash_flag: true,
                  mark_id: selectedTrademark.id,
                  search_classes: `${this.state.selectedFilters.classes}`,
                  page: 1,
                  search_type: "fuzzy",
                  fuzzy_depth: Math.round(
                    (selectedTrademark?.term.trim().length || 0) * 0.4,
                  ),
                },
                "Fuzzy",
                fuzzyAxiosSource.token,
                true,
              );

              this.props.getSearchResults(
                {
                  operation: "search",
                  flash_flag: true,
                  mark_id: selectedTrademark.id,
                  search_classes: `${this.state.selectedFilters.classes}`,
                  page: 1,
                  search_type: "wildcard",
                  query: `[[*]]${
                    selectedTrademark?.term
                      ? selectedTrademark.term
                          .replace(/[&|!'"()$%_^]/g, "")
                          .replace(/ {1,}/g, " ")
                          .trim()
                          .replace(/ /g, "[[*]]")
                      : ""
                  }[[*]]`,
                },
                "Wildcard",
                wildcardAxiosSource.token,
                true,
              );

              this.props.getSearchResults(
                {
                  operation: "search",
                  flash_flag: true,
                  mark_id: selectedTrademark.id,
                  search_classes: `${this.state.selectedFilters.classes}`,
                  page: 1,
                  search_type: "standard",
                  query: selectedTrademark?.term
                    ? selectedTrademark.term.trim().toLowerCase()
                    : "",
                },
                "Standard",
                standardAxiosSource.token,
                true,
              );

              this.props.getSearchResults(
                {
                  operation: "search",
                  flash_flag: true,
                  mark_id: selectedTrademark.id,
                  search_classes: `${this.state.selectedFilters.classes}`,
                  page: 1,
                  search_type: "contains",
                  query: selectedTrademark?.term
                    ? selectedTrademark.term
                        .replace(/[&:|!'"()$%_^]/g, "")
                        .replace(/ {1,}/g, " ")
                        .trim()
                    : "",
                },
                "Contains",
                containsAxiosSource.token,
                true,
              );

              this.props.getSearchResults(
                {
                  operation: "search",
                  flash_flag: true,
                  mark_id: selectedTrademark.id,
                  search_classes: `${this.state.selectedFilters.classes}`,
                  page: 1,
                  search_type: "phonetic",
                  query: selectedTrademark?.term
                    ? selectedTrademark.term
                        .replace(/[^a-zA-Z0-9 ]/g, "")
                        .replace(/ {1,}/g, " ")
                        .trim()
                    : "",
                },
                "Phonetic",
                phoneticAxiosSource.token,
                true,
              );
              break;

            case "Wildcard":
              this.props.handleSearchInfoDispatch({
                trademarksSearchAlgo:
                  this.state.selectedFilters.trademarksSearchAlgo,
                activeTab: "Wildcard",
                userTrademark: selectedTrademark,
                multiwordTrademark,
                axiosCancelSearches: {
                  Wildcard: wildcardAxiosSource.cancel,
                },
                searchCounter: 1,
                reminderStamp: new Date().getTime() + refreshPeriod,
                searchStamp: new Date().getTime() + refreshPeriod,
              });

              this.props.getSearchResults(
                {
                  operation: "search",
                  flash_flag: false,
                  mark_id: selectedTrademark.id,
                  search_classes: `${this.state.selectedFilters.classes}`,
                  page: 1,
                  search_type: "wildcard",
                  query: this.state.selectedFilters.queries.Wildcard.replace(
                    /[&|!'"()$%_^]/g,
                    "",
                  )
                    .replace(/ {1,}/g, " ")
                    .trim(),
                },
                "Wildcard",
                wildcardAxiosSource.token,
                true,
              );
              break;

            case "Initials":
              this.props.handleSearchInfoDispatch({
                trademarksSearchAlgo:
                  this.state.selectedFilters.trademarksSearchAlgo,
                activeTab: "Initials",
                userTrademark: selectedTrademark,
                multiwordTrademark: true,
                axiosCancelSearches: {
                  Initials: initialsAxiosSource.cancel,
                },
                searchCounter: 1,
                reminderStamp: new Date().getTime() + refreshPeriod,
                searchStamp: new Date().getTime() + refreshPeriod,
              });

              this.props.getSearchResults(
                {
                  operation: "search",
                  flash_flag: false,
                  mark_id: selectedTrademark.id,
                  search_classes: `${this.state.selectedFilters.classes}`,
                  page: 1,
                  search_type: "initials",
                  query: selectedTrademark?.term
                    ? selectedTrademark.term
                        .split(" ")
                        .map((a) => a[0])
                        .join("")
                        .replace(/[&|!%_]/g, "")
                    : "",
                },
                "Initials",
                initialsAxiosSource.token,
                true,
              );

              break;

            case "Fuzzy":
              if (/^\d+$/.test(this.state.selectedFilters.queries.Fuzzy)) {
                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo:
                    this.state.selectedFilters.trademarksSearchAlgo,
                  activeTab: "Fuzzy",
                  userTrademark: selectedTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: {
                    Fuzzy: fuzzyAxiosSource.cancel,
                  },
                  searchCounter: 1,
                  reminderStamp: new Date().getTime() + refreshPeriod,
                  searchStamp: new Date().getTime() + refreshPeriod,
                });

                this.props.getSearchResults(
                  {
                    operation: "search",
                    flash_flag: false,
                    mark_id: selectedTrademark.id,
                    search_classes: `${this.state.selectedFilters.classes}`,
                    page: 1,
                    search_type: "fuzzy",
                    fuzzy_depth: this.state.selectedFilters.queries.Fuzzy,
                  },
                  "Fuzzy",
                  fuzzyAxiosSource.token,
                  true,
                );
              } else {
                notification["warning"]({
                  message: "Not An Integer",
                  description: "Fuzz depth can only be an integer.",
                });
              }
              break;

            case "Boolean":
              this.props.handleSearchInfoDispatch({
                trademarksSearchAlgo:
                  this.state.selectedFilters.trademarksSearchAlgo,
                activeTab: "Boolean",
                userTrademark: selectedTrademark,
                multiwordTrademark: true,
                axiosCancelSearches: {
                  Boolean: booleanAxiosSource.cancel,
                },
                searchCounter: 1,
                reminderStamp: new Date().getTime() + refreshPeriod,
                searchStamp: new Date().getTime() + refreshPeriod,
              });

              this.props.getSearchResults(
                {
                  operation: "search",
                  flash_flag: false,
                  mark_id: selectedTrademark.id,
                  search_classes: `${this.state.selectedFilters.classes}`,
                  page: 1,
                  search_type: "boolean",
                  query: this.state.selectedFilters.queries.Boolean.replace(
                    / {1,}/g,
                    " ",
                  ).trim(),
                },
                "Boolean",
                booleanAxiosSource.token,
                true,
              );
              break;

            case "IP India":
              if (
                this.state.selectedFilters.classes.length > 5 ||
                this.state.selectedFilters.classes.length === 0
              ) {
                notification["warning"]({
                  message: "IP India Search! 0 < Classes <= 5",
                  description:
                    'While doing "IP India" search, a minimum of 1 class must be selected and a maximum of 5 classes can be selected.',
                });
                break;
              }

              this.props.getTrademarkReportStatus(
                {
                  check: true,
                  report_source: "IPINDIA",
                  mark_id: selectedTrademark.id,
                },
                SRCH_GET_REPORT_AVAILABILITY,
              );

              this.setState({
                drawersVisibility: {
                  ...this.state.drawersVisibility,
                  ipIndiaReport: true,
                },
                ipIndiaReportObject: {
                  operation: "search",
                  flash_flag: false,
                  clear: false,
                  mark_id: selectedTrademark.id,
                  search_classes: `${this.state.selectedFilters.classes}`,
                  search_type: "ipIndia",
                  report_file_name: "",
                  report_logic: [],
                  report_download: false,
                },
              });
              break;

            default:
              break;
          }

          if (this.state.selectedFilters.trademarksSearchAlgo !== "IP India") {
            this.props.history.push("/search/trademarks-search");
          }
        } else {
        }

        break;

      case "Proprietors":
        if (this.state.selectedFilters.proprietor.length > 0) {
          if (this.props.proprietorsSearchStatus === LOADING) {
            notification["warning"]({
              message: "Request Under Process",
              description:
                "A request is under process. Kindly, wait for its completion.",
            });
          } else {
            this.props.TPAction(SET_SEARCH_PATH, "/search/proprietors-search");
            if (window.location.pathname === "/search/proprietors-search") {
              resetProprietorsSearchState();
            }
            this.props.getProprietorsSearch({
              search_type: this.state.selectedFilters.proprietorsSearchType,
              query:
                this.state.selectedFilters.proprietorsSearchType === "Contains"
                  ? this.state.selectedFilters.proprietor
                      .toLowerCase()
                      .replace(/ {1,}/g, " ")
                      .trim()
                  : this.state.selectedFilters.proprietor
                      .toLowerCase()
                      .replace(/[&|!'"()$%_^]/g, "")
                      .replace(/ {1,}/g, " ")
                      .trim(),
              page: 1,
            });
            this.props.history.push("/search/proprietors-search");
          }
        } else {
          notification["warning"]({
            message: "Search input empty",
            description: "Please enter a search term",
          });
        }
        break;

      case "Application":
        if (/^\d+$/.test(this.state.selectedFilters.application)) {
          this.props.TPAction(SET_SEARCH_PATH, "/search/application-search");

          const trademarkCheck = await this.props.getTrademarkProfileData(
            true,
            this.state.selectedFilters.application,
            false,
          );
          if (trademarkCheck.status === SUCCESS) {
            this.props.getTrademarkProfileData(
              false,
              this.state.selectedFilters.application,
              false,
            );
            this.props.getTrademarkProfileData(
              false,
              this.state.selectedFilters.application,
              true,
            );
            this.props.history.push("/search/application-search");
          }
        } else if (
          this.state.selectedFilters.application === null ||
          this.state.selectedFilters.application === ""
        ) {
          notification["warning"]({
            message: "Search input empty",
            description: "Please enter a search term",
          });
        } else {
          notification["warning"]({
            message: "Not An Integer",
            description: "Application number can consist of only numbers.",
          });
        }
        break;
      case "Image_Search":
        if (this.state.imageSearch?.fileList.length) {
          this.props.TPAction(SET_SEARCH_PATH, "/search/image_search");
          let imageSearchCancelToken = Axios.CancelToken;
          let imageSearchAxiosSource = imageSearchCancelToken.source();

          this.props.handleSearchInfoDispatch({
            trademarksSearchAlgo: "none",
            activeTab: "ImageSearch",
            userTrademark: "none",
            multiwordTrademark: false,
            axiosCancelSearches: {
              ImageSearch: imageSearchAxiosSource.cancel,
            },
            searchCounter: 1,
            reminderStamp: new Date().getTime() + refreshPeriod,
            searchStamp: new Date().getTime() + refreshPeriod,
          });
          this.props.getImageSearchResults(
            {
              operation: "search",
              search_classes: `${this.state.selectedFilters.classes}`,
              page: 1,
              image: this.state.imageSearch?.fileList[0],
            },
            "ImageSearch",
            true,
          );
          this.props.SetFileAction(
            SET_FILE,
            JSON.stringify(this.state.imageSearch?.fileList[0]),
          );
          this.props.history.push("/search/image_search");
        } else {
          notification["warning"]({
            message: "Image Not Uploaded",
            description: "Please upload an Image to get results.",
          });
        }
        break;

      default:
        break;
    }
  }

  handleConfirmReport() {
    if (this.state.ipIndiaReportObject.report_logic.length === 0) {
      notification["warning"]({
        message: "No Report Type Selected",
        description:
          "At least one report type must be selected in order to do this operation.",
      });
      return;
    }

    let CancelToken = Axios.CancelToken;
    let axiosSource = CancelToken.source();

    this.props.getSearchResults(
      {
        ...this.state.ipIndiaReportObject,
        report_logic: `${this.state.ipIndiaReportObject.report_logic}`,
      },
      "ipIndia",
      axiosSource.token,
      false,
    );

    this.changeStateValue_L1("drawersVisibility", "ipIndiaReport", false);
  }

  handleMenuClick(e) {
    this.setState({ selectedKeys: [e.key] });
  }
  setSearchFile(fileList) {
    this.setState({
      imageSearch: { fileList: fileList },
    });
    // this.props.TPAction("SET_FILE",fileList);
  }

  onFilterPaginate() {
    if (this.props.userTrademarksStatus !== "LOADING") {
      const page_size = 100;
      let total_pages = 0;
      let page = this.props.userTrademarksPage + 1;
      let count = 0;
      count = this.props.userTrademarksCount;

      if (count !== undefined && count !== null && count > 0) {
        total_pages = Math.ceil(count / page_size);
      }
      if (
        page <= total_pages &&
        this.props.userTrademarksStatus !== "LOADING"
      ) {
        this.props.getUserTrademarksInSearch({
          filter_contains_term: this.state.filtersSearchTerms?.userTrademarks,
          page: page,
        });
      }
    }
  }

  debouncedSearch = debounce(async (searchInput) => {
    await this.props.getUserTrademarksInSearch({
      filter_contains_term: searchInput,
      page: 1,
    });
  }, 300);

  handleSearchInput = (e) => {
    this.debouncedSearch(e.target.value);
  };

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  componentWillUnmount() {
    this.debouncedSearch.cancel();
  }

  render() {
    if (
      this.props.userSubscriptionStatus === "LOADING" ||
      this.props.userSubscriptionStatus === null
    ) {
      return (
        <div className="loading-spin">
          <Spin />
        </div>
      );
    }
    const headerButton = {
      margin: "0px 5px 0px 0px",
      boxShadow: "none",
      border: "none",
      padding: "0px 5px",
      fontSize: "16px",
      color: "#929292",
      height:
        this.state.selectedFilters.searchType === "Image_Search"
          ? "128px"
          : "inherit",
    };

    const headerButtonSelected = { ...headerButton, color: "#4285f4" };

    const classesOptions = allClasses?.filter((eachClass) =>
      `${eachClass}`.includes(`${this.state.filtersSearchTerms.classes}`),
    );
    const classesMenu = (
      <>
        <Input
          placeholder="Search classes"
          onChange={(event) =>
            this.changeStateValue_L1_Debounced(
              "filtersSearchTerms",
              "classes",
              event.target.value,
            )
          }
          style={{ width: "200px", fontSize: "16px" }}
        />
        <Menu
          selectable={true}
          multiple={true}
          selectedKeys={this.state.selectedFilters.classes}
          onSelect={(event) =>
            this.changeStateValue_L1(
              "selectedFilters",
              "classes",
              event.selectedKeys,
            )
          }
          onDeselect={(event) =>
            this.changeStateValue_L1(
              "selectedFilters",
              "classes",
              event.selectedKeys,
            )
          }
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {classesOptions.map((eachClass) => (
            <Menu.Item key={eachClass} style={{ fontSize: "16px" }}>
              {eachClass}
            </Menu.Item>
          ))}
        </Menu>
      </>
    );

    const algos = [
      "Flash",
      "Wildcard",
      "Initials",
      "Fuzzy",
      "Boolean",
      "IP India",
    ];
    const algosMenu = (
      <>
        <Menu
          selectable={true}
          selectedKeys={[this.state.selectedFilters.trademarksSearchAlgo]}
          onClick={({ key }) =>
            this.changeStateValue_L1(
              "selectedFilters",
              "trademarksSearchAlgo",
              key,
            )
          }
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {algos.map((e) => (
            <Menu.Item key={e} style={{ fontSize: "16px" }}>
              {e}
            </Menu.Item>
          ))}
        </Menu>
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <Tooltip
            placement="right"
            open={this.state.filtersVisibility.trademarksSearchAlgos}
            title="What are these search types?"
          >
            <span
              style={{
                padding: "10px",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                boxShadow: "0px 0px 5px #4285f4",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({
                  filtersVisibility: {
                    ...this.state.filtersVisibility,
                    trademarksSearchAlgos: false,
                  },
                  modalsVisibility: {
                    ...this.state.modalsVisibility,
                    trademarksAlgosInfo: true,
                  },
                })
              }
            >
              <QuestionCircleFilled
                style={{
                  fontSize: "20px",
                  verticalAlign: "0px",
                  color: "#4285f4",
                }}
              />
            </span>
          </Tooltip>
        </div>
      </>
    );

    const searchTypesMenu = (
      <>
        <Menu
          selectable={true}
          selectedKeys={[this.state.selectedFilters.searchType]}
          onClick={({ key }) => {
            this.changeStateValue_L1("selectedFilters", "searchType", key);
          }}
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {this.state.searchTypeOptions.map((e) => (
            <Menu.Item key={e} style={{ fontSize: "16px" }}>
              {e === "Image_Search" ? "Image Search" : e}
            </Menu.Item>
          ))}
        </Menu>
      </>
    );

    const proprietorsSearchTypes = [
      "Standard",
      "Name",
      "Address",
      "Trading As",
      "Type Details",
      "Contains",
    ];
    const proprietorsSearchTypesMenu = (
      <>
        <Menu
          selectable={true}
          selectedKeys={[this.state.selectedFilters.proprietorsSearchType]}
          onClick={({ key }) =>
            this.changeStateValue_L1(
              "selectedFilters",
              "proprietorsSearchType",
              key,
            )
          }
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {proprietorsSearchTypes.map((e) => (
            <Menu.Item key={e} style={{ fontSize: "16px" }}>
              {e}
            </Menu.Item>
          ))}
        </Menu>
      </>
    );

    let userTrademarksMenu = (
      <Menu>
        <Menu.Item>No trademarks found</Menu.Item>
      </Menu>
    );

    if (this.props.userTrademarks.length > 0) {
      userTrademarksMenu = (
        <>
          <Menu
            style={{
              position: "initial",
              height: "150px",
              overflow: "scroll",
              width: "450px",
            }}
            selectable
            onScroll={(event) => {
              const { scrollTop, scrollHeight, clientHeight } = event.target;
              if (scrollTop + clientHeight >= scrollHeight - 20) {
                this.onFilterPaginate();
              }
            }}
          >
            {this.props.userTrademarks?.map((eachCategory) => (
              <Menu.Item
                key={eachCategory.id}
                className={`ant-dropdown-menu-item`}
                onClick={() => {
                  this.setState({
                    selectedFilters: {
                      ...this.state.selectedFilters,
                      queries: {
                        ...this.state.selectedFilters.queries,
                        Fuzzy: Math.round(eachCategory.term.length * 0.4),
                        Boolean: eachCategory.term
                          .replace(/[&|!'"()$%_^]/g, "")
                          .replace(/ {1,}/g, " ")
                          .trim()
                          .replace(/ /g, "[[&]]"),
                        Wildcard: `[[*]]${eachCategory.term
                          .replace(/[&|!'"()$%_^]/g, "")
                          .replace(/ {1,}/g, " ")
                          .trim()
                          .replace(/ /g, "[[*]]")}[[*]]`,
                      },
                    },
                    filtersSearchTerms: {
                      ...this.state.filtersSearchTerms,
                      userTrademarks: eachCategory.term,
                    },
                  });
                  this.changeStateValue_L1_Debounced(
                    "filtersVisibility",
                    "userTrademarks",
                    false,
                  );
                }}
              >
                {eachCategory.term}
              </Menu.Item>
            ))}
          </Menu>
        </>
      );
    }

    const ImageUploader = (
      <span>
        <ImgSearchInput
          setSearchFile={this.setSearchFile}
          imgFileList={this.props.ImageSearch?.fileList}
        />
      </span>
    );

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: search_anim,
      // rendererSettings: {
      //   preserveAspectRatio: "xMidYMid slice"
      // }
    };

    return (
      <>
        <Layout className="Search">
          <Sider
            className="Sidebar"
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            <div
              className="Mikelegal"
              onClick={() => this.props.history.push("/")}
            >
              {this.state.collapsed ? "M" : "MikeLegal"}
            </div>
            {this.props.userProfile && (
              <div
                style={{
                  display: `${this.state.collapsed ? "none" : ""}`,
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                <Tooltip
                  placement="right"
                  title="Click here to Upload/Change logo"
                >
                  <Avatar
                    size={80}
                    shape="circle"
                    style={{ margin: "10px" }}
                    className="avatar-logo"
                    onClick={() =>
                      this.changeStateValue_L1(
                        "modalsVisibility",
                        "uploadLogo",
                        true,
                      )
                    }
                  >
                    {this.props.userProfile.picture ? (
                      <img
                        src={this.props.userProfile.picture}
                        style={{ width: "100%" }}
                        alt="User"
                      />
                    ) : (
                      <UserOutlined />
                    )}
                  </Avatar>
                </Tooltip>
                <div className="Navbar-Profile-Details">
                  {this.props.userProfile.name}
                </div>
                <div className="Navbar-Profile-Details">
                  {this.props.userProfile.email}
                </div>
              </div>
            )}
            <Menu
              theme="dark"
              mode="vertical"
              selectedKeys={this.state.selectedKeys}
              onClick={this.handleMenuClick}
              style={searchMenuStyle}
            >
              {/* <Menu.Item key="2" onClick={() => this.props.history.push("/watch/dashboard")}>
                              <Icon type="desktop" />
                              <span>Dashboard</span>
                          </Menu.Item> */}
              <Menu.Item
                icon={<SearchOutlined />}
                key="/search"
                onClick={() => {
                  this.props.history.push(this.props.searchPath);
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
              >
                <span>Search</span>
              </Menu.Item>

              <Menu.Item
                icon={<UserOutlined />}
                key="/search/profile"
                onClick={() => {
                  this.props.history.push("/search/profile");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
              >
                <span>Profile</span>
              </Menu.Item>
              <SubMenu
                key="reports"
                title={
                  <span>
                    <FolderOpenOutlined />
                    <span>Reports</span>
                  </span>
                }
              >
                <Menu.Item
                  key="/search/reports-management"
                  onClick={() => {
                    this.props.history.push("/search/reports-management");
                    this.changeStateValue_L1(
                      "drawersVisibility",
                      "whatsNew",
                      false,
                    );
                  }}
                >
                  Report Management
                </Menu.Item>
                <Menu.Item
                  key="/search/reports-download"
                  onClick={() => {
                    this.props.history.push("/search/reports-download");
                    this.changeStateValue_L1(
                      "drawersVisibility",
                      "whatsNew",
                      false,
                    );
                  }}
                >
                  Report Download
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                icon={<MailOutlined />}
                key="/search/email-template"
                onClick={() => {
                  this.props.history.push("/search/email-template");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
                className="ipsuite-menu-item"
              >
                <span>Email Template</span>
              </Menu.Item>
              <Menu.Item
                icon={<SettingOutlined />}
                key="/search/email-preferences"
                onClick={() => {
                  this.props.history.push("/search/email-preferences");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
                className="ipsuite-menu-item"
              >
                <span>Email Preferences</span>
              </Menu.Item>
              <Menu.Item
                icon={<UsergroupAddOutlined />}
                key="/search/client-master"
                onClick={() => {
                  this.props.history.push("/search/client-master");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
                className="ipsuite-menu-item"
              >
                <span>Client Master</span>
              </Menu.Item>
              <Menu.Item
                icon={
                  <GlobalOutlined
                    style={{
                      color:
                        this.state.selectedKeys[0] === "/search/whats-new"
                          ? "#fff"
                          : "#bebebe",
                    }}
                  />
                }
                key="/search/whats-new"
                onClick={() => {
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    true,
                  );
                  document.cookie = "whatsNewSearch=false";
                }}
              >
                <Badge
                  dot={this.state.collapsed}
                  count={
                    document.cookie
                      .split("; ")
                      .find((row) => row.startsWith("whatsNewSearch"))
                      ? "0"
                      : "New"
                  }
                  style={{
                    top: `${this.state.collapsed ? "10px" : "7px"}`,
                    right: `${this.state.collapsed ? "-10px" : "-30px"}`,
                  }}
                >
                  <span
                    style={{
                      color:
                        this.state.selectedKeys[0] === "/search/whats-new"
                          ? "#fff"
                          : "#bebebe",
                    }}
                  >
                    What's New
                  </span>
                </Badge>
              </Menu.Item>

              <Menu.Item
                icon={<QuestionCircleOutlined />}
                key="/search/faqs"
                className="ipsuite-menu-item"
                onClick={() => {
                  this.props.history.push("/search/faqs");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
              >
                <span>FAQs</span>
              </Menu.Item>

              {searchPageTours &&
                searchPageTours.includes(window.location.pathname) && (
                  <Menu.Item
                    icon={<QuestionCircleOutlined />}
                    key="pagTour"
                    onClick={() => {
                      introJs().start();
                    }}
                  >
                    <span>Page Tour</span>
                  </Menu.Item>
                )}
              <SubMenu
                icon={<SmileOutlined />}
                key="feedbacks"
                title={
                  <span>
                    <span>Feedback</span>
                  </span>
                }
              >
                <Menu.Item
                  key="customer-satisfaction"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "CSSurvey",
                      true,
                    )
                  }
                >
                  Customer Satisfaction
                </Menu.Item>
                <Menu.Item
                  key="suggestions"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "suggestions",
                      true,
                    )
                  }
                >
                  Suggestions
                </Menu.Item>
                <Menu.Item
                  key="complaints"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "complaints",
                      true,
                    )
                  }
                >
                  Complaints
                </Menu.Item>
                <Menu.Item
                  key="queries-survey"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "queriesSurvey",
                      true,
                    )
                  }
                >
                  Queries
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="logout" onClick={this.props.logOut}>
                <LogoutOutlined />
                <span>Log Out</span>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout>
            {this.props.searchAlert.length > 0 && (
              <Alert
                type="error"
                message={
                  <div
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{ __html: this.props.searchAlert }}
                  ></div>
                }
              />
            )}
            {window.location.pathname === "/search/profile" ||
            window.location.pathname === "/search/reports-management" ||
            window.location.pathname === "/search/reports-download" ||
            window.location.pathname === "/search/email-template" ||
            window.location.pathname === "/search/email-preferences" ||
            window.location.pathname === "/search/client-master" ||
            window.location.pathname === "/search/faqs" ? (
              <></>
            ) : (
              <>
                <div
                  style={{
                    // display: "table",
                    width: "100%",
                    padding:
                      window.location.pathname === "/search/search-tips"
                        ? "64px"
                        : "16px",
                    textAlign: "center",
                    minHeight: `${
                      window.location.pathname === "/search/search-tips"
                        ? "32vh"
                        : "0px"
                    }`,
                    backgroundColor: `${
                      window.location.pathname === "/search/search-tips"
                        ? "#4285f429"
                        : "#f5f5f5"
                    }`,
                  }}
                >
                  {window.location.pathname === "/search/search-tips" && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <div style={{ fontSize: "2rem", fontWeight: "bold" }}>
                            <MikeTyped text={["Future of Trademarks Search"]} />

                            {/* <strong>Future Of Trademarks Search</strong> */}
                          </div>
                          <div style={{ color: "grey", fontSize: "18px" }}>
                            <p>
                              Unlock Your Brand's Potential: Advanced Search for
                              Text, Applications, Proprietors & Visual
                              Trademarks
                            </p>
                          </div>
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                          <Lottie
                            options={defaultOptions}
                            height={320}
                            width={320}
                            style={{ margin: "-120px auto" }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <header
                  className={
                    this.state.selectedFilters.searchType === "Image_Search"
                      ? "Search-Bar-Big"
                      : "Search-Bar"
                  }
                  style={{
                    bottom:
                      window.location.pathname === "/search/search-tips"
                        ? "42px"
                        : "20px",
                  }}
                >
                  <Dropdown overlay={searchTypesMenu}>
                    <Button style={headerButtonSelected}>
                      {this.state.selectedFilters.searchType === "Image_Search"
                        ? "Image Search"
                        : this.state.selectedFilters.searchType}
                      <DownOutlined style={{ fontSize: "12px" }} />
                    </Button>
                  </Dropdown>
                  {this.state.selectedFilters.searchType === "Trademarks" && (
                    <>
                      <Dropdown
                        dropdownRender={() => userTrademarksMenu}
                        open={this.state.open === "userTrademarks"}
                        autoAdjustOverflow
                        onOpenChange={(flag) => {
                          this.changeStateValue_L1_Debounced(
                            "filtersVisibility",
                            "userTrademarks",
                            flag,
                          );
                          this.handleOpenChange(flag, "userTrademarks");
                        }}
                      >
                        <Input
                          // suffix={
                          //   this.props.userTrademarksStatus === "LOADING" ? (
                          //     <Spin indicator={<LoadingOutlined spin />} />
                          //   ) : null
                          // }
                          placeholder="Select trademark"
                          onFocus={() =>
                            this.changeStateValue_L1(
                              "filtersVisibility",
                              "userTrademarks",
                              true,
                            )
                          }
                          onBlur={() =>
                            this.changeStateValue_L1_Debounced(
                              "filtersVisibility",
                              "userTrademarks",
                              false,
                            )
                          }
                          value={this.state.filtersSearchTerms.userTrademarks}
                          onChange={(event) => {
                            if (
                              !event.target.value.includes("  ") &&
                              !event.target.value.includes("#") &&
                              !event.target.value.includes("\\") &&
                              !event.target.value.includes("/")
                            ) {
                              this.changeStateValue_L1(
                                "filtersSearchTerms",
                                "userTrademarks",
                                event.target.value,
                              );
                              this.handleSearchInput(event);
                              this.setState({
                                selectedFilters: {
                                  ...this.state.selectedFilters,
                                  queries: {
                                    ...this.state.selectedFilters.queries,
                                    Fuzzy: Math.round(
                                      event.target.value.length * 0.4,
                                    ),
                                    Boolean: event.target.value
                                      .replace(/[&|!'"()$%_^]/g, "")
                                      .replace(/ {1,}/g, " ")
                                      .trim()
                                      .replace(/ /g, "[[&]]"),
                                    Wildcard: `[[*]]${event.target.value
                                      .replace(/[&|!'"()$%_^]/g, "")
                                      .replace(/ {1,}/g, " ")
                                      .trim()
                                      .replace(/ /g, "[[*]]")}[[*]]`,
                                  },
                                },
                                filtersSearchTerms: {
                                  ...this.state.filtersSearchTerms,
                                  userTrademarks: event.target.value,
                                },
                              });
                            }
                          }}
                          style={{
                            width: "16vw",
                            border: "none",
                            margin: "0px 3px 0px 0px",
                            fontSize: "18px",
                            height:
                              this.state.selectedFilters.searchType ===
                              "Image_Search"
                                ? "116px"
                                : "inherit",
                          }}
                        />
                      </Dropdown>
                      <Dropdown
                        overlay={classesMenu}
                        open={this.state.filtersVisibility.classes}
                        onVisibleChange={(event) =>
                          this.changeStateValue_L1(
                            "filtersVisibility",
                            "classes",
                            event,
                          )
                        }
                      >
                        <Button
                          style={
                            this.state.selectedFilters.classes.length > 0
                              ? headerButtonSelected
                              : headerButton
                          }
                        >
                          Classes
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                      <Dropdown
                        overlay={algosMenu}
                        open={
                          this.state.filtersVisibility.trademarksSearchAlgos
                        }
                        onVisibleChange={(event) =>
                          this.changeStateValue_L1(
                            "filtersVisibility",
                            "trademarksSearchAlgos",
                            event,
                          )
                        }
                      >
                        <Button style={headerButtonSelected}>
                          {this.state.selectedFilters.trademarksSearchAlgo}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                      {(this.state.selectedFilters.trademarksSearchAlgo ===
                        "Boolean" ||
                        this.state.selectedFilters.trademarksSearchAlgo ===
                          "Wildcard") && (
                        <Input
                          onChange={(event) => {
                            if (
                              this.state.selectedFilters
                                .trademarksSearchAlgo === "Wildcard" &&
                              event.target.value.search(/[%_]/g) === -1
                            ) {
                              this.changeStateValue_L2(
                                "selectedFilters",
                                "queries",
                                this.state.selectedFilters.trademarksSearchAlgo,
                                event.target.value.trim(),
                              );
                            }
                            if (
                              this.state.selectedFilters
                                .trademarksSearchAlgo === "Boolean"
                            ) {
                              this.changeStateValue_L2(
                                "selectedFilters",
                                "queries",
                                this.state.selectedFilters.trademarksSearchAlgo,
                                event.target.value.trim(),
                              );
                            }
                          }}
                          value={
                            this.state.selectedFilters.queries[
                              this.state.selectedFilters.trademarksSearchAlgo
                            ]
                          }
                          placeholder="Enter your query"
                          style={{
                            width: "200px",
                            border: "none",
                            margin: "0px 3px 0px 0px",
                            fontSize: "16px",
                            height:
                              this.state.selectedFilters.searchType ===
                              "Image_Search"
                                ? "116px"
                                : "inherit",
                          }}
                        />
                      )}
                      {this.state.selectedFilters.trademarksSearchAlgo ===
                        "Fuzzy" && (
                        <InputNumber
                          onChange={(value) => {
                            if (Number.isInteger(value)) {
                              this.changeStateValue_L2(
                                "selectedFilters",
                                "queries",
                                "Fuzzy",
                                value,
                              );
                            }
                          }}
                          value={this.state.selectedFilters.queries.Fuzzy}
                          placeholder="Enter fuzz depth"
                          style={{
                            padding: "4px",
                            width: "200px",
                            border: "none",
                            margin: "0px 3px 0px 0px",
                            fontSize: "16px",
                            height:
                              this.state.selectedFilters.searchType ===
                              "Image_Search"
                                ? "116px"
                                : "inherit",
                          }}
                        />
                      )}
                      {this.state.selectedFilters.trademarksSearchAlgo ===
                        "Boolean" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="And">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Boolean",
                                  this.state.selectedFilters.queries.Boolean.concat(
                                    "[[&]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[&]]"}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Or">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Boolean",
                                  this.state.selectedFilters.queries.Boolean.concat(
                                    "[[|]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[|]]"}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Not">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Boolean",
                                  this.state.selectedFilters.queries.Boolean.concat(
                                    "[[!]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[!]]"}
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                      {this.state.selectedFilters.trademarksSearchAlgo ===
                        "Wildcard" && (
                        <>
                          <Tooltip title="Multiple characters">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Wildcard",
                                  this.state.selectedFilters.queries.Wildcard.concat(
                                    "[[*]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[*]]"}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Single character">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Wildcard",
                                  this.state.selectedFilters.queries.Wildcard.concat(
                                    "[[?]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[?]]"}
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </>
                  )}
                  {this.state.selectedFilters.searchType === "Proprietors" && (
                    <>
                      <Input
                        onChange={(event) =>
                          this.changeStateValue_L1_Debounced(
                            "selectedFilters",
                            "proprietor",
                            event.target.value,
                          )
                        }
                        style={{
                          width: "16vw",
                          border: "none",
                          margin: "0px 3px 0px 0px",
                          fontSize: "18px",
                          height:
                            this.state.selectedFilters.searchType ===
                            "Image_Search"
                              ? "116px"
                              : "inherit",
                        }}
                        placeholder="Search proprietors"
                      />
                      <Dropdown overlay={proprietorsSearchTypesMenu}>
                        <Button style={headerButtonSelected}>
                          {this.state.selectedFilters.proprietorsSearchType}
                          <DownOutlined style={{ fontSize: "12px" }} />
                        </Button>
                      </Dropdown>
                    </>
                  )}
                  {this.state.selectedFilters.searchType === "Application" && (
                    <Input
                      onChange={(event) =>
                        this.changeStateValue_L1_Debounced(
                          "selectedFilters",
                          "application",
                          event.target.value,
                        )
                      }
                      placeholder="Enter application"
                      style={{
                        width: "16vw",
                        border: "none",
                        margin: "0px 3px 0px 0px",
                        fontSize: "18px",
                        height:
                          this.state.selectedFilters.searchType ===
                          "Image_Search"
                            ? "116px"
                            : "inherit",
                      }}
                    />
                  )}
                  {this.state.selectedFilters.searchType === "Image_Search" && (
                    <>
                      {ImageUploader}
                      <Dropdown
                        overlay={classesMenu}
                        open={this.state.filtersVisibility.classes}
                        onVisibleChange={(event) =>
                          this.changeStateValue_L1(
                            "filtersVisibility",
                            "classes",
                            event,
                          )
                        }
                      >
                        <Button
                          style={
                            this.state.selectedFilters.classes.length > 0
                              ? headerButtonSelected
                              : headerButton
                          }
                        >
                          Classes
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </>
                  )}
                  <Button
                    // className={
                    //   this.state.selectedFilters.searchType === "Image_Search"
                    //     ? "Search-Btn-Big"
                    //     : "Search-Btn"
                    // }
                    style={
                      this.state.selectedFilters.searchType === "Image_Search"
                        ? {
                            marginLeft: "auto",
                            border: "none",
                            color: "#ffffff",
                            backgroundColor: "#4285f4",
                            borderRadius: "0px",
                            height: "128px",
                            fontSize: "16px",
                            minWidth: "8vw",
                          }
                        : {
                            marginLeft: "auto",
                            border: "none",
                            color: "#ffffff",
                            backgroundColor: "#4285f4",
                            borderRadius: "0px",
                            height: "42px",
                            fontSize: "16px",
                          }
                    }
                    disabled={
                      this.props.quota &&
                      this.props.quota.left_quota === 0 &&
                      this.state.selectedFilters.searchType === "Trademarks"
                    }
                    onClick={() => this.handleSearch()}
                    loading={
                      this.props.searchStatus === LOADING ||
                      this.props.userTrademarksStatus === LOADING
                    }
                  >
                    Search
                  </Button>
                </header>
              </>
            )}
            <Layout>
              <Switch>
                <Route
                  exact
                  path="/search/search-tips"
                  component={SearchTips}
                />
                <Route
                  exact
                  path="/search/image_search"
                  render={(props) => (
                    <ImageSearch
                      {...props}
                      searchType={this.state.selectedFilters.searchType}
                    />
                  )}
                />
                <Route
                  exact
                  path="/search/trademarks-search"
                  render={(props) => (
                    <TrademarksSearch
                      {...props}
                      searchType={this.state.selectedFilters.searchType}
                    />
                  )}
                />
                <Route
                  exact
                  path="/search/proprietors-search"
                  component={ProprietorsSearch}
                />
                <Route
                  exact
                  path="/search/application-search"
                  component={TrademarkProfile}
                />
                <Route
                  exact
                  path="/search/trademark-profile"
                  component={TrademarkProfile}
                />
                <Route
                  exact
                  path="/search/proprietor-profile/:text"
                  component={ProprietorProfile}
                />
                <Route
                  exact
                  path="/search/profile"
                  component={ProfileDashboard}
                />
                <Route exact path="/search/faqs" component={Faqs} />
                <Route
                  exact
                  path="/search/reports-management"
                  component={ReportManagement}
                />
                <Route
                  exact
                  path="/search/reports-download"
                  component={ReportDownload}
                />
                <Route
                  exact
                  path="/search/email-template"
                  component={EmailTemplate}
                />
                <Route
                  exact
                  path="/search/email-preferences"
                  component={EmailPreferences}
                />
                <Route
                  exact
                  path="/search/client-master"
                  component={ClientMaster}
                />
                <Route component={NoMatch} />
              </Switch>
            </Layout>
          </Layout>
        </Layout>

        <UploadProfileLogo
          visible={this.state.modalsVisibility.uploadLogo}
          onCloseModal={() =>
            this.changeStateValue_L1("modalsVisibility", "uploadLogo", false)
          }
        />

        {/* This Modal will open when the question mark in trademarks algos filter is clicked */}
        <TrademarksAlgosInfo
          visible={this.state.modalsVisibility.trademarksAlgosInfo}
          onClose={() =>
            this.changeStateValue_L1(
              "modalsVisibility",
              "trademarksAlgosInfo",
              false,
            )
          }
        />

        {this.state.drawersVisibility.whatsNew && (
          <WhatsNew
            visible={this.state.drawersVisibility.whatsNew}
            onCloseDrawer={() =>
              this.changeStateValue_L1("drawersVisibility", "whatsNew", false)
            }
            collapsed={this.state.collapsed}
          />
        )}

        <Drawer
          title={<h2>IP India Report Details</h2>}
          placement="right"
          width={400}
          closable={true}
          open={this.state.drawersVisibility.ipIndiaReport}
          onClose={() =>
            this.changeStateValue_L1(
              "drawersVisibility",
              "ipIndiaReport",
              false,
            )
          }
        >
          <form>
            Enter the name of your report:
            <br />
            <Input
              onChange={(event) => {
                if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_file_name",
                    event.target.value,
                  );
                }
              }}
              value={this.state.ipIndiaReportObject.report_file_name}
              placeholder="Report's name (Optional)"
            />
            <br />
            <br />
            {this.props.reportAvailabilityStatus === SUCCESS &&
              this.props.reportAvailability &&
              this.props.reportAvailability.id && (
                <>
                  Do you want to clear previous data:
                  <br />
                  <Radio.Group
                    value={this.state.ipIndiaReportObject.clear}
                    onChange={(event) =>
                      this.changeStateValue_L1(
                        "ipIndiaReportObject",
                        "clear",
                        event.target.value,
                      )
                    }
                  >
                    <Radio value={true}>Yes</Radio>
                    <br />
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  <br />
                  <br />
                </>
              )}
            Do you want to download the Excel file:
            <br />
            <Radio.Group
              value={this.state.ipIndiaReportObject.report_download}
              onChange={(event) =>
                this.changeStateValue_L1(
                  "ipIndiaReportObject",
                  "report_download",
                  event.target.value,
                )
              }
            >
              <Radio value={true}>Yes</Radio>
              <br />
              <Radio value={false}>No</Radio>
            </Radio.Group>
            <br />
            <br />
            Choose your preferred report logic:
            <br />
            <Checkbox
              checked={this.state.ipIndiaReportObject.report_logic?.includes(
                "Word",
              )}
              onChange={(event) => {
                if (event.target.checked) {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_logic",
                    [...this.state.ipIndiaReportObject.report_logic, "Word"],
                  );
                } else {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_logic",
                    this.state.ipIndiaReportObject.report_logic?.filter(
                      (e) => e !== "Word",
                    ),
                  );
                }
              }}
            >
              Word (Contains)
            </Checkbox>
            <br />
            <Checkbox
              checked={this.state.ipIndiaReportObject.report_logic.includes(
                "Phonetic",
              )}
              onChange={(event) => {
                if (event.target.checked) {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_logic",
                    [
                      ...this.state.ipIndiaReportObject.report_logic,
                      "Phonetic",
                    ],
                  );
                } else {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_logic",
                    this.state.ipIndiaReportObject.report_logic?.filter(
                      (e) => e !== "Phonetic",
                    ),
                  );
                }
              }}
            >
              Phonetic
            </Checkbox>
            <br />
            <br />
            <Alert
              type="warning"
              message="Note: The report created will be on the basis of information provided on the public search page."
            />
            <br />
            <br />
            <Button
              type="primary"
              ghost
              rootStyle={{ marginRight: "10px" }}
              onClick={() => this.handleConfirmReport_Debounced()}
              disabled={
                this.props.reportAvailabilityStatus !== SUCCESS ||
                (this.props.reportAvailability &&
                  this.props.reportAvailability.id &&
                  this.props.reportAvailability.available === false)
              }
            >
              Confirm
            </Button>
            <Button
              type="danger"
              ghost
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "ipIndiaReport",
                  false,
                )
              }
            >
              Cancel
            </Button>
          </form>
        </Drawer>

        {/* TM-Search customer satisfaction survey. */}
        {/* <SurveyModal
                  title="customerSatisfactionSearch"
                  src="https://s.surveyplanet.com/nkBHU9nKb"
                  visible={this.state.modalsVisibility.CSSurvey}
                  maskClosable={false}
                  onCancel={() => {
                      document.cookie = `Search_CSSurvey=;max-age=${60 * 60 * 24 * 20}`;
                      this.changeStateValue_L1("modalsVisibility", "CSSurvey", false);
                  }}
              /> */}

        {/* Suggestions survey. */}
        {/* <SurveyModal
                  title="suggestions"
                  src="https://s.surveyplanet.com/1eI4bBov0"
                  visible={this.state.modalsVisibility.suggestions}
                  maskClosable={true}
                  onCancel={() => this.changeStateValue_L1("modalsVisibility", "suggestions", false)}
              /> */}

        {/* Complaints survey */}
        {/* <SurveyModal
                  title="complaints"
                  src="https://s.surveyplanet.com/7597Miojp"
                  visible={this.state.modalsVisibility.complaints}
                  maskClosable={true}
                  onCancel={() => this.changeStateValue_L1("modalsVisibility", "complaints", false)}
              /> */}

        {/* Queries survey */}
        {/* <SurveyModal
                  title="queriesSurvey"
                  src="https://s.surveyplanet.com/JlqHxcfFh"
                  visible={this.state.modalsVisibility.queriesSurvey}
                  maskClosable={true}
                  onCancel={() => this.changeStateValue_L1("modalsVisibility", "queriesSurvey", false)}
              /> */}

        {/* Image Recognition */}
        {/* <ImageRecognition
                  visible={this.state.modalsVisibility.imageRecognition}
                  onCloseModal={() => this.changeStateValue_L1("modalsVisibility", "imageRecognition", false)}
              /> */}
      </>
    );
  }
}

const mapDispatchToProps = {
  getProfile,
  logOut,
  getUserSubscriptions,
  getUserTrademarksInSearch,
  handleSearchInfoDispatch,
  getSearchResults,
  postUserTrademark,
  getTrademarkProfileData,
  getImageSearchResults,
  TPAction,
  SetFileAction,
  getTrademarkReportStatus,
  getProprietorsSearch,
  getAlertMessage,
  // getSearchTrademarks,
};

function mapStateToProps(state) {
  const { userSubscriptions, userSubscriptionStatus, userDetails } =
    state.userSubscriptions;
  const { userProfile, userProfileStatus } = state.profile;

  const {
    quota,
    userTrademarks,
    userTrademarksCount,
    userTrademarksPage,
    userTrademarksStatus,
    searchPath,
    reportAvailabilityStatus,
    reportAvailability,

    // searchUserTrademarks,
    // searchUserTrademarksStatus,
  } = state.searchState.searchBar;

  const {
    axiosCancelSearches,
    axiosCancelAnalysis,
    axiosCancelFilters,
    searchStatus,
    ImageSearch,
  } = state.searchState.trademarksSearch;
  return deepFreeze({
    userDetails,
    userSubscriptions,
    userSubscriptionStatus,
    userProfile,
    userProfileStatus,
    quota,
    userTrademarks,
    userTrademarksCount,
    userTrademarksPage,
    userTrademarksStatus,
    reportAvailabilityStatus,
    reportAvailability,
    searchPath,
    axiosCancelSearches,
    axiosCancelAnalysis,
    axiosCancelFilters,
    proprietorsSearchStatus:
      state.searchState.proprietorsSearch.proprietorsSearchStatus,
    searchAlert: state.alertMessages.search,
    searchStatus,
    ImageSearch,
    // searchUserTrademarks,
    // searchUserTrademarksStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
