import img1 from '../../../assets/managerfaqimages/2.1.gif'
import img2 from '../../../assets/managerfaqimages/2.2.gif'
import img3 from '../../../assets/managerfaqimages/2.3.gif'
import img4 from '../../../assets/managerfaqimages/2.4.gif'
import img5 from '../../../assets/managerfaqimages/2.5.gif'
import img6 from '../../../assets/managerfaqimages/2.6.gif'
import img7 from '../../../assets/managerfaqimages/2.7.gif'
import img8 from '../../../assets/managerfaqimages/2.8.gif'
import img9 from '../../../assets/managerfaqimages/2.9.gif'
import img10 from '../../../assets/managerfaqimages/2.10.gif'
import img11 from '../../../assets/managerfaqimages/2.11.gif'
import img12 from '../../../assets/managerfaqimages/2.12.gif'
import img13 from '../../../assets/managerfaqimages/2.13.gif'
import img14 from '../../../assets/managerfaqimages/2.14.gif'
import img15 from '../../../assets/managerfaqimages/2.15.gif'
import img16 from '../../../assets/managerfaqimages/2.16.gif'
import img17 from '../../../assets/managerfaqimages/2.17.gif'
import img18 from '../../../assets/managerfaqimages/2.18.gif'
import img19 from '../../../assets/managerfaqimages/2.19.gif'
import img20 from '../../../assets/managerfaqimages/2.20.gif'
import img21 from '../../../assets/managerfaqimages/2.21.gif'
import img22 from '../../../assets/managerfaqimages/2.22.gif'
import img23 from '../../../assets/managerfaqimages/2.23.gif'

const ManagerData={

    FeaturesGuide:[
        {
            question:'Options in MikeTM Manager',
            answers:[
                {answer:'Manage trademarks and their attributes.'},
                {answer:'Create reports based on different filters.'},
                {answer:'Create workflows based on selected criteria.'},
                {answer:'Send mails'},
                {answer:'Create Email templates'},
                {answer:'Create To-do tasks'} 
            ]

        },

        {
            question:'Guide to MikeTM Manager',
            answers:[
                {answer:'-	MikeTM Manager to automate your entire portfolio of Trademarks'},
                {answer:'-	To set up MikeTM Manager you have to click on the option ‘Trademark’ on the left had side a dropdown will be shown which will give you options like Manage Trademark just insert the application no. and you can avail the latest update from the IP India website on MikeTM Manager on the marks of your requested.'},
                {answer:'-	By selecting Total Application you extract all the details as updated on the IP India website on MikeTM manager.'},
                {answer:'-	By selecting ‘Reply to correspondence’ you can see all the pending reply which ispending for you to reply'}, 
                {answer:'-	By selecting ‘Upcoming Opposition hearing; TLA hearing for your marks MikeTM'},
                {answer:'-	By selecting Upcoming hearings, you can see all the upcoming hearing which'}, 
                {answer:'-	Manager will automatically update you on upcoming renewals which gives proper time either to renew or to update clients. With this no renew is missed.'},
                {answer:'-	MikeTM Manager will track the date of application and remove them from upcoming renewal after that very update is passed to avoid confusion.'},
                {answer:'-	To access document you can click on the document as shown in the category ‘Document’'},  
                {answer:'-	Create workflows using the “Workflow” option and selecting the appropriate options for the given fields.'},
            ]

        },
    ],
    Question:[
        {
            heading:'FAQs',
            question:'1. How to add a trademark?',
            img:img1,
            answers:[
                {answer:'Go to “manage trademarks” and click on “Add Trademark” & upload the Application numbers manually or via excel.(2.1)'}
            ]

        },
        {
            question:'2. How to delete a trademark?',
            img:img2,
            answers:[
                {answer:'Go to “manage trademark” & select the application numbers’s checkbox and click on on “delete trademark” option.(2.2)'}
           ]

        },
        {
            question:'3. What is a tag and how to apply it?',
            img:img3,
            answers:[
                {answer:' Tag is a manual option for classification based on your own criteria, you can go to “Manage trademark” and click on “Manage Tags” to add or delete a tag. To Apply a tag you need to go to the trademark and click on edit and select “edit” button and select the tag to apply it.(2.3)'},
            ]
           

        },
        {
            question:'4. How to create a client report?',
            img:img4,
            answers:[
                {answer:'Go to “Total Applications” and select the proprietor under the proprietor filter and select the trademarks and create a report to create the required report.(2.4)'},
           ]

        },

        {
            question:'5. How to see the pending replies for my portfolio?',
            img:img5,
            answers:[
                {answer:" Click on the “Pending replies” on “My trademark” dashboard to see the pending replies for your trademarks(2.5)"},
           ]

        },
        {
            question:'6. How to see the upcoming TLA/Opposition Hearing for my Portfolio?',
            img:img6,
            answers:[
                {answer:' Click on the “Upcoming Opposition/TLA hearing” on “Your Trademarks” to see the upcoming TLA/Opposition hearings(2.6)'},
            ]
        },
        {
            question:'7. How to see the upcoming renewals for Your trademarks?',
            img:img7,
            answers:[
                {answer:'Scroll to the “upcoming renewals” sections to see the upcoming renewals for your trademarks(2.7)'},
               
            ]
        },
        {
            question:'8. How to see the Formalities Check Failed Applications?',
            img:img8,
            answers:[
                {answer:' Scroll to the “Formalities Check fail” sections to see the applications under it and the reason.(2.8)'},
            ]
        },
        {
            question:'9. How to see the Objected Applications?',
            img:img9,
            answers:[
                {answer:'Scroll to the “Objected” section to see the applications under it.(2.9)'},
            ]
        },
        {
            question:'10. How to see the Opposed Applications?',
            img:img10,
            answers:[
                {answer:'Scroll to the “Opposed” section to see the applications under it.(2.10)'},
            ]
        },
        {
            question:'11. How to see the 3rd party opposed Applications?',
            img:img11,
            answers:[
                {answer:'Click on the “Trademark” dropdown and click on the “3rd party trademarks” and “dashboard” to see the 3rd party opposed applications(2.11)'},
            ]
        },
        {
            question:'12. How to see the pending replies of the 3rd party opposed Applications?',
            img:img12,
            answers:[
                {answer:'Click on the “3rd party trademarks” and click on “pending replies” to see the pending replies of the 3rd party opposed applications(2.12)'},
            ]
        },
        {
            question:'13. How to see the TLA/Opposition Hearing of the 3rd party opposed Applications?',
            img:img13,
            answers:[
                {answer:'Click on the “3rd party trademarks” and click on “TLA/Opposition hearing” to see the upcoming TLA/opposition hearings(2.13)'},
            ]
        },

        {
            question:'14. How to see the opposed Applications of 3rd party opposed Applications?',
            img:img14,
            answers:[
                {answer:'Click on the “3rd party trademarks” and scroll to “opposed” applications to see the selected applications.(2.14)'},
            ]
        },

        {
            question:'15. What is Status Tracker?',
            img:img15,
            answers:[
                {answer:'If you want to track the applications when they are being published you can add it to the “status tracker”.(2.15)'},
            ]
        },
        {
            question:'16. How to add marks to Status Tracker?',
            img:img16,
            answers:[
                {answer:'Go to the “trademark” dropdown and click on the “manage trademark” and click on the “add trademark” button and write “status tracker” under the comment section.(2.16)'},
            ]
        },

        {
            question:'17. How to see notifications?',
            img:img17,
            answers:[
                {answer:'Click on the “Notification” section to see the changes which have taken place regarding the applications under all the dashboards.(2.17)'},
            ]
        },

        {
            question:'18. How to create Email Templates?',
            img:img18,
            answers:[
                {answer:'Click on the “Email Template” and select the “Create Email template” button to create the email template for the profile.(2.18)'},
            ]
        },
        {
            question:'19. How to create a workflow?',
            img:img19,
            answers:[
                {answer:'- Click on the “Workflow” button to create a workflow, click on “Create new workflow” to create a workflow, select the given fields and options to create a workflow.(2.19)'},
            ]
        },
        {
            question:'20. How to add/delete a team member?',
            img:img20,
            answers:[
                {answer:'Click on “Profile” section and scroll to Team members to add or delete the team members.(2.20)'},
            ]
        },
        {
            question:'21. How to edit the user details?',
            img:img21,
            answers:[
                {answer:'Click on the “Profile” section and click on “edit” to edit the user details.(2.21)'},
            ]
        },
        {
            question:'22. How to access the weekly report?',
            img:img22,
            answers:[
                {answer:'Click on the “report” section and click on the “Daily report” to access the weekly reports.(2.22)'},
            ]
        },
        {
            question:'23. How to create custom reports?',
            img:img23,
            answers:[
                {answer:'Click on the checkbox against the application under any section and select the “create report” button to create PDF or Excel reports.(2.23)'},
            ]
        },
    ]
};
export default ManagerData;
