import React from "react";
import {
  Row,
  Col,
  Button,
  Space,
  Tabs,
  List,
  Checkbox,
  Dropdown,
  Input,
  Spin,
  Pagination,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import SaveTemplateButton from "./SaveTemplateButton";
import { useDispatch } from "react-redux";
import {
  updateWatchTemplate,
  changeTemplateId,
  emptyListDropdownSearchValues,
  deleteWatchTemplate,
  getWatchFilterTemplate,
} from "../../../../../../../redux/actions/watchActions/watchActions";
import { useState } from "react";
import {
  RECOMMENDED_TEMPLATE_TYPE,
  USER_TEMPLATE_TYPE,
} from "../../../../../../../redux/actions/watchActions/types";

const TemplateSection = (props) => {
  const {
    selected,
    setSelected,
    handleUseTemplate,
    USER,
    RECOMMENDED,
    current_template_id,
    template_status,
    filterSelected = false,
  } = props;
  const [tabValue, setTabValue] = React.useState("1");
  const [openRenameFilter, setOpenRenameFilter] = useState(false);
  const [renameFileName, setRenameFileName] = useState("");
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();

  const handleCheckboxClicked = (item) => {
    if (selected.indexOf(item.value) !== -1) {
      let temp = selected.filter((templateId) => templateId !== item.value);
      setSelected(temp);
    } else {
      let temp = [...selected, item.value];
      setSelected(temp);
    }
  };

  const handleNextpage = (pageNumber) => {
    if (tabValue === "1") {
      dispatch(
        getWatchFilterTemplate(
          USER_TEMPLATE_TYPE,
          `${props.getFilterPath()}/`,
          0,
          pageNumber,
        ),
      );
    } else {
      dispatch(
        getWatchFilterTemplate(
          RECOMMENDED_TEMPLATE_TYPE,
          `${props.getFilterPath()}/`,
          0,
          pageNumber,
        ),
      );
    }
  };

  const renderList = () =>
    USER.loading ? (
      <Spin />
    ) : (
      <>
        <Spin spinning={template_status.loading}>
          <List
            itemLayout="vertical"
            size="large"
            style={{ height: "400px", overflowY: "scroll" }}
            dataSource={tabValue === "2" ? RECOMMENDED.data : USER.data}
            renderItem={(item) => {
              return (
                <List.Item
                  key={item.value}
                  extra={
                    <Button
                      type="primary"
                      ghost={item.value !== current_template_id}
                      onClick={() => {
                        dispatch(changeTemplateId(item.value));
                        dispatch(emptyListDropdownSearchValues());
                        handleUseTemplate(item);
                      }}
                    >
                      Use
                    </Button>
                  }
                >
                  {tabValue === "1" ? (
                    <Checkbox
                      checked={selected.includes(item.value)}
                      onChange={() => handleCheckboxClicked(item)}
                    >
                      {item.name}
                    </Checkbox>
                  ) : (
                    item.name
                  )}
                </List.Item>
              );
            }}
          />
        </Spin>
        <footer className="General-Footer Box-Shadow">
          <Pagination
            defaultCurrent={1}
            current={
              tabValue === "1" ? USER.pageNumber : RECOMMENDED.pageNumber
            }
            total={
              tabValue === "1"
                ? USER?.metadata?.count ?? 0
                : RECOMMENDED?.metadata?.count ?? 0
            }
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showSizeChanger={false}
            onChange={(pageNumber) => handleNextpage(pageNumber)}
          />
        </footer>
      </>
    );

  const tabItems = [
    {
      key: "1",
      label: "User Created",
      children: renderList(),
    },
    {
      key: "2",
      label: "Recommended (Beta)",
      children: renderList(),
    },
  ];

  const handleTabChange = (tabKey) => {
    setSelected([]);
    setTabValue(tabKey);
  };

  const handleTemplateDelete = () => {
    let body = {
      filter_template_ids: selected,
    };
    dispatch(
      deleteWatchTemplate(
        body,
        USER_TEMPLATE_TYPE,
        `${props.getFilterPath()}/`,
        0,
        USER.pageNumber,
      ),
    );
    setSelected([]);
  };

  const handleFilenameChange = (event) => {
    setRenameFileName(event.target.value);
  };

  const handleUpdateTemplateName = () => {
    let selectedData = USER.data.find((item) => item.value === selected[0]);
    const modifiedBody = {
      name: renameFileName,
      template_key: selectedData.template_key,
      template_type: selectedData.template_type,
      template_json: selectedData.template_json,
      pk: selectedData.value,
    };
    dispatch(
      updateWatchTemplate(
        modifiedBody,
        USER_TEMPLATE_TYPE,
        `${props.getFilterPath()}/`,
        USER.pageNumber,
      ),
    );
    setRenameFileName("");
  };

  const renameDropdownMenu = () => (
    <React.Fragment>
      <Row style={{ padding: "10px" }}>
        <Space.Compact
          style={{
            width: "100%",
          }}
        >
          <Input
            placeholder="Enter Name"
            value={renameFileName}
            onChange={handleFilenameChange}
            prefix={<FileTextOutlined />}
          />
          <Button
            type="primary"
            disabled={!renameFileName.length}
            loading={USER.loading || RECOMMENDED.loading}
            onClick={() => handleUpdateTemplateName()}
          >
            <SaveOutlined />
          </Button>
        </Space.Compact>
      </Row>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Row style={{ marginBottom: "10px" }}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontWeight: "500",
          }}
        >
          <Space>
            <SaveTemplateButton
              USER={USER}
              tabValue={tabValue}
              current_template_id={current_template_id}
              fileName={fileName}
              setFileName={setFileName}
              template_status={template_status}
              filterSelected={filterSelected}
              {...props}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Tabs
            activeKey={tabValue}
            items={tabItems}
            onChange={handleTabChange}
            tabBarExtraContent={
              tabValue === "1" ? (
                <Space>
                  <Dropdown
                    dropdownRender={() => renameDropdownMenu()}
                    overlayClassName="Filters-Menu"
                    open={openRenameFilter}
                    onOpenChange={(event) => {
                      setOpenRenameFilter(event);
                    }}
                    disabled={selected.length !== 1}
                  >
                    <Button
                      type="link"
                      style={{ paddingRight: "5px", paddingLeft: "0px" }}
                      icon={<EditOutlined />}
                    >
                      Rename
                    </Button>
                  </Dropdown>
                  <Button
                    type="link"
                    danger
                    style={{ paddingRight: "0px", paddingLeft: "5px" }}
                    icon={<DeleteOutlined />}
                    onClick={() => handleTemplateDelete()}
                    disabled={!selected.length}
                  >
                    Delete
                  </Button>
                </Space>
              ) : null
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TemplateSection;
