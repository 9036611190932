export const TRADEMARK_CUSTOM_REPORT = 'TRADEMARK_CUSTOM_REPORT';
export const TRADEMARK_AUTOMATED_REPORT = 'TRADEMARK_AUTOMATED_REPORT';
export const TLA_REPORT = 'TLA_REPORT';
export const OPPOSTION_REPORT = 'OPPOSTION_REPORT';
export const CUSTOM_TRADEMARK_REPORT = 'CUSTOM_TRADEMARK_REPORT';
export const INTERNATIONAL_TRADEMARK_REPORT = 'INTERNATIONAL_TRADEMARK_REPORT';
export const PATENT_CUSTOM_REPORT = 'PATENT_CUSTOM_REPORT';
export const PATENT_AUTOMATED_REPORT = 'PATENT_AUTOMATED_REPORT';
export const CUSTOM_PATENT_REPORT = 'CUSTOM_PATENT_REPORT';
export const INTERNATIONAL_PATENT_REPORT = 'INTERNATIONAL_PATENT_REPORT';
export const MANAGER_DOWNLOAD_REPORT = 'MANAGER_DOWNLOAD_REPORT';

export const EXAM_REPORT = 'EXAM_REPORT';

export const EXAM_REPORT_YOUR_CLASSES_FILTERS = 'EXAM_REPORT_YOUR_CLASSES_FILTERS';
export const EXAM_REPORT_YOUR_PROPRIETORS_FILTERS = 'EXAM_REPORT_YOUR_PROPRIETORS_FILTERS';
export const EXAM_REPORT_YOUR_TM_TYPE_FILTERS = 'EXAM_REPORT_YOUR_TM_TYPE_FILTERS';

export const EXAM_REPORT_THREAT_CLASSES_FILTERS = 'EXAM_REPORT_THREAT_CLASSES_FILTERS';
export const EXAM_REPORT_THREAT_PROPRIETORS_FILTERS = 'EXAM_REPORT_THREAT_PROPRIETORS_FILTERS';
export const EXAM_REPORT_THREAT_TM_TYPE_FILTERS = 'EXAM_REPORT_THREAT_TM_TYPE_FILTERS';

export const EXAM_REPORT_YOUR_TAG_FILTERS = 'EXAM_REPORT_TAG_FILTERS';
