import React, { useEffect, useState } from "react";
import { Image, Checkbox, Tooltip, Empty, Pagination, Spin, Badge } from "antd";
import { DownOutlined, MinusCircleOutlined } from "@ant-design/icons";
import ThreatDetailArea from "./ThreatDetailArea";
import { useDispatch, useSelector } from "react-redux";
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../../../constants/commonConstants/generalConstants";
import { getGridLayoutData } from "../../../../../redux/actions/watchActions/gridDashboardActions";

const Content = (props) => {
  const { journal, setShowHeader, showHeader, openGrid } = props;
  const [selectedMark, setSelectedMark] = useState({});
  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3] || "";

  const { data, status, count, message, pageNumber } = useSelector(
    (state) => state.watchState.gridLayoutData[dashboardLayout][urlKey].config,
  );
  const dispatch = useDispatch();

  const handleOpenThreat = (item) => {
    if (selectedMark.id === item.id) {
      setSelectedMark({});
      setShowHeader(true);
    } else {
      setShowHeader(false);
      setSelectedMark(item);
    }
  };

  useEffect(() => {
    if (openGrid) {
      if (data && data?.length && !selectedMark.id) handleOpenThreat(data[0]);
    }
  }, [openGrid]);

  const handleCheckboxClicked = (event, trademark) => {
    props.setSelectedData({
      ...props.selectedData,
      data: {
        ...props.selectedData.data,
        [trademark.application_number]: {
          ...props.selectedData.data[trademark.application_number],
          select_all: event.target.checked,
        },
      },
      threatsCount: event.target.checked
        ? props.selectedData.threatsCount +
          trademark.last_week_threats -
          (props.selectedData.data[trademark.application_number]?.threats
            ?.length || 0)
        : props.selectedData.threatsCount >= 0
          ? props.selectedData.threatsCount -
            trademark.last_week_threats +
            (props.selectedData.data[trademark.application_number]?.threats
              ?.length || 0)
          : 0,
    });
  };

  let filterSelected = props?.isAnyPrimaryFilterSelected(
    props?.generatePrimaryFilterObject(),
  );

  const handleNextpage = (pageNumber) => {
    const filters = props?.generatePrimaryFilterObject();
    dispatch(
      getGridLayoutData(
        journal,
        pageNumber,
        filterSelected,
        filters,
        window?.location,
      ),
    );
  };

  const mikeDynamicComponent = (node) => {
    if (status === LOADING)
      return (
        <div
          style={{
            display: "table",
            height: "calc(100vh - 200px)",
            width: "100%",
            verticalAlign: "middle",
          }}
        >
          <Spin size="large" style={{ display: "table-cell" }} />
        </div>
      );

    if (status === ERROR)
      return (
        <div
          style={{
            display: "table",
            height: "calc(100vh - 200px)",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
          >
            <h1>{message}</h1>
          </div>
        </div>
      );
    if (status === SUCCESS && count === 0)
      return (
        <div
          style={{
            display: "table",
            height: "calc(100vh - 200px)",
            width: "100%",
          }}
        >
          <Empty style={{ display: "table-cell", verticalAlign: "middle" }} />
        </div>
      );
    if (!data.length) {
      return (
        <div
          style={{
            display: "table",
            height: "calc(100vh - 200px)",
            width: "100%",
          }}
        >
          <Empty style={{ display: "table-cell", verticalAlign: "middle" }} />
        </div>
      );
    }
    return (
      <div
        className="General-Content vertical_scroll"
        style={{
          height: `${
            showHeader ? "calc(100vh - 200px)" : "calc(100vh - 140px)"
          }`,
          background: "white",
        }}
      >
        {node}
      </div>
    );
  };

  const markCollapse = data?.map((eachTrademark) => (
    <React.Fragment key={`${eachTrademark.id}`}>
      <div
        id={eachTrademark.id}
        className="Record-Row"
        style={{
          background: "linear-gradient(0.91turn, #fff,#d0e1f6,#ebf4ff )",
          // background: "linear-gradient(0.91turn, #fff,#ebf4ff)",

          color: "black",
        }}
      >
        <div className="Record-Row-Cell" style={{ width: "20px" }}>
          <Checkbox
            checked={
              props?.selectedData?.data[eachTrademark.application_number]
                ?.select_all || props?.selectedData?.select_all
            }
            onClick={(event) => handleCheckboxClicked(event, eachTrademark)}
            disabled={props?.selectedData?.select_all}
            className="checkbox-content-list"
          />
        </div>
        <div className="Record-Row-Cell">
          {urlKey === "tm_protect" ? (
            <Tooltip placement={"top"} title={`Term:- ${eachTrademark?.term}`}>
              {eachTrademark?.term}
            </Tooltip>
          ) : (
            <Tooltip
              placement={"top"}
              title={`Application Number:- ${eachTrademark.application_number}`}
            >
              {eachTrademark.application_number}
            </Tooltip>
          )}
        </div>
        {urlKey === "tm_protect" && (
          <div className="Record-Row-Cell" style={{ width: "200px" }}>
            <Tooltip
              placement={
                eachTrademark.focus_classes &&
                eachTrademark.focus_classes.length > 25
                  ? "topLeft"
                  : "top"
              }
              title={`Focus Classes:- ${`${eachTrademark.focus_classes}`}`}
            >
              {`${eachTrademark.focus_classes}`}
            </Tooltip>
          </div>
        )}
        {urlKey !== "tm_protect" && (
          <>
            <div className="Record-Row-Cell">
              <Tooltip placement={"top"} title={`Associated Image`}>
                {eachTrademark.associated_image ? (
                  <Image
                    src={eachTrademark.associated_image}
                    height="60px"
                    width="60px"
                    alt=""
                  />
                ) : (
                  <div>NO IMAGE AVAILABLE</div>
                )}
              </Tooltip>
            </div>
            <div className="Record-Row-Cell">
              <Tooltip
                placement={
                  eachTrademark.applied_for &&
                  eachTrademark.applied_for.length > 25
                    ? "topLeft"
                    : "top"
                }
                title={`Applied For:- ${
                  eachTrademark.applied_for ? eachTrademark.applied_for : "N/A"
                }`}
              >
                {eachTrademark.applied_for ? eachTrademark.applied_for : "N/A"}
              </Tooltip>
            </div>
            <div className="Record-Row-Cell">
              <Tooltip
                placement={
                  eachTrademark.associated_class &&
                  eachTrademark.associated_class.length > 15
                    ? "topLeft"
                    : "top"
                }
                title={`Associated Class:- ${eachTrademark.associated_class}`}
              >
                {`${eachTrademark.associated_class}`}
              </Tooltip>
            </div>
          </>
        )}
        {(urlKey === "image_label" ||
          urlKey === "image_text" ||
          urlKey === "image_text_new") && (
          <div className="Record-Row-Cell">
            <Tooltip
              placement={
                eachTrademark?.focus_labels &&
                eachTrademark?.focus_labels.length > 5
                  ? "topLeft"
                  : "top"
              }
              title={
                urlKey === "image_label"
                  ? `Focus Labels:- ${eachTrademark.focus_labels}`
                  : `Image Text:- ${eachTrademark.image_text}`
              }
            >
              {urlKey === "image_label"
                ? eachTrademark.focus_labels.map((data) => data).join(", ")
                : eachTrademark.image_text}
            </Tooltip>
          </div>
        )}
        <div className="Record-Row-Cell" id="threat-view-btn">
          <a
            href={`#${eachTrademark.id}`}
            onClick={() => handleOpenThreat(eachTrademark)}
          >
            {`${eachTrademark.constant_last_week_threats} Threats`}
            <DownOutlined />
          </a>
        </div>
        <div className="Record-Row-Cell" style={{ width: "50px" }}>
          {props?.selectedData?.data[eachTrademark.application_number] &&
            (props?.selectedData?.data[eachTrademark.application_number]
              .select_all ||
              props?.selectedData?.data[eachTrademark.application_number]
                .threats?.length > 0) && (
              <>
                <Badge
                  overflowCount={999}
                  count={
                    props?.selectedData?.data[eachTrademark.application_number]
                      .select_all
                      ? eachTrademark.last_week_threats
                      : props?.selectedData?.data[
                          eachTrademark.application_number
                        ].threats?.length || 0
                  }
                />
                {!props?.selectedData?.data[eachTrademark.application_number]
                  .select_all && (
                  <MinusCircleOutlined
                    style={{ marginLeft: "5px", fontSize: "14px" }}
                    onClick={() =>
                      props.setSelectedData({
                        ...props.selectedData,
                        threatsCount:
                          props.selectedData.threatsCount -
                          props.selectedData?.data[
                            eachTrademark.application_number
                          ]?.threats?.length,
                        data: {
                          ...props.selectedData.data,
                          [eachTrademark.application_number]: {
                            ...props.selectedData.data[
                              eachTrademark.application_number
                            ],
                            threats: [],
                          },
                        },
                      })
                    }
                  />
                )}
              </>
            )}
        </div>
      </div>
      {String(eachTrademark.id) === String(selectedMark.id) && (
        <div
          style={{
            height: "calc(100% - 60px)",
            overflow: "auto",
            margin: "8px 5px",
            borderRadius: "5px",
            border: "1px solid #c9c9c9",
          }}
        >
          {/* This will show the grid of trademarks/threats. */}
          <ThreatDetailArea markDetails={eachTrademark} {...props} />
        </div>
      )}
    </React.Fragment>
  ));
  return (
    <>
      {mikeDynamicComponent(markCollapse)}
      <footer className="General-Footer">
        <Pagination
          defaultCurrent={1}
          current={pageNumber}
          total={count}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
          pageSize={100}
          showSizeChanger={false}
          showQuickJumper
          onChange={(pageNumber) => handleNextpage(pageNumber)}
        />
      </footer>
    </>
  );
};

export default Content;
