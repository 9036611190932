//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { MailOutlined, TableOutlined, UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Modal, Button, Input, Typography, notification } from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import {
  editClientEmail,
  setStoreData,
} from "../../../redux/actions/commonActions/actions";

const { Text } = Typography;

class EditClientEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "tm",
      file: "",
      fileValue: "",
      uploadFileError: false,
      subscription: "TRADEMARK",
      markType: "",
    };
  }

  onClose() {
    this.props.setStoreData({
      clientEmailUploadData: {
        message: "",
        errors: [],
      },
    });
    this.props.onCloseModal();
    this.props.form.resetFields();
  }

  async handleEditClientEmail(values) {
    let response = await this.props.editClientEmail({
      client_email_id: this.props?.editEmailData?.client_email_id,
      ...values,
    });

    if (response === SUCCESS) {
      this.editEmailMessage(SUCCESS);
      this.props.onConfirm();
      this.onClose();
    } else {
      this.editEmailMessage(ERROR);
    }
  }

  editEmailMessage(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Emails Edit Successful",
          description: "Successfully updated client email.",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Updating",
          description: "Unfortunately, an error occured. Please, try again.",
        });
        break;
      default:
        break;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form
      .validateFields((err, values) => {
        if (!err) {
          this.props.setStoreData({
            ...this.props.editEmailData,
          });
        }
      })
      .then((values) => {
        this.handleEditClientEmail(values);
      });
  };

  render() {
    const { form } = this.props;

    const { getFieldDecorator } = form;
    const { client_email, person_name, company_name } =
      this.props?.editEmailData;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    return (
      <>
        <Modal
          title={
            <div className="alignC">
              <Text strong style={{ fontSize: "16px" }}>
                EDIT EMAIL
              </Text>
            </div>
          }
          open={this.props.visible}
          closable={false}
          footer={null}
        >
          <>
            <Form
              id="client_email_edit"
              labelCol={{ span: 5 }}
              onSubmit={this.handleSubmit}
            >
              <Form.Item {...formItemLayout} label="Email">
                {getFieldDecorator("client_email", {
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "Please enter valid email!",
                    },
                  ],
                  initialValue: client_email,
                })(
                  <Input
                    type="text"
                    prefix={
                      <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Add email"
                  />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Person Name">
                {getFieldDecorator("person_name", {
                  rules: [
                    {
                      type: "string",
                    },
                  ],
                  initialValue: person_name,
                })(
                  <Input
                    type="string"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Please add Person Name"
                  />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Company Name">
                {getFieldDecorator("company_name", {
                  initialValue: company_name,
                })(
                  <Input
                    type="text"
                    prefix={
                      <TableOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Please add company name"
                  />
                )}
              </Form.Item>

              <div
                style={{
                  width: " 100%",
                  textAlign: "right",
                }}
              >
                <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                  <Button type="default" onClick={() => this.onClose()}>
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                  <Button type="primary" htmlType="submit">
                    Edit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  editClientEmail,
  setStoreData,
};

function mapStateToProps(state) {
  const {
    clientEmailMessage,
    editClientEmailStatus,
    createClientEmailStatus,
    editEmailData,
  } = state.clientEmailState;
  return deepFreeze({
    createClientEmailStatus,
    editEmailData,

    clientEmailMessage,
    editClientEmailStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EditClientEmail));
