import { CloseOutlined } from "@ant-design/icons";
import { Drawer, Space, Tabs, Typography } from "antd";
import React, { useState } from "react";
import { ClientContactComponent } from "./../../DetailPageComponents/ClientContacts/ClientContactComponent";
import { NotesComponent } from "./../../DetailPageComponents/Notes/NotesComponent";
import { ToDoTaskComponent } from "./../../DetailPageComponents/ToDoTasks/ToDoTaskComponent";
import { LinkedAttachmentComponent } from "./../../DetailPageComponents/LinkedAttachments/LinkedAttachmentComponent";
import { LinkedTrademarkComponent } from "./../../DetailPageComponents/LinkedTrademarks/LinkedTrademarkComponent";
import { useSelector } from "react-redux";

const { Text } = Typography;

export const ClientMasterDetail = (props) => {
  const { open, setOpen } = props;

  const [activeTabKey, setActiveTabKey] = useState("client-details");

  const { data: singleClient } = useSelector(
    (state) => state.masters.clientMaster.singleClient,
  );

  const onClose = () => {
    setOpen(false);
  };

  const renderClientDetail = (label, value) => {
    return (
      <div style={{ marginBottom: "5px" }}>
        <Text strong style={{ marginBottom: "5px" }}>
          {label}
        </Text>
        <br />
        <Text level={5} style={{ color: "#5A6675" }}>
          {value ? value : "N.A."}
        </Text>
      </div>
    );
  };

  const ClientDetailSection = () => {
    return (
      <Space direction="vertical">
        {renderClientDetail("Client Name", singleClient?.client_name ?? "")}
        {renderClientDetail("PAN Number", singleClient?.pan_number ?? "")}
        {renderClientDetail("GST Number", singleClient?.gst_number ?? "")}
        {renderClientDetail("Address", singleClient?.address ?? "")}
      </Space>
    );
  };

  const tabProps = {
    parent: singleClient?.id ?? "",
    activeTab: activeTabKey,
  };

  const tabItems = [
    {
      key: "client-details",
      label: "Client Details",
      children: (
        <>
          <ClientDetailSection />
          <ClientContactComponent key_value={"client-details"} {...tabProps} />
        </>
      ),
    },
    {
      key: "notes",
      label: "Notes",
      children: <NotesComponent key_value="notes" {...tabProps} />,
    },
    // {
    //   key: "to-do-tasks",
    //   label: "To-do Tasks",
    //   children: <ToDoTaskComponent />,
    // },
    {
      key: "attachments",
      label: "Attachment",
      children: (
        <LinkedAttachmentComponent key_value={"attachments"} {...tabProps} />
      ),
    },
    // {
    //   key: "linked-trademarks",
    //   label: "Linked Trademark",
    //   children: <LinkedTrademarkComponent {...tabProps} />,
    // },
  ];

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey);
  };

  return (
    <Drawer
      title="Client Details"
      placement="right"
      width={"80%"}
      style={{ overflowY: "inherit" }}
      closeIcon={<CloseOutlined onClick={onClose} />}
      open={open}
    >
      <Tabs
        defaultActiveKey={activeTabKey}
        items={tabItems}
        onChange={handleTabChange}
      />
    </Drawer>
  );
};
