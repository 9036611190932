import React, { useEffect, useState } from "react";

const TypedComponent = (text) => {
  const [displayText, setDisplayText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  const textArray = ["Future Of Trademarks Search"];
  const period = 2000;

  useEffect(() => {
    let typingTimeout;

    const handleTyping = () => {
      const i = loopNum % textArray.length;
      const fullText = textArray[i];

      setDisplayText((prev) => {
        if (isDeleting) {
          return fullText.substring(0, prev.length - 1);
        } else {
          return fullText.substring(0, prev.length + 1);
        }
      });

      setTypingSpeed(isDeleting ? 30 : 80);
    };

    typingTimeout = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(typingTimeout);
  }, [displayText, isDeleting, loopNum, typingSpeed]);

  return <span>{displayText}</span>;
};

export default TypedComponent;
