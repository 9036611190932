import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { UserOutlined } from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Avatar,
  Modal,
  Alert,
  Spin,
} from "antd";

//--------COMMON ACTIONS--------
import {
  logOut,
  getUserSubscriptions,
  getAlertMessage,
} from "../redux/actions/commonActions/actions";
import SignInPageImg from "../assets/images/option-1.png";
import { changeStateValue_L1 } from "../functions/commonFunctions/generalFunctions";
import ProductInformation from "../components/commonComponents/Modals/ProductInformation";
import TermsAndCondition from "../components/commonComponents/Modals/TermsAndCondition";
import PrivacyPolicy from "../components/commonComponents/Modals/PrivacyPolicy";
import { GET_SIGN_IN_ALERT } from "../redux/actions/commonActions/types";
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../constants/commonConstants/generalConstants";
import * as Sentry from "@sentry/browser";

const { Paragraph, Title, Text } = Typography;

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalsVisibility: {
        productInfo: false,
        product: "",
        termsAndCondition: false,
        privacyPolicy: false,
      },
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.userSubscriptions === null) {
        this.props.getUserSubscriptions();
        this.props.getAlertMessage(GET_SIGN_IN_ALERT, "sign_in");
      }
    } else {
      this.props.logOut();
    }
    if (window.Tawk_API !== undefined) {
      window.Tawk_API.showWidget();
    } else {
      //Chat Widget--------Tawk
      var Tawk_API = Tawk_API || {},
        Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/619205a96885f60a50bbcd33/1fkh6c8b3";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.userDetails?.name !== null && props?.userDetails?.email) {
      localStorage.setItem("userName", props?.userDetails?.name);
      localStorage.setItem("userEmail", props?.userDetails?.email);
      Sentry.setTag("userName", props?.userDetails?.name || "NA");
      Sentry.setTag("userEmail", props?.userDetails?.email || "NA");
    }
  }

  handleProductIntro(modalsVisibility, modal, value, product) {
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        product: product,
      },
    });
  }
  componentWillUnmount() {
    if (window.Tawk_API !== undefined) {
      window.Tawk_API.hideWidget();
    }
  }
  render() {
    const {
      userSubscriptions,
      userDetails,
      userSubscriptionStatus,
      signInAlert,
    } = this.props;
    return (
      <div className="Sign-In-Page vertical_scroll">
        <Layout>
          {userSubscriptionStatus === LOADING && (
            <div className="loading-spin">
              <Spin />
            </div>
          )}
          {userSubscriptionStatus === SUCCESS && (
            <div>
              {signInAlert.length > 0 && (
                <Alert
                  type="error"
                  message={
                    <div
                      style={{ textAlign: "center" }}
                      dangerouslySetInnerHTML={{ __html: signInAlert }}
                    ></div>
                  }
                />
              )}
              <header>
                <Row span={24} className="Mikelegal-logo">
                  <Col span={12}>
                    <Title level={2}>
                      <a
                        href="https://mikelegal.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        MikeLegal
                      </a>
                    </Title>
                  </Col>
                  <Col span={12}>
                    <div className="alignR">
                      <Avatar
                        size={60}
                        shape="circle"
                        className="avatar-logo"
                        onClick={() =>
                          this.changeStateValue_L1(
                            "modalsVisibility",
                            "profileInfo",
                            true,
                          )
                        }
                      >
                        {userDetails.picture ? (
                          <img
                            src={userDetails.picture}
                            style={{ width: "100%" }}
                            alt="user"
                          />
                        ) : (
                          <UserOutlined />
                        )}
                      </Avatar>
                    </div>
                  </Col>
                </Row>
              </header>
              <div>
                <Row span={24}>
                  <Col sm={24} md={12} className="SignIn-Image-Container">
                    <div className="SignIn-Image">
                      <img src={SignInPageImg} alt="" />
                    </div>
                  </Col>
                  <Col sm={24} md={12} className="SignIn-Image-Container">
                    <div className="Sign-In-Form">
                      <div className="Welcome-Heading">
                        <Title level={3}>
                          You can access your subscriptions by clicking on the
                          buttons below:
                        </Title>
                      </div>
                      <Row>
                        <Col span={24} className="product-container">
                          <Button
                            className={`product-btn ${
                              userSubscriptions.search.length > 0
                                ? ""
                                : "inactive-subscription-btn"
                            }`}
                            size="large"
                            type="primary"
                            onClick={
                              userSubscriptions.search.length > 0
                                ? () =>
                                    this.props.history.push(
                                      this.props.searchPath,
                                    )
                                : () =>
                                    this.handleProductIntro(
                                      "modalsVisibility",
                                      "productInfo",
                                      true,
                                      "Trademark Search",
                                    )
                            }
                          >
                            Trademark Search
                          </Button>
                        </Col>
                        <Col span={24} className="product-container">
                          <Button
                            className={`product-btn ${
                              userSubscriptions.watch.length > 0
                                ? ""
                                : "inactive-subscription-btn"
                            }`}
                            size="large"
                            type="primary"
                            onClick={
                              userSubscriptions.watch.length > 0
                                ? () =>
                                    this.props.history.push(
                                      "/watch/tm_applied/list_layout",
                                    )
                                : () =>
                                    this.handleProductIntro(
                                      "modalsVisibility",
                                      "productInfo",
                                      true,
                                      "Trademark Watch",
                                    )
                            }
                          >
                            Trademark Watch
                          </Button>
                        </Col>
                        <Col span={24} className="product-container">
                          <Button
                            className={`product-btn ${
                              userSubscriptions.manager.includes("TRADEMARK") ||
                              userSubscriptions.manager.includes(
                                "PSEUDO_TRADEMARK",
                              ) ||
                              userSubscriptions.manager.includes(
                                "CUSTOM_TRADEMARK",
                              ) ||
                              userSubscriptions.manager.includes(
                                "INTERNATIONAL_TRADEMARK",
                              )
                                ? ""
                                : "inactive-subscription-btn"
                            }`}
                            size="large"
                            type="primary"
                            onClick={
                              userSubscriptions.manager.includes("TRADEMARK") ||
                              userSubscriptions.manager.includes(
                                "PSEUDO_TRADEMARK",
                              )
                                ? () =>
                                    this.props.history.push(
                                      "/manager/trademark/your-trademark/dashboard",
                                    )
                                : userSubscriptions.manager.includes(
                                      "CUSTOM_TRADEMARK",
                                    )
                                  ? () =>
                                      this.props.history.push(
                                        "/manager/trademark/docketed-trademark/custom-trademark",
                                      )
                                  : userSubscriptions.manager.includes(
                                        "INTERNATIONAL_TRADEMARK",
                                      )
                                    ? () =>
                                        this.props.history.push(
                                          "/manager/trademark/docketed-trademark/international-trademark",
                                        )
                                    : () =>
                                        this.handleProductIntro(
                                          "modalsVisibility",
                                          "productInfo",
                                          true,
                                          "Trademark Manager",
                                        )
                            }
                          >
                            Trademark Manager
                          </Button>
                        </Col>
                        <Col span={24} className="product-container">
                          <Button
                            className={`product-btn ${
                              userSubscriptions.manager.includes("PATENT") ||
                              userSubscriptions.manager.includes(
                                "CUSTOM_PATENT",
                              ) ||
                              userSubscriptions.manager.includes(
                                "INTERNATIONAL_PATENT",
                              )
                                ? ""
                                : "inactive-subscription-btn"
                            }`}
                            size="large"
                            type="primary"
                            onClick={
                              userSubscriptions.manager.includes("PATENT") ||
                              userSubscriptions.manager.includes(
                                "PSEUDO_PATENT",
                              )
                                ? () =>
                                    this.props.history.push(
                                      "/manager/patent/your-patent/dashboard",
                                    )
                                : userSubscriptions.manager.includes(
                                      "CUSTOM_PATENT",
                                    )
                                  ? () =>
                                      this.props.history.push(
                                        "/manager/patent/docketed-patent/custom-patent",
                                      )
                                  : userSubscriptions.manager.includes(
                                        "INTERNATIONAL_PATENT",
                                      )
                                    ? () =>
                                        this.props.history.push(
                                          "/manager/patent/docketed-patent/international-patent",
                                        )
                                    : () =>
                                        this.handleProductIntro(
                                          "modalsVisibility",
                                          "productInfo",
                                          true,
                                          "Patent Manager",
                                        )
                            }
                          >
                            Patent Manager
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <footer>
                <Col
                  span={24}
                  className="footer-container"
                  style={{
                    marginTop: `${signInAlert.length > 0 ? "1em" : "4em"}`,
                  }}
                >
                  <ul>
                    <li>
                      <Text
                        strong
                        onClick={() =>
                          this.changeStateValue_L1(
                            "modalsVisibility",
                            "termsAndCondition",
                            true,
                          )
                        }
                      >
                        Terms And Conditions
                      </Text>
                    </li>
                    <li>
                      <Text
                        strong
                      >{`© ${new Date().getFullYear()} MikeLegal`}</Text>
                    </li>
                    <li>
                      <Text
                        strong
                        onClick={() =>
                          this.changeStateValue_L1(
                            "modalsVisibility",
                            "privacyPolicy",
                            true,
                          )
                        }
                      >
                        Privacy Policy
                      </Text>
                    </li>
                  </ul>
                </Col>
              </footer>
            </div>
          )}
          {userSubscriptionStatus === ERROR && (
            <div className="error-container" style={{ height: "100vh" }}>
              <Title level={4}>
                unfortunately an error has occurred please try again later. will
                connect you shortly.
              </Title>
            </div>
          )}
        </Layout>
        <Modal
          closable={false}
          footer={false}
          className="Profile-box"
          open={this.state.modalsVisibility.profileInfo}
          onOk={() =>
            this.changeStateValue_L1("modalsVisibility", "profileInfo", false)
          }
          onCancel={() =>
            this.changeStateValue_L1("modalsVisibility", "profileInfo", false)
          }
        >
          <div>
            {userDetails && (
              <div className="alignC">
                <Paragraph>
                  <Avatar size={100} shape="circle" className="avatar-logo">
                    {userDetails.picture ? (
                      <img
                        src={userDetails.picture}
                        style={{ width: "100%" }}
                        alt="user"
                      />
                    ) : (
                      <UserOutlined />
                    )}
                  </Avatar>
                </Paragraph>
                <Paragraph
                  style={{ fontSize: "14px" }}
                  className="Navbar-Profile-Details"
                >
                  {userDetails.email}
                </Paragraph>
                <Paragraph>
                  <Button type="primary" onClick={this.props.logOut}>
                    Log Out
                  </Button>
                </Paragraph>
              </div>
            )}
          </div>
        </Modal>
        <ProductInformation
          visible={this.state.modalsVisibility.productInfo}
          onCloseModal={() =>
            this.handleProductIntro("modalsVisibility", "productInfo", false)
          }
          product={this.state.modalsVisibility.product}
        />
        <PrivacyPolicy
          visible={this.state.modalsVisibility.privacyPolicy}
          onCloseModal={() =>
            this.changeStateValue_L1("modalsVisibility", "privacyPolicy", false)
          }
        />
        <TermsAndCondition
          visible={this.state.modalsVisibility.termsAndCondition}
          onCloseModal={() =>
            this.changeStateValue_L1(
              "modalsVisibility",
              "termsAndCondition",
              false,
            )
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getUserSubscriptions,
  logOut,
  getAlertMessage,
};

function mapStateToProps(state) {
  const { userSubscriptions, userDetails, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userSubscriptionStatus,
    searchPath: state.searchState.searchBar.searchPath,
    signInAlert: state.alertMessages.signIn,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
