import React from "react";
import { Dropdown, Button, Menu, Space, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getGridLayoutThreatData } from "../../../../../redux/actions/watchActions/gridDashboardActions";
import { updateWatchShortlist } from "../../../../../redux/actions/watchActions/watchActions";
import { ROOT_URL } from "../../../../../configs/rootConfigs";
import axios from "axios";

const UpdateGridShortlistedComponent = (props) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();
  const handleClickMenuItem = async (shortlistFlag) => {
    setLoading(true);
    if (props?.creationOrigin === "BRAND_COMPARE") {
      try {
        const response = await axios.put(
          `${ROOT_URL}/brand_compare/manage/activate_brand/`,
          {
            filter_flag: props?.selectAll,
            activate_flag: shortlistFlag,
            selected_ids: props?.selected,
            threat_flag: props?.threatFlag,
            filters: props?.filter,
          },
          {
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          },
        );
        if (response.data.success) {
          message.success(response.data.message);
          props?.onSuccess();
        } else {
          message.error("Failed to perform operation");
        }
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Failed to perform operation",
        );
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    } else {
      await dispatch(
        updateWatchShortlist({
          shortlistFlag: shortlistFlag,
          source: props?.source,
          creationOrigin: props?.creationOrigin,
          journalNumber: props?.journalNumber,
          selected: props?.selected,
          filterFlag: props?.filterFlag,
          filters: props?.filter,
          selectAll: props?.selectAll,
          applicationNumber: props?.applicationNumber,
        }),
      );
      dispatch(
        getGridLayoutThreatData(
          props?.applicationNumber,
          props?.journalNumber,
          props?.pageNumber,
          props?.filterFlag,
          props?.filter,
          window?.location,
        ),
      );
    }
    setLoading(false);
  };
  const dropdownMenu = () => (
    <Menu
      id={"tm_applied_ll_take_tour_step3"}
      disabled={loading}
      style={{
        position: "initial",
        overflowY: "auto",
        width: "250px",
        maxHeight: "200px",
      }}
    >
      <Menu.Item key={"ADD"} onClick={() => handleClickMenuItem(true)}>
        {/* {props?.addLabel !== null && props?.addLabel !== ""
          ? props?.addLabel
          : "Add"} */}
        {props?.addLabel ? props?.addLabel : "Add"}
      </Menu.Item>
      <Menu.Item key={"REMOVE"} onClick={() => handleClickMenuItem(false)}>
        {/* {props?.removeLabel !== null && props?.removeLabel !== ""
          ? props?.removeLabel
          : "Remove"} */}
        {props?.removeLabel ? props.removeLabel : "Remove"}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      dropdownRender={() => dropdownMenu()}
      open={openFilter}
      onOpenChange={(event) => {
        setOpenFilter(event);
      }}
      disabled={props?.disabled}
    >
      <Button
        id={"update_shortlist_menu"}
        style={{ background: "inherit" }}
        loading={loading}
      >
        <Space>
          {/* {props?.title !== null && props?.title !== ""
            ? props?.title
            : "Update Shortlist"} */}
          {props?.title ? props.title : "Update Shortlist"}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default UpdateGridShortlistedComponent;
