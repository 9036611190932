//--------LIBRARIES--------
import React, { Component } from "react";
import { Typography } from "antd";
import ErrorImg from "../../../assets/images/error.png";

const { Title } = Typography;

export class NetworkError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="error-page">
        <Title level={2}>Network Error</Title>
        <div className="error-image-container">
          <img src={ErrorImg} alt="" />
        </div>
        <div className="error-content-container">
          <p>
            Hey! Looks like there is an unexpected error. Kindly try after some
            time
          </p>
          <div>
            <Title level={4}>Please note:</Title>
            <p>
              1. Do not refresh the page as that would lose the marks you may
              have selected.
            </p>
            <p>
              2. Try clicking on the page number again to go to the desired page
              after some time.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export class BadRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="error-page">
        <Title level={2}>400 (Bad Request)</Title>
        <div className="error-image-container">
          <img src={ErrorImg} alt="" />
        </div>
        <div className="error-content-container">
          <p>Oops something went wrong here and are working on it!</p>
          <p>
            Please try again after sometime or reach out to{" "}
            <a href="mailto:team@Mikelegal.com">team@Mikelegal.com</a>
          </p>
        </div>
      </div>
    );
  }
}

export class PageNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="error-page">
        <div className="error-image-container">
          <img src={ErrorImg} alt="" />
        </div>
        <div className="error-content-container">
          <p>Oops something went wrong here and are working on it!</p>
          <p>
            Please try again after sometime or reach out to{" "}
            <a href="mailto:team@Mikelegal.com">team@Mikelegal.com</a>
          </p>
        </div>
      </div>
    );
  }
}
export class InternalServerError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="error-page">
        <div className="error-image-container">
          <img src={ErrorImg} alt="" />
        </div>
        <div className="error-content-container">
          <p>
            Hey! Looks like there is an unexpected error. Kindly try after some
            time
          </p>
          <div>
            <Title level={4}>Please note:</Title>
            <p>
              1. Do not refresh the page as that would lose the marks you may
              have selected.
            </p>
            <p>
              2. Try clicking on the page number again to go to the desired page
              after some time.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
