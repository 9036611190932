import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Layout, Row, Col, Card, Table } from "antd";

import { LOADING } from "../../../constants/commonConstants/generalConstants";

import EditProfile from "../../commonComponents/ProfileDetails/EditProfile";
import TeamMemberDetails from "../../commonComponents/ProfileDetails/TeamMember";
import EmailPreferences from "../../managerComponents/profile/EmailPreferences";

const { Content } = Layout;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (window.Tawk_API !== undefined) {
      window.Tawk_API.showWidget();
    } else {
      //Chat Widget--------Tawk
      (function () {
        const s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/619205a96885f60a50bbcd33/1fkh6c8b3";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }

  componentWillUnmount() {
    //Chat Widget--------Hider
    if (window.Tawk_API !== undefined) {
      window.Tawk_API.hideWidget();
    }
  }

  render() {
    const { userTrademarks, userTrademarksStatus } = this.props;
    const trademarksData =
      userTrademarks &&
      userTrademarks.length > 0 &&
      userTrademarks?.map((value, key) => ({ ...value, s_no: key + 1 }));
    const columnsUserTrademarks = [
      {
        title: "S.No",
        dataIndex: "s_no",
        key: "s_no",
        align: "left",
        width: "10%",
      },
      {
        title: "Trademarks",
        dataIndex: "term",
        key: "term",
        align: "left",
        width: "90%",
      },
    ];

    return (
      <div className="Profile">
        <Layout>
          <Content style={{ margin: "0px" }}>
            <Row
              className="content-container vertical_scroll"
              style={{
                height: `${
                  this.props.searchAlert.length > 0
                    ? "calc(100vh - 40px)"
                    : "calc(100vh - 0px)"
                }`,
              }}
            >
              <Col span={24}>
                {/* profile-edit */}
                <Row>
                  <Col span={24} className="m-top-20">
                    <EditProfile />
                  </Col>
                </Row>
                {/* profile-edit */}

                {/* TeamMember */}
                <Row>
                  <Col span={24} className="m-top-20">
                    <TeamMemberDetails product={"tm-search"} />
                  </Col>
                </Row>
                {/* TeamMember */}

                {/* your trademarkmark */}
                <Row>
                  <Col span={24} className="m-top-20">
                    <Card title="YOUR TRADEMARKS">
                      <Table
                        bordered
                        rowKey={(record) => record.id}
                        columns={columnsUserTrademarks}
                        dataSource={trademarksData}
                        scroll={{ y: "calc(70vh - 170px)" }}
                        loading={userTrademarksStatus === LOADING}
                        pagination={false}
                      />
                    </Card>
                  </Col>
                </Row>
                {/* your trademarkmark */}

                {/* EmailPreferences */}
                <Row>
                  <Col span={24}>
                    <EmailPreferences />
                  </Col>
                </Row>
                {/* EmailPreferences */}
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    );
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  const { userTrademarks, userTrademarksStatus } = state.searchState.searchBar;
  return deepFreeze({
    userTrademarks,
    userTrademarksStatus,
    searchAlert: state.alertMessages.search,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
