import React, { Component } from "react";
import { CarryOutTwoTone } from "@ant-design/icons";
import { Row, Col, Card, Tooltip, Table, Switch, Typography } from "antd";

import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";

const { Text } = Typography;

class HearingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipIdia: false,
      ipIndiaData: "",
    };
  }
  ipIndiaButtonHandler() {
    this.setState({ ipIndiaData: this.props.ipIndiaHearingData });
    this.state.ipIdia
      ? this.setState({ ipIdia: false })
      : this.setState({ ipIdia: true });
  }

  render() {
    const { hearingData, hearingType } = this.props;

    const hearingColumns = [
      {
        title: "HEARING OFFICER",
        dataIndex: "hearing_officer",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "HEARING LOCATION",
        dataIndex: "hearing_location",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "HEARING DATE",
        dataIndex: "hearing_date",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
      },

      {
        title: "HEARING TIME",
        dataIndex: "hearing_time",
        render: (text) => (
          <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
        ),
      },

      {
        title: "ACTION",
        render: (action) => {
          return (
            <div className="alignC">
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p>
                  <CarryOutTwoTone
                    onClick={this.props.changeStateValue_L0}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p onClick={() => this.props.handleEmail(action)}>
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
            // title = {hearingType}
            // headStyle={{background: '#e8e8e8'}}
            >
              {hearingType === "TLA HEARING" ? (
                <>
                  <Text strong>As Per Cause List</Text>
                  <Switch
                    className="change-manager-type"
                    onChange={() => this.ipIndiaButtonHandler()}
                    checked={this.state.ipIdia}
                  />
                  <Text strong>IP India</Text>
                </>
              ) : null}

              <Table
                rowKey={(record) => record.id}
                bordered
                columns={hearingColumns}
                dataSource={
                  this.state.ipIdia ? this.state.ipIndiaData : hearingData
                }
                scroll={{ y: "calc(70vh - 200px)" }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default HearingTable;
