//--------IP-SUITE TYPES--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../constants/commonConstants/generalConstants";
import { GET_USER_SUBSCRIPTIONS } from "../actions/commonActions/types";

const defaultState = {
  userSubscriptions: null,
  userDetails: null,
  userTeam: null,
  userQuota: null,
  userSubscriptionStatus: null,
  mfaRequired: null,
  mfaSetup: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_USER_SUBSCRIPTIONS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            userSubscriptionStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            mfaRequired: action.payload.mfa,
            mfaSetup: action.payload.mfa_setup,
            userSubscriptions: action.payload.subscriptions,
            userDetails: action.payload.user,
            userTeam: action.payload.team_email_lists,
            userQuota: action.payload.uploaded_documents_quota,
            userSubscriptionStatus: SUCCESS,
          };
        case ERROR:
          return {
            ...state,
            userSubscriptionStatus: ERROR,
          };
        default:
          break;
      }
      break;
    default:
      return state;
  }
}
