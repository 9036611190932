//--------Change any component state variable with the value provided: L0| Level0--------
export function changeStateValue_L0(stateKey, value) {
    this.setState({[stateKey]: value});
}

//--------Change any component state variable with the value provided: L1| Level1--------
export function changeStateValue_L1(stateKey, subStateKey, value) {
    this.setState({[stateKey]: {
        ...this.state[stateKey],
        [subStateKey]: value
    }});
}

//--------Change any component state variable with the value provided: L2| Level2--------
export function changeStateValue_L2(stateKey, subStateKey, subSubStateKey, value) {
    this.setState({[stateKey]: {
        ...this.state[stateKey],
        [subStateKey]: {
            ...this.state[stateKey][subStateKey],
            [subSubStateKey]: value
        }
    }});
}

//--------Change any component state variable with the value provided: L3| Level3--------
export function changeStateValue_L3(stateKey, subStateKey, subSubStateKey, subSubSubStateKey, value) {
    this.setState({[stateKey]: {
        ...this.state[stateKey],
        [subStateKey]: {
            ...this.state[stateKey][subStateKey],
            [subSubStateKey]: {
                ...this.state[stateKey][subStateKey][subSubStateKey],
                [subSubSubStateKey]: value
            }
        }
    }});
}

//--------Compare array elements--------
export function compareArrays(arr1, arr2, order = false){  // order = true; it will check if the arrays' elements have the same position or not
    
    if(!arr1 || !arr2){
        return false;
    }
    
    if(arr1?.length!==arr2?.length){
        return false;
    }
    var i=0;

    if(order){
        for(i=0; i<arr1.length; ++i){
            if(arr1[i]!==arr2[i]){
                return false;
            }
        }
    } else {
        for(i=0; i<arr1.length; ++i){
            if(!arr1.includes(arr2[i])){
                return false;
            }
        }
    }

    return true;
}

//--------Change any component state variable with the selected filter value--------
export function selectFilterValue(stateKey, subStateKey, value) {
    this.setState({[stateKey]: {
        ...this.state[stateKey],
        [subStateKey]: value
    }});
}