import { Button,Input} from 'antd'
import React,{useState} from 'react'
import ManagerData from './ManagerData';
import SearchData from './SearchData';
import WatchData from './WatchData';

function Faqs(props){
    let Data,text1,text2;
    if(props.location.pathname==='/search/faqs'){
        Data=SearchData;
        text1="Features of MikeTM Search";
        text2="Guide to MikeTM search features"

    }
    else if(props.location.pathname==='/watch/faqs'){
        Data=WatchData;
        text1="Options in MikeTM Watch";
        text2="Guide to MikeTM Watch";
    }
    else if(props.location.pathname==='/manager/faqs'){
            Data=ManagerData;
            text1="Features of MikeTM Manager";
            text2="Guide to MikeTM manager features"

    }
    const[filter,setFilter] = useState('');


    const Applystyle2=()=>{
        document.getElementById('btn2').style.backgroundColor='#C4C4C4';
        document.getElementById('btn3').style.backgroundColor='';
        document.getElementById('btn4').style.backgroundColor='';
        var elmnt = document.getElementById("scroller");
        elmnt.scrollTo({
            top: 20,
            behavior:'smooth',
          });
    }
    const Applystyle3=()=>{
        document.getElementById('btn2').style.backgroundColor='';
        document.getElementById('btn3').style.backgroundColor='#C4C4C4';
        document.getElementById('btn4').style.backgroundColor='';
        var elmnt = document.getElementById("scroller");
        if(props.location.pathname==='/watch/faqs'){
        elmnt.scrollTo({
            top: 340,
            behavior:'smooth',
          });
        }
        else{
            elmnt.scrollTo({
                top: 280,
                behavior:'smooth',
              }); 
        }
    }
    const Applystyle4=()=>{ 
        document.getElementById('btn2').style.backgroundColor='';
        document.getElementById('btn3').style.backgroundColor='';
        document.getElementById('btn4').style.backgroundColor='#C4C4C4';
        var elmnt = document.getElementById("scroller");
        if(props.location.pathname==='/watch/faqs'){
            elmnt.scrollTo({
                top: 650,
                behavior:'smooth',
              });
            }
        else if(props.location.pathname==='/manager/faqs'){
            elmnt.scrollTo({
                top: 750,
                behavior:'smooth',
              });
            }   
        else{
        elmnt.scrollTo({
            top: 585,
            behavior:'smooth',
          });
     }
        }
    
    const SearchFilter=(e)=>{
           setFilter(e.target.value);
    }
    
    let dataSearch=Data.Question.filter(item=>{
        return Object.keys(item).some(key=>
            item[key].toString().toLowerCase().includes(filter.toString().toLowerCase())
        )
    });

    let featureguide=Data.FeaturesGuide.filter(item=>{
        return Object.keys(item).some(key=>
            item[key].toString().toLowerCase().includes(filter.toString().toLowerCase())
        )
    });
   
    

    return (
        // <Layout >
        <div id="scroller"
            style={{
                height: "calc(140vh - 300px)",
                //overflowX:'hidden',
                overflowY:'auto'
            }}
        >
        <div className="mx-3 mt-5 col-lg-3 col-md-3" style={{position:'fixed'}}> 
            <div className='d-flex'>
            <Input style={{width:'70%'}} className="shadow-none" placeholder="Search" value={filter} onChange={(e)=>SearchFilter(e)}/>
            </div>
            <br />
            <Button type="link" id="btn2" className="my-3" style={{color:'black'}} onClick={Applystyle2}>{text1}</Button>
            <br />
            <Button type="link" id="btn3" style={{color:'black'}} onClick={Applystyle3}>{text2}</Button>
            <br />
            <Button type="link" id="btn4" className="my-3" style={{color:'black'}} onClick={Applystyle4}>FAQs</Button>
            <br />
            <div style={{height:"560px",borderLeft:"1px solid gray",position:'relative',left:'290px',bottom:'185px'}}></div>

        </div>
        
        <div className="col-lg-7 my-5"  style={{marginLeft:'400px',position:'relative'}}>
        {featureguide.map((val,key)=>{
                
                return (
                    <>
                    <br/>
                    <h2 className="mb-4" key={key}>{val.question}</h2>
                    {val.answers.map((ans,key)=>{
                    return(
                        <p  key={key}>{ans.answer}</p>   
                    )
                    })}
                    </>
                )

            })}
            <br/>
            <br/>
            
            {dataSearch.map((val,key)=>{
                
                return (
                    <>
                    <br/>
                    <h2  style={{fontWeight:'700'}} key={key}>{val.heading}</h2>
                    <h3  className="mt-5" key={key}>{val.question}</h3>
                    <br/>
                    <img src={val.img} className="w-100 mx-3 mb-5"/>
                    {val.answers.map((ans,key)=>{
                    return(
                        <p key={key}>{ans.answer}</p>   
                    )
                    })}
                    </>
                )

            })}
            <br/>
            <br/>
        </div>
        </div>
        // </Layout>
    )
}

export default Faqs;





