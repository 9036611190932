import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../constants/commonConstants/generalConstants";
import {
  CLIENT_EMAIL_TM,
  CLIENT_EMAIL_PT,
  CLIENT_EMAIL_WT,
  CLIENT_EMAIL_TM_PROTECT,
  CREATE_CLIENT_EMAIL,
  EDIT_CLIENT_EMAIL,
  DELETE_CLIENT_EMAIL,
  SET_STORE,
  CLIENT_EMAIL_PROPRIETOR_FILTERS,
  CLIENT_EMAIL_INVENTOR_FILTERS,
  CLIENT_EMAIL_APPLICANT_FILTERS,
  CREATE_CLIENT_EMAIL_REPORT,
} from "../actions/commonActions/types";
import {
  TM_DefaultSelectedFilters,
  DefaultClientEmailObject,
} from "../../constants/managerConstants/trademarkConstants";

const defaultState = {
  TRADEMARK: {
    clientEmail: DefaultClientEmailObject,
    clientEmailStatus: false,
    pageNumber: 0,
    lastAppliedFilters: {
      ...TM_DefaultSelectedFilters,
      filterFlag: false,
    },
  },
  PATENT: {
    clientEmail: DefaultClientEmailObject,
    clientEmailStatus: false,
    pageNumber: 0,
    lastAppliedFilters: {
      ...TM_DefaultSelectedFilters,
      filterFlag: false,
    },
  },
  JOURNAL_WATCH: {
    clientEmail: DefaultClientEmailObject,
    clientEmailStatus: false,
    pageNumber: 0,
    lastAppliedFilters: {
      ...TM_DefaultSelectedFilters,
      filterFlag: false,
    },
  },
  TM_PROTECT: {
    clientEmail: DefaultClientEmailObject,
    clientEmailStatus: false,
    pageNumber: 0,
    lastAppliedFilters: {
      ...TM_DefaultSelectedFilters,
      filterFlag: false,
    },
  },
  filters: {
    PROPRIETORFilterData: [],
    PROPRIETORFilterDataCount: 0,
    PROPRIETORFilterStatus: null,

    APPLICANTFilterData: [],
    APPLICANTFilterDataCount: 0,
    APPLICANTFilterStatus: null,

    INVENTORFilterData: [],
    INVENTORFilterDataCount: 0,
    INVENTORFilterStatus: null,
  },
  createClientEmailStatus: null,
  editClientEmailStatus: null,
  deleteClientEmailStatus: null,
  clientEmailMessage: null,
  manageClientEmailLastAppliedFilters: {
    ...TM_DefaultSelectedFilters,
    filterFlag: false,
  },
  pageNumber: 0,

  activeTab: "TRADEMARK",
  selectedSubscription: "TRADEMARK",
  mmType: "Portfolio trademark",
  selectedEmails: [],
  clientEmailUploadData: { message: "", errors: [] },

  editEmailData: {
    id: "",
    email: "",
    person_name: "",
    company_name: "",
  },
  selectedEmailDetail: {
    application_number: "",
    applied_for: "",
    associated_image: "",
    client_emails: "",
    id: "",
    proprietors: "",
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLIENT_EMAIL_TM:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            TRADEMARK: {
              ...state.TRADEMARK,
              clientEmailStatus: LOADING,
            },
          };

        case SUCCESS:
          return {
            ...state,
            TRADEMARK: {
              ...state.TRADEMARK,
              clientEmail: action.payload,
              clientEmailStatus: action.status,
              manageClientEmailType: action.clientEmailType,
              manageClientEmailLastAppliedFilters: action.lastAppliedFilters,
              pageNumber: action.pageNumber,
            },
            pageNumber: action.pageNumber,
          };

        case ERROR:
          return {
            ...state,
            TRADEMARK: {
              ...state.TRADEMARK,
              clientEmailMessage:
                action && action?.payload && action?.payload?.error,
              clientEmailStatus: ERROR,
              clientEmail: DefaultClientEmailObject,
            },
          };

        default:
          break;
      }
      break;

    case CLIENT_EMAIL_PT:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            PATENT: {
              ...state.PATENT,
              clientEmailStatus: LOADING,
            },
          };

        case SUCCESS:
          return {
            ...state,
            PATENT: {
              ...state.PATENT,
              clientEmail: action.payload,
              clientEmailStatus: action.status,
              manageClientEmailType: action.clientEmailType,
              manageClientEmailLastAppliedFilters: action.lastAppliedFilters,
              pageNumber: action.pageNumber,
            },
            pageNumber: action.pageNumber,
          };

        case ERROR:
          return {
            ...state,
            PATENT: {
              ...state.PATENT,
              clientEmailMessage:
                action && action?.payload && action?.payload?.error,
              clientEmailStatus: ERROR,
              clientEmail: DefaultClientEmailObject,
            },
          };

        default:
          break;
      }
      break;

    case CLIENT_EMAIL_WT:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            JOURNAL_WATCH: {
              ...state.JOURNAL_WATCH,
              clientEmailStatus: LOADING,
            },
          };

        case SUCCESS:
          return {
            ...state,
            JOURNAL_WATCH: {
              ...state.JOURNAL_WATCH,
              clientEmail: action.payload,
              clientEmailStatus: action.status,
              manageClientEmailType: action.clientEmailType,
              manageClientEmailLastAppliedFilters: action.lastAppliedFilters,
              pageNumber: action.pageNumber,
            },
            pageNumber: action.pageNumber,
          };

        case ERROR:
          return {
            ...state,
            JOURNAL_WATCH: {
              ...state.JOURNAL_WATCH,
              clientEmailMessage:
                action && action?.payload && action?.payload?.error,
              clientEmailStatus: ERROR,
              clientEmail: DefaultClientEmailObject,
            },
          };

        default:
          break;
      }
      break;

    case CLIENT_EMAIL_TM_PROTECT:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            TM_PROTECT: {
              ...state.TM_PROTECT,
              clientEmailStatus: LOADING,
            },
          };

        case SUCCESS:
          return {
            ...state,
            TM_PROTECT: {
              ...state.TM_PROTECT,
              clientEmail: action.payload,
              clientEmailStatus: action.status,
              manageClientEmailType: action.clientEmailType,
              manageClientEmailLastAppliedFilters: action.lastAppliedFilters,
              pageNumber: action.pageNumber,
            },
            pageNumber: action.pageNumber,
          };

        case ERROR:
          return {
            ...state,
            TM_PROTECT: {
              ...state.TM_PROTECT,
              clientEmailMessage:
                action && action?.payload && action?.payload?.error,
              clientEmailStatus: ERROR,
              clientEmail: DefaultClientEmailObject,
            },
          };

        default:
          break;
      }
      break;

    case CLIENT_EMAIL_PROPRIETOR_FILTERS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            filters: {
              ...state.filters,
              PROPRIETORFilterStatus: action.status,
              filterStatus: action.status,
            },
          };
        case SUCCESS:
          return {
            ...state,
            filters: {
              ...state.filters,
              PROPRIETORFilterData: action.paginate
                ? [
                    ...new Set([
                      ...state.filters.PROPRIETORFilterData,
                      ...action.payload?.filters?.data,
                    ]),
                  ]
                : action.payload?.filters?.data,
              PROPRIETORFilterDataCount: action.payload?.filters?.count || 0,
              PROPRIETORFilterStatus: action.status,
              filterStatus: action.status,
            },
          };
        case ERROR:
          return {
            ...state,
            clientEmailMessage:
              (action && action?.payload && action?.payload?.error) ||
              "Something Went Wrong",
            PROPRIETORFilterStatus: ERROR,
          };
        default:
          break;
      }
      break;
    case CLIENT_EMAIL_APPLICANT_FILTERS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            filters: {
              ...state.filters,
              APPLICANTFilterStatus: action.status,
              filterStatus: action.status,
            },
          };
        case SUCCESS:
          return {
            ...state,
            filters: {
              ...state.filters,
              APPLICANTFilterData: action.paginate
                ? [
                    ...new Set([
                      ...state.filters.APPLICANTFilterData,
                      ...action.payload?.filters?.data,
                    ]),
                  ]
                : action.payload?.filters?.data,
              APPLICANTFilterDataCount: action.payload?.filters?.count || 0,
              APPLICANTFilterStatus: action.status,
              filterStatus: action.status,
            },
          };
        case ERROR:
          return {
            ...state,
            clientEmailMessage:
              (action && action?.payload && action?.payload?.error) ||
              "Something Went Wrong",
            APPLICANTFilterStatus: ERROR,
          };
        default:
          break;
      }
      break;
    case CLIENT_EMAIL_INVENTOR_FILTERS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            filters: {
              ...state.filters,
              INVENTORFilterStatus: action.status,
              filterStatus: action.status,
            },
          };
        case SUCCESS:
          return {
            ...state,
            filters: {
              ...state.filters,
              INVENTORFilterData: action.paginate
                ? [
                    ...new Set([
                      ...state.filters.INVENTORFilterData,
                      ...action.payload?.filters?.data,
                    ]),
                  ]
                : action.payload?.filters?.data,
              INVENTORFilterDataCount: action.payload?.filters?.count || 0,
              INVENTORFilterStatus: action.status,
              filterStatus: action.status,
            },
          };
        case ERROR:
          return {
            ...state,
            clientEmailMessage:
              (action && action?.payload && action?.payload?.error) ||
              "Something Went Wrong",
            INVENTORFilterStatus: ERROR,
          };
        default:
          break;
      }

      break;

    case CREATE_CLIENT_EMAIL:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            createClientEmailStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            // clientEmail: {
            //   ...state.clientEmail,
            //   email_template: [
            //     ...state.clientEmail.email_template,
            //     action.payload.email_template,
            //   ],
            // },
            clientEmailMessage: action.payload.message,
            createClientEmailStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            createClientEmailStatus: ERROR,
            clientEmailUploadData: action.payload,
          };

        default:
          break;
      }
      break;

    case EDIT_CLIENT_EMAIL:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            editClientEmailStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            clientEmailMessage: action.payload.message,
            editClientEmailStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            clientEmailMessage: action.payload.message,
            editClientEmailStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case DELETE_CLIENT_EMAIL:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteClientEmailStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            clientEmailMessage: action.payload.message,
            deleteClientEmailStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            clientEmailMessage: action.payload.message,
            deleteClientEmailStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case CREATE_CLIENT_EMAIL_REPORT:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            createCEReportStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            createCEReportStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            createCEReportStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case SET_STORE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
