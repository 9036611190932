import React, { useState, useEffect } from "react";
import {
  Drawer,
  Row,
  Col,
  Input,
  Radio,
  Button,
  Checkbox,
  Alert,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGridLayoutData } from "../../../../../redux/actions/watchActions/gridDashboardActions";
import { createCustomReport } from "../../../../../redux/actions/watchActions/watchActions";

const CheckboxGroup = Checkbox.Group;

const CreateReportDrawer = (props) => {
  const {
    open,
    setOpen,
    journal,
    selected,
    generateFilterObject,
    selectAll,
    report_columns = [],
    source = "GRID_LAYOUT",
    creation_origin = "IMAGE_LABEL",
    filterApplied,
  } = props;
  const [reportName, setReportName] = useState("");
  const [reportFormat, setReportFormat] = useState("PDF");
  const [selectedYourColumns, setSelectedYourColumns] = useState([]);
  const [selectedThreatColumns, setSelectedThreatColumns] = useState([]);
  let urlKey = window.location.pathname.split("/")[2] || "";
  let dashboardLayout = window.location.pathname.split("/")[3] || "";

  const { temp_threat_filters } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const dispatch = useDispatch();
  const yourMarkOptions = report_columns.filter(
    (item) => !item.includes("Threat"),
  );
  const threatMarkOptions = report_columns.filter((item) =>
    item.includes("Threat"),
  );

  useEffect(() => {
    setSelectedYourColumns(yourMarkOptions);
    setSelectedThreatColumns(threatMarkOptions);
  }, [yourMarkOptions.length, threatMarkOptions.length]);

  const handleCreateReport = async () => {
    setOpen(false);
    if (selectAll && !filterApplied) {
      notification["warning"]({
        message:
          "You can access this report from 'Automated Report' section in Reports",
        duration: 2,
      });
      return;
    }
    let body = {
      // journal_number: journal,
      filter_flag: false,
      data: selected.data,
      source: source,
      report_format: reportFormat,
      report_name: reportName,
      creation_origin: creation_origin,
      report_type: "Custom",
      report_columns:
        reportFormat === "EXCEL"
          ? [...selectedYourColumns, ...selectedThreatColumns]
          : [],
    };
    if (urlKey === "tm_protect") {
      body = {
        ...body,
        cease_desist_report_id: journal,
      };
    } else {
      body = {
        ...body,
        journal_number: journal,
      };
    }
    if (selectAll) {
      let modifiedBody = {
        ...body,
        filter_flag: true,
        filters: generateFilterObject(),
      };
      await dispatch(createCustomReport(modifiedBody));
      dispatch(
        getGridLayoutData(
          journal,
          props?.pageNumber,
          true,
          generateFilterObject(),
          window?.location,
        ),
      );
    } else {
      const secondaryFilteredData = {};
      Object.entries(props.selectedData?.data).map((item) => {
        if (item[1]?.select_all || item[1]?.threats?.length > 0) {
          secondaryFilteredData[item[0]] = {
            filter_flag: item[1]?.select_all || false,
            selected_ids: item[1]?.threats || [],
            filters:
              (temp_threat_filters[urlKey] &&
                temp_threat_filters[urlKey][item[0]] &&
                temp_threat_filters[urlKey][item[0]]) ||
              {},
          };
        }
      });
      let modifiedBody = {
        ...body,
        data: secondaryFilteredData,
        filters: generateFilterObject(),
      };
      await dispatch(createCustomReport(modifiedBody));
      dispatch(
        getGridLayoutData(
          journal,
          props?.pageNumber,
          true,
          generateFilterObject(),
          window?.location,
        ),
      );
    }
  };

  return (
    <Drawer
      title={<h3>Custom Report Details</h3>}
      placement="right"
      size={"large"}
      closable={false}
      open={open}
      onClose={() => setOpen(false)}
      footer={
        <>
          <Button type="primary" ghost onClick={() => handleCreateReport()}>
            Confirm
          </Button>
          &emsp;
          <Button type="primary" danger ghost onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </>
      }
    >
      <Row>
        <Col span={16}>
          <p className="fw_500">Enter the name of your report:</p>
          <Input
            onChange={(event) => {
              if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                setReportName(event.target.value);
              }
            }}
            value={reportName}
            placeholder="Report's name (Optional)"
          />
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <p className="fw_500">Choose your preferred report format:</p>
          <Radio.Group
            value={reportFormat}
            onChange={(event) => setReportFormat(event.target.value)}
            size="small"
          >
            <Radio value={"PDF"} style={{ fontWeight: 400 }}>
              PDF
            </Radio>
            <Radio value={"EXCEL"} style={{ fontWeight: 400 }}>
              EXCEL
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      {reportFormat === "EXCEL" && (
        <Row className="m-top-20">
          <p className="fw_500">
            Choose your preferred Tables for your Mark and Threat Marks:
          </p>
          <Col span={24}>
            <Alert
              message="INFO: By default the report will contain all columns."
              type="warning"
            />
          </Col>
          <Col
            span={12}
            className="m-top-20"
            style={{ borderRight: "1px solid lightGrey" }}
          >
            <p className="fw_600">Your Mark</p>
            <CheckboxGroup
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="checkbox-custom-dropdown"
              onChange={(checkedValues) =>
                setSelectedYourColumns(checkedValues)
              }
              value={selectedYourColumns}
              options={yourMarkOptions}
            />
          </Col>
          <Col span={12} className="m-top-20" style={{ paddingLeft: "10px" }}>
            <p className="fw_600">Threat Mark</p>
            <CheckboxGroup
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="checkbox-custom-dropdown"
              onChange={(checkedValues) =>
                setSelectedThreatColumns(checkedValues)
              }
              value={selectedThreatColumns}
              options={threatMarkOptions}
            />
          </Col>
        </Row>
      )}
    </Drawer>
  );
};

export default CreateReportDrawer;
