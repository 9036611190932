import { ERROR, LOADING, SUCCESS } from '../../constants/commonConstants/generalConstants';
import { GET_EMAIL_TEMPLATE_DATA, CLEAR_EMAIL_TEMPLATE_DATA } from '../actions/commonActions/types';

const defaultState = {
    emailTemplateData: null,
    emailTemplateDataStatus: null,
    createEmailTemplateDataStatus: null,
    editEmailTemplateDataStatus: null,
    deleteEmailTemplateDataStatus: null,
    emailTemplateDataMessage: null,
};

export default function(state = defaultState, action){
    switch (action.type) {
        case CLEAR_EMAIL_TEMPLATE_DATA:
            return {
                ...state,
                emailTemplateData: null,
                emailTemplateDataStatus: null,
                createEmailTemplateDataStatus: null,
                editEmailTemplateDataStatus: null,
                deleteEmailTemplateDataStatus: null,
                emailTemplateDataMessage: null,          
              };
        case GET_EMAIL_TEMPLATE_DATA:
            switch (action.status) {
                case LOADING:
                    return {
                        ...state,
                        createEmailTemplateDataStatus: LOADING,
                    };

                case SUCCESS:
                    return {
                        ...state,
                        emailTemplateData: action.payload?.data,
                        emailTemplateDataStatus: action.status,
                    };

                case ERROR:
                    return {
                        ...state,
                        emailTemplateDataMessage: action?.payload?.message,
                        createEmailTemplateDataStatus: ERROR,
                    };
            
                default:
                    break;
            }
            break;

        default:
            return state;
    }
}