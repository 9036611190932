export const listSampleObject = {
  id: 123456789,
  mark: {
    id: 987654321,
    application_number: 1234567890,
    applied_for: "SampleTrademark",
    associated_class: [1, 2],
    associated_image: "https://example.com/image.png",
    proprietor: [
      {
        id: 123456,
        name: "Sample Proprietor",
        address: "123 Sample St, Sample City, SA 12345",
      },
    ],
    date_of_usage: "01-01-2023",
    date_of_application: "01-01-2023",
    description: "Sample description of the trademark.",
    tags: [
      {
        id: 1,
        name: "Tag1",
        color_code: "#000000",
      },
      {
        id: 2,
        name: "Tag2",
        color_code: "#FFFFFF",
      },
    ],
  },
  similarity: {
    score: 95,
    focus_word: "sample",
    threat_focus_word: null,
    algorithm: "SAMPLE_ALGORITHM",
    image_text: "SampleText",
    threat_image_text: null,
  },
  created_custom_report: true,
  is_shortlist: true,
  threat: {
    id: 987654321,
    application_number: 1234567890,
    applied_for: "SampleThreat",
    associated_image: "https://example.com/threat_image.png",
    associated_class: [3, 4],
    proprietor: [
      {
        id: 654321,
        name: "Sample Threat Proprietor",
        address: "456 Sample St, Sample City, SA 54321",
      },
    ],
    date_of_usage: "01-01-2022",
    date_of_application: "01-01-2022",
    description: "Sample description of the threat.",
    type_name: "Sample Type",
  },
  application_number: 1234567890,
  applied_for: "SampleApplication",
  associated_image: null,
  associated_class: [5, 6],
  proprietor: [
    {
      id: 13579,
      name: "Sample Application Proprietor",
      address: "789 Sample St, Sample City, SA 13579",
    },
  ],
  date_of_usage: "Proposed",
  date_of_application: "01-01-2021",
  description: "Sample description of the application.",
  tm_type: "Sample Type",
  onw_brand_detail: {
    id: 3001,
    application_number: "1",
    brand_name: "threat-sample-brand-1",
    address: "sample - addr-1",
    proprietor: [
      {
        id: 6352822,
        name: "sample-prop-1",
        address: null,
      },
    ],
    date_of_usage: "2024-11-22",
    date_of_application: "2021-12-23",
    associated_class: [7],
    is_active: true,
  },
  threat_brand_detail: {
    id: 2984,
    application_number: "700700",
    brand_name: "sample-brand-1",
    address: "sample-addr-1",
    proprietor: [
      {
        id: 6352872,
        name: "sample-prop 1",
        address: null,
      },
    ],
    date_of_usage: "2022-12-20",
    date_of_application: "2023-01-01",
    associated_class: [45],
    is_active: true,
  },
};
