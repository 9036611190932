//--------LIBRARIES--------
import Axios from "axios";
//--------RT_CONFIG--------
import { ROOT_URL } from "../../../configs/rootConfigs";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE TYPES--------
import { GET_EMAIL_PREFERENCE, SAVE_EMAIL_PREFERENCE } from "./types";

import { checkAuthorisation } from "./actions";

//--------Email-Preference: Get-Email-Preferences--------
export function getEmailPreferences() {
  return async (dispatch) => {
    dispatch({ type: GET_EMAIL_PREFERENCE, status: LOADING });
    try {
      let response = await Axios.get(`${ROOT_URL}/email_preferences/`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: GET_EMAIL_PREFERENCE,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: GET_EMAIL_PREFERENCE,
        status: ERROR,
        payload: err.response?.data,
      });
    }
  };
}

//--------Email-Preference: Save-Email-Preferences--------
export function saveEmailPreferences({ email_preferences, product }) {
  return async (dispatch) => {
    dispatch({ type: SAVE_EMAIL_PREFERENCE, status: LOADING });

    try {
      let response;
      response = await Axios.put(
        `${ROOT_URL}/email_preferences/`,
        { email_preferences },
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );

      dispatch({
        type: SAVE_EMAIL_PREFERENCE,
        status: SUCCESS,
        payload: response?.data,
        data: { email_preferences, product },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: SAVE_EMAIL_PREFERENCE, status: ERROR });
    }
  };
}
