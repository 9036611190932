import React, { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { addClientContactData } from "../../../../../redux/actions/commonActions/masterActions/clientContact";
import ClientBulkErrorScreen from "../../ClientMaster/BulkUpload/ClientBulkErrorScreen";

const { Text } = Typography;
const { Panel } = Collapse;
const { Link } = Typography;

const ClientContactAddComponent = ({ open, setOpen, client_id }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [lengthOfArrayOfData, setLengthOfArrayOfData] = useState(1);
  const [warningList, setWarningList] = useState([]);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  const [detailList, setDetailList] = useState({});
  const [openDetailListPopUp, setOpenDetailListPopUp] = useState(false);
  const [loading, setLoading] = useState(false);

  const { metadata } = useSelector(
    (state) => state.masters.clientMaster.clientContact,
  );
  const { pageNumber } = metadata;

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    setLoading(true);
    let body = {
      client_id,
      contact_person: values?.contact_person?.length
        ? values.contact_person
        : [],
      force_update: isForceUpdate,
    };
    dispatch(
      addClientContactData(body, pageNumber, {
        onSuccess: (successResponse) => {
          notification["success"]({
            message:
              successResponse?.message ?? "Contact Created Successfully!",
          });
          setLoading(false);
          onClose();
        },
        onError: (errorResponse) => {
          setLoading(false);
          notification["error"]({
            message: errorResponse?.message ?? "Something went wrong!",
          });
          if (errorResponse?.payload?.warning_list?.length) {
            setIsForceUpdate(true);
          }
          errorResponse?.payload?.warning_list?.length
            ? setWarningList(errorResponse?.payload?.warning_list)
            : setWarningList([]);
        },
      }),
    );
  };

  const renderLabel = (text) => (
    <Text strong style={{ margin: 0, padding: 0 }}>
      {text}
    </Text>
  );

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues?.contact_person) {
      setIsForceUpdate(false);
      setLengthOfArrayOfData(allValues?.contact_person?.length ?? 1);
    }
  };

  const warningDescription = (
    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
      <ul>
        {warningList.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );

  const customWarningPanelStyle = {
    backgroundColor: "#FFF6E6",
    border: "1px solid #FFA700",
  };

  const customWarningHeaderStyle = {
    color: "#B57701",
  };

  const handleViewInLargeScreenClicked = (e, type) => {
    e.stopPropagation();
    setOpenDetailListPopUp(true);
    switch (type) {
      case "warning":
        {
          setDetailList({
            type: "warning",
            heading: "Warnings in current excel.",
            list: warningList,
          });
        }
        break;
      default:
        return null;
    }
  };

  const warningArea = (
    <div style={{ margin: "10px 0px" }}>
      <Collapse
        defaultActiveKey={warningList?.length ? "1" : "0"}
        expandIconPosition={"start"}
      >
        <Panel
          header={
            <div style={customWarningHeaderStyle}>
              Warnings in current excel.
            </div>
          }
          key="1"
          extra={
            <div style={customWarningHeaderStyle}>
              {warningList?.length ?? 0}{" "}
              {warningList?.length ? (
                <Link
                  onClick={(event) =>
                    handleViewInLargeScreenClicked(event, "warning")
                  }
                  style={{ color: "blue" }}
                >
                  ( View in large screen )
                </Link>
              ) : null}
            </div>
          }
          style={customWarningPanelStyle}
        >
          {warningDescription}
        </Panel>
      </Collapse>
    </div>
  );

  const propsForErrorScreen = {
    data: detailList,
    open: openDetailListPopUp,
    setOpen: setOpenDetailListPopUp,
  };

  return (
    <>
      {openDetailListPopUp && (
        <ClientBulkErrorScreen {...propsForErrorScreen} />
      )}
      <Modal
        title={"Add Contact Person Name"}
        centered
        open={open}
        onCancel={onClose}
        footer={[
          <Button
            key="cancel"
            loading={loading}
            disabled={loading}
            type="default"
            onClick={onClose}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={() => form.submit()}
          >
            {"Add"}
          </Button>,
        ]}
        bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
      >
        <Form
          form={form}
          layout="vertical"
          style={{ margin: "10px" }}
          onFinish={handleSubmit}
          onValuesChange={handleValuesChange}
          initialValues={{
            contact_person: [{ name: "", email: "", phone: "" }],
          }}
        >
          <Form.List name="contact_person">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restFields }) => (
                  <Row
                    key={key}
                    gutter={[8, 8]}
                    style={
                      lengthOfArrayOfData > 1
                        ? {
                            borderBottom: "1px solid #d9d9d9",
                            marginBottom: 10,
                          }
                        : {}
                    }
                  >
                    <Col span={24}>
                      <Form.Item
                        {...restFields}
                        label={renderLabel("Contact Person Name")}
                        name={[name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Name!",
                          },
                        ]}
                        style={{ marginBottom: 5 }}
                      >
                        <Input placeholder="Enter Contact Person Name" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restFields}
                        label={renderLabel("Email ID")}
                        name={[name, "email"]}
                        rules={[
                          {
                            type: "email",
                            message: "Please Enter Valid Email ID!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Email ID" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restFields}
                        label={renderLabel("Phone Number")}
                        name={[name, "phone"]}
                        rules={[
                          {
                            max: 15,
                            message: "Maximum limit exceeded.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Phone Number"
                          onInput={(e) =>
                            (e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              "",
                            ))
                          }
                        />
                      </Form.Item>
                    </Col>
                    {lengthOfArrayOfData > 1 && (
                      <Col
                        span={2}
                        style={{ display: "flex", verticalAlign: "middle" }}
                      >
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                    )}
                  </Row>
                ))}
                <Form.Item style={{ marginBottom: 5 }}>
                  <Button
                    type="link"
                    onClick={() => add({ name: "", email: "", phone: "" })}
                    icon={<PlusOutlined />}
                  >
                    Add Another Contact Person
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          {warningList?.length ? warningArea : null}
        </Form>
      </Modal>
    </>
  );
};

export default ClientContactAddComponent;
