import React, { useEffect } from "react";
import { Alert, Spin } from "antd";

const SmartRender = (status, mainContent) => {
  useEffect(() => {}, []);
  if (status && status === "ERROR") {
    return (
      <div style={{ margin: "16px 0px" }}>
        <Alert
          message={
            <>
              There was some technical issue.
              <p>
                Please try again after sometime or reach out to{" "}
                <a href="mailto:team@Mikelegal.com">team@Mikelegal.com</a>
              </p>
            </>
          }
          type="error"
          showIcon
        />
      </div>
    );
  } else if (status && status === "LOADING") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "3em",
        }}
      >
        <Spin />
      </div>
    );
  }

  return mainContent;
};

export default SmartRender;
