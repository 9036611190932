//--------LIBRARIES--------
import Axios from "axios";
import { batch } from "react-redux";
import { notification, message } from "antd";

//--------RT_CONFIG--------
import { WAT_RT_URL } from "../../../configs/rootConfigs";

//--------GRID LAYOUT TYPES--------
import {
  GL_GET_THREAT_TRADEMARKS,
  GL_GET_IMAGE_TEXT,
  GL_GET_IMAGE_TEXT_TRADEMARKS,
  GL_GET_IMAGE_LABEL,
  GL_GET_IMAGE_LABEL_TRADEMARKS,
  GL_GET_TM_PROTECT,
  GL_GET_TM_PROTECT_TRADEMARKS,
  SET_CURRENT_JOURNAL,
  SET_CURRENT_DATE_RANGE,
} from "./types";

//--------COMMON IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
  PROGRESSING,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../commonActions/actions";

//--------Get Threat Trademarks for ThreatsGrid--------
export function GL_getThreatTrademarks(
  applicationNumber,
  journalNumber,
  pageNumber,
  filterFlag,
  filters
) {
  const threat_classes = `${filters.trademarkClasses}`;
  const trademark_types = `${filters.trademarkTypes}`;
  const proprietors = `${filters.proprietors}`;
  const trademark_categories = `${filters.trademarkCategory}`;
  const trademark_offices = `${filters.trademarkOffices}`;
  const infringement_types = `${filters.infringementTypes}`;
  const threat_similarity = `${filters.threatsSimilarity.lowerLimit}to${filters.threatsSimilarity.upperLimit}`;
  const threat_desc_contains_term = `${filters.threat_desc_contains_term}`;
  const searchType = `${filters.secondarySearchType}`;

  return async (dispatch) => {
    let layout_url = `${WAT_RT_URL}/grid_layout/${applicationNumber}/mark-reports/?`;
    let response;
    let params = {
      journal_number: journalNumber,
      page: pageNumber,
      filter_flag: filterFlag ? true : null,
      threat_classes: filterFlag ? threat_classes : null,
      trademark_types: filterFlag ? trademark_types : null,
      proprietors: filterFlag ? proprietors : null,
      trademark_categories: filterFlag ? trademark_categories : null,
      trademark_offices: filterFlag ? trademark_offices : null,
      infringement_types: filterFlag ? infringement_types : null,
      threat_similarity: filterFlag ? threat_similarity : null,
      date_of_application: filterFlag ? filters.date_of_application : null,
      date_of_usage: filterFlag ? filters.date_of_usage : null,
      date_of_usage_proposed: filterFlag
        ? filters.date_of_usage_proposed
        : null,
      search_term:
        filterFlag && searchType === "Application No/Name"
          ? filters.searchTerm
          : null,
      red_band: filterFlag ? (filters.redBand ? true : null) : null,
      exclude_same_class: filterFlag
        ? filters.excludeClass
          ? true
          : null
        : null,
      threat_desc_contains_term:
        filterFlag && searchType === "Your Threat Description Search"
          ? threat_desc_contains_term
          : null,
    };
    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }
    if (layout_url.length <= 2048) {
      try {
        dispatch({ type: GL_GET_THREAT_TRADEMARKS, status: LOADING });
        response = await Axios.get(
          `${WAT_RT_URL}/grid_layout/${applicationNumber}/mark-reports/`,
          {
            params,
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );

        switch (response.status) {
          case 200:
            dispatch({
              type: GL_GET_THREAT_TRADEMARKS,
              status: SUCCESS,
              payload: response?.data,
              pageNumber,
              lastAppliedFilters: {
                filterFlag,
                ...filters,
              },
            });
            return {
              status: SUCCESS,
              filteredThreatsCount: response?.data.count,
            };

          default:
            notification["error"]({
              message: "Cannot Receive Threat Trademarks",
              description:
                "An error occured while receiving threat trademarks. Please try again after some time.",
            });
            dispatch({ type: GL_GET_THREAT_TRADEMARKS, status: ERROR });
            return {
              status: ERROR,
            };
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: GL_GET_THREAT_TRADEMARKS, status: ERROR });
        return {
          status: ERROR,
        };
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}

export function GL_getImageText(
  { journalNumber, pageNumber, searchTerm, filters },
  journalChange = false
) {
  const search_term = `${filters?.searchTerm}`;
  const mark_desc_contains_term = `${filters?.mark_desc_contains_term}`;
  const search_type = `${filters?.primarySearchType}`;
  return async (dispatch) => {
    let response;
    let layout_url = `${WAT_RT_URL}/image_text/grid_layout/?`;
    let params = {
      page: pageNumber ? pageNumber : null,
      filter_flag:
        searchTerm?.length > 0 ||
        search_term?.length > 0 ||
        mark_desc_contains_term?.length > 0
          ? true
          : null,
      search_term: searchTerm
        ? searchTerm
        : search_type === "Application No/Name"
        ? search_term
        : null,
      mark_desc_contains_term:
        search_type === "Your Mark Description Search"
          ? mark_desc_contains_term
          : null,
      journal_number: journalNumber ? journalNumber : null,
    };
    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      try {
        dispatch({ type: GL_GET_IMAGE_TEXT, status: LOADING, journalChange });
        response = await Axios.get(`${WAT_RT_URL}/image_text/grid_layout/`, {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        switch (response.status) {
          case 200:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: GL_GET_IMAGE_TEXT,
                  status: SUCCESS,
                  payload: response?.data,
                  pageNumber,
                  searchTerm,
                  search_term,
                  mark_desc_contains_term,
                  lastAppliedFilters: {
                    ...filters,
                  },
                  journalNumber,
                  journalChange,
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: GL_GET_IMAGE_TEXT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
                searchTerm,
                search_term,
                mark_desc_contains_term,
                lastAppliedFilters: {
                  ...filters,
                },
                journalNumber,
                journalChange,
              });
            }
            break;

          case 202:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: GL_GET_IMAGE_TEXT,
                  status: PROGRESSING,
                  payload: response?.data,
                  pageNumber,
                  searchTerm,
                  search_term,
                  mark_desc_contains_term,
                  lastAppliedFilters: {
                    ...filters,
                  },
                  journalNumber,
                  journalChange,
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: GL_GET_IMAGE_TEXT,
                status: PROGRESSING,
                payload: response?.data,
                pageNumber,
                searchTerm,
                search_term,
                mark_desc_contains_term,
                lastAppliedFilters: {
                  ...filters,
                },
                journalNumber,
                journalChange,
              });
            }
            break;

          default:
            notification["error"]({
              message: "Cannot Receive Image Text",
              description:
                "An error occured while receiving image text. Please try again after some time.",
            });
            dispatch({
              type: GL_GET_IMAGE_TEXT,
              status: ERROR,
              payload:
                "An error occured while receiving image text. Please try again after some time.",
              journalChange,
            });
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({
          type: GL_GET_IMAGE_TEXT,
          status: ERROR,
          payload: err.response?.data.message,
          journalChange,
        });
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}

//--------Get Threat Trademarks for ImageText--------
export function GL_getImageTextTrademarks(
  applicationNumber,
  journalNumber,
  pageNumber,
  filterFlag,
  filters
) {
  const threat_classes = `${filters.trademarkClasses}`;
  const trademark_types = `${filters.trademarkTypes}`;
  const proprietors = `${filters.proprietors}`;
  const trademark_categories = `${filters.trademarkCategory}`;
  const trademark_offices = `${filters.trademarkOffices}`;
  const infringement_types = `${filters.infringementTypes}`;
  const threat_similarity = `${filters.threatsSimilarity.lowerLimit}to${filters.threatsSimilarity.upperLimit}`;
  const threat_desc_contains_term = `${filters.threat_desc_contains_term}`;
  const searchType = `${filters?.secondarySearchType}`;

  return async (dispatch) => {
    let response;
    let layout_url = `${WAT_RT_URL}/image_text/detail/grid_layout/?`;
    let params = {
      application_number: applicationNumber,
      journal_number: journalNumber,
      page: pageNumber,
      filter_flag: filterFlag ? true : null,
      threat_classes: filterFlag ? threat_classes : null,
      trademark_types: filterFlag ? trademark_types : null,
      proprietors: filterFlag ? proprietors : null,
      trademark_categories: filterFlag ? trademark_categories : null,
      trademark_offices: filterFlag ? trademark_offices : null,
      infringement_types: filterFlag ? infringement_types : null,
      threat_similarity: filterFlag ? threat_similarity : null,
      date_of_application: filterFlag ? filters.date_of_application : null,
      date_of_usage: filterFlag ? filters.date_of_usage : null,
      date_of_usage_proposed: filterFlag
        ? filters.date_of_usage_proposed
        : null,
      search_term:
        filterFlag && searchType === "Application No/Name"
          ? filters.searchTerm
          : null,
      threat_desc_contains_term:
        filterFlag && searchType === "Your Threat Description Search"
          ? threat_desc_contains_term
          : null,
      red_band: filterFlag ? (filters.redBand ? true : null) : null,
      exclude_same_class: filterFlag
        ? filters.excludeClass
          ? true
          : null
        : null,
    };
    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      try {
        dispatch({ type: GL_GET_IMAGE_TEXT_TRADEMARKS, status: LOADING });
        response = await Axios.get(
          `${WAT_RT_URL}/image_text/detail/grid_layout/`,
          {
            params,
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );

        switch (response.status) {
          case 200:
            dispatch({
              type: GL_GET_IMAGE_TEXT_TRADEMARKS,
              status: SUCCESS,
              payload: response?.data,
              pageNumber,
              lastAppliedFilters: {
                filterFlag,
                ...filters,
              },
            });
            return {
              status: SUCCESS,
              filteredThreatsCount: response?.data.count,
            };

          default:
            notification["error"]({
              message: "Cannot Receive Threat Trademarks",
              description:
                "An error occured while receiving threat trademarks. Please try again after some time.",
            });
            dispatch({ type: GL_GET_IMAGE_TEXT_TRADEMARKS, status: ERROR });
            return {
              status: ERROR,
            };
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: GL_GET_IMAGE_TEXT_TRADEMARKS, status: ERROR });
        return {
          status: ERROR,
        };
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}

export function GL_getImageLabel(
  { journalNumber, pageNumber, searchTerm, filters },
  journalChange = false
) {
  const search_term = `${filters?.searchTerm}`;
  const mark_desc_contains_term = `${filters?.mark_desc_contains_term}`;
  const search_type = `${filters?.primarySearchType}`;

  return async (dispatch) => {
    let response;
    let layout_url = `${WAT_RT_URL}/image_label/grid_layout/?`;
    let params = {
      page: pageNumber ? pageNumber : null,
      filter_flag:
        searchTerm?.length > 0 ||
        search_term?.length > 0 ||
        mark_desc_contains_term?.length > 0
          ? true
          : null,
      search_term: searchTerm
        ? searchTerm
        : search_type === "Application No/Name"
        ? search_term
        : null,
      journal_number: journalNumber ? journalNumber : null,
      mark_desc_contains_term:
        search_type === "Your Mark Description Search"
          ? mark_desc_contains_term
          : null,
    };
    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      try {
        dispatch({ type: GL_GET_IMAGE_LABEL, status: LOADING, journalChange });
        response = await Axios.get(`${WAT_RT_URL}/image_label/grid_layout/`, {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        switch (response.status) {
          case 200:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: GL_GET_IMAGE_LABEL,
                  status: SUCCESS,
                  payload: response?.data,
                  pageNumber,
                  searchTerm,
                  search_term,
                  mark_desc_contains_term,
                  lastAppliedFilters: {
                    ...filters,
                  },
                  journalNumber,
                  journalChange,
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: GL_GET_IMAGE_LABEL,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
                searchTerm,
                search_term,
                mark_desc_contains_term,
                lastAppliedFilters: {
                  ...filters,
                },
                journalNumber,
                journalChange,
              });
            }
            break;

          case 202:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: GL_GET_IMAGE_LABEL,
                  status: PROGRESSING,
                  payload: response?.data,
                  pageNumber,
                  searchTerm,
                  search_term,
                  mark_desc_contains_term,
                  lastAppliedFilters: {
                    ...filters,
                  },
                  journalNumber,
                  journalChange,
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: GL_GET_IMAGE_LABEL,
                status: PROGRESSING,
                payload: response?.data,
                pageNumber,
                searchTerm,
                search_term,
                mark_desc_contains_term,
                lastAppliedFilters: {
                  ...filters,
                },
                journalNumber,
                journalChange,
              });
            }
            break;

          default:
            notification["error"]({
              message: "Cannot Receive Image Label",
              description:
                "An error occured while receiving image Label. Please try again after some time.",
            });
            dispatch({
              type: GL_GET_IMAGE_LABEL,
              status: ERROR,
              payload:
                "An error occured while receiving image Label. Please try again after some time.",
              journalChange,
            });
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({
          type: GL_GET_IMAGE_LABEL,
          status: ERROR,
          payload: err.response?.data.message,
          journalChange,
        });
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}

//--------Get Threat Trademarks for ImageLabel--------
export function GL_getImageLabelTrademarks(
  applicationNumber,
  journalNumber,
  pageNumber,
  filterFlag,
  filters
) {
  const threat_classes = `${filters.trademarkClasses}`;
  const trademark_types = `${filters.trademarkTypes}`;
  const proprietors = `${filters.proprietors}`;
  const trademark_categories = `${filters.trademarkCategory}`;
  const trademark_offices = `${filters.trademarkOffices}`;
  const threat_labels = `${filters.threatLabels}`;
  const threat_desc_contains_term = `${filters.threat_desc_contains_term}`;
  const searchType = `${filters?.secondarySearchType}`;

  return async (dispatch) => {
    let layout_url = `${WAT_RT_URL}/image_label/detail/grid_layout/?`;
    let response;
    let params = {
      application_number: applicationNumber,
      journal_number: journalNumber,
      page: pageNumber,
      filter_flag: filterFlag ? true : null,
      threat_classes: filterFlag ? threat_classes : null,
      trademark_types: filterFlag ? trademark_types : null,
      proprietors: filterFlag ? proprietors : null,
      trademark_categories: filterFlag ? trademark_categories : null,
      trademark_offices: filterFlag ? trademark_offices : null,
      threat_labels: filterFlag ? threat_labels : null,
      date_of_application: filterFlag ? filters.date_of_application : null,
      date_of_usage: filterFlag ? filters.date_of_usage : null,
      date_of_usage_proposed: filterFlag
        ? filters.date_of_usage_proposed
        : null,
      search_term:
        filterFlag && searchType === "Application No/Name"
          ? filters.searchTerm
          : null,
      red_band: filterFlag ? (filters.redBand ? true : null) : null,
      exclude_same_class: filterFlag
        ? filters.excludeClass
          ? true
          : null
        : null,
      threat_desc_contains_term:
        filterFlag && searchType === "Your Threat Description Search"
          ? threat_desc_contains_term
          : null,
    };
    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      try {
        dispatch({ type: GL_GET_IMAGE_LABEL_TRADEMARKS, status: LOADING });
        response = await Axios.get(
          `${WAT_RT_URL}/image_label/detail/grid_layout/`,
          {
            params,
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );

        switch (response.status) {
          case 200:
            dispatch({
              type: GL_GET_IMAGE_LABEL_TRADEMARKS,
              status: SUCCESS,
              payload: response?.data,
              pageNumber,
              lastAppliedFilters: {
                filterFlag,
                ...filters,
              },
            });
            return {
              status: SUCCESS,
              filteredThreatsCount: response?.data.count,
            };

          default:
            notification["error"]({
              message: "Cannot Receive Threat Trademarks",
              description:
                "An error occured while receiving threat trademarks. Please try again after some time.",
            });
            dispatch({ type: GL_GET_IMAGE_LABEL_TRADEMARKS, status: ERROR });
            return {
              status: ERROR,
            };
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: GL_GET_IMAGE_LABEL_TRADEMARKS, status: ERROR });
        return {
          status: ERROR,
        };
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}

export function GL_getTMProtect(
  { profileReportID, dateRange, pageNumber, filters },
  journalChange = false
) {
  const searchTerm = `${filters?.searchTerm}`;

  return async (dispatch) => {
    dispatch({ type: GL_GET_TM_PROTECT, status: LOADING, journalChange });
    let response;
    try {
      response = await Axios.get(`${WAT_RT_URL}/tm_protect/grid_layout/`, {
        params: {
          page: pageNumber ? pageNumber : null,
          filter_flag: searchTerm.length > 0 ? true : null,
          search_term: searchTerm.length > 0 ? searchTerm : null,
          profile_report_id: profileReportID ? profileReportID : null,
        },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      switch (response.status) {
        case 200:
          if (journalChange) {
            batch(() => {
              dispatch({
                type: GL_GET_TM_PROTECT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
                searchTerm,
                lastAppliedFilters: {
                  ...filters,
                },
                profileReportID,
                dateRange,
                journalChange,
              });
              dispatch({
                type: SET_CURRENT_DATE_RANGE,
                payload: dateRange,
                report_id: profileReportID,
              });
            });
          } else {
            dispatch({
              type: GL_GET_TM_PROTECT,
              status: SUCCESS,
              payload: response?.data,
              pageNumber,
              searchTerm,
              lastAppliedFilters: {
                ...filters,
              },
              profileReportID,
              dateRange,
              journalChange,
            });
          }
          break;

        case 202:
          if (journalChange) {
            batch(() => {
              dispatch({
                type: GL_GET_TM_PROTECT,
                status: PROGRESSING,
                payload: response?.data,
                pageNumber,
                searchTerm,
                lastAppliedFilters: {
                  ...filters,
                },
                profileReportID,
                dateRange,
                journalChange,
              });
              dispatch({
                type: SET_CURRENT_DATE_RANGE,
                payload: dateRange,
                report_id: profileReportID,
              });
            });
          } else {
            dispatch({
              type: GL_GET_TM_PROTECT,
              status: PROGRESSING,
              payload: response?.data,
              pageNumber,
              searchTerm,
              lastAppliedFilters: {
                ...filters,
              },
              profileReportID,
              dateRange,
              journalChange,
            });
          }
          break;

        default:
          notification["error"]({
            message: "Cannot Receive Image Label",
            description:
              "An error occured while receiving image Label. Please try again after some time.",
          });
          dispatch({
            type: GL_GET_TM_PROTECT,
            status: ERROR,
            payload:
              "An error occured while receiving image Label. Please try again after some time.",
            journalChange,
          });
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: GL_GET_TM_PROTECT,
        status: ERROR,
        payload: err.response?.data.message,
        journalChange,
      });
    }
  };
}

//--------Get Threat Trademarks for TMProtect--------
export function GL_getTMProtectTrademarks(
  profileReportID,
  markId,
  pageNumber,
  filterFlag,
  filters
) {
  const threat_classes = `${filters.trademarkClasses}`;
  const trademark_types = `${filters.trademarkTypes}`;
  const proprietors = `${filters.proprietors}`;
  const trademark_categories = `${filters.trademarkCategory}`;
  const trademark_offices = `${filters.trademarkOffices}`;
  const infringement_types = `${filters.infringementTypes}`;
  const threat_similarity = `${filters.threatsSimilarity.lowerLimit}to${filters.threatsSimilarity.upperLimit}`;
  const constain_term = `${filters.searchTerm}`;
  const threat_desc_contains_term = `${filters.threat_desc_contains_term}`;
  const searchType = `${filters?.secondarySearchType}`;

  return async (dispatch) => {
    let response;
    let layout_url = `${WAT_RT_URL}/tm_protect/detail/grid_layout/?`;
    let params = {
      search_term:
        filterFlag && searchType === "Application No/Name"
          ? constain_term
          : null,
      threat_desc_contains_term:
        filterFlag && searchType === "Your Threat Description Search"
          ? threat_desc_contains_term
          : null,
      profile_report_id: profileReportID,
      cease_desist_mark_id: markId,
      page: pageNumber,
      filter_flag: filterFlag ? true : null,
      threat_classes: filterFlag ? threat_classes : null,
      trademark_types: filterFlag ? trademark_types : null,
      proprietors: filterFlag ? proprietors : null,
      trademark_categories: filterFlag ? trademark_categories : null,
      trademark_offices: filterFlag ? trademark_offices : null,
      infringement_types: filterFlag ? infringement_types : null,
      threat_similarity: filterFlag ? threat_similarity : null,
      date_of_application: filterFlag ? filters.date_of_application : null,
      date_of_usage: filterFlag ? filters.date_of_usage : null,
      date_of_usage_proposed: filterFlag
        ? filters.date_of_usage_proposed
        : null,
      // search_term: filterFlag ? filters.searchTerm : null,
      red_band: filterFlag ? (filters.redBand ? true : null) : null,
      exclude_same_class: filterFlag
        ? filters.excludeClass
          ? true
          : null
        : null,
    };

    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      try {
        dispatch({ type: GL_GET_TM_PROTECT_TRADEMARKS, status: LOADING });
        response = await Axios.get(
          `${WAT_RT_URL}/tm_protect/detail/grid_layout/`,
          {
            params,
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );

        switch (response.status) {
          case 200:
            dispatch({
              type: GL_GET_TM_PROTECT_TRADEMARKS,
              status: SUCCESS,
              payload: response?.data,
              pageNumber,
              lastAppliedFilters: {
                filterFlag,
                ...filters,
              },
            });
            return {
              status: SUCCESS,
              filteredThreatsCount: response?.data.count,
            };

          default:
            notification["error"]({
              message: "Cannot Receive Threat Trademarks",
              description:
                "An error occured while receiving threat trademarks. Please try again after some time.",
            });
            dispatch({ type: GL_GET_TM_PROTECT_TRADEMARKS, status: ERROR });
            return {
              status: ERROR,
            };
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: GL_GET_TM_PROTECT_TRADEMARKS, status: ERROR });
        return {
          status: ERROR,
        };
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}
