import React, { useState, useEffect } from "react";
import {
  Drawer,
  Row,
  Col,
  Input,
  Radio,
  Button,
  Checkbox,
  Alert,
  notification,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createCustomReport } from "../../../../../redux/actions/watchActions/watchActions";
import {
  getCompareMarks,
  getListLayoutData,
} from "../../../../../redux/actions/watchActions/listDashboardActions";
import ComparativeReportFilter from "../ComparativeReportFilter";
import { debounce } from "lodash";

const CheckboxGroup = Checkbox.Group;

const CreateReportDrawer = (props) => {
  const {
    open,
    setOpen,
    journal,
    selected,
    generateFilterObject,
    selectAll,
    report_columns = [],
    source = "LIST_LAYOUT",
    creation_origin = "APPLIED_FOR",
    filterApplied,
  } = props;

  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3];

  const [reportName, setReportName] = useState("");
  const [reportFormat, setReportFormat] = useState(
    urlKey === "brand_compare" ? "EXCEL" : "PDF",
  );
  const [selectedYourColumns, setSelectedYourColumns] = useState([]);
  const [selectedThreatColumns, setSelectedThreatColumns] = useState([]);
  const [comparativePage, setComparativePage] = useState(1);
  const [comparativeMark, setComparativeMark] = useState(null);
  const [comparativeSearchTerm, setComparativeSearchTerm] = useState("");

  const dispatch = useDispatch();
  const yourMarkOptions = report_columns.filter(
    (item) => !item.includes("Threat"),
  );
  const threatMarkOptions = report_columns.filter((item) =>
    item.includes("Threat"),
  );

  const getMarksDataForComparativeReport = () => {
    dispatch(getCompareMarks(comparativePage, comparativeSearchTerm));
  };

  useEffect(() => {
    if (urlKey === "journals") {
      // dispatch marks api for comparative report
      getMarksDataForComparativeReport();
    }
  }, []);

  const handleComparativeSearchDebounced = React.useCallback(
    debounce((newValue) => {
      dispatch(getCompareMarks(comparativePage, newValue));
    }, 500),
    [],
  );

  const { compareMarks } =
    useSelector(
      (state) =>
        state.watchState.listLayoutData[dashboardLayout][urlKey].config,
    ) || {};

  useEffect(() => {
    setSelectedYourColumns(yourMarkOptions);
    setSelectedThreatColumns(threatMarkOptions);
  }, [yourMarkOptions.length, threatMarkOptions.length]);

  const getReportType = () => {
    let reportType = "Custom";
    if (urlKey === "journals" && !comparativeMark) {
      reportType = "Journal";
    } else {
      reportType = "Custom";
    }
    return reportType;
  };

  const handleClose = () => {
    setComparativeSearchTerm("");
    setComparativeMark(null);
    setOpen(false);
  };

  const handleCreateReport = async () => {
    handleClose();
    if (selectAll && !filterApplied && urlKey === "image_similarity") {
      notification["warning"]({
        message: "You can access this report from your mail.",
        duration: 2,
      });
      return;
    }
    // run api for journal - no checks needed for now...
    if (
      selectAll &&
      !filterApplied &&
      !(urlKey === "journals" || urlKey === "brand_compare")
    ) {
      notification["warning"]({
        message:
          "You can access this report from 'Automated Report' section in Reports",
        duration: 2,
      });
      return;
    }

    let body = {
      ...(urlKey === "tm_protect"
        ? { cease_desist_report_id: journal }
        : { journal_number: journal }),
      filter_flag: false,
      selected_ids: selected,
      source: urlKey === "journals" ? "Journal" : source,
      report_format: reportFormat,
      report_name: reportName,
      creation_origin: creation_origin,
      report_type: getReportType(),
      // report_type : "Custom",
      report_columns:
        reportFormat === "EXCEL"
          ? [...selectedYourColumns, ...selectedThreatColumns]
          : [],
    };
    if (comparativeMark) {
      body.mark = comparativeMark;
    }
    if (urlKey === "brand_compare") {
      delete body.journal_number;
      body.analysis_id = journal;
    }
    if (selectAll) {
      let newFilterObject = generateFilterObject();
      if (urlKey === "journals") {
        delete newFilterObject.threat_similarity;
      }
      let modifiedBody = {
        ...body,
        filter_flag: true,
        selected_ids: [],
        filters: newFilterObject,
      };
      await dispatch(createCustomReport(modifiedBody));
      dispatch(
        getListLayoutData(
          journal,
          props?.pageNumber,
          true,
          generateFilterObject(),
        ),
      );
    } else {
      await dispatch(createCustomReport(body));
      dispatch(
        getListLayoutData(
          journal,
          props?.pageNumber,
          true,
          generateFilterObject(),
        ),
      );
    }
  };

  return (
    <Drawer
      title={<h3>Custom Report Details</h3>}
      placement="right"
      size={"large"}
      closable={false}
      open={open}
      onClose={() => handleClose()}
      footer={
        <>
          <Button type="primary" ghost onClick={() => handleCreateReport()}>
            Confirm
          </Button>
          &emsp;
          <Button type="primary" danger ghost onClick={() => handleClose()}>
            Cancel
          </Button>
        </>
      }
    >
      <Row>
        <Col span={16}>
          <p className="fw_500">Enter the name of your report:</p>
          <Input
            onChange={(event) => {
              if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                setReportName(event.target.value);
              }
            }}
            value={reportName}
            placeholder="Report's name (Optional)"
          />
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <p className="fw_500">Choose your preferred report format:</p>
          <Radio.Group
            value={reportFormat}
            onChange={(event) => setReportFormat(event.target.value)}
            size="small"
          >
            {urlKey !== "brand_compare" && (
              <Radio value={"PDF"} style={{ fontWeight: 400 }}>
                PDF
              </Radio>
            )}
            <Radio
              value={"EXCEL"}
              disabled={comparativeMark}
              style={{ fontWeight: 400 }}
            >
              EXCEL
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      {reportFormat === "PDF" && urlKey === "journals" && (
        <Row className="m-top-20">
          <Col span={24}>
            <Alert
              message="Note: Excel is not available for comparative report."
              type="warning"
            />
            <Alert
              message="Note: If your trademark is selected then a comparative report will be generated."
              type="warning"
            />
          </Col>
          <Col
            span={24}
            className="m-top-20"
            style={{ borderRight: "1px solid lightGrey" }}
          >
            <p className="fw_500">Choose any of your trademark:</p>
            <ComparativeReportFilter
              loading={compareMarks.loading}
              filteredData={compareMarks}
              value={comparativeMark}
              setValue={setComparativeMark}
              searchValue={comparativeSearchTerm}
              setSearchValue={setComparativeSearchTerm}
              handleSearchDebounced={handleComparativeSearchDebounced}
            />
          </Col>
        </Row>
      )}
      {reportFormat === "EXCEL" && (
        <Row className="m-top-20">
          <p className="fw_500">
            Choose your preferred Tables for your Mark and Threat Marks:
          </p>
          <Col span={24}>
            <Alert
              message="INFO: By default the report will contain all columns."
              type="warning"
            />
          </Col>
          <Col
            span={12}
            className="m-top-20"
            style={{ borderRight: "1px solid lightGrey" }}
          >
            <p className="fw_600">Your Mark</p>
            <CheckboxGroup
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="checkbox-custom-dropdown"
              onChange={(checkedValues) =>
                setSelectedYourColumns(checkedValues)
              }
              value={selectedYourColumns}
              options={yourMarkOptions}
            />
          </Col>
          <Col span={12} className="m-top-20" style={{ paddingLeft: "10px" }}>
            <p className="fw_600">Threat Mark</p>
            <CheckboxGroup
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="checkbox-custom-dropdown"
              onChange={(checkedValues) =>
                setSelectedThreatColumns(checkedValues)
              }
              value={selectedThreatColumns}
              options={threatMarkOptions}
            />
          </Col>
        </Row>
      )}
    </Drawer>
  );
};

export default CreateReportDrawer;
