import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import {
  AppstoreTwoTone,
  DashboardTwoTone,
  DownloadOutlined,
  ExperimentTwoTone,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  Typography,
  Modal,
  Button,
  Card,
  Input,
  message,
  Drawer,
} from "antd";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import {
  getTeamMember,
  logOut,
} from "../../../redux/actions/commonActions/actions";
import {
  getRefinedPatent,
  getPatentChangelog,
} from "../../../redux/actions/managerActions/patentDashboard/actions";
import {
  postDownloadFile,
  putUploadedFile,
  postUploadFile,
  deleteUploadedFile,
} from "../../../redux/actions/managerActions/trademarkDashboard/actions";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

//--------MODALS--------
import ChangeLogPatent from "./ChangeLog";
import { standardizeDateTime } from "../../../functions/commonFunctions/dateFunctions";
import { P } from "../../../constants/managerConstants/managerConstants";

import UploadDocuments from "../../commonComponents/Drawers/UploadDocuments";

import { UPLOAD_FILE_P } from "../../../redux/actions/managerActions/patentDashboard/types";

import { changeStateValue_L0 } from "../../../functions/commonFunctions/generalFunctions";
import ManagerNotes from "../subComponents/Modal/managerNotes";
import {
  getNotes,
  deleteNotes,
} from "../../../redux/actions/managerActions/commonAction/action";
import {
  GET_P_NOTES,
  POST_P_NOTES,
  PATCH_P_NOTES,
  DELETE_P_NOTES,
} from "../../../redux/actions/managerActions/commonAction/types";
import NotesTable from "../subComponents/trademarkProfileComponents/NotesTable";
import UploadDocumentsTable from "../subComponents/trademarkProfileComponents/UploadDocumentsTable";
import Timeline from "../subComponents/trademarkProfileComponents/Timeline";
import RenewalTable from "../subComponents/patentProfile/RenewalTable";
import ReplyToFerTable from "../subComponents/patentProfile/ReplyToFerTable";
import TodoTaskTable from "../subComponents/trademarkProfileComponents/TodoTaskTable";
import RenewalFiledModal from "./RenewalFiled";
import ReplyFiledModal from "./ReplyFiled";
import CreateTask from "../to-do-task/CreateTask";
import SendEmail from "../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../commonComponents/Modals/SendEmailNew";
import PandingPatent from "../subComponents/patentProfile/PandingPatent";

const { Title, Text } = Typography;
const { TextArea } = Input;

class PatentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      changeLogVisible: false,
      managerNotes: false,
      notesEditingKey: "",
      notesDeletingKey: "",
      note_id: "",
      note: "",
      changeSwitch: false,
      renewalFiledVisible: false,
      replyFiledVisible: false,
      addTaskVisible: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    let management_patent_id = sessionStorage.getItem("management_patent_id");
    if (localStorage.getItem("token")) {
      this.props.getRefinedPatent(management_patent_id);
      this.props.getPatentChangelog(management_patent_id);

      if (this.props.teamMember === null) {
        this.props.getTeamMember("manager");
      }

      this.props.getNotes({
        actionType: GET_P_NOTES,
        note_for: P,
        application_number: sessionStorage.getItem("patent_application_number"),
      });
    } else {
      this.props.logOut();
    }
  }

  showCompleteSpecification() {
    Modal.info({
      title: "Complete Specification",
      width: 800,
      className: "mark-delete-modal",
      content: (
        <div>
          {this.props.refinedPatent && (
            <TextArea
              value={this.props.refinedPatent.ip_india.complete_specification}
              className="complete-specification-box vertical_scroll"
              autosize={{ minRows: 5 }}
            />
          )}
        </div>
      ),
      okText: "cancel",
      // okType: '',
      onOk() {},
    });
  }

  handleDownloadDocument(data) {
    checkValue(data?.file)
      ? window.open(data?.file, "_blank")
      : message.error("No Document Found");
  }

  onChangeLog() {
    this.setState({
      changeLogVisible: true,
    });
  }

  closeChangeLog() {
    this.setState({
      changeLogVisible: false,
    });
  }

  handleAddNotes() {
    this.setState({
      managerNotes: true,
      type: "ADD",
    });
  }

  handleEditNotes(data) {
    this.setState({
      managerNotes: true,
      notesEditingKey: data.id,
      note_id: data.id,
      note: data.note,
      type: "EDIT",
    });
  }

  async handleDeleteNotes(data) {
    this.setState({
      notesDeletingKey: data.id,
    });
    await this.props.deleteNotes({
      actionType: DELETE_P_NOTES,
      note_for: P,
      application_number: sessionStorage.getItem("patent_application_number"),
      note_id: data.id,
      delete_all: false,
    });
    message[this.props.deleteManageNotesStatus.toLowerCase()](
      this.props.manageNotesMessage,
    );
  }

  async handleDeleteNotesAll() {
    await this.props.deleteNotes({
      actionType: DELETE_P_NOTES,
      note_for: P,
      application_number: sessionStorage.getItem("patent_application_number"),
      delete_all: true,
    });
    message[this.props.deleteAllManageNotesStatus.toLowerCase()](
      this.props.manageNotesMessage,
    );
  }

  handleChangeSwitch() {
    this.setState({
      changeSwitch: !this.state.changeSwitch,
    });
  }

  renewalFiled(data) {
    this.setState({
      renewalFiledVisible: true,
      renewal: false,
      application_number: data.application_number,
      title_of_invention: data.title_of_invention,
      pseudo: this.state.changeSwitch,
    });
  }

  replyFiled(data) {
    this.setState({
      replyFiledVisible: true,
      pending_reply: false,
      application_number: data.application_number,
      title: data.title_of_invention,
    });
  }

  handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Patent Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [this.state.changeSwitch ? "PSEUDO_PATENT" : "PATENT"],
        report_tag: {
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          status: checkValue(data.application_status)
            ? data.application_status
            : "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_patent" : "patent",
        applicationNumber: data.application_number,
        sourceId: data.management_patent_id,
      },
    });
  }

  render() {
    const {
      refinedPatent,
      refinedPatentStatus,
      manageNotes,
      manageNotesStatus,
      deleteManageNotesStatus,
      deleteAllManageNotesStatus,
    } = this.props;

    let customProfile = false;
    let pendingRenewal = "";
    let pendingReplyToFer = "";
    let todoTask = [];
    let pendingStatementOfUndertaking = "";
    let pendingWorkingOfPatent = "";
    let pendingRequestOfExamination = "";
    if (refinedPatentStatus === SUCCESS) {
      customProfile = refinedPatent.abides_by === "Own Data";
      if (this.state.changeSwitch) {
        pendingRenewal =
          refinedPatent.pseudo && refinedPatent.pseudo.pending_renewal;
        pendingReplyToFer =
          refinedPatent.pseudo && refinedPatent.pseudo.pending_reply_to_fer;
        todoTask = refinedPatent.pseudo && refinedPatent.pseudo.to_do_task;
        pendingStatementOfUndertaking =
          refinedPatent.pseudo &&
          refinedPatent.pseudo.pending_statement_of_undertaking;
        pendingWorkingOfPatent =
          refinedPatent.pseudo &&
          refinedPatent.pseudo.pending_working_of_patent;
        pendingRequestOfExamination =
          refinedPatent.pseudo &&
          refinedPatent.pseudo.pending_request_of_examination;
      } else {
        pendingRenewal =
          refinedPatent.ip_india && refinedPatent.ip_india.pending_renewal;
        pendingReplyToFer =
          refinedPatent.ip_india && refinedPatent.ip_india.pending_reply_to_fer;
        todoTask = refinedPatent.ip_india && refinedPatent.ip_india.to_do_task;
        pendingStatementOfUndertaking =
          refinedPatent.ip_india &&
          refinedPatent.ip_india.pending_statement_of_undertaking;
        pendingWorkingOfPatent =
          refinedPatent.ip_india &&
          refinedPatent.ip_india.pending_working_of_patent;
        pendingRequestOfExamination =
          refinedPatent.ip_india &&
          refinedPatent.ip_india.pending_request_of_examination;
      }
    }

    const totalDocumentColumns = [
      {
        title: "NAME",
        dataIndex: "name",
        width: "60%",
      },

      {
        title: "DATE",
        dataIndex: "date",
        width: "25%",
      },

      {
        title: "DOWNLOAD",
        width: "15%",
        align: "center",
        render: (text, fileData) => {
          return fileData ? (
            <div>
              <DownloadOutlined
                style={{ color: "#4285f4", fontSize: "20px" }}
                onClick={() => this.handleDownloadDocument(fileData)}
              />
            </div>
          ) : (
            ""
          );
        },
      },
    ];

    return (
      <div>
        {/* Back-section */}
        <Row>
          <Col span={4} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
          <Col span={20} className="alignR">
            <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
              *Data as on -{" "}
              {checkValue(this.props.refinedPatent)
                ? standardizeDateTime(
                    this.props.refinedPatent.ip_india.last_realtime_date,
                  )
                : "N.A."}
            </Text>
          </Col>
        </Row>
        {/* Back-section */}
        {/* card-section */}
        <Row gutter={16} className="m-top-10">
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={
                this.props.refinedPatentStatus === LOADING ? true : false
              }
            >
              <Row>
                <Col span={4}>
                  <DashboardTwoTone style={{ fontSize: "35px" }} />
                </Col>
                <Col span={20}>
                  <Title level={4} style={{ fontSize: "14px" }}>
                    APPLICATION STATUS
                  </Title>
                  <Text strong style={{ fontSize: "14px" }}>
                    {checkValue(this.props.refinedPatent)
                      ? checkValue(
                          this.props.refinedPatent.ip_india.application_status,
                        )
                        ? this.props.refinedPatent.ip_india.application_status
                        : "N.A."
                      : "N.A."}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={
                this.props.refinedPatentStatus === LOADING ? true : false
              }
            >
              <Row>
                <Col span={4}>
                  <AppstoreTwoTone style={{ fontSize: "35px" }} />
                </Col>
                <Col span={20}>
                  <Title level={4} style={{ fontSize: "14px" }}>
                    APPLICATION TYPE
                  </Title>
                  <Text strong style={{ fontSize: "14px" }}>
                    {checkValue(this.props.refinedPatent)
                      ? checkValue(
                          this.props.refinedPatent.ip_india.application_type,
                        )
                        ? this.props.refinedPatent.ip_india.application_type
                        : "N.A."
                      : "N.A."}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={
                this.props.refinedPatentStatus === LOADING ? true : false
              }
            >
              <Row>
                <Col span={4}>
                  <ExperimentTwoTone style={{ fontSize: "35px" }} />
                </Col>
                <Col span={20}>
                  <Title level={4} style={{ fontSize: "14px" }}>
                    FIELD OF INVENTION
                  </Title>
                  <Text strong style={{ fontSize: "14px" }}>
                    {checkValue(this.props.refinedPatent)
                      ? checkValue(
                          this.props.refinedPatent.ip_india.field_of_invention,
                        )
                        ? this.props.refinedPatent.ip_india.field_of_invention
                            .name
                        : "N.A."
                      : "N.A."}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* card-section */}
        {/* patent-Information */}
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title="PATENT INFORMATION"
              extra={
                <div>
                  <Button type="primary" onClick={() => this.onChangeLog()}>
                    Change Log
                  </Button>
                </div>
              }
              headStyle={{ background: "#e8e8e8" }}
              loading={
                this.props.refinedPatentStatus === LOADING ? true : false
              }
            >
              <Col span={20}>
                {this.props.refinedPatent && (
                  <div>
                    <table className="patent-information-table">
                      <tbody>
                        <tr>
                          <td>Title of Invention</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .title_of_invention,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .title_of_invention
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Application Number</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .application_number,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .application_number
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Applicant Name</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.applicant,
                            ) ? (
                              <ul>
                                {this.props.refinedPatent.ip_india.applicant.map(
                                  (applicantData, applicantkey) => (
                                    <li key={applicantData.id}>
                                      {checkValue(applicantData.name) ? (
                                        <>
                                          <span>{applicantkey + 1}. </span>
                                          <span>{applicantData.name}</span>
                                        </>
                                      ) : (
                                        "N.A."
                                      )}
                                    </li>
                                  ),
                                )}
                                {this.props.refinedPatent.ip_india.applicant
                                  .length === 0 && <sapn>N.A.</sapn>}
                              </ul>
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Inventor Name</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.inventor,
                            ) ? (
                              <ul>
                                {this.props.refinedPatent.ip_india.inventor.map(
                                  (invName, key) => (
                                    <li key={invName.id}>
                                      {checkValue(invName.name) ? (
                                        <>
                                          <span>{key + 1}. </span>
                                          <span>{invName.name}</span>
                                        </>
                                      ) : (
                                        "N.A."
                                      )}
                                    </li>
                                  ),
                                )}
                                {this.props.refinedPatent.ip_india.inventor
                                  .length === 0 && <sapn>N.A.</sapn>}
                              </ul>
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Field of Invention</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .field_of_invention,
                            )
                              ? checkValue(
                                  this.props.refinedPatent.ip_india
                                    .field_of_invention.name,
                                )
                                ? this.props.refinedPatent.ip_india
                                    .field_of_invention.name
                                : "N.A."
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Application Date (Date of Filing)</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.date_of_filing,
                            )
                              ? this.props.refinedPatent.ip_india.date_of_filing
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>PCT International Application Number</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .pct_international_application_number,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .pct_international_application_number
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Priority Number</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.priority_number,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .priority_number
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Priority Date</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.priority_date,
                            )
                              ? this.props.refinedPatent.ip_india.priority_date
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Priority Country</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .priority_country,
                            )
                              ? checkValue(
                                  this.props.refinedPatent.ip_india
                                    .priority_country.name,
                                )
                                ? this.props.refinedPatent.ip_india
                                    .priority_country.name
                                : "N.A."
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Parent Application Filing Date</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .application_filing_date,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .application_filing_date
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Classification</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .classification_ipc,
                            ) ? (
                              <ul>
                                {this.props.refinedPatent.ip_india.classification_ipc.map(
                                  (ipc, ipckey) => (
                                    <li key={ipc.id}>
                                      {checkValue(ipc.code) ? (
                                        <>
                                          <span>{ipckey + 1}. </span>
                                          <span>{ipc.code}</span>
                                        </>
                                      ) : (
                                        "N.A."
                                      )}
                                    </li>
                                  ),
                                )}
                                {this.props.refinedPatent.ip_india
                                  .classification_ipc.length === 0 && (
                                  <sapn>N.A.</sapn>
                                )}
                              </ul>
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Publication Type</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .publication_type,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .publication_type
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Request for Examination Date</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .request_for_examination_date,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .request_for_examination_date
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Reply to First Examination Report Date</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .reply_to_fer_date,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .reply_to_fer_date
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Publication Date</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .publication_date_us,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .publication_date_us
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>First Examination Report Date</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .first_examination_report_date,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .first_examination_report_date
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Date of Certificate Issue</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .date_of_certificate_issue,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .date_of_certificate_issue
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Post Grant Journal Date</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .post_grant_journal_date,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .post_grant_journal_date
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Renewal Date</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.renewal_date,
                            )
                              ? this.props.refinedPatent.ip_india.renewal_date
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Abstract</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.abstract,
                            )
                              ? this.props.refinedPatent.ip_india.abstract
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Complete Specification</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .complete_specification,
                            ) ? (
                              <span
                                className="application-text"
                                onClick={() => this.showCompleteSpecification()}
                              >
                                Click Here to Show Complete Specification
                              </span>
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </Col>
            </Card>
          </Col>
        </Row>
        {/* patent-Information */}
        {/* e-register-Information */}
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title="E-REGISTER INFORMATION"
              headStyle={{ background: "#e8e8e8" }}
              loading={
                this.props.refinedPatentStatus === LOADING ? true : false
              }
            >
              <Col span={20}>
                {this.props.refinedPatent && (
                  <div>
                    <table className="patent-information-table">
                      <tbody>
                        <tr>
                          <td>Patent Number</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.patent_number,
                            )
                              ? this.props.refinedPatent.ip_india.patent_number
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Date Of Patent</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.date_of_patent,
                            )
                              ? this.props.refinedPatent.ip_india.date_of_patent
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Grant Title</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.grant_title,
                            )
                              ? this.props.refinedPatent.ip_india.grant_title
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Date Of Grant</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.date_of_grant,
                            )
                              ? this.props.refinedPatent.ip_india.date_of_grant
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Legal Status</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india.legal_status,
                            )
                              ? this.props.refinedPatent.ip_india.legal_status
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Appropriate Office</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .appropriate_office,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .appropriate_office
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Address of Service</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .address_of_service,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .address_of_service
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Additional Address of Servicee</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .additional_address_of_service,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .additional_address_of_service
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Date Of Cessation</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .date_of_cessation,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .date_of_cessation
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Date Of Recordal</td>
                          <td>
                            {checkValue(
                              this.props.refinedPatent.ip_india
                                .date_of_recordal,
                            )
                              ? this.props.refinedPatent.ip_india
                                  .date_of_recordal
                              : "N.A."}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </Col>
            </Card>
          </Col>
        </Row>
        {/* patent-Information */}
        {/* Timeline */}
        {this.props.refinedPatent && this.props.refinedPatent.ip_india && (
          <Timeline
            documents={
              this.props.refinedPatent.ip_india.documents
                ? this.props.refinedPatent.ip_india.documents
                : []
            }
            status={this.props.refinedTrademarkStatus}
            description="name"
          />
        )}
        {/* Timeline */}
        {/* Documents */}
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title="DOCUMENT"
              headStyle={{ background: "#e8e8e8" }}
              loading={
                this.props.refinedPatentStatus === LOADING ? true : false
              }
            >
              <div>
                <Table
                  rowKey={(record) => record.name}
                  bordered
                  dataSource={
                    this.props.refinedPatent &&
                    this.props.refinedPatent.ip_india.documents
                  }
                  columns={totalDocumentColumns}
                  pagination={false}
                  scroll={{ y: "calc(70vh - 200px)" }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        {/* Documents */}
        {/* UPLOADED DOCUMENTS TABLE */}
        {refinedPatent && (
          <UploadDocumentsTable
            data={refinedPatent.ip_india}
            status={refinedPatentStatus}
            postDownloadFile={(title) =>
              this.props.postDownloadFile({
                applicationNumber: refinedPatent.ip_india.application_number,
                documentFor: P,
                fileName: title,
              })
            }
            changeVisible={() => this.setState({ visible: true })}
          />
        )}
        {/* UPLOADED DOCUMENTS TABLE */}
        {/* NOTES SECTION TABLE */}
        {manageNotes && (
          <NotesTable
            data={manageNotes}
            manageNotesStatus={manageNotesStatus}
            deleteAllManageNotesStatus={deleteAllManageNotesStatus}
            deleteManageNotesStatus={deleteManageNotesStatus}
            handleDeleteNotesAll={() => this.handleDeleteNotesAll()}
            handleAddNotes={() => this.handleAddNotes()}
            handleEditNotes={(record) => this.handleEditNotes(record)}
            handleDeleteNotes={(record) => this.handleDeleteNotes(record)}
            notesDeletingKey={this.state.notesDeletingKey}
          />
        )}
        {/* NOTES SECTION TABLE */}
        {/* UPCOMING RENEWAL */}
        {pendingRenewal && (
          <RenewalTable
            renewalType={`UPCOMING RENEWAL`}
            customProfile={customProfile}
            renewalData={pendingRenewal}
            changeSwitch={this.state.changeSwitch}
            handleChangeSwitch={() => this.handleChangeSwitch()}
            renewalFiled={(data) => this.renewalFiled(data)}
            changeStateValue_L0={() =>
              this.changeStateValue_L0("addTaskVisible", true)
            }
            handleEmail={(data) =>
              this.handleEmail("modalsVisibility", "sendEmail", true, data)
            }
          />
        )}
        {/* UPCOMING RENEWAL */}
        {/* REPLY TO FER */}
        {pendingReplyToFer && (
          <ReplyToFerTable
            replyToFerType={`PENDING REPLY TO FER`}
            customProfile={customProfile}
            replyToFerData={pendingReplyToFer}
            changeSwitch={this.state.changeSwitch}
            handleChangeSwitch={() => this.handleChangeSwitch()}
            replyFiled={(data) => this.replyFiled(data)}
            changeStateValue_L0={() =>
              this.changeStateValue_L0("addTaskVisible", true)
            }
            handleEmail={(data) =>
              this.handleEmail("modalsVisibility", "sendEmail", true, data)
            }
          />
        )}
        {/* REPLY TO CORRESPONDENCE */}
        {/* PENDING STATEMENT OF UNDERTAKING */}
        {pendingStatementOfUndertaking && (
          <PandingPatent
            pendingType={`PENDING STATEMENT OF UNDERTAKING`}
            customProfile={customProfile}
            pendingData={pendingStatementOfUndertaking}
            changeSwitch={this.state.changeSwitch}
            handleChangeSwitch={() => this.handleChangeSwitch()}
            changeStateValue_L0={() =>
              this.changeStateValue_L0("addTaskVisible", true)
            }
            handleEmail={(data) =>
              this.handleEmail("modalsVisibility", "sendEmail", true, data)
            }
          />
        )}
        {/* PENDING STATEMENT OF UNDERTAKING */}
        {pendingWorkingOfPatent && (
          <PandingPatent
            pendingType={`PENDING WORKING OF PATENT`}
            customProfile={customProfile}
            pendingData={pendingWorkingOfPatent}
            changeSwitch={this.state.changeSwitch}
            handleChangeSwitch={() => this.handleChangeSwitch()}
            changeStateValue_L0={() =>
              this.changeStateValue_L0("addTaskVisible", true)
            }
            handleEmail={(data) =>
              this.handleEmail("modalsVisibility", "sendEmail", true, data)
            }
          />
        )}
        {/* PENDING STATEMENT OF UNDERTAKING */}
        {pendingRequestOfExamination && (
          <PandingPatent
            pendingType={`PENDING REQUEST OF EXAMINATION`}
            customProfile={customProfile}
            pendingData={pendingRequestOfExamination}
            changeSwitch={this.state.changeSwitch}
            handleChangeSwitch={() => this.handleChangeSwitch()}
            changeStateValue_L0={() =>
              this.changeStateValue_L0("addTaskVisible", true)
            }
            handleEmail={(data) =>
              this.handleEmail("modalsVisibility", "sendEmail", true, data)
            }
          />
        )}
        {/* PENDING STATEMENT OF UNDERTAKING */}
        {/* TODO TASK */}
        {todoTask && todoTask.length > 0 && (
          <TodoTaskTable TodoTaskType={`TODO TASK`} TodoTaskData={todoTask} />
        )}
        {/* TODO TASK */}
        <Drawer
          title={
            <Title level={4}>
              UPLOADED DOCUMENTS (
              {this.props.refinedPatent &&
                this.props.refinedPatent.ip_india.application_number}
              )
            </Title>
          }
          placement="right"
          width={400}
          closable={true}
          onClose={() => this.setState({ visible: false })}
          open={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {this.props.refinedPatent && (
            <UploadDocuments
              applicationNumber={
                this.props.refinedPatent.ip_india.application_number
              }
              documentFor={P}
              teamMembers={
                checkValue(this.props.userDetails)
                  ? checkValue(this.props.teamMembe)
                    ? [...this.props.teamMember, this.props.userDetails]
                    : ""
                  : ""
              }
              defaultEmail={
                checkValue(this.props.userDetails)
                  ? this.props.userDetails.email
                  : ""
              }
              uploadedDocuments={
                this.props.refinedPatent.ip_india.uploaded_documents
              }
              putUploadedFile={(Obj) =>
                this.props.putUploadedFile(Obj, UPLOAD_FILE_P)
              }
              postUploadFile={(Obj) =>
                this.props.postUploadFile(Obj, UPLOAD_FILE_P)
              }
              deleteUploadedFile={(Obj) =>
                this.props.deleteUploadedFile(Obj, UPLOAD_FILE_P)
              }
            />
          )}
        </Drawer>
        <ChangeLogPatent
          visible={this.state.changeLogVisible}
          onCloseModal={() => this.closeChangeLog()}
        />
        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_P_NOTES, PATCH_P_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={P}
          application_number={sessionStorage.getItem(
            "patent_application_number",
          )}
          note_id={this.state.note_id}
          note={this.state.note}
          type={this.state.type}
        />
        <RenewalFiledModal
          visible={this.state.renewalFiledVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("renewalFiledVisible", false)
          }
          renewal={this.state.renewal}
          application_number={this.state.application_number}
          title_of_invention={this.state.title_of_invention}
          pseudo={this.state.pseudo}
        />
        <ReplyFiledModal
          visible={this.state.replyFiledVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("replyFiledVisible", false)
          }
          pending_reply={this.state.pending_reply}
          application_number={this.state.application_number}
          title={this.state.title}
        />
        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"patent"}
        />
        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          source={`PATENT`}
          sourceId={this.state.modalsVisibility.sourceId}
          managerEmail={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getRefinedPatent,
  getPatentChangelog,
  postDownloadFile,
  putUploadedFile,
  postUploadFile,
  deleteUploadedFile,
  getTeamMember,
  logOut,
  getNotes,
  deleteNotes,
};

function mapStateToProps(state) {
  const { teamMember, teamMemberStatus } = state.profile;
  const {
    refinedPatent,
    refinedPatentStatus,
    patentChangelog,
    patentChangelogStatus,
  } = state.managerState.patentDashboardState;

  const {
    manageNotes,
    manageNotesStatus,
    manageNotesMessage,
    addManageNotesStatus,
    editManageNotesStatus,
    deleteManageNotesStatus,
    deleteAllManageNotesStatus,
  } = state.managerState.notesState;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;

  return deepFreeze({
    teamMember,
    teamMemberStatus,
    refinedPatent,
    refinedPatentStatus,
    patentChangelog,
    patentChangelogStatus,
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    manageNotes,
    manageNotesStatus,
    manageNotesMessage,
    addManageNotesStatus,
    editManageNotesStatus,
    deleteManageNotesStatus,
    deleteAllManageNotesStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(PatentProfile);
