export const TM_DASHBOARD = 'TM_DASHBOARD';
export const OPPOSED_TM_DASHBOARD = 'OPPOSED_TM_DASHBOARD';
export const PATENT_DASHBOARD = 'PATENT_DASHBOARD';

export const TM_NOTIFICATION = 'TM_NOTIFICATION';
export const PATENT_NOTIFICATION = 'PATENT_NOTIFICATION';
export const CUSTOM_NOTIFICATION = 'CUSTOM_NOTIFICATION';

export const TM_CUSTOM = 'TM_CUSTOM';
export const TM_INTERNATIONAL = 'TM_INTERNATIONAL';
export const PATENT_CUSTOM = 'PATENT_CUSTOM';
export const PATENT_INTERNATIONAL = 'PATENT_INTERNATIONAL';

export const DELETE_TM_CUSTOM = 'DELETE_TM_CUSTOM';
export const DELETE_TM_INTERNATIONAL = 'DELETE_TM_INTERNATIONAL';
export const DELETE_PATENT_CUSTOM = 'DELETE_PATENT_CUSTOM';
export const DELETE_PATENT_INTERNATIONAL = 'DELETE_PATENT_INTERNATIONAL';

export const TM_CUSTOM_PROFILE = 'TM_CUSTOM_PROFILE';
export const TM_INTERNATIONAL_PROFILE = 'TM_INTERNATIONAL_PROFILE';
export const PATENT_CUSTOM_PROFILE = 'PATENT_CUSTOM_PROFILE';
export const PATENT_INTERNATIONAL_PROFILE = 'PATENT_INTERNATIONAL_PROFILE';

export const DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD';

export const TM_EDIT_CUSTOM_PROFILE = 'TM_EDIT_CUSTOM_PROFILE';
export const TM_EDIT_INTERNATIONAL_PROFILE = 'TM_EDIT_INTERNATIONAL_PROFILE';
export const PATENT_EDIT_CUSTOM_PROFILE = 'PATENT_EDIT_CUSTOM_PROFILE';
export const PATENT_EDIT_INTERNATIONAL_PROFILE = 'PATENT_EDIT_INTERNATIONAL_PROFILE';

export const TM_DASHBOARD_ANALYTICS = 'TM_DASHBOARD_ANALYTICS';

export const OPPOSED_TM_DASHBOARD_ANALYTICS = 'OPPOSED_TM_DASHBOARD_ANALYTICS';

export const PATENT_DASHBOARD_ANALYTICS = 'PATENT_DASHBOARD_ANALYTICS';

