export const GET_CLIENT_MASTER_DATA = "GET_CLIENT_MASTER_DATA";
export const ADD_CLIENT_MASTER_DATA = "ADD_CLIENT_MASTER_DATA";
export const UPDATE_CLIENT_MASTER_DATA = "UPDATE_CLIENT_MASTER_DATA";
export const DELETE_CLIENT_MASTER_DATA = "DELETE_CLIENT_MASTER_DATA";

export const UPDATE_SINGLE_CLIENT_MASTER = "UPDATE_SINGLE_CLIENT_MASTER";
export const UPDATE_SINGLE_CLIENT_CONTACT = "UPDATE_SINGLE_CLIENT_CONTACT";
export const UPDATE_SINGLE_CLIENT_NOTES = "UPDATE_SINGLE_CLIENT_NOTES";

export const GET_CLIENT_CONTACT_DATA = "GET_CLIENT_CONTACT_DATA";
export const ADD_CLIENT_CONTACT_DATA = "ADD_CLIENT_CONTACT_DATA";
export const UPDATE_CLIENT_CONTACT_DATA = "UPDATE_CLIENT_CONTACT_DATA";
export const DELETE_CLIENT_CONTACT_DATA = "DELETE_CLIENT_CONTACT_DATA";

export const GET_CLIENT_NOTES_DATA = "GET_CLIENT_NOTES_DATA";
export const ADD_CLIENT_NOTES_DATA = "ADD_CLIENT_NOTES_DATA";
export const UPDATE_CLIENT_NOTES_DATA = "UPDATE_CLIENT_NOTES_DATA";
export const DELETE_CLIENT_NOTES_DATA = "DELETE_CLIENT_NOTES_DATA";

export const GET_CLIENT_ATTACHMENT_DATA = "GET_CLIENT_ATTACHMENT_DATA";
export const ADD_CLIENT_ATTACHMENT_DATA = "ADD_CLIENT_ATTACHMENT_DATA";
export const DELETE_CLIENT_ATTACHMENT_DATA = "DELETE_CLIENT_ATTACHMENT_DATA";

export const BULK_ADD_CLIENT_MASTER_DATA = "BULK_ADD_CLIENT_MASTER_DATA";
export const BULK_DOWNLOAD_CLIENT_MASTER_DATA =
  "BULK_DOWNLOAD_CLIENT_MASTER_DATA";
