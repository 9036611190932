import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleFilled } from "@ant-design/icons";
import {
  Layout,
  Row,
  Tabs,
  Col,
  Typography,
  Checkbox,
  Button,
  Alert,
  message,
  Spin,
  Tooltip,
  Popconfirm,
} from "antd";

//--------ACTIONS--------
import {
  getEmailPreferences,
  saveEmailPreferences,
} from "../../../redux/actions/commonActions/emailPreference";

const { TabPane } = Tabs;
const { Content } = Layout;
const { Title, Text } = Typography;

const MANAGER = "manager";
const WATCH = "watch";
const SEARCH = "search";

const EmailPreferences = () => {
  const dispatch = useDispatch();

  const productList =
    useSelector((state) => state.productSubscription.productSubscription)?.map(
      (x) => x.value
    ) || [];

  const [product, setProduct] = useState(null);

  const getEmailPreferencesStatus = useSelector(
    (state) => state.emailPreference.getEmailPreferencesStatus
  );
  const saveEmailPreferencesStatus = useSelector(
    (state) => state.emailPreference.saveEmailPreferencesStatus
  );
  const emailPreferences =
    useSelector((state) => state.emailPreference.emailPreferences) || [];
  const [data, setData] = useState(emailPreferences[product]);

  useEffect(() => {
    if (product === null) {
      if (productList?.includes("MANAGER")) setProduct(MANAGER);
      else if (productList?.includes("WATCH")) setProduct(WATCH);
      else if (productList?.includes("SEARCH")) setProduct(SEARCH);
    }
  }, [productList, product]);

  useEffect(() => {
    setData(emailPreferences[product]);
  }, [emailPreferences, product]);

  useEffect(() => {
    dispatch(getEmailPreferences());
  }, [dispatch]);

  useEffect(() => {
    if (saveEmailPreferencesStatus === "LOADING")
      message.loading({ content: "Saving Your Preferences", key: "updatable" });
    else if (saveEmailPreferencesStatus === "SUCCESS")
      message.success({ content: "Preferences Saved!", key: "updatable" });
    else if (saveEmailPreferencesStatus === "ERROR")
      message.success({
        content: "There was an issue in Saving Preference!",
        key: "updatable",
        duration: 2,
      });
  }, [saveEmailPreferencesStatus]);

  const handleTabChange = (prod) => {
    setProduct(prod);
    setData(emailPreferences[prod]);
  };

  const applySetting = () => {
    let backendSetting = data?.map((obj) => ({
      id: obj.id,
      mail_permitted: obj.mail_permitted,
    }));
    dispatch(
      saveEmailPreferences({
        email_preferences: backendSetting,
        product,
      })
    );
  };

  const onChange = (e, id) =>
    setData(
      data?.map((obj) =>
        obj.id === id ? { ...obj, mail_permitted: e.target.checked } : obj
      )
    );

  const checkBoxes = data?.map((obj) => {
    return (
      <Col
        span={24}
        style={{
          margin: "8px 0px",
        }}
        key={obj?.id}
      >
        <Checkbox
          checked={obj?.mail_permitted}
          onChange={(e) => onChange(e, obj?.id)}
        >
          <Text style={{ fontWeight: "700" }}>
            <span
              style={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {obj?.label_name}

              <Tooltip title={obj?.description} placement="right">
                <InfoCircleFilled
                  style={{ color: "#C1C1C1", marginLeft: "5px" }}
                />
              </Tooltip>
            </span>
          </Text>
        </Checkbox>
      </Col>
    );
  });

  const savePreferences =
    saveEmailPreferencesStatus === "LOADING" ? (
      <Popconfirm
        placement="topLeft"
        title="Changing preferences would impact the existing reports in progress. Are you sure you want to change?"
        onConfirm={() => applySetting()}
      >
        <Button type="primary" loading>
          Save Settings
        </Button>
      </Popconfirm>
    ) : (
      <Popconfirm
        placement="topLeft"
        title="Changing preferences would impact the existing reports in progress. Are you sure you want to change?"
        onConfirm={() => applySetting()}
      >
        <Button type="primary">
          <div style={{ cursor: "pointer" }}>
            <i className="fa fa-check" aria-hidden="true" />
            <Text style={{ color: "#fff", marginLeft: "5px" }}>
              Save Settings
            </Text>
          </div>
        </Button>
      </Popconfirm>
    );

  const tabContent = (
    <Content style={{ margin: "16px 0px" }}>
      <Alert
        message={"You will receive emails only from the checked options."}
        type="info"
        showIcon
      />
      <Row style={{ maxHeight: "480px", overflow: "auto" }}>{checkBoxes}</Row>
      <Col
        span={24}
        style={{
          margin: "16px 0px",
        }}
      >
        {savePreferences}
      </Col>
    </Content>
  );

  return (
    <Layout
      style={{ background: "white", padding: "24px", maxHeight: "100vh" }}
    >
      <Content>
        <div style={{ margin: "0px" }}>
          <Row>
            <Col xs={{ span: 12 }} lg={{ span: 22 }}>
              <Title level={3}>Email Preferences</Title>
            </Col>
          </Row>
          {getEmailPreferencesStatus &&
          getEmailPreferencesStatus === "ERROR" ? (
            <div style={{ margin: "16px 0px" }}>
              <Alert
                message={
                  <>
                    There was some issue while getting your email preferences.
                    <p>
                      Please try again after sometime or reach out to{" "}
                      <a href="mailto:team@Mikelegal.com">team@Mikelegal.com</a>
                    </p>
                  </>
                }
                type="error"
                showIcon
              />
            </div>
          ) : getEmailPreferencesStatus &&
            getEmailPreferencesStatus === "LOADING" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minHeight: "3em",
              }}
            >
              <Spin />
            </div>
          ) : (
            <Row>
              <Tabs
                activeKey={product}
                onChange={handleTabChange}
                tabBarStyle={{ margin: "0px" }}
              >
                {productList && productList?.includes("MANAGER") && (
                  <TabPane
                    tab={"MikeIP Manager"}
                    key={MANAGER}
                    style={{ margin: "0px" }}
                  >
                    {tabContent}
                  </TabPane>
                )}
                {productList && productList?.includes("WATCH") && (
                  <TabPane
                    tab={"MikeTM Watch"}
                    key={WATCH}
                    style={{ margin: "0px" }}
                  >
                    {tabContent}
                  </TabPane>
                )}
                {productList && productList?.includes("SEARCH") && (
                  <TabPane
                    tab={"MikeTM Search"}
                    key={SEARCH}
                    style={{ margin: "0px" }}
                  >
                    {tabContent}
                  </TabPane>
                )}
              </Tabs>
            </Row>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default EmailPreferences;
