//--------LIBRARIES--------
import React from "react";
import _ from "lodash";
import { Row, Col, Card } from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Bar,
  Cell,
  XAxis,
  YAxis,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import {
  renderBarCustomTooltip,
  renderPieCustomTooltip,
  renderPieCustomizedLabel,
  resetLabelPositions,
} from "../../../functions/commonFunctions/reChartsFunctions";

const colors = scaleOrdinal([
  ...schemeCategory10,
  ...schemeCategory10,
  ...schemeCategory10,
]).range();

function ResultsAnalysis({
  analysisStatus,
  statusesDistribution,
  typesDistribution,
  classesDistribution,
  yearsDistribution,
}) {
  let StatusSeries = [];
  let TypeSeries = [];
  let ClassSeries = [];
  let YearSeries = [];

  if (analysisStatus === SUCCESS) {
    StatusSeries = _.map(statusesDistribution, function (graphData) {
      return {
        name: graphData?.status,
        value: graphData?.frequency,
      };
    });

    TypeSeries = _.map(typesDistribution, function (graphData) {
      return {
        name: graphData?.type,
        value: graphData?.frequency,
      };
    });

    ClassSeries = _.map(classesDistribution, function (graphData) {
      return {
        name: "Class " + graphData?.class_number,
        data: [graphData?.frequency],
        uv: graphData?.frequency,
      };
    });

    YearSeries = _.map(yearsDistribution, function (graphData) {
      return {
        name: "Year " + graphData?.year,
        data: [graphData?.frequency],
        uv: graphData?.frequency,
      };
    });

    StatusSeries = _.filter(StatusSeries, function (distribution) {
      return distribution.value !== 0 && distribution.name !== null;
    });

    TypeSeries = _.filter(TypeSeries, function (distribution) {
      return distribution.value !== 0 && distribution.name !== null;
    });

    ClassSeries = _.filter(ClassSeries, function (distribution) {
      return distribution.data[0] !== 0;
    });

    YearSeries = _.filter(YearSeries, function (distribution) {
      return distribution.data[0] !== 0;
    });
  }

  const chartIdStatus = "result_analysis_statusChart";
  const chartIdType = "result_analysis_typeChart";

  // Reset label positions for this chartId before rendering
  resetLabelPositions(chartIdStatus);
  resetLabelPositions(chartIdType);

  return (
    <Row gutter={16}>
      {/* status distribution */}
      {classesDistribution && (
        <Col className="gutter-row" span={24}>
          <Card
            hoverable
            bodyStyle={{ textAlign: "center", padding: "15px" }}
            loading={analysisStatus === LOADING}
          >
            <h5>Status Distribution</h5>
            <div className="recharts-parent-div-custom-style">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={StatusSeries}
                    label={(props) =>
                      renderPieCustomizedLabel(props, chartIdStatus)
                    }
                    labelLine={false}
                    isAnimationActive={false}
                    fill="#8884d8"
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                  >
                    {StatusSeries.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value) => value}
                    content={(props) =>
                      renderPieCustomTooltip(props, StatusSeries ?? [])
                    }
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      )}
      {/* status distribution */}

      {/* type distribution */}
      {typesDistribution && (
        <Col className="gutter-row" span={24}>
          <Card
            hoverable
            bodyStyle={{ textAlign: "center", padding: "15px" }}
            loading={analysisStatus === LOADING}
          >
            <h5>Type Distribution</h5>
            <div className="recharts-parent-div-custom-style">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={TypeSeries}
                    label={(props) =>
                      renderPieCustomizedLabel(props, chartIdType)
                    }
                    labelLine={false}
                    isAnimationActive={false}
                    fill="#8884d8"
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                  >
                    {TypeSeries.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value) => value}
                    content={(props) =>
                      renderPieCustomTooltip(props, TypeSeries ?? [])
                    }
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      )}
      {/* type distribution */}

      {/* class distribution */}
      {classesDistribution && (
        <Col className="gutter-row m-top-20" span={24}>
          <Card
            hoverable
            bodyStyle={{ textAlign: "center", padding: "15px" }}
            loading={analysisStatus === LOADING}
          >
            <h5>Classes</h5>
            <p>Class-wise Distribution</p>
            <div className="recharts-parent-div-custom-style">
              <ResponsiveContainer>
                <BarChart data={ClassSeries} margin={{ bottom: 30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    label={{
                      value: "Classes",
                      offset: -20,
                      position: "insideBottom",
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Trademarks",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Bar dataKey="uv" fill="#8884d8">
                    {ClassSeries.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Bar>
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={renderBarCustomTooltip}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      )}
      {/* class distribution */}

      {/* year distribution */}
      {yearsDistribution && (
        <Col className="gutter-row m-top-20" span={24}>
          <Card
            hoverable
            bodyStyle={{ textAlign: "center", padding: "15px" }}
            loading={analysisStatus === LOADING}
          >
            <h5>Year</h5>
            <p>Year-wise Distribution</p>
            <div className="recharts-parent-div-custom-style">
              <ResponsiveContainer>
                <BarChart data={YearSeries} margin={{ bottom: 30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    label={{
                      value: "Year",
                      offset: -20,
                      position: "insideBottom",
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Trademarks",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Bar
                    dataKey="uv"
                    fill="#8884d8"
                    // label={{ position: "top" }}
                  >
                    {YearSeries.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Bar>
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={renderBarCustomTooltip}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      )}
      {/* year distribution */}
    </Row>
  );
}

export default ResultsAnalysis;
