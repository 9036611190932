import React, { useEffect, useState } from "react";
import { TabContent } from "../../commonComponents/TabContent";
import { Popconfirm, Tooltip, notification } from "antd";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ClientContactAddComponent from "./ClientContactAddComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClientContactData,
  getClientContactData,
  updateSingleClientContactData,
} from "../../../../../redux/actions/commonActions/masterActions/clientContact";
import ClientContactEditComponent from "./ClientContactEditComponent";

export const ClientContactComponent = (props) => {
  const { parent, activeTab, key_value } = props;
  const dispatch = useDispatch();
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const tabHeight = 520;

  const { data, loading, metadata } = useSelector(
    (state) => state.masters.clientMaster.clientContact,
  );
  const { count, pageNumber } = metadata;

  const getClientContactListData = () => {
    dispatch(getClientContactData(pageNumber, parent));
  };

  useEffect(() => {
    if (parent && activeTab === key_value) {
      getClientContactListData();
    }
  }, [parent, activeTab, dispatch]);

  const handleAddClientContact = () => {
    setOpenAddForm(true);
  };

  const handleClientContactNextPage = () => {};

  const handleEditClientContact = (clientContact) => {
    dispatch(updateSingleClientContactData(clientContact));
    setOpenEditForm(true);
  };

  const handleDeleteClientContact = (clientContact) => {
    dispatch(deleteClientContactData(clientContact, pageNumber));
  };

  const renderColumns = [
    {
      className: "tm-protect-column-1",
      title: "S.No",
      width: 50,

      render: (text, record, index) => {
        return {
          children: (
            <div>
              <p>
                <span>{index + 1 + (pageNumber - 1) * 100}. </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Contact Name",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>{checkValue(record?.name) ? record?.name : "N.A."}</span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Email Id",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.email) ? record?.email : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Phone Number",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.phone) ? record?.phone : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Actions",
      width: 80,
      render: (text, record) => {
        return (
          <div
            className="iconTable tmapplied_ll_actions"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              padding: "8px",
            }}
          >
            <Tooltip title="Edit Client Contact">
              <EditOutlined
                style={{ cursor: "pointer", color: "#1677ff" }}
                onClick={() => handleEditClientContact(record)}
              />
            </Tooltip>
            <Popconfirm
              title="Delete Client Contact"
              description="Are you sure to delete this contact?"
              onConfirm={() => handleDeleteClientContact(record)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Client Contact">
                <DeleteOutlined style={{ cursor: "pointer", color: "red" }} />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const propsForTabContent = {
    header: "Client Contact",
    addButtonLabel: "Add Client Contact",
    handleAddButton: handleAddClientContact,
    childrenType: "table",
    loading: loading,
    tablePageNumber: pageNumber,
    dataCount: count,
    handleTableNextPage: handleClientContactNextPage,
    renderColumns: renderColumns,
    tableData: data,
    isSelectable: true,
    tableHeight: tabHeight - 210,
  };

  const propsForAddContacttPage = {
    open: openAddForm,
    setOpen: setOpenAddForm,
    client_id: parent,
  };

  const propsForEditContacttPage = {
    open: openEditForm,
    setOpen: setOpenEditForm,
    client_id: parent,
  };

  return (
    <div style={{ height: tabHeight, overflowY: "auto" }}>
      {openAddForm && (
        <ClientContactAddComponent {...propsForAddContacttPage} />
      )}
      {openEditForm && (
        <ClientContactEditComponent {...propsForEditContacttPage} />
      )}
      <TabContent {...propsForTabContent} />
    </div>
  );
};
