//--------COMMON ACTIONS--------
import {
  LOG_IN,
  LOG_OUT,
  SIGN_UP,
  MARK_UPLOAD,
  MARK_DOWNLOAD,
  GET_TAGS,
  ADD_TAGS,
  DELETE_TAGS,
  EDIT_TAGS,
  GET_PATENT_TAGS,
  ADD_PATENT_TAGS,
  DELETE_PATENT_TAGS,
  EDIT_PATENT_TAGS,
  VERIFY_AUTH_CODE,
  SETUP_AUTH,
  REGENERATE_CODE,
  SET_MFA,
  DELETE_MFA,
  SET_RECOVERY_CODES,
  VERIFY_SETUP,
  RESET_PROFILE_PASSWORD,
} from "../actions/commonActions/types";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../constants/commonConstants/generalConstants";

const defaultSignInState = {
  logInMessage: null,
  logInStatus: null,
  logOutMessage: null,
  logOutStatus: null,
  signUpMessage: null,
  signUpSubscriptions: null,
  signUpQuota: null,
  signUpStatus: null,
  markUploadData: "",
  markUploadStatus: "",
  markDownloadData: "",
  markDownloadStatus: "",
  manageTags: null,
  manageTagsStatus: null,
  authSetupStatus: null,
  authData: null,
  authCodeStatus: null,
  authVerification: null,
  mfaRequired: null,
  mfaSetup: null,
  recoveryCodes: [],
  setMFAStatus: null,
  setMFAError: "",
  verifySetupStatus: null,
  verifySetupData: null,
  verifySetupError: "",
  resetPasswordStatus: null,
  resetPasswordMessage: "",
};

export default function (state = defaultSignInState, action) {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        logInMessage: action.payload,
        logInStatus: action.status,
      };

    case LOG_OUT:
      return {
        ...state,
        logOutMessage: action.payload,
        logOutStatus: action.status,
      };

    case SIGN_UP:
      return {
        ...state,
        signUpMessage: action.payload,
        signUpSubscriptions: action.subscriptions,
        signUpQuota: action.quota,
        signUpStatus: action.status,
      };

    case SET_MFA:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            setMFAStatus: LOADING,
            setMFAError: "",
            deleteMFAStatus: "",
          };
        case SUCCESS:
          return {
            ...state,
            setMFAStatus: SUCCESS,
            mfaRequired: action.payload?.mfa,
            mfaSetup: action.payload?.mfa_setup,
          };
        case ERROR:
          return {
            ...state,
            setMFAStatus: ERROR,
            setMFAError: action?.payload?.message || "Something went wrong",
          };
        default:
          return state;
      }

    case DELETE_MFA:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteMFAStatus: LOADING,
            deleteMFAError: "",
          };
        case SUCCESS:
          return {
            ...state,
            deleteMFAStatus: SUCCESS,
          };
        case ERROR:
          return {
            ...state,
            deleteMFAStatus: ERROR,
            deleteMFAError: action?.payload?.message || "Something went wrong",
          };
        default:
          return state;
      }

    case RESET_PROFILE_PASSWORD:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            resetPasswordStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            resetPasswordStatus: SUCCESS,
            resetPasswordMessage: action?.payload,
          };

        case ERROR:
          return {
            ...state,
            resetPasswordStatus: ERROR,
            resetPasswordMessage: action?.payload || "Something went wrong",
          };
        default:
          return state;
      }

    case VERIFY_AUTH_CODE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            authCodeStatus: action.status,
          };

        case SUCCESS:
          return {
            ...state,
            authCodeStatus: action.status,
            authVerification: action.payload,
          };
        case ERROR:
          return {
            ...state,
            authCodeStatus: action.status,
          };
        default:
          return state;
      }

    case SET_RECOVERY_CODES:
      return {
        ...state,
        recoveryCodes:
          action.payload?.recovery_codes &&
          action.payload?.recovery_codes?.length > 0
            ? action.payload?.recovery_codes
            : [],
      };
    case VERIFY_SETUP:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            verifySetupError: "",
            verifySetupStatus: action.status,
          };

        case SUCCESS:
          return {
            ...state,
            verifySetupStatus: action.status,
            verifySetupData: action.payload,
          };
        case ERROR:
          return {
            ...state,
            verifySetupError: action.payload,
            verifySetupStatus: action.status,
          };
        default:
          return state;
      }

    case SETUP_AUTH:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            authSetupStatus: action.status,
          };
        case SUCCESS:
          return {
            ...state,
            authData: action.payload,
            authSetupStatus: action.status,
          };
        case ERROR:
          return {
            ...state,
            authSetupStatus: action.status,
          };

        default:
          return state;
      }

    case REGENERATE_CODE:
      return {
        ...state,
      };

    case MARK_UPLOAD:
      return {
        ...state,
        markUploadData: action.payload,
        markUploadStatus: action.status,
      };

    case MARK_DOWNLOAD:
      return {
        ...state,
        markDownloadData: action.payload,
        markDownloadStatus: action.status,
      };

    case GET_TAGS:
      return {
        ...state,
        manageTags: action.payload,
        manageTagsStatus: action.status,
      };

    case ADD_TAGS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            addTagsStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            manageTags: {
              ...state.manageTags,
              data: state.manageTags.data.concat(action.payload.data),
            },
            addTagsStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            addTagsStatus: ERROR,
          };

        default:
          return state;
      }

    case DELETE_TAGS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteTagsStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            manageTags: {
              ...state.manageTags,
              data: state.manageTags.data.filter(
                (item) => item.id !== action.payload.id
              ),
            },
            deleteTagsStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            deleteTagsStatus: ERROR,
          };

        default:
          return state;
      }

    case EDIT_TAGS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            editTagsStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            manageTags: {
              ...state.manageTags,
              data: state.manageTags.data.map((item) =>
                item.id === action.payload.id
                  ? action.payload.response?.data
                  : item
              ),
            },
            editTagsStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            editTagsStatus: ERROR,
          };

        default:
          return state;
      }
    // ************************************PATENT TAGS************************//
    case GET_PATENT_TAGS:
      return {
        ...state,
        manageTags: action.payload,
        manageTagsStatus: action.status,
      };

    case ADD_PATENT_TAGS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            addTagsStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            manageTags: {
              ...state.manageTags,
              data: state.manageTags.data.concat(action.payload.data),
            },
            addTagsStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            addTagsStatus: ERROR,
          };

        default:
          return state;
      }

    case DELETE_PATENT_TAGS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteTagsStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            manageTags: {
              ...state.manageTags,
              data: state.manageTags.data.filter(
                (item) => item.id !== action.payload.id
              ),
            },
            deleteTagsStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            deleteTagsStatus: ERROR,
          };

        default:
          return state;
      }

    case EDIT_PATENT_TAGS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            editTagsStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            manageTags: {
              ...state.manageTags,
              data: state.manageTags.data.map((item) =>
                item.id === action.payload.id
                  ? action.payload.response?.data
                  : item
              ),
            },
            editTagsStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            editTagsStatus: ERROR,
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
