import React from "react";
import {
  Space,
  Row,
  Col,
  Tag,
  Collapse,
  Switch,
  Menu,
  Checkbox,
  Typography,
  Divider,
} from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import SelectFilter from "../../SelectFilter";
import MenuFilter from "../../MenuFilter";
import DateFilter from "../../DateFilter";
import ProprietorFilterComponent from "../../ProprietorFilterComponent";
import MultipleCheckboxComponents from "../../MultipleCheckboxComponents";
import SimilarityFilter from "./../../SimilarityFilter";

const { Panel } = Collapse;
const { Text } = Typography;

const FilterSection = (props) => {
  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3];

  const [activeDashboardCollapseKeys, setActiveDashboardCollapseKeys] =
    React.useState([]);
  const [activeAdvancedCollapseKeys, setActiveAdvancedCollapseKeys] =
    React.useState([]);
  const [showSelection, setShowSelection] = React.useState(false);
  const {
    journal,
    threatTMTypes,
    setThreatTMTypes,
    threatTMCategories,
    setThreatTMCategories,
    threatTMOffices,
    setThreatTMOffices,
    infringement,
    setInfringement,
    threatAttorneyValue,
    setThreatAttorneyValue,
    threatProprietors,
    setThreatProprietors,
    threatTMClass,
    setThreatClass,
    similarity,
    setSimilarity,
    threatDateOfApplication,
    setThreatDateOfApplication,
    threatDateOfUsage,
    setThreatDateOfUsage,
    threatDateOfUsageProposed,
    setThreatDateOfUsageProposed,
    excludeThreatProprietors,
    setExcludeThreatProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    current_template_id,
    andOrThreatClass,
    setAndOrThreatClass,
    shortlisted,
    setShortlisted,
    excludeShortlisted,
    setExcludeShortlisted,
    excludeReported,
    setExcludeReported,
    threatFilterSelected,
    threatLabel,
    setThreatLabel,
    andOrThreatLabel,
    setAndOrThreatLabel,
    getFilterPath,
    markDetails,
  } = props;

  const useMemoizedComponent = (Component, deps) => {
    return React.useMemo(() => Component, deps);
  };

  const {
    threatTypeConfig,
    threatCategoryConfig,
    threatOfficeConfig,
    infringementConfig,
    threatAttorneyConfig,
    threatproprietorConfig,
    threatClassConfig,
    threatLabelConfig,
  } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const handleRemoveTag = (e, id, list, setVal) => {
    e.preventDefault();
    let tempData = list.filter((item) => item !== id);
    setVal(tempData);
  };

  const oneLayoutChildrenComponent = (data, value, setValue) => (
    <React.Fragment>
      <Row style={{ marginBottom: "10px", fontWeight: "600" }}>
        <Col span={24}>{data.menu_label ? data.menu_label : ""}</Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            maxHeight: "80px",
            overflow: "auto",
            padding: "0px 5px",
          }}
        >
          <Space wrap>
            {value.length ? (
              value.map((id, index) => (
                <Tag
                  closable
                  key={index}
                  onClose={(event) =>
                    handleRemoveTag(event, id, value, setValue)
                  }
                  style={{ margin: "auto" }}
                >
                  {data.data.length || data.selectedValues.length
                    ? [...data.data, ...data.selectedValues].find(
                        (item) => String(item.value) === String(id),
                      )?.label
                    : ""}
                </Tag>
              ))
            ) : (
              <div>Filter not selected</div>
            )}
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  );

  const oneLayoutExcludeChildrenComponent = (data, value, setValue) => (
    <React.Fragment>
      <Row style={{ marginBottom: "10px", fontWeight: "600" }}>
        <Col span={24}>{data.menu_label ? data.menu_label : ""}</Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            maxHeight: "80px",
            overflow: "auto",
            padding: "0px 5px",
          }}
        >
          <Space wrap>
            {value.length ? (
              value.map((id, index) => (
                <Tag
                  closable
                  key={index}
                  onClose={(event) =>
                    handleRemoveTag(event, id, value, setValue)
                  }
                  style={{ margin: "auto" }}
                >
                  {data.data.length || data.excludeValues.length
                    ? [...data.data, ...data.excludeValues].find(
                        (item) => String(item.value) === String(id),
                      )?.label
                    : ""}
                </Tag>
              ))
            ) : (
              <div>Filter not selected</div>
            )}
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  );

  const renderSimpleTag = (label, color = "default") => {
    return (
      <div style={{ marginTop: "3px", marginBottom: "3px" }}>
        <Tag color={color}>{label}</Tag>
      </div>
    );
  };

  const renderBoldText = (text) => (
    <Text style={{ fontWeight: 600 }}>{text}</Text>
  );

  const showNotSelected = (
    <div style={{ marginTop: "3px", marginBottom: "3px" }}>Not Selected</div>
  );

  const dateChildrenComponent = (
    <Row>
      <Col span={12}>
        {renderBoldText("Threat's TM DoA")}
        <br />
        {threatDateOfApplication.dateString
          ? renderSimpleTag(
              threatDateOfApplication.dateString.replace("to", " -> "),
            )
          : showNotSelected}
        <br />
        {renderBoldText("Threat's TM DoU")}
        <br />
        {threatDateOfUsage.dateString
          ? renderSimpleTag(threatDateOfUsage.dateString.replace("to", " -> "))
          : showNotSelected}
        {threatDateOfUsageProposed
          ? renderSimpleTag("Proposed", "processing")
          : ""}
      </Col>
    </Row>
  );

  const extraClassChildren = (
    <>
      <div style={{ textAlign: "left", padding: "5px" }}>
        <Checkbox
          disabled={excludeSameClassInfringement}
          checked={sameClassInfringement}
          onChange={(event) => setSameClassInfringement(event.target.checked)}
        >
          Same Class Infringements
        </Checkbox>
      </div>
      <div style={{ textAlign: "left", padding: "5px" }}>
        <Checkbox
          disabled={sameClassInfringement}
          checked={excludeSameClassInfringement}
          onChange={(event) =>
            setExcludeSameClassInfringement(event.target.checked)
          }
        >
          Exclude Same Class Infringements
        </Checkbox>
      </div>
    </>
  );

  const advancedFilterItems = [
    {
      key: "1",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <SelectFilter
                filteredData={threatAttorneyConfig}
                key={threatAttorneyConfig.key}
                value={threatAttorneyValue}
                setValue={setThreatAttorneyValue}
                loading={threatAttorneyConfig.loading}
                journal_number={journal}
                current_template_id={current_template_id}
                filterPath={getFilterPath()}
                application_number={markDetails.application_number}
                rename="Threat's Attorney"
              />,
              [threatAttorneyValue, threatAttorneyConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !threatAttorneyValue.length,
      children: (
        <div>
          {oneLayoutChildrenComponent(
            threatAttorneyConfig,
            threatAttorneyValue,
            setThreatAttorneyValue,
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <ProprietorFilterComponent
                filteredData={threatproprietorConfig}
                key={threatproprietorConfig.key}
                value={threatProprietors}
                setValue={setThreatProprietors}
                loading={threatproprietorConfig.loading}
                journal_number={journal}
                exclude={excludeThreatProprietors}
                setExclude={setExcludeThreatProprietors}
                current_template_id={current_template_id}
                filterPath={getFilterPath()}
                application_number={markDetails.application_number}
              />,
              [
                threatProprietors,
                threatproprietorConfig,
                excludeThreatProprietors,
              ],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(threatProprietors.length || excludeThreatProprietors.length),
      children: (
        <div>
          <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
            {oneLayoutChildrenComponent(
              threatproprietorConfig,
              threatProprietors,
              setThreatProprietors,
            )}
          </div>
          <Divider
            style={{ margin: "0px", marginTop: "15px", marginBottom: "10px" }}
          />
          <Text style={{ color: "#ff0000", fontWeight: 600 }}>Excluded</Text>
          <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
            {oneLayoutExcludeChildrenComponent(
              threatproprietorConfig,
              excludeThreatProprietors,
              setExcludeThreatProprietors,
            )}
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <SelectFilter
                filteredData={threatClassConfig}
                key={threatClassConfig.key}
                value={threatTMClass}
                setValue={setThreatClass}
                loading={threatClassConfig.loading}
                journal_number={journal}
                extraChildren={urlKey !== "tm_protect" && extraClassChildren}
                showSwitch
                andOr={andOrThreatClass}
                setAndOr={setAndOrThreatClass}
                current_template_id={current_template_id}
                extraHighlight={
                  sameClassInfringement || excludeSameClassInfringement
                }
                filterPath={getFilterPath()}
                application_number={markDetails.application_number}
              />,
              [
                threatClassConfig,
                threatTMClass,
                excludeSameClassInfringement,
                sameClassInfringement,
                andOrThreatClass,
              ],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(
        threatTMClass.length ||
        sameClassInfringement ||
        excludeSameClassInfringement
      ),
      children: (
        <div>
          {oneLayoutChildrenComponent(
            threatClassConfig,
            threatTMClass,
            setThreatClass,
          )}
          <Divider />
          {extraClassChildren}
        </div>
      ),
    },
  ];

  const dashboardFilterItems = [
    {
      key: "1",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MultipleCheckboxComponents
                excludedReport={excludeReported}
                setExcludedReport={setExcludeReported}
                shortlist={shortlisted}
                setShortlist={setShortlisted}
                excludeShortlisted={excludeShortlisted}
                setExcludeShortlisted={setExcludeShortlisted}
              />,
              [excludeReported, shortlisted, excludeShortlisted],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(shortlisted || excludeShortlisted || excludeReported),
      children: (
        <div>
          {useMemoizedComponent(
            <MultipleCheckboxComponents
              excludedReport={excludeReported}
              setExcludedReport={setExcludeReported}
              shortlist={shortlisted}
              setShortlist={setShortlisted}
              excludeShortlisted={excludeShortlisted}
              setExcludeShortlisted={setExcludeShortlisted}
              tableView
            />,
            [excludeReported, shortlisted, excludeShortlisted],
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MenuFilter
                filteredData={threatTypeConfig}
                key={threatTypeConfig.key}
                value={threatTMTypes}
                setValue={setThreatTMTypes}
                loading={threatTypeConfig.loading}
              />,
              [threatTMTypes, threatTypeConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !threatTMTypes.length,
      children: (
        <div>
          {oneLayoutChildrenComponent(
            threatTypeConfig,
            threatTMTypes,
            setThreatTMTypes,
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MenuFilter
                filteredData={threatCategoryConfig}
                key={threatCategoryConfig.key}
                value={threatTMCategories}
                setValue={setThreatTMCategories}
                loading={threatCategoryConfig.loading}
              />,
              [threatTMCategories, threatCategoryConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !threatTMCategories.length,
      children: (
        <div>
          {oneLayoutChildrenComponent(
            threatCategoryConfig,
            threatTMCategories,
            setThreatTMCategories,
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MenuFilter
                filteredData={threatOfficeConfig}
                key={threatOfficeConfig.key}
                value={threatTMOffices}
                setValue={setThreatTMOffices}
                loading={threatOfficeConfig.loading}
              />,
              [threatTMOffices, threatOfficeConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !threatTMOffices.length,
      children: (
        <div>
          {oneLayoutChildrenComponent(
            threatOfficeConfig,
            threatTMOffices,
            setThreatTMOffices,
          )}
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <DateFilter
                menu_label="Threat's DoA | DoU"
                subtitle1="Threat's Date of Application"
                subtitle2="Threat's Date of Usage"
                key={"threat_date"}
                value1={threatDateOfApplication}
                setValue1={setThreatDateOfApplication}
                value2={threatDateOfUsage}
                setValue2={setThreatDateOfUsage}
                proposed={threatDateOfUsageProposed}
                setProposed={setThreatDateOfUsageProposed}
              />,
              [
                threatDateOfApplication,
                threatDateOfUsage,
                threatDateOfUsageProposed,
              ],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(
        threatDateOfApplication?.dateString.length ||
        threatDateOfUsage?.dateString.length ||
        threatDateOfUsageProposed
      ),
      children: <div>{dateChildrenComponent}</div>,
    },
  ];

  const threatLabelObject = {
    key: "6",
    label: (
      <React.Fragment>
        <Space>
          <SelectFilter
            filteredData={threatLabelConfig}
            key={threatLabelConfig.key}
            value={threatLabel}
            setValue={setThreatLabel}
            journal_number={journal}
            loading={threatLabelConfig.loading}
            showSwitch
            andOr={andOrThreatLabel}
            setAndOr={setAndOrThreatLabel}
            current_template_id={current_template_id}
            filterPath={getFilterPath()}
            application_number={markDetails.application_number}
            rename="Focus Label"
          />
        </Space>
      </React.Fragment>
    ),
    disable: !threatLabel.length,
    children: (
      <div>
        {oneLayoutChildrenComponent(
          threatLabelConfig,
          threatLabel,
          setThreatLabel,
        )}
      </div>
    ),
  };

  const extraAdvancedObject = [
    {
      key: "4",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <SimilarityFilter
                key={"similarity"}
                value={similarity}
                setValue={setSimilarity}
              />,
              [similarity],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !(
        (similarity.from > 0 || similarity.to < 100) &&
        similarity.to !== null
      ),
      children: (
        <div>
          Range
          <br />
          {renderSimpleTag(`${similarity.from} - ${similarity.to}`)}
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <React.Fragment>
          <Space>
            {useMemoizedComponent(
              <MenuFilter
                filteredData={infringementConfig}
                key={infringementConfig.key}
                value={infringement}
                setValue={setInfringement}
                loading={infringementConfig.loading}
              />,
              [infringement, infringementConfig],
            )}
          </Space>
        </React.Fragment>
      ),
      disable: !infringement.length,
      children: (
        <div>
          {oneLayoutChildrenComponent(
            infringementConfig,
            infringement,
            setInfringement,
          )}
        </div>
      ),
    },
  ];

  if (urlKey === "image_label") {
    dashboardFilterItems.push(threatLabelObject);
  } else {
    advancedFilterItems.push(...extraAdvancedObject);
  }

  const onDashboardCollapseChange = (keys) => {
    setActiveDashboardCollapseKeys(keys);
  };

  const onAdvancedCollapseChange = (keys) => {
    setActiveAdvancedCollapseKeys(keys);
  };

  const collapseProps = {
    size: "small",
    expandIcon: ({ isActive }) =>
      !isActive ? <PlusSquareOutlined /> : <MinusSquareOutlined />,
    expandIconPosition: "end",
    style: { marginBottom: "20px" },
  };

  const handleShowSelection = (value) => {
    setShowSelection(value);
    if (value) {
      let tempDashboardActiveKeys = [
        shortlisted || excludeShortlisted || excludeReported ? "0" : null,
        threatTMTypes.length ? "1" : null,
        threatTMCategories.length ? "2" : null,
        threatTMOffices.length ? "3" : null,
        threatDateOfApplication.dateString.length ||
        threatDateOfUsage.dateString.length ||
        threatDateOfUsageProposed
          ? "4"
          : null,
        threatLabel.length ? "5" : null,
      ].filter(Boolean);

      let tempAdvancedActiveKeys = [
        threatAttorneyValue.length ? "0" : null,
        threatProprietors.length || excludeThreatProprietors.length
          ? "1"
          : null,
        threatTMClass.length ||
        sameClassInfringement ||
        excludeSameClassInfringement
          ? "2"
          : null,
        similarity.from > 0 || similarity.to < 100 ? "3" : null,
        infringement.length ? "4" : null,
      ];

      setActiveDashboardCollapseKeys(tempDashboardActiveKeys);
      setActiveAdvancedCollapseKeys(tempAdvancedActiveKeys);
    } else {
      setActiveDashboardCollapseKeys([]);
      setActiveAdvancedCollapseKeys([]);
    }
  };

  useEffect(() => {
    if (!threatFilterSelected) handleShowSelection(threatFilterSelected);
  }, [threatFilterSelected]);

  return (
    <React.Fragment>
      <Row style={{ marginBottom: "10px" }}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontWeight: "500",
            color: threatFilterSelected ? "black" : "lightgrey",
          }}
        >
          Show Selections &nbsp;
          <Switch
            checked={showSelection}
            disabled={!threatFilterSelected}
            onChange={handleShowSelection}
          />
        </Col>
        <Col span={24} style={{ fontWeight: "600", marginBottom: "10px" }}>
          Advanced Filters
        </Col>
        <Col span={24}>
          {advancedFilterItems.map((item, index) => (
            <Collapse
              key={index}
              {...collapseProps}
              activeKey={activeAdvancedCollapseKeys}
              onChange={onAdvancedCollapseChange}
            >
              <Panel
                header={item.label}
                key={index}
                collapsible={item.disable ? "disabled" : "icon"}
                style={{ background: !item.disable ? "#ebf4ff" : "inherit" }}
              >
                {item.children}
              </Panel>
            </Collapse>
          ))}
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ fontWeight: "600", margin: "10px 0px" }}>
          Dashboard Filters
        </Col>
        <Col span={24}>
          {dashboardFilterItems.map((item, index) => (
            <Collapse
              key={index}
              {...collapseProps}
              activeKey={activeDashboardCollapseKeys}
              onChange={onDashboardCollapseChange}
            >
              <Panel
                header={item.label}
                key={index}
                collapsible={item.disable ? "disabled" : "icon"}
                style={{ background: !item.disable ? "#ebf4ff" : "inherit" }}
              >
                {item.children}
              </Panel>
            </Collapse>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FilterSection;
