import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import _ from "lodash";

import {
  CarryOutTwoTone,
  DownOutlined,
  MessageTwoTone,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Button,
  Input,
  message,
  Modal,
  Typography,
  Checkbox,
  notification,
  Switch,
  Tooltip,
} from "antd";

//--------MANAGER-PATENT ACTIONS--------
import { getDashboard } from "../../../../redux/actions/managerActions/profile/actions";
import {
  getPatent,
  deletePatent,
} from "../../../../redux/actions/managerActions/patentDashboard/actions";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------HISTORY--------
import { history } from "../../../../history";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import {
  changeStateValue_L0,
  changeStateValue_L2,
} from "../../../../functions/commonFunctions/generalFunctions";
import {
  Patent_DefaultSelectedFilters,
  Patent_DefaultSearchFilters,
} from "../../../../constants/managerConstants/patentConstants";

//--------MODALS--------
import DownloadReportModal from "../../report/DownloadReportModal";
import CreateTask from "../../to-do-task/CreateTask";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { P } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_P_NOTES,
  PATCH_P_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import FilterSearchBasic from "../../../commonComponents/FilterComponents/FilterSearchBasic";

const { Text } = Typography;
const { Search } = Input;

class Patent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      visible: false,
      add_patent_id: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,
      management_patent_id: "",
      applicant_id: "",
      inventor_id: "",
      addTaskVisible: false,
      changeSwitch: false,

      selectedFilters: {
        ip_india: this.props.patentAppliedFilters,
        pseudo: this.props.pseudoPatentAppliedFilters,
      },

      searchFilters: {
        ip_india: Patent_DefaultSearchFilters,
        pseudo: Patent_DefaultSearchFilters,
      },
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
  }

  componentDidMount() {
    let type = "patent";
    if (localStorage.getItem("token")) {
      if (this.props.patentDashboard === null) {
        this.props.getDashboard(type);
      }

      if (this.props.patent === null) {
        this.props.getPatent({
          filterFlag: false,
          filters: Patent_DefaultSelectedFilters,
          pageNumber: 1,
        });
      }

      if (this.props.pseudoPatent === null) {
        // this.props.getPatent({
        //     pseudo: true,
        //     filterFlag: false,
        //     filters: Patent_DefaultSelectedFilters,
        //     pageNumber: 1,
        // });
      }
    } else {
      this.props.logOut();
    }
  }

  handlPatentProfile(data) {
    if (data) {
      sessionStorage.setItem("management_patent_id", data.management_patent_id);
      sessionStorage.setItem(
        "patent_application_number",
        data.application_number,
      );
      window.open(
        "/manager/patent/patent-profile/" + data.management_patent_id,
        "_blank",
      );
      this.setState({
        management_patent_id: data.management_patent_id,
      });
    }
  }

  handleApplicantProfile(data) {
    if (data) {
      sessionStorage.setItem("applicant_id", data.id);
      window.open("/manager/patent/applicant-profile/" + data.id, "_blank");
      this.setState({
        applicant_id: data.id,
      });
    }
  }

  handleInventorProfile(data) {
    if (data) {
      sessionStorage.setItem("inventor_id", data.id);
      window.open("/manager/patent/inventor-profile/" + data.id, "_blank");
      this.setState({
        inventor_id: data.id,
      });
    }
  }

  showDeleteConfirm(data) {
    if (this.state.add_patent_id?.length > 0) {
      Modal.confirm({
        title:
          "Are you sure you want to delete the following Patent Marks from MikeTM Manager?",
        width: 800,
        className: "mark-delete-modal",
        content: (
          <div>
            <div style={{ height: "30vh", overflow: "auto" }}>
              <table
                className="mark-delete-table"
                style={{ width: "100%", border: "1px solid #dee2e6" }}
              >
                <thead>
                  <tr>
                    <th>APPLICATION NUMBER</th>
                    <th>TITLE OF INVENTION</th>
                    <th>TYPE</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 &&
                    data.map((deleteData) => (
                      <tr key={deleteData.id}>
                        <td>{deleteData.application_number}</td>
                        <td>{deleteData.title_of_invention}</td>
                        <td>{deleteData.application_type}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="alignR m-top-10">
              <Button danger onClick={() => this.confrimDeleteMark(data)}>
                Confirm
              </Button>
            </div>
          </div>
        ),
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {},
        onCancel() {},
      });
    } else {
      message.error("no mark selected");
    }
  }

  confrimDeleteMark(data) {
    let deletePatentId = [];
    deletePatentId = data.map((e) => e.id);
    this.props.deletePatent(deletePatentId);
  }

  downloadReport() {
    const { changeSwitch, add_patent_id, pseudo_add_patent_id } = this.state;
    if (
      changeSwitch &&
      (pseudo_add_patent_id?.length > 0 || this.state.selectAll)
    ) {
      this.setState({
        visible: true,
      });
    } else if (add_patent_id?.length > 0 || this.state.selectAll) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  resetFilter() {
    const { changeSwitch } = this.state;
    const { patentAppliedFilters, pseudoPatentAppliedFilters } = this.props;
    if (changeSwitch ? pseudoPatentAppliedFilters : patentAppliedFilters) {
      const {
        applicant,
        inventor,
        applicationType,
        fieldOfInvention,
        applicationStatus,
        legalStatus,
        classificationIPC,
        containsTerm,
      } = changeSwitch
        ? pseudoPatentAppliedFilters || {}
        : patentAppliedFilters || {};

      if (
        applicant?.length > 0 ||
        inventor?.length > 0 ||
        applicationType?.length > 0 ||
        fieldOfInvention?.length > 0 ||
        applicationStatus?.length > 0 ||
        legalStatus?.length > 0 ||
        classificationIPC?.length > 0 ||
        containsTerm?.length > 0
      ) {
        this.props.getPatent({
          pseudo: changeSwitch,
          filterFlag: false,
          filters: Patent_DefaultSelectedFilters,
          pageNumber: 1,
        });
        this.setState({
          selectedFilters: {
            ...this.state.selectedFilters,
            [changeSwitch ? "pseudo" : "ip_india"]: {
              ...this.state.selectedFilters[
                changeSwitch ? "pseudo" : "ip_india"
              ],
              ...Patent_DefaultSelectedFilters,
              filterFlag: false,
              pageNumber: 1,
            },
          },
        });
      } else {
        message.error("No Filter Applied");
      }
    }
  }

  applyFilter() {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.state?.selectedFilters?.pseudo || {}
      : this.state?.selectedFilters?.ip_india || {};
    if (
      changeSwitch
        ? this.state?.selectedFilters?.pseudo
        : this.state?.selectedFilters?.ip_india
    ) {
      const {
        applicant,
        inventor,
        applicationType,
        fieldOfInvention,
        applicationStatus,
        legalStatus,
        classificationIPC,
        containsTerm,
      } = selectedFilters || {};

      if (
        applicant?.length > 0 ||
        inventor?.length > 0 ||
        applicationType?.length > 0 ||
        fieldOfInvention?.length > 0 ||
        applicationStatus?.length > 0 ||
        legalStatus?.length > 0 ||
        classificationIPC?.length > 0 ||
        containsTerm?.length > 0
      ) {
        this.props.getPatent({
          pseudo: changeSwitch,
          filterFlag: true,
          filters: selectedFilters,
          pageNumber: 1,
        });
        this.setState({
          selectedFilters: {
            ...this.state.selectedFilters,
            [changeSwitch ? "pseudo" : "ip_india"]: {
              ...this.state.selectedFilters[
                changeSwitch ? "pseudo" : "ip_india"
              ],
              ...selectedFilters,
              filterFlag: true,
              pageNumber: 1,
            },
          },
        });
      } else {
        message.error("please select filter!");
      }
    }
  }

  handleNextpage = (pageNumber) => {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.props?.pseudoPatentAppliedFilters || {}
      : this.props?.patentAppliedFilters || {};
    this.props.getPatent({
      pseudo: changeSwitch,
      filterFlag: selectedFilters?.filterFlag,
      filters: selectedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [changeSwitch ? "pseudo" : "ip_india"]: {
          ...this.state.selectedFilters[changeSwitch ? "pseudo" : "ip_india"],
          pageNumber: pageNumber,
        },
      },
    });
  };

  changeSwitch() {
    this.setState({
      changeSwitch: !this.state.changeSwitch,
    });
  }

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Patent Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [this.state.changeSwitch ? "PSEUDO_PATENT" : "PATENT"],
        report_tag: {
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          status: checkValue(data.application_status)
            ? data.application_status
            : "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_patent" : "patent",
        applicationNumber: data.application_number,
        sourceId: data.management_patent_id,
      },
    });
  }

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.state?.selectedFilters?.pseudo
      : this.state?.selectedFilters?.ip_india;
    const searchFilters = changeSwitch
      ? this.state?.searchFilters?.pseudo
      : this.state?.searchFilters?.ip_india;
    const patentFilters = changeSwitch
      ? this.props?.pseudoPatentStatus
      : this.props?.patentStatus;
    const currentPageNumber = changeSwitch
      ? this.props?.pseudoPatentPageNumber
      : this.props?.patentPageNumber;

    let switchValue;
    if (changeSwitch) {
      switchValue = "pseudo";
    } else {
      switchValue = "ip_india";
    }
    const totalPatentColumns = [
      {
        title: "PATENT",
        dataIndex: "application_number",
        className: "reply-column-1",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handlPatentProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.title_of_invention)
                    ? record.title_of_invention.toLowerCase()
                    : "N.A."}
                </span>
              </p>
            </div>
          );
        },
      },
      {
        title: "APPLICANT",
        dataIndex: "applicant",
        className: "reply-column-2",
        render: (applicant) => {
          return (
            <div>
              {applicant.length === 0 && <p>NA</p>}
              {applicant.map((applicantData, applicantKey) => (
                <p key={applicantKey} className="text-camelCase">
                  {checkValue(applicantData.name)
                    ? applicantData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        title: "INVENTOR",
        dataIndex: "inventor",
        className: "reply-column-3",
        render: (inventor) => {
          return (
            <div>
              {inventor.length === 0 && <p>NA</p>}
              {inventor.map((inventorData, inventorKey) => (
                <p key={inventorKey} className="text-camelCase">
                  {checkValue(inventorData.name)
                    ? inventorData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        title: "TYPE",
        dataIndex: "application_type",
        className: "reply-column-4",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },
      {
        title: "FIELD OF INVENTION",
        dataIndex: "field_of_invention",
        className: "patent-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text?.name) ? text.name.toLowerCase() : "N.A."}
          </p>
        ),
      },
      {
        title: "STATUS",
        dataIndex: "application_status",
        className: "patent-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },
      {
        title: "DATE OF APPLICATION",
        dataIndex: "date_of_filing",
        className: "patent-column",
        render: (text) => (
          <p>{checkValue(text) ? text.toLowerCase() : "N.A."}</p>
        ),
      },

      {
        title: "ACTION",
        className: "reply-column-8",
        render: (action) => {
          return (
            <div>
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const totalPatentRowSelection = {
      // onChange: (selectedRowKeys, selectedRows) => {
      //     this.setState({
      //         selectedRows: _.uniq([...selectedRows, ...this.state.selectedRows]),
      //         add_patent_id:  _.uniq([...selectedRows.map(data=> data.id), ...this.state.add_patent_id]),
      //         selectedRowKeys: selectedRowKeys
      //     })
      // },
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_patent_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_patent_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },

      onSelectAll: (selected, selectedRows) => {
        let data =
          this.props.patent &&
          this.props.patent?.ip_india &&
          this.props.patent?.ip_india.patents;
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },
      // onSelect: (record, selected, selectedRows) => {
      //     this.setState({
      //         selectRow: selected,
      //         selectAll: selectedRows.length === 100 ? true : false,
      //     })
      // },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: !this.state.selectAll,
                  add_patent_id: [],
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let applicantMenu = noFilterData;
    let inventorMenu = noFilterData;
    let typeMenu = noFilterData;
    let fieldMenu = noFilterData;
    let statusMenu = noFilterData;
    let legalMenu = noFilterData;

    if (patentFilters === SUCCESS) {
      const {
        applicant,
        inventor,
        application_type,
        field_of_invention,
        application_status,
        legal_status,
      } = changeSwitch
        ? this.props?.pseudoPatent?.pseudo?.filters || {}
        : this.props.patent?.ip_india?.filters || {};

      if (applicant !== null && applicant?.length > 0) {
        applicantMenu = (
          <FilterSearchBasic
            data={applicant}
            searchFilters={searchFilters}
            search_key={"applicantSearch"}
            filter_key={"applicant"}
            switchValue={switchValue}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L2}
            type={"name"}
          />
        );
      }

      if (inventor !== null && inventor?.length > 0) {
        inventorMenu = (
          <FilterSearchBasic
            data={inventor}
            searchFilters={searchFilters}
            search_key={"inventorSearch"}
            filter_key={"inventor"}
            switchValue={switchValue}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L2}
            type={"name"}
          />
        );
      }

      if (application_type !== null && application_type?.length > 0) {
        typeMenu = (
          <FilterSearchBasic
            data={application_type}
            searchFilters={searchFilters}
            search_key={"applicationTypeSearch"}
            filter_key={"applicationType"}
            switchValue={switchValue}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L2}
          />
        );
      }

      if (field_of_invention !== null && field_of_invention?.length > 0) {
        fieldMenu = (
          <FilterSearchBasic
            data={field_of_invention}
            searchFilters={searchFilters}
            search_key={"fieldOfInventionSearch"}
            filter_key={"fieldOfInvention"}
            switchValue={switchValue}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L2}
            type={"name"}
          />
        );
      }

      if (application_status !== null && application_status?.length > 0) {
        statusMenu = (
          <FilterSearchBasic
            data={application_status}
            searchFilters={searchFilters}
            search_key={"applicationStatusSearch"}
            filter_key={"applicationStatus"}
            switchValue={switchValue}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L2}
          />
        );
      }

      if (legal_status !== null && legal_status?.length > 0) {
        legalMenu = (
          <FilterSearchBasic
            data={legal_status}
            searchFilters={searchFilters}
            search_key={"legalStatusSearch"}
            filter_key={"legalStatus"}
            switchValue={switchValue}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L2}
          />
        );
      }
    }

    return (
      <div>
        {/* Card-Section */}
        <Row>
          <Col span={4} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
          <Col
            span={12}
            className="alignC"
            style={{ fontSize: "14px!important" }}
          >
            <Text
              strong
              style={{ margin: "0px", fontSize: "16px" }}
              className={changeSwitch ? "display_none" : ""}
            >
              TOTAL PATENT :{" "}
              {checkValue(this.props.patent)
                ? this.props.patent?.ip_india.count
                : 0}{" "}
            </Text>
            <Text
              strong
              style={{ margin: "0px", fontSize: "16px" }}
              className={changeSwitch ? "" : "display_none"}
            >
              TOTAL PSEUDO PATENT :{" "}
              {checkValue(this.props.pseudoPatent)
                ? this.props.pseudoPatent.pseudo.count
                : 0}{" "}
            </Text>
          </Col>
          <Col span={8} className="alignR">
            <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
              *Information shown here is not more than{" "}
              {checkValue(this.props.patentDashboard)
                ? this.props.patentDashboard.patent?.days_old
                : 0}{" "}
              day(s) old.
            </Text>
          </Col>
        </Row>
        {/* Card-Section */}

        {/* Change-Switch */}
        <Row
          className={
            checkValue(this.props.userSubscriptions)
              ? this.props.userSubscriptions.manager.includes("PSEUDO_PATENT")
                ? ""
                : "display_none"
              : "display_none"
          }
        >
          <Col span={24} className="alignR m-top-10">
            <Text strong>IP INDIA</Text>
            <Switch
              className="change-manager-type"
              onChange={() => this.changeSwitch()}
              checked={changeSwitch}
            />
            <Text strong>CUSTOM</Text>
          </Col>
        </Row>
        {/* Change-Switch */}

        {/* total-patent-filter */}
        <Row>
          <Col span={20} className="alignC m-top-10">
            <Menu
              selectedKeys={[
                selectedFilters?.applicant?.length > 0 ? "applicantMenu" : "",
                selectedFilters?.inventor?.length > 0 ? "inventorMenu" : "",
                selectedFilters?.applicationType?.length > 0 ? "typeMenu" : "",
                selectedFilters?.fieldOfInvention?.length > 0
                  ? "fieldMenu"
                  : "",
                selectedFilters?.applicationStatus?.length > 0
                  ? "statusMenu"
                  : "",
                selectedFilters?.legalStatus?.length > 0 ? "legalMenu" : "",
              ]}
              className="filter-list"
              mode="horizontal"
            >
              <Menu.Item key="applicantMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => applicantMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "applicantType")
                  }
                  open={this.state.open === "applicantType"}
                >
                  <div className="ant-dropdown-link">
                    <span>Applicant </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="inventorMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => inventorMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "inventorType")
                  }
                  open={this.state.open === "inventorType"}
                >
                  <div className="ant-dropdown-link">
                    <span>Inventor </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="typeMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => typeMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "typeMenu")
                  }
                  open={this.state.open === "typeMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Application Type </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="fieldMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => fieldMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "fieldMenu")
                  }
                  open={this.state.open === "fieldMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Field of Invention </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="statusMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => statusMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "statusMenu")
                  }
                  open={this.state.open === "statusMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Application Status </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="legalMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => legalMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "legalMenu")
                  }
                  open={this.state.open === "legalMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Legal Status </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={4} className="alignR m-top-10">
            <div>
              <Text style={{ padding: "0px 5px" }}>
                {this.state.selectAll
                  ? this.props.patent && this.props.patent?.ip_india.count
                  : _.uniq(this.state.add_patent_id).length}{" "}
                Patent(s)
              </Text>
              <Button type="primary" onClick={() => this.downloadReport()}>
                Report
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            offset={7}
            span={9}
            className="alignC"
            style={{ borderBottom: "1px solid #E9E9E9" }}
          >
            <Menu
              selectedKeys={[]}
              className="filter-list"
              mode="horizontal"
              style={{ alignItems: "baseline" }}
            >
              <Menu.Item
                key="containsTerm"
                className="filter-list-item apply-filter"
              >
                <Search
                  placeholder="Application No/Name"
                  onChange={(event) =>
                    this.changeStateValue_L2(
                      "selectedFilters",
                      switchValue,
                      "containsTerm",
                      event.target.value,
                    )
                  }
                  value={selectedFilters?.containsTerm}
                  allowClear
                  style={{ width: 200, display: "block" }}
                />
              </Menu.Item>
              <Menu.Item
                key="applyFilter"
                className="filter-list-item apply-filter"
              >
                <Button type="primary" onClick={() => this.applyFilter()}>
                  <i className="fa fa-filter" aria-hidden="true" />
                  <Text style={{ color: "#fff", marginLeft: "5px" }}>
                    Apply Filter
                  </Text>
                </Button>
              </Menu.Item>
              <Menu.Item
                key="resetFilter"
                className="filter-list-item apply-filter"
              >
                <span
                  onClick={() => this.resetFilter()}
                  style={{ padding: "0px 5px" }}
                >
                  RESET
                </span>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        {/* total-patent-filter */}

        {/* total-patent-table */}
        <Row>
          <Col span={24} className="m-top-20 total-patent">
            <Table
              rowKey={(record) => record.id}
              bordered
              rowSelection={totalPatentRowSelection}
              columns={totalPatentColumns}
              dataSource={
                changeSwitch
                  ? this.props.pseudoPatent &&
                    this.props.pseudoPatent.pseudo &&
                    this.props.pseudoPatent.pseudo.patents
                  : this.props.patent &&
                    this.props.patent?.ip_india &&
                    this.props.patent?.ip_india.patents
              }
              pagination={{
                pageSize: 100,
                showQuickJumper: true,
                showSizeChanger: false,
                defaultCurrent: 1,
                current: currentPageNumber,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} `,
                total: changeSwitch
                  ? this.props.pseudoPatent &&
                    this.props.pseudoPatent.pseudo &&
                    this.props.pseudoPatent.pseudo.count
                  : this.props.patent &&
                    this.props.patent?.ip_india &&
                    this.props.patent?.ip_india.count,
                onChange: this.handleNextpage,
              }}
              scroll={{ y: "calc(100vh - 300px)" }}
              loading={
                changeSwitch
                  ? this.props.pseudoPatentStatus === LOADING
                    ? true
                    : false
                  : this.props.patentStatus === LOADING
                    ? true
                    : false
              }
            />
          </Col>
        </Row>
        {/* total-patent-table */}
        {this.state.visible && (
          <DownloadReportModal
            visible={this.state.visible}
            onCloseModal={() => this.setState({ visible: false })}
            type={"patent"}
            add_patent_id={
              changeSwitch
                ? this.state.pseudo_add_patent_id
                : this.state.add_patent_id
            }
            selectAll={
              changeSwitch ? this.state.pseudo_selectAll : this.state.selectAll
            }
            changeSwitch={changeSwitch}
            filters={
              changeSwitch
                ? this.props.pseudoPatentAppliedFilters
                : this.props.patentAppliedFilters
            }
          />
        )}

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"patent"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_P_NOTES, PATCH_P_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={P}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          source={`PATENT`}
          sourceId={this.state.modalsVisibility.sourceId}
          managerEmail={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getDashboard,
  getPatent,
  deletePatent,
  logOut,
};

function mapStateToProps(state) {
  const { patentDashboard, patentDashboardStatus } =
    state.managerState.profileState;
  const {
    pseudo,

    patent,
    patentStatus,
    patentAppliedFilters,
    patentPageNumber,

    pseudoPatent,
    pseudoPatentStatus,
    pseudoPatentAppliedFilters,
    pseudoPatentPageNumber,
  } = state.managerState.patentDashboardState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
    patentDashboard,
    patentDashboardStatus,
    pseudo,

    patent,
    patentStatus,
    patentAppliedFilters,
    patentPageNumber,

    pseudoPatent,
    pseudoPatentStatus,
    pseudoPatentAppliedFilters,
    pseudoPatentPageNumber,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Patent);
