//--------LIBRARIES--------
import React from "react";
import { Spin, Empty } from "antd";

//--------IP-SUITE CONSTANTS--------
import {
  SUCCESS,
  LOADING,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

//--------WATCH SUB-COMPONENTS--------
import GeneralMarkCard from "../../commonComponents/generalCards/GeneralMarkCard";

export default function ThreatsGrid({
  gridTrademarksData,
  gridTrademarksDataStatus,
  userTrademarkClasses,
  changeStateValue_L0,
  changeStateValue_L1,
  expandRecord,
  customReportData,
  imageLabel,
  imageText,
  tmProtect,
  algorithm,
}) {
  return (
    <div
      className="General-Content Flex-Content vertical_scroll"
      style={{ height: "calc(100% - 94px)" }}
    >
      {/* If gridTrademarksData is received successfully and is greater than 0 then the code below will execute. */}
      {gridTrademarksDataStatus === SUCCESS &&
        gridTrademarksData.length > 0 &&
        gridTrademarksData.map((eachTrademark) => (
          <GeneralMarkCard
            key={eachTrademark.threat.application_number}
            eachMark={eachTrademark.threat}
            onSelectMark={(eachMark, checked) => {
              if (checked) {
                changeStateValue_L0("customReportData", {
                  ...customReportData,
                  threatsCount: customReportData.threatsCount + 1,
                  data: {
                    ...customReportData.data,
                    [expandRecord]: {
                      ...customReportData.data[expandRecord],
                      threats: [
                        ...customReportData.data[expandRecord].threats,
                        eachMark.application_number,
                      ],
                      threatsCount:
                        customReportData.data[expandRecord].threatsCount + 1,
                    },
                  },
                });
              } else {
                changeStateValue_L0("customReportData", {
                  ...customReportData,
                  threatsCount: customReportData.threatsCount - 1,
                  data: {
                    ...customReportData.data,
                    [expandRecord]: {
                      ...customReportData.data[expandRecord],
                      threats: customReportData.data[
                        expandRecord
                      ].threats.filter(
                        (eachThreat) =>
                          eachThreat !== eachMark.application_number,
                      ),
                      threatsCount:
                        customReportData.data[expandRecord].threatsCount - 1,
                    },
                  },
                });
              }
            }}
            checked={
              customReportData.data[expandRecord].threats.indexOf(
                eachTrademark.threat.application_number,
              ) !== -1 ||
              customReportData.data[expandRecord].select_all ||
              customReportData.select_all
                ? true
                : false
            }
            selectAllCards={
              customReportData.data[expandRecord].select_all ||
              customReportData.select_all
            }
            changeStateValue_L1={changeStateValue_L1}
            styleCard={
              eachTrademark.threat.associated_class.filter((eachClass) =>
                userTrademarkClasses.includes(eachClass),
              ).length > 0
                ? { boxShadow: "0px 0px 5px #ff0000" }
                : {}
            }
            similarity={eachTrademark.similarity}
            dateOfUsage={true}
            created_custom_report={eachTrademark.created_custom_report}
            imageLabel={imageLabel}
            imageText={imageText}
            tmProtect={tmProtect}
            algorithm={algorithm}
          />
        ))}

      {/* When the gridTrademark data is received successfully but the count is 0. */}
      {gridTrademarksDataStatus === SUCCESS &&
        gridTrademarksData.length === 0 && (
          <div style={{ display: "table", height: "50vh", width: "100%" }}>
            <Empty style={{ display: "table-cell", verticalAlign: "middle" }} />
          </div>
        )}

      {/* While loading the loader below will appear. */}
      {gridTrademarksDataStatus === LOADING && (
        <div
          style={{
            display: "table",
            height: "50vh",
            width: "100%",
            verticalAlign: "middle",
          }}
        >
          <Spin size="large" style={{ display: "table-cell" }} />
        </div>
      )}

      {/* When the gridTrademark data is received successfully but the count is 0. */}
      {gridTrademarksDataStatus === ERROR && (
        <div style={{ display: "table", height: "50vh", width: "100%" }}>
          <div
            style={{
              display: "table-cell",
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            {/* <h1>Unfortunately, due to the occurrence of an error, we were unable to fetch the data from the server.</h1>
                            <h1>We apologize for the inconvenience. Please, try again after some time.</h1> */}
          </div>
        </div>
      )}
    </div>
  );
}
