//--------IP-SUITE CONSTANTS--------
import { PDF } from "../../constants/commonConstants/generalConstants";

//--------Default Fiters Visibility--------
export const GL_defaultSecondaryFiltersVisibility = {
  trademarkClassesMenu: false,
  proprietorsMenu: false,
  trademarkCategoryMenu: false,
  trademarkOfficesMenu: false,
  trademarkTypesMenu: false,
  infringementTypesMenu: false,
  selfLabelsMenu: false,
  threatLabelsMenu: false,
  threatsSimilarityMenu: false,
};

//--------Default Selected Filters--------
export const GL_defaultSecondarySelectedFilters = {
  trademarkTypes: [],
  trademarkClasses: [],
  trademarkCategory: [],
  trademarkOffices: [],
  proprietors: [],
  selfLabels: [],
  threatLabels: [],
  infringementTypes: [],
  threatsSimilarity: {
    lowerLimit: 0,
    upperLimit: 100,
  },
  date_of_application: "",
  date_of_usage: "",
  date_of_usage_proposed: false,
  searchTerm: "",
  threat_desc_contains_term: "",
  redBand: false,
  excludeClass: false,
  secondarySearchType: "Application No/Name",
};

//--------Default Selected Filters--------
export const GL_defaultSearchSelectedFilters = {
  searchTerm: "",
  mark_desc_contains_term: "",
  primarySearchType: "Application No/Name",
};

//--------Default Fiters Search Terms--------
export const GL_defaultSecondaryFiltersSearchTerms = {
  proprietors: "",
};

//--------Default Custom Report Data--------
export const GL_defaultCustomReportData = {
  select_all: false,
  data: {},
  threatsCount: 0,
  selectAllCount: 0,
  report_format: PDF,
  report_name: "",
  report_type: "CUSTOM",
};

//--------Default filters with backend keys--------
export const GL_defaultSecondarySelectedFilters_BEK = {
  threat_types: [],
  threat_classes: [],
  threat_categories: [],
  threat_offices: [],
  threat_proprietors: [],
  self_labels: [],
  threat_labels: [],
  infringement_types: [],
  threat_similarity: {
    lowerLimit: 0,
    upperLimit: 100,
  },
  date_of_application: "",
  date_of_usage: "",
  date_of_usage_proposed: false,
  contains_term: "",
  red_band: false,
  secondarySearchType: "Application No/Name",
};

//--------Default Data Report Record Initial State--------
export const GL_defaultInitialReport = {
  threats: [],
  threatsCount: 0,
  filteredThreatsCount: 0,
  select_all: false,
  filter_flag: false,
  filters: GL_defaultSecondarySelectedFilters_BEK,
};
