import React, { useState, useEffect } from "react";
import { LockOutlined } from "@ant-design/icons";
import { Form, Icon as LegacyIcon } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Layout, Row, Col, Typography, Button, Input, Alert } from "antd";
import SignInPageImg from "../../assets/images/option-1.png";
import { changeStateValue_L1 } from "../../functions/commonFunctions/generalFunctions";
import { useDispatch, useSelector } from "react-redux";

// import "antd/dist/antd.css";

import { resetPassword } from "../../redux/actions/commonActions/actions";
import { logOut } from "../../redux/actions/commonActions/actions";
import SignInSider from "../signInComponents/SignInSider";
import {
  ERROR,
  SUCCESS,
} from "./../../constants/commonConstants/generalConstants";

var qs = require("qs");

function ResetPassword(props) {
  const [otp, setOtp] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const selector = useSelector((state) => {
    return {
      Message: state.forgotState.Message,
      Status: state.forgotState.Status,
    };
  });

  if (selector.Status === SUCCESS) {
    dispatch(logOut());
  }

  useEffect(() => {
    var fetchOtp = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).key;
    setOtp(fetchOtp);
  });

  const { Title, Text } = Typography;
  const { getFieldDecorator, validateFields } = props.form;

  const handleSubmit = async () => {
    await validateFields((err) => {
      if (password !== null && confirmpassword !== null && !err) {
        dispatch(resetPassword({ password, confirmpassword, otp }));
      }
    });
  };

  return (
    <div class="container-fluid">
      <div class="row login-row">
        <SignInSider />
        <div class="col-lg-6 col-sm-12">
          <Col sm={24} className="SignInForm">
            <div className="Sign-In-Form">
              <div className="Welcome-Heading">
                <Title style={{ fontSize: "22px", padding: "10px" }}>
                  Welcome to your AI powered legal associate
                </Title>
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                >
                  {(selector.Status === SUCCESS ||
                    selector.Status === ERROR) && (
                    <Form.Item className="Sign-In-Input-Field">
                      <Alert
                        message={selector.Message}
                        type={selector.Status.toLowerCase()}
                        showIcon
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    style={{
                      marginLeft: "15px",
                      marginTop: "15px",
                    }}
                  >
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        suffix={
                          <LegacyIcon
                            type={passwordVisible ? "eye-invisible" : "eye"}
                            style={{ color: "rgba(0,0,0,.25)" }}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          />
                        }
                        type={passwordVisible ? "password" : "text"}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    )}
                  </Form.Item>

                  <Form.Item
                    style={{
                      marginLeft: "15px",
                      marginTop: "-5px",
                    }}
                  >
                    {getFieldDecorator("confirm password", {
                      rules: [
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        suffix={
                          <LegacyIcon
                            type={
                              confirmPasswordVisible ? "eye-invisible" : "eye"
                            }
                            style={{ color: "rgba(0,0,0,.25)" }}
                            onClick={() =>
                              setConfirmPasswordVisible(!confirmPasswordVisible)
                            }
                          />
                        }
                        type={confirmPasswordVisible ? "password" : "text"}
                        placeholder="confirm password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    )}
                  </Form.Item>

                  <Form.Item style={{ marginLeft: "15px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button w-100"
                      onClick={handleSubmit}
                    >
                      submit
                    </Button>
                    <br />

                    <Text strong>
                      Already a member ?
                      <Button type="link" href="/sign-in">
                        Login
                      </Button>
                    </Text>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
}

export default Form.create()(ResetPassword);
