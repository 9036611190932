import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import { ClockCircleOutlined } from "@ant-design/icons";

import { Row, Col, Drawer, Tabs, Typography, Menu, Timeline, Spin } from "antd";
import { getWhatsNew } from "../../../redux/actions/commonActions/actions";

const { Title, Text } = Typography;
const { SubMenu } = Menu;
const { TabPane } = Tabs;

class WhatsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      activeKey: "new",
      selectedKey: "",
      mode: "left",
    };
  }

  componentDidMount() {
    if (this.props.whatsNew === null) {
      this.props.getWhatsNew("new");
    }
  }

  callback = (key) => {
    this.setState({
      activeKey: key,
    });
    this.props.getWhatsNew(key);
  };

  handleClick(selectedKey, event) {
    this.setState({
      selectedKey: event,
    });
    window.scrollTo({
      top: 10,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <Drawer
        title={
          <section>
            <div
              className="feature-header"
              style={{ backgroundColor: "#617edc" }}
            >
              <Title level={3} className="section-heading">
                What's New
              </Title>
            </div>
          </section>
        }
        placement="left"
        width={"50%"}
        onClose={() => this.props.onCloseDrawer()}
        open={this.props.visible}
        rootClassName={`whats-news-drawer ${
          this.props.collapsed ? "whats-news-true" : "whats-news-false"
        }`}
      >
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={this.state.activeKey}
              tabBarStyle={{
                margin: "0px",
                display: "flex",
                alignSelf: "flex-end",
                marginRight: "20px",
              }}
              animated={false}
              onChange={this.callback}
            >
              <TabPane tab="New" key="new">
                {this.props.whatsNew && (
                  <Row>
                    <Col span={8}>
                      <div
                        className="timeline-container vertical_scroll"
                        style={{ padding: "20px" }}
                      >
                        <div>
                          <Title level={4}>TIMELINE</Title>
                        </div>
                        <Menu
                          onClick={(event) =>
                            this.handleClick("selectedKey", event.key)
                          }
                          selectedKeys={[this.state.selectedKey]}
                          style={{
                            width: "100%",
                            border: "1px solid #e8e8e8",
                          }}
                          mode="inline"
                        >
                          {this.props.whatsNew.data.map((data1) => (
                            <SubMenu
                              key={data1.year}
                              title={
                                <span>
                                  <ClockCircleOutlined />
                                  <span>{data1.year}</span>
                                </span>
                              }
                            >
                              {data1.month.map((data2, key2) => (
                                <Menu.Item
                                  key={`${data1.year}-${data2.month_name}-${key2}`}
                                >
                                  <a
                                    href={`#${key2}-${data1.year}-${data2.month_name}-new`}
                                  >
                                    {data2.month_name}
                                  </a>
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          ))}
                        </Menu>
                      </div>
                    </Col>
                    <Col span={16}>
                      <div className="timeline-container vertical_scroll">
                        <Timeline mode="left">
                          {this.props.whatsNew.data.map((data1, key1) => (
                            <React.Fragment key={key1}>
                              <Timeline.Item
                                key={data1.year}
                                dot={
                                  <ClockCircleOutlined
                                    rootStyle={{ fontSize: "16px" }}
                                  />
                                }
                                position="right"
                              >
                                <div>
                                  <Title level={2}>{data1.year}</Title>
                                </div>
                              </Timeline.Item>
                              {data1.month.map((data2, key2) => (
                                <React.Fragment key={key2}>
                                  <Timeline.Item
                                    key={data2.month_name}
                                    id={`${key2}-${data1.year}-${data2.month_name}-new`}
                                    color="red"
                                    position="right"
                                  >
                                    <div>
                                      <Title level={3}>
                                        {data2.month_name}
                                      </Title>
                                    </div>
                                  </Timeline.Item>
                                  {data2.body.map((data3, key3) => (
                                    <Timeline.Item
                                      key={key3}
                                      color="gray"
                                      position="right"
                                    >
                                      <div>
                                        <Title level={4}>
                                          {data3.title}
                                          <Text strong className="date-heading">
                                            ({data3.date_of_update})
                                          </Text>
                                        </Title>
                                        <p
                                          rootStyle={{ textAlign: "justify" }}
                                          dangerouslySetInnerHTML={{
                                            __html: data3.content,
                                          }}
                                        ></p>
                                        {data3.image === null ||
                                        data3.image.includes("/null") ? (
                                          ""
                                        ) : (
                                          <div className="image-container">
                                            <img src={data3.image} alt="" />
                                          </div>
                                        )}
                                      </div>
                                    </Timeline.Item>
                                  ))}
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ))}
                        </Timeline>
                      </div>
                    </Col>
                  </Row>
                )}
                {!this.props.whatsNew && (
                  <Row justify="center">
                    <Col span={24}>
                      <div className="spin-container">
                        <Spin size="large" />
                      </div>
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tab="Upcoming" key="upcoming">
                {this.props.whatsNew && (
                  <Row>
                    <Col span={8}>
                      <div
                        className="timeline-container vertical_scroll"
                        style={{ padding: "20px" }}
                      >
                        <div>
                          <Title level={4}>TIMELINE</Title>
                        </div>
                        <Menu
                          onClick={(event) =>
                            this.handleClick("selectedKey", event.key)
                          }
                          selectedKeys={[this.state.selectedKey]}
                          style={{
                            width: "100%",
                            border: "1px solid #e8e8e8",
                          }}
                          mode="inline"
                        >
                          {this.props.whatsNew.data.map((data1) => (
                            <SubMenu
                              key={data1.year}
                              title={
                                <span>
                                  <ClockCircleOutlined />
                                  <span>{data1.year}</span>
                                </span>
                              }
                            >
                              {data1.month.map((data2, key2) => (
                                <Menu.Item key={data2.month_name}>
                                  <a
                                    href={`#${key2}-${data2.month_name}-upcoming`}
                                  >
                                    {data2.month_name}
                                  </a>
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          ))}
                        </Menu>
                      </div>
                    </Col>
                    <Col span={16}>
                      <div className="timeline-container vertical_scroll">
                        <Timeline mode="left">
                          {this.props.whatsNew.data.map((data1, key1) => (
                            <>
                              <Timeline.Item
                                key={data1.year}
                                dot={
                                  <ClockCircleOutlined
                                    rootStyle={{ fontSize: "16px" }}
                                  />
                                }
                                position="right"
                              >
                                <div>
                                  <Title level={2}>{data1.year}</Title>
                                </div>
                              </Timeline.Item>
                              {data1.month.map((data2, key2) => (
                                <>
                                  <Timeline.Item
                                    key={data2.month_name}
                                    id={`${key2}-${data2.month_name}-upcoming`}
                                    color="red"
                                    position="right"
                                  >
                                    <div>
                                      <Title level={3}>
                                        {data2.month_name}
                                      </Title>
                                    </div>
                                  </Timeline.Item>
                                  {data2.body.map((data3, key3) => (
                                    <Timeline.Item
                                      key={key3}
                                      color="gray"
                                      position="right"
                                    >
                                      <div>
                                        <Title level={4}>
                                          {data3.title}
                                          <Text strong className="date-heading">
                                            ({data3.date_of_update})
                                          </Text>
                                        </Title>
                                        <p
                                          rootStyle={{ textAlign: "justify" }}
                                          dangerouslySetInnerHTML={{
                                            __html: data3.content,
                                          }}
                                        ></p>
                                        {data3.image === null ||
                                        data3.image.includes("/null") ? (
                                          ""
                                        ) : (
                                          <div className="image-container">
                                            <img src={data3.image} alt="" />
                                          </div>
                                        )}
                                      </div>
                                    </Timeline.Item>
                                  ))}
                                </>
                              ))}
                            </>
                          ))}
                        </Timeline>
                      </div>
                    </Col>
                  </Row>
                )}
                {!this.props.whatsNew && (
                  <Row justify="center">
                    <Col span={24}>
                      <div className="spin-container">
                        <Spin size="large" />
                      </div>
                    </Col>
                  </Row>
                )}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

const mapDispatchToProps = {
  getWhatsNew,
};

function mapStateToProps(state) {
  const { whatsNew, whatsNewStatus } = state.whatsNewState;
  return deepFreeze({
    whatsNew,
    whatsNewStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNew);
