//--------WATCH TYPES--------
import { GET_DATE_RANGE_LIST } from "../../actions/watchActions/types";

//--------IP-SUITE CONSTANTS--------
import { LOADING, SUCCESS, ERROR } from "../../../constants/commonConstants/generalConstants";

const defaultState = {
    date_range_list: null
}
export default function(state = defaultState, action){
    switch (action.type) {
        case GET_DATE_RANGE_LIST:
            switch (action.status) {
                case SUCCESS:
                    return {
                        ...state,
                        date_range_list: action.payload.data
                    }

                case LOADING:
                    return {
                        ...state,
                    }
                    
                case ERROR:
                    return {
                        ...state,
                    }
                
                default:
                    return {
                        ...state,
                    }
            }
        default:
            return state;
    }
}