//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import Axios from "axios";
import { DownOutlined, TagsTwoTone, DeleteOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Button,
  Input,
  Checkbox,
  message,
  notification,
  Typography,
  Select,
  Tooltip,
  Badge,
} from "antd";

//--------MANGER: ACTIONS--------
import {
  getManageTrademark,
  postManageTrademark,
  postDownloadFile,
} from "../../../redux/actions/managerActions/trademarkDashboard/actions";
import {
  markDownload,
  getTeamMember,
  logOut,
} from "../../../redux/actions/commonActions/actions";
import {
  getManagePatent,
  postManagePatent,
} from "../../../redux/actions/managerActions/patentDashboard/actions";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
  MANAGER,
} from "../../../constants/commonConstants/generalConstants";

//--------MANAGER CONSTANTS--------
import {
  TM_DefaultSelectedFilters,
  TM_DefaultSearchFilters,
  MT_defaultSelectedSetters,
} from "../../../constants/managerConstants/trademarkConstants";
import {
  Patent_DefaultSearchFilters,
  Patent_DefaultSelectedFilters,
} from "../../../constants/managerConstants/patentConstants";
import { changeStateValue_L1 } from "../../../functions/commonFunctions/generalFunctions";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

//--------IP-SUITE COMMON COMPONENTS--------
import AddTrademarks from "../../commonComponents/Modals/AddTrademarks";
import TagsDrawer from "../../commonComponents/Drawers/PatentTags";
import DownloadPortfolioDrawer from "../../commonComponents/Drawers/DownloadPatentPorfolio";

//--------Manager: Components--------
import ManagePatentDrawer from "./YourPatent/Manage-Patent-Drawer";

//--------RT_CONFIG--------
import { MGR_RT_URL } from "../../../configs/rootConfigs";

//--------HISTORY--------
import { history } from "../../../history";
import { TM, P } from "../../../constants/managerConstants/managerConstants";
import { standardizeDate } from "../../../functions/commonFunctions/dateFunctions";
import { getPatentTags } from "../../../redux/actions/commonActions/actions";
import FilterSearchBasic from "../../commonComponents/FilterComponents/FilterSearchBasic";

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;
const _ = require("lodash");

class ManagePatent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      editingKey: "",
      selectedSetters: MT_defaultSelectedSetters,
      add_patent_id: [],
      tag_color: "",
      add_application_no: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,
      noTagToggle: false,
      searchFilters: Patent_DefaultSearchFilters,
      selectedFilters: Patent_DefaultSelectedFilters,
      pageNumber: this.props.pageNumber,
      drawersVisibility: {
        patentData: false,
        manageTags: false,
        downloadPortfolio: false,
      },
      modalsVisibility: {
        addTrademarks: false,
      },
      value: "patent",
    };

    this.handleNoTag = this.handleNoTag.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.patentData !== null) {
        this.props.getManagePatent({
          filterFlag: false,
          filters: Patent_DefaultSelectedFilters,
          pageNumber: 1,
        });
        this.props.getPatentTags();
      }
    } else {
      this.props.logOut();
    }
  }

  handleTrademarkProfile(data) {
    if (data) {
      sessionStorage.setItem("management_patent_id", data.management_patent_id);
      sessionStorage.setItem(
        "patent_application_number",
        data.application_number,
      );
      window.open(
        "/manager/patent/manage-patent-profile/" + data.management_patent_id,
        "_blank",
      );
      this.setState({
        management_patent_id: data.management_patent_id,
      });
    }
  }

  handleChange = (value) => {
    this.props.getManagePatent({
      mmType: value,
      filterFlag: false,
      filters: Patent_DefaultSelectedFilters,
      pageNumber: this.props.pageNumber,
    });
    this.setState({
      value: value,
      selectedFilters: Patent_DefaultSelectedFilters,
      searchFilters: TM_DefaultSearchFilters,
      add_application_no: [],
      add_patent_id: [],
      selectedRowKeys: [],
      selectAll: false,
    });
  };

  async deleteMarks() {
    if (this.state.add_patent_id?.length > 0) {
      await this.props.postManagePatent({
        mmType: this.state.value,
        setters: {},
        select_all: this.state.selectAll,
        delete_flag: true,
        filterFlag: this.props?.lastAppliedFilters?.filterFlag,
        filters: this.props?.lastAppliedFilters,
        patents: this?.state?.add_application_no,
        application_number: null,
      });

      notification[
        this.props.patentStatus === SUCCESS
          ? "success"
          : this.props.patentStatus === ERROR
          ? "error"
          : "smile"
      ]({
        message: "Delete Patent",
        description:
          this.props.patentStatus === SUCCESS
            ? "Successfully Deleted Patent"
            : this.props.patentStatus === ERROR
            ? "Unable to Deleted patent. Please try again later!"
            : "",
      });
      this.props.getManagePatent({
        mmType: this.state.value,
        filterFlag: this.props.lastAppliedFilters.filterFlag,
        filters: this.props.lastAppliedFilters,
        pageNumber: this.props.pageNumber,
      });
      this.setState({
        add_application_no: [],
        add_patent_id: [],
        selectedRowKeys: [],
        selectAll: false,
        selectedRows: [],
      });
    } else {
      message.error("No Mark Selected!");
    }
  }

  editTrademark(data, selected_tags, single, tags) {
    this.setState({
      editingKey: data.id,
      application_number: data.application_number,
      [selected_tags]: {
        ...this.state[selected_tags],
        [single]: {
          ...this.state[selected_tags][single],
          [tags]: data.tags.map((tagsData) => `${tagsData.id}`),
        },
      },
      drawersVisibility: {
        ...this.state.drawersVisibility,
        patentData: data,
      },
    });
  }

  applyFilter() {
    const {
      applicant,
      inventor,
      applicationType,
      fieldOfInvention,
      applicationStatus,
      legalStatus,
      containsTerm,
      patentTags,
    } = this.state?.selectedFilters || {};

    if (
      applicant?.length > 0 ||
      inventor?.length > 0 ||
      applicationType?.length > 0 ||
      fieldOfInvention?.length > 0 ||
      applicationStatus?.length > 0 ||
      legalStatus?.length > 0 ||
      containsTerm?.length > 0 ||
      patentTags?.length > 0
    ) {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          filterFlag: true,
        },
        pageNumber: 1,
      });
      this.props.getManagePatent({
        filterFlag: true,
        filters: this.state.selectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Selected!");
    }
  }

  resetFilter() {
    const {
      applicant,
      inventor,
      applicationType,
      fieldOfInvention,
      applicationStatus,
      legalStatus,
      containsTerm,
      patentTags,
    } = this.props?.lastAppliedFilters || {};

    if (
      applicant?.length > 0 ||
      inventor?.length > 0 ||
      applicationType?.length > 0 ||
      fieldOfInvention?.length > 0 ||
      applicationStatus?.length > 0 ||
      legalStatus?.length > 0 ||
      containsTerm?.length > 0 ||
      patentTags?.length > 0
    ) {
      this.setState({
        searchFilters: Patent_DefaultSearchFilters,
        selectedFilters: {
          ...this.state.selectedFilters,
          ...Patent_DefaultSelectedFilters,
          filterFlag: false,
        },
        pageNumber: 1,
      });
      this.props.getManagePatent({
        mmType: this.state.value,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Applied");
    }
  }
  //   *********************************NO Tags ********************************//

  handleNoTag(e) {
    this.setState({ noTagToggle: e.target.checked });
    if (this.state.noTagToggle !== true) {
      this.setState({
        tag_color: (document.getElementById("tag-color").style.color =
          "#3DA7FF"),
      });
    }
  }

  handleNextpage = (pageNumber) => {
    this.props.getManagePatent({
      filterFlag: this.props?.lastAppliedFilters?.filterFlag,
      filters: this.state?.selectedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  submitFile = (event, application_number) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.file[0]);
    formData.append("application_number", application_number);
    Axios.post(`${MGR_RT_URL}/test-upload/`, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // handle your response;
      })
      .catch((error) => {
        // handle your error
      });
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    const { searchFilters, selectedFilters } = this.state;
    const manageTrademarkColumns = [
      {
        title: "APPLICATION NUMBER",
        dataIndex: "application_number",
        className: "manage-trademark-column",
        render: (text, record) => (
          <p
            className="application-text text-camelCase"
            onClick={() => this.handleTrademarkProfile(record)}
          >
            {checkValue(text) ? text : "N.A."}
          </p>
        ),
      },

      {
        title: "APPLICANT",
        dataIndex: "applicants",
        className: "manage-trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.join(", ") : "N.A."}
          </p>
        ),
      },

      {
        title: "APPLICATION TYPE",
        dataIndex: "application_type",
        className: "manage-trademark-column",
        render: (text) => (
          <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
        ),
      },

      {
        title: "TITLE OF INVENTION",
        dataIndex: "title_of_invention",
        className: "manage-trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "INVENTORS",
        dataIndex: "inventors",
        className: "manage-trademark-column-class",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.join(", ") : "N.A."}
          </p>
        ),
      },

      {
        title: "APPLICATION STATUS",
        dataIndex: "application_status",
        className: "manage-trademark-column",
        render: (text) => {
          return (
            <div>
              <p className="text-camelCase">
                {checkValue(text) ? text.toLowerCase() : "N.A."}
              </p>
            </div>
          );
        },
      },

      {
        title: "TAGS",
        width: 150,
        dataIndex: "tags",
        className: "manage-trademark-column",
        render: (tags) => {
          return (
            <div>
              {tags?.length > 0
                ? tags.map((tag) => (
                    <span
                      key={tag.id}
                      className="Tag"
                      style={{
                        color: `${tag.color_code}`,
                        border: `1px solid ${tag.color_code}`,
                      }}
                    >
                      {tag.name.toUpperCase()}
                    </span>
                  ))
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "UPLOADED DOCUMENTS",
        dataIndex: "uploaded_documents",
        className: "manage-trademark-column",
        render: (documents, record) => {
          return (
            <div>
              {documents?.length > 0
                ? documents.map((eachDocument) => (
                    <Tooltip
                      placement="left"
                      title={
                        <div>
                          <div>
                            <strong>File Name:</strong> {eachDocument.title}
                          </div>
                          <div>
                            <strong>Uploaded By:</strong>{" "}
                            {eachDocument.uploaded_by}
                          </div>
                          <div>
                            <strong>Last Modified:</strong>
                            <br />
                            {standardizeDate(eachDocument.modified)}
                          </div>
                        </div>
                      }
                    >
                      <div
                        key={eachDocument.title}
                        className="Clickable-Link"
                        onClick={() =>
                          this.props.postDownloadFile({
                            applicationNumber: record.application_number,
                            documentFor: P,
                            fileName: eachDocument.title,
                          })
                        }
                      >
                        {eachDocument.title}
                      </div>
                    </Tooltip>
                  ))
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "ACTIONS",
        dataIndex: "application_number",
        className: "manage-trademark-column-action",
        key: "actions",
        render: (application_number, record) => {
          return (
            <Button
              type="primary"
              style={{
                marginRight: 10,
                background: "#59c4af",
                borderColor: "#59c4af",
              }}
              onClick={() =>
                this.editTrademark(record, "selectedSetters", "single", "tags")
              }
            >
              Edit
            </Button>
          );
        },
      },
    ];

    const manageTrademarkRowSelection = {
      onSelect: (record, selected, selectedRows) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_patent_id: newSelected.map((x) => x.id),
            add_application_no: newSelected.map((x) => x.application_number),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_patent_id: newSelected.map((x) => x.id),
            add_application_no: newSelected.map((x) => x.application_number),
            selectedRows: newSelected,
          });
        }
      },

      onSelectAll: (selected, selectedRows) => {
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: this.props?.patentData?.patents.map((row) => row.id),
        });

        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: false,
                  add_patent_id: [],
                  add_application_no: [],
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let applicantMenu = noFilterData;
    let inventorMenu = noFilterData;
    let typeMenu = noFilterData;
    let fieldMenu = noFilterData;
    let statusMenu = noFilterData;
    let legalMenu = noFilterData;
    let tagsMenu = noFilterData;

    if (this.props?.patentStatus === SUCCESS) {
      const {
        applicant,
        application_status,
        application_type,
        field_of_invention,
        inventor,
        legal_status,
        tag,
      } = this.props?.patentData?.filters || {};

      if (applicant !== null && applicant?.length > 0) {
        applicantMenu = (
          <FilterSearchBasic
            data={applicant}
            searchFilters={searchFilters}
            search_key={"applicantSearch"}
            filter_key={"applicant"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (inventor !== null && inventor?.length > 0) {
        inventorMenu = (
          <FilterSearchBasic
            data={inventor}
            searchFilters={searchFilters}
            search_key={"inventorSearch"}
            filter_key={"inventor"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (application_type !== null && application_type?.length > 0) {
        typeMenu = (
          <FilterSearchBasic
            data={application_type}
            searchFilters={searchFilters}
            search_key={"applicationTypeSearch"}
            filter_key={"applicationType"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (field_of_invention !== null && field_of_invention?.length > 0) {
        fieldMenu = (
          <FilterSearchBasic
            data={field_of_invention}
            searchFilters={searchFilters}
            search_key={"fieldOfInventionSearch"}
            filter_key={"fieldOfInvention"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (application_status !== null && application_status?.length > 0) {
        statusMenu = (
          <FilterSearchBasic
            data={application_status}
            searchFilters={searchFilters}
            search_key={"applicationStatusSearch"}
            filter_key={"applicationStatus"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (legal_status !== null && legal_status?.length > 0) {
        legalMenu = (
          <FilterSearchBasic
            data={legal_status}
            searchFilters={searchFilters}
            search_key={"legalStatusSearch"}
            filter_key={"legalStatus"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
          />
        );
      }

      if (tag !== null && tag?.length > 0) {
        tagsMenu = (
          <FilterSearchBasic
            data={tag}
            searchFilters={searchFilters}
            search_key={"tagSearch"}
            filter_key={"patentTags"}
            selectedFilters={selectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"tags"}
            handleNoTag={this.handleNoTag}
            noTagToggle={this.state.noTagToggle}
          />
        );
      }
    }

    return (
      <>
        {/* Back */}
        <Row>
          <Col span={2} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
          <Col
            span={10}
            className="alignR"
            style={{ fontSize: "14px!important" }}
          >
            <Text strong style={{ margin: "0px", fontSize: "16px" }}>
              TOTAL PATENT :{" "}
              {checkValue(this.props.patentData)
                ? this.props.patentData.count
                : 0}{" "}
            </Text>
          </Col>
          <Col span={12} className="alignR">
            <Button
              type="primary"
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "manageTags",
                  true,
                )
              }
              style={{ background: "#59c4af", borderColor: "#59c4af" }}
            >
              Manage Tags
            </Button>
            <Button
              type="primary"
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "downloadPortfolio",
                  true,
                )
              }
              style={{ margin: "0 10px" }}
            >
              Download Portfolio
            </Button>
          </Col>
        </Row>
        {/* Back */}

        {/* Manage Trademarks filter */}
        <Row>
          <Col span={18} className="alignL m-top-10">
            <Menu
              selectedKeys={[
                selectedFilters?.applicant?.length > 0 ? "applicantMenu" : "",
                selectedFilters?.inventor?.length > 0 ? "inventorMenu" : "",
                selectedFilters?.applicationType?.length > 0 ? "typeMenu" : "",
                selectedFilters?.fieldOfInvention?.length > 0
                  ? "fieldMenu"
                  : "",
                selectedFilters?.applicationStatus?.length > 0
                  ? "statusMenu"
                  : "",
                selectedFilters?.legalStatus?.length > 0 ? "legalMenu" : "",
                selectedFilters?.patentTags?.length > 0 ? "tagsMenu" : "",
              ]}
              className="filter-list"
              mode="horizontal"
            >
              <Menu.Item key="applicantMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => applicantMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "applicantType")
                  }
                  open={this.state.open === "applicantType"}
                >
                  <div className="ant-dropdown-link">
                    <span>Applicant </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="inventorMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => inventorMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "inventorType")
                  }
                  open={this.state.open === "inventorType"}
                >
                  <div className="ant-dropdown-link">
                    <span>Inventor </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="typeMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => typeMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "typeMenu")
                  }
                  open={this.state.open === "typeMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Application Type </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="fieldMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => fieldMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "fieldMenu")
                  }
                  open={this.state.open === "fieldMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Field of Invention </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="statusMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => statusMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "statusMenu")
                  }
                  open={this.state.open === "statusMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Application Status </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="legalMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => legalMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "legalMenu")
                  }
                  open={this.state.open === "legalMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Legal Status </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>

              <Menu.Item key="tagsMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => tagsMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "tagsMenu")
                  }
                  open={this.state.open === "tagsMenu"}
                >
                  <div className="ant-dropdown-link">
                    <span>Tags</span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
            </Menu>
          </Col>

          <Col
            span={6}
            className="alignR m-top-10"
            style={{ padding: "5px 0px" }}
          >
            <div>
              <Badge
                count={
                  this.state?.selectAll
                    ? "All"
                    : this.state?.selectedRowKeys?.length
                }
                style={{ marginTop: "5px" }}
              >
                <Button
                  style={{
                    backgroundColor: "#FDE5E7",
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                  }}
                  danger
                  loading={this.props.deleteManagePatentStatus === LOADING}
                  onClick={() => this.deleteMarks()}
                  icon={
                    <DeleteOutlined
                      style={{ fontSize: "18px", color: "red" }}
                    />
                  }
                >
                  Delete Patent
                </Button>
              </Badge>
              <Button
                type="primary"
                style={{ margin: "0 10px" }}
                onClick={() =>
                  this.changeStateValue_L1(
                    "modalsVisibility",
                    "addTrademarks",
                    true,
                  )
                }
              >
                Add Patent
              </Button>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            span={9}
            className="alignC"
            style={{ borderBottom: "1px solid #E9E9E9" }}
          >
            <Menu
              selectedKeys={[]}
              className="filter-list"
              mode="horizontal"
              style={{ alignItems: "baseline" }}
            >
              <Menu.Item
                key="contentTerm"
                className="filter-list-item apply-filter"
              >
                <Search
                  placeholder="Application No/Name"
                  onChange={(event) =>
                    this.changeStateValue_L1(
                      "selectedFilters",
                      "containsTerm",
                      event.target.value,
                    )
                  }
                  value={this.state?.selectedFilters?.containsTerm}
                  allowClear
                  style={{ width: 200, display: "block" }}
                />
              </Menu.Item>
              <Menu.Item
                key="applyFilter"
                className="filter-list-item apply-filter"
              >
                <Button type="primary" onClick={() => this.applyFilter()}>
                  <i className="fa fa-filter" aria-hidden="true" />
                  <Text style={{ color: "#fff", marginLeft: "5px" }}>
                    Apply Filter
                  </Text>
                </Button>
              </Menu.Item>
              <Menu.Item
                key="resetFilter"
                className="filter-list-item apply-filter"
              >
                <span
                  onClick={() => this.resetFilter()}
                  style={{ padding: "0px 5px" }}
                >
                  RESET
                </span>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        {/* Manage Trademarks filter */}

        {/* Manage Trademarks table */}
        <Row>
          <Col span={24}>
            <div className="m-top-20 manage-patent">
              <Table
                rowKey={(record) => record.id}
                bordered
                rowSelection={manageTrademarkRowSelection}
                columns={manageTrademarkColumns}
                dataSource={
                  this.props?.patentData && this.props?.patentData?.patents
                }
                pagination={{
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  current: this.state.pageNumber,
                  total:
                    this.props?.patentData && this.props?.patentData?.count,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} `,
                  onChange: this.handleNextpage,
                }}
                scroll={{ y: "calc(100vh - 320px)" }}
                loading={this.props.patentStatus === LOADING ? true : false}
              />
            </div>
          </Col>
        </Row>
        {/* Manage Trademarks table */}

        {/* Manage Trademark Drawer */}
        {this.state.drawersVisibility.patentData && (
          <ManagePatentDrawer
            visible={this.props.patentData.patents.find(
              ({ application_number }) =>
                application_number === this.state.application_number,
            )}
            onCloseDrawer={() =>
              this.changeStateValue_L1("drawersVisibility", "patentData", false)
            }
            editingKey={this.state.editingKey}
            selectedSetters={this.state.selectedSetters}
            application_number={this.state.application_number}
            selectAll={this.state.selectAll}
            mmType={this.state.value}
          />
        )}

        <AddTrademarks
          visible={this.state.modalsVisibility.addTrademarks}
          onCloseModal={() =>
            this.changeStateValue_L1("modalsVisibility", "addTrademarks", false)
          }
          product="pt-manager"
        />

        <TagsDrawer
          visible={this.state.drawersVisibility.manageTags}
          onCloseDrawer={() =>
            this.changeStateValue_L1("drawersVisibility", "manageTags", false)
          }
        />

        <DownloadPortfolioDrawer
          visible={this.state.drawersVisibility.downloadPortfolio}
          onCloseDrawer={() =>
            this.changeStateValue_L1(
              "drawersVisibility",
              "downloadPortfolio",
              false,
            )
          }
          product={"manager"}
          sub_product={"trademark"}
        />
      </>
    );
  }

  componentWillUnmount() {
    this.setState({
      intervalIndex: clearInterval(this.state.intervalIndex),
    });
  }
}

const mapDispatchToProps = {
  getManagePatent,
  getPatentTags,
  postManagePatent,
  postDownloadFile,
  getTeamMember,
  logOut,
};

function mapStateToProps(state) {
  const {
    patentData,
    patentStatus,
    patentAppliedFilter,
    patentPageNumber,
    deleteManagePatentStatus,
  } = state?.managerState?.patentDashboardState;

  const { teamMember, teamMemberStatus } = state.profile;

  return {
    teamMember,
    patentData,
    teamMemberStatus,
    patentStatus,
    lastAppliedFilters: patentAppliedFilter,
    pageNumber: patentPageNumber,
    deleteManagePatentStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePatent);
