//--------LIBRARIES--------
import Axios from 'axios';

//--------RT_CONFIG--------
import { WAT_RT_URL } from '../../../configs/rootConfigs';

//--------IP-SUITE CONSTANTS--------
import { SUCCESS, LOADING, ERROR } from '../../../constants/commonConstants/generalConstants';

//--------MANAGE TRADEMARKS TYPES--------
import { MT_GET_TABLE, MT_POST_MANAGE_FOCUS } from './types';

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from '../commonActions/actions';

//--------Get Table Data--------
export function MT_getTable(pageNumber, filterFlag, filters) {
    const proprietors = `${filters.proprietors}`;
    const tags = `${filters.tags}`;
    const classes = `${filters.classes}`;

    return async (dispatch) => {
        dispatch({ type: MT_GET_TABLE, status: LOADING });
        let response;
        try {
            response = await Axios.get(
                `${WAT_RT_URL}/manage_focus/`,
                {
                    params: {
                        page: pageNumber,
                        filter_flag: (filterFlag ? true : null),
                        proprietors,
                        classes,
                        tags,
                        contains_term: filters.containsTerm
                    },
                    headers: { Authorization: 'Token ' + localStorage.getItem('token') }
                }
            );

            switch (response.status) {
                case 200:
                    dispatch({
                        type: MT_GET_TABLE,
                        status: SUCCESS,
                        payload: response?.data,
                        pageNumber,
                        lastAppliedFilters: {
                            filterFlag,
                            ...filters
                        }
                    })
                    break;

                default:
                    alert(`Unexpected Response : ${response.status}`);
                    dispatch({ type: MT_GET_TABLE, status: ERROR });
                    break;
            }
        } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: MT_GET_TABLE, status: ERROR });
        }
    };
}

//--------Post Manage Focus--------
export function MT_postManageFocus(
    filterFlag,
    filters,
    setters,
    select_all = false,
    application_number = null,
    trademarks = null,
    delete_flag = false
) {
    return async (dispatch) => {
        dispatch({ type: MT_POST_MANAGE_FOCUS, status: LOADING, deleteFlag: delete_flag });
        let response;
        try {
            response = await Axios.post(
                `${WAT_RT_URL}/manage_focus/`,
                {
                    ...setters,
                    select_all,
                    application_number,
                    filter_flag: filterFlag,
                    filters: {
                        proprietors: filters.proprietors,
                        classes: filters.classes,
                        tags: filters.tags,
                        contains_term: filters.containsTerm
                    },
                    trademarks,
                    delete_flag
                },
                {
                    headers: { Authorization: 'Token ' + localStorage.getItem('token') }
                }
            );

            switch (response.status) {
                case 200:
                    dispatch({
                        type: MT_POST_MANAGE_FOCUS,
                        status: SUCCESS,
                        payload: response?.data,
                        selectAll: select_all,
                        setters,
                        deleteFlag: delete_flag
                    });
                    return SUCCESS;

                default:
                    dispatch({ type: MT_POST_MANAGE_FOCUS, status: ERROR, deleteFlag: delete_flag });
                    return ERROR;
            }
        } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: MT_POST_MANAGE_FOCUS, status: ERROR, deleteFlag: delete_flag });
            return ERROR;
        }
    };
}
