import React, { useEffect, useState } from "react";
import { Modal, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { ROOT_URL } from "../../../../../../configs/rootConfigs";

const { Dragger } = Upload;

const errorContainerStyle = {
  marginTop: "20px",
  color: "#dc3545",
  padding: "8px",
  border: "1px solid #dc3545",
  borderRadius: "4px",
  background: "#fff0f0",
  fontWeight: "bold",
};

const UploadModal = ({ threatFlag, openUpload, onCancel, onSuccess }) => {
  const [file, setFile] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUpload = async () => {
    setLoading(true);

    if (!file) {
      message.error("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    formData.append("file", file[0]?.originFileObj);
    formData.append("threat_flag", threatFlag);

    await axios
      .post(`${ROOT_URL}/brand_compare/manage/upload_excel/`, formData, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      })
      .then((response) => {
        message.success("File uploaded successfully");
        setError(null);
        setFile(null);
        // Additional handling if needed after successful upload
        onCancel(); // Close the modal after successful upload
        onSuccess();
      })
      .catch((error) => {
        if (
          error?.response &&
          error?.response.data &&
          error?.response.data.message
        ) {
          setErrorDetail(error?.response.data.payload?.detail);
          if (error?.response.data.message?.length > 0) {
            setError(error?.response.data.message);
          }
        } else {
          message.error("Failed to upload file");
          console.error("Upload failed:", error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Upload Excel File"
      open={openUpload}
      onCancel={() => {
        setFile(null);
        setError(null);
        setErrorDetail(null);
        onCancel();
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setFile(null);
            setError(null);
            setErrorDetail(null);
            onCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleUpload}
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Dragger
        name="file"
        multiple={false}
        maxCount={1}
        fileList={file}
        customRequest={({ file, onSuccess }) => onSuccess("ok")} // bypass antd Dragger's default behavior
        onChange={(info) => {
          const { status } = info.file;
          setFile(info.fileList);

          if (status !== "uploading") {
            setError(false);
          }
          if (status === "done") {
            // message.success(`${info.file.name} file uploaded successfully.`);
            setFile(info.fileList);
          } else if (status === "error") {
            message.error(`${info.file.name} file upload failed.`);
          }
        }}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Upload brand details.</p>
      </Dragger>
      {error && (
        <div style={errorContainerStyle}>
          {/* <h6>{errorDetail}</h6> */}
          <div>
            Upload Failed! Please try again after fixing following issues.
          </div>
          <ul
            style={{
              maxHeight: "120px",
              overflowY: "auto",
            }}
          >
            {error?.length > 0 &&
              error?.map((errorMessage, index) => (
                <li key={index}>
                  {index + 1}. {errorMessage}
                </li>
              ))}
          </ul>
        </div>
      )}
      <div style={{ marginTop: "20px" }}>
        Sample Excel file:{" "}
        <a
          href="https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/BrandCompare.xlsx"
          target="_blank"
          rel="noopener noreferrer"
        >
          sample1.xlsx
        </a>
      </div>
    </Modal>
  );
};

export default UploadModal;
