import React from "react";

import { Menu, Checkbox, Spin, Input, Typography } from "antd";
import { FixedSizeList } from "react-window";

const { Search } = Input;
const { Text } = Typography;

const FilterSearchPaginate = ({
  datasource,
  filter_key,
  selectedFilters,
  onFilterSearch,
  onFilterPaginate,
  loader,
  title,
  onSelect,
  onDeselect,
  type,
  searchPlaceholder,
  searchValue,
  req_filter,
  switchValue,
}) => {
  return (
    <>
      <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
        {title && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
              justifyContent: "center",
              fontWeight: 600,
            }}
          >
            {title}
          </div>
        )}
        <Search
          placeholder={searchPlaceholder ? searchPlaceholder : "Search"}
          value={searchValue}
          onChange={(event) => {
            onFilterSearch(req_filter, event.target.value);
          }}
          allowClear
        />
      </div>
      {datasource && datasource !== null && datasource?.length > 0 ? (
        <>
          <FixedSizeList
            className="List"
            height={150}
            itemCount={datasource?.length}
            itemSize={35}
            itemData={datasource}
            width={320}
            onScroll={({ scrollDirection, scrollOffset }) => {
              if (scrollDirection === "forward" && scrollOffset > 0) {
                onFilterPaginate && onFilterPaginate();
              }
            }}
          >
            {({ index, style, data }) => {
              return (
                <div>
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters?.[filter_key]}
                    onSelect={(event) => onSelect(event)}
                    onDeselect={(event) => onDeselect(event)}
                    style={
                      loader
                        ? {
                            cursor: "default",
                            textDecoration: "none",
                            color: "black",
                            pointerEvents: "none",
                            maxHeight: "175px",
                            minWidth: "150px",
                            overflowY: "scroll",
                          }
                        : {
                            maxHeight: "175px",
                            minWidth: "150px",
                            overflowY: "scroll",
                          }
                    }
                  >
                    <Menu.Item
                      key={
                        (type === "object" && data[index].id) ||
                        (type === "journal" && data[index].number) ||
                        data[index]
                      }
                      style={{
                        ...style,
                        padding: "5px",
                        width: "1200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={
                          (type === "object" &&
                            selectedFilters?.[filter_key]?.includes(
                              `${data[index]?.id}`
                            )) ||
                          (type === "journal" &&
                            selectedFilters?.journalNumber.includes(
                              `${data[index]?.number}`
                            )) ||
                          selectedFilters?.[filter_key]?.includes(
                            `${data[index]}`
                          )
                        }
                        disabled={loader}
                      >
                        {(type === "object" && (
                          <Text
                            className="text-camelCase"
                            style={{ fontWeight: "400" }}
                          >
                            {data[index]?.name?.toLowerCase()}
                          </Text>
                        )) ||
                          (type === "journal" && (
                            <Text
                              className="text-camelCase"
                              style={{ fontWeight: "400" }}
                            >
                              {data[index]?.number} (
                              {data[index]?.date_of_publication})
                            </Text>
                          )) || (
                            <Text style={{ fontWeight: "400" }}>
                              {data[index] ? data[index] : ""}
                            </Text>
                          )}
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                </div>
              );
            }}
          </FixedSizeList>
          {loader && (
            <div
              style={{
                position: "absolute",
                bottom: "8px",
                right: "8px",
                padding: "5px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Spin />
            </div>
          )}
        </>
      ) : (
        <Menu>
          <Menu.Item
            style={{
              width: 320,
              height: 150,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="alignC">
              <Text strong>{loader ? <Spin /> : "No Filters Data Found"}</Text>
            </div>
          </Menu.Item>
        </Menu>
      )}
    </>
  );
};

export default FilterSearchPaginate;
