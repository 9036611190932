import React from "react";
import { Drawer, Row, Col, Button, Input, Radio, Alert, Space } from "antd";
import {
  PDF,
  EXCEL,
  LOADING,
} from "../../../../constants/commonConstants/generalConstants";

export default function CustomReport({
  visible,
  customReportData,
  customReportStatus,
  changeStateValue_L1,
  closeDrawer,
  handleCreateReportConfirmation,
}) {
  return (
    <Drawer
      title={<h3>Custom Report Details</h3>}
      placement="right"
      width={400}
      closable={false}
      open={visible}
      onClose={() => closeDrawer()}
    >
      <Row>
        <Col span={24}>
          <p className="fw_500">Enter the name of your report:</p>
          <Input
            onChange={(event) => {
              if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                changeStateValue_L1(
                  "customReportData",
                  "report_name",
                  event.target.value,
                );
              }
            }}
            value={customReportData.report_name}
            placeholder="Report's name (Optional)"
          />
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <p className="fw_500">Choose your preferred report format:</p>
          <Radio.Group
            value={customReportData.report_format}
            onChange={(event) =>
              changeStateValue_L1(
                "customReportData",
                "report_format",
                event.target.value,
              )
            }
            size="small"
          >
            <Radio value={PDF} style={{ fontWeight: 400 }}>
              PDF
            </Radio>
            <br />
            <Radio value={EXCEL} style={{ fontWeight: 400 }}>
              EXCEL
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <Alert
            message="Note: Once the report is created, it cannot be changed."
            type="warning"
          />
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <Space>
            <Button
              type="primary"
              ghost
              onClick={handleCreateReportConfirmation}
              loading={customReportStatus === LOADING}
              rootStyle={{ marginRight: "10px" }}
            >
              Confirm
            </Button>
            <Button danger ghost onClick={() => closeDrawer()}>
              Cancel
            </Button>
          </Space>
        </Col>
      </Row>
    </Drawer>
  );
}
