import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import { Row, Col, Modal, Button } from "antd";

//--------COMMON CONSTANTS--------
import { LOADING } from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import { replyFiledToFer } from "../../../redux/actions/managerActions/patentDashboard/actions";

class ReplyFiledModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  async confirmReplyFiled() {
    const { pending_reply, application_number } = this.props;
    await this.props.replyFiledToFer(pending_reply, application_number);
    this.props.onCloseModal();
  }

  render() {
    return (
      <div>
        <Modal
          title="Confirm Remove"
          open={this.props.visible}
          closable={false}
          footer={false}
        >
          <div>
            <Row gutter={16}>
              <Col span={24}>
                <p>
                  Are you sure you want to remove{" "}
                  <span>
                    <strong>{this.props.title}</strong>
                  </span>{" "}
                  from this table?
                </p>
              </Col>
              <Col span={24} className="m-top-20 alignR">
                <div>
                  <ul className="report-footer">
                    <li>
                      <Button type="default" onClick={this.props.onCloseModal}>
                        Cancel
                      </Button>
                    </li>
                    <li>
                      <Button
                        danger
                        loading={
                          this.props.replyFiledStatus === LOADING ? true : false
                        }
                        onClick={() => this.confirmReplyFiled()}
                      >
                        Confirm
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  replyFiledToFer,
};

function mapStateToProps(state) {
  const { replyFiledMessage, replyFiledStatus } =
    state.managerState.trademarkDashboardState;
  return deepFreeze({
    replyFiledMessage,
    replyFiledStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplyFiledModal);
