//check amount/data is null or not global
export function checkValue(value) {
    if (value !== null && value !== "" && value !== undefined && value !== "undefined" && value !== "null" && typeof(value) !== undefined) {
        return true;
    } else {
        return false;
    }
}

//--------Check Not a Number or NaN--------
export function checkNaN(value) {
    if (isNaN(value)) {
        return false;
    } else {
        return true;
    }
}

//--------Check if the value is valid or not, returns (value/"N/A")--------
export function checkNA(value){
    if(value){
        return value;
    } else {
        return "N/A";
    }
}