//--------TYPES--------
import { 

    TM_DASHBOARD,
    OPPOSED_TM_DASHBOARD,
    PATENT_DASHBOARD,
    TM_DASHBOARD_ANALYTICS,
    OPPOSED_TM_DASHBOARD_ANALYTICS,
    PATENT_DASHBOARD_ANALYTICS,

    TM_NOTIFICATION,
    PATENT_NOTIFICATION,
    CUSTOM_NOTIFICATION,

    TM_CUSTOM,
    TM_INTERNATIONAL,
    PATENT_CUSTOM,
    PATENT_INTERNATIONAL,

    DELETE_TM_CUSTOM,
    DELETE_TM_INTERNATIONAL,
    DELETE_PATENT_CUSTOM,
    DELETE_PATENT_INTERNATIONAL,
    
    TM_CUSTOM_PROFILE,
    TM_INTERNATIONAL_PROFILE,
    PATENT_CUSTOM_PROFILE,
    PATENT_INTERNATIONAL_PROFILE,
    
    TM_EDIT_CUSTOM_PROFILE,
    TM_EDIT_INTERNATIONAL_PROFILE,
    PATENT_EDIT_CUSTOM_PROFILE,
    PATENT_EDIT_INTERNATIONAL_PROFILE,
 } from '../../../actions/managerActions/profile/types';

//--------COMMON CONSTANTS--------
import { LOADING, SUCCESS, ERROR } from '../../../../constants/commonConstants/generalConstants'; 
import { TM_DefaultSelectedFilters } from '../../../../constants/managerConstants/trademarkConstants';
import { Patent_DefaultSelectedFilters } from '../../../../constants/managerConstants/patentConstants';
import { Notification_DefaultSelectedFilters } from '../../../../constants/commonConstants/generalConstants';
import { UPLOAD_FILE_TM_CUSTOM, UPLOAD_FILE_TM_INT } from '../../../actions/managerActions/trademarkDashboard/types';

export default function( state = {
    
        tmDashboard: null,
        tmDashboardStatus: null,
        tmDashboardAnalytics:null,
        tmDashboardAnalyticsStatus:null,
        opposedTmDashboardAnalytics:null,
        opposedTmDashboardAnalyticsStatus:null,
        patentDashboardAnalytics:null,
        patentDashboardAnalyticsStatus:null,
        opposedTmDashboard: null,
        opposedTmDashboardStatus: null,
        patentDashboard: null,
        patentDashboardStatus: null,

        notificationType: false,
        tmNotificationType: 'trademark',
        tmNotification: null,
        tmNotificationStatus: null,
        tmNotificationAppliedFilters: {
            ...Notification_DefaultSelectedFilters,
            filterFlag: false
        },
        tmNotificationPageNumber: 1,

        patentNotification: null,
        patentNotificationStatus: null,
        patentNotificationAppliedFilters: {
            ...Notification_DefaultSelectedFilters,
            filterFlag: false
        },
        patentNotificationPageNumber: 1,

        customNotification: null,
        customNotificationStatus: null,
        customNotificationAppliedFilters: {
            ...Notification_DefaultSelectedFilters,
            filterFlag: false
        },
        customNotificationPageNumber: 1,

        customTrademark: null,
        customTrademarkStatus: null,
        customLastAppliedFilters: {
            ...TM_DefaultSelectedFilters,
            filterFlag: false
        },
        customPageNumber: 1,
        deleteCustomTrademark: null,
        deleteCustomTrademarkStatus: null,
        customTrademarkProfile: null,
        customTrademarkProfileStatus: null, 

        internationalTrademark: null,
        internationalTrademarkStatus: null,
        internationalLastAppliedFilters: {
            ...TM_DefaultSelectedFilters,
            filterFlag: false
        },
        internationalPageNumber: 1,
        deleteInternationalTrademark: null,
        deleteInternationalTrademarkStatus: null,
        internationalTrademarkProfile: null, 
        internationalTrademarkProfileStatus: null, 

        customPatent: null,
        customPatentStatus: null,
        customPatentAppliedFilters: {
            ...Patent_DefaultSelectedFilters,
            filterFlag: false
        },
        customPatentPageNumber: 1,
        deleteCustomPatent: null,
        deleteCustomPatentStatus: null,
        customPatentProfile: null,
        customPatentProfileStatus: null,

        internationalPatent: null,
        internationalPatentStatus: null,
        internationalPatentAppliedFilters: {
            ...Patent_DefaultSelectedFilters,
            filterFlag: false
        },
        internationalPatentPageNumber: 1,
        deleteInternationalPatent: null,
        deleteInternationalPatentStatus: null,
        internationalPatentProfile: null,
        internationalPatentProfileStatus: null,

        customTrademarkProfileEditStatus: null,
        internationalTrademarkProfileEditStatus: null,
        customPatentProfileEditStatus: null,
        internationalPatentProfileEditStatus: null,

    }, action ){
    switch (action.type) {

        case TM_DASHBOARD:
            return {
                ...state,
                tmDashboard: action.payload,
                tmDashboardStatus: action.status
            };

        case TM_DASHBOARD_ANALYTICS:
            return {
                ...state,
                tmDashboardAnalytics: action.payload,
                tmDashboardAnalyticsStatus: action.status
            };

        case OPPOSED_TM_DASHBOARD:
            return {
                ...state,
                opposedTmDashboard: action.payload,
                opposedTmDashboardStatus: action.status
            };

        case OPPOSED_TM_DASHBOARD_ANALYTICS:
            return {
                ...state,
                opposedTmDashboardAnalytics: action.payload,
                opposedTmDashboardAnalyticsStatus: action.status
            };

        case PATENT_DASHBOARD:
            return {
                ...state,
                patentDashboard: action.payload,
                patentDashboardStatus: action.status
            };
        case PATENT_DASHBOARD_ANALYTICS:
            return {
                ...state,
                patentDashboardAnalytics: action.payload,
                patentDashboardAnalyticsStatus: action.status
            };


        case TM_NOTIFICATION:
            return {
                ...state,
                tmNotification: action.payload,
                tmNotificationStatus: action.status,
                notificationType: action.notificationType,
                tmNotificationType: action.tmNotificationType,
                tmNotificationAppliedFilters: action.lastAppliedFilters,
                tmNotificationPageNumber: action.pageNumber
            };
           
        case PATENT_NOTIFICATION:
            return {
                ...state,
                patentNotification: action.payload,
                patentNotificationStatus: action.status,
                notificationType: action.notificationType,
                patentNotificationAppliedFilters: action.lastAppliedFilters,
                patentNotificationPageNumber: action.pageNumber
            };

        case CUSTOM_NOTIFICATION:
            return {
                ...state,
                customNotification: action.payload,
                customNotificationStatus: action.status,
                customNotificationAppliedFilters: action.lastAppliedFilters,
                customNotificationPageNumber: action.pageNumber
            };

        case TM_CUSTOM:
            return {
                ...state,
                customTrademark: action.payload,
                customTrademarkStatus: action.status,
                customLastAppliedFilters: action.lastAppliedFilters,
                customPageNumber: action.pageNumber
            };

        case DELETE_TM_CUSTOM:
            return {
                ...state,
                deleteCustomTrademark: action.payload,
                deleteCustomTrademarkStatus: action.status,
            };

        case TM_CUSTOM_PROFILE:
            return {
                ...state,
                customTrademarkProfile: action.payload,
                customTrademarkProfileStatus: action.status
            };

        case TM_INTERNATIONAL:
            return {
                ...state,
                internationalTrademark: action.payload,
                internationalTrademarkStatus: action.status,
                internationalLastAppliedFilters: action.lastAppliedFilters,
                internationalPageNumber: action.pageNumber
            };

        case DELETE_TM_INTERNATIONAL:
            return {
                ...state,
                deleteInternationalTrademark: action.payload,
                deleteInternationalTrademarkStatus: action.status,
            };
        
        case TM_INTERNATIONAL_PROFILE:
            return {
                ...state,
                internationalTrademarkProfile: action.payload,
                internationalTrademarkProfileStatus: action.status
            };

        case PATENT_CUSTOM:
            return {
                ...state,
                customPatent: action.payload,
                customPatentStatus: action.status,
                customPatentAppliedFilters: action.lastAppliedFilters,
                customPatentPageNumber: action.pageNumber
            };

        case DELETE_PATENT_CUSTOM:
            return {
                ...state,
                deleteCustomPatent: action.payload,
                deleteCustomPatentStatus: action.status,
            };

        case PATENT_CUSTOM_PROFILE:
            return {
                ...state,
                customPatentProfile: action.payload,
                customPatentProfileStatus: action.status
            };

        case PATENT_INTERNATIONAL:
            return {
                ...state,
                internationalPatent: action.payload,
                internationalPatentStatus: action.status,
                internationalPatentAppliedFilters: action.lastAppliedFilters,
                internationalPatentPageNumber: action.pageNumber
            };

        case DELETE_PATENT_INTERNATIONAL:
            return {
                ...state,
                deleteInternationalPatent: action.payload,
                deleteInternationalPatentStatus: action.status,
            };

        case PATENT_INTERNATIONAL_PROFILE:
            return {
                ...state,
                internationalPatentProfile: action.payload,
                internationalPatentProfileStatus: action.status
            };

        case UPLOAD_FILE_TM_CUSTOM:
            switch (action.status) {
                case SUCCESS:                    
                    return {
                        ...state,
                        customTrademarkProfile: {
                            ...state.customTrademarkProfile,
                            uploaded_documents: action.payload.uploaded_documents
                        }
                    };
        
                case LOADING:
                    return state;

                case ERROR:
                    return state;
        
                default:
                    return state;
        }

        case UPLOAD_FILE_TM_INT:
            switch (action.status) {
                case SUCCESS:                    
                    return {
                        ...state,
                        internationalTrademarkProfile: {
                            ...state.internationalTrademarkProfile,
                            uploaded_documents: action.payload.uploaded_documents
                        }
                    };
        
                case LOADING:
                    return state;

                case ERROR:
                    return state;
        
                default:
                    return state;
        }

        case TM_EDIT_CUSTOM_PROFILE:
            return {
                ...state,
                customTrademarkProfile: action.payload,
                customTrademarkProfileStatus: action.status,
                customTrademarkProfileEditStatus: action.status
            };
        
        case TM_EDIT_INTERNATIONAL_PROFILE:
            return {
                ...state,
                internationalTrademarkProfile: action.payload,
                internationalTrademarkProfileStatus: action.status,
                internationalTrademarkProfileEditStatus: action.status
            };
        
        case PATENT_EDIT_CUSTOM_PROFILE:
            return {
                ...state,
                customPatentProfile: action.payload,
                customPatentProfileStatus: action.status,
                customPatentProfileEditStatus: action.status,
            };
                
        case PATENT_EDIT_INTERNATIONAL_PROFILE:
            return {
                ...state,
                internationalPatentProfile: action.payload,
                internationalPatentProfileStatus: action.status,
                internationalPatentProfileEditStatus: action.status,
            };

        default:
            return state;
    }
}