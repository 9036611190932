//--------WATCH TYPES--------
import { 
    PS_GET_SEARCH_RESULTS, 
    PS_GET_PROPRIETOR_MARKS,
    PS_GET_PROPRIETOR_DATA, 
    PS_GET_PROPRIETOR_ANALYSIS,
    PS_SELECT_VIEW
} from "../../actions/searchActions/types";

//--------IP-SUITE CONSTANTS--------
import { LOADING, SUCCESS, ERROR } from "../../../constants/commonConstants/generalConstants";
import { PS_secondarySelectedFilters, PS_secondarySelectedSorting } from '../../../constants/searchConstants/proprietorsSearchConstants';

const defaultProprietorsSearchState = {
    proprietorsSearchStatus: SUCCESS,
    proprietors: [],
    count: 0,
    filters: null,
    searchParams: {}, 

    proprietorMark: null,
    proprietorMarkStatus: null,
    proprietorMarkFilters: {
        ...PS_secondarySelectedFilters,
        filterFlag: false
    }, 
    proprietorMarkSorting: {
        ...PS_secondarySelectedSorting,
        sortFlag: false
    },
    proprietorMarkPageNumber: 1,

    proprietorProfile: null,
    proprietorProfileStatus: null,

    similarProprietor: null,
    similarProprietorStatus: null,

    proprietorAnalysis: null,
    proprietorAnalysisStatus: null,

    selectedView: "cards",
    axiosCancelSearches: null,
    axiosToken: null,
};

export default function(state = defaultProprietorsSearchState, action){
    switch (action.type) {
        case PS_GET_SEARCH_RESULTS:
            switch (action.status) {
                case SUCCESS:
                    return {
                        ...state,
                        proprietorsSearchStatus: SUCCESS,
                        ...action.payload,
                        searchParams: action.searchParams
                    };

                case LOADING:
                    return {
                        ...state,
                        proprietorsSearchStatus: LOADING
                    };

                case ERROR:
                    return {
                        ...state,
                        proprietorsSearchStatus: ERROR
                    };
            
                default:
                    break;
            }
            break;

        case PS_GET_PROPRIETOR_MARKS: 
            return {
                ...state,
                proprietorMark: action.payload,
                proprietorMarkStatus: action.status,
                proprietorMarkFilters: action.lastAppliedFilters,
                proprietorMarkSorting: action.lastAppliedSorting,
                proprietorMarkPageNumber: action.pageNumber,
            };
        
        case PS_GET_PROPRIETOR_DATA: 
            switch (action.status) {
                case LOADING:
                    return {
                        ...state,
                        proprietorProfileStatus: LOADING,
                        similarProprietorStatus: LOADING,
                    };

                case SUCCESS:
                    return {
                        ...state,
                        proprietorProfile: action.payload.proprietor,
                        proprietorProfileStatus: SUCCESS,
                        similarProprietor: action.payload.similarProprietor,
                        similarProprietorStatus: SUCCESS,
                    };

                case ERROR:
                    return {
                        ...state,
                        proprietorProfileStatus: ERROR,
                        similarProprietorStatus: ERROR,
                    };
            
                default:
                    break;
            }
            break;

        case PS_GET_PROPRIETOR_ANALYSIS: 
            return {
                ...state,
                proprietorAnalysis: action.payload,
                proprietorAnalysisStatus: action.status
            };

        case PS_SELECT_VIEW:
            return {
                ...state,
                selectedView: action.payload
            };

        default:
            return state;
    }
}