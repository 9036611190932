export const MANAGE_NOTES = 'MANAGE_NOTES';
export const NOTES_CLASS_FILTER="NOTES_CLASS_FILTER";
export const NOTES_PROPRIETOR_FILTER="NOTES_PROPRIETOR_FILTER";
export const NOTES_TAG_FILTER="NOTES_TAG_FILTER";
export const NOTES_APPLICANT_FILTER="NOTES_APPLICANT_FILTER";
export const NOTES_INVENTOR_FILTER="NOTES_INVENTOR_FILTER";
export const NOTES="NOTES";
export const DELETE_NOTES="DELETE_NOTES";
export const DELETE_ALL_NOTES="DELETE_ALL_NOTES";
export const CASCADER_STATE="CASCADER_STATE";
export const CREATE_CLIENT_NOTES="CREATE_CLIENT_NOTES";
export const SET_NOTES_STORE="SET_NOTES_STORE";