import React, { Component } from "react";
import { connect } from "react-redux";
import { uniq, uniqWith, compact, isEqual, debounce } from "lodash";
import { FixedSizeList } from "react-window";
import {
  DownOutlined,
  FileTextOutlined,
  PlusOutlined,
  RestFilled,
} from "@ant-design/icons";
import {
  Popover,
  Checkbox,
  Row,
  Col,
  Input,
  AutoComplete,
  Tag,
  Button,
  Modal,
  Typography,
  Tooltip,
  message,
  Menu,
  Dropdown,
  Select,
  Spin,
} from "antd";

import { CKEditor } from "ckeditor4-react";

import {
  sendCustomEmail,
  getEmailTemplate,
  getUserClientEmail,
  getEmailDocument,
  downloadLetterHead,
  clearEmailTemplate,
} from "../../../redux/actions/commonActions/actions";
import { sendManagerEmail } from "../../../redux/actions/managerActions/commonAction/action";
import {
  GET_EMAIL_TEMPLATE,
  GET_USER_CLIENT_EMAIL,
  GET_USER_TO_CLIENT_EMAIL_MORE,
} from "../../../redux/actions/commonActions/types";
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { changeStateValue_L0 } from "../../../functions/commonFunctions/generalFunctions";
import EmailTemplateHandler from "../subComponents/EmailTemplateHandler";
// import { getUserClientEmailMore } from "../../../redux/actions/commonActions/actions";

// const { Option } = AutoComplete;
const { Text, Title } = Typography;
const _ = require("lodash");
const { Option } = Select;
class SendEmailNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      application_number: "",
      file_name: "",
      downloadContent: false,
      letter_head_visible: true,
      add_journal_copy: false,
      open: false,
      selectValue: "",
      searchValue: {
        send_to: "",
        cc: "",
      },
      cc_target_data: [],
      send_target_data: [],
      fetching: false,
      Tags: {
        Send_To: [],
        CC: [],
      },
      send_from: "",
      send_to: "",
      cc: "",
      subject: "",
      tags: {
        send_to: [],

        cc: [],
      },
      mail_body: "<p>Dear Sir/Ma'am,<p><strong>Regards</strong></p></p>",
      page: {
        send_to: 1,
        cc: 1,
      },
      send_from_input_error: false,
      send_to_input_error: false,
      cc_input_error: false,
      subject_input_error: false,
      selectedKeys: [],
      selectedKeys1: [],
      attachment: [],
      report_tag: {},
      file_attachment: {
        file: "",
        fileName: "",
        filePreviewUrl: "",
      },
      multiple_files: [],
      select_doc_link: [],
      send_from_error: false,
      send_to_error: false,
      subject_error: false,
      activeKey: "",
      selectedKey: "",
    };
    this.editorRef = React.createRef();
    this.autoRef = React.createRef();
    this.deletePrevious = React.createRef(false);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    // var data = window.CKEDITOR.instances.editor1.getData();
    // console.log("data",data);
  }

  async shouldComponentUpdate(nextProps) {
    if (nextProps.report_tag !== this.props.report_tag) {
      const salutation = "Dear Sir/Ma'am,";
      const mail_Content = checkValue(nextProps.mailContent)
        ? nextProps.mailContent
        : "<p></p>";
      const mail_html = nextProps.attachment
        ? "<p>" +
          salutation +
          "</p>" +
          mail_Content +
          "<p><strong>Regards</strong></p>" +
          "<a href=" +
          nextProps.attachment +
          "  target=_blank >Report Link </a>"
        : "<p>" +
          salutation +
          "</p>" +
          mail_Content +
          "<p><strong>Regards</strong></p>";

      this.setState({
        send_from: checkValue(nextProps.sendFrom) ? nextProps.sendFrom : "",
        subject: "",
        mail_body: mail_html,
        attachment: checkValue(nextProps.journal_copy)
          ? [nextProps.journal_copy]
          : checkValue(nextProps.attachment)
            ? nextProps.attachment
            : [],
        selectedKeys: [],
        product: checkValue(nextProps.product) ? nextProps.product : "",
        subscription: checkValue(nextProps.subscription)
          ? nextProps.subscription
          : "",
        sub_section: checkValue(nextProps.sub_section)
          ? nextProps.sub_section
          : "",
        report_tag: checkValue(nextProps.report_tag)
          ? nextProps.report_tag
          : {},

        application_number: checkValue(nextProps.applicationNumber)
          ? nextProps.applicationNumber
          : "",
      });
    }

    if (nextProps.visible && !this.props.visible) {
      this.props.getEmailTemplate({
        actionType: GET_EMAIL_TEMPLATE,
        product: nextProps.product,
        subscription: `${nextProps.subscription}`,
        sub_section: nextProps.sub_section,
      });
      if (nextProps.markId) {
        this.props.getEmailDocument({
          mark_id: nextProps.markId,
        });
      }

      if (!nextProps.attachment || nextProps.attachment) {
        await this.props.getUserClientEmail({
          actionType: GET_USER_CLIENT_EMAIL,
          product: nextProps.product,
          subscription: !nextProps.subscription
            ? null
            : nextProps.subscription?.includes("JOURNAL WATCH") ||
                nextProps.subscription?.includes("IMAGE_RECOGNITION")
              ? "Journal Watch"
              : nextProps.subscription?.includes("TM PROTECT")
                ? "TM Protect"
                : nextProps.subscription[0],
          mm_type: nextProps.mm_type,
          report_flag: nextProps.report_flag,
          application_number:
            nextProps.subscription &&
            nextProps.subscription?.includes("TM PROTECT")
              ? nextProps?.mark_type
              : checkValue(nextProps.applicationNumber)
                ? nextProps.applicationNumber
                : "0000000",
        });
        this.setState({
          tags: {
            ...this.state.tags,
            send_to: checkValue(this.props.userClientEmail)
              ? this.props?.userClientEmail?.associated_client_emails?.map(
                  (eachmail) => `${eachmail.id}/${eachmail.email}`,
                )
              : [],
            cc: checkValue(this.props.userClientEmail)
              ? this.props?.userClientEmail?.team_emails?.map(
                  (eachmail) => `${eachmail.id}/${eachmail.email}`,
                )
              : [],
          },
          Tags: {
            ...this.state.Tags,
            Send_To: checkValue(this.props.userClientEmail)
              ? this.props?.userClientEmail?.associated_client_emails?.map(
                  (eachmail) => eachmail.email,
                )
              : [],
            CC: checkValue(this.props.userClientEmail)
              ? this.props?.userClientEmail?.team_emails?.map(
                  (eachmail) => eachmail.email,
                )
              : [],
          },
        });
      }
    }
    return true;
  }

  onEditorChange = (evt) => {
    const mail_Content = checkValue(evt.editor.getData())
      ? evt.editor.getData()
      : "<p></p>";
    const mail_html = this.props.attachment
      ? mail_Content +
        "<a href=" +
        this.props.attachment +
        "  target=_blank >Report Link </a>"
      : mail_Content;

    this.setState({
      mail_body: mail_html,
    });
  };

  onDropdownVisibleChange(open, selectValue) {
    this.props.getUserClientEmail({
      actionType: GET_USER_CLIENT_EMAIL,
      product: this.props.product,
      subscription: !this.props.subscription
        ? null
        : this.props.subscription?.includes("JOURNAL WATCH") ||
            this.props.subscription?.includes("IMAGE_RECOGNITION")
          ? "Journal Watch"
          : this.props.subscription?.includes("TM PROTECT")
            ? "TM Protect"
            : this.props.subscription[0],
      mm_type: this.props.mm_type,
      report_flag: this.props.report_flag,
      application_number:
        this.props.subscription &&
        this.props.subscription?.includes("TM PROTECT")
          ? this.props?.mark_type
          : checkValue(this.props.applicationNumber)
            ? this.props.applicationNumber
            : "0000000",
    });
    this.setState({
      open,
      selectValue,
    });
  }

  handleChange(event, selectValue) {
    const { value, id } = event.target;
    this.setState({
      [selectValue]: value,
    });
    setTimeout(() => {
      let element = document.getElementById(id);
      if (this.state[selectValue].length > 0) {
        element.classList.remove("email_error_input");
        this.setState({
          [`${selectValue}_error`]: false,
        });
      } else {
        element.classList.add("email_error_input");
        this.setState({
          [`${selectValue}_error`]: true,
        });
      }
    }, 500);
  }

  handleKeyPress(event, selectValue) {
    const { id, value } = event.target;
    let email_id = value.split("/");
    var re = /\S+@\S+\.\S+/;
    if (event.key === "Enter") {
      if (value && value.toLowerCase().match(re)) {
        if (selectValue === "send_to") {
          this.setState({
            Tags: {
              ...this.state.Tags,
              ["Send_To"]: uniq([...this.state.Tags["Send_To"], value]),
            },
          });
          let result1 = this.props?.userClientEmail?.client_emails.forEach(
            (arrayItem) => {
              if (arrayItem.id === parseInt(email_id[0])) {
                this.setState({
                  send_target_data: [...this.state.send_target_data, arrayItem],
                });
              }
            },
          );
        } else if (selectValue === "cc") {
          this.setState({
            Tags: {
              ...this.state.Tags,
              ["CC"]: uniq([...this.state.Tags["CC"], value]),
            },
          });

          let result1 = this.props?.userClientEmail?.client_emails.forEach(
            (arrayItem) => {
              if (arrayItem.id === parseInt(email_id[0])) {
                this.setState({
                  cc_target_data: [...this.state.cc_target_data, arrayItem],
                });
              }
            },
          );
        }
        this.setState({
          tags: {
            ...this.state.tags,
            [selectValue]: uniq([...this.state.tags[selectValue], value]),
          },
          send_to_error: false,
        });
      }
    }
  }

  handleGetUserClientEmail(searchValue, selectValue) {
    if (searchValue.length !== 0) {
      this.props.getUserClientEmail({
        actionType: GET_USER_CLIENT_EMAIL,
        product: this.props.product,
        subscription: !this.props.subscription
          ? null
          : this.props.subscription?.includes("JOURNAL WATCH") ||
              this.props.subscription?.includes("IMAGE_RECOGNITION")
            ? "Journal Watch"
            : this.props.subscription?.includes("TM PROTECT")
              ? "TM Protect"
              : this.props.subscription[0],
        mm_type: this.props.mm_type,
        report_flag: this.props.report_flag,
        application_number:
          this.props.subscription &&
          this.props.subscription?.includes("TM PROTECT")
            ? this.props?.mark_type
            : checkValue(this.props.applicationNumber)
              ? this.props.applicationNumber
              : "0000000",
        contains_term: searchValue,
        type: selectValue,
      });
    }
  }

  handleSearchDebounced = debounce(function (searchValue, selectValue) {
    this.handleGetUserClientEmail(searchValue, selectValue);
  }, 500);

  handleSearch(searchValue, selectValue) {
    this.setState({
      selectValue,
      searchValue: {
        ...this.state.searchValue,
        [selectValue]: searchValue,
      },
      page: {
        ...this.state.page,
        [selectValue]: 1,
      },
    });
    this.handleSearchDebounced(searchValue, selectValue);
  }

  handleTemplateSearch(searchValue, selectValue) {
    this.props.getEmailTemplate({
      actionType: GET_EMAIL_TEMPLATE,
      product: this.props.product,
      subscription: `${this.props.subscription}`,
      sub_section: this.props.sub_section,
    });
  }

  handleSelect(value, selectValue) {
    let email_id = value.split("/");
    let email = email_id[1];
    this.setState({
      tags: {
        ...this.state.tags,
        [selectValue]: uniq([...this.state.tags[selectValue], value]),
      },
    });

    if (selectValue === "send_to") {
      this.setState({
        Tags: {
          ...this.state.Tags,
          ["Send_To"]: uniq([...this.state.Tags["Send_To"], email]),
        },
      });

      let result1 = this.props?.userClientEmail?.client_emails.forEach(
        (arrayItem) => {
          if (arrayItem.id === parseInt(email_id[0])) {
            this.setState({
              send_target_data: [...this.state.send_target_data, arrayItem],
            });
          }
        },
      );
    } else {
      this.setState({
        Tags: {
          ...this.state.Tags,
          ["CC"]: uniq([...this.state.Tags["CC"], email]),
        },
        send_to_error: false,
      });
      let result1 = this.props?.userClientEmail?.client_emails.forEach(
        (arrayItem) => {
          if (arrayItem.id === parseInt(email_id[0])) {
            this.setState({
              cc_target_data: [...this.state.cc_target_data, arrayItem],
            });
          }
        },
      );
    }
  }
  handleDeselect(value, selectValue) {
    let email_id = value.split("/");
    let email = email_id[1];
    this.setState({
      tags: {
        ...this.state.tags,
        [selectValue]: uniq(
          this.state.tags[selectValue].filter((item) => item !== value),
        ),
      },
    });
    if (selectValue === "send_to") {
      this.setState({
        Tags: {
          ...this.state.Tags,
          ["Send_To"]: uniq(
            this.state.Tags["Send_To"].filter((item) => item !== email),
          ),
        },
        send_to_error: false,
      });
    } else {
      this.setState({
        Tags: {
          ...this.state.Tags,
          ["CC"]: uniq(this.state.Tags["CC"].filter((item) => item !== email)),
        },
        send_to_error: false,
      });
    }
  }

  handleDeleteTag(tag, selectValue) {
    this.deletePrevious.current = false;
    this.setState({
      tags: {
        ...this.state.tags,
        [selectValue]: this.state.tags[selectValue].filter(
          (eachTag) => eachTag !== tag,
        ),
      },
    });
  }

  handleLoadMore(event, selectValue) {
    const { target } = event;
    let pagination;
    if (selectValue === "send_to") {
      pagination = this.props?.userClientEmail?.count / 100;
    } else if (selectValue === "cc") {
      pagination = this.props?.userClientEmail?.count / 100;
    }
    if (
      pagination >= this.state.page[selectValue] &&
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      this.props?.userClientEmailMoreStatus !== LOADING &&
      this.props?.userClientEmailStatus !== LOADING
    ) {
      this.setState(
        {
          selectValue,
          page: {
            ...this.state.page,
            [selectValue]: this.state.page[selectValue] + 1,
          },
        },
        () => {
          this.props.getUserClientEmail({
            actionType: GET_USER_TO_CLIENT_EMAIL_MORE,
            product: this.props.product,
            subscription: !this.props.subscription
              ? null
              : this.props.subscription.includes("JOURNAL WATCH") ||
                  this.props.subscription.includes("IMAGE_RECOGNITION")
                ? "Journal Watch"
                : this.props.subscription.includes("TM PROTECT")
                  ? "TM Protect"
                  : this.props.subscription[0],
            mm_type: this.props.mm_type,
            report_flag: this.props.report_flag,
            application_number:
              this.props.subscription &&
              this.props.subscription.includes("TM PROTECT")
                ? this.props?.mark_type
                : checkValue(this.props.applicationNumber)
                  ? this.props.applicationNumber
                  : "0000000",
            type: selectValue,
            page: this.state.page[selectValue],
            contains_term: this.state.contains_term,
          });
        },
      );
    }
  }

  handleSelectTemplate(data) {
    const { report_tag, product } = this.props;
    let tagValue = [];
    if (product === "MANAGER") {
      let oldTags = [
        "report_name",
        "status",
        "journal_number",
        "journal_date",
        "tla_hearing_date",
        "opposition_hearing_date",
        "opponent_name",
        "proprietor_name",
        "renewal_date",
        "application_number",
        "mark_name",
        "date_of_application",
        "class",
        "date_of_usage",
      ];
      tagValue = [
        "trademark_application_number",
        "trademark_name",
        "trademark_status",
        "trademark_classes",
        "date_of_application",
        "date_of_usage",
        "renewal_date",
        "g_and_s_description",
        "trademark_images",
        "journal_number",
        "journal_date",
        "proprietor_names",
        "proprietor_addresses",
        "proprietor_trading_as",
        "latest_tla_hearing_date",
        "opponent_names",
        "opponent_numbers",
        "opponent_attorney_names",
        "opponent_attorney_addresses",
        "attorney_name",
        "attorney_address",
        "latest_date_of_examination_report",
        "formatted_proprietor_addresses",
        "latest_formality_fail_date",
        "latest_formality_fail_reason",
        "latest_examination_report_objection",
        "tags",
        "latest_opposition_hearing_date",
      ];
      tagValue.push(...oldTags);
    } else if (product === "SEARCH") {
      tagValue = ["mark_name", "created_date"];
    } else if (product === "WATCH") {
      let oldTags = [
        "report_name",
        "journal_number",
        "journal_date",
        "opposition",
        "current_date",
        "your_mark_image",
        "threat_mark_image",
        "your_mark",
        "your_application",
        "your_DOA",
        "your_DOU",
        "your_class",
        "your_proprietor",
        "your_address",
        "threat_mark",
        "threat_application",
        "threat_DOA",
        "threat_DOU",
        "threat_class",
        "threat_proprietor",
        "threat_address",
        "threat_description",
        "mark_description",
      ];

      tagValue = [
        "journal_number",
        "journal_date",
        "last_date_of_filing_opposition",
        "current_date",
        "your_trademark_name",
        "your_trademark_images",
        "your_application_number",
        "your_date_of_application",
        "your_date_of_usage",
        "your_trademark_classes",
        "your_proprietor_names",
        "your_proprietor_addresses",
        "your_proprietor_trading_as",
        "your_g_and_s_description",
        "your_attorney_name",
        "your_attorney_address",
        "tags",
        "threat_trademark_name",
        "threat_trademark_images",
        "threat_application_number",
        "threat_date_of_application",
        "threat_date_of_usage",
        "threat_trademark_classes",
        "threat_proprietor_names",
        "threat_proprietor_addresses",
        "threat_proprietor_trading_as",
        "threat_g_and_s_description",
        "threat_attorney_name",
        "threat_attorney_address",
        "your_formatted_proprietor_addresses",
        "threat_formatted_proprietor_addresses",
        "latest_date_of_examination_report",
      ];
      tagValue.push(...oldTags);
    }
    let subjectText = data.subject.toString();
    let contentText = data.content.toString();
    const templateInjectData = this.props.emailTemplateData;

    if (templateInjectData !== null) {
      tagValue.forEach((data) => {
        if (
          data === "your_trademark_images" ||
          data === "threat_trademark_images" ||
          data === "trademark_images"
        ) {
          let image = "";
          if (templateInjectData[data]?.length > 0) {
            templateInjectData[data].forEach(
              (imgUrl) =>
                (image =
                  image +
                  `<img src=${imgUrl} style="height:50px; width:50px;margin: 0px 12px ;" alt="No Image"/>`),
            );
          } else {
            image = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, image);
        } else if (data === "tags") {
          let tags = "";
          if (templateInjectData[data]?.length > 0) {
            templateInjectData[data].forEach((tag, index) => {
              if (index !== templateInjectData[data].length - 1)
                tags = tags + `<span>${tag.name}</span>,`;
              else tags = tags + `<span>${tag.name}</span>`;
            });
          } else {
            tags = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, tags);
        } else if (
          data === "your_formatted_proprietor_addresses" ||
          data === "threat_formatted_proprietor_addresses" ||
          data === "formatted_proprietor_addresses"
        ) {
          let address = "";

          if (templateInjectData[data]?.length > 0) {
            templateInjectData[data].forEach(
              (tag) =>
                (address =
                  address +
                  `<div  style="
                        margin: 2px;
                        " >${tag}</div>`),
            );
          } else {
            address = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, address);
        } else {
          subjectText = subjectText.replaceAll(
            `@${data}`,
            `${templateInjectData[data]}`,
          );
          contentText = contentText.replaceAll(
            `@${data}`,
            `<span>${templateInjectData[data]}</span>`,
          );
        }
      });
    } else {
      tagValue.forEach((data) => {
        if (
          data === "your_trademark_images" ||
          data === "threat_trademark_images" ||
          data === "trademark_images"
        ) {
          let image = "";
          if (report_tag[data]) {
            image =
              image +
              `<img src=${report_tag[data]} style="height:50px; width:50px;margin: 0px 12px ;" alt="No Image"/>`;
          } else {
            image = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, image);
        } else {
          subjectText = subjectText.replaceAll(
            `@${data}`,
            `${report_tag[data]}`,
          );
          contentText = contentText.replaceAll(
            `@${data}`,
            `<span>${report_tag[data]}</span>`,
          );
        }
      });
    }

    window.CKEDITOR.instances.editor.setData(contentText);

    this.setState({
      subject: subjectText,
      mail_body: contentText,
      select_doc_link: [],
      subject_error: false,
    });
  }

  handlePaginateTemplate(page) {
    this.props.getEmailTemplate({
      actionType: GET_EMAIL_TEMPLATE,
      product: this.state.product,
      subscription: `${this.state.subscription}`,
      sub_section: this.state.sub_section,
      // contains_term:searchValue,
      page: page,
    });
  }
  handleSearchTemplate(searchValue) {
    this.props.getEmailTemplate({
      actionType: GET_EMAIL_TEMPLATE,
      product: this.state.product,
      subscription: `${this.state.subscription}`,
      contains_term: searchValue,
      sub_section: this.state.sub_section,
    });

    // this.setState({
    //   searchValue,
    //   // page: {
    //   //   ...this.state.page,
    //   //   [selectValue]: 1,
    //   // },
    // });
  }
  // handleUploadFile(file){
  //     const isLt5M = ((file.size / (1024 * 1024)) < 5);
  //     if (isLt5M) {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(file)
  //         reader.onloadend = () => {
  //             this.setState({
  //                 file_attachment: {
  //                     file: file,
  //                     fileName: file.name,
  //                     filePreviewUrl: reader.result,
  //                 },
  //             });
  //         }
  //     } else {
  //         message.error('File size must smaller than 5MB!');
  //     }
  // }

  handleUploadMultipleFile(files) {
    let sum = 0;
    sum = Array.from(files).reduce(function (accumulator, current_file) {
      return accumulator + current_file.size;
    }, 0);

    const isLt5M = sum / (1024 * 1024) < 5;
    if (isLt5M) {
      this.setState({
        multiple_files: files,
      });
    } else {
      message.error("File size must smaller than 5MB!");
    }
  }
  removeFileUpload() {
    document.getElementById("files").value = null;
    this.setState({
      file_attachment: {
        file: "",
        fileName: "",
        filePreviewUrl: "",
      },
    });
  }

  handleDocument(name, url) {
    if (this.state.mail_body.includes(`<p><a href="${url}">${name}</a></p>`)) {
      window.CKEDITOR.instances.editor.setData(
        this.state.mail_body.replaceAll(
          `<p><a href="${url}">${name}</a></p>`,
          "",
        ),
      );
      this.setState({
        mail_body: this.state.mail_body.replaceAll(
          `<p><a href="${url}">${name}</a></p>`,
          "",
        ),
      });
    } else {
      this.setState({
        mail_body: this.state.mail_body + `<p><a href="${url}">${name}</a></p>`,
      });
      window.CKEDITOR.instances.editor.setData(
        this.state.mail_body + `<p><a href="${url}">${name}</a></p>`,
      );
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {
      send_from: sendFrom,
      subject,
      tags,
      Tags,
      mail_body,
      file_attachment,
      attachment,
      multiple_files,
    } = this.state;
    let send_from = sendFrom;
    let sendFromElement = document.getElementById("send_from_input");
    let sendFromElementValue = sendFromElement?.value;
    if (send_from.length <= 0 && sendFromElementValue.length > 0) {
      send_from = sendFromElementValue;
    }
    let sendToElement = document.getElementById("send_to_input");
    let subjectElement = document.getElementById("subject_input");
    if (send_from.length > 0) {
      sendFromElement.classList.remove("email_error_input");
      this.setState({
        send_from_error: false,
      });
    } else {
      // sendFromElement.classList.add("email_error_input");
      this.setState({
        send_from_error: true,
      });
    }
    if (tags.send_to.length > 0) {
      sendToElement.classList.remove("email_error_input");
      this.setState({
        send_to_error: false,
      });
    } else {
      // sendToElement.classList.add("email_error_input");
      this.setState({
        send_to_error: true,
      });
    }
    if (subject.length > 0) {
      subjectElement.classList.remove("email_error_input");
      this.setState({
        subject_error: false,
      });
    } else {
      // subjectElement.classList.add("email_error_input");
      this.setState({
        subject_error: true,
      });
    }

    if (send_from.length > 0 && subject.length > 0 && tags.send_to.length > 0) {
      //
      if (this.props.managerEmail) {
        await this.props.sendManagerEmail({
          send_from: checkValue(send_from) ? send_from : "",
          send_to: checkValue(Tags.Send_To) ? `${Tags.Send_To}` : "",
          cc: checkValue(Tags.CC) ? `${Tags.CC}` : "",
          mail_subject: checkValue(subject) ? subject : "",
          mail_body: checkValue(mail_body) ? mail_body : "",
          source: checkValue(this.props.source) ? this.props.source : "",
          source_id: checkValue(this.props.sourceId) ? this.props.sourceId : "",
          // file_attachment: checkValue(file_attachment) ? file_attachment : '',
          attachment: checkValue(attachment) ? attachment : [],
          multiple_files: multiple_files,
        });
        message[this.props.sendManagerEmailStatus.toLowerCase()](
          this.props.sendManagerEmailMessage,
        );
      } else {
        await this.props.sendCustomEmail({
          send_from: checkValue(send_from) ? send_from : "",
          send_to: checkValue(Tags.Send_To) ? `${Tags.Send_To}` : "",
          cc: checkValue(Tags.CC) ? `${Tags.CC}` : "",
          mail_subject: checkValue(subject) ? subject : "",
          mail_body: checkValue(mail_body) ? mail_body : "",
          // file_attachment: checkValue(file_attachment) ? file_attachment : '',
          attachment: checkValue(attachment) ? attachment : [],
          multiple_files: multiple_files,
        });
        message[this.props.sendEmailStatus.toLowerCase()](
          this.props.sendEmailMessage,
        );
      }
      this.setState({
        open: false,
        selectValue: "",
        searchValue: {
          send_to: "",
          cc: "",
        },
        tags: {
          ...this.state.tags,
          send_to: checkValue(this.props.userClientEmail)
            ? this.props?.userClientEmail?.associated_client_emails?.map(
                (eachmail) => `${eachmail.id}/${eachmail.email}`,
              )
            : [],
          cc: checkValue(this.props.userClientEmail)
            ? this.props?.userClientEmail?.team_emails?.map(
                (eachmail) => `${eachmail.id}/${eachmail.email}`,
              )
            : [],
        },
        Tags: {
          ...this.state.Tags,
          Send_To: checkValue(this.props.userClientEmail)
            ? this.props?.userClientEmail?.associated_client_emails?.map(
                (eachmail) => eachmail.email,
              )
            : [],
          CC: checkValue(this.props.userClientEmail)
            ? this.props?.userClientEmail?.team_emails?.map(
                (eachmail) => eachmail.email,
              )
            : [],
        },
        page: {
          send_to: 1,
          cc: 1,
        },
        send_from_error: false,
        send_to_error: false,
        subject_error: false,
        multiple_files: [],
      });
    }
  }

  async handleDownloadLetter(event) {
    event.preventDefault();
    const { mail_body, add_journal_copy, letter_head_visible } = this.state;
    await this.props.downloadLetterHead({
      content: mail_body,
      add_journal_copy: add_journal_copy,
      letter_head_visible: letter_head_visible,
      file_name: this.state.file_name,
      journal_number: this.state?.report_tag?.journal_number,
      application_number: this.state?.report_tag?.threat_application,
    });
  }

  handleClose() {
    this.setState({
      subject: "",
      mail_body: "<p>Dear Sir/Ma'am,<p><strong>Regards</strong></p></p>",
      selectedKeys: [],
      attachment: [],
      report_tag: [],
      file_attachment: {
        file: "",
        fileName: "",
        filePreviewUrl: "",
      },
      page: {
        send_to: 1,
        cc: 1,
      },
      cc_target_data: [],
      send_target_data: [],
      select_doc_link: [],
      send_from_error: false,
      send_to_error: false,
      subject_error: false,
      activeKey: null,
      selectedKey: null,
    });
    window.CKEDITOR.instances.editor.setData(
      "<p>Dear Sir/Ma'am,<p><strong>Regards</strong></p></p>",
    );
    this.props.clearEmailTemplate();
    this.props.onCloseModal();
  }

  handleCollapse = (key) => {
    this.setState({ activeKey: key });
  };
  handleSelected = (key) => {
    this.setState({ selectedKey: key });
  };

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    const {
      sendEmailStatus,
      documentListStatus,
      documentList,
      userClientEmail,
      userClientEmailStatus,
      sendManagerEmailStatus,
    } = this.props;

    let data_cc;
    let data_send_to;

    data_send_to = this.props?.userClientEmail?.associated_client_emails.concat(
      this.state.send_target_data,
    );

    data_send_to = data_send_to?.concat(userClientEmail?.client_emails);

    data_send_to = _.uniqWith(data_send_to, _.isEqual);

    data_cc = userClientEmail?.team_emails?.concat(this.state.cc_target_data);
    data_cc = data_cc?.concat(userClientEmail?.client_emails);

    data_cc = _.uniqWith(data_cc, _.isEqual);

    let dataSource_sendTo;
    let dataSource_cc;

    let no_data_Menu = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Data</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let attachedDocMenu = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Document Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let popovercontent = (
      <>
        {" "}
        <span>
          {this.state.file_name_error && (
            <div
              style={{
                color: "red",
                fontSize: "12px",
                margin: "0px 16px 4px 16px",
              }}
            >
              File Name cannot contain /
            </div>
          )}
          <Input
            value={this.state.file_name}
            prefix={<FileTextOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="File Name"
            style={{
              margin: "0px 16px",
              width: "200px",
            }}
            onChange={(e) => {
              if (e?.target?.value.includes("/")) {
                this.setState({ file_name_error: true });
              } else {
                this.setState({ file_name_error: false });
                this.setState({ file_name: e?.target?.value });
              }
            }}
          />
        </span>
        <Tooltip placement="topLeft" title="Attach Letterhead">
          <Checkbox
            checked={this.state?.letter_head_visible}
            onChange={(e) => {
              this.setState({ letter_head_visible: e?.target?.checked });
            }}
          ></Checkbox>
        </Tooltip>
        {(this.props?.subscription?.includes("JOURNAL WATCH") ||
          this.props?.subscription?.includes("IMAGE_RECOGNITION")) && (
          <Tooltip placement="topLeft" title="Add Journal Copy">
            <Checkbox
              checked={this.state?.add_journal_copy}
              onChange={(e) => {
                this.setState({ add_journal_copy: e?.target?.checked });
              }}
            ></Checkbox>
          </Tooltip>
        )}
        <Button
          type="dashed"
          key="submit"
          htmlType="submit"
          onClick={(event) => this.handleDownloadLetter(event)}
          style={{ margin: "0px 10px" }}
          loading={
            sendEmailStatus === LOADING || sendManagerEmailStatus === LOADING
          }
        >
          <i
            className="fa fa-download"
            style={{ padding: "0px 5px" }}
            aria-hidden="true"
          ></i>
        </Button>
      </>
    );
    if (userClientEmailStatus === SUCCESS) {
      dataSource_sendTo =
        userClientEmail &&
        data_send_to?.map((eachmail) => (
          <Option
            key={`send_to-${eachmail?.id}`}
            id={`send_to-${eachmail?.id}`}
            value={`${eachmail?.id}/${eachmail?.email}`}
            label={eachmail?.email}
          >
            {eachmail?.email}
            {!eachmail?.person_name ? "" : ` - ${eachmail?.person_name}`}
            {!eachmail?.company_name ? "" : ` - ${eachmail?.company_name}`}
          </Option>
        ));
    }

    if (userClientEmailStatus === SUCCESS) {
      dataSource_cc =
        userClientEmail &&
        data_cc?.map((eachmail) => (
          <Option
            key={`send_to-${eachmail?.id}`}
            id={`send_to-${eachmail?.id}`}
            value={`${eachmail?.id}/${eachmail?.email}`}
            label={eachmail?.email}
          >
            {eachmail?.email}
            {!eachmail?.person_name ? "" : ` - ${eachmail?.person_name}`}
            {!eachmail?.company_name ? "" : ` - ${eachmail?.company_name}`}
          </Option>
        ));
    }

    if (documentListStatus === SUCCESS) {
      let document = documentList && documentList.documents;
      let document1,
        document2,
        document3,
        document4,
        document5 = [];

      // opposition_documents
      if (
        document.opposition_documents &&
        document.opposition_documents.length > 0
      ) {
        document1 = document.opposition_documents.map((doc) => ({
          name: doc.description,
          url: doc.file ? doc.file : doc.url ? doc.url : "No Document",
        }));
      }

      // opposition_correspondence_notices
      if (
        document.opposition_correspondence_notices &&
        document.opposition_correspondence_notices.length > 0
      ) {
        document2 = document.opposition_correspondence_notices.map((doc) => ({
          name: doc.subject,
          url: doc.file ? doc.file : doc.url ? doc.url : "No Document",
        }));
      }

      // correspondence_notices
      if (
        document.correspondence_notices &&
        document.correspondence_notices.length > 0
      ) {
        document3 = document.correspondence_notices.map((doc) => ({
          name: doc.subject,
          url: doc.file ? doc.file : doc.url ? doc.url : "No Document",
        }));
      }

      // documents
      if (document.documents && document.documents.length > 0) {
        document4 = document.documents.map((doc) => ({
          name: doc.description,
          url: doc.file ? doc.file : doc.url ? doc.url : "No Document",
        }));
      }

      // examination_report_documents
      if (
        document.examination_report_documents &&
        document.examination_report_documents.length > 0
      ) {
        document5 = document.examination_report_documents.map((doc) => ({
          name: "Examination Report",
          url: doc.url
            ? doc.url.includes("http://")
              ? doc.url
              : `https://`.concat(doc.url)
            : "No Document",
        }));
      }

      const documents_attached = Array.prototype.concat.apply(
        [],
        [document1, document2, document3, document4, document5],
      );
      const attached_doc = checkValue(documents_attached)
        ? compact(uniqWith(documents_attached, isEqual))
        : [];

      if (attached_doc && attached_doc.length > 0) {
        attachedDocMenu = (
          <Menu
            selectable={true}
            multiple={true}
            selectedKeys={this.state.select_doc_link}
            onSelect={(event) =>
              this.changeStateValue_L0("select_doc_link", event.selectedKeys)
            }
            onDeselect={(event) =>
              this.changeStateValue_L0("select_doc_link", event.selectedKeys)
            }
          >
            {attached_doc &&
              attached_doc.map((docs) => (
                <Menu.Item
                  key={docs.url}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    borderBottom: "1px solid #ddd",
                  }}
                  className="menu-items-list"
                >
                  <div onClick={() => this.handleDocument(docs.name, docs.url)}>
                    {docs.name}
                  </div>
                </Menu.Item>
              ))}
          </Menu>
        );
      }
    }

    return (
      <Modal
        open={this.props.visible}
        onOk={this.props.onCloseModal}
        onCancel={this.props.onCloseModal}
        closable={false}
        footer={false}
        maskClosable={false}
        className="custom-send-email-modal"
        bodyStyle={{
          borderRadius: "24px",
        }}
        title={null}
      >
        <Row className="custom-send-email">
          <Col
            xs={{ span: 24 }}
            lg={this.props.core ? { span: 24 } : { span: 14 }}
            className="custom-send-email-form"
          >
            <Title level={4}>Send Email</Title>

            <div
              style={{
                minHeight: "70vh",
              }}
            >
              <Row
                style={{
                  margin: "12px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col span={3} className="custom-send-email-form">
                  <Text strong>From:</Text>
                </Col>
                <Col span={20}>
                  <Row span={20}>
                    {!this.state.send_from?.length > 0 &&
                      this.state.send_from_error && (
                        <Text style={{ color: "red", fontStyle: "italic" }}>
                          Please enter email address
                        </Text>
                      )}
                  </Row>
                  <Input
                    autoFocus={true}
                    id="send_from_input"
                    type="email"
                    defaultValue={
                      checkValue(this.props.sendFrom) ? this.props.sendFrom : ""
                    }
                    onChange={(event) => this.handleChange(event, "send_from")}
                  />
                </Col>
              </Row>

              <Row
                style={{
                  margin: "12px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col span={3} className="custom-send-email-form">
                  <Text strong>To:</Text>
                </Col>
                <Col span={20}>
                  <Row span={20}>
                    {this.state.send_to_error && (
                      <Text style={{ color: "red", fontStyle: "italic" }}>
                        Please enter email address
                      </Text>
                    )}
                  </Row>
                  <Select
                    id="send_to_input"
                    value={this.state?.tags?.send_to}
                    mode="multiple"
                    defaultValue={this.state?.tags?.send_to}
                    style={{ width: "100%", scrollBehavior: "smooth" }}
                    placeholder="Select or search email"
                    key={`MultiSelect${this.state.tags?.send_to?.length}`}
                    ref={this.autoRef}
                    autoClearSearchValue={true}
                    dataSource={dataSource_sendTo}
                    onInputKeyDown={(event) =>
                      this.handleKeyPress(event, "send_to")
                    }
                    onSearch={(value) => this.handleSearch(value, "send_to")}
                    onSelect={(value) => this.handleSelect(value, "send_to")}
                    onDeselect={(value) =>
                      this.handleDeselect(value, "send_to")
                    }
                    onDropdownVisibleChange={(open) =>
                      this.onDropdownVisibleChange(open, "send_to")
                    }
                    onPopupScroll={(event) =>
                      this.handleLoadMore(event, "send_to")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    optionLabelProp="label"
                    optionFilterProp="children"
                    showSearch
                    notFoundContent={
                      this.props.userClientEmailMoreStatus === LOADING ||
                      this.props.userClientEmailStatus === LOADING ? (
                        <Spin size="small" />
                      ) : (
                        no_data_Menu
                      )
                    }
                    loading={
                      this.props.userClientEmailMoreStatus === LOADING ||
                      this.props.userClientEmailStatus === LOADING
                        ? true
                        : false
                    }
                  >
                    {dataSource_sendTo}
                  </Select>
                </Col>
              </Row>

              <Row
                style={{
                  margin: "12px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col span={3} className="custom-send-email-form">
                  <Text strong>CC:</Text>
                </Col>
                <Col span={20}>
                  <Select
                    value={this.state?.tags?.cc}
                    mode="multiple"
                    defaultValue={this.state?.tags?.cc}
                    placeholder="Select or search email"
                    style={{ width: "100%", scrollBehavior: "smooth" }}
                    key={`Multiselect${this.state.tags?.cc?.length}`}
                    ref={this.autoRef}
                    backfill={true}
                    autoFocus={true}
                    autoClearSearchValue={true}
                    dataSource={dataSource_cc}
                    onInputKeyDown={(event) => this.handleKeyPress(event, "cc")}
                    onSearch={(value) => this.handleSearch(value, "cc")}
                    onSelect={(value) => this.handleSelect(value, "cc")}
                    onDeselect={(value) => this.handleDeselect(value, "cc")}
                    onDropdownVisibleChange={(open) =>
                      this.onDropdownVisibleChange(open, "cc")
                    }
                    onPopupScroll={(event) => this.handleLoadMore(event, "cc")}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    optionLabelProp="label"
                    optionFilterProp="children"
                    showSearch={true}
                    notFoundContent={
                      this.props.userClientEmailMoreStatus === LOADING ||
                      this.props.userClientEmailStatus === LOADING ? (
                        <Spin size="small" />
                      ) : (
                        no_data_Menu
                      )
                    }
                    loading={
                      this.props.userClientEmailMoreStatus === LOADING ||
                      this.props.userClientEmailStatus === LOADING
                        ? true
                        : false
                    }
                  >
                    {dataSource_cc}
                  </Select>
                </Col>
              </Row>

              <Row
                style={{
                  margin: "12px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col span={3} className="custom-send-email-form">
                  <Text strong>Subject:</Text>
                </Col>

                <Col span={20}>
                  <Row span={20}>
                    {!this.state.subject_error?.length > 0 &&
                      this.state.subject_error && (
                        <Text style={{ color: "red", fontStyle: "italic" }}>
                          Please enter Subject
                        </Text>
                      )}
                  </Row>
                  <Input
                    id="subject_input"
                    // prefix={<Text strong style={{ color: 'rgba(0,0,0,.25)' }}>Subject</Text>}
                    type="text"
                    className="input-box subject_box"
                    value={this.state.subject}
                    onChange={(event) => this.handleChange(event, "subject")}
                  />
                </Col>
              </Row>

              <Row
                style={{
                  margin: "12px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col span={3} className="custom-send-email-form">
                  <Text strong>Attach:</Text>
                </Col>
                <Col span={20}>
                  <Row>
                    {/* <Col className="ml-center-flex" span={12}> */}
                    <span>
                      <Dropdown
                        dropdownRender={() => attachedDocMenu}
                        overlayClassName="filter-menu"
                        autoAdjustOverflow
                        onOpenChange={(flag) =>
                          this.handleOpenChange(flag, "attachedDocMenu")
                        }
                        open={this.state.open === "attachedDocMenu"}
                      >
                        <Button
                          type={
                            this.state.select_doc_link?.length > 0
                              ? "primary"
                              : "default"
                          }
                          ghost={this.state.select_doc_link?.length > 0}
                        >
                          {/* <Dropdown overlay={attachedDocMenu}>
                        <Button
                          type={
                            this.state.select_doc_link?.length > 0
                              ? "primary"
                              : "default"
                          }
                          ghost={this.state.select_doc_link?.length > 0}
                        > */}
                          <div className="ml-center-flex">
                            <div
                              style={{
                                marginRight: "4px",
                              }}
                            >
                              Select IP India Document
                            </div>
                            <DownOutlined />
                          </div>
                        </Button>
                      </Dropdown>
                    </span>
                    <span
                      style={{
                        margin: "0px 12px",
                      }}
                    >
                      or
                    </span>
                    {/* </Col> */}

                    {/* <Col span={8}> */}

                    <span style={{ width: "50%" }} className="alignL">
                      {/* <Button type="dashed" > */}

                      <label
                        style={{
                          border: "1px solid #00000024",
                          display: "inline-block",
                          padding: "6px 12px",
                          cursor: "pointer",
                          borderRadius: "6px",
                          fontWeight: "normal",
                        }}
                      >
                        <input
                          type="file"
                          id="files"
                          name="files"
                          accept="image/*, .pdf, .xlsx, .xls, .doc, .docx"
                          onChange={(event) =>
                            this.handleUploadMultipleFile(event.target.files)
                          }
                          multiple
                          style={{ display: "none", width: "40%" }}
                        />{" "}
                        {this.state.multiple_files?.length <= 0 ? (
                          <div className="ml-center-flex">
                            <div
                              style={{
                                marginRight: "8px",
                              }}
                            >
                              Upload Document
                            </div>
                            <PlusOutlined />
                          </div>
                        ) : (
                          <div className="ml-center-flex">
                            <div
                              style={{
                                marginRight: "8px",
                              }}
                            >
                              {this.state.multiple_files?.length === 1
                                ? this.state.multiple_files[0]?.name
                                : `${this.state.multiple_files?.length} Files`}
                            </div>
                            <PlusOutlined />
                          </div>
                        )}
                      </label>
                      {/* </Button> */}
                      <RestFilled
                        style={{
                          margin: " 0px 4px",
                          fontSize: 20,
                          color: "#f81d22",
                          cursor: "pointer",
                          visibility: `${
                            this.state.multiple_files?.length
                              ? "visible"
                              : "hidden"
                          }`,
                        }}
                        onClick={() => this.removeFileUpload()}
                      />
                    </span>
                    {/* </Col> */}
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  margin: "12px 0px",
                  display: "flex",
                }}
              >
                <Col span={3} className="custom-send-email-form">
                  <Text strong>Subject:</Text>
                </Col>
                <Col span={20}>
                  {/* <div className="custom-send-email-Input-Field"> */}
                  <div className="text-editor">
                    <CKEditor
                      initData={this.state.mail_body}
                      name="editor"
                      onChange={this.onEditorChange}
                      editorUrl={
                        "https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
                      }
                      config={{
                        allowedContent: true,
                        extraAllowedContent: "div(*)",
                        extraPlugins: ["editorplaceholder"],
                        editorplaceholder: "Start typing here...",
                        toolbarGroups: [
                          { name: "basicstyles", groups: ["basicstyles"] },
                          { name: "paragraph", groups: ["list", "align"] },
                          { name: "links" },
                          { name: "insert", groups: ["table"] },
                          { name: "styles" },
                          { name: "links" },
                        ],
                        removeButtons:
                          "Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar,PasteFromWord",
                        autoGrow_onStartup: true,
                        height: "28vh",
                      }}
                    />
                  </div>
                  {/* </div> */}
                </Col>
              </Row>
            </div>
            <Row
              style={{
                margin: "12px 0px",
              }}
            >
              <Col span={23} className="alignR">
                <Popover content={popovercontent} title="Download As PDF">
                  <Button
                    type="dashed"
                    key="submit"
                    htmlType="submit"
                    loading={
                      sendEmailStatus === LOADING ||
                      sendManagerEmailStatus === LOADING
                    }
                  >
                    <i
                      className="fa fa-download"
                      style={{ padding: "0px 5px" }}
                      aria-hidden="true"
                    ></i>
                    Download As PDF
                  </Button>
                </Popover>

                <Button
                  type="primary"
                  key="submit"
                  htmlType="submit"
                  onClick={(event) => this.handleSubmit(event)}
                  style={{ margin: "0px 10px" }}
                  loading={
                    sendEmailStatus === LOADING ||
                    sendManagerEmailStatus === LOADING
                  }
                >
                  Send Email
                </Button>
                {this.props.core && (
                  <Button onClick={() => this.handleClose()}>Close</Button>
                )}
              </Col>
            </Row>
          </Col>
          {!this.props.core && (
            <Col
              xs={{ span: 24 }}
              lg={{ span: 10 }}
              className="custom-send-email-form"
            >
              <Title level={4}>Use Email Template</Title>

              <Row>
                <EmailTemplateHandler
                  activeKey={this.state.activeKey}
                  selectedKey={this.state.selectedKey}
                  handleSelectTemplate={(data) =>
                    this.handleSelectTemplate(data)
                  }
                  handleSearchTemplate={(data) =>
                    this.handleSearchTemplate(data)
                  }
                  handlePaginateTemplate={(data) =>
                    this.handlePaginateTemplate(data)
                  }
                  handleSelected={(data) => this.handleSelected(data)}
                  handleCollapse={(data) => this.handleCollapse(data)}
                  count={this.props.templateCount}
                />
              </Row>
              <Row
                style={{
                  margin: "12px 0px",
                }}
              >
                <Col span={24} className="alignR">
                  <Button onClick={() => this.handleClose()}>Close</Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  sendCustomEmail,
  getEmailTemplate,
  getUserClientEmail,
  getEmailDocument,
  sendManagerEmail,

  downloadLetterHead,
  clearEmailTemplate,
};

function mapStateToProps(state) {
  const {
    sendEmailMessage,
    sendEmailStatus,
    clientEmail,
    clientEmailCount,
    clientEmailStatus,
    userClientEmailMoreStatus,
    clientEmailCC,
    clientEmailCCCount,
    clientEmailCCStatus,
    clientEmailCCMoreStatus,
    userClientEmail,
    userClientEmailStatus,
    documentList,
    documentListStatus,
    downloadContentStatus,
  } = state.sendEmailState;
  const { emailTemplate, emailTemplateStatus, templateCount } =
    state.emailTemplateState;

  const { sendManagerEmailMessage, sendManagerEmailStatus } =
    state.managerState.managerEmailState;

  const { emailTemplateData, emailTemplateDataStatus } =
    state.emailTemplateDataState;
  return {
    sendEmailMessage,
    sendEmailStatus,
    clientEmail,
    clientEmailCount,
    clientEmailStatus,
    userClientEmailMoreStatus,
    clientEmailCC,
    clientEmailCCCount,
    clientEmailCCStatus,
    clientEmailCCMoreStatus,

    documentList,
    documentListStatus,

    emailTemplate,
    emailTemplateStatus,
    templateCount,

    userClientEmail,
    userClientEmailStatus,
    sendManagerEmailMessage,
    sendManagerEmailStatus,
    downloadContentStatus,

    emailTemplateData,
    emailTemplateDataStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailNew);
