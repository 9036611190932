//--------IP-SUITE TYPES--------
import {
    GET_SIGN_IN_ALERT, GET_SEARCH_ALERT, GET_WATCH_ALERT, GET_MANAGER_ALERT
} from "../actions/commonActions/types";

const defaultAlertMessagesState = {
    signIn: "",
    search: "",
    watch: "",
    manager: ""
}

export default function(state = defaultAlertMessagesState, action){
    switch (action.type) {
        case GET_SIGN_IN_ALERT:
            return {
                ...state,
                signIn: (action.payload.message? action.payload.message: "")
            };

        case GET_SEARCH_ALERT:
            return {
                ...state,
                search: (action.payload.message? action.payload.message: "")
            };

        case GET_WATCH_ALERT:
            return {
                ...state,
                watch: (action.payload.message? action.payload.message: "")
            };

        case GET_MANAGER_ALERT:
            return {
                ...state,
                manager: (action.payload.message? action.payload.message: "")
            };

        default:
            return state;
    }
}