//--------LIST LAYOUT TYPES--------
import {
  W_CHANGE_LIST_VIEW,
  W_INSERT_SAMPLE_DATA,
  W_LIST_GET_BOOKMARK,
  W_LIST_GET_DATA,
  W_LIST_PATCH_BOOKMARK,
} from "../../../actions/watchActions/types";

//--------COMMON IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "./../../../../constants/commonConstants/generalConstants";

import { finalReduxCachedState } from "../../../../functions/commonFunctions/finalCachedState";
import { W_COMPARE_MARKS } from "./../../../actions/watchActions/types";

const defaultListLayoutState = {
  tableData: [],
  isTableDataPresent: false,
  count: null,
  automatedReportsLinks: null,
  journalNumber: {
    image_label: null,
    image_text: null,
    tm_applied: null,
    tm_protect: null,
    journals: null,
    brand_compare: null,
    image_text_new: null,
    image_similarity: null,
  },
  pageNumber: 1,
  status: null,
  report_columns: [],
  bookmarkTemplate: null,
  bookmarkStatus: null,
  combinePdf: {
    loading: false,
  },
  compareMarks: {
    key: "compare_marks",
    menu_label: "Trademark",
    searchValue: "",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
  },
  listLayoutView: "",
};

const newDefaultConfig = {
  list_layout: {
    image_label: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    image_text: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    image_text_new: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    tm_protect: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    tm_applied: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    journals: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    brand_compare: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    image_similarity: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
  },
  grid_layout: {
    image_label: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    image_text: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    image_text_new: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    tm_protect: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    tm_applied: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    journals: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
    image_similarity: {
      config: defaultListLayoutState,
      isComponentRendered: false,
    },
  },
};

const generateUniqueArrayOfObject = (arrayOfObjects) => {
  const uniqueArray = [];
  const seenValues = new Set();

  for (const obj of arrayOfObjects) {
    const value = obj.value;
    if (!seenValues.has(value)) {
      uniqueArray.push(obj);
      seenValues.add(value);
    }
  }
  return uniqueArray;
};

export default function (state = newDefaultConfig, action) {
  switch (action.type) {
    case W_LIST_GET_DATA: {
      // const layout = window.location.pathname.split("/")[3];
      // const urlKey = window.location.pathname.split("/")[2];
      const layout = action.layout;
      const urlKey = action.urlKey;
      switch (action.status) {
        case SUCCESS:
          if (action.pageNumber > 1) {
            const newSuccessState = {
              tableData: action.payload.data,
              isTableDataPresent: action.payload.data.length ? true : false,
              count: action.payload.metadata.count,
              pageNumber: action.pageNumber,
              status: action.status,
              report_columns: action.payload.metadata.report_columns,
              journalNumber: {
                ...state[layout][urlKey].journalNumber,
                [`${urlKey}`]: action.journalNumber,
              },
            };
            return finalReduxCachedState(
              state,
              layout,
              urlKey,
              newSuccessState,
              "isComponentRendered",
              true,
            );
          } else {
            const newSuccessState = {
              tableData: action.payload.data,
              isTableDataPresent: action.payload.data.length ? true : false,
              count: action.payload.metadata.count,
              automatedReportsLinks: {
                ...state[layout][urlKey].automatedReportsLinks,
                all_class_watch_excel_report_url:
                  action.payload.metadata.all_class_watch_excel_report_url,
                all_class_watch_pdf_report_url:
                  action.payload.metadata.all_class_watch_pdf_report_url,
                same_class_watch_excel_report_url:
                  action.payload.metadata.same_class_watch_excel_report_url,
                same_class_watch_pdf_report_url:
                  action.payload.metadata.same_class_watch_pdf_report_url,
              },
              journalNumber: {
                ...state[layout][urlKey].journalNumber,
                [`${urlKey}`]: action.journalNumber,
              },
              pageNumber: action.pageNumber,
              status: action.status,
              report_columns: action.payload.metadata.report_columns,
            };
            return finalReduxCachedState(
              state,
              layout,
              urlKey,
              newSuccessState,
              "isComponentRendered",
              true,
            );
          }

        case LOADING:
          const newLoadingState = {
            // ...(action.journalChange
            //   ? defaultListLayoutState
            //   : state[layout][urlKey]),
            status: action.status,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isComponentRendered",
            false,
          );

        case ERROR:
          const newErrorState = {
            status: action.status,
            count: 0,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isComponentRendered",
            false,
          );

        default:
          return state;
      }
    }
    case W_LIST_GET_BOOKMARK: {
      // const layout = window.location.pathname.split("/")[3];
      // const urlKey = window.location.pathname.split("/")[2];
      const layout = action.layout;
      const urlKey = action.urlKey;
      switch (action.status) {
        case SUCCESS:
          const newSuccessState = {
            bookmarkTemplate:
              action.payload.data?.length &&
              action.payload.data[0].template_json
                ? action.payload.data[0].template_json
                : null,
            bookmarkStatus: action.status,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newSuccessState,
            "isBookmarkRendered",
            true,
          );

        case LOADING:
          const newLoadingState = {
            // ...(action.journalChange ? defaultListLayoutState : state),
            bookmarkStatus: action.status,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isBookmarkRendered",
            false,
          );

        case ERROR:
          const newErrorState = {
            bookmarkStatus: action.status,
            count: 0,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isBookmarkRendered",
            false,
          );
        default:
          return state;
      }
    }
    case W_LIST_PATCH_BOOKMARK: {
      // const layout = window.location.pathname.split("/")[3];
      // const urlKey = window.location.pathname.split("/")[2];
      const layout = action.layout;
      const urlKey = action.urlKey;
      switch (action.status) {
        case SUCCESS:
          const newSuccessState = {
            bookmarkTemplate: action.payload.data.template_json,
            bookmarkStatus: action.status,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newSuccessState,
            "isBookmarkRendered",
            true,
          );

        case LOADING:
          const newLoadingState = {
            // ...(action.journalChange ? defaultListLayoutState : state),
            bookmarkStatus: action.status,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isBookmarkRendered",
            false,
          );

        case ERROR:
          const newErrorState = {
            bookmarkStatus: action.status,
            count: 0,
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isBookmarkRendered",
            false,
          );

        default:
          return state;
      }
    }

    case W_COMPARE_MARKS: {
      const layout = action.layout;
      const urlKey = action.urlKey;
      switch (action.status) {
        case SUCCESS:
          if (action.page > 1) {
            const newSuccessState = {
              compareMarks: {
                ...state?.[layout]?.[urlKey]?.config?.compareMarks,
                data: generateUniqueArrayOfObject([
                  ...state?.[layout]?.[urlKey]?.config?.compareMarks?.data,
                  ...action.payload.data,
                ]),
                metadata: {
                  count: action.payload.count,
                  page: action.page,
                },
                loading: false,
              },
            };
            return finalReduxCachedState(
              state,
              layout,
              urlKey,
              newSuccessState,
              "isCompareMarksRendered",
              true,
            );
          } else {
            const newSuccessState = {
              compareMarks: {
                ...state?.[layout]?.[urlKey]?.config?.compareMarks,
                loading: false,
                data: action.payload.data,
                metadata: {
                  count: action.payload.count,
                  page: action.page,
                },
              },
            };
            return finalReduxCachedState(
              state,
              layout,
              urlKey,
              newSuccessState,
              "isCompareMarksRendered",
              true,
            );
          }
        case LOADING:
          const newLoadingState = {
            compareMarks: {
              ...state?.[layout]?.[urlKey]?.config?.compareMarks,
              loading: true,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isCompareMarksRendered",
            false,
          );

        case ERROR:
          const newErrorState = {
            ...state?.[layout]?.[urlKey]?.config?.compareMarks,
            loading: true,
            data: [],
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isCompareMarksRendered",
            false,
          );

        default:
          return state;
      }
    }

    case W_CHANGE_LIST_VIEW: {
      const layout = action.layout;
      const urlKey = action.urlKey;
      const newState = {
        listLayoutView: action.view,
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isListLayoutViewRendered",
        true,
      );
    }

    case W_INSERT_SAMPLE_DATA: {
      const layout = action.layout;
      const urlKey = action.urlKey;
      const newState = {
        tableData: action.sampleObject ? [action.sampleObject] : [],
        isTableDataPresent: false,
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isListLayoutViewRendered",
        true,
      );
    }

    default:
      return state;
  }
}
