import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";

import {
  CarryOutTwoTone,
  DownOutlined,
  MessageTwoTone,
  TagTwoTone,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Layout,
  Table,
  Menu,
  Dropdown,
  Input,
  Checkbox,
  Button,
  message,
  Switch,
  Typography,
  Tooltip,
  notification,
  Tabs,
  Radio,
  Spin,
  Badge,
  Alert,
} from "antd";

//--------ACTIONS--------
import {
  getTLAOpposition,
  getTLAOppositionIP,
  causeListHearings,
  ipIndiaHearings,
} from "../../../../redux/actions/managerActions/trademarkDashboard/actions";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------JOURNALS CONSTANTS--------
import { history } from "../../../../history";

import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import {
  changeStateValue_L0,
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../../../functions/commonFunctions/generalFunctions";
import {
  Hearing_DefaultSelectedFilters,
  Hearing_DefaultSearchFilters,
  Hearing_DefaultFilterConfig,
} from "../../../../constants/managerConstants/trademarkConstants";
import DateRangePicker from "../../../../functions/commonFunctions/dateRangePicker";
import CreateTask from "../../to-do-task/CreateTask";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { TM } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_TM_NOTES,
  PATCH_TM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import DownloadReportModal from "./DownloadHearingReportModal";
import { getEmailTemplateData } from "../../../../redux/actions/commonActions/actions";
// import { supportsHistory } from "history/DOMUtils";
import classNames from "classnames";
import FilterSearchBasic from "../../../commonComponents/FilterComponents/FilterSearchBasic";
import FilterSearchPaginate from "../../../commonComponents/FilterComponents/FilterSearchPaginate";
import { debounce } from "lodash";

const { Text, Title } = Typography;
const { Search } = Input;
const { Content } = Layout;
const { TabPane } = Tabs;

class UpcomingHearings extends Component {
  constructor(props) {
    super(props);
    const root = window.location.pathname.split("/").includes("your-trademark")
      ? "YOUR_TM"
      : "OPPOSED_TM";

    this.state = {
      open: null,
      root: checkValue(root) ? root : "YOUR_TM",
      management_trademark_id: "",
      proprietor_id: "",
      addTaskVisible: false,
      ipIdia: this.props.tla,
      visible: false,
      range: null,

      add_trademark_id: [],
      selectedRowKeys: [],
      selectAll: false,
      checkAll: false,

      pseudo_add_trademark_id: [],
      pseudo_selectedRowKeys: [],
      pseudo_selectAll: false,
      pseudo_checkAll: false,
      selectedFilters: {
        opposition: this.props.oppositionHearingLastAppliedFilters,
        tla: this.props.tlaHearingLastAppliedFilters,
      },

      searchFilters: {
        opposition: Hearing_DefaultSearchFilters,
        tla: Hearing_DefaultSearchFilters,
      },

      // isTla: this.props.tla,
      seeHearing: this.props.upcomingHearings,
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },

      ////
      hearingType: "opposition",
      isTla: false,
      subHearingType: "causeList",
      seeAllHearings: false,
      filterConfig: Hearing_DefaultFilterConfig,
      isSearching: false,
      noTagToggle: false,
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.setDate = this.setDate.bind(this);
  }

  fetchFilters = (required_filter, filter_contains_term, page) => {
    if (this.state.isTla) {
      if (this.state.subHearingType === "causeList") {
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          tla: true,
          filters_required_flag: true,
          required_filter: required_filter,
          filter_contains_term: filter_contains_term,
          pageNumber: page,
        });
      } else {
        setTimeout(() => {
          this.props.ipIndiaHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            filterFlag: false,
            tla: true,
            filters_required_flag: true,
            required_filter: required_filter,
            filter_contains_term: filter_contains_term,
            pageNumber: page,
          });
        }, 500);
      }
    } else {
      if (this.state.subHearingType === "causeList") {
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          tla: false,
          filters_required_flag: true,
          required_filter: required_filter,
          filter_contains_term: filter_contains_term,
          pageNumber: page,
        });
      } else {
        setTimeout(() => {
          this.props.ipIndiaHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            filterFlag: false,
            tla: false,
            filters_required_flag: true,
            required_filter: required_filter,
            filter_contains_term: filter_contains_term,
            pageNumber: page,
          });
        }, 500);
      }
    }
    this.setState({ isSearching: false });
  };

  handleSearchDebounced = debounce(function (
    required_filter,
    filter_contains_term,
  ) {
    this.fetchFilters(required_filter, filter_contains_term, 1);
  }, 500);

  onFilterSearch = (required_filter, filter_contains_term) => {
    this.setState({
      isSearching: true,
      filterConfig: {
        ...this.state?.filterConfig,
        [`${required_filter}`]: {
          ...this.state?.filterConfig[`${required_filter}`],
          filter_contains_term: filter_contains_term,
          page: 1,
        },
      },
    });

    this.handleSearchDebounced(required_filter, filter_contains_term);
  };
  onFilterPaginate = (required_filter, filter_contains_term, page) => {
    if (
      this.state.isTla
        ? this.props.tlaHearingFiltersStatus !== "LOADING"
        : this.props.oppositionHearingFiltersStatus !== "LOADING"
    ) {
      let count = 0;
      if (this.state.isTla) {
        switch (required_filter) {
          case "PROPRIETOR":
            count = this.props.tlaHearingProprietorsFiltersCount;
            break;
          case "HEARING_LOCATION":
            count = this.props.tlaHearingLocationFiltersCount;
            break;
          case "HEARING_OFFICERS":
            count = this.props.tlaHearingOfficersFiltersCount;
            break;
          case "TAG":
            count = this.props.tlaHearingTagFiltersCount;
            break;
          default:
            count = 0;
        }
      } else {
        switch (required_filter) {
          case "PROPRIETOR":
            count = this.props.oppositionHearingProprietorsFiltersCount;
            break;
          case "HEARING_LOCATION":
            count = this.props.oppositionHearingLocationFiltersCount;
            break;
          case "HEARING_OFFICERS":
            count = this.props.oppositionHearingOfficersFiltersCount;
            break;
          case "TAG":
            count = this.props.oppositionHearingTagFiltersCount;
            break;
          default:
            count = 0;
        }
      }
      const page_size = 100;
      let total_pages = 0;
      if (count !== undefined && count !== null && count > 0) {
        total_pages = Math.ceil(count / page_size);
      }
      if (page <= total_pages) {
        this.setState({
          filterConfig: {
            ...this.state?.filterConfig,
            [`${required_filter}`]: {
              ...this.state?.filterConfig[`${required_filter}`],
              page,
            },
          },
        });

        this.fetchFilters(required_filter, filter_contains_term, page);
      }
    }
  };

  componentDidMount() {
    this.setState({ seeHearing: false, isTla: false });

    if (localStorage.getItem("token")) {
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla,
        filters_required_flag: false,
      });

      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla,
        filters_required_flag: true,
        required_filter: "CLASSES",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla,
        filters_required_flag: true,
        required_filter: "PROPRIETOR",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla,
        filters_required_flag: true,
        required_filter: "HEARING_LOCATION",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla,
        filters_required_flag: true,
        required_filter: "HEARING_OFFICERS",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla,
        filters_required_flag: true,
        required_filter: "TAG",
        page: 1,
      });
    } else {
      this.props.logOut();
    }
  }

  handleTrademarkProfile(data) {
    if (data) {
      sessionStorage.setItem(
        "management_trademark_id",
        data.management_mark_id,
      );
      sessionStorage.setItem(
        "trademark_application_number",
        data.application_number,
      );
      window.open(
        "/manager/trademark/trademark-profile/" + data.management_mark_id,
        "_blank",
      );
      this.setState({
        management_trademark_id: data.management_mark_id,
      });
    }
  }

  handleProprietorProfile(propId) {
    if (propId) {
      sessionStorage.setItem("proprietor_id", propId);
      window.open("/manager/trademark/proprietor-profile/" + propId, "_blank");
      this.setState({
        proprietor_id: propId,
      });
    }
  }

  applyFilter() {
    const { seeHearing, ipIdia, isTla, seeAllHearings, subHearingType } =
      this.state;
    // const isTla = this.state?.hearingType === 'tla' ? true : false;

    const selectedFilters = isTla
      ? this.state.selectedFilters?.tla
      : this.state.selectedFilters?.opposition;
    const {
      trademarkClass,
      hearingLocation,
      hearingOfficer,
      trademarkProprietors,
      trademarkTags,
      hearing_date_value,
      hearing_date,
      containsTerm,
      hearingOffice,
      trademarkNOtags,
    } = selectedFilters || {};

    if (
      trademarkClass?.length > 0 ||
      hearingLocation?.length > 0 ||
      hearingOfficer?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      trademarkTags?.length > 0 ||
      hearing_date_value?.length > 0 ||
      hearing_date?.length > 0 ||
      containsTerm?.length > 0 ||
      hearingOffice?.length > 0 ||
      trademarkNOtags.length > 0
    ) {
      if (this.state.isTla) {
        if (this.state.subHearingType === "causeList") {
          this.props.causeListHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            tla: isTla,
            filterFlag: true,
            filters: selectedFilters,
            pageNumber: 1,
          });
        } else {
          this.props.ipIndiaHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            tla: isTla,
            filterFlag: true,
            filters: selectedFilters,
            pageNumber: 1,
          });
        }
      } else {
        if (this.state.subHearingType === "causeList") {
          this.props.causeListHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            tla: isTla,
            filterFlag: true,
            filters: selectedFilters,
            pageNumber: 1,
          });
        } else {
          this.props.ipIndiaHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            tla: isTla,
            filterFlag: true,
            filters: selectedFilters,
            pageNumber: 1,
          });
        }
      }
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [isTla ? "tla" : "opposition"]: {
            ...this.state.selectedFilters[isTla ? "tla" : "opposition"],
            ...selectedFilters,
            filterFlag: true,
            pageNumber: 1,
          },
        },
      });
    } else {
      message.error("please select filter");
    }
  }

  setDate(val) {
    this.setState({
      selectedFilters: {
        ...this.state?.selectedFilters,
        [`${this.state.hearingType}`]: {
          ...this.state?.selectedFilters[`${this.state.hearingType}`],
          range: val,
        },
      },
    });
  }

  handleNoTag = (e) => {
    this.setState({ noTagToggle: !this.state.noTagToggle });
  };

  resetApi = (hearingType) => {
    this.setState({
      hearingType: hearingType,
      selectAll: false,
      selectedRowKeys: [],
      isTla: hearingType === "tla" ? true : false,
      filterConfig: Hearing_DefaultFilterConfig,
    });
    if (hearingType === "opposition") {
      if (this.state?.subHearingType === "causeList") {
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          tla: false,
          filterFlag: false,
          filters: Hearing_DefaultFilterConfig,
          pageNumber: 1,
        });

        //filters
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "CLASSES",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "PROPRIETOR",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "HEARING_LOCATION",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "HEARING_OFFICERS",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "TAG",
          page: 1,
        });
      } else if (this.state?.subHearingType === "ipIndia") {
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          tla: false,
          filterFlag: false,
          filters: Hearing_DefaultFilterConfig,
          pageNumber: 1,
        });
        //filters
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "CLASSES",
          page: 1,
        });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "PROPRIETOR",
          page: 1,
        });
        // this.props.ipIndiaHearings({
        //   mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        //   upcomingHearings: !this.state?.seeAllHearings,
        //   filterFlag: false,
        //   filters: Hearing_DefaultSelectedFilters,
        //   pageNumber: 1,
        //   tla: hearingType === "tla" ? true : false,
        //   filters_required_flag: true,
        //   required_filter: "HEARING_OFFICERS",
        //   page: 1,
        // });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "TAG",
          page: 1,
        });
      }
    } else if (hearingType === "tla") {
      if (this.state?.subHearingType === "causeList") {
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          tla: true,
          filterFlag: false,
          filters: Hearing_DefaultFilterConfig,
          pageNumber: 1,
          filters_required_flag: false,
        });
        //filters
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "CLASSES",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "PROPRIETOR",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "HEARING_LOCATION",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "HEARING_OFFICERS",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "TAG",
          page: 1,
        });
      } else if (this.state?.subHearingType === "ipIndia") {
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          tla: true,
          filterFlag: false,
          filters: Hearing_DefaultFilterConfig,
          pageNumber: 1,
        });
        //filters
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "CLASSES",
          page: 1,
        });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "PROPRIETOR",
          page: 1,
        });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "HEARING_OFFICERS",
          page: 1,
        });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: hearingType === "tla" ? true : false,
          filters_required_flag: true,
          required_filter: "TAG",
          page: 1,
        });
      }
    }
  };

  resetFilter() {
    const { isTla } = this.state;

    this.setState({
      range: null,
      noTagToggle: false,
      selectedFilters: {
        ...this.state.selectedFilters,
        [isTla ? "tla" : "opposition"]: {
          ...this.state.selectedFilters[isTla ? "tla" : "opposition"],
          ...Hearing_DefaultSelectedFilters,
          filterFlag: false,
          pageNumber: 1,
        },
      },
      filterConfig: Hearing_DefaultFilterConfig,
    });

    this.resetApi(this.state?.hearingType);
  }

  handleNextpage = (pageNumber) => {
    const { isTla } = this.state;

    const selectedFilters = isTla
      ? this.state.selectedFilters?.tla
      : this.state.selectedFilters?.opposition;
    const {
      trademarkClass,
      hearingLocation,
      hearingOfficer,
      trademarkProprietors,
      trademarkTags,
      hearing_date_value,
      hearing_date,
      containsTerm,
      hearingOffice,
    } = selectedFilters || {};

    if (
      trademarkClass?.length > 0 ||
      hearingLocation?.length > 0 ||
      hearingOfficer?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      trademarkTags?.length > 0 ||
      hearing_date_value?.length > 0 ||
      hearing_date?.length > 0 ||
      containsTerm?.length > 0 ||
      hearingOffice?.length > 0
    ) {
      if (this.state.isTla) {
        if (this.state.subHearingType === "causeList") {
          this.props.causeListHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            tla: isTla,
            filterFlag: true,
            filters: selectedFilters,
            pageNumber: pageNumber,
          });
        } else {
          this.props.ipIndiaHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            tla: isTla,
            filterFlag: true,
            filters: selectedFilters,
            pageNumber: pageNumber,
          });
        }
      } else {
        if (this.state.subHearingType === "causeList") {
          this.props.causeListHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            tla: isTla,
            filterFlag: true,
            filters: selectedFilters,
            pageNumber: pageNumber,
          });
        } else {
          this.props.ipIndiaHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state?.seeAllHearings,
            tla: isTla,
            filterFlag: true,
            filters: selectedFilters,
            pageNumber: pageNumber,
          });
        }
      }
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [isTla ? "tla" : "opposition"]: {
            ...this.state.selectedFilters[isTla ? "tla" : "opposition"],
            ...selectedFilters,
            filterFlag: true,
            pageNumber: pageNumber,
          },
        },
      });
    } else {
      if (this.state?.hearingType === "opposition") {
        if (this.state?.subHearingType === "causeList") {
          this.props.causeListHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state.seeAllHearings,
            tla: false,
            filterFlag: false,
            filters: Hearing_DefaultSelectedFilters,
            pageNumber: pageNumber,
          });
        } else if (this.state?.subHearingType === "ipIndia") {
          this.props.ipIndiaHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state.seeAllHearings,
            tla: false,
            filterFlag: false,
            filters: Hearing_DefaultSelectedFilters,
            pageNumber: pageNumber,
          });
        }
      } else if (this.state?.hearingType === "tla") {
        if (this.state?.subHearingType === "causeList") {
          this.props.causeListHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state.seeAllHearings,
            tla: true,
            filterFlag: false,
            filters: Hearing_DefaultSelectedFilters,
            pageNumber: pageNumber,
          });
        } else if (this.state?.subHearingType === "ipIndia") {
          this.props.ipIndiaHearings({
            mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
            upcomingHearings: !this.state.seeAllHearings,
            tla: true,
            filterFlag: false,
            filters: Hearing_DefaultSelectedFilters,
            pageNumber: pageNumber,
          });
        }
      }
    }
  };

  seeUpcomingHearing() {
    const { isTla } = this.state;

    const selectedFilters = isTla
      ? this.state.selectedFilters?.tla
      : this.state.selectedFilters?.opposition;
    // const selectedFilters  = isTla ? this.props.tlaHearingLastAppliedFilters : this.props.oppositionHearingLastAppliedFilters;
    this.setState({
      seeHearing: !this.state.seeHearing,
      selectedFilters: {
        ...this.state.selectedFilters,
        [isTla ? "tla" : "opposition"]: {
          ...this.state.selectedFilters[isTla ? "tla" : "opposition"],
          pageNumber: 1,
        },
      },
    });

    setTimeout(() => {
      if (this.state.seeHearing) {
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: false,
          tla: isTla,
          filterFlag: selectedFilters?.filterFlag,
          filters: selectedFilters,
          pageNumber: 1,
        });
      } else {
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          tla: isTla,
          filterFlag: selectedFilters?.filterFlag,
          filters: selectedFilters,
          pageNumber: 1,
        });
      }
    }, 500);
  }

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  downloadReport() {
    const { selectedRowKeys, selectAll } = this.state;
    // const isTla = this.state?.hearingType === 'tla' ? true : false;

    if (selectAll || selectedRowKeys?.length > 0) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  async handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: ["TRADEMARK"],
        report_tag: {
          journal_number: "",
          journal_date: "",
          status: checkValue(data.status) ? data.status : "",
          renewal_date:
            data.trademark && checkValue(data.trademark.valid_upto)
              ? data.trademark.valid_upto
              : "",
          application_number: checkValue(data.application_number)
            ? data.application_number
            : "",
          mark_name:
            data.trademark && checkValue(data.trademark.applied_for)
              ? data.trademark.applied_for
              : "",
          date_of_application:
            data.trademark && checkValue(data.trademark.application_date)
              ? data.trademark.application_date
              : "",
          class:
            data.trademark && checkValue(data.trademark.associated_class)
              ? data.trademark.associated_class.join(", ")
              : "",
          date_of_usage:
            data.trademark && checkValue(data.trademark.date_of_usage)
              ? data.trademark.date_of_usage
              : "",
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          opponent_name: checkValue(data.opponent_name)
            ? data.opponent_name
            : "",
          tla_hearing_date: this.state.isTla
            ? checkValue(data.hearing_date)
              ? data.hearing_date
              : ""
            : "",
          opposition_hearing_date: this.state.isTla
            ? ""
            : checkValue(data.hearing_date)
              ? data.hearing_date
              : "",
          report_name: "",
        },
        objectType: "trademark",
        applicationNumber: data.application_number,
        markId: data.management_mark_id,
        sourceId: data.management_mark_id,
      },
    });
    if (value) {
      await this.props.getEmailTemplateData({
        subscription: "TRADEMARK",
        sub_section: "DASHBOARD",
        product: "MANAGER",
        object_id: data?.application_number,
      });
    }
  }

  handleTabChange = (hearingType) => {
    const selectedFilters =
      hearingType === "tla"
        ? this.state.selectedFilters?.tla
        : this.state.selectedFilters?.opposition;

    this.setState({
      hearingType: hearingType,
      selectAll: false,
      selectedRowKeys: [],
      isTla: hearingType === "tla" ? true : false,
      filterConfig: Hearing_DefaultFilterConfig,
      subHearingType: "causeList",
    });
    if (hearingType === "opposition") {
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        tla: false,
        filterFlag: true,
        filters: selectedFilters,
        pageNumber: 1,
      });

      //filters
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "CLASSES",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "PROPRIETOR",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "HEARING_LOCATION",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "HEARING_OFFICERS",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "TAG",
        page: 1,
      });
    } else if (hearingType === "tla") {
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        tla: true,
        filterFlag: true,
        filters: selectedFilters,
        pageNumber: 1,
      });
      //filters
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "CLASSES",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "PROPRIETOR",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "HEARING_LOCATION",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "HEARING_OFFICERS",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: hearingType === "tla" ? true : false,
        filters_required_flag: true,
        required_filter: "TAG",
        page: 1,
      });
    }
  };

  onSubHearingChange = (e) => {
    const subHearingType = e?.target?.value || "causeList";

    this.setState({
      subHearingType: subHearingType,
      selectAll: false,
      selectedRowKeys: [],
      filterConfig: Hearing_DefaultFilterConfig,
      selectedFilters: {
        opposition: Hearing_DefaultSelectedFilters,
        tla: Hearing_DefaultSelectedFilters,
      },
    });

    if (subHearingType === "causeList") {
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        tla: this.state.isTla ? true : false,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
      });
      //filters
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla ? true : false,
        filters_required_flag: true,
        required_filter: "CLASSES",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla ? true : false,
        filters_required_flag: true,
        required_filter: "PROPRIETOR",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla ? true : false,
        filters_required_flag: true,
        required_filter: "HEARING_LOCATION",
        page: 1,
      });
      this.props.causeListHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state.isTla ? true : false,
        filters_required_flag: true,
        required_filter: "TAG",
        page: 1,
      });
    } else if (subHearingType === "ipIndia") {
      this.props.ipIndiaHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        tla: this.state?.isTla ? true : false,
        filterFlag: true,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
      });
      //filters
      this.props.ipIndiaHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state?.isTla ? true : false,
        filters_required_flag: true,
        required_filter: "CLASSES",
        page: 1,
      });
      this.props.ipIndiaHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state?.isTla ? true : false,
        filters_required_flag: true,
        required_filter: "PROPRIETOR",
        page: 1,
      });
      if (this.state.hearingType === "tla") {
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !this.state?.seeAllHearings,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state?.isTla ? true : false,
          filters_required_flag: true,
          required_filter: "HEARING_OFFICE",
          page: 1,
        });
      }
      this.props.ipIndiaHearings({
        mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        upcomingHearings: !this.state?.seeAllHearings,
        filterFlag: false,
        filters: Hearing_DefaultSelectedFilters,
        pageNumber: 1,
        tla: this.state?.isTla ? true : false,
        filters_required_flag: true,
        required_filter: "TAG",
        page: 1,
      });
    }
  };
  seeAllClick = (e) => {
    const checked = e.target?.checked;

    this.setState({
      seeAllHearings: checked,
      selectedFilters: {
        opposition: Hearing_DefaultSelectedFilters,
        tla: Hearing_DefaultSelectedFilters,
      },
    });

    if (this.state?.hearingType === "opposition") {
      if (this.state?.subHearingType === "causeList") {
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          tla: this.state.isTla,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "CLASSES",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "PROPRIETOR",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "HEARING_LOCATION",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "HEARING_OFFICERS",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "TAG",
          page: 1,
        });
      } else if (this.state?.subHearingType === "ipIndia") {
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          tla: false,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
        });

        //filters
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: false,
          filters_required_flag: true,
          required_filter: "CLASSES",
          page: 1,
        });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: false,
          filters_required_flag: true,
          required_filter: "PROPRIETOR",
          page: 1,
        });
        // this.props.ipIndiaHearings({
        //   mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
        //   upcomingHearings: !checked,
        //   filterFlag: false,
        //   filters: Hearing_DefaultSelectedFilters,
        //   pageNumber: 1,
        //   tla: false,
        //   filters_required_flag: true,
        //   required_filter: "HEARING_OFFICE",
        //   page: 1,
        // });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: false,
          filters_required_flag: true,
          required_filter: "TAG",
          page: 1,
        });
      }
    } else if (this.state?.hearingType === "tla") {
      if (this.state?.subHearingType === "causeList") {
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          tla: true,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
        });
        //filters

        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "CLASSES",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "PROPRIETOR",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "HEARING_LOCATION",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "HEARING_OFFICERS",
          page: 1,
        });
        this.props.causeListHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: this.state.isTla,
          filters_required_flag: true,
          required_filter: "TAG",
          page: 1,
        });
      } else if (this.state?.subHearingType === "ipIndia") {
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          tla: true,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
        });

        //filters
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: true,
          filters_required_flag: true,
          required_filter: "CLASSES",
          page: 1,
        });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: true,
          filters_required_flag: true,
          required_filter: "PROPRIETOR",
          page: 1,
        });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: true,
          filters_required_flag: true,
          required_filter: "HEARING_OFFICE",
          page: 1,
        });
        this.props.ipIndiaHearings({
          mmType: this.state.root === "YOUR_TM" ? "trademark" : "opposed",
          upcomingHearings: !checked,
          filterFlag: false,
          filters: Hearing_DefaultSelectedFilters,
          pageNumber: 1,
          tla: true,
          filters_required_flag: true,
          required_filter: "TAG",
          page: 1,
        });
      }
    }
  };

  componentWillUnmount() {
    this.setState({ seeHearing: false, isTla: false });
  }

  render() {
    const { seeAllHearings, isTla, hearingType } = this.state;
    const selectedFilters = isTla
      ? this.state.selectedFilters?.tla
      : this.state.selectedFilters?.opposition;
    const searchFilters = isTla
      ? this.state.searchFilters.tla
      : this.state.searchFilters.opposition;
    const hearingFilters = isTla
      ? this.props.tlaHearingStatus
      : this.props.oppositionHearingStatus;
    const currentPageNumber = isTla
      ? this.props.tlaHearingPageNumber
      : this.props.oppositionHearingPageNumber;

    let oppositionHearingColumns = [];

    if (this.state.subHearingType === "ipIndia") {
      oppositionHearingColumns = [
        {
          title: "TRADEMARK",
          className: "trademark-column",
          render: (text, record) => {
            return (
              <div>
                <p
                  className="application-text"
                  onClick={() => this.handleTrademarkProfile(record)}
                >
                  <span>
                    {checkValue(record?.application_number)
                      ? record?.application_number
                      : "N.A."}{" "}
                    -{" "}
                  </span>
                  <span className="text-camelCase">
                    {checkValue(record?.applied_for)
                      ? record?.applied_for?.toLowerCase()
                      : "N.A."}
                  </span>
                </p>
                <p>
                  <img
                    className="trademark-image"
                    src={
                      checkValue(record?.associated_image)
                        ? record?.associated_image
                        : ""
                    }
                    alt=""
                  />
                </p>
              </div>
            );
          },
        },

        {
          title: "CLASS",
          // dataIndex: "trademark",
          className: "trademark-column",
          render: (text, record) => (
            <p className="text-camelCase">
              {checkValue(record)
                ? record?.associated_class?.join(", ")
                : "N.A."}
            </p>
          ),
        },

        {
          title: "PROPRIETOR",
          dataIndex: "proprietor",
          className: "trademark-column",
          render: (text) =>
            text.map((element, index, array) => (
              <p
                key={element.id}
                className="application-text text-camelCase"
                onClick={() => this.handleProprietorProfile(element.id)}
              >
                {checkValue(element)
                  ? `${element.name?.toLowerCase()}${
                      index + 1 === array?.length ? "" : " | "
                    }`
                  : "N.A."}
              </p>
            )),
        },

        {
          title: "OPPOSITION NUMBER",
          dataIndex: "opposition_number",
          className: "trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "OPPONENT NAME",
          dataIndex: "opponent_name",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">
              {checkValue(text) ? text?.toLowerCase() : "N.A."}
            </p>
          ),
        },

        // {
        //   title: "HEARING LOCATION",
        //   dataIndex: "hearing_location",
        //   className: "trademark-column",
        //   render: (text) => (
        //     <p className="text-camelCase">
        //       {checkValue(text) ? text?.toLowerCase() : "N.A."}
        //     </p>
        //   ),
        // },
        // {
        //   title: "HEARING OFFICE",
        //   dataIndex: "hearing_office",
        //   className: "trademark-column",
        //   render: (text) => (
        //     <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
        //   ),
        // },

        // {
        //   title: "HEARING OFFICER",
        //   dataIndex: "hearing_officer",
        //   className: "trademark-column",
        //   render: (text) => (
        //     <p className="text-camelCase">
        //       {checkValue(text) ? text?.toLowerCase() : "N.A."}
        //     </p>
        //   ),
        // },

        {
          title: "HEARING DATE",
          dataIndex: "hearing_date",
          className: "trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "HEARING TIME",
          dataIndex: "hearing_time",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
          ),
        },

        {
          title: "TAG",
          dataIndex: "tags",
          className: "trademark-column",
          render: (tags) => {
            return (
              <div>
                {tags?.length > 0
                  ? tags.map((tag) => (
                      <span
                        key={tag.id}
                        className="Tag"
                        style={{
                          color: `${tag.color_code}`,
                          border: `1px solid ${tag.color_code}`,
                        }}
                      >
                        {tag.name.toUpperCase()}
                      </span>
                    ))
                  : "N.A."}
              </div>
            );
          },
        },

        {
          title: "ACTION",
          className: "trademark-column",
          render: (action) => {
            return (
              <div>
                <Tooltip placement="top" title={"Create To-do Task"}>
                  <p className="alignC">
                    <CarryOutTwoTone
                      onClick={() =>
                        this.changeStateValue_L0("addTaskVisible", true)
                      }
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </p>
                </Tooltip>
                <Tooltip placement="top" title={"Notes"}>
                  <p className="alignC">
                    <MessageTwoTone
                      onClick={() => this.handleManagerNotes(action)}
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </p>
                </Tooltip>
                <Tooltip placement="top" title={"Send email"}>
                  <p
                    className="alignC"
                    onClick={() =>
                      this.handleEmail(
                        "modalsVisibility",
                        "sendEmail",
                        true,
                        action,
                      )
                    }
                  >
                    <i
                      className="fa fa-paper-plane"
                      style={{
                        padding: "0px 5px",
                        color: "#1890ff",
                        fontSize: 20,
                      }}
                      aria-hidden="true"
                    ></i>
                  </p>
                </Tooltip>
              </div>
            );
          },
        },
      ];
    } else {
      oppositionHearingColumns = [
        {
          title: "TRADEMARK",
          className: "trademark-column",
          render: (text, record) => {
            return (
              <div>
                <p
                  className="application-text"
                  onClick={() => this.handleTrademarkProfile(record)}
                >
                  <span>
                    {checkValue(record?.application_number)
                      ? record?.application_number
                      : "N.A."}{" "}
                    -{" "}
                  </span>
                  <span className="text-camelCase">
                    {checkValue(record?.applied_for)
                      ? record?.applied_for?.toLowerCase()
                      : "N.A."}
                  </span>
                </p>
                <p>
                  <img
                    className="trademark-image"
                    src={
                      checkValue(record?.associated_image)
                        ? record?.associated_image
                        : ""
                    }
                    alt=""
                  />
                </p>
              </div>
            );
          },
        },

        {
          title: "CLASS",
          // dataIndex: "trademark",
          className: "trademark-column",
          render: (text, record) => (
            <p className="text-camelCase">
              {checkValue(record)
                ? record?.associated_class?.join(", ")
                : "N.A."}
            </p>
          ),
        },

        {
          title: "PROPRIETOR",
          dataIndex: "proprietor",
          className: "trademark-column",
          render: (text) =>
            text.map((element, index, array) => (
              <p
                key={element.id}
                className="application-text text-camelCase"
                onClick={() => this.handleProprietorProfile(element.id)}
              >
                {checkValue(element)
                  ? `${element.name?.toLowerCase()}${
                      index + 1 === array?.length ? "" : " | "
                    }`
                  : "N.A."}
              </p>
            )),
        },

        {
          title: "OPPOSITION NUMBER",
          dataIndex: "opposition_number",
          className: "trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "OPPONENT NAME",
          dataIndex: "opponent_name",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">
              {checkValue(text) ? text?.toLowerCase() : "N.A."}
            </p>
          ),
        },

        {
          title: "HEARING LOCATION",
          dataIndex: "hearing_location",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">
              {checkValue(text) ? text?.toLowerCase() : "N.A."}
            </p>
          ),
        },

        {
          title: "HEARING OFFICER",
          dataIndex: "hearing_officer",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">
              {checkValue(text) ? text?.toLowerCase() : "N.A."}
            </p>
          ),
        },

        {
          title: "HEARING DATE",
          dataIndex: "hearing_date",
          className: "trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "HEARING TIME",
          dataIndex: "hearing_time",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
          ),
        },

        {
          title: "TAG",
          dataIndex: "tags",
          className: "trademark-column",
          render: (tags) => {
            return (
              <div>
                {tags?.length > 0
                  ? tags.map((tag) => (
                      <span
                        key={tag.id}
                        className="Tag"
                        style={{
                          color: `${tag.color_code}`,
                          border: `1px solid ${tag.color_code}`,
                        }}
                      >
                        {tag.name.toUpperCase()}
                      </span>
                    ))
                  : "N.A."}
              </div>
            );
          },
        },

        {
          title: "ACTION",
          className: "trademark-column",
          render: (action) => {
            return (
              <div>
                <Tooltip placement="top" title={"Create To-do Task"}>
                  <p className="alignC">
                    <CarryOutTwoTone
                      onClick={() =>
                        this.changeStateValue_L0("addTaskVisible", true)
                      }
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </p>
                </Tooltip>
                <Tooltip placement="top" title={"Notes"}>
                  <p className="alignC">
                    <MessageTwoTone
                      onClick={() => this.handleManagerNotes(action)}
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </p>
                </Tooltip>
                <Tooltip placement="top" title={"Send email"}>
                  <p
                    className="alignC"
                    onClick={() =>
                      this.handleEmail(
                        "modalsVisibility",
                        "sendEmail",
                        true,
                        action,
                      )
                    }
                  >
                    <i
                      className="fa fa-paper-plane"
                      style={{
                        padding: "0px 5px",
                        color: "#1890ff",
                        fontSize: 20,
                      }}
                      aria-hidden="true"
                    ></i>
                  </p>
                </Tooltip>
              </div>
            );
          },
        },
      ];
    }

    let tlaHearingColumns = [];

    if (this.state.subHearingType === "ipIndia") {
      tlaHearingColumns = [
        {
          title: "TRADEMARK",
          className: "trademark-column",
          render: (text, record) => {
            return (
              <div>
                <p
                  className="application-text"
                  onClick={() => this.handleTrademarkProfile(record)}
                >
                  <span>
                    {checkValue(record?.application_number)
                      ? record?.application_number
                      : "N.A."}{" "}
                    -{" "}
                  </span>
                  <span className="text-camelCase">
                    {checkValue(record?.applied_for)
                      ? record?.applied_for?.toLowerCase()
                      : "N.A."}
                  </span>
                </p>
                <p>
                  <img
                    className="trademark-image"
                    src={
                      checkValue(record?.associated_image)
                        ? record?.associated_image
                        : ""
                    }
                    alt=""
                  />
                </p>
              </div>
            );
          },
        },

        {
          title: "CLASS",
          // dataIndex: "trademark",
          className: "trademark-column",
          render: (text, record) => (
            <p className="text-camelCase">
              {checkValue(record)
                ? record?.associated_class?.join(", ")
                : "N.A."}
            </p>
          ),
        },

        {
          title: "PROPRIETOR",
          dataIndex: "proprietor",
          className: "trademark-column",
          render: (text) =>
            text.map((element, index, array) => (
              <p
                key={element.id}
                className="application-text text-camelCase"
                onClick={() => this.handleProprietorProfile(element.id)}
              >
                {checkValue(element)
                  ? `${element.name?.toLowerCase()}${
                      index + 1 === array?.length ? "" : " | "
                    }`
                  : "N.A."}
              </p>
            )),
        },
        {
          title: "HEARING LOCATION",
          dataIndex: "hearing_location",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">
              {checkValue(text) ? text?.toLowerCase() : "N.A."}
            </p>
          ),
        },

        {
          title: "HEARING OFFICE",
          dataIndex: "hearing_office",
          className: "trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },
        {
          title: "HEARING DATE",
          dataIndex: "hearing_date",
          className: "trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "HEARING TIME",
          dataIndex: "hearing_time",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
          ),
        },

        {
          title: "TAG",
          dataIndex: "tags",
          className: "trademark-column",
          render: (tags) => {
            return (
              <div>
                {tags?.length > 0
                  ? tags.map((tag) => (
                      <span
                        key={tag.id}
                        className="Tag"
                        style={{
                          color: `${tag.color_code}`,
                          border: `1px solid ${tag.color_code}`,
                        }}
                      >
                        {tag.name.toUpperCase()}
                      </span>
                    ))
                  : "N.A."}
              </div>
            );
          },
        },

        {
          title: "ACTION",
          className: "trademark-column",
          render: (action) => {
            return (
              <div>
                <Tooltip placement="top" title={"Create To-do Task"}>
                  <p className="alignC">
                    <CarryOutTwoTone
                      onClick={() =>
                        this.changeStateValue_L0("addTaskVisible", true)
                      }
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </p>
                </Tooltip>
                <Tooltip placement="top" title={"Notes"}>
                  <p className="alignC">
                    <MessageTwoTone
                      onClick={() => this.handleManagerNotes(action)}
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </p>
                </Tooltip>
                <Tooltip placement="top" title={"Send email"}>
                  <p
                    className="alignC"
                    onClick={() =>
                      this.handleEmail(
                        "modalsVisibility",
                        "sendEmail",
                        true,
                        action,
                      )
                    }
                  >
                    <i
                      className="fa fa-paper-plane"
                      style={{
                        padding: "0px 5px",
                        color: "#1890ff",
                        fontSize: 20,
                      }}
                      aria-hidden="true"
                    ></i>
                  </p>
                </Tooltip>
              </div>
            );
          },
        },
      ];
    } else {
      tlaHearingColumns = [
        {
          title: "TRADEMARK",
          className: "trademark-column",
          render: (text, record) => {
            return (
              <div>
                <p
                  className="application-text"
                  onClick={() => this.handleTrademarkProfile(record)}
                >
                  <span>
                    {checkValue(record?.application_number)
                      ? record?.application_number
                      : "N.A."}{" "}
                    -{" "}
                  </span>
                  <span className="text-camelCase">
                    {checkValue(record?.applied_for)
                      ? record?.applied_for?.toLowerCase()
                      : "N.A."}
                  </span>
                </p>
                <p>
                  <img
                    className="trademark-image"
                    src={
                      checkValue(record?.associated_image)
                        ? record?.associated_image
                        : ""
                    }
                    alt=""
                  />
                </p>
              </div>
            );
          },
        },

        {
          title: "CLASS",
          // dataIndex: "trademark",
          className: "trademark-column",
          render: (text, record) => (
            <p className="text-camelCase">
              {checkValue(record)
                ? record?.associated_class?.join(", ")
                : "N.A."}
            </p>
          ),
        },

        {
          title: "PROPRIETOR",
          dataIndex: "proprietor",
          className: "trademark-column",
          render: (text) =>
            text.map((element, index, array) => (
              <p
                key={element.id}
                className="application-text text-camelCase"
                onClick={() => this.handleProprietorProfile(element.id)}
              >
                {checkValue(element)
                  ? `${element.name?.toLowerCase()}${
                      index + 1 === array?.length ? "" : " | "
                    }`
                  : "N.A."}
              </p>
            )),
        },

        {
          title: "HEARING LOCATION",
          dataIndex: "hearing_location",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">
              {checkValue(text) ? text?.toLowerCase() : "N.A."}
            </p>
          ),
        },

        {
          title: "HEARING DATE",
          dataIndex: "hearing_date",
          className: "trademark-column",
          render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        },

        {
          title: "HEARING TIME",
          dataIndex: "hearing_time",
          className: "trademark-column",
          render: (text) => (
            <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
          ),
        },

        {
          title: "TAG",
          dataIndex: "tags",
          className: "trademark-column",
          render: (tags) => {
            return (
              <div>
                {tags?.length > 0
                  ? tags.map((tag) => (
                      <span
                        key={tag.id}
                        className="Tag"
                        style={{
                          color: `${tag.color_code}`,
                          border: `1px solid ${tag.color_code}`,
                        }}
                      >
                        {tag.name.toUpperCase()}
                      </span>
                    ))
                  : "N.A."}
              </div>
            );
          },
        },

        {
          title: "ACTION",
          className: "trademark-column",
          render: (action) => {
            return (
              <div>
                <Tooltip placement="top" title={"Create To-do Task"}>
                  <p className="alignC">
                    <CarryOutTwoTone
                      onClick={() =>
                        this.changeStateValue_L0("addTaskVisible", true)
                      }
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </p>
                </Tooltip>
                <Tooltip placement="top" title={"Notes"}>
                  <p className="alignC">
                    <MessageTwoTone
                      onClick={() => this.handleManagerNotes(action)}
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </p>
                </Tooltip>
                <Tooltip placement="top" title={"Send email"}>
                  <p
                    className="alignC"
                    onClick={() =>
                      this.handleEmail(
                        "modalsVisibility",
                        "sendEmail",
                        true,
                        action,
                      )
                    }
                  >
                    <i
                      className="fa fa-paper-plane"
                      style={{
                        padding: "0px 5px",
                        color: "#1890ff",
                        fontSize: 20,
                      }}
                      aria-hidden="true"
                    ></i>
                  </p>
                </Tooltip>
              </div>
            );
          },
        },
      ];
    }

    const HearingColumnsrowselection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let select_trademark_id = [];
        select_trademark_id = selectedRows.map((mark_id) => mark_id.id);
        this.setState({
          selectedRows: selectedRows,
          add_trademark_id: select_trademark_id,
          selectedRowKeys: selectedRowKeys,
        });
      },

      onSelectAll: (selected, selectedRows) => {
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows) => {
        this.setState({
          selectRow: selected,
          selectAll: selectedRows?.length === 100 ? true : false,
        });
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: !this.state.selectAll,
                  add_trademark_id: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    const oppositionHearingColumnsrowselection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let select_trademark_id = [];
        select_trademark_id = selectedRows.map((mark_id) => mark_id.id);
        this.setState({
          selectedRows: selectedRows,
          add_trademark_id: select_trademark_id,
          selectedRowKeys: selectedRowKeys,
        });
      },

      onSelectAll: (selected, selectedRows) => {
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows) => {
        this.setState({
          selectRow: selected,
          selectAll: selectedRows?.length === 100 ? true : false,
        });
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: !this.state.selectAll,
                  add_trademark_id: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    const tlaHearingColumnsrowselection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let select_trademark_id = [];
        select_trademark_id = selectedRows.map((mark_id) => mark_id.id);
        this.setState({
          pseudo_selectedRows: selectedRows,
          pseudo_add_trademark_id: select_trademark_id,
          pseudo_selectedRowKeys: selectedRowKeys,
        });
      },

      onSelectAll: (selected, selectedRows) => {
        this.setState({
          pseudo_selectAll: selected,
          pseudo_selectedRows: selectedRows,
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows) => {
        this.setState({
          pseudo_selectRow: selected,
          // pseudo_selectAll: selectedRows?.length === 100 ? true : false,
        });
      },

      hideDefaultSelections: true,
      selections: this.state.pseudo_selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  pseudo_selectedRowKeys: changeableRowKeys,
                  pseudo_selectAll: false,
                  pseudo_add_trademark_id: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.pseudo_selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.pseudo_selectAll,
      }),
    };

    let noFilterData = (
      <Menu>
        <Menu.Item className="filter-list-item">
          <div>No filter Data</div>
        </Menu.Item>
      </Menu>
    );
    let classesMenu = noFilterData;
    let locationMenu = noFilterData;
    let officerMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    let tagsMenu = noFilterData;
    let officeMenu = noFilterData;

    const classes = isTla
      ? this.props.tlaHearingClassFilters
      : this.props.oppositionHearingClassFilters;
    const proprietors = isTla
      ? this.props.tlaHearingProprietorsFilters?.filter((x) => x?.name !== "")
      : this.props.oppositionHearingProprietorsFilters?.filter(
          (x) => x?.name !== "",
        );
    const hearing_location = isTla
      ? this.state.subHearingType === "ipIndia"
        ? this.props.tlaHearingLocationFilters?.filter(
            (x) => x?.location_name !== "" || x?.location_name !== null,
          )
        : this.props.tlaHearingLocationFilters?.filter((x) => x !== null)
      : this.props.oppositionHearingLocationFilters;
    const hearing_officers = isTla
      ? this.props.tlaHearingOfficersFilters?.filter((x) => x !== null)
      : this.props.oppositionHearingOfficersFilters?.filter((x) => x !== null);
    const tags = isTla
      ? this.props.tlaHearingTagsFilters
      : this.props.oppositionHearingTagFilters;
    const hearing_office = isTla
      ? this.props.tlaHearingOfficeFilters?.filter((x) => x !== null)
      : this.props.oppositionHearingOfficeFilters?.filter((x) => x !== null);

    const isFilterLoading = this.state.isTla
      ? this.props.tlaHearingFiltersStatus === "LOADING"
      : this.props.oppositionHearingFiltersStatus === "LOADING";

    classesMenu = (
      <FilterSearchBasic
        data={classes}
        searchFilters={searchFilters}
        search_key={"classSearch"}
        filter_key={"trademarkClass"}
        selectedFilters={selectedFilters}
        switchValue={this.state.hearingType}
        onSelect={this.changeStateValue_L2}
        title={"class"}
      />
    );

    locationMenu = (
      <FilterSearchPaginate
        datasource={hearing_location}
        filter_key={"hearingLocation"}
        req_filter={"HEARING_LOCATION"}
        selectedFilters={selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "HEARING_LOCATION",
            this.state?.filterConfig[`${"HEARING_LOCATION"}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${"HEARING_LOCATION"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Location"}
        searchValue={
          this.state?.filterConfig["HEARING_LOCATION"]?.filter_contains_term
        }
        switchValue={this.state.hearingType}
      />
    );

    officerMenu = (
      <FilterSearchPaginate
        datasource={hearing_officers}
        filter_key={"hearingOfficer"}
        req_filter={"HEARING_OFFICERS"}
        selectedFilters={selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "HEARING_OFFICERS",
            this.state?.filterConfig[`${"HEARING_OFFICERS"}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${"HEARING_OFFICERS"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Officer"}
        searchValue={
          this.state?.filterConfig["HEARING_OFFICERS"]?.filter_contains_term
        }
        switchValue={this.state.hearingType}
      />
    );

    officeMenu = (
      <FilterSearchPaginate
        datasource={hearing_office}
        filter_key={"hearingOffice"}
        req_filter={"HEARING_OFFICE"}
        selectedFilters={selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "HEARING_OFFICE",
            this.state?.filterConfig[`${"HEARING_OFFICE"}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${"HEARING_OFFICE"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Office"}
        searchValue={
          this.state?.filterConfig["HEARING_OFFICE"]?.filter_contains_term
        }
        switchValue={this.state.hearingType}
      />
    );

    proprietorsMenu = (
      <FilterSearchPaginate
        datasource={proprietors}
        filter_key={"trademarkProprietors"}
        req_filter={"PROPRIETOR"}
        selectedFilters={selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={isFilterLoading}
        title={""}
        type={"object"}
        onSelect={this.changeStateValue_L2}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "PROPRIETOR",
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Proprietor"}
        searchValue={
          this.state?.filterConfig["PROPRIETOR"]?.filter_contains_term
        }
        switchValue={this.state.hearingType}
      />
    );

    tagsMenu = (
      <FilterSearchPaginate
        datasource={tags}
        filter_key={"trademarkTags"}
        req_filter={"TAG"}
        selectedFilters={selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        onFilterPaginate={() => {
          let required_filter = "TAG";
          this.onFilterPaginate(
            required_filter,
            this.state?.filterConfig[`${required_filter}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${required_filter}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Tags"}
        searchValue={this.state?.filterConfig["TAG"]?.filter_contains_term}
        switchValue={this.state.hearingType}
        type={"noTag"}
        handleNoTag={this.handleNoTag}
        noTagToggle={this.state.noTagToggle}
      />
    );

    const HearingType = this.state.isTla ? "TLA" : "Opposition";
    const SubHearingType =
      this.state.subHearingType === "causeList"
        ? "(As Per Cause)"
        : "(IP India)";
    const title = (
      <Row style={{ marginBottom: "8px" }}>
        <Col xs={{ span: 12 }} lg={{ span: 24 }}>
          <Title style={{ marginBottom: "4px" }} level={3}>
            {this.state.root === "YOUR_TM"
              ? "Upcoming Hearings (Portfolio Trademarks)"
              : "Upcoming Hearings (3rd Party Opposed Trademarks)"}
          </Title>
          <Text style={{ fontSize: "16px" }} type="secondary">
            {this.state.isTla
              ? `Total ${HearingType} Hearing ${SubHearingType}: ${
                  this.props.tlaHearing?.tla?.count || 0
                }`
              : `Total ${HearingType} Hearing ${SubHearingType}: ${
                  this.props.oppositionHearing?.opposition?.count || 0
                }`}
          </Text>
        </Col>
      </Row>
    );

    const handleOpenChange = (flag, key) => {
      if (flag) {
        this.setState({
          open: key,
        });
      } else {
        this.setState({
          open: null,
        });
      }
    };

    const filters = (
      <Row>
        <Col span={24} style={{ marginTop: "10px" }}>
          <Menu
            selectedKeys={[
              selectedFilters?.trademarkClass
                ? selectedFilters?.trademarkClass.length > 0
                  ? "classesMenu"
                  : ""
                : "",
              selectedFilters?.hearingLocation
                ? selectedFilters?.hearingLocation.length > 0
                  ? "locationMenu"
                  : ""
                : "",
              selectedFilters?.hearingOfficer
                ? selectedFilters?.hearingOfficer.length > 0
                  ? "officerMenu"
                  : ""
                : "",
              selectedFilters?.trademarkProprietors
                ? selectedFilters?.trademarkProprietors.length > 0
                  ? "proprietorsMenu"
                  : ""
                : "",
              selectedFilters?.trademarkTags
                ? selectedFilters?.trademarkTags.length > 0 ||
                  this.state.noTagToggle
                  ? "tagsMenu"
                  : ""
                : "",
              selectedFilters?.hearing_date
                ? selectedFilters?.hearing_date.length > 0
                  ? "hearingDate"
                  : ""
                : "",
              selectedFilters?.hearingOffice
                ? selectedFilters?.hearingOffice.length > 0
                  ? "officeMenu"
                  : ""
                : "",
            ]}
            className="filter-list"
            mode="horizontal"
            style={{ alignItems: "center" }}
          >
            <Menu.Item key="radio" className="filter-list-item apply-filter">
              <Radio.Group
                buttonStyle="solid"
                optionType="button"
                value={this.state?.subHearingType}
                onChange={this.onSubHearingChange}
                style={{ display: "flex", flex: "none" }}
              >
                <Radio.Button value="causeList">As Per Cause</Radio.Button>
                <Radio.Button value="ipIndia">IP India</Radio.Button>
              </Radio.Group>
            </Menu.Item>

            <Menu.Item
              key="contentTerm"
              className="filter-list-item apply-filter"
            >
              <Input
                placeholder="Search Application No/Name"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "selectedFilters",
                    this.state.hearingType,
                    "containsTerm",
                    event.target.value,
                  )
                }
                value={selectedFilters?.containsTerm}
                allowClear
                style={{ width: 240 }}
                suffix={null}
              />
            </Menu.Item>
            <Menu.Item key="classesMenu" className="filter-list-item">
              <Dropdown
                dropdownRender={() => classesMenu}
                overlayClassName="filter-menu"
                autoAdjustOverflow
                onOpenChange={(flag) => handleOpenChange(flag, "classes")}
                open={this.state.open === "classes"}
              >
                <div className="ant-dropdown-link">
                  <Text>Class </Text>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>

            <Menu.Item key="proprietorsMenu" className="filter-list-item">
              <Dropdown
                dropdownRender={() => proprietorsMenu}
                overlayClassName="filter-menu"
                autoAdjustOverflow
                onOpenChange={(flag) => handleOpenChange(flag, "proprietor")}
                open={this.state.open === "proprietor"}
              >
                <div className="ant-dropdown-link">
                  <Text>Proprietor </Text>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>

            {this.state.subHearingType === "ipIndia" ? (
              isTla ? (
                <Menu.Item key="officeMenu" className="filter-list-item">
                  <Dropdown
                    dropdownRender={() => officeMenu}
                    overlayClassName="filter-menu"
                    autoAdjustOverflow
                    onOpenChange={(flag) => handleOpenChange(flag, "office")}
                    open={this.state.open === "office"}
                  >
                    <div className="ant-dropdown-link">
                      <Text>Hearing Office </Text>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
              ) : null
            ) : (
              <Menu.Item key="locationMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => locationMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) => handleOpenChange(flag, "location")}
                  open={this.state.open === "location"}
                >
                  <div className="ant-dropdown-link">
                    <Text>Hearing Location </Text>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
            )}
            {!isTla && this.state.subHearingType !== "ipIndia" && (
              <Menu.Item key="officerMenu" className="filter-list-item">
                <Dropdown
                  dropdownRender={() => officerMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) => handleOpenChange(flag, "officer")}
                  open={this.state.open === "officer"}
                >
                  <div className="ant-dropdown-link">
                    <Text>Hearing Officer </Text>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
            )}
            <Menu.Item key="tagsMenu" className="filter-list-item">
              <Dropdown
                dropdownRender={() => tagsMenu}
                overlayClassName="filter-menu"
                autoAdjustOverflow
                onOpenChange={(flag) => handleOpenChange(flag, "tags")}
                open={this.state.open === "tags"}
              >
                <div className="ant-dropdown-link">
                  <span>Tags</span>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>
            <Menu.Item key="hearingDate" className="filter-list-item">
              <Dropdown
                overlay={
                  <DateRangePicker
                    type={"Hearing Date"}
                    startValue={selectedFilters?.hearing_date_startValue}
                    endValue={selectedFilters?.hearing_date_endValue}
                    setDate={(val) => this.setDate(val)}
                    range={
                      this.state.selectedFilters?.[this.state.hearingType]
                        ?.range
                    }
                    handleDateString={(startValue, endValue, dateString) =>
                      this.setState({
                        selectedFilters: {
                          ...this.state.selectedFilters,
                          [this.state.hearingType]: {
                            ...this.state.selectedFilters[
                              this.state.hearingType
                            ],
                            hearing_date_startValue: startValue,
                            hearing_date_endValue: endValue,
                            hearing_date:
                              startValue !== null || endValue !== null
                                ? dateString
                                : null,
                          },
                        },
                      })
                    }
                  />
                }
                overlayClassName="filter-menu"
              >
                <div className="ant-dropdown-link">
                  <Text>Hearing Date </Text>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>
            <Menu.Item
              key="applyFilter"
              className="filter-list-item apply-filter"
            >
              <Button type="primary" onClick={() => this.applyFilter()}>
                <i className="fa fa-filter" aria-hidden="true" />
                <Text style={{ color: "#fff", marginLeft: "5px" }}>
                  Apply Filters
                </Text>
              </Button>
            </Menu.Item>
            <Menu.Item
              key="resetFilter"
              className="filter-list-item apply-filter"
            >
              <Button type="link" onClick={() => this.resetFilter()}>
                <Text o style={{ padding: "0px 5px", color: "#1890ff" }}>
                  Reset All
                </Text>
              </Button>
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
    );
    const toolbar = (
      <Row style={{ marginTop: "8px" }} justify="space-between">
        <Col span={4}>
          <Checkbox
            checked={this.state.seeAllHearings}
            onChange={this.seeAllClick}
          >
            <Text className="text-camelCase" style={{ fontWeight: "400" }}>
              See All Hearings
            </Text>
          </Checkbox>
        </Col>
        <Col>
          <div style={{ marginLeft: "auto" }}>
            <Badge
              count={
                this.state?.selectAll
                  ? "All"
                  : this.state?.selectedRowKeys?.length
              }
              style={{ marginTop: "5px", marginRight: "10px" }}
            >
              <Button
                type="primary"
                onClick={() => this.downloadReport()}
                style={{ marginRight: "10px", marginLeft: "8px" }}
              >
                Create Report
              </Button>
            </Badge>
          </div>
        </Col>
      </Row>
    );

    const tabContent = (
      <>
        {filters}
        {toolbar}
        {!isTla && this.state.subHearingType === "ipIndia" && (
          <div style={{ marginTop: "10px" }}>
            <Alert
              type="info"
              message="Note: This data is from readable documents only."
            />
          </div>
        )}

        <Row>
          <Col span={24}>
            <div className="m-top-20 upcoming-hearing">
              <Table
                rowSelection={HearingColumnsrowselection}
                rowKey={(record) => record.id}
                columns={isTla ? tlaHearingColumns : oppositionHearingColumns}
                dataSource={
                  isTla
                    ? checkValue(this.props.tlaHearing)
                      ? this.props.tlaHearing?.tla?.trademarks
                      : []
                    : checkValue(this.props.oppositionHearing)
                      ? this.props.oppositionHearing?.opposition?.trademarks
                      : []
                }
                pagination={{
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  current: currentPageNumber,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} `,
                  total: isTla
                    ? checkValue(this.props.tlaHearing)
                      ? this.props.tlaHearing.tla?.count
                      : 0
                    : checkValue(this.props.oppositionHearing)
                      ? this.props.oppositionHearing.opposition?.count
                      : 0,
                  onChange: this.handleNextpage,
                }}
                loading={
                  this.props.tlaHearingStatus === LOADING
                    ? true
                    : false || this.props.oppositionHearingStatus === LOADING
                      ? true
                      : false
                }
                scroll={{ y: "calc(100vh - 390px)" }}
              />
            </div>
          </Col>
        </Row>
      </>
    );
    const tabConfig = [
      {
        tab: "Opposition Hearing",
        key: "opposition",
      },
      {
        tab: "TLA Hearing",
        key: "tla",
      },
    ];

    const hearingTabs = (
      <Row>
        <Col>
          <Tabs
            activeKey={this.state?.hearingType}
            onChange={this.handleTabChange}
            tabBarStyle={{ margin: "0px" }}
          >
            {tabConfig?.map((tab) => (
              <TabPane tab={tab.tab} key={tab.key} style={{ margin: "0px" }}>
                {tabContent}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    );
    return (
      //   <Layout style={{ background: "white", padding: "24px", height: "100vh" }}>
      <Content style={{ margin: "16px" }}>
        {title}
        {hearingTabs}

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"trademark"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
          email={
            checkValue(this.props.userSubscriptions)
              ? this.props.userSubscriptions.email
              : ""
          }
          note_for={TM}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          mm_type={
            this.state.root === "YOUR_TM"
              ? "Portfolio trademark"
              : "Third party Opposed trademark"
          }
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          markId={this.state.modalsVisibility.markId}
          doc_link={true}
          sourceId={this.state.modalsVisibility.sourceId}
          source={`TRADEMARK`}
          sub_section="DASHBOARD"
          managerEmail={true}
        />
        {this.state.visible && (
          <DownloadReportModal
            visible={this.state.visible}
            onCloseModal={() =>
              this.setState({
                visible: false,
                selectAll: false,
                selectedRowKeys: [],
              })
            }
            tla={isTla}
            causeList={this.state.subHearingType === "causeList" ? true : false}
            upcomingHearings={seeAllHearings}
            mm_type={this.state.root === "YOUR_TM" ? "trademark" : "opposed"}
            selectAll={this.state.selectAll}
            selectedRows={this.state.selectedRowKeys}
            filterFlag={selectedFilters?.filterFlag}
            filters={selectedFilters}
          />
        )}
      </Content>
      //   </Layout>
    );
  }
}

const mapDispatchToProps = {
  getTLAOppositionIP,
  getTLAOpposition,
  causeListHearings,
  ipIndiaHearings,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const {
    upcomingHearings,
    tla,
    tlaHearing,
    tlaHearingStatus,
    tlaHearingLastAppliedFilters,
    tlaHearingPageNumber,

    oppositionHearing,
    oppositionHearingStatus,
    oppositionHearingLastAppliedFilters,
    oppositionHearingPageNumber,

    tlaHearingFiltersStatus,
    oppositionHearingFiltersStatus,

    tlaHearingClassFilters,
    tlaHearingProprietorsFilters,

    tlaHearingTagsFilters,
    tlaHearingTagsFiltersCount,
    tlaHearingLocationFilters,
    tlaHearingLocationFiltersCount,
    tlaHearingOfficersFilters,
    tlaHearingOfficersFiltersCount,
    tlaHearingOfficeFilters,
    tlaHearingOfficeFiltersCount,

    oppositionHearingClassFilters,
    oppositionHearingProprietorsFilters,
    tlaHearingProprietorsFiltersCount,
    oppositionHearingProprietorsFiltersCount,
    oppositionHearingTagFilters,
    oppositionHearingTagFiltersCount,
    oppositionHearingLocationFilters,
    oppositionHearingLocationFiltersCount,
    oppositionHearingOfficersFilters,
    oppositionHearingOfficersFiltersCount,
  } = state.managerState.trademarkDashboardState;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    upcomingHearings,
    tla,
    tlaHearing,
    tlaHearingStatus,
    tlaHearingLastAppliedFilters,
    tlaHearingPageNumber,

    oppositionHearing,
    oppositionHearingStatus,
    oppositionHearingLastAppliedFilters,
    oppositionHearingPageNumber,

    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    tlaHearingFiltersStatus,
    oppositionHearingFiltersStatus,

    tlaHearingClassFilters,
    tlaHearingProprietorsFilters,
    tlaHearingProprietorsFiltersCount,
    tlaHearingTagsFilters,
    tlaHearingTagsFiltersCount,
    tlaHearingLocationFilters,
    tlaHearingLocationFiltersCount,
    tlaHearingOfficersFilters,
    tlaHearingOfficersFiltersCount,
    tlaHearingOfficeFilters,
    tlaHearingOfficeFiltersCount,

    oppositionHearingClassFilters,
    oppositionHearingProprietorsFilters,
    oppositionHearingProprietorsFiltersCount,

    oppositionHearingTagFilters,
    oppositionHearingTagFiltersCount,
    oppositionHearingLocationFilters,
    oppositionHearingLocationFiltersCount,
    oppositionHearingOfficersFilters,
    oppositionHearingOfficersFiltersCount,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingHearings);
