//--------WATCH TYPES--------
import { SET_CURRENT_JOURNAL } from "../../actions/watchActions/types";

export default function(state = null, action){
    switch (action.type) {
        case SET_CURRENT_JOURNAL:
            return action.payload;

        default:
            return state;
    }
}