import React, { Component } from "react";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
export default class DateRangePicker extends Component {
  constructor(props) {
    super(props);
  }

  onChange = (dates, dateStrings) => {
    if (dates === null) {
      this.props.setDate(["", ""]);
      setTimeout(() => {
        this.props.handleDateString("", "", "");
      }, 500);
    }

    if (dates?.[0] !== undefined && dates?.[1] !== undefined) {
      this.props.setDate([dates[0], dates[1]]);
      setTimeout(() => {
        this.props.handleDateString(
          dates[0],
          dates[1],
          dateStrings[0] + "to" + dateStrings[1],
        );
      }, 500);
    } else {
      this.props.setDate(null);
      setTimeout(() => {
        this.props.handleDateString(
          dates?.[0] || "",
          dates?.[1] || "",
          dateStrings?.[0] || "" + "" + dateStrings?.[1] || "",
        );
      }, 500);
    }
  };

  render() {
    return (
      <div>
        <RangePicker
          format="DD-MM-YYYY"
          //    ranges={{
          //         'This Month' : [moment().startOf('month'), moment().endOf('month')],
          //     }}
          onChange={this.onChange}
          value={this.props.range}
          allowClear={true}
        />
      </div>
    );
  }
}
