import React, { Component } from "react";
import SignInSider from "./SignInSider";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Typography, Col, Alert, Button, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { setUpAuth } from "../../redux/actions/commonActions/actions";
import { connect } from "react-redux";
import QRCode from "qrcode";
import {
  LOADING,
  SUCCESS,
} from "../../constants/commonConstants/generalConstants";
import { verifyAuthCode } from "../../redux/actions/commonActions/actions";
import { history } from "../../history";

const { Title } = Typography;

class SetUpAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrCode: "",
      textCode: "",
      code: "",
      codeError: "",
    };
  }
  generateQR = async (url) => {
    try {
      let qr = await QRCode.toDataURL(url);
      this.setState({ qrCode: qr });
    } catch (err) {
      console.error(err);
    }
  };

  async componentDidMount() {
    let userId = localStorage.getItem("user_id");
    await this.props.setUpAuth({
      user_id: userId,
      operation: "SETUP",
      sub_operation: "GENERATE_URL",
    });
    // this.setState({ textCode: this.props?.authData?.binding?.secret });
  }

  async shouldComponentUpdate(nextProps) {
    if (
      nextProps?.authSetupStatus === SUCCESS &&
      this.state.qrCode === "" &&
      nextProps.authData?.binding?.uri !== undefined &&
      nextProps.authData?.binding?.uri
    ) {
      await this.generateQR(nextProps.authData?.binding?.uri);
      return true;
    }
    if (
      nextProps?.authSetupStatus === SUCCESS &&
      this.props?.authData?.binding?.secret &&
      this.state.textCode === ""
    ) {
      this.setState({ textCode: this.props?.authData?.binding?.secret });
      return true;
    }
    return false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state?.code?.length === 6) {
      this.props.verifyAuthCode({
        user_id: localStorage.getItem("user_id"),
        operation: "SETUP",
        sub_operation: "SETUP_CODE",
        code: this.state.code,
      });
    } else {
      this.setState({ codeError: "password length should be 6" });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { qrCode, showTextCode } = this.state;
    const { authVerification, authCodeStatus, authSetupStatus } = this.props;

    if (authVerification?.verified === true) {
      history.push("/recovery-codes");
    }

    return (
      <div class="container-fluid">
        <div class="row login-row">
          <SignInSider />
          <div class="col-lg-6 col-sm-12">
            <Col sm={24} md={18} className="SignInForm">
              <div className="authsetup-section">
                <Title level={3}>Setup Two Factor Authentication</Title>

                <p className="subtitle">
                  Scan the image below or enter the text to any authenticator
                  app of your choice like Authy, Google Authenticator, Microsoft
                  Authenticator or other TOTP apps. If you can’t use a QR code,
                  &nbsp;
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => this.setState({ showTextCode: true })}
                  >
                    enter this text code{" "}
                  </span>{" "}
                  &nbsp;instead.
                </p>
                {showTextCode && (
                  <>
                    <div style={{ color: "#494949" }}>Text Code</div>
                    <div className="setup-text-code">{this.state.textCode}</div>
                  </>
                )}
                {authSetupStatus === "LOADING" && (
                  <LoadingOutlined style={{ margin: "16px 0px" }} />
                )}
                {authSetupStatus === "ERROR" && (
                  <Alert
                    style={{ margin: "16px 0px" }}
                    message="Something went wrong."
                    type="error"
                    showIcon
                  />
                )}

                {authSetupStatus === "SUCCESS" && qrCode !== "" && (
                  <img
                    src={qrCode}
                    className="auth_qrimage"
                    alt="no_qrimage"
                    width={240}
                  />
                )}
                {/* {qrCode ? (
                                        <img src={qrCode} className="auth_qrimage" alt="no_qrimage" width={240} />
                                        ) : (
                                        <LoadingOutlined />
                                        )}
                           */}
                <Form onSubmit={(e, values) => this.handleSubmit(e, values)}>
                  <Form.Item
                    // validateStatus={codeError ? 'error' : ''}
                    label="Authentication Code"
                    // help={passwordError || ''}
                  >
                    {getFieldDecorator("Authentication Code", {
                      rules: [
                        { required: true },
                        {
                          min: 6,
                          max: 6,
                          message: "Code must be of 6 digits ",
                        },
                      ],
                    })(
                      <Input
                        type="number"
                        className="auth-input"
                        placeholder="XXXXXX"
                        maxLength={6}
                        onChange={(e) =>
                          this.setState({ code: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>

                  <Form.Item className="login-submit-container">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={authCodeStatus === LOADING}
                    >
                      Verify Code
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setUpAuth,
  verifyAuthCode,
};

function mapStateToProps(state) {
  const { authData, authSetupStatus, authCodeStatus, authVerification } =
    state.signInState;

  return {
    authData,
    authSetupStatus,
    authCodeStatus,
    authVerification,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SetUpAuth));
