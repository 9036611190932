import Axios from "axios";
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import {
  ADD_CLIENT_MASTER_DATA,
  DELETE_CLIENT_MASTER_DATA,
  GET_CLIENT_MASTER_DATA,
  UPDATE_SINGLE_CLIENT_MASTER,
  UPDATE_CLIENT_MASTER_DATA,
  BULK_ADD_CLIENT_MASTER_DATA,
  BULK_DOWNLOAD_CLIENT_MASTER_DATA,
} from "./masterTypes";
import { ROOT_URL } from "../../../../configs/rootConfigs";
import { checkAuthorisation } from "../actions";
import { notification } from "antd";
import { onResponseError } from "./../../../../functions/commonFunctions/interceptor";

export function getClientMasterData(pageNumber, filters) {
  return async (dispatch) => {
    dispatch({ type: GET_CLIENT_MASTER_DATA, status: LOADING });
    try {
      const params = {
        page: pageNumber,
        page_size: 100,
        contain_term: filters?.contains_term ?? "",
      };

      let response = await Axios.get(`${ROOT_URL}/client_master/`, {
        params: params,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: GET_CLIENT_MASTER_DATA,
        status: SUCCESS,
        payload: response?.data,
        pageNumber,
      });
    } catch (error) {
      checkAuthorisation(dispatch, error);
      dispatch({
        type: GET_CLIENT_MASTER_DATA,
        status: ERROR,
        payload: error,
      });
    }
  };
}

export function addClientMasterData(
  body,
  pageNumber,
  filters,
  callbacksFunction = {},
) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: ADD_CLIENT_MASTER_DATA, status: LOADING });
    try {
      let response = await Axios.post(`${ROOT_URL}/client_master/`, body, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      onSuccess(response?.data);
      dispatch(getClientMasterData(pageNumber, filters));
    } catch (error) {
      onResponseError(error);
      onError(error?.response?.data);
      if (!error?.response?.data?.payload?.force_update) {
        notification["error"]({
          message: error?.response?.data?.message,
        });
      }
      dispatch(getClientMasterData(pageNumber, filters));
    }
  };
}

export function updateClientMasterData(
  body,
  pageNumber,
  filters,
  callbacksFunction = {},
) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: UPDATE_CLIENT_MASTER_DATA, status: LOADING });
    try {
      let response = await Axios.patch(`${ROOT_URL}/client_master/`, body, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      onSuccess(response?.data);
      dispatch(getClientMasterData(pageNumber, filters));
    } catch (error) {
      onResponseError(error);
      onError(error?.response?.data);
      notification["error"]({
        message: error?.response?.data?.message,
      });
      dispatch(getClientMasterData(pageNumber, filters));
    }
  };
}

export function deleteClientMasterData(
  body,
  pageNumber,
  filters,
  callbacksFunction = {},
) {
  const { onSuccess } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: DELETE_CLIENT_MASTER_DATA, status: LOADING });
    try {
      const response = await Axios.delete(`${ROOT_URL}/client_master/`, {
        data: {
          client_id: body?.client_id ?? "",
          filter_flag: Boolean(
            body?.selectAll || body?.client_master_list?.length,
          ),
          client_master_list: body?.client_master_list || [],
          contain_term: body?.contain_term ?? "",
        },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      notification["success"]({
        message: "Client Master Deleted Successfully!",
      });
      dispatch(getClientMasterData(pageNumber, filters));
      onSuccess && onSuccess(response?.data);
    } catch (error) {
      onResponseError(error);
      notification["error"]({
        message: error?.response?.data?.payload?.detail || "An error occured",
      });
      dispatch(getClientMasterData(pageNumber, filters));
    }
  };
}

export function updateSingleClientData(body) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SINGLE_CLIENT_MASTER,
      status: SUCCESS,
      payload: body,
    });
  };
}

export function addBulkClientMasterData(
  body,
  pageNumber,
  filters,
  callbacksFunction = {},
) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: BULK_ADD_CLIENT_MASTER_DATA, status: LOADING });
    try {
      let response = await Axios.post(
        `${ROOT_URL}/client_master/bulk_upload/`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      onSuccess(response?.data);
      dispatch(getClientMasterData(pageNumber, filters));
    } catch (error) {
      onResponseError(error);
      onError(error?.response?.data);
    }
  };
}

export function downloadBulkClientMasterData(body, callbacksFunction = {}) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: BULK_DOWNLOAD_CLIENT_MASTER_DATA, status: LOADING });
    notification["warning"]({
      message: "Request Under Process",
      description:
        "A request is under process. Kindly, wait for its completion.",
      duration: 2,
    });
    try {
      let response = await Axios.post(
        `${ROOT_URL}/client_master/bulk_download/`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
          responseType: "blob", // <-- Ensure binary data is received
        },
      );

      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      let excelName = `Client Master list_${new Date().getTime()}.xlsx`;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(excelBlob);
      link.download = excelName;
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);

      onSuccess(response?.data);
      dispatch({ type: BULK_DOWNLOAD_CLIENT_MASTER_DATA, status: SUCCESS });
    } catch (error) {
      onError(error?.response?.data);
      notification["error"]({
        message:
          error?.response?.data?.message ||
          "Error occurred while downloading the file.",
      });
      dispatch({ type: BULK_DOWNLOAD_CLIENT_MASTER_DATA, status: ERROR });
    }
  };
}
