//--------LIBRARIES--------
import Axios from "axios";
import { message } from "antd";

//--------ROOT_CONFIG--------
import { MGR_RT_URL } from "../../../../configs/rootConfigs";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------TYPES--------
import {
  TM_RENEWALS,
  RENEWAL_FILED,
  REPLY_TO_CORRESPONDENCE,
  PSEUDO_REPLY_TO_CORRESPONDENCE,
  OPPOSED_REPLY_TO_CORRESPONDENCE,
  PSEUDO_OPPOSED_REPLY_TO_CORRESPONDENCE,
  TOTAL_TM,
  REFINED_TRADEMARK,
  TRADEMARK_CHANGELOG,
  PROPRIETOR,
  PROPRIETOR_DETAIL,
  TLA_HEARING,
  OPPOSITION_HEARING,
  OPPOSED_TLA_HEARING,
  OPPOSED_OPPOSITION_HEARING,
  TLA_HEARING_CLASSES_FILTERS,
  TLA_HEARING_PROPRIETORS_FILTERS,
  TLA_HEARING_LOCATION_FILTERS,
  TLA_HEARING_OFFICERS_FILTERS,
  TLA_HEARING_OFFICE_FILTERS,
  TLA_HEARING_TAG_FILTERS,
  OPPOSITION_HEARING_CLASSES_FILTERS,
  OPPOSITION_HEARING_PROPRIETORS_FILTERS,
  OPPOSITION_HEARING_LOCATION_FILTERS,
  OPPOSITION_HEARING_OFFICERS_FILTERS,
  OPPOSITION_HEARING_TAG_FILTERS,
  MANAGE_TRADEMARK,
  POST_MANAGE_TRADEMARK,
  OPPOSITION_HEARING_OFFICE_FILTERS,
} from "./types";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../../commonActions/actions";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";

//--------Manager-Trademark: Renewals--------
export function getTmRenewals(pageNumber) {
  return async (dispatch) => {
    dispatch({ type: TM_RENEWALS, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/trademarks/renewals/`, {
        params: {
          page: pageNumber,
        },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: TM_RENEWALS,
        status: SUCCESS,
        payload: response?.data,
        pageNumber,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: TM_RENEWALS, status: ERROR });
    }
  };
}

//-----------Manager: renewal-Filed-----
export function renewalFiled(
  renewal,
  trademark_number,
  pseudo,
  application_list,
) {
  return async (dispatch) => {
    dispatch({ type: RENEWAL_FILED, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/trademarks/renewals/`,
        {
          renewal,
          trademark_number,
          pseudo,
          application_list,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: RENEWAL_FILED,
        status: SUCCESS,
        payload: {
          message: response?.data,
          trademark_number: trademark_number,
        },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: RENEWAL_FILED, status: ERROR });
    }
  };
}

// // --------Manager-Trademark: reply-to-correspondence--------
// export function getReplyToCorrespondence({ mmType, pseudo, filterFlag, filters, pageNumber,management_mark_id }){
//     if(management_mark_id){
//         return async (dispatch) => {
//             dispatch({ type: REPLY_TO_CORRESPONDENCE, status: LOADING });
//             try {
//                 const response = await Axios.get(`${MGR_RT_URL}/trademarks/reply_to_correspondence/`, {
//                     params: {
//                         management_mark_id,

//                     },
//                     headers: { Authorization: 'Token ' + localStorage.getItem('token') }
//                 });
//                 dispatch({
//                     type: REPLY_TO_CORRESPONDENCE,
//                     status: SUCCESS,
//                     payload: response?.data,

//                 })
//             } catch (err) {
//                 checkAuthorisation(dispatch, err);
//                 dispatch({ type: REPLY_TO_CORRESPONDENCE, status: ERROR })
//             }
//         }

//     }
//     else{

//     const classes = `${filters?.trademarkClass}`;
//     const trademark_status = `${filters?.trademarkStatus}`;
//     const trademark_type = `${filters?.trademarkType}`;
//     const states = `${filters?.trademarkStates}`;
//     const proprietor = `${filters?.trademarkProprietors}`;
//     const tags = `${filters?.trademarkTags}`;
//     const opponent = `${filters?.trademarkOpponent}`;
//     const evidence_filter = filters?.evidenceFilter;
//     const date_of_application = `${filters?.date_of_application}`;
//     const contains_term = `${filters?.containsTerm}`;
//     const alerts = filters?.trademarkAlerts ? filters?.trademarkAlerts.join(';') : `${filters?.trademarkAlerts}` ;
//     const no_Tags=filters?.trademarkNOtags;
//     // if(management_mark_id){
//     //     return async (dispatch) => {
//     //         dispatch({ type: REPLY_TO_CORRESPONDENCE, status: LOADING });
//     //         try {
//     //             const response = await Axios.get(`${MGR_RT_URL}/trademarks/reply_to_correspondence/`, {
//     //                 params: {
//     //                     management_mark_id

//     //                 },
//     //                 headers: { Authorization: 'Token ' + localStorage.getItem('token') }
//     //             });
//     //             dispatch({
//     //                 type: REPLY_TO_CORRESPONDENCE,
//     //                 status: SUCCESS,
//     //                 payload: response,

//     //             })
//     //         } catch (err) {
//     //             checkAuthorisation(dispatch, err);
//     //             dispatch({ type: REPLY_TO_CORRESPONDENCE, status: ERROR })
//     //         }
//     //     }

//     // }

//     return async (dispatch) => {
//         switch (mmType) {
//             case 'trademark':
//                 if (pseudo) {
//                     dispatch({ type: PSEUDO_REPLY_TO_CORRESPONDENCE, status: LOADING });
//                     try {
//                         let response;
//                         response = await Axios.get(`${MGR_RT_URL}/trademarks/reply_to_correspondence/`, {
//                             params: {
//                                 mm_type: mmType,
//                                 pseudo: pseudo,
//                                 filter_flag: filterFlag ? filterFlag: null,
//                                 classes: filterFlag ? classes: null,
//                                 trademark_status: filterFlag ? trademark_status: null,
//                                 trademark_type: filterFlag ? trademark_type: null,
//                                 states: filterFlag ? states: null,
//                                 proprietor: filterFlag ? proprietor: null,
//                                 tags: filterFlag ? tags: null,
//                                 opponent: filterFlag ? opponent: null,
//                                 evidence_filter: filterFlag ? evidence_filter: null,
//                                 date_of_application: filterFlag ? date_of_application: null,
//                                 contains_term: filterFlag ? contains_term: null,
//                                 page: pageNumber,
//                                 alerts:filterFlag ?  alerts : null,
//                                 no_tags: tags ? null: no_Tags.length>0 ? true: null,
//                             },
//                             headers: { Authorization: 'Token ' + localStorage.getItem('token') }
//                         });
//                         dispatch({
//                             type: PSEUDO_REPLY_TO_CORRESPONDENCE,
//                             status: SUCCESS,
//                             payload: response?.data,
//                             pseudo,
//                             lastAppliedFilters: {
//                                 ...filters,
//                                 filterFlag
//                             },
//                             pageNumber,
//                         });
//                     }
//                     catch (err) {
//                         checkAuthorisation(dispatch, err);
//                         dispatch({ type: PSEUDO_REPLY_TO_CORRESPONDENCE, status: ERROR })
//                     }
//                 }
//                 else {
//                     dispatch({ type: REPLY_TO_CORRESPONDENCE, status: LOADING });
//                     try {
//                         let response;
//                         response = await Axios.get(`${MGR_RT_URL}/trademarks/reply_to_correspondence/`, {
//                             params: {
//                                 mm_type: mmType,
//                                 filter_flag: filterFlag ? filterFlag: null,
//                                 classes: filterFlag ? classes: null,
//                                 trademark_status: filterFlag ? trademark_status: null,
//                                 trademark_type: filterFlag ? trademark_type: null,
//                                 states: filterFlag ? states: null,
//                                 proprietor: filterFlag ? proprietor: null,
//                                 tags: filterFlag ? tags: null,
//                                 opponent: filterFlag ? opponent: null,
//                                 evidence_filter: filterFlag ? evidence_filter: null,
//                                 date_of_application: filterFlag ? date_of_application: null,
//                                 contains_term: filterFlag ? contains_term: null,
//                                 page: pageNumber,
//                                 alerts:filterFlag ?  alerts : null,
//                                 no_tags: tags ? null: no_Tags.length>0 ? true: null,
//                             },
//                             headers: {Authorization: 'Token ' + localStorage.getItem('token')}
//                         });
//                         dispatch({
//                             type: REPLY_TO_CORRESPONDENCE,
//                             status: SUCCESS,
//                             payload: response?.data,
//                             lastAppliedFilters: {
//                                 ...filters,
//                                 filterFlag
//                             },
//                             pageNumber,
//                         });
//                     }
//                     catch (err) {
//                         checkAuthorisation(dispatch, err);
//                         dispatch({type: REPLY_TO_CORRESPONDENCE, status: ERROR})
//                     }
//                 }
//             break;

//             case 'opposed':
//                 if (pseudo) {
//                     dispatch({ type: PSEUDO_OPPOSED_REPLY_TO_CORRESPONDENCE, status: LOADING });
//                     try {
//                         let response;
//                         response = await Axios.get(`${MGR_RT_URL}/trademarks/reply_to_correspondence/`, {
//                             params: {
//                                 mm_type: mmType,
//                                 pseudo: pseudo,
//                                 filter_flag: filterFlag ? filterFlag: null,
//                                 classes: filterFlag ? classes: null,
//                                 trademark_status: filterFlag ? trademark_status: null,
//                                 trademark_type: filterFlag ? trademark_type: null,
//                                 states: filterFlag ? states: null,
//                                 proprietor: filterFlag ? proprietor: null,
//                                 tags: filterFlag ? tags: null,
//                                 opponent: filterFlag ? opponent: null,
//                                 evidence_filter: filterFlag ? evidence_filter: null,
//                                 date_of_application: filterFlag ? date_of_application: null,
//                                 contains_term: filterFlag ? contains_term: null,
//                                 page: pageNumber,
//                             },
//                             headers: { Authorization: 'Token ' + localStorage.getItem('token') }
//                         });
//                         dispatch({
//                             type: PSEUDO_OPPOSED_REPLY_TO_CORRESPONDENCE,
//                             status: SUCCESS,
//                             payload: response?.data,
//                             pseudo,
//                             lastAppliedFilters: {
//                                 ...filters,
//                                 filterFlag
//                             },
//                             pageNumber,
//                         });
//                     }
//                     catch (err) {
//                         checkAuthorisation(dispatch, err);
//                         dispatch({ type: PSEUDO_OPPOSED_REPLY_TO_CORRESPONDENCE, status: ERROR })
//                     }
//                 }
//                 else {
//                     dispatch({ type: OPPOSED_REPLY_TO_CORRESPONDENCE, status: LOADING });
//                     try {
//                         let response;
//                         response = await Axios.get(`${MGR_RT_URL}/trademarks/reply_to_correspondence/`, {
//                             params: {
//                                 mm_type: mmType,
//                                 filter_flag: filterFlag ? filterFlag: null,
//                                 classes: filterFlag ? classes: null,
//                                 trademark_status: filterFlag ? trademark_status: null,
//                                 trademark_type: filterFlag ? trademark_type: null,
//                                 states: filterFlag ? states: null,
//                                 proprietor: filterFlag ? proprietor: null,
//                                 tags: filterFlag ? tags: null,
//                                 opponent: filterFlag ? opponent: null,
//                                 evidence_filter: filterFlag ? evidence_filter: null,
//                                 date_of_application: filterFlag ? date_of_application: null,
//                                 contains_term: filterFlag ? contains_term: null,
//                                 page: pageNumber,
//                             },
//                             headers: {Authorization: 'Token ' + localStorage.getItem('token')}
//                         });
//                         dispatch({
//                             type: OPPOSED_REPLY_TO_CORRESPONDENCE,
//                             status: SUCCESS,
//                             payload: response?.data,
//                             lastAppliedFilters: {
//                                 ...filters,
//                                 filterFlag
//                             },
//                             pageNumber,
//                         });
//                     }
//                     catch (err) {
//                         checkAuthorisation(dispatch, err);
//                         dispatch({type: OPPOSED_REPLY_TO_CORRESPONDENCE, status: ERROR})
//                     }
//                 }
//             break;

//         default:
//             break;
//     }

//     }}}

// --------Manager-Trademark: getReplyToCorrespondenceDashboard--------
export function getReplyToCorrespondenceDashboard({
  actionType,
  mmType,
  pseudo,
  filterFlag,
  filters,
  pageNumber,
}) {
  const trademark_status = `${filters?.trademarkStatus}`;
  const evidence_filter = filters?.evidenceFilter;
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      const response = await Axios.get(
        `${MGR_RT_URL}/trademarks/reply_to_correspondence/`,
        {
          params: {
            mm_type: mmType,
            pseudo: pseudo ? pseudo : null,
            filter_flag: filterFlag ? filterFlag : null,
            trademark_status: checkValue(trademark_status)
              ? trademark_status
              : null,
            evidence_filter: evidence_filter ? evidence_filter : null,
            page: pageNumber,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
        pseudo,
        pageNumber,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: actionType, status: ERROR });
    }
  };
}

//-----------Manager: reply-Filed-to-correspondence-----
export function replyFiledToCorrespondence(
  actionType,
  mm_type,
  pending_reply,
  trademark_number,
  pseudo = false,
  filter_flag,
  evidence_filter,
  trademark_status,
  mark_type,
  selectAll,
  filters,
) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/trademarks/reply_to_correspondence/`,
        {
          pending_reply: pending_reply,
          application_list: trademark_number,
          pseudo: pseudo,
          select_all: selectAll,

          filter_flag: filters ? filters?.filterFlag : null,
          filters: filters
            ? {
                classes: filters?.trademarkClass,
                trademark_status: filters?.trademarkStatus,
                trademark_type: filters?.trademarkType,
                states: filters?.trademarkStates,
                proprietor: filters?.trademarkProprietors,
                tags: filters?.trademarkTags,
                opponent: filters?.trademarkOpponent,
                date_of_application: filters?.date_of_application,
                contains_term: filters?.containsTerm,
              }
            : null,
          // filter_flag:filters?.filterFlag,

          // evidence_filter: filter_flag ? evidence_filter : null,
          // trademark_status: filter_flag ? trademark_status : null,
          mark_type: mark_type,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: actionType,
        status: SUCCESS,
        mm_type: mm_type,
        payload: {
          message: response?.data,
          trademark_number: trademark_number,
        },
        pseudo,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: actionType, status: ERROR });
    }
  };
}

//--------Manager-Trademark: TLA Opposition--------
export function causeListHearings({
  mmType,
  upcomingHearings,
  tla,
  filterFlag,
  filters,
  pageNumber,
  filters_required_flag,
  required_filter,
  filter_contains_term,
}) {
  const classes = `${filters?.trademarkClass}`;
  const location = `${filters?.hearingLocation}`;
  const officer = `${filters?.hearingOfficer}`;
  const proprietor = `${filters?.trademarkProprietors}`;
  const tags = `${filters?.trademarkTags}`;
  const date_of_hearing = `${filters?.hearing_date}`;
  const contains_term = `${filters?.containsTerm}`;
  const no_Tags = filters?.trademarkNOtags || [];
  return async (dispatch) => {
    if (tla) {
      if (filters_required_flag && required_filter === "CLASSES") {
        dispatch({ type: TLA_HEARING_CLASSES_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_CLASSES_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_CLASSES_FILTERS, status: ERROR });
        }
      } else if (filters_required_flag && required_filter === "PROPRIETOR") {
        dispatch({ type: TLA_HEARING_PROPRIETORS_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_PROPRIETORS_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_PROPRIETORS_FILTERS, status: ERROR });
        }
      } else if (
        filters_required_flag &&
        required_filter === "HEARING_LOCATION"
      ) {
        dispatch({ type: TLA_HEARING_LOCATION_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_LOCATION_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_LOCATION_FILTERS, status: ERROR });
        }
      } else if (
        filters_required_flag &&
        required_filter === "HEARING_OFFICERS"
      ) {
        dispatch({ type: TLA_HEARING_OFFICERS_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_OFFICERS_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_OFFICERS_FILTERS, status: ERROR });
        }
      } else if (filters_required_flag && required_filter === "TAG") {
        dispatch({ type: TLA_HEARING_TAG_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_TAG_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_TAG_FILTERS, status: ERROR });
        }
      } else {
        dispatch({ type: TLA_HEARING, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? (no_Tags.length > 0 ? null : tags) : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING, status: ERROR });
        }
      }
    } else {
      if (filters_required_flag && required_filter === "CLASSES") {
        dispatch({ type: OPPOSITION_HEARING_CLASSES_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: OPPOSITION_HEARING_CLASSES_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          dispatch({ type: OPPOSITION_HEARING_CLASSES_FILTERS, status: ERROR });
        }
      } else if (filters_required_flag && required_filter === "PROPRIETOR") {
        dispatch({
          type: OPPOSITION_HEARING_PROPRIETORS_FILTERS,
          status: LOADING,
        });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: OPPOSITION_HEARING_PROPRIETORS_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({
            type: OPPOSITION_HEARING_PROPRIETORS_FILTERS,
            status: ERROR,
          });
        }
      } else if (
        filters_required_flag &&
        required_filter === "HEARING_LOCATION"
      ) {
        dispatch({
          type: OPPOSITION_HEARING_LOCATION_FILTERS,
          status: LOADING,
        });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: OPPOSITION_HEARING_LOCATION_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({
            type: OPPOSITION_HEARING_LOCATION_FILTERS,
            status: ERROR,
          });
        }
      } else if (
        filters_required_flag &&
        required_filter === "HEARING_OFFICERS"
      ) {
        dispatch({
          type: OPPOSITION_HEARING_OFFICERS_FILTERS,
          status: LOADING,
        });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: OPPOSITION_HEARING_OFFICERS_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({
            type: OPPOSITION_HEARING_OFFICERS_FILTERS,
            status: ERROR,
          });
        }
      } else if (filters_required_flag && required_filter === "TAG") {
        dispatch({ type: OPPOSITION_HEARING_TAG_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: OPPOSITION_HEARING_TAG_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: OPPOSITION_HEARING_TAG_FILTERS, status: ERROR });
        }
      } else {
        dispatch({ type: OPPOSITION_HEARING, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/cause_list_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? (no_Tags.length > 0 ? null : tags) : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: OPPOSITION_HEARING,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          dispatch({ type: OPPOSITION_HEARING, status: ERROR });
        }
      }
    }
  };
}
//--------TLA DashBoard IP India--------
export function ipIndiaHearings({
  mmType,
  upcomingHearings,
  tla,
  filterFlag,
  filters,
  pageNumber,
  filters_required_flag,
  required_filter,
  filter_contains_term,
}) {
  const classes = `${filters?.trademarkClass}`;
  const location = `${filters?.hearingLocation}`;
  const officer = `${filters?.hearingOfficer}`;
  const proprietor = `${filters?.trademarkProprietors}`;
  const tags = `${filters?.trademarkTags}`;
  const date_of_hearing = `${filters?.hearing_date}`;
  const contains_term = `${filters?.containsTerm}`;
  const office = `${filters?.hearingOffice}`;
  const no_Tags = filters?.trademarkNOtags;

  return async (dispatch) => {
    if (tla) {
      if (filters_required_flag && required_filter === "CLASSES") {
        dispatch({ type: TLA_HEARING_CLASSES_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/ip_india_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_CLASSES_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_CLASSES_FILTERS, status: ERROR });
        }
      } else if (filters_required_flag && required_filter === "PROPRIETOR") {
        dispatch({ type: TLA_HEARING_PROPRIETORS_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/ip_india_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_PROPRIETORS_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_PROPRIETORS_FILTERS, status: ERROR });
        }
      } else if (
        filters_required_flag &&
        required_filter === "HEARING_LOCATION"
      ) {
        dispatch({ type: TLA_HEARING_LOCATION_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/ip_india_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_LOCATION_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_LOCATION_FILTERS, status: ERROR });
        }
      } else if (
        filters_required_flag &&
        required_filter === "HEARING_OFFICERS"
      ) {
        dispatch({ type: TLA_HEARING_OFFICERS_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/ip_india_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_OFFICERS_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_OFFICERS_FILTERS, status: ERROR });
        }
      } else if (filters_required_flag && required_filter === "TAG") {
        dispatch({ type: TLA_HEARING_TAG_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/ip_india_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_TAG_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_TAG_FILTERS, status: ERROR });
        }
      } else if (
        filters_required_flag &&
        required_filter === "HEARING_OFFICE"
      ) {
        dispatch({ type: TLA_HEARING_OFFICE_FILTERS, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/ip_india_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING_OFFICE_FILTERS,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
          });
        } catch (err) {
          dispatch({ type: TLA_HEARING_OFFICE_FILTERS, status: ERROR });
        }
      } else {
        dispatch({ type: TLA_HEARING, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/ip_india_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                hearing_office: filterFlag ? office : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: TLA_HEARING,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: TLA_HEARING, status: ERROR });
        }
      }
    } else {
      if (filters_required_flag) {
        dispatch({
          type: `OPPOSITION_HEARING_${required_filter === "PROPRIETOR" ? "PROPRIETORS" : required_filter}_FILTERS`,
          status: LOADING,
        });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/ip_india_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                location: filterFlag ? location : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: `OPPOSITION_HEARING_${required_filter === "PROPRIETOR" ? "PROPRIETORS" : required_filter}_FILTERS`,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          dispatch({
            type: `OPPOSITION_HEARING_${required_filter === "PROPRIETOR" ? "PROPRIETORS" : required_filter}_FILTERS`,
            status: ERROR,
          });
        }
      } else {
        dispatch({ type: OPPOSITION_HEARING, status: LOADING });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/ip_india_tla_opposition_hearing/`,
            {
              params: {
                mm_type: mmType,
                upcoming_hearings: upcomingHearings,
                tla,
                filter_flag: filterFlag ? filterFlag : null,
                classes: filterFlag ? classes : null,
                hearing_office: filterFlag ? office : null,
                officer: filterFlag ? officer : null,
                proprietor: filterFlag ? proprietor : null,
                tags: filterFlag ? tags : null,
                date_of_hearing: filterFlag ? date_of_hearing : null,
                contains_term: filterFlag ? contains_term : null,
                no_tags: tags ? null : no_Tags.length > 0 ? true : null,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: OPPOSITION_HEARING,
            status: SUCCESS,
            payload: response?.data,
            upcomingHearings,
            tla,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: OPPOSITION_HEARING, status: ERROR });
        }
      }
    }
  };
}

//--------Manager-Trademark: TLA Opposition--------
export function getTLAOpposition({
  mmType,
  upcomingHearings,
  tla,
  filterFlag,
  filters,
  pageNumber,
}) {
  const classes = `${filters?.trademarkClass}`;
  const location = `${filters?.hearingLocation}`;
  const officer = `${filters?.hearingOfficer}`;
  const proprietor = `${filters?.trademarkProprietors}`;
  const tags = `${filters?.trademarkTags}`;
  const date_of_hearing = `${filters?.hearing_date}`;
  const contains_term = `${filters?.containsTerm}`;

  return async (dispatch) => {
    switch (mmType) {
      case "trademark":
        if (tla) {
          dispatch({ type: TLA_HEARING, status: LOADING });
          try {
            let response;
            response = await Axios.get(
              `${MGR_RT_URL}/trademarks/tla_opposition/`,
              {
                params: {
                  mm_type: mmType,
                  upcoming_hearings: upcomingHearings,
                  tla,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  location: filterFlag ? location : null,
                  officer: filterFlag ? officer : null,
                  proprietor: filterFlag ? proprietor : null,
                  tags: filterFlag ? tags : null,
                  date_of_hearing: filterFlag ? date_of_hearing : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              },
            );
            dispatch({
              type: TLA_HEARING,
              status: SUCCESS,
              payload: response?.data,
              upcomingHearings,
              tla,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: TLA_HEARING, status: ERROR });
          }
        } else {
          dispatch({ type: OPPOSITION_HEARING, status: LOADING });
          try {
            let response;
            response = await Axios.get(
              `${MGR_RT_URL}/trademarks/tla_opposition/`,
              {
                params: {
                  mm_type: mmType,
                  upcoming_hearings: upcomingHearings,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  location: filterFlag ? location : null,
                  officer: filterFlag ? officer : null,
                  proprietor: filterFlag ? proprietor : null,
                  tags: filterFlag ? tags : null,
                  date_of_hearing: filterFlag ? date_of_hearing : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                  page_size: 100,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              },
            );
            dispatch({
              type: OPPOSITION_HEARING,
              status: SUCCESS,
              payload: response?.data,
              upcomingHearings,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: OPPOSITION_HEARING, status: ERROR });
          }
        }
        break;

      case "opposed":
        if (tla) {
          dispatch({ type: OPPOSED_TLA_HEARING, status: LOADING });
          try {
            let response;
            response = await Axios.get(
              `${MGR_RT_URL}/trademarks/tla_opposition/`,
              {
                params: {
                  mm_type: mmType,
                  upcoming_hearings: upcomingHearings,
                  tla,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  location: filterFlag ? location : null,
                  officer: filterFlag ? officer : null,
                  proprietor: filterFlag ? proprietor : null,
                  tags: filterFlag ? tags : null,
                  date_of_hearing: filterFlag ? date_of_hearing : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                  page_size: 100,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              },
            );
            dispatch({
              type: OPPOSED_TLA_HEARING,
              status: SUCCESS,
              payload: response?.data,
              upcomingHearings,
              tla,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: OPPOSED_TLA_HEARING, status: ERROR });
          }
        } else {
          dispatch({ type: OPPOSED_OPPOSITION_HEARING, status: LOADING });
          try {
            let response;
            response = await Axios.get(
              `${MGR_RT_URL}/trademarks/tla_opposition/`,
              {
                params: {
                  mm_type: mmType,
                  upcoming_hearings: upcomingHearings,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  location: filterFlag ? location : null,
                  officer: filterFlag ? officer : null,
                  proprietor: filterFlag ? proprietor : null,
                  tags: filterFlag ? tags : null,
                  date_of_hearing: filterFlag ? date_of_hearing : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                  page_size: 10,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              },
            );
            dispatch({
              type: OPPOSED_OPPOSITION_HEARING,
              status: SUCCESS,
              payload: response?.data,
              upcomingHearings,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: OPPOSED_OPPOSITION_HEARING, status: ERROR });
          }
        }
        break;

      default:
        break;
    }
  };
}
//--------TLA DashBoard IP India--------
export function getTLAOppositionIP({
  mmType,
  upcomingHearings,
  tla,
  filterFlag,
  filters,
  pageNumber,
}) {
  const classes = `${filters?.trademarkClass}`;
  const location = `${filters?.hearingLocation}`;
  const officer = `${filters?.hearingOfficer}`;
  const proprietor = `${filters?.trademarkProprietors}`;
  const tags = `${filters?.trademarkTags}`;
  const date_of_hearing = `${filters?.hearing_date}`;
  const contains_term = `${filters?.containsTerm}`;

  return async (dispatch) => {
    switch (mmType) {
      case "trademark":
        if (tla) {
          dispatch({ type: TLA_HEARING, status: LOADING });
          try {
            let response;
            response = await Axios.get(
              `${MGR_RT_URL}/trademarks/tla_hearing/`,
              {
                params: {
                  mm_type: mmType,
                  upcoming_hearings: upcomingHearings,
                  tla,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  location: filterFlag ? location : null,
                  officer: filterFlag ? officer : null,
                  proprietor: filterFlag ? proprietor : null,
                  tags: filterFlag ? tags : null,
                  date_of_hearing: filterFlag ? date_of_hearing : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              },
            );
            dispatch({
              type: TLA_HEARING,
              status: SUCCESS,
              payload: response?.data,
              upcomingHearings,
              tla,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: TLA_HEARING, status: ERROR });
          }
        } else {
          dispatch({ type: OPPOSITION_HEARING, status: LOADING });
          try {
            let response;
            response = await Axios.get(
              `${MGR_RT_URL}/trademarks/tla_opposition/`,
              {
                params: {
                  mm_type: mmType,
                  upcoming_hearings: upcomingHearings,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  location: filterFlag ? location : null,
                  officer: filterFlag ? officer : null,
                  proprietor: filterFlag ? proprietor : null,
                  tags: filterFlag ? tags : null,
                  date_of_hearing: filterFlag ? date_of_hearing : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              },
            );
            dispatch({
              type: OPPOSITION_HEARING,
              status: SUCCESS,
              payload: response?.data,
              upcomingHearings,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: OPPOSITION_HEARING, status: ERROR });
          }
        }
        break;

      case "opposed":
        if (tla) {
          dispatch({ type: OPPOSED_TLA_HEARING, status: LOADING });
          try {
            let response;
            response = await Axios.get(
              `${MGR_RT_URL}/trademarks/tla_opposition/`,
              {
                params: {
                  mm_type: mmType,
                  upcoming_hearings: upcomingHearings,
                  tla,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  location: filterFlag ? location : null,
                  officer: filterFlag ? officer : null,
                  proprietor: filterFlag ? proprietor : null,
                  tags: filterFlag ? tags : null,
                  date_of_hearing: filterFlag ? date_of_hearing : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              },
            );
            dispatch({
              type: OPPOSED_TLA_HEARING,
              status: SUCCESS,
              payload: response?.data,
              upcomingHearings,
              tla,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: OPPOSED_TLA_HEARING, status: ERROR });
          }
        } else {
          dispatch({ type: OPPOSED_OPPOSITION_HEARING, status: LOADING });
          try {
            let response;
            response = await Axios.get(
              `${MGR_RT_URL}/trademarks/tla_opposition/`,
              {
                params: {
                  mm_type: mmType,
                  upcoming_hearings: upcomingHearings,
                  filter_flag: filterFlag ? filterFlag : null,
                  classes: filterFlag ? classes : null,
                  location: filterFlag ? location : null,
                  officer: filterFlag ? officer : null,
                  proprietor: filterFlag ? proprietor : null,
                  tags: filterFlag ? tags : null,
                  date_of_hearing: filterFlag ? date_of_hearing : null,
                  contains_term: filterFlag ? contains_term : null,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              },
            );
            dispatch({
              type: OPPOSED_OPPOSITION_HEARING,
              status: SUCCESS,
              payload: response?.data,
              upcomingHearings,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: OPPOSED_OPPOSITION_HEARING, status: ERROR });
          }
        }
        break;

      default:
        break;
    }
  };
}

//tla dashboard download report--------------
export function downloadHearingReport({
  hearing_type,
  upcomingHearings,
  mm_type,
  selectAll,
  selectedRows,
  filterFlag,
  filters,
  report_columns,
  report_name,
}) {
  return async (dispatch) => {
    // dispatch({ type: HEARING_REPORT_DOWNLOAD, status: LOADING });

    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/report/`,
        {
          hearing_flag: "true",
          hearing_type,
          mm_type,
          upcoming_hearings: !upcomingHearings,
          select_all: selectAll,
          selected_hearings: selectAll ? [] : selectedRows,
          filter_flag: filterFlag,
          filters: {
            ...filters,
          },
          report_columns,
          report_name,
          report_type: "EXCEL",
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      //   dispatch({ type: HEARING_REPORT_DOWNLOAD, status: SUCCESS, payload: response?.data });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      // dispatch({ type: HEARING_REPORT_DOWNLOAD, status: ERROR, payload: err.response })
    }
  };
}
//tla dashboard download report--------------
export function getTLAOppositionIPReport({
  mmType,
  upcomingHearings,
  tla,
  filterFlag,
  filters,
  tla_id_list,
  pageNumber,
  selectAll,
}) {
  const classes = `${filters?.trademarkClass}`;
  const location = `${filters?.hearingLocation}`;
  const officer = `${filters?.hearingOfficer}`;
  const proprietor = `${filters?.trademarkProprietors}`;
  const tags = `${filters?.trademarkTags}`;
  const date_of_hearing = `${filters?.hearing_date}`;
  const contains_term = `${filters?.containsTerm}`;
  const MANAGER_DOWNLOAD_REPORT = "MANAGER_DOWNLOAD_REPORT";
  const alerts = filters?.trademarkAlerts
    ? filters?.trademarkAlerts.join(";")
    : `${filters?.trademarkAlerts}`;

  return async (dispatch) => {
    dispatch({ type: MANAGER_DOWNLOAD_REPORT, status: LOADING });

    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/trademarks/tla_hearing/`,
        {
          mm_type: mmType,
          upcoming_hearings: upcomingHearings,
          tla,
          filter_flag: filterFlag ? filterFlag : null,
          classes: filterFlag ? classes : null,
          location: filterFlag ? location : null,
          officer: filterFlag ? officer : null,
          proprietor: filterFlag ? proprietor : null,
          tags: filterFlag ? tags : null,
          date_of_hearing: filterFlag ? date_of_hearing : null,
          contains_term: filterFlag ? contains_term : null,
          page: pageNumber,
          alerts: filterFlag ? alerts : null,
          tla_id_list: selectAll ? [] : tla_id_list,
          select_all: selectAll,
        },

        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: MANAGER_DOWNLOAD_REPORT,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: MANAGER_DOWNLOAD_REPORT,
        status: ERROR,
        payload: err.response,
      });
    }

    //     switch (mmType) {
    //         case 'trademark':
    //             if (tla) {
    //                 dispatch({ type: TLA_HEARING, status: LOADING });
    //                 try {
    //                     let response;
    //                     response = await Axios.post(`${MGR_RT_URL}/trademarks/tla_hearing/`, {
    //                         params: {
    //                             mm_type: mmType,
    //                             upcoming_hearings: upcomingHearings,
    //                             tla,
    //                             filter_flag: filterFlag ? filterFlag: null,
    //                             classes: filterFlag ? classes: null,
    //                             location: filterFlag ? location: null,
    //                             officer: filterFlag ? officer: null,
    //                             proprietor: filterFlag ? proprietor: null,
    //                             tags: filterFlag ? tags: null,
    //                             date_of_hearing: filterFlag ? date_of_hearing: null,
    //                             contains_term: filterFlag ? contains_term: null,
    //                             page: pageNumber,
    //                             tla_id_list:tla_id_list
    //                         },
    //                         headers: { Authorization: 'Token ' + localStorage.getItem('token') }
    //                     });
    //                     dispatch({
    //                         type: TLA_HEARING,
    //                         status: SUCCESS,
    //                         payload: response?.data,
    //                         upcomingHearings,
    //                         tla,
    //                         lastAppliedFilters: {
    //                             ...filters,
    //                             filterFlag
    //                         },
    //                         pageNumber,
    //                     });
    //                 }
    //                 catch (err) {
    //                     checkAuthorisation(dispatch, err);
    //                     dispatch({ type: TLA_HEARING, status: ERROR })
    //                 }
    //             }
    //             else {
    //                 dispatch({ type: OPPOSITION_HEARING, status: LOADING });
    //                 try {
    //                     let response;
    //                     response = await Axios.post(`${MGR_RT_URL}/trademarks/tla_opposition/`, {
    //                         params: {
    //                             mm_type: mmType,
    //                             upcoming_hearings: upcomingHearings,
    //                             filter_flag: filterFlag ? filterFlag: null,
    //                             classes: filterFlag ? classes: null,
    //                             location: filterFlag ? location: null,
    //                             officer: filterFlag ? officer: null,
    //                             proprietor: filterFlag ? proprietor: null,
    //                             tags: filterFlag ? tags: null,
    //                             date_of_hearing: filterFlag ? date_of_hearing: null,
    //                             contains_term: filterFlag ? contains_term: null,
    //                             page: pageNumber,
    //                             tla_id_list:tla_id_list
    //                         },
    //                         headers: {Authorization: 'Token ' + localStorage.getItem('token')}
    //                     });
    //                     dispatch({
    //                         type: OPPOSITION_HEARING,
    //                         status: SUCCESS,
    //                         payload: response?.data,
    //                         upcomingHearings,
    //                         lastAppliedFilters: {
    //                             ...filters,
    //                             filterFlag
    //                         },
    //                         pageNumber,
    //                     });
    //                 }
    //                 catch (err) {
    //                     checkAuthorisation(dispatch, err);
    //                     dispatch({type: OPPOSITION_HEARING, status: ERROR})
    //                 }
    //             }
    //             break;

    //         case 'opposed':
    //             if (tla) {
    //                 dispatch({ type: OPPOSED_TLA_HEARING, status: LOADING });
    //                 try {
    //                     let response;
    //                     response = await Axios.post(`${MGR_RT_URL}/trademarks/tla_opposition/`, {
    //                         params: {
    //                             mm_type: mmType,
    //                             upcoming_hearings: upcomingHearings,
    //                             tla,
    //                             filter_flag: filterFlag ? filterFlag: null,
    //                             classes: filterFlag ? classes: null,
    //                             location: filterFlag ? location: null,
    //                             officer: filterFlag ? officer: null,
    //                             proprietor: filterFlag ? proprietor: null,
    //                             tags: filterFlag ? tags: null,
    //                             date_of_hearing: filterFlag ? date_of_hearing: null,
    //                             contains_term: filterFlag ? contains_term: null,
    //                             page: pageNumber,
    //                             tla_id_list:tla_id_list
    //                         },
    //                         headers: { Authorization: 'Token ' + localStorage.getItem('token') }
    //                     });
    //                     dispatch({
    //                         type: OPPOSED_TLA_HEARING,
    //                         status: SUCCESS,
    //                         payload: response?.data,
    //                         upcomingHearings,
    //                         tla,
    //                         lastAppliedFilters: {
    //                             ...filters,
    //                             filterFlag
    //                         },
    //                         pageNumber,
    //                     });
    //                 }
    //                 catch (err) {
    //                     checkAuthorisation(dispatch, err);
    //                     dispatch({ type: OPPOSED_TLA_HEARING, status: ERROR })
    //                 }
    //             }
    //             else {
    //                 dispatch({ type: OPPOSED_OPPOSITION_HEARING, status: LOADING });
    //                 try {
    //                     let response;
    //                     response = await Axios.post(`${MGR_RT_URL}/trademarks/tla_opposition/`, {
    //                         params: {
    //                             mm_type: mmType,
    //                             upcoming_hearings: upcomingHearings,
    //                             filter_flag: filterFlag ? filterFlag: null,
    //                             classes: filterFlag ? classes: null,
    //                             location: filterFlag ? location: null,
    //                             officer: filterFlag ? officer: null,
    //                             proprietor: filterFlag ? proprietor: null,
    //                             tags: filterFlag ? tags: null,
    //                             date_of_hearing: filterFlag ? date_of_hearing: null,
    //                             contains_term: filterFlag ? contains_term: null,
    //                             page: pageNumber,
    //                             tla_id_list:tla_id_list
    //                         },
    //                         headers: {Authorization: 'Token ' + localStorage.getItem('token')}
    //                     });
    //                     dispatch({
    //                         type: OPPOSED_OPPOSITION_HEARING,
    //                         status: SUCCESS,
    //                         payload: response?.data,
    //                         upcomingHearings,
    //                         lastAppliedFilters: {
    //                             ...filters,
    //                             filterFlag
    //                         },
    //                         pageNumber,
    //                     });
    //                 }
    //                 catch (err) {
    //                     checkAuthorisation(dispatch, err);
    //                     dispatch({type: OPPOSED_OPPOSITION_HEARING, status: ERROR})
    //                 }
    //             }
    //             break;

    //         default:
    //             break;
    //     }
    // }
  };
}

//--------Manager-Trademark: refined-Trademark--------
export function getRefinedTrademark(id) {
  return async (dispatch) => {
    dispatch({ type: REFINED_TRADEMARK, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/refined_trademarks/${id}`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: REFINED_TRADEMARK,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: REFINED_TRADEMARK, status: ERROR });
    }
  };
}

//--------Manager-Trademark: Changelog--------
export function getTrademarkChangelog(id) {
  return async (dispatch) => {
    dispatch({ type: TRADEMARK_CHANGELOG, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/trademarks/changelog/${id}`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: TRADEMARK_CHANGELOG,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: TRADEMARK_CHANGELOG, status: ERROR });
    }
  };
}

//--------Manager-Trademark: Proprietors--------
export function getProprietor(id) {
  return async (dispatch) => {
    dispatch({ type: PROPRIETOR, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/proprietors/${id}`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({ type: PROPRIETOR, status: SUCCESS, payload: response?.data });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: PROPRIETOR, status: ERROR });
    }
  };
}

//--------Manager-Trademark: Proprietor-Detail--------
export function getProprietorDetail(id) {
  return async (dispatch) => {
    dispatch({ type: PROPRIETOR_DETAIL, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/proprietors/${id}/marks/`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: PROPRIETOR_DETAIL,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: PROPRIETOR_DETAIL, status: ERROR });
    }
  };
}

//--------Manager: Get Manage Trademark --------
export function getManageTrademark({
  mmType,
  filterFlag,
  filters,
  pageNumber,
}) {
  const classes = `${filters?.trademarkClass}`;
  const proprietors = `${filters?.trademarkProprietors}`;
  const tags = `${filters?.trademarkTags}`;
  const contains_term = `${filters?.containsTerm}`;
  const no_Tags = filters?.trademarkNOtags;

  return async (dispatch) => {
    dispatch({ type: MANAGE_TRADEMARK, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/manage/trademarks/`, {
        params: {
          mm_type: mmType,
          filter_flag: filterFlag ? filterFlag : null,
          classes: filterFlag ? classes : null,
          proprietors: filterFlag ? proprietors : null,
          tags: filterFlag ? tags : null,
          contains_term: filterFlag ? contains_term : null,
          page: pageNumber,
          no_tags: tags ? null : no_Tags.length > 0 ? true : null,
        },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: MANAGE_TRADEMARK,
        status: SUCCESS,
        payload: response?.data,
        lastAppliedFilters: {
          ...filters,
          filterFlag,
        },
        trademarkType: mmType,
        pageNumber,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: MANAGE_TRADEMARK, status: ERROR });
    }
  };
}

//--------Manager: Post Manage Trademark --------
export function postManageTrademark({
  mmType,
  setters,
  select_all,
  delete_flag,
  filterFlag,
  filters,
  trademarks,
  application_number,
}) {
  return async (dispatch) => {
    dispatch({
      type: POST_MANAGE_TRADEMARK,
      status: LOADING,
      deleteFlag: delete_flag,
    });
    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/manage/trademarks/`,
        {
          mm_type: mmType,
          ...setters,
          select_all,
          delete_flag,
          filter_flag: filterFlag,
          filters: {
            classes: filters?.trademarkClass,
            proprietors: filters?.trademarkProprietors,
            tags: filters?.trademarkTag,
            contains_term: filters?.containsTerm,
          },
          application_number,
          trademarks,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: POST_MANAGE_TRADEMARK,
        status: SUCCESS,
        payload: response?.data,
        trademarkType: mmType,
        setters,
        selectAll: select_all,
        deleteFlag: delete_flag,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: POST_MANAGE_TRADEMARK,
        status: ERROR,
        deleteFlag: delete_flag,
      });
    }
  };
}

//--------Manager: Download File POST --------
export function postDownloadFile({ applicationNumber, documentFor, fileName }) {
  return async (dispatch) => {
    try {
      let response = await Axios.post(
        `${MGR_RT_URL}/signing/`,
        {
          action: "DOWNLOAD",
          application_number: applicationNumber,
          document_for: documentFor,
          file_name: fileName,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      window.open(response?.data, "_blank");
      return SUCCESS;
    } catch (err) {
      checkAuthorisation(dispatch, err);
      message.error(`${err.response?.data.error}`);
      return ERROR;
    }
  };
}

//--------Manager: Upload file POST --------
export function postUploadFile(
  { applicationNumber, documentFor, selectedTeamMember, fileName, fileSize },
  actionType,
) {
  return async (dispatch) => {
    try {
      let response = await Axios.post(
        `${MGR_RT_URL}/signing/`,
        {
          action: "ADD",
          application_number: applicationNumber,
          document_for: documentFor,
          file_name: fileName,
          uploaded_by: selectedTeamMember,
          file_size: fileSize,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
        applicationNumber,
      });
      message.success(`"${fileName}" has been uploaded successfully.`);
      return SUCCESS;
    } catch (err) {
      checkAuthorisation(dispatch, err);
      // message.error(`${err.response?.data.error}`);
      return ERROR;
    }
  };
}

//--------Manager: Uploaded File DELETE --------
export function deleteUploadedFile(
  { applicationNumber, documentFor, fileName },
  actionType,
) {
  return async (dispatch) => {
    try {
      let response = await Axios.delete(`${MGR_RT_URL}/signing/`, {
        data: {
          document_for: documentFor,
          file_name: fileName,
          application_number: applicationNumber,
        },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
        applicationNumber,
      });
      message.success(`"${fileName}" has been deleted.`);
      return SUCCESS;
    } catch (err) {
      checkAuthorisation(dispatch, err);
      message.error(`"${fileName}" cannot be deleted.`);
      return ERROR;
    }
  };
}

//--------Manager: Uploaded File Rename PUT --------
export function putUploadedFile(
  { applicationNumber, documentFor, fileName, renameValue },
  actionType,
) {
  return async (dispatch) => {
    try {
      let response = await Axios.put(
        `${MGR_RT_URL}/signing/`,
        {
          document_for: documentFor,
          file_name: fileName,
          application_number: applicationNumber,
          rename: renameValue.trim(),
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
        applicationNumber,
      });
      message.success(`"${fileName}" has been renamed to "${renameValue}".`);
      return SUCCESS;
    } catch (err) {
      checkAuthorisation(dispatch, err);
      message.error(`${err.response?.data.error}`);
      return ERROR;
    }
  };
}

// --- Total Trademark Revamp ====
// --------Manager-Trademark: Trademark--------
export function getTrademark({
  mmType,
  pseudo = false,
  filterFlag,
  filters,
  pageNumber,
  searchType,
  filters_required_flag,
  required_filter,
  filter_contains_term,
}) {
  const classes = `${filters?.trademarkClass}`;
  const trademark_status = `${filters?.trademarkStatus}`;
  const trademark_type = `${filters?.trademarkType}`;
  const states = `${filters?.trademarkStates}`;
  const proprietor = `${filters?.trademarkProprietors}`;
  const tags = `${filters?.trademarkTags}`;
  const opponent = `${filters?.trademarkOpponent}`;
  const date_of_application = `${filters?.date_of_application}`;
  const valid_upto_date = `${filters?.valid_upto_date}`;
  const journal_number = `${filters?.journalNumber}`;
  const contains_term = `${filters?.containsTerm}`;
  const alerts = filters?.trademarkAlerts
    ? filters?.trademarkAlerts.join(";")
    : `${filters?.trademarkAlerts}`;
  const no_Tags = `${filters?.trademarkNOtags}`;
  const desc_contains_term = filters?.desc_contains_term;
  const date_of_usage = `${filters?.date_of_usage}`;
  const trademark_office = `${filters?.trademarkOffice}`;
  const date_of_usage_proposed = `${filters?.date_of_usage_proposed}`;

  return async (dispatch) => {
    let tmParams = {
      mm_type: mmType,
      filter_flag: filterFlag ? filterFlag : null,
      classes: filterFlag ? classes : null,
      trademark_status: filterFlag ? trademark_status : null,
      trademark_type: filterFlag ? trademark_type : null,
      states: filterFlag ? states : null,
      proprietor: filterFlag ? proprietor : null,
      tags: filterFlag ? (no_Tags?.length > 0 ? null : tags) : null,
      opponent: filterFlag ? opponent : null,
      date_of_application: filterFlag ? date_of_application : null,
      valid_upto_date: filterFlag ? valid_upto_date : null,
      journal_number: filterFlag ? journal_number : null,
      page: pageNumber,
      no_tags: no_Tags.length > 0 ? true : null,
      alerts: filterFlag ? alerts : null,
      date_of_usage: filterFlag ? date_of_usage : null,
      trademark_office: filterFlag ? trademark_office : null,
      filters_required_flag: false,
      date_of_usage_proposed: filterFlag ? date_of_usage_proposed : null,
    };

    if (pseudo) {
      tmParams = { ...tmParams, pseudo: pseudo };
    }

    if (searchType === "Description (G&S)") {
      tmParams = {
        ...tmParams,
        desc_contains_term: filterFlag ? desc_contains_term : null,
      };
    } else {
      tmParams = {
        ...tmParams,
        contains_term:
          filterFlag && contains_term !== undefined ? contains_term : null,
      };
    }

    if (filters_required_flag && required_filter !== "") {
      dispatch({
        type: `TOTAL_TM_${required_filter}_FILTERS`,
        status: LOADING,
        pseudo,
      });
      try {
        let response;
        response = await Axios.get(`${MGR_RT_URL}/trademarks/`, {
          params: {
            ...tmParams,
            page: pageNumber,
            filters_required_flag,
            required_filter,
            filter_contains_term,
            page_size: 100,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: `TOTAL_TM_${required_filter}_FILTERS`,
          status: SUCCESS,
          payload: response?.data,
          lastAppliedFilters: {
            ...filters,
            filterFlag,
          },
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
          pseudo,
        });
      } catch (err) {
        dispatch({
          type: `TOTAL_TM_${required_filter}_FILTERS`,
          status: ERROR,
        });
      }
    } else {
      dispatch({ type: TOTAL_TM, status: LOADING, pseudo });
      try {
        let response;
        response = await Axios.get(`${MGR_RT_URL}/trademarks/`, {
          params: tmParams,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });

        dispatch({
          type: TOTAL_TM,
          status: SUCCESS,
          payload: response?.data,
          lastAppliedFilters: {
            ...filters,
            filterFlag,
          },
          pageNumber,
          pseudo,
        });
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: TOTAL_TM, status: ERROR });
      }
    }
  };
}

// --------Manager-Trademark: reply-to-correspondence Revamp--------
export function getReplyToCorrespondence({
  management_mark_id,
  mmType,
  pseudo = false,
  filterFlag,
  filters,
  pageNumber,
  searchType,
  filters_required_flag,
  required_filter,
  filter_contains_term,
}) {
  if (management_mark_id) {
    return async (dispatch) => {
      dispatch({ type: REPLY_TO_CORRESPONDENCE, status: LOADING });
      try {
        const response = await Axios.get(
          `${MGR_RT_URL}/trademarks/reply_to_correspondence/`,
          {
            params: {
              management_mark_id,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          },
        );
        dispatch({
          type: REPLY_TO_CORRESPONDENCE,
          status: SUCCESS,
          payload: response?.data,
        });
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: REPLY_TO_CORRESPONDENCE, status: ERROR });
      }
    };
  } else {
    const classes = `${filters?.trademarkClass}`;
    const trademark_status = `${filters?.trademarkStatus}`;
    const trademark_type = `${filters?.trademarkType}`;
    const states = `${filters?.trademarkStates}`;
    const proprietor = `${filters?.trademarkProprietors}`;
    const tags = `${filters?.trademarkTags}`;
    const opponent = `${filters?.trademarkOpponent}`;
    const date_of_application = `${filters?.date_of_application}`;
    const valid_upto_date = `${filters?.valid_upto_date}`;
    const journal_number = `${filters?.journalNumber}`;
    const contains_term = `${filters?.containsTerm}`;
    const alerts = filters?.trademarkAlerts
      ? filters?.trademarkAlerts.join(";")
      : `${filters?.trademarkAlerts}`;
    const no_Tags = `${filters?.trademarkNOtags}`;
    const desc_contains_term = filters?.desc_contains_term;
    const date_of_usage = `${filters?.date_of_usage}`;
    const trademark_office = `${filters?.trademarkOffice}`;
    const date_of_usage_proposed = `${filters?.date_of_usage_proposed}`;

    return async (dispatch) => {
      let tmParams = {
        mm_type: mmType,
        filter_flag: filterFlag ? filterFlag : null,
        classes: filterFlag ? classes : null,
        trademark_status: filterFlag ? trademark_status : null,
        trademark_type: filterFlag ? trademark_type : null,
        states: filterFlag ? states : null,
        proprietor: filterFlag ? proprietor : null,
        tags: filterFlag ? (no_Tags?.length > 0 ? null : tags) : null,
        opponent: filterFlag ? opponent : null,
        date_of_application: filterFlag ? date_of_application : null,
        valid_upto_date: filterFlag ? valid_upto_date : null,
        journal_number: filterFlag ? journal_number : null,
        page: pageNumber,
        no_tags: no_Tags?.length > 0 ? true : null,
        alerts: filterFlag ? alerts : null,
        date_of_usage: filterFlag ? date_of_usage : null,
        trademark_office: filterFlag ? trademark_office : null,
        filters_required_flag: false,
        date_of_usage_proposed: filterFlag ? date_of_usage_proposed : null,
      };

      if (pseudo) {
        tmParams = { ...tmParams, pseudo: pseudo };
      }

      if (searchType === "Description (G&S)") {
        tmParams = {
          ...tmParams,
          desc_contains_term: filterFlag ? desc_contains_term : null,
        };
      } else {
        tmParams = {
          ...tmParams,
          contains_term:
            filterFlag && contains_term !== undefined ? contains_term : null,
        };
      }

      if (filters_required_flag && required_filter !== "") {
        dispatch({
          type: `TOTAL_TM_${required_filter}_FILTERS`,
          status: LOADING,
          pseudo,
        });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/reply_to_correspondence/`,
            {
              params: {
                ...tmParams,
                page: pageNumber,
                filters_required_flag,
                required_filter,
                filter_contains_term,
                page_size: 100,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: `TOTAL_TM_${required_filter}_FILTERS`,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            paginate: pageNumber > 1 ? true : false,
            pseudo,
          });
        } catch (err) {
          dispatch({
            type: `TOTAL_TM_${required_filter}_FILTERS`,
            status: ERROR,
          });
        }
      } else {
        dispatch({ type: TOTAL_TM, status: LOADING, pseudo });
        try {
          let response;
          response = await Axios.get(
            `${MGR_RT_URL}/trademarks/reply_to_correspondence/`,
            {
              params: tmParams,
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );

          dispatch({
            type: TOTAL_TM,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
            pseudo,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: TOTAL_TM, status: ERROR });
        }
      }
    };
  }
}
