import React, { Component } from 'react';
import { connect } from 'react-redux';
import deepFreeze from 'deep-freeze';
import { Row, Col, Progress, Typography } from 'antd';
import { SUCCESS } from '../../../constants/commonConstants/generalConstants';

const { Text } = Typography;
class SearchTips extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { quota, userTrademarksStatus, userSubscriptions } = this.props;
        const unlimitedSearch = userSubscriptions && userSubscriptions.unlimited_search_quota;
        let successPercent; 
        let percent;
        let color;
        if (userTrademarksStatus === SUCCESS) {
            if (quota) {
                successPercent = ((quota.left_quota/quota.quota)*100).toFixed(2)
                percent = (quota.quota/quota.quota)*100
                if (successPercent > 60) {
                    color = '#87d068';
                } else if (successPercent > 30 && successPercent < 60) {
                    color = '#1b91ff';
                } else if (successPercent > 10 && successPercent < 30) {
                    color = '#ff5500';
                } else if (successPercent < 10) {
                    color = '#f5222d';
                } else {
                    color = '#ddd'; 
                }
            } else {
                successPercent = 0;
                percent = 100; 
            }
        }

        return (
            <>
                <div style={{height: "calc(100vh - 385px)"}}>
                    <Row>
                        <Col span={unlimitedSearch ? 24 : 18}>
                            <div style={{ width: `${unlimitedSearch ? "70%" : "90%"}`, margin: "auto",  overflowY: "auto" }}>
                                <div style={{ fontWeight: "600", fontSize: "14px", marginBottom: 10, textAlign: "center" }}>Search Tips</div>
                                <div style={{ backgroundColor: "#ffffff", padding: "30px", fontSize: "14px" }}>
                                    <p><Text strong>1. </Text>It is recommended to separately search "Mike" and "Legal" so as to get better and more targeted results.</p>
                                    <p><Text strong>2. </Text>Do not put space after the mark and few special characters do not get searched such as : #.</p>
                                    <p><Text strong>3. </Text>Do real time report to ensure the marks in the report have all the updated information as per the registry.</p>
                                    <p><Text strong>4. </Text>While doing a flash search, if you are looking for a particular variation of the searched mark, which is two or more word mark, then you can modify the search from bar present just above the results.</p>
                                </div>
                            </div>
                        </Col>
                        {!unlimitedSearch && 
                        <Col span={6}>
                            <div style={{ margin: "0px 10px",  textAlign: "center", overflowY: "auto" }}>
                                <div style={{ fontWeight: "600", fontSize: "14px", marginBottom: 10 }}>Search Quota</div>
                                { quota && 
                                    <div style={{padding: "10px"}}>
                                        <Progress 
                                            className="quota-progress"
                                            width={150}
                                            type="circle" 
                                            strokeColor={color}
                                            // successPercent={successPercent} 
                                            percent={successPercent} 
                                            // format={(percent, successPercent) => `${(successPercent/100)*(quota && quota.quota)} Left`}
                                            format={(percent) => `${percent} % Remaining`}
                                        />
                                        <>
                                            <div>
                                                <Text strong>Remaining Quota : </Text>
                                                <Text>{quota.left_quota}</Text>
                                            </div>
                                            <div>
                                                <Text strong>Total Quota : </Text>
                                                <Text>{quota.quota}</Text>
                                            </div>
                                        </>
                                    </div>
                                }
                            </div>
                        </Col>
                        }
                    </Row>
                    
                    
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {

};

function mapStateToProps(state) {
    const {
        quota,
        userTrademarks,
        userTrademarksStatus,
    } = state.searchState.searchBar;

    return deepFreeze({
        quota,
        userTrademarks,
        userTrademarksStatus,
        userSubscriptions: state.userSubscriptions.userSubscriptions
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchTips);