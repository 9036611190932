import React, { Component } from 'react';
import { Row, Col, Card, Table } from 'antd';

import { checkValue } from '../../../../functions/commonFunctions/checkValueFunctions';


class TodoTaskTable extends Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        const { TodoTaskData, TodoTaskType } = this.props;

        const totalTodoTaskColumns = [

            {
                title: 'TITLE',
                dataIndex: 'title',
                render: text => <p>{checkValue(text) ? text : 'N.A.'}</p>
            },

            {
                title: 'DESCRIPTION',
                dataIndex: 'description',
                render: text => <p className="h_80px vertical_scroll">{checkValue(text) ? text : 'N.A.'}</p>
            },

            {
                title: 'PRIORITY',
                dataIndex: 'priority',
                render: text => <p>{checkValue(text) ? text : 'N.A.'}</p>
            },

            {
                title: 'STATUS',
                dataIndex: 'status',
                render: text => <p>{checkValue(text) ? text : 'N.A.'}</p>
            },

            {
                title: 'CREATED BY',
                dataIndex: 'created_by',
                render: text => <p>{checkValue(text) ? text.email : 'N.A.'}</p>
            },

            {
                title: 'DUE DATE',
                dataIndex: 'due_on_datetime',
                render: text => <p>{checkValue(text) ? text : 'N.A.'}</p>
            },

            {
                title: 'REMINDERS',
                render: data => {
                    return (
                        <div className="h_80px vertical_scroll">
                            {data.reminders && data.reminders.length > 0 && data.reminders.map(doc=>
                                <p>{doc.local_time}</p>
                            )}
                        </div>
                    )
                }
            }
        ]

        return (
            <div>
                <Row gutter={16} className="m-top-20">
                    <Col span={24}>
                        <Card 
                            title = {TodoTaskType}
                            headStyle={{background: '#e8e8e8'}}>
                            <Table 
                                rowKey={record => record.id} 
                                bordered
                                columns={ totalTodoTaskColumns } 
                                dataSource={ TodoTaskData } 
                                scroll={{ y: "calc(70vh - 200px)" }}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }       
}

export default TodoTaskTable;