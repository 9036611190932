//--------LIBRARIES--------
import React from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Checkbox, Tooltip } from "antd";

//--------Check value functions--------
import { checkNA } from "../../../functions/commonFunctions/checkValueFunctions";

function GeneralMarkCard({
  eachMark,
  onSelectMark,
  checked,
  selectAllCards,
  changeStateValue_L1,
  styleCard,
  similarity,
  status,
  proprietorClickable,
  proprietorFlat,
  onProprietorClick,
  dataAsOn,
  classesFlat,
  created_custom_report,
  imageLabel,
  imageText,
  tmProtect,
  algorithm,
}) {
  return (
    <div className="General-Mark-Card" style={styleCard}>
      <Checkbox
        checked={checked}
        onChange={(event) => onSelectMark(eachMark, event.target.checked)}
        disabled={selectAllCards}
      />
      {created_custom_report && (
        <Tooltip placement="top" title="Custom report created">
          <EyeOutlined style={{ paddingLeft: "10px" }} />
        </Tooltip>
      )}
      <div
        className={tmProtect ? "" : "Application-Number"}
        style={{ float: "right", display: "inline-block" }}
        onClick={() => {
          tmProtect
            ? changeStateValue_L1()
            : changeStateValue_L1(
                "drawersVisibility",
                "journalPage",
                eachMark.application_number
              );
        }}
      >
        {eachMark.application_number}
      </div>
      {eachMark.associated_image ? (
        <>
          <img
            src={eachMark.associated_image}
            height="100"
            width="200"
            alt=""
          />
          <br />
        </>
      ) : (
        <div
          style={{
            height: "100px",
            fontSize: "20px",
            textAlign: "center",
            wordBreak: "break-word",
            overflow: "auto",
          }}
        >
          {checkNA(eachMark.applied_for)}
        </div>
      )}
      <div className="General-Mark-Card-Title">APPLIED FOR:</div>
      <div className="General-Mark-Card-Detail">
        {checkNA(eachMark.applied_for)}
      </div>
      {status && (
        <>
          <div className="General-Mark-Card-Title">STATUS:</div>
          <div className="General-Mark-Card-Detail">{status}</div>
        </>
      )}
      <div className="General-Mark-Card-Title">CLASSES:</div>
      <div className="General-Mark-Card-Detail">
        {classesFlat && `${eachMark.associated_class}`}
        {!classesFlat &&
          eachMark.associated_class.map((eachClass, index, allClasses) =>
            allClasses.length - 1 === index ? `${eachClass}` : `${eachClass}, `
          )}
      </div>
      <div className="General-Mark-Card-Title">DoA | DoU:</div>
      <div className="General-Mark-Card-Detail">
        {eachMark.application_date} |{" "}
        {eachMark.date_of_usage ? eachMark.date_of_usage : "Proposed"}
      </div>
      <div className="General-Mark-Card-Title">PROPRIETORS:</div>
      <div className="General-Mark-Card-Detail">
        {proprietorFlat && (
          <>
            <span>{eachMark.proprietor_name}</span>
            <br />
          </>
        )}
        {!proprietorFlat &&
          eachMark.proprietor.map((eachProprietor) => (
            <React.Fragment
              key={`${eachMark.application_number}-${eachProprietor.id}`}
            >
              {proprietorClickable ? (
                <span
                  className="Application-Number"
                  onClick={() => onProprietorClick(eachProprietor.id)}
                >
                  {eachProprietor.name}
                </span>
              ) : (
                <span>{eachProprietor.name}</span>
              )}
              <br />
            </React.Fragment>
          ))}
      </div>
      {similarity !== null && (
        <>
          <div className="General-Mark-Card-Title">
            SIMILARITY |{" "}
            {imageLabel
              ? "IMAGE LABEL"
              : imageText
              ? "IMAGE TEXT"
              : algorithm
              ? "ALGORITHM"
              : "FOCUS WORD"}
            :
          </div>
          <div className="General-Mark-Card-Detail">{`${similarity.score}% | ${
            imageLabel
              ? similarity.focus_word
              : imageText
              ? eachMark.threat_image_text
              : algorithm
              ? similarity.algorithm
              : similarity.focus_word
          } `}</div>
        </>
      )}
      {dataAsOn && (
        <>
          <div className="General-Mark-Card-Title">DATA AS ON:</div>
          <div className="General-Mark-Card-Detail">{`${eachMark.data_as_on}`}</div>
        </>
      )}
    </div>
  );
}

export default GeneralMarkCard;
