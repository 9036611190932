import img1 from '../../../assets/searchfaqimages/4.1.gif'
import img2 from '../../../assets/searchfaqimages/4.2.gif'
import img3 from '../../../assets/searchfaqimages/4.3.gif'
import img4 from '../../../assets/searchfaqimages/4.4.gif'
import img5 from '../../../assets/searchfaqimages/4.5.gif'
import img6 from '../../../assets/searchfaqimages/4.6.gif'
import img7 from '../../../assets/searchfaqimages/4.7.gif'
import img8 from '../../../assets/searchfaqimages/4.8.gif'
import img9 from '../../../assets/searchfaqimages/4.9.gif'
import img10 from '../../../assets/searchfaqimages/4.10.gif'
import img11 from '../../../assets/searchfaqimages/4.11.gif'
import img12 from '../../../assets/searchfaqimages/4.12.gif'
import img13 from '../../../assets/searchfaqimages/4.13.gif'
import img14 from '../../../assets/searchfaqimages/4.14.gif'
import img15 from '../../../assets/searchfaqimages/4.15.gif'
import img17 from '../../../assets/searchfaqimages/4.17.gif'
import img18 from '../../../assets/searchfaqimages/4.18.gif'
import img19 from '../../../assets/searchfaqimages/4.19.gif'

const SearchData={

    FeaturesGuide:[
        {
            question:'Features of MikeTM Search',
            answers:[
                {answer:'1. Single Class/Multiple Class/All 45 Class Search'}, 
                {answer:'2. Ability to perform multiple types of searches with a single click'}, 
                {answer:'3. Ability to perform comprehensive proprietor search'},
                {answer:'4. Ability to create report'}, 
                {answer:'5. Ability to download the report in PDF and Excel'}, 
                {answer:'6. Ability to see information of marks in real-time'}, 
            ]

        },

        {
            question:'Guide to MikeTM search features',
            answers:[
                {answer:'Search the desired Trademark name. You can select the classes in which you need to search. If no class is selected then it will be done across all 45 classes. Besides, you can also perform multiple search options available. For general comprehensive results, Flash is the recommended search. '}, 
                {answer:'When Flash search is executed, you will see results bifurcated into different set of marks. It is recommended that you go through all the tabs to identify different type of similar marks. You can toggle between different set of results by clicking on the button as shown in the screenshot.  '}, 
                {answer:'Once results are shown then you can filter the results using multiple filter options available such as Classes, Statuses, States, Trademark Types and so on.You can also select from the given tabs ‘Classes’, ‘Statutes ‘, ‘States’, ‘TM Types’, ‘Proprietors’,’DoA, DoU.  In case you do not chose any specific category.  Mike TM search will search across all the classes.'},
            ]

        },
    ],
    Question:[
        {
            heading:'FAQs',
            question:'1. Can I do Multiple Class Search? ',
            img:img1,
            answers:[
                {answer:'Yes, you can do multiple class search or even all 45-class search on MikeTM Search platform. MikeTM Search by default does an all 45-class search for your selected mark. Alternatively, you can select multiple classes from the drop down provided at the bottom of the search bar '}
            ]

        },
        {
            question:'2. What type of search one can perform on MikeTM Search?',
            img:img2,
            answers:[
                {answer:'MikeTM Search allows you to do multiple types of state-of-the-art searches based on your requirement. Every possible analysis will be displayed on your screen. Following are the types of searches which are available on MikeTM Search - '},
                {answer:'1.Fuzzy Search'}, 
                {answer:'2.Wildcard Search'}, 
                {answer:'3.Initial Search'}, 
                {answer:'4.Standard Boolean Search'}, 
                {answer:'5.Phonetic Search'}, 
           ]

        },
        {
            question:'2.1 Fuzzy Search',
            img:img3,
            answers:[
                {answer:'Fuzzy Search will give you search results based on the length of the character and marks with a particular character variation. For a thorough analysis it is suggested you view all the different search results (Wildcard, Standard etc.).'},
                {answer:'Fuzzy Search will give you search results based on the length of the character and marks with a particular character variation. For a thorough analysis it is suggested you view all the different search results (Wildcard, Standard etc.).'}
            ]
           

        },
        {
            question:'2.2 WildCard Search',
            img:img4,
            answers:[
                {answer:'Wildcard Search will give search results not just give you a prefix, suffix but also to do a single character variation search'},
                {answer:'On selecting Wildcard Search, you can search prompts whether you want to do a multiple character variation or single character variation. Multiple character variations allows user to do a prefix, suffix and contains search whereas single character variation allows user to check variations for a single character of the mark. '},
                {answer:'Example- If you want to do prefix search for the term legal, you can select [*] before legal to get results such as Mikelegal and DSKlegal and so on. Similarly, if you want to do a single character variation you can put [?] in the place of character for which you want to find variations of followed by the entire mark.  '}
           ]

        },

        {
            question:'2.3 Standard Initial Search',
            img:img5,
            answers:[
                {answer:"Initial Search allows you to identify marks with same initials."},
                {answer:"SEARCH EXAMPLE"},
                {answer:"‘Officers Choice’ → ‘Oliver Champagne’, ‘Omega Clove’Standard Search will give you search results which are generic in nature covering all the possible."},
                {answer:"Example-"},
           ]

        },
        {
            question:'2.4 Phonetic & Boolean Search',
            img:img6,
            answers:[
                {answer:'Boolean Search allows you to use characters AND, OR and NOT to get customised set of results. AND allows you to get results having both the elements present in the search query. OR allows you to get results with either of the element present in the search query. NOT allows you to remove a particular mark from the result set to get customised results. '},
                {answer:'SEARCH EXAMPLE'},
                {answer:'• Rock [[&]] Cafe → Rock Bottom Cafe, Cafe Rock Inn, Hard Rock Cafe and Cafe Rock'},
                {answer:'• Rock [[|]] Cafe → Rock Card, Cafe Roman and Rock Cafe'},
                {answer:'• Rock [[|]] Cafe [[!]] Hard → Rock Bottom Cafe, Cafe Rock Inn and Cafe Rock. (Here, marks which has Hard in it will not be shown)'},
                {answer:'Phonetic Search will give you search results which are phonetically similar i.e., the way the words sound is similar.'}, 
                {answer:'Example- The letter ‘k’ can be spelled as ck, ch'},
            ]
        },
        {
            question:'3. How to perform a proprietor search? ',
            img:img7,
            answers:[
                {answer:'Proprietor Search allows you to analyse all the marks of a particular proprietor with graphical representation of the data. Moreover, unlike IP India where you only get access to registered marks, MikeTM Search gives you access of all the marks filed by the proprietor regardless of its status.'},
                {answer:'You can access proprietor search feature by clicking hovering on “choose mark/proprietorTrademark” and then choosing the proprietor search option. '},
                {answer:'You will need to put in the name of the proprietor and the system runs a ‘Contains’ search i.e., if you search “Sun” then MikeTM Search will show you all proprietors such as “Sun Pharmaceutical” and “Maxsun Pharma”. It will even search on the ‘trading as’ and ‘name of the partners’ to provide information which otherwise was almost impossible to get. '},
                {answer:'Once you have the results you can either individually click on a proprietor to see not just all of their marks but also important data elements such as year wise filing distribution, status distribution and type wise distribution. You can also create an urgent report as well as relatime report from there as well.  '},
                {answer:'Else you can click of the checkbox next to each proprietor and create a consolidated report as urgent or real time. You can even filter proprietor results on the basis of address also.'},  
                {answer:'You can see the number of marks corresponding to each proprietor and select multiple proprietors to create a consolidated report. '}
               
            ]
        },
        {
            question:'4. How to create reports on MikeTM Search?',
            img:img8,
            answers:[
                {answer:'You will see a list of Trademarks based on your entered Trademark name and category. A pop-up will be raised with will give you options on your desired way simply just select on it and click on the small box created. A blue tick will be marked on your selected search box.'},
                {answer:'You can create reports in the following ways:'},
                {answer:'1.	The report will be generated based on your requirement you can select either ‘Urgent Report’ or ‘Realtime Report’ and it also give options of report version PDF or Excel or both.'}, 
                {answer:'2.	Select ‘Confirm’ option the report will be sent to you via email or your Report dashboard.'},  
                {answer:'3.‘Cancel’ in case you don’t want the report. It will not be saved. Only if you select the ‘Confirm’ option report will be generated.'},
                {answer:'There are two type of reports that can be created:'},
                {answer:'1.	Urgent report: Creates reports based on the last updated data'}, 
                {answer:'2.	Real time report: Creates reports based on the current information of the mark as per IP India'},
                {answer:'Pro Tip: Real time reports should always be used if you wish to send reports to a client or want to see up-to date information. You can queue the real time report and continue with your work, once the report is ready you would be communicated via mail.'},
            ]
        },
        {
            question:'5. How to create reports in PDF and Excel?',
            img:img9,
            answers:[
                {answer:'Reports can be downloaded in both PDF and Excel format and can be created on MikeTM Search itself.'},
                {answer:'You have to click on the download option in the ‘Report preview’ page and choose the format in which you want the report.'}, 
                {answer:'The report is sent to your registered email along with email ids added on profile page and can also be accessed from the Your Mark Reports and Your Proprietor Reports, as the case maybe, from the profile.'},
                
            ]
        },
        {
            question:'6. How to get up to date information of trademarks?',
            img:img10,
            answers:[
                {answer:'To get the exact information of a trademark as of now, you can use the following methods:'},
                {answer:'1.	Get current info: Whenever you do the trademark search, every mark has an option ‘Get current info’ on the bottom of the tile. This option fetches the exact information of that trademark and shows you the updated details on the trademark profile page at that very moment.'},
                {answer:'2.	Real-time report: Whenever you create a report, there is an option to generate a real-time report. This option refreshes all the marks selected in the report at that moment and notifies you via email that your report is ready and available for download.'}
            ]
        },
        {
            question:'7. What is an Email Template?',
            img:img11,
            answers:[
                {answer:'Email Template is a regular email body filled with variables in terms of Trademark’s information, which can help you save time and send customized mails with respect to the selected trademarks or proprietors. '},
                {answer:'For Example – Let’s say your email body says,'},
                {answer:'“Dear Sir '},
                {answer:'Your trademark XYZ is currently at the status – Abandoned.”'},
                {answer:'Now to send this exact same mail to multiple addresses, you can simply convert this body into a template and automate mails, let’s have a look how –'},
                {answer:'“Dear @proprietor_name '},
                {answer:'Your trademark @mark_name is currently at the status - @status.”'}
            ]
        },
        {
            question:'8. How do I filter the search result by classes? ',
            img:img12,
            answers:[
                {answer:'Click on the “Class” dropdown to select the required classes'},
            ]
        },
        {
            question:'9. How do I change the Algorithm? ',
            img:img13,
            answers:[
                {answer:'Click on the “Fuzzy” button to open the Algorithm dropdown and select the desired one '},
            ]
        },

        {
            question:'10. How do I edit the Fuzzy depth?',
            img:img14,
            answers:[
                {answer:'Select the number of characters up to which you need to see the variations, for example if you select 3, you will be shown character variations of 0-, 1-, 2- & 3-character variations and click on Enter'},
            ]
        },

        {
            question:'11. How do I Change Wildcard Query ?',
            img:img15,
            answers:[
                {answer:'You can write your own query by simply placing variable at either prefix or suffix or a word or words. [[*]] for “multiple characters” & [[?]] for “single character”. Once you have finalised, click on Enter to continue.'},
            ]
        },
        {
            question:'12. How to Select all the trademarks in the Page? ?',
            img:img17,
            answers:[
                {answer:'To select the full page of result, you can click on the 3 parallel lined button on the left side of the “Algorithm button” and click on “select page”.'},
            ]
        },

        {
            question:'13. How to Add/Delete Team Members ?',
            img:img18,
            answers:[
                {answer:'Go to the Profile page, and click on “Add team member” to add a member or click on “remove” to delete a team member'},
            ]
        },

        {
            question:'14. How to Edit User Details?',
            img:img19,
            answers:[
                {answer:'Click on “Edit” button on the “Profile” page to edit the User details.'},
            ]
        },
    ]
};
export default SearchData;
