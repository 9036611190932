export const WORKFLOW_KEY_VALUE = 'WORKFLOW_KEY_VALUE';
export const GET_WORKFLOW = 'GET_WORKFLOW';
export const CREATE_WORKFLOW = 'CREATE_WORKFLOW';
export const DELETE_WORKFLOW = 'DELETE_WORKFLOW';
export const EDIT_WORKFLOW = 'EDIT_WORKFLOW';
export const GET_WORKFLOW_DETAILS = 'GET_WORKFLOW_DETAILS';
export const SEND_WORKFLOW_EMAIL = 'SEND_WORKFLOW_EMAIL';
export const EDIT_WORKFLOW_EMAIL = 'EDIT_WORKFLOW_EMAIL';
export const DELETE_WORKFLOW_EMAIL = 'DELETE_WORKFLOW_EMAIL';
export const CREATE_WORKFLOW_TODO_TASK = 'CREATE_WORKFLOW_TODO_TASK';
export const EDIT_WORKFLOW_TODO_TASK = 'EDIT_WORKFLOW_TODO_TASK';
export const DELETE_WORKFLOW_TODO_TASK = 'DELETE_WORKFLOW_TODO_TASK';