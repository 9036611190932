//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DownOutlined,
  MinusCircleOutlined,
  MinusSquareFilled,
  PlusSquareFilled,
} from "@ant-design/icons";
import {
  Pagination,
  Menu,
  Input,
  Button,
  Dropdown,
  Select,
  notification,
  Checkbox,
  Drawer,
  Badge,
  Radio,
  Alert,
  Spin,
  Tooltip,
  Empty,
  Row,
  Col,
  Space,
} from "antd";
import { debounce } from "lodash";
import { FixedSizeList } from "react-window";
import deepFreeze from "deep-freeze";

//--------WATCH SUB-COMPONENTS DRAWERS--------
import TrademarkJournalPage from "../subComponents/Drawers/TrademarkJournalPage";

//--------IP_SUITE SUB-COMPONENTS--------
import GeneralMarkCard from "../../commonComponents/generalCards/GeneralMarkCard";

//--------IP-SUITE ACTIONS--------
import { TPAction, logOut } from "../../../redux/actions/commonActions/actions";

//--------WATCH ACTIONS--------
import {
  getJournalsList,
  getUserTrademarks,
  postCustomReport,
} from "../../../redux/actions/watchActions/commonActions";

//--------JOURNAL ACTIONS--------
import { J_getJournal } from "../../../redux/actions/watchActions/journalsActions";

//--------JOURNALS ACTION TYPES--------
import {
  J_POST_CUSTOM_REPORT,
  J_SELECT_VIEW,
} from "../../../redux/actions/watchActions/types";

//--------IP-SUITE GENERAL FUNCTIONS--------
import {
  changeStateValue_L1,
  compareArrays,
} from "../../../functions/commonFunctions/generalFunctions";

//--------IP-SUITE DATE FUNCTIONS--------
import { dateFormat } from "../../../functions/commonFunctions/dateFunctions";

//--------IP-SUITE CONSTANTS--------
import {
  SUCCESS,
  LOADING,
  ERROR,
  PROGRESSING,
  PDF,
  EXCEL,
} from "../../../constants/commonConstants/generalConstants";

//--------JOURNALS CONSTANTS--------
import {
  J_defaultFiltersVisibility,
  J_defaultSelectedFilters,
  J_defaultFiltersSearchTerms,
  J_defaultCustomReportData,
} from "../../../constants/watchConstants/journalsConstants";
import { JOURNALS } from "../../../constants/watchConstants/generalConstants";

// import { InternalServerError, NetworkError, PageNotFound } from '../../commonComponents/Error-Pages/Error';

const { Search } = Input;
const { Option } = Select;

class Journals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersVisibility: J_defaultFiltersVisibility,
      selectedFilters: this.props.lastAppliedFilters,
      filtersSearchTerms: J_defaultFiltersSearchTerms,
      customReportData: J_defaultCustomReportData,
      drawersVisibility: {
        journalPage: false,
        customReport: false,
      },
      tableDescriptionRowsVisibility: false,
      page: 50,
    };

    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L1_Debounced = debounce(
      this.changeStateValue_L1,
      400,
    );
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.currentJournal === null) {
        await this.props.getJournalsList(true);
      }
      if (
        this.props.journalData === null &&
        this.props.currentJournal !== null
      ) {
        this.props.J_getJournal(
          this.props.currentJournal,
          1,
          false,
          J_defaultSelectedFilters,
        );
      }
    } else {
      this.props.logOut();
    }
  }

  handleJournalChange(journalNumber) {
    switch (this.props.journalDataStatus) {
      case SUCCESS:
      case ERROR:
      case PROGRESSING:
        this.setState({
          selectedFilters: J_defaultSelectedFilters,
          filtersSearchTerms: J_defaultFiltersSearchTerms,
          customReportData: J_defaultCustomReportData,
        });
        this.props.J_getJournal(
          journalNumber,
          1,
          false,
          J_defaultSelectedFilters,
          true,
        );
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      default:
        notification["error"]({
          message: "Unable to Change Journal! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleApplyFilters() {
    switch (this.props.journalDataStatus) {
      case SUCCESS:
      case ERROR:
        const {
          trademarkTypes,
          trademarkClasses,
          trademarkCategories,
          trademarkOffices,
          proprietors,
          containsTerm,
          mark_desc_contains_term,
        } = this.props.lastAppliedFilters;

        let filterFlag = false;

        let { selectedFilters } = this.state;

        selectedFilters = {
          ...selectedFilters,
        };

        if (
          !compareArrays(
            selectedFilters.trademarkTypes,
            J_defaultSelectedFilters.trademarkTypes,
          ) ||
          !compareArrays(
            selectedFilters.trademarkClasses,
            J_defaultSelectedFilters.trademarkClasses,
          ) ||
          !compareArrays(
            selectedFilters.trademarkCategories,
            J_defaultSelectedFilters.trademarkCategories,
          ) ||
          !compareArrays(
            selectedFilters.trademarkOffices,
            J_defaultSelectedFilters.trademarkOffices,
          ) ||
          !compareArrays(
            selectedFilters.proprietors,
            J_defaultSelectedFilters.proprietors,
          ) ||
          selectedFilters?.containsTerm !==
            J_defaultSelectedFilters.containsTerm ||
          selectedFilters?.mark_desc_contains_term !==
            J_defaultSelectedFilters.mark_desc_contains_term
        ) {
          filterFlag = true;
        } else {
          filterFlag = false;
        }

        if (
          !compareArrays(trademarkTypes, selectedFilters?.trademarkTypes) ||
          !compareArrays(trademarkClasses, selectedFilters?.trademarkClasses) ||
          !compareArrays(
            trademarkCategories,
            selectedFilters?.trademarkCategories,
          ) ||
          !compareArrays(trademarkOffices, selectedFilters?.trademarkOffices) ||
          !compareArrays(proprietors, selectedFilters?.proprietors) ||
          containsTerm !== selectedFilters?.containsTerm ||
          mark_desc_contains_term !== selectedFilters?.mark_desc_contains_term
        ) {
          this.props.J_getJournal(
            this.props.journalNumber,
            1,
            filterFlag,
            selectedFilters,
            false,
          );
        } else {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  handleResetFilters() {
    switch (this.props.journalDataStatus) {
      case SUCCESS:
      case ERROR:
        const {
          trademarkTypes,
          trademarkClasses,
          trademarkCategories,
          trademarkOffices,
          proprietors,
          containsTerm,
          mark_desc_contains_term,
        } = this.props.lastAppliedFilters;

        if (
          compareArrays(
            trademarkTypes,
            J_defaultSelectedFilters.trademarkTypes,
          ) &&
          compareArrays(
            trademarkClasses,
            J_defaultSelectedFilters.trademarkClasses,
          ) &&
          compareArrays(
            trademarkCategories,
            J_defaultSelectedFilters.trademarkCategories,
          ) &&
          compareArrays(
            trademarkOffices,
            J_defaultSelectedFilters.trademarkOffices,
          ) &&
          compareArrays(proprietors, J_defaultSelectedFilters.proprietors) &&
          containsTerm === J_defaultSelectedFilters.containsTerm &&
          mark_desc_contains_term ===
            J_defaultSelectedFilters.mark_desc_contains_term
        ) {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        } else {
          this.setState({
            selectedFilters: J_defaultSelectedFilters,
            filtersSearchTerms: J_defaultFiltersSearchTerms,
          });
          this.props.J_getJournal(
            this.props.journalNumber,
            1,
            false,
            J_defaultSelectedFilters,
          );
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        notification["error"]({
          message: "Unable to Reset! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handlePageChange(pageNumber) {
    switch (this.props.journalDataStatus) {
      case SUCCESS:
      case ERROR:
        this.props.J_getJournal(
          this.props.journalNumber,
          pageNumber,
          this.props.lastAppliedFilters.filterFlag,
          this.props.lastAppliedFilters,
          false,
        );
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        notification["error"]({
          message: "Unexpected Error! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleUserTrademarksApplyFilter(searchTerm) {
    switch (this.props.userTrademarks.trademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        this.props.getUserTrademarks({
          journalNumber: this.props.journal_number,
          pageNumber: 1,
          latestJournalReport: true,
          searchTerm,
        });
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  handleUserTrademarksPageChange(pageNumber) {
    switch (this.props.userTrademarks.trademarksDataStatus) {
      case SUCCESS:
      case ERROR:
        this.props.getUserTrademarks({
          journalNumber: this.props.journal_number,
          pageNumber,
          latestJournalReport: true,
          searchTerm: this.props.userTrademarks.searchTerm,
        });
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        notification["error"]({
          message: "Unexpected Error! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleCustomReportRecordSelection(eachMark, checked) {
    if (checked) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          threats: [
            ...this.state.customReportData.threats,
            eachMark.application_number,
          ],
          threatsLength: this.state.customReportData.threatsLength + 1,
        },
      });
    } else {
      let { threats } = this.state.customReportData;
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          threats: threats.filter(
            (appNum) => appNum !== eachMark.application_number,
          ),
          threatsLength: this.state.customReportData.threatsLength - 1,
        },
      });
    }
  }

  handleCreateReport() {
    switch (this.props.journalDataStatus) {
      case SUCCESS:
      case ERROR:
        if (this.props.userTrademarks.trademarksData === null) {
          this.props.getUserTrademarks({
            journalNumber: this.props.journal_number,
            pageNumber: 1,
            latestJournalReport: true,
            searchTerm: this.props.userTrademarks.searchTerm,
          });
        }
        this.changeStateValue_L1("drawersVisibility", "customReport", true);
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  async handleCreateReportConfirmation() {
    await this.props.postCustomReport(
      {
        ...this.state.customReportData,
        journalNumber: this.props.journalNumber,
        filters: this.props.lastAppliedFilters,
        filter_flag: this.props.lastAppliedFilters.filterFlag,
        source: JOURNALS,
      },
      J_POST_CUSTOM_REPORT,
    );
    this.changeStateValue_L1("drawersVisibility", "customReport", false);
  }

  render() {
    const headerButton = {
      margin: "0px 5px 0px 0px",
      boxShadow: "none",
      border: "1px solid #1890ff",
      padding: "0px 5px",
      fontSize: "16px",
      color: "#1890ff",
    };

    const headerButtonSelected = { ...headerButton, color: "#4285f4" };
    const searchTypesMenu = (
      <>
        <Menu
          selectable
          selectedKeys={[this.state.selectedFilters.searchType]}
          onClick={({ key }) =>
            this.changeStateValue_L1("selectedFilters", "searchType", key)
          }
          style={{
            maxHeight: "200px",
            width: "250px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {["Application No/Name", "Mark Description Search"].map((e) => (
            <Menu.Item key={e} style={{ fontSize: "16px" }}>
              {e}
            </Menu.Item>
          ))}
        </Menu>
      </>
    );

    let noFilterData = (
      <Menu>
        <Menu.Item>No Filters Data Found</Menu.Item>
      </Menu>
    );
    let trademarkClassesMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    let trademarkCategoriesMenu = noFilterData;
    let trademarkOfficesMenu = noFilterData;
    let trademarkTypesMenu = noFilterData;

    if (this.props.filters !== null) {
      const {
        proprietors,
        trademark_categories,
        trademark_classes,
        trademark_offices,
        trademark_types,
      } = this.props.filters;

      if (trademark_classes !== null && trademark_classes.length > 0) {
        trademarkClassesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.selectedFilters.trademarkClasses}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "trademarkClasses",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "trademarkClasses",
                event.selectedKeys,
              )
            }
            style={{ height: "200px", overflow: "auto" }}
          >
            {trademark_classes.map((eachClass) => (
              <Menu.Item key={eachClass.id}>{eachClass.number}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (proprietors !== null && proprietors.length > 0) {
        let filteredProprietors = proprietors
          .filter(
            (eachProprietor) =>
              eachProprietor.name
                .toLowerCase()
                .indexOf(`${this.state.filtersSearchTerms.proprietors}`) !== -1,
          )
          .slice(0, this.state?.page);
        proprietorsMenu = (
          <>
            <Input
              placeholder="Search proprietors"
              onChange={(event) =>
                this.changeStateValue_L1_Debounced(
                  "filtersSearchTerms",
                  "proprietors",
                  event.target.value.toLowerCase(),
                )
              }
              allowClear
            />
            <Menu
              style={{
                position: "initial",
                height: "200px",
                overflowY: "scroll",
                width: "250px",
              }}
              onScroll={(x) => {
                const { scrollTop, scrollHeight, clientHeight } = x.target;
                if (scrollTop + clientHeight >= scrollHeight - 20) {
                  this.setState({ page: this.state?.page + 50 });
                }
              }}
              selectable
              multiple
              selectedKeys={this.state.selectedFilters?.proprietors}
              onSelect={(event) =>
                this.changeStateValue_L1(
                  "selectedFilters",
                  "proprietors",
                  event.selectedKeys,
                )
              }
              onDeselect={(event) =>
                this.changeStateValue_L1(
                  "selectedFilters",
                  "proprietors",
                  event.selectedKeys,
                )
              }
            >
              {filteredProprietors.length > 0 ? (
                filteredProprietors.map((eachCategory) => (
                  <Menu.Item key={eachCategory.id}>
                    {eachCategory.name}
                  </Menu.Item>
                ))
              ) : (
                <div>No Data</div>
              )}
            </Menu>
          </>
        );
      }

      if (trademark_categories !== null && trademark_categories.length > 0) {
        trademarkCategoriesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.selectedFilters.trademarkCategories}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "trademarkCategories",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "trademarkCategories",
                event.selectedKeys,
              )
            }
          >
            {trademark_categories.map((eachCategory) => (
              <Menu.Item key={eachCategory.id}>{eachCategory.name}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (trademark_offices !== null && trademark_offices.length > 0) {
        trademarkOfficesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.selectedFilters.trademarkOffices}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "trademarkOffices",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "trademarkOffices",
                event.selectedKeys,
              )
            }
          >
            {trademark_offices.map((eachOffice) => (
              <Menu.Item key={eachOffice.id}>{eachOffice.name}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (trademark_types !== null && trademark_types.length > 0) {
        trademarkTypesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.selectedFilters.trademarkTypes}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "trademarkTypes",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "trademarkTypes",
                event.selectedKeys,
              )
            }
          >
            {trademark_types.map((eachType) => (
              <Menu.Item key={eachType.id}>{eachType.name}</Menu.Item>
            ))}
          </Menu>
        );
      }
    }

    return (
      <>
        <header className="General-Header">
          <Row className="m-top-10" justify="space-between" align="bottom">
            <Col span={18} className="alignL">
              <Dropdown
                overlay={trademarkClassesMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.trademarkClassesMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "trademarkClassesMenu",
                    event,
                  )
                }
              >
                <Button
                  className={`${
                    this.state.selectedFilters.trademarkClasses.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }`}
                >
                  Classes
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={proprietorsMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.proprietorsMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "proprietorsMenu",
                    event,
                  )
                }
              >
                <Button
                  className={`${
                    this.state.selectedFilters.proprietors.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }`}
                >
                  Proprietors
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={trademarkCategoriesMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.trademarkCategoriesMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "trademarkCategoriesMenu",
                    event,
                  )
                }
              >
                <Button
                  className={`${
                    this.state.selectedFilters.trademarkCategories.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }`}
                >
                  Categories
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={trademarkTypesMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.trademarkTypesMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "trademarkTypesMenu",
                    event,
                  )
                }
              >
                <Button
                  className={`${
                    this.state.selectedFilters.trademarkTypes.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }`}
                >
                  TM Types
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={trademarkOfficesMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.trademarkOfficesMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "trademarkOfficesMenu",
                    event,
                  )
                }
              >
                <Button
                  className={`${
                    this.state.selectedFilters.trademarkOffices.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }`}
                >
                  Offices
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            <Col span={4}>
              {/* <Tooltip placement="bottom" title="Download Journal">
                          <Icon type="download" style={{fontSize:"20px", color:"#4285f4", padding:"0px 5px"}}/>
                      </Tooltip> */}
              <Select
                style={{ width: 180, marginRight: "20px", float: "right" }}
                value={
                  this.props.journalNumber
                    ? this.props.journalNumber
                    : "Journal"
                }
                onChange={(journalNumber) =>
                  this.handleJournalChange(journalNumber)
                }
                loading={!this.props.journalNumber}
              >
                {this.props.journalsList &&
                  this.props.journalsList.map((eachJournal) => (
                    <Option
                      key={eachJournal.journal_number}
                      value={eachJournal.journal_number}
                    >{`${eachJournal.journal_number} | ${dateFormat(
                      eachJournal.published_date,
                      "-",
                    )}`}</Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={24}>
              <Menu mode="horizontal" className="filter-list m-top-10">
                <Menu.Item
                  style={{
                    backgroundColor: "white",
                  }}
                  key="searchDropdown"
                  className="filter-list-item apply-filter"
                >
                  <Dropdown overlay={searchTypesMenu}>
                    <Button style={headerButtonSelected}>
                      {this.state?.selectedFilters?.searchType}
                      <DownOutlined style={{ fontSize: "12px" }} />
                    </Button>
                  </Dropdown>
                </Menu.Item>

                {this.state?.selectedFilters?.searchType ===
                  "Application No/Name" && (
                  <Menu.Item
                    style={{ backgroundColor: "white" }}
                    key="search"
                    className="filter-list-item apply-filter"
                  >
                    <Input
                      placeholder="Application No/Name"
                      onChange={(event) =>
                        this.changeStateValue_L1(
                          "selectedFilters",
                          "containsTerm",
                          event.target.value,
                        )
                      }
                      value={this.state?.selectedFilters?.containsTerm}
                      allowClear
                      style={{ width: 200 }}
                    />
                  </Menu.Item>
                )}
                {this.state?.selectedFilters?.searchType ===
                  "Mark Description Search" && (
                  <Menu.Item
                    style={{ backgroundColor: "white" }}
                    key="mark"
                    className="filter-list-item apply-filter"
                  >
                    <Input
                      placeholder="Mark Description Search"
                      onChange={(event) =>
                        this.changeStateValue_L1(
                          "selectedFilters",
                          "mark_desc_contains_term",
                          event.target.value,
                        )
                      }
                      value={
                        this.state?.selectedFilters?.mark_desc_contains_term
                      }
                      allowClear
                      style={{ width: 250 }}
                    />
                  </Menu.Item>
                )}

                {/* <Search
                      placeholder="Search application number or trademark name"
                      onSearch={(searchInput) => this.handleApplyFilters(searchInput)}
                      enterButton={ */}
                <Menu.Item
                  key="apply"
                  className="filter-list-item apply-filter"
                >
                  <Button
                    style={{
                      backgroundColor: "#4285f4",
                      color: "#ffffff",
                      fontWeight: "600",
                    }}
                    onClick={() => this.handleApplyFilters()}
                  >
                    Apply
                  </Button>
                </Menu.Item>
                {/* }
                      style={{ width: "15vw", minWidth: "150px" }}
                  /> */}
                <Menu.Item
                  key="reset"
                  className="filter-list-item apply-filter"
                >
                  <Button type="link" onClick={() => this.handleResetFilters()}>
                    Reset
                  </Button>
                </Menu.Item>
                <Menu.Item
                  key="select-all"
                  className="filter-list-item apply-filter"
                >
                  <Checkbox
                    style={{ fontWeight: "100" }}
                    checked={this.state.customReportData.selectAll}
                    disabled={
                      !this.props.journalTotalMarksCount ||
                      this.props.journalDataStatus === PROGRESSING ||
                      this.props.journalDataStatus === LOADING
                        ? true
                        : false
                    }
                    onClick={(event) =>
                      this.changeStateValue_L1(
                        "customReportData",
                        "selectAll",
                        event.target.checked,
                      )
                    }
                  >
                    Select All
                  </Checkbox>
                </Menu.Item>
                {(this.state.customReportData.threatsLength > 0 ||
                  this.state.customReportData.selectAll) && (
                  <Menu.Item
                    key="create-report"
                    className="filter-list-item apply-filter"
                  >
                    <Badge
                      count={
                        this.state.customReportData.selectAll
                          ? "All"
                          : this.state.customReportData.threatsLength
                      }
                      style={{ marginTop: "5px" }}
                    >
                      <Button onClick={() => this.handleCreateReport()}>
                        Create Report
                      </Button>
                    </Badge>
                    {!this.state.customReportData.selectAll && (
                      <MinusCircleOutlined
                        style={{ marginLeft: "10px", fontSize: "14px" }}
                        onClick={() =>
                          this.setState({
                            customReportData: J_defaultCustomReportData,
                          })
                        }
                      />
                    )}
                  </Menu.Item>
                )}
              </Menu>
            </Col>
          </Row>
        </header>
        <div
          className="General-Content Flex-Content vertical_scroll"
          style={{
            height: `${
              this.props.watchAlert.length > 0
                ? "calc(100vh - 195px)"
                : "calc(100vh - 95px)"
            }`,
          }}
        >
          {/* When the journalData is received successfully and selectedVew is 1, cards will be rendered by the following code. */}
          {this.props.selectedView === "1" &&
            this.props.journalDataStatus === SUCCESS &&
            this.props.journalTotalMarksCount > 0 &&
            this.props.journalData.map((eachMark) => (
              <GeneralMarkCard
                key={eachMark.application_number}
                eachMark={eachMark}
                onSelectMark={(eachMark, checked) =>
                  this.handleCustomReportRecordSelection(eachMark, checked)
                }
                checked={
                  this.state.customReportData.threats.includes(
                    eachMark.application_number,
                  ) || this.state.customReportData.selectAll
                    ? true
                    : false
                }
                selectAllCards={this.state.customReportData.selectAll}
                changeStateValue_L1={this.changeStateValue_L1}
                styleCard={{}}
                similarity={null}
              />
            ))}

          {/* When the journalData is received successfully and selectedVew is 1, cards will be rendered by the following code. */}
          {this.props.selectedView === "2" &&
            this.props.journalDataStatus === SUCCESS &&
            this.props.journalTotalMarksCount > 0 && (
              <table className="Table">
                <thead className="Table-Head">
                  <tr className="Table-Head-Row">
                    <th
                      className="Table-Head-Cell Black-Shadow"
                      style={{ width: "4%", minWidth: "50px" }}
                    />
                    <th className="Table-Head-Cell Red-Shadow Red-BG">MARK</th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">TYPE</th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      PROPRIETORS
                    </th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      CLASSES
                    </th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      DoA | DoU
                    </th>
                    <th
                      className="Table-Head-Cell Red-Shadow Red-BG"
                      style={{ width: "4%", minWidth: "50px" }}
                    >
                      {this.state.tableDescriptionRowsVisibility ? (
                        <Tooltip placement="left" title="Hide Descriptions">
                          <MinusSquareFilled
                            style={{
                              fontSize: "20px",
                              verticalAlign: "baseline",
                            }}
                            onClick={() =>
                              this.setState({
                                tableDescriptionRowsVisibility: false,
                              })
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="left" title="Show Descriptions">
                          <PlusSquareFilled
                            style={{
                              fontSize: "20px",
                              verticalAlign: "baseline",
                            }}
                            onClick={() =>
                              this.setState({
                                tableDescriptionRowsVisibility: true,
                              })
                            }
                          />
                        </Tooltip>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="Table-Body">
                  {/* When table data is received successfully and is not empty, this table will render */}
                  {this.props.journalDataStatus === SUCCESS &&
                    this.props.journalTotalMarksCount > 0 &&
                    this.props.journalData.map((eachRow, rowIndex) => {
                      return (
                        <React.Fragment key={eachRow.application_number}>
                          <tr
                            className={`Table-Body-Row ${
                              rowIndex % 2 === 0 ? "Row-Even" : "Row-Odd"
                            }`}
                          >
                            <td>
                              <Checkbox
                                checked={
                                  this.state.customReportData.threats.includes(
                                    eachRow.application_number,
                                  ) || this.state.customReportData.selectAll
                                    ? true
                                    : false
                                }
                                onChange={(event) =>
                                  this.handleCustomReportRecordSelection(
                                    eachRow,
                                    event.target.checked,
                                  )
                                }
                                disabled={this.state.customReportData.selectAll}
                              />
                            </td>
                            <td style={{ display: "grid" }}>
                              <span>{eachRow.applied_for}</span>

                              {eachRow.associated_image && (
                                <>
                                  <span>
                                    <img
                                      src={eachRow.associated_image}
                                      height="70px"
                                      width="70px"
                                      alt=""
                                    />
                                  </span>
                                </>
                              )}
                              <span
                                onClick={() =>
                                  this.changeStateValue_L1(
                                    "drawersVisibility",
                                    "journalPage",
                                    eachRow.application_number,
                                  )
                                }
                                className="Application-Number"
                              >
                                {eachRow.application_number}
                              </span>
                            </td>
                            <td>{eachRow.tm_type}</td>
                            <td>
                              {eachRow.proprietor.map(
                                (eachProprietor, index, allProprietors) => (
                                  <React.Fragment key={eachProprietor.id}>
                                    <span>
                                      <span>
                                        <strong>Proprietor:</strong>{" "}
                                        {eachProprietor.name}
                                      </span>

                                      <span>
                                        <strong>Address:</strong>{" "}
                                        {eachProprietor.address}
                                      </span>
                                    </span>
                                    {allProprietors.length === index + 1 ? (
                                      ""
                                    ) : (
                                      <hr />
                                    )}
                                  </React.Fragment>
                                ),
                              )}
                            </td>
                            <td>
                              {eachRow.associated_class.map(
                                (eachClass, index, allClasses) =>
                                  allClasses.length === index + 1
                                    ? `${eachClass}`
                                    : `${eachClass}, `,
                              )}
                            </td>
                            <td>
                              <span>
                                DoA:{" "}
                                {eachRow.application_date
                                  ? eachRow.application_date
                                  : ""}
                              </span>

                              <span>
                                DoU:{" "}
                                {eachRow.date_of_usage
                                  ? eachRow.date_of_usage
                                  : ""}
                              </span>
                            </td>
                            <td />
                          </tr>
                          {this.state.tableDescriptionRowsVisibility && (
                            <tr
                              className={`Table-Body-Row ${
                                rowIndex % 2 === 0 ? "Row-Even" : "Row-Odd"
                              }`}
                            >
                              <td />
                              <td colSpan="5" style={{ verticalAlign: "top" }}>
                                {eachRow.description ? eachRow.description : ""}
                              </td>
                              <td />
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}

                  {/* When table data is received successfully and is empty, this table will render */}
                  {this.props.tableDataStatus === SUCCESS &&
                    this.props.totalTableDataCount === 0 && (
                      <tr style={{ height: "50vh" }}>
                        <td colSpan="6">
                          <Empty />
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            )}

          {/* When journalData is successful, unlike you but the is 0, just like you. Oooooooooooo B) */}
          {this.props.journalDataStatus === SUCCESS &&
            this.props.journalTotalMarksCount === 0 && (
              <div style={{ display: "table", height: "50vh", width: "100%" }}>
                <Empty
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                />
              </div>
            )}

          {/* When journalData is being fetched, this loading screen will render. */}
          {this.props.journalDataStatus === LOADING && (
            <div
              style={{
                display: "table",
                height: "70vh",
                width: "100%",
                verticalAlign: "middle",
              }}
            >
              <Spin size="large" style={{ display: "table-cell" }} />
            </div>
          )}

          {/* When there is an error while fetching the journal */}
          {this.props.journalDataStatus === ERROR && (
            <div
              style={{
                display: "table",
                height: "70vh",
                width: "100%",
                verticalAlign: "middle",
              }}
            >
              {/* <h1>Unfortunately, due to the occurrence of an error, we were unable to fetch the data from the server.</h1>
                              <h1>We apologize for the inconvenience. Please, try again after some time.</h1> */}
              {/* <NetworkError/> */}
            </div>
          )}
        </div>
        <footer className="General-Footer">
          <Radio.Group
            onChange={(event) =>
              this.props.TPAction(J_SELECT_VIEW, event.target.value)
            }
            value={this.props.selectedView}
            style={{ float: "left" }}
          >
            <Radio.Button value="1">Cards</Radio.Button>
            <Radio.Button value="2">Table</Radio.Button>
          </Radio.Group>
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            current={this.props.pageNumber}
            total={this.props.journalTotalMarksCount}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showSizeChanger={false}
            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
          />
        </footer>

        {/* When application number is clicked, this drawer will be opened */}
        <TrademarkJournalPage
          journalNumber={this.props.journalNumber}
          applicationNumber={this.state.drawersVisibility.journalPage}
          closeDrawer={() =>
            this.changeStateValue_L1("drawersVisibility", "journalPage", false)
          }
        />

        {/* When creat report is clicked, this drawer will be opened */}
        <Drawer
          title={<h4>Custom Report Details</h4>}
          placement="right"
          width={400}
          closable={false}
          open={this.state.drawersVisibility.customReport}
          onClose={() =>
            this.changeStateValue_L1("drawersVisibility", "customReport", false)
          }
        >
          <Row>
            <Col span={24}>
              Enter the name of your report:
              <br />
              <Input
                onChange={(event) => {
                  if (
                    /^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)
                  ) {
                    this.changeStateValue_L1(
                      "customReportData",
                      "reportName",
                      event.target.value,
                    );
                  }
                }}
                value={this.state.customReportData.reportName}
                placeholder="Report's name (Optional)"
              />
            </Col>
          </Row>
          <Row className="m-top-20">
            <Col span={24}>
              Choose your preferred report format:
              <br />
              <Radio.Group
                value={this.state.customReportData.reportFormat}
                onChange={(event) =>
                  this.changeStateValue_L1(
                    "customReportData",
                    "reportFormat",
                    event.target.value,
                  )
                }
                size="small"
              >
                <Radio value={PDF} style={{ fontWeight: 400 }}>
                  PDF
                </Radio>
                <br />
                <Radio
                  disabled={
                    this.state.customReportData.mark.application_number
                      ? true
                      : false
                  }
                  value={EXCEL}
                >
                  EXCEL
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row className="m-top-20">
            <Col span={24}>
              <Alert
                message="Note: Excel is not available for comparative report."
                type="warning"
                className="m-bot-10"
              />
              Choose any of your trademark:
              <br />
              <div>
                <Search
                  allowClear
                  placeholder="Search application number or name"
                  onSearch={(searchInput) =>
                    this.handleUserTrademarksApplyFilter(searchInput)
                  }
                  enterButton={
                    <Button
                      rootStyle={{
                        backgroundColor: "#4285f4",
                        color: "#ffffff",
                        fontWeight: "600",
                      }}
                    >
                      Apply
                    </Button>
                  }
                />
                <Menu
                  selectable
                  selectedKeys={
                    this.state.customReportData.mark.application_number
                      ? [
                          `${this.state.customReportData.mark.application_number}`,
                        ]
                      : []
                  }
                  onClick={(event) =>
                    this.setState({
                      customReportData: {
                        ...this.state.customReportData,
                        reportFormat: PDF,
                        mark:
                          this.state.customReportData.mark
                            .application_number ===
                          event.item.props.value.application_number
                            ? {}
                            : event.item.props.value,
                      },
                    })
                  }
                  rootStyle={{
                    height: "150px",
                    border: "1px solid #c9c9c9",
                    borderRadius: "5px",
                    overflow: "auto",
                  }}
                >
                  {(this.props.userTrademarks.trademarksDataStatus ===
                    SUCCESS ||
                    this.props.userTrademarks.trademarksDataStatus === ERROR) &&
                    this.props.userTrademarks.trademarksData !== null &&
                    this.props.userTrademarks.trademarksData.map(
                      (eachTrademark) => (
                        <Menu.Item
                          key={eachTrademark.application_number}
                          value={eachTrademark}
                        >
                          {eachTrademark.applied_for}
                        </Menu.Item>
                      ),
                    )}
                  {this.props.userTrademarks.trademarksDataStatus ===
                    LOADING && (
                    <div
                      rootStyle={{
                        display: "table",
                        height: "100%",
                        width: "100%",
                        verticalAlign: "middle",
                      }}
                    >
                      <Spin
                        size="default"
                        rootStyle={{ display: "table-cell" }}
                      />
                    </div>
                  )}
                </Menu>
                <div rootStyle={{ margin: "10px 0px" }} className="m-top-10">
                  <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.props.userTrademarks.pageNumber}
                    total={this.props.userTrademarks.filteredTrademarksCount}
                    pageSize={100}
                    showSizeChanger={false}
                    onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                  />
                </div>
              </div>
              <Alert
                message="Note: If your trademark is selected then a comparative report will be generated."
                type="warning"
                className="m-top-5"
              />
            </Col>
          </Row>
          <Row className="m-top-20">
            <Col span={24}>
              <Space>
                <Button
                  type="primary"
                  ghost
                  loading={this.props.customReportStatus === LOADING}
                  onClick={() => this.handleCreateReportConfirmation()}
                  rootStyle={{ marginRight: "10px" }}
                >
                  Confirm
                </Button>
                <Button
                  danger
                  ghost
                  onClick={() =>
                    this.changeStateValue_L1(
                      "drawersVisibility",
                      "customReport",
                      false,
                    )
                  }
                >
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Drawer>
      </>
    );
  }
}

const mapDispatchToProps = {
  getJournalsList,
  J_getJournal,
  getUserTrademarks,
  postCustomReport,
  TPAction,
  logOut,
};

function mapStateToProps(state) {
  return deepFreeze({
    journalsList: state.watchState.journalsList,
    currentJournal: state.watchState.currentJournal,
    pageNumber: state.watchState.journals.pageNumber,
    journalTotalMarksCount: state.watchState.journals.journalTotalMarksCount,
    filters: state.watchState.journals.filters,
    journalData: state.watchState.journals.journalData,
    journalNumber: state.watchState.journals.journalNumber,
    journalDataStatus: state.watchState.journals.journalDataStatus,
    lastAppliedFilters: state.watchState.journals.lastAppliedFilters,
    userTrademarks: state.watchState.journals.userTrademarks,
    selectedView: state.watchState.journals.selectedView,
    customReportStatus: state.watchState.journals.customReportStatus,

    watchAlert: state.alertMessages.watch,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Journals);
