import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  Drawer,
  Descriptions,
  Tooltip,
  Typography,
  Spin,
  Modal,
  Button,
  message,
} from "antd";
import {
  getWorkflowDetails,
  editWorkflowEmail,
  deleteWorkflowTask,
} from "../../../redux/actions/managerActions/workflow/action";
import {
  EDIT_WORKFLOW_EMAIL,
  EDIT_WORKFLOW_TODO_TASK,
  DELETE_WORKFLOW_EMAIL,
  DELETE_WORKFLOW_TODO_TASK,
} from "../../../redux/actions/managerActions/workflow/types";

import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import {
  changeStateValue_L0,
  changeStateValue_L1,
} from "./../../../functions/commonFunctions/generalFunctions";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import WorkflowEmailModal from "./WorkflowEmailModal";
import WorkflowTodoTaskModal from "./WorkflowTodoTaskModal";

const { Title } = Typography;

class WorkflowDetailsDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      deletingKey: "",
      modalVisibility: {
        addTaskVisible: false,
        workflowEmail: false,
      },
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    this.props.getWorkflowDetails({ workflow_id: this.props.workflowId });
  }

  showMailBody(data) {
    Modal.info({
      title: "Email Body",
      width: "50%",
      content: <div dangerouslySetInnerHTML={{ __html: data }}></div>,
      onOk() {},
    });
  }

  handleEditTask(data, taskType) {
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    if (taskType === "SEND EMAIL") {
      this.setState({
        modalVisibility: {
          taskData: data,
          taskType: taskType,
          editTaskFlag: true,
          workflowEmail: true,
          mailContent: mailContent,
          product: "MANAGER",
          subscription: "TRADEMARK",
          report_tag: {
            journal_number: "",
            journal_date: "",
            status: "",
            renewal_date: "",
            application_number: "",
            mark_name: "",
            date_of_application: "",
            class: "",
            date_of_usage: "",
            proprietor_name: "",
            opponent_name: "",
            tla_hearing_date: "",
            opposition_hearing_date: "",
            report_name: "",
          },
        },
      });
    } else if (taskType === "TO DO TASK") {
      this.setState({
        modalVisibility: {
          taskData: data,
          taskType: taskType,
          editTaskFlag: true,
          addTaskVisible: true,
        },
      });
    }
  }

  async handleDeleteTask(data, taskType) {
    this.setState({
      deletingKey: data.id,
    });
    if (taskType === "SEND EMAIL") {
      await this.props.deleteWorkflowTask({
        actionType: DELETE_WORKFLOW_EMAIL,
        task_id: data.id,
        action: taskType,
      });
      message[this.props.deleteWorkflowEmailStatus.toLowerCase()](
        this.props.deleteWorkflowEmailMessage
      );
    } else if (taskType === "TO DO TASK") {
      await this.props.deleteWorkflowTask({
        actionType: DELETE_WORKFLOW_TODO_TASK,
        task_id: data.id,
        action: taskType,
      });
      message[this.props.deleteWorkflowTodoTaskStatus.toLowerCase()](
        this.props.deleteWorkflowTodoTaskMessage
      );
    }
  }

  render() {
    const { modalVisibility } = this.state;
    const {
      managerWorkflowDetail,
      managerWorkflowEmailTask,
      managerWorkflowTodoTask,
      managerWorkflowDetailStatus,
      deleteWorkflowEmailStatus,
      deleteWorkflowTodoTaskStatus,
    } = this.props;

    const EmailTaskColumns = [
      {
        title: "Timing",
        width: 150,
        fixed: "left",
        render: (record) => {
          return (
            <div>
              <Tooltip title="Timing Of Email">
                <span>
                  {checkValue(record.timing_of_email)
                    ? record.timing_of_email
                    : "N.A."}
                </span>
              </Tooltip>
              {record.is_active ? (
                <Tooltip title="Active">
                  <CheckCircleOutlined
                    style={{
                      color: "#52c41a",
                      fontSize: "20px",
                      float: "right",
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Inactive">
                  <WarningOutlined
                    style={{
                      color: "#f81d22",
                      fontSize: "20px",
                      float: "right",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      },

      {
        title: "Send From | Is Send To Based On Mark",
        width: 150,
        render: (record) => {
          return (
            <div>
              <span>
                {checkValue(record.send_from_email)
                  ? record.send_from_email
                  : "N.A."}{" "}
              </span>
              {record?.is_send_to_based_on_mark ? (
                <Tooltip title="Is Send To Based On Mark">
                  <CheckCircleOutlined
                    style={{
                      color: "#52c41a",
                      fontSize: "20px",
                      float: "right",
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Is Send To Based On Mark (Inactive)">
                  <WarningOutlined
                    style={{
                      color: "#f81d22",
                      fontSize: "20px",
                      float: "right",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      },

      {
        title: "Send To",
        width: 150,
        render: (record) => {
          return (
            <div
              className="vertical_scroll"
              style={{ maxHeight: 200, height: 200, overflow: "auto" }}
            >
              {record.send_to_emails &&
                record.send_to_emails.length > 0 &&
                record.send_to_emails.map((eachMail, key) => (
                  <p key={`${key}-send_email`}>
                    {checkValue(eachMail) ? eachMail : "N.A."}
                  </p>
                ))}
              {record.send_to_emails.length === 0 && (
                <div>
                  <p>N.A.</p>
                </div>
              )}
            </div>
          );
        },
      },

      {
        title: "CC",
        width: 150,
        render: (record) => {
          return (
            <div
              className="vertical_scroll"
              style={{ maxHeight: 200, height: 200, overflow: "auto" }}
            >
              {record.cc_emails &&
                record.cc_emails.length > 0 &&
                record.cc_emails.map((eachMail, key) => (
                  <p key={`${key}-send_email`}>
                    {checkValue(eachMail) ? eachMail : "N.A."}
                  </p>
                ))}
              {record.cc_emails.length === 0 && (
                <div>
                  <p>N.A.</p>
                </div>
              )}
            </div>
          );
        },
      },

      {
        title: "Subject",
        width: 150,
        render: (record) => {
          return (
            record.email_template && (
              <div>
                <p>
                  {checkValue(record.email_template.subject)
                    ? record.email_template.subject
                    : "N.A."}
                </p>
              </div>
            )
          );
        },
      },

      {
        title: "Mail Body",
        width: 150,
        render: (record) => {
          return (
            record.email_template && (
              <>
                {record.email_template.content && (
                  <>
                    <div
                      style={{
                        maxHeight: 200,
                        height: 200,
                        overflow: "hidden",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: record.email_template.content,
                      }}
                    ></div>
                    {record.email_template.content.length > 300 && (
                      <span
                        className="application-text alignC"
                        onClick={() =>
                          this.showMailBody(record.email_template.content)
                        }
                      >
                        See more
                      </span>
                    )}
                  </>
                )}
              </>
            )
          );
        },
      },

      // {
      //     title: 'Attachement',
      //     width: 150,
      //     render: (record)=> {
      //         return (
      //             <div>
      //                 <p
      //                     className={checkValue(record.file_name) ? 'application-text' : ''}
      //                     onClick={checkValue(record.file_name) ? ()=> window.open(record.file_attachment, '_blank')  : ()=> {}}>
      //                         {checkValue(record.file_name) ? record.file_name : 'N.A.'}
      //                 </p>
      //                 <p
      //                     className={checkValue(record.file_urls) ? 'application-text' : ''}
      //                     onClick={checkValue(record.file_urls) ? ()=> window.open(record.file_urls, '_blank')  : ()=> {}}>
      //                         {checkValue(record.file_urls) ? record.file_urls : 'N.A.'}
      //                 </p>
      //             </div>
      //         )
      //     }
      // },

      {
        title: "Days Between Two Emails",
        width: 100,
        dataIndex: "days_between_two_emails",
        key: "days_between_two_emails",
      },

      {
        title: "Maximum Send Emails Limit",
        width: 100,
        dataIndex: "maximum_send_emails_limit",
        key: "maximum_send_emails_limit",
      },

      {
        title: "Send Email After These Days",
        width: 100,
        dataIndex: "send_email_after_these_days",
        key: "send_email_after_these_days",
      },

      {
        title: "Created | Modified ",
        width: 150,
        render: (record) => {
          return (
            <div>
              <p>{checkValue(record.created) ? record.created : "N.A."}</p>
              <p>{checkValue(record.modified) ? record.modified : "N.A."}</p>
              <p>
                {checkValue(record.trigger_date) ? record.trigger_date : "N.A."}
              </p>
            </div>
          );
        },
      },

      {
        title: "Action",
        fixed: "right",
        width: 150,
        render: (record) => {
          return (
            <div>
              <Tooltip placement="top" title={"Edit Send Email Task"}>
                <p className="alignC">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => this.handleEditTask(record, "SEND EMAIL")}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Delete Send Email Task"}>
                <p className="alignC">
                  <Button
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    loading={
                      this.state.deletingKey === record.id &&
                      deleteWorkflowEmailStatus === LOADING
                    }
                    onClick={() => this.handleDeleteTask(record, "SEND EMAIL")}
                  />
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const TodoTaskColumns = [
      {
        title: "Title",
        width: 150,
        fixed: "left",
        render: (record) => {
          return (
            <div>
              <p>{checkValue(record.title) ? record.title : "N.A."}</p>
            </div>
          );
        },
      },

      {
        title: "Description",
        width: 200,
        render: (record) => {
          return (
            <div>
              <p>
                {checkValue(record.description) ? record.description : "N.A."}
              </p>
            </div>
          );
        },
      },

      {
        title: "Priority",
        width: 150,
        render: (record) => {
          return (
            <div>
              <p>{checkValue(record.priority) ? record.priority : "N.A."}</p>
            </div>
          );
        },
      },

      {
        title: "Timing",
        width: 150,
        render: (record) => {
          return (
            <div>
              <Tooltip title="Timing Of Reminder">
                <span>
                  {checkValue(record.timing_of_reminder)
                    ? record.timing_of_reminder
                    : "N.A."}
                </span>
              </Tooltip>
              <Tooltip title="Active Status">
                <LegacyIcon
                  style={{
                    fontSize: "20px",
                    float: "right",
                    color: `${record.is_active ? "#52c41a" : "#f81d22"}`,
                  }}
                  type={`${record.is_active ? "check-circle" : "warning"}`}
                />
              </Tooltip>
            </div>
          );
        },
      },

      {
        title: "Emails",
        width: 150,
        render: (record) => {
          return (
            <div
              className="vertical_scroll"
              style={{ maxHeight: 200, height: 200, overflow: "auto" }}
            >
              {record.emails &&
                record.emails.length > 0 &&
                record.emails.map((eachMail, key) => (
                  <p key={`${key}-send_email`}>
                    {checkValue(eachMail) ? eachMail : "N.A."}
                  </p>
                ))}
              {record.emails.length === 0 && <p>N.A.</p>}
            </div>
          );
        },
      },

      {
        title: "Days Between Two Reminders",
        width: 100,
        dataIndex: "days_between_two_reminders",
        key: "days_between_two_reminders",
      },

      {
        title: "Maximum Reminders",
        width: 100,
        dataIndex: "maximum_reminder",
        key: "maximum_reminder",
      },

      {
        title: "Due Date After These Days",
        width: 100,
        dataIndex: "due_date_after_these_days",
        key: "due_date_after_these_days",
      },

      {
        title: "Create Reminder After These Days",
        width: 100,
        dataIndex: "create_reminder_after_these_days",
        key: "create_reminder_after_these_days",
      },

      {
        title: "Created | Modified ",
        width: 150,
        render: (record) => {
          return (
            <div>
              <p>{checkValue(record.created) ? record.created : "N.A."}</p>
              <p>{checkValue(record.modified) ? record.modified : "N.A."}</p>
              <p>
                {checkValue(record.trigger_date) ? record.trigger_date : "N.A."}
              </p>
            </div>
          );
        },
      },

      {
        title: "Action",
        fixed: "right",
        width: 150,
        render: (record) => {
          return (
            <div>
              <Tooltip placement="top" title={"Edit Todo Task"}>
                <p className="alignC">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => this.handleEditTask(record, "TO DO TASK")}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Delete Todo Task"}>
                <p className="alignC">
                  <Button
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    loading={
                      this.state.deletingKey === record.id &&
                      deleteWorkflowTodoTaskStatus === LOADING
                    }
                    onClick={() => this.handleDeleteTask(record, "TO DO TASK")}
                  />
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Drawer
          title={
            <Title level={4} style={{ marginBottom: 0 }}>
              Workflow Details
            </Title>
          }
          placement="right"
          width={"70%"}
          closable={true}
          onClose={() => this.props.onCloseDrawer()}
          open={this.props.visible}
          bodyStyle={{ padding: 0 }}
          footer={false}
        >
          <div
            className="vertical_scroll"
            style={{
              height: "calc(100vh - 65px)",
              overflowX: "hidden",
              overflowY: "auto",
              padding: 20,
            }}
          >
            {managerWorkflowDetailStatus === LOADING && (
              <Row>
                <Col span={24}>
                  <div
                    className="loading-spin"
                    style={{ height: "calc(100vh - 105px)" }}
                  >
                    <Spin />
                  </div>
                </Col>
              </Row>
            )}
            {managerWorkflowDetailStatus === SUCCESS && (
              <Row>
                {managerWorkflowDetail && (
                  <Col span={24}>
                    <Descriptions
                      title={
                        checkValue(managerWorkflowDetail.title)
                          ? managerWorkflowDetail.title
                          : "N.A."
                      }
                      bordered
                      size="small"
                      layout="horizontal"
                      column={2}
                    >
                      <Descriptions.Item label="TITLE">
                        {checkValue(managerWorkflowDetail.title)
                          ? managerWorkflowDetail.title
                          : "N.A."}
                      </Descriptions.Item>
                      <Descriptions.Item label="ACTION">
                        {checkValue(managerWorkflowDetail.actions)
                          ? managerWorkflowDetail.actions
                          : "N.A."}
                      </Descriptions.Item>
                      <Descriptions.Item label="APPLIED ON">
                        {checkValue(managerWorkflowDetail.applied_on)
                          ? managerWorkflowDetail.applied_on
                          : "N.A."}
                      </Descriptions.Item>
                      <Descriptions.Item label="CHANGE IN">
                        {checkValue(managerWorkflowDetail.key)
                          ? managerWorkflowDetail.key
                          : "N.A."}
                      </Descriptions.Item>
                      <Descriptions.Item label="VALUES">
                        {checkValue(managerWorkflowDetail.values)
                          ? managerWorkflowDetail.values
                          : "N.A."}
                      </Descriptions.Item>
                      <Descriptions.Item label="TIGGER TASKS">
                        {checkValue(managerWorkflowDetail.trigger_tasks)
                          ? managerWorkflowDetail.trigger_tasks
                          : "N.A."}
                      </Descriptions.Item>
                      <Descriptions.Item label="CREATED">
                        {checkValue(managerWorkflowDetail.created)
                          ? managerWorkflowDetail.created
                          : "N.A."}
                      </Descriptions.Item>
                      <Descriptions.Item label="MODIFIED">
                        {checkValue(managerWorkflowDetail.modified)
                          ? managerWorkflowDetail.modified
                          : "N.A."}
                      </Descriptions.Item>
                      {/* <Descriptions.Item label="START DATE TIME">{checkValue(managerWorkflowDetail.start_date_time) ? managerWorkflowDetail.start_date_time : 'N.A.'}</Descriptions.Item>
                                            <Descriptions.Item label="END DATE TIME">{checkValue(managerWorkflowDetail.end_date_time) ? managerWorkflowDetail.end_date_time : 'N.A.'}</Descriptions.Item>
                                            <Descriptions.Item label="MARKS">{checkValue(managerWorkflowDetail.marks) ? managerWorkflowDetail.marks.join(', ') : 'N.A.'}</Descriptions.Item> */}
                    </Descriptions>
                  </Col>
                )}

                {managerWorkflowEmailTask &&
                managerWorkflowEmailTask?.length > 0 ? (
                  <Col span={24} className="m-top-20">
                    <Title level={4}>Workflow Send Email</Title>
                    <Table
                      bordered
                      rowKey={(record) => record.id}
                      columns={EmailTaskColumns}
                      dataSource={managerWorkflowEmailTask}
                      scroll={{ x: 1500, y: 500 }}
                      pagination={false}
                    />
                  </Col>
                ) : null}

                {managerWorkflowTodoTask &&
                managerWorkflowTodoTask?.length > 0 ? (
                  <Col span={24} className="m-top-20">
                    <Title level={4}>Workflow Todo Task</Title>
                    <Table
                      bordered
                      rowKey={(record) => record.id}
                      columns={TodoTaskColumns}
                      dataSource={managerWorkflowTodoTask}
                      scroll={{ x: 1500, y: 500 }}
                      pagination={false}
                    />
                  </Col>
                ) : null}
              </Row>
            )}
            {managerWorkflowDetailStatus === ERROR && (
              <Row>
                <Col span={24}>
                  <div
                    className="error-container"
                    style={{ height: "calc(100vh - 105px)" }}
                  >
                    <Title level={4}>
                      unfortunately an error has occurred please try again
                      later. will connect you shortly.
                    </Title>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Drawer>
        <WorkflowEmailModal
          visible={modalVisibility.workflowEmail}
          onCloseModal={() =>
            this.changeStateValue_L1("modalVisibility", "workflowEmail", false)
          }
          taskData={modalVisibility.taskData}
          editTaskFlag={modalVisibility.editTaskFlag}
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={modalVisibility.mailContent}
          action={modalVisibility.taskType}
          product={modalVisibility.product}
          subscription={modalVisibility.subscription}
          report_tag={modalVisibility.report_tag}
          actionType={EDIT_WORKFLOW_EMAIL}
          report_flag={true}
        />
        {modalVisibility.addTaskVisible && (
          <WorkflowTodoTaskModal
            visible={modalVisibility.addTaskVisible}
            onCloseModal={() =>
              this.changeStateValue_L1(
                "modalVisibility",
                "addTaskVisible",
                false
              )
            }
            taskData={modalVisibility.taskData}
            editTaskFlag={modalVisibility.editTaskFlag}
            action={modalVisibility.taskType}
            actionType={EDIT_WORKFLOW_TODO_TASK}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getWorkflowDetails,
  editWorkflowEmail,
  deleteWorkflowTask,
};

function mapStateToProps(state) {
  const {
    managerWorkflowDetail,
    managerWorkflowEmailTask,
    managerWorkflowTodoTask,
    managerWorkflowDetailStatus,

    deleteWorkflowEmailMessage,
    deleteWorkflowEmailStatus,

    deleteWorkflowTodoTaskMessage,
    deleteWorkflowTodoTaskStatus,
  } = state.managerState.workflowState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    managerWorkflowDetail,
    managerWorkflowEmailTask,
    managerWorkflowTodoTask,
    managerWorkflowDetailStatus,

    deleteWorkflowEmailMessage,
    deleteWorkflowEmailStatus,

    deleteWorkflowTodoTaskMessage,
    deleteWorkflowTodoTaskStatus,

    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowDetailsDrawer);
