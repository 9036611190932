import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import _, { debounce } from "lodash";

import { DownOutlined } from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  Tabs,
  Card,
  Button,
  Typography,
  Table,
  Switch,
  Menu,
  Radio,
  Badge,
  Dropdown,
  Input,
  Checkbox,
  message,
  Tooltip,
  notification,
  Spin,
} from "antd";

import {
  getExamReportData,
  getExamReportFilterData,
} from "../../../redux/actions/managerActions/report/actions";

import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import {
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../../functions/commonFunctions/generalFunctions";

import {
  LOADING,
  Exam_Report_DefaultSelectedFilters,
  Exam_Report_DefaultFilterConfig,
} from "../../../constants/commonConstants/generalConstants";

import SendEmail from "../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../commonComponents/Modals/SendEmailNew";
import { logOut } from "../../../redux/actions/commonActions/actions";
import { getEmailTemplateData } from "../../../redux/actions/commonActions/actions";

import { history } from "../../../history";
import DateRangePicker from "../../../functions/commonFunctions/dateRangePicker";

import DownloadReportModal from "./DownloadReportDrawer";
import FilterSearchPaginate from "../../commonComponents/FilterComponents/FilterSearchPaginate";

const { Content } = Layout;
const { Text, Title } = Typography;
const { Search } = Input;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      managerType: true,
      hearingType: true,
      tmActiveTab: "trademark_custom_report",
      patentActiveTab: "patent_custom_report",
      modalsVisibility: {
        sendEmail: false,
        attachment: null,
        mailContent: "",
        report_tag: {},
      },
      selectedFilters: Exam_Report_DefaultSelectedFilters,
      filterConfig: Exam_Report_DefaultFilterConfig,
      selectedRows: [],
      selectAll: false,
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.getExamReportData({
        filterFlag: false,
        filters: this.state.selectedFilters,
        pageNumber: 1,
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "YOUR_CLASSES",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "THREAT_CLASSES",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "YOUR_PROPRIETOR",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "THREAT_PROPRIETOR",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "YOUR_TRADEMARK_TYPE",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "THREAT_TRADEMARK_TYPE",
        filter_contains_term: "",
      });
    } else {
      this.props.logOut();
    }
  }

  changeManagerType() {
    this.setState({
      managerType: !this.state.managerType,
    });
  }

  changeHearingReport() {
    this.setState({
      hearingType: !this.state.hearingType,
    });
  }

  downloadPDFReport(data) {
    window.open(data.pdf, "_blank");
  }

  downloadEXCELReport(data) {
    window.open(data.excel, "_blank");
  }

  viewReport(data) {
    window.open(data, "_blank");
  }

  handleNextpage = (pageNumber) => {
    this.props.getExamReportData({
      filterFlag: true,
      filters: this.state.selectedFilters,
      pageNumber: pageNumber,
    });
  };

  handleTrademarkProfile(data) {
    if (data) {
      sessionStorage.setItem(
        "management_trademark_id",
        data.management_mark_id || data.management_threat_id,
      );
      sessionStorage.setItem(
        "trademark_application_number",
        data.application_number,
      );
      window.open(
        "/manager/trademark/trademark-profile/" +
          (data.management_mark_id || data.management_threat_id),
        "_blank",
      );
      this.setState({
        management_trademark_id:
          data.management_mark_id || data.management_threat_id,
      });
    }
  }

  setDate = (key, val) => {
    this.setState({
      selectedFilters: {
        ...this.state?.selectedFilters,
        [`${key}`]: {
          ...this.state?.selectedFilters[`${key}`],
          range: val,
        },
      },
    });
  };

  fetchFilters = (required_filter, filter_contains_term, pageNumber) => {
    setTimeout(() => {
      this.props.getExamReportFilterData({
        pageNumber,
        filters_required_flag: true,
        required_filter,
        filter_contains_term,
      });
    }, 500);
  };

  handleSearchDebounced = debounce(function (
    required_filter,
    filter_contains_term,
  ) {
    this.fetchFilters(required_filter, filter_contains_term, 1);
  }, 500);

  onFilterSearch = (required_filter, filter_contains_term) => {
    this.setState({
      isSearching: true,
      filterConfig: {
        ...this.state?.filterConfig,
        [`${required_filter}`]: {
          ...this.state?.filterConfig[`${required_filter}`],
          filter_contains_term: filter_contains_term,
          page: 1,
        },
      },
    });

    this.handleSearchDebounced(required_filter, filter_contains_term);
  };
  onFilterPaginate = (required_filter, filter_contains_term, page) => {
    if (this.props.examReportFilterStatus !== "LOADING") {
      const page_size = 100;
      let total_pages = 0;

      let count = 0;

      switch (required_filter) {
        case "YOUR_CLASSES":
          count = this.props.yourClassFiltersCount;
          break;
        case "YOUR_PROPRIETOR":
          count = this.props.yourProprietorsFiltersCount;
          break;
        case "YOUR_TRADEMARK_TYPE":
          count = this.props.yourTrademarkTypesFiltersCount;
          break;
        case "THREAT_CLASSES":
          count = this.props.threatClassesFiltersCount;
          break;
        case "THREAT_PROPRIETOR":
          count = this.props.threatProprietorsFiltersCount;
          break;
        case "THREAT_TRADEMARK_TYPE":
          count = this.props.threatTrademarkTypesFiltersCount;
          break;
        default:
          count = 0;
      }

      if (count !== undefined && count !== null && count > 0) {
        total_pages = Math.ceil(count / page_size);
      }
      if (page <= total_pages) {
        this.setState({
          filterConfig: {
            ...this.state?.filterConfig,
            [`${required_filter}`]: {
              ...this.state?.filterConfig[`${required_filter}`],
              page,
            },
          },
        });

        this.fetchFilters(required_filter, filter_contains_term, page);
      }
    }
  };

  applyFilter() {
    const { selectedFilters } = this.state;

    if (this.state.selectedFilters !== Exam_Report_DefaultSelectedFilters) {
      this.props.getExamReportData({
        filterFlag: true,
        filters: selectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("please select filter");
    }
  }

  resetFilter = () => {
    if (this.state.selectedFilters !== Exam_Report_DefaultSelectedFilters) {
      this.props.getExamReportData({
        filterFlag: false,
        filters: this.state.selectedFilters,
        pageNumber: 1,
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "YOUR_CLASSES",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "THREAT_CLASSES",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "YOUR_PROPRIETOR",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "THREAT_PROPRIETOR",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "YOUR_TRADEMARK_TYPE",
        filter_contains_term: "",
      });
      this.props.getExamReportFilterData({
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "THREAT_TRADEMARK_TYPE",
        filter_contains_term: "",
      });
      this.setState({
        selectedFilters: Exam_Report_DefaultSelectedFilters,
        filterConfig: Exam_Report_DefaultFilterConfig,
      });
    } else {
      message.error("No Filter Applied");
    }
  };

  downloadReport() {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys?.length > 0) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }
  handleProprietorProfile = (propId) => {
    if (propId) {
      sessionStorage.setItem("proprietor_id", propId);
      window.open("/manager/trademark/proprietor-profile/" + propId, "_blank");
      this.setState({
        proprietor_id: propId,
      });
    }
  };
  render() {
    const ExamReportRowselection = {
      onSelectAll: (selected, selectedRows) => {
        let data = this.props.examReport && this.props.examReport.data;
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: !this.state.selectAll,
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
        className: "exam-report-checkbox",
      }),
    };

    const columnsThreatMarks = [
      {
        className: "exam-report-column-1",
        title: "Your Marks",
        children: [
          {
            className: "exam-report-column-1",
            title: "Application Number",

            render: (text, record) => {
              return (
                <div>
                  <p
                    className="application-text"
                    onClick={() =>
                      this.handleTrademarkProfile(record.cited_mark)
                    }
                  >
                    <span>
                      {checkValue(record.cited_mark?.application_number)
                        ? record.cited_mark?.application_number
                        : "N.A."}{" "}
                      -{" "}
                    </span>
                    <span className="text-camelCase">
                      {checkValue(record.cited_mark?.applied_for)
                        ? record.cited_mark?.applied_for?.toLowerCase()
                        : "N.A."}
                    </span>
                  </p>
                  <p>
                    <img
                      className="trademark-image"
                      src={
                        checkValue(record.cited_mark?.associated_image)
                          ? record.cited_mark?.associated_image
                          : ""
                      }
                      alt=""
                      style={{ width: "90%" }}
                    />
                  </p>
                </div>
              );
            },
          },

          {
            className: "exam-report-column-1",
            title: "TM Applied For",

            render: (text, record) => (
              <p className="text-camelCase">
                {checkValue(record) ? record?.cited_mark?.applied_for : "N.A."}
              </p>
            ),
          },
          {
            className: "exam-report-column-1",
            title: "Type",
            render: (text, record) => (
              <p className="text-camelCase">
                {checkValue(record?.cited_mark?.type)
                  ? record?.cited_mark?.type
                  : "N.A."}
              </p>
            ),
          },
          {
            className: "exam-report-column-1",
            title: "Class",

            render: (text, record) => (
              <p className="text-camelCase">
                {checkValue(record)
                  ? record?.cited_mark?.associated_class?.join(", ")
                  : "N.A."}
              </p>
            ),
          },

          {
            className: "exam-report-column-1",
            title: "Proprietor",

            render: (text, record) =>
              record?.cited_mark?.proprietor.map((element, index, array) => (
                <p
                  key={element.id}
                  className="application-text text-camelCase"
                  onClick={() => this.handleProprietorProfile(element.id)}
                >
                  {checkValue(element)
                    ? `${element.name?.toLowerCase()}${
                        index + 1 === array?.length ? "" : " | "
                      }`
                    : "N.A."}
                </p>
              )),
          },

          {
            width: 100,
            className: "exam-report-column-1",
            title: "Tag",
            render: (text, record) => {
              return (
                <div>
                  {record?.cited_mark?.tags?.length > 0
                    ? record?.cited_mark?.tags?.map((tag) => (
                        <span
                          key={tag.id}
                          className="Tag"
                          style={{
                            color: `${tag.color_code}`,
                            border: `1px solid ${tag.color_code}`,
                          }}
                        >
                          {tag.name.toUpperCase()}
                        </span>
                      ))
                    : "N.A."}
                </div>
              );
            },
          },
          {
            className: "exam-report-column-1",
            title: "DoA & DoU",
            style: { background: "black" },

            render: (text, record) => {
              return {
                props: {
                  style: { borderRight: "2px solid #C4C4C4" },
                },
                children: (
                  <>
                    <p>
                      DoA:{" "}
                      {checkValue(record?.cited_mark?.date_of_application)
                        ? record?.cited_mark?.date_of_application
                        : "N.A."}
                    </p>
                    <p>
                      DoU:{" "}
                      {checkValue(record?.cited_mark?.date_of_usage)
                        ? record?.cited_mark?.date_of_usage
                        : "N.A."}
                    </p>
                  </>
                ),
              };
            },
          },
        ],
      },

      {
        className: "exam-report-column-1",
        title: "Threat Marks",
        children: [
          {
            className: "exam-report-column-1",
            title: "Application Number",

            render: (text, record) => {
              return (
                <div>
                  <p
                    className="application-text"
                    onClick={() =>
                      this.handleTrademarkProfile(record.threat_mark)
                    }
                  >
                    <span>
                      {checkValue(record.threat_mark?.application_number)
                        ? record.threat_mark?.application_number
                        : "N.A."}{" "}
                      -{" "}
                    </span>
                    <span className="text-camelCase">
                      {checkValue(record.threat_mark?.applied_for)
                        ? record.threat_mark?.applied_for?.toLowerCase()
                        : "N.A."}
                    </span>
                  </p>
                  <p>
                    <img
                      className="trademark-image"
                      src={
                        checkValue(record.threat_mark?.associated_image)
                          ? record.threat_mark?.associated_image
                          : ""
                      }
                      alt=""
                      style={{ width: "90%" }}
                    />
                  </p>
                </div>
              );
            },
          },

          {
            className: "exam-report-column-1",
            title: "TM Applied For",

            render: (text, record) => (
              <p className="text-camelCase">
                {checkValue(record) ? record?.threat_mark?.applied_for : "N.A."}
              </p>
            ),
          },
          {
            className: "exam-report-column-1",
            title: "Type",
            render: (text, record) => (
              <p className="text-camelCase">
                {checkValue(record?.threat_mark?.type)
                  ? record?.threat_mark?.type
                  : "N.A."}
              </p>
            ),
          },
          {
            className: "exam-report-column-1",
            title: "Class",

            render: (text, record) => (
              <p className="text-camelCase">
                {checkValue(record)
                  ? record?.threat_mark?.associated_class?.join(", ")
                  : "N.A."}
              </p>
            ),
          },
          {
            className: "exam-report-column-1",
            title: "Proprietor",

            render: (text, record) =>
              record?.threat_mark?.proprietor.map((element, index, array) => (
                <p
                  key={element.id}
                  className="application-text text-camelCase"
                  onClick={() => this.handleProprietorProfile(element.id)}
                >
                  {checkValue(element)
                    ? `${element.name?.toLowerCase()}${
                        index + 1 === array?.length ? "" : " | "
                      }`
                    : "N.A."}
                </p>
              )),
          },

          {
            className: "exam-report-column-1",
            title: "DoA & DoU",

            render: (text, record) => (
              <>
                <p>
                  DoA:{" "}
                  {checkValue(record?.threat_mark?.date_of_application)
                    ? record?.threat_mark?.date_of_application
                    : "N.A."}
                </p>
                <p>
                  DoU:{" "}
                  {checkValue(record?.threat_mark?.date_of_usage)
                    ? record?.threat_mark?.date_of_usage
                    : "N.A."}
                </p>
              </>
            ),
          },
          {
            className: "exam-report-column-1",
            title: "Actions",
            render: (text, record) => {
              return record ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div key={record?.id}>
                    <Button
                      type="link"
                      onClick={() =>
                        this.viewReport(record?.examination_report_url)
                      }
                      style={{ padding: "0px" }}
                    >
                      View Report
                    </Button>
                  </div>
                  <div>Report Date: {record?.examination_report_date}</div>
                </div>
              ) : (
                ""
              );
            },
          },
        ],
      },
    ];

    const { selectedFilters } = this.state;
    const yourClassesData = this.props.yourClassFilters;
    const threatClassesData = this.props.threatClassesFilters;
    const yourProprietorsData = this.props.yourProprietorsFilters;
    const threatProprietorsData = this.props.threatProprietorsFilters;
    const yourTrademarkTypesData = this.props.yourTrademarkTypesFilters;
    const threatTrademarkTypesData = this.props.threatTrademarkTypesFilters;

    let noFilterData = (
      <Menu>
        <Menu.Item className="filter-list-item">
          <div style={{ width: "200px" }}>No filter Data</div>
        </Menu.Item>
      </Menu>
    );
    let proprietorsMenu = noFilterData;
    let classesMenu = noFilterData;
    let trademarkTypeMenu = noFilterData;

    let yourClasses = (
      <FilterSearchPaginate
        datasource={yourClassesData}
        filter_key={"YOUR_CLASSES"}
        req_filter={"YOUR_CLASSES"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.examReportFilterStatus}
        title={"Your"}
        onSelect={this.changeStateValue_L1}
        type={"array"}
        searchPlaceholder={"Search Your Classes"}
        searchValue={
          this.state?.filterConfig["YOUR_CLASSES"]?.filter_contains_term
        }
      />
    );
    let threatClasses = (
      <FilterSearchPaginate
        datasource={threatClassesData}
        filter_key={"THREAT_CLASSES"}
        req_filter={"THREAT_CLASSES"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.examReportFilterStatus}
        title={"Threat"}
        onSelect={this.changeStateValue_L1}
        type={"array"}
        searchPlaceholder={"Search Threat Classes"}
        searchValue={
          this.state?.filterConfig["THREAT_CLASSES"]?.filter_contains_term
        }
      />
    );
    classesMenu = (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {yourClasses}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {threatClasses}
          </div>
        </div>
      </>
    );
    let yourTrademarkTypes = (
      <FilterSearchPaginate
        datasource={yourTrademarkTypesData}
        filter_key={"YOUR_TRADEMARK_TYPE"}
        req_filter={"YOUR_TRADEMARK_TYPE"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.examReportFilterStatus}
        title={"Your"}
        onSelect={this.changeStateValue_L1}
        type={"object"}
        searchPlaceholder={"Search Your TM Type"}
        searchValue={
          this.state?.filterConfig["YOUR_TRADEMARK_TYPE"]?.filter_contains_term
        }
      />
    );
    let threatTrademarkTypes = (
      <FilterSearchPaginate
        datasource={threatTrademarkTypesData}
        filter_key={"THREAT_TRADEMARK_TYPE"}
        req_filter={"THREAT_TRADEMARK_TYPE"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.examReportFilterStatus}
        title={"Threat"}
        onSelect={this.changeStateValue_L1}
        type={"object"}
        searchPlaceholder={"Search Threat TM Type"}
        searchValue={
          this.state?.filterConfig["THREAT_TRADEMARK_TYPE"]
            ?.filter_contains_term
        }
      />
    );
    trademarkTypeMenu = (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {yourTrademarkTypes}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {threatTrademarkTypes}
          </div>
        </div>
      </>
    );

    let yourProprietors = (
      <FilterSearchPaginate
        datasource={yourProprietorsData}
        filter_key={"YOUR_PROPRIETOR"}
        req_filter={"YOUR_PROPRIETOR"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.examReportFilterStatus}
        title={"Your"}
        onSelect={this.changeStateValue_L1}
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "YOUR_PROPRIETOR",
            this.state?.filterConfig[`${"YOUR_PROPRIETOR"}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${"YOUR_PROPRIETOR"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Proprietor"}
        searchValue={
          this.state?.filterConfig["YOUR_PROPRIETOR"]?.filter_contains_term
        }
      />
    );
    let threatProprietors = (
      <FilterSearchPaginate
        datasource={threatProprietorsData}
        filter_key={"THREAT_PROPRIETOR"}
        req_filter={"THREAT_PROPRIETOR"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.examReportFilterStatus}
        title={"Threat"}
        onSelect={this.changeStateValue_L1}
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "THREAT_PROPRIETOR",
            this.state?.filterConfig[`${"THREAT_PROPRIETOR"}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${"THREAT_PROPRIETOR"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Threat Proprietor"}
        searchValue={
          this.state?.filterConfig["THREAT_PROPRIETOR"]?.filter_contains_term
        }
      />
    );
    proprietorsMenu = (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {yourProprietors}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {threatProprietors}
          </div>
        </div>
      </>
    );

    const filters = (
      <Row justify="space-between">
        <Col span={16}>
          <Menu
            selectedKeys={[
              selectedFilters?.YOUR_CLASSES || selectedFilters?.THREAT_CLASSES
                ? selectedFilters?.YOUR_CLASSES.length > 0 ||
                  selectedFilters?.THREAT_CLASSES.length > 0
                  ? "classesMenu"
                  : ""
                : "",
              selectedFilters?.YOUR_TRADEMARK_TYPE ||
              selectedFilters?.THREAT_TRADEMARK_TYPE
                ? selectedFilters?.YOUR_TRADEMARK_TYPE.length > 0 ||
                  selectedFilters?.THREAT_TRADEMARK_TYPE.length > 0
                  ? "trademarkTypeMenu"
                  : ""
                : "",
              selectedFilters?.YOUR_PROPRIETOR ||
              selectedFilters?.THREAT_PROPRIETOR
                ? selectedFilters?.YOUR_PROPRIETOR.length > 0 ||
                  selectedFilters?.THREAT_PROPRIETOR.length > 0
                  ? "proprietorsMenu"
                  : ""
                : "",
              selectedFilters?.threat_date_of_application?.value !== "" ||
              selectedFilters?.date_of_application?.value !== ""
                ? "doaMenu"
                : "",
              selectedFilters?.date_of_examination?.value !== ""
                ? "doeMenu"
                : "",
              selectedFilters?.threat_date_of_usage?.value !== "" ||
              selectedFilters?.date_of_usage?.value !== "" ||
              selectedFilters?.date_of_usage_proposed ||
              selectedFilters?.threat_date_of_usage_proposed
                ? "douMenu"
                : "",
            ]}
            className="filter-list"
            mode="horizontal"
          >
            <Menu.Item
              key="contentTerm"
              className="filter-list-item apply-filter"
            >
              <Input
                placeholder="Search Application No/Name"
                onChange={(event) =>
                  this.changeStateValue_L1(
                    "selectedFilters",
                    "contains_term",
                    event.target.value,
                  )
                }
                value={selectedFilters?.contains_term}
                allowClear
                style={{ width: 240 }}
                suffix={null}
              />
            </Menu.Item>

            <Menu.Item key="proprietorsMenu" className="filter-list-item">
              <Dropdown
                dropdownRender={() => proprietorsMenu}
                overlayClassName="filter-menu"
                autoAdjustOverflow
                onOpenChange={(flag) => handleOpenChange(flag, "proprietors")}
                open={this.state.open === "proprietors"}
              >
                <div className="ant-dropdown-link">
                  <Text>Proprietor </Text>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>
            <Menu.Item key="doeMenu" className="filter-list-item">
              <Dropdown
                overlay={
                  <>
                    <div style={{ padding: "8px" }}>
                      <span style={{ fontWeight: "600" }}>
                        Your TM Date of Examination
                      </span>
                      <DateRangePicker
                        startValue={
                          selectedFilters?.date_of_examination?.startValue
                        }
                        endValue={
                          selectedFilters?.date_of_examination?.endValue
                        }
                        setDate={(val) =>
                          this.setDate("date_of_examination", val)
                        }
                        range={
                          this.state.selectedFilters?.date_of_examination?.range
                        }
                        handleDateString={(startValue, endValue, dateString) =>
                          this.setState({
                            selectedFilters: {
                              ...this.state.selectedFilters,
                              date_of_examination: {
                                ...this.state?.selectedFilters
                                  ?.date_of_examination,
                                startValue: startValue,
                                endValue: endValue,
                                value:
                                  startValue !== null || endValue !== null
                                    ? dateString
                                    : null,
                              },
                            },
                          })
                        }
                      />
                    </div>
                  </>
                }
                overlayClassName="filter-menu"
              >
                <div className="ant-dropdown-link">
                  <Text>DoE</Text>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>
            <Menu.Item key="classesMenu" className="filter-list-item">
              <Dropdown
                dropdownRender={() => classesMenu}
                overlayClassName="filter-menu"
                autoAdjustOverflow
                onOpenChange={(flag) => handleOpenChange(flag, "classes")}
                open={this.state.open === "classes"}
              >
                <div className="ant-dropdown-link">
                  <Text>Class </Text>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>
            <Menu.Item key="trademarkTypeMenu" className="filter-list-item">
              <Dropdown
                dropdownRender={() => trademarkTypeMenu}
                overlayClassName="filter-menu"
                autoAdjustOverflow
                onOpenChange={(flag) => handleOpenChange(flag, "type")}
                open={this.state.open === "type"}
              >
                <div className="ant-dropdown-link">
                  <Text>Type </Text>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>

            <Menu.Item key="doaMenu" className="filter-list-item">
              <Dropdown
                overlay={
                  <>
                    <div style={{ padding: "8px" }}>
                      <span style={{ fontWeight: "600" }}>
                        Your TM Date of Application
                      </span>
                      <DateRangePicker
                        startValue={
                          selectedFilters?.date_of_application?.startValue
                        }
                        endValue={
                          selectedFilters?.date_of_application?.endValue
                        }
                        setDate={(val) =>
                          this.setDate("date_of_application", val)
                        }
                        range={
                          this.state.selectedFilters?.date_of_application?.range
                        }
                        handleDateString={(startValue, endValue, dateString) =>
                          this.setState({
                            selectedFilters: {
                              ...this.state.selectedFilters,
                              date_of_application: {
                                ...this.state?.selectedFilters
                                  ?.date_of_application,
                                startValue: startValue,
                                endValue: endValue,
                                value:
                                  startValue !== null || endValue !== null
                                    ? dateString
                                    : null,
                              },
                            },
                          })
                        }
                      />
                    </div>
                    <div style={{ padding: "8px" }}>
                      <span style={{ fontWeight: "600" }}>
                        Threat's Date of Application
                      </span>
                      <DateRangePicker
                        startValue={
                          selectedFilters?.threat_date_of_application
                            ?.startValue
                        }
                        endValue={
                          selectedFilters?.threat_date_of_application?.endValue
                        }
                        setDate={(val) =>
                          this.setDate("threat_date_of_application", val)
                        }
                        range={
                          this.state.selectedFilters?.threat_date_of_application
                            ?.range
                        }
                        handleDateString={(startValue, endValue, dateString) =>
                          this.setState({
                            selectedFilters: {
                              ...this.state.selectedFilters,
                              threat_date_of_application: {
                                ...this.state?.selectedFilters
                                  ?.threat_date_of_application,
                                startValue: startValue,
                                endValue: endValue,
                                value:
                                  startValue !== null || endValue !== null
                                    ? dateString
                                    : null,
                              },
                            },
                          })
                        }
                      />
                    </div>
                  </>
                }
                overlayClassName="filter-menu"
              >
                <div className="ant-dropdown-link">
                  <Text>DoA</Text>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>
            <Menu.Item key="douMenu" className="filter-list-item">
              <Dropdown
                overlay={
                  <>
                    <div style={{ padding: "8px" }}>
                      <span style={{ fontWeight: "600" }}>
                        Your TM Date of Usage
                      </span>
                      <DateRangePicker
                        startValue={selectedFilters?.date_of_usage?.startValue}
                        endValue={selectedFilters?.date_of_usage?.endValue}
                        setDate={(val) => this.setDate("date_of_usage", val)}
                        range={this.state.selectedFilters?.date_of_usage?.range}
                        handleDateString={(startValue, endValue, dateString) =>
                          this.setState({
                            selectedFilters: {
                              ...this.state.selectedFilters,
                              date_of_usage: {
                                ...this.state?.selectedFilters?.date_of_usage,
                                startValue: startValue,
                                endValue: endValue,
                                value:
                                  startValue !== null || endValue !== null
                                    ? dateString
                                    : null,
                              },
                            },
                          })
                        }
                      />
                      <div style={{ textAlign: "center", lineHeight: "32px" }}>
                        <Checkbox
                          checked={
                            this.state.selectedFilters.date_of_usage_proposed
                          }
                          onChange={(event) =>
                            this.changeStateValue_L1(
                              "selectedFilters",
                              "date_of_usage_proposed",
                              event.target.checked,
                            )
                          }
                        >
                          Proposed
                        </Checkbox>
                      </div>
                    </div>
                    <div style={{ padding: "8px" }}>
                      <span style={{ fontWeight: "600" }}>
                        Threat's Date of Usage
                      </span>
                      <DateRangePicker
                        startValue={
                          selectedFilters?.threat_date_of_usage?.startValue
                        }
                        endValue={
                          selectedFilters?.threat_date_of_usage?.endValue
                        }
                        setDate={(val) =>
                          this.setDate("threat_date_of_usage", val)
                        }
                        range={
                          this.state.selectedFilters?.threat_date_of_usage
                            ?.range
                        }
                        handleDateString={(startValue, endValue, dateString) =>
                          this.setState({
                            selectedFilters: {
                              ...this.state.selectedFilters,
                              threat_date_of_usage: {
                                ...this.state?.selectedFilters
                                  ?.threat_date_of_usage,
                                startValue: startValue,
                                endValue: endValue,
                                value:
                                  startValue !== null || endValue !== null
                                    ? dateString
                                    : null,
                              },
                            },
                          })
                        }
                      />
                      <div style={{ textAlign: "center", lineHeight: "32px" }}>
                        <Checkbox
                          checked={
                            this.state.selectedFilters
                              .threat_date_of_usage_proposed
                          }
                          onChange={(event) =>
                            this.changeStateValue_L1(
                              "selectedFilters",
                              "threat_date_of_usage_proposed",
                              event.target.checked,
                            )
                          }
                        >
                          Proposed
                        </Checkbox>
                      </div>
                    </div>
                  </>
                }
                overlayClassName="filter-menu"
              >
                <div className="ant-dropdown-link">
                  <Text>DoU</Text>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Menu.Item>

            <Menu.Item
              key="applyFilter"
              className="filter-list-item apply-filter"
            >
              <Button type="primary" onClick={() => this.applyFilter()}>
                <i className="fa fa-filter" aria-hidden="true" />
                <Text style={{ color: "#fff", marginLeft: "5px" }}>
                  Apply Filters
                </Text>
              </Button>
            </Menu.Item>
            <Menu.Item
              key="resetFilter"
              className="filter-list-item apply-filter"
            >
              <Button type="link" onClick={() => this.resetFilter()}>
                <Text o style={{ padding: "0px 5px", color: "#1890ff" }}>
                  Reset All
                </Text>
              </Button>
            </Menu.Item>
          </Menu>
        </Col>
        <Col>
          <>
            <Badge
              count={
                this.state?.selectAll
                  ? "All"
                  : this.state?.selectedRowKeys?.length
              }
              style={{ marginTop: "5px" }}
            >
              <Button
                type="primary"
                onClick={() => this.downloadReport()}
                style={{ marginTop: "8px", marginLeft: "8px" }}
              >
                Create Report
              </Button>
            </Badge>
          </>
        </Col>
      </Row>
    );

    const table = (
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ padding: "16px" }}>
          <Table
            rowKey={(record) => record.id}
            columns={columnsThreatMarks}
            className="exam-report-threat-table"
            bordered
            dataSource={this.props.examReport && this.props.examReport.data}
            pagination={{
              pageSize: 100,
              showQuickJumper: true,
              showSizeChanger: false,
              defaultCurrent: 1,
              total: this.props.examReport && this.props.examReport.count,
              onChange: this.handleNextpage,
            }}
            scroll={{ y: "calc(70vh - 100px)" }}
            loading={this.props.examReportStatus === LOADING ? true : false}
            rowSelection={ExamReportRowselection}
          />
        </Col>
      </Row>
    );

    const title = (
      <Row style={{ marginBottom: "8px" }}>
        <Col xs={{ span: 12 }} lg={{ span: 24 }}>
          <Title style={{ marginBottom: "4px" }} level={3}>
            {"Examination Report"}
          </Title>
          <Text style={{ fontSize: "16px" }} type="secondary">
            {`Total Threats against your Marks: ${
              this.props.examReport?.count || 0
            }`}
          </Text>
        </Col>
      </Row>
    );

    const content = (
      <>
        {filters}
        {table}
      </>
    );

    const handleOpenChange = (flag, key) => {
      if (flag) {
        this.setState({
          open: key,
        });
      } else {
        this.setState({
          open: null,
        });
      }
    };

    return (
      <div style={{ background: "white", height: "100vh" }}>
        <Content style={{ margin: "16px" }}>
          {title}
          {content}
        </Content>
        {this.state.visible && (
          <DownloadReportModal
            visible={this.state.visible}
            onCloseModal={() => this.setState({ visible: false })}
            selectAll={this.state.selectAll}
            selectedRows={this.state.selectedRowKeys}
            filterFlag={selectedFilters?.filterFlag}
            filters={selectedFilters}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getExamReportData,
  getExamReportFilterData,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const {
    examReport,
    examReportStatus,
    examReportPageNumber,

    yourClassFilters,
    threatClassesFilters,
    yourProprietorsFilters,
    threatProprietorsFilters,
    yourTrademarkTypesFilters,
    threatTrademarkTypesFilters,
    examReportFilterStatus,

    yourClassFiltersCount,
    threatClassesFiltersCount,
    yourProprietorsFiltersCount,
    threatProprietorsFiltersCount,
    yourTrademarkTypesFiltersCount,
    threatTrademarkTypesFiltersCount,
  } = state.managerState.reportState;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    examReport,
    examReportStatus,
    examReportPageNumber,

    yourClassFilters,
    threatClassesFilters,
    yourProprietorsFilters,
    threatProprietorsFilters,
    yourTrademarkTypesFilters,
    threatTrademarkTypesFilters,
    examReportFilterStatus,

    yourClassFiltersCount,
    threatClassesFiltersCount,
    yourProprietorsFiltersCount,
    threatProprietorsFiltersCount,
    yourTrademarkTypesFiltersCount,
    threatTrademarkTypesFiltersCount,

    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
