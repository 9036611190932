import React from "react";
import { Dropdown, Button, Space, Checkbox, Menu } from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { updateBooleanFilters } from "../../../../redux/actions/watchActions/watchActions";

const MultipleCheckboxComponents = ({
  excludedReport = false,
  setExcludedReport,
  shortlist = false,
  setShortlist,
  excludeShortlisted = false,
  setExcludeShortlisted,
  tableView = false,
  open = false,
  shouldUpdateInRedux = true,
}) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const dispatch = useDispatch();

  const dropdownArea = () => (
    <Menu
      id="tm_applied_ll_take_tour_step3"
      style={open === "1" ? { display: "none" } : {}}
    >
      <Menu.Item>
        <Checkbox
          checked={excludedReport}
          onChange={(e) => {
            setExcludedReport(e.target.checked);
            shouldUpdateInRedux &&
              dispatch(
                updateBooleanFilters("excludeReportedConfig", e.target.checked),
              );
          }}
        >
          Exclude Reported
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          checked={shortlist}
          disabled={excludeShortlisted}
          onChange={(e) => {
            setShortlist(e.target.checked);
            shouldUpdateInRedux &&
              dispatch(
                updateBooleanFilters("shortlistedConfig", e.target.checked),
              );
          }}
        >
          Shortlisted
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          checked={excludeShortlisted}
          disabled={shortlist}
          onChange={(e) => {
            setExcludeShortlisted(e.target.checked);
            shouldUpdateInRedux &&
              dispatch(
                updateBooleanFilters(
                  "excludeShortlistedConfig",
                  e.target.checked,
                ),
              );
          }}
        >
          Exclude Shortlisted
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  return tableView ? (
    dropdownArea()
  ) : (
    <Dropdown
      className="tm_applied_ll_take_tour_step3_dropdown"
      dropdownRender={() => dropdownArea()}
      overlayClassName="Filters-Menu"
      open={openFilter || open}
      onOpenChange={(event) => {
        setOpenFilter(event);
      }}
      selectable
    >
      <Button
        // id="tm_applied_ll_take_tour_step3_btn"
        className={`${
          excludedReport || shortlist || excludeShortlisted
            ? "Filters-Button-Selected"
            : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
      >
        <Space>
          <MenuOutlined />
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default MultipleCheckboxComponents;
