//--------IP-SUITE TYPES--------
import { GET_USER_SUBSCRIPTIONS } from '../actions/commonActions/types';
import { LOADING, SUCCESS, ERROR } from '../../constants/commonConstants/generalConstants';

const defaultState = {
    productSubscription: null,
};

export default function(state = defaultState, action){
    switch (action.type) {
        case GET_USER_SUBSCRIPTIONS:
            switch (action.status) {
                case LOADING:
                    return {
                        ...state,
                    }
                case SUCCESS:
                    const subscription = action.payload.subscriptions;
                    let productSubscription = [];
                    if (subscription) {
                        if (subscription.manager.length > 0 ) {
                            const option = {
                                label: "Manager",
                                value: "MANAGER",
                                children: subscription.manager.map((data) => ({ label: data.toUpperCase(), value: data.toUpperCase()}))
                            }
                            productSubscription = [...productSubscription, option]
                            
                        } 
                        if (subscription.search.length > 0 ) {
                            const option = {
                                label: "Trademark Search",
                                value: "SEARCH",
                                children: subscription.search.map((data) => ({ label: data.toUpperCase(), value: data.toUpperCase()}))
                            }
                            productSubscription = [...productSubscription, option]
                        }
                        if (subscription.watch.length > 0 ) {
                            const option = {
                                label: "Trademark Watch",
                                value: "WATCH",
                                children: subscription.watch.map((data) => ({ label: data.toUpperCase(), value: data.toUpperCase()}))
                            }
                            productSubscription = [...productSubscription, option]
                        }
                    }
                    return {
                        ...state,
                        productSubscription
                    }
                case ERROR:
                    return {
                        ...state,
                    }
                default:
                    break;
            }
            break;
        default:
            return state;
    }
}