//--------ROOT URL--------
export const ROOT_URL = 'https://mikeapi-staging.mikelegal.com/api';
// export const ROOT_URL = 'https://miketmapi.mikelegal.com/api';
// export const ROOT_URL = 'http://127.0.0.1:8000/api';

//--------MANAGER--------
export const MGR_RT_URL = `${ROOT_URL}/manager`;


//--------SEARCH--------
export const SRCH_RT_URL = `${ROOT_URL}/search`;

//--------WATCH--------
export const WAT_RT_URL = `${ROOT_URL}/watch`;
