//--------LIBRARIES--------
import {
  Table,
  Row,
  Col,
  Image,
  Space,
  Tag,
  Tooltip,
  Checkbox,
  Menu,
  Typography,
  notification,
  Divider,
  Empty,
} from "antd";
import React, { useState } from "react";
import {
  EyeFilled,
  StarFilled,
  BookTwoTone,
  FlagFilled,
  BookOutlined,
  FilterFilled,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import SimilarityFilter from "../../../watchFilter/newCommonComponent/SimilarityFilter";
import ProprietorFilterComponent from "../../../watchFilter/newCommonComponent/ProprietorFilterComponent";
import SelectFilter from "../../../watchFilter/newCommonComponent/SelectFilter";
import DateFilter from "../../../watchFilter/newCommonComponent/DateFilter";
import TagFilter from "../../../watchFilter/newCommonComponent/TagFilter";
import { ROOT_URL } from "../../../../../configs/rootConfigs";
import { dateFormat } from "../../../../../functions/commonFunctions/dateFunctions";
import { patchListBookmark } from "../../../../../redux/actions/watchActions/listDashboardActions";
import { getEmailTemplateData } from "../../../../../redux/actions/commonActions/actions";
import { getListLayoutData } from "../../../../../redux/actions/watchActions/listDashboardActions";
import { LOADING } from "../../../../../constants/commonConstants/generalConstants";
import TrademarkJournalPage from "../../../subComponents/Drawers/TrademarkJournalPage";
import {
  updateBooleanFilters,
  updateNoTags,
} from "../../../../../redux/actions/watchActions/watchActions";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import MenuFilter from "../../../watchFilter/newCommonComponent/MenuFilter";
import progressImage from "../../../../../assets/common/progress_gif.gif";

const { Text } = Typography;

const ListContent = (props) => {
  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3] || "";
  const [isJournalProcessed, setIsJournalProcessed] = useState(true);
  const {
    rowExpanded,
    similarity,
    setSimilarity,
    proprietors,
    setProprietors,
    excludeMarkProprietors,
    journal,
    setExcludeMarkProprietors,
    tmClass,
    excludeSameClassInfringement,
    sameClassInfringement,
    setTMClass,
    setSameClassInfringement,
    setExcludeSameClassInfringement,
    yourDateOfApplication,
    yourDateOfUsage,
    yourDateOfUsageProposed,
    setYourDateOfApplication,
    setYourDateOfUsage,
    setYourDateOfUsageProposed,
    threatDateOfApplication,
    threatDateOfUsage,
    threatDateOfUsageProposed,
    setThreatDateOfApplication,
    setThreatDateOfUsage,
    setThreatDateOfUsageProposed,
    threatTMClass,
    setThreatClass,
    threatProprietors,
    excludeThreatProprietors,
    setThreatProprietors,
    setExcludeThreatProprietors,
    tags,
    setTags,
    noTags,
    setNoTags,
    setMailObj,
    publishedDate,
    selected,
    setSelected,
    andOrTags,
    setAndOrTags,
    andOrClass,
    setAndOrClass,
    andOrThreatClass,
    setAndOrThreatClass,
    selectAll,
    setSelectAll,
    generateFilterObject,
    showSelectIcons,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
    threatTMTypes,
    setThreatTMTypes,
    imageTextPercentage,
    setImageTextPercentage,
    threatImageTextPercentage,
    setThreatImageTextPercentage,
  } = props;

  const getPrimaryFilterPath = () => {
    let templatePath = "applied/list_layout";

    if (urlKey === "tm_applied") {
      templatePath = "applied/list_layout";
    } else if (urlKey === "tm_protect") {
      templatePath = "tm_protect/list_layout";
    } else if (urlKey === "image_text" || urlKey === "image_text_new") {
      templatePath = "image_text/list_layout";
    } else if (urlKey === "image_label") {
      templatePath = "image_label/list_layout";
    } else if (urlKey === "journals") {
      templatePath = "journals/dashboard";
    } else if (urlKey === "brand_compare") {
      templatePath = "brand_compare/list_layout";
    } else if (urlKey === "image_similarity") {
      templatePath = "image_similarity/list_layout";
    } else {
      templatePath = "applied/list_layout";
    }
    return templatePath;
  };

  const [journalPageVisible, setJournalPageVisible] = useState(null);

  const {
    markproprietorConfig,
    threatproprietorConfig,
    markClassConfig,
    threatClassConfig,
    tagsConfig,
    threatTypeConfig,
    ownBrandClassConfig,
    threatBrandClassConfig,
    ownBrandProprietorConfig,
    threatBrandProprietorConfig,
    journalConfig,
  } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  React.useEffect(() => {
    if (journal && journalConfig.data.length) {
      const journalDetail = journalConfig.data.find(
        (item) => String(item.value) === String(journal),
      );
      if (journalDetail) {
        setIsJournalProcessed(journalDetail.meta_data.processing);
      }
    }
  }, [journal, journalConfig.data]);

  const { current_template_id } = useSelector(
    (state) => state.watchState.watchTemplate[dashboardLayout][urlKey].config,
  );

  const { watch: watchAlert } = useSelector((state) => state.alertMessages);

  const extraClassChildren = (
    <>
      <div style={{ textAlign: "left", padding: "5px" }}>
        <Checkbox
          disabled={excludeSameClassInfringement}
          checked={sameClassInfringement}
          onChange={(event) => {
            setSameClassInfringement(event.target.checked);
            dispatch(
              updateBooleanFilters(
                "sameClassInfringementConfig",
                event.target.checked,
              ),
            );
          }}
        >
          Same Class Infringements
        </Checkbox>
      </div>
      <div style={{ textAlign: "left", padding: "5px" }}>
        <Checkbox
          disabled={sameClassInfringement}
          checked={excludeSameClassInfringement}
          onChange={(event) => {
            setExcludeSameClassInfringement(event.target.checked);
            dispatch(
              updateBooleanFilters(
                "excludeSameClassInfringementConfig",
                event.target.checked,
              ),
            );
          }}
        >
          Exclude Same Class Infringements
        </Checkbox>
      </div>
    </>
  );

  let extraTagChildren = (
    <Menu>
      <Menu.Item
        disabled={tags?.length > 0}
        key="no_tags"
        style={{
          position: "relative",
          marginLeft: "-5px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        className="menu-items-list"
      >
        <Checkbox
          checked={noTags}
          onChange={(event) => {
            setNoTags(event.target.checked);
            dispatch(updateNoTags(event.target.checked));
          }}
          disabled={tags?.length > 0}
        >
          <Text style={{ fontWeight: "400" }}>No Tags</Text>
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  // Add Filters here.....
  const getColumnFilter = (dataIndex) => ({
    filterIcon: () => {
      let filtered = false;
      filtered =
        (dataIndex === "similarity" &&
          (similarity?.from > 0 || similarity?.to < 100)) ||
        (dataIndex === "tags" && (tags?.length || noTags)) ||
        (dataIndex === "mark_doa" &&
          (yourDateOfApplication?.dateString !== "" ||
            yourDateOfUsage?.dateString !== "" ||
            yourDateOfUsageProposed)) ||
        (dataIndex === "mark_classes" &&
          (tmClass?.length ||
            sameClassInfringement ||
            excludeSameClassInfringement)) ||
        (dataIndex === "mark_proprietor" &&
          (proprietors?.length || excludeMarkProprietors?.length)) ||
        (dataIndex === "threat_doa" &&
          (threatDateOfApplication?.dateString !== "" ||
            threatDateOfUsage?.dateString !== "" ||
            threatDateOfUsageProposed)) ||
        (dataIndex === "threat_classes" &&
          (threatTMClass?.length ||
            sameClassInfringement ||
            excludeSameClassInfringement)) ||
        (dataIndex === "threat_proprietor" &&
          (threatProprietors?.length || excludeThreatProprietors?.length)) ||
        (dataIndex === "threat_types" && threatTMTypes?.length) ||
        (dataIndex === "own_brand_classes" &&
          (tmClass?.length ||
            sameClassInfringement ||
            excludeSameClassInfringement)) ||
        (dataIndex === "threat_brand_classes" &&
          (threatTMClass?.length ||
            sameClassInfringement ||
            excludeSameClassInfringement)) ||
        (dataIndex === "own_brand_proprietor" &&
          (proprietors?.length || excludeMarkProprietors?.length)) ||
        (dataIndex === "threat_brand_proprietor" &&
          (threatProprietors?.length || excludeThreatProprietors?.length)) ||
        (dataIndex === "image_text_percentage" &&
          (imageTextPercentage?.from > 0 || imageTextPercentage?.to < 100)) ||
        (dataIndex === "threat_image_text_percentage" &&
          (threatImageTextPercentage?.from > 0 ||
            threatImageTextPercentage?.to < 100));
      return (
        <FilterFilled
          id={`watch_table_filter_icon_id_${dataIndex}`}
          style={{
            color: filtered ? "#1677ff" : "black",
            marginRight: dataIndex === "threat_proprietor" ? "4px" : "",
          }}
        />
      );
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => (
      <>
        {dataIndex === "tags" && visible && (
          <TagFilter
            filteredData={tagsConfig}
            key={tagsConfig.key}
            value={tags}
            setValue={setTags}
            extraChildren={extraTagChildren}
            menuItemDisabled={noTags}
            loading={tagsConfig.loading}
            journal_number={journal}
            current_template_id={current_template_id}
            tableView
            andOrTags={andOrTags}
            setAndOrTags={setAndOrTags}
            filterPath={getPrimaryFilterPath()}
          />
        )}
        {dataIndex === "similarity" && visible && (
          <SimilarityFilter
            key={"similarity"}
            value={similarity}
            setValue={setSimilarity}
            tableView
          />
        )}
        {dataIndex === "mark_proprietor" && visible && (
          <ProprietorFilterComponent
            filteredData={markproprietorConfig}
            key={markproprietorConfig.key}
            value={proprietors}
            setValue={setProprietors}
            loading={markproprietorConfig.loading}
            journal_number={journal}
            exclude={excludeMarkProprietors}
            setExclude={setExcludeMarkProprietors}
            tableView
            current_template_id={current_template_id}
            filterPath={getPrimaryFilterPath()}
          />
        )}
        {dataIndex === "mark_classes" && visible && (
          <SelectFilter
            filteredData={markClassConfig}
            key={markClassConfig.key}
            value={tmClass}
            setValue={setTMClass}
            loading={markClassConfig.loading}
            journal_number={journal}
            extraChildren={extraClassChildren}
            tableView
            showSwitch
            andOr={andOrClass}
            setAndOr={setAndOrClass}
            current_template_id={current_template_id}
            filterPath={getPrimaryFilterPath()}
          />
        )}
        {dataIndex === "mark_doa" && visible && (
          <DateFilter
            menu_label="Your TM DoA|DoU"
            subtitle1="Your TM Date of Application"
            subtitle2="Your TM Date of Usage"
            key={"tm_date"}
            filter_key_1="your_date_of_application"
            filter_key_2="your_date_of_usage"
            value1={yourDateOfApplication}
            setValue1={setYourDateOfApplication}
            value2={yourDateOfUsage}
            setValue2={setYourDateOfUsage}
            proposed={yourDateOfUsageProposed}
            setProposed={setYourDateOfUsageProposed}
            tableView
          />
        )}
        {dataIndex === "threat_proprietor" && visible && (
          <ProprietorFilterComponent
            filteredData={threatproprietorConfig}
            key={threatproprietorConfig.key}
            value={threatProprietors}
            setValue={setThreatProprietors}
            loading={threatproprietorConfig.loading}
            journal_number={journal}
            exclude={excludeThreatProprietors}
            setExclude={setExcludeThreatProprietors}
            tableView
            current_template_id={current_template_id}
            filterPath={getPrimaryFilterPath()}
            rename={urlKey === "journals" ? "Proprietors" : ""}
          />
        )}
        {dataIndex === "threat_classes" && visible && (
          <SelectFilter
            filteredData={threatClassConfig}
            key={threatClassConfig.key}
            value={threatTMClass}
            setValue={setThreatClass}
            loading={threatClassConfig.loading}
            journal_number={journal}
            extraChildren={
              !(urlKey === "tm_protect" || urlKey === "journals") &&
              extraClassChildren
            }
            tableView
            showSwitch
            andOr={andOrThreatClass}
            setAndOr={setAndOrThreatClass}
            current_template_id={current_template_id}
            filterPath={getPrimaryFilterPath()}
            rename={urlKey === "journals" ? "Classes" : ""}
          />
        )}
        {dataIndex === "threat_doa" && visible && (
          <DateFilter
            menu_label="Threat's TM DoA|DoU"
            subtitle1={
              urlKey === "journals"
                ? "TM Date of Application"
                : "Threat's TM Date of Application"
            }
            subtitle2={
              urlKey === "journals"
                ? "TM Date of Usage"
                : "Threat's TM Date of Usage"
            }
            key={"threat_date"}
            filter_key_1="threat_date_of_application"
            filter_key_2="threat_date_of_usage"
            value1={threatDateOfApplication}
            setValue1={setThreatDateOfApplication}
            value2={threatDateOfUsage}
            setValue2={setThreatDateOfUsage}
            proposed={threatDateOfUsageProposed}
            setProposed={setThreatDateOfUsageProposed}
            tableView
          />
        )}
        {dataIndex === "threat_types" && visible && (
          <MenuFilter
            filteredData={threatTypeConfig}
            value={threatTMTypes}
            setValue={setThreatTMTypes}
            loading={threatTypeConfig.loading}
            tableView
          />
        )}
        {dataIndex === "own_brand_classes" && visible && (
          <SelectFilter
            filteredData={ownBrandClassConfig}
            key={ownBrandClassConfig.key}
            value={tmClass}
            setValue={setTMClass}
            loading={ownBrandClassConfig.loading}
            journal_number={journal}
            // extraChildren={extraClassChildren}
            tableView
            showSwitch
            andOr={andOrClass}
            setAndOr={setAndOrClass}
            current_template_id={current_template_id}
            filterPath={getPrimaryFilterPath()}
          />
        )}
        {dataIndex === "threat_brand_classes" && visible && (
          <SelectFilter
            filteredData={threatBrandClassConfig}
            key={threatBrandClassConfig.key}
            value={threatTMClass}
            setValue={setThreatClass}
            loading={threatBrandClassConfig.loading}
            journal_number={journal}
            // extraChildren={extraClassChildren}
            tableView
            showSwitch
            andOr={andOrThreatClass}
            setAndOr={setAndOrThreatClass}
            current_template_id={current_template_id}
            filterPath={getPrimaryFilterPath()}
          />
        )}
        {dataIndex === "own_brand_proprietor" && visible && (
          <ProprietorFilterComponent
            filteredData={ownBrandProprietorConfig}
            key={ownBrandProprietorConfig.key}
            value={proprietors}
            setValue={setProprietors}
            loading={ownBrandProprietorConfig.loading}
            journal_number={journal}
            exclude={excludeMarkProprietors}
            setExclude={setExcludeMarkProprietors}
            tableView
            current_template_id={current_template_id}
            filterPath={getPrimaryFilterPath()}
          />
        )}
        {dataIndex === "threat_brand_proprietor" && visible && (
          <ProprietorFilterComponent
            filteredData={threatBrandProprietorConfig}
            key={threatBrandProprietorConfig.key}
            value={threatProprietors}
            setValue={setThreatProprietors}
            loading={threatBrandProprietorConfig.loading}
            journal_number={journal}
            exclude={excludeThreatProprietors}
            setExclude={setExcludeThreatProprietors}
            tableView
            current_template_id={current_template_id}
            filterPath={getPrimaryFilterPath()}
            // rename={urlKey === "journals" ? "Proprietors" : ""}
          />
        )}
        {dataIndex === "image_text_percentage" && visible && (
          <SimilarityFilter
            key={"image_text_percentage"}
            value={imageTextPercentage}
            setValue={setImageTextPercentage}
            tableView
            type={"image_text_percentage"}
            showTitle
          />
        )}
        {dataIndex === "threat_image_text_percentage" && visible && (
          <SimilarityFilter
            key={"threat_image_text_percentage"}
            value={threatImageTextPercentage}
            setValue={setThreatImageTextPercentage}
            tableView
            type={"threat_image_text_percentage"}
            showTitle
          />
        )}
      </>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });
  const dispatch = useDispatch();

  const {
    tableData,
    status,
    count,
    pageNumber,
    journalNumber,
    bookmarkTemplate,
  } =
    useSelector(
      (state) =>
        state.watchState.listLayoutData[dashboardLayout][urlKey].config,
    ) || [];

  const { currentJournalDate } =
    useSelector(
      (state) =>
        state.watchState.watchTemplate[dashboardLayout][urlKey].config
          ?.journalConfig?.currentJournalDate,
    ) || [];

  let filterSelected = props?.isAnyFilterSelected(generateFilterObject());

  const handleNextpage = (pageNumber) => {
    const filters = generateFilterObject();
    dispatch(getListLayoutData(journal, pageNumber, filterSelected, filters));
  };

  const handleEmail = async (data) => {
    let journalNumber = journal;
    let mailContent = "<p><strong>Trademark Watch</strong></p>";
    let applicationNumber = checkValue(data.threat)
      ? data.threat?.application_number
      : "";
    let mark_type = checkValue(data.mark) ? data.mark?.term : "";
    let journalDate = checkValue(publishedDate)
      ? publishedDate
      : currentJournalDate;

    let FilingDate = new Date(journalDate);
    let d = FilingDate.getDate();
    FilingDate.setMonth(FilingDate.getMonth() + 4);
    if (FilingDate.getDate() !== d) {
      FilingDate.setDate(0);
    }

    let tempDate = new Date();
    let currDate =
      tempDate.getFullYear() +
      "-" +
      (tempDate.getMonth() + 1) +
      "-" +
      tempDate.getDate() +
      " " +
      tempDate.getHours() +
      ":" +
      tempDate.getMinutes() +
      ":" +
      tempDate.getSeconds();

    let currSubscription = "DASHBOARD";

    if (urlKey === "image_label") {
      currSubscription = "IMAGE_RECOGNITION";
    } else if (urlKey === "tm_applied") {
      currSubscription = "JOURNAL WATCH";
    } else if (
      urlKey === "image_text" ||
      urlKey === "image_text_new" ||
      urlKey === "image_similarity"
    ) {
      currSubscription = "IMAGE_RECOGNITION";
    } else if (urlKey === "tm_protect") {
      currSubscription = "TM PROTECT";
    }

    setMailObj({
      visible: true,
      mailContent: mailContent,
      product: "WATCH",
      subscription: currSubscription,
      report_tag: {
        report_name: "",
        journal_number: journalNumber,
        journal_date: dateFormat(journalDate, "-"),
        journal_copy: `${ROOT_URL}/watch/api/journal_threat_pdf/?journal_number=${journalNumber}&application_number=${applicationNumber}`,
        opposition: dateFormat(FilingDate, "-"),
        your_mark: checkValue(data.mark) ? data.mark?.applied_for : "",
        your_application: checkValue(data.mark)
          ? data.mark?.application_number
          : "",
        your_proprietor: checkValue(data.mark)
          ? data.mark?.proprietor?.map((propData) => propData.name).join(", ")
          : "",
        your_address: checkValue(data.mark)
          ? data.mark?.proprietor
              ?.map((propData) => propData.address)
              .join(", ")
          : "",
        your_DOA: checkValue(data.mark) ? data.mark?.date_of_application : "",
        your_DOU: checkValue(data.mark) ? data.mark?.date_of_usage : "",
        your_class: checkValue(data.mark)
          ? data.mark?.associated_class?.join(", ")
          : "",
        your_mark_image: checkValue(data.mark)
          ? data.mark?.associated_image
          : "",
        threat_mark: checkValue(data.threat) ? data.threat?.applied_for : "",
        threat_application: checkValue(data.threat)
          ? data.threat?.application_number
          : "",
        threat_proprietor: checkValue(data.threat)
          ? data.threat?.proprietor?.map((propData) => propData.name).join(", ")
          : "",
        threat_address: checkValue(data.threat)
          ? data.threat?.proprietor
              .map((propData) => propData.address)
              .join(", ")
          : "",
        threat_DOA: checkValue(data.threat)
          ? data.threat?.date_of_application
          : "",
        threat_DOU: checkValue(data.threat) ? data.threat?.date_of_usage : "",
        threat_class: checkValue(data.threat)
          ? data.threat?.associated_class?.join(", ")
          : "",
        threat_mark_image: checkValue(data.threat)
          ? data.threat?.associated_image
          : "",
        current_date: checkValue(currDate) ? dateFormat(currDate, "-") : "",
      },
      journal_copy: `${ROOT_URL}/watch/api/journal_threat_pdf/?journal_number=${journalNumber}&application_number=${applicationNumber}`,
      objectType: "trademark",
      applicationNumber:
        urlKey === "tm_protect"
          ? checkValue(data.mark)
            ? data.mark?.term
            : ""
          : checkValue(data.mark)
            ? data.mark?.application_number
            : "",
      mark_type,
    });

    if (urlKey === "tm_applied") {
      await dispatch(
        getEmailTemplateData({
          subscription: "Journal Watch",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
        }),
      );
    } else if (
      urlKey === "image_text" ||
      urlKey === "image_text_new" ||
      urlKey === "image_similarity"
    ) {
      await dispatch(
        getEmailTemplateData({
          subscription: "IMAGE_RECOGNITION",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
          image_recognition_type:
            urlKey === "image_text" || urlKey === "image_text_new"
              ? "IMAGE_TEXT_RECOGNITION_TYPE"
              : "IMAGE_SIMILARITY_RECOGNITION_TYPE",
        }),
      );
    } else if (urlKey === "tm_protect") {
      await dispatch(
        getEmailTemplateData({
          subscription: "TM Protect",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
        }),
      );
    }
  };

  const columnsThreatMarks =
    urlKey === "tm_protect"
      ? [
          ...(urlKey === "tm_protect"
            ? [
                {
                  className: "tm-protect-column-1",
                  title: "Your Mark",
                  width: 120,

                  render: (text, record) => {
                    return {
                      children: (
                        <div>
                          <p>
                            <span style={{ textTransform: "uppercase" }}>
                              {checkValue(record.mark?.term)
                                ? record.mark?.term
                                : "N.A."}
                            </span>
                          </p>
                        </div>
                      ),
                    };
                  },
                },
              ]
            : []),
          {
            className: "tm-protect-column-1",
            title: urlKey === "journals" ? "Mark" : "Threat Mark",

            width: 120,
            render: (text, record) => {
              return {
                children: (
                  <div>
                    <p>
                      <span>
                        {urlKey === "tm_protect" ? (
                          checkValue(record.threat?.application_number) ? (
                            record.threat?.application_number
                          ) : (
                            "N.A."
                          )
                        ) : (
                          <p
                            className="application-text"
                            onClick={() =>
                              setJournalPageVisible(record?.application_number)
                            }
                          >
                            <span>
                              {checkValue(record?.application_number)
                                ? record?.application_number
                                : "N.A."}{" "}
                              -{" "}
                            </span>
                          </p>
                        )}{" "}
                        -{" "}
                      </span>
                      <span>
                        {urlKey === "tm_protect"
                          ? checkValue(record.threat?.applied_for)
                            ? record.threat?.applied_for
                            : "N.A."
                          : checkValue(record?.applied_for)
                            ? record?.applied_for
                            : "N.A."}
                      </span>
                    </p>
                    {urlKey === "tm_protect"
                      ? checkValue(record.threat?.associated_image) &&
                        record.threat?.associated_image !== null &&
                        record.threat?.associated_image !== "N.A." && (
                          <p>
                            <Image
                              width={100}
                              src={
                                checkValue(record.threat?.associated_image)
                                  ? record.threat?.associated_image
                                  : ""
                              }
                            />
                          </p>
                        )
                      : checkValue(record?.associated_image) &&
                        record?.associated_image !== null &&
                        record?.associated_image !== "N.A." && (
                          <p>
                            <Image
                              width={100}
                              src={
                                checkValue(record?.associated_image)
                                  ? record?.associated_image
                                  : ""
                              }
                            />
                          </p>
                        )}
                  </div>
                ),
              };
            },
          },
          {
            className: "tm-protect-column-1",
            title: "Proprietor",
            width: 100,
            ...getColumnFilter("threat_proprietor"),
            render: (text, record) =>
              urlKey === "tm_protect"
                ? record?.threat?.proprietor?.map((element, index, array) => (
                    <p key={element.id}>
                      {checkValue(element)
                        ? `${element.name}${
                            index + 1 === array?.length ? "" : " | "
                          }`
                        : "N.A."}
                    </p>
                  ))
                : record?.proprietor?.map((element, index, array) => (
                    <p key={element.id}>
                      {checkValue(element)
                        ? `${element.name}${
                            index + 1 === array?.length ? "" : " | "
                          }`
                        : "N.A."}
                    </p>
                  )),
          },
          {
            className: "tm-protect-column-1",
            title: "Class",
            ...getColumnFilter("threat_classes"),
            width: 60,

            render: (text, record) => (
              <p>
                {urlKey === "tm_protect"
                  ? checkValue(record?.threat?.associated_class)
                    ? record?.threat?.associated_class?.join(", ")
                    : "N.A."
                  : checkValue(record?.associated_class)
                    ? record?.associated_class?.join(", ")
                    : "N.A."}
              </p>
            ),
          },
          {
            className: "tm-protect-column-1",
            title: "DoA | DoU",
            width: 100,
            ...getColumnFilter("threat_doa"),
            render: (text, record) => (
              <>
                <p>
                  <b>DoA:</b>{" "}
                  {urlKey === "tm_protect"
                    ? checkValue(record?.threat?.date_of_application)
                      ? record?.threat?.date_of_application
                      : "N.A."
                    : checkValue(record?.date_of_application)
                      ? record?.date_of_application
                      : "N.A."}
                </p>
                <p>
                  <b>DoU:</b>{" "}
                  {urlKey === "tm_protect"
                    ? checkValue(record?.threat?.date_of_usage)
                      ? record?.threat?.date_of_usage
                      : "N.A."
                    : checkValue(record?.date_of_usage)
                      ? record?.date_of_usage
                      : "N.A."}
                </p>
              </>
            ),
          },
          {
            className: "tm-protect-column-1",
            title: "Type",
            width: 100,
            render: (text, record) => (
              <>
                <p>
                  {urlKey === "tm_protect"
                    ? checkValue(record?.threat?.type_name)
                      ? record?.threat?.type_name
                      : "N.A."
                    : checkValue(record?.tm_type)
                      ? record?.tm_type
                      : "N.A."}
                </p>
              </>
            ),
          },

          ...(urlKey === "tm_protect"
            ? [
                {
                  className: "tm-protect-column-1",
                  title: "Similarity",
                  ...getColumnFilter("similarity"),

                  children: [
                    {
                      className: "tm-protect-column-1",
                      width: 180,
                      title: (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div>Focus Word</div>
                          </div>
                        </div>
                      ),
                      render: (text, record) => {
                        return {
                          props: {
                            style: {
                              padding: "0px !important",
                              background: "inherit",
                            },
                          },
                          children: (
                            <div
                              style={{
                                display: "flex",
                                padding: "0 !important",
                                flexDirection: "column",
                                background: "white",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  padding: "8px",
                                  justifyContent: "center",
                                }}
                              >
                                {`${record?.similarity?.score}%`}
                              </div>
                              <Divider />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {record?.similarity?.focus_word}
                              </div>
                            </div>
                          ),
                        };
                      },
                    },
                  ],
                },
              ]
            : []),

          {
            className: "tm-protect-column-1",
            title: "Actions",
            width: 80,
            render: (text, record) => {
              return (
                <div
                  className="iconTable tmapplied_ll_actions"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                    padding: "8px",
                  }}
                >
                  {urlKey !== "journals" && (
                    <Tooltip placement="top" title={"Send email"}>
                      <p className="alignC" onClick={() => handleEmail(record)}>
                        <i
                          className="fa fa-paper-plane"
                          style={{
                            padding: "0px 5px",
                            color: "#1890ff",
                            fontSize: 20,
                            cursor: "pointer",
                          }}
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Tooltip>
                  )}

                  {bookmarkTemplate?.bookmarked_id !== record.id ? (
                    <Tooltip placement="top" title={"Bookmark"}>
                      <p className="alignC">
                        <BookOutlined
                          onClick={async () => {
                            await dispatch(
                              patchListBookmark(
                                {
                                  journal_number: journal,
                                  template_json: {
                                    ...generateFilterObject(),
                                    bookmarked_mark:
                                      urlKey === "tm_protect"
                                        ? record.mark?.application_number
                                        : record?.application_number,
                                    bookmarked_threat:
                                      urlKey === "tm_protect"
                                        ? record.threat?.application_number
                                        : record?.application_number,
                                    bookmarked_id: record.id,
                                    page: pageNumber,
                                    disable: false,
                                    filterApplied: props.filterApplied,
                                  },
                                },
                                `${getPrimaryFilterPath()}/`,
                              ),
                            );
                          }}
                          twoToneColor="black"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </p>
                    </Tooltip>
                  ) : bookmarkTemplate?.disable ? (
                    <Tooltip placement="top" title={"Bookmark"}>
                      <p className="alignC">
                        <BookOutlined
                          onClick={async () => {
                            await dispatch(
                              patchListBookmark(
                                {
                                  journal_number: journal,
                                  template_json: {
                                    ...generateFilterObject(),
                                    bookmarked_mark:
                                      urlKey === "tm_protect"
                                        ? record.mark?.application_number
                                        : record?.application_number,
                                    bookmarked_threat:
                                      urlKey === "tm_protect"
                                        ? record.threat?.application_number
                                        : record?.application_number,
                                    bookmarked_id: record.id,
                                    page: pageNumber,
                                    disable: false,
                                  },
                                },
                                `${getPrimaryFilterPath()}/`,
                              ),
                            );
                          }}
                          twoToneColor="black"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </p>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title={"Bookmark"}>
                      <p className="alignC">
                        <BookTwoTone
                          id="Bookmarked"
                          onClick={async () => {
                            await dispatch(
                              patchListBookmark(
                                {
                                  journal_number: journal,
                                  template_json: {
                                    ...generateFilterObject(),
                                    bookmarked_mark:
                                      urlKey === "tm_protect"
                                        ? record.mark?.application_number
                                        : record?.application_number,
                                    bookmarked_threat:
                                      urlKey === "tm_protect"
                                        ? record.threat?.application_number
                                        : record?.application_number,
                                    bookmarked_id: record.id,
                                    page: pageNumber,
                                    disable: true,
                                  },
                                },
                                `${getPrimaryFilterPath()}/`,
                              ),
                            );
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                        />
                      </p>
                    </Tooltip>
                  )}
                </div>
              );
            },
          },
        ]
      : urlKey === "journals"
        ? [
            {
              className: "tm-protect-column-1",
              title: "Mark",
              width: 120,
              render: (text, record) => {
                return {
                  children: (
                    <div>
                      <p>
                        {checkValue(record?.associated_image) &&
                          record?.associated_image !== null &&
                          record?.associated_image !== "N.A." && (
                            <p>
                              <Image
                                width={100}
                                src={
                                  checkValue(record?.associated_image)
                                    ? record?.associated_image
                                    : ""
                                }
                              />
                            </p>
                          )}
                        <span>
                          {checkValue(record?.applied_for) ? (
                            <b>{record?.applied_for}</b>
                          ) : (
                            "N.A."
                          )}
                        </span>
                        <span>
                          <p
                            className="application-text"
                            onClick={() =>
                              setJournalPageVisible(record?.application_number)
                            }
                          >
                            <span>
                              {checkValue(record?.application_number)
                                ? record?.application_number
                                : "N.A."}
                            </span>
                          </p>
                        </span>
                      </p>
                    </div>
                  ),
                };
              },
            },
            {
              className: "tm-protect-column-1",
              title: "Type",
              width: 100,
              ...getColumnFilter("threat_types"),
              render: (text, record) => (
                <>
                  <p>
                    {checkValue(record?.tm_type) ? (
                      <b>{record?.tm_type}</b>
                    ) : (
                      "N.A."
                    )}
                  </p>
                </>
              ),
            },
            {
              className: "tm-protect-column-1",
              title: "Proprietors",
              width: 100,
              ...getColumnFilter("threat_proprietor"),
              render: (text, record) =>
                record?.proprietor?.map((element, index, array) => (
                  <p key={element.id}>
                    {checkValue(element)
                      ? `${element.name}${
                          index + 1 === array?.length ? "" : " | "
                        }`
                      : "N.A."}
                  </p>
                )),
            },
            {
              className: "tm-protect-column-1",
              title: "Classes",
              ...getColumnFilter("threat_classes"),
              width: 60,

              render: (text, record) => (
                <p>
                  {checkValue(record?.associated_class)
                    ? record?.associated_class?.join(", ")
                    : "N.A."}
                </p>
              ),
            },
            {
              className: "tm-protect-column-1",
              title: "DoA | DoU",
              width: 100,
              ...getColumnFilter("threat_doa"),
              render: (text, record) => (
                <>
                  <p>
                    <b>DoA:</b>{" "}
                    {checkValue(record?.date_of_application)
                      ? record?.date_of_application
                      : "N.A."}
                  </p>
                  <p>
                    <b>DoU:</b>{" "}
                    {checkValue(record?.date_of_usage)
                      ? record?.date_of_usage
                      : "N.A."}
                  </p>
                </>
              ),
            },

            {
              className: "tm-protect-column-1",
              title: "Actions",
              width: 80,
              render: (text, record) => {
                return (
                  <div
                    className="iconTable tmapplied_ll_actions"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      padding: "8px",
                    }}
                  >
                    {bookmarkTemplate?.bookmarked_id !== record.id ? (
                      <Tooltip placement="top" title={"Bookmark"}>
                        <p className="alignC">
                          <BookOutlined
                            onClick={async () => {
                              await dispatch(
                                patchListBookmark(
                                  {
                                    journal_number: journal,
                                    template_json: {
                                      ...generateFilterObject(),
                                      bookmarked_mark:
                                        urlKey === "tm_protect"
                                          ? record.mark?.application_number
                                          : record?.application_number,
                                      bookmarked_threat:
                                        urlKey === "tm_protect"
                                          ? record.threat?.application_number
                                          : record?.application_number,
                                      bookmarked_id: record.id,
                                      page: pageNumber,
                                      disable: false,
                                      filterApplied: props.filterApplied,
                                    },
                                  },
                                  `${getPrimaryFilterPath()}/`,
                                ),
                              );
                            }}
                            twoToneColor="black"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        </p>
                      </Tooltip>
                    ) : bookmarkTemplate?.disable ? (
                      <Tooltip placement="top" title={"Bookmark"}>
                        <p className="alignC">
                          <BookOutlined
                            onClick={async () => {
                              await dispatch(
                                patchListBookmark(
                                  {
                                    journal_number: journal,
                                    template_json: {
                                      ...generateFilterObject(),
                                      bookmarked_mark:
                                        urlKey === "tm_protect"
                                          ? record.mark?.application_number
                                          : record?.application_number,
                                      bookmarked_threat:
                                        urlKey === "tm_protect"
                                          ? record.threat?.application_number
                                          : record?.application_number,
                                      bookmarked_id: record.id,
                                      page: pageNumber,
                                      disable: false,
                                    },
                                  },
                                  `${getPrimaryFilterPath()}/`,
                                ),
                              );
                            }}
                            twoToneColor="black"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        </p>
                      </Tooltip>
                    ) : (
                      <Tooltip placement="top" title={"Bookmark"}>
                        <p className="alignC">
                          <BookTwoTone
                            id="Bookmarked"
                            onClick={async () => {
                              await dispatch(
                                patchListBookmark(
                                  {
                                    journal_number: journal,
                                    template_json: {
                                      ...generateFilterObject(),
                                      bookmarked_mark:
                                        urlKey === "tm_protect"
                                          ? record.mark?.application_number
                                          : record?.application_number,
                                      bookmarked_threat:
                                        urlKey === "tm_protect"
                                          ? record.threat?.application_number
                                          : record?.application_number,
                                      bookmarked_id: record.id,
                                      page: pageNumber,
                                      disable: true,
                                    },
                                  },
                                  `${getPrimaryFilterPath()}/`,
                                ),
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                          />
                        </p>
                      </Tooltip>
                    )}
                  </div>
                );
              },
            },
          ]
        : urlKey === "brand_compare"
          ? [
              {
                className: "tm-applied-column-1",
                title: "Your Brand",
                children: [
                  {
                    className: "tm-applied-column-1",
                    title: "DoA | DoU",
                    width: 100,
                    ...getColumnFilter("mark_doa"),
                    render: (text, record) => (
                      <>
                        <p>
                          <b>DoA:</b>{" "}
                          {checkValue(
                            record?.own_brand_detail?.date_of_application,
                          )
                            ? record?.own_brand_detail?.date_of_application
                            : "N.A."}
                        </p>
                        <p>
                          <b>DoU:</b>{" "}
                          {checkValue(record?.own_brand_detail?.date_of_usage)
                            ? record?.own_brand_detail?.date_of_usage
                            : "N.A."}
                        </p>
                      </>
                    ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Class",
                    ...getColumnFilter("own_brand_classes"),
                    width: 60,

                    render: (text, record) => (
                      <p>
                        {checkValue(record)
                          ? record?.own_brand_detail?.associated_class?.join(
                              ", ",
                            )
                          : "N.A."}
                      </p>
                    ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Proprietor",
                    width: 100,
                    ...getColumnFilter("own_brand_proprietor"),
                    render: (text, record) =>
                      record?.own_brand_detail?.proprietor?.map(
                        (element, index, array) => (
                          <p key={element.id}>
                            {checkValue(element)
                              ? `${element.name}${
                                  index + 1 === array?.length ? "" : " | "
                                }`
                              : "N.A."}
                          </p>
                        ),
                      ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Application No.",
                    width: 100,
                    render: (text, record) => {
                      return {
                        children: (
                          <div>
                            <p>
                              <span>
                                {checkValue(
                                  record.own_brand_detail?.application_number,
                                )
                                  ? record.own_brand_detail?.application_number
                                  : "N.A."}{" "}
                              </span>
                            </p>
                          </div>
                        ),
                      };
                    },
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Brand",
                    width: 120,

                    render: (text, record) => {
                      return {
                        props: {
                          style: { borderRight: "2px solid #C4C4C4" },
                        },
                        children: (
                          <div>
                            <p>
                              <span>
                                {checkValue(record.own_brand_detail?.brand_name)
                                  ? record.own_brand_detail?.brand_name
                                  : "N.A."}
                              </span>
                            </p>
                            {checkValue(
                              record.own_brand_detail?.associated_image,
                            ) &&
                              record.own_brand_detail?.associated_image !==
                                null &&
                              record.own_brand_detail?.associated_image !==
                                "N.A." && (
                                <p>
                                  <Image
                                    width={100}
                                    src={
                                      checkValue(
                                        record.own_brand_detail
                                          ?.associated_image,
                                      )
                                        ? record.own_brand_detail
                                            ?.associated_image
                                        : ""
                                    }
                                  />
                                </p>
                              )}
                          </div>
                        ),
                      };
                    },
                  },
                ],
              },
              {
                className: "tm-applied-column-1",
                title: "Similarity",
                ...getColumnFilter("similarity"),

                children: [
                  {
                    className: "tm-applied-column-1",
                    width: 180,
                    title: (
                      <div>
                        <div style={{ display: "flex", width: "100%" }}>
                          <div
                            style={{
                              padding: "8px",
                              borderRight: "1px solid #bcbcbc",
                              background: "#ebf4ff",
                              width: "50%",
                            }}
                          >
                            Your Focus Word
                          </div>
                          <div
                            style={{
                              padding: "8px",
                              background: "#fde5e6",
                              width: "50%",
                            }}
                          >
                            Threat Focus Word
                          </div>
                        </div>
                      </div>
                    ),
                    render: (text, record) => {
                      return {
                        props: {
                          style: {
                            padding: "0px !important",
                            background: "inherit",
                          },
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              padding: "0 !important",
                              flexDirection: "column",
                              background: "white",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                padding: "8px",
                                justifyContent: "center",
                              }}
                            >
                              {`${record?.similarity?.score}%`}
                              {record.own_brand_detail?.associated_class?.filter(
                                (element) =>
                                  record.threat_brand_detail?.associated_class?.includes(
                                    element,
                                  ),
                              ).length > 0 && (
                                <div
                                  style={{
                                    padding: "0px 4px 4px 4px",
                                    marginLeft: "8px",
                                    color: "white",
                                    background: "red",
                                    borderRadius: "4px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <Tooltip title={"Same Class"}>
                                    <FlagFilled />
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                borderTop: "1px solid #bcbcbc",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  padding: "4px",
                                  wordBreak: "break-all",
                                  borderRight: "1px solid #bcbcbc",
                                  width: "50%",
                                  justifyContent: "center",
                                }}
                              >
                                {record?.own_brand_detail?.brand_name}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  padding: "4px",
                                  wordBreak: "break-all",
                                  width: "50%",
                                  justifyContent: "center",
                                }}
                              >
                                {record?.similarity?.focus_word}
                              </div>
                            </div>
                          </div>
                        ),
                      };
                    },
                  },
                ],
              },
              {
                className: "tm-applied-column-1",
                title: "Threat Brand",
                children: [
                  {
                    className: "tm-applied-column-1",
                    title: "Brand",

                    width: 120,
                    render: (text, record) => {
                      return {
                        props: {
                          style: { borderLeft: "2px solid #C4C4C4" },
                        },
                        children: (
                          <div>
                            <p>
                              <span>
                                {checkValue(
                                  record.threat_brand_detail?.brand_name,
                                )
                                  ? record.threat_brand_detail?.brand_name
                                  : "N.A."}
                              </span>
                            </p>
                            {checkValue(
                              record.threat_brand_detail?.associated_image,
                            ) &&
                              record.threat_brand_detail?.associated_image !==
                                null &&
                              record.threat_brand_detail?.associated_image !==
                                "N.A." && (
                                <p>
                                  <Image
                                    width={100}
                                    src={
                                      checkValue(
                                        record.threat_brand_detail
                                          ?.associated_image,
                                      )
                                        ? record.threat_brand_detail
                                            ?.associated_image
                                        : ""
                                    }
                                  />
                                </p>
                              )}
                          </div>
                        ),
                      };
                    },
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Application No.",
                    width: 100,
                    render: (text, record) => {
                      return {
                        children: (
                          <div>
                            <p>
                              <span>
                                {checkValue(
                                  record.threat_brand_detail
                                    ?.application_number,
                                )
                                  ? record.threat_brand_detail
                                      ?.application_number
                                  : "N.A."}{" "}
                              </span>
                            </p>
                          </div>
                        ),
                      };
                    },
                  },
                  {
                    className: "tm-applied-column-1",
                    title: <div>Proprietor</div>,
                    width: 100,
                    ...getColumnFilter("threat_brand_proprietor"),
                    render: (text, record) =>
                      record?.threat_brand_detail?.proprietor?.map(
                        (element, index, array) => (
                          <p key={element.id}>
                            {checkValue(element)
                              ? `${element.name}${
                                  index + 1 === array?.length ? "" : " | "
                                }`
                              : "N.A."}
                          </p>
                        ),
                      ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Class",
                    width: 80,
                    ...getColumnFilter("threat_brand_classes"),
                    render: (text, record) => (
                      <p>
                        {checkValue(record)
                          ? record?.threat_brand_detail?.associated_class?.join(
                              ", ",
                            )
                          : "N.A."}
                      </p>
                    ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "DoA | DoU",
                    ...getColumnFilter("threat_doa"),
                    width: 100,
                    render: (text, record) => (
                      <>
                        <p>
                          <b>DoA:</b>{" "}
                          {checkValue(
                            record?.threat_brand_detail?.date_of_application,
                          )
                            ? record?.threat_brand_detail?.date_of_application
                            : "N.A."}
                        </p>
                        <p>
                          <b>DoU:</b>{" "}
                          {checkValue(
                            record?.threat_brand_detail?.date_of_usage,
                          )
                            ? record?.threat_brand_detail?.date_of_usage
                            : "N.A."}
                        </p>
                      </>
                    ),
                  },
                ],
              },
              {
                className: "tm-applied-column-1",
                title: "",
                children: [
                  {
                    className: "tm-applied-column-1",
                    title: "Actions",
                    width: 80,
                    render: (text, record) => {
                      return (
                        <div
                          className="iconTable tmapplied_ll_actions"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "column",
                            padding: "8px",
                          }}
                        >
                          {/* <Tooltip placement="top" title={"Send email"}>
                        <p
                          className="alignC"
                          onClick={() => handleEmail(record)}
                        >
                          <i
                            className="fa fa-paper-plane"
                            style={{
                              padding: "0px 5px",
                              color: "#1890ff",
                              fontSize: 20,
                              cursor: "pointer",
                            }}
                            aria-hidden="true"
                          ></i>
                        </p>
                      </Tooltip> */}

                          {bookmarkTemplate?.bookmarked_id !== record.id ? (
                            <Tooltip placement="top" title={"Bookmark"}>
                              <p className="alignC">
                                <BookOutlined
                                  onClick={async () => {
                                    await dispatch(
                                      patchListBookmark(
                                        {
                                          journal_number: journal,
                                          template_json: {
                                            ...generateFilterObject(),
                                            bookmarked_mark:
                                              record.own_brand_detail
                                                ?.application_number,
                                            bookmarked_threat:
                                              record.threat_brand_detail
                                                ?.application_number,
                                            bookmarked_id: record.id,
                                            page: pageNumber,
                                            disable: false,
                                            filterApplied: props.filterApplied,
                                          },
                                        },
                                        `${getPrimaryFilterPath()}/`,
                                      ),
                                    );
                                  }}
                                  twoToneColor="black"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              </p>
                            </Tooltip>
                          ) : bookmarkTemplate?.disable ? (
                            <Tooltip placement="top" title={"Bookmark"}>
                              <p className="alignC">
                                <BookOutlined
                                  onClick={async () => {
                                    await dispatch(
                                      patchListBookmark(
                                        {
                                          journal_number: journal,
                                          template_json: {
                                            ...generateFilterObject(),
                                            bookmarked_mark:
                                              record.own_brand_detail
                                                ?.application_number,
                                            bookmarked_threat:
                                              record.threat_brand_detail
                                                ?.application_number,
                                            bookmarked_id: record.id,
                                            page: pageNumber,
                                            disable: false,
                                          },
                                        },
                                        `${getPrimaryFilterPath()}/`,
                                      ),
                                    );
                                  }}
                                  twoToneColor="black"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              </p>
                            </Tooltip>
                          ) : (
                            <Tooltip placement="top" title={"Bookmark"}>
                              <p className="alignC">
                                <BookTwoTone
                                  id="Bookmarked"
                                  onClick={async () => {
                                    await dispatch(
                                      patchListBookmark(
                                        {
                                          journal_number: journal,
                                          template_json: {
                                            ...generateFilterObject(),
                                            bookmarked_mark:
                                              record.own_brand_detail
                                                ?.application_number,
                                            bookmarked_threat:
                                              record.threat_brand_detail
                                                ?.application_number,
                                            bookmarked_id: record.id,
                                            page: pageNumber,
                                            disable: true,
                                          },
                                        },
                                        `${getPrimaryFilterPath()}/`,
                                      ),
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              </p>
                            </Tooltip>
                          )}
                        </div>
                      );
                    },
                  },
                ],
              },
            ]
          : [
              {
                className: "tm-applied-column-1",
                title: "Your Marks",
                children: [
                  {
                    className: "tm-applied-column-1",
                    title: "Tags",
                    width: 100,
                    ...getColumnFilter("tags"),
                    render: (text, record) => (
                      <>
                        <Space
                          size={[0, 8]}
                          wrap
                          style={{ overflow: "auto", maxHeight: "120px" }}
                        >
                          {record?.mark?.tags?.slice(0, 3)?.map((tag) => (
                            <Tag
                              bordered={false}
                              style={{
                                wordBreak: "break-all",
                                textWrap: "balance",
                                maxWidth: "80px",
                                background: tag?.color_code + "2e",
                                color: tag?.color_code,
                              }}
                            >
                              {tag?.name}
                            </Tag>
                          ))}
                        </Space>
                        {record?.mark?.tags?.length > 3 && (
                          <Tooltip
                            overlayInnerStyle={{
                              background: "white",
                              padding: "4px",
                            }}
                            title={
                              <Space
                                size={[0, 8]}
                                wrap
                                style={{
                                  padding: "8px",
                                  background: "white",
                                  overflow: "auto",
                                  maxHeight: "240px",
                                }}
                              >
                                {record?.mark?.tags.map((tag) => (
                                  <Tag
                                    style={{
                                      background: tag?.color_code + "2e",
                                      color: tag?.color_code,
                                    }}
                                  >
                                    {tag?.name}
                                  </Tag>
                                ))}
                              </Space>
                            }
                          >
                            <Tag
                              style={{ margin: "8px" }}
                              bordered={false}
                            >{`+ ${record?.mark?.tags?.length - 3} More`}</Tag>
                          </Tooltip>
                        )}
                      </>
                    ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "DoA | DoU",
                    width: 100,
                    ...getColumnFilter("mark_doa"),
                    render: (text, record) => (
                      <>
                        <p>
                          <b>DoA:</b>{" "}
                          {checkValue(record?.mark?.date_of_application)
                            ? record?.mark?.date_of_application
                            : "N.A."}
                        </p>
                        <p>
                          <b>DoU:</b>{" "}
                          {checkValue(record?.mark?.date_of_usage)
                            ? record?.mark?.date_of_usage
                            : "N.A."}
                        </p>
                      </>
                    ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Class",
                    ...getColumnFilter("mark_classes"),
                    width: 60,

                    render: (text, record) => (
                      <p>
                        {checkValue(record)
                          ? record?.mark?.associated_class?.join(", ")
                          : "N.A."}
                      </p>
                    ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Proprietor",
                    width: 100,
                    ...getColumnFilter("mark_proprietor"),
                    render: (text, record) =>
                      record?.mark?.proprietor?.map((element, index, array) => (
                        <p key={element.id}>
                          {checkValue(element)
                            ? `${element.name}${
                                index + 1 === array?.length ? "" : " | "
                              }`
                            : "N.A."}
                        </p>
                      )),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Mark",
                    width: 120,
                    ...(urlKey === "image_similarity"
                      ? getColumnFilter("image_text_percentage")
                      : {}),
                    render: (text, record) => {
                      return {
                        props: {
                          style: { borderRight: "2px solid #C4C4C4" },
                        },
                        children: (
                          <div>
                            <p>
                              <span>
                                {checkValue(record.mark?.application_number)
                                  ? record.mark?.application_number
                                  : "N.A."}{" "}
                                -{" "}
                              </span>
                              <span>
                                {checkValue(record.mark?.applied_for)
                                  ? record.mark?.applied_for
                                  : "N.A."}
                              </span>
                            </p>
                            {checkValue(record.mark?.associated_image) &&
                              record.mark?.associated_image !== null &&
                              record.mark?.associated_image !== "N.A." && (
                                <p>
                                  <Image
                                    width={100}
                                    src={
                                      checkValue(record.mark?.associated_image)
                                        ? record.mark?.associated_image
                                        : ""
                                    }
                                  />
                                </p>
                              )}
                          </div>
                        ),
                      };
                    },
                  },
                ],
              },
              {
                className: "tm-applied-column-1",
                title: "Similarity",
                ...getColumnFilter("similarity"),

                children: [
                  {
                    className: "tm-applied-column-1",
                    width: 180,
                    title: (
                      <div>
                        {(urlKey === "image_text" ||
                          urlKey === "image_text_new") && (
                          <div
                            style={{
                              width: "100%",
                              padding: "4px",
                              borderBottom: "1px solid #bcbcbc",
                            }}
                          >
                            Focus Text
                          </div>
                        )}
                        {urlKey === "image_similarity" ? null : (
                          <div style={{ display: "flex", width: "100%" }}>
                            <div
                              style={{
                                padding: "8px",
                                borderRight: "1px solid #bcbcbc",
                                background: "#ebf4ff",
                                width: "50%",
                              }}
                            >
                              {urlKey === "image_text" ||
                              urlKey === "image_text_new"
                                ? "Your Image Text"
                                : "Your Focus word"}
                            </div>
                            <div
                              style={{
                                padding: "8px",
                                background: "#fde5e6",
                                width: "50%",
                              }}
                            >
                              {urlKey === "image_text" ||
                              urlKey === "image_text_new"
                                ? "Threat Image Text"
                                : "Threat Focus word"}
                            </div>
                          </div>
                        )}
                      </div>
                    ),
                    render: (text, record) => {
                      return {
                        props: {
                          style: {
                            padding: "0px !important",
                            background: "inherit",
                          },
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              padding: "0 !important",
                              flexDirection: "column",
                              background: "white",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                padding: "8px",
                                justifyContent: "center",
                              }}
                            >
                              {`${record?.similarity?.score}%`}
                              {record.mark?.associated_class?.filter(
                                (element) =>
                                  record.threat?.associated_class?.includes(
                                    element,
                                  ),
                              ).length > 0 && (
                                <div
                                  style={{
                                    padding: "0px 4px 4px 4px",
                                    marginLeft: "8px",
                                    color: "white",
                                    background: "red",
                                    borderRadius: "4px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <Tooltip title={"Same Class"}>
                                    <FlagFilled />
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                            {(urlKey === "image_text" ||
                              urlKey === "image_text_new") && (
                              <div
                                style={{
                                  width: "100%",
                                  padding: "4px",
                                  borderTop: "1px solid #bcbcbc",
                                  textAlign: "center",
                                }}
                              >
                                {record?.similarity?.focus_word}
                                {(record?.similarity?.comparison ===
                                  "IMAGE_FW_TO_APPLIED_FOR_FW" ||
                                  record?.similarity?.comparison ===
                                    "IMAGE_FW_TO_IMAGE_FW") &&
                                  record?.similarity?.is_custom && (
                                    <>
                                      {" "}
                                      <Tooltip title="Focus word uploaded by user.">
                                        <span
                                          style={{
                                            background: "#1677ff",
                                            color: "white",
                                            padding: "0px 2px 2px 2px",
                                            borderRadius: "50%",
                                          }}
                                        >
                                          <UserOutlined color="primary" />
                                        </span>
                                      </Tooltip>
                                    </>
                                  )}
                              </div>
                            )}
                            {urlKey === "image_similarity" ? null : (
                              <div
                                style={{
                                  display: "flex",
                                  borderTop: "1px solid #bcbcbc",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    padding: "4px",
                                    wordBreak: "break-all",
                                    borderRight: "1px solid #bcbcbc",
                                    width: "50%",
                                    justifyContent: "center",
                                  }}
                                >
                                  {urlKey === "image_text" ||
                                  urlKey === "image_text_new"
                                    ? record?.similarity?.image_text
                                    : record?.similarity?.focus_word}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    padding: "4px",
                                    wordBreak: "break-all",
                                    width: "50%",
                                    justifyContent: "center",
                                  }}
                                >
                                  {urlKey === "image_text" ||
                                  urlKey === "image_text_new"
                                    ? record?.similarity?.threat_image_text
                                    : record?.similarity?.threat_focus_word}
                                </div>
                              </div>
                            )}
                          </div>
                        ),
                      };
                    },
                  },
                ],
              },
              {
                className: "tm-applied-column-1",
                title: "Threat Marks",
                children: [
                  {
                    className: "tm-applied-column-1",
                    title: "Mark",
                    ...(urlKey === "image_similarity"
                      ? getColumnFilter("threat_image_text_percentage")
                      : {}),
                    width: 120,
                    render: (text, record) => {
                      return {
                        props: {
                          style: { borderLeft: "2px solid #C4C4C4" },
                        },
                        children: (
                          <div>
                            <p
                              className="application-text"
                              onClick={() =>
                                setJournalPageVisible(
                                  record.threat?.application_number,
                                )
                              }
                            >
                              <span>
                                {checkValue(record.threat?.application_number)
                                  ? record.threat?.application_number
                                  : "N.A."}{" "}
                                -{" "}
                              </span>
                              <span>
                                {checkValue(record.threat?.applied_for)
                                  ? record.threat?.applied_for
                                  : "N.A."}
                              </span>
                            </p>
                            {checkValue(record.threat?.associated_image) &&
                              record.threat?.associated_image !== null &&
                              record.threat?.associated_image !== "N.A." && (
                                <p>
                                  <Image
                                    width={100}
                                    src={
                                      checkValue(
                                        record.threat?.associated_image,
                                      )
                                        ? record.threat?.associated_image
                                        : ""
                                    }
                                  />
                                </p>
                              )}
                          </div>
                        ),
                      };
                    },
                  },
                  {
                    className: "tm-applied-column-1",
                    title: <div>Proprietor</div>,
                    width: 100,
                    ...getColumnFilter("threat_proprietor"),
                    render: (text, record) =>
                      record?.threat?.proprietor?.map(
                        (element, index, array) => (
                          <p key={element.id}>
                            {checkValue(element)
                              ? `${element.name}${
                                  index + 1 === array?.length ? "" : " | "
                                }`
                              : "N.A."}
                          </p>
                        ),
                      ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "Class",
                    width: 80,
                    ...getColumnFilter("threat_classes"),
                    render: (text, record) => (
                      <p>
                        {checkValue(record)
                          ? record?.threat?.associated_class?.join(", ")
                          : "N.A."}
                      </p>
                    ),
                  },
                  {
                    className: "tm-applied-column-1",
                    title: "DoA | DoU",
                    ...getColumnFilter("threat_doa"),
                    width: 100,

                    render: (text, record) => (
                      <>
                        <p>
                          <b>DoA:</b>{" "}
                          {checkValue(record?.threat?.date_of_application)
                            ? record?.threat?.date_of_application
                            : "N.A."}
                        </p>
                        <p>
                          <b>DoU:</b>{" "}
                          {checkValue(record?.threat?.date_of_usage)
                            ? record?.threat?.date_of_usage
                            : "N.A."}
                        </p>
                      </>
                    ),
                  },
                ],
              },
              {
                className: "tm-applied-column-1",
                title: "",

                children: [
                  {
                    className: "tm-applied-column-1",
                    title: "Actions",
                    width: 80,
                    render: (text, record) => {
                      return (
                        <div
                          className="iconTable tmapplied_ll_actions"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "column",
                            padding: "8px",
                          }}
                        >
                          <Tooltip placement="top" title={"Send email"}>
                            <p
                              className="alignC"
                              onClick={() => handleEmail(record)}
                            >
                              <i
                                className="fa fa-paper-plane"
                                style={{
                                  padding: "0px 5px",
                                  color: "#1890ff",
                                  fontSize: 20,
                                  cursor: "pointer",
                                }}
                                aria-hidden="true"
                              ></i>
                            </p>
                          </Tooltip>

                          {bookmarkTemplate?.bookmarked_id !== record.id ? (
                            <Tooltip placement="top" title={"Bookmark"}>
                              <p className="alignC">
                                <BookOutlined
                                  onClick={async () => {
                                    await dispatch(
                                      patchListBookmark(
                                        {
                                          journal_number: journal,
                                          template_json: {
                                            ...generateFilterObject(),
                                            bookmarked_mark:
                                              record.mark?.application_number,
                                            bookmarked_threat:
                                              record.threat?.application_number,
                                            bookmarked_id: record.id,
                                            page: pageNumber,
                                            disable: false,
                                            filterApplied: props.filterApplied,
                                          },
                                        },
                                        `${getPrimaryFilterPath()}/`,
                                      ),
                                    );
                                  }}
                                  twoToneColor="black"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              </p>
                            </Tooltip>
                          ) : bookmarkTemplate?.disable ? (
                            <Tooltip placement="top" title={"Bookmark"}>
                              <p className="alignC">
                                <BookOutlined
                                  onClick={async () => {
                                    await dispatch(
                                      patchListBookmark(
                                        {
                                          journal_number: journal,
                                          template_json: {
                                            ...generateFilterObject(),
                                            bookmarked_mark:
                                              record.mark?.application_number,
                                            bookmarked_threat:
                                              record.threat?.application_number,
                                            bookmarked_id: record.id,
                                            page: pageNumber,
                                            disable: false,
                                          },
                                        },
                                        `${getPrimaryFilterPath()}/`,
                                      ),
                                    );
                                  }}
                                  twoToneColor="black"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              </p>
                            </Tooltip>
                          ) : (
                            <Tooltip placement="top" title={"Bookmark"}>
                              <p className="alignC">
                                <BookTwoTone
                                  id="Bookmarked"
                                  onClick={async () => {
                                    await dispatch(
                                      patchListBookmark(
                                        {
                                          journal_number: journal,
                                          template_json: {
                                            ...generateFilterObject(),
                                            bookmarked_mark:
                                              record.mark?.application_number,
                                            bookmarked_threat:
                                              record.threat?.application_number,
                                            bookmarked_id: record.id,
                                            page: pageNumber,
                                            disable: true,
                                          },
                                        },
                                        `${getPrimaryFilterPath()}/`,
                                      ),
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              </p>
                            </Tooltip>
                          )}
                        </div>
                      );
                    },
                  },
                ],
              },
            ];

  const onSelectChange = (record, selected) => {
    if (selected) {
      let newSelected = [...selectedRows, record];
      setSelected(newSelected.map((x) => x.id));
      setSelectedRowKeys(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    } else {
      let newSelected = selectedRows.filter((x) => x.id !== record.id);

      setSelected(newSelected.map((x) => x.id));
      setSelectedRowKeys(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    }
  };
  const rowSelection = {
    renderCell: function (checked, record, index, originNode) {
      return (
        <div
          className="tmapplied_selection_cols"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "4px" }}>
              {" "}
              {index + 1 + (pageNumber - 1) * 100}.{" "}
            </div>
            <div> {originNode}</div>
          </div>
          {(record?.created_custom_report || showSelectIcons) && (
            <Tooltip title="Custom Report Created">
              <EyeFilled
                style={{
                  margin: "8px 8px 8px 28px",
                  color: "#1890FF",
                  fontSize: "18px",
                }}
              />
            </Tooltip>
          )}
          {(record?.is_shortlist || showSelectIcons) && (
            <Tooltip title="Shortlisted">
              <StarFilled
                style={{
                  margin: "8px 8px 8px 28px",
                  color: "#FFA701",
                  fontSize: "18px",
                }}
              />
            </Tooltip>
          )}
        </div>
      );
    },
    selectedRowKeys: selected,
    onSelect: onSelectChange,
    selections: null,
    onSelectAll: (selected, selectedRows) => {
      setSelectAll(selected);
      setSelected(selectedRows);
      if (selected) {
        notification.open({
          message: "Deselect All Mark",
          description:
            "Click on the arrow near the select all checkbox to deselect all marks",
        });
      }
    },
    selections: selectAll
      ? [
          {
            key: "all-data",
            text: "Deselect All Data",
            onSelect: (changeableRowKeys) => {
              setSelectAll(false);
              setSelected([]);
              setSelectedRowKeys([]);
              setSelectedRows([]);
            },
          },
        ]
      : false,
    getCheckboxProps: (record) => ({
      disabled: selectAll,
    }),
  };

  const table = (
    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ padding: "16px" }}>
        <Table
          rowKey={(record) => record.id}
          expandedRowKeys={rowExpanded ? tableData?.map((x) => x.id) : []}
          columns={columnsThreatMarks}
          className={
            urlKey === "tm_protect" || urlKey === "journals"
              ? "tm-protect-table"
              : urlKey === "brand_compare"
                ? "brand-compare-table"
                : "tm-applied-table"
          }
          bordered
          expandable={{
            expandedRowRender: (record) => {
              return urlKey !== "tm_protect" ? (
                urlKey === "journals" ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "90%" }}>{record?.description}</div>
                  </div>
                ) : (
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div style={{ width: "30%" }}>
                      {record?.mark?.description}
                    </div>
                    <div style={{ width: "30%" }}>
                      {record?.threat?.description}
                    </div>
                  </div>
                )
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ width: "90%" }}>
                    {record?.threat?.description}
                  </div>
                </div>
              );
            },
            rowExpandable: (record) => true,
            showExpandColumn: false,
            expandIcon: undefined,
            columnTitle: "G&S Description",
          }}
          dataSource={tableData}
          scroll={
            watchAlert === ""
              ? { y: "calc(80vh - 150px)" }
              : {
                  y:
                    urlKey === "journals"
                      ? "calc(80vh - 130px)"
                      : urlKey === "brand_compare"
                        ? "calc(80vh - 210px)"
                        : "calc(80vh - 180px)",
                }
          }
          loading={status === LOADING ? true : false}
          rowSelection={rowSelection}
          pagination={
            urlKey === "journals"
              ? false
              : {
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  current: pageNumber,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} `,
                  total: count,
                  onChange: handleNextpage,
                }
          }
        />
      </Col>
    </Row>
  );

  const getDescription = () => {
    if (urlKey === "brand_compare") {
      return (
        <div>
          <h2>Analysis is in progress</h2>
          <h4>You will recieve an email once analysis is done.</h4>
        </div>
      );
    } else {
      return (
        <div>
          <h2>Journal is in progress</h2>
          <h4>
            Your infringement will be available once this process is complete.
          </h4>
        </div>
      );
    }
  };

  if (!isJournalProcessed) {
    return (
      <div
        style={{
          display: "table",
          paddingTop: "10%",
          width: "100%",
        }}
      >
        <Empty
          image={progressImage}
          description={getDescription()}
          style={{ display: "table-cell", verticalAlign: "middle" }}
        />
      </div>
    );
  }
  return (
    <>
      {table}
      {journalPageVisible !== null && (
        <TrademarkJournalPage
          journalNumber={journalNumber[urlKey]}
          applicationNumber={journalPageVisible}
          closeDrawer={() => setJournalPageVisible(null)}
        />
      )}
    </>
  );
};

export default ListContent;
