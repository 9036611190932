//--------LIBRARIES--------
import React, { Component } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col, Table, Button, Card, Popconfirm } from "antd";

import { LOADING } from "../../../../constants/commonConstants/generalConstants";

class NotesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const notesColumns = [
      {
        title: "NOTE",
        dataIndex: "note",
        width: "60%",
        render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
      },

      {
        title: "MODIFIED",
        dataIndex: "modified",
        width: "15%",
      },

      {
        title: "CREATED",
        dataIndex: "created",
        width: "15%",
      },

      {
        title: "ACTION",
        width: "10%",
        align: "center",
        render: (record) => {
          return record ? (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                style={{ margin: "0px 5px" }}
                onClick={() => this.props.handleEditNotes(record)}
              />
              <Button
                danger
                shape="circle"
                icon={<DeleteOutlined />}
                style={{ margin: "0px 5px" }}
                onClick={() => this.props.handleDeleteNotes(record)}
                loading={
                  this.props.notesDeletingKey === record.id &&
                  this.props.deleteManageNotesStatus === LOADING
                }
              />
            </div>
          ) : (
            ""
          );
        },
      },
    ];

    return (
      <Row gutter={16} className="m-top-20">
        <Col span={24}>
          <Card
            title="NOTE"
            headStyle={{ background: "#e8e8e8" }}
            extra={
              <>
                {this.props.data.notes.length > 0 && (
                  <Popconfirm
                    placement="top"
                    title={"Do you want to delete all existing notes"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={this.props.handleDeleteNotesAll}
                  >
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      style={{ margin: "0px 5px" }}
                      loading={
                        this.props.deleteAllManageNotesStatus === LOADING
                      }
                    >
                      Delete All
                    </Button>
                  </Popconfirm>
                )}
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ margin: "0px 5px" }}
                  onClick={this.props.handleAddNotes}
                >
                  Add Note
                </Button>
              </>
            }
          >
            <Col span={24} className="note-container">
              <Table
                rowKey={(record) => record.id}
                bordered
                dataSource={this.props.data.notes}
                columns={notesColumns}
                pagination={false}
                scroll={{ y: "calc(70vh - 200px)" }}
                loading={this.props.manageNotesStatus === LOADING}
              />
            </Col>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default NotesTable;
