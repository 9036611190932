import React, { Component } from "react";
import { connect } from "react-redux";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Icon as LegacyIcon } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Input, Button, Select, Alert, Typography } from "antd";

//--------COMMON ACTIONS--------
import { signInUser } from "../../redux/actions/commonActions/actions";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../constants/commonConstants/generalConstants";

const { Option } = Select;
const { Text } = Typography;

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordVisible: true,
    };
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.props.signInUser(values);
        this.setState({
          passwordVisible: true,
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { logInMessage, logInStatus } = this.props;
    const { passwordVisible } = this.state;

    return (
      <div className="login-form">
        <Form
          onSubmit={(event) => this.handleSubmit(event)}
          className="login-form"
        >
          {(logInStatus === SUCCESS || logInStatus === ERROR) &&
            logInMessage && (
              <Form.Item className="Sign-In-Input-Field">
                <Alert
                  message={logInMessage}
                  type={logInStatus.toLowerCase()}
                  showIcon
                />
              </Form.Item>
            )}

          <Form.Item className="Sign-In-Input-Field">
            {getFieldDecorator("username", {
              rules: [
                { required: true, message: "Please input your username!" },
              ],
            })(
              <Input
                type="text"
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Username"
              />
            )}
          </Form.Item>
          <Form.Item className="Sign-In-Input-Field">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" },
              ],
            })(
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                suffix={
                  <LegacyIcon
                    type={passwordVisible ? "eye-invisible" : "eye"}
                    style={{ color: "rgba(0,0,0,.25)" }}
                    onClick={() =>
                      this.setState({
                        passwordVisible: !this.state.passwordVisible,
                      })
                    }
                  />
                }
                type={passwordVisible ? "password" : "text"}
                placeholder="Password"
              />
            )}
          </Form.Item>

          <Form.Item className="Sign-In-Input-Field">
            {getFieldDecorator("product", {
              rules: [{ required: true, message: "Please select a Product!" }],
            })(
              <Select placeholder="Product">
                <Option value="tm-search">Trademark Search</Option>
                <Option value="tm-watch">Trademark Watch</Option>
                <Option value="tm-manager">Trademark Manager</Option>
                <Option value="patent-manager">Patent Manager</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item className="Sign-In-Input-Field Log-In-btn">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button w-100"
              loading={logInStatus === LOADING}
            >
              Log In
            </Button>

            <Button type="link" href="/forgot">
              <Text underline style={{ color: "#1890FF", marginLeft: "-14px" }}>
                Forgot password?
              </Text>
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  signInUser,
};

function mapStateToProps(state) {
  return {
    logInMessage: state.signInState.logInMessage,
    logInStatus: state.signInState.logInStatus,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "sign_in" })(SignInForm));
