import React from "react";
import { Dropdown, Button, Space, Select, Tooltip } from "antd";
import { DownOutlined, StopOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { default_filter_page, default_filter_page_size } from "../utils/utils";
import {
  getWatchAllFilters,
  changeListSelectedDropdownValues,
  changeListDropdownSearchValues,
  changeListExcludeDropdownValues,
} from "../../../../redux/actions/watchActions/watchActions";
import { getFilters } from "../../../../redux/actions/watchActions/brandCompareActions";

const { Option } = Select;

const ProprietorFilterComponent = ({
  filteredData,
  extraChildren,
  value = [],
  setValue,
  loading = false,
  journal_number = "",
  exclude = [],
  setExclude,
  current_template_id = 0,
  tableView = false,
  filterPath = "",
  application_number = 0,
  rename = "",
  shouldUpdateStore = true,
  threatFlag = false,
  searchTerm,
  handleSearchTerm,
}) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (tableView) {
      setOpenFilter(true);
    }
  }, []);

  const handleChange = (newValue, newOption) => {
    setValue(newValue);
    const tempObject = newOption.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    shouldUpdateStore &&
      dispatch(changeListSelectedDropdownValues(filteredData.key, tempObject));
  };

  const handleSearchDebounced = React.useCallback(
    debounce((newValue) => {
      if (filteredData.key === "brand_proprietors") {
        dispatch(
          getFilters(
            threatFlag,
            filteredData.key,
            newValue,
            default_filter_page,
            default_filter_page_size,
          ),
        );
      } else {
        journal_number &&
          dispatch(
            getWatchAllFilters(
              journal_number,
              filteredData.key,
              newValue,
              default_filter_page,
              default_filter_page_size,
              current_template_id,
              filterPath,
              application_number,
            ),
          );
      }
    }, 500),
    [journal_number, current_template_id],
  );

  const handleSearch = (newValue) => {
    if (filteredData.key === "brand_proprietors") {
      handleSearchTerm(newValue);
    } else {
      dispatch(changeListDropdownSearchValues(filteredData.key, newValue));
    }
    handleSearchDebounced(newValue);
  };

  const placeholder = `Search ${rename ? rename : filteredData.menu_label}`;

  const tagRender = (props) => {
    return null;
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (filteredData.data.length === filteredData.metadata.count) {
      return;
    }
    if (
      !loading &&
      scrollTop + clientHeight >= scrollHeight - 20 &&
      filteredData.data.length < filteredData.metadata.count
    ) {
      if (filteredData.key === "brand_proprietors") {
        dispatch(
          getFilters(
            threatFlag,
            filteredData.key,
            filteredData.searchValue,
            filteredData.metadata.page + 1,
            default_filter_page_size,
          ),
        );
      } else {
        journal_number &&
          dispatch(
            getWatchAllFilters(
              journal_number,
              filteredData.key,
              filteredData.searchValue,
              filteredData.metadata.page + 1,
              default_filter_page_size,
              current_template_id,
              filterPath,
              application_number,
            ),
          );
      }
    }
  };

  const handleExcludeButton = (event, item) => {
    event.stopPropagation();
    let allData = [...filteredData.data, ...filteredData?.excludeValues] || [];
    if (!value.includes(item.value)) {
      if (exclude.indexOf(item.value) !== -1) {
        let temp = exclude.filter(
          (eachProprietor) => eachProprietor !== item.value,
        );
        let tempObject = allData
          .filter((item) => temp.includes(item.value))
          .reduce((unique, obj) => {
            return unique.some(
              (item) => String(item.value) === String(obj.value),
            )
              ? unique
              : [...unique, obj];
          }, []);
        shouldUpdateStore &&
          dispatch(
            changeListExcludeDropdownValues(filteredData.key, tempObject),
          );
        setExclude(temp);
      } else {
        let temp = [...exclude, item.value];
        const tempObject = allData
          .filter((item) => temp.includes(item.value))
          .reduce((unique, obj) => {
            return unique.some(
              (item) => String(item.value) === String(obj.value),
            )
              ? unique
              : [...unique, obj];
          }, []);
        shouldUpdateStore &&
          dispatch(
            changeListExcludeDropdownValues(filteredData.key, tempObject),
          );
        setExclude(temp);
      }
    }
  };

  const selectMenu = () => (
    <Select
      mode="multiple"
      style={{
        minWidth: "320px",
      }}
      dropdownStyle={{
        minWidth: "320px",
      }}
      onPopupScroll={handleScroll}
      loading={loading}
      placeholder={placeholder}
      open={openFilter}
      value={value}
      onChange={handleChange}
      showSearch
      searchValue={
        filteredData.key === "brand_proprietors"
          ? searchTerm
          : filteredData.searchValue
      }
      onSearch={handleSearch}
      id={`watch_filter_id_${filteredData.key}`}
      className="select-filter-dropdown"
      optionFilterProp="children"
      filterOption={(input, option) =>
        String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      tagRender={tagRender}
    >
      {filteredData.data.map((item, index) => (
        <Option
          value={item.value}
          label={item.label}
          disabled={exclude.includes(item.value)}
          key={item.value}
        >
          <div style={{ display: "flex", alignItems: "end" }}>
            <Button
              type="link"
              disabled={value.includes(item.value)}
              onClick={(event) => handleExcludeButton(event, item)}
              style={{ color: exclude.includes(item.value) ? "#ff0000" : "" }}
            >
              <Tooltip
                placement="left"
                title={
                  exclude.includes(item.value) || value.includes(item.value)
                    ? ""
                    : "Exclude"
                }
              >
                <StopOutlined />
              </Tooltip>
            </Button>
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.label}
            </span>
          </div>
        </Option>
      ))}
    </Select>
  );

  return tableView ? (
    selectMenu()
  ) : (
    <Dropdown
      dropdownRender={() => selectMenu()}
      open={openFilter}
      onOpenChange={(event) => setOpenFilter(event)}
      selectable
      multiple={filteredData.multiple ? true : false}
    >
      <Button
        className={`${
          value.length || exclude.length
            ? "Filters-Button-Selected"
            : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
        loading={loading}
      >
        <Space>
          {rename ? rename : filteredData.menu_label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default ProprietorFilterComponent;
