//--------LIBRARIES--------
import Axios from "axios";

//--------ROOT_CONFIG--------
import { MGR_RT_URL } from "../../../../configs/rootConfigs";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------TYPES--------
import {
  TRADEMARK_CUSTOM_REPORT,
  TRADEMARK_AUTOMATED_REPORT,
  TLA_REPORT,
  OPPOSTION_REPORT,
  CUSTOM_TRADEMARK_REPORT,
  INTERNATIONAL_TRADEMARK_REPORT,
  PATENT_CUSTOM_REPORT,
  PATENT_AUTOMATED_REPORT,
  CUSTOM_PATENT_REPORT,
  INTERNATIONAL_PATENT_REPORT,
  MANAGER_DOWNLOAD_REPORT,
  EXAM_REPORT,
  EXAM_REPORT_YOUR_CLASSES_FILTERS,
  EXAM_REPORT_YOUR_PROPRIETORS_FILTERS,
  EXAM_REPORT_YOUR_TM_TYPE_FILTERS,
  EXAM_REPORT_THREAT_CLASSES_FILTERS,
  EXAM_REPORT_THREAT_PROPRIETORS_FILTERS,
  EXAM_REPORT_THREAT_TM_TYPE_FILTERS,
  EXAM_REPORT_YOUR_TAG_FILTERS,
} from "./types";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../../commonActions/actions";

//--------Manager: Profile-Report--------
export function getProfileReport(type, objectType, pageNumber) {
  return async (dispatch) => {
    switch (type) {
      case "trademark":
        switch (objectType) {
          case "custom_report":
            dispatch({ type: TRADEMARK_CUSTOM_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: TRADEMARK_CUSTOM_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: TRADEMARK_CUSTOM_REPORT, status: ERROR });
            }
            break;

          case "automated_report":
            dispatch({ type: TRADEMARK_AUTOMATED_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: TRADEMARK_AUTOMATED_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: TRADEMARK_AUTOMATED_REPORT, status: ERROR });
            }
            break;

          case "tla_report":
            dispatch({ type: TLA_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: TLA_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: TLA_REPORT, status: ERROR });
            }
            break;

          case "opposition_report":
            dispatch({ type: OPPOSTION_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: OPPOSTION_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: OPPOSTION_REPORT, status: ERROR });
            }
            break;

          case "custom_trademark_report":
            dispatch({ type: CUSTOM_TRADEMARK_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: CUSTOM_TRADEMARK_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: CUSTOM_TRADEMARK_REPORT, status: ERROR });
            }
            break;

          case "international_trademark_report":
            dispatch({ type: INTERNATIONAL_TRADEMARK_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: INTERNATIONAL_TRADEMARK_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: INTERNATIONAL_TRADEMARK_REPORT, status: ERROR });
            }
            break;

          default:
            break;
        }
        break;

      case "patent":
        switch (objectType) {
          case "custom_report":
            dispatch({ type: PATENT_CUSTOM_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: PATENT_CUSTOM_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: PATENT_CUSTOM_REPORT, status: ERROR });
            }
            break;

          case "automated_report":
            dispatch({ type: PATENT_AUTOMATED_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: PATENT_AUTOMATED_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: PATENT_AUTOMATED_REPORT, status: ERROR });
            }
            break;

          case "custom_patent_report":
            dispatch({ type: CUSTOM_PATENT_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: CUSTOM_PATENT_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: CUSTOM_PATENT_REPORT, status: ERROR });
            }
            break;

          case "international_patent_report":
            dispatch({ type: INTERNATIONAL_PATENT_REPORT, status: LOADING });
            try {
              let response;
              response = await Axios.get(`${MGR_RT_URL}/report/`, {
                params: {
                  type,
                  object_type: objectType,
                  page: pageNumber,
                },
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              });
              dispatch({
                type: INTERNATIONAL_PATENT_REPORT,
                status: SUCCESS,
                payload: response?.data,
                pageNumber,
              });
            } catch (err) {
              checkAuthorisation(dispatch, err);
              dispatch({ type: INTERNATIONAL_PATENT_REPORT, status: ERROR });
            }
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  };
}

//--------Manager: Report--------
export function downloadReport({
  type,
  mm_type,
  report_name,
  report_type,
  selected_marks,
  selected_patents,
  filters,
  evidence_filter,
  all,
  upcoming_flag,
  pending_reply_to_correspondence,
  pending_reply_to_fer,
  filter_flag,
  pseudo_flag,
  comparative,
  custom_trademark,
  international_trademark,
  custom_patent,
  international_patent,
  report_columns,
  working_of_patent,
  request_for_examination,
  statement_of_undertaking,
  all_pending_replies,
  selected_pending_replies,
  selected_notification,
  notification_page_flag,
}) {
  return async (dispatch) => {
    dispatch({ type: MANAGER_DOWNLOAD_REPORT, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/report/`,
        {
          type,
          mm_type,
          report_name: report_name.trim(),
          report_type,
          selected_marks,
          selected_patents,
          filters,
          evidence_filter,
          all,
          upcoming_flag,
          pending_reply_to_correspondence,
          pending_reply_to_fer,
          filter_flag,
          pseudo_flag,
          comparative,
          custom_trademark,
          international_trademark,
          custom_patent,
          international_patent,
          report_columns,
          working_of_patent,
          request_for_examination,
          statement_of_undertaking,
          all_pending_replies,
          selected_pending_replies,
          selected_notification,
          notification_page_flag,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        }
      );
      dispatch({
        type: MANAGER_DOWNLOAD_REPORT,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: MANAGER_DOWNLOAD_REPORT,
        status: ERROR,
        payload: err.response,
      });
    }
  };
}

export function getExamReportData({ filterFlag, filters, pageNumber }) {
  const contains_term = `${filters?.contains_term}`;
  const classes = `${filters?.YOUR_CLASSES}`;
  const proprietor = `${filters?.YOUR_PROPRIETOR}`;
  const date_of_examination =
    `${filters?.date_of_examination?.value}` !== undefined
      ? `${filters?.date_of_examination?.value}`
      : "";
  const date_of_application =
    `${filters?.date_of_application?.value}` !== undefined
      ? `${filters?.date_of_application?.value}`
      : "";
  const date_of_usage =
    `${filters?.date_of_usage.value}` !== undefined
      ? `${filters?.date_of_usage?.value}`
      : "";
  const mark_type = `${filters?.YOUR_TRADEMARK_TYPE}`;
  const threat_proprietor = `${filters?.THREAT_PROPRIETOR}`;
  const threat_date_of_application =
    `${filters?.threat_date_of_application?.value}` !== undefined
      ? `${filters?.threat_date_of_application?.value}`
      : "";
  const threat_date_of_usage =
    `${filters?.threat_date_of_usage?.value}` !== undefined
      ? `${filters?.threat_date_of_usage?.value}`
      : "";
  const threat_type = `${filters?.THREAT_TRADEMARK_TYPE}`;
  const threat_classes = `${filters?.THREAT_CLASSES}`;
  const threat_date_of_usage_proposed = `${filters?.threat_date_of_usage_proposed}`;
  const date_of_usage_proposed = `${filters?.date_of_usage_proposed}`;

  return async (dispatch) => {
    dispatch({ type: EXAM_REPORT, status: LOADING });
    try {
      let response;
      response = await Axios.get(
        `${MGR_RT_URL}/examination_report_cited_marks/`,
        {
          params: {
            filter_flag: filterFlag ? filterFlag : null,
            contains_term: filterFlag ? contains_term : null,

            proprietor: filterFlag ? proprietor : null,
            date_of_examination: filterFlag ? date_of_examination : null,
            date_of_application: filterFlag ? date_of_application : null,
            date_of_usage: filterFlag ? date_of_usage : null,
            mark_type: filterFlag ? mark_type : null,
            classes: filterFlag ? classes : null,
            threat_proprietor: filterFlag ? threat_proprietor : null,
            threat_date_of_application: filterFlag
              ? threat_date_of_application
              : null,
            threat_date_of_usage: filterFlag ? threat_date_of_usage : null,
            threat_type: filterFlag ? threat_type : null,
            threat_classes: filterFlag ? threat_classes : null,

            date_of_usage_proposed: filterFlag ? date_of_usage_proposed : null,

            threat_date_of_usage_proposed: filterFlag
              ? threat_date_of_usage_proposed
              : null,

            page: pageNumber ? pageNumber : 1,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        }
      );
      dispatch({
        type: EXAM_REPORT,
        status: SUCCESS,
        payload: response?.data,
        pageNumber,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: EXAM_REPORT, status: ERROR });
    }
  };
}

export function getExamReportFilterData({
  pageNumber,
  filters_required_flag,
  required_filter,
  filter_contains_term,
}) {
  return async (dispatch) => {
    if (filters_required_flag && required_filter === "YOUR_CLASSES") {
      dispatch({ type: EXAM_REPORT_YOUR_CLASSES_FILTERS, status: LOADING });
      try {
        let response;
        response = await Axios.get(
          `${MGR_RT_URL}/examination_report_cited_marks/`,
          {
            params: {
              page: pageNumber,
              filters_required_flag,
              required_filter,
              filter_contains_term,
              page_size: 100,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );
        dispatch({
          type: EXAM_REPORT_YOUR_CLASSES_FILTERS,
          status: SUCCESS,
          payload: response?.data,
          pageNumber,
        });
      } catch (err) {
        dispatch({ type: EXAM_REPORT_YOUR_CLASSES_FILTERS, status: ERROR });
      }
    } else if (filters_required_flag && required_filter === "YOUR_PROPRIETOR") {
      dispatch({ type: EXAM_REPORT_YOUR_PROPRIETORS_FILTERS, status: LOADING });
      try {
        let response;
        response = await Axios.get(
          `${MGR_RT_URL}/examination_report_cited_marks/`,
          {
            params: {
              page: pageNumber,
              filters_required_flag,
              required_filter,
              filter_contains_term,
              page_size: 100,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );
        dispatch({
          type: EXAM_REPORT_YOUR_PROPRIETORS_FILTERS,
          status: SUCCESS,
          payload: response?.data,
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({ type: EXAM_REPORT_YOUR_PROPRIETORS_FILTERS, status: ERROR });
      }
    } else if (filters_required_flag && required_filter === "YOUR_TAG") {
      dispatch({ type: EXAM_REPORT_YOUR_TAG_FILTERS, status: LOADING });
      try {
        let response;
        response = await Axios.get(
          `${MGR_RT_URL}/examination_report_cited_marks/`,
          {
            params: {
              page: pageNumber,
              filters_required_flag,
              required_filter,
              filter_contains_term,
              page_size: 100,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );
        dispatch({
          type: EXAM_REPORT_YOUR_TAG_FILTERS,
          status: SUCCESS,
          payload: response?.data,
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({ type: EXAM_REPORT_YOUR_TAG_FILTERS, status: ERROR });
      }
    } else if (
      filters_required_flag &&
      required_filter === "YOUR_TRADEMARK_TYPE"
    ) {
      dispatch({ type: EXAM_REPORT_YOUR_TM_TYPE_FILTERS, status: LOADING });
      try {
        let response;
        response = await Axios.get(
          `${MGR_RT_URL}/examination_report_cited_marks/`,
          {
            params: {
              page: pageNumber,
              filters_required_flag,
              required_filter,
              filter_contains_term,
              page_size: 100,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );
        dispatch({
          type: EXAM_REPORT_YOUR_TM_TYPE_FILTERS,
          status: SUCCESS,
          payload: response?.data,
          pageNumber,
        });
      } catch (err) {
        dispatch({ type: EXAM_REPORT_YOUR_TM_TYPE_FILTERS, status: ERROR });
      }
    } else if (
      filters_required_flag &&
      required_filter === "THREAT_PROPRIETOR"
    ) {
      dispatch({
        type: EXAM_REPORT_THREAT_PROPRIETORS_FILTERS,
        status: LOADING,
      });
      try {
        let response;
        response = await Axios.get(
          `${MGR_RT_URL}/examination_report_cited_marks/`,
          {
            params: {
              page: pageNumber,
              filters_required_flag,
              required_filter,
              filter_contains_term,
              page_size: 100,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );
        dispatch({
          type: EXAM_REPORT_THREAT_PROPRIETORS_FILTERS,
          status: SUCCESS,
          payload: response?.data,
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({
          type: EXAM_REPORT_THREAT_PROPRIETORS_FILTERS,
          status: ERROR,
        });
      }
    } else if (filters_required_flag && required_filter === "THREAT_CLASSES") {
      dispatch({ type: EXAM_REPORT_THREAT_CLASSES_FILTERS, status: LOADING });
      try {
        let response;
        response = await Axios.get(
          `${MGR_RT_URL}/examination_report_cited_marks/`,
          {
            params: {
              page: pageNumber,
              filters_required_flag,
              required_filter,
              filter_contains_term,
              page_size: 100,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );
        dispatch({
          type: EXAM_REPORT_THREAT_CLASSES_FILTERS,
          status: SUCCESS,
          payload: response?.data,
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({ type: EXAM_REPORT_THREAT_CLASSES_FILTERS, status: ERROR });
      }
    } else if (
      filters_required_flag &&
      required_filter === "THREAT_TRADEMARK_TYPE"
    ) {
      dispatch({ type: EXAM_REPORT_THREAT_TM_TYPE_FILTERS, status: LOADING });
      try {
        let response;
        response = await Axios.get(
          `${MGR_RT_URL}/examination_report_cited_marks/`,
          {
            params: {
              page: pageNumber,
              filters_required_flag,
              required_filter,
              filter_contains_term,
              page_size: 100,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );
        dispatch({
          type: EXAM_REPORT_THREAT_TM_TYPE_FILTERS,
          status: SUCCESS,
          payload: response?.data,
          pageNumber,
        });
      } catch (err) {
        dispatch({ type: EXAM_REPORT_THREAT_TM_TYPE_FILTERS, status: ERROR });
      }
    }
  };
}

export function downloadExamReport({
  type,
  report_name,
  report_type,
  report_columns,
  selectedRows,
  selectAll,
  filterFlag,
  filters,
}) {
  const contains_term = `${filters?.contains_term}`;
  const classes = `${filters?.YOUR_CLASSES}`;
  const proprietor = `${filters?.YOUR_PROPRIETOR}`;
  const date_of_examination =
    `${filters?.date_of_examination?.value}` !== undefined
      ? `${filters?.date_of_examination?.value}`
      : "";
  const date_of_application =
    `${filters?.date_of_application?.value}` !== undefined
      ? `${filters?.date_of_application?.value}`
      : "";
  const date_of_usage =
    `${filters?.date_of_usage.value}` !== undefined
      ? `${filters?.date_of_usage?.value}`
      : "";
  const mark_type = `${filters?.YOUR_TRADEMARK_TYPE}`;
  const threat_proprietor = `${filters?.THREAT_PROPRIETOR}`;
  const threat_date_of_application =
    `${filters?.threat_date_of_application?.value}` !== undefined
      ? `${filters?.threat_date_of_application?.value}`
      : "";
  const threat_date_of_usage =
    `${filters?.threat_date_of_usage?.value}` !== undefined
      ? `${filters?.threat_date_of_usage?.value}`
      : "";
  const threat_type = `${filters?.THREAT_TRADEMARK_TYPE}`;
  const threat_classes = `${filters?.THREAT_CLASSES}`;
  const threat_date_of_usage_proposed = `${filters?.threat_date_of_usage_proposed}`;
  const date_of_usage_proposed = `${filters?.date_of_usage_proposed}`;

  return async (dispatch) => {
    // dispatch({ type: HEARING_REPORT_DOWNLOAD, status: LOADING });

    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/report/`,
        {
          er_cited_marks_flag: true,
          type: "trademark",
          report_name,
          report_type: "EXCEL",
          report_columns,
          selected_er_cited_marks: selectedRows,
          all: selectAll ? selectAll : "",

          filter_flag: filterFlag ? filterFlag : null,
          contains_term: filterFlag ? contains_term : null,

          proprietor: filterFlag ? proprietor : null,
          date_of_examination: filterFlag ? date_of_examination : null,
          date_of_application: filterFlag ? date_of_application : null,
          date_of_usage: filterFlag ? date_of_usage : null,
          mark_type: filterFlag ? mark_type : null,
          classes: filterFlag ? classes : null,
          threat_proprietor: filterFlag ? threat_proprietor : null,
          threat_date_of_application: filterFlag
            ? threat_date_of_application
            : null,
          threat_date_of_usage: filterFlag ? threat_date_of_usage : null,
          threat_type: filterFlag ? threat_type : null,
          threat_classes: filterFlag ? threat_classes : null,

          date_of_usage_proposed: filterFlag ? date_of_usage_proposed : null,

          threat_date_of_usage_proposed: filterFlag
            ? threat_date_of_usage_proposed
            : null,
        },
        { headers: { Authorization: "Token " + localStorage.getItem("token") } }
      );
      //   dispatch({ type: HEARING_REPORT_DOWNLOAD, status: SUCCESS, payload: response?.data });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      // dispatch({ type: HEARING_REPORT_DOWNLOAD, status: ERROR, payload: err.response })
    }
  };
}
