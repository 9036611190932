import React, { Component } from "react";
import SignInSider from "./SignInSider";
import "../../stylesheets/loginstylesheets/login.css";
import { LOADING } from "../../constants/commonConstants/generalConstants";
import { verifyAuthCode } from "../../redux/actions/commonActions/actions";

import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Col, Typography, Input, Button } from "antd";
import { connect } from "react-redux";
import TermsAndCondition from "../commonComponents/Modals/TermsAndCondition";

const { Title } = Typography;

class AuthRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formVisible: {
        Recovery: false,
        tnc: false,
      },
      codeError: "",
      auth_code: "",
      recovery_code: "",
    };
  }

  handleSubmitVerify = (e) => {
    e.preventDefault();

    if (this.state?.auth_code?.length === 6) {
      this.props.verifyAuthCode({
        user_id: localStorage.getItem("user_id"),
        operation: "VERIFY",
        sub_operation: "CHALLENGE_CODE",
        code: this.state.auth_code,
      });
    }
  };

  handleSubmitRecovery = (e) => {
    e.preventDefault();
    if (this.state?.recovery_code?.length > 0) {
      this.props.verifyAuthCode({
        user_id: localStorage.getItem("user_id"),
        operation: "VERIFY",
        sub_operation: "RECOVERY_CODE",
        code: this.state.recovery_code,
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { authCodeStatus } = this.props;
    const tnc_accepted = localStorage.getItem("tnc_accepted");
    return (
      <div class="container-fluid">
        <div class="row login-row">
          <SignInSider />
          <div class="col-lg-6 col-sm-12">
            <Col sm={24} md={18} className="SignInForm">
              <div className="Sign-In-Form">
                <div className="Welcome-Heading">
                  {!this.state.Recovery ? (
                    <div className="auth-section">
                      <Title level={3}>Login to MikeIP Suite</Title>
                      <p className="subtitle">
                        Enter the authentication code you’ve recieved on your
                        authenticator app.
                      </p>
                      <Form
                        onSubmit={(e, values) =>
                          this.handleSubmitVerify(e, values)
                        }
                      >
                        <Form.Item label="Authentication Code">
                          {getFieldDecorator("auth_code", {
                            rules: [
                              {
                                required: true,
                                message: "Please enter the code!",
                              },
                              {
                                min: 6,
                                max: 6,
                                message: "Code must be of 6 digits",
                              },
                            ],
                          })(
                            <Input
                              type="number"
                              maxLength={6}
                              onChange={(e) =>
                                this.setState({ auth_code: e.target.value })
                              }
                              placeholder="XXXXXX"
                            />
                          )}

                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button mt-4 w-100"
                            loading={authCodeStatus === LOADING}
                          >
                            Verify Code
                          </Button>
                        </Form.Item>
                      </Form>
                      <div className="having-problem">
                        <p className="p-2">Having Problems?</p>
                        <ul>
                          <li>
                            <p className="sub-text">
                              <a
                                onClick={() =>
                                  this.setState({ Recovery: true })
                                }
                              >
                                Use a recovery code instead
                              </a>
                            </p>
                          </li>
                          <li>
                            <p className="sub-text">
                              <a>
                                Locked out? Contact us at team@mikelegal.com
                              </a>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <div className="recovery-section">
                      <Title level={3}>Login to MikeIP Suite</Title>
                      <p className="subtitle">
                        Enter one of the Recovery Codes we asked you to save
                        during 2 Factor Authentication setup.
                      </p>

                      <Form
                        onSubmit={(e, values) =>
                          this.handleSubmitRecovery(e, values)
                        }
                      >
                        <Form.Item label="Recovery Code">
                          {getFieldDecorator("recovery_code", {
                            rules: [
                              {
                                required: true,
                                message: "Please enter the code!",
                              },
                            ],
                          })(
                            <Input
                              type="text"
                              onChange={(e) =>
                                this.setState({ recovery_code: e.target.value })
                              }
                              placeholder="XXXXXX"
                            />
                          )}
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button mt-4 w-100"
                            loading={authCodeStatus === LOADING}
                          >
                            Verify Recovery Code
                          </Button>
                        </Form.Item>
                      </Form>
                      <div className="having-problem">
                        <p className="p-2">Having Problems?</p>
                        <ul>
                          <li>
                            <p className="sub-text">
                              <a
                                onClick={() =>
                                  this.setState({ Recovery: false })
                                }
                              >
                                Use authentication code instead
                              </a>
                            </p>
                          </li>
                          <li>
                            <p className="sub-text">
                              <a>
                                Locked out? Contact us at team@mikelegal.com
                              </a>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </div>
        </div>
        <TermsAndCondition
          visible={tnc_accepted === "false"}
          onCloseModal={() => {
            localStorage.setItem("tnc_accepted", null);
            this.setState({
              formVisible: {
                ...this.state.formVisible,
                tnc: false,
                auth_code: "",
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  verifyAuthCode,
};

function mapStateToProps(state) {
  const { authData, authSetupStatus, authCodeStatus, authVerification } =
    state.signInState;

  return {
    authData,
    authSetupStatus,
    authCodeStatus,
    authVerification,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AuthRecovery));
