//--------PROFILE TYPES--------
import { P_GET_PROFILE_PREFERENCES } from '../../actions/watchActions/types';

const defaultProfilePreferences = {
    reportPreferences: null,
    reportPreferenceStatus: null
};

export default function(state = defaultProfilePreferences, action){
    switch (action.type) {
        case P_GET_PROFILE_PREFERENCES:
            return {
                ...state,
                reportPreferences: action.payload,
                reportPreferenceStatus: action.status
            };

        default:
            return state;
    }
}