import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addClientMasterData,
  updateClientMasterData,
  updateSingleClientData,
} from "../../../../../redux/actions/commonActions/masterActions/clientMaster";

const { Text } = Typography;
const { TextArea } = Input;

const ClientMasterAddComponent = ({ open, setOpen, filters, type }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const panInputRef = useRef(null);
  const gstInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;

  const validateGst = (rule, value) => {
    if (value) {
      if (!gstRegex.test(value)) {
        return Promise.reject("Invalid GST Number!");
      }
      if (value.length !== 15) {
        return Promise.reject("GST Number is of length 15");
      }
      const panValue = form.getFieldValue("pan_number");
      if (panValue && value.slice(2, 12) !== panValue) {
        return Promise.reject("PAN Number must be included in GST Number!");
      }
    }
    return Promise.resolve();
  };

  const validatePan = (rule, value) => {
    if (value) {
      if (value.length !== 10) {
        return Promise.reject("PAN Number is of length 10");
      }
      if (!panRegex.test(value)) {
        return Promise.reject("Invalid PAN Number!");
      }
      if (value.length === 10) {
        const gstValue = form.getFieldValue("gst_number");
        if (gstValue && gstValue.length) {
          validateGst(null, gstValue);
        }
      }
    }
    return Promise.resolve();
  };

  const { metadata } = useSelector(
    (state) => state.masters.clientMaster.listData,
  );

  const singleClient = useSelector(
    (state) => state.masters.clientMaster.singleClient,
  );

  const { pageNumber } = metadata;

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    dispatch(updateSingleClientData({}));
  };

  useEffect(() => {
    if (type === "edit" && singleClient) {
      form.setFieldsValue(singleClient?.data);
    }
  }, [form, singleClient, type]);

  const handleSubmit = (values) => {
    setLoading(true);
    let body = {
      ...values,
      client_name: values?.client_name ?? "",
      pan_number: values?.pan_number ?? "",
      gst_number: values?.gst_number ?? "",
      address: values?.address ?? "",
      force_update: isForceUpdate,
    };
    if (type === "add") {
      dispatch(
        addClientMasterData(body, pageNumber, filters, {
          onSuccess: (successResponse) => {
            setLoading(false);
            onClose();
            notification["success"]({
              message:
                successResponse?.message ??
                "Client Master Created Successfully!",
            });
          },
          onError: (errorResponse) => {
            setLoading(false);
            if (errorResponse?.payload?.force_update) {
              setIsForceUpdate(true);
              setErrorMessage(errorResponse?.message ?? "");
            }
          },
        }),
      );
    } else {
      const updatedData = {
        ...body,
        client_id: singleClient?.data?.id ?? "",
      };
      dispatch(
        updateClientMasterData(updatedData, pageNumber, filters, {
          onSuccess: (successResponse) => {
            setLoading(false);
            onClose();
            notification["success"]({
              message:
                successResponse?.message ??
                "Client Master Updated Successfully!",
            });
          },
          onError: (errorResponse) => {
            setLoading(false);
            if (errorResponse?.payload?.force_update) {
              setIsForceUpdate(true);
              setErrorMessage(errorResponse?.message ?? "");
            }
          },
        }),
      );
    }
  };

  const renderLabel = (text) => (
    <Text strong style={{ margin: 0, padding: 0 }}>
      {text}
    </Text>
  );

  const handlePanChange = (e) => {
    const { value, selectionStart } = e.target;
    // Set the value to uppercase
    form.setFieldsValue({
      pan_number: value.toUpperCase(),
    });
    // Restore the cursor position
    setTimeout(() => {
      panInputRef.current.setSelectionRange(selectionStart, selectionStart);
    }, 0);
  };

  const handleGSTChange = (e) => {
    const { value, selectionStart } = e.target;
    // Set the value to uppercase
    form.setFieldsValue({
      gst_number: value.toUpperCase(),
    });
    // Restore the cursor position
    setTimeout(() => {
      gstInputRef.current.setSelectionRange(selectionStart, selectionStart);
    }, 0);
  };

  const handleBlur = (key) => {
    switch (key) {
      case "pan":
        form.validateFields(["pan_number"]);
        break;

      case "gst":
        form.validateFields(["gst_number"]);
        break;

      default:
        return null;
    }
  };

  const handleFormChange = () => {
    if (errorMessage) {
      setErrorMessage("");
      setIsForceUpdate(false);
    }
  };

  return (
    <Modal
      title={type === "add" ? "Add Client" : "Edit Client"}
      centered
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" type="default" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={() => form.submit()}
        >
          {type === "add" ? "Add" : "Update"}
        </Button>,
      ]}
      bodyStyle={{ maxHeight: "85vh", overflowY: "auto" }}
    >
      <Form
        form={form}
        id="client-master-add"
        layout="vertical"
        style={{ margin: "20px 10px" }}
        onFinish={handleSubmit}
        onValuesChange={handleFormChange}
        initialValues={type === "edit" ? singleClient : {}}
      >
        <Row style={errorMessage ? { paddingBottom: "30px" } : {}}>
          <Col span={24}>
            <Form.Item
              label={renderLabel("Client Name")}
              name="client_name"
              rules={[{ required: true, message: "Please Enter Client Name!" }]}
            >
              <Input placeholder="Enter Client Name" />
            </Form.Item>
            <Form.Item
              label={renderLabel("PAN Number")}
              name="pan_number"
              rules={[{ validator: validatePan }]}
            >
              <Input
                ref={panInputRef}
                placeholder="Enter PAN Number"
                onChange={handlePanChange}
                onBlur={() => handleBlur("pan")}
              />
            </Form.Item>
            <Form.Item
              label={renderLabel("GST Number")}
              name="gst_number"
              rules={[{ validator: validateGst }]}
            >
              <Input
                ref={gstInputRef}
                placeholder="Enter GST Number"
                onChange={handleGSTChange}
                onBlur={() => handleBlur("gst")}
              />
            </Form.Item>
            <Form.Item label={renderLabel("Client Address")} name="address">
              <TextArea
                placeholder="Enter Address"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {isForceUpdate && (
        <div
          style={{
            position: "absolute",
            bottom: "40px",
            width: "90%",
            background: "white",
            textAlign: "left",
            marginLeft: "4px",
          }}
        >
          <p
            style={{
              backgroundColor: "#FFF6E6",
              border: "1px solid #FFA700",
              padding: "10px",
              color: "#B57701",
            }}
          >
            {errorMessage} Do you still want to continue?
          </p>
        </div>
      )}
    </Modal>
  );
};

export default ClientMasterAddComponent;
