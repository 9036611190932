import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import { CheckCircleFilled } from "@ant-design/icons";

import { Row, Col, Modal, Timeline, Button, Typography } from "antd";

const { Text } = Typography;

class ChangeLogTrademark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render() {
    return (
      <div>
        <Modal
          title={
            <Row>
              <Col span={8} className="alignL">
                <Text strong style={{ fontSize: "16px" }}>
                  CHANGE LOG
                </Text>
              </Col>
              <Col span={16} className="alignR">
                <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
                  *Based on our recorded changes
                </Text>
              </Col>
            </Row>
          }
          open={this.props.visible}
          width={600}
          closable={false}
          onOk={this.props.onCloseModal}
          onCancel={this.props.onCloseModal}
          footer={[
            <Button type="default" onClick={this.props.onCloseModal}>
              cancel
            </Button>,
          ]}
        >
          <div
            style={{ maxHeight: "300px", overflow: "auto" }}
            className="vertical_scroll"
          >
            <Row>
              <Col span={24} style={{ padding: "10px 15px" }}>
                <div>
                  {this.props.trademarkChangelog &&
                    this.props.trademarkChangelog.map((doc) => (
                      <Timeline>
                        <Timeline.Item
                          className="timeline-line"
                          key={doc.trademark_id}
                          dot={
                            <CheckCircleFilled style={{ fontSize: "16px" }} />
                          }
                          color="blue"
                        >
                          <label style={{ margin: "0px" }}>{doc.created}</label>
                          <p>{doc.message}</p>
                        </Timeline.Item>
                      </Timeline>
                    ))}

                  {this.props.trademarkChangelog &&
                    this.props.trademarkChangelog.length === 0 && (
                      <p className="alignC">
                        No changes have been recorded for this trademark.
                      </p>
                    )}
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  const { trademarkChangelog, trademarkChangelogStatus } =
    state.managerState.trademarkDashboardState;
  return deepFreeze({
    trademarkChangelog,
    trademarkChangelogStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLogTrademark);
