//--------TYPES--------
import {
  USER_PROFILE,
  TEAM_MEMBER,
  ADD_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
} from "../actions/commonActions/types";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../constants/commonConstants/generalConstants";

export default function (
  state = {
    userProfile: null,
    userProfileStatus: null,
    userProfileError: null,
    teamMember: null,
    teamMemberStatus: null,
    addTeamStatus: null,
    deleteTeamMember: null,
    deleteTeamStatus: null,
    editTeamStatus: null,
  },
  action
) {
  switch (action.type) {
    case USER_PROFILE:
      return {
        ...state,
        userProfile:
          action.payload && action.status === SUCCESS
            ? action.payload
            : state.userProfile,
        userProfileStatus: action.status,
        userProfileError:
          action.status === ERROR && action.payload ? action.payload : null,
      };

    case TEAM_MEMBER:
      return {
        ...state,
        teamMember: action.payload,
        teamMemberStatus: action.status,
      };

    case ADD_TEAM_MEMBER:
      return {
        ...state,
        teamMember: action.payload,
        teamMemberStatus: action.status,
        addTeamStatus: action.status,
      };

    case EDIT_TEAM_MEMBER:
      return {
        ...state,
        teamMember: action.payload,
        teamMemberStatus: action.status,
        editTeamStatus: action.status,
      };

    case DELETE_TEAM_MEMBER:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteTeamStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            teamMember: state.teamMember.filter(
              (item) => item.id !== action.payload.id
            ),
            deleteTeamMember: action.payload.response,
            deleteTeamStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            deleteTeamStatus: ERROR,
          };

        default:
          break;
      }
      break;

    default:
      return state;
  }
}
