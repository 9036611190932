import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Modal,
  Button,
  Avatar,
  Upload,
  message,
  notification,
} from "antd";

//--------ACTIONS--------
import { editProfile } from "../../../redux/actions/commonActions/actions";

//--------CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

class UploadLetterhead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreviewUrl: "",
      section1: true,
      section2: false,
    };
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / (1024 * 1024) < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    if (isJpgOrPng && isLt2M) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
          section1: false,
          section2: true,
        });
      };
    }
    return isJpgOrPng && isLt2M;
  };

  async uploadConfirm() {
    await this.props.editProfile({
      name: this.props.userProfile.name,
      email: this.props.userProfile.email,
      ph_number: this.props.userProfile.ph_number,
      file: this.state.file,
      imageType: "LETTERHEAD",
    });
    if (this.props.userProfileStatus === SUCCESS) {
      this.uploadLetterhead(SUCCESS);
      this.props.onCloseModal();
      this.setState({
        file: "",
        imagePreviewUrl: "",
        section1: true,
        section2: false,
      });
    } else {
      this.uploadLetterhead(ERROR);
    }
  }

  uploadLetterhead(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Profile Letterhead Uploaded",
          description: "Letterhead uploaded successfully.",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Profile Letterhead Uploaded",
          description:
            "Unfortunately, An error has occured while uploading the logo. Please try again later.",
        });
        break;

      default:
        break;
    }
  }

  render() {
    const { imagePreviewUrl } = this.state;
    return (
      <div>
        <Modal
          title="Upload Profile Letterhead"
          open={this.props.visible}
          onCancel={this.props.onCloseModal}
          footer={false}
        >
          <div>
            <Row
              gutter={16}
              className={this.state.section1 ? "" : "display_none"}
            >
              <Col span={24} className="alignC">
                <Avatar
                  shape="square"
                  size={200}
                  className="avatar-logo"
                  style={{ border: "1px solid #e7e7e7" }}
                >
                  {checkValue(this.props.userProfile) ? (
                    <img
                      src={this.props.userProfile.letter_head_image}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <UserOutlined />
                  )}
                </Avatar>
              </Col>
              <Col span={24} className="alignC m-top-10">
                <Upload beforeUpload={this.beforeUpload} showUploadList={false}>
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <UploadOutlined />
                    {checkValue(this.props.userProfile)
                      ? this.props.userProfile.letter_head_image
                        ? "Change Letterhead"
                        : "Upload Letterhead"
                      : "Upload Letterhead"}
                  </Button>
                </Upload>
              </Col>
            </Row>
            <Row
              gutter={16}
              className={this.state.section2 ? "" : "display_none"}
            >
              <Col span={24} className="alignC">
                <Avatar
                  shape="square"
                  size={200}
                  className="avatar-logo"
                  style={{ border: "#e7e7e7" }}
                >
                  {imagePreviewUrl ? (
                    <img
                      src={imagePreviewUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                </Avatar>
              </Col>
              <Col span={24} className="alignC">
                <div>
                  <ul className="report-footer">
                    <li>
                      <Upload
                        beforeUpload={this.beforeUpload}
                        showUploadList={false}
                      >
                        <Button
                          danger
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <UploadOutlined />
                          Re-Upload
                        </Button>
                      </Upload>
                    </li>
                    <li>
                      <Button
                        type="primary"
                        loading={this.props.userProfileStatus === LOADING}
                        onClick={() => this.uploadConfirm()}
                      >
                        Confirm
                      </Button>
                    </li>
                    <li>
                      <Button type="default" onClick={this.props.onCloseModal}>
                        Close
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  editProfile,
};

function mapStateToProps(state) {
  const { userProfile, userProfileStatus } = state.profile;
  return deepFreeze({
    userProfile,
    userProfileStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadLetterhead);
