import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  FileAddOutlined,
  FilterFilled,
  MinusCircleOutlined,
  PlusOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import {
  Col,
  Input,
  Row,
  Button,
  Badge,
  Tooltip,
  Dropdown,
  Space,
  Popconfirm,
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClientMasterData,
  downloadBulkClientMasterData,
} from "../../../../redux/actions/commonActions/masterActions/clientMaster";

export const ClientMasterHeader = (props) => {
  const {
    searchValue,
    setSearchValue,
    selectAll,
    selected,
    setOpenAddClient,
    filters,
    fetchClientMasterData,
    resetFilters,
    setSelected,
    setSelectedRows,
    setSelectAll,
    filterApplied,
    setFilterApplied,
    setOpenBulkUpload,
    setBulkUploadKey,
    downloadLoading,
    setDownloadLoading,
  } = props;

  const { metadata, loading } = useSelector(
    (state) => state.masters.clientMaster.listData,
  );
  const { pageNumber } = metadata;

  const dispatch = useDispatch();

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleApplyFilters = () => {
    setFilterApplied(true);
    fetchClientMasterData(1, filters);
  };

  const handleResetFilters = () => {
    resetFilters();
    fetchClientMasterData();
  };

  const handleAddClient = () => {
    setOpenAddClient(true);
  };

  const handleMultipleDeleteClient = () => {
    const body = {
      client_id: "",
      selectAll,
      client_master_list: selectAll ? [] : selected,
      contain_term: searchValue,
    };
    dispatch(
      deleteClientMasterData(body, pageNumber, filters, {
        onSuccess: () => {
          setSelected([]);
          setSelectedRows([]);
          setSelectAll(false);
        },
      }),
    );
  };

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1": {
        setBulkUploadKey("add");
        setOpenBulkUpload(true);
        break;
      }
      case "2": {
        setBulkUploadKey("edit_client_masters");
        setOpenBulkUpload(true);
        break;
      }
      case "3": {
        setBulkUploadKey("replace_contact_persons");
        setOpenBulkUpload(true);
        break;
      }
      default:
        return null;
    }
  };

  const items = [
    {
      label: "Bulk Add",
      key: "1",
      icon: <FileAddOutlined />,
    },
    {
      label: "Edit Client Masters",
      key: "2",
      icon: <EditOutlined />,
    },
    {
      label: "Replace Contact Persons",
      key: "3",
      icon: <RedoOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const downloadClients = () => {
    setDownloadLoading(true);
    let body = {
      client_master_list: selectAll ? [] : selected,
    };
    dispatch(
      downloadBulkClientMasterData(body, {
        onSuccess: (successResponse) => {
          setDownloadLoading(false);
          notification["success"]({
            message: successResponse?.message ?? "File Added Successfully!",
          });
        },
      }),
    );
  };

  return (
    <div>
      <Row
        style={{
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Col xs={{ span: 18 }} lg={{ span: 16 }} md={{ span: 12 }}>
          <Input
            placeholder={"Search Client/Contact Person/Email"}
            onChange={(event) => handleSearchChange(event)}
            value={searchValue}
            allowClear
            className="search-value-input-field"
            style={{
              width: 410,
              background: "#F5F6F8",
              borderRadius: "0px",
            }}
          />
          <Button
            type="primary"
            icon={<FilterFilled />}
            style={{ margin: "0px 15px" }}
            onClick={() => handleApplyFilters()}
            loading={loading}
          >
            Apply
          </Button>
          <Button
            type="text"
            danger
            onClick={() => handleResetFilters()}
            loading={loading}
            disabled={!filterApplied}
          >
            Reset All
          </Button>
        </Col>
        <Col
          xs={{ span: 6 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: selectAll ? 13 : 10,
          }}
        >
          <Tooltip title="Deselect All">
            <Button
              danger
              onClick={() => {
                setSelectAll(false);
                setSelected([]);
                setSelectedRows([]);
              }}
              disabled={!selected.length}
              icon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MinusCircleOutlined style={{ margin: 0, fontSize: 16 }} />
                </div>
              }
            />
          </Tooltip>
          <Space>
            <Dropdown menu={menuProps}>
              <Button>
                Bulk Upload <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
          <Tooltip title="Download Excel for Selected Clients.">
            <Badge
              overflowCount={9999}
              count={selectAll ? "ALL" : selected.length}
            >
              <Button
                type="primary"
                loading={downloadLoading}
                icon={<DownloadOutlined />}
                disabled={!(selectAll || selected?.length) || downloadLoading}
                onClick={() => downloadClients()}
              >
                Download
              </Button>
            </Badge>
          </Tooltip>
          <Popconfirm
            title="Delete Client Master"
            description="All the related documents will be deleted.Are you sure to delete this task?"
            onConfirm={() => handleMultipleDeleteClient()}
            okText="Yes"
            cancelText="No"
          >
            <Badge
              overflowCount={9999}
              count={selectAll ? "ALL" : selected.length}
            >
              <Button
                type="default"
                icon={<DeleteOutlined />}
                danger
                disabled={!(selectAll || selected?.length)}
              >
                Delete
              </Button>
            </Badge>
          </Popconfirm>
          <Button
            type="link"
            icon={<PlusOutlined />}
            style={{ border: "1px solid #1677ff" }}
            onClick={() => handleAddClient()}
            loading={loading}
          >
            Add Client
          </Button>
        </Col>
      </Row>
    </div>
  );
};
