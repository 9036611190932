//--------JOURNALS TYPES--------
import {
  J_GET_JOURNAL,
  J_GET_USER_TRADEMARKS,
  J_SELECT_VIEW,
  J_POST_CUSTOM_REPORT,
} from "../../actions/watchActions/types";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

//--------JOURNALS CONSTANTS--------
import { J_defaultSelectedFilters } from "../../../constants/watchConstants/journalsConstants";

const defaultJournalsState = {
  journalData: null,
  journalDataStatus: SUCCESS,
  filters: null,
  journalNumber: null,
  journalDate: null,
  journalTotalMarksCount: null,
  pageNumber: 1,
  lastAppliedFilters: {
    ...J_defaultSelectedFilters,
    filtersFlag: false,
  },
  userTrademarks: {
    trademarksData: null,
    trademarksDataStatus: SUCCESS,
    trademarksTotalCount: null,
    filteredTrademarksCount: null,
    pageNumber: 0,
    searchTerm: "",
  },
  selectedView: "1",
};

export default function (state = defaultJournalsState, action) {
  switch (action.type) {
    case J_GET_JOURNAL:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            journalData: action.payload.data,
            journalDataStatus: action.status,
            filters: action.payload.filters,
            journalNumber: action.payload.journal_number,
            journalDate: action.payload.journal_date,
            journalTotalMarksCount: action.payload.marks_count,
            pageNumber: action.pageNumber,
            lastAppliedFilters: action.lastAppliedFilters,
          };

        case LOADING:
          return {
            ...(action.journalChange ? defaultJournalsState : state),
            journalDataStatus: action.status,
            userTrademarks: state.userTrademarks,
          };

        case ERROR:
          return {
            ...state,
            journalDataStatus: action.status,
          };

        default:
          return state;
      }

    case J_GET_USER_TRADEMARKS:
      {
      }
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            userTrademarks: {
              ...state.userTrademarks,
              trademarksData: action.payload.data,
              trademarksDataStatus: action.status,
              trademarksTotalCount: action.payload.total_count,
              filteredTrademarksCount: action.payload.risk_count,
              pageNumber: action.pageNumber,
              searchTerm: action.searchTerm,
              search_term: action.search_term,
              mark_desc_contains_terms: action.mark_desc_contains_terms,
            },
          };

        case LOADING:
          return {
            ...state,
            userTrademarks: {
              ...state.userTrademarks,
              trademarksDataStatus: action.status,
            },
          };

        case ERROR:
          return {
            ...state,
            userTrademarks: {
              ...state.userTrademarks,
              trademarksDataStatus: action.status,
              trademarksTotalCount: 0,
              filteredTrademarksCount: 0,
            },
          };

        default:
          return {
            ...state,
            userTrademarks: {
              ...state.userTrademarks,
              trademarksDataStatus: action.status,
            },
          };
      }

    case J_SELECT_VIEW:
      return {
        ...state,
        selectedView: action.payload,
      };

    case J_POST_CUSTOM_REPORT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case LOADING:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            customReportStatus: action.status,
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
