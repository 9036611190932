import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import {
  Row,
  Col,
  Drawer,
  Input,
  Alert,
  Button,
  Typography,
  Checkbox,
  Radio,
} from "antd";
import { postReport } from "../../../redux/actions/searchActions/commonActions";
import { LOADING } from "../../../constants/commonConstants/generalConstants";

const { Title, Text } = Typography;

class CreateSearchReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customReportData: this.props.customReportData,
    };
  }

  selectReportType(type, checked) {
    if (checked) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          report_file_types: [
            ...this.state.customReportData.report_file_types,
            type,
          ],
        },
      });
    } else {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          report_file_types:
            this.state.customReportData.report_file_types.filter(
              (items) => items !== type
            ),
        },
      });
    }
  }

  async handleCreateReportConfirmation() {
    const { customReportData } = this.state;
    let markId = customReportData.id;
    let filter_data = {};
    let sort_data = {};

    if (customReportData.data[`${markId}`].filter_flag) {
      filter_data = {
        filter_class: customReportData.data[`${markId}`].filters.filterClasses,
        filter_state: customReportData.data[`${markId}`].filters.filterState,
        filter_trademark_type:
          customReportData.data[`${markId}`].filters.filterType,
        filter_trademark_status:
          customReportData.data[`${markId}`].filters.filterStatus,
        filter_proprietor:
          customReportData.data[`${markId}`].filters.filterProprietor,
        filter_application_date:
          customReportData.data[`${markId}`].filters.date_of_application,
        filter_date_of_usage:
          customReportData.data[`${markId}`].filters.date_of_usage,
        filter_date_of_usage_proposed:
          customReportData.data[`${markId}`].filters.date_of_usage_proposed,
        filter_contains_term:
          customReportData.data[`${markId}`].filters.containsTerm,
      };
    } else {
      filter_data = {};
    }

    if (customReportData.data[`${markId}`].sort_flag) {
      sort_data = {
        sort_type: customReportData.data[`${markId}`].sort.sortType,
        sort_on: customReportData.data[`${markId}`].sort.sortOn,
      };
    } else {
      sort_data = {};
    }

    await this.props.postReport({
      report_source: customReportData.report_source,
      report_type: customReportData.report_type,
      report_file: customReportData.report_file,
      report_file_types: customReportData.report_file_types,
      report_file_name: customReportData.report_file_name,
      data: {
        [markId]: {
          select_all: customReportData.data[markId].select_all,
          selected_mark_id_list: customReportData.data[markId].selectedMark,
          filter_flag: customReportData.data[markId].filter_flag,
          filters: filter_data,
        },
      },
    });
    this.props.onCloseDrawer();
  }

  render() {
    return (
      <div>
        <Drawer
          title={<h3>Custom Report Details</h3>}
          placement="right"
          width={400}
          closable={false}
          open={this.props.visible}
          onClose={() => this.props.onCloseDrawer()}
        >
          <div>
            <Row>
              <Col span={24}>
                Enter the name of your report:
                <br />
                <Input
                  onChange={(event) => {
                    if (
                      /^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)
                    ) {
                      this.setState({
                        customReportData: {
                          ...this.state.customReportData,
                          report_file_name: event.target.value,
                        },
                      });
                    }
                  }}
                  value={this.state.customReportData.report_file_name}
                  placeholder="Report's name (Optional)"
                />
              </Col>
              <Col span={24} className="m-top-20">
                Choose your preferred report type:
                <br />
                <Radio.Group
                  style={{ fontWeight: "100", padding: "0px 15px" }}
                  value={this.state.customReportData.report_type}
                  onChange={(event) =>
                    this.setState({
                      customReportData: {
                        ...this.state.customReportData,
                        report_type: event.target.value,
                      },
                    })
                  }
                >
                  <Radio value="URGENT">Urgent Report</Radio>
                  <br />
                  <Radio value="REALTIME">Realtime report</Radio>
                </Radio.Group>
              </Col>
              <Col span={24} className="m-top-20">
                Select your preferred report format:
                <br />
                <Checkbox
                  checked={this.state.customReportData.report_file_types.includes(
                    "PDF"
                  )}
                  onClick={(event) =>
                    this.selectReportType("PDF", event.target.checked)
                  }
                >
                  PDF
                </Checkbox>
                <br />
                <Checkbox
                  checked={this.state.customReportData.report_file_types.includes(
                    "EXCEL"
                  )}
                  onClick={(event) =>
                    this.selectReportType("EXCEL", event.target.checked)
                  }
                >
                  EXCEL
                </Checkbox>
              </Col>
              <Col span={24} className="m-top-20">
                <Alert
                  message="Note: Once the report is created, it cannot be changed."
                  type="warning"
                />
              </Col>
              <Col span={24} className="m-top-20">
                <Button
                  style={{ marginRight: "10px" }}
                  ghost
                  type="primary"
                  onClick={() => this.handleCreateReportConfirmation()}
                  loading={this.props.searchReportStatus === LOADING}
                >
                  Confirm
                </Button>
                <Button ghost danger onClick={() => this.props.onCloseDrawer()}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapDispatchToProps = {
  postReport,
};

function mapStateToProps(state) {
  const { searchReport, searchReportStatus } = state.searchState.searchBar;
  return deepFreeze({
    searchReport,
    searchReportStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSearchReport);
