//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { MailOutlined, TableOutlined, UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Modal,
  Button,
  Input,
  Alert,
  Typography,
  notification,
  List,
} from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import {
  clientEmailAdd,
  setStoreData,
} from "../../../redux/actions/commonActions/actions";

const { Text } = Typography;

class AddClientEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_input: {
        client_email: "",
        company_name: "",
        person_name: "",
      },
    };
  }

  onClose() {
    this.props.setStoreData({
      clientEmailUploadData: {
        message: "",
        errors: [],
      },
    });
    this.props.onCloseModal();
    this.props.form.resetFields();
  }

  async handleAddClientEmail(e) {
    let response = await this.props.clientEmailAdd({
      client_email_entity_id: this.props?.selectedEmailDetail?.id,
      ...this.state?.email_input,
      subscription: this.props?.selectedSubscription,
      mm_type: this.props?.mmType,
    });

    if (response === SUCCESS) {
      this.uploadMarkMessage(SUCCESS);
      this.props.onConfirm();
      this.onClose();
    } else {
      this.uploadMarkMessage(ERROR);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form
      .validateFields((err, values) => {
        if (!err) {
          this.props.setStoreData({
            ...this.props.editEmailData,
          });
        }
      })
      .then((values) => {
        this.handleAddClientEmail(values);
      })
      .catch((info) => {});
  };

  uploadMarkMessage(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Email Added",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Adding",
          description:
            "Unfortunately, an error occured. Please, try again later.",
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    return (
      <>
        <Modal
          title={
            <div className="alignC">
              <Text strong style={{ fontSize: "16px" }}>
                ADD EMAIL
              </Text>
            </div>
          }
          open={this.props?.visible}
          closable={false}
          footer={null}
        >
          <>
            <Form
              id="client_email_add"
              labelCol={{ span: 5 }}
              // wrapperCol={{ span: 12 }}
              onSubmit={this.handleSubmit}
            >
              {this.props?.createClientEmailStatus === ERROR &&
                this.props?.clientEmailUploadData?.message && (
                  <>
                    <Form.Item>
                      <Alert
                        message={this.props?.clientEmailUploadData?.message}
                        type="error"
                        showIcon
                      />
                      <div style={{ maxHeight: "160px", overflow: "auto" }}>
                        {this.props?.clientEmailUploadData?.errors &&
                          this.props?.clientEmailUploadData?.errors?.length >
                            0 && (
                            <List
                              size="small"
                              bordered
                              dataSource={
                                this.props?.clientEmailUploadData?.errors
                              }
                              renderItem={(item, index) => (
                                <Alert
                                  message={`${index + 1}. ${item}`}
                                  style={{ margin: "16px" }}
                                  banner
                                />
                              )}
                            />
                          )}
                      </div>
                    </Form.Item>
                  </>
                )}

              <Form.Item {...formItemLayout} label="Email">
                {getFieldDecorator("client_email", {
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "Please enter valid email!",
                    },
                  ],
                })(
                  <Input
                    type="text"
                    prefix={
                      <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Add email"
                    onChange={(x) =>
                      this.setState({
                        email_input: {
                          ...this.state?.email_input,

                          client_email: x.target.value,
                        },
                      })
                    }
                  />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Person Name">
                {getFieldDecorator("person_name", {
                  rules: [
                    {
                      type: "string",
                    },
                  ],
                })(
                  <Input
                    type="string"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Please add Person Name"
                    onChange={(x) =>
                      this.setState({
                        email_input: {
                          ...this.state?.email_input,

                          person_name: x.target.value,
                        },
                      })
                    }
                  />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Company Name">
                {getFieldDecorator(
                  "company_name",
                  {}
                )(
                  <Input
                    type="text"
                    prefix={
                      <TableOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Please add company name"
                    onChange={(x) =>
                      this.setState({
                        email_input: {
                          ...this.state?.email_input,

                          company_name: x.target.value,
                        },
                      })
                    }
                  />
                )}
              </Form.Item>
              <div
                style={{
                  width: " 100%",
                  textAlign: "right",
                }}
              >
                <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                  <Button type="default" onClick={() => this.onClose()}>
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  clientEmailAdd,
  setStoreData,
};

function mapStateToProps(state) {
  const {
    clientEmailUploadData,
    createClientEmailStatus,
    selectedEmailDetail,
    selectedSubscription,
    mmType,
  } = state.clientEmailState;
  return deepFreeze({
    clientEmailUploadData,
    createClientEmailStatus,
    selectedEmailDetail,
    selectedSubscription,
    mmType,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AddClientEmailForm));
