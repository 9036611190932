import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { TagsOutlined, TagTwoTone, UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Table,
  Drawer,
  Button,
  Input,
  notification,
  Typography,
  Popconfirm,
} from "antd";
import {
  getPatentTags,
  deletePatentTags,
  editPatentTags,
} from "../../../redux/actions/commonActions/actions";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import { changeStateValue_L0 } from "./../../../functions/commonFunctions/generalFunctions";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import AddTagsModal from "../Modals/AddPatentTags";
import { getManagePatent } from "../../../redux/actions/managerActions/patentDashboard/actions";
import { Patent_DefaultSelectedFilters } from "../../../constants/managerConstants/patentConstants";

const { Title, Text } = Typography;

class TagsDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      addTags: false,
      editingKey: "",
      deletingKey: "",
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    if (this.props.manageTags === null) {
      this.props.getPatentTags();
    }
  }

  isEditing = (record) => record.id === this.state.editingKey;

  editTags(data) {
    this.setState({
      editingKey: data.id,
    });
  }

  async deleteTags(data) {
    this.setState({
      deletingKey: data.id,
    });
    await this.props.deletePatentTags(data.id);
    if (this.props.deleteTagsStatus === SUCCESS) {
      this.deleteTagsData(SUCCESS);
      this.props.getManagePatent({
        filterFlag: false,
        filters: Patent_DefaultSelectedFilters,
        pageNumber: 1,
      });
    } else {
      this.deleteTagsData(ERROR);
    }
  }

  async saveTags(event, data) {
    event.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.props.editPatentTags({
          id: data.id,
          name: values.name,
          color_code: values.color_code,
        });
        if (this.props.editTagsStatus === SUCCESS) {
          this.updateTagsData(SUCCESS);
          this.props.getManagePatent({
            filterFlag: false,
            filters: Patent_DefaultSelectedFilters,
            pageNumber: 1,
          });
        } else {
          this.updateTagsData(ERROR);
        }
        this.setState({ editingKey: "" });
      }
    });
  }

  cancelTags(data) {
    this.setState({ editingKey: "" });
  }

  deleteTagsData(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Delete Tags",
          description: "Successfully Deleted tags",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Tags Deleted",
          description:
            "Unfortunately, an error occured while Tags Deleting. Please, try again later.",
        });
        break;

      default:
        break;
    }
  }

  updateTagsData(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Update Tags",
          description: "Successfully Updated tags",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Tags Updated",
          description:
            "Unfortunately, an error occured while Tags Updating. Please, try again later.",
        });
        break;

      default:
        break;
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const manageTagsColumns = [
      {
        title: "TAGS",
        width: 150,
        className: "trademark-column",
        render: (text, record) => {
          const editable = this.isEditing(record);
          return editable ? (
            <div>
              <Form
                onSubmit={(event) => this.saveTags(event, record)}
                className="login-form"
              >
                <Form.Item className="Sign-In-Input-Field">
                  {getFieldDecorator("color_code", {
                    rules: [
                      { required: true, message: "please pickup tags color!" },
                    ],
                    initialValue: checkValue(record.color_code)
                      ? record.color_code
                      : "",
                  })(
                    <Input
                      type="color"
                      prefix={
                        <TagsOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="please pickup tags color"
                    />
                  )}
                </Form.Item>
                <Form.Item className="Sign-In-Input-Field">
                  {getFieldDecorator("name", {
                    rules: [
                      { required: true, message: "please enter tag name!" },
                    ],
                    initialValue: checkValue(record.name) ? record.name : "",
                  })(
                    <Input
                      type="text"
                      prefix={
                        <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="please enter tag name"
                    />
                  )}
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div>
              <p
                className="application-text"
                style={{ display: "flex", alignItems: "center" }}
              >
                <TagTwoTone twoToneColor={`${record.color_code}`} />
                <Text
                  className="Tag"
                  style={{
                    color: "#fff",
                    background: `${record.color_code}`,
                    border: `1px solid ${record.color_code}`,
                  }}
                >
                  {record.name}
                </Text>
              </p>
            </div>
          );
        },
      },

      {
        title: "ACTION",
        width: 150,
        className: "trademark-column",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <>
              <Button
                type="primary"
                htmlType="submit"
                key="submit"
                loading={
                  this.state.editingKey === record.id &&
                  this.props.editTagsStatus === LOADING
                    ? true
                    : false
                }
                onClick={(event) => this.saveTags(event, record)}
                style={{ marginRight: 10 }}
              >
                Save
              </Button>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancelTags(record)}
              >
                <Button type="default">Cancel</Button>
              </Popconfirm>
            </>
          ) : (
            <>
              <Button
                type="primary"
                disabled={editingKey !== ""}
                style={{
                  marginRight: 10,
                  background: "#59c4af",
                  borderColor: "#59c4af",
                }}
                onClick={() => this.editTags(record)}
              >
                Edit
              </Button>
              <Button
                danger
                onClick={() => this.deleteTags(record)}
                loading={
                  this.state.deletingKey === record.id &&
                  this.props.deleteTagsStatus === LOADING
                    ? true
                    : false
                }
              >
                Remove
              </Button>
            </>
          );
        },
      },
    ];

    return (
      <div>
        <Drawer
          title={
            <Title level={4} rootStyle={{ marginBottom: 0 }}>
              Manage Tags
            </Title>
          }
          placement="right"
          width={"35%"}
          closable={true}
          onClose={() => this.props.onCloseDrawer()}
          open={this.props.visible}
        >
          <Row>
            <Col span={24} className="alignR">
              <Button
                type="primary"
                onClick={() => this.changeStateValue_L0("addTags", true)}
              >
                Add Tags
              </Button>
            </Col>
          </Row>
          <Row className="m-top-20">
            {/* Manage Tags table */}
            <Col span={24}>
              <Table
                rowKey={(record) => record.id}
                bordered
                columns={manageTagsColumns}
                dataSource={this.props.manageTags && this.props.manageTags.data}
                // pagination={{
                //     pageSize: 100,
                //     showQuickJumper: true,showSizeChanger: false,
                //     defaultCurrent: 1,
                //     total: this.props.manageTags && this.props.manageTags.count,
                //     onChange: this.handleNextpage
                // }}
                pagination={false}
                scroll={{ y: "calc(90vh - 200px)" }}
                loading={this.props.manageTagsStatus === LOADING ? true : false}
              />
            </Col>
            {/* Manage Tags table */}
          </Row>
        </Drawer>
        {this.state.addTags && (
          <AddTagsModal
            visible={this.state.addTags}
            onCloseModal={() => this.changeStateValue_L0("addTags", false)}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getPatentTags,
  deletePatentTags,
  getManagePatent,
  editPatentTags,
};

function mapStateToProps(state) {
  const { manageTags, manageTagsStatus, deleteTagsStatus, editTagsStatus } =
    state.signInState;

  return deepFreeze({
    manageTags,
    manageTagsStatus,
    deleteTagsStatus,
    editTagsStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "edit_tags" })(TagsDrawer));
