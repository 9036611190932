import {
  B_C_brand_proprietors,
  B_C_brand_classes,
  B_C_UPDATE_EXCLUDE_VALUES,
} from "../../../actions/watchActions/types";

import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

const defaultFiltersConfig = {
  proprietorConfig: {
    key: "brand_proprietors",
    menu_label: "Proprietors",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
  classConfig: {
    key: "brand_classes",
    menu_label: "Classes",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
};

const generateUniqueArrayOfObject = (arrayOfObjects) => {
  const uniqueArray = [];
  const seenValues = new Set();

  for (const obj of arrayOfObjects) {
    const value = obj.value;
    if (!seenValues.has(value)) {
      uniqueArray.push(obj);
      seenValues.add(value);
    }
  }
  return uniqueArray;
};

export default function (state = defaultFiltersConfig, action) {
  switch (action.type) {
    case B_C_brand_classes:
    case B_C_brand_proprietors: {
      const changedKey =
        Object.entries(state).find(
          (item) => item[1].key === action.filter_key,
        )[0] || "";
      switch (action.status) {
        case SUCCESS:
          if (changedKey) {
            if (action.page > 1) {
              return {
                ...state,
                [changedKey]: {
                  ...state[changedKey],
                  data: generateUniqueArrayOfObject([
                    ...state[changedKey].data,
                    ...action.payload.data,
                  ]),
                  metadata: action.payload.metadata,
                  loading: false,
                },
              };
            } else {
              return {
                ...state,
                [changedKey]: {
                  ...state[changedKey],
                  data: action.payload.data,
                  metadata: action.payload.metadata,
                  loading: false,
                },
              };
            }
          }
          break;
        case LOADING:
          if (changedKey)
            return {
              ...state,
              [changedKey]: {
                ...state[changedKey],
                loading: true,
              },
            };
          break;
        case ERROR:
          return {
            ...state,
            [changedKey]: {
              ...state[changedKey],
              loading: false,
            },
          };

        default:
          return {
            ...state,
          };
      }
      break;
    }
    case B_C_UPDATE_EXCLUDE_VALUES: {
      const stateinWhichNeedToBeApplied = {
        proprietorConfig: state.proprietorConfig,
      };
      const changedKey =
        Object.entries(stateinWhichNeedToBeApplied).find(
          (item) => item[1].key === action.filter_key,
        )[0] || "";
      return {
        ...state,
        [changedKey]: {
          ...state[changedKey],
          excludeValues: action.values,
        },
      };
    }
    default:
      return state;
  }
}
