//--------LIBRARIES--------
import Axios from "axios";

//--------ROOT_CONFIG--------
import { MGR_RT_URL } from "../../../../configs/rootConfigs";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------TYPES--------
import {
  TO_DO_TASK,
  ADD_TO_DO_TASK,
  DELETE_DO_TASK,
  TO_DO_REMINDER,
  DELETE_REMINDER,
  RESET_REMINDER,
  EDIT_REMINDER,
} from "./types";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../../commonActions/actions";

//--------Manager: TODO-Task--------
export function getToDoTask(status, priority) {
  return async (dispatch) => {
    dispatch({ type: TO_DO_TASK, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/to-do/tasks/`, {
        params: { status, priority },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({ type: TO_DO_TASK, status: SUCCESS, payload: response?.data });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: TO_DO_TASK, status: ERROR });
    }
  };
}

//--------Manager: Delete-TODO-Task--------
export function deleteToDoTask(id) {
  return async (dispatch) => {
    dispatch({ type: DELETE_DO_TASK, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${MGR_RT_URL}/to-do/tasks/`, {
        params: { id },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: DELETE_DO_TASK,
        status: SUCCESS,
        payload: { response: response?.data, id: id },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: DELETE_DO_TASK, status: ERROR });
    }
  };
}

//--------Manager: Delete-ALL-TODO-Task--------
export function deleteAllToDoTask() {
  return async (dispatch) => {
    dispatch({ type: TO_DO_TASK, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${MGR_RT_URL}/to-do/tasks/`, {
        params: { all: true },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({ type: TO_DO_TASK, status: SUCCESS, payload: response?.data });
      getToDoTask();
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: TO_DO_TASK, status: ERROR });
    }
  };
}

//--------Manager: Create-TODO-Task--------
export function createToDoTask({
  title,
  description,
  created_by,
  priority,
  emails,
  reminders,
  associated_trademarks,
  associated_patent,
  due_on_datetime,
}) {
  let formData = new FormData();
  formData.append("priority", priority);
  formData.append("title", title);
  formData.append("description", description);
  formData.append("created_by", created_by);

  if (emails && emails.length > 0) {
    let arr = emails;
    for (let i = 0; i < arr.length; i++) {
      formData.append("emails", arr[i]);
    }
  }

  if (associated_trademarks && associated_trademarks.length > 0) {
    let arr1 = associated_trademarks;
    for (let i = 0; i < arr1.length; i++) {
      formData.append("associated_trademarks", arr1[i]);
    }
  }

  if (associated_patent && associated_patent.length > 0) {
    let arr2 = associated_patent;
    for (let i = 0; i < arr2.length; i++) {
      formData.append("associated_patents", arr2[i]);
    }
  }

  if (reminders && reminders.length > 0) {
    let arr3 = reminders;
    for (let i = 0; i < arr3.length; i++) {
      formData.append("reminders", arr3[i]);
    }
  }

  if (due_on_datetime && due_on_datetime.length > 0) {
    formData.append("due_on_datetime", due_on_datetime);
  }

  return async (dispatch) => {
    dispatch({ type: ADD_TO_DO_TASK, status: LOADING });
    try {
      let response;
      response = await Axios.post(`${MGR_RT_URL}/to-do/tasks/`, formData, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: ADD_TO_DO_TASK,
        status: SUCCESS,
        payload: response?.data,
      });
      getToDoTask();
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: ADD_TO_DO_TASK, status: ERROR });
    }
  };
}

//--------Manager: Reminder-TODO-Task--------
export function todoReminder(time) {
  let formData = new FormData();
  formData.append("time", time);
  return async (dispatch) => {
    dispatch({ type: TO_DO_REMINDER, status: LOADING });
    try {
      let response;
      response = await Axios.post(`${MGR_RT_URL}/to-do/reminders/`, formData, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: TO_DO_REMINDER,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: TO_DO_REMINDER, status: ERROR });
    }
  };
}

//--------Manager: Reminder-TODO-Task--------
export function deleteReminder(id) {
  return async (dispatch) => {
    dispatch({ type: DELETE_REMINDER, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${MGR_RT_URL}/to-do/reminder/${id}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: DELETE_REMINDER,
        status: SUCCESS,
        payload: { response: response?.data, id: id },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: DELETE_REMINDER, status: ERROR });
    }
  };
}

// --------Manager: Reminder-RESET--------
export function resetReminder() {
  return async (dispatch) => {
    dispatch({ type: RESET_REMINDER });
  };
}

// --------Manager: Reminder-RESET--------
export function editReminder(data) {
  return async (dispatch) => {
    dispatch({ type: EDIT_REMINDER, payload: data });
  };
}
