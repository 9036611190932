import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import {
  ADD_CLIENT_NOTES_DATA,
  DELETE_CLIENT_NOTES_DATA,
  GET_CLIENT_NOTES_DATA,
  UPDATE_CLIENT_NOTES_DATA,
} from "../../../actions/commonActions/masterActions/masterTypes";

const initialState = {
  data: [],
  isDataLoaded: false,
  loading: false,
  metadata: {
    count: 0,
    pageNumber: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_NOTES_DATA: {
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            data: action.payload.data,
            isDataLoaded: true,
            loading: false,
            metadata: {
              count: action.payload.metadata.count,
              pageNumber: action.pageNumber,
            },
          };

        case LOADING:
          return {
            ...state,
            loading: true,
          };

        case ERROR:
          return {
            ...state,
            data: [],
            isDataLoaded: false,
            loading: false,
            metadata: {
              count: 0,
              pageNumber: 1,
            },
          };

        default:
          return state;
      }
    }

    case ADD_CLIENT_NOTES_DATA: {
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            loading: true,
          };
        default:
          return state;
      }
    }

    case UPDATE_CLIENT_NOTES_DATA: {
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            loading: true,
          };
        default:
          return state;
      }
    }

    case DELETE_CLIENT_NOTES_DATA: {
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            loading: true,
          };
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
