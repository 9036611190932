import React, { Component } from "react";
import { CarryOutTwoTone } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Tooltip,
  Descriptions,
  Typography,
  Switch,
  message,
} from "antd";

import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";

const { Text } = Typography;

class ReplyToFerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handlePdfClick(data) {
    checkValue(data?.file)
      ? window.open(data?.file, "_blank")
      : message.error("No Document Found");
  }

  render() {
    const { replyToFerData, replyToFerType, customProfile, changeSwitch } =
      this.props;

    return (
      <div>
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title={replyToFerType}
              headStyle={{ background: "#e8e8e8" }}
              extra={
                customProfile && (
                  <div>
                    <Text strong>IP INDIA</Text>
                    <Switch
                      className="change-manager-type"
                      onChange={() => this.props.handleChangeSwitch()}
                      checked={changeSwitch}
                    />
                    <Text strong>CUSTOM</Text>
                  </div>
                )
              }
            >
              <Descriptions
                bordered
                size="small"
                layout="horizontal"
                column={1}
                className="description-table"
              >
                <Descriptions.Item label={<Text strong>TYPE</Text>}>
                  {checkValue(replyToFerData.application_type)
                    ? replyToFerData.application_type
                    : "N.A."}
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong>DOCUMENT DATE</Text>}>
                  <div className="max_h_100px vertical_scroll">
                    {replyToFerData.notice_document &&
                      replyToFerData.notice_document.length > 0 &&
                      replyToFerData.notice_document.map((doc, docKey) => (
                        <p key={docKey}>
                          {checkValue(doc.date) ? doc.date : "N.A."}
                        </p>
                      ))}

                    {replyToFerData.notice_document.length === 0 && (
                      <p className="m-0">N.A.</p>
                    )}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong>DOCUMENT</Text>}>
                  <div className="max_h_100px vertical_scroll">
                    {replyToFerData.notice_document &&
                      replyToFerData.notice_document.length > 0 &&
                      replyToFerData.notice_document.map((doc, docKey) => (
                        <p
                          key={docKey}
                          className="application-text"
                          onClick={() => this.handlePdfClick(doc)}
                        >
                          {checkValue(doc.name) ? doc.name : "N.A."}
                        </p>
                      ))}

                    {replyToFerData.notice_document.length === 0 && (
                      <p className="m-0">N.A.</p>
                    )}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong>TIME REMAINING</Text>}>
                  <div className="max_h_100px vertical_scroll">
                    {replyToFerData.notice_document &&
                      replyToFerData.notice_document.length > 0 &&
                      replyToFerData.notice_document.map((doc, docKey) => (
                        <p key={docKey}>
                          <span>{doc.remaining_days} Days</span>
                          <span
                            className={doc.alert ? "visible" : "not-visible"}
                          >
                            <Tooltip
                              title="Deadline to file reply for this mark has passed"
                              className="toolTip-custom"
                            >
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                                style={{
                                  color: "#e1001b",
                                  paddingLeft: "5px",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </Tooltip>
                          </span>
                        </p>
                      ))}

                    {replyToFerData.notice_document.length === 0 && (
                      <p className="m-0">N.A.</p>
                    )}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong>ACTION</Text>}>
                  <div>
                    <p
                      className="application-text"
                      onClick={() => this.props.replyFiled(replyToFerData)}
                    >
                      Reply Filed
                    </p>
                    <Tooltip placement="top" title={"Create To-do Task"}>
                      <p>
                        <CarryOutTwoTone
                          onClick={this.props.changeStateValue_L0}
                          style={{ cursor: "pointer", fontSize: "30px" }}
                        />
                      </p>
                    </Tooltip>
                    <Tooltip placement="top" title={"Send email"}>
                      <p onClick={() => this.props.handleEmail(replyToFerData)}>
                        <i
                          className="fa fa-paper-plane"
                          style={{
                            padding: "0px 5px",
                            color: "#1890ff",
                            fontSize: 20,
                          }}
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Tooltip>
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ReplyToFerTable;
