import React, { useEffect, useState } from "react";
import { Tooltip, notification } from "antd";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import { DeleteOutlined } from "@ant-design/icons";
import { TabContent } from "../../commonComponents/TabContent";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClientAttachmentData,
  getClientAttachmentListData,
} from "../../../../../redux/actions/commonActions/masterActions/clientAttachment";
import ClientAddAttachmentComponent from "./ClientAddAttachmentComponent";

export const LinkedAttachmentComponent = (props) => {
  const { parent, activeTab, key_value } = props;

  const [openAddForm, setOpenAddForm] = useState(false);
  const dispatch = useDispatch();
  const tabHeight = 720;

  const { data, loading, metadata } = useSelector(
    (state) => state.masters.clientMaster.clientAttachment,
  );
  const { count, pageNumber } = metadata;

  const getClientAttachmentData = () => {
    dispatch(getClientAttachmentListData(pageNumber, parent));
  };

  useEffect(() => {
    if (parent && activeTab === key_value) {
      getClientAttachmentData();
    }
  }, [parent, activeTab, dispatch]);

  const handleAddAttachment = () => {
    setOpenAddForm(true);
  };

  const handleAttachmentNextPage = (pageNumber) => {
    dispatch(getClientAttachmentListData(pageNumber, parent));
  };

  const handleDeleteAttachment = (clientAttachment) => {
    const body = {
      client_id: clientAttachment?.client_id ?? "",
      id: clientAttachment?.id ?? "",
    };
    dispatch(deleteClientAttachmentData(body, pageNumber));
  };

  const handleDownloadFile = async (e, item) => {
    e.preventDefault();
    try {
      const response = await fetch(item.file);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = item.file_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file", error);
    }
  };

  const renderColumns = [
    {
      className: "tm-protect-column-1",
      title: "S.No",
      width: 50,

      render: (text, record, index) => {
        return {
          children: (
            <div>
              <p>
                <span>{index + 1 + (pageNumber - 1) * 100}. </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "File Name",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.file_name) ? (
                    <a
                      href={record?.file ?? ""}
                      onClick={(e) => handleDownloadFile(e, record)}
                    >
                      {record?.file_name}{" "}
                    </a>
                  ) : (
                    "N.A."
                  )}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Modified Date & Time",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.modified_date)
                    ? record?.modified_date
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Actions",
      width: 80,
      render: (text, record) => {
        return (
          <div
            className="iconTable tmapplied_ll_actions"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              padding: "8px",
            }}
          >
            <Tooltip title="Delete Attachment">
              <DeleteOutlined
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => handleDeleteAttachment(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const propsForTabContent = {
    addButtonLabel: "Add Files",
    handleAddButton: handleAddAttachment,
    childrenType: "table",
    loading: loading,
    tablePageNumber: pageNumber,
    dataCount: count,
    handleTableNextPage: handleAttachmentNextPage,
    renderColumns: renderColumns,
    tableData: data,
    isSelectable: false,
    tableHeight: tabHeight - 210,
  };

  const filters = {
    client_id: parent ?? "",
  };

  const propsForAddNotesPage = {
    open: openAddForm,
    setOpen: setOpenAddForm,
    filters: filters,
  };

  return (
    <div
      style={{
        height: tabHeight,
        overflowY: "auto",
      }}
    >
      {openAddForm && (
        <ClientAddAttachmentComponent {...propsForAddNotesPage} />
      )}
      <TabContent {...propsForTabContent} />
    </div>
  );
};
