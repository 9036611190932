import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Link } from "react-router-dom";

import {
  CarryOutTwoTone,
  ClockCircleTwoTone,
  FileTwoTone,
} from "@ant-design/icons";

import { Row, Col, Typography, Card } from "antd";

//--------PROFILE ACTIONS--------
import {
  getDashboard,
  getDashboardAnalytics,
} from "../../../../redux/actions/managerActions/profile/actions";

//--------IP-SUITE CONSTANTS--------
import { LOADING } from "../../../../constants/commonConstants/generalConstants";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";

//--------PATENT COMPONENTS--------
import PatentRenewal from "../YourPatent/Renewal";
import PatentFER from "../YourPatent/Patent-FER";
import PendingWorkingOfPatent from "../YourPatent/PendingWorkingOfPatent";
import StatementOfUndertaking from "../YourPatent/StatementOfUndertaking";
import RequestForExamination from "../YourPatent/RequestForExamination";
import PatentAnalysis from "../YourPatent/Patent-Analysis";
import { logOut } from "../../../../redux/actions/commonActions/actions";

const { Title, Text } = Typography;

class PatentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let type = "patent";
    if (localStorage.getItem("token")) {
      if (this.props.patentDashboard === null) {
        this.props.getDashboard(type);
        this.props.getDashboardAnalytics(type);
      }
    } else {
      this.props.logOut();
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col span={24} className="alignR">
            <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
              *Information shown here is not more than{" "}
              {checkValue(this.props.patentDashboard)
                ? this.props.patentDashboard.patent.days_old
                : 0}{" "}
              day(s) old.
            </Text>
          </Col>
        </Row>

        {/* card-content */}
        <Row gutter={16} className="m-top-20">
          <Col className="gutter-row" span={8}>
            <Link to="/manager/patent/your-patent/total-marks">
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "100px",
                  padding: "15px",
                }}
                loading={
                  this.props.patentDashboardStatus === LOADING ? true : false
                }
              >
                <Row>
                  <Col span={4}>
                    <FileTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "14px" }}>
                      TOTAL APPLICATION
                    </Title>
                    <Text strong style={{ fontSize: "14px" }}>
                      {checkValue(this.props.patentDashboard)
                        ? this.props.patentDashboard.patent
                            .managed_patents_count
                        : 0}
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col className="gutter-row" span={8}>
            <Link to="/manager/patent/your-patent/reply-to-fer">
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "100px",
                  padding: "15px",
                }}
                loading={
                  this.props.patentDashboardStatus === LOADING ? true : false
                }
              >
                <Row>
                  <Col span={4}>
                    <ClockCircleTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "14px" }}>
                      REPLY TO FER
                    </Title>
                    <Text strong style={{ fontSize: "14px" }}>
                      {checkValue(this.props.patentDashboard)
                        ? this.props.patentDashboard.patent
                            .pending_reply_ip_india_count
                        : 0}
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col className="gutter-row" span={8}>
            <Link to="/manager/to-do-task">
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "100px",
                  padding: "15px",
                }}
                loading={
                  this.props.patentDashboardStatus === LOADING ? true : false
                }
              >
                <Row>
                  <Col span={4}>
                    <CarryOutTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "16px" }}>
                      TO DO TASK
                    </Title>
                    <Text strong style={{ fontSize: "14px" }}>
                      {checkValue(this.props.patentDashboard)
                        ? this.props.patentDashboard.patent.todo_count
                        : 0}
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        </Row>
        {/* card-content */}

        {/* upcoming renewals */}
        <Row className="m-top-20">
          <Col span={24}>
            <PatentRenewal />
          </Col>
        </Row>
        {/* upcoming renewals */}

        {/* reply to fer */}
        <Row className="m-top-20">
          <Col span={24}>
            <PatentFER />
          </Col>
        </Row>
        {/* reply to fer */}

        {/* Pending Working Of Patent */}
        <Row className="m-top-20">
          <Col span={24}>
            <PendingWorkingOfPatent />
          </Col>
        </Row>
        {/* Pending Working Of Patent */}

        {/* Statement Of Undertaking */}
        <Row className="m-top-20">
          <Col span={24}>
            <StatementOfUndertaking />
          </Col>
        </Row>
        {/* Statement Of Undertaking */}

        {/* Request For Examination */}
        <Row className="m-top-20">
          <Col span={24}>
            <RequestForExamination />
          </Col>
        </Row>
        {/* Request For Examination */}

        {/* reply to fer */}
        <Row className="m-top-20">
          <Col span={24}>
            <PatentAnalysis />
          </Col>
        </Row>
        {/* reply to fer */}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getDashboard,
  getDashboardAnalytics,
  logOut,
};

function mapStateToProps(state) {
  const { patentDashboard, patentDashboardStatus } =
    state.managerState.profileState;
  return deepFreeze({
    patentDashboard,
    patentDashboardStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(PatentDashboard);
