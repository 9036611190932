import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import {
  Row,
  Col,
  Drawer,
  Input,
  Alert,
  Button,
  Typography,
  Checkbox,
  notification,
} from "antd";
import {
  createReport,
  getReport,
} from "../../../redux/actions/searchActions/ReportAction";
import { LOADING } from "../../../constants/commonConstants/generalConstants";
import {
  Report_defaultPrimarySelectedFilters,
  Report_defaultPrimarySorting,
} from "../../../constants/searchConstants/reportConstants";

const { Title, Text } = Typography;

class CreateReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customReportData: this.props.customReportData,
    };
  }

  selectReportType(type, checked) {
    if (checked) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          report_file_types: [
            ...this.state.customReportData.report_file_types,
            type,
          ],
        },
      });
    } else {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          report_file_types:
            this.state.customReportData.report_file_types.filter(
              (items) => items !== type,
            ),
        },
      });
    }
  }

  async handleCreateReportConfirmation() {
    const { customReportData } = this.state;
    let markId = customReportData.id;
    let filter_data = {};
    let sort_data = {};

    if (
      this.state.customReportData.report_file_types &&
      this.state.customReportData.report_file_types.length === 0
    ) {
      notification["warning"]({
        message: "No Format Selected",
        description: "At least 1 report format must be selected.",
      });
      return;
    }
    if (customReportData.data[`${markId}`].filter_flag) {
      filter_data = {
        filter_class: customReportData.data[`${markId}`].filters.filterClasses,
        filter_state: customReportData.data[`${markId}`].filters.filterState,
        filter_office: customReportData.data[`${markId}`].filters.filterOffice,
        filter_trademark_category:
          customReportData.data[`${markId}`].filters.filterCategory,
        filter_trademark_type:
          customReportData.data[`${markId}`].filters.filterType,
        filter_trademark_status:
          customReportData.data[`${markId}`].filters.filterStatus,
        filter_proprietor:
          customReportData.data[`${markId}`].filters.filterProprietor,
        filter_application_date:
          customReportData.data[`${markId}`].filters.date_of_application,
        filter_date_of_usage:
          customReportData.data[`${markId}`].filters.date_of_usage,
        filter_date_of_usage_proposed:
          customReportData.data[`${markId}`].filters.date_of_usage_proposed,
        filter_contains_term:
          customReportData.data[`${markId}`].filters.containsTerm,
      };
    } else {
      filter_data = {};
    }

    if (customReportData.data[`${markId}`].sort_flag) {
      sort_data = {
        sort_type: customReportData.data[`${markId}`].sort.sortType,
        sort_on: customReportData.data[`${markId}`].sort.sortOn,
      };
    } else {
      sort_data = {};
    }

    await this.props.createReport({
      id: customReportData.id,
      report_source: customReportData.report_source,
      operation: customReportData.operation,
      report_file: customReportData.report_file,
      report_file_name: customReportData.report_file_name,
      report_file_types: customReportData.report_file_types,
      select_all: customReportData.data[`${markId}`].select_all,
      selected_mark_id_list: customReportData.data[`${markId}`].selectedMark,
      sort_flag: customReportData.data[`${markId}`].sort_flag,
      sort: sort_data,
      filter_flag: customReportData.data[`${markId}`].filter_flag,
      filters: filter_data,
    });
    if (customReportData.operation === "REALTIME") {
      this.props.getReport({
        report_source: customReportData.report_source,
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: false,
        sorting: Report_defaultPrimarySorting,
        check: false,
        files: false,
        pageNumber: 1,
      });
    }
    if (this.props?.createSearchReportStatus) {
      notification[this.props?.createSearchReportStatus?.toLowerCase()]({
        message: "Create Report for download",
        description:
          "Your Report is being generated. You can visit the Reports section to access the report.",
      });
    }
    this.props.onCloseDrawer();
  }

  render() {
    const { createSearchReportStatus } = this.props;
    return (
      <div>
        <Drawer
          title={<h3>Custom Report Details</h3>}
          placement="right"
          width={400}
          closable={false}
          open={this.props.visible}
          onClose={() => this.props.onCloseDrawer()}
        >
          <div>
            <Row>
              <Col span={24}>
                Enter the name of your report:
                <br />
                <Input
                  onChange={(event) => {
                    if (
                      /^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)
                    ) {
                      this.setState({
                        customReportData: {
                          ...this.state.customReportData,
                          report_file_name: event.target.value,
                        },
                      });
                    }
                  }}
                  value={this.state.customReportData.report_file_name}
                  placeholder="Report's name (Optional)"
                />
              </Col>
              <Col span={24} className="m-top-20">
                Select your preferred report format:
                <br />
                {!this.props.disablePDF && (
                  <>
                    <Checkbox
                      checked={this.state.customReportData.report_file_types.includes(
                        "PDF",
                      )}
                      onClick={(event) =>
                        this.selectReportType("PDF", event.target.checked)
                      }
                    >
                      PDF
                    </Checkbox>
                    <br />
                  </>
                )}
                <Checkbox
                  checked={this.state.customReportData.report_file_types.includes(
                    "EXCEL",
                  )}
                  onClick={(event) =>
                    this.selectReportType("EXCEL", event.target.checked)
                  }
                >
                  EXCEL
                </Checkbox>
              </Col>
              <Col span={24} className="m-top-20">
                <Alert
                  message="Note: Once the report is created, it cannot be changed."
                  type="warning"
                />
              </Col>
              <Col span={24} className="m-top-20">
                <Button
                  ghost
                  type="primary"
                  onClick={() => this.handleCreateReportConfirmation()}
                  loading={createSearchReportStatus === LOADING}
                  style={{ marginRight: "10px" }}
                >
                  Confirm
                </Button>
                <Button ghost danger onClick={() => this.props.onCloseDrawer()}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapDispatchToProps = {
  createReport,
  getReport,
};

function mapStateToProps(state) {
  const { createSearchReport, createSearchReportStatus } =
    state.searchState.report;
  return deepFreeze({
    createSearchReport,
    createSearchReportStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport);
