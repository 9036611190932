import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

import {
  W_TEMPLATE,
  W_CHANGE_TEMPLATE_ID,
  W_CREATE_TEMPLATE,
  W_UPDATE_TEMPLATE,
} from "../../actions/watchActions/types";
import { finalReduxCachedState } from "../../../functions/commonFunctions/finalCachedState";

export const defaultTemplates = {
  USER: {
    data: [],
    metadata: {
      count: 0,
    },
    pageNumber: 1,
    loading: false,
  },
  RECOMMENDED: {
    data: [],
    metadata: {
      count: 0,
    },
    pageNumber: 1,
    loading: false,
  },
  current_template_id: 0,
  template_status: {
    loading: false,
  },
};

const newDefaultConfig = {
  list_layout: {
    image_label: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    image_text: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    image_text_new: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    tm_protect: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    tm_applied: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    journals: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    brand_compare: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    image_similarity: {
      config: defaultTemplates,
      isComponentRendered: false,
    },
  },
  grid_layout: {
    image_label: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    image_text: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    image_text_new: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    tm_protect: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    tm_applied: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    journals: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    brand_compare: {
      config: defaultTemplates,
      isTemplateRendered: false,
    },
    image_similarity: {
      config: defaultTemplates,
      isComponentRendered: false,
    },
  },
};

export default function (state = newDefaultConfig, action) {
  switch (action.type) {
    case W_TEMPLATE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      switch (action.status) {
        case SUCCESS: {
          const newSuccessState = {
            [action.template_type]: {
              loading: false,
              data: action.payload.data,
              metadata: action.payload.metadata,
              pageNumber: action.pageNumber ?? 1,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newSuccessState,
            "isTemplateRendered",
            true,
          );
        }
        case LOADING: {
          const newLoadingState = {
            [action.template_type]: {
              loading: true,
            },
          };

          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isTemplateRendered",
            false,
          );
        }
        case ERROR: {
          const newErrorState = {
            [action.template_type]: {
              loading: false,
            },
          };

          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isTemplateRendered",
            false,
          );
        }
        default:
          return state;
      }
    }
    case W_CHANGE_TEMPLATE_ID: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        current_template_id: action.template_id,
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isTemplateRendered",
        true,
      );
    }
    case W_CREATE_TEMPLATE:
    case W_UPDATE_TEMPLATE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      switch (action.status) {
        case SUCCESS: {
          const newSuccessState = {
            template_status: {
              loading: false,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newSuccessState,
            "isTemplateRendered",
            true,
          );
        }
        case LOADING: {
          const newLoadingState = {
            template_status: {
              loading: true,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isTemplateRendered",
            false,
          );
        }
        case ERROR: {
          const newErrorState = {
            template_status: {
              loading: false,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isTemplateRendered",
            false,
          );
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
