import {
  SET_NOTES_STORE,
  CREATE_CLIENT_NOTES,
  CASCADER_STATE,
  DELETE_ALL_NOTES,
  MANAGE_NOTES,
  NOTES_CLASS_FILTER,
  NOTES_PROPRIETOR_FILTER,
  NOTES_TAG_FILTER,
  NOTES_APPLICANT_FILTER,
  NOTES_INVENTOR_FILTER,
  NOTES,
  DELETE_NOTES,
} from "./types";
import { MGR_RT_URL } from "../../../../configs/rootConfigs";
import Axios from "axios";
import { checkAuthorisation } from "../../commonActions/actions";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../../constants/commonConstants/generalConstants";
import React from "react";
import { Modal } from "antd";
import { NetworkError } from "../../../../components/commonComponents/Error-Pages/Error";
import * as Sentry from "@sentry/browser";

export function notesDashboard({
  filterFlag,
  filters,
  pageNumber,
  subscription,
  filters_required_flag,
  required_filter,
  filter_contains_term,
  mmType,
}) {
  const classes = `${filters?.trademarkClass}`;
  const proprietors = `${filters?.trademarkProprietors}`;
  const tags = `${filters?.trademarkTags}`;
  const contains_term = `${filters?.containsTerm}`;
  const no_Tags = `${filters?.trademarkNOtags}`;
  const applicant = `${filters?.patentApplicants}`;
  const inventor = `${filters?.patentInventors}`;

  return async (dispatch) => {
    if (filters_required_flag && required_filter === "CLASSES") {
      dispatch({ type: NOTES_CLASS_FILTER, status: LOADING });
      try {
        let response;
        response = await Axios.get(`${MGR_RT_URL}/notes_dashboard/`, {
          params: {
            subscription: subscription,
            filter_flag: filterFlag ? filterFlag : null,
            classes: filterFlag ? classes : null,
            proprietor: filterFlag ? proprietors : null,
            tags: filterFlag ? (no_Tags?.length > 0 ? null : tags) : null,
            no_tags: no_Tags?.length > 0 ? true : null,
            contains_term: filterFlag ? contains_term : null,
            page: pageNumber,
            filters_required_flag,
            required_filter,
            filter_contains_term,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: NOTES_CLASS_FILTER,
          status: SUCCESS,
          payload: response?.data,
          lastAppliedFilters: {
            ...filters,
            filterFlag,
          },
          pageNumber,
        });
      } catch (err) {
        dispatch({ type: NOTES_CLASS_FILTER, status: ERROR });
      }
    } else if (filters_required_flag && required_filter === "PROPRIETOR") {
      dispatch({ type: NOTES_PROPRIETOR_FILTER, status: LOADING });
      try {
        let response;
        response = await Axios.get(`${MGR_RT_URL}/notes_dashboard/`, {
          params: {
            subscription: subscription,
            filter_flag: filterFlag ? filterFlag : null,
            classes: filterFlag ? classes : null,
            proprietor: filterFlag ? proprietors : null,
            tags: filterFlag ? (no_Tags ? null : tags) : null,
            no_tags: no_Tags ? true : null,
            contains_term: filterFlag ? contains_term : null,
            page: pageNumber,
            filters_required_flag,
            required_filter,
            filter_contains_term,
            page_size: 100,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: NOTES_PROPRIETOR_FILTER,
          status: SUCCESS,
          payload: response?.data,
          lastAppliedFilters: {
            ...filters,
            filterFlag,
          },
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({ type: NOTES_PROPRIETOR_FILTER, status: ERROR });
      }
    } else if (filters_required_flag && required_filter === "TAG") {
      dispatch({ type: NOTES_TAG_FILTER, status: LOADING, mmType: mmType });
      try {
        let response;
        response = await Axios.get(`${MGR_RT_URL}/notes_dashboard/`, {
          params: {
            subscription: subscription,
            filter_flag: filterFlag ? filterFlag : null,
            classes: filterFlag ? classes : null,
            proprietor: filterFlag ? proprietors : null,
            tags: filterFlag ? (no_Tags ? null : tags) : null,
            no_tags: no_Tags ? true : null,
            contains_term: filterFlag ? contains_term : null,
            page: pageNumber,
            filters_required_flag,
            required_filter,
            filter_contains_term,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: NOTES_TAG_FILTER,
          status: SUCCESS,
          payload: response?.data,
          mmType: mmType,
          lastAppliedFilters: {
            ...filters,
            filterFlag,
          },
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({ type: NOTES_TAG_FILTER, status: ERROR });
      }
    } else if (filters_required_flag && required_filter === "APPLICANT") {
      dispatch({ type: NOTES_APPLICANT_FILTER, status: LOADING });
      try {
        let response;
        response = await Axios.get(`${MGR_RT_URL}/notes_dashboard/`, {
          params: {
            subscription: subscription,
            filter_flag: filterFlag ? filterFlag : null,
            classes: filterFlag ? classes : null,
            proprietor: filterFlag ? proprietors : null,
            tags: filterFlag ? (no_Tags ? null : tags) : null,
            no_tags: no_Tags ? true : null,
            contains_term: filterFlag ? contains_term : null,
            page: pageNumber,
            filters_required_flag,
            required_filter,
            filter_contains_term,
            page_size: 100,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: NOTES_APPLICANT_FILTER,
          status: SUCCESS,
          payload: response?.data,
          lastAppliedFilters: {
            ...filters,
            filterFlag,
          },
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({ type: NOTES_APPLICANT_FILTER, status: ERROR });
      }
    } else if (filters_required_flag && required_filter === "INVENTOR") {
      dispatch({ type: NOTES_INVENTOR_FILTER, status: LOADING });
      try {
        let response;
        response = await Axios.get(`${MGR_RT_URL}/notes_dashboard/`, {
          params: {
            subscription: subscription,
            filter_flag: filterFlag ? filterFlag : null,
            classes: filterFlag ? classes : null,
            proprietor: filterFlag ? proprietors : null,
            tags: filterFlag ? (no_Tags ? null : tags) : null,
            no_tags: no_Tags ? true : null,
            contains_term: filterFlag ? contains_term : null,
            page: pageNumber,
            filters_required_flag,
            required_filter,
            filter_contains_term,
            page_size: 100,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: NOTES_INVENTOR_FILTER,
          status: SUCCESS,
          payload: response?.data,
          lastAppliedFilters: {
            ...filters,
            filterFlag,
          },
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({ type: NOTES_INVENTOR_FILTER, status: ERROR });
      }
    } else {
      dispatch({ type: MANAGE_NOTES, status: LOADING });
      try {
        let response;
        response = await Axios.get(`${MGR_RT_URL}/notes_dashboard/`, {
          params: {
            subscription: subscription,
            filter_flag: filterFlag ? filterFlag : null,
            classes: filterFlag ? classes : null,
            proprietor: filterFlag ? proprietors : null,
            tags: filterFlag ? (no_Tags ? null : tags) : null,
            no_tags: no_Tags ? true : null,
            contains_term: filterFlag ? contains_term : null,
            page: pageNumber,
            inventor: filterFlag ? inventor : null,
            applicant: filterFlag ? applicant : null,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: MANAGE_NOTES,
          status: SUCCESS,
          payload: response?.data,
          lastAppliedFilters: {
            ...filters,
            filterFlag,
          },
          pageNumber,
        });
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: MANAGE_NOTES, status: ERROR });
      }
    }
  };
}

// ********************************************GET NOTES*******************************************//
export function getNotes({
  subscription,
  pageNumber,
  application_number,
  application_no_storage,
}) {
  return async (dispatch) => {
    dispatch({
      type: NOTES,
      status: LOADING,
      application_no: application_number,
    });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/notes/`, {
        params: {
          subscription: subscription,
          application_number: application_number,
        },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: NOTES,
        status: SUCCESS,

        payload: response?.data,
        application_no: application_number,
        application_no_storage: application_no_storage,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: NOTES, status: ERROR });
    }
  };
}

// *****************************************************ADD NOTES**************************************//
export function addNotes({
  actionType,
  note_for,
  application_number,
  email,
  note_data,
  subscription,
}) {
  const note = note_data
    .replace(/<p>|<\/p>/g, "")
    .replace(/(?:\r\n|\r|\n)/g, "");

  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/notes/`,
        { note_for, application_number, email, note, subscription },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        }
      );
      dispatch({
        type: actionType,
        status: SUCCESS,

        payload: response?.data,
        application_no: application_number,
        message: "Note Added Successfully !",
      });
    } catch (err) {
      if (err.response.status === 400) {
        dispatch({
          type: actionType,
          status: ERROR,
          message: err.response?.data.error,
        });
      } else {
        checkAuthorisation(dispatch, err);
        dispatch({ type: actionType, status: ERROR });
      }
    }
  };
}

// ***************************************************************EDIT NOTES*******************************************//
export function editNotes({
  actionType,
  note_for,
  application_number,
  email,
  note_id,
  note,
  subscription,
}) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.patch(
        `${MGR_RT_URL}/notes/`,
        { note_for, application_number, email, note_id, note, subscription },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        }
      );
      dispatch({
        type: actionType,
        status: SUCCESS,

        payload: response?.data,
        application_no: application_number,
        message: "Note Updated Successfully !",
      });
    } catch (err) {
      if (err.response.status === 400) {
        dispatch({
          type: actionType,
          status: ERROR,
          message: err.response?.data.error,
        });
      } else {
        checkAuthorisation(dispatch, err);
        dispatch({ type: actionType, status: ERROR });
      }
    }
  };
}

// ***********************************************************DELETE NOTES***********************************************//

export function deleteNotes({
  note_for,
  application_number,
  note_id,
  delete_all,
  subscription,
  delete_object,
}) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_NOTES,
      status: LOADING,
      application_no: application_number,
    });
    try {
      let response;
      response = await Axios.delete(`${MGR_RT_URL}/notes/`, {
        data: {
          note_for,
          application_number,
          note_id,
          delete_all,
          subscription,
        },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: DELETE_NOTES,
        status: SUCCESS,

        payload: response?.data,
        noteID: note_id,
        application_no: application_number,
        deleteAll: delete_all,
        message: " Note Deleted Successfully !",
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: DELETE_NOTES,
        status: ERROR,
        message: `Unfortunately, an error occured while Deleting ${
          delete_all ? "All" : ""
        } Note. Please, try again later.!`,
      });
    }
  };
}

export function deleteAllNotes({ note_for, subscription, delete_object }) {
  let select_notes = [];
  Object.keys(delete_object).map(function (key, index) {
    select_notes.push(delete_object[key]);
  });
  return async (dispatch) => {
    dispatch({ type: DELETE_ALL_NOTES, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${MGR_RT_URL}/notes/`, {
        data: { note_for, subscription, select_notes },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: DELETE_ALL_NOTES,
        status: SUCCESS,

        payload: select_notes,
        message: "Selected Notes Deleted Successfully !",
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: DELETE_ALL_NOTES,
        status: ERROR,
        message: `Unfortunately, an error occured while Deleting  All Notes. Please, try again later.!`,
      });
    }
  };
}

// **********************************notes Report *******************************//
export function downloadNotesReport({
  selectAll,
  filterFlag,
  filters,
  report_columns,
  report_name,
  mm_type,
  type,
  selected_marks,
  selected_patents,
  custom_trademark,
  international_trademark,
  custom_patent,
  international_patent,
}) {
  return async (dispatch) => {
    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/report/`,
        {
          notes_flag: true,
          custom_trademark: custom_trademark,
          international_trademark: international_trademark,
          custom_patent: custom_patent,
          international_patent: international_patent,
          select_all: selectAll,
          selected_marks: selected_marks,
          selected_patents: selected_patents,
          filter_flag: filterFlag,
          filters: {
            ...filters,
          },
          report_columns,
          report_name,
          type,
          report_type: "EXCEL",
        },
        { headers: { Authorization: "Token " + localStorage.getItem("token") } }
      );
    } catch (err) {
      checkAuthorisation(dispatch, err);
    }
  };
}

// *************************************Casecader State**************************************//
export function caseCaderState({ subscription, mmType, defaultValue }) {
  return (dispatch) => {
    dispatch({ type: CASCADER_STATE, status: LOADING });
    try {
      dispatch({
        type: CASCADER_STATE,
        status: SUCCESS,
        payload: subscription,
        mm_type: mmType,
        defaultValue: defaultValue,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
    }
  };
}

// **************************************************NOTES BULK UPDATE ************************************//
//--------Manager: Set State Client Notes --------
export function setStoreData(newState) {
  return (dispatch) => {
    dispatch({ type: SET_NOTES_STORE, payload: newState });
  };
}

export function clientNotesUpload({ uploaded_file, subscription }) {
  let formData = new FormData();
  formData.append("subscription", subscription);
  if (uploaded_file && uploaded_file !== undefined) {
    formData.append("file", uploaded_file);
  }

  return async (dispatch) => {
    dispatch({ type: CREATE_CLIENT_NOTES, status: LOADING });
    try {
      let response;
      response = await Axios.post(`${MGR_RT_URL}/notes_dashboard/`, formData, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: CREATE_CLIENT_NOTES,
        status: SUCCESS,
        payload: response?.data,
      });
      return SUCCESS;
    } catch (err) {
      if (!err.response) {
        Modal.error({
          width: 600,
          className: "error-modal",
          content: <NetworkError />,
          okText: "Close",
          onOk() {},
        });
      } else {
        switch (err.response.status) {
          case 400:
            dispatch({
              type: CREATE_CLIENT_NOTES,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          case 403:
            checkAuthorisation(dispatch, err);
            dispatch({
              type: CREATE_CLIENT_NOTES,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          default:
            dispatch({
              type: CREATE_CLIENT_NOTES,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;
        }
      }
    }
  };
}
