import { ERROR, LOADING, SUCCESS } from '../../../constants/commonConstants/generalConstants';
import { GET_TM_NOTES, GET_CTM_NOTES, GET_ITM_NOTES, GET_P_NOTES, GET_CP_NOTES, GET_IP_NOTES,
    POST_TM_NOTES, POST_CTM_NOTES, POST_ITM_NOTES, POST_P_NOTES, POST_CP_NOTES, POST_IP_NOTES,
    PATCH_TM_NOTES, PATCH_CTM_NOTES, PATCH_ITM_NOTES, PATCH_P_NOTES, PATCH_CP_NOTES, PATCH_IP_NOTES,
    DELETE_TM_NOTES, DELETE_CTM_NOTES, DELETE_ITM_NOTES, DELETE_P_NOTES, DELETE_CP_NOTES, DELETE_IP_NOTES,
} from '../../actions/managerActions/commonAction/types';

const defaultState = {
    manageNotes: null,
    manageNotesStatus: null,
    manageNotesMessage: '',
    addManageNotesStatus: null,
    editManageNotesStatus: null,
    deleteManageNotesStatus: null,
    deleteAllManageNotesStatus: null
};

export default function(state = defaultState, action){
    switch (action.type) {
        case GET_TM_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
            }

        case POST_TM_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                addManageNotesStatus: action.status,
            }

        case PATCH_TM_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                editManageNotesStatus: action.status,
            }

        case DELETE_TM_NOTES:
            if (action.deleteAll) {
                return {
                    ...state,
                    manageNotes: action.payload,
                    manageNotesStatus: action.status,
                    manageNotesMessage: action.message,
                    deleteAllManageNotesStatus: action.status,
                }
            } else {
                switch (action.status) {
                    case LOADING:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: LOADING,
                        }
                        
                    case SUCCESS:
                        return {
                            ...state,
                            manageNotes: {
                                ...state.manageNotes,
                                notes: state.manageNotes.notes.filter(eachData => eachData.id !== action.noteID)
                            },
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: SUCCESS,
                        }

                    case ERROR:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: ERROR,
                        }
                
                    default:
                        break;
                }
            }
            break;

        case GET_CTM_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
            }

        case POST_CTM_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                addManageNotesStatus: action.status,
            }

        case PATCH_CTM_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                editManageNotesStatus: action.status,
            }

        case DELETE_CTM_NOTES:
            if (action.deleteAll) {
                return {
                    ...state,
                    manageNotes: action.payload,
                    manageNotesStatus: action.status,
                    manageNotesMessage: action.message,
                    deleteAllManageNotesStatus: action.status,
                }
            } else {
                switch (action.status) {
                    case LOADING:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: LOADING,
                        }
                        
                    case SUCCESS:
                        return {
                            ...state,
                            manageNotes: {
                                ...state.manageNotes,
                                notes: state.manageNotes.notes.filter(eachData => eachData.id !== action.noteID)
                            },
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: SUCCESS,
                        }

                    case ERROR:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: ERROR,
                        }
                
                    default:
                        break;
                }
            }
            break;

        case GET_ITM_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
            }

        case POST_ITM_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                addManageNotesStatus: action.status,
            }

        case PATCH_ITM_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                editManageNotesStatus: action.status,
            }

        case DELETE_ITM_NOTES:
            if (action.deleteAll) {
                return {
                    ...state,
                    manageNotes: action.payload,
                    manageNotesStatus: action.status,
                    manageNotesMessage: action.message,
                    deleteAllManageNotesStatus: action.status,
                }
            } else {
                switch (action.status) {
                    case LOADING:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: LOADING,
                        }
                        
                    case SUCCESS:
                        return {
                            ...state,
                            manageNotes: {
                                ...state.manageNotes,
                                notes: state.manageNotes.notes.filter(eachData => eachData.id !== action.noteID)
                            },
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: SUCCESS,
                        }

                    case ERROR:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: ERROR,
                        }
                
                    default:
                        break;
                }
            }
            break;


        case GET_P_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
            }

        case POST_P_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                addManageNotesStatus: action.status,
            }

        case PATCH_P_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                editManageNotesStatus: action.status,
            }

        case DELETE_P_NOTES:
            if (action.deleteAll) {
                return {
                    ...state,
                    manageNotes: action.payload,
                    manageNotesStatus: action.status,
                    manageNotesMessage: action.message,
                    deleteAllManageNotesStatus: action.status,
                }
            } else {
                switch (action.status) {
                    case LOADING:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: LOADING,
                        }
                        
                    case SUCCESS:
                        return {
                            ...state,
                            manageNotes: {
                                ...state.manageNotes,
                                notes: state.manageNotes.notes.filter(eachData => eachData.id !== action.noteID)
                            },
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: SUCCESS,
                        }

                    case ERROR:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: ERROR,
                        }
                
                    default:
                        break;
                }
            }
            break;


        case GET_CP_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
            }
            

        case POST_CP_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                addManageNotesStatus: action.status,
            }

        case PATCH_CP_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                editManageNotesStatus: action.status,
            }

        case DELETE_CP_NOTES:
            if (action.deleteAll) {
                return {
                    ...state,
                    manageNotes: action.payload,
                    manageNotesStatus: action.status,
                    manageNotesMessage: action.message,
                    deleteAllManageNotesStatus: action.status,
                }
            } else {
                switch (action.status) {
                    case LOADING:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: LOADING,
                        }
                        
                    case SUCCESS:
                        return {
                            ...state,
                            manageNotes: {
                                ...state.manageNotes,
                                notes: state.manageNotes.notes.filter(eachData => eachData.id !== action.noteID)
                            },
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: SUCCESS,
                        }

                    case ERROR:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: ERROR,
                        }
                
                    default:
                        break;
                }
            }
            break;


        case GET_IP_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
            }

        case POST_IP_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                addManageNotesStatus: action.status,
            }

        case PATCH_IP_NOTES:
            return {
                ...state,
                manageNotes: action.payload,
                manageNotesStatus: action.status,
                manageNotesMessage: action.message,
                editManageNotesStatus: action.status,
            }

        case DELETE_IP_NOTES:
            if (action.deleteAll) {
                return {
                    ...state,
                    manageNotes: action.payload,
                    manageNotesStatus: action.status,
                    manageNotesMessage: action.message,
                    deleteAllManageNotesStatus: action.status,
                }
            } else {
                switch (action.status) {
                    case LOADING:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: LOADING,
                        }
                        
                    case SUCCESS:
                        return {
                            ...state,
                            manageNotes: {
                                ...state.manageNotes,
                                notes: state.manageNotes.notes.filter(eachData => eachData.id !== action.noteID)
                            },
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: SUCCESS,
                        }

                    case ERROR:
                        return {
                            ...state,
                            manageNotesMessage: action.message,
                            deleteManageNotesStatus: ERROR,
                        }
                
                    default:
                        break;
                }
            }
            break;


        default:
            return state;
    }
}