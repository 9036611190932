import React, { useEffect, useState } from "react";
import { Col, Row, Table, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const TabContent = ({
  header,
  addButtonLabel,
  handleAddButton,
  childrenType = "table",
  customComponent,
  loading,
  tablePageNumber = 1,
  dataCount = 0,
  handleTableNextPage,
  tableRowSelection,
  renderColumns,
  tableData,
  isSelectable = false,
  tableHeight = 100,
}) => {
  const finalChildComponent =
    childrenType === "table" ? (
      <Table
        rowKey={(record) => record.id}
        className="tm-protect-table"
        bordered
        dataSource={tableData}
        columns={renderColumns}
        scroll={{ y: tableHeight }}
        // scroll={{ y: "calc(100vh-1000px)" }}
        loading={loading}
        rowSelection={isSelectable ? tableRowSelection : undefined}
        pagination={{
          pageSize: 100,
          showQuickJumper: true,
          showSizeChanger: false,
          defaultCurrent: 1,
          current: tablePageNumber,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          total: dataCount,
          onChange: handleTableNextPage,
        }}
      />
    ) : (
      customComponent
    );

  return (
    <div>
      <Row
        style={{
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col xs={18} lg={16} md={12}>
          {header && <Title level={4}>{header}</Title>}
        </Col>
        <Col
          xs={6}
          lg={8}
          md={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {addButtonLabel && (
            <Button
              type="link"
              icon={<PlusOutlined />}
              style={{
                margin: "0 15px",
                border: "1px solid #1677ff",
              }}
              onClick={handleAddButton}
            >
              {addButtonLabel}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={24} style={{ padding: "16px" }}>
          {finalChildComponent}
        </Col>
      </Row>
    </div>
  );
};
