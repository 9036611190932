import React, { useState, useEffect } from "react";
import { Dropdown, Button, Space, Radio, Row, Col, Input } from "antd";
import {
  DownOutlined,
  FolderAddOutlined,
  FileTextOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const SaveTemplateButton = (props) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [value, setValue] = useState(1);
  const {
    fileName,
    setFileName,
    template_status,
    filterSelected = false,
  } = props;

  useEffect(() => {
    let selectedData = props.USER?.data?.find(
      (item) => item.value === props.current_template_id,
    );
    selectedData?.name && value === 2 && setFileName(selectedData?.name);
  }, [openFilter, value, props.USER?.data, props.current_template_id]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleInputChange = (event) => {
    setFileName(event.target.value);
  };

  const dropdownMenu = () => (
    <React.Fragment>
      <Row style={{ padding: "10px" }}>
        <Col span={24} style={{ paddingBottom: "10px", width: "60px" }}>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={1}>Save New</Radio>
              {props.tabValue === "1" && (
                <Radio disabled={!props.current_template_id} value={2}>
                  {props.current_template_id
                    ? `Update`
                    : `Update (Please use template first)`}
                </Radio>
              )}
            </Space>
          </Radio.Group>
        </Col>
        <Col span={24}>
          <Space.Compact
            style={{
              width: "100%",
            }}
          >
            <Input
              placeholder="Enter Name"
              value={fileName}
              onChange={handleInputChange}
              prefix={<FileTextOutlined />}
            />
            <Button
              type="primary"
              disabled={!fileName.length}
              loading={template_status.loading}
              onClick={() =>
                props.handleTemplateSave(fileName, value, setFileName)
              }
            >
              <SaveOutlined />
            </Button>
          </Space.Compact>
        </Col>
      </Row>
    </React.Fragment>
  );

  return (
    <Dropdown
      dropdownRender={() => dropdownMenu()}
      overlayClassName="Filters-Menu"
      open={openFilter}
      onOpenChange={(event) => {
        setOpenFilter(event);
      }}
      disabled={!filterSelected}
    >
      <Button type="primary" ghost icon={<FolderAddOutlined />}>
        Save Template
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default SaveTemplateButton;
