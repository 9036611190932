//--------LIST LAYOUT TYPES--------
import { BC_UPLOAD_DATA } from "../../../actions/watchActions/types";

//--------COMMON IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../../constants/commonConstants/generalConstants";

const defaultListLayoutState = {
  tableData: [],
  count: null,
  pageNumber: 1,
  status: null,
};

export default function (state = defaultListLayoutState, action) {
  switch (action.type) {
    case BC_UPLOAD_DATA:
      switch (action.status) {
        case SUCCESS:
          if (action.pageNumber > 1) {
            return {
              ...state,
              tableData: action.payload.data,
              count: action.payload.metadata.count,
              pageNumber: action.pageNumber,
              status: action.status,
            };
          } else {
            return {
              ...state,
              tableData: action.payload.data,
              count: action.payload.metadata.count,
              pageNumber: action.pageNumber,
              status: action.status,
            };
          }

        case LOADING:
          return {
            ...(action.journalChange ? defaultListLayoutState : state),
            status: action.status,
          };

        case ERROR:
          return {
            ...state,
            status: action.status,
            count: 0,
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
