//--------ACTIONS STATUSES--------
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const PROGRESSING = "PROGRESSING";
export const WARNING = "WARNING";

//--------FILE FORMATS--------
export const PDF = "pdf";
export const EXCEL = "excel";
export const BI_EXCEL = "excel_bifurcated";
//--------IP-SUITE PRODUCTS--------
export const SEARCH = "tm-search";
export const WATCH = "tm-watch";
export const MANAGER = "tm-manager";

//--------DATE FORMATION FUNCTION USES THIS CONSTANT--------
export const MONTHS_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const SHORT_MONTHS_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

//--------Notification Default Selected Filters--------
export const Notification_DefaultSelectedFilters = {
  notificationFields: [],
  proprietor: [],
  opponent: [],
  applicant: [],
  inventor: [],
  status: [],
  date_of_notification_startValue: null,
  date_of_notification_endValue: null,
  date_of_notification: "",
  containsTerm: "",
};

//--------Notification Default Search Filters--------
export const Notification_DefaultSearchFilters = {
  fieldSearch: "",
  proprietorSearch: "",
  opponentSearch: "",
  applicantSearch: "",
  inventorSearch: "",
  statusSearch: "",
};

//--------Expire cookie date--------
export const expireCookie = "Thu, 01 Jan 1970 00:00:00 UTC";

//---Exam Report filters---

export const Exam_Report_DefaultSelectedFilters = {
  YOUR_CLASSES: [],
  YOUR_PROPRIETOR: [],
  YOUR_TRADEMARK_TYPE: [],
  THREAT_PROPRIETOR: [],
  THREAT_TRADEMARK_TYPE: [],
  THREAT_CLASSES: [],
  contains_term: "",
  date_of_examination: {
    startValue: "",
    endValue: "",
    value: "",
    range: "",
  },
  date_of_application: {
    startValue: "",
    endValue: "",
    value: "",
    range: "",
  },
  date_of_usage: {
    startValue: "",
    endValue: "",
    value: "",
    range: "",
  },
  threat_date_of_application: {
    startValue: "",
    endValue: "",
    value: "",
    range: "",
  },
  threat_date_of_usage: {
    startValue: "",
    endValue: "",
    value: "",
    range: "",
  },
  threat_date_of_usage_proposed: false,
  date_of_usage_proposed: false,
  no_Tags: [],
};

export const Exam_Report_DefaultFilterConfig = {
  YOUR_CLASSES: {
    filter_contains_term: "",
    page: 1,
  },
  YOUR_PROPRIETOR: {
    filter_contains_term: "",
    page: 1,
  },
  YOUR_TRADEMARK_TYPE: {
    filter_contains_term: "",
    page: 1,
  },
  THREAT_PROPRIETOR: {
    filter_contains_term: "",
    page: 1,
  },
  THREAT_TRADEMARK_TYPE: {
    filter_contains_term: "",
    page: 1,
  },
  THREAT_CLASSES: {
    filter_contains_term: "",
    page: 1,
  },
};

export const Notes_DefaultFilterConfig = {
  CLASSES: {
    filter_contains_term: "",
    page: 1,
  },
  PROPRIETOR: {
    filter_contains_term: "",
    page: 1,
  },
  APPLICANT: {
    filter_contains_term: "",
    page: 1,
  },
  INVENTOR: {
    filter_contains_term: "",
    page: 1,
  },
  TAG: {
    filter_contains_term: "",
    page: 1,
  },
};
