import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import {
  DatabaseOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Descriptions,
  Spin,
  Button,
  Typography,
  Checkbox,
  Empty,
  Tooltip,
  Pagination,
  message,
} from "antd";

//--------MANAGER ACTIONS--------
import {
  getWorkflowKeyValue,
  getWorkflow,
  createWorkflow,
  deleteWorkflow,
  getWorkflowDetails,
} from "../../../redux/actions/managerActions/workflow/action";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { changeStateValue_L1 } from "../../../functions/commonFunctions/generalFunctions";
import { logOut } from "../../../redux/actions/commonActions/actions";

import CreateWorkflowModal from "./CreateWorkflowModal";
import WorkflowDetailsDrawer from "./WorkflowDetailDrawer";

const { Content } = Layout;
const { Title, Text } = Typography;

class Workflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: this.props.managerWorkflowPageNumber,
      workflowId: "",
      workflowIdList: [],
      drawersVisibility: {
        workflowDetail: false,
      },
      modalVisibility: {
        addTaskVisible: false,
        createWorkflow: false,
        workflowEmail: false,
        editWorkflowFlag: false,
        editWorkflowData: "",
      },
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.getWorkflowKeyValue();
      this.props.getWorkflow({});
    } else {
      this.props.logOut();
    }
  }

  createWorkflow(data) {
    this.setState({
      modalVisibility: {
        createWorkflow: true,
        editWorkflowFlag: checkValue(data) ? true : false,
        editWorkflowData: data,
      },
    });
  }

  async deleteWorkflow() {
    await this.props.deleteWorkflow({
      workflow_ids: this.state.workflowIdList,
    });
    message[this.props.deleteWorkflowStatus.toLowerCase()](
      this.props.deleteWorkflowMessage,
    );
    this.props.getWorkflow({});
    this.setState({
      workflowIdList: [],
    });
  }

  onSelectWorkflow(data, checked) {
    if (checked) {
      this.setState({
        workflowIdList: [...this.state.workflowIdList, data.workflow_id],
      });
    } else {
      this.setState({
        workflowIdList: this.state.workflowIdList.filter(
          (items) => items !== data.workflow_id,
        ),
      });
    }
  }

  handleDetails(data) {
    this.setState({
      workflowId: data.workflow_id,
      drawersVisibility: {
        workflowDetail: true,
      },
    });
  }

  handlePageChange(pageNumber) {
    this.props.getWorkflow({
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  }

  render() {
    const { managerWorkflow, managerWorkflowStatus, deleteWorkflowStatus } =
      this.props;
    const {
      pageNumber,
      workflowId,
      workflowIdList,
      modalVisibility,
      drawersVisibility,
    } = this.state;
    return (
      <Layout className="workflow">
        <Content style={{ margin: "0px" }}>
          <div
            className="content-container vertical_scroll"
            style={{
              height: `${
                this.props.managerAlert.length > 0
                  ? "calc(100vh - 40px)"
                  : "calc(100vh - 0px)"
              }`,
              padding: 0,
            }}
          >
            <header
              className="General-Header Box-Shadow alignR"
              style={{ paddingTop: "4px" }}
            >
              <Row>
                <Col span={24} style={{ display: "flex" }}>
                  <Button
                    type="primary"
                    danger
                    loading={deleteWorkflowStatus === LOADING}
                    disabled={!this.state.workflowIdList.length > 0}
                    onClick={() => this.deleteWorkflow()}
                    style={{
                      backgroundColor: "#FDE5E7",
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                      marginLeft: "auto",
                    }}
                  >
                    <DeleteOutlined
                      style={{ fontSize: "18px", color: "red" }}
                    />
                    Delete Workflow
                  </Button>
                  <Button type="primary" onClick={() => this.createWorkflow()}>
                    <i className="fa fa-plus" aria-hidden="true" />
                    <Text style={{ color: "#fff", marginLeft: "5px" }}>
                      CREATE WORKFLOW
                    </Text>
                  </Button>
                </Col>
              </Row>
            </header>
            <div className="General-Body">
              <div
                className={`General-Content vertical_scroll`}
                style={{
                  height: "calc(100vh - 95px)",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                {managerWorkflowStatus === LOADING && (
                  <Row>
                    <Col span={24}>
                      <div
                        className="loading-spin"
                        style={{ height: "calc(100vh - 95px)" }}
                      >
                        <Spin />
                      </div>
                    </Col>
                  </Row>
                )}
                {managerWorkflowStatus === SUCCESS && (
                  <Row>
                    {managerWorkflow &&
                      managerWorkflow.workflows &&
                      managerWorkflow.workflows.length > 0 &&
                      managerWorkflow.workflows.map((data, key) => (
                        <Col xxl={6} xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div
                            className="General-Description-Card"
                            style={{ height: "254px", overflow: "auto" }}
                          >
                            <Descriptions
                              title={
                                <div>
                                  <Checkbox
                                    checked={workflowIdList.includes(
                                      data.workflow_id,
                                    )}
                                    onChange={(event) =>
                                      this.onSelectWorkflow(
                                        data,
                                        event.target.checked,
                                      )
                                    }
                                  />
                                  <div
                                    style={{
                                      float: "right",
                                      display: "inline-block",
                                    }}
                                  >
                                    <Tooltip title="Edit Workflow">
                                      <EditOutlined
                                        style={{
                                          fontSize: 18,
                                          padding: "0px 5px",
                                          marginRight: 15,
                                        }}
                                        onClick={() =>
                                          this.createWorkflow(data)
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip title="See Details">
                                      <DatabaseOutlined
                                        style={{ fontSize: 18 }}
                                        onClick={() => this.handleDetails(data)}
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                              }
                              bordered
                              column={{
                                xxl: 4,
                                xl: 2,
                                lg: 2,
                                md: 2,
                                sm: 1,
                                xs: 1,
                              }}
                            >
                              <Descriptions.Item label="TITLE">
                                {checkValue(data.title) ? data.title : "N.A."}
                              </Descriptions.Item>
                              <Descriptions.Item label="ACTION">
                                {checkValue(data.actions)
                                  ? data.actions
                                  : "N.A."}
                              </Descriptions.Item>
                              <Descriptions.Item label="APPLIED ON">
                                {checkValue(data.applied_on)
                                  ? data.applied_on
                                  : "N.A."}
                              </Descriptions.Item>
                              <Descriptions.Item label="CHANGE IN">
                                {checkValue(data.key) ? data.key : "N.A."}
                              </Descriptions.Item>
                              <Descriptions.Item label="CREATED">
                                {checkValue(data.created)
                                  ? data.created
                                  : "N.A."}
                              </Descriptions.Item>
                              <Descriptions.Item label="MODIFIED">
                                {checkValue(data.modified)
                                  ? data.modified
                                  : "N.A."}
                              </Descriptions.Item>
                              <Descriptions.Item label="TIGGER TASKS">
                                {checkValue(data.trigger_tasks)
                                  ? data.trigger_tasks
                                  : "N.A."}
                              </Descriptions.Item>
                            </Descriptions>
                          </div>
                        </Col>
                      ))}
                    {managerWorkflow &&
                      managerWorkflow.workflows &&
                      managerWorkflow.workflows.length === 0 && (
                        <Col span={24}>
                          <div
                            className="empty-container"
                            style={{ height: "calc(100vh - 95px)" }}
                          >
                            <Empty />
                          </div>
                        </Col>
                      )}
                  </Row>
                )}
                {managerWorkflowStatus === ERROR && (
                  <Row>
                    <Col span={24}>
                      <div
                        className="error-container"
                        style={{ height: "calc(100vh - 95px)" }}
                      >
                        <Title level={4}>
                          unfortunately an error has occurred please try again
                          later. will connect you shortly.
                        </Title>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
            <footer className="General-Footer Box-Shadow">
              <Row>
                <Col span={24}>
                  <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={pageNumber}
                    total={managerWorkflow && managerWorkflow.workflows_count}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} `
                    }
                    pageSize={100}
                    showSizeChanger={false}
                    onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                  />
                </Col>
              </Row>
            </footer>
          </div>
        </Content>

        {/* CreateWorkflowModal */}
        <CreateWorkflowModal
          visible={modalVisibility.createWorkflow}
          onCloseModal={() =>
            this.changeStateValue_L1("modalVisibility", "createWorkflow", false)
          }
          editWorkflowFlag={modalVisibility.editWorkflowFlag}
          editWorkflowData={modalVisibility.editWorkflowData}
        />
        {/* CreateWorkflowModal */}

        {/* WorkflowDetailsDrawer */}
        {drawersVisibility.workflowDetail && (
          <WorkflowDetailsDrawer
            visible={drawersVisibility.workflowDetail}
            onCloseDrawer={() =>
              this.changeStateValue_L1(
                "drawersVisibility",
                "workflowDetail",
                false,
              )
            }
            workflowId={workflowId}
          />
        )}
        {/* WorkflowDetailsDrawer */}
      </Layout>
    );
  }
}

const mapDispatchToProps = {
  getWorkflowKeyValue,
  getWorkflow,
  createWorkflow,
  deleteWorkflow,
  getWorkflowDetails,
  logOut,
};

function mapStateToProps(state) {
  const {
    managerWorkflow,
    managerWorkflowStatus,
    managerWorkflowPageNumber,

    deleteWorkflowStatus,
    deleteWorkflowMessage,
  } = state.managerState.workflowState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    managerWorkflow,
    managerWorkflowStatus,
    managerWorkflowPageNumber,

    deleteWorkflowStatus,
    deleteWorkflowMessage,

    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
