import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import { TagsOutlined, UserOutlined } from "@ant-design/icons";

import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Modal, Button, Input, Alert, notification, Typography } from "antd";

//--------ACTIONS--------
import {
  addPatentTags,
  getPatentTags,
} from "../../../redux/actions/commonActions/actions";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import { Patent_DefaultSelectedFilters } from "../../../constants/managerConstants/patentConstants";
import { getManagePatent } from "../../../redux/actions/managerActions/patentDashboard/actions";

const { Text } = Typography;

class AddTagsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.props.addPatentTags(values);
        if (this.props.addTagsStatus === SUCCESS) {
          this.updateTagsStatus(SUCCESS);
          this.props.getManagePatent({
            filterFlag: false,
            filters: Patent_DefaultSelectedFilters,
            pageNumber: 1,
          });
          this.props.form.resetFields();
          this.props.onCloseModal();
        } else {
          this.updateTagsStatus(ERROR);
        }
      }
    });
  }

  updateTagsStatus(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Tags Added",
          description: "Tags Added Successfully",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Tags Added",
          description:
            "Unfortunately, an error occured while Tags Adding. Please, try again later.",
        });
        break;

      default:
        break;
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    return (
      <div>
        <Modal
          title={
            <div className="alignC">
              <Text>ADD TAGS</Text>
            </div>
          }
          open={this.props.visible}
          onOk={this.props.onCloseModal}
          onCancel={this.props.onCloseModal}
          closable={false}
          width={"30%"}
          footer={[
            <Button type="default" onClick={this.props.onCloseModal}>
              Cancel
            </Button>,
            <Button
              type="primary"
              htmlType="submit"
              key="submit"
              loading={this.props.addTagsStatus === LOADING ? true : false}
              onClick={(event) => this.handleSubmit(event)}
            >
              Confirm
            </Button>,
          ]}
        >
          <div>
            <Form
              {...formItemLayout}
              onSubmit={(event) => this.handleSubmit(event)}
              className="login-form"
            >
              {this.props.addTagsStatus === ERROR && (
                <Form.Item>
                  <Alert
                    message="Getting error while adding tags"
                    type="error"
                  />
                </Form.Item>
              )}
              <Form.Item label="Tag Name" className="Sign-In-Input-Field">
                {getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "please enter tag name!" },
                  ],
                })(
                  <Input
                    type="text"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="please enter tag name"
                  />
                )}
              </Form.Item>
              <Form.Item label="Tag Color" className="Sign-In-Input-Field">
                {getFieldDecorator("color_code", {
                  rules: [
                    { required: true, message: "please pickup tags color!" },
                  ],
                  initialValue: "#000000",
                })(
                  <Input
                    type="color"
                    prefix={
                      <TagsOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="please pickup tags color"
                  />
                )}
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  addPatentTags,
  getManagePatent,
  getPatentTags,
};

function mapStateToProps(state) {
  const { addTagsStatus } = state.signInState;

  return deepFreeze({
    addTagsStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "add_tags" })(AddTagsModal));
