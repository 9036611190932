//--------LIBRARIES--------
import Axios from "axios";

//--------RT_CONFIG--------
import { SRCH_RT_URL } from "../../../configs/rootConfigs";

//--------REPORT TYPES--------
import {
  GET_TRADEMARK_REPORT,
  GET_PROPRIETOR_REPORT,
  GET_IPINDIA_REPORT,
  GET_TRADEMARK_REPORT_FILES,
  GET_PROPRIETOR_REPORT_FILES,
  GET_IPINDIA_REPORT_FILES,
  GET_TRADEMARK_REPORT_DETAILS,
  GET_PROPRIETOR_REPORT_DETAILS,
  GET_IPINDIA_REPORT_DETAILS,
  CREATE_SEARCH_REPORT,
  UPDATE_REPORT_MARK,
  DELETE_REPORT,
  DELETE_ALL_REPORT,
  GET_IMAGE_SEARCH_REPORT,
  GET_IMAGE_SEARCH_REPORT_FILES,
  GET_IMAGE_SEARCH_REPORT_DETAILS,
} from "./types";

//--------COMMON IP-SUITE CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../commonActions/actions";
import { notification } from "antd";

//--------Get Reports--------
export function getReport({
  check,
  files,
  report_source,
  filterFlag,
  filters,
  sortFlag,
  sorting,
  pageNumber,
}) {
  const filter_contains_term = filters?.containsTerm;
  const sort_type = sorting?.sortType;
  const sort_on = sorting?.sortOn;
  return async (dispatch) => {
    if (files) {
      switch (report_source) {
        case "MARK":
          dispatch({ type: GET_TRADEMARK_REPORT_FILES, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${SRCH_RT_URL}/reports/`, {
              params: {
                check: check,
                files: files,
                report_source: report_source,
                filter_flag: filterFlag ? true : null,
                filter_contains_term: filterFlag ? filter_contains_term : null,
                sort_flag: sortFlag ? true : null,
                sort_type: sortFlag ? sort_type : null,
                sort_on: sortFlag ? sort_on : null,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_TRADEMARK_REPORT_FILES,
              status: SUCCESS,
              payload: response?.data,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              lastAppliedSorting: {
                ...sorting,
                sortFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_TRADEMARK_REPORT_FILES, status: ERROR });
          }
          break;

        case "PROPRIETOR":
          dispatch({ type: GET_PROPRIETOR_REPORT_FILES, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${SRCH_RT_URL}/reports/`, {
              params: {
                check: check,
                files: files,
                report_source: report_source,
                filter_flag: filterFlag ? true : null,
                filter_contains_term: filterFlag ? filter_contains_term : null,
                sort_flag: sortFlag ? true : null,
                sort_type: sortFlag ? sort_type : null,
                sort_on: sortFlag ? sort_on : null,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_PROPRIETOR_REPORT_FILES,
              status: SUCCESS,
              payload: response?.data,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              lastAppliedSorting: {
                ...sorting,
                sortFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_PROPRIETOR_REPORT_FILES, status: ERROR });
          }
          break;

        case "IPINDIA":
          dispatch({ type: GET_IPINDIA_REPORT_FILES, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${SRCH_RT_URL}/reports/`, {
              params: {
                check: check,
                files: files,
                report_source: report_source,
                filter_flag: filterFlag ? true : null,
                filter_contains_term: filterFlag ? filter_contains_term : null,
                sort_flag: sortFlag ? true : null,
                sort_type: sortFlag ? sort_type : null,
                sort_on: sortFlag ? sort_on : null,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_IPINDIA_REPORT_FILES,
              status: SUCCESS,
              payload: response?.data,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              lastAppliedSorting: {
                ...sorting,
                sortFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_IPINDIA_REPORT_FILES, status: ERROR });
          }
          break;

        case "IMAGE_SEARCH":
          dispatch({ type: GET_IMAGE_SEARCH_REPORT_FILES, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${SRCH_RT_URL}/reports/`, {
              params: {
                check: check,
                files: files,
                report_source: report_source,
                filter_flag: filterFlag ? true : null,
                filter_contains_term: filterFlag ? filter_contains_term : null,
                sort_flag: sortFlag ? true : null,
                sort_type: sortFlag ? sort_type : null,
                sort_on: sortFlag ? sort_on : null,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_IMAGE_SEARCH_REPORT_FILES,
              status: SUCCESS,
              payload: response?.data,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              lastAppliedSorting: {
                ...sorting,
                sortFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_IMAGE_SEARCH_REPORT_FILES, status: ERROR });
          }
          break;

        default:
          break;
      }
    } else {
      switch (report_source) {
        case "IMAGE_SEARCH":
          dispatch({ type: GET_IMAGE_SEARCH_REPORT, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${SRCH_RT_URL}/reports/`, {
              params: {
                check: check,
                files: files,
                report_source: report_source,
                filter_flag: filterFlag ? true : null,
                filter_contains_term: filterFlag ? filter_contains_term : null,
                sort_flag: sortFlag ? true : null,
                sort_type: sortFlag ? sort_type : null,
                sort_on: sortFlag ? sort_on : null,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_IMAGE_SEARCH_REPORT,
              status: SUCCESS,
              payload: response?.data,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              lastAppliedSorting: {
                ...sorting,
                sortFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_IMAGE_SEARCH_REPORT, status: ERROR });
          }
          break;

        case "MARK":
          dispatch({ type: GET_TRADEMARK_REPORT, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${SRCH_RT_URL}/reports/`, {
              params: {
                check: check,
                files: files,
                report_source: report_source,
                filter_flag: filterFlag ? true : null,
                filter_contains_term: filterFlag ? filter_contains_term : null,
                sort_flag: sortFlag ? true : null,
                sort_type: sortFlag ? sort_type : null,
                sort_on: sortFlag ? sort_on : null,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_TRADEMARK_REPORT,
              status: SUCCESS,
              payload: response?.data,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              lastAppliedSorting: {
                ...sorting,
                sortFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_TRADEMARK_REPORT, status: ERROR });
          }
          break;

        case "PROPRIETOR":
          dispatch({ type: GET_PROPRIETOR_REPORT, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${SRCH_RT_URL}/reports/`, {
              params: {
                check: check,
                files: files,
                report_source: report_source,
                filter_flag: filterFlag ? true : null,
                filter_contains_term: filterFlag ? filter_contains_term : null,
                sort_flag: sortFlag ? true : null,
                sort_type: sortFlag ? sort_type : null,
                sort_on: sortFlag ? sort_on : null,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_PROPRIETOR_REPORT,
              status: SUCCESS,
              payload: response?.data,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              lastAppliedSorting: {
                ...sorting,
                sortFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_PROPRIETOR_REPORT, status: ERROR });
          }
          break;

        case "IPINDIA":
          dispatch({ type: GET_IPINDIA_REPORT, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${SRCH_RT_URL}/reports/`, {
              params: {
                check: check,
                files: files,
                report_source: report_source,
                filter_flag: filterFlag ? true : null,
                filter_contains_term: filterFlag ? filter_contains_term : null,
                sort_flag: sortFlag ? true : null,
                sort_type: sortFlag ? sort_type : null,
                sort_on: sortFlag ? sort_on : null,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_IPINDIA_REPORT,
              status: SUCCESS,
              payload: response?.data,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
              lastAppliedSorting: {
                ...sorting,
                sortFlag,
              },
              pageNumber,
            });
          } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_IPINDIA_REPORT, status: ERROR });
          }
          break;

        default:
          break;
      }
    }
  };
}

//--------Get Report Details--------
export function getReportDetail({
  id,
  report_source,
  filterFlag,
  filters,
  sortFlag,
  sorting,
  pageNumber,
}) {
  const filter_class = `${filters?.filterClasses}`;
  const filter_journal_number = `${filters?.filterJournalNumber}`;
  const filter_state = `${filters?.filterState}`;
  const filter_office = `${filters?.filterOffice}`;
  const filter_trademark_category = `${filters?.filterCategory}`;
  const filter_trademark_type = `${filters?.filterType}`;
  const filter_logic_type = `${filters?.filterLogicType}`;
  const filter_trademark_status = `${filters?.filterStatus}`;
  const filter_proprietor = `${filters?.filterProprietor}`;
  const filter_proprietor_name = filters?.filterProprietorName
    ? filters?.filterProprietorName.join("|")
    : null;
  const filter_application_date = `${filters?.date_of_application}`;
  const filter_date_of_usage = `${filters?.date_of_usage}`;
  const filter_date_of_usage_proposed = `${filters?.date_of_usage_proposed}`;
  const filter_contains_term = `${filters?.containsTerm}`;
  const sort_type = `${sorting?.sortType}`;
  const sort_on = `${sorting?.sortOn}`;
  return async (dispatch) => {
    switch (report_source) {
      case "MARK":
        dispatch({ type: GET_TRADEMARK_REPORT_DETAILS, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${SRCH_RT_URL}/report/${id}`, {
            params: {
              report_source: report_source,
              filter_flag: filterFlag ? true : null,
              filter_state: filterFlag ? filter_state : null,
              filter_proprietor: filterFlag ? filter_proprietor : null,
              filter_trademark_category: filterFlag
                ? filter_trademark_category
                : null,
              filter_class: filterFlag ? filter_class : null,
              filter_office: filterFlag ? filter_office : null,
              filter_trademark_status: filterFlag
                ? filter_trademark_status
                : null,
              filter_trademark_type: filterFlag ? filter_trademark_type : null,
              filter_application_date: filterFlag
                ? filter_application_date
                : null,
              filter_date_of_usage: filterFlag ? filter_date_of_usage : null,
              filter_date_of_usage_proposed: filterFlag
                ? filter_date_of_usage_proposed
                : null,
              filter_contains_term: filterFlag ? filter_contains_term : null,
              sort_flag: sortFlag ? true : null,
              sort_type: sortFlag ? sort_type : null,
              sort_on: sortFlag ? sort_on : null,
              page: pageNumber,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: GET_TRADEMARK_REPORT_DETAILS,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            lastAppliedSorting: {
              ...sorting,
              sortFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: GET_TRADEMARK_REPORT_DETAILS, status: ERROR });
        }
        break;

      case "PROPRIETOR":
        dispatch({ type: GET_PROPRIETOR_REPORT_DETAILS, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${SRCH_RT_URL}/report/${id}`, {
            params: {
              report_source: report_source,
              filter_flag: filterFlag ? true : null,
              filter_state: filterFlag ? filter_state : null,
              filter_proprietor: filterFlag ? filter_proprietor : null,
              filter_trademark_category: filterFlag
                ? filter_trademark_category
                : null,
              filter_class: filterFlag ? filter_class : null,
              filter_office: filterFlag ? filter_office : null,
              filter_trademark_status: filterFlag
                ? filter_trademark_status
                : null,
              filter_trademark_type: filterFlag ? filter_trademark_type : null,
              filter_application_date: filterFlag
                ? filter_application_date
                : null,
              filter_date_of_usage: filterFlag ? filter_date_of_usage : null,
              filter_date_of_usage_proposed: filterFlag
                ? filter_date_of_usage_proposed
                : null,
              filter_contains_term: filterFlag ? filter_contains_term : null,
              sort_flag: sortFlag ? true : null,
              sort_type: sortFlag ? sort_type : null,
              sort_on: sortFlag ? sort_on : null,
              page: pageNumber,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: GET_PROPRIETOR_REPORT_DETAILS,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            lastAppliedSorting: {
              ...sorting,
              sortFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: GET_PROPRIETOR_REPORT_DETAILS, status: ERROR });
        }
        break;

      case "IPINDIA":
        dispatch({ type: GET_IPINDIA_REPORT_DETAILS, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${SRCH_RT_URL}/report/${id}`, {
            params: {
              report_source: report_source,
              filter_flag: filterFlag ? true : null,
              filter_state: filterFlag ? filter_state : null,
              filter_proprietor_name: filterFlag
                ? filter_proprietor_name
                : null,
              filter_trademark_category: filterFlag
                ? filter_trademark_category
                : null,
              filter_class: filterFlag ? filter_class : null,
              filter_journal_number: filterFlag ? filter_journal_number : null,
              filter_office: filterFlag ? filter_office : null,
              filter_trademark_status: filterFlag
                ? filter_trademark_status
                : null,
              filter_trademark_type: filterFlag ? filter_trademark_type : null,
              filter_logic_type: filterFlag ? filter_logic_type : null,
              filter_application_date: filterFlag
                ? filter_application_date
                : null,
              filter_date_of_usage: filterFlag ? filter_date_of_usage : null,
              filter_date_of_usage_proposed: filterFlag
                ? filter_date_of_usage_proposed
                : null,
              filter_contains_term: filterFlag ? filter_contains_term : null,
              sort_flag: sortFlag ? true : null,
              sort_type: sortFlag ? sort_type : null,
              sort_on: sortFlag ? sort_on : null,
              page: pageNumber,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: GET_IPINDIA_REPORT_DETAILS,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            lastAppliedSorting: {
              ...sorting,
              sortFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: GET_IPINDIA_REPORT_DETAILS, status: ERROR });
        }
        break;
      case "IMAGE_SEARCH":
        dispatch({ type: GET_IMAGE_SEARCH_REPORT_DETAILS, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${SRCH_RT_URL}/report/${id}`, {
            params: {
              report_source: report_source,
              filter_flag: filterFlag ? true : null,
              filter_state: filterFlag ? filter_state : null,
              filter_proprietor: filterFlag ? filter_proprietor : null,
              filter_trademark_category: filterFlag
                ? filter_trademark_category
                : null,
              filter_class: filterFlag ? filter_class : null,
              filter_office: filterFlag ? filter_office : null,
              filter_trademark_status: filterFlag
                ? filter_trademark_status
                : null,
              filter_trademark_type: filterFlag ? filter_trademark_type : null,
              filter_application_date: filterFlag
                ? filter_application_date
                : null,
              filter_date_of_usage: filterFlag ? filter_date_of_usage : null,
              filter_date_of_usage_proposed: filterFlag
                ? filter_date_of_usage_proposed
                : null,
              filter_contains_term: filterFlag ? filter_contains_term : null,
              sort_flag: sortFlag ? true : null,
              sort_type: sortFlag ? sort_type : null,
              sort_on: sortFlag ? sort_on : null,
              page: pageNumber,
            },
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: GET_IMAGE_SEARCH_REPORT_DETAILS,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            lastAppliedSorting: {
              ...sorting,
              sortFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: GET_IMAGE_SEARCH_REPORT_DETAILS, status: ERROR });
        }
        break;

      default:
        break;
    }
  };
}

//--------Create search Report --------
export function createReport({
  id,
  report_source,
  operation,
  report_file,
  report_file_types,
  report_file_name,
  select_all,
  selected_mark_id_list,
  sort_flag,
  sort,
  filter_flag,
  filters,
}) {
  return async (dispatch) => {
    dispatch({ type: CREATE_SEARCH_REPORT, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${SRCH_RT_URL}/report/${id}/`,
        {
          report_source,
          operation,
          report_file,
          report_file_types,
          report_file_name,
          select_all,
          selected_mark_id_list,
          sort_flag,
          sort,
          filter_flag,
          filters,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: CREATE_SEARCH_REPORT,
        status: SUCCESS,
        payload: response?.data.message,
      });
      notification[SUCCESS.toLowerCase()]({
        message: "Queued Realtime Report",
        description:
          "Your Report is queued for real time. You can visit the Reports section, once generated.",
        duration: 10,
      });
    } catch (err) {
      if (err.response.request.status === 403) {
        notification["error"]({
          message: "Queued Realtime Report",
          description: err.response?.data.message,
          duration: 10,
        });
      } else {
        checkAuthorisation(dispatch, err);
        dispatch({
          type: CREATE_SEARCH_REPORT,
          status: ERROR,
          payload: err.response?.data.error,
        });
        notification["error"]({
          message: "Queued Realtime Report",
          description: err.response?.data.message,
          duration: 10,
        });
      }
    }
  };
}

//--------Update Report Mark --------
export function updateReportMark({
  id,
  report_source,
  operation,
  report_type,
  report_file,
  report_file_types,
  report_file_name,
  select_all,
  selected_mark_id_list,
  sort_flag,
  sort,
  filter_flag,
  filters,
}) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_REPORT_MARK, status: LOADING });
    try {
      let response;
      response = await Axios.patch(
        `${SRCH_RT_URL}/report/${id}/`,
        {
          report_source,
          operation,
          report_type,
          report_file,
          report_file_types,
          report_file_name,
          select_all,
          selected_mark_id_list,
          sort_flag,
          sort,
          filter_flag,
          filters,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: UPDATE_REPORT_MARK,
        status: SUCCESS,
        payload: { response: response?.data, id: id },
        message: "Your mark deleted successfully",
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: UPDATE_REPORT_MARK,
        status: ERROR,
        message: err.response?.data.error,
      });
    }
  };
}

//--------Delete Reports--------
export function deleteReport({ report_source, report_id_list }) {
  return async (dispatch) => {
    dispatch({ type: DELETE_REPORT, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${SRCH_RT_URL}/reports/`, {
        data: { report_source, report_id_list },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: DELETE_REPORT,
        status: SUCCESS,
        payload: {
          response: response?.data,
          idList: report_id_list,
        },
        message: "your report deleted successfully",
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: DELETE_REPORT,
        status: ERROR,
        message: err.response?.data.error,
      });
    }
  };
}

//--------Delete All Report--------
export function deleteAllReport({ report_source }) {
  return async (dispatch) => {
    dispatch({ type: DELETE_ALL_REPORT, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${SRCH_RT_URL}/report/`, {
        data: { report_source },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: DELETE_ALL_REPORT,
        status: SUCCESS,
        payload: response?.data,
        message: "your all report deleted successfully",
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: DELETE_ALL_REPORT,
        status: ERROR,
        message: err.response?.data.error,
      });
    }
  };
}
