//--------Trademark Default Selected Filters--------
export const TM_DefaultSelectedFilters = {
  trademarkClass: [],
  trademarkStatus: [],
  trademarkType: [],
  trademarkOffice: [],
  trademarkCountry: [],
  trademarkStates: [],
  trademarkProprietors: [],
  trademarkReferenceNo: [],
  trademarkTags: [],
  noTagsdata: "",
  trademarkNOtags: [],
  trademarkOpponent: [],
  trademarkAlerts: [],
  journalNumber: [],
  reportColumn: [],
  evidenceFilter: false,
  date_of_application_value: "",
  date_of_application: "",
  valid_upto_date_value: "",
  valid_upto_date: "",
  containsTerm: "",
  patentApplicants: [],
  patentInventors: [],
  desc_contains_term: "",
  date_of_usage: "",
  date_of_usage_proposed: "",
};

//--------Trademark Default Search Filters--------
export const TM_DefaultSearchFilters = {
  classSearch: "",
  statusSearch: "",
  typeSearch: "",
  officeSearch: "",
  countrySearch: "",
  stateSearch: "",
  proprietorSearch: "",
  journalSearch: "",
  referenceNoSearch: "",
  tagsSearch: "",
  opponentSearch: "",
  reportSearch: "",
  alertSearch: "",
  inventorSearch: "",
  applicantSearch: "",
  desc_contains_term: "",
};

export const TM_DefaultFilterConfig = {
  PROPRIETOR: {
    filter_contains_term: "",
    page: 1,
  },
  CLASSES: {
    filter_contains_term: "",
    page: 1,
  },
  TRADEMARK_TYPE: {
    filter_contains_term: "",
    page: 1,
  },
  TAG: {
    filter_contains_term: "",
    page: 1,
  },
  TRADEMARK_STATUS: {
    filter_contains_term: "",
    page: 1,
  },
  TRADEMARK_STATES: {
    filter_contains_term: "",
    page: 1,
  },
  TRADEMARK_OPPONENT: {
    filter_contains_term: "",
    page: 1,
  },
  JOURNAL: {
    filter_contains_term: "",
    page: 1,
  },
  ALERTS: {
    filter_contains_term: "",
    page: 1,
  },
  TRADEMARK_OFFICE: {
    filter_contains_term: "",
    page: 1,
  },
  INVENTOR: {
    filter_contains_term: "",
    page: 1,
  },
  APPLICANT: {
    filter_contains_term: "",
    page: 1,
  },
};

//--------Trademark TLA and Oppostion Hearing Default Selected Filters--------
export const Hearing_DefaultSelectedFilters = {
  trademarkClass: [],
  trademarkAlerts: [],
  hearingLocation: [],
  hearingOfficer: [],
  hearingOffice: [],
  trademarkNOtags: [],
  trademarkProprietors: [],
  trademarkTags: [],
  hearing_date_value: "",
  hearing_date: "",
  containsTerm: "",
  range: "",
};

//--------Trademark TLA and Oppostion Hearing Default Search Filters--------
export const Hearing_DefaultSearchFilters = {
  classSearch: "",
  locationSearch: "",
  officerSearch: "",
  proprietorSearch: "",
  tagsSearch: "",
};

export const Hearing_DefaultFilterConfig = {
  CLASSES: {
    filter_contains_term: "",
    page: 1,
  },
  PROPRIETOR: {
    filter_contains_term: "",
    page: 1,
  },
  HEARING_LOCATION: {
    filter_contains_term: "",
    page: 1,
  },
  HEARING_OFFICERS: {
    filter_contains_term: "",
    page: 1,
  },
  TAG: {
    filter_contains_term: "",
    page: 1,
  },
};
//--------Manage Trademark Default Selected Setters--------
export const MT_defaultSelectedSetters = {
  multiple: {
    addTags: [],
    removeTags: [],
  },
  single: {
    tags: [],
  },
};

//--------Manage Trademark Default Setters Search--------
export const MT_defaultSettersSearch = {
  multiple: {
    addTags: "",
    removeTags: "",
  },
  single: {
    tags: "",
  },
};

export const DefaultClientEmailObject = {
  count: 0,
  next: null,
  previous: null,
  data: [],
};
