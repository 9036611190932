//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Tabs, Card, Table, Button, Col, Typography } from "antd";

//--------REPORTS ACTIONS--------
import { R_getReports } from "../../../redux/actions/watchActions/reportsActions";

//--------IP-SUITE CONSTANTS--------
import { LOADING } from "../../../constants/commonConstants/generalConstants";

//--------DATE FUNCTIONS--------
import { dateFormat } from "../../../functions/commonFunctions/dateFunctions";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { changeStateValue_L1 } from "../../../functions/commonFunctions/generalFunctions";

import SendEmail from "../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../commonComponents/Modals/SendEmailNew";
import { getEmailTemplateData } from "../../../redux/actions/commonActions/actions";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Text } = Typography;

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "custom_report",
      activeImageLabelTab: "image_label_custom_report",
      activeImageTextTab: "image_text_custom_report",
      modalsVisibility: {
        sendEmail: false,
        attachment: null,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.R_getReports();
    } else {
      this.props.history.push("sign-in");
    }
  }

  async handleEmail(modalsVisibility, modal, value, url, data) {
    const { activeTab, activeImageLabelTab, activeImageTextTab } = this.state;
    let title = checkValue(data.title) ? data.title : "";
    let journalNumber = checkValue(data.journal_number)
      ? data.journal_number
      : "";
    let publicationDate = checkValue(data.date_of_publication)
      ? data.date_of_publication
      : "";
    let fromDate = checkValue(data.from_date) ? data.from_date : "";
    let toDate = checkValue(data.to_date) ? data.to_date : "";

    let FilingDate = new Date(publicationDate.split("-").reverse().join("-"));
    let d = FilingDate.getDate();
    FilingDate.setMonth(FilingDate.getMonth() + 4);
    if (FilingDate.getDate() !== d) {
      FilingDate.setDate(0);
    }

    let tempDate = new Date();
    let currDate =
      tempDate.getFullYear() +
      "-" +
      (tempDate.getMonth() + 1) +
      "-" +
      tempDate.getDate() +
      " " +
      tempDate.getHours() +
      ":" +
      tempDate.getMinutes() +
      ":" +
      tempDate.getSeconds();

    let mailContent;
    let subscription;
    let sub_section;
    let templateSub;

    if (activeTab === "custom_report") {
      mailContent =
        "<p>Attached is the infringement analysis created for your marks on <strong>Journal Number:- " +
        journalNumber +
        "</strong>. For these marks, you can file an opposition till 4 months from publication of the date of journal (<strong>" +
        publicationDate +
        "</strong>). </p><p> Kindly advise on the oppositions to be filed.</p>";
      subscription = ["JOURNAL WATCH"];
      templateSub = "Journal Watch";
      sub_section = "CUSTOM WATCH REPORT";
    } else if (activeTab === "automated_report") {
      mailContent =
        "<p>Attached is the infringement analysis created for your marks on <strong>Journal Number:- " +
        journalNumber +
        "</strong>. For these marks, you can file an opposition till 4 months from publication of the date of journal (<strong>" +
        publicationDate +
        "</strong>). </p><p> Kindly advise on the oppositions to be filed.</p>";
      subscription = ["JOURNAL WATCH"];
      templateSub = "Journal Watch";
      sub_section = "AUTOMATED WATCH REPORT";
    } else if (activeTab === "protect_report") {
      mailContent =
        "<p>Cease and Desist report on date range <strong> " +
        fromDate +
        " to " +
        toDate +
        "</strong>.</p>";
      subscription = ["TM PROTECT"];
      templateSub = "TM Protect";
      sub_section = "AUTOMATED TM_PROTECT REPORT";
    } else if (activeTab === "image_label_report") {
      if (activeImageLabelTab === "image_label_custom_report") {
        mailContent =
          "<p>Attached is the infringement analysis created for your marks on <strong>Journal Number:- " +
          journalNumber +
          "</strong>. For these marks, you can file an opposition till 4 months from publication of the date of journal (<strong>" +
          publicationDate +
          "</strong>). </p><p> Kindly advise on the oppositions to be filed.</p>";
        subscription = ["IMAGE_RECOGNITION"];
        templateSub = "IMAGE_RECOGNITION";
        sub_section = "CUSTOM IMAGE LABEL REPORT";
      } else if (activeImageLabelTab === "image_label_automated_report") {
        mailContent =
          "<p>Attached is the infringement analysis created for your marks on <strong>Journal Number:- " +
          journalNumber +
          "</strong>. For these marks, you can file an opposition till 4 months from publication of the date of journal (<strong>" +
          publicationDate +
          "</strong>). </p><p> Kindly advise on the oppositions to be filed.</p>";
        subscription = ["IMAGE_RECOGNITION"];
        templateSub = "IMAGE_RECOGNITION";
        sub_section = "CUSTOM IMAGE LABEL REPORT";
      } else {
        mailContent = "<p><strong>Trademark Watch</strong></p>";
        subscription = ["IMAGE_RECOGNITION"];
        templateSub = "IMAGE_RECOGNITION";
        sub_section = "DASHBOARD";
      }
    } else if (activeTab === "image_text_report") {
      if (activeImageTextTab === "image_text_custom_report") {
        mailContent =
          "<p>Attached is the infringement analysis created for your marks on <strong>Journal Number:- " +
          journalNumber +
          "</strong>. For these marks, you can file an opposition till 4 months from publication of the date of journal (<strong>" +
          publicationDate +
          "</strong>). </p><p> Kindly advise on the oppositions to be filed.</p>";
        subscription = ["IMAGE_RECOGNITION"];
        templateSub = "IMAGE_RECOGNITION";
        sub_section = "CUSTOM IMAGE TEXT REPORT";
      } else if (activeImageTextTab === "image_text_automated_report") {
        mailContent =
          "<p>Attached is the infringement analysis created for your marks on <strong>Journal Number:- " +
          journalNumber +
          "</strong>. For these marks, you can file an opposition till 4 months from publication of the date of journal (<strong>" +
          publicationDate +
          "</strong>). </p><p> Kindly advise on the oppositions to be filed.</p>";
        subscription = ["IMAGE_RECOGNITION"];
        templateSub = "IMAGE_RECOGNITION";
        sub_section = "AUTOMATED IMAGE TEXT REPORT";
      } else {
        mailContent = "<p><strong>Trademark Watch</strong></p>";
        subscription = ["IMAGE_RECOGNITION"];
        templateSub = "IMAGE_RECOGNITION";
        sub_section = "DASHBOARD";
      }
    } else {
      mailContent = "<p><strong>Trademark Watch</strong></p>";
      subscription = ["JOURNAL WATCH"];
      templateSub = "Journal Watch";
      sub_section = "DASHBOARD";
    }
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        attachment: url,
        mailContent: mailContent,
        product: "WATCH",
        subscription: subscription,
        sub_section: sub_section,
        report_tag: {
          journal_number: journalNumber,
          journal_date: publicationDate,
          report_name: title,
          opposition: dateFormat(FilingDate, "-"),
          your_mark: "",
          your_application: "",
          your_proprietor: "",
          your_address: "",
          your_DOA: "",
          your_DOU: "",
          your_class: "",
          your_mark_image: "",
          threat_mark: "",
          threat_application: "",
          threat_proprietor: "",
          threat_address: "",
          threat_DOA: "",
          threat_DOU: "",
          threat_class: "",
          threat_mark_image: "",
          current_date: checkValue(currDate) ? dateFormat(currDate, "-") : "",
        },
      },
    });

    if (value) {
      await this.props.getEmailTemplateData({
        subscription: templateSub,
        product: "WATCH",
        object_id: data?.id,
        sub_section: sub_section,
      });
    }
  }

  render() {
    const { userSubscriptions, userDetails, userTeam, reports } = this.props;

    const columnsCustomReport = [
      {
        title: "Title",
        dataIndex: "title",
        key: "custom_title",
        align: "left",
        width: "30%",
      },

      {
        title: "Report Name",
        dataIndex: "file_name",
        key: "custom_file_name",
        align: "left",
        width: "15%",
      },

      {
        title: "Publication Date",
        dataIndex: "date_of_publication",
        key: "custom_date_of_publication",
        align: "left",
        width: "20%",
      },

      {
        title: "Date",
        dataIndex: "modified",
        key: "custom_modified",
        align: "left",
        width: "15%",
      },

      {
        title: "Download",
        key: "download",
        align: "center",
        width: "10%",
        render: (text, custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                onClick={() => window.open(custom_record.pdf, "_blank")}
                className={checkValue(custom_record.pdf) ? "" : "display_none"}
                style={{ width: "70px", height: "30px", margin: "0px 10px" }}
              >
                {custom_record.pdf &&
                custom_record.pdf.split(".").slice(-1).includes("pdf")
                  ? "PDF"
                  : custom_record.pdf &&
                      (custom_record.pdf
                        .split(".")
                        .slice(-1)
                        .includes("xlsx") ||
                        custom_record.pdf.split(".").slice(-1).includes("xls"))
                    ? "EXCEL"
                    : custom_record.pdf &&
                        custom_record.pdf.split(".").slice(-1).includes("zip")
                      ? "ZIP"
                      : ""}
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "center",
        width: "10%",
        render: (custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                className={checkValue(custom_record.pdf) ? "" : "display_none"}
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    [custom_record.pdf],
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    const columnsAutomatedAllClassReport = [
      {
        title: "Title",
        dataIndex: "title",
        key: "automated_title",
        align: "left",
        width: "40%",
      },

      {
        title: "Publication Date",
        dataIndex: "date_of_publication",
        key: "automated_date_of_publication",
        align: "left",
        width: "15%",
      },

      {
        title: "Date",
        dataIndex: "modified",
        key: "automated_modified",
        align: "left",
        width: "15%",
        render: (text) => <span>{dateFormat(text)}</span>,
      },

      {
        title: "Download",
        key: "download",
        align: "center",
        width: "20%",
        render: (text, custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                disabled={custom_record.pdf ? false : true}
                type="primary"
                ghost
                style={{ width: "70px", height: "30px", margin: "0px 10px" }}
                onClick={() => window.open(custom_record.pdf, "_blank")}
              >
                PDF
              </Button>
              <Button
                disabled={custom_record.excel ? false : true}
                type="primary"
                ghost
                style={{ width: "70px", height: "30px", margin: "0px 10px" }}
                onClick={() => window.open(custom_record.excel, "_blank")}
              >
                EXCEL
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "center",
        width: "10%",
        render: (custom_record) => {
          let emailFiles = [];
          if (checkValue(custom_record.pdf)) {
            emailFiles.push(custom_record.pdf);
          }
          if (checkValue(custom_record.excel)) {
            emailFiles.push(custom_record.excel);
          }
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                disabled={
                  checkValue(custom_record.pdf) ||
                  checkValue(custom_record.excel)
                    ? false
                    : true
                }
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    emailFiles,
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    const columnsAutomatedSameClassReport = [
      {
        title: "Title",
        dataIndex: "title",
        key: "automated_title",
        align: "left",
        width: "40%",
      },

      {
        title: "Publication Date",
        dataIndex: "date_of_publication",
        key: "automated_date_of_publication",
        align: "left",
        width: "15%",
      },

      {
        title: "Date",
        dataIndex: "modified",
        key: "automated_modified",
        align: "left",
        width: "15%",
        render: (text) => <span>{dateFormat(text)}</span>,
      },

      {
        title: "Download",
        key: "download",
        align: "center",
        width: "20%",
        render: (text, custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                disabled={custom_record.pdf_red_band ? false : true}
                type="primary"
                ghost
                style={{ width: "70px", height: "30px", margin: "0px 10px" }}
                onClick={() =>
                  window.open(custom_record.pdf_red_band, "_blank")
                }
              >
                PDF
              </Button>
              <Button
                disabled={custom_record.excel_red_band ? false : true}
                type="primary"
                ghost
                style={{ width: "70px", height: "30px", margin: "0px 10px" }}
                onClick={() =>
                  window.open(custom_record.excel_red_band, "_blank")
                }
              >
                EXCEL
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "center",
        width: "10%",
        render: (custom_record) => {
          let emailFiles = [];
          if (checkValue(custom_record.pdf_red_band)) {
            emailFiles.push(custom_record.pdf_red_band);
          }
          if (checkValue(custom_record.excel_red_band)) {
            emailFiles.push(custom_record.excel_red_band);
          }
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                disabled={
                  checkValue(custom_record.pdf_red_band) ||
                  checkValue(custom_record.excel_red_band)
                    ? false
                    : true
                }
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    emailFiles,
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    const columnsProtectReport = [
      {
        title: "Title",
        dataIndex: "title",
        key: "protect_title",
        align: "left",
        width: "35%",
      },

      {
        title: "From Date",
        dataIndex: "from_date",
        key: "protect_from_date",
        align: "left",
        width: "15%",
      },

      {
        title: "To Date",
        dataIndex: "to_date",
        key: "custom_to_date",
        align: "left",
        width: "15%",
      },

      {
        title: "Modified Date",
        dataIndex: "modified",
        key: "protect_modified",
        align: "left",
        width: "15%",
        render: (text) => <span>{dateFormat(text)}</span>,
      },

      {
        title: "Download",
        key: "download",
        align: "center",
        width: "10%",
        render: (text, custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                onClick={() => window.open(custom_record.excel, "_blank")}
                className={
                  checkValue(custom_record.excel) ? "" : "display_none"
                }
                style={{ width: "70px", height: "30px", margin: "0px 10px" }}
              >
                {custom_record.excel &&
                custom_record.excel.split(".").slice(-1).includes("pdf")
                  ? "PDF"
                  : custom_record.excel &&
                      (custom_record.excel
                        .split(".")
                        .slice(-1)
                        .includes("xlsx") ||
                        custom_record.excel
                          .split(".")
                          .slice(-1)
                          .includes("xls"))
                    ? "EXCEL"
                    : ""}
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "center",
        width: "10%",
        render: (custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                className={
                  checkValue(custom_record.excel) ? "" : "display_none"
                }
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    [custom_record.excel],
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    return (
      <Layout className="Report">
        <Content style={{ margin: "0px" }}>
          <div
            className="content-container vertical_scroll"
            style={{
              height: `${
                this.props.watchAlert.length > 0
                  ? "calc(100vh - 40px)"
                  : "calc(100vh - 0px)"
              }`,
            }}
          >
            <Tabs
              type="card"
              activeKey={this.state.activeTab}
              onChange={(activeKey) => this.setState({ activeTab: activeKey })}
              tabBarStyle={{ paddingTop: "10px" }}
            >
              {/* Custom reoorts tab */}
              {userSubscriptions &&
                userSubscriptions.watch.includes("Journal Watch") && (
                  <TabPane
                    tab="CUSTOM REPORTS"
                    key="custom_report"
                    className="Report-Tab vertical_scroll"
                  >
                    <Card title="CUSTOM REPORTS">
                      <Table
                        bordered
                        rowKey={(record) => record.id}
                        columns={columnsCustomReport}
                        dataSource={reports && reports.custom}
                        scroll={{ y: "calc(70vh - 80px)" }}
                        loading={reports.reportsStatus === LOADING}
                        pagination={false}
                      />
                    </Card>
                  </TabPane>
                )}

              {/* Automated reports tab */}
              {userSubscriptions &&
                userSubscriptions.watch.includes("Journal Watch") && (
                  <TabPane
                    tab=" TM APPLIED AUTOMATED REPORTS"
                    key="automated_report"
                    className="Report-Tab vertical_scroll"
                  >
                    <Card title="ALL CLASS WEEKLY REPORTS">
                      <Table
                        bordered
                        rowKey={(record) => record.id}
                        columns={columnsAutomatedAllClassReport}
                        dataSource={reports && reports.automated_all_class}
                        scroll={{ y: "calc(70vh - 80px)" }}
                        loading={reports.reportsStatus === LOADING}
                        pagination={false}
                      />
                    </Card>
                    <Card
                      title="SAME CLASS WEEKLY REPORTS"
                      style={{ marginTop: "20px" }}
                    >
                      <Table
                        bordered
                        rowKey={(record) => record.id}
                        columns={columnsAutomatedSameClassReport}
                        dataSource={reports && reports.automated_same_class}
                        scroll={{ y: "calc(70vh - 80px)" }}
                        loading={reports.reportsStatus === LOADING}
                        pagination={false}
                      />
                    </Card>
                  </TabPane>
                )}

              {/* TM Protect Report tab */}
              {userSubscriptions &&
                userSubscriptions.watch.includes("TM Protect") && (
                  <TabPane
                    tab="TM PROTECT AUTOMATED REPORTS"
                    key="protect_report"
                    className="Report-Tab vertical_scroll"
                  >
                    <Card title="TM PROTECT REPORTS">
                      <Table
                        bordered
                        rowKey={(record) => record.id}
                        columns={columnsProtectReport}
                        dataSource={reports && reports.protect_reports}
                        scroll={{ y: "calc(70vh - 80px)" }}
                        loading={reports.reportsStatus === LOADING}
                        pagination={false}
                      />
                    </Card>
                  </TabPane>
                )}

              {/* Image Label Report tab */}
              {userSubscriptions &&
                userSubscriptions.watch.includes("IMAGE_RECOGNITION") && (
                  <TabPane
                    tab="IMAGE LABEL REPORTS"
                    key="image_label_report"
                    className="Report-Tab vertical_scroll"
                  >
                    <Tabs
                      type="card"
                      activeKey={this.state.activeImageLabelTab}
                      onChange={(activeKey) =>
                        this.setState({ activeImageLabelTab: activeKey })
                      }
                      tabBarStyle={{ paddingTop: "10px" }}
                    >
                      <TabPane
                        tab="CUSTOM REPORTS"
                        key="image_label_custom_report"
                        className="Report-Tab vertical_scroll"
                      >
                        <Col
                          span={24}
                          className="alignL"
                          style={{ margin: "8px 8px" }}
                        >
                          <Text
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "red",
                            }}
                          >
                            *From 7th Nov 2023 all New Custom Reports will be
                            available at Main Custom Reports Tab
                          </Text>
                        </Col>
                        <Card title="CUSTOM REPORTS">
                          <Table
                            bordered
                            rowKey={(record) => record.id}
                            columns={columnsCustomReport}
                            dataSource={
                              reports &&
                              reports.image_report_details &&
                              reports.image_report_details
                                .custom_image_label_reports
                            }
                            scroll={{ y: "calc(70vh - 80px)" }}
                            loading={reports.reportsStatus === LOADING}
                            pagination={false}
                          />
                        </Card>
                      </TabPane>
                      <TabPane
                        tab="AUTOMATED REPORTS"
                        key="image_label_automated_report"
                        className="Report-Tab vertical_scroll"
                      >
                        <Card title="ALL CLASS WEEKLY REPORTS">
                          <Table
                            bordered
                            rowKey={(record) => record.id}
                            columns={columnsAutomatedAllClassReport}
                            dataSource={
                              reports &&
                              reports.image_report_details &&
                              reports.image_report_details
                                .automated_image_label_all_class_reports
                            }
                            scroll={{ y: "calc(70vh - 80px)" }}
                            loading={reports.reportsStatus === LOADING}
                            pagination={false}
                          />
                        </Card>
                        <Card
                          title="SAME CLASS WEEKLY REPORTS"
                          style={{ marginTop: "20px" }}
                        >
                          <Table
                            bordered
                            rowKey={(record) => record.id}
                            columns={columnsAutomatedSameClassReport}
                            dataSource={
                              reports &&
                              reports.image_report_details &&
                              reports.image_report_details
                                .automated_image_label_same_class_reports
                            }
                            scroll={{ y: "calc(70vh - 80px)" }}
                            loading={reports.reportsStatus === LOADING}
                            pagination={false}
                          />
                        </Card>
                      </TabPane>
                    </Tabs>
                  </TabPane>
                )}

              {/* Image Text Report tab */}
              {userSubscriptions &&
                userSubscriptions.watch.includes("IMAGE_RECOGNITION") && (
                  <TabPane
                    tab="IMAGE TEXT REPORTS"
                    key="image_text_report"
                    className="Report-Tab vertical_scroll"
                  >
                    <Tabs
                      type="card"
                      activeKey={this.state.activeImageTextTab}
                      onChange={(activeKey) =>
                        this.setState({ activeImageTextTab: activeKey })
                      }
                      tabBarStyle={{ paddingTop: "10px" }}
                    >
                      <TabPane
                        tab="CUSTOM REPORTS"
                        key="image_text_custom_report"
                        className="Report-Tab vertical_scroll"
                      >
                        <Card title="CUSTOM REPORTS">
                          <Table
                            bordered
                            rowKey={(record) => record.id}
                            columns={columnsCustomReport}
                            dataSource={
                              reports &&
                              reports.image_report_details &&
                              reports.image_report_details
                                .custom_image_text_reports
                            }
                            scroll={{ y: "calc(70vh - 80px)" }}
                            loading={reports.reportsStatus === LOADING}
                            pagination={false}
                          />
                        </Card>
                      </TabPane>
                      <TabPane
                        tab="AUTOMATED REPORTS"
                        key="image_text_automated_report"
                        className="Report-Tab vertical_scroll"
                      >
                        <Card title="ALL CLASS WEEKLY REPORTS">
                          <Table
                            bordered
                            rowKey={(record) => record.id}
                            columns={columnsAutomatedAllClassReport}
                            dataSource={
                              reports &&
                              reports.image_report_details &&
                              reports.image_report_details
                                .automated_image_text_all_class_reports
                            }
                            scroll={{ y: "calc(70vh - 80px)" }}
                            loading={reports.reportsStatus === LOADING}
                            pagination={false}
                          />
                        </Card>
                        <Card
                          title="SAME CLASS WEEKLY REPORTS"
                          style={{ marginTop: "20px" }}
                        >
                          <Table
                            bordered
                            rowKey={(record) => record.id}
                            columns={columnsAutomatedSameClassReport}
                            dataSource={
                              reports &&
                              reports.image_report_details &&
                              reports.image_report_details
                                .automated_image_text_same_class_reports
                            }
                            scroll={{ y: "calc(70vh - 80px)" }}
                            loading={reports.reportsStatus === LOADING}
                            pagination={false}
                          />
                        </Card>
                      </TabPane>
                    </Tabs>
                  </TabPane>
                )}
            </Tabs>
          </div>

          <SendEmailNew
            visible={this.state.modalsVisibility.sendEmail}
            onCloseModal={() =>
              this.handleEmail("modalsVisibility", "sendEmail", false, [], {})
            }
            sendFrom={userDetails && userDetails.communication_email}
            sendCC={userTeam && userTeam.watch}
            mailContent={this.state.modalsVisibility.mailContent}
            product={this.state.modalsVisibility.product}
            subscription={this.state.modalsVisibility.subscription}
            report_tag={this.state.modalsVisibility.report_tag}
            sub_section={this.state.modalsVisibility.sub_section}
            attachment={this.state.modalsVisibility.attachment}
          />
        </Content>
      </Layout>
    );
  }
}

const mapDispatchToProps = {
  R_getReports,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return {
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
    reports: state.watchState.reports,

    watchAlert: state.alertMessages.watch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
