import { message } from "antd";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import {
  WORKFLOW_KEY_VALUE,
  GET_WORKFLOW,
  CREATE_WORKFLOW,
  EDIT_WORKFLOW,
  DELETE_WORKFLOW,
  GET_WORKFLOW_DETAILS,
  SEND_WORKFLOW_EMAIL,
  EDIT_WORKFLOW_EMAIL,
  DELETE_WORKFLOW_EMAIL,
  CREATE_WORKFLOW_TODO_TASK,
  EDIT_WORKFLOW_TODO_TASK,
  DELETE_WORKFLOW_TODO_TASK,
} from "../../actions/managerActions/workflow/types";

const defaultState = {
  workflowAction: [],
  workflowAppliedOn: [],
  workflowTriggerTasks: [],
  workflowKeys: null,
  workflowValues: null,
  workflowKeyValueStatus: null,

  managerWorkflow: null,
  managerWorkflowStatus: null,
  managerWorkflowPageNumber: 1,

  managerWorkflowDetail: null,
  managerWorkflowEmailTask: null,
  managerWorkflowTodoTask: null,
  managerWorkflowDetailStatus: null,

  createWorkflowStatus: null,
  createWorkflowMessage: null,
  createWorkflowData: null,

  deleteWorkflowStatus: null,
  deleteWorkflowMessage: null,

  workflowEmailMessage: null,
  workflowEmailTask: null,
  workflowEmailStatus: null,

  deleteWorkflowEmailStatus: null,
  deleteWorkflowEmailMessage: null,

  workflowTodoTaskMessage: null,
  workflowTodoTask: null,
  workflowTodoTaskStatus: null,

  deleteWorkflowTodoTaskStatus: null,
  deleteWorkflowTodoTaskMessage: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case WORKFLOW_KEY_VALUE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            workflowKeyValueStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            workflowAction: action.payload.action,
            workflowAppliedOn: action.payload.applied_on,
            workflowTriggerTasks: action.payload.trigger_tasks,
            workflowKeys: action.payload.keys,
            workflowValues: action.payload.values,
            workflowKeyValueStatus: SUCCESS,
          };
        case ERROR:
          return {
            ...state,
            workflowKeyValueStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case GET_WORKFLOW:
      return {
        ...state,
        managerWorkflow: action.payload,
        managerWorkflowStatus: action.status,
        managerWorkflowPageNumber: action.pageNumber,
      };

    case CREATE_WORKFLOW:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            createWorkflowStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            createWorkflowMessage: action.payload.message,
            createWorkflowData: action.payload.workflow,
            managerWorkflow: {
              ...state.managerWorkflow,
              workflows: [
                ...state.managerWorkflow.workflows,
                action.payload.workflow,
              ],
            },
            createWorkflowStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            createWorkflowStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case EDIT_WORKFLOW:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            createWorkflowStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            createWorkflowMessage: action.payload.message,
            createWorkflowData: action.payload.workflow,
            managerWorkflow: {
              ...state.managerWorkflow,
              workflows: state.managerWorkflow.workflows.map((eachRow) =>
                eachRow.workflow_id === action.payload.workflow.workflow_id
                  ? action.payload.workflow
                  : eachRow
              ),
            },
            createWorkflowStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            createWorkflowStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case DELETE_WORKFLOW:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteWorkflowStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            deleteWorkflowMessage: action.payload.message,
            deleteWorkflowStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            deleteWorkflowStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case GET_WORKFLOW_DETAILS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            managerWorkflowDetailStatus: LOADING,
          };
        case SUCCESS:
          return {
            ...state,
            managerWorkflowDetail: action.payload.workflow,
            managerWorkflowEmailTask: action.payload["SEND EMAIL"],
            managerWorkflowTodoTask: action.payload["TO DO TASK"],
            managerWorkflowDetailStatus: action.status,
          };
        case ERROR:
          return {
            ...state,
            managerWorkflowDetailStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case SEND_WORKFLOW_EMAIL:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            workflowEmailStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            workflowEmailMessage: action.payload.message,
            workflowEmailTask: action.payload.sent_email_task,
            workflowEmailStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            workflowEmailStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case EDIT_WORKFLOW_EMAIL:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            workflowEmailStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            workflowEmailMessage: action.payload.message,
            workflowEmailTask: action.payload.sent_email_task,
            managerWorkflowEmailTask: state.managerWorkflowEmailTask.map(
              (eachRow) =>
                eachRow.id === action.payload.sent_email_task.id
                  ? action.payload.sent_email_task
                  : eachRow
            ),
            workflowEmailStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            workflowEmailStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case DELETE_WORKFLOW_EMAIL:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteWorkflowEmailStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            deleteWorkflowEmailMessage: action.payload.message,
            managerWorkflowEmailTask: state.managerWorkflowEmailTask.filter(
              (item) => item.id !== action.taskId
            ),
            deleteWorkflowEmailStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            deleteWorkflowEmailStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case CREATE_WORKFLOW_TODO_TASK:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            workflowTodoTaskStatus: LOADING,
          };

        case SUCCESS:
          message.success(action?.payload?.message);
          return {
            ...state,
            workflowTodoTaskMessage: action.payload.message,
            workflowTodoTask: action.payload.todo_task,
            workflowTodoTaskStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            workflowTodoTaskStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case EDIT_WORKFLOW_TODO_TASK:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            workflowTodoTaskStatus: LOADING,
          };

        case SUCCESS:
          message.success(action?.payload?.message);
          return {
            ...state,
            workflowTodoTaskMessage: action.payload.message,
            workflowTodoTask: action.payload.todo_task,
            managerWorkflowTodoTask: state.managerWorkflowTodoTask.map(
              (eachRow) =>
                eachRow.id === action.payload.todo_task.id
                  ? action.payload.todo_task
                  : eachRow
            ),
            workflowTodoTaskStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            workflowTodoTaskStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case DELETE_WORKFLOW_TODO_TASK:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteWorkflowTodoTaskStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            deleteWorkflowTodoTaskMessage: action.payload.message,
            managerWorkflowTodoTask: state.managerWorkflowTodoTask.filter(
              (item) => item.id !== action.taskId
            ),
            deleteWorkflowTodoTaskStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            deleteWorkflowTodoTaskStatus: ERROR,
          };

        default:
          break;
      }
      break;

    default:
      return state;
  }
}
