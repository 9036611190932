import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import { TagTwoTone } from "@ant-design/icons";
import {
  Row,
  Col,
  Menu,
  Drawer,
  Button,
  Input,
  notification,
  Typography,
  Space,
} from "antd";
import {
  changeStateValue_L1,
  changeStateValue_L2,
} from "./../../../functions/commonFunctions/generalFunctions";
import { MT_defaultSettersSearch } from "./../../../constants/managerConstants/trademarkConstants";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

import UploadDocuments from "../../commonComponents/Drawers/UploadDocuments";
import { TM } from "../../../constants/managerConstants/managerConstants";

//--------TRADEMARK DASHBOARD ACTIONS--------
import {
  postManageTrademark,
  postUploadFile,
  deleteUploadedFile,
  putUploadedFile, //----Upload Documents Actions
} from "../../../redux/actions/managerActions/trademarkDashboard/actions";
import { UPLOAD_FILE_TM } from "../../../redux/actions/managerActions/trademarkDashboard/types";

const { Title, Text, Paragraph } = Typography;
const { Search } = Input;

class ManageTrademarkDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagsPage: 10,
      selectedSetters: this.props.selectedSetters,
      searchSetters: MT_defaultSettersSearch,
    };
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  async handleConfirmManageTrademark() {
    await this.props.postManageTrademark({
      mmType: this.props.mmType,
      setters: this.state.selectedSetters.single,
      select_all: this.props.selectAll,
      delete_flag: false,
      filterFlag: this.props.lastAppliedFilters.filterFlag,
      filters: this.props.lastAppliedFilters,
      trademarks: [],
      application_number: this.props.application_number,
    });
    if (this.props?.editManageTrademarkStatus === SUCCESS) {
      this.props.onCloseDrawer();
    }
    notification[
      this.props?.editManageTrademarkStatus === SUCCESS
        ? "success"
        : this.props?.editManageTrademarkStatus === ERROR
          ? "error"
          : "info"
    ]({
      message: "Update Trademark",
      description:
        this.props?.editManageTrademarkStatus === SUCCESS
          ? "Trademark has been updated successfully."
          : this.props?.editManageTrademarkStatus === ERROR
            ? "Unable to update trademark. Please try again later!"
            : "",
    });
  }

  render() {
    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let singleSelectTagsMenu = noFilterData;

    if (this.props.manageTrademarkStatus === SUCCESS) {
      const { tags } = this.props.manageTrademark.setter;

      if (tags !== null && tags.length > 0) {
        let filteredTag = tags.filter((eachTag) =>
          checkValue(eachTag)
            ? eachTag.name
                .toLowerCase()
                .includes(this.state.searchSetters.single.tags)
            : "",
        );
        singleSelectTagsMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search Tags"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchSetters",
                    "single",
                    "tags",
                    event.target.value.toLowerCase(),
                  )
                }
                value={this.state.searchSetters.single.tags}
                allowClear
              />
            </div>

            <Menu
              onScroll={(x) => {
                const { scrollTop, scrollHeight, clientHeight } = x.target;
                if (scrollTop + clientHeight >= scrollHeight - 20) {
                  this.setState({ tagsPage: this.state.tagsPage + 50 });
                }
              }}
              style={{
                position: "initial",
                height: "200px",
                overflowY: "scroll",
              }}
              selectable={true}
              multiple={true}
              selectedKeys={this.state.selectedSetters.single.tags}
              onSelect={(event) =>
                this.changeStateValue_L2(
                  "selectedSetters",
                  "single",
                  "tags",
                  event.selectedKeys,
                )
              }
              onDeselect={(event) =>
                this.changeStateValue_L2(
                  "selectedSetters",
                  "single",
                  "tags",
                  event.selectedKeys,
                )
              }
            >
              {filteredTag
                ?.slice(0, this.state.tagsPage)
                .map((eachCategory) => (
                  <Menu.Item key={eachCategory.id}>
                    <TagTwoTone twoToneColor={`${eachCategory.color_code}`} />{" "}
                    {eachCategory.name}
                  </Menu.Item>
                ))}
            </Menu>
          </>
        );
      }
    }

    return (
      <Drawer
        title={
          <Title level={4}>
            Manage Trademark ({this.props.application_number})
          </Title>
        }
        placement="right"
        width={400}
        closable={true}
        onClose={() => this.props.onCloseDrawer()}
        open={this.props.visible ? true : false}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Row>
          <Col span={24}>
            <UploadDocuments
              applicationNumber={this.props.application_number}
              documentFor={TM}
              teamMembers={
                checkValue(this.props.userDetails)
                  ? checkValue(this.props.teamMembe)
                    ? [...this.props.teamMember, this.props.userDetails]
                    : ""
                  : ""
              }
              defaultEmail={
                checkValue(this.props.userDetails)
                  ? this.props.userDetails.email
                  : ""
              }
              uploadedDocuments={this.props.visible.uploaded_documents}
              putUploadedFile={(Obj) =>
                this.props.putUploadedFile(Obj, UPLOAD_FILE_TM)
              }
              postUploadFile={(Obj) =>
                this.props.postUploadFile(Obj, UPLOAD_FILE_TM)
              }
              deleteUploadedFile={(Obj) =>
                this.props.deleteUploadedFile(Obj, UPLOAD_FILE_TM)
              }
            />
          </Col>
        </Row>
        <Row className="m-top-20">
          <Col span={24}>
            <div rootStyle={{ marginBottom: "1em" }}>
              <Text strong>Select tags:</Text>
              <Button
                rootStyle={{ float: "right" }}
                type="link"
                onClick={() =>
                  this.changeStateValue_L1(
                    "selectedSetters",
                    "single",
                    "tags",
                    [],
                  )
                }
              >
                Clear
              </Button>
            </div>
            <div rootStyle={{ border: "1px solid #E9E9E9" }}>
              {singleSelectTagsMenu}
            </div>
          </Col>
        </Row>
        {/* <Row className="m-top-20">
          <Col span={24}> */}
        {/* <Space> */}
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button
            danger
            ghost
            onClick={() => this.props.onCloseDrawer()}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            ghost
            loading={this.props?.editManageTrademarkStatus === LOADING}
            onClick={() => this.handleConfirmManageTrademark()}
          >
            Confirm
          </Button>
        </div>
        {/* </Space> */}
        {/* </Col>
        </Row> */}
      </Drawer>
    );
  }
}

const mapDispatchToProps = {
  postManageTrademark,
  postUploadFile,
  deleteUploadedFile,
  putUploadedFile,
};

function mapStateToProps(state) {
  const {
    manageTrademark,
    manageTrademarkStatus,
    manageTrademarkLastAppliedFilters,
    editManageTrademarkStatus,
  } = state.managerState.trademarkDashboardState;

  const { teamMember, teamMemberStatus } = state.profile;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;

  return deepFreeze({
    manageTrademark,
    manageTrademarkStatus,
    lastAppliedFilters: manageTrademarkLastAppliedFilters,
    editManageTrademarkStatus,
    teamMember,
    teamMemberStatus,
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageTrademarkDrawer);
