import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import {
  Row,
  Col,
  Card,
  Modal,
  Typography,
  Input,
  Alert,
  Button,
  Drawer,
  message,
  DatePicker,
} from "antd";
import {
  getTeamMember,
  logOut,
} from "../../../../redux/actions/commonActions/actions";
import {
  getCustomListProfile,
  editCustomProfile,
  getCustomList,
} from "../../../../redux/actions/managerActions/profile/actions";
import { LOADING } from "../../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import { UPLOAD_FILE_TM_INT } from "../../../../redux/actions/managerActions/trademarkDashboard/types";

import {
  putUploadedFile,
  postUploadFile,
  deleteUploadedFile,
  postDownloadFile,
} from "../../../../redux/actions/managerActions/trademarkDashboard/actions";
import { ITM } from "../../../../constants/managerConstants/managerConstants";
import UploadDocuments from "../../../commonComponents/Drawers/UploadDocuments";
import {
  getNotes,
  deleteNotes,
} from "../../../../redux/actions/managerActions/commonAction/action";
import { changeStateValue_L0 } from "../../../../functions/commonFunctions/generalFunctions";
import ManagerNotes from "../../subComponents/Modal/managerNotes";
import {
  GET_ITM_NOTES,
  POST_ITM_NOTES,
  PATCH_ITM_NOTES,
  DELETE_ITM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import {
  TM_INTERNATIONAL_PROFILE,
  TM_EDIT_INTERNATIONAL_PROFILE,
} from "../../../../redux/actions/managerActions/profile/types";
import { Custom_Trademark } from "../../../../constants/managerConstants/customConstants";
import moment from "moment";
import { TM_DefaultSelectedFilters } from "../../../../constants/managerConstants/trademarkConstants";
import UploadDocumentsTable from "../../subComponents/trademarkProfileComponents/UploadDocumentsTable";
import NotesTable from "../../subComponents/trademarkProfileComponents/NotesTable";

const { Text, Paragraph, Title } = Typography;
const { TextArea } = Input;

class InternationalTrademarkProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeLogVisible: false,
      managerNotes: false,
      notesEditingKey: "",
      notesDeletingKey: "",
      note_id: "",
      note: "",
      trademark: Custom_Trademark,
      editCustomTrademark: false,
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      let international_trademark_id = sessionStorage.getItem(
        "international_trademark_id",
      );
      this.props.getCustomListProfile({
        customActionType: TM_INTERNATIONAL_PROFILE,
        id: international_trademark_id,
        operation_type: "trademark",
        object_type: "international",
      });

      if (this.props.teamMember === null) {
        this.props.getTeamMember("manager");
      }

      this.props.getNotes({
        actionType: GET_ITM_NOTES,
        note_for: ITM,
        application_number: sessionStorage.getItem(
          "international_trademark_application_number",
        ),
      });
    } else {
      this.props.logOut();
    }
  }

  showDescription(internationalTrademarkProfile) {
    Modal.info({
      title: "DESCRIPTION",
      width: 800,
      className: "mark-delete-modal",
      content: (
        <div>
          <TextArea
            value={internationalTrademarkProfile.description}
            className="complete-specification-box vertical_scroll"
            autosize={{ minRows: 5 }}
          />
        </div>
      ),
      okText: "Close",
      onOk() {},
    });
  }

  handleAddNotes() {
    this.setState({
      managerNotes: true,
      type: "ADD",
    });
  }

  handleEditNotes(data) {
    this.setState({
      managerNotes: true,
      notesEditingKey: data.id,
      note_id: data.id,
      note: data.note,
      type: "EDIT",
    });
  }

  async handleDeleteNotes(data) {
    this.setState({
      notesDeletingKey: data.id,
    });
    await this.props.deleteNotes({
      actionType: DELETE_ITM_NOTES,
      note_for: ITM,
      application_number: sessionStorage.getItem(
        "international_trademark_application_number",
      ),
      note_id: data.id,
      delete_all: false,
    });
    message[this.props.deleteManageNotesStatus.toLowerCase()](
      this.props.manageNotesMessage,
    );
  }

  async handleDeleteNotesAll() {
    await this.props.deleteNotes({
      actionType: DELETE_ITM_NOTES,
      note_for: ITM,
      application_number: sessionStorage.getItem(
        "international_trademark_application_number",
      ),
      delete_all: true,
    });
    message[this.props.deleteAllManageNotesStatus.toLowerCase()](
      this.props.manageNotesMessage,
    );
  }

  handleEditFields() {
    const editFields = this.props.internationalTrademarkProfile;
    this.setState({
      editCustomTrademark: true,
      trademark: {
        ...this.state.trademark,
        applied_for: checkValue(editFields.applied_for)
          ? editFields.applied_for
          : "",
        associated_class: checkValue(editFields.associated_class)
          ? editFields.associated_class.join(", ")
          : "",
        country: checkValue(editFields.country) ? editFields.country.name : "",
        status: checkValue(editFields.status) ? editFields.status : "",
        type: checkValue(editFields.type) ? editFields.type : "",
        category: checkValue(editFields.category) ? editFields.category : "",
        application_office: checkValue(editFields.application_office)
          ? editFields.application_office
          : "",
        reference_number: checkValue(editFields.reference_number)
          ? editFields.reference_number
          : "",
        registration_number: checkValue(editFields.registration_number)
          ? editFields.registration_number
          : "",
        registration_date: checkValue(editFields.registration_date)
          ? editFields.registration_date
          : "",
        application_date: checkValue(editFields.application_date)
          ? editFields.application_date
          : "",
        valid_upto: checkValue(editFields.valid_upto)
          ? editFields.valid_upto
          : "",
        date_of_usage: checkValue(editFields.date_of_usage)
          ? editFields.date_of_usage
          : "",
        proprietor: checkValue(editFields.proprietor)
          ? editFields.proprietor
          : [],
        description: checkValue(editFields.description)
          ? editFields.description
          : "",
      },
    });
  }

  handleChangeTrademark(type, event) {
    event.preventDefault();
    this.setState({
      trademark: {
        ...this.state.trademark,
        [type]: event.target.value,
      },
    });
  }

  handleChangeDate(type, date, dateString) {
    this.setState({
      trademark: {
        ...this.state.trademark,
        [type]: dateString,
      },
    });
  }

  handleChangeTrademarkLogo(type, file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / (1024 * 1024) < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    if (isJpgOrPng && isLt2M) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          trademark: {
            ...this.state.trademark,
            [type]: reader.result,
          },
        });
      };
      reader.readAsBinaryString(file);
    }
    return isJpgOrPng && isLt2M;
  }

  handleChangeClass(type, event) {
    event.preventDefault();
    this.setState({
      trademark: {
        ...this.state.trademark,
        [type]: event.target.value.replace(/[A-Za-z!@#$%^&*()]/g, ""),
      },
    });
  }

  async handleEditTrademark() {
    let international_trademark_id = sessionStorage.getItem(
      "international_trademark_id",
    );
    await this.props.editCustomProfile({
      customActionType: TM_EDIT_INTERNATIONAL_PROFILE,
      id: international_trademark_id,
      operation_type: "trademark",
      object_type: "international",
      custom_data: {
        ...this.state.trademark,
        associated_class: this.state.trademark.associated_class
          ? this.state.trademark.associated_class
              .split(",")
              .map((eachClass) => parseInt(eachClass))
          : [],
      },
    });
    this.props.getCustomList({
      operation_type: "trademark",
      object_type: "international",
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });
    this.setState({
      editCustomTrademark: false,
      trademark: Custom_Trademark,
    });
  }

  render() {
    const {
      internationalTrademarkProfile,
      internationalTrademarkProfileStatus,
      internationalTrademarkProfileEditStatus,
      manageNotes,
      manageNotesStatus,
      deleteManageNotesStatus,
      deleteAllManageNotesStatus,
    } = this.props;

    const { editCustomTrademark, trademark } = this.state;

    return (
      <div>
        {/* back-section */}
        <Row>
          <Col span={24} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
        </Row>
        {/* back-section */}
        {/* International Trademarks Information */}
        <Row>
          <Col span={24} className="m-top-20">
            <Card
              title="INTERNATIONAL TRADEMARK INFORMATION"
              headStyle={{ background: "#e8e8e8" }}
              extra={
                <>
                  {this.state.editCustomTrademark ? (
                    <>
                      <Button
                        style={{ margin: "0px 5px" }}
                        onClick={() =>
                          this.setState({ editCustomTrademark: false })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        style={{ margin: "0px 5px" }}
                        loading={
                          internationalTrademarkProfileEditStatus === LOADING
                        }
                        onClick={() => this.handleEditTrademark()}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="primary"
                      style={{ margin: "0px 5px" }}
                      onClick={() => this.handleEditFields()}
                    >
                      Edit
                    </Button>
                  )}
                </>
              }
              loading={
                internationalTrademarkProfileStatus === LOADING ? true : false
              }
            >
              {internationalTrademarkProfile && (
                <>
                  {checkValue(internationalTrademarkProfile.alert) ? (
                    <div className="alignL m-bot-20">
                      <Alert
                        className="trademark-alert-message"
                        message={internationalTrademarkProfile.alert}
                        type="error"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <table className="inventor-profile-table">
                      <tbody>
                        <tr>
                          <td>Application Number</td>
                          <td>
                            {checkValue(
                              internationalTrademarkProfile.application_number,
                            )
                              ? internationalTrademarkProfile.application_number
                              : "N.A."}
                          </td>
                        </tr>
                        <tr>
                          <td>Applied For</td>
                          <td>
                            {editCustomTrademark ? (
                              <Input
                                type="text"
                                value={trademark.applied_for}
                                onChange={(event) =>
                                  this.handleChangeTrademark(
                                    "applied_for",
                                    event,
                                  )
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.applied_for,
                              ) ? (
                              internationalTrademarkProfile.applied_for
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Trademark Logo</td>
                          <td>
                            {/* { editCustomTrademark ?
                                                            <input 
                                                                type="file" 
                                                                id="img" 
                                                                name="img" 
                                                                accept="image/*"
                                                                onChange={(event)=> this.handleChangeTrademarkLogo('associated_image', event.target.files[0])}
                                                            />
                                                            : (checkValue(internationalTrademarkProfile.associated_image) ? 
                                                                <div className="thumbnail" style={{margin: '0px'}}>
                                                                    <img className="trademark-profile-logo" src={internationalTrademarkProfile.associated_image} alt=""/> 
                                                                </div>
                                                            : 'N.A.')
                                                        } */}
                            {checkValue(
                              internationalTrademarkProfile.associated_image,
                            ) ? (
                              <div
                                className="thumbnail"
                                style={{ margin: "0px" }}
                              >
                                <img
                                  className="trademark-profile-logo"
                                  src={
                                    internationalTrademarkProfile.associated_image
                                  }
                                  alt=""
                                />
                              </div>
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Reference Number</td>
                          <td>
                            {editCustomTrademark ? (
                              <Input
                                type="text"
                                value={trademark.reference_number}
                                onChange={(event) =>
                                  this.handleChangeTrademark(
                                    "reference_number",
                                    event,
                                  )
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.reference_number,
                              ) ? (
                              internationalTrademarkProfile.reference_number
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Registration Number</td>
                          <td>
                            {editCustomTrademark ? (
                              <Input
                                type="text"
                                value={trademark.registration_number}
                                onChange={(event) =>
                                  this.handleChangeTrademark(
                                    "registration_number",
                                    event,
                                  )
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.registration_number,
                              ) ? (
                              internationalTrademarkProfile.registration_number
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Registration Date</td>
                          <td>
                            {editCustomTrademark ? (
                              <DatePicker
                                defaultValue={
                                  checkValue(
                                    internationalTrademarkProfile.registration_date,
                                  )
                                    ? moment(
                                        internationalTrademarkProfile.registration_date,
                                        "DD/MM/YYYY",
                                      )
                                    : ""
                                }
                                format={"DD/MM/YYYY"}
                                onChange={(date, dateString) =>
                                  this.handleChangeDate(
                                    "registration_date",
                                    date,
                                    dateString,
                                  )
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.registration_date,
                              ) ? (
                              internationalTrademarkProfile.registration_date
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Associated Class</td>
                          <td>
                            {editCustomTrademark ? (
                              <Input
                                type="text"
                                value={trademark.associated_class}
                                onChange={(event) =>
                                  this.handleChangeClass(
                                    "associated_class",
                                    event,
                                  )
                                }
                              />
                            ) : internationalTrademarkProfile.associated_class
                                .length > 0 ? (
                              internationalTrademarkProfile.associated_class.join(
                                ", ",
                              )
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>
                            {editCustomTrademark ? (
                              <Input
                                type="text"
                                value={trademark.status}
                                onChange={(event) =>
                                  this.handleChangeTrademark("status", event)
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.status,
                              ) ? (
                              internationalTrademarkProfile.status
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Type</td>
                          <td>
                            {editCustomTrademark ? (
                              <Input
                                type="text"
                                value={trademark.type}
                                onChange={(event) =>
                                  this.handleChangeTrademark("type", event)
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.type,
                              ) ? (
                              internationalTrademarkProfile.type
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Category</td>
                          <td>
                            {editCustomTrademark ? (
                              <Input
                                type="text"
                                value={trademark.category}
                                onChange={(event) =>
                                  this.handleChangeTrademark("category", event)
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.category,
                              ) ? (
                              internationalTrademarkProfile.category
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Trademark Office</td>
                          <td>
                            {editCustomTrademark ? (
                              <Input
                                type="text"
                                value={trademark.application_office}
                                onChange={(event) =>
                                  this.handleChangeTrademark(
                                    "application_office",
                                    event,
                                  )
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.application_office,
                              ) ? (
                              internationalTrademarkProfile.application_office
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Filing Country</td>
                          <td>
                            {editCustomTrademark ? (
                              <Input
                                type="text"
                                value={trademark.country}
                                onChange={(event) =>
                                  this.handleChangeTrademark("country", event)
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.country,
                              ) ? (
                              internationalTrademarkProfile.country.name
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Application Date</td>
                          <td>
                            {editCustomTrademark ? (
                              <DatePicker
                                defaultValue={
                                  checkValue(
                                    internationalTrademarkProfile.application_date,
                                  )
                                    ? moment(
                                        internationalTrademarkProfile.application_date,
                                        "DD/MM/YYYY",
                                      )
                                    : ""
                                }
                                format={"DD/MM/YYYY"}
                                onChange={(date, dateString) =>
                                  this.handleChangeDate(
                                    "application_date",
                                    date,
                                    dateString,
                                  )
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.application_date,
                              ) ? (
                              internationalTrademarkProfile.application_date
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Valid Upto</td>
                          <td>
                            {editCustomTrademark ? (
                              <DatePicker
                                defaultValue={
                                  checkValue(
                                    internationalTrademarkProfile.valid_upto,
                                  )
                                    ? moment(
                                        internationalTrademarkProfile.valid_upto,
                                        "DD/MM/YYYY",
                                      )
                                    : ""
                                }
                                format={"DD/MM/YYYY"}
                                onChange={(date, dateString) =>
                                  this.handleChangeDate(
                                    "valid_upto",
                                    date,
                                    dateString,
                                  )
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.valid_upto,
                              ) ? (
                              internationalTrademarkProfile.valid_upto
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Date of Usage</td>
                          <td>
                            {editCustomTrademark ? (
                              <DatePicker
                                defaultValue={
                                  checkValue(
                                    internationalTrademarkProfile.date_of_usage,
                                  )
                                    ? moment(
                                        internationalTrademarkProfile.date_of_usage,
                                        "DD/MM/YYYY",
                                      )
                                    : ""
                                }
                                format={"DD/MM/YYYY"}
                                onChange={(date, dateString) =>
                                  this.handleChangeDate(
                                    "date_of_usage",
                                    date,
                                    dateString,
                                  )
                                }
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.date_of_usage,
                              ) ? (
                              internationalTrademarkProfile.date_of_usage
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Proprietor</td>
                          <td>
                            {checkValue(
                              internationalTrademarkProfile.proprietor,
                            ) ? (
                              <ul>
                                {internationalTrademarkProfile.proprietor.map(
                                  (proprietorData, proprietorkey) => (
                                    <li key={proprietorData.id}>
                                      <Paragraph>
                                        <div>
                                          <Text strong>Name : </Text>
                                          {editCustomTrademark ? (
                                            <Input
                                              defaultValue={
                                                checkValue(proprietorData.name)
                                                  ? proprietorData.name
                                                  : "N.A."
                                              }
                                              onChange={(event) =>
                                                this.setState({
                                                  trademark: {
                                                    ...this.state.trademark,
                                                    proprietor:
                                                      this.state.trademark.proprietor.map(
                                                        (data) => ({
                                                          ...data,
                                                          name:
                                                            data.id ===
                                                            proprietorData.id
                                                              ? event.target
                                                                  .value
                                                              : data.name,
                                                        }),
                                                      ),
                                                  },
                                                })
                                              }
                                            />
                                          ) : (
                                            <Text>
                                              {checkValue(proprietorData.name)
                                                ? proprietorData.name
                                                : "N.A."}
                                            </Text>
                                          )}
                                        </div>
                                        <div>
                                          <Text strong>Address : </Text>
                                          {editCustomTrademark ? (
                                            <Input
                                              defaultValue={
                                                checkValue(
                                                  proprietorData.address,
                                                )
                                                  ? proprietorData.address
                                                  : "N.A."
                                              }
                                              onChange={(event) =>
                                                this.setState({
                                                  trademark: {
                                                    ...this.state.trademark,
                                                    proprietor:
                                                      this.state.trademark.proprietor.map(
                                                        (data) => ({
                                                          ...data,
                                                          address:
                                                            data.id ===
                                                            proprietorData.id
                                                              ? event.target
                                                                  .value
                                                              : data.address,
                                                        }),
                                                      ),
                                                  },
                                                })
                                              }
                                            />
                                          ) : (
                                            <Text>
                                              {checkValue(
                                                proprietorData.address,
                                              )
                                                ? proprietorData.address
                                                : "N.A."}
                                            </Text>
                                          )}
                                        </div>
                                      </Paragraph>
                                    </li>
                                  ),
                                )}
                              </ul>
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Description</td>
                          <td>
                            {editCustomTrademark ? (
                              <TextArea
                                value={trademark.description}
                                onChange={(event) =>
                                  this.handleChangeTrademark(
                                    "description",
                                    event,
                                  )
                                }
                                autoSize={{ minRows: 3, maxRows: 5 }}
                              />
                            ) : checkValue(
                                internationalTrademarkProfile.description,
                              ) ? (
                              <Text
                                className="application-text"
                                onClick={() =>
                                  this.showDescription(
                                    internationalTrademarkProfile,
                                  )
                                }
                              >
                                Click Here to Show Description
                              </Text>
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </Card>
          </Col>
        </Row>
        {/* International Trademarks Information */}
        {/* UPLOADED DOCUMENTS TABLE */}
        <UploadDocumentsTable
          data={this.props.internationalTrademarkProfile}
          status={this.props.internationalTrademarkProfileStatus}
          postDownloadFile={(title) =>
            this.props.postDownloadFile({
              applicationNumber:
                this.props.internationalTrademarkProfile.application_number,
              documentFor: ITM,
              fileName: title,
            })
          }
          changeVisible={() => this.setState({ visible: true })}
        />
        {/* UPLOADED DOCUMENTS TABLE */}
        {/* NOTES SECTION TABLE */}
        {manageNotes && (
          <NotesTable
            data={manageNotes}
            manageNotesStatus={manageNotesStatus}
            deleteAllManageNotesStatus={deleteAllManageNotesStatus}
            deleteManageNotesStatus={deleteManageNotesStatus}
            handleDeleteNotesAll={() => this.handleDeleteNotesAll()}
            handleAddNotes={() => this.handleAddNotes()}
            handleEditNotes={(record) => this.handleEditNotes(record)}
            handleDeleteNotes={(record) => this.handleDeleteNotes(record)}
            notesDeletingKey={this.state.notesDeletingKey}
          />
        )}
        {/* NOTES SECTION TABLE */}
        {/* UPLOAD DOCUMENTS DRAWER */}
        <Drawer
          title={
            <Title level={4}>
              UPLOADED DOCUMENTS (
              {this.props.refinedTrademark &&
                this.props.refinedTrademark.ip_india.application_number}
              )
            </Title>
          }
          placement="right"
          width={400}
          closable={true}
          onClose={() => this.setState({ visible: false })}
          open={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {this.props.internationalTrademarkProfile && (
            <UploadDocuments
              applicationNumber={
                this.props.internationalTrademarkProfile.application_number
              }
              documentFor={ITM}
              teamMembers={
                checkValue(this.props.userDetails)
                  ? checkValue(this.props.teamMembe)
                    ? [...this.props.teamMember, this.props.userDetails]
                    : ""
                  : ""
              }
              defaultEmail={
                checkValue(this.props.userDetails)
                  ? this.props.userDetails.email
                  : ""
              }
              uploadedDocuments={
                this.props.internationalTrademarkProfile.uploaded_documents
              }
              putUploadedFile={(Obj) =>
                this.props.putUploadedFile(Obj, UPLOAD_FILE_TM_INT)
              }
              postUploadFile={(Obj) =>
                this.props.postUploadFile(Obj, UPLOAD_FILE_TM_INT)
              }
              deleteUploadedFile={(Obj) =>
                this.props.deleteUploadedFile(Obj, UPLOAD_FILE_TM_INT)
              }
            />
          )}
        </Drawer>
        {/* UPLOAD DOCUMENTS DRAWER */}
        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_ITM_NOTES, PATCH_ITM_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={ITM}
          application_number={sessionStorage.getItem(
            "international_trademark_application_number",
          )}
          note_id={this.state.note_id}
          note={this.state.note}
          type={this.state.type}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCustomListProfile,
  putUploadedFile,
  postUploadFile,
  deleteUploadedFile,
  postDownloadFile,
  getTeamMember,
  logOut,
  getNotes,
  deleteNotes,
  editCustomProfile,
  getCustomList,
};

function mapStateToProps(state) {
  const {
    internationalTrademarkProfile,
    internationalTrademarkProfileStatus,
    internationalTrademarkProfileEditStatus,
  } = state.managerState.profileState;

  const { teamMember, teamMemberStatus } = state.profile;

  const {
    manageNotes,
    manageNotesStatus,
    manageNotesMessage,
    addManageNotesStatus,
    editManageNotesStatus,
    deleteManageNotesStatus,
    deleteAllManageNotesStatus,
  } = state.managerState.notesState;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;

  return deepFreeze({
    teamMember,
    teamMemberStatus,
    internationalTrademarkProfile,
    internationalTrademarkProfileStatus,
    internationalTrademarkProfileEditStatus,

    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    manageNotes,
    manageNotesStatus,
    manageNotesMessage,
    addManageNotesStatus,
    editManageNotesStatus,
    deleteManageNotesStatus,
    deleteAllManageNotesStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InternationalTrademarkProfile);
