//--------TYPES--------
import { 
    TO_DO_TASK,
    ADD_TO_DO_TASK,
    DELETE_DO_TASK,
    TO_DO_REMINDER,
    DELETE_REMINDER,
    RESET_REMINDER,
    EDIT_REMINDER
 } from '../../../actions/managerActions/to-do-task/types';

//--------IP-SUITE CONSTANTS--------
import { LOADING, SUCCESS, ERROR } from '../../../../constants/commonConstants/generalConstants'; 

export default function( state = {
        todoTask: null,
        todoTaskStatus: null,
        addTodoTask: null,
        addTodoTaskStatus: null,
        deleteDodoTaskStatus: null,
        todoReminder: null,
        todoReminderStatus: null,
        addReminder: [],
        addReminderStatus: null,
    }, action ){
        
        switch (action.type) {
            case TO_DO_TASK:
                return {
                    ...state,
                    todoTask: action.payload,
                    todoTaskStatus: action.status,
                };

            case ADD_TO_DO_TASK:
                return {
                    ...state,
                    addTodoTask: action.payload,
                    addTodoTaskStatus: action.status,
                };


            case DELETE_DO_TASK:
                switch (action.status) {
                    case LOADING:
                        return {
                            ...state,
                            deleteDodoTaskStatus: LOADING,
                        };

                    case SUCCESS:
                            return {
                                ...state,
                                todoTask: {
                                    ...state.todoTask, 
                                    data: state.todoTask.data.filter(item => item.id !== action.payload.id),
                                },
                                deleteDodoTaskStatus: action.status,
                            };

                    case ERROR:
                        return {
                            ...state,
                            deleteDodoTaskStatus: ERROR,
                        };
                
                    default:
                        break;
                }
                break;

            case TO_DO_REMINDER:
                switch (action.status) {
                    case LOADING:
                        return {
                            ...state,
                            todoReminderStatus: LOADING,
                        };

                    case SUCCESS:
                            return {
                                ...state,
                                todoReminder: action.payload,
                                addReminder : state.addReminder.concat(action.payload),
                                todoReminderStatus: action.status,
                            };

                    case ERROR:
                        return {
                            ...state,
                            todoReminderStatus: ERROR,
                        };
                
                    default:
                        break;
                }
                break;

            case DELETE_REMINDER:
                switch (action.status) {
                    case LOADING:
                        return {
                            ...state,
                            todoReminderStatus: LOADING,
                        };
    
                    case SUCCESS:
                            return {
                                ...state,
                                addReminder : state.addReminder.filter(item => item.id !== action.payload.id),
                                todoReminderStatus: action.status,
                            };
    
                    case ERROR:
                        return {
                            ...state,
                            todoReminderStatus: ERROR,
                        };
                
                    default:
                        break;
                }
                break;

            case RESET_REMINDER:
                return {
                    ...state, addReminder: []
                };

            case EDIT_REMINDER:
                return {
                    ...state,
                    addReminder: action.payload
                }

        default:
            return state;
    }
}