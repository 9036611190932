//--------Default Filters Visibility--------
export const PP_defaultFiltersVisibility = {
    classesMenu: false,
    stateMenu: false,
    officeMenu: false,
    categoryMenu: false,
    typeMenu: false,
    statusMenu: false,
    proprietorMenu: false,
    sortingMenu: false
}

//--------Default Selected Filters--------
export const PP_defaultSelectedFilters = {
    filterFlag: false,
    filterClasses: [],
    filterState: [],
    filterType: [],
    filterStatus: [],
    filterProprietor: [],
    date_of_application: '',
    date_of_usage: '',
    date_of_usage_proposed: false,
    containsTerm: '',
}

//--------Default Filters Search Terms--------
export const PP_defaultFiltersSearchTerms = {
    classes: '',
    state: '',
    office: '',
    category: '',
    type: '',
    status: '',
    proprietor: '',
};

//--------Default Sorting Terms--------
export const PP_defaultSorting = {
    sortFlag: false,
    sortType: '',
    sortOn: ''
};

//--------Default Custom Report Data--------
export const PP_defaultCustomReportData = {
    data: {},
    id: '',
    report_source: 'PROPRIETOR',
    report_type: 'URGENT',
    report_file: true,
    report_file_name: '',
    report_file_types: ['PDF'],
};

//--------Default Data Report Record Initial State--------
export const PP_defaultInitialReport = {
    select_all: false,
    select_page: false,
    selectedMark: [],
    applications: [],
    filter_flag: false,
    filters: PP_defaultSelectedFilters,
    sort_flag: false,
    sort: PP_defaultSorting
};