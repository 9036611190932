//--------LIBRARIES--------
import Axios from 'axios';

//--------RT_CONFIG--------
import { WAT_RT_URL } from '../../../configs/rootConfigs';

//--------COMMON IP-SUITE CONSTANTS--------
import { LOADING, ERROR, SUCCESS } from '../../../constants/commonConstants/generalConstants';

//--------REPORTS TYPES--------
import { R_GET_REPORTS } from './types';

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from '../commonActions/actions';

//--------Get all the Reports--------
export function R_getReports(){
    return async (dispatch) => {
        dispatch({type: R_GET_REPORTS, status: LOADING});
        let response;
        try {
            response = await Axios.get(
                `${WAT_RT_URL}/report/`,
                {
                    headers: {Authorization:'Token '+ localStorage.getItem('token')}
                }
            );

            switch (response.status) {
                case 200:
                    dispatch({type: R_GET_REPORTS, status: SUCCESS, payload: response?.data})
                    break;

                default:
                    alert(`Unexpected Response : ${response.status}`);
                    dispatch({type: R_GET_REPORTS, status: ERROR});
                    break;
            }
        } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({type: R_GET_REPORTS, status: ERROR});
        }
    };
}