//--------LIBRARIES--------
import Axios from 'axios';

//--------RT_CONFIG--------
import { WAT_RT_URL } from '../../../configs/rootConfigs';

//--------IP-SUITE CONSTANTS--------
import { LOADING, ERROR, SUCCESS } from '../../../constants/commonConstants/generalConstants';

//--------PROFILE TYPES--------
import { P_GET_PROFILE_PREFERENCES } from './types';

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from '../commonActions/actions';

//--------Get Report Preferences--------
export function P_getProfilePreferences(){
    return async (dispatch) => {
        dispatch({type: P_GET_PROFILE_PREFERENCES, status: LOADING});
        let response;
        try {
            response = await Axios.get(
                `${WAT_RT_URL}/profile_preferences/`,
                {
                    headers: {Authorization:'Token '+ localStorage.getItem('token')}
                }
            );

            switch (response.status) {
                case 200:
                    dispatch({type: P_GET_PROFILE_PREFERENCES, status: SUCCESS, payload: response?.data})
                    break;

                default:
                    alert(`Unexpected Response : ${response.status}`);
                    dispatch({type: P_GET_PROFILE_PREFERENCES, status: ERROR});
                    break;
            }
        } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({type: P_GET_PROFILE_PREFERENCES, status: ERROR});
        }
    };
}

//--------Patch Profile Preferences--------
export function P_patchProfilePreferences(profilePreferences){
    return async (dispatch) => {
        dispatch({type: P_GET_PROFILE_PREFERENCES, status: LOADING});
        let response;
        try {
            response = await Axios.patch(
                `${WAT_RT_URL}/profile_preferences/`, profilePreferences,
                {
                    headers: {Authorization:'Token '+ localStorage.getItem('token')}
                }
            );

            switch (response.status) {
                case 200:
                    dispatch({type: P_GET_PROFILE_PREFERENCES, status: SUCCESS, payload: response?.data})
                    break;

                default:
                    alert(`Unexpected Response : ${response.status}`);
                    dispatch({type: P_GET_PROFILE_PREFERENCES, status: ERROR});
                    break;
            }
        } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({type: P_GET_PROFILE_PREFERENCES, status: ERROR});
        }
    };
}