import React from "react";
import { Select, Tooltip } from "antd";
import { ClockCircleTwoTone } from "@ant-design/icons/lib/icons";

const { Option } = Select;

const JournalFilter = ({
  filteredData,
  value = "",
  handleJournalChange,
  extraLoading = false,
}) => {
  let urlKey = window.location.pathname.split("/")[2] || "";

  const currentJournal =
    filteredData?.data && filteredData?.data[0]?.value
      ? filteredData.data[0].value
      : filteredData?.loading
        ? "Loading..."
        : "No Data";

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {urlKey !== "tm_protect" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <b>
            {urlKey === "brand_compare" ? "Choose Analysis" : "Select Journal"}:
          </b>{" "}
          &nbsp;
        </div>
      )}
      <Select
        key={filteredData.key}
        style={{ width: urlKey !== "tm_protect" ? 190 : 220, float: "right" }}
        value={value ? value : currentJournal}
        onChange={(journalNumber, option) =>
          handleJournalChange(journalNumber, option)
        }
        optionLabelProp="label"
        id={`watch_journal_id_${filteredData.key}`}
        loading={filteredData.loading || extraLoading}
        className="tmapplied_ll_journal_dropdown"
      >
        {filteredData.data.map((item, index) => (
          <Option
            value={item.value}
            disabled={!item.meta_data.processing}
            label={item.label}
            key={item.value}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {item.label}
              {!item.meta_data.processing && (
                <span role="img" aria-label="journal">
                  <Tooltip title="Processing...">
                    <ClockCircleTwoTone />
                  </Tooltip>
                </span>
              )}
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default JournalFilter;
