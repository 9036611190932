//--------LIBRARIES--------
import React from "react";
import Axios from "axios";
import * as Sentry from "@sentry/browser";

import { message, Modal } from "antd";
//--------RT_CONFIG--------
import { ROOT_URL, MGR_RT_URL } from "../../../configs/rootConfigs";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import {
  NetworkError,
  BadRequest,
  PageNotFound,
  InternalServerError,
} from "../../../components/commonComponents/Error-Pages/Error";

//--------IP-SUITE TYPES--------
import {
  LOG_IN,
  CLEAR_REDUX,
  LOG_OUT,
  SIGN_UP,
  GET_USER_SUBSCRIPTIONS,
  USER_PROFILE,
  TEAM_MEMBER,
  ADD_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
  MARK_UPLOAD,
  MARK_DOWNLOAD,
  GET_TAGS,
  ADD_TAGS,
  DELETE_TAGS,
  EDIT_TAGS,
  GET_WHATS_NEW,
  GET_IN_TOUCH,
  SEND_CUSTOM_EMAIL,
  GET_TO_CLIENT_EMAIL,
  GET_TO_CLIENT_EMAIL_MORE,
  GET_CC_CLIENT_EMAIL,
  GET_CC_CLIENT_EMAIL_MORE,
  EMAIL_DOCUMENT_LIST,
  GET_COMMENT,
  PASSWORD_RESET,
  PASSWORD_FORGET,
  GET_PATENT_TAGS,
  ADD_PATENT_TAGS,
  DELETE_PATENT_TAGS,
  EDIT_PATENT_TAGS,
  CLIENT_EMAIL_TM,
  CLIENT_EMAIL_PT,
  CLIENT_EMAIL_WT,
  CLIENT_EMAIL_TM_PROTECT,
  CREATE_CLIENT_EMAIL,
  EDIT_CLIENT_EMAIL,
  DELETE_CLIENT_EMAIL,
  SET_STORE,
  GET_CLIENT_EMAIL_CUSTOM_REPORT,
  DOWNLOAD_EMAIL_CONTENT,
  GET_EMAIL_VARS,
  CLEAR_EMAIL_TEMPLATE_DATA,
  GET_USER_TO_CLIENT_EMAIL,
  GET_USER_TO_CLIENT_EMAIL_MORE,
  GET_USER_CC_CLIENT_EMAIL,
  GET_USER_CC_CLIENT_EMAIL_MORE,
  SETUP_AUTH,
  VERIFY_AUTH_CODE,
  SET_MFA,
  SET_RECOVERY_CODES,
  VERIFY_SETUP,
  CREATE_CLIENT_EMAIL_REPORT,
} from "./types";

//--------HISTORY--------
import { history } from "../../../history";

export function checkLoginError(error, dispatch) {
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 400:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data?.error,
        });
        break;

      case 403:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data.message,
        });
        break;

      case 429:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data.message,
        });
        break;

      default:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: "Unable to log in with provided credentials.",
        });
        break;
    }
  } else {
    dispatch({
      type: LOG_IN,
      status: ERROR,
      payload: "Unable to log in with provided credentials.",
    });
  }
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 400:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data?.error,
        });
        break;

      case 403:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data.message,
        });
        break;

      case 429:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data.message,
        });
        break;

      default:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: "Unable to log in with provided credentials.",
        });
        break;
    }
  } else {
    dispatch({
      type: LOG_IN,
      status: ERROR,
      payload: "Unable to log in with provided credentials.",
    });
  }
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 400:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data?.error,
        });
        break;

      case 403:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data.message,
        });
        break;

      case 429:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data.message,
        });
        break;

      default:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: "Unable to log in with provided credentials.",
        });
        break;
    }
  } else {
    dispatch({
      type: LOG_IN,
      status: ERROR,
      payload: "Unable to log in with provided credentials.",
    });
  }
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 400:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data?.error,
        });
        break;

      case 403:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data.message,
        });
        break;

      case 429:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: error.response?.data.message,
        });
        break;

      default:
        dispatch({
          type: LOG_IN,
          status: ERROR,
          payload: "Unable to log in with provided credentials.",
        });
        break;
    }
  } else {
    dispatch({
      type: LOG_IN,
      status: ERROR,
      payload: "Unable to log in with provided credentials.",
    });
  }
}

//--------SignIn: Sign User in--------
export function signInUser({ username, password, product }) {
  return async (dispatch) => {
    dispatch({ type: LOG_IN, status: LOADING });
    let response;
    switch (product) {
      case "tm-search":
        try {
          response = await Axios.post(`${ROOT_URL}/login/`, {
            username,
            password,
            product,
          });
          switch (response.status) {
            case 200:
              localStorage.setItem("user_id", response?.data?.user_id);

              if (response?.data?.mfa === false) {
                if (!response?.data?.tnc_accepted) {
                  localStorage.setItem("tnc_accepted", false);
                  message.info("Please accept terms and conditions to Login");

                  localStorage.setItem("tnc_api_token", response?.data?.token);
                  dispatch({
                    type: SET_MFA,
                    payload: {
                      mfa: response?.data?.mfa,
                      mfa_setup: response?.data?.mfa_setup,
                    },
                  });
                  dispatch({
                    type: LOG_IN,
                    status: SUCCESS,
                    payload: "",
                  });
                  localStorage.setItem("mfa", response?.data.mfa);
                  localStorage.setItem("mfa_setup", response?.data.mfa_setup);
                } else {
                  //normal login
                  localStorage.setItem("token", response?.data.token);
                  localStorage.setItem("userName", response?.data?.user?.name);
                  localStorage.setItem(
                    "userEmail",
                    response?.data?.user?.email,
                  );
                  Sentry.setTag("userName", response?.data?.user?.name);
                  Sentry.setTag("userEmail", response?.data?.user?.email);
                  dispatch({
                    type: GET_USER_SUBSCRIPTIONS,
                    status: SUCCESS,
                    payload: response?.data,
                  });
                  dispatch({
                    type: LOG_IN,
                    status: SUCCESS,
                    payload: "Your login has been completed successfully!",
                  });
                  history.push("/search/search-tips");
                }
              } else if (
                response?.data?.mfa === true &&
                response?.data?.mfa_setup
              ) {
                localStorage.setItem("mfa_token", response?.data?.mfa_token);
                //2fa setup flow
                history.push("/setup-auth");
              } else {
                localStorage.setItem("mfa_token", response?.data?.mfa_token);
                //2fa verification flow
                history.push("/auth");
              }
              break;

            default:
              dispatch({
                type: LOG_IN,
                status: ERROR,
                payload: "Unable to log in with provided credentials.",
              });
              break;
          }
        } catch (error) {
          checkLoginError(error, dispatch);
        }
        break;

      case "tm-watch":
        try {
          response = await Axios.post(`${ROOT_URL}/login/`, {
            username,
            password,
            product,
          });
          switch (response.status) {
            case 200:
              localStorage.setItem("user_id", response?.data?.user_id);

              if (response?.data?.mfa === false) {
                if (!response?.data?.tnc_accepted) {
                  localStorage.setItem("tnc_accepted", false);
                  message.info("Please accept terms and conditions to Login");

                  localStorage.setItem("tnc_api_token", response?.data?.token);
                  dispatch({
                    type: SET_MFA,
                    payload: {
                      mfa: response?.data?.mfa,
                      mfa_setup: response?.data?.mfa_setup,
                    },
                  });
                  dispatch({
                    type: LOG_IN,
                    status: SUCCESS,
                    payload: "",
                  });
                  localStorage.setItem("mfa", response?.data.mfa);
                  localStorage.setItem("mfa_setup", response?.data.mfa_setup);
                } else {
                  //normal login
                  localStorage.setItem("token", response?.data.token);
                  localStorage.setItem("userName", response?.data?.user?.name);
                  localStorage.setItem(
                    "userEmail",
                    response?.data?.user?.email,
                  );
                  Sentry.setTag("userName", response?.data?.user?.name);
                  Sentry.setTag("userEmail", response?.data?.user?.email);
                  dispatch({
                    type: GET_USER_SUBSCRIPTIONS,
                    status: SUCCESS,
                    payload: response?.data,
                  });
                  dispatch({
                    type: LOG_IN,
                    status: SUCCESS,
                    payload: "Your login has been completed successfully!",
                  });
                  history.push("/watch/tm_applied/list_layout");
                }
              } else if (
                response?.data?.mfa === true &&
                response?.data?.mfa_setup
              ) {
                localStorage.setItem("mfa_token", response?.data?.mfa_token);
                //2fa setup flow
                history.push("/setup-auth");
              } else {
                localStorage.setItem("mfa_token", response?.data?.mfa_token);
                //2fa verification flow
                history.push("/auth");
              }
              break;

            default:
              dispatch({
                type: LOG_IN,
                status: ERROR,
                payload: "Unable to log in with provided credentials.",
              });
              break;
          }
        } catch (error) {
          checkLoginError(error, dispatch);
        }
        break;

      case "tm-manager":
        try {
          response = await Axios.post(`${ROOT_URL}/login/`, {
            username,
            password,
            product,
          });
          switch (response.status) {
            case 200:
              localStorage.setItem("user_id", response?.data?.user_id);
              if (response?.data?.mfa === false) {
                if (!response?.data?.tnc_accepted) {
                  localStorage.setItem("tnc_accepted", false);
                  message.info("Please accept terms and conditions to Login");

                  localStorage.setItem("tnc_api_token", response?.data?.token);
                  dispatch({
                    type: SET_MFA,
                    payload: {
                      mfa: response?.data?.mfa,
                      mfa_setup: response?.data?.mfa_setup,
                    },
                  });
                  dispatch({
                    type: LOG_IN,
                    status: SUCCESS,
                    payload: "",
                  });
                  localStorage.setItem("mfa", response?.data.mfa);
                  localStorage.setItem("mfa_setup", response?.data.mfa_setup);
                } else {
                  //normal login
                  localStorage.setItem("token", response?.data.token);
                  localStorage.setItem("userName", response?.data?.user?.name);
                  localStorage.setItem(
                    "userEmail",
                    response?.data?.user?.email,
                  );
                  Sentry.setTag("userName", response?.data?.user?.name);
                  Sentry.setTag("userEmail", response?.data?.user?.email);
                  dispatch({
                    type: GET_USER_SUBSCRIPTIONS,
                    status: SUCCESS,
                    payload: response?.data,
                  });
                  dispatch({
                    type: LOG_IN,
                    status: SUCCESS,
                    payload: "Your login has been completed successfully!",
                  });
                  history.push("/manager/trademark/your-trademark/dashboard");
                }
              } else if (
                response?.data?.mfa === true &&
                response?.data?.mfa_setup
              ) {
                localStorage.setItem("mfa_token", response?.data?.mfa_token);
                //2fa setup flow
                history.push("/setup-auth");
              } else {
                localStorage.setItem("mfa_token", response?.data?.mfa_token);
                //2fa verification flow
                history.push("/auth");
              }
              break;

            default:
              dispatch({
                type: LOG_IN,
                status: ERROR,
                payload: "Unable to log in with provided credentials.",
              });
              break;
          }
        } catch (error) {
          checkLoginError(error, dispatch);
        }
        break;

      case "patent-manager":
        try {
          response = await Axios.post(`${ROOT_URL}/login/`, {
            username,
            password,
            product,
          });
          switch (response.status) {
            case 200:
              localStorage.setItem("user_id", response?.data?.user_id);

              if (response?.data?.mfa === false) {
                if (!response?.data?.tnc_accepted) {
                  localStorage.setItem("tnc_accepted", false);
                  message.info("Please accept terms and conditions to Login");

                  localStorage.setItem("tnc_api_token", response?.data?.token);
                  dispatch({
                    type: SET_MFA,
                    payload: {
                      mfa: response?.data?.mfa,
                      mfa_setup: response?.data?.mfa_setup,
                    },
                  });
                  dispatch({
                    type: LOG_IN,
                    status: SUCCESS,
                    payload: "",
                  });
                  localStorage.setItem("mfa", response?.data.mfa);
                  localStorage.setItem("mfa_setup", response?.data.mfa_setup);
                } else {
                  //normal login
                  localStorage.setItem("token", response?.data.token);
                  localStorage.setItem("userName", response?.data?.user?.name);
                  localStorage.setItem(
                    "userEmail",
                    response?.data?.user?.email,
                  );
                  Sentry.setTag("userName", response?.data?.user?.name);
                  Sentry.setTag("userEmail", response?.data?.user?.email);
                  dispatch({
                    type: GET_USER_SUBSCRIPTIONS,
                    status: SUCCESS,
                    payload: response?.data,
                  });
                  dispatch({
                    type: LOG_IN,
                    status: SUCCESS,
                    payload: "Your login has been completed successfully!",
                  });
                  history.push("/manager/patent/your-patent/dashboard");
                }
              } else if (
                response?.data?.mfa === true &&
                response?.data?.mfa_setup
              ) {
                localStorage.setItem("mfa_token", response?.data?.mfa_token);
                //2fa setup flow
                history.push("/setup-auth");
              } else {
                localStorage.setItem("mfa_token", response?.data?.mfa_token);
                //2fa verification flow
                history.push("/auth");
              }
              break;

            default:
              dispatch({
                type: LOG_IN,
                status: ERROR,
                payload: "Unable to log in with provided credentials.",
              });
              break;
          }
        } catch (error) {
          checkLoginError(error, dispatch);
        }
        break;

      default:
        break;
    }
  };
}

//--------SignUp: User Registration--------
export function signUpUser({ email, password, confirm_password }) {
  return async (dispatch) => {
    dispatch({ type: SIGN_UP, status: LOADING });
    let response;
    try {
      response = await Axios.post(`${ROOT_URL}/registration/`, {
        email,
        password,
        confirm_password,
      });
      switch (response.status) {
        case 201:
          localStorage.setItem("token", response?.data.token);
          dispatch({
            type: SIGN_UP,
            status: SUCCESS,
            payload: "Your registration has been completed successfully!",
            subscriptions: response?.data.subscriptions,
            quota: response?.data.uploaded_documents_quota,
          });
          history.push("/");
          break;

        default:
          dispatch({
            type: SIGN_UP,
            status: ERROR,
            payload:
              "Unable to register with this domain. Please write to us at team@mikelegal.com.",
          });
          break;
      }
    } catch (error) {
      if (error.response !== undefined) {
        switch (error.response.status) {
          case 400:
            dispatch({
              type: SIGN_UP,
              status: ERROR,
              payload: error.response?.data.error,
            });
            break;

          case 403:
            dispatch({
              type: SIGN_UP,
              status: ERROR,
              payload: error.response?.data.error,
            });
            break;

          default:
            dispatch({
              type: SIGN_UP,
              status: ERROR,
              payload:
                "Unable to register with this domain. Please write to us at team@mikelegal.com.",
            });
            break;
        }
      } else {
        dispatch({
          type: SIGN_UP,
          status: ERROR,
          payload:
            "Unable to register with this domain. Please write to us at team@mikelegal.com.",
        });
      }
    }
  };
}

//--------Subscriptions: Get user subscriptions--------
export function getUserSubscriptions() {
  return async (dispatch) => {
    dispatch({ type: GET_USER_SUBSCRIPTIONS, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/subscriptions/`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      localStorage.setItem("userName", response?.data?.user?.name);

      localStorage.setItem("userEmail", response?.data?.user?.email);
      localStorage.setItem("mfa", response?.data?.mfa);
      localStorage.setItem("mfa_setup", response?.data?.mfa_setup);
      localStorage.setItem("user_id", response?.data?.user_id);
      localStorage.setItem("tnc_accepted", response?.data?.tnc_accepted);
      if (!localStorage.setItem("tnc_accepted", response?.data?.tnc_accepted)) {
        localStorage.setItem("tnc_api_token", response?.data?.token);
      }
      Sentry.setTag("userName", response?.data?.user?.name);
      Sentry.setTag("userEmail", response?.data?.user?.email);
      dispatch({
        type: GET_USER_SUBSCRIPTIONS,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: GET_USER_SUBSCRIPTIONS, status: ERROR });
    }
  };
}

// ------------Setup-Auth----------
export function setUpAuth({ user_id, operation, sub_operation }) {
  return async (dispatch) => {
    dispatch({ type: SETUP_AUTH, status: LOADING });
    try {
      let mfa_token = localStorage.getItem("mfa_token");
      let response;
      response = await Axios.post(
        `${ROOT_URL}/mfa/${user_id}/`,
        {
          operation: operation,
          sub_operation: sub_operation,
          mfa_token,
        },
        !mfa_token && {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: SETUP_AUTH,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: SETUP_AUTH, status: ERROR });
    }
  };
}

// ----------post Auth--------
export function verifyAuthCode({ user_id, operation, sub_operation, code }) {
  return async (dispatch) => {
    dispatch({ type: VERIFY_AUTH_CODE, status: LOADING });
    try {
      let mfa_token = localStorage.getItem("mfa_token");
      let response;
      response = await Axios.post(
        `${ROOT_URL}/mfa/${user_id}/`,
        {
          operation: operation,
          sub_operation: sub_operation,
          code: code,
          mfa_token,
        },
        !mfa_token && {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );

      if (response?.data?.verified) {
        if (sub_operation === "SETUP_CODE") {
          dispatch({
            type: SET_RECOVERY_CODES,
            status: SUCCESS,
            payload: response?.data,
          });
          dispatch({
            type: VERIFY_AUTH_CODE,
            status: SUCCESS,
            payload: response?.data,
          });
          history.push("/recovery-codes");
        } else {
          if (!response?.data?.tnc_accepted) {
            message.info("Please accept terms and conditions to Login");
            localStorage.setItem("tnc_accepted", response?.data?.tnc_accepted);
            if (
              !localStorage.setItem(
                "tnc_accepted",
                response?.data?.tnc_accepted,
              )
            ) {
              localStorage.setItem("tnc_api_token", response?.data?.token);
            }
            dispatch({ type: VERIFY_AUTH_CODE, status: ERROR });
          } else {
            localStorage.setItem("token", response?.data?.token);
            dispatch({
              type: VERIFY_AUTH_CODE,
              status: SUCCESS,
              payload: response?.data,
            });
            history.push("/");
          }
        }
      } else {
        dispatch({
          type: VERIFY_AUTH_CODE,
          status: ERROR,
          payload: response?.data,
        });
        message.error("Invalid Code ! Please try again");
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: VERIFY_AUTH_CODE, status: ERROR });
    }
  };
}

export function resetRecoveryCode({ user_id, operation, sub_operation, code }) {
  return async (dispatch) => {
    dispatch({ type: SET_RECOVERY_CODES, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/mfa/${user_id}/`,
        {
          operation: operation,
          sub_operation: sub_operation,
          code: code,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );

      if (response?.status === 200) {
        dispatch({
          type: SET_RECOVERY_CODES,
          status: SUCCESS,
          payload: response?.data,
        });
      } else {
        dispatch({
          type: SET_RECOVERY_CODES,
          status: ERROR,
          payload: response?.data,
        });
        message.error("Something Went Wrong.");
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: VERIFY_AUTH_CODE, status: ERROR });
    }
  };
}

// ----------post Auth--------
export function verifySetup({ user_id, operation, sub_operation, code }) {
  return async (dispatch) => {
    dispatch({ type: VERIFY_SETUP, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/mfa/${user_id}/`,
        {
          operation: operation,
          sub_operation: sub_operation,
          code: code,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      if (response?.data?.verified) {
        if (sub_operation === "SETUP_CODE") {
          localStorage.setItem("mfa", true);
          localStorage.setItem("mfa_setup", false);
          dispatch({
            type: SET_RECOVERY_CODES,
            status: SUCCESS,
            payload: response?.data,
          });
        }
        dispatch({
          type: VERIFY_SETUP,
          status: SUCCESS,
          payload: response?.data,
        });
      } else {
        message.error("Invalid Code! Please Try Again");
        dispatch({
          type: VERIFY_SETUP,
          status: ERROR,
          payload: "Invalid Code! Please Try Again",
        });
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: VERIFY_SETUP,
        status: ERROR,
        payload: "Something went wrong",
      });
    }
  };
}

//--------Profile: get Profile--------
export function getProfile() {
  return async (dispatch) => {
    dispatch({ type: USER_PROFILE, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/profile/`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: USER_PROFILE,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: USER_PROFILE, status: ERROR });
    }
  };
}

//--------Password:Forgot Password--------
export function forgotPassword(email) {
  return async (dispatch) => {
    dispatch({ type: PASSWORD_FORGET, status: LOADING });
    let response;
    try {
      response = await Axios.post(`${ROOT_URL}/reset/password/`, {
        mode: "generate_link",
        email: email,
      });

      switch (response.status) {
        case 201:
          localStorage.setItem("token", response?.data.token);
          dispatch({
            type: PASSWORD_FORGET,
            status: SUCCESS,
            payload: response?.data.message,
          });
          break;
        default:
          break;
      }
    } catch (error) {
      if (error.response !== undefined) {
        switch (error.response.status) {
          case 400:
            dispatch({
              type: PASSWORD_FORGET,
              status: ERROR,
              payload: error.response?.data.message,
            });
            break;

          case 403:
            dispatch({
              type: PASSWORD_FORGET,
              status: ERROR,
              payload: error.response?.data.message,
            });
            break;

          default:
            dispatch({
              type: PASSWORD_FORGET,
              status: ERROR,
              payload: "Unable to sent password reset email.",
            });
            break;
        }
      } else {
        dispatch({
          type: PASSWORD_FORGET,
          status: ERROR,
          payload: "Unable to sent password reset email.",
        });
      }
    }
  };
}

// ------Password:Reset Password -------
export function resetPassword({ password, confirmpassword, otp }) {
  return async (dispatch) => {
    dispatch({ type: PASSWORD_RESET, status: LOADING });
    let response;
    try {
      response = await Axios.post(
        `${ROOT_URL}/reset/password/`,

        {
          mode: "reset_password",
          password: password,
          confirm_password: confirmpassword,
          otp: otp,
        },
      );

      switch (response.status) {
        case 200:
          dispatch({
            type: PASSWORD_RESET,
            status: SUCCESS,
            payload: response?.data.message,
          });
          break;
        default:
          break;
      }
    } catch (error) {
      if (error.response !== undefined) {
        switch (error.response.status) {
          case 400:
            dispatch({
              type: PASSWORD_RESET,
              status: ERROR,
              payload: error.response?.data.message,
            });
            break;

          case 403:
            dispatch({
              type: PASSWORD_RESET,
              status: ERROR,
              payload: error.response?.data.message,
            });
            break;

          default:
            dispatch({
              type: PASSWORD_RESET,
              status: ERROR,
              payload: "Unable to set password.",
            });
            break;
        }
      } else {
        dispatch({
          type: PASSWORD_RESET,
          status: ERROR,
          payload: "Unable to set password.",
        });
      }
    }
  };
}

//--------Profile: edit Profile--------
export function editProfile({
  name,
  email,
  ph_number,
  communication_email,
  file,
  imageType,
}) {
  return async (dispatch) => {
    let formData = new FormData();

    if (file) {
      if (imageType === "LETTERHEAD") {
        formData.append("letter_head_image", file);
      } else {
        formData.append("picture", file);
      }
    } else {
      formData.append("name", name);
      formData.append("email", email);
      if (communication_email && communication_email !== undefined)
        formData.append("communication_email", communication_email);
      if (ph_number && ph_number !== undefined)
        formData.append("phone_number", ph_number);
    }
    dispatch({ type: USER_PROFILE, status: LOADING });
    try {
      let response;
      response = await Axios.patch(`${ROOT_URL}/profile/`, formData, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: USER_PROFILE,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      // checkAuthorisation(dispatch, err);
      console.log(err.response?.data);
      dispatch({
        type: USER_PROFILE,
        status: ERROR,
        payload: err.response?.data?.error,
      });
    }
  };
}

//--------Team: Team Member--------
export function getTeamMember(product) {
  return async (dispatch) => {
    dispatch({ type: TEAM_MEMBER, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/team/`, {
        params: { product },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({ type: TEAM_MEMBER, status: SUCCESS, payload: response?.data });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: TEAM_MEMBER, status: ERROR });
    }
  };
}

//--------Team: Add Team Member--------
export function addTeamMember({ email, name, contact, product }) {
  return async (dispatch) => {
    dispatch({ type: ADD_TEAM_MEMBER, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/team/`,
        { email, name, contact, product },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: ADD_TEAM_MEMBER,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: ADD_TEAM_MEMBER, status: ERROR });
    }
  };
}

//--------Team: Edit Team Member--------
export function editTeamMember({ id, email, name, contact, product }) {
  return async (dispatch) => {
    dispatch({ type: EDIT_TEAM_MEMBER, status: LOADING });
    try {
      let response;
      response = await Axios.put(
        `${ROOT_URL}/team/`,
        { id, email, name, contact, product },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: EDIT_TEAM_MEMBER,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: EDIT_TEAM_MEMBER, status: ERROR });
    }
  };
}

//--------Team: Delete Team Member--------
export function deleteTeamMember({ id, product }) {
  return async (dispatch) => {
    dispatch({ type: DELETE_TEAM_MEMBER, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${ROOT_URL}/team/`, {
        data: { id, product },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: DELETE_TEAM_MEMBER,
        status: SUCCESS,
        payload: { response: response?.data, id: id },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: DELETE_TEAM_MEMBER, status: ERROR });
    }
  };
}

export function clearError() {
  return (dispatch) =>
    dispatch({ type: MARK_UPLOAD, status: SUCCESS, payload: "" });
}

//--------Mark: Mark-Upload--------
export function markUpload({
  platform,
  requested_trademarks,
  comments,
  uploaded_file,
  trademarkType,
}) {
  return async (dispatch) => {
    let formData = new FormData();
    formData.append("product", platform);
    formData.append("mm_type", trademarkType);
    if (requested_trademarks && requested_trademarks.length > 0) {
      formData.append("applications", requested_trademarks);
    }
    if (uploaded_file && uploaded_file !== undefined) {
      formData.append("file", uploaded_file);
    }
    dispatch({ type: MARK_UPLOAD, status: LOADING });
    try {
      let response;
      response = await Axios.post(`${ROOT_URL}/application-upload/`, formData, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "content-type": "multipart/form-data",
        },
      });
      dispatch({ type: MARK_UPLOAD, status: SUCCESS, payload: response?.data });
      return SUCCESS;
    } catch (err) {
      if (!err.response) {
        Modal.error({
          width: 600,
          className: "error-modal",
          content: <NetworkError />,
          okText: "Close",
          onOk() {},
        });
      } else {
        switch (err.response.status) {
          case 400:
            dispatch({
              type: MARK_UPLOAD,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          case 403:
            checkAuthorisation(dispatch, err);
            dispatch({
              type: MARK_UPLOAD,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          default:
            dispatch({
              type: MARK_UPLOAD,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;
        }
      }
    }
  };
}

//--------Mark: Mark-Download--------
export function markDownload(product, subscription) {
  return async (dispatch) => {
    dispatch({ type: MARK_DOWNLOAD, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/download_portfolio/`,
        { product, subscription },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: MARK_DOWNLOAD,
        status: SUCCESS,
        payload: response?.data.message,
      });
    } catch (err) {
      if (!err.response) {
        Modal.error({
          width: 600,
          className: "error-modal",
          content: <NetworkError />,
          okText: "Close",
          onOk() {},
        });
      } else {
        checkAuthorisation(dispatch, err);
        dispatch({
          type: MARK_DOWNLOAD,
          status: ERROR,
          payload: err.response?.data.error,
        });
      }
    }
  };
}

//--------Tags: Get Tags --------
export function getTags() {
  return async (dispatch) => {
    dispatch({ type: GET_TAGS, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/tags/`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: GET_TAGS,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: GET_TAGS, status: ERROR });
    }
  };
}

//--------Tags: Add Tags --------
export function addTags({ name, color_code }) {
  return async (dispatch) => {
    dispatch({ type: ADD_TAGS, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/tags/`,
        { name, color_code },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: ADD_TAGS,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: ADD_TAGS, status: ERROR });
    }
  };
}

//--------Tags: Delete Tags--------
export function deleteTags(id) {
  return async (dispatch) => {
    dispatch({ type: DELETE_TAGS, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${ROOT_URL}/tag/${id}`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: DELETE_TAGS,
        status: SUCCESS,
        payload: { response: response?.data, id: id },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: DELETE_TAGS, status: ERROR });
    }
  };
}

//--------Tags: Edit Tags--------
export function editTags({ id, name, color_code }) {
  return async (dispatch) => {
    dispatch({ type: EDIT_TAGS, status: LOADING });
    try {
      let response;
      response = await Axios.patch(
        `${ROOT_URL}/tag/${id}/`,
        { name, color_code },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: EDIT_TAGS,
        status: SUCCESS,
        payload: { response: response?.data, id: id },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: EDIT_TAGS, status: ERROR });
    }
  };
}

//--------PATENT Tags: Get PATENT Tags --------
export function getPatentTags() {
  return async (dispatch) => {
    dispatch({ type: GET_PATENT_TAGS, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/tags/`, {
        params: { product: "patent" },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: GET_PATENT_TAGS,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: GET_PATENT_TAGS, status: ERROR });
    }
  };
}

//--------Tags: Add Tags --------
export function addPatentTags({ name, color_code }) {
  let response;
  return async (dispatch) => {
    dispatch({ type: ADD_PATENT_TAGS, status: LOADING });
    try {
      response = await Axios.post(
        `${ROOT_URL}/tags/`,
        { name, color_code, product: "patent" },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );

      dispatch({
        type: ADD_PATENT_TAGS,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: ADD_PATENT_TAGS, status: ERROR });
    }
  };
}

//--------Tags: Delete Tags--------
export function deletePatentTags(id) {
  return async (dispatch) => {
    dispatch({ type: DELETE_TAGS, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${ROOT_URL}/tag/${id}`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: DELETE_TAGS,
        status: SUCCESS,
        payload: { response: response?.data, id: id },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: DELETE_TAGS, status: ERROR });
    }
  };
}

//--------Tags: Edit Tags--------
export function editPatentTags({ id, name, color_code }) {
  return async (dispatch) => {
    dispatch({ type: EDIT_TAGS, status: LOADING });
    try {
      let response;
      response = await Axios.patch(
        `${ROOT_URL}/tag/${id}/`,
        { name, color_code, product: "patent" },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: EDIT_TAGS,
        status: SUCCESS,
        payload: { response: response?.data, id: id },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: EDIT_TAGS, status: ERROR });
    }
  };
}
//--------Whats New: Get whats new--------
export function getWhatsNew(type) {
  return async (dispatch) => {
    dispatch({ type: GET_WHATS_NEW, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/whats_new/`, {
        params: { type },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: GET_WHATS_NEW,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      checkAuthorisation(dispatch, error);
      dispatch({ type: GET_WHATS_NEW, status: ERROR });
    }
  };
}

//--------Contact Us: Contact Us--------
export function getInTouch({ name, email, contact, firm_name }) {
  return async (dispatch) => {
    dispatch({ type: GET_IN_TOUCH, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/contact_us`,
        { name, email, contact, firm_name },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: GET_IN_TOUCH,
        status: SUCCESS,
        payload: response?.data.message,
      });
    } catch (err) {
      if (!err.response) {
        Modal.error({
          width: 600,
          className: "error-modal",
          content: <NetworkError />,
          okText: "Close",
          onOk() {},
        });
      } else {
        checkAuthorisation(dispatch, err);
        dispatch({
          type: GET_IN_TOUCH,
          status: ERROR,
          payload: err.response?.data.message,
        });
      }
    }
  };
}

//--------Send Email: send email to client--------
export function sendCustomEmail({
  send_from,
  send_to,
  cc,
  mail_subject,
  mail_body,
  file_attachment,
  attachment,
  multiple_files,
}) {
  let formData = new FormData();
  formData.append("send_from", send_from);
  formData.append("send_to", send_to);
  formData.append("cc", cc);
  formData.append("mail_subject", mail_subject);
  formData.append("mail_body", mail_body);
  formData.append("file_url", JSON.stringify(attachment));
  Array.from(multiple_files).forEach((element) =>
    formData.append("multiple_files", element),
  );

  return async (dispatch) => {
    dispatch({ type: SEND_CUSTOM_EMAIL, status: LOADING });
    try {
      let response;
      response = await Axios.post(`${ROOT_URL}/send_email/`, formData, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: SEND_CUSTOM_EMAIL,
        status: SUCCESS,
        payload: response?.data.message,
      });
    } catch (err) {
      if (!err.response) {
        Modal.error({
          width: 600,
          className: "error-modal",
          content: <NetworkError />,
          okText: "Close",
          onOk() {},
        });
      } else {
        checkAuthorisation(dispatch, err);
        dispatch({
          type: SEND_CUSTOM_EMAIL,
          status: ERROR,
          payload: err.response?.data.message,
        });
      }
    }
  };
}

//--------Send Email: client email list--------
export function getClientEmail({ type, contains_term, page }) {
  return async (dispatch) => {
    switch (type) {
      case "send_to":
        if (page > 1) {
          dispatch({ type: GET_TO_CLIENT_EMAIL_MORE, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${ROOT_URL}/send_email/`, {
              params: {
                contains_term: contains_term,
                page: page,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_TO_CLIENT_EMAIL_MORE,
              status: SUCCESS,
              payload: response?.data,
            });
          } catch (error) {
            checkAuthorisation(dispatch, error);
            dispatch({ type: GET_TO_CLIENT_EMAIL_MORE, status: ERROR });
          }
        } else {
          dispatch({ type: GET_TO_CLIENT_EMAIL, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${ROOT_URL}/send_email/`, {
              params: {
                contains_term: contains_term,
                page: page,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_TO_CLIENT_EMAIL,
              status: SUCCESS,
              payload: response?.data,
            });
          } catch (error) {
            checkAuthorisation(dispatch, error);
            dispatch({ type: GET_TO_CLIENT_EMAIL, status: ERROR });
          }
        }
        break;

      case "cc":
        if (page > 1) {
          dispatch({ type: GET_CC_CLIENT_EMAIL_MORE, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${ROOT_URL}/send_email/`, {
              params: {
                contains_term: contains_term,
                page: page,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_CC_CLIENT_EMAIL_MORE,
              status: SUCCESS,
              payload: response?.data,
            });
          } catch (error) {
            checkAuthorisation(dispatch, error);
            dispatch({ type: GET_CC_CLIENT_EMAIL_MORE, status: ERROR });
          }
        } else {
          dispatch({ type: GET_CC_CLIENT_EMAIL, status: LOADING });
          try {
            let response;
            response = await Axios.get(`${ROOT_URL}/send_email/`, {
              params: {
                contains_term: contains_term,
                page: page,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            });
            dispatch({
              type: GET_CC_CLIENT_EMAIL,
              status: SUCCESS,
              payload: response?.data,
            });
          } catch (error) {
            checkAuthorisation(dispatch, error);
            dispatch({ type: GET_CC_CLIENT_EMAIL, status: ERROR });
          }
        }
        break;

      default:
        break;
    }
  };
}

//--------Clears Browser storage, token and redux -------
export function clearStorage() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_REDUX });
    localStorage.clear();
    sessionStorage.clear();
    history.push("/sign-in");
    window.location.reload();
  };
}

//--------Log out user--------
export function logOut() {
  return async (dispatch) => {
    if (localStorage.getItem("token")) {
      dispatch({ type: LOG_OUT, status: LOADING });
      try {
        let response;
        response = await Axios.post(
          `${ROOT_URL}/logout/`,
          {},
          {
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          },
        );
        if (response.status === 200) {
          dispatch({
            type: LOG_OUT,
            status: SUCCESS,
            payload: response?.data,
          });
          dispatch(clearStorage());
        }
      } catch (error) {
        dispatch(clearStorage());
        checkAuthorisation(dispatch, error);
        dispatch({ type: LOG_OUT, status: ERROR });
      }
    } else {
      dispatch(clearStorage());
    }
  };
}

//--------Error Handling--------
export function checkAuthorisation(dispatch, err) {
  if (!err.response) {
    Modal.error({
      width: 600,
      className: "error-modal",
      content: <NetworkError />,
      okText: "Close",
      onOk() {},
    });
  } else {
    switch (err.response.request.status) {
      case 400:
        Modal.error({
          width: 600,
          className: "error-modal",
          content: <BadRequest />,
          okText: "Close",
          onOk() {},
        });
        return false;

      case 401:
        dispatch({ type: CLEAR_REDUX });
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        return false;

      case 403:
        Modal.error({
          title: "403 (Forbidden)",
          width: 600,
          className: "error-modal",
          content: <BadRequest />,
          okText: "Close",
          onOk() {},
        });
        dispatch({ type: CLEAR_REDUX });
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        return false;

      case 404:
        Modal.error({
          title: "404 (Not Found)",
          width: 600,
          className: "error-modal",
          content: <PageNotFound />,
          okText: "Close",
          onOk() {},
        });
        return false;

      case 405:
        Modal.error({
          title: "405 (Method Not Allowed)",
          width: 600,
          className: "error-modal",
          content: <PageNotFound />,
          okText: "Close",
          onOk() {},
        });
        return false;

      case 500:
        Modal.error({
          title: "500 (Internal Server Error)",
          width: 600,
          className: "error-modal",
          content: <InternalServerError />,
          okText: "Close",
          onOk() {},
        });
        return false;

      default:
        Modal.error({
          title: `${err.response.request.status} (${err.response.request.statusText})`,
          width: 600,
          className: "error-modal",
          content: <InternalServerError />,
          okText: "Close",
          onOk() {},
        });
        return false;
    }
  }
}

//--------TYPE PAYLOAD ACTION--------
export function TPAction(type, payload) {
  return (dispatch) => {
    dispatch({ type, payload });
  };
}

//--------TYPE PAYLOAD ACTION--------
export function SetFileAction(type, payload) {
  return (dispatch) => {
    dispatch({ type, payload });
  };
}

//--------TYPE PAYLOAD STATUS ACTION--------
export function TPSAction(type, payload, status) {
  return (dispatch) => {
    dispatch({ type, payload, status });
  };
}

//--------Get Alert Message--------
export function getAlertMessage(type, alert_source) {
  return async (dispatch) => {
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/alert_change/`, {
        params: { alert_source },
      });
      dispatch({
        type,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {}
  };
}

//--------GET COMMENT: comment details--------
export function getComment() {
  return async (dispatch) => {
    dispatch({ type: GET_COMMENT, status: LOADING });
    try {
      let response;
      // response = await Axios.get(`${ROOT_URL}/comment/`, {
      //     headers: { Authorization: 'Token ' + localStorage.getItem('token') }
      // });
      response = "Hello Mikelegal";
      dispatch({
        type: GET_COMMENT,
        status: SUCCESS,
        // payload: response?.data,
        payload: response,
      });
    } catch (error) {
      checkAuthorisation(dispatch, error);
      dispatch({ type: GET_COMMENT, status: ERROR });
    }
  };
}

//--------COMMENT OPERATION: comment details--------
export function commentOperation(operation) {
  return async (dispatch) => {
    switch (operation) {
      case "ADD":
        dispatch({ type: GET_COMMENT, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${ROOT_URL}/comment/`, {
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: GET_COMMENT,
            status: SUCCESS,
            payload: response?.data,
          });
        } catch (error) {
          checkAuthorisation(dispatch, error);
          dispatch({ type: GET_COMMENT, status: ERROR });
        }
        break;

      case "EDIT":
        break;

      case "DELETE":
        break;

      default:
        break;
    }
  };
}

//--------Email-Template: Get-Email-Template--------
export function getEmailTemplate({
  actionType,
  product,
  subscription,
  page,
  contains_term,
  sub_section,
}) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/email_template/`, {
        params: {
          product,
          subscription,
          page,
          contains_term,
          sub_section,
          page_size: 10,
        },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
        page,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: actionType,
        status: ERROR,
        payload: err?.response?.data,
      });
    }
  };
}

//--------Email-Template: Get-Email-Template--------
export function clearEmailTemplate() {
  return (dispatch) => {
    dispatch({ type: CLEAR_EMAIL_TEMPLATE_DATA });
  };
}

//--------Email-Template: Create-Email-Template--------
export function createEmailTemplate({
  actionType,
  product,
  subscription,
  subject,
  content,
  sub_section,
}) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/email_template/`,
        { product, subscription, subject, content, sub_section },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: actionType,
        status: ERROR,
        payload: err.response?.data,
      });
    }
  };
}

//--------Email-Template: Edit-Email-Template--------
export function editEmailTemplate({
  actionType,
  template_id,
  product,
  subscription,
  subject,
  content,
  sub_section,
}) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.patch(
        `${ROOT_URL}/email_template/`,
        { template_id, product, subscription, subject, content, sub_section },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: actionType,
        status: ERROR,
        payload: err.response?.data,
      });
    }
  };
}

//--------Email-Template: Delete-Email-Template--------
export function deleteEmailTemplate({
  actionType,
  delete_all,
  template_id,
  product,
  contains_term,
}) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${ROOT_URL}/email_template/`, {
        data: { delete_all, template_id, product, contains_term },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
        request_status: response?.request?.status,
      });
    } catch (err) {
      if (err?.response?.request?.status === 400) {
        message.error(err?.response?.data?.message);
      } else {
        checkAuthorisation(dispatch, err);
      }
      dispatch({
        type: actionType,
        status: ERROR,
        payload: err.response?.data,
        request_status: err?.response?.request?.status,
      });
    }
  };
}

//--------Email-Template: Get-User-Client-Email--------

export function getUserClientEmail({
  actionType,
  product,
  subscription,
  mm_type,
  mark,
  report_flag,
  application_number,
  page,
  contains_term,
}) {
  return async (dispatch) => {
    if (page > 1) {
      dispatch({ type: GET_USER_TO_CLIENT_EMAIL_MORE, status: LOADING });
      try {
        let response;
        response = await Axios.get(`${ROOT_URL}/client_email/`, {
          params: {
            contains_term: contains_term,
            page_size: 100,
            product,
            subscription,
            mm_type,
            mark,
            report_flag,
            application_number,
            page: page,
          },
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        });
        dispatch({
          type: GET_USER_TO_CLIENT_EMAIL_MORE,
          status: SUCCESS,
          payload: response?.data,
        });
      } catch (error) {
        checkAuthorisation(dispatch, error);
        dispatch({ type: GET_USER_TO_CLIENT_EMAIL_MORE, status: ERROR });
      }
    } else {
      dispatch({ type: actionType, status: LOADING });
      try {
        let response;
        response = await Axios.get(`${ROOT_URL}/client_email/`, {
          params: {
            contains_term: contains_term,
            page: page,
            product,
            subscription,
            mm_type,
            mark,
            report_flag,
            application_number,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: actionType,
          status: SUCCESS,
          payload: response?.data,
        });
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({
          type: actionType,
          status: ERROR,
          payload: err.response?.data,
        });
      }
    }
  };
}

//--------Email-Template: Get-User-document_list--------
export function getEmailDocument({ mark_id }) {
  return async (dispatch) => {
    dispatch({ type: EMAIL_DOCUMENT_LIST, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/document_list/`, {
        params: { mark_id },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: EMAIL_DOCUMENT_LIST,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: EMAIL_DOCUMENT_LIST,
        status: ERROR,
        payload: err?.response?.data,
      });
    }
  };
}

//--------Manager: Get Client Email --------
export function getClientEmails({
  mmType,
  subscription,
  filterFlag,
  filters,
  pageNumber,
  filters_required_flag,
  required_filter,
  filter_contains_term,
  no_email,
}) {
  const proprietor = `${filters?.trademarkProprietors}`;
  const contains_term = `${filters?.containsTerm}`;
  const inventor = `${filters?.patentInventors}`;
  const applicant = `${filters?.patentApplicants}`;

  return async (dispatch) => {
    let params = {
      subscription: subscription,
      filter_flag: no_email ? true : filterFlag ? filterFlag : null,
      filters_required_flag: filters_required_flag,
      no_email: no_email ? no_email : false,
      page: pageNumber,
    };
    if (
      ["TRADEMARK", "CUSTOM_TRADEMARK", "INTERNATIONAL_TRADEMARK"].includes(
        subscription,
      )
    ) {
      params = {
        ...params,
        mm_type: mmType,
        proprietor: filterFlag ? proprietor : null,
        contains_term: filterFlag ? contains_term : null,
        page: pageNumber,
      };
    } else if (["JOURNAL_WATCH"].includes(subscription)) {
      params = {
        ...params,
        proprietor: filterFlag ? proprietor : null,
        contains_term: filterFlag ? contains_term : null,
        page: pageNumber,
      };
    } else {
      params = {
        ...params,
        inventor: filterFlag ? inventor : null,
        applicant: filterFlag ? applicant : null,
        contains_term: filterFlag ? contains_term : null,
        page: pageNumber,
      };
    }

    if (filters_required_flag && required_filter !== "") {
      try {
        dispatch({
          type: `CLIENT_EMAIL_${required_filter}_FILTERS`,
          status: LOADING,
        });
        let response;
        response = await Axios.get(`${ROOT_URL}/client_email_dashboard/`, {
          params: {
            ...params,
            page: pageNumber,
            filters_required_flag,
            required_filter,
            filter_contains_term,
            page_size: 100,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: `CLIENT_EMAIL_${required_filter}_FILTERS`,
          status: SUCCESS,
          payload: response?.data,
          lastAppliedFilters: {
            ...filters,
            filterFlag,
          },
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({
          type: `CLIENT_EMAIL_${required_filter}_FILTERS`,
          status: ERROR,
        });
      }
    } else {
      if (
        ["TRADEMARK", "CUSTOM_TRADEMARK", "INTERNATIONAL_TRADEMARK"].includes(
          subscription,
        )
      ) {
        dispatch({ type: CLIENT_EMAIL_TM, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${ROOT_URL}/client_email_dashboard/`, {
            params,
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: CLIENT_EMAIL_TM,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: CLIENT_EMAIL_TM, status: ERROR });
        }
      } else if (
        ["PATENT", "CUSTOM_PATENT", "INTERNATIONAL_PATENT"].includes(
          subscription,
        )
      ) {
        dispatch({ type: CLIENT_EMAIL_PT, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${ROOT_URL}/client_email_dashboard/`, {
            params,
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: CLIENT_EMAIL_PT,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: CLIENT_EMAIL_PT, status: ERROR });
        }
      } else if (subscription === "JOURNAL_WATCH") {
        dispatch({ type: CLIENT_EMAIL_WT, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${ROOT_URL}/client_email_dashboard/`, {
            params,
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: CLIENT_EMAIL_WT,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: CLIENT_EMAIL_WT, status: ERROR });
        }
      } else if (subscription === "TM_PROTECT") {
        dispatch({ type: CLIENT_EMAIL_TM_PROTECT, status: LOADING });
        try {
          let response;
          response = await Axios.get(`${ROOT_URL}/client_email_dashboard/`, {
            params,
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          });
          dispatch({
            type: CLIENT_EMAIL_TM_PROTECT,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: CLIENT_EMAIL_TM_PROTECT, status: ERROR });
        }
      }
    }
  };
}

//--------Tags: Delete ClientEmail--------
export function deleteClientEmail({
  email,
  mmType,
  subscription,
  deletionType,
  selectedEmails,
  filters,
  allFlag,
}) {
  let data = {
    subscription: subscription,
    mm_type: mmType,
    deletion_type: deletionType,
  };
  if (deletionType === "SINGLE_EMAIL") {
    data = {
      ...data,
      client_email_id: email.id,
    };
  } else if (deletionType === "CLIENT_EMAIL_ENTITY") {
    if (allFlag) {
      const proprietor = `${filters?.trademarkProprietors}`;
      const contains_term = `${filters?.containsTerm}`;

      const inventor = `${filters?.patentInventors}`;
      const applicant = `${filters?.patentApplicants}`;
      data = {
        ...data,
        proprietor,
        inventor,
        applicant,
        contains_term,
        all_flag: true,
        filter_flag: true,
      };
    } else {
      data = {
        ...data,
        all_flag: false,
        selected_email_entity_ids: selectedEmails,
      };
    }
  }

  return async (dispatch) => {
    dispatch({ type: DELETE_CLIENT_EMAIL, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${ROOT_URL}/client_email_dashboard/`, {
        data,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: DELETE_CLIENT_EMAIL,
        status: SUCCESS,
        payload: { response: response?.data },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: DELETE_CLIENT_EMAIL, status: ERROR });
    }
  };
}

//--------Manager: Set State Client Email --------
export function setStoreData(newState) {
  return (dispatch) => {
    dispatch({ type: SET_STORE, payload: newState });
  };
}

//--------Mark: ClientEmail-Upload--------
export function clientEmailUpload({ uploaded_file, subscription, mmType }) {
  return async (dispatch) => {
    let formData = new FormData();
    formData.append("addition_type", "BULK_ADD");

    formData.append("subscription", subscription);
    if (subscription === "TRADEMARK") {
      formData.append("mm_type", mmType);
    }
    if (uploaded_file && uploaded_file !== undefined) {
      formData.append("excel_file", uploaded_file);
    }
    dispatch({ type: CREATE_CLIENT_EMAIL, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/client_email_dashboard/`,
        formData,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "content-type": "multipart/form-data",
          },
        },
      );
      dispatch({
        type: CREATE_CLIENT_EMAIL,
        status: SUCCESS,
        payload: response?.data,
      });
      return SUCCESS;
    } catch (err) {
      if (!err.response) {
        Modal.error({
          width: 600,
          className: "error-modal",
          content: <NetworkError />,
          okText: "Close",
          onOk() {},
        });
      } else {
        switch (err.response.status) {
          case 400:
            dispatch({
              type: CREATE_CLIENT_EMAIL,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          case 403:
            checkAuthorisation(dispatch, err);
            dispatch({
              type: CREATE_CLIENT_EMAIL,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          default:
            dispatch({
              type: CREATE_CLIENT_EMAIL,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;
        }
      }
    }
  };
}

//--------Mark: ClientEmail-Add--------
export function clientEmailAdd(clientEmailObject) {
  return async (dispatch) => {
    let formData = new FormData();
    formData.append("addition_type", "SINGLE_CLIENT_EMAIL_ENTITY");
    dispatch({ type: CREATE_CLIENT_EMAIL, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/client_email_dashboard/`,
        {
          ...clientEmailObject,

          addition_type: "SINGLE_CLIENT_EMAIL_ENTITY",
        },
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            // "content-type": "multipart/form-data",
          },
        },
      );
      dispatch({
        type: CREATE_CLIENT_EMAIL,
        status: SUCCESS,
        payload: response?.data,
      });
      return SUCCESS;
    } catch (err) {
      if (!err.response) {
        Modal.error({
          width: 600,
          className: "error-modal",
          content: <NetworkError />,
          okText: "Close",
          onOk() {},
        });
      } else {
        switch (err.response.status) {
          case 400:
            dispatch({
              type: CREATE_CLIENT_EMAIL,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          case 403:
            checkAuthorisation(dispatch, err);
            dispatch({
              type: CREATE_CLIENT_EMAIL,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          default:
            dispatch({
              type: CREATE_CLIENT_EMAIL,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;
        }
      }
    }
  };
}

//--------Tags: Edit ClientEmail--------
export function editClientEmail({
  client_email_id,
  client_email,
  company_name,
  person_name,
}) {
  let data = { client_email_id, client_email, company_name, person_name };
  return async (dispatch) => {
    dispatch({ type: EDIT_CLIENT_EMAIL, status: LOADING });
    try {
      let response;
      response = await Axios.put(`${ROOT_URL}/client_email_dashboard/`, data, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });

      dispatch({
        type: EDIT_CLIENT_EMAIL,
        status: SUCCESS,
        payload: { response: response?.data },
      });
      return SUCCESS;
    } catch (err) {
      if (!err.response) {
        Modal.error({
          width: 600,
          className: "error-modal",
          content: <NetworkError />,
          okText: "Close",
          onOk() {},
        });
      } else {
        switch (err.response.status) {
          case 400:
            dispatch({
              type: EDIT_CLIENT_EMAIL,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          case 403:
            checkAuthorisation(dispatch, err);
            dispatch({
              type: EDIT_CLIENT_EMAIL,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;

          default:
            dispatch({
              type: EDIT_CLIENT_EMAIL,
              status: ERROR,
              payload: err.response?.data,
            });
            return ERROR;
        }
      }
    }
  };
}

//--------Manager: Get Client Email Reports --------
export function getClientEmailCustomReport({
  pageNumber,
  filters_required_flag,
  filter_contains_term,
  required_filter,
  filterFlag,
  containsTerm,
  creationOrigin,
  DOC,
}) {
  let contains_term = "";
  let creation_origin = "";
  let date_of_creation = "";

  contains_term = containsTerm ? `${containsTerm}` : "";
  creation_origin = creationOrigin ? `${creationOrigin}` : "";
  date_of_creation = DOC ? `${DOC}` : "";

  return async (dispatch) => {
    let params = {
      page: pageNumber,
    };

    if (filters_required_flag && required_filter !== "") {
      try {
        dispatch({
          type: `CLIENT_EMAIL_CREATION_ORIGIN_FILTERS`,
          status: LOADING,
        });
        let response;
        response = await Axios.get(`${ROOT_URL}/client_email_custom_report/`, {
          params: {
            ...params,
            filters_required_flag: true,
            required_filter: "CREATION_ORIGIN",
            filter_contains_term,
            page_size: 100,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        dispatch({
          type: `CLIENT_EMAIL_CREATION_ORIGIN_FILTERS`,
          status: SUCCESS,
          payload: response?.data,
          pageNumber,
          paginate: pageNumber > 1 ? true : false,
        });
      } catch (err) {
        dispatch({
          type: `CLIENT_EMAIL_CREATION_ORIGIN_FILTERS`,
          status: ERROR,
        });
      }
    } else {
      let params = {
        page: pageNumber,
        contains_term,
        creation_origin,
        date_of_creation,
        filter_flag: filterFlag,
      };
      dispatch({ type: GET_CLIENT_EMAIL_CUSTOM_REPORT, status: LOADING });
      try {
        let response;
        response = await Axios.get(`${ROOT_URL}/client_email_custom_report/`, {
          params,
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        });
        dispatch({
          type: GET_CLIENT_EMAIL_CUSTOM_REPORT,
          status: SUCCESS,
          payload: response?.data,
          pageNumber,
        });
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: GET_CLIENT_EMAIL_CUSTOM_REPORT, status: ERROR });
      }
    }
  };
}

//--------Tags: Create ClientEmail Report--------
export function createCEReport({
  file_name,
  mmType,
  subscription,
  selectedEmails,
  filters,
  allFlag,
  no_email,
}) {
  let data = {
    subscription: subscription,
    mm_type: mmType,
  };
  if (allFlag) {
    const proprietor = `${filters?.trademarkProprietors}`;
    const contains_term = `${filters?.containsTerm}`;
    const inventor = `${filters?.patentInventors}`;
    const applicant = `${filters?.patentApplicants}`;
    data = {
      ...data,
      proprietor,
      inventor,
      applicant,
      contains_term,
      all_flag: true,
      filter_flag: true,
      file_name,
      no_email,
    };
  } else {
    data = {
      ...data,
      all_flag: false,
      selected_email_entity_ids: selectedEmails,
      file_name,
    };
  }

  return async (dispatch) => {
    dispatch({ type: CREATE_CLIENT_EMAIL_REPORT, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/client_email_custom_report/`,
        data,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );

      dispatch({
        type: CREATE_CLIENT_EMAIL_REPORT,
        status: SUCCESS,
        payload: { response: response?.data },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: CREATE_CLIENT_EMAIL_REPORT, status: ERROR });
    }
  };
}
//--------Send Email: LETTER HEAD API--------
export function downloadLetterHead({
  content,
  file_name,
  add_journal_copy,
  letter_head_visible,
  journal_number,
  application_number,
}) {
  // let formData = new FormData();
  // formData.append('content', content)
  // formData.append('file_name', file_name)
  // formData.append('add_journal_copy', add_journal_copy)
  // formData.append('letter_head_visible', letter_head_visible)

  //   if(add_journal_copy){
  //     formData.append('journal_number', journal_number)
  //     formData.append('application_number', application_number)
  //   }

  let responseData = {
    content: content,
    file_name: file_name,
    add_journal_copy: add_journal_copy,
    letter_head_visible: letter_head_visible,
  };

  if (add_journal_copy) {
    responseData = {
      ...responseData,
      journal_number: journal_number,
      application_number: application_number,
    };
  }

  return async (dispatch) => {
    dispatch({ type: DOWNLOAD_EMAIL_CONTENT, status: LOADING });
    try {
      let response;
      response = await Axios.post(`${ROOT_URL}/letter/`, responseData, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-type": "application/json",
        },
        responseType: "blob",
      });
      const blob = response?.data;

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = file_name ? file_name : "file";
      a.click();
      a.remove();
      dispatch({
        type: DOWNLOAD_EMAIL_CONTENT,
        status: SUCCESS,
        payload: response?.data.message,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: DOWNLOAD_EMAIL_CONTENT,
        status: ERROR,
        payload: err.response?.data.message,
      });
    }
  };
}

//--------Email-Template: Get-Email-Template--------
export function getEmailTemplateData({
  subscription,
  product,
  object_id,
  sub_section = "",
  image_recognition_type = null,
}) {
  return async (dispatch) => {
    dispatch({ type: "GET_EMAIL_TEMPLATE_DATA", status: LOADING });
    try {
      let response;
      let params = { product, subscription, object_id, sub_section };
      if (image_recognition_type !== null) {
        params = { ...params, image_recognition_type };
      }
      response = await Axios.get(`${ROOT_URL}/email_template_variable/`, {
        params,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: "GET_EMAIL_TEMPLATE_DATA",
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: "GET_EMAIL_TEMPLATE_DATA",
        status: ERROR,
        payload: err.response?.data,
      });
    }
  };
}

//--------Email-Template: Get-Email-Template--------
export function getEmailTemplateVariables() {
  return async (dispatch) => {
    dispatch({ type: GET_EMAIL_VARS, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${ROOT_URL}/email_template_variable/`, {
        params: { keys: true },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: GET_EMAIL_VARS,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: GET_EMAIL_VARS,
        status: ERROR,
        payload: err.response?.data,
      });
    }
  };
}
