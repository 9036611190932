//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import {
  DownOutlined,
  InfoCircleFilled,
  TagTwoTone,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Menu,
  Input,
  Dropdown,
  Button,
  Pagination,
  notification,
  Drawer,
  Tag,
  message,
  Spin,
  Alert,
  Empty,
  Checkbox,
  Popconfirm,
  Badge,
} from "antd";
import { FixedSizeList } from "react-window";
import { debounce, uniq } from "lodash";

//--------IP-SUITE SUB-COMPONENTS--------
import AddTrademarks from "../../commonComponents/Modals/AddTrademarks";
import TagsDrawer from "../../commonComponents/Drawers/Tags";
import DownloadPortfolioDrawer from "../../commonComponents/Drawers/DownloadPortfolio";

//--------WATCH | MANAGER ACTIONS--------
import {
  markDownload,
  logOut,
} from "../../../redux/actions/commonActions/actions";

//--------MANAGE TRADEMARKS ACTIONS--------
import {
  MT_getTable,
  MT_postManageFocus,
} from "../../../redux/actions/watchActions/manageTrademarks";

//--------IP-SUITE GENERAL FUNCTIONS--------
import {
  changeStateValue_L1,
  changeStateValue_L2,
  changeStateValue_L3,
  compareArrays,
} from "../../../functions/commonFunctions/generalFunctions";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
  WATCH,
} from "../../../constants/commonConstants/generalConstants";

//--------MANAGE TRADEMARKS CONSTANTS--------
import {
  MT_defaultSelectedFilters,
  MT_defaultFiltersSearchTerms,
  MT_defaultSelectedSetters,
  MT_defaultSettersSearchTerms,
  MT_defaultSelectedTrademarks,
} from "../../../constants/watchConstants/manageTrademarks";

//--------STYLESHEETS--------
import "../../../stylesheets/watchStylesheets/manageTrademarks.css";

const { Search } = Input;

class ManageTrademarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagsPage: 10,
      tagPageFilter: 20,
      proprietorsFilterPage: 50,
      selectedFilters: MT_defaultSelectedFilters,
      selectedSetters: MT_defaultSelectedSetters,
      filtersSearchTerms: MT_defaultFiltersSearchTerms,
      settersSearchTerms: MT_defaultSettersSearchTerms,
      selectedTrademarks: MT_defaultSelectedTrademarks,
      addFocusWord: "",
      drawersVisibility: {
        manageTrademark: false,
        manageTags: false,
        downloadPortfolio: false,
      },
      modalsVisibility: {
        addTrademarks: false,
      },
      removeFocusWordsMenu: [],
      filtersVisibility: {},
    };

    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L1_Debounced = debounce(
      this.changeStateValue_L1,
      400,
    );
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.changeStateValue_L2_Debounced = debounce(
      this.changeStateValue_L2,
      400,
    );
    this.changeStateValue_L3 = changeStateValue_L3.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.MT_getTable(1, false, MT_defaultSelectedFilters);
    } else {
      this.props.logOut();
    }
  }

  handlePageChange(pageNumber) {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        this.props.MT_getTable(
          pageNumber,
          this.props.lastAppliedFilters.filterFlag,
          this.props.lastAppliedFilters,
        );
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      default:
        notification["error"]({
          message: "Unexpected Error! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleApplyFilters(searchInput) {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        const { tags, classes, proprietors, containsTerm } =
          this.props.lastAppliedFilters;

        let { selectedFilters } = this.state;
        let filterFlag = false;

        selectedFilters = {
          ...selectedFilters,
          containsTerm: searchInput,
        };

        if (
          !compareArrays(
            selectedFilters.tags,
            MT_defaultSelectedFilters.tags,
          ) ||
          !compareArrays(
            selectedFilters.classes,
            MT_defaultSelectedFilters.classes,
          ) ||
          !compareArrays(
            selectedFilters.proprietors,
            MT_defaultSelectedFilters.proprietors,
          ) ||
          selectedFilters?.containsTerm !==
            MT_defaultSelectedFilters.containsTerm
        ) {
          filterFlag = true;
        } else {
          filterFlag = false;
        }

        if (
          !compareArrays(tags, selectedFilters?.tags) ||
          !compareArrays(classes, selectedFilters?.classes) ||
          !compareArrays(proprietors, selectedFilters?.proprietors) ||
          containsTerm !== selectedFilters?.containsTerm
        ) {
          this.props.MT_getTable(1, filterFlag, selectedFilters);
        } else {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      default:
        break;
    }
  }

  handleResetFilters() {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        const { tags, classes, proprietors, containsTerm } =
          this.props.lastAppliedFilters;

        if (
          compareArrays(tags, MT_defaultSelectedFilters.tags) &&
          compareArrays(classes, MT_defaultSelectedFilters.classes) &&
          compareArrays(proprietors, MT_defaultSelectedFilters.proprietors) &&
          containsTerm === MT_defaultSelectedFilters.containsTerm
        ) {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        } else {
          this.setState({
            selectedFilters: MT_defaultSelectedFilters,
            filtersSearchTerms: MT_defaultFiltersSearchTerms,
          });
          this.props.MT_getTable(1, false, MT_defaultSelectedFilters);
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      default:
        notification["error"]({
          message: "Unable to Reset! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  addFocusWord(focusWord) {
    if (
      this.state.selectedSetters.single.focus_words.add.indexOf(focusWord) ===
      -1
    ) {
      this.setState({
        addFocusWord: "",
        selectedSetters: {
          ...this.state.selectedSetters,
          single: {
            ...this.state.selectedSetters.single,
            focus_words: {
              ...this.state.selectedSetters.single.focus_words,
              add: [
                focusWord,
                ...this.state.selectedSetters.single.focus_words.add,
              ],
            },
          },
        },
      });
    } else {
      notification["warning"]({
        message: "Same Focus Word",
        description: "Focus words cannot be added again.",
      });
    }
  }

  closeManageTrademarkDrawer() {
    this.setState({
      drawersVisibility: {
        ...this.state.drawersVisibility,
        manageTrademark: false,
      },
      selectedSetters: {
        ...this.state.selectedSetters,
        single: MT_defaultSelectedSetters.single,
      },
      removeFocusWordsMenu: [],
      addFocusWord: "",
    });
  }

  async handleConfirmManageTrademark() {
    let response = await this.props.MT_postManageFocus(
      false,
      MT_defaultSelectedFilters,
      this.state.selectedSetters.single,
      false,
      this.state.drawersVisibility.manageTrademark,
    );
    if (response === SUCCESS) {
      message.success("Trademark has been updated successfully.");
      this.closeManageTrademarkDrawer();
    } else {
      message.error("Unable to update trademark. Please try again later!");
    }
  }

  async handleDeleteTrademarks() {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        if (this.state.selectedTrademarks.selectAll) {
          if (this.props.filteredTableCount === 0) {
            notification["warning"]({
              message: "No Trademarks Selected",
              description: "No trademarks are selected to delete.",
            });
            return;
          }
        } else {
          if (this.state.selectedTrademarks.trademarks.length === 0) {
            notification["warning"]({
              message: "No Trademarks Selected",
              description: "No trademarks are selected to delete.",
            });
            return;
          }
        }

        let response = await this.props.MT_postManageFocus(
          this.props.lastAppliedFilters.filterFlag,
          this.props.lastAppliedFilters,
          {},
          this.state.selectedTrademarks.selectAll,
          null,
          this.state.selectedTrademarks.trademarks,
          true,
        );

        if (response === SUCCESS) {
          notification["success"]({
            message: "Trademarks Deleted",
            description:
              "Trademarks are deleted, its information would no longer be available in the report analysis dashboard.",
          });

          if (this.state.selectedTrademarks.selectAll) {
            this.props.MT_getTable(1, false, MT_defaultSelectedFilters);
            this.setState({ selectedFilters: MT_defaultSelectedFilters });
          } else {
            this.props.MT_getTable(
              this.props.pageNumber,
              this.props.lastAppliedFilters.filterFlag,
              this.props.lastAppliedFilters,
            );
          }

          this.setState({ selectedTrademarks: MT_defaultSelectedTrademarks });
        } else {
          notification["error"]({
            message: "Unable To Delete",
            description:
              "Due to an unfortunate error, trademerks cannot be deleted.",
          });
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      default:
        notification["error"]({
          message: "Unexpected Error",
          description:
            "Due to an unexpected error, trademerks cannot be deleted.",
        });
    }
  }

  render() {
    let noFilterData = (
      <Menu>
        <Menu.Item>No Filters Data Found</Menu.Item>
      </Menu>
    );
    let noSetterData = (
      <Menu>
        <Menu.Item>No Setters Data Found</Menu.Item>
      </Menu>
    );
    let tagsMenu = noFilterData;
    let classesMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    // let multipleSetterTagsMenu = noSetterData;
    // let multipleSetterFCMenu = noSetterData;
    let singleSelectTagsMenu = noFilterData;

    if (this.props.setters !== null) {
      const { tags, focus_classes } = this.props.setters;

      if (tags !== null && tags.length > 0) {
        let singleSelectTags = tags.filter(
          (eachTags) =>
            eachTags.name
              .toUpperCase()
              .indexOf(`${this.state.settersSearchTerms.single.tags}`) !== -1,
        );
        // let multipleAddTags = tags.filter(eachTags => eachTags.name.toUpperCase().indexOf(`${this.state.settersSearchTerms.multiple.addTags}`) !== -1);
        // let multipleRemoveTags = tags.filter(eachTags => eachTags.name.toUpperCase().indexOf(`${this.state.settersSearchTerms.multiple.removeTags}`) !== -1);

        singleSelectTagsMenu = (
          <>
            <Input
              placeholder="Search tags"
              onChange={(event) =>
                this.changeStateValue_L2_Debounced(
                  "settersSearchTerms",
                  "single",
                  "tags",
                  event.target.value.toUpperCase(),
                )
              }
              allowClear
              style={{ marginTop: "5px" }}
            />

            <Menu
              onScroll={(x) => {
                const { scrollTop, scrollHeight, clientHeight } = x.target;
                if (scrollTop + clientHeight >= scrollHeight - 20) {
                  this.setState({ tagsPage: this.state.tagsPage + 50 });
                }
              }}
              style={{
                position: "initial",
                height: "200px",
                overflowY: "scroll",
              }}
              selectable={true}
              multiple={true}
              selectedKeys={this.state.selectedSetters.single.tags}
              onSelect={(event) =>
                this.changeStateValue_L2(
                  "selectedSetters",
                  "single",
                  "tags",
                  event.selectedKeys,
                )
              }
              onDeselect={(event) =>
                this.changeStateValue_L2(
                  "selectedSetters",
                  "single",
                  "tags",
                  event.selectedKeys,
                )
              }
            >
              {singleSelectTags
                .slice(0, this.state.tagsPage)
                .map((eachCategory) => (
                  <Menu.Item key={eachCategory.id}>
                    <TagTwoTone twoToneColor={`${eachCategory.color_code}`} />{" "}
                    {eachCategory.name}
                  </Menu.Item>
                ))}
            </Menu>
          </>
        );

        // multipleSetterTagsMenu = (
        //     <>
        //         <div>
        //             <div className="Setters-Head">
        //                 Add Tags
        //                 <Button
        //                     type="link"
        //                     onClick={() => this.changeStateValue_L2("selectedSetters", "multiple", "addTags", [])}
        //                     style={{ float: "right" }}
        //                 >Clear</Button>
        //             </div>
        //             <div className="Setters-Head">
        //                 Remove Tags
        //                 <Button
        //                     type="link"
        //                     onClick={() => this.changeStateValue_L2("selectedSetters", "multiple", "removeTags", [])}
        //                     style={{ float: "right" }}
        //                 >Clear</Button>
        //             </div>
        //         </div>
        //         <div className="Tags-Setter">
        //             <div className="Tags-Setter-Menu">
        //                 <Input
        //                     placeholder="Search tags"
        //                     onChange={(event) => this.changeStateValue_L2_Debounced("settersSearchTerms", "multiple", "addTags", event.target.value.toUpperCase())}
        //                     allowClear
        //                 />
        //                 <FixedSizeList
        //                     className="List"
        //                     height={150}
        //                     itemCount={multipleAddTags.length}
        //                     itemSize={35}
        //                     itemData={multipleAddTags}
        //                     width={300}
        //                 >
        //                     {
        //                         ({ index, style, data }) => {
        //                             return (
        //                                 <div
        //                                     key={data[index].id}
        //                                     style={{
        //                                         ...style,
        //                                         padding: "5px",
        //                                         overflow: "hidden",
        //                                         textOverflow: "ellipsis",
        //                                         whiteSpace: "nowrap"
        //                                     }}
        //                                     className={`ant-dropdown-menu-item ${this.state.selectedSetters.multiple.addTags.indexOf(data[index].id) !== -1 ? "ant-dropdown-menu-item-selected" : ""}`}
        //                                     onClick={() => {
        //                                         let { addTags, removeTags } = this.state.selectedSetters.multiple;
        //                                         if (removeTags.indexOf(data[index].id) === -1) {
        //                                             if (addTags.indexOf(data[index].id) !== -1) {
        //                                                 this.changeStateValue_L2("selectedSetters", "multiple", "addTags", addTags.filter(eachTag => eachTag !== data[index].id));
        //                                             } else {
        //                                                 this.changeStateValue_L2("selectedSetters", "multiple", "addTags", [...addTags, data[index].id]);
        //                                             }
        //                                         } else {
        //                                             notification['warning']({
        //                                                 message: 'Cannot Add & Remove Simultaneously',
        //                                                 description: 'Doing both at the same time will have no change.',
        //                                             });
        //                                         }
        //                                     }}
        //                                 >
        //                                     <Icon type="tag" theme="twoTone" twoToneColor={`${data[index].color_code}`} /> {data[index].name}
        //                                 </div>
        //                             );
        //                         }
        //                     }
        //                 </FixedSizeList>
        //             </div>
        //             <div className="Tags-Setter-Menu">
        //                 <Input
        //                     placeholder="Search tags"
        //                     onChange={(event) => this.changeStateValue_L2_Debounced("settersSearchTerms", "multiple", "removeTags", event.target.value.toUpperCase())}
        //                     allowClear
        //                 />
        //                 <FixedSizeList
        //                     className="List"
        //                     height={150}
        //                     itemCount={multipleRemoveTags.length}
        //                     itemSize={35}
        //                     itemData={multipleRemoveTags}
        //                     width={300}
        //                 >
        //                     {
        //                         ({ index, style, data }) => {
        //                             return (
        //                                 <div
        //                                     key={data[index].id}
        //                                     style={{
        //                                         ...style,
        //                                         padding: "5px",
        //                                         overflow: "hidden",
        //                                         textOverflow: "ellipsis",
        //                                         whiteSpace: "nowrap"
        //                                     }}
        //                                     className={`ant-dropdown-menu-item ${this.state.selectedSetters.multiple.removeTags.indexOf(data[index].id) !== -1 ? "ant-dropdown-menu-item-selected" : ""}`}
        //                                     onClick={() => {
        //                                         let { removeTags, addTags } = this.state.selectedSetters.multiple;
        //                                         if (addTags.indexOf(data[index].id) === -1) {
        //                                             if (removeTags.indexOf(data[index].id) !== -1) {
        //                                                 this.changeStateValue_L2("selectedSetters", "multiple", "removeTags", removeTags.filter(eachTag => eachTag !== data[index].id));
        //                                             } else {
        //                                                 this.changeStateValue_L2("selectedSetters", "multiple", "removeTags", [...removeTags, data[index].id]);
        //                                             }
        //                                         } else {
        //                                             notification['warning']({
        //                                                 message: 'Cannot Add & Remove Simultaneously',
        //                                                 description: 'Doing both at the same time will have no change.',
        //                                             });
        //                                         }
        //                                     }}
        //                                 >
        //                                     {data[index].name}
        //                                 </div>
        //                             );
        //                         }
        //                     }
        //                 </FixedSizeList>
        //             </div>
        //         </div>
        //         <div style={{ textAlign: "center", padding: "0px 5px 10px 5px" }}>
        //             <Button
        //                 type="primary"
        //                 style={{ padding: "0px 285px" }}
        //                 onClick={() => {
        //                     if (this.state.selectedSetters.multiple.addTags.length > 0 || this.state.selectedSetters.multiple.removeTags.length > 0) {
        //                         if ((this.props.filteredTableCount > 0) && (this.props.tableDataStatus === SUCCESS)) {
        //                             this.props.MT_postManageFocus(
        //                                 this.props.lastAppliedFilters.filterFlag,
        //                                 this.props.lastAppliedFilters,
        //                                 {
        //                                     tags: {
        //                                         add: this.state.selectedSetters.multiple.addTags,
        //                                         remove: this.state.selectedSetters.multiple.removeTags
        //                                     },
        //                                 },
        //                                 true
        //                             );
        //                         } else {
        //                             notification['warning']({
        //                                 message: 'Cannot Set Tags',
        //                                 description: 'Unable to set tags to the filtered results.',
        //                             });
        //                         }
        //                     } else {
        //                         notification['warning']({
        //                             message: 'No Tags Selected',
        //                             description: 'There are no tags to add or remove.',
        //                         });
        //                     }
        //                 }}
        //             >Apply</Button>
        //         </div>
        //     </>
        // );
      }

      if (focus_classes !== null && focus_classes.length > 0) {
        // multipleSetterFCMenu = (
        //     <>
        //         <div>
        //             <div className="Setters-Head-FC">
        //                 Add Focus Classes
        //                 <Button
        //                     type="link"
        //                     onClick={() => this.changeStateValue_L2("selectedSetters", "multiple", "addClasses", [])}
        //                     style={{ float: "right" }}
        //                 >Clear</Button>
        //             </div>
        //             <div className="Setters-Head-FC">
        //                 Remove Focus Classes
        //                 <Button
        //                     type="link"
        //                     onClick={() => this.changeStateValue_L2("selectedSetters", "multiple", "removeClasses", [])}
        //                     style={{ float: "right" }}
        //                 >Clear</Button>
        //             </div>
        //         </div>
        //         <div className="Tags-Setter-FC">
        //             <Menu
        //                 style={{
        //                     height: "200px",
        //                     width: "200px",
        //                     overflow: "auto",
        //                     margin: "5px"
        //                 }}
        //                 selectable={true}
        //                 multiple={true}
        //                 selectedKeys={this.state.selectedSetters.multiple.addClasses}
        //                 onSelect={(event) => {
        //                     let { removeClasses } = this.state.selectedSetters.multiple;
        //                     if (removeClasses.indexOf(event.key) === -1) {
        //                         this.changeStateValue_L2("selectedSetters", "multiple", "addClasses", event.selectedKeys);
        //                     } else {
        //                         notification['warning']({
        //                             message: 'Cannot Add & Remove Simultaneously',
        //                             description: 'Doing both at the same time will have no change.',
        //                         });
        //                     }
        //                 }}
        //                 onDeselect={(event) => this.changeStateValue_L2("selectedSetters", "multiple", "addClasses", event.selectedKeys)}
        //             >
        //                 {
        //                     focus_classes.map(eachClass => (
        //                         <Menu.Item key={eachClass.id}>
        //                             {eachClass.number}
        //                         </Menu.Item>
        //                     ))
        //                 }
        //             </Menu>
        //             <Menu
        //                 style={{
        //                     height: "200px",
        //                     width: "200px",
        //                     overflow: "auto",
        //                     margin: "5px"
        //                 }}
        //                 selectable={true}
        //                 multiple={true}
        //                 selectedKeys={this.state.selectedSetters.multiple.removeClasses}
        //                 onSelect={(event) => {
        //                     let { addClasses } = this.state.selectedSetters.multiple;
        //                     if (addClasses.indexOf(event.key) === -1) {
        //                         this.changeStateValue_L2("selectedSetters", "multiple", "removeClasses", event.selectedKeys);
        //                     } else {
        //                         notification['warning']({
        //                             message: 'Cannot Add & Remove Simultaneously',
        //                             description: 'Doing both at the same time will have no change.',
        //                         });
        //                     }
        //                 }}
        //                 onDeselect={(event) => this.changeStateValue_L2("selectedSetters", "multiple", "removeClasses", event.selectedKeys)}
        //             >
        //                 {
        //                     focus_classes.map(eachClass => (
        //                         <Menu.Item key={eachClass.id}>
        //                             {eachClass.number}
        //                         </Menu.Item>
        //                     ))
        //                 }
        //             </Menu>
        //         </div>
        //         <div style={{ textAlign: "center", padding: "0px 5px 10px 5px" }}>
        //             <Button
        //                 type="primary"
        //                 style={{ padding: "0px 190px" }}
        //                 onClick={
        //                     () => {
        //                         if (this.state.selectedSetters.multiple.addClasses.length > 0 || this.state.selectedSetters.multiple.removeClasses.length > 0) {
        //                             if ((this.props.filteredTableCount > 0) && (this.props.tableDataStatus === SUCCESS)) {
        //                                 this.props.MT_postManageFocus(
        //                                     this.props.lastAppliedFilters.filterFlag,
        //                                     this.props.lastAppliedFilters,
        //                                     {
        //                                         focus_classes: {
        //                                             add: this.state.selectedSetters.multiple.addClasses,
        //                                             remove: this.state.selectedSetters.multiple.removeClasses
        //                                         },
        //                                     },
        //                                     true
        //                                 );
        //                             } else {
        //                                 notification['warning']({
        //                                     message: 'Cannot Set Focus Classes',
        //                                     description: 'Unable to set classes to the filtered results.',
        //                                 });
        //                             }
        //                         } else {
        //                             notification['warning']({
        //                                 message: 'No Focus Classes Selected',
        //                                 description: 'There are no classes to add or remove.',
        //                             });
        //                         }
        //                     }
        //                 }
        //             >Apply</Button>
        //         </div>
        //     </>
        // );
      }
    }

    if (this.props.filters !== null) {
      const { proprietors, classes, tags } = this.props.filters;

      if (tags !== null && tags.length > 0) {
        let filteredTags = tags
          .filter(
            (eachTags) =>
              eachTags.name
                .toUpperCase()
                .indexOf(`${this.state.filtersSearchTerms.tags}`) !== -1,
          )
          .slice(0, this.state?.tagPageFilter);
        tagsMenu = (
          <>
            <Input
              placeholder="Search tags"
              onChange={(event) =>
                this.changeStateValue_L1_Debounced(
                  "filtersSearchTerms",
                  "tags",
                  event.target.value.toUpperCase(),
                )
              }
              allowClear
            />
            <Menu
              disabled={this.state.noTagToggle}
              onScroll={(x) => {
                const { scrollTop, scrollHeight, clientHeight } = x.target;
                if (scrollTop + clientHeight >= scrollHeight - 20) {
                  this.setState({
                    tagPageFilter: this.state?.tagPageFilter + 50,
                  });
                }
              }}
              style={{
                position: "initial",
                height: "200px",
                overflowY: "scroll",
                width: "250px",
              }}
              selectable
              multiple
              selectedKeys={this.state.selectedFilters?.tags}
              onSelect={(event) =>
                this.changeStateValue_L1(
                  "selectedFilters",
                  "tags",
                  event.selectedKeys,
                )
              }
              onDeselect={(event) =>
                this.changeStateValue_L1(
                  "selectedFilters",
                  "tags",
                  event.selectedKeys,
                )
              }
            >
              {filteredTags.map((eachCategory) => (
                <Menu.Item key={eachCategory.id}>
                  <TagTwoTone twoToneColor={`${eachCategory.color_code}`} />{" "}
                  {eachCategory.name}
                </Menu.Item>
              ))}
            </Menu>
          </>
        );
      }

      if (classes !== null && classes.length > 0) {
        classesMenu = (
          <Menu
            selectable={true}
            multiple={true}
            selectedKeys={this.state.selectedFilters.classes}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "classes",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "classes",
                event.selectedKeys,
              )
            }
          >
            {classes.map((eachClass) => (
              <Menu.Item key={eachClass.id}>{eachClass.number}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (proprietors !== null && proprietors.length > 0) {
        let filteredProprietors = proprietors
          .filter(
            (eachProprietor) =>
              eachProprietor.name
                .toLowerCase()
                .indexOf(`${this.state.filtersSearchTerms.proprietors}`) !== -1,
          )
          .slice(0, this.state.proprietorsFilterPage);
        proprietorsMenu = (
          <>
            <Input
              placeholder="Search proprietors"
              onChange={(event) =>
                this.changeStateValue_L1_Debounced(
                  "filtersSearchTerms",
                  "proprietors",
                  event.target.value.toLowerCase(),
                )
              }
              allowClear
            />
            <Menu
              style={{
                position: "initial",
                height: "200px",
                overflowY: "scroll",
                width: "250px",
              }}
              onScroll={(x) => {
                const { scrollTop, scrollHeight, clientHeight } = x.target;
                if (scrollTop + clientHeight >= scrollHeight - 800) {
                  this.setState({
                    proprietorsFilterPage:
                      this.state.proprietorsFilterPage + 50,
                  });
                }
              }}
              selectable
              multiple
              selectedKeys={this.state.selectedFilters?.proprietors}
              onSelect={(event) =>
                this.changeStateValue_L1(
                  "selectedFilters",
                  "proprietors",
                  event.selectedKeys,
                )
              }
              onDeselect={(event) =>
                this.changeStateValue_L1(
                  "selectedFilters",
                  "proprietors",
                  event.selectedKeys,
                )
              }
            >
              {filteredProprietors.map((eachCategory) => (
                <Menu.Item key={eachCategory.id}>{eachCategory.name}</Menu.Item>
              ))}
            </Menu>
          </>
        );
      }
    }

    console.log("---", this.state);
    return (
      <>
        <header className="General-Header m-top-10">
          <Dropdown
            overlay={tagsMenu}
            overlayClassName="Filters-Menu"
            open={this.state.filtersVisibility.tagsMenu}
            onVisibleChange={(event) =>
              this.changeStateValue_L1("filtersVisibility", "tagsMenu", event)
            }
          >
            <Button
              className={`${
                this.state.selectedFilters.tags.length > 0
                  ? "Filters-Button-Selected"
                  : "Filters-Button-Empty"
              }`}
            >
              Tags
              <DownOutlined />
            </Button>
          </Dropdown>
          <Dropdown
            overlay={classesMenu}
            overlayClassName="Filters-Menu-Scroll"
            open={this.state.filtersVisibility.classesMenu}
            onVisibleChange={(event) =>
              this.changeStateValue_L1(
                "filtersVisibility",
                "classesMenu",
                event,
              )
            }
          >
            <Button
              className={`${
                this.state.selectedFilters.classes.length > 0
                  ? "Filters-Button-Selected"
                  : "Filters-Button-Empty"
              }`}
            >
              Classes
              <DownOutlined />
            </Button>
          </Dropdown>
          <Dropdown
            overlay={proprietorsMenu}
            overlayClassName="Filters-Menu"
            open={this.state.filtersVisibility.proprietorsMenu}
            onVisibleChange={(event) =>
              this.changeStateValue_L1(
                "filtersVisibility",
                "proprietorsMenu",
                event,
              )
            }
          >
            <Button
              className={`${
                this.state.selectedFilters.proprietors.length > 0
                  ? "Filters-Button-Selected"
                  : "Filters-Button-Empty"
              }`}
            >
              Proprietors
              <DownOutlined />
            </Button>
          </Dropdown>
          <Search
            placeholder="Search application number or name"
            onSearch={(searchInput) => this.handleApplyFilters(searchInput)}
            enterButton={
              <Button
                style={{
                  backgroundColor: "#4285f4",
                  color: "#ffffff",
                  fontWeight: "600",
                }}
              >
                Apply
              </Button>
            }
            style={{ width: "20vw", minWidth: "150px" }}
          />
          <Button type="link" onClick={() => this.handleResetFilters()}>
            Reset
          </Button>
          <div style={{ float: "right" }}>
            {(this.state.selectedTrademarks.selectAll ||
              this.state.selectedTrademarks.trademarks.length > 0) && (
              <Popconfirm
                placement="bottomLeft"
                title={
                  <>
                    <div>
                      Deleting the trademarks will remove them from previous
                      report analysis dashboards too.
                    </div>
                    <div>Are you sure to delete them?</div>
                  </>
                }
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.handleDeleteTrademarks()}
              >
                <Badge
                  count={
                    this.state.selectedTrademarks.selectAll
                      ? "All"
                      : uniq(this.state.selectedTrademarks.trademarks).length
                  }
                >
                  <Button
                    danger
                    style={{
                      margin: "0px 5px",
                      backgroundColor: "#FDE5E7",
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    loading={this.props.deleteTrademarkStatus === LOADING}
                    icon={<DeleteOutlined />}
                  >
                    Delete Trademarks
                  </Button>
                </Badge>
              </Popconfirm>
            )}
            <Button
              type="primary"
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "manageTags",
                  true,
                )
              }
              style={{
                background: "#59c4af",
                borderColor: "#59c4af",
                margin: "0px 10px",
              }}
            >
              Manage Tags
            </Button>
            <Button
              type="primary"
              onClick={() =>
                this.changeStateValue_L1(
                  "modalsVisibility",
                  "addTrademarks",
                  true,
                )
              }
              style={{ margin: "0px 5px" }}
            >
              Add Trademarks
            </Button>
            <Button
              type="primary"
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "downloadPortfolio",
                  true,
                )
              }
              style={{ margin: "0 10px" }}
            >
              Download Portfolio
            </Button>
          </div>
        </header>
        <div
          className="General-Content vertical_scroll"
          style={{
            height: `${
              this.props.watchAlert.length > 0
                ? "calc(100vh - 135px)"
                : "calc(100vh - 95px)"
            }`,
          }}
        >
          <Alert
            style={{ margin: "5px" }}
            message={
              <span>
                <InfoCircleFilled
                  style={{
                    fontSize: "20px",
                    verticalAlign: "0px",
                    color: "#ffc107",
                    marginRight: "10px",
                    backgroundColor: "#ffffff",
                    border: "2px solid #ffffff",
                    borderRadius: "50%",
                  }}
                />
                Removing focus words would remove the infringement analysis for
                the words from the previous journals.
              </span>
            }
            type="warning"
          />
          {this.props.noFocusWords.length > 0 && (
            <Alert
              className="trademark-alert-message"
              style={{ margin: "5px" }}
              message={`Applications with no focus words: ${this.props.noFocusWords.map(
                (eachWord) => ` ${eachWord}`,
              )}`}
              type="error"
            />
          )}
          {this.props.noFocusClasses.length > 0 && (
            <Alert
              className="trademark-alert-message"
              style={{ margin: "5px" }}
              message={`Applications with no focus classes: ${this.props.noFocusClasses.map(
                (eachClass) => ` ${eachClass}`,
              )}`}
              type="error"
            />
          )}
          <table className="Table">
            <thead className="Table-Head">
              <tr className="Table-Head-Row Blue-Shadow">
                <th
                  className="Table-Head-Cell"
                  style={{ width: "4%", minWidth: "50px" }}
                >
                  <Checkbox
                    disabled={this.props.filteredTableCount <= 0}
                    checked={this.state.selectedTrademarks.selectAll}
                    onChange={(event) =>
                      this.changeStateValue_L1(
                        "selectedTrademarks",
                        "selectAll",
                        event.target.checked,
                      )
                    }
                  />
                </th>
                <th className="Table-Head-Cell">APPLICATION</th>
                <th className="Table-Head-Cell">IMAGE</th>
                <th className="Table-Head-Cell">APPLIED FOR</th>
                <th className="Table-Head-Cell">PROPRIETORS</th>
                <th className="Table-Head-Cell">CLASSES</th>
                <th className="Table-Head-Cell">FOCUS WORDS</th>
                <th className="Table-Head-Cell">
                  FOCUS CLASSES
                  {/* <Dropdown
                                    overlay={multipleSetterFCMenu}
                                    overlayClassName="Filters-Menu"
                                    placement="bottomRight"
                                    visible={this.state.filtersVisibility.multipleSetterFCMenu}
                                    onVisibleChange={(event) => this.changeStateValue_L1("filtersVisibility", "multipleSetterFCMenu", event)}
                                >
                                    <Button className="Setters-Button">
                                        FOCUS CLASSES<Icon type="down"/>
                                    </Button>
                                </Dropdown> */}
                </th>
                <th className="Table-Head-Cell">
                  TAGS
                  {/* <Dropdown
                                    overlay={multipleSetterTagsMenu}
                                    overlayClassName="Filters-Menu"
                                    placement="bottomRight"
                                    visible={this.state.filtersVisibility.multipleSetterTagsMenu}
                                    onVisibleChange={(event) => this.changeStateValue_L1("filtersVisibility", "multipleSetterTagsMenu", event)}
                                >
                                    <Button className="Setters-Button">
                                        TAGS<Icon type="down" />
                                    </Button>
                                </Dropdown> */}
                </th>
                <th className="Table-Head-Cell" style={{ minWidth: "70px" }}>
                  EDIT
                </th>
              </tr>
            </thead>
            <tbody className="Table-Body">
              {/* If the data is received successfully and it is more than 0, the below code will run and generate a table rows */}
              {this.props.tableDataStatus === SUCCESS &&
                this.props.filteredTableCount > 0 &&
                this.props.tableData.map((eachRow, rowIndex) => {
                  return (
                    <tr
                      key={eachRow.application_number}
                      className={`Table-Body-Row ${
                        rowIndex % 2 === 0 ? "Row-Even" : "Row-Odd"
                      }`}
                    >
                      <td style={{ verticalAlign: "middle" }}>
                        <Checkbox
                          disabled={this.state.selectedTrademarks.selectAll}
                          checked={
                            this.state.selectedTrademarks.trademarks.indexOf(
                              eachRow.application_number,
                            ) !== -1 || this.state.selectedTrademarks.selectAll
                              ? true
                              : false
                          }
                          onClick={(event) => {
                            if (event.target.checked) {
                              this.changeStateValue_L1(
                                "selectedTrademarks",
                                "trademarks",
                                [
                                  ...this.state.selectedTrademarks.trademarks,
                                  eachRow.application_number,
                                ],
                              );
                            } else {
                              this.changeStateValue_L1(
                                "selectedTrademarks",
                                "trademarks",
                                this.state.selectedTrademarks.trademarks.filter(
                                  (eachApplication) =>
                                    eachApplication !==
                                    eachRow.application_number,
                                ),
                              );
                            }
                          }}
                        />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {eachRow.application_number}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {eachRow.associated_image ? (
                          <img
                            src={eachRow.associated_image}
                            height="70px"
                            width="70px"
                            alt=""
                          />
                        ) : (
                          "NO IMAGE AVAILABLE"
                        )}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {eachRow.applied_for ? eachRow.applied_for : "N/A"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {eachRow.proprietors.map(
                          (eachProprietor, index, allProprietors) =>
                            allProprietors.length === index + 1
                              ? `${eachProprietor}`
                              : `${eachProprietor}, `,
                        )}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {eachRow.associated_classes.map(
                          (eachClass, index, allClasses) =>
                            allClasses.length === index + 1
                              ? `${eachClass}`
                              : `${eachClass}, `,
                        )}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {eachRow.focus_words.length > 0
                          ? eachRow.focus_words.map(
                              (eachWord, index, allWords) =>
                                allWords.length === index + 1
                                  ? `${eachWord.word}`
                                  : `${eachWord.word}, `,
                            )
                          : "N/A"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {eachRow.focus_classes.length > 1
                          ? eachRow.focus_classes
                              .filter((eachClass) => eachClass.id !== 51)
                              .map((eachClass, index, allClasses) =>
                                allClasses.length === index + 1
                                  ? `${eachClass.number}`
                                  : `${eachClass.number}, `,
                              )
                          : "N/A"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {eachRow.tags.length > 0
                          ? eachRow.tags.map((tag) => {
                              return (
                                <span
                                  className="Tag"
                                  style={{
                                    color: `${tag.color_code}`,
                                    border: `1px solid ${tag.color_code}`,
                                  }}
                                  key={tag.id}
                                >
                                  {tag.name.toUpperCase()}
                                </span>
                              );
                            })
                          : "N/A"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            let focus_classes = eachRow.focus_classes.map(
                              (eachClass) => `${eachClass.id}`,
                            );
                            let tags = eachRow.tags.map((tag) => `${tag.id}`);
                            this.changeStateValue_L1(
                              "selectedSetters",
                              "single",
                              {
                                ...this.state.selectedSetters.single,
                                tags,
                                focus_classes,
                              },
                            );
                            this.setState({
                              removeFocusWordsMenu: eachRow.focus_words,
                              drawersVisibility: {
                                ...this.state.drawersVisibility,
                                manageTrademark: eachRow.application_number,
                              },
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  );
                })}

              {/* While the request's response has not been received yet, this loading screen eill appear. */}
              {this.props.tableDataStatus === LOADING && (
                <tr>
                  <td colSpan="10" style={{ height: "50vh" }}>
                    <Spin size="large" />
                  </td>
                </tr>
              )}

              {/* If the request's response is received successfully but there is no data to show, the below screen will appear. */}
              {this.props.tableDataStatus === SUCCESS &&
                this.props.filteredTableCount === 0 && (
                  <tr style={{ height: "50vh" }}>
                    <td colSpan="10">
                      <Empty />
                    </td>
                  </tr>
                )}

              {/* When the data is not received due to some error */}
              {this.props.tableDataStatus === ERROR && (
                <tr>
                  <td colSpan="10" style={{ height: "50vh" }}>
                    {/* <h1>Unfortunately, due to the occurrence of an error, we were unable to fetch the data from the server.</h1>
                                    <h1>We apologize for the inconvenience. Please, try again after some time.</h1> */}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <footer className="General-Footer">
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            current={this.props.pageNumber}
            total={this.props.filteredTableCount}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showSizeChanger={false}
            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
          />
        </footer>

        {/* When Edit is clicked, this drawer will be opened */}
        <Drawer
          title={
            <h4>
              Manage Trademark (
              {`${
                this.state.drawersVisibility.manageTrademark
                  ? this.state.drawersVisibility.manageTrademark
                  : ""
              }`}
              )
            </h4>
          }
          placement="right"
          width={400}
          closable={false}
          open={this.state.drawersVisibility.manageTrademark ? true : false}
          onClose={() => this.closeManageTrademarkDrawer()}
        >
          {this.state.drawersVisibility.manageTrademark && (
            <>
              Select focus classes:
              <Button
                rootStyle={{ float: "right" }}
                type="link"
                onClick={() =>
                  this.changeStateValue_L2(
                    "selectedSetters",
                    "single",
                    "focus_classes",
                    [51],
                  )
                }
              >
                Clear
              </Button>
              <Menu
                style={{
                  position: "initial",
                  height: "200px",
                  overflowY: "scroll",
                }}
                rootStyle={{
                  height: "150px",
                  width: "350px",
                  overflow: "auto",
                  marginTop: "5px",
                }}
                selectable={true}
                multiple={true}
                selectedKeys={this.state.selectedSetters.single.focus_classes}
                onSelect={(event) =>
                  this.changeStateValue_L2(
                    "selectedSetters",
                    "single",
                    "focus_classes",
                    event.selectedKeys,
                  )
                }
                onDeselect={(event) =>
                  this.changeStateValue_L2(
                    "selectedSetters",
                    "single",
                    "focus_classes",
                    event.selectedKeys,
                  )
                }
              >
                {this.props.setters.focus_classes.map((eachClass) => (
                  <Menu.Item key={eachClass.id}>{eachClass.number}</Menu.Item>
                ))}
              </Menu>
              <br />
              Add focus words:
              {this.state.selectedSetters.single.focus_words.add.map(
                (eachWord) => (
                  <Tag
                    key={eachWord}
                    closable
                    onClose={() =>
                      this.changeStateValue_L3(
                        "selectedSetters",
                        "single",
                        "focus_words",
                        "add",
                        this.state.selectedSetters.single.focus_words.add.filter(
                          (word) => word !== eachWord,
                        ),
                      )
                    }
                    rootStyle={{
                      margin: "5px",
                      verticalAlign: "middle",
                    }}
                  >
                    {eachWord}
                  </Tag>
                ),
              )}
              <Search
                value={this.state.addFocusWord}
                onChange={(event) =>
                  event.target.value.indexOf("  ") === -1
                    ? this.setState({
                        addFocusWord: event.target.value.toLowerCase(),
                      })
                    : null
                }
                enterButton={
                  <Button
                    rootStyle={{
                      backgroundColor: "#4285f4",
                      color: "#ffffff",
                      fontWeight: "600",
                    }}
                  >
                    Add
                  </Button>
                }
                placeholder="Enter focus word"
                onSearch={(searchInput) => this.addFocusWord(searchInput)}
                onPressEnter={(event) => this.addFocusWord(event.target.value)}
                rootStyle={{ marginTop: "5px" }}
              />
              <br />
              <br />
              Select focus words to remove:
              <Menu
                rootStyle={{
                  maxHeight: "150px",
                  width: "350px",
                  overflow: "auto",
                }}
                selectable={true}
                multiple={true}
                selectedKeys={
                  this.state.selectedSetters.single.focus_words.remove
                }
                onSelect={(event) =>
                  this.changeStateValue_L3(
                    "selectedSetters",
                    "single",
                    "focus_words",
                    "remove",
                    event.selectedKeys,
                  )
                }
                onDeselect={(event) =>
                  this.changeStateValue_L3(
                    "selectedSetters",
                    "single",
                    "focus_words",
                    "remove",
                    event.selectedKeys,
                  )
                }
              >
                {this.state.removeFocusWordsMenu.map((eachWord) => (
                  <Menu.Item key={eachWord.id}>{eachWord.word}</Menu.Item>
                ))}
              </Menu>
              <br />
              Select tags:
              <Button
                rootStyle={{ float: "right" }}
                type="link"
                onClick={() =>
                  this.changeStateValue_L2(
                    "selectedSetters",
                    "single",
                    "tags",
                    [],
                  )
                }
              >
                Clear
              </Button>
              {singleSelectTagsMenu}
              <br />
              <Button
                type="primary"
                ghost
                onClick={() => this.handleConfirmManageTrademark()}
                rootStyle={{ marginRight: "10px" }}
                loading={this.props.editTrademarkStatus === LOADING}
              >
                Confirm
              </Button>
              <Button
                danger
                ghost
                style={{ marginLeft: "10px" }}
                onClick={() => this.closeManageTrademarkDrawer()}
              >
                Cancel
              </Button>
            </>
          )}
        </Drawer>

        <AddTrademarks
          visible={this.state.modalsVisibility.addTrademarks}
          onCloseModal={() =>
            this.changeStateValue_L1("modalsVisibility", "addTrademarks", false)
          }
          product={WATCH}
        />

        <TagsDrawer
          visible={this.state.drawersVisibility.manageTags}
          onCloseDrawer={() =>
            this.changeStateValue_L1("drawersVisibility", "manageTags", false)
          }
        />

        <DownloadPortfolioDrawer
          visible={this.state.drawersVisibility.downloadPortfolio}
          onCloseDrawer={() =>
            this.changeStateValue_L1(
              "drawersVisibility",
              "downloadPortfolio",
              false,
            )
          }
          product={"watch"}
        />
      </>
    );
  }

  componentWillUnmount() {}
}

const mapDispatchToProps = {
  MT_getTable,
  MT_postManageFocus,
  markDownload,
  logOut,
};

function mapStateToProps(state) {
  const {
    tableData,
    tableDataStatus,
    filters,
    setters,
    filteredTableCount,
    pageNumber,
    lastAppliedFilters,
    noFocusWords,
    noFocusClasses,
    editTrademarkStatus,
    deleteTrademarkStatus,
  } = state.watchState.manageTrademarks;
  return deepFreeze({
    stateRedux: state,
    tableData,
    tableDataStatus,
    filters,
    setters,
    filteredTableCount,
    pageNumber,
    lastAppliedFilters,
    noFocusWords,
    noFocusClasses,
    editTrademarkStatus,
    deleteTrademarkStatus,

    watchAlert: state.alertMessages.watch,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTrademarks);
