import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import _, { debounce } from "lodash";

import {
  CarryOutTwoTone,
  DownOutlined,
  MessageTwoTone,
  MinusCircleOutlined,
  TagTwoTone,
} from "@ant-design/icons";

import { Icon as LegacyIcon } from "@ant-design/compatible";

import {
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Button,
  Input,
  message,
  Typography,
  Checkbox,
  Switch,
  notification,
  Tooltip,
  Layout,
  Spin,
  Badge,
} from "antd";

//--------MANAGER-PATENT ACTIONS--------
import { getDashboard } from "../../../../redux/actions/managerActions/profile/actions";
import { getTrademark } from "../../../../redux/actions/managerActions/trademarkDashboard/actions";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import {
  changeStateValue_L0,
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../../../functions/commonFunctions/generalFunctions";
import {
  TM_DefaultSelectedFilters,
  TM_DefaultSearchFilters,
  TM_DefaultFilterConfig,
} from "../../../../constants/managerConstants/trademarkConstants";
import DateRangePicker from "../../../../functions/commonFunctions/dateRangePicker";

//--------MODALS--------
import CreateTask from "../../to-do-task/CreateTask";
import DownloadReportModal from "../../report/DownloadReportModal";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { TM } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_TM_NOTES,
  PATCH_TM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import { getEmailTemplateData } from "../../../../redux/actions/commonActions/actions";
import FilterSearchBasic from "../../../commonComponents/FilterComponents/FilterSearchBasic";
import FilterSearchPaginate from "../../../commonComponents/FilterComponents/FilterSearchPaginate";

const { Text, Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

class TotalTrademark extends Component {
  constructor(props) {
    super(props);
    let loc = window.location.pathname.split("/");
    const root = loc.includes("your-trademark")
      ? "YOUR_TM"
      : loc.includes("opposed-trademark") && loc.includes("total-marks")
      ? "OPPOSED_TM"
      : "STATUS_TRACKER";
    let mmType = "trademark";
    if (root === "OPPOSED_TM") mmType = "opposed";
    else if (root === "STATUS_TRACKER") mmType = "tracker";
    this.state = {
      open: null,
      noTagsdata: false,
      visible: false,
      management_trademark_id: "",
      proprietor_id: "",
      dateRange: true,
      range1: null,
      range2: null,

      noTagToggle: false,
      tag_color: "",

      addTaskVisible: false,
      changeSwitch: false,

      add_trademark_id: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,

      pseudo_add_trademark_id: [],
      pseudo_selectedRowKeys: [],
      pseudo_selectAll: false,

      selectedFilters: {
        ip_india: TM_DefaultSelectedFilters,
        pseudo: TM_DefaultSelectedFilters,
      },

      searchFilters: {
        ip_india: TM_DefaultSearchFilters,
        pseudo: TM_DefaultSearchFilters,
      },
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
      searchType: "Application No/Name",
      root: root,
      mmType: mmType,
      filterKeys: [
        "PROPRIETOR",
        "CLASSES",
        "TRADEMARK_TYPE",
        "TAG",
        "TRADEMARK_STATUS",
        "TRADEMARK_STATES",
        "TRADEMARK_OPPONENT",
        "JOURNAL",
        "ALERTS",
        "TRADEMARK_OFFICE",
      ],
      filterConfig: TM_DefaultFilterConfig,
    };
    this.handleNoTag = this.handleNoTag.bind(this);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.setDate1 = this.setDate1.bind(this);
    this.setDate2 = this.setDate2.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.tmDashboard === null) {
        this.props.getDashboard("trademark", "trademark");
      }

      // if (this.props.totalTMState.totalTrademarks === null) {
      this.props.getTrademark({
        mmType: this.state.mmType,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
      });

      this.state.filterKeys.map((key) =>
        this.props.getTrademark({
          mmType: this.state.mmType,
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: key,
          page: 1,
        }),
      );
      // }

      // if (this.props.pseudoTMState.pseudoTotalTrademarks === null) {
      if (this.props.userSubscriptions?.manager?.includes("PSEUDO_TRADEMARK")) {
        this.props.getTrademark({
          mmType: this.state.mmType,
          filterFlag: false,
          pseudo: true,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });

        this.state.filterKeys.map((key) =>
          this.props.getTrademark({
            mmType: this.state.mmType,
            pseudo: true,
            filterFlag: false,
            filters: TM_DefaultSelectedFilters,
            pageNumber: 1,
            filters_required_flag: true,
            required_filter: key,
            page: 1,
          }),
        );
      }
    } else {
      this.props.logOut();
    }
  }

  handleTrademarkProfile(data) {
    if (data) {
      sessionStorage.setItem(
        "management_trademark_id",
        data.management_mark_id,
      );
      sessionStorage.setItem(
        "trademark_application_number",
        data.application_number,
      );
      // history.push('/manager/trademark/trademark-profile/'+data.management_mark_id )
      window.open(
        "/manager/trademark/trademark-profile/" + data.management_mark_id,
        "_blank",
      );
      this.setState({
        management_trademark_id: data.management_mark_id,
      });
    }
  }

  handleProprietorProfile(data) {
    if (data) {
      sessionStorage.setItem("proprietor_id", data.id);
      window.open("/manager/trademark/proprietor-profile/" + data.id, "_blank");
      // history.push('/manager/trademark/proprietor-profile/'+data.id )
      this.setState({
        proprietor_id: data.id,
      });
    }
  }

  //*********************************NO Tags********************************//

  handleNoTag = (e) => {
    this.setState({ noTagToggle: !this.state.noTagToggle });
  };

  downloadReport() {
    const { changeSwitch, add_trademark_id, pseudo_add_trademark_id } =
      this.state;
    if (
      changeSwitch &&
      (pseudo_add_trademark_id?.length > 0 || this.state.selectAll)
    ) {
      this.setState({
        visible: true,
      });
    } else if (add_trademark_id?.length > 0 || this.state.selectAll) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  setDate1(val) {
    this.setState({ range1: val });
  }

  setDate2(val) {
    this.setState({ range2: val });
  }

  resetFilter() {
    const { changeSwitch } = this.state;
    if (
      changeSwitch
        ? this.props.pseudoTMState?.pseudoTotalTrademarksAppliedFilters
        : this.props.totalTMState?.totalTrademarksAppliedFilters
    ) {
      const {
        trademarkClass,
        trademarkStatus,
        trademarkType,
        trademarkStates,
        trademarkProprietors,
        trademarkTags,
        trademarkOpponent,
        journalNumber,
        date_of_application,
        valid_upto_date,
        containsTerm,
        trademarkAlerts,
        trademarkNOtags,
        desc_contains_term,
        date_of_usage,
        trademarkOffice,
        date_of_usage_proposed,
      } = changeSwitch
        ? this.props.pseudoTMState?.pseudoTotalTrademarksAppliedFilters
        : this.props.totalTMState?.totalTrademarksAppliedFilters;

      if (
        trademarkClass?.length > 0 ||
        trademarkStatus?.length > 0 ||
        trademarkType?.length > 0 ||
        trademarkStates?.length > 0 ||
        trademarkProprietors?.length > 0 ||
        trademarkTags?.length > 0 ||
        trademarkOpponent?.length > 0 ||
        journalNumber?.length > 0 ||
        date_of_application?.length > 0 ||
        valid_upto_date?.length > 0 ||
        containsTerm?.length > 0 ||
        trademarkAlerts.length > 0 ||
        trademarkNOtags?.length > 0 ||
        desc_contains_term?.length > 0 ||
        date_of_usage?.length > 0 ||
        trademarkOffice?.length > 0 ||
        date_of_usage_proposed
      ) {
        this.props.getTrademark({
          mmType: this.state.mmType,
          pseudo: changeSwitch,
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });

        this.setState({
          selectedFilters: {
            ...this.state.selectedFilters,
            [changeSwitch ? "pseudo" : "ip_india"]: {
              ...this.state.selectedFilters[
                changeSwitch ? "pseudo" : "ip_india"
              ],
              ...TM_DefaultSelectedFilters,
              filterFlag: false,
              pageNumber: 1,
            },
          },
        });
        this.setState({ range1: null, range2: null });
        this.setState({ noTagToggle: !true });
      } else {
        message.error("No Filter Applied");
      }
    }
  }

  applyFilter() {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.state.selectedFilters.pseudo
      : this.state.selectedFilters.ip_india;
    const {
      trademarkClass,
      trademarkStatus,
      trademarkType,
      trademarkStates,
      trademarkProprietors,
      trademarkTags,
      trademarkOpponent,
      journalNumber,
      date_of_application,
      valid_upto_date,
      containsTerm,
      trademarkAlerts,
      trademarkNOtags,
      desc_contains_term,
      date_of_usage,
      trademarkOffice,
      date_of_usage_proposed,
    } = selectedFilters;

    if (
      trademarkClass?.length > 0 ||
      trademarkStatus?.length > 0 ||
      trademarkType?.length > 0 ||
      trademarkStates?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      trademarkTags?.length > 0 ||
      trademarkOpponent?.length > 0 ||
      journalNumber?.length > 0 ||
      date_of_application?.length > 0 ||
      valid_upto_date?.length > 0 ||
      containsTerm?.length > 0 ||
      trademarkAlerts?.length > 0 ||
      trademarkNOtags?.length > 0 ||
      desc_contains_term?.length > 0 ||
      date_of_usage?.length > 0 ||
      trademarkOffice?.length > 0 ||
      date_of_usage_proposed
    ) {
      this.props.getTrademark({
        mmType: this.state.mmType,
        pseudo: changeSwitch,
        filterFlag: true,
        filters: selectedFilters,
        pageNumber: 1,
        searchType: this.state.searchType,
      });
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [changeSwitch ? "pseudo" : "ip_india"]: {
            ...this.state.selectedFilters[changeSwitch ? "pseudo" : "ip_india"],
            ...selectedFilters,
            filterFlag: true,
            pageNumber: 1,
          },
        },
      });
    } else {
      message.error("please select filter!");
    }
  }

  handleNextpage = (pageNumber) => {
    const { changeSwitch } = this.state;
    const selectedFilters =
      this.state.selectedFilters[changeSwitch ? "pseudo" : "ip_india"];
    this.props.getTrademark({
      mmType: this.state.mmType,
      pseudo: changeSwitch,
      filterFlag: selectedFilters?.filterFlag,
      filters: selectedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [changeSwitch ? "pseudo" : "ip_india"]: {
          ...this.state.selectedFilters[changeSwitch ? "pseudo" : "ip_india"],
          pageNumber: pageNumber,
        },
      },
    });
  };

  changeSwitch() {
    this.setState({
      changeSwitch: !this.state.changeSwitch,
    });
  }

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  async handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [
          this.state.changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK",
        ],
        report_tag: {
          journal_number: checkValue(data.journal) ? data.journal.number : "",
          journal_date: checkValue(data.journal)
            ? data.journal.date_of_publication
            : "",
          status: checkValue(data.status) ? data.status : "",
          renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
          application_number: checkValue(data.application_number)
            ? data.application_number
            : "",
          mark_name: checkValue(data.applied_for) ? data.applied_for : "",
          date_of_application: checkValue(data.application_date)
            ? data.application_date
            : "",
          class: checkValue(data.associated_class)
            ? data.associated_class.join(", ")
            : "",
          date_of_usage: checkValue(data.date_of_usage)
            ? data.date_of_usage
            : "",
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          opponent_name: checkValue(data.opponent)
            ? data.opponent.map((opp) => opp.name).join(", ")
            : "",
          tla_hearing_date: "",
          opposition_hearing_date: "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_trademark" : "trademark",
        applicationNumber: data.application_number,
        markId: data.management_mark_id,
        sourceId: data.management_mark_id,
      },
    });
    if (value) {
      await this.props.getEmailTemplateData({
        subscription: "TRADEMARK",
        sub_section: "DASHBOARD",
        product: "MANAGER",
        object_id: data?.application_number,
      });
    }
  }

  fetchFilters = (key, filter_contains_term, page) => {
    this.props.getTrademark({
      mmType: this.state.mmType,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: page,
      filters_required_flag: true,
      required_filter: key,
      filter_contains_term: filter_contains_term,
      page: page,
      pseudo: this.state.changeSwitch,
    });
  };

  handleSearchDebounced = debounce(function (
    required_filter,
    filter_contains_term,
  ) {
    this.fetchFilters(required_filter, filter_contains_term, 1);
  }, 500);

  onFilterSearch = (required_filter, filter_contains_term) => {
    this.setState({
      isSearching: true,
      filterConfig: {
        ...this.state?.filterConfig,
        [`${required_filter}`]: {
          ...this.state?.filterConfig[`${required_filter}`],
          filter_contains_term: filter_contains_term,
          page: 1,
        },
      },
    });

    this.handleSearchDebounced(required_filter, filter_contains_term);
  };

  // onFilterSearch = (required_filter, filter_contains_term) => {
  //   this.setState({
  //     isSearching: true,
  //     filterConfig: {
  //       ...this.state?.filterConfig,
  //       [`${required_filter}`]: {
  //         ...this.state?.filterConfig[`${required_filter}`],
  //         filter_contains_term: filter_contains_term,
  //         page: 1,
  //       },
  //     },
  //   });
  //   this.fetchFilters(required_filter, filter_contains_term, 1);
  // };

  onFilterPaginate = (required_filter, filter_contains_term, page) => {
    const filterObj = this.state.changeSwitch
      ? this.props.pseudoTMState?.filters
      : this.props.totalTMState?.filters;
    const isFilterLoading = this.state.changeSwitch
      ? this.props.pseudoTMState?.filters?.filterStatus
      : this.props.totalTMState?.filters?.filterStatus;

    if (filterObj.notesFilterStatus !== "LOADING") {
      const page_size = 100;
      let total_pages = 0;

      let count = 0;

      this.state.filterKeys.forEach((key) => {
        if (required_filter === key) {
          count = filterObj[`${key}FilterDataCount`];
        }
      });

      if (count !== undefined && count !== null && count > 0) {
        total_pages = Math.ceil(count / page_size);
      }
      if (page <= total_pages && isFilterLoading !== "LOADING") {
        this.setState({
          filterConfig: {
            ...this.state?.filterConfig,
            [`${required_filter}`]: {
              ...this.state?.filterConfig[`${required_filter}`],
              page,
            },
          },
        });
        this.fetchFilters(required_filter, filter_contains_term, page);
      }
    }
  };

  render() {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.state.selectedFilters.pseudo
      : this.state.selectedFilters.ip_india;
    const searchFilters = changeSwitch
      ? this.state.searchFilters.pseudo
      : this.state.searchFilters.ip_india;
    // const currentPageNumber = changeSwitch ? this.props.pseudoTotalTrademarksStatus : this.props.totalTrademarksPageNumber;
    const currentPageNumber = changeSwitch
      ? this.props.pseudoTMState?.pseudoTotalTrademarksPageNumber
      : this.props.totalTMState?.totalTrademarksPageNumber;
    let switchValue;
    if (changeSwitch) {
      switchValue = "pseudo";
    } else {
      switchValue = "ip_india";
    }

    const headerButton = {
      margin: "0px 5px 0px 0px",
      boxShadow: "none",
      border: "1px solid #1890ff",
      padding: "0px 5px",
      fontSize: "16px",
      color: "#1890ff",
    };

    const headerButtonSelected = { ...headerButton, color: "#4285f4" };
    const searchTypesMenu = (
      <>
        <Menu
          selectable={true}
          selectedKeys={[this.state.selectedFilters.searchType]}
          onClick={({ key }) => this.setState({ searchType: key })}
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {["Application No/Name", "Description (G&S)"].map((e) => (
            <Menu.Item key={e} style={{ fontSize: "16px" }}>
              {e}
            </Menu.Item>
          ))}
        </Menu>
      </>
    );

    const getColumnFilter = (dataIndex) => ({
      filtered:
        (dataIndex === "date_of_usage" &&
          (selectedFilters?.date_of_usage?.length > 0 ||
            selectedFilters?.date_of_usage_proposed)) ||
        (dataIndex === "tags" &&
          (selectedFilters?.trademarkTags?.length > 0 ||
            selectedFilters?.trademarkNOtags?.length > 0)) ||
        (dataIndex === "alert" &&
          selectedFilters?.trademarkAlerts?.length > 0) ||
        (dataIndex === "opponent" &&
          selectedFilters?.trademarkOpponent?.length > 0) ||
        (dataIndex === "proprietor" &&
          selectedFilters?.trademarkProprietors?.length > 0) ||
        (dataIndex === "status" &&
          selectedFilters?.trademarkStatus?.length > 0) ||
        (dataIndex === "associated_class" &&
          selectedFilters?.trademarkClass?.length > 0) ||
        (dataIndex === "valid_upto" &&
          selectedFilters?.valid_upto_date?.length > 0) ||
        (dataIndex === "application_date" &&
          selectedFilters?.date_of_application?.length > 0),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          {dataIndex === "tags" && tagsMenu}
          {dataIndex === "alert" && alertMenu}
          {dataIndex === "opponent" && opponentMenu}
          {dataIndex === "proprietor" && proprietorsMenu}
          {dataIndex === "status" && statusMenu}
          {dataIndex === "associated_class" && classesMenu}
          {dataIndex === "date_of_usage" && (
            <>
              <DateRangePicker
                key="date_of_usage"
                type={"Date of Usage"}
                startValue={selectedFilters?.date_of_usage_startValue}
                endValue={selectedFilters?.date_of_usage_endValue}
                setDate={(val) => this.setDate2(val)}
                range={this.state.range2}
                handleDateString={(startValue, endValue, dateString) =>
                  this.setState({
                    selectedFilters: {
                      ...this.state.selectedFilters,
                      [switchValue]: {
                        ...this.state.selectedFilters[switchValue],
                        date_of_usage_startValue: startValue,
                        date_of_usage_endValue: endValue,
                        date_of_usage:
                          startValue !== null || endValue !== null
                            ? dateString
                            : null,
                      },
                    },
                  })
                }
              />

              <div style={{ textAlign: "center", lineHeight: "32px" }}>
                <Checkbox
                  key="date_of_usage_proposed"
                  checked={
                    this.state.selectedFilters[switchValue]
                      ?.date_of_usage_proposed
                  }
                  onChange={(event) => {
                    this.changeStateValue_L2(
                      "selectedFilters",
                      switchValue,
                      "date_of_usage_proposed",
                      event.target.checked,
                    );
                  }}
                >
                  Proposed
                </Checkbox>
              </div>
            </>
          )}
          {dataIndex === "valid_upto" && (
            <>
              <DateRangePicker
                key="valid_upto"
                type={"Valid Upto Date"}
                startValue={selectedFilters?.valid_upto_date_startValue}
                endValue={selectedFilters?.valid_upto_date_endValue}
                setDate={(val) => this.setDate2(val)}
                range={this.state.range2}
                handleDateString={(startValue, endValue, dateString) =>
                  this.setState({
                    selectedFilters: {
                      ...this.state.selectedFilters,
                      [switchValue]: {
                        ...this.state.selectedFilters[switchValue],
                        valid_upto_date_startValue: startValue,
                        valid_upto_date_endValue: endValue,
                        valid_upto_date:
                          startValue !== null || endValue !== null
                            ? dateString
                            : null,
                      },
                    },
                  })
                }
              />
            </>
          )}
          {dataIndex === "application_date" && (
            <DateRangePicker
              key="application_date"
              type={"Application Date"}
              startValue={selectedFilters?.date_of_application_startValue}
              endValue={selectedFilters?.date_of_application_endValue}
              setDate={(val) => this.setDate1(val)}
              range={this.state.range1}
              handleDateString={(startValue, endValue, dateString) =>
                this.setState({
                  selectedFilters: {
                    ...this.state.selectedFilters,
                    [switchValue]: {
                      ...this.state.selectedFilters[switchValue],
                      date_of_application_startValue: startValue,
                      date_of_application_endValue: endValue,
                      date_of_application:
                        startValue !== null || endValue !== null
                          ? dateString
                          : null,
                    },
                  },
                })
              }
            />
          )}
        </div>
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    });

    const totalTrademarkColumns = [
      {
        title: "TRADEMARK",
        className: "trademark-column",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handleTrademarkProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.applied_for)
                    ? record.applied_for.toLowerCase()
                    : "N.A."}
                </span>
              </p>
              <p>
                <img
                  className="trademark-image"
                  src={
                    checkValue(record.associated_image)
                      ? record.associated_image
                      : ""
                  }
                  alt=""
                />
              </p>
            </div>
          );
        },
      },

      {
        title: "STATUS",
        dataIndex: "status",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
        ...getColumnFilter("status"),
      },

      {
        title: "CLASS",
        dataIndex: "associated_class",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.join(", ") : "N.A."}
          </p>
        ),
        ...getColumnFilter("associated_class"),
      },

      {
        title: "DOA",
        dataIndex: "application_date",
        className: "trademark-column",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        ...getColumnFilter("application_date"),
      },

      {
        title: "DOU",
        dataIndex: "date_of_usage",
        className: "trademark-column",
        render: (text) => (
          <p>{checkValue(text) ? text : "Proposed To Be Used"}</p>
        ),
        ...getColumnFilter("date_of_usage"),
      },

      {
        title: "VALID UPTO",
        dataIndex: "valid_upto",
        className: "trademark-column",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
        ...getColumnFilter("valid_upto"),
      },

      {
        title: "PROPRIETOR",
        width: "10%",
        dataIndex: "proprietor",
        className: "trademark-column",
        render: (proprietor) => {
          return (
            <div>
              {proprietor?.map((proprietorData, proprietorKey) => (
                <p
                  key={proprietorKey}
                  className="application-text text-camelCase"
                  onClick={() => this.handleProprietorProfile(proprietorData)}
                >
                  {checkValue(proprietorData.name)
                    ? proprietorData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
        ...getColumnFilter("proprietor"),
      },

      {
        title: "OPPONENT",
        dataIndex: "opponent",
        className: "trademark-column",
        render: (opponent) => {
          return (
            <div>
              {opponent?.length > 0
                ? opponent.map((opponent, index) => (
                    <p key={`opponent` - index} className="text-camelCase">
                      <span>
                        {checkValue(opponent.name)
                          ? opponent.name.toLowerCase()
                          : "N.A."}
                      </span>
                      <p>
                        (
                        {checkValue(opponent.opp_rec_number)
                          ? opponent.opp_rec_number.toLowerCase()
                          : "N.A."}
                        )
                      </p>
                    </p>
                  ))
                : "N.A."}
            </div>
          );
        },
        ...getColumnFilter("opponent"),
      },

      {
        title: "ALERTS",
        dataIndex: "alert",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
        ),
        ...getColumnFilter("alert"),
      },

      {
        title: "TAGS",
        dataIndex: "tags",
        className: "trademark-column",
        render: (tags) => {
          return (
            <div>
              {tags?.length > 0
                ? tags.map((tag) => (
                    <span
                      key={tag.id}
                      className="Tag"
                      style={{
                        color: `${tag.color_code}`,
                        border: `1px solid ${tag.color_code}`,
                      }}
                    >
                      {tag.name.toUpperCase()}
                    </span>
                  ))
                : "N.A."}
            </div>
          );
        },
        ...getColumnFilter("tags"),
      },

      {
        title: "ACTION",
        className: "trademark-column",
        render: (action) => {
          return (
            <div>
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const totalTrademarkRowSelection = {
      // onChange: (selectedRowKeys, selectedRows) => {
      //     this.setState({
      //         selectedRows: _.uniq([...selectedRows, ...this.state.selectedRows]),
      //         add_trademark_id:  _.uniq([...selectedRows.map(data=> data.id), ...this.state.add_trademark_id]),
      //         selectedRowKeys: selectedRowKeys
      //     })
      // },

      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },

      onSelectAll: (selected, selectedRows) => {
        let data =
          this.props.totalTMState.totalTrademarks &&
          this.props.totalTMState.totalTrademarks.ip_india &&
          this.props.totalTMState.totalTrademarks.ip_india.trademarks;

        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      // onSelect: (record, selected, selectedRows ) => {
      //     this.setState({
      //         selectRow: selected,
      //         selectAll: selectedRows.length === 100 ? true : false,
      //     })
      // },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: false,
                  add_trademark_id: [],
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    const pseudoTrademarkRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let select_trademark_id = [];
        select_trademark_id = selectedRows.map((mark_id) => mark_id.id);
        this.setState({
          pseudo_selectedRows: selectedRows,
          pseudo_add_trademark_id: select_trademark_id,
          pseudo_selectedRowKeys: selectedRowKeys,
        });
      },

      onSelectAll: (selected, selectedRows) => {
        let data =
          this.props.pseudoTotalTrademarks &&
          this.props.pseudoTotalTrademarks.pseudo &&
          this.props.pseudoTotalTrademarks.pseudo.trademarks;

        this.setState({
          pseudo_selectAll: selected,
          pseudo_selectedRows: selectedRows,
          pseudo_selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows) => {
        this.setState({
          pseudo_selectRow: selected,
          // pseudo_selectAll: selectedRows.length === 100 ? true : false,
        });
      },

      hideDefaultSelections: true,
      selections: this.state.pseudo_selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  pseudo_selectedRowKeys: changeableRowKeys,
                  pseudo_selectAll: false,
                  pseudo_add_trademark_id: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.pseudo_selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.pseudo_selectAll,
      }),
    };

    let isFilterLoading = changeSwitch
      ? this.props.pseudoTMState?.filters[`PROPRIETORFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`CLASSESFilterStatus`] === LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_TYPEFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`TAGFilterStatus`] === LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_STATUSFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_STATESFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_OPPONENTFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters[`JOURNALFilterStatus`] === LOADING ||
        this.props.pseudoTMState?.filters[`ALERTSFilterStatus`] === LOADING ||
        this.props.pseudoTMState?.filters[`TRADEMARK_OFFICEFilterStatus`] ===
          LOADING
      : this.props.totalTMState?.filters[`PROPRIETORFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`CLASSESFilterStatus`] === LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_TYPEFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`TAGFilterStatus`] === LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_STATUSFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_STATESFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_OPPONENTFilterStatus`] ===
          LOADING ||
        this.props.totalTMState?.filters[`JOURNALFilterStatus`] === LOADING ||
        this.props.totalTMState?.filters[`ALERTSFilterStatus`] === LOADING ||
        this.props.totalTMState?.filters[`TRADEMARK_OFFICEFilterStatus`] ===
          LOADING ||
        this.props.pseudoTMState?.filters?.filterStatus === LOADING;

    const classes = changeSwitch
      ? this.props.pseudoTMState?.filters?.CLASSESFilterData
      : this.props.totalTMState?.filters?.CLASSESFilterData;
    const status = changeSwitch
      ? this.props.pseudoTMState?.filters?.TRADEMARK_STATUSFilterData
      : this.props.totalTMState?.filters?.TRADEMARK_STATUSFilterData;
    const type = changeSwitch
      ? this.props.pseudoTMState?.filters?.TRADEMARK_TYPEFilterData
      : this.props.totalTMState?.filters?.TRADEMARK_TYPEFilterData;
    const states = changeSwitch
      ? this.props.pseudoTMState?.filters?.TRADEMARK_STATESFilterData
      : this.props.totalTMState?.filters?.TRADEMARK_STATESFilterData;
    const proprietors = changeSwitch
      ? this.props.pseudoTMState?.filters?.PROPRIETORFilterData
      : this.props.totalTMState?.filters?.PROPRIETORFilterData;
    const tags = changeSwitch
      ? this.props.pseudoTMState?.filters?.TAGFilterData
      : this.props.totalTMState?.filters?.TAGFilterData;
    const opponent = changeSwitch
      ? this.props.pseudoTMState?.filters?.TRADEMARK_OPPONENTFilterData
      : this.props.totalTMState?.filters?.TRADEMARK_OPPONENTFilterData;
    const journal = changeSwitch
      ? this.props.pseudoTMState?.filters?.JOURNALFilterData
      : this.props.totalTMState?.filters?.JOURNALFilterData;
    const alerts = changeSwitch
      ? this.props.pseudoTMState?.filters?.ALERTSFilterData
      : this.props.totalTMState?.filters?.ALERTSFilterData;
    const office = changeSwitch
      ? this.props.pseudoTMState?.filters?.TRADEMARK_OFFICEFilterData
      : this.props.totalTMState?.filters?.TRADEMARK_OFFICEFilterData;
    //   const isFilterLoading =   changeSwitch ? this.props.pseudoTMState?.filters?.filterStatus : this.props.totalTMState?.filters?.filterStatus ;

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>
              {isFilterLoading ? <Spin /> : "No Filters Data Found"}
            </Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let classesMenu = noFilterData;
    let statusMenu = noFilterData;
    let typeMenu = noFilterData;
    let stateMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    let tagsMenu = noFilterData;
    let opponentMenu = noFilterData;
    let journalMenu = noFilterData;
    let alertMenu = noFilterData;
    let officeMenu = noFilterData;

    const selectedFiltersObj = changeSwitch
      ? this.state.selectedFilters.pseudo
      : this.state.selectedFilters.ip_india;

    if (classes !== null && classes.length > 0) {
      classesMenu = (
        <FilterSearchBasic
          data={classes}
          searchFilters={searchFilters}
          search_key={"classSearch"}
          filter_key={"trademarkClass"}
          selectedFilters={selectedFilters}
          switchValue={switchValue}
          onSelect={this.changeStateValue_L2}
        />
      );
    }

    if (status !== null && status?.length > 0) {
      statusMenu = (
        <FilterSearchBasic
          data={status}
          searchFilters={searchFilters}
          search_key={"statusSearch"}
          filter_key={"trademarkStatus"}
          selectedFilters={selectedFilters}
          switchValue={switchValue}
          onSelect={this.changeStateValue_L2}
          type={"object"}
        />
      );
    }

    if (type !== null && type?.length > 0) {
      typeMenu = (
        <FilterSearchBasic
          data={type}
          searchFilters={searchFilters}
          search_key={"typeSearch"}
          filter_key={"trademarkType"}
          selectedFilters={selectedFilters}
          switchValue={switchValue}
          onSelect={this.changeStateValue_L2}
        />
      );
    }
    if (states !== null && states?.length > 0) {
      stateMenu = (
        <FilterSearchBasic
          data={states}
          searchFilters={searchFilters}
          search_key={"stateSearch"}
          filter_key={"trademarkStates"}
          selectedFilters={selectedFilters}
          switchValue={switchValue}
          onSelect={this.changeStateValue_L2}
        />
      );
    }

    proprietorsMenu = (
      <FilterSearchPaginate
        datasource={proprietors}
        filter_key={"trademarkProprietors"}
        req_filter={"PROPRIETOR"}
        selectedFilters={selectedFiltersObj}
        onFilterSearch={this.onFilterSearch}
        changeSwitch={changeSwitch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "PROPRIETOR",
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Proprietors"}
        searchValue={
          this.state?.filterConfig["PROPRIETOR"]?.filter_contains_term
        }
        switchValue={switchValue}
      />
    );

    tagsMenu = (
      <FilterSearchPaginate
        datasource={tags}
        filter_key={"trademarkTags"}
        req_filter={"TAG"}
        selectedFilters={selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        onFilterPaginate={() => {
          let required_filter = "TAG";
          this.onFilterPaginate(
            required_filter,
            this.state?.filterConfig[`${required_filter}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${required_filter}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Tags"}
        searchValue={this.state?.filterConfig["TAG"]?.filter_contains_term}
        switchValue={switchValue}
        type={"noTag"}
        handleNoTag={this.handleNoTag}
        noTagToggle={this.state.noTagToggle}
      />
    );

    opponentMenu = (
      <FilterSearchPaginate
        datasource={opponent}
        filter_key={"trademarkOpponent"}
        req_filter={"TRADEMARK_OPPONENT"}
        selectedFilters={selectedFiltersObj}
        onFilterSearch={this.onFilterSearch}
        changeSwitch={changeSwitch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "TRADEMARK_OPPONENT",
            this.state?.filterConfig[`${"TRADEMARK_OPPONENT"}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${"TRADEMARK_OPPONENT"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Opponent"}
        searchValue={
          this.state?.filterConfig["TRADEMARK_OPPONENT"]?.filter_contains_term
        }
        switchValue={switchValue}
      />
    );

    officeMenu = (
      <FilterSearchPaginate
        datasource={office}
        filter_key={"trademarkOffice"}
        req_filter={"TRADEMARK_OFFICE"}
        selectedFilters={selectedFiltersObj}
        onFilterSearch={this.onFilterSearch}
        changeSwitch={changeSwitch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "TRADEMARK_OFFICE",
            this.state?.filterConfig[`${"TRADEMARK_OFFICE"}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${"TRADEMARK_OFFICE"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Opponent"}
        searchValue={
          this.state?.filterConfig["TRADEMARK_OFFICE"]?.filter_contains_term
        }
        switchValue={switchValue}
      />
    );

    journalMenu = (
      <FilterSearchPaginate
        datasource={journal}
        filter_key={"journalNumber"}
        req_filter={"JOURNAL"}
        selectedFilters={selectedFiltersObj}
        onFilterSearch={this.onFilterSearch}
        changeSwitch={changeSwitch}
        loader={isFilterLoading}
        title={""}
        onSelect={this.changeStateValue_L2}
        type={"journal"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "JOURNAL",
            this.state?.filterConfig[`${"JOURNAL"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"JOURNAL"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Journals"}
        searchValue={this.state?.filterConfig["JOURNAL"]?.filter_contains_term}
        switchValue={switchValue}
      />
    );

    // ****************************************ALERTS Filter**************************************//

    if (alerts !== null && alerts?.length > 0) {
      alertMenu = (
        <FilterSearchBasic
          data={alerts}
          searchFilters={searchFilters}
          search_key={"alertSearch"}
          filter_key={"trademarkAlerts"}
          selectedFilters={selectedFilters}
          switchValue={switchValue}
          onSelect={this.changeStateValue_L2}
        />
      );
    }

    const title = (
      <Row>
        <Col xs={{ span: 12 }} lg={{ span: 20 }}>
          <Title style={{ marginBottom: "4px" }} level={3}>
            {(this.state.root === "YOUR_TM" &&
              (changeSwitch
                ? "Your Total Trademark Applications (Custom)"
                : "Your Total Trademark Applications")) ||
              (this.state.root === "OPPOSED_TM" &&
                (changeSwitch
                  ? "3rd Party Trademark Applications (Custom)"
                  : "3rd Party Trademark Applications")) ||
              (this.state.root === "STATUS_TRACKER" &&
                (changeSwitch ? "Status Tracker (Custom)" : "Status Tracker"))}
          </Title>
          <Text style={{ fontSize: "16px" }} type="secondary">
            {changeSwitch
              ? `Total number of trademarks: ${
                  (this.props.pseudoTMState.pseudoTotalTrademarksStatus ===
                  LOADING
                    ? 0
                    : this.props.pseudoTMState.pseudoTotalTrademarks &&
                      this.props.pseudoTMState.pseudoTotalTrademarks.ip_india &&
                      this.props.pseudoTMState.pseudoTotalTrademarks.pseudo
                        .count) || 0
                }`
              : `Total number of trademarks: ${
                  (this.props.totalTMState.totalTrademarksStatus === LOADING
                    ? 0
                    : this.props.totalTMState.totalTrademarks &&
                      this.props.totalTMState.totalTrademarks.ip_india &&
                      this.props.totalTMState.totalTrademarks.ip_india.count) ||
                  0
                }`}
          </Text>
        </Col>
        <Col
          span={4}
          className={
            checkValue(this.props.userSubscriptions)
              ? this.props.userSubscriptions.manager.includes(
                  "PSEUDO_TRADEMARK",
                )
                ? "alignR m-top-10"
                : "display_none"
              : "display_none"
          }
        >
          <Text strong>IP INDIA</Text>
          <Switch
            className="change-manager-type"
            onChange={() => this.changeSwitch()}
            checked={changeSwitch}
          />
          <Text strong>CUSTOM</Text>
        </Col>
      </Row>
    );

    const handleOpenChange = (flag, key) => {
      if (flag) {
        this.setState({
          open: key,
        });
      } else {
        this.setState({
          open: null,
        });
      }
    };

    return (
      <>
        <Row style={{ marginBottom: "8px" }}>
          <Col span={2} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => {
                window.history.back();
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
        </Row>
        <Content style={{ margin: "0px 16px" }}>
          {title}

          {/* Total Trademarks filter */}
          <Row align="middle" justify="space-between">
            <Col span={21} className="m-top-10">
              <Menu
                selectedKeys={[
                  selectedFilters?.trademarkOffice?.length > 0
                    ? "officeMenu"
                    : "",
                  selectedFilters?.trademarkType?.length > 0 ? "typeMenu" : "",
                  selectedFilters?.trademarkStates?.length > 0
                    ? "stateMenu"
                    : "",
                  selectedFilters?.journalNumber?.length > 0
                    ? "journalMenu"
                    : "",
                ]}
                className="filter-list"
                mode="horizontal"
              >
                {" "}
                <Menu.Item
                  key="search-Input"
                  className="filter-list-item apply-filter"
                >
                  <Dropdown overlay={searchTypesMenu}>
                    <Button style={headerButtonSelected}>
                      {this.state.searchType}
                      <DownOutlined style={{ fontSize: "12px" }} />
                    </Button>
                  </Dropdown>
                </Menu.Item>
                {this.state.searchType === "Application No/Name" && (
                  <Menu.Item
                    key="contains"
                    className="filter-list-item apply-filter"
                  >
                    <Input
                      placeholder="Application No/Name"
                      onChange={(event) =>
                        this.changeStateValue_L2(
                          "selectedFilters",
                          switchValue,
                          "containsTerm",
                          event.target.value,
                        )
                      }
                      value={selectedFilters?.containsTerm}
                      allowClear
                      style={{ width: 200 }}
                    />
                  </Menu.Item>
                )}
                {this.state.searchType === "Description (G&S)" && (
                  <Menu.Item
                    key="description"
                    className="filter-list-item apply-filter"
                  >
                    <Input
                      placeholder="Description (G&S)"
                      onChange={(event) =>
                        this.changeStateValue_L2(
                          "selectedFilters",
                          switchValue,
                          "desc_contains_term",
                          event.target.value,
                        )
                      }
                      value={selectedFilters.desc_contains_term}
                      allowClear
                      style={{ width: 200 }}
                    />
                  </Menu.Item>
                )}
                <Menu.Item key="typeMenu" className="filter-list-item">
                  <Dropdown
                    dropdownRender={() => typeMenu}
                    overlayClassName="filter-menu"
                    autoAdjustOverflow
                    onOpenChange={(flag) => handleOpenChange(flag, "type")}
                    open={this.state.open === "type"}
                  >
                    <div className="ant-dropdown-link">
                      <span>Type </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item key="stateMenu" className="filter-list-item">
                  <Dropdown
                    dropdownRender={() => stateMenu}
                    overlayClassName="filter-menu"
                    autoAdjustOverflow
                    onOpenChange={(flag) => handleOpenChange(flag, "state")}
                    open={this.state.open === "state"}
                  >
                    <div className="ant-dropdown-link">
                      <span>State </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item key="officeMenu" className="filter-list-item">
                  <Dropdown
                    dropdownRender={() => officeMenu}
                    overlayClassName="filter-menu"
                    autoAdjustOverflow
                    onOpenChange={(flag) => handleOpenChange(flag, "office")}
                    open={this.state.open === "office"}
                  >
                    <div className="ant-dropdown-link">
                      <span>Office </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item key="journalMenu" className="filter-list-item">
                  <Dropdown
                    dropdownRender={() => journalMenu}
                    overlayClassName="filter-menu"
                    autoAdjustOverflow
                    onOpenChange={(flag) => handleOpenChange(flag, "journal")}
                    open={this.state.open === "journal"}
                  >
                    <div className="ant-dropdown-link">
                      <span>Journal </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item
                  key="applyFilter"
                  className="filter-list-item apply-filter"
                >
                  <Button type="primary" onClick={() => this.applyFilter()}>
                    <i className="fa fa-filter" aria-hidden="true" />
                    <Text style={{ color: "#fff", marginLeft: "5px" }}>
                      Apply Filter
                    </Text>
                  </Button>
                </Menu.Item>
                <Menu.Item
                  key="resetFilter"
                  className="filter-list-item apply-filter"
                >
                  <span
                    onClick={() => this.resetFilter()}
                    style={{ padding: "0px 5px" }}
                  >
                    RESET
                  </span>
                </Menu.Item>
              </Menu>
            </Col>
            <Col className="m-top-10" style={{ display: "flex" }}>
              <Tooltip title="Deselect All">
                <Button
                  danger
                  onClick={() => {
                    this.setState({
                      selectedRowKeys: [],
                      selectAll: false,
                      add_trademark_id: [],
                      selectedRows: [],
                      pseudo_selectedRowKeys: [],
                      pseudo_selectAll: false,
                      pseudo_add_trademark_id: [],
                    });
                  }}
                  icon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MinusCircleOutlined
                        style={{ margin: 0, fontSize: 16 }}
                      />
                    </div>
                  }
                  disabled={
                    (changeSwitch
                      ? this.state.pseudo_selectAll
                        ? this.props.pseudoTotalTrademarks &&
                          this.props.pseudoTotalTrademarks.pseudo.count
                        : _.uniq(this.state.pseudo_add_trademark_id).length ===
                          0
                      : this.state.selectAll
                      ? this.props.totalTMState.totalTrademarks &&
                        this.props.totalTMState.totalTrademarks.ip_india.count
                      : _.uniq(this.state.add_trademark_id).length === 0) &&
                    !(changeSwitch
                      ? this.state.pseudo_selectAll
                      : this.state.selectAll)
                  }
                />
              </Tooltip>

              <Badge
                count={
                  (changeSwitch
                    ? this.state.pseudo_selectAll && "All"
                    : this.state.selectAll && "All") ||
                  (changeSwitch
                    ? this.state.pseudo_selectAll
                      ? this.props.pseudoTotalTrademarks &&
                        this.props.pseudoTotalTrademarks.pseudo.count
                      : _.uniq(this.state.pseudo_add_trademark_id).length
                    : this.state.selectAll
                    ? this.props.totalTMState.totalTrademarks &&
                      this.props.totalTMState.totalTrademarks.ip_india.count
                    : _.uniq(this.state.add_trademark_id).length)
                }
              >
                <Button
                  type="primary"
                  style={{ marginLeft: "12px" }}
                  onClick={() => this.downloadReport()}
                >
                  Report
                </Button>
              </Badge>
            </Col>
            {/* </Col> */}
          </Row>
          {/* Total Trademarks filter */}

          {/* total-Trademarks-table */}
          <Row>
            <Col span={24}>
              <div className="m-top-20 total-tm">
                <Table
                  rowKey={(record) => record.id}
                  bordered
                  rowSelection={
                    changeSwitch
                      ? pseudoTrademarkRowSelection
                      : totalTrademarkRowSelection
                  }
                  columns={totalTrademarkColumns}
                  className="total-tm"
                  dataSource={
                    changeSwitch
                      ? this.props.pseudoTMState &&
                        this.props.pseudoTMState.pseudoTotalTrademarks &&
                        this.props.pseudoTMState.pseudoTotalTrademarks.pseudo &&
                        this.props.pseudoTMState.pseudoTotalTrademarks.pseudo
                          .trademarks
                      : this.props.totalTMState &&
                        this.props.totalTMState.totalTrademarks &&
                        this.props.totalTMState.totalTrademarks.ip_india &&
                        this.props.totalTMState.totalTrademarks.ip_india
                          .trademarks
                  }
                  pagination={{
                    pageSize: 100,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    defaultCurrent: 1,
                    current: currentPageNumber,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} `,
                    total: changeSwitch
                      ? this.props.pseudoTMState.pseudoTotalTrademarks &&
                        this.props.pseudoTMState.pseudoTotalTrademarks.pseudo &&
                        this.props.pseudoTMState.pseudoTotalTrademarks.pseudo
                          .count
                      : this.props.totalTMState.totalTrademarks &&
                        this.props.totalTMState.totalTrademarks.ip_india &&
                        this.props.totalTMState.totalTrademarks.ip_india.count,
                    onChange: this.handleNextpage,
                  }}
                  scroll={{ y: "calc(100vh - 300px)" }}
                  loading={
                    changeSwitch
                      ? this.props.pseudoTMState
                          ?.pseudoTotalTrademarksStatus === LOADING
                        ? true
                        : false
                      : this.props.totalTMState?.totalTrademarksStatus ===
                        LOADING
                      ? true
                      : false
                  }
                />
              </div>
            </Col>
          </Row>
          {/* total-Trademarks-table */}
          {this.state.visible && (
            <DownloadReportModal
              visible={this.state.visible}
              onCloseModal={() => this.setState({ visible: false })}
              type={"trademark"}
              mm_type={this.state?.mmType}
              add_trademark_id={
                changeSwitch
                  ? this.state.pseudo_add_trademark_id
                  : this.state.add_trademark_id
              }
              selectAll={
                changeSwitch
                  ? this.state.pseudo_selectAll
                  : this.state.selectAll
              }
              changeSwitch={changeSwitch}
              comparative={
                checkValue(this.props.userSubscriptions)
                  ? this.props.userSubscriptions.manager.includes(
                      "PSEUDO_TRADEMARK",
                    )
                  : false
              }
              filters={
                changeSwitch
                  ? this.props.pseudoTMState.pseudoTotalTrademarksAppliedFilters
                  : this.props.totalTMState.totalTrademarksAppliedFilters
              }
              searchType={this.state.searchType}
            />
          )}

          <CreateTask
            visible={this.state.addTaskVisible}
            onCloseModal={() =>
              this.changeStateValue_L0("addTaskVisible", false)
            }
            type={"trademark"}
          />

          <ManagerNotes
            visible={this.state.managerNotes}
            onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
            actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
            email={
              checkValue(this.props.userDetails)
                ? this.props.userDetails.email
                : ""
            }
            note_for={TM}
            application_number={this.state.application_number}
            type={"ADD"}
          />

          <SendEmailNew
            visible={this.state.modalsVisibility.sendEmail}
            onCloseModal={() =>
              this.handleEmail("modalsVisibility", "sendEmail", false, {})
            }
            sendFrom={
              this.props.userDetails &&
              this.props.userDetails.communication_email
            }
            sendCC={this.props.userTeam && this.props.userTeam.manager}
            mailContent={this.state.modalsVisibility.mailContent}
            product={this.state.modalsVisibility.product}
            subscription={this.state.modalsVisibility.subscription}
            report_tag={this.state.modalsVisibility.report_tag}
            objectType={this.state.modalsVisibility.objectType}
            mm_type={
              this.state.mmType === "trademark"
                ? "Portfolio trademark"
                : this.state.mmType === "opposed"
                ? "Third party Opposed trademark"
                : "Status tracker"
            }
            applicationNumber={this.state.modalsVisibility.applicationNumber}
            markId={this.state.modalsVisibility.markId}
            doc_link={true}
            sourceId={this.state.modalsVisibility.sourceId}
            source={`TRADEMARK`}
            sub_section="DASHBOARD"
            managerEmail={true}
          />
        </Content>
      </>
    );
  }

  componentWillUnmount() {}
}

const mapDispatchToProps = {
  getDashboard,
  getTrademark,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const { tmDashboard, tmDashboardStatus } = state.managerState.profileState;
  const {
    pseudo,

    totalTrademarks,
    totalTrademarksStatus,
    totalTrademarksAppliedFilters,
    totalTrademarksPageNumber,
    pseudoTotalTrademarks,
    pseudoTotalTrademarksStatus,
    pseudoTotalTrademarksAppliedFilters,
    pseudoTotalTrademarksPageNumber,

    //..
    totalTMState,
    pseudoTMState,
  } = state.managerState.trademarkDashboardState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;

  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
    tmDashboard,
    tmDashboardStatus,
    pseudo,

    totalTrademarks,
    totalTrademarksStatus,
    totalTrademarksAppliedFilters,
    totalTrademarksPageNumber,

    pseudoTotalTrademarks,
    pseudoTotalTrademarksStatus,
    pseudoTotalTrademarksAppliedFilters,
    pseudoTotalTrademarksPageNumber,

    //..
    totalTMState,
    pseudoTMState,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalTrademark);
