import React, { Component } from "react";
import { CarryOutTwoTone } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Tooltip,
  Descriptions,
  Typography,
  Switch,
} from "antd";

import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";

const { Text } = Typography;

class RenewalTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { renewalData, renewalType, customProfile, changeSwitch } =
      this.props;

    return (
      <div>
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title={renewalType}
              headStyle={{ background: "#e8e8e8" }}
              extra={
                customProfile && (
                  <div>
                    <Text strong>IP INDIA</Text>
                    <Switch
                      className="change-manager-type"
                      onChange={() => this.props.handleChangeSwitch()}
                      checked={changeSwitch}
                    />
                    <Text strong>CUSTOM</Text>
                  </div>
                )
              }
            >
              <Descriptions
                bordered
                size="small"
                layout="horizontal"
                column={1}
                className="description-table"
              >
                <Descriptions.Item label={<Text strong>DATE OF RENEWAL</Text>}>
                  {checkValue(renewalData.renewal_date)
                    ? renewalData.renewal_date
                    : "N.A."}
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong>ACTION</Text>}>
                  <div>
                    <p
                      className="application-text"
                      onClick={() => this.props.renewalFiled(renewalData)}
                    >
                      Renewal Filed
                    </p>
                    <Tooltip placement="top" title={"Create To-do Task"}>
                      <p>
                        <CarryOutTwoTone
                          onClick={this.props.changeStateValue_L0}
                          style={{ cursor: "pointer", fontSize: "30px" }}
                        />
                      </p>
                    </Tooltip>
                    <Tooltip placement="top" title={"Send email"}>
                      <p onClick={() => this.props.handleEmail(renewalData)}>
                        <i
                          className="fa fa-paper-plane"
                          style={{
                            padding: "0px 5px",
                            color: "#1890ff",
                            fontSize: 20,
                          }}
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Tooltip>
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RenewalTable;
