import React, { Component } from "react";
import { connect } from "react-redux";
import { uniq, uniqWith, compact, isEqual } from "lodash";
import { FixedSizeList } from "react-window";
import {
  CaretDownOutlined,
  FileTextOutlined,
  RestFilled,
} from "@ant-design/icons";
import {
  Popover,
  Checkbox,
  Row,
  Col,
  Input,
  AutoComplete,
  Tag,
  Button,
  Modal,
  Typography,
  Tooltip,
  message,
  Menu,
  Dropdown,
} from "antd";

import { CKEditor } from "ckeditor4-react";

import {
  sendCustomEmail,
  getClientEmail,
  getEmailTemplate,
  getUserClientEmail,
  getEmailDocument,
  downloadLetterHead,
} from "../../../redux/actions/commonActions/actions";
import { sendManagerEmail } from "../../../redux/actions/managerActions/commonAction/action";
import {
  GET_EMAIL_TEMPLATE,
  GET_USER_CLIENT_EMAIL,
} from "../../../redux/actions/commonActions/types";
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { changeStateValue_L0 } from "../../../functions/commonFunctions/generalFunctions";

const { Option } = AutoComplete;
const { Text } = Typography;

class SendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application_number: "",
      file_name: "",
      downloadContent: false,
      letter_head_visible: true,
      add_journal_copy: false,
      open: false,
      selectValue: "",
      searchValue: {
        send_to: "",
        cc: "",
      },
      send_from: "",
      send_to: "",
      cc: "",
      subject: "",
      tags: {
        send_to: [],
        cc: [],
      },
      mail_body: "",
      page: {
        send_to: 1,
        cc: 1,
      },
      send_from_input_error: false,
      send_to_input_error: false,
      cc_input_error: false,
      subject_input_error: false,
      selectedKeys: [],
      selectedKeys1: [],
      attachment: [],
      report_tag: {},
      file_attachment: {
        file: "",
        fileName: "",
        filePreviewUrl: "",
      },
      multiple_files: [],
      select_doc_link: [],
      send_from_error: false,
      send_to_error: false,
      subject_error: false,
    };
    this.autoRef = React.createRef();
    this.deletePrevious = React.createRef(false);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    this.props.getClientEmail({ type: "send_to" });
    this.props.getClientEmail({ type: "cc" });
  }

  async shouldComponentUpdate(nextProps) {
    if (nextProps.report_tag !== this.props.report_tag) {
      const salutation = "Dear Sir/Ma'am,";
      const mail_Content = checkValue(nextProps.mailContent)
        ? nextProps.mailContent
        : "<p></p>";
      const mail_html =
        "<p>" +
        salutation +
        "</p>" +
        mail_Content +
        "<p><strong>Regards</strong></p>";
      this.setState({
        send_from: checkValue(nextProps.sendFrom) ? nextProps.sendFrom : "",
        subject: "",
        mail_body: mail_html,
        attachment: checkValue(nextProps.journal_copy)
          ? [nextProps.journal_copy]
          : checkValue(nextProps.attachment)
          ? nextProps.attachment
          : [],
        selectedKeys: [],
        product: checkValue(nextProps.product) ? nextProps.product : "",
        subscription: checkValue(nextProps.subscription)
          ? nextProps.subscription
          : "",
        report_tag: checkValue(nextProps.report_tag)
          ? nextProps.report_tag
          : {},
        tags: {
          ...this.state.tags,
          cc: checkValue(nextProps.sendCC) ? nextProps.sendCC : [],
        },
        application_number: checkValue(nextProps.applicationNumber)
          ? nextProps.applicationNumber
          : "",
      });
    }

    if (nextProps.visible !== this.props.visible) {
      this.props.getEmailTemplate({
        actionType: GET_EMAIL_TEMPLATE,
        product: nextProps.product,
        subscription: `${nextProps.subscription}`,
      });
      if (nextProps.markId) {
        this.props.getEmailDocument({
          mark_id: nextProps.markId,
        });
      }

      if (!nextProps.attachment) {
        await this.props.getUserClientEmail({
          actionType: GET_USER_CLIENT_EMAIL,
          object_type: nextProps.objectType,
          application_number: checkValue(nextProps.applicationNumber)
            ? nextProps.applicationNumber
            : "0000000",
        });
        this.setState({
          tags: {
            ...this.state.tags,
            send_to: checkValue(this.props.userClientEmail)
              ? this.props.userClientEmail.emails
              : [],
          },
        });
      }
    }
    return true;
  }

  onEditorChange = (evt) => {
    this.setState({
      mail_body: evt.editor.getData(),
    });
  };

  onDropdownVisibleChange(open, selectValue) {
    this.setState({
      open,
      selectValue,
    });
  }

  handleChange(event, selectValue) {
    const { value, id } = event.target;
    this.setState({
      [selectValue]: value,
    });
    setTimeout(() => {
      let element = document.getElementById(id);
      if (this.state[selectValue].length > 0) {
        element.classList.remove("email_error_input");
        this.setState({
          [`${selectValue}_error`]: false,
        });
      } else {
        element.classList.add("email_error_input");
        this.setState({
          [`${selectValue}_error`]: true,
        });
      }
    }, 500);
  }

  handleSearch(searchValue, selectValue) {
    if (searchValue.length > 3) {
      this.props.getClientEmail({
        type: selectValue,
        contains_term: searchValue,
      });
    } else if (searchValue.length === 0) {
      this.props.getClientEmail({ type: selectValue });
    }
    this.setState({
      selectValue,
      searchValue: {
        ...this.state.searchValue,
        [selectValue]: searchValue,
      },
      page: {
        ...this.state.page,
        [selectValue]: 1,
      },
    });
  }

  handleSelect(value, selectValue) {
    this.setState({
      tags: {
        ...this.state.tags,
        [selectValue]: uniq([...this.state.tags[selectValue], value]),
      },
    });
  }

  handleKeyPress(event, selectValue) {
    const { id, value } = event.target;
    if (event.key === "Enter") {
      if (value && value.includes("@")) {
        this.setState({
          tags: {
            ...this.state.tags,
            [selectValue]: uniq([...this.state.tags[selectValue], value]),
          },
        });
        document.getElementById(id).value = "";
      }
    }
  }

  handleDeleteTag(tag, selectValue) {
    this.deletePrevious.current = false;
    this.setState({
      tags: {
        ...this.state.tags,
        [selectValue]: this.state.tags[selectValue].filter(
          (eachTag) => eachTag !== tag,
        ),
      },
    });
  }

  handleLoadMore(event, selectValue) {
    const { target } = event;
    let pagination;
    if (selectValue === "send_to") {
      pagination = this.props.clientEmailCount / 100;
    } else if (selectValue === "cc") {
      pagination = this.props.clientEmailCCCount / 100;
    }
    if (
      pagination >= this.state.page[selectValue] &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      this.setState(
        {
          selectValue,
          page: {
            ...this.state.page,
            [selectValue]: this.state.page[selectValue] + 1,
          },
        },
        () => {
          target.scrollTo(0, target.scrollHeight);
          setTimeout(() => {
            this.props.getClientEmail({
              type: selectValue,
              page: this.state.page[selectValue],
            });
          }, 500);
        },
      );
    }
  }

  handleSelectTemplate(data) {
    const { report_tag, product } = this.props;
    let tagValue = [];
    if (product === "MANAGER") {
      let oldTags = [
        "report_name",
        "status",
        "journal_number",
        "journal_date",
        "tla_hearing_date",
        "opposition_hearing_date",
        "opponent_name",
        "proprietor_name",
        "renewal_date",
        "application_number",
        "mark_name",
        "date_of_application",
        "class",
        "date_of_usage",
      ];
      tagValue = [
        "trademark_application_number",
        "trademark_name",
        "trademark_status",
        "trademark_classes",
        "date_of_application",
        "date_of_usage",
        "renewal_date",
        "g_and_s_description",
        "trademark_images",
        "journal_number",
        "journal_date",
        "proprietor_names",
        "proprietor_addresses",
        "proprietor_trading_as",
        "latest_tla_hearing_date",
        "opponent_names",
        "opponent_numbers",
        "opponent_attorney_names",
        "opponent_attorney_addresses",
        "attorney_name",
        "attorney_address",
        "latest_date_of_examination_report",
        "formatted_proprietor_addresses",
        "latest_formality_fail_date",
        "latest_formality_fail_reason",
        "latest_examination_report_objection",
        "tags",
      ];
      tagValue.push(oldTags);
    } else if (product === "SEARCH") {
      tagValue = ["mark_name", "created_date"];
    } else if (product === "WATCH") {
      let oldTags = [
        "report_name",
        "journal_number",
        "journal_date",
        "opposition",
        "current_date",
        "your_mark_image",
        "threat_mark_image",
        "your_mark",
        "your_application",
        "your_DOA",
        "your_DOU",
        "your_class",
        "your_proprietor",
        "your_address",
        "threat_mark",
        "threat_application",
        "threat_DOA",
        "threat_DOU",
        "threat_class",
        "threat_proprietor",
        "threat_address",
        "threat_description",
        "mark_description",
      ];

      tagValue = [
        "journal_number",
        "journal_date",
        "last_date_of_filing_opposition",
        "current_date",
        "your_trademark_name",
        "your_trademark_images",
        "your_application_number",
        "your_date_of_application",
        "your_date_of_usage",
        "your_trademark_classes",
        "your_proprietor_names",
        "your_proprietor_addresses",
        "your_proprietor_trading_as",
        "your_g_and_s_description",
        "your_attorney_name",
        "your_attorney_address",
        "tags",
        "threat_trademark_name",
        "threat_trademark_images",
        "threat_application_number",
        "threat_date_of_application",
        "threat_date_of_usage",
        "threat_trademark_classes",
        "threat_proprietor_names",
        "threat_proprietor_addresses",
        "threat_proprietor_trading_as",
        "threat_g_and_s_description",
        "threat_attorney_name",
        "threat_attorney_address",
        "your_formatted_proprietor_addresses",
        "threat_formatted_proprietor_addresses",
        "latest_date_of_examination_report",
      ];
      tagValue.push(oldTags);
    }
    let subjectText = data.subject.toString();
    let contentText = data.content.toString();
    const templateInjectData = this.props.emailTemplateData;

    if (templateInjectData !== null) {
      tagValue.forEach((data) => {
        if (
          data === "your_trademark_images" ||
          data === "threat_trademark_images"
        ) {
          let image = "";
          if (templateInjectData[data]?.length > 0) {
            templateInjectData[data].forEach(
              (imgUrl) =>
                (image =
                  image +
                  `<img src=${imgUrl} style="height:50px; width:50px;margin: 0px 12px ;" alt="No Image"/>`),
            );
          } else {
            image = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, image);
        } else if (data === "tags") {
          let tags = "";
          if (templateInjectData[data]?.length > 0) {
            templateInjectData[data].forEach((tag, index) => {
              if (index !== templateInjectData[data].length - 1)
                tags = tags + `<span>${tag.name}</span>,`;
              else tags = tags + `<span>${tag.name}</span>`;
            });
          } else {
            tags = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, tags);
        } else if (
          data === "your_formatted_proprietor_addresses" ||
          data === "threat_formatted_proprietor_addresses" ||
          data === "formatted_proprietor_addresses"
        ) {
          let address = "";

          if (templateInjectData[data]?.length > 0) {
            templateInjectData[data].forEach(
              (tag) =>
                (address =
                  address +
                  `<div  style="
                        margin: 2px;
                        " >${tag}</div>`),
            );
          } else {
            address = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, address);
        } else {
          subjectText = subjectText.replaceAll(
            `@${data}`,
            `${templateInjectData[data]}`,
          );
          contentText = contentText.replaceAll(
            `@${data}`,
            `<span>${templateInjectData[data]}</span>`,
          );
        }
      });
    } else {
      tagValue.forEach((data) => {
        if (
          data === "your_trademark_images" ||
          data === "threat_trademark_images" ||
          data === "trademark_images"
        ) {
          let image = "";
          if (report_tag[data]) {
            image =
              image +
              `<img src=${report_tag[data]} style="height:50px; width:50px;margin: 0px 12px ;" alt="No Image"/>`;
          } else {
            image = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, image);
        } else {
          subjectText = subjectText.replaceAll(
            `@${data}`,
            `${report_tag[data]}`,
          );
          contentText = contentText.replaceAll(
            `@${data}`,
            `<span>${report_tag[data]}</span>`,
          );
        }
      });
    }

    this.setState({
      subject: subjectText,
      mail_body: contentText,
      select_doc_link: [],
    });
  }

  // handleUploadFile(file){
  //     const isLt5M = ((file.size / (1024 * 1024)) < 5);
  //     if (isLt5M) {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(file)
  //         reader.onloadend = () => {
  //             this.setState({
  //                 file_attachment: {
  //                     file: file,
  //                     fileName: file.name,
  //                     filePreviewUrl: reader.result,
  //                 },
  //             });
  //         }
  //     } else {
  //         message.error('File size must smaller than 5MB!');
  //     }
  // }

  handleUploadMultipleFile(files) {
    let sum = 0;
    sum = Array.from(files).reduce(function (accumulator, current_file) {
      return accumulator + current_file.size;
    }, 0);

    const isLt5M = sum / (1024 * 1024) < 5;
    if (isLt5M) {
      this.setState({
        multiple_files: files,
      });
    } else {
      message.error("File size must smaller than 5MB!");
    }
  }
  removeFileUpload() {
    document.getElementById("files").value = null;
    this.setState({
      file_attachment: {
        file: "",
        fileName: "",
        filePreviewUrl: "",
      },
    });
  }

  handleDocument(name, url) {
    if (this.state.mail_body.includes(`<p><a href="${url}">${name}</a></p>`)) {
      this.setState({
        mail_body: this.state.mail_body.replaceAll(
          `<p><a href="${url}">${name}</a></p>`,
          "",
        ),
      });
    } else {
      this.setState({
        mail_body: this.state.mail_body + `<p><a href="${url}">${name}</a></p>`,
      });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {
      send_from,
      subject,
      tags,
      mail_body,
      file_attachment,
      attachment,
      multiple_files,
    } = this.state;

    let sendFromElement = document.getElementById("send_from_input");
    let sendToElement = document.getElementById("send_to_input");
    let subjectElement = document.getElementById("subject_input");
    if (send_from.length > 0) {
      sendFromElement.classList.remove("email_error_input");
      this.setState({
        send_from_error: false,
      });
    } else {
      sendFromElement.classList.add("email_error_input");
      this.setState({
        send_from_error: true,
      });
    }
    if (tags.send_to.length > 0) {
      sendToElement.classList.remove("email_error_input");
      this.setState({
        send_to_error: false,
      });
    } else {
      sendToElement.classList.add("email_error_input");
      this.setState({
        send_to_error: true,
      });
    }
    if (subject.length > 0) {
      subjectElement.classList.remove("email_error_input");
      this.setState({
        subject_error: false,
      });
    } else {
      subjectElement.classList.add("email_error_input");
      this.setState({
        subject_error: true,
      });
    }

    if (send_from.length > 0 && subject.length > 0 && tags.send_to.length > 0) {
      if (this.props.managerEmail) {
        await this.props.sendManagerEmail({
          send_from: checkValue(send_from) ? send_from : "",
          send_to: checkValue(tags.send_to) ? `${tags.send_to}` : "",
          cc: checkValue(tags.cc) ? `${tags.cc}` : "",
          mail_subject: checkValue(subject) ? subject : "",
          mail_body: checkValue(mail_body) ? mail_body : "",
          source: checkValue(this.props.source) ? this.props.source : "",
          source_id: checkValue(this.props.sourceId) ? this.props.sourceId : "",
          // file_attachment: checkValue(file_attachment) ? file_attachment : '',
          attachment: checkValue(attachment) ? attachment : [],
          multiple_files: multiple_files,
        });
        message[this.props.sendManagerEmailStatus.toLowerCase()](
          this.props.sendManagerEmailMessage,
        );
      } else {
        await this.props.sendCustomEmail({
          send_from: checkValue(send_from) ? send_from : "",
          send_to: checkValue(tags.send_to) ? `${tags.send_to}` : "",
          cc: checkValue(tags.cc) ? `${tags.cc}` : "",
          mail_subject: checkValue(subject) ? subject : "",
          mail_body: checkValue(mail_body) ? mail_body : "",
          // file_attachment: checkValue(file_attachment) ? file_attachment : '',
          attachment: checkValue(attachment) ? attachment : [],
          multiple_files: multiple_files,
        });
        message[this.props.sendEmailStatus.toLowerCase()](
          this.props.sendEmailMessage,
        );
      }
      this.props.getClientEmail({ type: "send_to" });
      this.props.getClientEmail({ type: "cc" });
      this.setState({
        open: false,
        selectValue: "",
        searchValue: {
          send_to: "",
          cc: "",
        },
        tags: {
          send_to: [],
          cc: [],
        },
        page: {
          send_to: 1,
          cc: 1,
        },
        send_from_error: false,
        send_to_error: false,
        subject_error: false,
      });
    }
  }

  async handleDownloadLetter(event) {
    event.preventDefault();
    const { mail_body, add_journal_copy, letter_head_visible } = this.state;
    await this.props.downloadLetterHead({
      content: mail_body,
      add_journal_copy: add_journal_copy,
      letter_head_visible: letter_head_visible,
      file_name: this.state.file_name,
      journal_number: this.state?.report_tag?.journal_number,
      application_number: this.state?.report_tag?.threat_application,
    });
  }

  handleClose() {
    this.setState({
      subject: "",
      mail_body: "",
      selectedKeys: [],
      attachment: [],
      report_tag: [],
      file_attachment: {
        file: "",
        fileName: "",
        filePreviewUrl: "",
      },
      select_doc_link: [],
      send_from_error: false,
      send_to_error: false,
      subject_error: false,
    });
    this.props.onCloseModal();
  }

  render() {
    const {
      sendEmailStatus,
      clientEmail,
      clientEmailStatus,
      clientEmailCC,
      clientEmailCCStatus,
      emailTemplate,
      emailTemplateStatus,
      documentList,
      documentListStatus,
    } = this.props;

    let dataSource_sendTo;
    let dataSource_cc;
    let emailTemplateMenu = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Email Template Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let attachedDocMenu = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Document Found Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let popovercontent = (
      <>
        {" "}
        <span>
          <Input
            value={this.state.file_name}
            prefix={<FileTextOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="text"
            placeholder="File Name"
            style={{
              margin: "0px 16px",
              width: "200px",
            }}
            onChange={(e) => this.setState({ file_name: e?.target?.value })}
          />
        </span>
        <Tooltip placement="topLeft" title="Attach Letterhead">
          <Checkbox
            checked={this.state?.letter_head_visible}
            onChange={(e) => {
              this.setState({ letter_head_visible: e?.target?.checked });
            }}
          ></Checkbox>
        </Tooltip>
        {(this.props?.subscription?.includes("JOURNAL WATCH") ||
          this.props?.subscription?.includes("IMAGE_RECOGNITION")) && (
          <Tooltip placement="topLeft" title="Add Journal Copy">
            <Checkbox
              checked={this.state?.add_journal_copy}
              onChange={(e) => {
                this.setState({ add_journal_copy: e?.target?.checked });
              }}
            ></Checkbox>
          </Tooltip>
        )}
        <Button
          type="dashed"
          key="submit"
          htmlType="submit"
          onClick={(event) => this.handleDownloadLetter(event)}
          style={{ margin: "0px 10px" }}
          loading={sendEmailStatus === LOADING}
        >
          <i
            className="fa fa-download"
            style={{ padding: "0px 5px" }}
            aria-hidden="true"
          ></i>
        </Button>
      </>
    );
    if (clientEmailStatus === SUCCESS) {
      dataSource_sendTo =
        clientEmail &&
        clientEmail.client_contact.map((eachmail) => (
          <Option
            key={`send_to-${eachmail.id}`}
            id={`send_to-${eachmail.id}`}
            value={eachmail.email}
          >
            {eachmail.email} - {eachmail.person_name} - {eachmail.company_name}
          </Option>
        ));
    }

    if (clientEmailCCStatus === SUCCESS) {
      dataSource_cc =
        clientEmailCC &&
        clientEmailCC.client_contact.map((eachmail) => (
          <Option
            key={`send_to-${eachmail.id}`}
            id={`send_to-${eachmail.id}`}
            value={eachmail.email}
          >
            {eachmail.email} - {eachmail.person_name} - {eachmail.company_name}
          </Option>
        ));
    }

    if (emailTemplateStatus === SUCCESS) {
      if (emailTemplate && emailTemplate.email_template.length > 0) {
        emailTemplateMenu = (
          <FixedSizeList
            className="List"
            height={400}
            itemCount={emailTemplate.email_template.length}
            itemSize={200}
            itemData={emailTemplate.email_template}
            width={500}
            style={{
              padding: 10,
              background: "#fff",
              border: "1px solid #ddd",
            }}
          >
            {({ index, style, data }) => {
              return (
                <Menu
                  selectable={true}
                  multiple={false}
                  selectedKeys={this.state.selectedKeys}
                  onSelect={(event) =>
                    this.changeStateValue_L0("selectedKeys", event.selectedKeys)
                  }
                  onDeselect={(event) =>
                    this.changeStateValue_L0("selectedKeys", event.selectedKeys)
                  }
                >
                  <Menu.Item
                    key={data[index].id}
                    style={{
                      ...style,
                      overflow: "scroll",
                      borderBottom: "1px solid #ddd",
                      height: "200px",
                      overflowWrap: "break-word",
                    }}
                    onClick={() => this.handleSelectTemplate(data[index])}
                    className="menu-items-list"
                  >
                    <div>
                      <div style={{ padding: "5px 0px" }}>
                        <Tag color="grey">Subject</Tag>
                        <Text>{data[index].subject}</Text>
                      </div>
                      <div style={{ padding: "5px 0px" }}>
                        <div
                          id={`select-template-id-${data[index].id}`}
                          dangerouslySetInnerHTML={{
                            __html: data[index].content,
                          }}
                        />
                      </div>
                    </div>
                  </Menu.Item>
                </Menu>
              );
            }}
          </FixedSizeList>
        );
      }
    }

    if (documentListStatus === SUCCESS) {
      let document = documentList && documentList.documents;
      let document1,
        document2,
        document3,
        document4,
        document5 = [];

      // opposition_documents
      if (
        document.opposition_documents &&
        document.opposition_documents.length > 0
      ) {
        document1 = document.opposition_documents.map((doc) => ({
          name: doc.description,
          url: doc.file ? doc.file : doc.url ? doc.url : "No Document",
        }));
      }

      // opposition_correspondence_notices
      if (
        document.opposition_correspondence_notices &&
        document.opposition_correspondence_notices.length > 0
      ) {
        document2 = document.opposition_correspondence_notices.map((doc) => ({
          name: doc.subject,
          url: doc.file ? doc.file : doc.url ? doc.url : "No Document",
        }));
      }

      // correspondence_notices
      if (
        document.correspondence_notices &&
        document.correspondence_notices.length > 0
      ) {
        document3 = document.correspondence_notices.map((doc) => ({
          name: doc.subject,
          url: doc.file ? doc.file : doc.url ? doc.url : "No Document",
        }));
      }

      // documents
      if (document.documents && document.documents.length > 0) {
        document4 = document.documents.map((doc) => ({
          name: doc.description,
          url: doc.file ? doc.file : doc.url ? doc.url : "No Document",
        }));
      }

      // examination_report_documents
      if (
        document.examination_report_documents &&
        document.examination_report_documents.length > 0
      ) {
        document5 = document.examination_report_documents.map((doc) => ({
          name: "Examination Report",
          url: doc.url
            ? doc.url.includes("http://")
              ? doc.url
              : `https://`.concat(doc.url)
            : "No Document",
        }));
      }

      const documents_attached = Array.prototype.concat.apply(
        [],
        [document1, document2, document3, document4, document5],
      );
      const attached_doc = checkValue(documents_attached)
        ? compact(uniqWith(documents_attached, isEqual))
        : [];

      if (attached_doc && attached_doc.length > 0) {
        attachedDocMenu = (
          <FixedSizeList
            className="List"
            height={150}
            itemCount={attached_doc.length}
            itemSize={35}
            itemData={attached_doc}
            width={300}
            style={{
              padding: 10,
              background: "#fff",
              border: "1px solid #ddd",
            }}
          >
            {({ index, style, data }) => {
              return (
                <Menu
                  selectable={true}
                  multiple={true}
                  selectedKeys={this.state.select_doc_link}
                  onSelect={(event) =>
                    this.changeStateValue_L0(
                      "select_doc_link",
                      event.selectedKeys,
                    )
                  }
                  onDeselect={(event) =>
                    this.changeStateValue_L0(
                      "select_doc_link",
                      event.selectedKeys,
                    )
                  }
                >
                  <Menu.Item
                    key={data[index].url}
                    style={{
                      ...style,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      borderBottom: "1px solid #ddd",
                    }}
                    className="menu-items-list"
                  >
                    <div
                      onClick={() =>
                        this.handleDocument(data[index].name, data[index].url)
                      }
                    >
                      {data[index].name}
                    </div>
                  </Menu.Item>
                </Menu>
              );
            }}
          </FixedSizeList>
        );
      }
    }

    return (
      <Modal
        open={this.props.visible}
        onOk={this.props.onCloseModal}
        onCancel={this.props.onCloseModal}
        closable={false}
        footer={false}
        maskClosable={false}
        className="custom-send-email-modal"
        title={
          <>
            <div className="alignL" style={{ display: "inline-block" }}>
              <Dropdown overlay={emailTemplateMenu}>
                <Button
                  type={
                    this.state.selectedKeys.length > 0 ? "primary" : "default"
                  }
                  ghost={this.state.selectedKeys.length > 0}
                >
                  Select Email Template <CaretDownOutlined />
                </Button>
              </Dropdown>
            </div>
            <div className="alignL" style={{ display: "inline-block" }}>
              <Button style={{ margin: "0px 10px" }}>
                <input
                  type="file"
                  id="files"
                  name="files"
                  accept="image/*, .pdf, .xlsx, .xls, .doc, .docx"
                  onChange={(event) =>
                    this.handleUploadMultipleFile(event.target.files)
                  }
                  multiple
                />
              </Button>
              <RestFilled
                style={{
                  marginRight: 5,
                  fontSize: 20,
                  color: "#f81d22",
                  cursor: "pointer",
                  visibility: `${
                    checkValue(this.state.file_attachment.fileName)
                      ? "visible"
                      : "hidden"
                  }`,
                }}
                onClick={() => this.removeFileUpload()}
              />
            </div>
            {this.props.doc_link && (
              <div className="alignR" style={{ display: "inline-block" }}>
                <Dropdown overlay={attachedDocMenu}>
                  <Button
                    type={
                      this.state.select_doc_link.length > 0
                        ? "primary"
                        : "default"
                    }
                    ghost={this.state.select_doc_link.length > 0}
                  >
                    Select Document <CaretDownOutlined />
                  </Button>
                </Dropdown>
              </div>
            )}
          </>
        }
      >
        <div className="custom-send-email-container vertical_scroll">
          <Row className="custom-send-email">
            <Col span={24} className="custom-send-email-form">
              <div className="custom-send-email-Input-Field">
                <Tooltip placement="topLeft" title="Please enter email address">
                  <Input
                    id="send_from_input"
                    prefix={
                      <Text strong style={{ color: "rgba(0,0,0,.25)" }}>
                        From
                      </Text>
                    }
                    type="email"
                    className="input-box send_from_box"
                    defaultValue={
                      checkValue(this.props.sendFrom) ? this.props.sendFrom : ""
                    }
                    onChange={(event) => this.handleChange(event, "send_from")}
                  />
                </Tooltip>
              </div>
              {!this.state.send_from.length > 0 &&
                this.state.send_from_error && (
                  <div className="email_error_box">
                    <Text className="email_error_text">
                      Please enter email address
                    </Text>
                  </div>
                )}
              <div className="custom-send-email-Input-Field">
                <Tooltip
                  placement="topLeft"
                  title="Please press Enter after each entry to add the email address"
                >
                  <div style={{ margin: 5 }}>
                    {this.state.tags.send_to.map((eachTag) => (
                      <Tag
                        style={{ display: "inline" }}
                        key={eachTag}
                        closable
                        onClose={() => this.handleDeleteTag(eachTag, "send_to")}
                      >
                        {eachTag}
                      </Tag>
                    ))}
                  </div>
                  <AutoComplete
                    key={`Autocomplete${this.state.tags.send_to.length}`}
                    ref={this.autoRef}
                    allowClear
                    backfill={true}
                    autoFocus={true}
                    autoClearSearchValue={true}
                    dataSource={dataSource_sendTo}
                    onSearch={(value) => this.handleSearch(value, "send_to")}
                    onSelect={(value) => this.handleSelect(value, "send_to")}
                    onDropdownVisibleChange={(open) =>
                      this.onDropdownVisibleChange(open, "send_to")
                    }
                    onPopupScroll={(event) =>
                      this.handleLoadMore(event, "send_to")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    style={{ width: "100%" }}
                  >
                    <Input
                      id="send_to_input"
                      prefix={
                        <Text strong style={{ color: "rgba(0,0,0,.25)" }}>
                          To
                        </Text>
                      }
                      className="custom-autocomplete-input"
                      onKeyPress={(event) =>
                        this.handleKeyPress(event, "send_to")
                      }
                      onChange={(event) => this.handleChange(event, "send_to")}
                    />
                  </AutoComplete>
                </Tooltip>
              </div>
              {!this.state.tags.send_to.length > 0 &&
                this.state.send_to_error && (
                  <div className="email_error_box">
                    <Text className="email_error_text">
                      Please press Enter after each entry to add the email
                      address
                    </Text>
                  </div>
                )}
              <div className="custom-send-email-Input-Field">
                <Tooltip
                  placement="topLeft"
                  title="Please press Enter after each entry to add the email address"
                >
                  <div style={{ margin: 5 }}>
                    {this.state.tags.cc.map((eachTag) => (
                      <Tag
                        key={eachTag}
                        style={{ display: "inline" }}
                        closable
                        onClose={() => this.handleDeleteTag(eachTag, "cc")}
                      >
                        {eachTag}
                      </Tag>
                    ))}
                  </div>
                  <AutoComplete
                    key={`Autocomplete${this.state.tags.cc.length}`}
                    ref={this.autoRef}
                    allowClear
                    backfill={true}
                    autoFocus={true}
                    autoClearSearchValue={true}
                    dataSource={dataSource_cc}
                    onSearch={(value) => this.handleSearch(value, "cc")}
                    onSelect={(value) => this.handleSelect(value, "cc")}
                    onDropdownVisibleChange={(open) =>
                      this.onDropdownVisibleChange(open, "cc")
                    }
                    onPopupScroll={(event) => this.handleLoadMore(event, "cc")}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    style={{ width: "100%" }}
                  >
                    <Input
                      id="cc_input"
                      prefix={
                        <Text strong style={{ color: "rgba(0,0,0,.25)" }}>
                          CC
                        </Text>
                      }
                      className="custom-autocomplete-input"
                      onKeyPress={(event) => this.handleKeyPress(event, "cc")}
                    />
                  </AutoComplete>
                </Tooltip>
              </div>
              <div className="custom-send-email-Input-Field">
                <Tooltip placement="topLeft" title="Please enter mail subject">
                  <Input
                    id="subject_input"
                    prefix={
                      <Text strong style={{ color: "rgba(0,0,0,.25)" }}>
                        Subject
                      </Text>
                    }
                    type="text"
                    className="input-box subject_box"
                    value={this.state.subject}
                    onChange={(event) => this.handleChange(event, "subject")}
                  />
                </Tooltip>
              </div>
              {!this.state.subject.length > 0 && this.state.subject_error && (
                <div className="email_error_box">
                  <Text className="email_error_text">
                    Please enter mail subject
                  </Text>
                </div>
              )}
              <div className="custom-send-email-Input-Field">
                <div className="text-editor">
                  <CKEditor
                    data={this.state.mail_body}
                    onChange={this.onEditorChange}
                    editorUrl={
                      "https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
                    }
                    config={{
                      extraAllowedContent: "div(*)",
                      extraPlugins: ["editorplaceholder"],
                      editorplaceholder: "Start typing here...",
                    }}
                  />
                </div>
              </div>
              <div className="custom-send-email-Input-Field alignR">
                <Popover content={popovercontent} title="Download As PDF">
                  <Button
                    type="dashed"
                    key="submit"
                    htmlType="submit"
                    // onClick={(event)=> this.setState({downloadContent:true})}
                    // style={this.state?.downloadContent ?
                    //      {display: 'None'}

                    //      :
                    //      {margin: '0px 10px'}
                    //     }
                    loading={sendEmailStatus === LOADING}
                  >
                    <i
                      className="fa fa-download"
                      style={{ padding: "0px 5px" }}
                      aria-hidden="true"
                    ></i>
                    Content
                  </Button>
                </Popover>

                <Button
                  type="primary"
                  key="submit"
                  htmlType="submit"
                  onClick={(event) => this.handleSubmit(event)}
                  style={{ margin: "0px 10px" }}
                  loading={sendEmailStatus === LOADING}
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{ padding: "0px 5px" }}
                    aria-hidden="true"
                  ></i>
                  Send
                </Button>
                <Button onClick={() => this.handleClose()}>Close</Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  sendCustomEmail,
  getClientEmail,
  getEmailTemplate,
  getUserClientEmail,
  getEmailDocument,
  sendManagerEmail,
  downloadLetterHead,
};

function mapStateToProps(state) {
  const {
    sendEmailMessage,
    sendEmailStatus,
    clientEmail,
    clientEmailCount,
    clientEmailStatus,
    clientEmailMoreStatus,
    clientEmailCC,
    clientEmailCCCount,
    clientEmailCCStatus,
    clientEmailCCMoreStatus,
    userClientEmail,
    userClientEmailStatus,
    documentList,
    documentListStatus,
    downloadContentStatus,
  } = state.sendEmailState;
  const { emailTemplate, emailTemplateStatus } = state.emailTemplateState;
  const { emailTemplateData, emailTemplateDataStatus } =
    state.emailTemplateDataState;

  const { sendManagerEmailMessage, sendManagerEmailStatus } =
    state.managerState.managerEmailState;
  return {
    sendEmailMessage,
    sendEmailStatus,
    clientEmail,
    clientEmailCount,
    clientEmailStatus,
    clientEmailMoreStatus,
    clientEmailCC,
    clientEmailCCCount,
    clientEmailCCStatus,
    clientEmailCCMoreStatus,

    documentList,
    documentListStatus,

    emailTemplate,
    emailTemplateStatus,

    userClientEmail,
    userClientEmailStatus,

    sendManagerEmailMessage,
    sendManagerEmailStatus,
    downloadContentStatus,

    emailTemplateData,
    emailTemplateDataStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);
