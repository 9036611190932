import React, { useEffect, useState } from "react";
import { Button, Empty, Spin, Tooltip, Upload, message } from "antd";
import MikeContainer from "../../CommonComponents/Layout/MikeContainer";
import { ROOT_URL } from "../../../../configs/rootConfigs";
import { WAT_RT_URL } from "../../../../configs/rootConfigs";
import axios from "axios";
import GridContent from "./Components/ContentGrid";
import InterestPopup from "./Components/InterestPopup";
import ClassFilter from "./Components/ClassFilter";
import SliderFilter from "./Components/SliderFilter";
const App = () => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [journal, setJournal] = useState({});
  const [file, setFile] = useState(null);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const [associatedClass, setAssociatedClass] = useState(null);
  const [classOptions, setClassOptions] = useState();
  const [textPer, setTextPer] = useState(0);

  const [showPopupCondition, setShowPopupCondition] = useState(false);

  const getData = async () => {
    setLoading(true);
    let response = await axios.get(`${ROOT_URL}/watch/new/journals/`, {
      params: { dashboard_type: "IMAGE_SEARCH" },
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    });

    setJournal(response.data.data[0]);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getResults = async (file) => {
    // const file = fileList[0]
    const formData = new FormData();
    formData.append("image", file);
    setLoading(true);
    await axios
      .put(`${WAT_RT_URL}/search_image/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setResults(response.data?.data?.result);
        setFilteredResults(response.data?.data?.result);
        setClassOptions(
          response.data?.data?.filters?.associated_classes?.map((x) => ({
            label: x,
            value: x,
          })),
        );
      })
      .catch((error) => {
        // Handle error
        setResults([]);
        setFilteredResults([]);
        message.error(
          error?.response?.data?.payload?.detail ||
            "Error uploading image. Please try again later.",
        );
      });
    setLoading(false);
  };

  useEffect(() => {}, [classOptions?.length]);
  const handleUploadChange = async (info) => {
    let fileList = [...info.fileList];

    // Limiting to 1 file
    fileList = fileList.slice(-1);

    // Convert uploaded file to Base64 before displaying
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    if (fileList?.length === 0) {
      setResults([]);
      setFilteredResults([]);
    } else {
      await getResults(file);
      setShowPopupCondition(true);
    }
    setFileList(fileList);
  };

  const filterClasses = (value) => {
    let res = results;
    const filteredArray = res.filter((obj) =>
      obj.associated_class.includes(value),
    );

    setFilteredResults(filteredArray);
  };
  const filterTextPer = (value) => {
    let res = results;
    let finalResult = [];
    finalResult = res.filter((obj) => obj.image_text_percentage <= value);
    if (associatedClass !== null) {
      finalResult = res.filter((obj) =>
        obj.associated_class.includes(associatedClass),
      );
    }
    setFilteredResults(finalResult);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error("Image must smaller than 20MB!");
    }
    setFile(file);
    return false;
  };

  let Content = null;
  if (loading) {
    Content = (
      <div className="spin-container" style={{ width: "100%" }}>
        <Spin size="large" />
      </div>
    );
  } else {
    Content = (
      <>
        {Object.values(filteredResults || []).length ? (
          <div
            className={"General-Content  Flex-Content  vertical_scroll"}
            style={{
              height: "calc(100% - 210px)",
            }}
          >
            {Object.values(
              filteredResults.sort(
                (a, b) => b.similarity_score - a.similarity_score,
              ) || [],
            ).map((obj, index) => (
              <GridContent key={`${obj.id}_${index}`} eachMark={obj} />
            ))}
          </div>
        ) : (
          <div
            className={"General-Content Flex-Content vertical_scroll"}
            style={{
              height: "calc(100% - 210px)",
            }}
          >
            <Empty
              style={{
                display: "table-cell",
                verticalAlign: "middle",
                margin: "auto",
              }}
              description="No results found"
            >
              <p style={{ color: "grey" }}>
                <span>Please upload images from journal</span>{" "}
                <span style={{ color: "black" }}>{journal?.label}</span>{" "}
                <span>for better results.</span>
              </p>
            </Empty>
          </div>
        )}
      </>
    );
  }

  const ImageUploader = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ margin: "2%" }}>
        <Upload
          accept=".png, .jpg, .jpeg"
          listType="picture-card"
          fileList={fileList}
          onChange={handleUploadChange}
          onPreview={onPreview}
          beforeUpload={beforeUpload}
        >
          + Upload
        </Upload>
      </div>
    </div>
  );

  const filters = (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "8px" }}>
        <ClassFilter
          associatedClass={associatedClass}
          options={classOptions}
          handleChange={(value) => {
            setAssociatedClass(value);
            filterClasses(value);
          }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", margin: "8px" }}>
        <Tooltip title="Filter marks on the basis of text component ">
          <span style={{ marginRight: "4px" }}>Text Percentage : </span>
        </Tooltip>

        <span>
          <SliderFilter
            value={textPer}
            handleTextPer={(value) => {
              setTextPer(value);
              filterTextPer(value);
            }}
          />
        </span>
      </div>
      <div style={{ margin: "8px 0px" }}>
        <Button
          type="link"
          onClick={() => {
            setAssociatedClass(null);
            setTextPer(0);
            setFilteredResults(results);
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
  return (
    <MikeContainer
      header={
        <>
          <div style={{ display: "flex" }}>
            <div style={{ margin: "4px", minWidth: "400px" }}>
              {ImageUploader}
            </div>
            <div style={{ margin: "16px", marginLeft: "auto" }}>
              Journal Used : {journal?.label}
            </div>
          </div>
          {filters}
        </>
      }
      content={Content}
      modals={showPopupCondition && <InterestPopup />}
    />
  );
};
export default App;
