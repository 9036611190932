import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import _ from "lodash";

import {
  FileTwoTone,
  TrademarkCircleTwoTone,
  UpSquareTwoTone,
} from "@ant-design/icons";

import { Row, Col, Tabs, Table, Card, Typography, Divider } from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import {
  getProprietor,
  getProprietorDetail,
} from "../../../redux/actions/managerActions/trademarkDashboard/actions";

import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { logOut } from "../../../redux/actions/commonActions/actions";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Bar,
  Cell,
  XAxis,
  YAxis,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import {
  renderBarCustomTooltip,
  renderPieCustomTooltip,
  renderPieCustomizedLabel,
} from "../../../functions/commonFunctions/reChartsFunctions";

const colors = scaleOrdinal([
  ...schemeCategory10,
  ...schemeCategory10,
  ...schemeCategory10,
]).range();

class ProprietorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StatusSeries: [],
      ClassSeries: [],
      graph_stored: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      let proprietor_id = sessionStorage.getItem("proprietor_id");
      this.props.getProprietor(proprietor_id);
      this.props.getProprietorDetail(proprietor_id);
    } else {
      this.props.logOut();
    }
  }

  setGraphData(StatusSeries, ClassSeries) {
    this.setState({
      StatusSeries: StatusSeries,
      ClassSeries: ClassSeries,
      graph_stored: true,
    });
  }

  render() {
    const ProprietorMarkColumns = [
      {
        title: "TRADEMARK",
        render: (text, record) => {
          return (
            <div>
              <p>
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.applied_for)
                    ? record.applied_for.toLowerCase()
                    : "N.A."}
                </span>
              </p>
              <p>
                <img
                  className="trademark-image"
                  src={
                    checkValue(record.associated_image)
                      ? record.associated_image
                      : ""
                  }
                  alt=""
                />
              </p>
            </div>
          );
        },
      },

      {
        title: "STATUS",
        dataIndex: "status",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "CLASS",
        dataIndex: "associated_class",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.join(", ") : "N.A."}
          </p>
        ),
      },

      {
        title: "DATE OF APPLICATION",
        dataIndex: "application_date",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
      },
    ];

    if (this.props.proprietorDetailStatus === SUCCESS) {
      let StatusSeries = _.map(
        this.props.proprietorDetail.status_distribution,
        function (graphData) {
          return {
            name: graphData?.status,
            value: graphData?.frequency,
          };
        },
      );

      let ClassSeries = _.map(
        this.props.proprietorDetail.class_distribution,
        function (graphData) {
          return {
            name: "Class " + graphData?.class_number,
            data: [graphData?.frequency],
            uv: graphData?.frequency,
          };
        },
      );

      StatusSeries = _.filter(StatusSeries, function (distribution) {
        return distribution.y !== 0 && distribution.name !== null;
      });

      ClassSeries = _.filter(ClassSeries, function (distribution) {
        return distribution.data[0] !== 0;
      });

      if (
        StatusSeries.length > 0 &&
        ClassSeries.length > 0 &&
        !this.state.graph_stored
      ) {
        this.setGraphData(StatusSeries, ClassSeries);
      }
    }

    const chartIdStatus = "proprietor_analysis_statusChart";

    return (
      <div>
        {/* back-section */}
        <Row>
          <Col span={24} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
        </Row>
        {/* back-section */}

        {/* card-section */}
        <Row gutter={16} className="m-top-10">
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={
                this.props.proprietorDetailStatus === LOADING ? true : false
              }
            >
              <Col span={4}>
                <FileTwoTone style={{ fontSize: "35px" }} />
              </Col>
              <Col span={20}>
                <Title level={4} style={{ fontSize: "14px" }}>
                  TOTAL APPLICATIONS
                </Title>
                <Text strong style={{ fontSize: "14px" }}>
                  {checkValue(this.props.proprietorDetail)
                    ? this.props.proprietorDetail.total_trademarks_count
                    : 0}
                </Text>
              </Col>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={
                this.props.proprietorDetailStatus === LOADING ? true : false
              }
            >
              <Col span={4}>
                <TrademarkCircleTwoTone style={{ fontSize: "35px" }} />
              </Col>
              <Col span={20}>
                <Title level={4} style={{ fontSize: "14px" }}>
                  REGISTERED MARKS
                </Title>
                <Text strong style={{ fontSize: "14px" }}>
                  {checkValue(this.props.proprietorDetail)
                    ? this.props.proprietorDetail.registered_trademarks_count
                    : 0}
                </Text>
              </Col>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={
                this.props.proprietorDetailStatus === LOADING ? true : false
              }
            >
              <Col span={4}>
                <UpSquareTwoTone style={{ fontSize: "35px" }} />
              </Col>
              <Col span={20}>
                <Title level={4} style={{ fontSize: "14px" }}>
                  MOST ACTIVE CLASS
                </Title>
                <Text strong style={{ fontSize: "14px" }}>
                  {checkValue(this.props.proprietorDetail)
                    ? this.props.proprietorDetail.most_active_class[0]
                    : ""}
                </Text>
              </Col>
            </Card>
          </Col>
        </Row>
        {/* card-section */}

        {/* proprietor-information */}
        <Row>
          <Col span={24} className="m-top-20">
            <Card
              title="PROPRIETOR INFORMATION"
              headStyle={{ background: "#e8e8e8" }}
              loading={this.props.proprietorStatus === LOADING ? true : false}
            >
              <div>
                <table className="inventor-profile-table">
                  {this.props.proprietor && (
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>
                          {checkValue(this.props.proprietor.proprietor.name)
                            ? this.props.proprietor.proprietor.name
                            : "N.A."}
                        </td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td>
                          {checkValue(this.props.proprietor.proprietor.address)
                            ? this.props.proprietor.proprietor.address
                            : "N.A."}
                        </td>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td>
                          {checkValue(this.props.proprietor.proprietor.type)
                            ? this.props.proprietor.proprietor.type
                            : "N.A."}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </Card>
          </Col>
        </Row>
        {/* proprietor-information */}

        {/* proprietor-details */}
        <Row>
          <Col span={24} className="m-top-20">
            <Tabs onChange={this.callback} type="card">
              <TabPane tab="PROPRIETOR ANALYSIS" key="1">
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Card
                      hoverable
                      bodyStyle={{ textAlign: "center", padding: "15px" }}
                      loading={
                        this.props.proprietorDetailStatus === LOADING
                          ? true
                          : false
                      }
                    >
                      <h5>Status Distribution</h5>
                      <div className="recharts-parent-div-custom-style">
                        <ResponsiveContainer>
                          <PieChart>
                            <Pie
                              data={this?.state?.StatusSeries ?? []}
                              label={(props) =>
                                renderPieCustomizedLabel(props, chartIdStatus)
                              }
                              labelLine={false}
                              fill="#8884d8"
                              dataKey="value"
                              isAnimationActive={false}
                              cx="50%"
                              cy="50%"
                            >
                              {this.state.StatusSeries.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={colors[index % 20]}
                                />
                              ))}
                            </Pie>
                            <Tooltip
                              formatter={(value) => value}
                              content={(props) =>
                                renderPieCustomTooltip(
                                  props,
                                  this?.state?.StatusSeries ?? [],
                                )
                              }
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </Card>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Card
                      hoverable
                      bodyStyle={{ textAlign: "center", padding: "15px" }}
                      loading={
                        this.props.proprietorDetailStatus === LOADING
                          ? true
                          : false
                      }
                    >
                      <h5>Classes</h5>
                      <p>Class-wise Distribution</p>
                      <div className="recharts-parent-div-custom-style">
                        <ResponsiveContainer>
                          <BarChart
                            data={this.state.ClassSeries}
                            margin={{ bottom: 30 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="name"
                              label={{
                                value: "Classes",
                                offset: -20,
                                position: "insideBottom",
                              }}
                            />
                            <YAxis
                              label={{
                                value: "Trademarks",
                                angle: -90,
                                position: "insideLeft",
                              }}
                            />
                            <Bar
                              dataKey="uv"
                              fill="#8884d8"
                              // label={{ position: "top" }}
                            >
                              {this.state.ClassSeries.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={colors[index % 20]}
                                />
                              ))}
                            </Bar>
                            <Tooltip
                              cursor={{ fill: "transparent" }}
                              content={renderBarCustomTooltip}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="PROPRIETOR MARK" key="2">
                <Row>
                  <Table
                    rowKey={(record) => record.id}
                    bordered
                    dataSource={
                      this.props.proprietorDetail &&
                      this.props.proprietorDetail.data
                    }
                    columns={ProprietorMarkColumns}
                    pagination={false}
                    scroll={{ y: "calc(70vh - 200px)" }}
                    loading={
                      this.props.proprietorDetailStatus === LOADING
                        ? true
                        : false
                    }
                  />
                  ;
                </Row>
              </TabPane>
              <TabPane tab="SIMILAR PROPRIETOR" key="3">
                <Row>
                  <Card
                    hoverable
                    bodyStyle={{ textAlign: "left", padding: "15px" }}
                    loading={
                      this.props.proprietorStatus === LOADING ? true : false
                    }
                  >
                    <Col span={24}>
                      {this.props.proprietor &&
                        this.props.proprietor.similar.length > 0 &&
                        this.props.proprietor.similar.map((similarProp) => (
                          <div key={similarProp.id}>
                            <Title level={4}>
                              {checkValue(similarProp.name)
                                ? similarProp.name
                                : "N.A."}
                            </Title>
                            <Text strong>
                              {checkValue(similarProp.address)
                                ? similarProp.address
                                : "N.A."}
                            </Text>
                            <Divider />
                          </div>
                        ))}
                      {this.props.proprietor &&
                        this.props.proprietor.similar.length === 0 && (
                          <div className="alignC">
                            <Title level={4}>
                              No Similar Proprietor Found{" "}
                            </Title>
                          </div>
                        )}
                    </Col>
                  </Card>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        {/* proprietor-details */}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getProprietor,
  getProprietorDetail,
  logOut,
};

function mapStateToProps(state) {
  const {
    proprietor,
    proprietorStatus,
    proprietorDetail,
    proprietorDetailStatus,
  } = state.managerState.trademarkDashboardState;
  return deepFreeze({
    proprietor,
    proprietorStatus,
    proprietorDetail,
    proprietorDetailStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ProprietorProfile);
