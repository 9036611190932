//--------TYPES--------
import {
  GET_EMAIL_PREFERENCE,
  SAVE_EMAIL_PREFERENCE,
} from "../actions/commonActions/types";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../constants/commonConstants/generalConstants";

export default function (
  state = {
    emailPreferences: {},
    getEmailPreferencesStatus: null,
    saveEmailPreferencesStatus: null,
  },
  action
) {
  switch (action.type) {
    case GET_EMAIL_PREFERENCE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            getEmailPreferencesStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            emailPreferences: action.payload,
            getEmailPreferencesStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            getEmailPreferencesStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case SAVE_EMAIL_PREFERENCE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            saveEmailPreferencesStatus: LOADING,
          };
        case SUCCESS: {
          const updatedPreference =
            action?.data?.email_preferences && action?.data?.product
              ? state?.emailPreferences[`${action.data.product}`].map(
                  (emailPref) => {
                    const foundObject = action.data?.email_preferences?.filter(
                      (obj) => obj.id === emailPref?.id
                    );
                    if (foundObject?.length > 0) {
                      return {
                        ...emailPref,
                        mail_permitted: foundObject[0]?.mail_permitted,
                      };
                    }
                    return emailPref;
                  }
                )
              : state?.emailPreferences[`${action.data.product}`];
          return {
            ...state,
            saveEmailPreferencesStatus: SUCCESS,
            emailPreferences: {
              ...state.emailPreferences,
              [`${action.data.product}`]: updatedPreference,
            },
          };
        }
        case ERROR:
          return {
            ...state,
            saveEmailPreferencesStatus: ERROR,
          };
        default:
          break;
      }
      break;

    default:
      return state;
  }
}
