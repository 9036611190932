//--------LIBRARIES--------
import Axios from "axios";
import { notification } from "antd";

//--------RT_CONFIG--------
import { SRCH_RT_URL } from "../../../configs/rootConfigs";

//--------GRID LAYOUT TYPES--------
import { 
    PS_GET_SEARCH_RESULTS, 
    PS_GET_PROPRIETOR_MARKS,
    PS_GET_PROPRIETOR_DATA, 
    PS_GET_PROPRIETOR_ANALYSIS
} from './types';

//--------COMMON IP-SUITE CONSTANTS--------
import { LOADING, ERROR, SUCCESS } from '../../../constants/commonConstants/generalConstants';

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from '../commonActions/actions';

//--------Get proprietors search--------
export function getProprietorsSearch(searchParams){
    return async (dispatch) => {
        dispatch({type: PS_GET_SEARCH_RESULTS, status: LOADING});
        let response;
        try {
            response = await Axios.get(
                `${SRCH_RT_URL}/proprietors/`,
                {
                    params: searchParams,
                    headers: {Authorization:'Token '+ localStorage.getItem('token')}
                }
            );

            switch (response.status) {
                case 200:
                    dispatch({type: PS_GET_SEARCH_RESULTS, status: SUCCESS, payload: response?.data, searchParams})
                    break;

                default:
                    notification['error']({
                        message: 'Unexpected Response! Proprietors Search',
                        description: 'We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.',
                    });
                    dispatch({type: PS_GET_SEARCH_RESULTS, status: ERROR});
                    break;
            }
        } catch (err) {
            checkAuthorisation(dispatch, err);
            notification['error']({
                message: 'Error! Proprietors Search',
                description: 'We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.',
            });
            dispatch({type: PS_GET_SEARCH_RESULTS, status: ERROR});
        }
    };
}

//--------Get proprietors Profile detail--------
export function getProprietorProfileDetail({id, operation, filterFlag, filters, sortFlag, sorting, pageNumber}){
    const filter_class = `${filters?.filterClasses}`;
    const filter_state = `${filters?.filterState}`;
    const filter_trademark_type = `${filters?.filterType}`;
    const filter_trademark_status = `${filters?.filterStatus}`;
    const filter_proprietor = `${filters?.filterProprietor}`;
    const filter_application_date = `${filters?.date_of_application}`;
    const filter_date_of_usage = `${filters?.date_of_usage}`;
    const filter_date_of_usage_proposed = `${filters?.date_of_usage_proposed}`;
    const filter_contains_term = `${filters?.containsTerm}`;
    const sort_type = `${sorting?.sortType}`;
    const sort_on = `${sorting?.sortOn}`;
    return async (dispatch) => {
        switch (operation) {
            case 'MARKS':
                dispatch({
                    type: PS_GET_PROPRIETOR_MARKS, 
                    status: LOADING,
                });
                try {
                    let response;
                    response = await Axios.get(`${SRCH_RT_URL}/proprietor/${id}`,
                        {
                            params: {
                                operation: operation,
                                filter_flag: filterFlag ? true : null,
                                filter_state: filterFlag ? filter_state : null,
                                filter_proprietor: filterFlag ? filter_proprietor : null,
                                filter_class: filterFlag ? filter_class : null,
                                filter_trademark_type: filterFlag ? filter_trademark_type : null,
                                filter_trademark_status: filterFlag ? filter_trademark_status : null,
                                filter_application_date: filterFlag ? filter_application_date : null,
                                filter_date_of_usage: filterFlag ? filter_date_of_usage : null,
                                filter_date_of_usage_proposed: filterFlag ? filter_date_of_usage_proposed : null,
                                filter_contains_term: filterFlag ? filter_contains_term : null,
                                sort_flag: sortFlag ? true : null,
                                sort_type: sortFlag ? sort_type : null,
                                sort_on: sortFlag ? sort_on : null,
                                page: pageNumber
                            },
                            headers: {Authorization:'Token '+ localStorage.getItem('token')}
                        }
                    );
                    dispatch({
                        type: PS_GET_PROPRIETOR_MARKS,
                        status: SUCCESS,
                        payload: response?.data,
                        operation,
                        lastAppliedFilters: {
                            ...filters,
                            filterFlag
                        },
                        lastAppliedSorting: {
                            ...sorting,
                            sortFlag
                        },
                        pageNumber,
                    });
                    return {
                        status: SUCCESS,
                        filteredMarkCount: response?.data.count
                    };
                } catch (err) {
                    checkAuthorisation(dispatch, err);
                    notification['error']({
                        message: 'Error! Proprietor Trademarks',
                        description: 'We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.',
                    });
                    dispatch({
                        type: PS_GET_PROPRIETOR_MARKS,
                        status: ERROR,
                    });
                }
                break;

            case 'MARKS_ANALYSIS':
                dispatch({
                    type: PS_GET_PROPRIETOR_ANALYSIS, 
                    status: LOADING,
                });
                try {
                    let response;
                    response = await Axios.get(`${SRCH_RT_URL}/proprietor/${id}`,
                        {
                            params: {
                                operation: operation,
                                page: pageNumber
                            },
                            headers: {Authorization:'Token '+ localStorage.getItem('token')}
                        }
                    );
                    dispatch({
                        type: PS_GET_PROPRIETOR_ANALYSIS,
                        status: SUCCESS,
                        payload: response?.data,
                        operation,
                        pageNumber,
                    });
                } catch (err) {
                    checkAuthorisation(dispatch, err);
                    notification['error']({
                        message: 'Error! Proprietor Analysis',
                        description: 'We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.',
                    });
                    dispatch({
                        type: PS_GET_PROPRIETOR_ANALYSIS, 
                        status: ERROR,
                    });
                }
                break;
        
            case 'DATA':
                dispatch({
                    type: PS_GET_PROPRIETOR_DATA, 
                    status: LOADING,
                });
                try {
                    let response;
                    response = await Axios.get(`${SRCH_RT_URL}/proprietor/${id}`,
                        {
                            params: {
                                operation: operation,
                                page: pageNumber
                            },
                            headers: {Authorization:'Token '+ localStorage.getItem('token')}
                        }
                    );
                    dispatch({
                        type: PS_GET_PROPRIETOR_DATA,
                        status: SUCCESS,
                        payload: {
                            proprietor: response?.data.proprietor,
                            similarProprietor: response?.data.similar_proprietors
                        },
                        operation,
                        pageNumber,
                    });
                } catch (err) {
                    checkAuthorisation(dispatch, err);
                    notification['error']({
                        message: 'Error! Proprietor Data',
                        description: 'We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.',
                    });
                    dispatch({
                        type: PS_GET_PROPRIETOR_DATA, 
                        status: ERROR,
                    });
                }
                break;
        
            default:
                break;
        }
    };
}