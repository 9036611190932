import img1 from '../../../assets/watchfaqimages/3.2.gif'
import img2 from '../../../assets/watchfaqimages/3.3.gif'
import img3 from '../../../assets/watchfaqimages/3.4.gif'
import img4 from '../../../assets/watchfaqimages/3.5.gif'
import img5 from '../../../assets/watchfaqimages/3.6.gif'
import img6 from '../../../assets/watchfaqimages/3.7.gif'
import img7 from '../../../assets/watchfaqimages/3.8.gif'
import img8 from '../../../assets/watchfaqimages/3.9.gif'
import img9 from '../../../assets/watchfaqimages/3.10.gif'
import img10 from '../../../assets/watchfaqimages/3.11.gif'
import img11 from '../../../assets/watchfaqimages/3.12.gif'
import img12 from '../../../assets/watchfaqimages/3.13.gif'
import img13 from '../../../assets/watchfaqimages/3.14.gif'


const WatchData={

    FeaturesGuide:[
        {
            question:'Options in types of Mike TM Watch ',
            answers:[
                {answer:'1.Single Class/Multiple Class/All 45 Class Watch'},
                {answer:'2.Ability to customise the type of infringements to be seen'},
                {answer:'3.Automated report generation within 36 hours of publication of journal'},
                {answer:'4.Ability to access entire journal with the ability to filter'}, 
                {answer:'5.Ability to create report'},
                {answer:'6.Ability to download the report in PDF and Excel'},
                {answer:'7.Ability to see infringements based on images (Mike TM Lens) *'},
                {answer:'8.Ability to perform text-based search in images (Mikel TM Lens) *'},
                 
            ]

        },

        {
            question:'Guide to MikeTM Watch',
            answers:[
                {answer:'MikeTM Watch is a tool that allows you to scan the weekly trademark journal and easily find marks that infringes your brand. MikeTM Watch has 3 modules, each with a different ability. Here is a quick overview of each.'}, 
                {answer:'TM Applied – Simply add the trademark application number and you will get infringements based on the “Focus Words” in the “Focus Classes”. '}, 
                {answer:'MikeTM Protect – Using MikeTM Protect you can identify similar/deceptively similar applications to your mark within 7 days of filing of those applications. This allows you to take appropriate action even before infringing applications gets published. '},
                {answer:'TM Lens – Using TM Lens, you can get infringements on the basis of the text written on the logo and you can even get marks with similar image label to yours.'},
            ]

        },
    ],
    Question:[
        {
            heading:'FAQs – TM Applied',
            question:'1. How to add a trademark in TM Applied?',
            img:img1,
            answers:[
                {answer:'-Go to the “Manage trademarks” and click on “Add trademark” and type the application number(s).'}
            ]

        },
        {
            question:'2. How to delete a trademark from TM Applied?',
            img:img2,
            answers:[
                {answer:'-	Go to the “Manage trademark” and select the checkbox against the application number which you want to delete. Now select the “Delete trademark” button to delete them from your profile.'}, 
           ]

        },
        {
            question:'3. How to edit focus word of a trademark?',
            img:img3,
            answers:[
                {answer:'-Go to the “Manage trademark” page and click on “edit” button against the desired trademark and you can add/delete focus words.'}, 
            ]
           

        },
        {
            question:'4. How to edit focus Class of a trademark?',
            img:img4,
            answers:[
                {answer:'- Go to the “Manage trademark” page and click on “edit” button against the desired trademarks and you can edit the focus classes.'},
           ]

        },

        {
            question:'5. What is the difference between List layout & Grid Layout in TM Applied?',
            img:img5,
            answers:[
                {answer:"-	List layout shows all the infringements found for your applications with respect to the similarity  whereas in Grid layout you get to see all the infringements against each and every trademark of yours which Is uploaded in the tool."},
           ]

        },
        {
            question:'6. How to view analysis of another Journal?',
            img:img6,
            answers:[
                {answer:'-	Click on the top right corner to open the dropdown for the journals & select the desired journal against which you want to see the infringements of your portfolio.'},
            ]
        },
        {
            question:'7. How to access manual Journals?',
            img:img7,
            answers:[
                {answer:'-	Click on “Journals” page to access the full journal for free.'},
               
            ]
        },
        {
            question:'8. How to create reports?',
            img:img8,
            answers:[
                {answer:'-	Click on the desired trademarks of which you want to create a report of, and click on the “create report” button to create a custom report.'},
            ]
        },
        {
            question:'9. What is an Email Template?',
            img:img9,
            answers:[
                {answer:'Email Template is a regular email body filled with variables in terms of Trademark’s information, which can help you save time and send customized mails with respect to the selected trademarks or proprietors.'},
                {answer:'For Example – Let’s say your email body says,'},
                {answer:'“Dear Sir,'},
                {answer:'Your trademark XYZ is currently at the status – Abandoned.”'},
                {answer:'Now to send this exact same mail to multiple addresses, you can simply convert this body into a template and automate mails, let’s have a look how –'},
                {answer:'“Dear @proprietor_name Your trademark @mark_name is currently at the status - @status.” '}
                
            ]
        },
        {
            question:'10. How to access Weekly reports?',
            img:img10,
            answers:[
                {answer:'- Click on the “report” page and select the “Automated reports” header, and can download the required journal analysis.'},
            ]
        },
        {
            question:'11. What is the difference between All Class & Same Class reports?',
            img:img11,
            answers:[
                {answer:'- All Class Report contains all the infringements based on the focus words for all the focus classes of all the trademarks contained in the profile, whereas the Same Class report contains only the infringements which are respectively in the same classes of your trademarks.'},
            ]
        },
        {
            heading:'FAQs – TM Lens',
            question:'1. What is Image text?',
            img:img12,
            answers:[
                {answer:'-If you want to see the infringements based on texts of your trademark, making sure that text is not present in any image of any trademark published in that particular journal.'},
            ]
        },
        {
            question:'2. What is Image Label?',
            img:img13,
            answers:[
                {answer:'-We find the components present inside your image and make sure that any similar components present in an other mark in the released journal is shown to you.'},
            ]
        },
        
    ]
};
export default WatchData;
