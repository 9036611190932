import React, { Component } from "react";
import { connect } from "react-redux";
import { FixedSizeList } from "react-window";
import deepFreeze from "deep-freeze";
import _ from "lodash";

import {
  CarryOutTwoTone,
  DownOutlined,
  MessageTwoTone,
  TagTwoTone,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Button,
  Input,
  message,
  Typography,
  Checkbox,
  Tooltip,
  Switch,
  notification,
  Modal,
} from "antd";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import { getDashboard } from "../../../../redux/actions/managerActions/profile/actions";
import { getReplyToCorrespondence } from "../../../../redux/actions/managerActions/trademarkDashboard/actions";

//--------HISTORY--------
import { history } from "../../../../history";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import {
  changeStateValue_L0,
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../../../functions/commonFunctions/generalFunctions";
import {
  TM_DefaultSelectedFilters,
  TM_DefaultSearchFilters,
} from "../../../../constants/managerConstants/trademarkConstants";
import DateRangePicker from "../../../../functions/commonFunctions/dateRangePicker";

//--------MODALS--------
import CreateTask from "../../to-do-task/CreateTask";
import DownloadReportModal from "../../report/DownloadReportModal";
import ReplyFiledModal from "../ReplyFiled";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { TM } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_TM_NOTES,
  PATCH_TM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import { REPLY_FILED_TO_CORRESPONDENCE } from "../../../../redux/actions/managerActions/trademarkDashboard/types";
import { getEmailTemplateData } from "../../../../redux/actions/commonActions/actions";

const { Text } = Typography;
const { Search } = Input;

class ReplyToCorrespondence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      management_trademark_id: "",
      addTaskVisible: false,
      changeSwitch: false,
      tag_color: "",
      range: null,

      add_trademark_id: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectAll: false,
      checkAll: false,
      noTagToggle: false,

      pseudo_add_trademark_id: [],
      pseudo_selectedRowKeys: [],
      pseudo_selectAll: false,
      pseudo_checkAll: false,

      selectedFilters: {
        ip_india: checkValue(this.props.replyToCorrespondenceAppliedFilters)
          ? this.props.replyToCorrespondenceAppliedFilters
          : TM_DefaultSelectedFilters,
        pseudo: checkValue(this.props.pseudoReplyToCorrespondenceAppliedFilters)
          ? this.props.pseudoReplyToCorrespondenceAppliedFilters
          : TM_DefaultSelectedFilters,
      },

      searchFilters: {
        ip_india: TM_DefaultSearchFilters,
        pseudo: TM_DefaultSearchFilters,
      },
      filterDocDOD: "doc_name",
      filterRemainingDate: "remaining_days",
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.handleNoTag = this.handleNoTag.bind(this);
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.setDate = this.setDate.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.tmDashboard === null) {
        this.props.getDashboard("trademark", "trademark");
      }

      if (this.props.replyToCorrespondence === null) {
        this.props.getReplyToCorrespondence({
          mmType: "trademark",
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });
      }

      if (this.props.pseudoReplyToCorrespondence === null) {
        this.props.getReplyToCorrespondence({
          mmType: "trademark",
          pseudo: true,
          filterFlag: false,
          filters: TM_DefaultSelectedFilters,
          pageNumber: 1,
        });
      }
    } else {
      this.props.logOut();
    }
  }

  handleTrademarkProfile(data) {
    if (data) {
      sessionStorage.setItem(
        "management_trademark_id",
        data.management_mark_id,
      );
      sessionStorage.setItem(
        "trademark_application_number",
        data.application_number,
      );
      window.open(
        "/manager/trademark/trademark-profile/" + data.management_mark_id,
        "_blank",
      );
      this.setState({
        management_trademark_id: data.management_mark_id,
      });
    }
  }
  //BackClickHandler
  backClickHandler() {
    // this.props.getReplyToCorrespondence({
    //     mmType: 'trademark',
    //     filterFlag: false,
    //     filters: TM_DefaultSelectedFilters,
    //     pageNumber: 1,
    // });
    window.history.back();
  }

  //Rerendering component after reply fiels complitation/ calling api again after filed reply
  reRender() {
    this.props.getReplyToCorrespondence({
      mmType: "trademark",
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });

    this.props.getReplyToCorrespondence({
      mmType: "trademark",
      pseudo: true,
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });
    this.setState({
      selectAll: false,
      pseudo_selectAll: false,
      pseudo_add_trademark_id: [],
      add_trademark_id: [],
      selectedRowKeys: [],
    });
  }

  handlePdfClick(data) {
    let url = "";
    if (data?.file) {
      url = data?.file;
    } else {
      url = data?.url;
    }
    if (url !== null) window.open(url, "_blank");
  }

  handlePdfClick1(data) {
    let url = "";
    if (data?.url.includes("http://")) {
      url = data?.url;
    } else {
      url = data?.url;
    }
    if (url !== null) window.open(url, "_blank");
  }

  replyFiled() {
    if (this.state.selectAll) {
      this.setState({ add_trademark_id: [] });
    } else if (this.state.pseudo_selectAll) {
      this.setState({ pseudo_add_trademark_id: [] });
    } else if (
      this.state.add_trademark_id.length <= 0 &&
      this.state.pseudo_add_trademark_id.length <= 0
    ) {
      message.error("no mark selected");
      return;
    }

    this.setState({
      replyFiledVisible: true,
      pending_reply: false,

      pseudo: this.state.changeSwitch,
    });
  }

  downloadReport() {
    const { changeSwitch, add_trademark_id, pseudo_add_trademark_id } =
      this.state;

    if (
      changeSwitch &&
      (pseudo_add_trademark_id.length > 0 || this.state.selectAll)
    ) {
      this.setState({
        visible: true,
      });
    } else if (add_trademark_id.length > 0 || this.state.selectAll) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  reasonShow(data) {
    Modal.info({
      title: "Reason for Notice",
      width: "50%",
      content: (
        <div>
          <p>{data}</p>
        </div>
      ),
      onOk() {},
    });
  }

  //   *********************************NO Tags ********************************//

  handleNoTag(e) {
    this.setState({ noTagToggle: e.target.checked });
    if (this.state.noTagToggle !== true) {
      this.setState({
        tag_color: (document.getElementById("tag-color").style.color =
          "#3DA7FF"),
      });
    }
  }

  setDate(val) {
    this.setState({ range: val });
  }

  resetFilter() {
    const { changeSwitch } = this.state;
    this.setState({ range: null });
    this.setState({ noTagToggle: !true });
    this.setState({
      tag_color: (document.getElementById("tag-color").style.color = "#595959"),
    });
    const {
      replyToCorrespondenceAppliedFilters,
      pseudoReplyToCorrespondenceAppliedFilters,
    } = this.props || {};
    const {
      trademarkClass,
      trademarkStatus,
      trademarkType,
      trademarkStates,
      trademarkProprietors,
      trademarkTags,
      trademarkOpponent,
      // evidenceFilter,
      date_of_application,
      containsTerm,
      trademarkAlerts,
      trademarkNOtags,
    } = changeSwitch
      ? pseudoReplyToCorrespondenceAppliedFilters
      : replyToCorrespondenceAppliedFilters || {};

    if (
      trademarkClass?.length > 0 ||
      trademarkStatus?.length > 0 ||
      trademarkType?.length > 0 ||
      trademarkStates?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      trademarkTags?.length > 0 ||
      trademarkOpponent?.length > 0 ||
      // evidenceFilter === true ||
      date_of_application?.length > 0 ||
      containsTerm?.length > 0 ||
      trademarkAlerts?.length > 0 ||
      trademarkNOtags?.length > 0
    ) {
      this.props.getReplyToCorrespondence({
        mmType: "trademark",
        pseudo: changeSwitch,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
      });
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [changeSwitch ? "pseudo" : "ip_india"]: {
            ...this.state.selectedFilters[changeSwitch ? "pseudo" : "ip_india"],
            ...TM_DefaultSelectedFilters,
            filterFlag: false,
            pageNumber: 1,
          },
        },
      });
    } else {
      message.error("No Filter Applied");
    }
  }

  applyFilter() {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.state.selectedFilters.pseudo
      : this.state.selectedFilters.ip_india;
    const {
      trademarkClass,
      trademarkStatus,
      trademarkType,
      trademarkStates,
      trademarkProprietors,
      trademarkTags,
      trademarkOpponent,
      // evidenceFilter,
      date_of_application,
      containsTerm,
      trademarkAlerts,
      trademarkNOtags,
    } = selectedFilters;

    if (
      trademarkClass?.length > 0 ||
      trademarkStatus?.length > 0 ||
      trademarkType?.length > 0 ||
      trademarkStates?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      trademarkTags?.length > 0 ||
      trademarkOpponent?.length > 0 ||
      // evidenceFilter === true ||
      date_of_application?.length > 0 ||
      containsTerm?.length > 0 ||
      trademarkAlerts?.length > 0 ||
      trademarkNOtags?.length > 0
    ) {
      this.props.getReplyToCorrespondence({
        mmType: "trademark",
        pseudo: changeSwitch,
        filterFlag: true,
        filters: selectedFilters,
        pageNumber: 1,
      });
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [changeSwitch ? "pseudo" : "ip_india"]: {
            ...this.state.selectedFilters[changeSwitch ? "pseudo" : "ip_india"],
            ...selectedFilters,
            filterFlag: true,
            pageNumber: 1,
          },
        },
      });
    } else {
      message.error("please select filter!");
    }
  }

  handleNextpage = (pageNumber) => {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.props.pseudoReplyToCorrespondenceAppliedFilters
      : this.props.replyToCorrespondenceAppliedFilters;
    this.props.getReplyToCorrespondence({
      mmType: "trademark",
      pseudo: changeSwitch,
      filterFlag: selectedFilters?.filterFlag,
      filters: selectedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [changeSwitch ? "pseudo" : "ip_india"]: {
          ...this.state.selectedFilters[changeSwitch ? "pseudo" : "ip_india"],
          pageNumber: pageNumber,
        },
      },
    });
  };

  changeSwitch() {
    this.setState({
      changeSwitch: !this.state.changeSwitch,
    });
  }

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  async handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [
          this.state.changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK",
        ],
        report_tag: {
          journal_number: checkValue(data.journal) ? data.journal.number : "",
          journal_date: checkValue(data.journal)
            ? data.journal.date_of_publication
            : "",
          status: checkValue(data.status) ? data.status : "",
          renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
          application_number: checkValue(data.application_number)
            ? data.application_number
            : "",
          mark_name: checkValue(data.applied_for) ? data.applied_for : "",
          date_of_application: checkValue(data.application_date)
            ? data.application_date
            : "",
          class: checkValue(data.associated_class)
            ? data.associated_class.join(", ")
            : "",
          date_of_usage: checkValue(data.date_of_usage)
            ? data.date_of_usage
            : "",
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          opponent_name: checkValue(data.opponent_name)
            ? data.opponent_name
            : "",
          tla_hearing_date: "",
          opposition_hearing_date: "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_trademark" : "trademark",
        applicationNumber: data.application_number,
        markId: data.management_mark_id,
        sourceId: data.management_mark_id,
      },
    });
    if (value) {
      await this.props.getEmailTemplateData({
        subscription: "TRADEMARK",
        sub_section: "DASHBOARD",
        product: "MANAGER",
        object_id: data?.application_number,
      });
    }
  }

  render() {
    const { changeSwitch } = this.state;
    const selectedFilters = changeSwitch
      ? this.state.selectedFilters.pseudo
      : this.state.selectedFilters.ip_india;
    const searchFilters = changeSwitch
      ? this.state.searchFilters.pseudo
      : this.state.searchFilters.ip_india;
    const trademarkFilters = changeSwitch
      ? this.props.pseudoReplyToCorrespondenceStatus
      : this.props.replyToCorrespondenceStatus;
    const currentPageNumber = changeSwitch
      ? this.props.pseudoReplyToCorrespondencePageNumber
      : this.props.replyToCorrespondencePageNumber;

    let switchValue;
    if (changeSwitch) {
      switchValue = "pseudo";
    } else {
      switchValue = "ip_india";
    }

    const totalReplyToCorrespondenceColumns = [
      {
        title: "TRADEMARK",
        className: "reply-to-correspondence-column-1",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handleTrademarkProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.applied_for)
                    ? record.applied_for.toLowerCase()
                    : "N.A."}
                </span>
              </p>
              <p>
                <img
                  className="trademark-image"
                  src={
                    checkValue(record.associated_image)
                      ? record.associated_image
                      : ""
                  }
                  alt=""
                />
              </p>
            </div>
          );
        },
      },

      {
        title: "STATUS | APPLICATION DATE | CLASS",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              <p className="text-camelCase">
                {checkValue(record.status)
                  ? record.status.toLowerCase()
                  : "N.A."}
              </p>
              <p className="text-camelCase">
                {checkValue(record.application_date)
                  ? record.application_date
                  : "N.A."}
              </p>
              <p className="text-camelCase">
                {checkValue(record.associated_class)
                  ? record.associated_class.join(", ")
                  : "N.A."}
              </p>
            </div>
          );
        },
      },

      {
        title: "OPPONENT NAME | OPPONENT NUMBER",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              <span className="text-camelCase">
                {checkValue(record.opponent_name)
                  ? record.opponent_name.toLowerCase()
                  : "N.A."}
              </span>
              {record.opponent_number && (
                <p>
                  (
                  {checkValue(record.opponent_number)
                    ? record.opponent_number
                    : "N.A."}
                  )
                </p>
              )}
            </div>
          );
        },
      },

      {
        title: "DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.documents &&
                record.documents.length > 0 &&
                record.documents.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                    <p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
                    <div>
                      <span>{doc.remaining_days} Days</span>
                      <span className={doc.alert ? "visible" : "not-visible"}>
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {record.correspondence_notices &&
                record.correspondence_notices.length > 0 &&
                record.correspondence_notices.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <div>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc.subject}
                      </span>
                      <p>
                        (
                        {checkValue(doc.date_of_correspondence)
                          ? doc.date_of_correspondence
                          : "N.A."}
                        )
                      </p>
                      <div>
                        <span>{doc.remaining_days} Days</span>
                        <span className={doc.alert ? "visible" : "not-visible"}>
                          <Tooltip
                            title="Deadline to file reply for this mark has passed"
                            className="toolTip-custom"
                          >
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                              style={{
                                color: "#e1001b",
                                paddingLeft: "5px",
                                cursor: "pointer",
                              }}
                            ></i>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </p>
                ))}
              {record.examination_report_documents &&
                record.examination_report_documents.length > 0 &&
                record.examination_report_documents.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <span
                      onClick={
                        checkValue(doc.url)
                          ? () => this.handlePdfClick1(doc)
                          : ""
                      }
                      style={
                        checkValue(doc.url)
                          ? { cursor: "pointer", color: "#4285f4" }
                          : {}
                      }
                    >
                      EXAMINATION REPORT
                    </span>
                    <p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
                    <div>
                      <span>{doc.remaining_days} Days</span>
                      <span className={doc.alert ? "visible" : "not-visible"}>
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {record.documents.length === 0 &&
                record.correspondence_notices.length === 0 &&
                record.examination_report_documents.length === 0 && (
                  <p className="h_80px vertical_scroll">N.A.</p>
                )}
            </div>
          );
        },
      },

      {
        title: "DISPATCH DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.correspondence_notices &&
                record.correspondence_notices.length > 0 &&
                record.correspondence_notices.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <div>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc.subject}
                      </span>
                      <p>
                        (
                        {checkValue(doc.date_of_dispatch)
                          ? doc.date_of_dispatch
                          : "N.A."}
                        )
                      </p>
                    </div>
                    <div>
                      <span>{doc.remaining_days_wrt_dod} Days</span>
                      <span
                        className={
                          doc.alert_wrt_dod ? "visible" : "not-visible"
                        }
                      >
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {record.correspondence_notices.length === 0 && (
                <p className="h_80px vertical_scroll">N.A.</p>
              )}
            </div>
          );
        },
      },

      {
        title: "TAGS",
        dataIndex: "tags",
        className: "reply-to-correspondence-column-1",
        render: (tags) => {
          return (
            <div>
              {tags.length > 0
                ? tags.map((tag) => (
                    <span
                      key={tag.id}
                      className="Tag"
                      style={{
                        color: `${tag.color_code}`,
                        border: `1px solid ${tag.color_code}`,
                      }}
                    >
                      {tag.name.toUpperCase()}
                    </span>
                  ))
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "ALERTS",
        dataIndex: "alert",
        className: "trademark-column",
        render: (text) => (
          <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
        ),
      },

      {
        title: "ACTION",
        className: "reply-to-correspondence-column-1",
        render: (action) => {
          return (
            <div
              className="iconTable"
              style={{
                minHeight: "24rem",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              {/* <p className="application-text alignC" onClick={() => this.replyFiled(action)}>Reply Filed</p> */}
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const totalReplyToCorrespondenceRowSelection = {
      // onChange: (selectedRowKeys, selectedRows) => {
      //     this.setState({
      //         selectedRows: _.uniq([...selectedRows, ...this.state.selectedRows]),
      //         add_trademark_id:  _.uniq([...selectedRows.map(data=> data.id), ...this.state.add_trademark_id]),
      //         selectedRowKeys: selectedRowKeys
      //     })
      // },

      onSelectAll: (selected, selectedRows) => {
        let data =
          this.props.replyToCorrespondence &&
          this.props.replyToCorrespondence.ip_india &&
          this.props.replyToCorrespondence.ip_india.trademarks;
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      // onSelect: (record, selected, selectedRows ) => {
      //     this.setState({
      //         selectRow: selected,
      //         selectAll: selectedRows.length === 100 ? true : false,
      //     })
      // },
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: [],
                  selectAll: !this.state.selectAll,
                  add_trademark_id: [],
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    const pseudoReplyToCorrespondenceRowSelection = {
      // onChange: (selectedRowKeys, selectedRows) => {
      //     let select_trademark_id = []
      //     select_trademark_id = selectedRows.map(mark_id=> mark_id.id)
      //     this.setState({
      //         pseudo_selectedRows: selectedRows,
      //         pseudo_add_trademark_id: select_trademark_id,
      //         pseudo_selectedRowKeys: selectedRowKeys
      //     })
      // },

      onSelectAll: (selected, selectedRows) => {
        let data =
          this.props.pseudoReplyToCorrespondence &&
          this.props.pseudoReplyToCorrespondence.pseudo &&
          this.props.pseudoReplyToCorrespondence.pseudo.trademarks;

        this.setState({
          pseudo_selectAll: selected,
          pseudo_selectedRows: selectedRows,
          pseudo_selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      // onSelect: (record, selected, selectedRows ) => {
      //     this.setState({
      //         pseudo_selectRow: selected,
      //         // pseudo_selectAll: selectedRows.length === 100 ? true : false,
      //     })
      // },

      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            pseudo_add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            pseudo_add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },
      hideDefaultSelections: true,
      selections: this.state.pseudo_selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  pseudo_selectedRowKeys: changeableRowKeys,
                  pseudo_selectAll: false,
                  pseudo_add_trademark_id: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.pseudo_selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.pseudo_selectAll,
      }),
    };

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let classesMenu = noFilterData;
    let statusMenu = noFilterData;
    let stateMenu = noFilterData;
    let typeMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    let tagsMenu = noFilterData;
    let opponentMenu = noFilterData;
    let reportMenu = noFilterData;
    let alertMenu = noFilterData;

    if (trademarkFilters === SUCCESS) {
      const {
        classes,
        status,
        states,
        type,
        proprietors,
        tags,
        opponent,
        report_columns,
        alerts,
      } = changeSwitch
        ? this.props.pseudoReplyToCorrespondence.pseudo.filters
        : this.props.replyToCorrespondence.ip_india.filters;

      if (classes !== null && classes.length > 0) {
        let filteredClass = classes.filter((eachclass) =>
          checkValue(`${eachclass}`)
            ? `${eachclass}`.includes(`${searchFilters.classSearch}`)
            : "",
        );
        classesMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search Class"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchFilters",
                    switchValue,
                    "classSearch",
                    event.target.value,
                  )
                }
                value={searchFilters.classSearch}
                allowClear
              />
            </div>
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredClass.length}
              itemSize={35}
              itemData={filteredClass}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters.trademarkClass}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkClass",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkClass",
                        event.selectedKeys,
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={selectedFilters.trademarkClass.includes(
                          `${data[index]}`,
                        )}
                      >
                        <Text style={{ fontWeight: "400" }}>{data[index]}</Text>
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (status !== null && status.length > 0) {
        let filteredStatus = status.filter((eachStatus) =>
          checkValue(eachStatus)
            ? eachStatus.label
                .toLowerCase()
                .includes(searchFilters.statusSearch)
            : "",
        );
        statusMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search Status"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchFilters",
                    switchValue,
                    "statusSearch",
                    event.target.value.toLowerCase(),
                  )
                }
                value={searchFilters.statusSearch}
                allowClear
              />
            </div>
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredStatus.length}
              itemSize={35}
              itemData={filteredStatus}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters.trademarkStatus}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkStatus",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkStatus",
                        event.selectedKeys,
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index].label}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={selectedFilters.trademarkStatus.includes(
                          data[index].label,
                        )}
                      >
                        <Text
                          className="text-camelCase"
                          style={{ fontWeight: "400" }}
                        >
                          {data[index].label.toLowerCase()}
                        </Text>
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (type !== null && type.length > 0) {
        let filteredType = type.filter((eachType) =>
          checkValue(eachType)
            ? eachType.toLowerCase().includes(searchFilters.typeSearch)
            : "",
        );
        typeMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search Type"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchFilters",
                    switchValue,
                    "typeSearch",
                    event.target.value.toLowerCase(),
                  )
                }
                value={searchFilters.typeSearch}
                allowClear
              />
            </div>
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredType.length}
              itemSize={35}
              itemData={filteredType}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters.trademarkType}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkType",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkType",
                        event.selectedKeys,
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={selectedFilters.trademarkType.includes(
                          data[index],
                        )}
                      >
                        <Text
                          className="text-camelCase"
                          style={{ fontWeight: "400" }}
                        >
                          {data[index].toLowerCase()}
                        </Text>
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (states !== null && states.length > 0) {
        let filteredState = states.filter((eachState) =>
          checkValue(eachState)
            ? eachState.toLowerCase().includes(searchFilters.stateSearch)
            : "",
        );
        stateMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search States"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchFilters",
                    switchValue,
                    "stateSearch",
                    event.target.value.toLowerCase(),
                  )
                }
                value={searchFilters.stateSearch}
                allowClear
              />
            </div>
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredState.length}
              itemSize={35}
              itemData={filteredState}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters.trademarkStates}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkStates",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkStates",
                        event.selectedKeys,
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={selectedFilters.trademarkStates.includes(
                          data[index],
                        )}
                      >
                        <Text
                          className="text-camelCase"
                          style={{ fontWeight: "400" }}
                        >
                          {data[index].toLowerCase()}
                        </Text>
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (proprietors !== null && proprietors.length > 0) {
        let filteredProprietors = proprietors.filter((eachProprietor) =>
          checkValue(eachProprietor)
            ? eachProprietor.name
                .toLowerCase()
                .includes(searchFilters.proprietorSearch)
            : "",
        );
        proprietorsMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search Proprietors"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchFilters",
                    switchValue,
                    "proprietorSearch",
                    event.target.value.toLowerCase(),
                  )
                }
                value={searchFilters.proprietorSearch}
                allowClear
              />
            </div>
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredProprietors.length}
              itemSize={35}
              itemData={filteredProprietors}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters.trademarkProprietors}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkProprietors",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkProprietors",
                        event.selectedKeys,
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={selectedFilters.trademarkProprietors.includes(
                          `${data[index].id}`,
                        )}
                      >
                        <Text
                          className="text-camelCase"
                          style={{ fontWeight: "400" }}
                        >
                          {data[index].name.toLowerCase()}
                        </Text>
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (tags !== null && tags.length > 0) {
        let filteredTags = tags?.filter((eachTags) =>
          checkValue(eachTags)
            ? eachTags.name.toLowerCase().includes(searchFilters.tagsSearch)
            : "",
        );
        tagsMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search Tags"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchFilters",
                    switchValue,
                    "tagsSearch",
                    event.target.value.toLowerCase(),
                  )
                }
                value={searchFilters.tagsSearch}
                allowClear
              />
            </div>

            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredTags.length}
              itemSize={35}
              itemData={filteredTags}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters.trademarkTags}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkTags",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkTags",
                        event.selectedKeys,
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={selectedFilters.trademarkTags.includes(
                          `${data[index].id}`,
                        )}
                        disabled={this.state.noTagToggle}
                      >
                        <TagTwoTone
                          twoToneColor={`${data[index].color_code}`}
                        />
                        <Text style={{ fontWeight: "400" }}>
                          {data[index].name}
                        </Text>
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>

            <Menu
              selectable={true}
              multiple={true}
              selectedKeys={selectedFilters.trademarkNOtags}
              onSelect={(event) =>
                this.changeStateValue_L2(
                  "selectedFilters",
                  switchValue,
                  "trademarkNOtags",
                  event.selectedKeys,
                )
              }
              onDeselect={(event) =>
                this.changeStateValue_L2(
                  "selectedFilters",
                  switchValue,
                  "trademarkNOtags",
                  event.selectedKeys,
                )
              }
            >
              <Menu.Item
                disabled={
                  this.state?.selectedFilters?.trademarkTags?.length > 0
                }
                style={{
                  padding: "5px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                className="menu-items-list"
              >
                <Checkbox
                  disabled={
                    this.state?.selectedFilters?.trademarkTags?.length > 0
                  }
                  checked={this.state.noTagToggle}
                  onChange={this.handleNoTag}
                >
                  <Text style={{ fontWeight: "400" }}>No tag</Text>
                </Checkbox>
              </Menu.Item>
            </Menu>
          </>
        );
      }

      if (opponent !== null && opponent.length > 0) {
        let filteredOpponent = opponent.filter((eachOpponent) =>
          checkValue(eachOpponent.opponent_name)
            ? eachOpponent.opponent_name
                .toLowerCase()
                .includes(searchFilters.opponentSearch)
            : "",
        );
        opponentMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search Opponent"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchFilters",
                    switchValue,
                    "opponentSearch",
                    event.target.value.toLowerCase(),
                  )
                }
                value={searchFilters.opponentSearch}
                allowClear
              />
            </div>
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredOpponent.length}
              itemSize={35}
              itemData={filteredOpponent}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters.trademarkOpponent}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkOpponent",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkOpponent",
                        event.selectedKeys,
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={selectedFilters.trademarkOpponent.includes(
                          `${data[index].id}`,
                        )}
                      >
                        <Text
                          className="text-camelCase"
                          style={{ fontWeight: "400" }}
                        >
                          {data[index].opponent_name.toLowerCase()}
                        </Text>
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (report_columns !== null && report_columns.length > 0) {
        let filteredReport = report_columns.filter((eachReport) =>
          checkValue(eachReport)
            ? eachReport.toLowerCase().includes(searchFilters.reportSearch)
            : "",
        );
        reportMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search Report Column"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchFilters",
                    switchValue,
                    "reportSearch",
                    event.target.value.toLowerCase(),
                  )
                }
                value={searchFilters.reportSearch}
                allowClear
              />
            </div>
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredReport.length}
              itemSize={35}
              itemData={filteredReport}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters.reportColumn}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "reportColumn",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "reportColumn",
                        event.selectedKeys,
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={selectedFilters.reportColumn.includes(
                          data[index],
                        )}
                      >
                        <Text
                          className="text-camelCase"
                          style={{ fontWeight: "400" }}
                        >
                          {data[index].toLowerCase()}
                        </Text>
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      // ***********************************************Alerts Filter**********************************//

      if (alerts !== null && alerts.length > 0) {
        let filteredAlerts = alerts.filter((eachalert) =>
          checkValue(`${eachalert}`)
            ? `${eachalert}`.includes(`${searchFilters.alertSearch}`)
            : "",
        );
        alertMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search alerts"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchFilters",
                    switchValue,
                    "alertSearch",
                    event.target.value,
                  )
                }
                value={searchFilters.alertSearch}
                allowClear
              />
            </div>
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredAlerts.length}
              itemSize={35}
              itemData={filteredAlerts}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters.trademarkAlerts}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkAlerts",
                        event.selectedKeys,
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedFilters",
                        switchValue,
                        "trademarkAlerts",
                        event.selectedKeys,
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        width: "1200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Checkbox
                        checked={selectedFilters.trademarkAlerts.includes(
                          `${data[index]}`,
                        )}
                      >
                        <Text style={{ fontWeight: "400" }}>{data[index]}</Text>
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }
    }

    return (
      <div>
        {/* Back */}
        <Row>
          <Col span={4} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => this.backClickHandler()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
          <Col
            span={12}
            className="alignC"
            style={{ fontSize: "14px!important" }}
          >
            <Text
              strong
              style={{ margin: "0px", fontSize: "16px" }}
              className={changeSwitch ? "display_none" : ""}
            >
              PENDING REPLY TO CORRESPONDENCE :{" "}
              {checkValue(this.props.replyToCorrespondence)
                ? this.props.replyToCorrespondence.ip_india.count
                : 0}{" "}
            </Text>
            <Text
              strong
              style={{ margin: "0px", fontSize: "16px" }}
              className={changeSwitch ? "" : "display_none"}
            >
              PSEUDO PENDING REPLY TO CORRESPONDENCE :{" "}
              {checkValue(this.props.pseudoReplyToCorrespondence)
                ? this.props.pseudoReplyToCorrespondence.pseudo.count
                : 0}{" "}
            </Text>
          </Col>
          <Col span={8} className="alignR">
            <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
              *Information shown here is not more than{" "}
              {checkValue(this.props.tmDashboard)
                ? this.props.tmDashboard.trademark.days_old
                : 0}{" "}
              day(s) old.
            </Text>
          </Col>
        </Row>
        {/* Back */}

        {/* Change-Switch */}
        <Row
          className={
            checkValue(this.props.userSubscriptions)
              ? this.props.userSubscriptions.manager.includes(
                  "PSEUDO_TRADEMARK",
                )
                ? ""
                : "display_none"
              : "display_none"
          }
        >
          <Col span={24} className="alignR m-top-10">
            <Text strong>IP INDIA</Text>
            <Switch
              className="change-manager-type"
              onChange={() => this.changeSwitch()}
              checked={changeSwitch}
            />
            <Text strong>CUSTOM</Text>
          </Col>
        </Row>
        {/* Change-Switch */}

        {/* reply to correspondence filter */}
        <Row>
          <Col span={17} className="alignC m-top-10">
            <Menu
              selectedKeys={[
                selectedFilters?.trademarkClass.length > 0 ? "classesMenu" : "",
                selectedFilters?.trademarkStatus.length > 0 ? "statusMenu" : "",
                selectedFilters?.trademarkType.length > 0 ? "typeMenu" : "",
                selectedFilters?.trademarkStates.length > 0 ? "stateMenu" : "",
                selectedFilters?.trademarkProprietors.length > 0
                  ? "proprietorsMenu"
                  : "",
                selectedFilters?.trademarkTags.length > 0 ? "tagsMenu" : "",
                selectedFilters?.trademarkOpponent.length > 0
                  ? "opponentMenu"
                  : "",
                selectedFilters?.date_of_application.length > 0
                  ? "applicationDate"
                  : "",
                selectedFilters?.trademarkAlerts.length > 0 ? "alertMenu" : "",
              ]}
              className="filter-list"
              mode="horizontal"
            >
              <Menu.Item key="classesMenu" className="filter-list-item">
                <Dropdown overlay={classesMenu} overlayClassName="filter-menu">
                  <div className="ant-dropdown-link">
                    <span>Class </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="statusMenu" className="filter-list-item">
                <Dropdown overlay={statusMenu} overlayClassName="filter-menu">
                  <div className="ant-dropdown-link">
                    <span>Status </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="typeMenu" className="filter-list-item">
                <Dropdown overlay={typeMenu} overlayClassName="filter-menu">
                  <div className="ant-dropdown-link">
                    <span>Type </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="stateMenu" className="filter-list-item">
                <Dropdown overlay={stateMenu} overlayClassName="filter-menu">
                  <div className="ant-dropdown-link">
                    <span>State </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="proprietorsMenu" className="filter-list-item">
                <Dropdown
                  overlay={proprietorsMenu}
                  overlayClassName="filter-menu"
                >
                  <div className="ant-dropdown-link">
                    <span>Proprietor </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>

              {this.state.noTagToggle === false ? (
                <Menu.Item key="tagsMenu" className="filter-list-item">
                  <Dropdown overlay={tagsMenu} overlayClassName="filter-menu">
                    {/* {this.state.noTagToggle===true?document.getElementById("tag-color").style.color="#3DA7FF":document.getElementById("tag-color").style.color="#595959"} */}
                    <div className="ant-dropdown-link" id="tag-color">
                      <span>Tags </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
              ) : (
                <Menu.Item key="tagsMenu" className="filter-list-item">
                  <Dropdown overlay={tagsMenu} overlayClassName="filter-menu">
                    {/* {this.state.noTagToggle===true?document.getElementById("tag-color").style.color="#3DA7FF":document.getElementById("tag-color").style.color="#595959"} */}
                    <div
                      className="ant-dropdown-link"
                      id="tag-color"
                      style={{ color: `${this.state.tag_color}` }}
                    >
                      <span>Tags </span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </Menu.Item>
              )}

              <Menu.Item key="opponentMenu" className="filter-list-item">
                <Dropdown overlay={opponentMenu} overlayClassName="filter-menu">
                  <div className="ant-dropdown-link">
                    <span>Opponent </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item key="applicationDate" className="filter-list-item">
                <Dropdown
                  overlay={
                    <DateRangePicker
                      type={"Application Date"}
                      startValue={
                        selectedFilters.date_of_application_startValue
                      }
                      endValue={selectedFilters.date_of_application_endValue}
                      setDate={(val) => this.setDate(val)}
                      range={this.state.range}
                      handleDateString={(startValue, endValue, dateString) =>
                        this.setState({
                          selectedFilters: {
                            ...this.state.selectedFilters,
                            [switchValue]: {
                              ...this.state.selectedFilters[switchValue],
                              date_of_application_startValue: startValue,
                              date_of_application_endValue: endValue,
                              date_of_application:
                                startValue !== null || endValue !== null
                                  ? dateString
                                  : null,
                            },
                          },
                        })
                      }
                    />
                  }
                  overlayClassName="filter-menu"
                >
                  <div className="ant-dropdown-link">
                    <Text>Application Date </Text>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>

              <Menu.Item key="alertMenu" className="filter-list-item">
                <Dropdown
                  placement={"bottomCenter"}
                  overlay={alertMenu}
                  overlayClassName="filter-menu"
                >
                  <div className="ant-dropdown-link">
                    <span>Alerts </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              {/* <Menu.Item key="evidence" className="filter-list-item">
                                    <Checkbox
                                        onChange={()=> 
                                            this.setState({
                                                selectedFilters: {
                                                    ...this.state.selectedFilters,
                                                    [switchValue]: {
                                                        ...this.state.selectedFilters[switchValue],
                                                        evidenceFilter: !selectedFilters.evidenceFilter,
                                                    }
                                                }
                                            })
                                        }
                                        checked={selectedFilters?.evidenceFilter }>
                                        <Text style={{fontWeight: '400'}}>Evidence</Text>
                                    </Checkbox>
                                </Menu.Item> */}
            </Menu>
          </Col>
          <Col span={7} className="alignR m-top-10">
            <Menu
              selectedKeys={[
                selectedFilters.reportColumn.length > 0 ? "reportMenu" : "",
              ]}
              className="filter-list"
              mode="horizontal"
            >
              <Menu.Item key="reportMenu" className="filter-list-item">
                <Dropdown overlay={reportMenu} overlayClassName="filter-menu">
                  <div className="ant-dropdown-link">
                    <span>Report Column </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Menu.Item>
              <Menu.Item
                key="reportDownload"
                className="filter-list-item report-button-menu"
              >
                <Text style={{ padding: "0px 5px" }}>
                  {changeSwitch
                    ? this.state.pseudo_selectAll
                      ? this.props.pseudoReplyToCorrespondence &&
                        this.props.pseudoReplyToCorrespondence.pseudo.count
                      : _.uniq(this.state.pseudo_add_trademark_id).length
                    : this.state.selectAll
                      ? this.props.replyToCorrespondence &&
                        this.props.replyToCorrespondence.ip_india.count
                      : _.uniq(this.state.add_trademark_id).length}{" "}
                  Mark(s)
                </Text>

                <Button
                  type="primary"
                  onClick={() => this.downloadReport()}
                  style={{ marginRight: "10px" }}
                >
                  Report
                </Button>
                <Button type="primary" onClick={() => this.replyFiled()}>
                  Reply Filed
                </Button>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            className="alignC"
            style={{ borderBottom: "1px solid #E9E9E9" }}
          >
            <Menu
              selectedKeys={[]}
              className="filter-list"
              mode="horizontal"
              style={{ alignItems: "baseline" }}
            >
              <Menu.Item
                key="contentTerm"
                className="filter-list-item apply-filter"
              >
                <Search
                  placeholder="Application No/Name"
                  onChange={(event) =>
                    this.changeStateValue_L2(
                      "selectedFilters",
                      switchValue,
                      "containsTerm",
                      event.target.value,
                    )
                  }
                  value={selectedFilters?.containsTerm}
                  allowClear
                  style={{ width: 200, display: "block" }}
                />
              </Menu.Item>
              <Menu.Item
                key="applyFilter"
                className="filter-list-item apply-filter"
              >
                <Button type="primary" onClick={() => this.applyFilter()}>
                  <i className="fa fa-filter" aria-hidden="true" />
                  <Text style={{ color: "#fff", marginLeft: "5px" }}>
                    Apply Filter
                  </Text>
                </Button>
              </Menu.Item>
              <Menu.Item
                key="resetFilter"
                className="filter-list-item apply-filter"
              >
                <span
                  onClick={() => this.resetFilter()}
                  style={{ padding: "0px 5px" }}
                >
                  RESET
                </span>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        {/* reply to correspondence filter */}

        {/* reply to correspondence */}
        <Row>
          <Col span={24}>
            <div className="m-top-20">
              <Table
                bordered
                rowKey={(record) => record.id}
                rowSelection={
                  changeSwitch
                    ? pseudoReplyToCorrespondenceRowSelection
                    : totalReplyToCorrespondenceRowSelection
                }
                columns={totalReplyToCorrespondenceColumns}
                dataSource={
                  changeSwitch
                    ? this.props.pseudoReplyToCorrespondence &&
                      this.props.pseudoReplyToCorrespondence.pseudo &&
                      this.props.pseudoReplyToCorrespondence.pseudo.trademarks
                    : this.props.replyToCorrespondence &&
                      this.props.replyToCorrespondence.ip_india &&
                      this.props.replyToCorrespondence.ip_india.trademarks
                }
                pagination={{
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  current: currentPageNumber,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} `,
                  total: changeSwitch
                    ? this.props.pseudoReplyToCorrespondence &&
                      this.props.pseudoReplyToCorrespondence.pseudo &&
                      this.props.pseudoReplyToCorrespondence.pseudo.count
                    : this.props.replyToCorrespondence &&
                      this.props.replyToCorrespondence.ip_india &&
                      this.props.replyToCorrespondence.ip_india.count,
                  onChange: this.handleNextpage,
                }}
                scroll={{ y: "calc(100vh - 300px)" }}
                loading={
                  changeSwitch
                    ? this.props.pseudoReplyToCorrespondenceStatus === LOADING
                      ? true
                      : false
                    : this.props.replyToCorrespondenceStatus === LOADING
                      ? true
                      : false
                }
              />
            </div>
          </Col>
        </Row>
        {/* reply to correspondence  */}
        {this.state.visible && (
          <DownloadReportModal
            visible={this.state.visible}
            onCloseModal={() => this.setState({ visible: false })}
            type={"trademark"}
            mm_type={"trademark"}
            add_trademark_id={
              changeSwitch
                ? this.state.pseudo_add_trademark_id
                : this.state.add_trademark_id
            }
            selected_pending_replies={
              changeSwitch
                ? this.state.pseudo_add_trademark_id
                : this.state.add_trademark_id
            }
            all_pending_replies={
              changeSwitch ? this.state.pseudo_selectAll : this.state.selectAll
            }
            pending_reply_to_correspondence={true}
            changeSwitch={changeSwitch}
            comparative={
              checkValue(this.props.userSubscriptions)
                ? this.props.userSubscriptions.manager.includes(
                    "PSEUDO_TRADEMARK",
                  )
                : false
            }
            filters={
              changeSwitch
                ? this.props.pseudoReplyToCorrespondenceAppliedFilters
                : this.props.replyToCorrespondenceAppliedFilters
            }
            report_columns={
              changeSwitch
                ? this.state.selectedFilters.pseudo.reportColumn
                : this.state.selectedFilters.ip_india.reportColumn
            }
          />
        )}

        <ReplyFiledModal
          // visible={this.state.replyFiledVisible}
          // onCloseModal = {()=> this.changeStateValue_L0('replyFiledVisible', false)}
          // mm_type = {'reply_to_correspondance'}
          // pending_reply = {this.state.pending_reply}
          // application_number = {this.state.application_number}
          // applied_for = {this.state.applied_for}
          // pseudo = {this.state.pseudo}
          // mark_type={'Portfolio trademark'}
          // actionType={REPLY_FILED_TO_CORRESPONDENCE}
          //-----------------------------------------------------------------------
          visible={this.state.replyFiledVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("replyFiledVisible", false)
          }
          mm_type={"reply_to_correspondance"}
          pending_reply={this.state.pending_reply}
          application_number={
            changeSwitch
              ? this.state.pseudo_add_trademark_id
              : this.state.add_trademark_id
          }
          applied_for={this.state.applied_for}
          pseudo={this.state.changeSwitch}
          mark_type={"Portfolio trademark"}
          actionType={REPLY_FILED_TO_CORRESPONDENCE}
          selectAll={
            this.state.changeSwitch
              ? this.state.pseudo_selectAll
              : this.state.selectAll
          }
          reRender={() => this.reRender()}
          filters={
            changeSwitch
              ? this.props.pseudoReplyToCorrespondenceAppliedFilters
              : this.props.replyToCorrespondenceAppliedFilters
          }
        />

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"trademark"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={TM}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          markId={this.state.modalsVisibility.markId}
          doc_link={true}
          sourceId={this.state.modalsVisibility.sourceId}
          source={`TRADEMARK`}
          sub_section="DASHBOARD"
          managerEmail={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getDashboard,
  getReplyToCorrespondence,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const { tmDashboard, tmDashboardStatus } = state.managerState.profileState;
  const {
    pseudo,

    replyToCorrespondence,
    replyToCorrespondenceStatus,
    replyToCorrespondenceAppliedFilters,
    replyToCorrespondencePageNumber,

    pseudoReplyToCorrespondence,
    pseudoReplyToCorrespondenceStatus,
    pseudoReplyToCorrespondenceAppliedFilters,
    pseudoReplyToCorrespondencePageNumber,
  } = state.managerState.trademarkDashboardState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
    tmDashboard,
    tmDashboardStatus,
    pseudo,

    replyToCorrespondence,
    replyToCorrespondenceStatus,
    replyToCorrespondenceAppliedFilters,
    replyToCorrespondencePageNumber,

    pseudoReplyToCorrespondence,
    pseudoReplyToCorrespondenceStatus,
    pseudoReplyToCorrespondenceAppliedFilters,
    pseudoReplyToCorrespondencePageNumber,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReplyToCorrespondence);
