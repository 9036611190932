//--------LIST LAYOUT TYPES--------
import {
  TMA_LL_DATA,
  W_LL_GET_BOOKMARK,
  W_LL_PATCH_BOOKMARK,
  W_TMA_LL_COMBINE_PDF,
} from "../../../actions/watchActions/types";

//--------COMMON IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../../constants/commonConstants/generalConstants";

const defaultListLayoutState = {
  tableData: [],
  count: null,
  automatedReportsLinks: null,
  journalNumber: null,
  pageNumber: 1,
  status: null,
  report_columns: [],
  bookmarkTemplate: null,
  bookmarkStatus: null,
  combinePdf: {
    loading: false,
  },
};

export default function (state = defaultListLayoutState, action) {
  switch (action.type) {
    case TMA_LL_DATA:
      switch (action.status) {
        case SUCCESS:
          if (action.pageNumber > 1) {
            return {
              ...state,
              tableData: action.payload.data,
              count: action.payload.metadata.count,
              pageNumber: action.pageNumber,
              status: action.status,
              report_columns: action.payload.metadata.report_columns,
              journalNumber: action.journalNumber,
            };
          } else {
            return {
              ...state,
              tableData: action.payload.data,
              count: action.payload.metadata.count,
              automatedReportsLinks: {
                ...state.automatedReportsLinks,
                all_class_watch_excel_report_url:
                  action.payload.metadata.all_class_watch_excel_report_url,
                all_class_watch_pdf_report_url:
                  action.payload.metadata.all_class_watch_pdf_report_url,
                same_class_watch_excel_report_url:
                  action.payload.metadata.same_class_watch_excel_report_url,
                same_class_watch_pdf_report_url:
                  action.payload.metadata.same_class_watch_pdf_report_url,
              },
              journalNumber: action.journalNumber,
              pageNumber: action.pageNumber,
              status: action.status,
              report_columns: action.payload.metadata.report_columns,
            };
          }

        case LOADING:
          return {
            ...(action.journalChange ? defaultListLayoutState : state),
            status: action.status,
          };

        case ERROR:
          return {
            ...state,
            status: action.status,
            count: 0,
          };

        default:
          return state;
      }

    case W_LL_GET_BOOKMARK:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            bookmarkTemplate:
              action.payload.data?.length &&
              action.payload.data[0].template_json
                ? action.payload.data[0].template_json
                : null,
            bookmarkStatus: action.status,
          };

        case LOADING:
          return {
            ...(action.journalChange ? defaultListLayoutState : state),
            bookmarkStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            bookmarkStatus: action.status,
            count: 0,
          };

        default:
          return state;
      }

    case W_LL_PATCH_BOOKMARK:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            bookmarkTemplate: action.payload.data.template_json,
            bookmarkStatus: action.status,
          };

        case LOADING:
          return {
            ...(action.journalChange ? defaultListLayoutState : state),
            bookmarkStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            bookmarkStatus: action.status,
            count: 0,
          };

        default:
          return state;
      }

    case W_TMA_LL_COMBINE_PDF:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            combinePdf: {
              ...state.combinePdf,
              loading: false,
            },
          };

        case LOADING:
          return {
            ...state,
            combinePdf: {
              ...state.combinePdf,
              loading: true,
            },
          };

        case ERROR:
          return {
            ...state,
            combinePdf: {
              ...state.combinePdf,
              loading: false,
            },
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
