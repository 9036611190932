import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Modal,
  Typography,
  Select,
  Input,
  Button,
  message,
  Popconfirm,
} from "antd";

import {
  getWorkflow,
  createWorkflow,
  editWorkflow,
} from "../../../redux/actions/managerActions/workflow/action";
import {
  SEND_WORKFLOW_EMAIL,
  CREATE_WORKFLOW_TODO_TASK,
} from "../../../redux/actions/managerActions/workflow/types";
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import { changeStateValue_L1 } from "../../../functions/commonFunctions/generalFunctions";
import WorkflowTodoTaskModal from "./WorkflowTodoTaskModal";
import WorkflowEmailModal from "./WorkflowEmailModal";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

const { Title } = Typography;
const { Option } = Select;

class CreateWorkflowModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actions_value: [],
      trigger_tasks_key: "",
      key_value: "",
      workflow_id: "",
      title: "",
      actions: [],
      applied_on: [],
      trigger_task: "",
      key: "",
      values: [],
      editWorkflowFlag: false,
      disableSave: false,
      modalVisibility: {
        addTaskVisible: false,
        workflowEmail: false,
      },
      showActionButton: false,
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }
  shouldComponentUpdate(nextProps) {
    if (nextProps.visible !== this.props.visible) {
      this.setState({
        showActionButton: checkValue(nextProps.editWorkflowFlag)
          ? nextProps.editWorkflowFlag
          : false,
        editWorkflowData: checkValue(nextProps.editWorkflowData)
          ? nextProps.editWorkflowData
          : "",
        editWorkflowFlag: checkValue(nextProps.editWorkflowFlag)
          ? nextProps.editWorkflowFlag
          : false,
        disableSave: checkValue(nextProps.editWorkflowFlag) && false,
        workflow_id: checkValue(nextProps.editWorkflowData)
          ? nextProps.editWorkflowData.workflow_id
          : "",
        title: checkValue(nextProps.editWorkflowData)
          ? nextProps.editWorkflowData.title
          : "",
        actions: checkValue(nextProps.editWorkflowData)
          ? nextProps.editWorkflowData.actions.split(", ")
          : [],
        actions_value: checkValue(nextProps.editWorkflowData)
          ? nextProps.editWorkflowData.actions
          : [],
        applied_on: checkValue(nextProps.editWorkflowData)
          ? nextProps.editWorkflowData.applied_on.split(", ")
          : [],
        trigger_task: checkValue(nextProps.editWorkflowData)
          ? nextProps.editWorkflowData.trigger_tasks
          : "",
        key: checkValue(nextProps.editWorkflowData)
          ? nextProps.editWorkflowData.key
          : "",
        values: checkValue(nextProps.editWorkflowData)
          ? nextProps.editWorkflowData.values.split(", ")
          : [],
      });
    }
    return true;
  }

  handleOnChange(fieldType, value) {
    if (fieldType === "actions_value") {
      this.setState({
        actions_value: value,
      });
    } else if (fieldType === "trigger_tasks_key") {
      this.setState({
        trigger_tasks_key: value,
        key_value: "",
      });
      this.setState({ values: [], key: "Select" });
      this.props.form.resetFields(["key", "values"]);
    } else if (fieldType === "key_value") {
      this.setState({
        key_value: value,
      });
      this.setState({ values: [] });
      this.props.form.resetFields(["values"]);
    } else {
      this.setState({
        actions_value: [],
        trigger_tasks_key: "",
        key_value: "",
      });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.props.form.validateFields(async (err, fieldsValue) => {
      const values = {
        ...fieldsValue,
        title: fieldsValue["title"],
        actions: fieldsValue["actions"],
        applied_on: fieldsValue["applied_on"],
        trigger_task: fieldsValue["trigger_task"],
        key: fieldsValue["key"],
        values: fieldsValue["values"],
      };
      if (!err) {
        let workflowID = this.state.workflow_id;
        if (workflowID) {
          await this.props.editWorkflow({ ...values, workflow_id: workflowID });
          message[this.props.createWorkflowStatus.toLowerCase()](
            this.props.createWorkflowMessage
          );
        } else {
          await this.props.createWorkflow(values);
          message[this.props.createWorkflowStatus.toLowerCase()](
            this.props.createWorkflowMessage
          );
          if (
            this.props.createWorkflowMessage ===
            "Management workflow is successfully created"
          ) {
            this.setState({ disableSave: true });
            this.setState({ showActionButton: true });
          }
        }
      }
    });
  };

  handleWorkflowTask(taskType) {
    let workflowID = this.state.workflow_id;
    if (!this.props.editWorkflowFlag) {
      workflowID =
        this.props.createWorkflowData &&
        this.props.createWorkflowData.workflow_id;
    }
    if (taskType === "TO DO TASK") {
      this.setState({
        workflow_id: workflowID,
        modalVisibility: {
          addTaskVisible: true,
        },
      });
    } else if (taskType === "SEND EMAIL") {
      let mailContent = "<p><strong>Trademark Manager</strong></p>";
      this.setState({
        workflow_id: workflowID,
        modalVisibility: {
          workflowEmail: true,
          mailContent: mailContent,
          product: "MANAGER",
          subscription: ["TRADEMARK"],
          report_tag: {
            journal_number: "",
            journal_date: "",
            status: "",
            renewal_date: "",
            application_number: "",
            mark_name: "",
            date_of_application: "",
            class: "",
            date_of_usage: "",
            proprietor_name: "",
            opponent_name: "",
            tla_hearing_date: "",
            opposition_hearing_date: "",
            report_name: "",
          },
        },
      });
    }
  }

  handleClose() {
    this.setState({
      actions_value: [],
      trigger_tasks_key: "",
      key_value: "",
      workflow_id: "",
      title: "",
      actions: [],
      applied_on: [],
      trigger_task: "",
      key: "",
      values: [],
      editWorkflowFlag: false,
      showActionButton: false,
    });
    this.props.form.resetFields([
      "title",
      "actions",
      "applied_on",
      "trigger_task",
      "key",
      "values",
    ]);
    this.props.onCloseModal();
  }

  render() {
    const {
      actions_value,
      key_value,
      trigger_tasks_key,
      title,
      actions,
      applied_on,
      trigger_task,
      key,
      values,
      editWorkflowFlag,
      modalVisibility,
    } = this.state;
    const {
      workflowAction,
      workflowAppliedOn,
      workflowKeys,
      workflowTriggerTasks,
      workflowValues,

      workflowKeyValueStatus,
      createWorkflowStatus,
    } = this.props;

    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    let defaultOptions = <Option value="please_select">Please Select</Option>;
    let actionOption = defaultOptions;
    let appliedOption = defaultOptions;
    let triggerTasksOption = defaultOptions;
    let keysOption = defaultOptions;
    let valuesOption = defaultOptions;

    if (workflowKeyValueStatus === SUCCESS) {
      actionOption =
        workflowAction &&
        workflowAction.length > 0 &&
        workflowAction.map((eachValue, key) => (
          <Option key={eachValue - key} value={eachValue}>
            {eachValue}
          </Option>
        ));
      appliedOption =
        workflowAppliedOn &&
        workflowAppliedOn.length > 0 &&
        workflowAppliedOn.map((eachValue, key) => (
          <Option key={eachValue - key} value={eachValue}>
            {eachValue}
          </Option>
        ));
      triggerTasksOption =
        workflowTriggerTasks &&
        workflowTriggerTasks.length > 0 &&
        workflowTriggerTasks.map((eachValue, key) => (
          <Option key={eachValue - key} value={eachValue}>
            {eachValue}
          </Option>
        ));
      keysOption =
        workflowKeys &&
        workflowKeys[trigger_tasks_key] &&
        workflowKeys[trigger_tasks_key].length > 0 &&
        workflowKeys[trigger_tasks_key].map((eachValue, key) => (
          <Option key={eachValue - key} value={eachValue}>
            {eachValue}
          </Option>
        ));
      if (key_value.length > 0) {
        valuesOption =
          workflowValues &&
          workflowValues[trigger_tasks_key] &&
          workflowValues[trigger_tasks_key][key_value] &&
          workflowValues[trigger_tasks_key][key_value].length > 0 &&
          workflowValues[trigger_tasks_key][key_value].map((eachValue, key) => (
            <Option key={eachValue - key} value={eachValue}>
              {eachValue}
            </Option>
          ));
      }
    }
    return (
      <div>
        <Modal
          title={
            <div className="alignC">
              <Title level={4} style={{ margin: 0 }}>
                {editWorkflowFlag ? "Edit" : "Create"} Workflow
              </Title>
            </div>
          }
          open={this.props.visible}
          onOk={this.props.onCloseModal}
          onCancel={this.props.onCloseModal}
          bodyStyle={{ padding: 0 }}
          width={"50%"}
          footer={false}
          maskClosable={false}
          closable={false}
        >
          <div class="modal-container vertical_scroll">
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Form.Item label="Title" className="m-bot-10">
                {getFieldDecorator("title", {
                  rules: [
                    {
                      required: true,
                      message: "Please input workflow title",
                    },
                  ],
                  initialValue: title,
                })(<Input placeholder="Please input workflow title" />)}
              </Form.Item>
              <Form.Item label="Actions" className="m-bot-10">
                {getFieldDecorator("actions", {
                  rules: [
                    {
                      required: true,
                      message: "Please select actions!",
                      type: "array",
                    },
                  ],
                  initialValue: actions,
                })(
                  <Select
                    mode="multiple"
                    onChange={(value) =>
                      this.handleOnChange("actions_value", value)
                    }
                    placeholder="Please select actions"
                  >
                    {actionOption}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Applied On" className="m-bot-10">
                {getFieldDecorator("applied_on", {
                  rules: [
                    {
                      required: true,
                      message: "Please select applied_on!",
                      type: "array",
                    },
                  ],
                  initialValue: applied_on,
                })(
                  <Select
                    mode="multiple"
                    placeholder="Please select applied_on"
                  >
                    {appliedOption}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Trigger Tasks" className="m-bot-10">
                {getFieldDecorator("trigger_task", {
                  rules: [
                    { required: true, message: "Please select trigger_task!" },
                  ],
                  initialValue: trigger_task,
                })(
                  <Select
                    onChange={(value) =>
                      this.handleOnChange("trigger_tasks_key", value)
                    }
                    placeholder="Please select trigger_tasks"
                  >
                    {triggerTasksOption}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Change In" className="m-bot-10">
                {getFieldDecorator("key", {
                  rules: [{ required: true, message: "Please select a key" }],
                  initialValue: key,
                })(
                  <Select
                    onChange={(value) =>
                      this.handleOnChange("key_value", value)
                    }
                    placeholder="Please select a key"
                    disabled={!trigger_tasks_key.length > 0}
                  >
                    {keysOption}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Values" className="m-bot-10">
                {getFieldDecorator("values", {
                  rules: [
                    {
                      required: true,
                      message: "Please select values!",
                      type: "array",
                    },
                  ],
                  initialValue: values,
                })(
                  <Select
                    mode="multiple"
                    placeholder="Please select values"
                    disabled={!key_value.length > 0}
                  >
                    {valuesOption}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                className="m-bot-10"
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 8 },
                }}
              >
                <>
                  <Button
                    disabled={this.state.disableSave}
                    type="primary"
                    loading={createWorkflowStatus === LOADING}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  {this.state.showActionButton && actions_value.length > 0 && (
                    <>
                      {actions_value.includes("TO DO TASK") && (
                        <Button
                          type="primary"
                          onClick={() => this.handleWorkflowTask("TO DO TASK")}
                          style={{ margin: "0px 5px" }}
                        >
                          Todo Task
                        </Button>
                      )}
                      {actions_value.includes("SEND EMAIL") && (
                        <Button
                          type="primary"
                          onClick={() => this.handleWorkflowTask("SEND EMAIL")}
                          style={{ margin: "0px 5px" }}
                        >
                          Add Email
                        </Button>
                      )}
                    </>
                  )}
                  <Popconfirm
                    title="Are you sure you want to close ?"
                    onConfirm={() => this.handleClose()}
                  >
                    <Button style={{ margin: "0px 5px" }}>Close</Button>
                  </Popconfirm>
                </>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <WorkflowEmailModal
          visible={modalVisibility.workflowEmail}
          onCloseModal={() =>
            this.changeStateValue_L1("modalVisibility", "workflowEmail", false)
          }
          workflowId={this.state.workflow_id}
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={modalVisibility.mailContent}
          action={"SEND EMAIL"}
          actionType={SEND_WORKFLOW_EMAIL}
          product={"MANAGER"}
          report_flag={true}
          subscription={"TRADEMARK"}
          report_tag={modalVisibility.report_tag}
        />
        <WorkflowTodoTaskModal
          visible={modalVisibility.addTaskVisible}
          onCloseModal={() =>
            this.changeStateValue_L1("modalVisibility", "addTaskVisible", false)
          }
          workflowId={this.state.workflow_id}
          action={"TO DO TASK"}
          actionType={CREATE_WORKFLOW_TODO_TASK}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getWorkflow,
  createWorkflow,
  editWorkflow,
};

function mapStateToProps(state) {
  const {
    workflowAction,
    workflowAppliedOn,
    workflowKeys,
    workflowTriggerTasks,
    workflowValues,
    workflowKeyValueStatus,

    createWorkflowStatus,
    createWorkflowMessage,
    createWorkflowData,
  } = state.managerState.workflowState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    workflowAction,
    workflowAppliedOn,
    workflowTriggerTasks,
    workflowKeys,
    workflowValues,
    workflowKeyValueStatus,

    createWorkflowStatus,
    createWorkflowMessage,
    createWorkflowData,

    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
  });
}

const WrappedCreateWorkflowModal = Form.create({ name: "validate_Workflow" })(
  CreateWorkflowModal
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedCreateWorkflowModal);
