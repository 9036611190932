export const defaultSelectedFilter = {
    emailSources: [],
    date_of_sending: '',
    date_of_sending_startValue: '',
    date_of_sending_endValue: '',
    containsTerm: ''
}

export const defaultSearchFilter = {
    sourceSearch: '',
}
