//--------LIBRARIES--------
import { combineReducers } from "redux";

//--------REDUCERS--------
import journalsList from "./journalsList";
import listLayout from "./listLayout";
import currentJournal from "./currentJournal";
import profilePreferences from "./profilePreferences";
import reports from "./reports";
import journals from "./journals";
import gridLayout from "./gridLayout";
import manageTrademarks from "./manageTrademarks";
import currentJournalDate from "./currentJournalDate";
import dateRange from "./dateRangeList";
import currentDateRange from "./currentDateRange";

// New Reducers
import tmApplied from "./tmApplied";
import gridLayoutData from "./gridDashboard/index";
import listLayoutData from "./listDashboard/index";
import watchFilters from "./watchFilters";
import watchTemplate from "./watchTemplate";

import brandCompare from "./brandCompare/index";

export default combineReducers({
  journalsList,
  listLayout,
  gridLayout,
  currentJournal,
  profilePreferences,
  reports,
  journals,
  manageTrademarks,
  currentJournalDate,
  dateRange,
  currentDateRange,
  tmApplied,
  gridLayoutData,
  watchFilters,
  watchTemplate,
  listLayoutData,
  brandCompare,
});
