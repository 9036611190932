//--------WATCH TYPES--------
import {
  SRCH_GET_USER_TMS,
  SET_SEARCH_PATH,
  SRCH_REPORT,
  SRCH_GET_REPORT_AVAILABILITY,
  GET_SRCH_USER_TMS,
} from "../../actions/searchActions/types";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

const defaultSearchBar = {
  userTrademarks: [],
  userTrademarksCount: 0,
  userTrademarksPage: 1,
  userTrademarksStatus: SUCCESS,
  searchPath: "/search/search-tips",
  quota: null,

  searchReport: null,
  searchReportStatus: null,

  reportAvailabilityStatus: SUCCESS,
  reportAvailability: {},
  searchUserTrademarks: null,
  searchUserTrademarksStatus: null,
};

export default function (state = defaultSearchBar, action) {
  switch (action.type) {
    case SRCH_GET_USER_TMS:
      switch (action?.status) {
        case SUCCESS:
          return {
            ...state,
            userTrademarks:
              action?.filter_contains_term === "" && action?.page > 1
                ? [...state.userTrademarks, ...action.payload]
                : action.payload,
            userTrademarksCount: action?.count,
            userTrademarksPage: action?.page,
            userTrademarksStatus: action?.status,
            quota: action?.quota,
          };

        case LOADING:
          return {
            ...state,
            userTrademarksStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            userTrademarksStatus: action.status,
          };

        default:
          return state;
      }

    case SET_SEARCH_PATH:
      return {
        ...state,
        searchPath: action.payload,
      };

    case SRCH_REPORT:
      return {
        ...state,
        searchReport: action.payload,
        searchReportStatus: action.status,
      };

    case SRCH_GET_REPORT_AVAILABILITY:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            reportAvailability: action.payload,
            reportAvailabilityStatus: SUCCESS,
          };

        case LOADING:
          return {
            ...state,
            reportAvailabilityStatus: LOADING,
          };

        case ERROR:
          return {
            ...state,
            reportAvailabilityStatus: ERROR,
          };

        default:
          return state;
      }

    // case GET_SRCH_USER_TMS:
    //     return {
    //         ...state,
    //         searchUserTrademarks: action.payload,
    //         searchUserTrademarksStatus: action.status,
    //     };

    default:
      return state;
  }
}
