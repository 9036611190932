//--------WATCH TYPES--------
import { TP_GET_TRADEMARK_PROFILE } from "../../actions/searchActions/types";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

const defaultState = {
  trademarkData: {},
  trademarkDataStatus: SUCCESS,
  realtimeData: false,
  realtimeDataStatus: SUCCESS,
  application: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case TP_GET_TRADEMARK_PROFILE:
      switch (action.status) {
        case SUCCESS:
          if (action.realtime) {
            if (
              action.application === null ||
              state.application ===
                action.payload.trademark.application_number.toString()
            ) {
              return {
                ...state,
                trademarkData: action.payload.trademark,
                realtimeData: action.payload.realtime,
                realtimeDataStatus: action.status,
              };
            } else {
              return state;
            }
          } else {
            return {
              ...state,
              trademarkData: action.payload.trademark,
              trademarkDataStatus: action.status,
              application: action.application,
            };
          }

        case LOADING:
          if (action.realtime) {
            return {
              ...state,
              realtimeDataStatus: action.status,
              application: action.application,
            };
          } else {
            return {
              ...defaultState,
              trademarkDataStatus: action.status,
              application: action.application,
            };
          }

        case ERROR:
          if (action.realtime) {
            return {
              ...state,
              realtimeDataStatus: action.status,
              application: action.application,
            };
          } else {
            return {
              ...state,
              trademarkDataStatus: action.status,
              application: action.application,
            };
          }

        default:
          return state;
      }

    default:
      return state;
  }
}
