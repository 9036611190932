//--------LIBRARIES--------
import { combineReducers } from "redux";

//--------REDUCERS--------
import uploadDashboardFilters from "./filters";
import uploadDashboard from "./uploadDashboard";

export default combineReducers({
  uploadDashboardFilters,
  uploadDashboard,
});
