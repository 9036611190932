import React, { Component } from "react";
import { connect } from "react-redux";
import {
  MailOutlined,
  PhoneOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Row, Col, Input, Button, Modal, Alert, Typography } from "antd";
import { getInTouch } from "../../../redux/actions/commonActions/actions";
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

const { Title } = Typography;

class ProductInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.props.getInTouchStatus !== LOADING) {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          await this.props.getInTouch(values);
          this.props.form.resetFields();
        }
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const MikeTM_Search_Video =
      "https://miketm-staging.s3.ap-south-1.amazonaws.com/demo-video/video/MikeTM-Search.mp4";
    const MikeTM_Watch_Video =
      "https://miketm-staging.s3.ap-south-1.amazonaws.com/demo-video/video/MikeTM-Watch.mp4";
    const MikeTM_Manager_Video =
      "https://miketm-staging.s3.ap-south-1.amazonaws.com/demo-video/video/MikeTM-Manager.mp4";
    const Patent_Manager_Video =
      "https://miketm-staging.s3.ap-south-1.amazonaws.com/demo-video/video/Patent-Manager.mp4";

    return (
      <>
        <Modal
          title={
            <div className="alignC">
              <Title level={3} style={{ margin: 0 }}>
                {this.props.product}
              </Title>
            </div>
          }
          open={this.props.visible}
          onOk={this.props.onCloseModal}
          onCancel={this.props.onCloseModal}
          className="product-modal"
          footer={false}
        >
          <div className="modal-container vertical_scroll">
            <Row className="product-information">
              {this.props.product === "Trademark Search" ? (
                <Col span={24} className="product-details">
                  <Col md={12} sm={24} className="mikelegal-content">
                    <div>
                      <p>
                        * Perform an effective public search using the advanced
                        algorithms.
                      </p>
                      <p>
                        * Just by a single click see similar marks such as
                        starts with, ends with and phonetically similar.
                      </p>
                      <p>
                        * Proprietor Search provides all marks filed by the
                        Proprietor regardless of their status.
                      </p>
                      <p>* Create reports in PDF and Excel </p>
                      <p>* Access reports from anywhere.</p>
                    </div>
                  </Col>
                  <Col md={12} sm={24}>
                    <div className="mikelegal-video-container">
                      <div className="mikelegal-video-screen">
                        <video controls className="video-container-overlay">
                          <source src={MikeTM_Search_Video} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </Col>
                </Col>
              ) : this.props.product === "Trademark Watch" ? (
                <Col span={24} className="product-details">
                  <Col span={12} className="mikelegal-content">
                    <div>
                      <p>
                        * Automated analysis of the trademark journal to show
                        potentially infringing marks.
                      </p>
                      <p>* Easy to operate cloud-based dashboard.</p>
                      <p>* Accessible from anywhere.</p>
                      <p>
                        * No need to manually go through the entire trademark
                        journal.
                      </p>
                      <p>* Access and filter entire journal for easy access.</p>
                      <p>* Access reports from anywhere.</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="mikelegal-video-container">
                      <div className="mikelegal-video-screen">
                        <video controls className="video-container-overlay">
                          <source type="video/mp4" src={MikeTM_Watch_Video} />
                        </video>
                      </div>
                    </div>
                  </Col>
                </Col>
              ) : this.props.product === "Trademark Manager" ? (
                <Col span={24} className="product-details">
                  <Col span={12} className="mikelegal-content">
                    <div>
                      <p>
                        * Automated updates for the changes in trademark
                        portfolio.
                      </p>
                      <p>
                        * Regular updates for the marks you have opposed as well
                        as marks waiting to get published so that they can be
                        opposed.
                      </p>
                      <p>
                        * Regular update and deadlines on the compliance of your
                        portfolio.
                      </p>
                      <p>
                        * Create multiple reports such as client portfolio and
                        status wise portfolio to name a few.
                      </p>
                      <p>* Upload and access documents for your trademarks.</p>
                      <p>* No need to maintain Excels manually.</p>
                      <p>* Acess reports from anywhere.</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="mikelegal-video-container">
                      <div className="mikelegal-video-screen">
                        <video controls className="video-container-overlay">
                          <source src={MikeTM_Manager_Video} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </Col>
                </Col>
              ) : this.props.product === "Patent Manager" ? (
                <Col span={24} className="product-details">
                  <Col md={12} sm={24} className="mikelegal-content">
                    <div>
                      <p>
                        * Automated updates for the changes in patent portfolio.
                      </p>
                      <p>
                        * Regular update and deadlines on the compliance of your
                        portfolio.
                      </p>
                      <p>
                        * Create multiple reports such as client portfolio and
                        status wise portfolio to name a few.
                      </p>
                      <p>* Upload and access documents for your patents.</p>
                      <p>* No need to maintain manual excel.</p>
                      <p>* Access reports from anywhere.</p>
                    </div>
                  </Col>
                  <Col md={12} sm={24}>
                    <div className="mikelegal-video-container">
                      <div className="mikelegal-video-screen">
                        <video controls className="video-container-overlay">
                          <source src={Patent_Manager_Video} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </Col>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row className="contactUs-container">
              <Col span={24}>
                <Title level={4} className="alignC">
                  Get In Touch
                </Title>
                <Form
                  onSubmit={(event) => this.handleSubmit(event)}
                  className="contactUs-form"
                >
                  {(this.props.getInTouchStatus === SUCCESS ||
                    this.props.getInTouchStatus === ERROR) && (
                    <Form.Item className="contactUs-Input-Field">
                      <Alert
                        message={this.props.getInTouchMessage}
                        type={this.props.getInTouchStatus.toLowerCase()}
                        showIcon
                      />
                    </Form.Item>
                  )}
                  <Form.Item className="contactUs-Input-Field">
                    {getFieldDecorator("name", {
                      rules: [
                        { required: true, message: "Please Enter Your Name!" },
                      ],
                    })(
                      <Input
                        type="text"
                        prefix={
                          <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="Name"
                      />
                    )}
                  </Form.Item>
                  <Form.Item className="contactUs-Input-Field">
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "Please Enter Your Email!" },
                      ],
                    })(
                      <Input
                        prefix={
                          <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="email"
                        placeholder="Email"
                      />
                    )}
                  </Form.Item>
                  <Form.Item className="contactUs-Input-Field">
                    {getFieldDecorator("contact", {
                      rules: [
                        {
                          required: true,
                          min: 7,
                          max: 13,
                          message: "Please Enter Your Contact Number!",
                        },
                      ],
                    })(
                      <Input
                        type="number"
                        prefix={
                          <PhoneOutlined
                            style={{
                              color: "rgba(0,0,0,.25)",
                              transform: "rotate(180deg)",
                            }}
                          />
                        }
                        placeholder="Contact Number"
                      />
                    )}
                  </Form.Item>
                  <Form.Item className="contactUs-Input-Field">
                    {getFieldDecorator("firm_name", {
                      rules: [
                        {
                          required: true,
                          message: "Please Enter Your Firm Name!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <ShopOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="text"
                        placeholder="Firm Name"
                      />
                    )}
                  </Form.Item>
                  <Form.Item className="contactUs-Input-Field alignC">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={
                        this.props.getInTouchStatus === LOADING ? true : false
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  getInTouch,
};

function mapStateToProps(state) {
  const { getInTouchMessage, getInTouchStatus } = state.getInTouchState;
  return {
    getInTouchMessage,
    getInTouchStatus,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ProductInformation));
