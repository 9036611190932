//--------TYPES--------
import {
  GET_CLIENT_EMAIL_CUSTOM_REPORT,
  CLIENT_EMAIL_CREATION_ORIGIN_FILTERS,
} from "../actions/commonActions/types";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../constants/commonConstants/generalConstants";

export default function (
  state = {
    clientEmailCustomReport: {},
    filters: [],
    getClientEmailCustomReportStatus: null,
    filterStatus: null,
  },
  action
) {
  switch (action.type) {
    case GET_CLIENT_EMAIL_CUSTOM_REPORT:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            getClientEmailCustomReportStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            clientEmailCustomReport: action.payload,
            getClientEmailCustomReportStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            getClientEmailCustomReportStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case CLIENT_EMAIL_CREATION_ORIGIN_FILTERS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            filterStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            filters: action.payload?.filters && action.payload?.filters?.data,
            filterStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            filterStatus: ERROR,
          };

        default:
          break;
      }
      break;

    default:
      return state;
  }
}
