import React, { Component, useState } from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  Table,
  Badge,
  Menu,
  Input,
  Row,
  Col,
  Typography,
  Dropdown,
  Button,
  Cascader,
  message,
  Checkbox,
  notification,
  Spin,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  DeleteTwoTone,
  DownOutlined,
  EditTwoTone,
  FileAddTwoTone,
  TagTwoTone,
  UpOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { FixedSizeList } from "react-window";
import {
  LOADING,
  MANAGER,
  Notes_DefaultFilterConfig,
} from "../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { connect } from "react-redux";
import {
  TM_DefaultSelectedFilters,
  TM_DefaultSearchFilters,
  MT_defaultSelectedSetters,
} from "../../../constants/managerConstants/trademarkConstants";
import {
  notesDashboard,
  getNotes,
} from "../../../redux/actions/managerActions/notes/actions";
import { logOut } from "../../../redux/actions/commonActions/actions";

import {
  changeStateValue_L1,
  changeStateValue_L0,
} from "../../../functions/commonFunctions/generalFunctions";
import { history } from "../../../history";
import {
  PATCH_TM_NOTES,
  POST_TM_NOTES,
} from "../../../redux/actions/managerActions/commonAction/types";
import {
  deleteNotes,
  deleteAllNotes,
} from "../../../redux/actions/managerActions/notes/actions";
import NotesModal from "./NotesModal";
import DownloadNotesReportModal from "./DownloadNotesReportModal";
import { caseCaderState } from "../../../redux/actions/managerActions/notes/actions";
import AddClientNotes from "./AddClientNotes";
import FilterSearchPaginate from "../../commonComponents/FilterComponents/FilterSearchPaginate";
import FilterSearchBasic from "../../commonComponents/FilterComponents/FilterSearchBasic";

const { Search } = Input;
const { Text } = Typography;
const _ = require("lodash");

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      searchFilters: TM_DefaultSearchFilters,
      selectedSetters: MT_defaultSelectedSetters,
      selectedFilters:
        this.props.lastAppliedFilters || TM_DefaultSelectedFilters,
      pageNumber: this.props.pageNumber,
      subscription: "TRADEMARK",
      noTagToggle: false,
      mmType: "TRADEMARK",
      add_trademark_id: [],
      selectedRowKeys: [],
      selectAll: false,
      selectedRows: [],
      filterConfig: Notes_DefaultFilterConfig,
      managerNotes: false,
      notesEditingKey: "",
      notesDeletingKey: "",
      type: "",
      teamemail: "",
      note_id: "",
      note: "",
      notes_for: "TM",
      notesCount: 0,
      notesPageNumber: {},
      application_no_storage: [],
      application_number: "",
      noteSelectedRowKeys: [],
      noteSelectedRows: [],
      allChecked: {},
      selected_ids: [],
      disabled: {},
      checkedClass: "",
      selected_notes: {},
      custom_trademark_id: "",
      international_trademark_id: "",
      management_patent_id: "",
      custom_patent_id: "",
      international_patent_id: "",
      modalsVisibility: {
        addClientNotes: false,
        edit: false,
        detail: false,
      },
    };
    this.handleNoTag = this.handleNoTag.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.notesDashboard({
        subscription:
          this.props.cascaderstate === null
            ? this.state.subscription
            : this.props.cascaderstate,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        filters_required_flag: false,
        pageNumber: 1,
      });
      this.props.notesDashboard({
        subscription:
          this.props.cascaderstate === null
            ? this.state.subscription
            : this.props.cascaderstate,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter:
          this.props.cascaderstate === null
            ? "CLASSES"
            : this.props.mm_type === "TRADEMARK"
            ? "CLASSES"
            : "APPLICANT",
        page: 1,
      });

      this.props.notesDashboard({
        subscription:
          this.props.cascaderstate === null
            ? this.state.subscription
            : this.props.cascaderstate,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter:
          this.props.cascaderstate === null
            ? "PROPRIETOR"
            : this.props.mm_type === "TRADEMARK"
            ? "PROPRIETOR"
            : "INVENTOR",
        page: 1,
      });
      this.props.notesDashboard({
        subscription:
          this.props.cascaderstate === null
            ? this.state.subscription
            : this.props.cascaderstate,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "TAG",
        page: 1,
        mmType: this.state.mmType,
      });
    } else {
      this.props.logOut();
    }
  }

  fetchFilters = (required_filter, filter_contains_term, pageNumber) => {
    setTimeout(() => {
      this.props.notesDashboard({
        pageNumber,
        filters_required_flag: true,
        required_filter,
        filter_contains_term,
        subscription:
          this.props.cascaderstate === null
            ? this.state.subscription
            : this.props.cascaderstate,
        mmType: this.state.mmType,
      });
    }, 500);
  };

  onFilterSearch = (required_filter, filter_contains_term) => {
    this.setState({
      isSearching: true,
      filterConfig: {
        ...this.state?.filterConfig,
        [`${required_filter}`]: {
          ...this.state?.filterConfig[`${required_filter}`],
          filter_contains_term: filter_contains_term,
          page: 1,
        },
      },
    });
    setTimeout(() => {
      this.fetchFilters(required_filter, filter_contains_term, 1);
    }, 500);
  };

  onFilterPaginate = (required_filter, filter_contains_term, page) => {
    if (this.props.notesFilterStatus !== "LOADING") {
      const page_size = 100;
      let total_pages = 0;

      let count = 0;

      switch (required_filter) {
        case "CLASSES":
          count = this.props.notesClassFilterCount;
          break;
        case "PROPRIETOR":
          count = this.props.notesProprietorFilterCount;
          break;
        case "TAG":
          count = this.props.notesTagFilterCount;
          break;
        case "APPLICANT":
          count = this.props.notesApplicantFilterCount;
          break;
        case "INVENTOR":
          count = this.props.notesInventorFilterCount;
          break;
        default:
          count = 0;
      }

      if (count !== undefined && count !== null && count > 0) {
        total_pages = Math.ceil(count / page_size);
      }
      if (page <= total_pages) {
        this.setState({
          filterConfig: {
            ...this.state?.filterConfig,
            [`${required_filter}`]: {
              ...this.state?.filterConfig[`${required_filter}`],
              page,
            },
          },
        });

        this.fetchFilters(required_filter, filter_contains_term, page);
      }
    }
  };

  customExpandIcon = (props) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: "#1890FF", marginLeft: "-200px" }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <UpOutlined />
        </a>
      );
    } else {
      return (
        <a
          style={{ color: "#1890FF", marginLeft: "-200px" }}
          onClick={(e) => {
            props.onExpand(props.record, e);
            if (
              this.state.application_no_storage.includes(
                props.record.application_number,
              ) === false
            ) {
              this.props.getNotes({
                subscription:
                  this.props.cascaderstate === null
                    ? this.state.subscription
                    : this.props.cascaderstate,
                pageNumber: 1,
                application_number: props.record.application_number,
                application_no_storage: this.state.application_no_storage,
              });
            }
            this.setState({
              ...this.state.application_no_storage,
              application_no_storage: [
                props.record.application_number,
                ...this.state.application_no_storage,
              ],
            });
            sessionStorage.setItem(
              "application_number",
              props.record.application_number,
            );
          }}
        >
          <DownOutlined />
        </a>
      );
    }
  };

  handleNextpage = (pageNumber) => {
    this.props.notesDashboard({
      subscription:
        this.props.cascaderstate === null
          ? this.state.subscription
          : this.props.cascaderstate,
      filterFlag: this.props.lastAppliedFilters.filterFlag,
      filters: this.props.lastAppliedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  handleTrademarkProfile(data) {
    if (
      this.props.cascaderstate === null ||
      this.props.cascaderstate === "TRADEMARK"
    ) {
      if (data) {
        sessionStorage.setItem(
          "management_trademark_id",
          data.management_mark_id,
        );
        sessionStorage.setItem(
          "trademark_application_number",
          data.application_number,
        );
        window.open(
          "/manager/trademark/trademark-profile/" + data.management_mark_id,
          "_blank",
        );
        this.setState({
          management_trademark_id: data.management_mark_id,
        });
      }
    } else if (this.props.cascaderstate === "CUSTOM_TRADEMARK") {
      if (data) {
        sessionStorage.setItem("custom_trademark_id", data.management_mark_id);
        sessionStorage.setItem(
          "custom_trademark_application_number",
          data.application_number,
        );
        window.open(
          "/manager/trademark/docketed-trademark/custom-trademark-profile/" +
            data.management_mark_id,
          "_blank",
        );
        this.setState({
          custom_trademark_id: data.management_mark_id,
        });
      }
    } else {
      if (data) {
        sessionStorage.setItem(
          "international_trademark_id",
          data.management_mark_id,
        );
        sessionStorage.setItem(
          "international_trademark_application_number",
          data.application_number,
        );
        window.open(
          "/manager/trademark/docketed-trademark/international-trademark-profile/" +
            data.management_mark_id,
          "_blank",
        );
        this.setState({
          international_trademark_id: data.management_mark_id,
        });
      }
    }
  }

  handlePatentProfile(data) {
    if (this.props.cascaderstate === "PATENT") {
      if (data) {
        sessionStorage.setItem(
          "management_patent_id",
          data.management_patent_id,
        );
        sessionStorage.setItem(
          "patent_application_number",
          data.application_number,
        );
        window.open(
          "/manager/patent/manage-patent-profile/" + data.management_patent_id,
          "_blank",
        );
        this.setState({
          management_patent_id: data.management_patent_id,
        });
      }
    } else if (this.props.cascaderstate === "CUSTOM_PATENT") {
      if (data) {
        sessionStorage.setItem("custom_patent_id", data.management_patent_id);
        sessionStorage.setItem(
          "custom_patent_application_number",
          data.application_number,
        );
        window.open(
          "/manager/patent/docketed-patent/custom-patent-profile/" +
            data.management_patent_id,
          "_blank",
        );
        this.setState({
          custom_patent_id: data.management_patent_id,
        });
      }
    } else {
      if (data) {
        sessionStorage.setItem(
          "international_patent_id",
          data.management_patent_id,
        );
        sessionStorage.setItem(
          "international_patent_application_number",
          data.application_number,
        );
        window.open(
          "/manager/patent/docketed-patent/international-patent-profile/" +
            data.management_patent_id,
          "_blank",
        );
        this.setState({
          international_patent_id: data.management_patent_id,
        });
      }
    }
  }

  handleProprietorProfile(data) {
    if (data) {
      sessionStorage.setItem("proprietor_id", data.id);
      window.open("/manager/trademark/proprietor-profile/" + data.id, "_blank");
      this.setState({
        proprietor_id: data.id,
      });
    }
  }

  handleCasCader = (value) => {
    this.props.caseCaderState({
      subscription: value[1],
      mmType: value[0],
      defaultValue: value,
    });
    this.props.notesDashboard({
      filterFlag: false,
      subscription: value[1],
      filters_required_flag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });

    if (value[0] === "TRADEMARK") {
      this.props.notesDashboard({
        subscription: value[1],
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "CLASSES",
        page: 1,
      });

      this.props.notesDashboard({
        subscription: value[1],
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "PROPRIETOR",
        page: 1,
      });
    } else {
      this.props.notesDashboard({
        subscription: value[1],
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "APPLICANT",
        page: 1,
      });

      this.props.notesDashboard({
        subscription: value[1],
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        filters_required_flag: true,
        required_filter: "INVENTOR",
        page: 1,
      });
    }
    if (value[0] === "TRADEMARK" || value[0] === "PATENT")
      this.props.notesDashboard({
        subscription: value[1],
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
        mmType: value[0],
        filters_required_flag: true,
        required_filter: "TAG",
        page: 1,
      });

    this.setState({
      value: value,
      selectedFilters: TM_DefaultSelectedFilters,
      searchFilters: TM_DefaultSearchFilters,
      subscription: value[1],
      mmType: value[0],
      pageNumber: 1,
      add_application_no: [],
      selectedRows: [],
      selectedRowKeys: [],
      noTagToggle: false,
      add_trademark_id: 0,
      selectAll: false,
      selected_notes: {},
    });
    if (value[0] === "TRADEMARK" && value[1] === "TRADEMARK") {
      this.setState({ notes_for: "TM" });
    } else if (value[0] === "TRADEMARK" && value[1] === "CUSTOM_TRADEMARK") {
      this.setState({ notes_for: "CTM" });
    } else if (
      value[0] === "TRADEMARK" &&
      value[1] === "INTERNATIONAL_TRADEMARK"
    ) {
      this.setState({ notes_for: "ITM" });
    } else if (value[0] === "PATENT" && value[1] === "PATENT") {
      this.setState({ notes_for: "P" });
    } else if (value[0] === "PATENT" && value[1] === "CUSTOM_PATENT") {
      this.setState({ notes_for: "CP" });
    } else {
      this.setState({ notes_for: "IP" });
    }
  };

  // *****************************NO TAGS**********************//

  handleNoTag(e) {
    this.setState({ noTagToggle: !this.state.noTagToggle });
  }

  handleAddNotes = (data) => {
    sessionStorage.setItem("application_number", data.application_number);
    this.setState({
      managerNotes: true,
      type: "ADD",
    });
  };

  handleEditNotes = (data, application) => {
    this.setState({
      managerNotes: true,
      notesEditingKey: data.id,
      note_id: data.id,
      note: data.note,
      type: "EDIT",
      teamemail: data.email,
      application_number: application,
    });
  };

  async handleDeleteNotes(data, application) {
    this.setState({
      notesDeletingKey: data.id,
    });
    if (
      `${application}` in this.state.selected_notes &&
      this.state.selected_notes[`${application}`].select_all
    ) {
      this.setState({ notesCount: this.state.notesCount - 1 });
    } else if (
      `${application}` in this.state.selected_notes &&
      this.state.selected_notes[`${application}`]["note_ids"].includes(data.id)
    ) {
      if (this.state.selected_notes[application]["note_ids"].length === 1) {
        this.setState({ notesCount: this.state.notesCount - 1 });
        delete this.state.selected_notes[`${application}`];
      } else {
        this.setState({
          selected_notes: {
            ...this.state.selected_notes,
            [application]: {
              ["note_ids"]: [
                ...this.state.selected_notes[application]["note_ids"].filter(
                  (item) => item !== data.id,
                ),
              ],
              ["select_all"]: false,
              ["application_number"]: application,
            },
          },
          notesCount: this.state.notesCount - 1,
        });
      }
    }

    await this.props.deleteNotes({
      note_for: this.state.notes_for,
      application_number: application,
      subscription:
        this.props.cascaderstate === null
          ? this.state.subscription
          : this.props.cascaderstate,
      note_id: data.id,
      delete_all: false,
    });

    message[this.props.deleteNotesStatus.toLowerCase()](
      this.props.notesMessage,
    );
  }

  async handleDeleteSelectedNotes() {
    if (Object.keys(this.state.selected_notes).length > 0) {
      await this.props.deleteAllNotes({
        note_for: this.state.notes_for,
        subscription:
          this.props.cascaderstate === null
            ? this.state.subscription
            : this.props.cascaderstate,
        delete_object: this.state.selected_notes,
      });
      message[this.props.deleteAllNotesStatus.toLowerCase()](
        this.props.notesMessage,
      );
      this.setState({
        selected_notes: {},
        allChecked: {},
        disabled: {},
        notesCount: 0,
      });
    } else {
      message.error("No Selected Notes!");
    }
  }

  downloadReport() {
    const { selectedRowKeys, selectAll } = this.state;
    // const isTla = this.state?.hearingType === 'tla' ? true : false;

    if (selectAll || selectedRowKeys?.length > 0) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  applyFilter() {
    const {
      trademarkClass,
      trademarkTags,
      trademarkProprietors,
      containsTerm,
      trademarkNOtags,
      patentApplicants,
      patentInventors,
    } = this.state?.selectedFilters;
    if (
      trademarkClass?.length > 0 ||
      trademarkTags?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      containsTerm?.length > 0 ||
      trademarkNOtags?.length > 0 ||
      patentApplicants?.length > 0 ||
      patentInventors?.length > 0
    ) {
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          filterFlag: true,
        },
        pageNumber: 1,
      });
      this.props.notesDashboard({
        subscription:
          this.props.cascaderstate === null
            ? this.state.subscription
            : this.props.cascaderstate,
        filterFlag: true,
        filters: this.state.selectedFilters,
        pageNumber: 1,
      });
    } else {
      message.error("No Filter Selected!");
    }
  }

  resetFilter() {
    const {
      trademarkClass,
      trademarkTags,
      trademarkProprietors,
      containsTerm,
      trademarkNOtags,
      patentApplicants,
      patentInventors,
    } = this.props?.lastAppliedFilters || {};
    if (
      trademarkClass?.length > 0 ||
      trademarkTags?.length > 0 ||
      trademarkProprietors?.length > 0 ||
      containsTerm?.length > 0 ||
      trademarkNOtags?.length > 0 ||
      patentApplicants?.length > 0 ||
      patentInventors?.length > 0
    ) {
      this.setState({
        noTagToggle: false,
        searchFilters: TM_DefaultSearchFilters,
        selectedFilters: {
          ...this.state.selectedFilters,
          ...TM_DefaultSelectedFilters,
          filterFlag: false,
        },
        filterConfig: Notes_DefaultFilterConfig,
        pageNumber: 1,
      });
      this.props.notesDashboard({
        subscription:
          this.props.cascaderstate === null
            ? this.state.subscription
            : this.props.cascaderstate,
        filterFlag: false,
        filters: TM_DefaultSelectedFilters,
        pageNumber: 1,
      });
      if (
        this.props.mm_type === "TRADEMARK" ||
        this.props.cascaderstate === null
      ) {
        this.props.notesDashboard({
          subscription:
            this.props.cascaderstate === null
              ? this.state.subscription
              : this.props.cascaderstate,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: "CLASSES",
          filter_contains_term: "",
        });
        this.props.notesDashboard({
          subscription:
            this.props.cascaderstate === null
              ? this.state.subscription
              : this.props.cascaderstate,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: "PROPRIETOR",
          filter_contains_term: "",
        });
        this.props.notesDashboard({
          subscription:
            this.props.cascaderstate === null
              ? this.state.subscription
              : this.props.cascaderstate,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: "TAG",
          filter_contains_term: "",
          mmType: this.state.mmType,
        });
      } else {
        this.props.notesDashboard({
          subscription:
            this.props.cascaderstate === null
              ? this.state.subscription
              : this.props.cascaderstate,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: "APPLICANT",
          filter_contains_term: "",
        });
        this.props.notesDashboard({
          subscription:
            this.props.cascaderstate === null
              ? this.state.subscription
              : this.props.cascaderstate,
          pageNumber: 1,
          filters_required_flag: true,
          required_filter: "INVENTOR",
          filter_contains_term: "",
        });
      }
    } else {
      message.error("No Filter Applied");
    }
  }

  onChange = (e, rec, application) => {
    const checked = e.target.checked;
    if (checked) {
      this.setState({ checkedClass: "checkbox-red" });

      if (`${application}` in this.state.selected_notes) {
        this.setState({
          noteSelectedRowKeys: [
            ...new Set([...this.state.noteSelectedRowKeys, rec.id]),
          ],
          selected_notes: {
            ...this.state.selected_notes,
            [application]: {
              ["note_ids"]: [
                ...this.state.selected_notes[application]["note_ids"],
                rec.id,
              ],
              ["select_all"]: false,
              ["application_number"]: application,
            },
          },
          notesCount: this.state.notesCount + 1,
        });
      } else {
        this.setState({
          noteSelectedRowKeys: [
            ...new Set([...this.state.noteSelectedRowKeys, rec.id]),
          ],
          selected_notes: {
            ...this.state.selected_notes,
            [application]: {
              ["note_ids"]: [rec.id],
              ["select_all"]: false,
              ["application_number"]: application,
            },
          },
          notesCount: this.state.notesCount + 1,
        });
      }
    } else {
      this.setState({
        notesCount: this.state.notesCount - 1,
        noteSelectedRowKeys: [
          ...this.state.noteSelectedRowKeys.filter((item) => item !== rec.id),
        ],
      });

      if (this.state.selected_notes[application]["note_ids"].length === 1) {
        delete this.state.selected_notes[`${application}`];
      } else {
        this.setState({
          selected_notes: {
            ...this.state.selected_notes,
            [application]: {
              ["note_ids"]: [
                ...this.state.selected_notes[application]["note_ids"].filter(
                  (item) => item !== rec.id,
                ),
              ],
              ["select_all"]: false,
              ["application_number"]: application,
            },
          },
          notesCount: this.state.notesCount - 1,
        });
      }
    }
  };

  selectAll = (e, rec, application) => {
    let selected_keys = rec.map((item) => item.id);
    const checked = e.target.checked;
    if (checked) {
      this.setState({
        noteSelectedRowKeys: [
          ...new Set([...this.state.noteSelectedRowKeys, ...selected_keys]),
        ],
        allChecked: { ...this.state.allChecked, [application]: true },
        selected_notes: {
          ...this.state.selected_notes,
          [application]: {
            ["select_all"]: true,
            ["application_number"]: application,
            ["note_ids"]: [],
          },
        },
        notesCount: this.state?.selected_notes[application]
          ? this.state.notesCount +
            rec?.length -
            this.state?.selected_notes[application]["note_ids"]?.length
          : this.state.notesCount + rec?.length,
        disabled: { ...this.state.disabled, [application]: true },
        checkedClass: "checkbox-red",
      });
      notification.open({
        message: "Deselect All Notes",
        description: "Click on the head checkbox to deselect all Notes",
      });
    } else {
      delete this.state.selected_notes[`${application}`];
      this.setState({
        allChecked: { ...this.state.allChecked, [application]: false },
        disabled: { ...this.state.disabled, [application]: false },
        notesCount: this.state.notesCount - rec?.length,
        noteSelectedRowKeys: [
          ...this.state.noteSelectedRowKeys.filter(
            (el) => selected_keys.indexOf(el) === -1,
          ),
        ],
      });
    }
  };

  render() {
    const { subscription, selectedFilters, searchFilters } = this.state;

    const trademark_children = [];
    const patent_children = [];

    if (this.props?.userSubscriptions?.manager?.includes("TRADEMARK")) {
      trademark_children.push({
        value: "TRADEMARK",
        label: "Trademark",
      });
    }
    if (this.props?.userSubscriptions?.manager?.includes("CUSTOM_TRADEMARK")) {
      trademark_children.push({
        value: "CUSTOM_TRADEMARK",
        label: "Custom Trademark",
      });
    }
    if (
      this.props?.userSubscriptions?.manager?.includes(
        "INTERNATIONAL_TRADEMARK",
      )
    ) {
      trademark_children.push({
        value: "INTERNATIONAL_TRADEMARK",
        label: "International Trademark",
      });
    }
    if (this.props?.userSubscriptions?.manager?.includes("PATENT")) {
      patent_children.push({
        value: "PATENT",
        label: "Patent",
      });
    }
    if (this.props?.userSubscriptions?.manager?.includes("CUSTOM_PATENT")) {
      patent_children.push({
        value: "CUSTOM_PATENT",
        label: "Custom Patent",
      });
    }
    if (
      this.props?.userSubscriptions?.manager?.includes("INTERNATIONAL_PATENT")
    ) {
      patent_children.push({
        value: "INTERNATIONAL_PATENT",
        label: "International Patent",
      });
    }

    const options = [
      {
        value: "TRADEMARK",
        label: "Trademark Notes",
        children: trademark_children,
      },
    ];

    if (patent_children?.length > 0) {
      options.push({
        value: "PATENT",
        label: "Patent Notes",
        children: patent_children,
      });
    }

    const notesDashboardrowSelection = {
      onSelect: (record, selected, selectedRows) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },
      onSelectAll: (selected, selectedRows) => {
        let trademark_data =
          this.props.manageNotes?.trademark &&
          this.props.manageNotes?.trademark?.trademark;
        let patent_data =
          this.props.manageNotes?.patent &&
          this.props.manageNotes?.patent?.patent;
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys:
            trademark_data.length !== 0
              ? trademark_data.map((row) => row.id)
              : patent_data.map((row) => row.id),
        });

        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: false,
                  add_trademark_id: [],
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: () => ({
        disabled:
          this.state.selectAll ||
          Object.keys(this.state.selected_notes).length > 0,
      }),
    };

    const expandedRowRenders = (row) => {
      let notesData = row.application_number
        ? this.props?.notes[`${row.application_number}`]
        : this.props?.notes;
      let notesCount = row.application_number
        ? this.props?.notes[`${row.application_number}`]?.note_count
        : this.props?.notes;
      let status = row.application_number
        ? this.props?.notes[`${row.application_number}`]?.notesStatus
        : LOADING;

      const notesColumns = [
        {
          dataIndex: "checked",
          width: 30,
          title: () => {
            return notesData?.notes?.length !== 0 ? (
              <Checkbox
                disabled={
                  this.state.selectAll ||
                  this.state?.add_trademark_id?.length > 0
                }
                // style={{ marginLeft: "-10px" }}
                className={this.state.checkedClass}
                checked={this.state.allChecked[row.application_number]}
                onChange={(e) =>
                  this.selectAll(e, notesData?.notes, row.application_number)
                }
              ></Checkbox>
            ) : (
              ""
            );
          },
          render: (text, rec) => {
            return (
              <Checkbox
                disabled={
                  this.state.disabled[row.application_number] ||
                  this.state.selectAll ||
                  this.state?.add_trademark_id?.length > 0
                }
                className={this.state.checkedClass}
                checked={
                  this.state.noteSelectedRowKeys.includes(rec.id) ||
                  this.state.allChecked[row.application_number]
                }
                onChange={(e) => this.onChange(e, rec, row.application_number)}
              ></Checkbox>
            );
          },
        },
        {
          title: "Notes",
          dataIndex: "note",
          width: "40%",
          className: "manage-trademark-column",
          render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
        },

        {
          title: "Emails",
          dataIndex: "email",
          width: 200,
          className: "manage-trademark-column",
          render: (text) => (
            <p className="text">
              {checkValue(text) ? text.toLowerCase() : "N.A."}
            </p>
          ),
        },
        {
          title: "Created On",
          dataIndex: "created",
          width: 150,
          className: "manage-trademark-column",
          render: (text) => (
            <p className="text-camelCase">
              {checkValue(text) ? text.toLowerCase() : "N.A."}
            </p>
          ),
        },
        {
          title: "Last Modified",
          dataIndex: "modified",
          width: 150,
          className: "manage-trademark-column",
          render: (text) => (
            <p className="text-camelCase">
              {checkValue(text) ? text.toLowerCase() : "N.A."}
            </p>
          ),
        },
        {
          title: "Actions",
          width: 150,
          className: "manage-trademark-column",
          render: (record) => (
            <div>
              <Tooltip title="Edit Notes">
                <EditTwoTone
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() =>
                    this.handleEditNotes(record, row.application_number)
                  }
                />
              </Tooltip>
              <Tooltip title="Delete Notes">
                <DeleteTwoTone
                  style={{
                    fontSize: "20px",
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.handleDeleteNotes(record, row.application_number)
                  }
                />
              </Tooltip>
            </div>
          ),
        },
      ];

      return (
        <div className="m-top-20 notes">
          <Table
            columns={notesColumns}
            rowKey={(record) => record.id}
            bordered
            dataSource={notesData?.notes}
            pagination={{
              pageSize: 3,
              showQuickJumper: true,
              showSizeChanger: false,
              defaultCurrent: 1,
              current: this.state.notesPageNumber[row.application_number],
              total: notesCount,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} `,
              onChange: (pageNumber) => {
                {
                  this.setState({
                    notesPageNumber: {
                      ...this.state.notesPageNumber,
                      [row.application_number]: pageNumber,
                    },
                  });
                  this.props.getNotes({
                    subscription:
                      this.props.cascaderstate === null
                        ? this.state.subscription
                        : this.props.cascaderstate,
                    application_number: row.application_number,
                  });
                }
              },
            }}
            loading={status === LOADING ? true : false}
          />
        </div>
      );
    };

    const notesTrademarkColumns = [
      {
        title: "IMAGE",
        dataIndex: "associated_image",
        className: "manage-trademark-column",
        render: (text) => {
          return (
            <>
              {text !== "N.A." ? (
                <img className="trademark-image" src={text} alt="" />
              ) : (
                <p className="text-camelCase">N.A.</p>
              )}
            </>
          );
        },
      },

      {
        title: "APPLICATION NUMBER",
        dataIndex: "application_number",
        className: "manage-trademark-column",
        render: (text, record) => (
          <p
            className="application-text text-camelCase"
            onClick={() => this.handleTrademarkProfile(record)}
          >
            {checkValue(text) ? text : "N.A."}
          </p>
        ),
      },
      {
        title: "TM APPLIED FOR",
        dataIndex: "applied_for",
        className: "manage-trademark-column",
        render: (text) => (
          <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
        ),
      },

      {
        title: "PROPRIETORS",
        dataIndex: "proprietor",
        className: "manage-trademark-column",
        render: (proprietor) => {
          return (
            <div>
              {proprietor.map((proprietorData) => (
                <p
                  key={proprietorData.id}
                  className={
                    this.props.cascaderstate === "TRADEMARK" ||
                    this.props.cascaderstate === null
                      ? "application-text text-camelCase"
                      : "text-camelCase"
                  }
                  onClick={() =>
                    this.props.cascaderstate === "TRADEMARK" ||
                    this.props.cascaderstate === null
                      ? this.handleProprietorProfile(proprietorData)
                      : ""
                  }
                >
                  {checkValue(proprietorData.name)
                    ? proprietorData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "CLASS",
        dataIndex: "associated_class",
        className: "manage-trademark-column-class",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.join(", ") : "N.A."}
          </p>
        ),
      },

      {
        title: "TAGS",
        dataIndex: "tags",
        width: 150,
        className: "manage-trademark-column",
        render: (tags) => {
          return (
            <div>
              {tags.length > 0
                ? tags.map((tag) => (
                    <span
                      key={tag.id}
                      className="Tag"
                      style={{
                        color: `${tag.color_code}`,
                        border: `1px solid ${tag.color_code}`,
                      }}
                    >
                      {tag.name.toUpperCase()}
                    </span>
                  ))
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "ACTIONS",
        dataIndex: "actions",
        className: "manage-trademark-column",
        render: (text, record) => (
          <>
            <Tooltip title="Add Notes">
              <FileAddTwoTone
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  padding: "16px",
                }}
                onClick={() => this.handleAddNotes(record)}
              />
            </Tooltip>
          </>
        ),
      },
    ].filter((item) =>
      this.props.cascaderstate === "TRADEMARK" ||
      this.props.cascaderstate === null
        ? item
        : item.title !== "TAGS",
    );

    const notesPatentColumns = [
      {
        title: "APPLICATION NUMBER",
        dataIndex: "application_number",
        className: "manage-trademark-column",
        render: (text, record) => (
          <p
            className="application-text text-camelCase"
            onClick={() => this.handlePatentProfile(record)}
          >
            {checkValue(text) ? text : "N.A."}
          </p>
        ),
      },

      {
        title: "APPLICANT",
        dataIndex: "applicant",
        className: "manage-trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.join(", ") : "N.A."}
          </p>
        ),
      },
      {
        title: "APPLICATION TYPE",
        dataIndex: "application_type",
        className: "manage-trademark-column",
        render: (text) => (
          <p className="text-camelCase">{checkValue(text) ? text : "N.A."}</p>
        ),
      },

      {
        title: "TITLE OF INVENTION",
        dataIndex: "title_of_invention",
        className: "manage-trademark-column",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        title: "INVENTORS",
        width: 150,
        dataIndex: "inventor",
        className: "manage-trademark-column-class",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.join(", ") : "N.A."}
          </p>
        ),
      },

      {
        title: "TAGS",
        dataIndex: "tags",
        width: 70,
        className: "manage-trademark-column",
        render: (tags) => {
          return (
            <div>
              {tags.length > 0
                ? tags.map((tag) => (
                    <span
                      key={tag.id}
                      className="Tag"
                      style={{
                        color: `${tag.color_code}`,
                        border: `1px solid ${tag.color_code}`,
                      }}
                    >
                      {tag.name.toUpperCase()}
                    </span>
                  ))
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "ACTIONS",
        dataIndex: "actions",
        width: 80,
        className: "manage-trademark-column",
        render: (text, record) => (
          <>
            <Tooltip title="Add Notes">
              <FileAddTwoTone
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  padding: "16px",
                  // marginLeft: "-100px",
                }}
                onClick={() => this.handleAddNotes(record)}
              />
            </Tooltip>
          </>
        ),
      },
    ].filter((item) =>
      this.props.cascaderstate === "PATENT" ? item : item.title !== "TAGS",
    );

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let classesMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    let tagsMenu = noFilterData;
    let applicantMenu = noFilterData;
    let inventorMenu = noFilterData;
    let tagStatus = this.props.notesFilterStatus;

    const classes = this.props.notesClassFilter;
    const proprietors = this.props.notesProprietorFilter;
    const tags = this.props.notesTagFilter;
    const applicant = this.props.notesApplicantFilter;
    const inventor = this.props.notesInventorFilter;

    console.log("calssses", classes);

    let yourClasses = (
      <FilterSearchBasic
        data={classes}
        searchFilters={searchFilters}
        search_key={"classSearch"}
        filter_key={"trademarkClass"}
        selectedFilters={selectedFilters}
        onSelect={this.changeStateValue_L1}
      />
    );

    classesMenu = (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {yourClasses}
          </div>
        </div>
      </>
    );

    let yourProprietors = (
      <FilterSearchPaginate
        datasource={proprietors}
        filter_key={"trademarkProprietors"}
        req_filter={"PROPRIETOR"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.notesFilterStatus}
        title={""}
        onSelect={this.changeStateValue_L1}
        type={"object"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "PROPRIETOR",
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"PROPRIETOR"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Proprietors"}
        searchValue={
          this.state?.filterConfig["PROPRIETOR"]?.filter_contains_term
        }
      />
    );

    proprietorsMenu = (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {yourProprietors}
          </div>
        </div>
      </>
    );

    tagsMenu = (
      <FilterSearchPaginate
        datasource={tags}
        filter_key={"trademarkTags"}
        req_filter={"TAG"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.notesFilterStatus === "LOADING"}
        title={""}
        onSelect={this.changeStateValue_L1}
        onFilterPaginate={() => {
          let required_filter = "TAG";
          this.onFilterPaginate(
            required_filter,
            this.state?.filterConfig[`${required_filter}`]
              ?.filter_contains_term,
            this.state?.filterConfig[`${required_filter}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Tags"}
        searchValue={this.state?.filterConfig["TAG"]?.filter_contains_term}
        type={"noTag"}
        handleNoTag={this.handleNoTag}
        noTagToggle={this.state.noTagToggle}
      />
    );

    let yourApplicant = (
      <FilterSearchPaginate
        datasource={applicant}
        filter_key={"patentApplicants"}
        req_filter={"APPLICANT"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.notesFilterStatus}
        title={""}
        onSelect={this.changeStateValue_L1}
        type={"array"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "APPLICANT",
            this.state?.filterConfig[`${"APPLICANT"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"APPLICANT"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Applicant"}
        searchValue={
          this.state?.filterConfig["APPLICANT"]?.filter_contains_term
        }
      />
    );

    applicantMenu = (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {yourApplicant}
          </div>
        </div>
      </>
    );

    let yourInventor = (
      <FilterSearchPaginate
        datasource={inventor}
        filter_key={"patentInventors"}
        req_filter={"INVENTOR"}
        selectedFilters={this.state.selectedFilters}
        onFilterSearch={this.onFilterSearch}
        loader={this.props.notesFilterStatus}
        title={""}
        onSelect={this.changeStateValue_L1}
        type={"array"}
        onFilterPaginate={() => {
          this.onFilterPaginate(
            "INVENTOR",
            this.state?.filterConfig[`${"INVENTOR"}`]?.filter_contains_term,
            this.state?.filterConfig[`${"INVENTOR"}`]?.page + 1,
          );
        }}
        searchPlaceholder={"Search Your Inventor"}
        searchValue={this.state?.filterConfig["INVENTOR"]?.filter_contains_term}
      />
    );

    inventorMenu = (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {yourInventor}
          </div>
        </div>
      </>
    );

    const handleOpenChange = (flag, key) => {
      if (flag) {
        this.setState({
          open: key,
        });
      } else {
        this.setState({
          open: null,
        });
      }
    };

    return (
      <>
        <div style={{ backgroundColor: "white" }} className="p-5">
          <h3 style={{ fontWeight: "600", fontSize: "24px" }}>
            Consolidated Notes
          </h3>
          <Row>
            <Col span={16}>
              <div className="d-flex mt-4">
                <p className="mt-2">Select Subscription :</p>
                <Cascader
                  style={{ width: "38%" }}
                  className="mx-3 h-25"
                  options={options}
                  defaultValue={
                    this.props.casCaderDefaultValue === null
                      ? ["TRADEMARK", "TRADEMARK"]
                      : this.props.casCaderDefaultValue
                  }
                  expandTrigger="hover"
                  onChange={this.handleCasCader}
                  allowClear={false}
                />
              </div>
            </Col>
            <Col span={8}>
              <div style={{ float: "right", marginTop: "20px" }}>
                <Button
                  type="primary"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "addClientNotes",
                      true,
                    )
                  }
                >
                  Add Notes
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={16} className="alignL m-top-10">
              <Menu
                style={{ display: "flex" }}
                selectedKeys={[
                  this.state.selectedFilters?.trademarkClass?.length > 0
                    ? "classesMenu"
                    : "",
                  this.state.selectedFilters?.trademarkProprietors?.length > 0
                    ? "proprietorsMenu"
                    : "",
                  this.state.selectedFilters?.trademarkTags?.length > 0
                    ? "tagsMenu"
                    : "",
                  this.state.selectedFilters?.trademarkNOtags?.length > 0
                    ? "tagsMenu"
                    : "",
                  this.state.selectedFilters?.patentApplicants?.length > 0
                    ? "applicantMenu"
                    : "",
                  this.state.selectedFilters?.patentInventors?.length > 0
                    ? "inventorMenu"
                    : "",
                ]}
                className="filter-list"
                mode="horizontal"
              >
                <Input
                  className="mt-2"
                  placeholder="Application No/Name"
                  onChange={(event) =>
                    this.changeStateValue_L1(
                      "selectedFilters",
                      "containsTerm",
                      event.target.value,
                    )
                  }
                  value={this.state?.selectedFilters?.containsTerm}
                  style={{ width: 250, height: "30px" }}
                  allowClear
                />

                {this.props.mm_type === null ||
                this.props.mm_type === "TRADEMARK" ? (
                  <Menu.Item
                    key="classesMenu"
                    className="filter-list-item mx-3"
                  >
                    <Dropdown
                      dropdownRender={() => classesMenu}
                      overlayClassName="filter-menu"
                      autoAdjustOverflow
                      onOpenChange={(flag) =>
                        handleOpenChange(flag, "classesMenu")
                      }
                      open={this.state.open === "classesMenu"}
                    >
                      <div className="ant-dropdown-link">
                        <span>Class </span>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    key="applicantMenu"
                    className="filter-list-item mx-3"
                  >
                    <Dropdown
                      dropdownRender={() => applicantMenu}
                      overlayClassName="filter-menu"
                      autoAdjustOverflow
                      onOpenChange={(flag) =>
                        handleOpenChange(flag, "applicant")
                      }
                      open={this.state.open === "applicant"}
                    >
                      <div className="ant-dropdown-link">
                        <span>Applicant</span>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </Menu.Item>
                )}
                {this.props.mm_type === null ||
                this.props.mm_type === "TRADEMARK" ? (
                  <Menu.Item key="proprietorsMenu" className="filter-list-item">
                    <Dropdown
                      dropdownRender={() => proprietorsMenu}
                      overlayClassName="filter-menu"
                      autoAdjustOverflow
                      onOpenChange={(flag) =>
                        handleOpenChange(flag, "proprietors")
                      }
                      open={this.state.open === "proprietors"}
                    >
                      <div
                        className="ant-dropdown-link"
                        style={{ marginLeft: "-10px" }}
                      >
                        <span>Proprietor </span>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </Menu.Item>
                ) : (
                  <Menu.Item key="inventorMenu" className="filter-list-item">
                    <Dropdown
                      dropdownRender={() => inventorMenu}
                      overlayClassName="filter-menu"
                      autoAdjustOverflow
                      onOpenChange={(flag) =>
                        handleOpenChange(flag, "inventor")
                      }
                      open={this.state.open === "inventor"}
                    >
                      <div
                        className="ant-dropdown-link"
                        style={{ marginLeft: "-10px" }}
                      >
                        <span>Inventor </span>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </Menu.Item>
                )}

                {subscription === "CUSTOM_TRADEMARK" ||
                subscription === "INTERNATIONAL_TRADEMARK" ||
                subscription === "CUSTOM_PATENT" ||
                subscription === "INTERNATIONAL_PATENT" ? (
                  ""
                ) : (
                  <Menu.Item key="tagsMenu" className="filter-list-item">
                    <Dropdown
                      dropdownRender={() => tagsMenu}
                      overlayClassName="filter-menu"
                      autoAdjustOverflow
                      onOpenChange={(flag) => handleOpenChange(flag, "tags")}
                      open={this.state.open === "tags"}
                    >
                      <div className="ant-dropdown-link">
                        <span>Tags </span>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </Menu.Item>
                )}
                <Menu.Item
                  key="applyFilter"
                  className="filter-list-item apply-filter"
                >
                  <Button type="primary" onClick={() => this.applyFilter()}>
                    <i className="fa fa-filter" aria-hidden="true" />
                    <Text style={{ color: "#fff", marginLeft: "5px" }}>
                      Apply Filter
                    </Text>
                  </Button>
                </Menu.Item>
                <Menu.Item
                  key="resetFilter"
                  className="filter-list-item apply-filter"
                >
                  <span
                    onClick={() => this.resetFilter()}
                    style={{ padding: "0px 5px", color: "#1677ff" }}
                  >
                    Reset All
                  </span>
                </Menu.Item>
              </Menu>
            </Col>

            <Col
              span={8}
              className="alignR m-top-10"
              style={{ padding: "5px 0px" }}
            >
              <Tooltip title="Deselect All">
                <Button
                  style={{
                    //  position:'fixed',
                    margin: "0px 8px",
                    padding: "0px 8px",
                  }}
                  danger
                  onClick={() =>
                    this.setState({
                      selectAll: false,
                      selectedRowKeys: [],
                      add_trademark_id: [],
                      selectedRows: [],
                      selected_notes: {},
                      noteSelectedRowKeys: [],
                      // noteSelectedRows: [],
                      allChecked: {},
                      disabled: {},
                      notesCount: 0,
                    })
                  }
                  // icon={<LegacyIcon type={"minus-circle"} />}
                  disabled={
                    !this.state.selectAll &&
                    this.state.selectedRowKeys?.length === 0 &&
                    Object.keys(this.state.selected_notes)?.length === 0
                  }
                >
                  <div style={{ display: "flex" }}>
                    <MinusCircleOutlined
                      style={{ fontSize: "15px" }}
                      onClick={() => {
                        this.setState({
                          selectedRowKeys: [],
                          selectAll: false,
                          selectedRows: [],
                        });
                      }}
                    />
                  </div>
                </Button>
              </Tooltip>
              <Badge count={this.state.notesCount} style={{ marginTop: "5px" }}>
                <Button
                  style={{
                    backgroundColor: "#FDE5E7",
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                  }}
                  danger
                  onClick={() => this.handleDeleteSelectedNotes()}
                >
                  <DeleteOutlined style={{ fontSize: "18px", color: "red" }} />
                  Delete Notes
                </Button>
              </Badge>
              <Badge
                count={
                  this.state?.selectAll
                    ? "All"
                    : this.state?.selectedRowKeys?.length
                }
                style={{ marginTop: "5px" }}
              >
                <Button
                  type="primary"
                  style={{ marginLeft: "10px", width: 120 }}
                  onClick={() => this.downloadReport()}
                >
                  Create Report
                </Button>
              </Badge>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className="m-top-20">
                <Table
                  className="notes-nested-table"
                  rowKey={(record) => record.id}
                  columns={
                    this.props.mm_type === null ||
                    this.props.mm_type === "TRADEMARK"
                      ? notesTrademarkColumns
                      : notesPatentColumns
                  }
                  bordered
                  rowSelection={notesDashboardrowSelection}
                  expandedRowRender={expandedRowRenders}
                  dataSource={
                    this.props.mm_type === null ||
                    this.props.mm_type === "TRADEMARK"
                      ? this.props.manageNotes?.trademark &&
                        this.props.manageNotes?.trademark?.trademark
                      : this.props.manageNotes?.patent &&
                        this.props.manageNotes?.patent?.patent
                  }
                  expandIcon={(props) => this.customExpandIcon(props)}
                  expandIconColumnIndex={
                    this.props.cascaderstate === "TRADEMARK" ||
                    this.props.cascaderstate === null ||
                    this.props.cascaderstate === "PATENT"
                      ? 8
                      : 7
                  }
                  expandIconAsCell={false}
                  pagination={{
                    pageSize: 100,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    defaultCurrent: 1,
                    current: this.state.pageNumber,
                    total:
                      this.props.mm_type === null ||
                      this.props.mm_type === "TRADEMARK"
                        ? this.props.manageNotes?.trademark &&
                          this.props.manageNotes?.trademark?.count
                        : this.props.manageNotes?.patent &&
                          this.props.manageNotes?.patent?.count,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} `,
                    onChange: this.handleNextpage,
                  }}
                  scroll={{ y: "calc(100vh - 340px)" }}
                  loading={
                    this.props.manageNotesStatus === LOADING ? true : false
                  }
                />
              </div>
            </Col>
          </Row>
        </div>

        <NotesModal
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          teamemail={
            this.state.type === "ADD" ? "Select Profile" : this.state.teamemail
          }
          note_for={this.state.notes_for}
          application_number={
            this.state.type === "ADD"
              ? sessionStorage.getItem("application_number")
              : this.state.application_number
          }
          note_id={this.state.note_id}
          note={this.state.type === "ADD" ? "" : this.state.note}
          type={this.state.type}
          subscription={
            this.props.cascaderstate === null
              ? this.state.subscription
              : this.props.cascaderstate
          }
        />

        {this.state.visible && (
          <DownloadNotesReportModal
            visible={this.state.visible}
            onCloseModal={() =>
              this.setState({
                visible: false,
                selectAll: false,
                selectedRowKeys: [],
                selectedRows: [],
                disabled: {},
                add_trademark_id: [],
              })
            }
            selectAll={this.state.selectAll}
            selectedRows={this.state.selectedRowKeys}
            filterFlag={this.state.selectedFilters?.filterFlag}
            filters={this.state.selectedFilters}
            mmType={
              this.props.cascaderstate === null
                ? this.state.subscription
                : this.props.cascaderstate
            }
            product_type={
              this.props.cascaderstate === null
                ? this.state.mmType
                : this.props.mm_type
            }
          />
        )}

        <AddClientNotes
          visible={this.state.modalsVisibility.addClientNotes}
          onCloseModal={() => {
            // this.resetState();

            this.changeStateValue_L1(
              "modalsVisibility",
              "addClientNotes",
              false,
            );
          }}
          product={MANAGER}
        />
      </>
    );
  }

  componentWillUnmount() {
    this.setState({
      intervalIndex: clearInterval(this.state.intervalIndex),
    });
  }
}

const mapDispatchToProps = {
  notesDashboard,
  getNotes,
  deleteNotes,
  deleteAllNotes,
  logOut,
  caseCaderState,
};

function mapStateToProps(state) {
  const {
    manageNotes,
    manageNotesStatus,
    notesClassFilter,
    notesProprietorFilter,
    notesTagFilter,
    notesApplicantFilter,
    notesInventorFilter,
    notesFilterStatus,
    manageNotesLastAppliedFilters,
    manageNotesPageNumber,
    notesClassFilterCount,
    notesProprietorFilterCount,
    notesApplicantFilterCount,
    notesInventorFilterCount,
    notesTagFilterCount,
    notes,
    notesStatus,
    deleteNotesStatus,
    deleteAllNotesStatus,
    notesMessage,
    cascaderstate,
    mm_type,
    casCaderDefaultValue,
  } = state.managerState.notesDashboard;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return {
    manageNotes,
    manageNotesStatus,
    notesClassFilter,
    notesProprietorFilter,
    notesTagFilter,
    notesApplicantFilter,
    notesInventorFilter,
    notesFilterStatus,
    notesClassFilterCount,
    notesProprietorFilterCount,
    notesApplicantFilterCount,
    notesInventorFilterCount,
    notesTagFilterCount,
    pageNumber: manageNotesPageNumber,
    lastAppliedFilters: manageNotesLastAppliedFilters,
    notes,
    notesStatus,
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
    deleteNotesStatus,
    deleteAllNotesStatus,
    notesMessage,
    cascaderstate,
    mm_type,
    casCaderDefaultValue,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
