import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import _ from "lodash";

import {
  Button,
  Input,
  notification,
  Radio,
  Drawer,
  Alert,
  Space,
  Col,
  Row,
} from "antd";

import {
  SUCCESS,
  ERROR,
  PDF,
  EXCEL,
  LOADING,
} from "../../../constants/commonConstants/generalConstants";
import { downloadReport } from "../../../redux/actions/managerActions/report/actions";

class DownloadReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFormat: EXCEL,
      reportName: "",
      reportPreference:
        this.props.userSubscriptions &&
        this.props.userSubscriptions.manager.includes("PSEUDO_TRADEMARK")
          ? "comparative"
          : "",
    };
  }

  onReportPreference = (event) => {
    this.setState({
      reportPreference: event.target.value,
      reportFormat:
        event.target.value === "comparative" ? EXCEL : this.state.reportFormat,
    });
  };

  async handleReport() {
    const {
      type,
      mm_type,
      selectAll,
      upcoming_flag,
      pending_reply_to_correspondence,
      pending_reply_to_fer,
      changeSwitch,
      customTrademark,
      internationalTrademark,
      customPatent,
      internationalPatent,
      filters,
      add_trademark_id,
      add_patent_id,
      report_columns,
      working_of_patent,
      request_for_examination,
      statement_of_undertaking,
      selected_pending_replies,
      all_pending_replies,
      hideExcel,

      selected_notifications,
      notification_page_flag,
      searchType,
    } = this.props;

    const { reportName, reportFormat } = this.state;

    let selected_marks = [];
    let selected_patents = [];
    let selected_pending_reply = [];
    let filter_data = {};

    let selected_notification = [];
    if (notification_page_flag) {
      if (selectAll) {
        selected_notification = [];
        filter_data = {};
      } else {
        selected_notification = _.uniq(selected_notifications);
      }

      if (filters?.filterFlag) {
        filter_data = {
          trademark_type: filters?.trademarkType,
          trademark_office: filters?.trademarkOffice,
          proprietor: filters?.trademarkProprietors,
          contains_term: filters?.containsTerm,
          alerts: filters?.trademarkAlerts
            ? filters.trademarkAlerts
              ? filters.trademarkAlerts.join(";")
              : `${filters.trademarkAlerts}`
            : undefined,
          fields: filters?.notificationFields,
          opponent: filters?.opponent,
          applicant: filters?.applicant,
          inventor: filters?.inventor,
          date_of_notification: filters?.date_of_notification,
        };
      } else {
        filter_data = {};
      }
    } else if (type === "trademark") {
      if (selectAll) {
        selected_marks = [];
        filter_data = {};
      } else if (upcoming_flag) {
        selected_marks = [];
        filter_data = {};
      } else if (all_pending_replies) {
        selected_marks = [];
        selected_pending_reply = [];
        filter_data = {};
      } else {
        selected_marks = _.uniq(add_trademark_id);
        selected_pending_reply = _.uniq(selected_pending_replies);
      }

      if (filters.filterFlag) {
        filter_data = {
          classes: filters.trademarkClass,
          trademark_status: filters.trademarkStatus,
          trademark_type: filters.trademarkType,
          trademark_office: filters.trademarkOffice,
          states: filters.trademarkStates,
          country: filters.trademarkCountry,
          proprietor: filters.trademarkProprietors,
          tags: filters.trademarkTags,
          opponent: filters.trademarkOpponent,
          journal_number: filters.journalNumber,
          reference_number: filters.trademarkReferenceNo,
          evidence_filter: filters.evidenceFilter,
          date_of_application: filters.date_of_application,
          valid_upto_date: filters.valid_upto_date,
          alerts: filters?.trademarkAlerts
            ? filters.trademarkAlerts
              ? filters.trademarkAlerts.join(";")
              : `${filters.trademarkAlerts}`
            : undefined,
        };
        if (searchType && searchType === "Description (G&S)") {
          filter_data = {
            ...filter_data,
            desc_contains_term: filters?.desc_contains_term,
          };
        } else {
          filter_data = {
            ...filter_data,
            contains_term: filters?.containsTerm,
          };
        }
      } else {
        filter_data = {};
      }
    } else if (type === "patent") {
      if (selectAll) {
        selected_patents = [];
        filter_data = {};
      } else if (upcoming_flag) {
        selected_patents = [];
        filter_data = {};
      } else if (pending_reply_to_fer) {
        selected_patents = [];
        filter_data = {};
      } else if (working_of_patent) {
        selected_patents = [];
        filter_data = {};
      } else if (statement_of_undertaking) {
        selected_patents = [];
        filter_data = {};
      } else if (request_for_examination) {
        selected_patents = [];
        filter_data = {};
      } else {
        selected_patents = _.uniq(add_patent_id);
      }

      if (filters.filterFlag) {
        filter_data = {
          applicant: filters.applicant,
          inventor: filters.inventor,
          application_type: filters.applicationType,
          field_of_invention: filters.fieldOfInvention,
          status: filters.applicationStatus,
          legal_status: filters.legalStatus,
          classification_ipc: filters.classificationIPC,
          country: filters.country,
          contains_term: filters.containsTerm,
        };
      } else {
        filter_data = {};
      }
    } else {
      if (selectAll) {
        selected_marks = [];
        filter_data = {};
      } else {
        selected_marks = _.uniq(add_trademark_id);
      }

      if (filters.filterFlag) {
        filter_data = {
          classes: filters.trademarkClass,
          location: filters.hearingLocation,
          officer: filters.hearingOfficer,
          proprietor: filters.trademarkProprietors,
          tags: filters.trademarkTags,
          date_of_hearing: filters.hearing_date_value,
          contains_term: filters.containsTerm,
        };
      } else {
        filter_data = {};
      }
    }

    await this.props.downloadReport({
      type: type,
      mm_type: mm_type,
      report_name: reportName,
      report_type: reportFormat,
      selected_marks: selected_marks,
      selected_patents: selected_patents,
      filter_flag: filters.filterFlag,
      filters: filter_data,
      all: selectAll,
      upcoming_flag: upcoming_flag,
      pending_reply_to_correspondence: pending_reply_to_correspondence,
      pending_reply_to_fer: pending_reply_to_fer,
      pseudo_flag: changeSwitch,
      comparative: this.state.reportPreference === "comparative",
      custom_trademark: customTrademark === "custom_trademark",
      international_trademark:
        internationalTrademark === "international_trademark",
      custom_patent: customPatent === "custom_patent",
      international_patent: internationalPatent === "international_patent",
      report_columns: report_columns,
      working_of_patent: working_of_patent,
      statement_of_undertaking: statement_of_undertaking,
      request_for_examination: request_for_examination,
      all_pending_replies: all_pending_replies,

      selected_pending_replies: selected_pending_reply,
      selected_notification: selected_notification ? selected_notification : [],
      notification_page_flag: notification_page_flag
        ? notification_page_flag
        : false,
    });
    this.props.onCloseModal();
    notification[
      this.props?.downloadReportStatus === SUCCESS
        ? "success"
        : this.props?.downloadReportStatus === ERROR
          ? "error"
          : "info"
    ]({
      message: "Download Report",
      description:
        this.props?.downloadReportStatus === SUCCESS
          ? this.props?.downloadReportMsg?.message
          : this.props?.downloadReportStatus === ERROR
            ? "No Report Created"
            : "",
    });
  }

  render() {
    return (
      <Drawer
        title={<h3>Custom Report Details</h3>}
        placement="right"
        width={400}
        closable={false}
        open={this.props.visible}
        onClose={() => this.props.onCloseModal()}
      >
        {(this.props.userSubscriptions
          ? this.props.userSubscriptions.manager.includes("PSEUDO_TRADEMARK")
          : false) && (
          <Row>
            <Col span={24}>
              <p className="fw_500">Choose your preferred report:</p>
              <Radio.Group
                onChange={this.onReportPreference}
                value={this.state.reportPreference}
              >
                <Radio value="comparative">Comparative Report</Radio>
                <br />
                <Radio value={this.props.changeSwitch ? "custom" : "ipindia"}>
                  {this.props.changeSwitch
                    ? "Custom Report"
                    : "IP India Report"}
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        )}
        <Row className="m-top-20">
          <Col span={24}>
            <p className="fw_500">Enter the name of your report:</p>
            <Input
              onChange={(event) => {
                if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                  this.setState({ reportName: event.target.value });
                }
              }}
              value={this.state.reportName}
              placeholder="Report's name (Optional)"
            />
          </Col>
        </Row>
        <Row className="m-top-20">
          <Col span={24}>
            <p className="fw_500">Choose your preferred report format:</p>
            <Radio.Group
              value={this.state.reportFormat}
              onChange={(event) => {
                if (
                  this.state.reportPreference === "comparative" ||
                  this.props.internationalTrademark ===
                    "international_trademark" ||
                  this.props.customTrademark === "custom_trademark" ||
                  this.props.internationalPatent === "international_patent" ||
                  this.props.customPatent === "custom_patent"
                ) {
                  this.setState({ reportFormat: EXCEL });
                } else {
                  this.setState({ reportFormat: event.target.value });
                }
              }}
              size="small"
            >
              {!this.props.notification_page_flag && (
                <>
                  <Radio
                    value={PDF}
                    style={{ fontWeight: 400 }}
                    disabled={
                      this.state.reportPreference === "comparative" ||
                      this.props.internationalTrademark ===
                        "international_trademark" ||
                      this.props.customTrademark === "custom_trademark" ||
                      this.props.internationalPatent ===
                        "international_patent" ||
                      this.props.customPatent === "custom_patent"
                        ? true
                        : false
                    }
                  >
                    PDF
                  </Radio>
                  <br />
                </>
              )}
              <Radio value={EXCEL} style={{ fontWeight: 400 }}>
                EXCEL
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row className="m-top-20">
          <Col span={24}>
            <Alert
              message="Note: Once the report is created, it cannot be changed."
              type="warning"
            />
          </Col>
        </Row>
        <Row className="m-top-20">
          <Col span={24}>
            <Space>
              <Button
                loading={this.props?.downloadReportStatus === LOADING}
                type="primary"
                ghost
                onClick={() => this.handleReport()}
                rootStyle={{ marginRight: "10px" }}
              >
                Confirm
              </Button>
              <Button danger ghost onClick={() => this.props.onCloseModal()}>
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

const mapDispatchToProps = {
  downloadReport,
};

function mapStateToProps(state) {
  const { downloadReportMsg, downloadReportStatus } =
    state.managerState.reportState;

  const { userSubscriptions, userDetails, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    downloadReportMsg,
    downloadReportStatus,
    userSubscriptions,
    userDetails,
    userSubscriptionStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadReportModal);
