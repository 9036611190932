import React, { Component } from "react";
import SignInPageImg from "../../assets/images/IpSuiteLogin.svg";
import { changeStateValue_L1 } from "../../functions/commonFunctions/generalFunctions";
import TermsAndCondition from "../commonComponents/Modals/TermsAndCondition";
import PrivacyPolicy from "../commonComponents/Modals/PrivacyPolicy";
import logo from "../../assets/images/MikeLegalLogo.svg";
import { Typography } from "antd";

const { Text } = Typography;

class SignInSider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalsVisibility: {
        termsAndCondition: false,
        privacyPolicy: false,
      },
      formVisible: {
        registration: false,
      },
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  render() {
    return (
      <div class="col-lg-6 col-sm-12 hero-left-section">
        <div className="Mikelegal-logo">
          <a
            href="https://mikelegal.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="mike-logo" src={logo} alt="logo" />
          </a>
        </div>

        <div className="hero-image-section">
          <img
            className="SignInPageImg"
            src={SignInPageImg}
            alt="SignInPageImg"
          />
        </div>

        <div className="ip-footer">
          <Text
            style={{ color: "white" }}
            underline
            className="footer-links"
            onClick={() =>
              this.changeStateValue_L1(
                "modalsVisibility",
                "termsAndCondition",
                true
              )
            }
          >
            Terms And Conditions
          </Text>

          <div className="footer-links">{`© ${new Date().getFullYear()} MikeLegal`}</div>

          <Text
            style={{ color: "white" }}
            underline
            className="footer-links"
            onClick={() =>
              this.changeStateValue_L1(
                "modalsVisibility",
                "privacyPolicy",
                true
              )
            }
          >
            Privacy Policy
          </Text>
        </div>
        <PrivacyPolicy
          visible={this.state.modalsVisibility.privacyPolicy}
          onCloseModal={() =>
            this.changeStateValue_L1("modalsVisibility", "privacyPolicy", false)
          }
        />

        <TermsAndCondition
          visible={this.state.modalsVisibility.termsAndCondition}
          onCloseModal={() =>
            this.changeStateValue_L1(
              "modalsVisibility",
              "termsAndCondition",
              false
            )
          }
        />
      </div>
    );
  }
}

export default SignInSider;
