import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Modal,
  Typography,
  Input,
  Radio,
  Select,
  DatePicker,
  Button,
  Tag,
  message,
  notification,
  Switch,
} from "antd";

//--------ACTIONS--------
import {
  getTeamMember,
  logOut,
} from "../../../redux/actions/commonActions/actions";
import {
  getToDoTask,
  createToDoTask,
  todoReminder,
  deleteReminder,
  resetReminder,
  editReminder,
} from "../../../redux/actions/managerActions/to-do-task/actions";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import moment from "moment";

const { Title, Text } = Typography;
const { Option } = Select;

class CreateTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      trademarks: "",
      trademarksData: [],
      patent: "",
      patentData: [],
      userEmails: "",
      emailData: [],
      priority: "",
      title: "",
      description: "",
      emails: "",
      associated_trademarks: "",
      associated_patent: "",
      created_by: "",
      due_on_datetime: "",
      reminders: "",
      managerType: true,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.type === "trademark") {
        this.setState({
          managerType: true,
        });
      } else if (this.props.type === "patent") {
        this.setState({
          managerType: false,
        });
      } else {
        this.setState({
          managerType: true,
        });
      }
    } else {
      this.props.logOut();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.editTask !== this.props.editTask) {
      this.setState({
        emailData: checkValue(nextProps.editTask)
          ? nextProps.editTask.emails
          : [],
        trademarksData: checkValue(nextProps.editTask)
          ? nextProps.editTask.associated_trademarks
          : [],
        patentData: checkValue(nextProps.editTask)
          ? nextProps.editTask.associated_patents
          : [],
      });
      this.props.editReminder(
        checkValue(nextProps.editTask) ? nextProps.editTask.reminders : []
      );
    }
    if (nextProps.visible !== this.props.visible) {
      if (nextProps.teamMember === null) {
        this.props.getTeamMember("manager");
      }
    }
    return true;
  }

  changeManagerType() {
    this.setState({
      managerType: !this.state.managerType,
    });
  }

  ChangeFontColor(todoData) {
    if (todoData) {
      if (todoData.status === "Expired") {
        return "expired-task-font-color";
      } else if (todoData.status === "Pending") {
        if (todoData.priority === "High") {
          return "high-priority-task-font-color";
        } else if (todoData.priority === "Medium") {
          return "medium-priority-task-font-color";
        } else if (todoData.priority === "Low") {
          return "low-priority-task-font-color";
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else if (todoData === "") {
      return "";
    }
  }

  addFieldValue(e, fieldName) {
    if (fieldName === "addTrademark") {
      this.setState({
        trademarks: e.target.value,
      });
    } else if (fieldName === "addPatent") {
      this.setState({
        patent: e.target.value,
      });
    } else if (fieldName === "userEmail") {
      this.setState({
        userEmails: e.target.value,
      });
    }
  }

  concatFieldValue(fieldName) {
    if (fieldName === "addTrademark") {
      if (this.state.trademarksData.indexOf(this.state.trademarks) > -1) {
        message.error("no trademark field added");
      } else {
        this.setState({
          trademarksData: this.state.trademarksData.concat([
            this.state.trademarks,
          ]),
          trademarks: this.props.form.resetFields("associated_trademarks"),
        });
      }
    } else if (fieldName === "addPatent") {
      if (this.state.patentData.indexOf(this.state.patent) > -1) {
        message.error("no patent field added");
      } else {
        this.setState({
          patentData: this.state.patentData.concat([this.state.patent]),
          patent: this.props.form.resetFields("associated_patent"),
        });
      }
    } else if (fieldName === "userEmail") {
      if (this.state.emailData.indexOf(this.state.userEmails) > -1) {
        message.error("no email field added");
      } else {
        this.setState({
          emailData: this.state.emailData.concat([this.state.userEmails]),
          userEmails: this.props.form.resetFields("emails"),
        });
      }
    }
  }

  removeFieldValue(fieldName, mark) {
    if (fieldName === "addTrademark") {
      let trademarksData = [...this.state.trademarksData];
      trademarksData = this.state.trademarksData.filter(
        (tmData) => tmData !== mark
      );
      this.setState({
        trademarksData: trademarksData,
      });
    } else if (fieldName === "addPatent") {
      let patentData = [...this.state.patentData];
      patentData = this.state.patentData.filter(
        (patentData) => patentData !== mark
      );
      this.setState({
        patentData: patentData,
      });
    } else if (fieldName === "userEmail") {
      let emailData = [...this.state.emailData];
      emailData = this.state.emailData.filter((emData) => emData !== mark);
      this.setState({
        emailData: emailData,
      });
    }
  }

  onSetDueDate = (value, dateString) => {
    this.setState({
      dueDateTimeValue: value,
      dueDateTime: dateString,
    });
  };

  onSetReminder = (value, dateString) => {
    this.setState({
      reminderValue: value,
      reminders: dateString,
    });
  };

  async setReminder() {
    let reminders = this.state.reminders;
    await this.props.todoReminder(reminders);
    this.props.form.resetFields("reminders");
  }

  async removeReminder(data) {
    let reminderId = data.id;
    await this.props.deleteReminder(reminderId);
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let priority = checkValue(values["priority"]) ? values["priority"] : "";
        let title = checkValue(values["title"]) ? values["title"] : "";
        let description = checkValue(values["description"])
          ? values["description"]
          : "";
        let emails = checkValue(this.state.emailData)
          ? this.state.emailData
          : "";
        let associated_trademarks = checkValue(this.state.trademarksData)
          ? this.state.trademarksData
          : "";
        let associated_patent = checkValue(this.state.patentData)
          ? this.state.patentData
          : "";
        let created_by = checkValue(values["created_by"])
          ? values["created_by"]
          : "";
        let due_on_datetime = checkValue(values["due_on_datetime"])
          ? values["due_on_datetime"].format("DD/MM/YYYY-h:mm a")
          : "";
        let reminders = checkValue(this.props.addReminder)
          ? this.props.addReminder.map((e) => e.id)
          : [];

        await this.props.createToDoTask({
          priority: priority,
          title: title,
          description: description,
          emails: emails,
          associated_trademarks: associated_trademarks,
          associated_patent: associated_patent,
          created_by: created_by,
          due_on_datetime: due_on_datetime,
          reminders: reminders,
        });
        if (this.props.addTodoTaskStatus === SUCCESS) {
          this.addTodoTaskNotification(SUCCESS);
          this.props.form.resetFields();
          this.props.onCloseModal();
          this.props.resetReminder();
          this.setState({
            emailData: [],
            trademarksData: [],
            patentData: [],
          });
        } else {
          this.addTodoTaskNotification(ERROR);
        }
      }
    });
  }

  addTodoTaskNotification(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Todo Task Added",
          description: "Your Todo Task Added Successfully",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Todo Task Added",
          description:
            "Unfortunately, an error occured while Todo Task Adding. Please, try again later.",
        });
        break;

      default:
        break;
    }
  }

  onCloseTask() {
    this.props.onCloseModal();
    this.props.form.resetFields();
  }

  render() {
    const { userSubscriptions } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    return (
      <div>
        <Modal
          title={
            <div>
              <Row>
                <Col span={12}>
                  <Title
                    level={4}
                    style={{ margin: "0px" }}
                    className={this.ChangeFontColor(this.props.editTask)}
                  >
                    {checkValue(this.props.editTask)
                      ? this.props.editTask.title
                      : "CREATE TASK"}
                  </Title>
                </Col>
                {this.props.userSubscriptions &&
                  this.props.userSubscriptions.manager.includes("TRADEMARK") &&
                  this.props.userSubscriptions.manager.includes("PATENT") && (
                    <Col span={12} className="alignR">
                      <div
                        className={
                          this.props.type === "trademark" ||
                          this.props.type === "patent"
                            ? "display_none"
                            : ""
                        }
                      >
                        <Text strong>TRADEMARK</Text>
                        <Switch
                          className="change-manager-type"
                          onChange={() => this.changeManagerType()}
                        />
                        <Text strong>PATENT</Text>
                      </div>
                    </Col>
                  )}
              </Row>
            </div>
          }
          open={this.props.visible}
          width={650}
          onOk={this.props.onCloseModal}
          onCancel={this.props.onCloseModal}
          closable={false}
          wrapClassName="create-task-modal"
          headStyle={{
            padding: "15px",
          }}
          bodyStyle={{
            padding: "15px",
            overflowX: "hidden",
            msOverflowY: "auto",
            height: "75vh",
          }}
          footer={[
            <Button type="default" onClick={() => this.onCloseTask()}>
              Close
            </Button>,
            <Button
              type="primary"
              htmlType="submit"
              key="submit"
              loading={this.props.addTodoTaskStatus === LOADING ? true : false}
              onClick={(e) => this.handleSubmit(e)}
            >
              Create Task
            </Button>,
          ]}
        >
          <div>
            <Row>
              <Form
                {...formItemLayout}
                style={{ width: "100%" }}
                onSubmit={(e) => this.handleSubmit(e)}
                className="create-task-form"
              >
                <Form.Item label="Priority">
                  {getFieldDecorator("priority", {
                    initialValue: checkValue(this.props.editTask)
                      ? this.props.editTask.priority
                      : "Low",
                  })(
                    <Radio.Group>
                      <Radio value="High">High</Radio>
                      <Radio value="Medium">Medium</Radio>
                      <Radio value="Low">Low</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
                <Form.Item label="Task Name">
                  {getFieldDecorator("title", {
                    rules: [
                      { required: true, message: "Please Enter Task Name!" },
                    ],
                    initialValue: checkValue(this.props.editTask)
                      ? this.props.editTask.title
                      : "",
                  })(<Input type="text" placeholder="Task Name" allowClear />)}
                </Form.Item>
                <Form.Item label="Task Description">
                  {getFieldDecorator("description", {
                    rules: [
                      {
                        required: true,
                        message: "Please Enter Task Description!",
                      },
                    ],
                    initialValue: checkValue(this.props.editTask)
                      ? this.props.editTask.description
                      : "",
                  })(
                    <Input
                      type="text"
                      placeholder="Task Description"
                      allowClear
                    />
                  )}
                </Form.Item>
                <Form.Item label="Email Address">
                  {getFieldDecorator("emails")(
                    <Input
                      type="email"
                      placeholder="Email Address"
                      onChange={(e) => this.addFieldValue(e, "userEmail")}
                      allowClear
                    />
                  )}
                </Form.Item>
                <div
                  className="ant-row"
                  style={{
                    display: "flex",
                    marginTop: "-10px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="ant-col ant-col-6 alignL">
                    <Button
                      type="default"
                      onClick={() => this.concatFieldValue("userEmail")}
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                      <Text strong style={{ marginLeft: "5px" }}>
                        Add Email
                      </Text>
                    </Button>
                  </div>
                  <div className="ant-col ant-col-18 mx-5">
                    {this.state.emailData &&
                      this.state.emailData.length > 0 &&
                      this.state.emailData.map((emData) => (
                        <Tag
                          key={emData}
                          style={{ margin: "5px" }}
                          closable
                          onClose={() =>
                            this.removeFieldValue("userEmail", emData)
                          }
                        >
                          {emData}
                        </Tag>
                      ))}
                  </div>
                </div>
                <Form.Item
                  label="Trademark"
                  className={
                    userSubscriptions &&
                    userSubscriptions.manager.includes("TRADEMARK") &&
                    userSubscriptions.manager.includes("PATENT")
                      ? this.state.managerType
                        ? ""
                        : "display_none"
                      : userSubscriptions &&
                        userSubscriptions.manager.includes("TRADEMARK")
                      ? ""
                      : "display_none"
                  }
                >
                  {getFieldDecorator("associated_trademarks")(
                    <Input
                      type="number"
                      placeholder="Add Trademark"
                      onChange={(e) => this.addFieldValue(e, "addTrademark")}
                      allowClear
                    />
                  )}
                </Form.Item>
                <div
                  className={
                    userSubscriptions &&
                    userSubscriptions.manager.includes("TRADEMARK") &&
                    userSubscriptions.manager.includes("PATENT")
                      ? this.state.managerType
                        ? "ant-row"
                        : "display_none"
                      : userSubscriptions &&
                        userSubscriptions.manager.includes("TRADEMARK")
                      ? "ant-row"
                      : "display_none"
                  }
                  style={{
                    display: "flex",
                    marginTop: "-10px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="ant-col ant-col-6 alignL">
                    <Button
                      type="default"
                      onClick={() => this.concatFieldValue("addTrademark")}
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                      <Text strong style={{ marginLeft: "5px" }}>
                        Add Trademark
                      </Text>
                    </Button>
                  </div>
                  <div className="ant-col ant-col-18 mx-5">
                    {this.state.trademarksData &&
                      this.state.trademarksData.length > 0 &&
                      this.state.trademarksData.map((tmData) => (
                        <Tag
                          key={tmData}
                          style={{ margin: "5px" }}
                          closable
                          onClose={() =>
                            this.removeFieldValue("addTrademark", tmData)
                          }
                        >
                          {tmData}
                        </Tag>
                      ))}
                  </div>
                </div>
                <Form.Item
                  label="Patent"
                  className={
                    userSubscriptions &&
                    userSubscriptions.manager.includes("TRADEMARK") &&
                    userSubscriptions.manager.includes("PATENT")
                      ? this.state.managerType
                        ? "display_none"
                        : ""
                      : userSubscriptions &&
                        userSubscriptions.manager.includes("PATENT")
                      ? ""
                      : "display_none"
                  }
                >
                  {getFieldDecorator("associated_patent")(
                    <Input
                      type="text"
                      placeholder="Add patent"
                      onChange={(e) => this.addFieldValue(e, "addPatent")}
                      allowClear
                    />
                  )}
                </Form.Item>
                <div
                  className={
                    userSubscriptions &&
                    userSubscriptions.manager.includes("TRADEMARK") &&
                    userSubscriptions.manager.includes("PATENT")
                      ? this.state.managerType
                        ? "display_none"
                        : "ant-row"
                      : userSubscriptions &&
                        userSubscriptions.manager.includes("PATENT")
                      ? "ant-row"
                      : "display_none"
                  }
                  style={{
                    display: "flex",
                    marginTop: "-10px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="ant-col ant-col-6 alignL">
                    <Button
                      type="default"
                      onClick={() => this.concatFieldValue("addPatent")}
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                      <Text strong style={{ marginLeft: "5px" }}>
                        Add Patent
                      </Text>
                    </Button>
                  </div>
                  <div className="ant-col ant-col-18 mx-5">
                    {this.state.patentData &&
                      this.state.patentData.length > 0 &&
                      this.state.patentData.map((patentData) => (
                        <Tag
                          key={patentData}
                          style={{ margin: "5px" }}
                          closable
                          onClose={() =>
                            this.removeFieldValue("addPatent", patentData)
                          }
                        >
                          {patentData}
                        </Tag>
                      ))}
                  </div>
                </div>
                <Form.Item label="Created By">
                  {getFieldDecorator("created_by", {
                    rules: [
                      { required: true, message: "Please select your email!" },
                    ],
                    initialValue: checkValue(this.props.editTask)
                      ? this.props.editTask.created_by.id
                      : [],
                  })(
                    <Select placeholder="Please select your Email">
                      {this.props.teamMember &&
                        this.props.teamMember.length > 0 &&
                        this.props.teamMember.map((member) => (
                          <Option key={member.id} value={member.id}>
                            {member.email}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Due Date and Time">
                  {getFieldDecorator("due_on_datetime", {
                    initialValue: checkValue(this.props.editTask)
                      ? moment(
                          this.props.editTask.local_time,
                          "DD/MM/YYYY-h:mm a"
                        )
                      : "",
                  })(
                    <DatePicker
                      use12Hours={true}
                      showTime={{ format: "HH:mm" }}
                      format="DD/MM/YYYY-h:mm a"
                      placeholder="Due-Date-Time"
                      onChange={this.onSetDueDate}
                      onOk={this.onOk}
                    />
                  )}
                </Form.Item>
                <Form.Item label="Set Date and Time">
                  {getFieldDecorator("reminders")(
                    <DatePicker
                      use12Hours={true}
                      showTime={{ format: "HH:mm" }}
                      format="DD/MM/YYYY-h:mm a"
                      placeholder="Set Reminder"
                      onChange={this.onSetReminder}
                      onOk={this.onOk}
                    />
                  )}
                </Form.Item>
                <div className="ant-row d-flex">
                  <div className="ant-col ant-col-6 alignL">
                    <Button
                      loading={this.props.todoReminderStatus === LOADING}
                      type="default"
                      onClick={() => this.setReminder()}
                    >
                      <i className="fa fa-plus cursor_pointer" />
                      <Text strong style={{ marginLeft: "5px" }}>
                        {" "}
                        Set Reminder
                      </Text>
                    </Button>
                  </div>
                  <div className="ant-col ant-col-18 mx-5">
                    {this.props.addReminder &&
                      this.props.addReminder.length > 0 &&
                      this.props.addReminder.map((reminderData) => (
                        <Tag
                          key={reminderData.id}
                          style={{ margin: "5px" }}
                          closable
                          onClose={() => this.removeReminder(reminderData)}
                        >
                          {reminderData.local_time}
                        </Tag>
                      ))}
                  </div>
                </div>
                <Form.Item className="alignR m-top-20 display_none">
                  <Button type="primary" htmlType="submit">
                    Create Task
                  </Button>
                </Form.Item>
              </Form>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }

  componentWillUnmount() {}
}

const mapDispatchToProps = {
  createToDoTask,
  todoReminder,
  deleteReminder,
  resetReminder,
  getTeamMember,
  getToDoTask,
  editReminder,
  logOut,
};

function mapStateToProps(state) {
  const {
    todoTask,
    todoTaskStatus,
    addTodoTask,
    addTodoTaskStatus,
    addReminder,
    addReminderStatus,
    todoReminder,
    todoReminderStatus,
  } = state.managerState.todoTaskState;
  const { teamMember, teamMemberStatus } = state.profile;

  const { userSubscriptions, userDetails, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userSubscriptionStatus,
    todoTask,
    todoTaskStatus,
    addTodoTask,
    addTodoTaskStatus,
    addReminder,
    addReminderStatus,
    todoReminder,
    todoReminderStatus,
    teamMember,
    teamMemberStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CreateTask));
