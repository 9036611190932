//--------LIBRARIES--------
import Axios from "axios";
//--------RT_CONFIG--------
import { ROOT_URL } from "../../../configs/rootConfigs";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE TYPES--------
import { SET_MFA, DELETE_MFA, RESET_PROFILE_PASSWORD } from "./types";

import { checkAuthorisation } from "./actions";
import { message } from "antd";

//---------MFA: Get--MFA--------
export function setMFA(params) {
  return async (dispatch) => {
    dispatch({ type: SET_MFA, status: LOADING });
    try {
      let response = await Axios.post(`${ROOT_URL}/profile/`, params, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      localStorage.setItem("mfa", params.mfa);
      localStorage.setItem("mfa_setup", params.mfa);
      dispatch({
        type: SET_MFA,
        status: SUCCESS,
        payload: {
          mfa_required: params.mfa,
          mfa_setup: params.mfa,
        },
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: SET_MFA,
        status: ERROR,
        payload: err.response?.data,
      });
    }
  };
}

export function deleteMFA({ current_password, code }) {
  return async (dispatch) => {
    dispatch({ type: DELETE_MFA, status: LOADING });

    const user_id = localStorage.getItem("user_id");

    const params = {
      operation: "DELETE",
      sub_operation: "",
      code,
      current_password,
    };
    try {
      let response = await Axios.post(`${ROOT_URL}/mfa/${user_id}/`, params, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      if (response.status === 202) {
        dispatch({ type: DELETE_MFA, status: SUCCESS });
        dispatch(
          setMFA({
            operation: "MFA",
            mfa: false,
          })
        );
      }
    } catch (err) {
      if (err.response.request.status === 400) {
        message.error(err.response?.data?.message || "Something went wrong");
      } else {
        checkAuthorisation(dispatch, err);
      }

      dispatch({
        type: DELETE_MFA,
        status: ERROR,
        payload: err.response?.data,
      });
    }
  };
}

export function resetProfilePassword({
  current_password,
  new_password,
  new_password_confirm,
}) {
  return async (dispatch) => {
    dispatch({ type: RESET_PROFILE_PASSWORD, status: LOADING });

    const params = {
      operation: "RESET_PASSWORD",
      current_password,
      new_password,
      new_password_confirm,
    };
    try {
      let response = await Axios.post(`${ROOT_URL}/profile/`, params, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      if (response.status === 200) {
        dispatch({
          type: RESET_PROFILE_PASSWORD,
          status: SUCCESS,
          payload: response?.data?.message,
        });
      }
    } catch (err) {
      if (err.response.request.status === 400) {
        message.error(
          err.response?.data?.error
            ? err.response?.data?.error
            : err.response?.data
        );
      } else {
        checkAuthorisation(dispatch, err);
      }

      dispatch({
        type: RESET_PROFILE_PASSWORD,
        status: ERROR,
        payload: err.response?.data,
      });
    }
  };
}
