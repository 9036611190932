import React, { Component } from "react";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Typography, Col, Modal, Button, Input, Row } from "antd";
import "../../../stylesheets/loginstylesheets/login.css";
import { connect } from "react-redux";
import { LOADING } from "../../../constants/commonConstants/generalConstants";
import { deleteMFA } from "../../../redux/actions/commonActions/mfa";

const { Title } = Typography;

class Disable2FAForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textCode: "",
      code: "",
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.props.deleteMFA({
          current_password: values?.current_password,
          code: values?.code,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { deleteMFAStatus } = this.props;

    if (deleteMFAStatus === "SUCCESS") {
      this.props.onSubmit();
    }

    return (
      <Modal
        open={this.props.visible}
        closable={false}
        footer={false}
        maskClosable={false}
        bodyStyle={{
          borderRadius: "24px",
        }}
        title={null}
        className="disable-2fa-modal"
      >
        <Row>
          <Col>
            <Title style={{ color: "#EB0013" }} level={4}>
              Disable Two Factor Authentication
            </Title>
            <hr />
            <p style={{ color: "black", fontSize: "16px" }}>
              Please verify authentication code before disabling two factor
              authentication.
            </p>
            <Form name="disable2FA" onSubmit={(e) => this.handleSubmit(e)}>
              <Form.Item name="current_password" label="Enter Current Password">
                {getFieldDecorator("current_password", {
                  rules: [
                    {
                      required: true,
                      message: "Current password is required.",
                    },
                  ],
                })(<Input.Password placeholder="Enter password" />)}
              </Form.Item>

              <Form.Item
                name="code"
                label="Enter code shown in your authenticator app"
                // validateStatus={deleteMFAStatus === 'ERROR' ? "error" : ""}
                // help={setMFAError}
              >
                {getFieldDecorator("code", {
                  rules: [
                    { required: true, message: "Code is required." },
                    { min: 6, max: 6, message: "Code must be of 6 digits " },
                  ],
                })(<Input type="number" placeholder="XXXXXX" maxLength={6} />)}
              </Form.Item>
              <Row
                style={{
                  margin: "12px 0px",
                }}
              >
                <Col className="alignR">
                  <Button onClick={(event) => this.props.onCloseModal(event)}>
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    // onClick={(event) => onDIs(event)}
                    style={{
                      margin: "0px 10px",
                      backgroundColor: "#EB0013",
                      borderColor: "#EB0013",
                      color: "white",
                    }}
                    htmlType="submit"
                    loading={deleteMFAStatus === LOADING}
                  >
                    Disable 2FA
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  deleteMFA,
};

function mapStateToProps(state) {
  const { deleteMFAStatus, setMFAError } = state.signInState;

  return {
    deleteMFAStatus,
    setMFAError,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(Disable2FAForm));
