//--------DOCUMENT UPLOAD: DOCUMENT FOR--------

//--------Trademarks--------
export const TM = 'TM';     //TradeMark
export const CTM = 'CTM';   //Custom trademark
export const ITM = 'ITM';   //International Trademark

//--------Patents--------
export const P = 'P';       //Patent
export const CP = 'CP';     //Custom Patent
export const IP = 'IP';     //International Patent