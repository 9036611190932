import React, { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Layout, Row, Col, Typography, Button, Input, Alert } from "antd";
import SignInPageImg from "../../assets/images/option-1.png";
import { changeStateValue_L1 } from "../../functions/commonFunctions/generalFunctions";
import { useDispatch, useSelector } from "react-redux";

// import "antd/dist/antd.css";

import { forgotPassword } from "../../redux/actions/commonActions/actions";
import SignInSider from "../signInComponents/SignInSider";
import {
  ERROR,
  SUCCESS,
} from "../../constants/commonConstants/generalConstants";
import { LOADING } from "./../../constants/commonConstants/generalConstants";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const selector = useSelector((state) => {
    return {
      Message: state.forgotState.Message,
      Status: state.forgotState.Status,
    };
  });

  const { Title, Text } = Typography;

  const { getFieldDecorator, validateFields } = props.form;

  const handleSubmit = async () => {
    await validateFields((err) => {
      if (!err) {
        dispatch(forgotPassword(email));
      }
    });
  };

  return (
    <div class="container-fluid">
      <div class="row login-row">
        <SignInSider />
        <div class="col-lg-6 col-sm-12">
          <Col sm={24} className="SignInForm">
            <div className="Sign-In-Form">
              <div className="Welcome-Heading">
                <Title style={{ fontSize: "22px", padding: "10px" }}>
                  Welcome to your AI powered legal associate
                </Title>
                <Form name="normal_login" className="login-form">
                  {(selector.Status === SUCCESS ||
                    selector.Status === ERROR) && (
                    <Form.Item className="Sign-In-Input-Field">
                      <Alert
                        message={selector.Message}
                        type={selector.Status.toLowerCase()}
                        showIcon
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    style={{
                      marginLeft: "15px",
                      marginTop: "15px",
                    }}
                  >
                    {getFieldDecorator("Email", {
                      rules: [
                        {
                          required: true,
                          message: "Email is required!",
                        },
                        {
                          required: false,
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ],
                    })(
                      <Input
                        type="text"
                        prefix={
                          <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    )}
                  </Form.Item>

                  <Form.Item style={{ marginLeft: "15px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button w-100"
                      loading={selector.Status === LOADING}
                      onClick={handleSubmit}
                    >
                      submit
                    </Button>
                    <br />

                    <Text>
                      Already a member ?
                      <Button type="link" href="/sign-in">
                        Login
                      </Button>
                    </Text>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
}

export default Form.create()(ForgotPassword);
