import { history } from "./../../history";

// const onRequest = (config) => {
//   return Promise.resolve(config);
// };

// const onRequestError = (error) => {
//   let status = error?.response?.status;
//   if (status === 401 || status === 403) {
//     localStorage.clear();
//     sessionStorage.clear();
//     history.push("/");
//     window.location.reload();
//   }
//   return Promise.reject(error);
// };

// const onResponse = (response) => {
//   return Promise.resolve(response);
// };

export const onResponseError = (error) => {
  let status = error?.response?.status;
  if (status === 401 || status === 403) {
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
    window.location.reload();
  }
  return;
  // return Promise.reject(error);
};

// export function setupInterceptorsTo(axiosInstance) {
//   axiosInstance.interceptors.request.use(onRequest, onRequestError);
//   axiosInstance.interceptors.response.use(onResponse, onResponseError);
//   return axiosInstance;
// }
