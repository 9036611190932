//--------LIBRARIES--------
import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";

//--------REDUCERS--------
import { rootReducerWrapper } from "./reducers";

const initialState = {};

const middleware = [thunk];
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
);
const store = createStore(
  rootReducerWrapper,
  initialState,
  enhancer,
  // applyMiddleware(...middleware)
);

export default store;
