import React from "react";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { getCompareMarks } from "../../../../redux/actions/watchActions/listDashboardActions";

const ComparativeReportFilter = ({
  filteredData,
  value = "",
  setValue,
  loading = false,
  searchValue,
  setSearchValue,
  handleSearchDebounced,
}) => {
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleSearch = (newValue) => {
    setSearchValue(newValue);
    handleSearchDebounced(newValue);
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (filteredData.data.length === filteredData.metadata.count) {
      return;
    }
    if (
      !loading &&
      scrollTop + clientHeight >= scrollHeight - 20 &&
      filteredData.data.length < filteredData.metadata.count
    ) {
      dispatch(getCompareMarks(filteredData.metadata.page + 1, searchValue));
    }
  };

  const selectMenu = () => (
    <>
      <Select
        showSearch
        placeholder={"Search Application no/Name"}
        optionFilterProp="children"
        onChange={handleChange}
        onSearch={handleSearch}
        style={{
          minWidth: "320px",
        }}
        allowClear
        dropdownStyle={{
          minWidth: "320px",
        }}
        onPopupScroll={handleScroll}
        loading={loading}
        value={value}
        options={filteredData.data}
        searchValue={searchValue}
        className="select-filter-dropdown"
        filterOption={(input, option) =>
          String(option?.label ?? "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      />
      <>{loading && "Loading..."}</>
    </>
  );

  return selectMenu();
};

export default ComparativeReportFilter;
