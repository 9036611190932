//--------Default Custom_Trademark--------
export const Custom_Trademark = {
    applied_for: '',
    associated_image: '',
    associated_class: '',
    country: '',
    status: '',
    type: '',
    category: '',
    application_office: '',
    reference_number: '',
    registration_number: '',
    registration_date: '',
    application_date: '',
    valid_upto: '',
    date_of_usage: '',
    proprietor: [{name: '', address: ''}],
    description: '',
}

//--------Default Custom_Patent--------
export const Custom_Patent = {
    title_of_invention: '',
    applicant: [{name: '', country: ''}],
    inventor: [{name: '', country: ''}],
    classification_ipc: [{name: '', code: ''}],
    application_type: '',
    field_of_invention: '',
    application_status: '',
    date_of_filing: '',
    renewal_date: '',
    first_examination_report_date: '',
    reply_to_fer_date: '',
    date_of_certificate_issue: '',
    pct_international_application_number: '',
    pct_international_filing_date: '',
    publication_date_us: '',
    priority_country: '',
    priority_date: '',
    request_for_examination_date: '',
    parent_application_filing_date: '',
    post_grant_journal_date: '',
}