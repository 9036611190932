//--------IP-SUITE CONSTANTS--------
import { PDF } from "../commonConstants/generalConstants";

//--------Default Fiters Visibility--------
export const J_defaultFiltersVisibility = {
  trademarkClassesMenu: false,
  proprietorsMenu: false,
  trademarkCategoriesMenu: false,
  trademarkOfficesMenu: false,
  trademarkTypesMenu: false,
};

//--------Default Selected Filters--------
export const J_defaultSelectedFilters = {
  trademarkTypes: [],
  trademarkClasses: [],
  trademarkCategories: [],
  trademarkOffices: [],
  proprietors: [],
  containsTerm: "",
  mark_desc_contains_term: "",
  searchType: "Application No/Name",
};

//--------Default Fiters Search Terms--------
export const J_defaultFiltersSearchTerms = {
  proprietors: "",
};

//--------Default Custom Report Data--------
export const J_defaultCustomReportData = {
  selectAll: false,
  mark: {},
  threats: [],
  threatsLength: 0,
  reportFormat: PDF,
  reportName: "",
  reportType: "download",
};
