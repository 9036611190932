//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import {
  AppstoreTwoTone,
  DashboardTwoTone,
  DownloadOutlined,
  ReconciliationTwoTone,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  Typography,
  Modal,
  Button,
  Card,
  Input,
  Alert,
} from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import { getTrademarkProfileData } from "../../../redux/actions/searchActions/commonActions";

//--------DATE FUNCTIONS--------
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { logOut } from "../../../redux/actions/commonActions/actions";
import Timeline from "../../managerComponents/subComponents/trademarkProfileComponents/Timeline";
import { standardizeDateTime } from "../../../functions/commonFunctions/dateFunctions";

const { Title, Text } = Typography;
const { TextArea } = Input;

class TrademarkProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      changeLogVisible: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (window.location.pathname === "/search/trademark-profile") {
        let application = sessionStorage.getItem("application");
        this.props.getTrademarkProfileData(false, application, false);
      } else {
        this.props.history.push(this.props.searchPath);
      }
    } else {
      this.props.logOut();
    }
  }

  handleProprietorProfile(data) {
    if (data) {
      sessionStorage.setItem("proprietor", data.id);
      window.open(`/search/proprietor-profile/${data.id}`);
      this.setState({
        proprietor_id: data.id,
      });
    }
  }

  showHistoryDetail(type) {
    Modal.info({
      title: "HISTORY/PR DETAILS:",
      width: 800,
      className: "mark-delete-modal",
      content: (
        <div>
          {this.props.trademarkData && (
            <div>
              {this.props.trademarkData.history && (
                <TextArea
                  value={this.props.trademarkData.history}
                  className="complete-specification-box vertical_scroll"
                  autosize={{ minRows: 5 }}
                />
              )}
            </div>
          )}
        </div>
      ),
      okText: "Close",
      // okType: '',
      onOk() {},
    });
  }

  showDescription() {
    Modal.info({
      title: "DESCRIPTION",
      width: 800,
      className: "mark-delete-modal",
      content: (
        <div>
          {this.props.trademarkData.description && (
            <div>
              <TextArea
                value={this.props.trademarkData.description}
                className="complete-specification-box vertical_scroll"
                autosize={{ minRows: 5 }}
              />
            </div>
          )}
        </div>
      ),
      okText: "Close",
      // okType: '',
      onOk() {},
    });
  }

  handleDownloadDocumentLink(data) {
    let link = "";
    link = data?.url;
    window.open(link, "_blank");
  }

  handleDownloadDocument(data) {
    let link = "";
    if (data?.file) {
      link = data?.file;
      window.open(link, "_blank");
    } else if (data?.url) {
      link = data?.url;
      window.open(link, "_blank");
    }
  }

  openExaReport(data) {
    if (data !== null) window.open(data, "_blank");
  }

  render() {
    const totalDocumentColumns = [
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        width: "60%",
      },

      {
        title: "DATE",
        dataIndex: "date",
        width: "25%",
      },

      {
        title: "DOWNLOAD",
        width: "15%",
        align: "center",
        render: (text, document) => {
          return document ? (
            <Button
              type="link"
              disabled={document.file || document.url ? false : true}
              style={{ fontSize: "20px" }}
              onClick={() => this.handleDownloadDocument(document)}
            >
              <DownloadOutlined style={{ position: "relative", top: "-3px" }} />
            </Button>
          ) : (
            ""
          );
        },
      },
    ];

    const correspondenceNoticeColumns = [
      {
        title: "DESCRIPTION",
        dataIndex: "subject",
        width: "35%",
      },

      {
        title: "DATE OF DISPATCH",
        dataIndex: "date_of_dispatch",
        width: "25%",
      },
      {
        title: "DATE OF CORRESPONDENCE",
        dataIndex: "date_of_correspondence",
        width: "25%",
      },

      {
        title: "VIEW",
        width: "15%",
        align: "center",
        render: (text, document) => {
          return document ? (
            <Button
              type="link"
              disabled={document.file || document.url ? false : true}
              style={{ fontSize: "20px" }}
              onClick={() => this.handleDownloadDocumentLink(document)}
            >
              <DownloadOutlined style={{ position: "relative", top: "-3px" }} />
            </Button>
          ) : (
            ""
          );
        },
      },
    ];

    const oppositionDocumentColumns = [
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        width: "60%",
      },

      {
        title: "DATE",
        dataIndex: "date",
        width: "25%",
      },

      {
        title: "VIEW",
        width: "15%",
        align: "center",
        render: (text, document) => {
          return document ? (
            <Button
              type="link"
              disabled={document.file || document.url ? false : true}
              style={{ fontSize: "20px" }}
              onClick={() => this.handleDownloadDocument(document)}
            >
              <DownloadOutlined style={{ position: "relative", top: "-3px" }} />
            </Button>
          ) : (
            ""
          );
        },
      },
    ];

    const correspondenceNoticeDocumentColumns = [
      {
        title: "CORRESPONDENCE NUMBER",
        dataIndex: "correspondence_number",
        className: "patent-column",
      },

      {
        title: "CORRESPONDENCE DATE",
        dataIndex: "date_of_correspondence",
        className: "patent-column",
      },

      {
        title: "SUBJECT",
        dataIndex: "subject",
        className: "patent-column",
      },

      {
        title: "DISPATCH NUMBER",
        dataIndex: "dispatch_number",
        className: "patent-column",
      },

      {
        title: "DISPATCH DATE",
        dataIndex: "date_of_dispatch",
        className: "patent-column",
      },

      {
        title: "VIEW",
        align: "center",
        render: (text, document) => {
          return document ? (
            <Button
              type="link"
              disabled={document.file || document.url ? false : true}
              style={{ fontSize: "20px" }}
              onClick={() => this.handleDownloadDocumentLink(document)}
            >
              <DownloadOutlined style={{ position: "relative", top: "-3px" }} />
            </Button>
          ) : (
            ""
          );
        },
      },
    ];

    return (
      <div
        className="vertical_scroll"
        style={{
          backgroundColor: "#ffffff",
          overflow: "auto",
          height: `${
            this.props.searchAlert.length > 0
              ? "calc(100vh - 125px)"
              : "calc(100vh - 85px)"
          }`,
          padding: "10px",
        }}
      >
        {/* Back-section */}
        <Row>
          {/* <Col span={4} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col> */}
          <Col span={24} className="alignR">
            <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
              *Data as on -{" "}
              {checkValue(this.props.trademarkData)
                ? standardizeDateTime(
                    this.props.trademarkData?.last_realtime_date,
                  )
                : "N.A."}
            </Text>
          </Col>
        </Row>
        {/* card-section */}
        <Row gutter={16} className="m-top-10">
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={
                this.props.trademarkDataStatus === LOADING ? true : false
              }
            >
              <Row>
                <Col span={4}>
                  <ReconciliationTwoTone style={{ fontSize: "35px" }} />
                </Col>
                <Col span={20}>
                  <Title level={4} style={{ fontSize: "14px" }}>
                    OPPOSITIONS/RECTIFICATIONS FILED
                  </Title>
                  <Text strong style={{ fontSize: "14px" }}>
                    {checkValue(this.props.trademarkData)
                      ? this.props.trademarkData.opposition_count
                      : ""}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={
                this.props.trademarkDataStatus === LOADING ? true : false
              }
            >
              <Row>
                <Col span={4}>
                  <AppstoreTwoTone style={{ fontSize: "35px" }} />
                </Col>
                <Col span={20}>
                  <Title level={4} style={{ fontSize: "14px" }}>
                    TRADEMARK TYPE
                  </Title>
                  <Text strong style={{ fontSize: "14px" }}>
                    {checkValue(this.props.trademarkData)
                      ? this.props.trademarkData.trademark_type
                      : ""}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={
                this.props.trademarkDataStatus === LOADING ? true : false
              }
            >
              <Row>
                <Col span={4}>
                  <DashboardTwoTone style={{ fontSize: "35px" }} />
                </Col>
                <Col span={20}>
                  <Title level={4} style={{ fontSize: "14px" }}>
                    TRADEMARK STATUS
                  </Title>
                  <Text strong style={{ fontSize: "14px" }}>
                    {checkValue(this.props.trademarkData)
                      ? this.props.trademarkData.status
                      : ""}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* card-section */}

        {/* TRADEMARK INFORMATION */}
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title="TRADEMARK INFORMATION"
              extra={
                <div>
                  {!this.props.realtimeData && (
                    <Button
                      type="primary"
                      onClick={() =>
                        this.props.application
                          ? this.props.getTrademarkProfileData(
                              false,
                              this.props.application,
                              true,
                            )
                          : null
                      }
                      loading={
                        this.props.realtimeDataStatus === LOADING ? true : false
                      }
                    >
                      {this.props.realtimeDataStatus === LOADING
                        ? "Getting Latest Information"
                        : "Get Latest Information"}
                    </Button>
                  )}
                  {this.props.realtimeDataStatus === SUCCESS &&
                    this.props.realtimeData && (
                      <Button
                        style={{ color: "#4285f4", backgroundColor: "#fff" }}
                      >
                        Information Updated
                      </Button>
                    )}
                </div>
              }
              headStyle={{ background: "#e8e8e8" }}
              loading={
                this.props.trademarkDataStatus === LOADING ? true : false
              }
            >
              <Col span={24}>
                {this.props.trademarkData.alert && (
                  <div className="alignL m-bot-20">
                    <Alert
                      className="trademark-alert-message"
                      message={this.props.trademarkData.alert}
                      type="error"
                    />
                  </div>
                )}
                <table className="trademark-information-table">
                  <tbody>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <p>NAME OF TRADEMARK:</p>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(
                                  this.props.trademarkData.applied_for,
                                )
                                  ? this.props.trademarkData.applied_for
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>TRADEMARK LOGO:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(
                                  this.props.trademarkData.associated_image,
                                ) ? (
                                  <span
                                    className="thumbnail"
                                    style={{ margin: "0px" }}
                                  >
                                    <img
                                      className="trademark-profile-logo"
                                      src={
                                        this.props.trademarkData
                                          .associated_image
                                      }
                                      alt="Trademark Logo Broken"
                                    />
                                  </span>
                                ) : (
                                  "N.A."
                                )}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>APPLICATION NUMBER:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(
                                  this.props.trademarkData.application_number,
                                )
                                  ? this.props.trademarkData.application_number
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>CLASSES:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData.associated_class && (
                            <div>
                              <p>
                                {this.props.trademarkData.associated_class.join(
                                  ", ",
                                )}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>STATUS:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(this.props.trademarkData.status)
                                  ? this.props.trademarkData.status
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>TRADEMARK TYPE:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        <div>
                          <p>
                            {this.props.trademarkData.trademark_type
                              ? this.props.trademarkData.trademark_type
                              : "N.A."}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>DATE OF APPLICATION:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(
                                  this.props.trademarkData.application_date,
                                )
                                  ? this.props.trademarkData.application_date
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>DATE OF USAGE:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {this.props.trademarkData.date_of_usage ==
                                null ? (
                                  <span>Proposed To Be Used</span>
                                ) : (
                                  <span>
                                    {this.props.trademarkData.date_of_usage}
                                  </span>
                                )}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>VALID UPTO:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(this.props.trademarkData.valid_upto)
                                  ? this.props.trademarkData.valid_upto
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>RESTRICTIONS:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(
                                  this.props.trademarkData.restrictions,
                                )
                                  ? this.props.trademarkData.restrictions
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>CONDITION:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(this.props.trademarkData.condition)
                                  ? this.props.trademarkData.condition
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>HISTORY/PR DETAILS:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(
                                  this.props.trademarkData.history,
                                ) ? (
                                  <span
                                    className="application-text"
                                    onClick={() =>
                                      this.showHistoryDetail("ipindia")
                                    }
                                  >
                                    Click Here to Show History/PR Details
                                  </span>
                                ) : (
                                  "N.A."
                                )}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>CATEGORY:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData.category && (
                            <div>
                              <p>
                                {checkValue(this.props.trademarkData.category)
                                  ? this.props.trademarkData.category
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                        {this.props.trademarkData &&
                          this.props.trademarkData &&
                          this.props.trademarkData.category &&
                          !this.props.trademarkData.category && (
                            <div>
                              <p></p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>APPROPRIATE OFFICE:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData &&
                          this.props.trademarkData.application_office && (
                            <div>
                              <p>
                                {checkValue(
                                  this.props.trademarkData.application_office,
                                )
                                  ? this.props.trademarkData.application_office
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>INTERNATIONAL REGISTRATION NUMBER:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(
                                  this.props.trademarkData
                                    .international_registration_number,
                                )
                                  ? this.props.trademarkData
                                      .international_registration_number
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>PUBLICATION DETAILS:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData.journal && (
                          <div>
                            <p>
                              <Text strong>Journal Number: </Text>
                              <Text>
                                {this.props.trademarkData.journal.number
                                  ? this.props.trademarkData.journal.number
                                  : "N.A."}
                              </Text>
                            </p>
                          </div>
                        )}
                        {this.props.trademarkData.journal &&
                          this.props.trademarkData.journal
                            .date_of_publication !== null && (
                            <div>
                              <p>
                                <Text strong>Journal Date: </Text>
                                <Text>
                                  {this.props.trademarkData.journal
                                    .date_of_publication
                                    ? this.props.trademarkData.journal
                                        .date_of_publication
                                    : "N.A."}
                                </Text>
                              </p>
                            </div>
                          )}
                        {this.props.trademarkData.journal && (
                          <div>
                            <p>
                              <Text strong>Journal Section: </Text>
                              <Text>
                                {this.props.trademarkData.journal_section
                                  ? this.props.trademarkData.journal_section
                                  : "N.A."}
                              </Text>
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>PROPRIETOR NAME:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData &&
                          this.props.trademarkData.proprietor &&
                          this.props.trademarkData.proprietor.length > 0 && (
                            <div>
                              <ul>
                                {this.props.trademarkData.proprietor.map(
                                  (propr) => (
                                    <li key={propr.id}>
                                      <Text
                                        className="application-text"
                                        onClick={() =>
                                          this.handleProprietorProfile(propr)
                                        }
                                      >
                                        {propr.name}
                                      </Text>
                                      {propr.trading_as && (
                                        <Text>
                                          {" "}
                                          trading as{" "}
                                          <strong>{propr.trading_as}</strong>
                                        </Text>
                                      )}
                                      <br />
                                      <Text>{propr.type_details}</Text>
                                      <br />
                                      <strong>Address: </strong>
                                      <Text>{propr.address}</Text>
                                    </li>
                                  ),
                                )}
                              </ul>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>ATTORNEY NAME:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(this.props.trademarkData.attorney)
                                  ? this.props.trademarkData.attorney.name
                                  : "N.A."}{" "}
                                (
                                {checkValue(this.props.trademarkData.attorney)
                                  ? this.props.trademarkData.attorney.code
                                  : "N.A."}
                                )
                              </p>
                              <p>
                                {checkValue(this.props.trademarkData.attorney)
                                  ? this.props.trademarkData.attorney.address
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="TP-Table-Data-35">
                        <div>
                          <p>DESCRIPTION:</p>
                        </div>
                      </td>
                      <td className="TP-Table-Data-65">
                        {this.props.trademarkData &&
                          this.props.trademarkData && (
                            <div>
                              <p>
                                {checkValue(
                                  this.props.trademarkData.description,
                                ) ? (
                                  <span
                                    className="application-text"
                                    onClick={() => this.showDescription()}
                                  >
                                    Click Here to Show Description
                                  </span>
                                ) : (
                                  "N.A."
                                )}
                              </p>
                            </div>
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Card>
          </Col>
        </Row>
        {/* TRADEMARK INFORMATION */}

        {/* Timeline */}
        {this.props.trademarkData && (
          <Timeline
            documents={
              this.props.trademarkData.documents
                ? this.props.trademarkData.documents
                : []
            }
            status={this.props.trademarkDataStatus}
            description="description"
          />
        )}
        {/* Timeline */}

        {/* DOCUMENT */}
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title="DOCUMENT"
              headStyle={{ background: "#e8e8e8" }}
              loading={
                this.props.trademarkDataStatus === LOADING ? true : false
              }
            >
              <Table
                rowKey={(record) => record.id}
                bordered
                dataSource={
                  this.props.trademarkData && this.props.trademarkData.documents
                }
                columns={totalDocumentColumns}
                pagination={false}
                scroll={{ y: "calc(70vh - 200px)" }}
              />
            </Card>
          </Col>
        </Row>
        {/* DOCUMENT */}

        {/* CORRESPONDENCE NOTICES */}
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title="CORRESPONDENCE NOTICES"
              headStyle={{ background: "#e8e8e8" }}
              loading={
                this.props.trademarkDataStatus === LOADING ? true : false
              }
            >
              <div>
                <Table
                  rowKey={(record) => record.id}
                  bordered
                  dataSource={
                    this.props.trademarkData &&
                    this.props.trademarkData.correspondence_notices
                  }
                  columns={correspondenceNoticeColumns}
                  pagination={false}
                  scroll={{ y: "calc(70vh - 200px)" }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        {/* CORRESPONDENCE NOTICES */}

        {/* OPPOSITION/RECTIFICATION DETAILS */}
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title="OPPOSITION/RECTIFICATION DETAILS"
              headStyle={{ background: "#e8e8e8" }}
              loading={
                this.props.trademarkDataStatus === LOADING ? true : false
              }
            >
              {this.props.trademarkData.opposition_documents &&
                this.props.trademarkData.opposition_documents.length > 0 &&
                this.props.trademarkData.opposition_documents.map((doc) => (
                  <Card
                    style={{ marginTop: 10 }}
                    type="inner"
                    title={
                      checkValue(doc.detail) ? (
                        <div style={{ background: "#FAFAFA" }}>
                          <Text style={{ fontSize: "16px", margin: "0px" }}>
                            {doc.detail.number}
                          </Text>
                        </div>
                      ) : (
                        ""
                      )
                    }
                  >
                    {checkValue(doc.detail) ? (
                      <div>
                        <div>
                          <table className="patent-information-table">
                            <tbody>
                              <tr>
                                <td style={{ border: "0" }}>
                                  <Text strong>Opponent Name:</Text>
                                </td>
                                <td style={{ border: "0" }}>
                                  <Text>
                                    {checkValue(doc.detail.opponent_name)
                                      ? doc.detail.opponent_name
                                      : "N.A."}
                                  </Text>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ border: "0" }}>
                                  <Text strong>Opponent Address:</Text>
                                </td>
                                <td style={{ border: "0" }}>
                                  <Text>
                                    {checkValue(doc.detail.opponent_address)
                                      ? doc.detail.opponent_address
                                      : "N.A."}
                                  </Text>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ border: "0" }}>
                                  <Text strong>Opposition Date:</Text>
                                </td>
                                <td style={{ border: "0" }}>
                                  <Text>
                                    {checkValue(doc.detail.date)
                                      ? doc.detail.date
                                      : "N.A."}
                                  </Text>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ border: "0" }}>
                                  <Text strong>Decision:</Text>
                                </td>
                                <td style={{ border: "0" }}>
                                  <Text>
                                    {checkValue(doc.detail.decision)
                                      ? doc.detail.decision
                                      : "N.A."}
                                  </Text>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ border: "0" }}>
                                  <Text strong>Status:</Text>
                                </td>
                                <td style={{ border: "0" }}>
                                  <Text>
                                    {checkValue(doc.detail.status)
                                      ? doc.detail.status
                                      : "N.A."}
                                  </Text>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="m-top-20">
                          <Table
                            rowKey={(record) => record.id}
                            bordered
                            dataSource={doc.detail.documents}
                            columns={oppositionDocumentColumns}
                            pagination={false}
                            scroll={{ y: "calc(70vh - 200px)" }}
                          />
                        </div>

                        <div className="m-top-20">
                          <Table
                            rowKey={(record) => record.id}
                            bordered
                            dataSource={doc.detail.correspondence_notices}
                            columns={correspondenceNoticeDocumentColumns}
                            pagination={false}
                            scroll={{ y: "calc(70vh - 200px)" }}
                          />
                        </div>

                        {doc.detail.documents.length === 0 &&
                          doc.detail.correspondence_notices.length === 0 && (
                            <div className="alignC">
                              <Text style={{ fontSize: "18px" }}>
                                No Opposition/Rectification Details Found
                              </Text>
                            </div>
                          )}
                      </div>
                    ) : (
                      <div className="alignC">
                        <Text style={{ fontSize: "18px" }}>
                          No Opposition/Rectification Details Found
                        </Text>
                      </div>
                    )}
                  </Card>
                ))}
              {this.props.trademarkData.opposition_documents &&
                this.props.trademarkData.opposition_documents.length === 0 && (
                  <div className="alignC">
                    <Text style={{ fontSize: "18px" }}>
                      No Opposition/Rectification Details Found
                    </Text>
                  </div>
                )}
            </Card>
          </Col>
        </Row>
        {/* OPPOSITION/RECTIFICATION DETAILS */}

        {/* MISCELLANEOUS DOCUMENTS */}
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title="MISCELLANEOUS DOCUMENTS"
              headStyle={{ background: "#e8e8e8" }}
              loading={
                this.props.trademarkDataStatus === LOADING ? true : false
              }
            >
              {this.props.trademarkData && (
                <Col span={24}>
                  {checkValue(this.props.trademarkData.examination_report) ? (
                    <div>
                      <table className="patent-information-table">
                        <tbody>
                          <tr>
                            <td>EXAMINATION REPORT</td>
                            <td className="alignR">
                              <Button
                                type="link"
                                style={{ fontSize: "20px" }}
                                onClick={() =>
                                  this.openExaReport(
                                    this.props.trademarkData.examination_report,
                                  )
                                }
                              >
                                <DownloadOutlined
                                  style={{ position: "relative", top: "-3px" }}
                                />
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="alignC">
                      <Text style={{ fontSize: "18px" }}>
                        No Miscellaneous Document Found
                      </Text>
                    </div>
                  )}
                </Col>
              )}
            </Card>
          </Col>
        </Row>
        {/* MISCELLANEOUS DOCUMENTS */}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getTrademarkProfileData,
  logOut,
};

function mapStateToProps(state) {
  const {
    trademarkData,
    trademarkDataStatus,
    realtimeData,
    realtimeDataStatus,
    application,
  } = state.searchState.trademarkProfile;

  return deepFreeze({
    trademarkData,
    trademarkDataStatus,
    realtimeData,
    realtimeDataStatus,
    application,
    searchPath: state.searchState.searchBar.searchPath,
    searchAlert: state.alertMessages.search,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(TrademarkProfile);
