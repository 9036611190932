//--------LIBRARIES--------
import Axios from 'axios';

//--------ROOT_CONFIG--------
import { MGR_RT_URL } from '../../../../configs/rootConfigs';

//--------IP-SUITE CONSTANTS--------
import { LOADING, ERROR, SUCCESS } from '../../../../constants/commonConstants/generalConstants';

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from '../../commonActions/actions';
import { 
    WORKFLOW_KEY_VALUE,
    GET_WORKFLOW,
    CREATE_WORKFLOW,
    DELETE_WORKFLOW,
    EDIT_WORKFLOW,
    GET_WORKFLOW_DETAILS,
} from '../workflow/types';

//--------Workflow: workflow key-value--------
export function getWorkflowKeyValue(){
    return async (dispatch)=> {
        dispatch({ type: WORKFLOW_KEY_VALUE, status: LOADING});
        try {
            const response = await Axios.get(`${MGR_RT_URL}/workflow/key_values/ `, {
                headers: { 
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            });
            dispatch({ 
                type: WORKFLOW_KEY_VALUE, 
                status: SUCCESS, 
                payload: response?.data,
            });

        } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: WORKFLOW_KEY_VALUE, status: ERROR }) 
        }
    }
}

//--------Workflow: get all workflows--------
export function getWorkflow({pageNumber}){
    return async (dispatch)=> {
        dispatch({ type: GET_WORKFLOW, status: LOADING});
        try {
            const response = await Axios.get(`${MGR_RT_URL}/workflows/`, {
                params: { page: pageNumber },
                headers: { Authorization: 'Token ' + localStorage.getItem('token')}
            });
            dispatch({ 
                type: GET_WORKFLOW, 
                status: SUCCESS, 
                payload: response?.data,
                pageNumber: pageNumber
            });

        } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_WORKFLOW, status: ERROR }) 
        }
    }
}

//--------Workflow: create workflows--------
export function createWorkflow({ title, actions, applied_on, trigger_task, key, values }) {
    return async (dispatch) => {
        dispatch({ type: CREATE_WORKFLOW, status: LOADING });
        try {
            let response;
            response = await Axios.post(`${MGR_RT_URL}/workflows/`, 
            { title, actions, applied_on, trigger_task, key, values }, 
            {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ 
                type: CREATE_WORKFLOW, 
                status: SUCCESS, 
                payload: response?.data,  
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: CREATE_WORKFLOW, status: ERROR })
        }
    }
}

//--------Workflow: delete workflows--------
export function deleteWorkflow({ workflow_ids }) {
    return async (dispatch) => {
        dispatch({ type: DELETE_WORKFLOW, status: LOADING });
        try {
            let response;
            response = await Axios.delete(`${MGR_RT_URL}/workflows/`, {
                data: { workflow_ids },
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ 
                type: DELETE_WORKFLOW, 
                status: SUCCESS, 
                payload: response?.data, 
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: DELETE_WORKFLOW, status: ERROR })
        }
    }
}

//--------Workflow: edit workflows--------
export function editWorkflow({ workflow_id, title, actions, applied_on, trigger_task, key, values }) {
    return async (dispatch) => {
        dispatch({ type: EDIT_WORKFLOW, status: LOADING });
        try {
            let response;
            response = await Axios.patch(`${MGR_RT_URL}/workflows/`, 
            { workflow_id, title, actions, applied_on, trigger_task, key, values }, 
            {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ 
                type: EDIT_WORKFLOW, 
                status: SUCCESS, 
                payload: response?.data,  
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: EDIT_WORKFLOW, status: ERROR })
        }
    }
}

//--------Workflow: get workflows details--------
export function getWorkflowDetails({workflow_id}){
    return async (dispatch)=> {
        dispatch({ type: GET_WORKFLOW_DETAILS, status: LOADING});
        try {
            const response = await Axios.get(`${MGR_RT_URL}/workflow/`, {
                params: { workflow_id },
                headers: { Authorization: 'Token ' + localStorage.getItem('token')}
            });
            dispatch({ 
                type: GET_WORKFLOW_DETAILS, 
                status: SUCCESS, 
                payload: response?.data,
            });

        } catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: GET_WORKFLOW_DETAILS, status: ERROR }) 
        }
    }
}

//--------Send Workflow Email: send workflow email --------
export function sendWorkflowEmail({
    actionType,
    workflow_id,
    send_email_after_these_days,
    maximum_send_emails_limit,
    days_between_two_emails,
    timing_of_email,
    active,
    action,
    file,
    send_to_based_on_mark,
    send_from,
    send_to,
    cc,
    mail_subject,
    mail_body,
    email_template
}) {
    let formData = new FormData();
    formData.append('workflow_id', workflow_id)
    formData.append('send_email_after_these_days', send_email_after_these_days)
    formData.append('maximum_send_emails_limit', maximum_send_emails_limit)
    formData.append('days_between_two_emails', days_between_two_emails)
    formData.append('timing_of_email', timing_of_email)
    formData.append('active', active)
    formData.append('action', action)
    formData.append('file_name', file.file_name)
    formData.append('file_attachment', file.file_attachment)
    formData.append('send_to_based_on_mark', send_to_based_on_mark)
    formData.append('send_from', send_from)
    formData.append('send_to', send_to)
    formData.append('cc', cc)
    formData.append('mail_subject', mail_subject)
    formData.append('mail_body', mail_body)
    formData.append('email_template', email_template)
    return async (dispatch) => {
        dispatch({ type: actionType, status: LOADING });
        try {
            let response;
            response = await Axios.post(`${MGR_RT_URL}/workflow/`, formData, {
                headers: { 
                    Authorization: 'Token ' + localStorage.getItem('token'), 
                    'Content-type': 'multipart/form-data' 
                }
            });
            dispatch({ 
                type: actionType, 
                status: SUCCESS, 
                payload: response?.data 
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: actionType, status: ERROR, payload: err.response?.data })
        }
    }
} 

//--------Edit Workflow Email: edit workflow email --------
export function editWorkflowEmail({
    actionType,
    task_id,
    send_email_after_these_days,
    maximum_send_emails_limit,
    days_between_two_emails,
    timing_of_email,
    active,
    action,
    file,
    send_to_based_on_mark,
    send_from,
    send_to,
    cc,
    mail_subject,
    mail_body,
    email_template
}) {
    let formData = new FormData();
    formData.append('task_id', task_id)
    formData.append('send_email_after_these_days', send_email_after_these_days)
    formData.append('maximum_send_emails_limit', maximum_send_emails_limit)
    formData.append('days_between_two_emails', days_between_two_emails)
    formData.append('timing_of_email', timing_of_email)
    formData.append('active', active)
    formData.append('action', action)
    formData.append('file_name', file.file_name)
    formData.append('file_attachment', file.file_attachment)
    formData.append('send_to_based_on_mark', send_to_based_on_mark)
    formData.append('send_from', send_from)
    formData.append('send_to', send_to)
    formData.append('cc', cc)
    formData.append('mail_subject', mail_subject)
    formData.append('mail_body', mail_body)
    formData.append('email_template', email_template)
    return async (dispatch) => {
        dispatch({ type: actionType, status: LOADING });
        try {
            let response;
            response = await Axios.patch(`${MGR_RT_URL}/workflow/`, formData, {
                headers: { 
                    Authorization: 'Token ' + localStorage.getItem('token'), 
                    'Content-type': 'multipart/form-data' 
                }
            });
            dispatch({ 
                type: actionType, 
                status: SUCCESS, 
                payload: response?.data 
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: actionType, status: ERROR, payload: err.response?.data })
        }
    }
} 

//--------Delete Workflow: delete workflows--------
export function deleteWorkflowTask({ actionType, task_id, action }) {
    return async (dispatch) => {
        dispatch({ type: actionType, status: LOADING });
        try {
            let response;
            response = await Axios.delete(`${MGR_RT_URL}/workflow/`, {
                data: { task_id, action },
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ 
                type: actionType, 
                status: SUCCESS, 
                payload: response?.data, 
                taskId: task_id,  
                taskType: action
            });
        }
        catch (err) {
            console.log('err', err);
            checkAuthorisation(dispatch, err);
            dispatch({ type: actionType, status: ERROR })
        }
    }
}

//-------- Workflow Task: create workflow Task --------
export function createWorkflowTask({
    actionType,
    workflow_id,
    due_date_after_these_days,
    maximum_reminder,
    timing_of_reminder,
    days_between_two_reminders,
    create_reminder_after_these_days,
    active,
    action,
    title,
    description,
    priority,
    emails
}) {
    return async (dispatch) => {
        dispatch({ type: actionType, status: LOADING });
        try {
            let response;
            response = await Axios.post(`${MGR_RT_URL}/workflow/`, {
                workflow_id,
                due_date_after_these_days,
                maximum_reminder,
                timing_of_reminder,
                days_between_two_reminders,
                create_reminder_after_these_days,
                active,
                action,
                title,
                description,
                priority,
                emails
            }, {
                headers: { 
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            });
            dispatch({ 
                type: actionType, 
                status: SUCCESS, 
                payload: response?.data 
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: actionType, status: ERROR, payload: err.response?.data })
        }
    }
} 

//--------Edit Workflow Email: edit workflow email --------
export function editWorkflowTask({
    actionType,
    task_id,
    due_date_after_these_days,
    maximum_reminder,
    timing_of_reminder,
    days_between_two_reminders,
    create_reminder_after_these_days,
    active,
    action,
    title,
    description,
    priority,
    emails
}) {
    return async (dispatch) => {
        dispatch({ type: actionType, status: LOADING });
        try {
            let response;
            response = await Axios.patch(`${MGR_RT_URL}/workflow/`, {
                task_id,
                due_date_after_these_days,
                maximum_reminder,
                timing_of_reminder,
                days_between_two_reminders,
                create_reminder_after_these_days,
                active,
                action,
                title,
                description,
                priority,
                emails
            }, {
                headers: { 
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            });
            dispatch({ 
                type: actionType, 
                status: SUCCESS, 
                payload: response?.data 
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: actionType, status: ERROR, payload: err.response?.data })
        }
    }
} 