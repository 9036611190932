import React, { useState } from "react";
import { Menu, Typography, Input, Checkbox } from "antd";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { TagsTwoTone, StopOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const { Text } = Typography;
const { Search } = Input;

const SearchFilterSearchBasic = ({
  data,
  searchFilters,
  search_key,
  filter_key,
  selectedFilters,
  onSelect,
  type,
  noSearch,
}) => {
  const [page, setPage] = useState(10);
  let filteredData = [];
  if (type === "object" && checkValue(type)) {
    filteredData = data.filter((obj) =>
      checkValue(obj)
        ? obj?.label
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchFilters?.[search_key]?.toLowerCase())
        : ""
    );
  } else if ((type === "name" || type === "proprietors") && checkValue(type)) {
    filteredData = data.filter((obj) =>
      checkValue(obj)
        ? obj?.name
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchFilters?.[search_key]?.toLowerCase())
        : ""
    );
  } else if (type === "number") {
    filteredData = data.filter((obj) =>
      checkValue(obj)
        ? obj?.number
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchFilters?.[search_key]?.toLowerCase())
        : ""
    );
  } else {
    filteredData = data.filter((eachEntity) =>
      checkValue(eachEntity)
        ? eachEntity
            ?.toString()
            ?.toLowerCase()
            .includes(searchFilters?.[search_key]?.toLowerCase())
        : ""
    );
  }

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  let itemsList = filteredData?.slice(0, page).map((entity) =>
    getItem(
      <Text className="text-camelCase" style={{ fontWeight: "400" }}>
        {(type === "name" && (
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            {entity?.name?.toString().toLowerCase()}
          </Text>
        )) ||
          (type === "proprietors" && (
            <>
              <Tooltip placement="left" title="Exclude">
                <StopOutlined />
              </Tooltip>{" "}
              <Text className="text-camelCase" style={{ fontWeight: "400" }}>
                {entity?.name?.toString().toLowerCase()}
              </Text>
            </>
          )) ||
          (type === "object" && (
            <Text className="text-camelCase" style={{ fontWeight: "400" }}>
              {entity?.label?.toString().toLowerCase()}
            </Text>
          )) ||
          entity.toString().toLowerCase()}
      </Text>,
      (type === "object" && entity?.label) ||
        ((type === "name" || type == "proprietors" || type === "number") &&
          entity?.id) ||
        entity,
      null
    )
  );

  return (
    <>
      {noSearch ? (
        ""
      ) : (
        <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
          <Search
            placeholder="Search Type"
            onChange={(event) =>
              onSelect("filtersSearchTerms", search_key, event.target.value)
            }
            value={searchFilters?.[search_key]}
            allowClear
          />
        </div>
      )}
      <Menu
        onScroll={(x) => {
          const { scrollTop, scrollHeight, clientHeight } = x.target;
          if (scrollTop + clientHeight >= scrollHeight - 20) {
            setPage(page + 50);
          }
        }}
        multiple={true}
        selectable
        items={itemsList}
        selectedKeys={selectedFilters?.[filter_key]}
        onSelect={(event) =>
          onSelect("selectedFilters", filter_key, event.selectedKeys)
        }
        onDeselect={(event) =>
          onSelect("selectedFilters", filter_key, event.selectedKeys)
        }
      />
    </>
  );
};

export default SearchFilterSearchBasic;
