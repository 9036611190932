import { InboxOutlined } from "@ant-design/icons";
import { Button, Form, Modal, notification, Upload } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addClientAttachmentData } from "../../../../../redux/actions/commonActions/masterActions/clientAttachment";

const { Dragger } = Upload;

const ClientAddAttachmentComponent = ({ open, setOpen, filters, type }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const { metadata } = useSelector(
    (state) => state.masters.clientMaster.clientAttachment,
  );

  const { pageNumber } = metadata;

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setFile(null);
  };

  const handleSubmit = (values) => {
    if (!file) {
      notification["error"]({
        message: "Please upload a file!",
      });
      return;
    }

    const formData = new FormData();
    formData.append("client_id", filters?.client_id);
    formData.append("attachment", file);
    setUploading(true);
    dispatch(
      addClientAttachmentData(formData, pageNumber, filters?.client_id, {
        onSuccess: (successResponse) => {
          onClose();
          setUploading(false);
          notification["success"]({
            message: successResponse?.message ?? "File Added Successfully!",
          });
        },
      }),
    );
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      const isFileSize = file.size / 1024 / 1024 < 30;
      if (!isFileSize) {
        notification["error"]({
          message: "File must be smaller than 30MB!",
        });
        return false;
      }
      setFile(file);
      return false;
    },
    fileList: file ? [file] : [],
    onRemove: () => {
      setFile(null);
    },
    accept: ".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt",
  };

  return (
    <Modal
      title={"Add Attachment"}
      centered
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" type="default" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={uploading}
          type="primary"
          onClick={() => form.submit()}
        >
          {"Add"}
        </Button>,
      ]}
      bodyStyle={{ maxHeight: "65vh", overflowY: "auto" }}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ margin: "15px 10px" }}
        onFinish={handleSubmit}
        initialValues={""}
      >
        <div className="text-editor">
          <Form.Item
            name="attachment"
            valuePropName="file"
            getValueFromEvent={(e) => e.file}
            rules={[
              {
                required: true,
                message: "Please upload a file!",
                validator: (_, value) =>
                  file
                    ? Promise.resolve()
                    : Promise.reject("Please upload a file!"),
              },
            ]}
          >
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Support for a single upload.</p>
            </Dragger>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ClientAddAttachmentComponent;
