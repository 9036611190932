//--------LIBRARIES--------
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "antd";
import MikeContainer from "./../../CommonComponents/Layout/MikeContainer";
import dayjs from "dayjs";
import { date_format } from "../../watchFilter/utils/utils";
import PrimaryGridFilter from "../../watchFilter/newCommonComponent/GridLayout/PrimaryFilter";
import Content from "./Components/Content";
import { initiateDefaultTemporaryThreatFilters } from "../../../../redux/actions/watchActions/watchActions";
import Tour from "reactour";
import { addGridSampleData } from "../../../../redux/actions/watchActions/gridDashboardActions";

const GridLayoutImageLabel = () => {
  let urlKey = window.location.pathname.split("/")[2] || "";
  let dashboardLayout = window.location.pathname.split("/")[3] || "";
  const dispatch = useDispatch();

  const { journalNumber, isDataPresent } = useSelector(
    (state) => state.watchState.gridLayoutData[dashboardLayout][urlKey].config,
  );

  const { journalConfig } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const [showHeader, setShowHeader] = useState(true);

  const [journal, setJournal] = useState(journalNumber?.[`${urlKey}`] || "");
  const [tmTypes, setTMTypes] = useState([]);
  const [tmCategories, setTMCategories] = useState([]);
  const [tmOffices, setTMOffices] = useState([]);
  const [searchType, setSearchType] = useState("Application No/Name");
  const [searchValue, setSearchValue] = useState("");
  const [primaryFilterApplied, setPrimaryFilterApplied] = useState(false);
  const [proprietors, setProprietors] = useState([]);
  const [excludeMarkProprietors, setExcludeMarkProprietors] = useState([]);
  const [tmClass, setTMClass] = useState([]);
  const [yourDateOfApplication, setYourDateOfApplication] = useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [yourDateOfUsage, setYourDateOfUsage] = useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [yourDateOfUsageProposed, setYourDateOfUsageProposed] = useState(false);
  const [sameClassInfringement, setSameClassInfringement] = useState(false);
  const [excludeSameClassInfringement, setExcludeSameClassInfringement] =
    useState(false);
  const [publishedDate, setPublishedDate] = useState("");

  const [selectedData, setSelectedData] = useState({
    select_all: false,
    threatsCount: 0,
    selectAllCount: 0,
    filters: {},
    journalNumber: journalConfig?.data[0]?.value ?? "",
    data: {},
  });

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [openGrid, setOpenGrid] = useState(false);
  const [actionDropDown, setActionDropdown] = useState(false);
  const [viewSwitch, setViewSwitch] = useState(false);

  const [showSelectIcons, setshowSelectIcons] = useState(false);
  const [showMoreFilter, setShowMoreFilterDropdown] = useState(false);

  const handleEmptySelectedData = () => {
    setSelectedData({
      select_all: false,
      threatsCount: 0,
      selectAllCount: 0,
      filters: {},
      journalNumber: journalConfig?.data[0]?.value ?? "",
      data: {},
    });
  };

  // AND - OR  states
  const [andOrClass, setAndOrClass] = useState(false);

  const getRealSearchParameters = () => {
    const defaultSearchParameters = {
      contains_term: {
        value: null,
      },
      mark_desc_contains_term: {
        value: null,
      },
    };

    switch (searchType) {
      case "Application No/Name":
        return Object.assign({}, defaultSearchParameters, {
          contains_term: {
            value: searchValue,
          },
        });
      case "Your Mark Description Search":
        return Object.assign({}, defaultSearchParameters, {
          mark_desc_contains_term: {
            value: searchValue,
          },
        });
      default:
        return defaultSearchParameters;
    }
  };

  const generateFilterObject = () => {
    const filters = {
      mark_classes: {
        value: tmClass,
        logic: andOrClass ? "AND" : "OR",
      },
      mark_types: {
        value: tmTypes,
      },
      mark_proprietors: {
        value: proprietors,
      },
      mark_categories: {
        value: tmCategories,
      },
      mark_offices: {
        value: tmOffices,
      },
      mark_date_of_application: {
        value: {
          from: yourDateOfApplication.from
            ? dayjs(yourDateOfApplication.from).format(date_format)
            : "",
          to: yourDateOfApplication.to
            ? dayjs(yourDateOfApplication.to).format(date_format)
            : "",
        },
      },
      mark_date_of_usage: {
        value: {
          from: yourDateOfUsage.from
            ? dayjs(yourDateOfUsage.from).format(date_format)
            : "",
          to: yourDateOfUsage.to
            ? dayjs(yourDateOfUsage.to).format(date_format)
            : "",
        },
      },
      mark_date_of_usage_proposed: {
        value: yourDateOfUsageProposed,
      },
      red_band: {
        value: sameClassInfringement,
      },
      exclude_same_class: {
        value: excludeSameClassInfringement,
      },
      exclude_mark_proprietors: {
        value: excludeMarkProprietors,
      },
      ...getRealSearchParameters(),
    };
    return filters;
  };

  React.useEffect(() => {
    if (journalNumber?.[urlKey] === null) {
      setJournal(journalConfig?.data[0]?.value ?? "");
    }
  }, [journalConfig, journalNumber]);

  React.useEffect(() => {
    dispatch(initiateDefaultTemporaryThreatFilters());
  }, []);

  const isAnyPrimaryFilterSelected = (filterObject) => {
    const {
      contains_term,
      exclude_mark_proprietors,
      exclude_same_class,
      mark_categories,
      mark_classes,
      mark_date_of_application,
      mark_date_of_usage,
      mark_date_of_usage_proposed,
      mark_desc_contains_term,
      mark_offices,
      mark_proprietors,
      mark_types,
      red_band,
    } = filterObject;

    let anyPrimaryFilterSelected =
      contains_term?.value ||
      exclude_mark_proprietors?.value.length ||
      exclude_same_class?.value ||
      mark_categories?.value.length ||
      mark_classes?.value.length ||
      mark_date_of_application?.value.from ||
      mark_date_of_application?.value.to ||
      mark_date_of_usage?.value.from ||
      mark_date_of_usage?.value.to ||
      mark_date_of_usage_proposed?.value ||
      mark_desc_contains_term.value?.length ||
      mark_offices?.value.length ||
      mark_proprietors.value?.length ||
      mark_types?.value.length ||
      red_band?.value;
    return Boolean(anyPrimaryFilterSelected);
  };

  let primaryFilterSelected = isAnyPrimaryFilterSelected(
    generateFilterObject(),
  );

  const propsForFilters = {
    showHeader,
    tmTypes,
    setTMTypes,
    tmCategories,
    setTMCategories,
    tmOffices,
    setTMOffices,
    journal,
    setJournal,
    searchType,
    setSearchType,
    searchValue,
    setSearchValue,
    proprietors,
    setProprietors,
    tmClass,
    setTMClass,
    yourDateOfApplication,
    setYourDateOfApplication,
    yourDateOfUsage,
    setYourDateOfUsage,
    yourDateOfUsageProposed,
    setYourDateOfUsageProposed,
    excludeMarkProprietors,
    setExcludeMarkProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    publishedDate,
    setPublishedDate,
    primaryFilterApplied,
    setPrimaryFilterApplied,
    andOrClass,
    setAndOrClass,
    primaryFilterSelected,
    generateFilterObject,
    selectedData,
    setSelectedData,
    handleEmptySelectedData,
    isTourOpen,
    setIsTourOpen,
    viewSwitch,
  };

  const propsForHeaderList = {
    selectedData,
    setSelectedData,
    generatePrimaryFilterObject: generateFilterObject,
    openGrid,
    actionDropDown,
    isAnyPrimaryFilterSelected,
  };

  const Filters = (
    <header className="General-Header">
      <Row>
        <Col span={24}>
          <PrimaryGridFilter {...propsForFilters} />
        </Col>
      </Row>
    </header>
  );

  const content = (
    <Content
      journal={journal}
      setShowHeader={setShowHeader}
      showHeader={showHeader}
      viewSwitch={viewSwitch}
      showMoreFilter={showMoreFilter}
      showSelectIcons={showSelectIcons}
      {...propsForHeaderList}
    />
  );

  const getTakeTourInfo = () => {
    if (urlKey === "image_label") {
      return "Image Label";
    } else if (urlKey === "tm_applied") {
      return "TM Applied";
    } else if (urlKey === "image_text" || urlKey === "image_text_new") {
      return "Image Text";
    } else if (urlKey === "tm_protect") {
      return "TM Protect";
    }
  };

  const steps = [
    {
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Welcome to MikeTM Watch's '{getTakeTourInfo()}' Analysis!
          </div>
          <br />
          <p style={{ color: "white" }}>
            We have updated the dashboard of MikeTM Watch. This quick tour will
            explain the updates that we have done.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    urlKey !== "tm_protect"
      ? {
          selector: "#grid-mark-filters",
          content: (
            <div>
              <div
                style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
              >
                Your Mark Filters
              </div>
              <br />
              <p style={{ color: "white" }}>
                You can access Your Mark Filters from the table itself. This is
                helpful to see infringements in specific marks.
              </p>
            </div>
          ),
          style: {
            backgroundColor: "#1F3EAA",
            borderRadius: "4px",
          },
        }
      : null,
    {
      selector: "#threat-view-btn",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Total Threats
          </div>
          <br />
          <p style={{ color: "white" }}>
            This indicates number of potential infringements identified by
            MikeTM Watch. You can click on this number to see details of the
            potential infringements.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setOpenGrid(true);
        setViewSwitch(true);
      },
    },
    {
      selector: "#grid-card-table-view-switch",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Multiple Views
          </div>
          <br />
          <p style={{ color: "white" }}>
            We have provided Card View and Table View to enable ease of use and
            access of more data points as per your convenience.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },

    {
      selector: "#threat-table-sendmail",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Send E-mail
          </div>
          <br />
          <p style={{ color: "white" }}>
            You can now send email from our Grid Layout as well using templates.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      // selector: "#checkable-tag",
      selector: "#grid_good_and_services_btn",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Goods and Services
          </div>
          <br />
          <p style={{ color: "white" }}>
            In table view you can see Goods and Services Description by clicking
            here.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },

    {
      selector: "#update_shortlist_menu",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Update shortlist
          </div>
          <br />
          <p style={{ color: "white" }}>
            Use this icon to add or remove shortlisted potential infringements.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setshowSelectIcons(true);
        setActionDropdown(true);
      },
    },

    {
      selector: "#tm_applied_ll_take_tour_step3",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Shortlist Threats
          </div>
          <br />
          <p style={{ color: "white" }}>
            If you wish to review potential infringements later then you can
            quickly shortlist some of the potential infringements and later can
            revisit and finalise those against which actions need to be taken.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: "#threatlist_selection_cols",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Shortlisted and Reported Icons
          </div>
          <br />
          <p style={{ color: "white" }}>
            The star icon indicates that this mark is shortlisted, whereas the
            eye icon indicates that a custom report has been created for this
            mark. You can remove a mark from the shortlist but cannot remove the
            eye mark because a report has already been created of that mark. You
            can remove a mark from shortlist but if a mark has been added in a
            report then eye mark will appear in the dashboard and cannont be
            removed.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setActionDropdown(false);
        setShowMoreFilterDropdown(true);
      },
    },

    {
      selector: ".tmapplied_ll_filter_template",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Filter template
          </div>
          <br />
          <p style={{ color: "white" }}>
            In this Filters & Template, you can access advanced filters and save
            the most commonly used filters as templates to simplify the process
            and save time.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: ".tmapplied_ll_journal_dropdown",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Select {urlKey !== "tm_protect" ? "Journal" : "Date Range"}
          </div>
          <br />
          <p style={{ color: "white" }}>
            This dropdown shows the{" "}
            {urlKey !== "tm_protect" ? "journal" : "date range"} for which
            analysis is shown on the dashboard.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },

      action: () => {
        setShowMoreFilterDropdown(false);
      },
    },
    urlKey !== "tm_protect"
      ? {
          selector: "#grid_filter_combine_pdf_btn",
          content: (
            <div>
              <div
                style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
              >
                Combine PDF
              </div>
              <br />
              <p style={{ color: "white" }}>
                Use this icon to download journal copy of all the selected
                potential threats in PDF.
              </p>
            </div>
          ),
          style: {
            backgroundColor: "#1F3EAA",
            borderRadius: "4px",
          },
        }
      : null,
  ].filter(Boolean);

  return (
    <>
      <MikeContainer header={Filters} content={content} />
      <Tour
        startAt={0}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => {
          setIsTourOpen(false);
          setOpenGrid(false);
          setActionDropdown(false);
          setshowSelectIcons(false);
          setShowMoreFilterDropdown(false);
          setViewSwitch(false);
          if (!isDataPresent) {
            dispatch(addGridSampleData());
          }
        }}
        nextButton={<Button>Next</Button>}
        prevButton={
          <Button style={{ color: "white", display: "none" }} type="link">
            Back
          </Button>
        }
        disableDotsNavigation
        showNavigation={false}
        // onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
        //   if (steps) {
        //     setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1))
        //   }
        // }}
        lastStepNextButton={<Button>Done</Button>}
        closeWithMask={false}
        badgeContent={(current, total) => `${current} of ${total}`}
        disableInteraction={true}
      />
    </>
  );
};

export default GridLayoutImageLabel;
