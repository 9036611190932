//--------GRID LAYOUT TYPES--------
import {
  GL_GET_USER_TRADEMARKS,
  GL_GET_THREAT_TRADEMARKS,
  GL_POST_CUSTOM_REPORT,
  GL_GET_IMAGE_TEXT,
  GL_GET_IMAGE_TEXT_TRADEMARKS,
  GL_POST_IMAGE_TEXT_CUSTOM_REPORT,
  GL_GET_IMAGE_LABEL,
  GL_GET_IMAGE_LABEL_TRADEMARKS,
  GL_POST_IMAGE_LABEL_CUSTOM_REPORT,
  GL_GET_TM_PROTECT,
  GL_GET_TM_PROTECT_TRADEMARKS,
  GL_POST_TM_PROTECT_CUSTOM_REPORT,
} from "../../actions/watchActions/types";

//--------IP-SUITE CONSTANTS--------
import {
  ERROR,
  SUCCESS,
  LOADING,
  PROGRESSING,
} from "../../../constants/commonConstants/generalConstants";

//--------GRID LAYOUT CONSTANTS--------
import {
  GL_defaultSearchSelectedFilters,
  GL_defaultSecondarySelectedFilters,
} from "../../../constants/watchConstants/gridLayoutConstants";

const defaultUserTrademarks = {
  trademarksData: null,
  trademarksDataStatus: SUCCESS,
  trademarksDataMessage: null,
  trademarksTotalCount: null,
  filteredTrademarksCount: null,
  pageNumber: 0,
  searchTerm: "",
};

const defaultGridState = {
  gridTrademarksData: null,
  gridTrademarksDataStatus: SUCCESS,
  gridFilters: null,
  gridFilteredTrademarksCount: null,
  gridLastAppliedFilters: GL_defaultSecondarySelectedFilters,
};

const defaultMarkState = {
  userTrademarks: defaultUserTrademarks,
  journalNumber: null,
  lastAppliedFilters: GL_defaultSearchSelectedFilters,
  profileReportID: null,
  dateRange: null,
  gridState: defaultGridState,
  automatedReportsLinks: null,
};

const defaultGridLayoutState = {
  mark: defaultMarkState,
  imageText: defaultMarkState,
  imageLabel: defaultMarkState,
  tm_protect: defaultMarkState,
};

export default function (state = defaultGridLayoutState, action) {
  switch (action.type) {
    case GL_GET_USER_TRADEMARKS:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            mark: {
              ...state.mark,
              userTrademarks: {
                ...state.mark.userTrademarks,
                trademarksData: action.payload.data,
                trademarksDataStatus: action.status,
                trademarksTotalCount: action.payload.total_count,
                filteredTrademarksCount: action.payload.risk_count,
                pageNumber: action.pageNumber,
                searchTerm: action.searchTerm,
                search_term: action.search_term,
                mark_desc_contains_term: action.mark_desc_contains_term,
              },
              lastAppliedFilters: action.lastAppliedFilters,
              automatedReportsLinks: {
                ...state.mark.automatedReportsLinks,
                all_class_watch_excel_report_url:
                  action.payload.all_class_watch_excel_report_url,
                all_class_watch_pdf_report_url:
                  action.payload.all_class_watch_pdf_report_url,
                same_class_watch_excel_report_url:
                  action.payload.same_class_watch_excel_report_url,
                same_class_watch_pdf_report_url:
                  action.payload.same_class_watch_pdf_report_url,
              },
              journalNumber: action.journalNumber,
            },
          };

        case LOADING:
          return {
            ...(action.journalChange ? defaultGridLayoutState : state),
            mark: {
              ...state.mark,
              userTrademarks: {
                ...state.userTrademarks,
                trademarksDataStatus: action.status,
              },
            },
          };

        case PROGRESSING:
          return {
            ...state,
            mark: {
              ...state.mark,
              userTrademarks: {
                ...state.userTrademarks,
                trademarksDataStatus: action.status,
              },
              journalNumber: action.journalNumber,
            },
          };

        case ERROR:
          return {
            ...state,
            mark: {
              ...state.mark,
              userTrademarks: {
                ...state.userTrademarks,
                trademarksDataStatus: action.status,
                trademarksDataMessage: action.payload,
              },
            },
          };

        default:
          return {
            ...state,
            mark: {
              ...state.mark,
              userTrademarks: {
                ...state.userTrademarks,
                trademarksDataStatus: action.status,
              },
            },
          };
      }

    case GL_GET_THREAT_TRADEMARKS:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            mark: {
              ...state.mark,
              gridState: {
                gridTrademarksData: action.payload.data.threats,
                gridTrademarksDataStatus: action.status,
                gridFilters: action.payload.filters,
                gridFilteredTrademarksCount: action.payload.count,
                gridLastAppliedFilters: action.lastAppliedFilters,
                pageNumber: action.pageNumber,
              },
            },
          };

        case LOADING:
          return {
            ...state,
            mark: {
              ...state.mark,
              gridState: {
                ...state.mark.gridState,
                gridTrademarksDataStatus: action.status,
              },
            },
          };

        case ERROR:
          return {
            ...state,
            mark: {
              ...state.mark,
              gridState: {
                ...state.mark.gridState,
                gridTrademarksDataStatus: action.status,
                gridFilteredTrademarksCount: 0,
              },
            },
          };

        default:
          return {
            ...state,
            mark: {
              ...state.mark,
              gridState: {
                ...state.mark.gridState,
                gridTrademarksDataStatus: action.status,
              },
            },
          };
      }

    case GL_POST_CUSTOM_REPORT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case LOADING:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            customReportStatus: action.status,
          };

        default:
          return state;
      }

    case GL_GET_IMAGE_TEXT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            imageText: {
              ...state.imageText,
              userTrademarks: {
                ...state.imageText.userTrademarks,
                trademarksData: action.payload.data,
                trademarksDataStatus: action.status,
                trademarksTotalCount: action.payload.total_count,
                filteredTrademarksCount: action.payload.risk_count,
                pageNumber: action.pageNumber,
                searchTerm: action.searchTerm,
                search_term: action.search_term,
              },
              lastAppliedFilters: action.lastAppliedFilters,
              automatedReportsLinks: {
                ...state.imageText.automatedReportsLinks,
                all_class_watch_excel_report_url:
                  action.payload.all_class_watch_excel_report_url,
                all_class_watch_pdf_report_url:
                  action.payload.all_class_watch_pdf_report_url,
                same_class_watch_excel_report_url:
                  action.payload.same_class_watch_excel_report_url,
                same_class_watch_pdf_report_url:
                  action.payload.same_class_watch_pdf_report_url,
              },
              journalNumber: action.journalNumber,
            },
          };

        case LOADING:
          return {
            ...(action.journalChange ? defaultGridLayoutState : state),
            imageText: {
              ...state.imageText,
              userTrademarks: {
                ...state.imageText.userTrademarks,
                trademarksDataStatus: action.status,
              },
            },
          };

        case PROGRESSING:
          return {
            ...state,
            imageText: {
              ...state.imageText,
              userTrademarks: {
                ...state.imageText.userTrademarks,
                trademarksDataStatus: action.status,
              },
              journalNumber: action.journalNumber,
            },
          };

        case ERROR:
          return {
            ...state,
            imageText: {
              ...state.imageText,
              userTrademarks: {
                ...state.imageText.userTrademarks,
                trademarksDataStatus: action.status,
                trademarksDataMessage: action.payload,
                trademarksTotalCount: 0,
                filteredTrademarksCount: 0,
              },
            },
          };

        default:
          return {
            ...state,
            imageText: {
              ...state.imageText,
              userTrademarks: {
                ...state.imageText.userTrademarks,
                trademarksDataStatus: action.status,
              },
            },
          };
      }

    case GL_GET_IMAGE_TEXT_TRADEMARKS:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            imageText: {
              ...state.imageText,
              gridState: {
                gridTrademarksData: action.payload.data.threats,
                gridTrademarksDataStatus: action.status,
                gridFilters: action.payload.filters,
                gridFilteredTrademarksCount: action.payload.count,
                gridLastAppliedFilters: action.lastAppliedFilters,
                pageNumber: action.pageNumber,
              },
            },
          };

        case LOADING:
          return {
            ...state,
            imageText: {
              ...state.imageText,
              gridState: {
                ...state.imageText.gridState,
                gridTrademarksDataStatus: action.status,
              },
            },
          };

        case ERROR:
          return {
            ...state,
            imageText: {
              ...state.imageText,
              gridState: {
                ...state.imageText.gridState,
                gridTrademarksDataStatus: action.status,
                gridFilteredTrademarksCount: 0,
              },
            },
          };

        default:
          return {
            ...state,
            imageText: {
              ...state.imageText,
              gridState: {
                ...state.imageText.gridState,
                gridTrademarksDataStatus: action.status,
              },
            },
          };
      }

    case GL_POST_IMAGE_TEXT_CUSTOM_REPORT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case LOADING:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            customReportStatus: action.status,
          };

        default:
          return state;
      }

    case GL_GET_IMAGE_LABEL:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            imageLabel: {
              ...state.imageLabel,
              userTrademarks: {
                ...state.imageLabel.userTrademarks,
                trademarksData: action.payload.data,
                trademarksDataStatus: action.status,
                trademarksTotalCount: action.payload.total_count,
                filteredTrademarksCount: action.payload.risk_count,
                pageNumber: action.pageNumber,
                searchTerm: action.searchTerm,
                search_term: action.search_term,
              },
              lastAppliedFilters: action.lastAppliedFilters,
              automatedReportsLinks: {
                ...state.imageLabel.automatedReportsLinks,
                all_class_watch_excel_report_url:
                  action.payload.all_class_watch_excel_report_url,
                all_class_watch_pdf_report_url:
                  action.payload.all_class_watch_pdf_report_url,
                same_class_watch_excel_report_url:
                  action.payload.same_class_watch_excel_report_url,
                same_class_watch_pdf_report_url:
                  action.payload.same_class_watch_pdf_report_url,
              },
              journalNumber: action.journalNumber,
            },
          };

        case LOADING:
          return {
            ...(action.journalChange ? defaultGridLayoutState : state),
            imageLabel: {
              ...state.imageLabel,
              userTrademarks: {
                ...state.imageLabel.userTrademarks,
                trademarksDataStatus: action.status,
              },
            },
          };

        case PROGRESSING:
          return {
            ...state,
            imageLabel: {
              ...state.imageLabel,
              userTrademarks: {
                ...state.imageLabel.userTrademarks,
                trademarksDataStatus: action.status,
              },
              journalNumber: action.journalNumber,
            },
          };

        case ERROR:
          return {
            ...state,
            imageLabel: {
              ...state.imageLabel,
              userTrademarks: {
                ...state.imageLabel.userTrademarks,
                trademarksDataStatus: action.status,
                trademarksDataMessage: action.payload,
                trademarksTotalCount: 0,
                filteredTrademarksCount: 0,
              },
            },
          };

        default:
          return {
            ...state,
            imageLabel: {
              ...state.imageLabel,
              userTrademarks: {
                ...state.imageLabel.userTrademarks,
                trademarksDataStatus: action.status,
              },
            },
          };
      }

    case GL_GET_IMAGE_LABEL_TRADEMARKS:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            imageLabel: {
              ...state.imageLabel,
              gridState: {
                gridTrademarksData: action.payload.data.threats,
                gridTrademarksDataStatus: action.status,
                gridFilters: action.payload.filters,
                gridFilteredTrademarksCount: action.payload.count,
                gridLastAppliedFilters: action.lastAppliedFilters,
                pageNumber: action.pageNumber,
              },
            },
          };

        case LOADING:
          return {
            ...state,
            imageLabel: {
              ...state.imageLabel,
              gridState: {
                ...state.imageLabel.gridState,
                gridTrademarksDataStatus: action.status,
              },
            },
          };

        case ERROR:
          return {
            ...state,
            imageLabel: {
              ...state.imageLabel,
              gridState: {
                ...state.imageLabel.gridState,
                gridTrademarksDataStatus: action.status,
                gridFilteredTrademarksCount: 0,
              },
            },
          };

        default:
          return {
            ...state,
            imageLabel: {
              ...state.imageLabel,
              gridState: {
                ...state.imageLabel.gridState,
                gridTrademarksDataStatus: action.status,
              },
            },
          };
      }

    case GL_POST_IMAGE_LABEL_CUSTOM_REPORT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case LOADING:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            customReportStatus: action.status,
          };

        default:
          return state;
      }

    case GL_GET_TM_PROTECT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              userTrademarks: {
                ...state.tm_protect.userTrademarks,
                trademarksData: action.payload.data,
                trademarksDataStatus: action.status,
                trademarksTotalCount: action.payload.total_count,
                filteredTrademarksCount: action.payload.risk_count,
                pageNumber: action.pageNumber,
                searchTerm: action.searchTerm,
              },
              lastAppliedFilters: action.lastAppliedFilters,
              profileReportID: action.profileReportID,
              dateRange: action.dateRange,
            },
          };

        case LOADING:
          return {
            ...(action.journalChange ? defaultGridLayoutState : state),
            tm_protect: {
              ...state.tm_protect,
              userTrademarks: {
                ...state.tm_protect.userTrademarks,
                trademarksDataStatus: action.status,
              },
            },
          };

        case PROGRESSING:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              userTrademarks: {
                ...state.tm_protect.userTrademarks,
                trademarksDataStatus: action.status,
              },
              profileReportID: action.profileReportID,
              dateRange: action.dateRange,
            },
          };

        case ERROR:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              userTrademarks: {
                ...state.tm_protect.userTrademarks,
                trademarksDataStatus: action.status,
                trademarksDataMessage: action.payload,
                trademarksTotalCount: 0,
                filteredTrademarksCount: 0,
              },
            },
          };

        default:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              userTrademarks: {
                ...state.tm_protect.userTrademarks,
                trademarksDataStatus: action.status,
              },
            },
          };
      }

    case GL_GET_TM_PROTECT_TRADEMARKS:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              gridState: {
                gridTrademarksData: action.payload.data.threats,
                gridTrademarksDataStatus: action.status,
                gridFilters: action.payload.filters,
                gridFilteredTrademarksCount: action.payload.count,
                gridLastAppliedFilters: action.lastAppliedFilters,
                pageNumber: action.pageNumber,
              },
            },
          };

        case LOADING:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              gridState: {
                ...state.tm_protect.gridState,
                gridTrademarksDataStatus: action.status,
              },
            },
          };

        case ERROR:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              gridState: {
                ...state.tm_protect.gridState,
                gridTrademarksDataStatus: action.status,
                gridFilteredTrademarksCount: 0,
              },
            },
          };

        default:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              gridState: {
                ...state.tm_protect.gridState,
                gridTrademarksDataStatus: action.status,
              },
            },
          };
      }

    case GL_POST_TM_PROTECT_CUSTOM_REPORT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case LOADING:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            customReportStatus: action.status,
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
