import React, { useState, useEffect } from "react";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Row, Col, Typography, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { setMFA } from "../../../redux/actions/commonActions/mfa";

import Setup2FAForm from "./Setup2FAForm";
import Disable2FAForm from "./Disable2FAForm";
import RecoveryCodesModal from "./RecoveryCodesModal";
import ResetPasswords from "./ResetPasswords";
import SMTPMicrosoftAuth from "./SMTPMicrosoftAuth";

const { Title } = Typography;

const ButtonBlock = ({ title, description, button }) => (
  <Row>
    {title && (
      <Col xs={{ span: 12 }} lg={{ span: 22 }}>
        <Title level={4}>{title}</Title>
      </Col>
    )}
    {description && <Col span={24}>{description}</Col>}

    {button && (
      <Col span={24} style={{ margin: "16px 0px" }}>
        {button}
      </Col>
    )}
  </Row>
);

const MFADashboard = () => {
  const [showSetup, setShowSetup] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [showRecovery, setShowRecovery] = useState(false);

  const [mfaRequired, setMfaRequired] = useState(false);
  const [mfaSetup, setMfaSetup] = useState(false);

  const dispatch = useDispatch();

  const mfa = JSON.parse(localStorage.getItem("mfa"));
  const mfa_setup = JSON.parse(localStorage.getItem("mfa_setup"));

  useEffect(() => {
    setMfaRequired(mfa);
    setMfaSetup(mfa_setup);
  }, [mfa, mfa_setup]);

  const triggerEnableForm = () => {
    //NOTE: First call setMFA then trigger generate URL
    dispatch(
      setMFA({
        operation: "MFA",
        mfa: true,
      }),
    );
    setShowSetup(true);
  };

  const onCloseSetupModal = () => {
    setShowSetup(false);
  };

  const triggerDisableForm = () => {
    setShowDisable(true);
  };

  const onCloseDisableForm = () => {
    setShowDisable(false);
  };

  const triggerRecoveryForm = () => {
    setShowRecovery(true);
  };

  const onCloseRecoveryForm = () => {
    setShowRecovery(false);
  };

  const onSubmitSetup = () => {
    setShowSetup(false);
    message.success("Two Factor Authentication Enabled");

    triggerRecoveryForm();
  };

  const onSubmitRecovery = () => {
    setShowSetup(false);
  };

  const onSubmitDisable = (values) => {
    message.success("Two Factor Authentication Disabled");
    onCloseDisableForm();
    setMfaRequired(false);
    setMfaSetup(false);
    localStorage.setItem("mfa", false);
    localStorage.setItem("mfa_setup", false);
  };

  const authBlock = (
    <ButtonBlock
      title="Two Factor Authentication"
      description={
        <>
          Two-factor authentication adds an additional layer of security to your
          account by requiring more <br /> than just a password to sign in.
        </>
      }
      button={
        mfaRequired && !mfaSetup ? (
          <Button type="link" danger onClick={triggerDisableForm}>
            <span style={{ color: "red", textDecoration: "underline" }}>
              Disable Two Factor Authentication
            </span>
          </Button>
        ) : (
          <Button type="primary" onClick={triggerEnableForm}>
            Enable Authentication
          </Button>
        )
      }
    />
  );
  const recoveryCodeBlock = (
    <ButtonBlock
      title="Recovery Codes"
      description={
        <>
          Two-factor authentication adds an additional layer of security to your
          account by requiring more <br /> than just a password to sign in.
        </>
      }
      button={<Button onClick={triggerRecoveryForm}>View Codes</Button>}
    />
  );

  return (
    <>
      <ResetPasswords />
      <hr />
      {authBlock}

      {mfaRequired && !mfaSetup && (
        <>
          <hr />
          {recoveryCodeBlock}
        </>
      )}

      {showSetup && (
        <Setup2FAForm
          visible={showSetup}
          onCloseModal={onCloseSetupModal}
          onSubmit={onSubmitSetup}
        />
      )}
      {showDisable && (
        <Disable2FAForm
          visible={showDisable}
          onCloseModal={onCloseDisableForm}
          onSubmit={onSubmitDisable}
        />
      )}
      {showRecovery && (
        <RecoveryCodesModal
          visible={showRecovery}
          onCloseModal={onCloseRecoveryForm}
          onSubmit={onSubmitRecovery}
        />
      )}
      <SMTPMicrosoftAuth />
    </>
  );
};

export default Form.create()(MFADashboard);
