import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import _ from "lodash";
import { CarryOutTwoTone, MessageTwoTone } from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  Card,
  Button,
  message,
  Typography,
  Tooltip,
  notification,
  Switch,
} from "antd";

//--------COMMON CONSTANTS--------
import { LOADING } from "../../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import {
  getReplyToFer,
  replyFiledToFer,
} from "../../../../redux/actions/managerActions/patentDashboard/actions";

import { history } from "../../../../history";

import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import { changeStateValue_L0 } from "../../../../functions/commonFunctions/generalFunctions";
import { Patent_DefaultSelectedFilters } from "../../../../constants/managerConstants/patentConstants";

import CreateTask from "../../to-do-task/CreateTask";
import DownloadReportModal from "../../report/DownloadReportModal";
import ReplyFiledModal from "../RenewalFiled";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { P } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_P_NOTES,
  PATCH_P_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";

const { Text } = Typography;

class PatentFER extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      pageNumber: 1,
      add_patent_id: [],
      selectedRowKeys: [],
      management_patent_id: "",
      addTaskVisible: false,
      replyFiledVisible: false,
      changeSwitch: false,
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.replyToFer === null) {
        this.props.getReplyToFer({
          filterFlag: false,
          filters: Patent_DefaultSelectedFilters,
        });
      }
      if (this.props.pseudoReplyToFer === null) {
        // this.props.getReplyToFer({
        //     pseudo: true,
        //     filterFlag: false,
        //     filters: Patent_DefaultSelectedFilters,
        // });
      }
    } else {
      this.props.logOut();
    }
  }

  handlPatentProfile(data) {
    if (data) {
      sessionStorage.setItem("management_patent_id", data.management_patent_id);
      sessionStorage.setItem(
        "patent_application_number",
        data.application_number,
      );
      window.open(
        "/manager/patent/patent-profile/" + data.management_patent_id,
        "_blank",
      );
      this.setState({
        management_patent_id: data.management_patent_id,
      });
    }
  }

  handleApplicantProfile(data) {
    if (data) {
      sessionStorage.setItem("applicant_id", data.id);
      window.open("/manager/patent/applicant-profile/" + data.id, "_blank");
      this.setState({
        applicant_id: data.id,
      });
    }
  }

  handleInventorProfile(data) {
    if (data) {
      sessionStorage.setItem("inventor_id", data.id);
      window.open("/manager/patent/inventor-profile/" + data.id, "_blank");
      this.setState({
        inventor_id: data.id,
      });
    }
  }

  handleDownloadDocument(data) {
    checkValue(data?.file)
      ? window.open(data?.file, "_blank")
      : message.error("No Document Found");
  }

  downloadReport() {
    if (this.state.add_patent_id.length > 0) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  replyFiled(data) {
    this.setState({
      replyFiledVisible: true,
      pending_reply: false,
      application_number: data.application_number,
      title: data.title_of_invention,
    });
  }

  handleNextpage = (pageNumber) => {
    this.props.getReplyToFer({
      pseudo: this.state.changeSwitch,
      filterFlag: false,
      filters: Patent_DefaultSelectedFilters,
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Patent Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [this.state.changeSwitch ? "PSEUDO_PATENT" : "PATENT"],
        report_tag: {
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          status: checkValue(data.application_status)
            ? data.application_status
            : "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_patent" : "patent",
        applicationNumber: data.application_number,
        sourceId: data.management_patent_id,
      },
    });
  }

  render() {
    const totalReplyToFerColumns = [
      {
        title: "PATENT",
        dataIndex: "application_number",
        className: "reply-column-1",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handlPatentProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.title_of_invention)
                    ? record.title_of_invention.toLowerCase()
                    : "N.A."}
                </span>
              </p>
            </div>
          );
        },
      },

      {
        title: "APPLICANT",
        dataIndex: "applicant",
        className: "reply-column-2",
        render: (applicant) => {
          return (
            <div>
              {applicant.length === 0 && <p>NA</p>}
              {applicant.map((applicantData, applicantKey) => (
                <p key={applicantKey} className="text-camelCase">
                  {checkValue(applicantData.name)
                    ? applicantData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "INVENTOR",
        dataIndex: "inventor",
        className: "reply-column-3",
        render: (inventor) => {
          return (
            <div>
              {inventor.length === 0 && <p>NA</p>}
              {inventor.map((inventorData, inventorKey) => (
                <p key={inventorKey} className="text-camelCase">
                  {checkValue(inventorData.name)
                    ? inventorData.name.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "TYPE",
        dataIndex: "application_type",
        className: "reply-column-4",
        render: (text) => (
          <p className="text-camelCase">
            {checkValue(text) ? text.toLowerCase() : "N.A."}
          </p>
        ),
      },

      {
        key: "patent_fer_name",
        title: "DOCUMENT",
        dataIndex: "notice_document",
        className: "reply-column-5",
        render: (document) => {
          return (
            <div>
              {document.map((documentData, documentKey) => (
                <p
                  key={documentKey}
                  className="application-text h_80px vertical_scroll"
                  onClick={() => this.handleDownloadDocument(documentData)}
                >
                  {checkValue(documentData.name) ? documentData.name : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        key: "patent_fer_date",
        title: "DOCUMENT DATE",
        dataIndex: "notice_document",
        className: "reply-column-6",
        render: (document) => {
          return (
            <div>
              {document.map((documentData, documentKey) => (
                <p className="h_80px vertical_scroll" key={documentKey}>
                  {checkValue(documentData.date) ? documentData.date : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        key: "patent_fer_time",
        title: "DUE DATE",
        dataIndex: "notice_document",
        className: "reply-column-9",
        render: (timeRemaining) => {
          return (
            <div>
              {timeRemaining.map((timeRemainingData, timeRemainingKey) => (
                <p className="h_80px vertical_scroll" key={timeRemainingKey}>
                  <span>{timeRemainingData.remaining_days}</span>
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "ACTION",
        className: "reply-column-8",
        render: (action) => {
          return (
            <div>
              <p
                className="application-text alignC"
                onClick={() => this.renewalFiled(action)}
              >
                Reply Filed
              </p>
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const totalReplyRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let select_patent_id = [];
        select_patent_id = selectedRows.map((mark_id) => mark_id.id);
        this.setState({
          selectedRows: selectedRows,
          add_patent_id: select_patent_id,
          selectedRowKeys: selectedRowKeys,
        });
      },

      onSelectAll: (selected, selectedRows) => {
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows) => {
        this.setState({
          selectRow: selected,
          selectAll: selectedRows.length === 100 ? true : false,
        });
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: !this.state.selectAll,
                  add_patent_id: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    return (
      <div>
        {/* Change-Switch */}
        <Row
          className={
            checkValue(this.props.userSubscriptions)
              ? this.props.userSubscriptions.manager.includes("PSEUDO_PATENT")
                ? ""
                : "display_none"
              : "display_none"
          }
        >
          <Col span={24} className="alignR m-top-10">
            <Text strong>IP INDIA</Text>
            <Switch
              className="change-manager-type"
              onChange={() => this.changeSwitch()}
              checked={this.state.changeSwitch}
            />
            <Text strong>CUSTOM</Text>
          </Col>
        </Row>
        {/* Change-Switch */}

        {/* reply to fer */}
        <Row>
          <Col span={24} className="m-top-10">
            <Card
              title="REPLY TO FER"
              extra={
                <div>
                  <Text style={{ padding: "0px 5px" }}>
                    {this.state.selectAll
                      ? this.props.replyToFer &&
                        this.props.replyToFer.ip_india.count
                      : _.uniq(this.state.add_patent_id).length}{" "}
                    Patent(s)
                  </Text>
                  <Button type="primary" onClick={() => this.downloadReport()}>
                    Report
                  </Button>
                </div>
              }
            >
              <Table
                rowKey={(record) => record.id}
                bordered
                rowSelection={totalReplyRowSelection}
                columns={totalReplyToFerColumns}
                dataSource={
                  this.props.replyToFer &&
                  this.props.replyToFer.ip_india &&
                  this.props.replyToFer.ip_india.patents
                }
                pagination={{
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} `,
                  total:
                    this.props.replyToFer &&
                    this.props.replyToFer.ip_india &&
                    this.props.replyToFer.ip_india.count,
                  onChange: this.handleNextpage,
                }}
                scroll={{ y: "calc(70vh - 200px)", x: "hidden" }}
                loading={this.props.replyToFerStatus === LOADING ? true : false}
              />
            </Card>
          </Col>
        </Row>
        {/* reply to fer */}
        {this.state.visible && (
          <DownloadReportModal
            visible={this.state.visible}
            onCloseModal={() => this.setState({ visible: false })}
            type={"patent"}
            add_patent_id={this.state.add_patent_id}
            pending_reply_to_fer={this.state.selectAll}
            filters={Patent_DefaultSelectedFilters}
          />
        )}

        <ReplyFiledModal
          visible={this.state.replyFiledVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("replyFiledVisible", false)
          }
          pending_reply={this.state.pending_reply}
          application_number={this.state.application_number}
          title={this.state.title}
        />

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"patent"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_P_NOTES, PATCH_P_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={P}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          source={`PATENT`}
          sourceId={this.state.modalsVisibility.sourceId}
          managerEmail={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getReplyToFer,
  replyFiledToFer,
  logOut,
};

function mapStateToProps(state) {
  const {
    pseudo,

    replyToFer,
    replyToFerStatus,
    replyToFerAppliedFilters,
    replyToFerPageNumber,

    pseudoReplyToFer,
    pseudoReplyToFerStatus,
    pseudoReplyToFerAppliedFilters,
    pseudoReplyToFerPageNumber,
  } = state.managerState.patentDashboardState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
    pseudo,

    replyToFer,
    replyToFerStatus,
    replyToFerAppliedFilters,
    replyToFerPageNumber,

    pseudoReplyToFer,
    pseudoReplyToFerStatus,
    pseudoReplyToFerAppliedFilters,
    pseudoReplyToFerPageNumber,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(PatentFER);
