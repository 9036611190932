//--------LIBRARIES--------
import Axios from 'axios';

//--------ROOT_CONFIG--------
import { MGR_RT_URL } from '../../../../configs/rootConfigs';

//--------COMMON CONSTANTS--------
import { LOADING, ERROR, SUCCESS } from '../../../../constants/commonConstants/generalConstants';

//--------TYPES--------
import {
    PATENT,
    PSEUDO_PATENT,
    PATENT_RENEWALS,
    PATENT_RENEWAL_FILED,
    REPLY_TO_FER,
    PSEUDO_REPLY_TO_FER,
    REPLY_FILED_TO_FER,
    REFINED_PATENT,
    PATENT_CHANGELOG,
    PATENT_APPLICANT,
    PATENT_APPLICANT_DETAIL,
    PATENT_INVENTOR,
    PATENT_INVENTOR_DETAIL,

    PENDING_WORKING_OF_PATENT,
    STATEMENT_OF_UNDERTAKING,
    REQUEST_FOR_EXAMINATION,
    MANAGE_PATENT,
    POST_MANAGE_PATENT

} from './types';

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from '../../commonActions/actions';


//--------Manager-Patent: Renewals--------
export function getPatentRenewals({pseudo, pageNumber}) {
    return async (dispatch) => {
        dispatch({ type: PATENT_RENEWALS, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/patents/renewals/`, {
                params: { 
                    pseudo: pseudo ? pseudo : null,
                    page: pageNumber
                },
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ 
                type: PATENT_RENEWALS, 
                status: SUCCESS, 
                payload: response?.data,
                pageNumber
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: PATENT_RENEWALS, status: ERROR })
        }
    }
}

//-----------Manager-Patent: renewal-Filed-----
export function patentRenewalFiled(renewal, patent_number, pseudo) {
    return async (dispatch) => {
        dispatch({ type: PATENT_RENEWAL_FILED, status: LOADING });
        try {
            let response;
            response = await Axios.post(`${MGR_RT_URL}/patents/renewals/`, 
            { 
                renewal, 
                patent_number, 
                pseudo, 
            }, 
            {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ 
                type: PATENT_RENEWAL_FILED, 
                status: SUCCESS, 
                payload: { 
                    message: response?.data, 
                    patent_number: patent_number 
                } 
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: PATENT_RENEWAL_FILED, status: ERROR })
        }
    }
}

//--------Manager-Patent: Patent--------
export function getPatent({ pseudo, filterFlag, filters, pageNumber }) {
    const applicant = `${filters.applicant}`;
    const inventor = `${filters.inventor}`;
    const application_type = `${filters.applicationType}`;
    const field_of_invention = `${filters.fieldOfInvention}`;
    const status = filters.applicationStatus ? filters.applicationStatus.join('; ') : `${filters.applicationStatus}` ;
    const legal_status = `${filters.legalStatus}`;
    const classification_ipc = filters.classificationIPC;
    const contains_term = `${filters.containsTerm}`;
    return async (dispatch) => {
        if (pseudo) {
            dispatch({ type: PSEUDO_PATENT, status: LOADING });
            try {
                let response;
                response = await Axios.get(`${MGR_RT_URL}/patents/`, {
                    params: {
                        pseudo: pseudo,
                        filter_flag: filterFlag ? filterFlag: null,
                        applicant: filterFlag ? applicant: null,
                        inventor: filterFlag ? inventor: null,
                        application_type: filterFlag ? application_type: null,
                        field_of_invention: filterFlag ? field_of_invention: null,
                        status: filterFlag ? status: null,
                        legal_status: filterFlag ? legal_status: null,
                        classification_ipc: filterFlag ? classification_ipc: null,
                        contains_term: filterFlag ? contains_term: null,
                        page: pageNumber,
                    },
                    headers: { Authorization: 'Token ' + localStorage.getItem('token') }
                });
                dispatch({
                    type: PSEUDO_PATENT,
                    status: SUCCESS,
                    payload: response?.data,
                    pseudo,
                    lastAppliedFilters: {
                        ...filters,
                        filterFlag
                    },
                    pageNumber,
                });
            }
            catch (err) {
                checkAuthorisation(dispatch, err);
                dispatch({ type: PSEUDO_PATENT, status: ERROR })
            }
        }
        else {
            dispatch({ type: PATENT, status: LOADING });
            try {
                let response;
                response = await Axios.get(`${MGR_RT_URL}/patents/`, {
                    params: {
                        filter_flag: filterFlag ? filterFlag: null,
                        applicant: filterFlag ? applicant: null,
                        inventor: filterFlag ? inventor: null,
                        application_type: filterFlag ? application_type: null,
                        field_of_invention: filterFlag ? field_of_invention: null,
                        status: filterFlag ? status: null,
                        legal_status: filterFlag ? legal_status: null,
                        classification_ipc: filterFlag ? classification_ipc: null,
                        contains_term: filterFlag ? contains_term: null,
                        page: pageNumber,
                    },
                    headers: { Authorization: 'Token ' + localStorage.getItem('token') }
                });
                dispatch({
                    type: PATENT,
                    status: SUCCESS,
                    payload: response?.data,
                    lastAppliedFilters: {
                        ...filters,
                        filterFlag
                    },
                    pageNumber,
                });
            }
            catch (err) {
                checkAuthorisation(dispatch, err);
                dispatch({ type: PATENT, status: ERROR })
            }
        }
    }
}

//-----------Manager-Patent: Delete-Patent-----
export function deletePatent(patentId) {
    if (patentId && patentId.length === 0) {
        patentId = null
    }
    else {
        patentId = patentId.toString();
    }
    return async (dispatch) => {
        dispatch({ type: PATENT, status: LOADING });
        try {
            let response;
            response = await Axios.delete(`${MGR_RT_URL}/patents/`, {
                data: { marks: patentId },
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ type: PATENT, status: SUCCESS, payload: response?.data });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: PATENT, status: ERROR })
        }
    }
}

//--------Manager-Patent: Reply-to-fer--------
export function getReplyToFer({ pseudo, filterFlag, filters, pageNumber }){
    const applicant = `${filters.applicant}`;
    const inventor = `${filters.inventor}`;
    const application_type = `${filters.applicationType}`;
    const field_of_invention = `${filters.fieldOfInvention}`;
    const status = filters.applicationStatus ? filters.applicationStatus.join('; ') : `${filters.applicationStatus}` ;
    const legal_status = `${filters.legalStatus}`;
    const classification_ipc = filters.classificationIPC;
    const contains_term = `${filters.containsTerm}`;
    return async (dispatch) => {
        if (pseudo) {
            dispatch({ type: PSEUDO_REPLY_TO_FER, status: LOADING });
            try {
                let response;
                response = await Axios.get(`${MGR_RT_URL}/patents/reply_to_fer/`, {
                    params: {
                        pseudo: pseudo,
                        filter_flag: filterFlag ? filterFlag: null,
                        applicant: filterFlag ? applicant: null,
                        inventor: filterFlag ? inventor: null,
                        application_type: filterFlag ? application_type: null,
                        field_of_invention: filterFlag ? field_of_invention: null,
                        status: filterFlag ? status: null,
                        legal_status: filterFlag ? legal_status: null,
                        classification_ipc: filterFlag ? classification_ipc: null,
                        contains_term: filterFlag ? contains_term: null,
                        page: pageNumber,
                    },
                    headers: { Authorization: 'Token ' + localStorage.getItem('token') }
                });
                dispatch({
                    type: PSEUDO_REPLY_TO_FER,
                    status: SUCCESS,
                    payload: response?.data,
                    pseudo,
                    lastAppliedFilters: {
                        ...filters,
                        filterFlag
                    },
                    pageNumber,
                });
            }
            catch (err) {
                checkAuthorisation(dispatch, err);
                dispatch({ type: PSEUDO_REPLY_TO_FER, status: ERROR })
            }
        }
        else {
            dispatch({ type: REPLY_TO_FER, status: LOADING });
            try {
                let response;
                response = await Axios.get(`${MGR_RT_URL}/patents/reply_to_fer/`, {
                    params: {
                        filter_flag: filterFlag ? filterFlag: null,
                        applicant: filterFlag ? applicant: null,
                        inventor: filterFlag ? inventor: null,
                        application_type: filterFlag ? application_type: null,
                        field_of_invention: filterFlag ? field_of_invention: null,
                        status: filterFlag ? status: null,
                        legal_status: filterFlag ? legal_status: null,
                        classification_ipc: filterFlag ? classification_ipc: null,
                        contains_term: filterFlag ? contains_term: null,
                        page: pageNumber,
                    },
                    headers: {Authorization: 'Token ' + localStorage.getItem('token')}
                }); 
                dispatch({
                    type: REPLY_TO_FER,
                    status: SUCCESS,
                    payload: response?.data,
                    lastAppliedFilters: {
                        ...filters,
                        filterFlag
                    },
                    pageNumber,
                });
            } 
            catch (err) {
                checkAuthorisation(dispatch, err);
                dispatch({type: REPLY_TO_FER, status: ERROR})
            }
        }
    }
}

//-----------Manager-Patent: Reply-Filed-to-fer-----
export function replyFiledToFer(pending_reply, patent_number) {
    return async (dispatch) => {
        dispatch({ type: REPLY_FILED_TO_FER, status: LOADING });
        try {
            let response;
            response = await Axios.post(`${MGR_RT_URL}/patents/reply_to_fer/`, { pending_reply, patent_number }, {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ type: REPLY_FILED_TO_FER, status: SUCCESS, payload: { response: response?.data, patent_number: patent_number } });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: REPLY_FILED_TO_FER, status: ERROR })
        }
    }
}

//--------Manager-Patent: refined-patent--------
export function getRefinedPatent(id) {
    return async (dispatch) => {
        dispatch({ type: REFINED_PATENT, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/refined_patent/${id}`, {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ type: REFINED_PATENT, status: SUCCESS, payload: response?.data });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: REFINED_PATENT, status: ERROR })
        }
    }
}

//--------Manager-Patent: Changelog--------
export function getPatentChangelog(id) {
    return async (dispatch) => {
        dispatch({ type: PATENT_CHANGELOG, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/patents/changelog/${id}`, {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ type: PATENT_CHANGELOG, status: SUCCESS, payload: response?.data });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: PATENT_CHANGELOG, status: ERROR })
        }
    }
}

//--------Manager-Patent: Applicant--------
export function getApplicant(id) {
    return async (dispatch) => {
        dispatch({ type: PATENT_APPLICANT, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/applicant/${id}`, {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ type: PATENT_APPLICANT, status: SUCCESS, payload: response?.data });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: PATENT_APPLICANT, status: ERROR })
        }
    }
}

//--------Manager-Patent: Applicant-Details--------
export function getApplicantDetail(id) {
    return async (dispatch) => {
        dispatch({ type: PATENT_APPLICANT_DETAIL, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/applicant/${id}/patents`, {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ type: PATENT_APPLICANT_DETAIL, status: SUCCESS, payload: response?.data });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: PATENT_APPLICANT_DETAIL, status: ERROR })
        }
    }
}

//--------Manager-Patent: Inventor--------
export function getInventor(id) {
    return async (dispatch) => {
        dispatch({ type: PATENT_INVENTOR, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/inventor/${id}`, {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ type: PATENT_INVENTOR, status: SUCCESS, payload: response?.data });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: PATENT_INVENTOR, status: ERROR })
        }
    }
}

//--------Manager-Patent: Inventor-Details--------
export function getInventorDetail(id) {
    return async (dispatch) => {
        dispatch({ type: PATENT_INVENTOR_DETAIL, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/inventor/${id}/patents`, {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ type: PATENT_INVENTOR_DETAIL, status: SUCCESS, payload: response?.data });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: PATENT_INVENTOR_DETAIL, status: ERROR });
        }
    }
}

//--------Manager-Patent: Pending Working Of Patent--------
export function getPendingWorkingOfPatent({pseudo, pageNumber}) {
    return async (dispatch) => {
        dispatch({ type: PENDING_WORKING_OF_PATENT, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/patents/working_of_patent/`, {
                params: { 
                    pseudo: pseudo ? pseudo : null,
                    page: pageNumber 
                },
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ 
                type: PENDING_WORKING_OF_PATENT, 
                status: SUCCESS, 
                payload: response?.data,
                pageNumber
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: PENDING_WORKING_OF_PATENT, status: ERROR })
        }
    }
}

//--------Manager-Patent: Statement Of Undertaking--------
export function getStatementOfUndertaking({pseudo, pageNumber}) {
    return async (dispatch) => {
        dispatch({ type: STATEMENT_OF_UNDERTAKING, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/patents/statement_of_undertaking/`, {
                params: { 
                    pseudo: pseudo ? pseudo : null,
                    page: pageNumber  
                },
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ 
                type: STATEMENT_OF_UNDERTAKING, 
                status: SUCCESS, 
                payload: response?.data,
                pageNumber
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: STATEMENT_OF_UNDERTAKING, status: ERROR })
        }
    }
}

//--------Manager-Patent: Request For Examination--------
export function getRequestForExamination({pseudo, pageNumber}) {
    return async (dispatch) => {
        dispatch({ type: REQUEST_FOR_EXAMINATION, status: LOADING });
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/patents/request_for_examination/`, {
                params: { 
                    pseudo: pseudo ? pseudo : null,
                    page: pageNumber 
                },
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            });
            dispatch({ 
                type: REQUEST_FOR_EXAMINATION, 
                status: SUCCESS, 
                payload: response?.data,
                pageNumber
            });
        }
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({ type: REQUEST_FOR_EXAMINATION, status: ERROR })
        }
    }
}

// *********************GET MANAGE PATENTS*********************//
export function getManagePatent({pageNumber,filters,filterFlag}){
    const applicant = `${filters?.applicant}`;
    const inventor = `${filters?.inventor}`;
    const application_type = `${filters?.applicationType}`;
    const status = `${filters?.applicationStatus}` ;
    const legal_status = `${filters?.legalStatus}`;
    const field_of_invention=`${filters?.fieldOfInvention}`
    const contains_term = `${filters?.containsTerm}`;
    const tags=`${filters?.patentTags}`
    
    
    return async (dispatch) => {
        dispatch({ type: MANAGE_PATENT, status: LOADING});
        try {
            let response;
            response = await Axios.get(`${MGR_RT_URL}/manage/patents/`,{
                params: {
                    filter_flag: filterFlag ? filterFlag: null,
                    applicant: filterFlag ? applicant : null,
                    inventor: filterFlag ? inventor : null,
                    application_type: filterFlag ? application_type : null,
                    application_status: filterFlag ? status : null,
                    legal_status: filterFlag ? legal_status : null,
                    field_of_invention:filterFlag ? field_of_invention:null,
                    contains_term:filterFlag? contains_term : null,
                    tag:filterFlag?tags:null,
                    page: pageNumber,
                },
                headers: {Authorization: 'Token ' + localStorage.getItem('token')}                            
            });            
            dispatch({
                type: MANAGE_PATENT,
                status: SUCCESS,
                payload: response?.data,
                lastAppliedFilters: {
                    ...filters,
                    filterFlag
                },
                pageNumber,
            });
         
        } 
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({type: MANAGE_PATENT, status: ERROR})
        }  
    }
}

export function postManagePatent({
    mmType,
    setters,
    select_all,
    delete_flag,
    filterFlag,
    filters,
    patents,
    application_number,
}){
    return async (dispatch) => {
        let response;
        dispatch({ type: POST_MANAGE_PATENT, status: LOADING, deleteFlag: delete_flag });
        try {
          
            response = await Axios.post(`${MGR_RT_URL}/manage/patents/`,
            {
                mm_type: mmType,
                ...setters,
                select_all,
                delete_flag,
                filter_flag: filterFlag,
                filters: {
                    classes: filters?.trademarkClass,
                    proprietors: filters?.trademarkProprietors,
                    tags: filters?.trademarkTag,
                    contains_term: filters?.containsTerm
                },
                patents,
                application_number,
            }, 
            {
                headers: {Authorization: 'Token ' + localStorage.getItem('token')}
            });

            dispatch({
                type: POST_MANAGE_PATENT,
                status: SUCCESS,
                payload: response?.data,
                trademarkType: mmType,
                setters,
                selectAll: select_all,
                deleteFlag: delete_flag
            });

            
        } 

      
        catch (err) {
            checkAuthorisation(dispatch, err);
            dispatch({type: POST_MANAGE_PATENT, status: ERROR, deleteFlag: delete_flag })
        }   
    }
}