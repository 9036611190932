import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import _ from "lodash";
import { Row, Col, Card } from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Bar,
  Cell,
  XAxis,
  YAxis,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import {
  renderBarCustomTooltip,
  renderPieCustomTooltip,
  renderPieCustomizedLabel,
} from "../../../../functions/commonFunctions/reChartsFunctions";

const colors = scaleOrdinal([
  ...schemeCategory10,
  ...schemeCategory10,
  ...schemeCategory10,
]).range();

class PatentAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StatusSeries: [],
      InventionTypeSeries: [],
      FilingYearSeries: [],
      graph_stored: false,
    };
  }

  setGraphData(StatusSeries, InventionTypeSeries, FilingYearSeries) {
    this.setState({
      StatusSeries: StatusSeries,
      InventionTypeSeries: InventionTypeSeries,
      FilingYearSeries: FilingYearSeries,
      graph_stored: true,
    });
  }

  render() {
    if (this.props.patentDashboardAnalyticsStatus === SUCCESS) {
      let StatusSeries = _.map(
        this.props.patentDashboardAnalytics.patent.status_distribution,
        function (graphData) {
          return {
            name: graphData?.status,
            y: graphData?.frequency,
            value: graphData?.frequency,
          };
        },
      );

      let InventionTypeSeries = _.map(
        this.props.patentDashboardAnalytics.patent.invention_type_distribution,
        function (graphData) {
          return {
            name: graphData?.invention,
            y: graphData?.frequency,
            value: graphData?.frequency,
          };
        },
      );

      let FilingYearSeries = _.map(
        this.props.patentDashboardAnalytics.patent.filing_year_distribution,
        function (graphData) {
          return {
            name: graphData?.year,
            data: [graphData?.frequency],
            uv: graphData?.frequency,
          };
        },
      );

      StatusSeries = _.filter(StatusSeries, function (distribution) {
        return distribution.y !== 0 && distribution.name !== null;
      });

      InventionTypeSeries = _.filter(
        InventionTypeSeries,
        function (distribution) {
          return distribution.y !== 0 && distribution.name !== null;
        },
      );

      FilingYearSeries = _.filter(FilingYearSeries, function (distribution) {
        return distribution.data[0] !== 0;
      });

      if (
        (StatusSeries.length > 0 ||
          InventionTypeSeries.length > 0 ||
          FilingYearSeries.length > 0) &&
        !this.state.graph_stored
      ) {
        this.setGraphData(StatusSeries, InventionTypeSeries, FilingYearSeries);
      }
    }

    const chartIdStatus = "your_patent_analysis_statusChart";
    const chartIdInvention = "your_patent_analysis_inventionChart";

    return (
      <div>
        {/* status-type distribution */}
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Card
              hoverable
              bodyStyle={{ textAlign: "center" }}
              loading={
                this.props.patentDashboardAnalyticsStatus === LOADING
                  ? true
                  : false
              }
            >
              <h5>Status Distribution</h5>
              <div className="recharts-parent-div-custom-style">
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={this?.state?.StatusSeries ?? []}
                      label={(props) =>
                        renderPieCustomizedLabel(props, chartIdStatus)
                      }
                      labelLine={false}
                      fill="#8884d8"
                      dataKey="value"
                      isAnimationActive={false}
                      cx="50%"
                      cy="50%"
                    >
                      {this.state.StatusSeries.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value) => value}
                      content={(props) =>
                        renderPieCustomTooltip(
                          props,
                          this?.state?.StatusSeries ?? [],
                        )
                      }
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
          <Col className="gutter-row" span={12}>
            <Card
              hoverable
              bodyStyle={{ textAlign: "center" }}
              loading={
                this.props.patentDashboardAnalyticsStatus === LOADING
                  ? true
                  : false
              }
            >
              <h5>Invention Type Distribution</h5>
              <div className="recharts-parent-div-custom-style">
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={this?.state?.InventionTypeSeries ?? []}
                      label={(props) =>
                        renderPieCustomizedLabel(props, chartIdInvention)
                      }
                      labelLine={false}
                      fill="#8884d8"
                      dataKey="value"
                      isAnimationActive={false}
                      cx="50%"
                      cy="50%"
                    >
                      {this.state.InventionTypeSeries.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value) => value}
                      content={(props) =>
                        renderPieCustomTooltip(
                          props,
                          this?.state?.InventionTypeSeries ?? [],
                        )
                      }
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
        </Row>
        {/* status-type distribution */}

        {/* class distribution */}
        <Row gutter={16}>
          <Col className="gutter-row m-top-20" span={24}>
            <Card
              hoverable
              bodyStyle={{ textAlign: "center" }}
              loading={
                this.props.patentDashboardAnalyticsStatus === LOADING
                  ? true
                  : false
              }
            >
              <h5>Filling Year</h5>
              <p>Year-wise Distribution</p>
              <div className="recharts-parent-div-custom-style">
                <ResponsiveContainer>
                  <BarChart
                    data={this.state.FilingYearSeries}
                    margin={{ bottom: 30 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      label={{
                        value: "Year",
                        offset: -20,
                        position: "insideBottom",
                      }}
                    />
                    <YAxis
                      label={{
                        value: "Patents",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Bar
                      dataKey="uv"
                      fill="#8884d8"
                      // label={{ position: "top" }}
                    >
                      {this.state.FilingYearSeries.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Bar>
                    <Tooltip
                      cursor={{ fill: "transparent" }}
                      content={renderBarCustomTooltip}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
        </Row>
        {/* class distribution */}
      </div>
    );
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  const { patentDashboardAnalytics, patentDashboardAnalyticsStatus } =
    state.managerState.profileState;
  return deepFreeze({
    patentDashboardAnalytics,
    patentDashboardAnalyticsStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(PatentAnalysis);
