//--------LIBRARIES--------
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Empty, Spin, Pagination } from "antd";
import dayjs from "dayjs";
import { date_format } from "../../../watchFilter/utils/utils";
import SecondaryFilter from "../../../watchFilter/newCommonComponent/GridLayout/SecondaryFilter";
import ThreatCard from "./ThreatCard";
import ThreatList from "./ThreatList";
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../../constants/commonConstants/generalConstants";
import { getGridLayoutThreatData } from "../../../../../redux/actions/watchActions/gridDashboardActions";
import SendEmailNew from "../../../../commonComponents/Modals/SendEmailNew";
import TrademarkJournalPage from "../../../subComponents/Drawers/TrademarkJournalPage";

const ThreatDetailArea = (props) => {
  let urlKey = window.location.pathname.split("/")[2] || "";
  let dashboardLayout = window.location.pathname.split("/")[3] || "";

  const dispatch = useDispatch();

  const { data, status, count, pageNumber, message } = useSelector(
    (state) =>
      state.watchState.gridLayoutData[dashboardLayout][urlKey].config.threats,
  );

  const [threatTMTypes, setThreatTMTypes] = React.useState([]);
  const [threatTMCategories, setThreatTMCategories] = React.useState([]);
  const [threatTMOffices, setThreatTMOffices] = React.useState([]);
  const [infringement, setInfringement] = React.useState([]);
  const [searchType, setSearchType] = React.useState("Application No/Name");
  const [searchValue, setSearchValue] = React.useState("");
  const [excludeReported, setExcludeReported] = React.useState(false);
  const [shortlisted, setShortlisted] = React.useState(false);
  const [excludeShortlisted, setExcludeShortlisted] = React.useState(false);
  const [showDescription, setShowDescription] = React.useState(false);
  const [bookmark, setBookmark] = React.useState(false);
  const [threatFilterApplied, setThreatFilterApplied] = React.useState(false);

  // Advance Filters
  const [threatAttorneyValue, setThreatAttorneyValue] = React.useState([]);
  const [threatProprietors, setThreatProprietors] = React.useState([]);
  const [excludeThreatProprietors, setExcludeThreatProprietors] =
    React.useState([]);
  const [threatTMClass, setThreatClass] = React.useState([]);
  const [similarity, setSimilarity] = React.useState({
    from: 0,
    to: 100,
  });
  const [threatDateOfApplication, setThreatDateOfApplication] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [threatDateOfUsage, setThreatDateOfUsage] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [threatDateOfUsageProposed, setThreatDateOfUsageProposed] =
    React.useState(false);
  const [sameClassInfringement, setSameClassInfringement] =
    React.useState(false);
  const [excludeSameClassInfringement, setExcludeSameClassInfringement] =
    React.useState(false);
  const [publishedDate, setPublishedDate] = React.useState("");
  const [threatLabel, setThreatLabel] = React.useState([]);

  // AND - OR  states
  const [andOrThreatClass, setAndOrThreatClass] = React.useState(false);
  const [andOrThreatLabel, setAndOrThreatLabel] = React.useState(false);

  const [threatView, setThreatView] = React.useState("card");

  useEffect(() => {
    if (props?.viewSwitch) setThreatView("table");
  }, [props?.viewSwitch]);
  const getRealSearchParameters = () => {
    const defaultSearchParameters = {
      contains_term: {
        value: null,
      },
      threat_desc_contains_term: {
        value: null,
      },
    };

    switch (searchType) {
      case "Application No/Name":
        return Object.assign({}, defaultSearchParameters, {
          contains_term: {
            value: searchValue,
          },
        });
      case "Your Threat Description Search":
        return Object.assign({}, defaultSearchParameters, {
          threat_desc_contains_term: {
            value: searchValue,
          },
        });
      default:
        return defaultSearchParameters;
    }
  };
  const generateFilterObject = () => {
    const filters = {
      threat_classes: {
        value: threatTMClass,
        logic: andOrThreatClass ? "AND" : "OR",
      },
      threat_sources: {
        value: infringement,
      },
      threat_similarity: {
        value: {
          to: similarity.to,
          from: similarity.from,
        },
      },
      threat_types: {
        value: threatTMTypes,
      },
      threat_proprietors: {
        value: threatProprietors,
      },
      threat_categories: {
        value: threatTMCategories,
      },
      threat_offices: {
        value: threatTMOffices,
      },
      threat_date_of_application: {
        value: {
          from: threatDateOfApplication.from
            ? dayjs(threatDateOfApplication.from).format(date_format)
            : "",
          to: threatDateOfApplication.to
            ? dayjs(threatDateOfApplication.to).format(date_format)
            : "",
        },
      },
      threat_date_of_usage: {
        value: {
          from: threatDateOfUsage.from
            ? dayjs(threatDateOfUsage.from).format(date_format)
            : "",
          to: threatDateOfUsage.to
            ? dayjs(threatDateOfUsage.to).format(date_format)
            : "",
        },
      },
      threat_date_of_usage_proposed: {
        value: threatDateOfUsageProposed,
      },
      red_band: {
        value: sameClassInfringement,
      },
      exclude_same_class: {
        value: excludeSameClassInfringement,
      },
      exclude_marks_wrt_custom_report: {
        value: excludeReported,
      },
      shortlisted: {
        value: shortlisted,
      },
      exclude_shortlisted: {
        value: excludeShortlisted,
      },
      exclude_threat_proprietors: {
        value: excludeThreatProprietors,
      },
      threat_attorney: {
        value: threatAttorneyValue,
      },
      ...getRealSearchParameters(),
    };
    if (urlKey === "image_label") {
      filters.threat_labels = {
        value: threatLabel,
        logic: andOrThreatLabel ? "AND" : "OR",
      };
    }
    return filters;
  };

  const isAnyFilterSelected = (filterObject) => {
    const {
      contains_term,
      exclude_mark_proprietors,
      exclude_marks_wrt_custom_report,
      exclude_same_class,
      exclude_shortlisted,
      exclude_threat_proprietors,
      threat_date_of_application,
      threat_date_of_usage,
      red_band,
      shortlisted,
      threat_attorney,
      threat_categories,
      threat_classes,
      threat_date_of_usage_proposed,
      threat_desc_contains_term,
      threat_offices,
      threat_proprietors,
      threat_similarity,
      threat_sources,
      threat_types,
      threat_labels,
    } = filterObject;

    let anyFilterSelected =
      contains_term?.value ||
      exclude_mark_proprietors?.value.length ||
      exclude_marks_wrt_custom_report?.value ||
      exclude_same_class?.value ||
      exclude_shortlisted?.value ||
      exclude_threat_proprietors?.value.length ||
      threat_date_of_application?.value.from ||
      threat_date_of_application?.value.to ||
      threat_date_of_usage?.value.from ||
      threat_date_of_usage?.value.to ||
      red_band?.value ||
      shortlisted?.value ||
      threat_attorney?.value.length ||
      threat_categories?.value.length ||
      threat_classes?.value.length ||
      threat_date_of_usage_proposed?.value ||
      threat_desc_contains_term?.value ||
      threat_offices?.value.length ||
      threat_proprietors?.value.length ||
      threat_similarity?.value.from > 0 ||
      threat_similarity?.value.to < 100 ||
      threat_sources?.value.length ||
      threat_types?.value.length ||
      threat_labels?.value.length;
    return Boolean(anyFilterSelected);
  };

  const propsForFilters = {
    threatTMTypes,
    setThreatTMTypes,
    threatTMCategories,
    setThreatTMCategories,
    threatTMOffices,
    setThreatTMOffices,
    infringement,
    setInfringement,
    searchType,
    setSearchType,
    searchValue,
    setSearchValue,
    excludeReported,
    setExcludeReported,
    shortlisted,
    setShortlisted,
    showDescription,
    setShowDescription,
    bookmark,
    setBookmark,
    threatAttorneyValue,
    setThreatAttorneyValue,
    threatProprietors,
    setThreatProprietors,
    threatTMClass,
    setThreatClass,
    similarity,
    setSimilarity,
    threatDateOfApplication,
    setThreatDateOfApplication,
    threatDateOfUsage,
    setThreatDateOfUsage,
    threatDateOfUsageProposed,
    setThreatDateOfUsageProposed,
    excludeThreatProprietors,
    setExcludeThreatProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    publishedDate,
    setPublishedDate,
    excludeShortlisted,
    setExcludeShortlisted,
    threatFilterApplied,
    setThreatFilterApplied,
    andOrThreatClass,
    setAndOrThreatClass,
    generateFilterObject,
    isAnyFilterSelected,
    threatLabel,
    setThreatLabel,
    andOrThreatLabel,
    setAndOrThreatLabel,
    getRealSearchParameters,
    threatView,
    setThreatView,
    actionDropDown: props?.actionDropDown,
    showMoreFilter: props?.showMoreFilter,
  };
  const Filters = (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <SecondaryFilter {...propsForFilters} {...props} />
        </Col>
      </Row>
    </React.Fragment>
  );

  let filterSelected = isAnyFilterSelected(generateFilterObject());

  const handleNextpage = (pageNumber) => {
    const filters = generateFilterObject();
    dispatch(
      getGridLayoutThreatData(
        props?.markDetails?.application_number,
        props?.journal,
        pageNumber,
        filterSelected,
        filters,
        window?.location,
      ),
    );
  };

  const onSelectMark = (eachThreat, checked) => {
    const threatSelected =
      props?.selectedData?.data[props.markDetails.application_number]
        ?.threats || [];

    if (checked) {
      props.setSelectedData({
        ...props.selectedData,
        data: {
          ...props.selectedData.data,
          [props.markDetails.application_number]: {
            ...props.selectedData.data[props.markDetails.application_number],
            threats: [...threatSelected, eachThreat.id],
            select_all: false,
          },
        },
        threatsCount: props.selectedData.threatsCount + 1,
      });
    } else {
      props.setSelectedData({
        ...props.selectedData,
        data: {
          ...props.selectedData.data,
          [props.markDetails.application_number]: {
            ...props.selectedData.data[props.markDetails.application_number],
            threats: threatSelected.filter((item) => item !== eachThreat.id),
            select_all: false,
          },
        },
        threatsCount: props.selectedData.threatsCount - 1,
      });
    }
  };

  const mikeDynamicComponent = (content) => {
    if (status === LOADING)
      return (
        <div
          style={{
            display: "table",
            height: "calc(100vh - 200px)",
            width: "100%",
            verticalAlign: "middle",
          }}
        >
          <Spin size="large" style={{ display: "table-cell" }} />
        </div>
      );

    if (status === ERROR)
      return (
        <div
          style={{
            display: "table",
            height: "calc(100vh - 200px)",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
          >
            <h1>{message}</h1>
          </div>
        </div>
      );
    if (status === SUCCESS && count === 0)
      return (
        <div
          style={{
            display: "table",
            height: "calc(100vh - 200px)",
            width: "100%",
          }}
        >
          <Empty style={{ display: "table-cell", verticalAlign: "middle" }} />
        </div>
      );
    return content;
  };

  const [mailObj, setMailObj] = React.useState({ visible: false });
  const [journalPageVisible, setJournalPageVisible] = React.useState(null);

  const propModals = {
    setMailObj,
    setJournalPageVisible,
    urlKey,
  };
  const { userDetails } = useSelector((state) => state?.userSubscriptions);

  const useMemoizedComponent = (Component, deps) => {
    return React.useMemo(() => Component, deps);
  };

  const Modals = (
    <>
      {useMemoizedComponent(
        <SendEmailNew
          visible={mailObj?.visible}
          onCloseModal={() => setMailObj({ visible: false })}
          product={mailObj?.product}
          subscription={[mailObj?.subscription]}
          mailContent={mailObj?.mailContent}
          report_tag={mailObj?.report_tag}
          journal_copy={urlKey === "tm_protect" ? "" : mailObj?.journal_copy}
          objectType={mailObj?.objectType}
          applicationNumber={mailObj?.applicationNumber}
          sendFrom={userDetails && userDetails?.communication_email}
          sub_section="DASHBOARD"
        />,
        [mailObj],
      )}
      {journalPageVisible !== null && (
        <TrademarkJournalPage
          journalNumber={props?.journal}
          applicationNumber={journalPageVisible}
          closeDrawer={() => setJournalPageVisible(null)}
        />
      )}
    </>
  );
  const Content =
    threatView === "table" ? (
      <ThreatList
        threatData={data || []}
        {...propsForFilters}
        {...props}
        {...propModals}
      />
    ) : (
      Object.values(data || []).map((obj) => (
        <ThreatCard
          key={obj.id}
          onSelectMark={onSelectMark}
          mark={props?.markDetails}
          eachMark={obj}
          selectAllCards={
            props?.selectedData?.select_all ||
            props?.selectedData?.data[props.markDetails.application_number]
              ?.select_all
          }
          checked={
            props?.selectedData?.data[
              props?.markDetails?.application_number
            ]?.threats?.includes(obj?.id) ||
            props?.selectedData?.select_all ||
            props?.selectedData?.data[props.markDetails.application_number]
              ?.select_all
          }
          {...props}
          {...propsForFilters}
          {...propModals}
        />
      ))
    );

  return (
    <>
      <header className="General-Header Box-Shadow">{Filters}</header>
      <div
        className={
          threatView === "table"
            ? "General-Content vertical_scroll"
            : "General-Content  Flex-Content  vertical_scroll"
        }
        style={{
          height: "calc(100% - 160px)",
        }}
      >
        {mikeDynamicComponent(Content)}
      </div>
      <footer className="General-Footer Box-Shadow">
        <Pagination
          defaultCurrent={1}
          current={pageNumber}
          total={count}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
          pageSize={100}
          showSizeChanger={false}
          showQuickJumper
          onChange={(pageNumber) => handleNextpage(pageNumber)}
        />
      </footer>
      {Modals}
    </>
  );
};

export default ThreatDetailArea;
