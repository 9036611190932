import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Row, Col, Modal, Button } from "antd";

//--------COMMON CONSTANTS--------
import { LOADING } from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import { renewalFiled } from "../../../redux/actions/managerActions/trademarkDashboard/actions";
import { resetReminder } from "../../../redux/actions/managerActions/to-do-task/actions";

class RenewalFiledModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  async confirmRenewalFiled() {
    const { renewal, application_number, pseudo, reRender, application_list } =
      this.props;
    await this.props.renewalFiled(
      renewal,
      application_number,
      pseudo,
      application_list
    );
    await this.props.onCloseModal();
    reRender();
  }

  render() {
    return (
      <div>
        <Modal
          title="Confirm Remove"
          open={this.props.visible}
          closable={false}
          footer={false}
        >
          <div>
            <Row gutter={16}>
              <Col span={24}>
                {this.props.applied_for ? (
                  <p>
                    Are you sure you want to remove{" "}
                    <span>
                      <strong>{this.props.applied_for}</strong>
                    </span>{" "}
                    from this table?
                  </p>
                ) : (
                  <p>
                    Are you sure you want to remove selected (
                    <span>
                      <strong>{this.props.count}</strong>
                    </span>
                    ) marks from this table?
                  </p>
                )}
              </Col>
              <Col span={24} className="m-top-20 alignR">
                <div>
                  <ul className="report-footer">
                    <li>
                      <Button type="default" onClick={this.props.onCloseModal}>
                        Cancel
                      </Button>
                    </li>
                    <li>
                      <Button
                        danger
                        loading={
                          this.props.renewalFiledStatus === LOADING
                            ? true
                            : false
                        }
                        onClick={() => this.confirmRenewalFiled()}
                      >
                        Confirm
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  renewalFiled,
};

function mapStateToProps(state) {
  const { renewalFiledMessage, renewalFiledStatus } =
    state.managerState.trademarkDashboardState;
  return deepFreeze({
    renewalFiledMessage,
    renewalFiledStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalFiledModal);
