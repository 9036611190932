import React, { Component } from "react";
import { Row, Col, Modal, Typography } from "antd";

const { Text } = Typography;

export default class ViewEmailTemplete extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        open={this.props.visible}
        onOk={this.props.onCloseModal}
        onCancel={this.props.onCloseModal}
        footer={false}
        maskClosable={false}
        className="custom-send-email-modal"
      >
        <div
          className="custom-send-email-container vertical_scroll"
          style={{ height: "calc(100vh - 50px)" }}
        >
          <Row className="custom-send-email">
            <Col span={24} className="custom-send-email-form">
              <div className="custom-send-email-Input-Field">
                <div
                  style={{
                    display: "inline-block",
                    padding: "5px 0px",
                    fontSize: 16,
                  }}
                >
                  <Text strong>PRODUCT :- </Text>
                  <Text>{this.props.templateContent.product}</Text>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    padding: "5px 50px",
                    fontSize: 16,
                  }}
                >
                  <Text strong>SUBSCRIPTION :- </Text>
                  <Text>{this.props.templateContent.subscription}</Text>
                </div>
              </div>
              <div className="custom-send-email-Input-Field">
                <div style={{ display: "inline-block", padding: "5px 0px" }}>
                  <Text strong>Subject :- </Text>
                  <Text>{this.props.templateContent.subject}</Text>
                </div>
              </div>
              <div className="custom-send-email-Input-Field">
                <div
                  style={{
                    padding: 15,
                    border: "1px solid #ddd",
                    borderRadius: 5,
                    minHeight: 420,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.templateContent.content,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}
