import React, { useEffect, useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import {
  Popover,
  Checkbox,
  Row,
  Col,
  Input,
  Button,
  Modal,
  Typography,
  Tooltip,
  Alert,
  notification,
} from "antd";
import { CKEditor } from "ckeditor4-react";
import EmailTemplateHandler from "../../../commonComponents/subComponents/EmailTemplateHandler";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailTemplate,
  getEmailTemplateData,
} from "../../../../redux/actions/commonActions/actions";
import { GET_EMAIL_TEMPLATE } from "../../../../redux/actions/commonActions/types";
import { createCustomReport } from "../../../../redux/actions/watchActions/watchActions";
import { gridSampleTemplateVariableObject } from "../../Pages/GridLayoutDashboard/gridSampleObject";
const { Text, Title } = Typography;

const AdvanceCombinePDF = (props) => {
  let urlKey = window.location.pathname.split("/")[2] || "";
  let dashboardLayout = window.location.pathname.split("/")[3] || "";
  const {
    open,
    onCloseModal,
    journal,
    selected,
    generateFilterObject,
    selectAll,
    source = "LIST_LAYOUT",
    creation_origin = "APPLIED_FOR",
    filterApplied,
  } = props;

  const dispatch = useDispatch();

  const { templateCount } = useSelector((state) => state.emailTemplateState);

  const { temp_threat_filters } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const { emailTemplateData } = useSelector(
    (state) => state.emailTemplateDataState,
  );

  const [editorValue, setEditorValue] = useState(
    "<p>Dear Sir/Ma'am,<p><strong>Regards</strong></p></p>",
  );
  const [fileName, setFileName] = useState("");
  const [attachLetterHead, setAttachLetterHead] = useState(false);
  const [addJournalCopy, setAddJournalCopy] = useState(false);
  const [collapseActiveKey, setCollapseActiveKey] = useState("");
  const [templateSelectedKey, setTemplateSelectedKey] = useState("");
  const [onlyTemplateKey, setOnlyTemplateKey] = useState(0);

  const getEmailTemplateVariableFunction = () => {
    if (urlKey === "tm_applied") {
      dispatch(
        getEmailTemplateData({
          subscription: "Journal Watch",
          product: "WATCH",
          object_id: selectAll ? selected[0].id : selected[0],
          sub_section: "DASHBOARD",
        }),
      );
    } else if (
      urlKey === "image_text" ||
      urlKey === "image_text_new" ||
      urlKey === "image_similarity"
    ) {
      dispatch(
        getEmailTemplateData({
          subscription: "IMAGE_RECOGNITION",
          product: "WATCH",
          object_id: selectAll ? selected[0].id : selected[0],
          sub_section: "DASHBOARD",
          image_recognition_type:
            urlKey === "image_text" || urlKey === "image_text_new"
              ? "IMAGE_TEXT_RECOGNITION_TYPE"
              : "IMAGE_SIMILARITY_RECOGNITION_TYPE",
        }),
      );
    } else if (urlKey === "tm_protect") {
      dispatch(
        getEmailTemplateData({
          subscription: "TM Protect",
          product: "WATCH",
          object_id: selectAll ? selected[0].id : selected[0],
          sub_section: "DASHBOARD",
        }),
      );
    }
  };

  const getEmailTemplateFunction = () => {
    if (urlKey === "tm_applied") {
      dispatch(
        getEmailTemplate({
          subscription: "JOURNAL WATCH",
          product: "WATCH",
          actionType: GET_EMAIL_TEMPLATE,
          sub_section: "DASHBOARD",
        }),
      );
    } else if (
      urlKey === "image_text" ||
      urlKey === "image_text_new" ||
      urlKey === "image_similarity"
    ) {
      dispatch(
        getEmailTemplate({
          subscription: "IMAGE_RECOGNITION",
          product: "WATCH",
          actionType: GET_EMAIL_TEMPLATE,
          sub_section: "DASHBOARD",
        }),
      );
    } else if (urlKey === "tm_protect") {
      dispatch(
        getEmailTemplate({
          subscription: "TM PROTECT",
          product: "WATCH",
          actionType: GET_EMAIL_TEMPLATE,
          sub_section: "DASHBOARD",
        }),
      );
    }
  };

  useEffect(() => {
    getEmailTemplateFunction();
    if (source === "LIST_LAYOUT") {
      getEmailTemplateVariableFunction();
    }
  }, []);

  const onEditorChange = (evt) => {
    setEditorValue(evt.editor.getData());
  };

  const handlePaginateTemplate = (page) => {
    if (urlKey === "tm_applied") {
      dispatch(
        getEmailTemplate({
          subscription: "JOURNAL WATCH",
          product: "WATCH",
          actionType: GET_EMAIL_TEMPLATE,
          sub_section: "DASHBOARD",
          page: page,
        }),
      );
    } else if (
      urlKey === "image_text" ||
      urlKey === "image_text_new" ||
      urlKey === "image_similarity"
    ) {
      dispatch(
        getEmailTemplate({
          subscription: "IMAGE_RECOGNITION",
          product: "WATCH",
          actionType: GET_EMAIL_TEMPLATE,
          sub_section: "DASHBOARD",
          page: page,
        }),
      );
    } else if (urlKey === "tm_protect") {
      dispatch(
        getEmailTemplate({
          subscription: "TM Protect",
          product: "WATCH",
          actionType: GET_EMAIL_TEMPLATE,
          sub_section: "DASHBOARD",
          page: page,
        }),
      );
    }
  };

  const handleSearchTemplate = (searchValue) => {
    if (urlKey === "tm_applied") {
      dispatch(
        getEmailTemplate({
          subscription: "JOURNAL WATCH",
          product: "WATCH",
          actionType: GET_EMAIL_TEMPLATE,
          sub_section: "DASHBOARD",
          contains_term: searchValue,
        }),
      );
    } else if (
      urlKey === "image_text" ||
      urlKey === "image_text_new" ||
      urlKey === "image_similarity"
    ) {
      dispatch(
        getEmailTemplate({
          subscription: "IMAGE_RECOGNITION",
          product: "WATCH",
          actionType: GET_EMAIL_TEMPLATE,
          sub_section: "DASHBOARD",
          contains_term: searchValue,
        }),
      );
    } else if (urlKey === "tm_protect") {
      dispatch(
        getEmailTemplate({
          subscription: "TM Protect",
          product: "WATCH",
          actionType: GET_EMAIL_TEMPLATE,
          sub_section: "DASHBOARD",
          contains_term: searchValue,
        }),
      );
    }
  };

  const handleCollapse = (key) => {
    setCollapseActiveKey(key);
  };

  const handleSelected = (key) => {
    setTemplateSelectedKey(key);
  };

  const handleSelectTemplate = (data) => {
    setOnlyTemplateKey(data?.id ?? 0);
    // const { report_tag, product } = this.props;
    let report_tag = [];
    let product = "WATCH";
    let tagValue = [];
    if (product === "MANAGER") {
      let oldTags = [
        "report_name",
        "status",
        "journal_number",
        "journal_date",
        "tla_hearing_date",
        "opposition_hearing_date",
        "opponent_name",
        "proprietor_name",
        "renewal_date",
        "application_number",
        "mark_name",
        "date_of_application",
        "class",
        "date_of_usage",
      ];
      tagValue = [
        "trademark_application_number",
        "trademark_name",
        "trademark_status",
        "trademark_classes",
        "date_of_application",
        "date_of_usage",
        "renewal_date",
        "g_and_s_description",
        "trademark_images",
        "journal_number",
        "journal_date",
        "proprietor_names",
        "proprietor_addresses",
        "proprietor_trading_as",
        "latest_tla_hearing_date",
        "opponent_names",
        "opponent_numbers",
        "opponent_attorney_names",
        "opponent_attorney_addresses",
        "attorney_name",
        "attorney_address",
        "latest_date_of_examination_report",
        "formatted_proprietor_addresses",
        "latest_formality_fail_date",
        "latest_formality_fail_reason",
        "latest_examination_report_objection",
        "tags",
      ];
      tagValue.push(...oldTags);
    } else if (product === "SEARCH") {
      tagValue = ["mark_name", "created_date"];
    } else if (product === "WATCH") {
      let oldTags = [
        "report_name",
        "journal_number",
        "journal_date",
        "opposition",
        "current_date",
        "your_mark_image",
        "threat_mark_image",
        "your_mark",
        "your_application",
        "your_DOA",
        "your_DOU",
        "your_class",
        "your_proprietor",
        "your_address",
        "threat_mark",
        "threat_application",
        "threat_DOA",
        "threat_DOU",
        "threat_class",
        "threat_proprietor",
        "threat_address",
        "threat_description",
        "mark_description",
      ];

      tagValue = [
        "journal_number",
        "journal_date",
        "last_date_of_filing_opposition",
        "current_date",
        "your_trademark_name",
        "your_trademark_images",
        "your_application_number",
        "your_date_of_application",
        "your_date_of_usage",
        "your_trademark_classes",
        "your_proprietor_names",
        "your_proprietor_addresses",
        "your_proprietor_trading_as",
        "your_g_and_s_description",
        "your_attorney_name",
        "your_attorney_address",
        "tags",
        "threat_trademark_name",
        "threat_trademark_images",
        "threat_application_number",
        "threat_date_of_application",
        "threat_date_of_usage",
        "threat_trademark_classes",
        "threat_proprietor_names",
        "threat_proprietor_addresses",
        "threat_proprietor_trading_as",
        "threat_g_and_s_description",
        "threat_attorney_name",
        "threat_attorney_address",
        "your_formatted_proprietor_addresses",
        "threat_formatted_proprietor_addresses",
        "latest_date_of_examination_report",
      ];
      tagValue.push(...oldTags);
    }
    let subjectText = data.subject.toString();
    let contentText = data.content.toString();
    const templateInjectData =
      source === "LIST_LAYOUT"
        ? emailTemplateData
        : gridSampleTemplateVariableObject;

    if (templateInjectData !== null) {
      tagValue.forEach((data) => {
        if (
          data === "your_trademark_images" ||
          data === "threat_trademark_images" ||
          data === "trademark_images"
        ) {
          let image = "";
          if (templateInjectData[data]?.length > 0) {
            templateInjectData[data].forEach(
              (imgUrl) =>
                (image =
                  image +
                  `<img src=${imgUrl} style="height:50px; width:50px;margin: 0px 12px ;" alt="No Image"/>`),
            );
          } else {
            image = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, image);
        } else if (data === "tags") {
          let tags = "";
          if (templateInjectData[data]?.length > 0) {
            templateInjectData[data].forEach((tag, index) => {
              if (index !== templateInjectData[data].length - 1)
                tags = tags + `<span>${tag.name}</span>,`;
              else tags = tags + `<span>${tag.name}</span>`;
            });
          } else {
            tags = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, tags);
        } else if (
          data === "your_formatted_proprietor_addresses" ||
          data === "threat_formatted_proprietor_addresses" ||
          data === "formatted_proprietor_addresses"
        ) {
          let address = "";

          if (templateInjectData[data]?.length > 0) {
            templateInjectData[data].forEach(
              (tag) =>
                (address =
                  address +
                  `<div  style="
							margin: 2px;
							" >${tag}</div>`),
            );
          } else {
            address = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, address);
        } else {
          subjectText = subjectText.replaceAll(
            `@${data}`,
            `${templateInjectData[data]}`,
          );
          contentText = contentText.replaceAll(
            `@${data}`,
            `<span>${templateInjectData[data]}</span>`,
          );
        }
      });
    } else {
      tagValue.forEach((data) => {
        if (
          data === "your_trademark_images" ||
          data === "threat_trademark_images" ||
          data === "trademark_images"
        ) {
          let image = "";
          if (report_tag[data]) {
            image =
              image +
              `<img src=${report_tag[data]} style="height:50px; width:50px;margin: 0px 12px ;" alt="No Image"/>`;
          } else {
            image = "<span>N.A.</span>";
          }
          subjectText = subjectText.replaceAll(`@${data}`, "");
          contentText = contentText.replaceAll(`@${data}`, image);
        } else {
          subjectText = subjectText.replaceAll(
            `@${data}`,
            `${report_tag[data]}`,
          );
          contentText = contentText.replaceAll(
            `@${data}`,
            `<span>${report_tag[data]}</span>`,
          );
        }
      });
    }

    window.CKEDITOR.instances.editor.setData(contentText);
    setEditorValue(contentText);
  };

  const handleDownload = () => {
    if (!onlyTemplateKey) {
      notification["warning"]({
        message: "Please select Template first.",
        duration: 2,
      });
      return;
    }

    let body = {
      ...(urlKey === "tm_protect"
        ? { cease_desist_report_id: journal }
        : { journal_number: journal }),
      filter_flag: false,
      source: urlKey === "journals" ? "Journal" : source,
      report_format: "ZIP",
      report_name: fileName,
      creation_origin: creation_origin,
      report_type: "EMAIL_TEMPLATE_PDF_ZIP",
      template_id: onlyTemplateKey,
      letter_head_visible: attachLetterHead,
      add_journal_copy: addJournalCopy,
    };
    if (source === "LIST_LAYOUT") {
      body.selected_ids = selected;
    }
    if (source === "GRID_LAYOUT") {
      const secondaryFilteredData = {};
      Object.entries(selected?.data).map((item) => {
        if (item[1]?.select_all || item[1]?.threats?.length > 0) {
          secondaryFilteredData[item[0]] = {
            filter_flag: item[1]?.select_all || false,
            selected_ids: item[1]?.threats || [],
            filters:
              (temp_threat_filters[urlKey] &&
                temp_threat_filters[urlKey][item[0]] &&
                temp_threat_filters[urlKey][item[0]]) ||
              {},
          };
        }
      });
      body.data = secondaryFilteredData;
    }
    if (selectAll) {
      let newFilterObject = generateFilterObject();
      if (urlKey === "journals") {
        delete newFilterObject.threat_similarity;
      }
      let modifiedBody = {
        ...body,
        filter_flag: true,
        selected_ids: [],
        filters: newFilterObject,
      };
      dispatch(createCustomReport(modifiedBody));
      onCloseModal();
    } else {
      dispatch(createCustomReport(body));
      onCloseModal();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCloseModal}
      onOk={onCloseModal}
      closable={false}
      footer={false}
      maskClosable={false}
      className="custom-send-email-modal"
      bodyStyle={{
        borderRadius: "24px",
      }}
      title={null}
    >
      <Row className="custom-send-email">
        <Col xs={{ span: 12 }} className="custom-send-email-form">
          <Title level={4}>Bulk Template Download</Title>
          <div>
            <Row>
              <Col span={22}>
                <div className="text-editor">
                  <CKEditor
                    initData={editorValue}
                    name="editor"
                    readOnly
                    onChange={onEditorChange}
                    editorUrl={
                      "https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"
                    }
                    config={{
                      allowedContent: true,
                      extraAllowedContent: "div(*)",
                      extraPlugins: ["editorplaceholder"],
                      editorplaceholder: "Start typing here...",
                      toolbarGroups: [],
                      // toolbarGroups: [
                      //   { name: "basicstyles", groups: ["basicstyles"] },
                      //   { name: "paragraph", groups: ["list", "align"] },
                      //   { name: "links" },
                      //   { name: "insert", groups: ["table"] },
                      //   { name: "styles" },
                      //   { name: "links" },
                      // ],
                      removeButtons:
                        "Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar,PasteFromWord",
                      autoGrow_onStartup: true,
                      height: "40vh",
                      // height: "30vh",
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <Row style={{ marginTop: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h6>Name the File</h6>
              <Input
                value={fileName}
                prefix={
                  <FileTextOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="text"
                placeholder="File Name"
                style={{
                  width: "300px",
                }}
                size="large"
                onChange={(e) => {
                  if (e?.target?.value.includes("/")) {
                    return null;
                  } else {
                    setFileName(e?.target?.value);
                  }
                }}
              />
            </div>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Alert
              type="warning"
              style={{ color: "#FFA700" }}
              message="Note : Only 50 Comparisons can be selected while adding a journal copy."
            />
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Checkbox
              checked={attachLetterHead}
              onChange={(e) => setAttachLetterHead(e.target.checked)}
            >
              Attach Letterhead
            </Checkbox>
            <Checkbox
              checked={addJournalCopy}
              onChange={(e) => setAddJournalCopy(e.target.checked)}
            >
              Add Journal Copy
            </Checkbox>
          </Row>

          <Row
            style={{
              margin: "12px 0px",
            }}
          >
            <Col span={23}>
              <Button
                type="primary"
                key="submit"
                htmlType="submit"
                onClick={() => handleDownload()}
                size="large"
              >
                <i
                  className="fa fa-download"
                  style={{ padding: "0px 5px" }}
                  aria-hidden="true"
                ></i>
                Generate PDF
              </Button>
              &emsp;
              <Button danger size="large" onClick={() => onCloseModal()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 12 }}
          className="custom-send-email-form"
        >
          <Title level={4}>Use Email Template</Title>

          <EmailTemplateHandler
            activeKey={collapseActiveKey}
            selectedKey={templateSelectedKey}
            handleSelectTemplate={(data) => handleSelectTemplate(data)}
            handleSearchTemplate={(data) => handleSearchTemplate(data)}
            handlePaginateTemplate={(data) => handlePaginateTemplate(data)}
            handleSelected={(data) => handleSelected(data)}
            handleCollapse={(data) => handleCollapse(data)}
            count={templateCount}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AdvanceCombinePDF;
