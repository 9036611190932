import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { PlusCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
// import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Modal,
  Input,
  Radio,
  Checkbox,
  TimePicker,
  Button,
  Tag,
  message,
  Tooltip,
  Typography,
  Form,
  Spin,
} from "antd";

//--------ACTIONS--------
import {
  createWorkflowTask,
  editWorkflowTask,
} from "../../../redux/actions/managerActions/workflow/action";

//--------COMMON CONSTANTS--------
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
const { Title } = Typography;

const WorkflowTodoTaskModal = ({
  visible,
  workflowId,
  action,
  actionType,
  taskData,
  editTaskFlag,
  onCloseModal,
}) => {
  const [state, setState] = useState({
    visible_modal: false,
    action: "",
    actionType: "",
    editTask_Flag: false,
    workflow_id: "",
    taskId: "",
    due_date_after_these_days: 1,
    maximum_reminder: 1,
    timing_of_reminder: null,
    days_between_two_reminders: 1,
    create_reminder_after_these_days: 1,
    active: false,
    title: "",
    description: "",
    priority: "Low",
    emails: "",
    userEmails: "",
    emailData: [],
    emailInputValue: "",
  });

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.managerState.workflowState);
  const { workflowTodoTaskStatus, workflowTodoTaskMessage } = selector;
  const [loader, setLoader] = useState(true);
  const initialValues = {
    visible_modal: false,
    action: "",
    actionType: "",
    editTask_Flag: false,
    workflow_id: "",
    taskId: "",
    due_date_after_these_days: 1,
    maximum_reminder: 1,
    timing_of_reminder: null,
    days_between_two_reminders: 1,
    create_reminder_after_these_days: 1,
    active: false,
    title: checkValue(taskData) ? taskData.title : "",
    description: "",
    priority: "Low",
    emails: "",
    userEmails: "",
    emailData: [],
    emailInputValue: "",
  };

  useEffect(() => {
    setLoader(true);
    setState({
      workflow_id: checkValue(workflowId) ? workflowId : "",
      taskId: checkValue(taskData) ? taskData.id : "",
      action: action,
      actionType: actionType,
      editTask_Flag: checkValue(editTaskFlag) ? editTaskFlag : false,
      due_date_after_these_days: checkValue(taskData)
        ? taskData.due_date_after_these_days
        : 1,
      maximum_reminder: checkValue(taskData) ? taskData.maximum_reminder : 1,
      timing_of_reminder: checkValue(taskData)
        ? dayjs(taskData?.timing_of_reminder, "HH:mm")
        : dayjs(),
      days_between_two_reminders: checkValue(taskData)
        ? taskData.days_between_two_reminders
        : 1,
      create_reminder_after_these_days: checkValue(taskData)
        ? taskData.create_reminder_after_these_days
        : 1,
      active: checkValue(taskData) ? taskData.is_active : false,
      title: checkValue(taskData) ? taskData.title : "",
      description: checkValue(taskData) ? taskData.description : "",
      priority: checkValue(taskData) ? taskData.priority : "Low",
      emails: checkValue(taskData) ? `${taskData.emails}` : "",
      emailData: checkValue(taskData) ? taskData.emails : "",
    });
    setLoader(false);
  }, [taskData, workflowId]);

  const concatFieldValue = () => {
    if (userEmails.trim() !== "") {
      const data = [...state.emailData, state.userEmails.trim("")];
      setState((prevState) => ({
        ...prevState,
        emailData: data,
      }));
      formRef.current.setFieldValue("email", "");
    }
  };

  const removeFieldValue = (data) => {
    const updatedData = state.emailData.filter((email) => email !== data);
    setState((prevState) => ({
      ...prevState,
      emailData: updatedData,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await formRef?.current?.validateFields().then(async (values) => {
      const formData = {
        ...values,
        timing_of_reminder: dayjs(state.timing_of_reminder, "HH:mm").format(
          "HH:mm"
        ),
        emailData: values.emailData,
        taskId: values.taskId,
        workflow_id: values.workflow_id,
        editTaskFlag: values.editTask_Flag,
        due_date_after_these_days: parseInt(values.due_date_after_these_days),
        maximum_reminder: parseInt(values.maximum_reminder),
        days_between_two_reminders: parseInt(values.days_between_two_reminders),
        create_reminder_after_these_days: parseInt(
          values.create_reminder_after_these_days
        ),
        active: values.active,
      };

      if (values) {
        let action_value = action;
        let action_Type = actionType;
        let workflowID = workflowId;
        let task_Id = checkValue(state.taskId) ? state.taskId : "";
        let emails = checkValue(state.emailData) ? `${state.emailData}` : "";

        if (workflowID) {
          await dispatch(
            createWorkflowTask({
              ...formData,
              actionType: action_Type,
              workflow_id: workflowID,
              action: action_value,
              emails: emails,
            })
          );
          formRef.current.setFieldsValue(initialValues);
          setState({ emailData: [] });
        } else if (task_Id) {
          await dispatch(
            editWorkflowTask({
              ...formData,
              actionType: action_Type,
              task_id: task_Id,
              action: action_value,
              emails: emails,
            })
          );
        }
      }
    });
  };

  const onCloseTask = () => {
    setState({ emailData: [] });
    formRef?.current?.resetFields();
    onCloseModal();
  };

  const handleEmailInputAdd = () => {
    concatFieldValue();
  };

  const handleEmailInputRemove = (email) => {
    removeFieldValue(email);
  };

  const {
    priority,
    active,
    title,
    description,
    emailData,
    userEmails,
    due_date_after_these_days,
    maximum_reminder,
    create_reminder_after_these_days,
    days_between_two_reminders,
    timing_of_reminder,
    editTask_Flag,
  } = state;

  return (
    <div>
      <Modal
        title={
          <div className="alignC">
            <Title level={4} style={{ margin: 0 }}>
              {editTask_Flag ? "Edit" : "Create"} Workflow Todo Task
            </Title>
          </div>
        }
        open={visible}
        closable={false}
        width="60%"
        footer={[
          <Button key="back" onClick={() => onCloseTask()}>
            Cancel
          </Button>,
          <Button
            htmlType="submit"
            type="primary"
            loading={workflowTodoTaskStatus === LOADING}
            onClick={(e) => handleSubmit(e)}
          >
            {editTask_Flag ? "Edit" : "Create"} Task
          </Button>,
        ]}
      >
        <div>
          {/* <Row> */}
          {loader ? (
            <Spin />
          ) : (
            <Col>
              <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                initialValues={editTaskFlag ? state : initialValues}
                ref={formRef}
              >
                <Form.Item label="Priority" name="priority">
                  <Radio.Group
                    style={{ marginTop: "10px" }}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        priority: e.target.value,
                      }))
                    }
                    value={priority}
                  >
                    <Radio value="High">High</Radio>
                    <Radio value="Medium">Medium</Radio>
                    <Radio value="Low">Low</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Active" name="active" valuePropName="checked">
                  <Checkbox
                    style={{ marginTop: "10px" }}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        active: e.target.checked,
                      }))
                    }
                    checked={active}
                  />
                </Form.Item>
                <Form.Item
                  label="Task Name"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter task title!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "90%" }}
                    value={title}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }))
                    }
                    type="text"
                    placeholder="Task Name"
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  label="Task Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter task description!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "90%" }}
                    type="text"
                    value={description}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }))
                    }
                    placeholder="Task Description"
                    allowClear
                  />
                </Form.Item>

                <div className="ant-row">
                  <div className="ant-col ant-col-6 not-visible" />
                  <div className="ant-col ant-col-18">
                    {emailData &&
                      emailData?.length > 0 &&
                      emailData?.map((emData, idx) => (
                        <Tag
                          key={idx}
                          style={{ marginRight: "5px", marginBottom: "5px" }}
                          closable
                          onClose={() => handleEmailInputRemove(emData)}
                        >
                          {emData}
                        </Tag>
                      ))}
                  </div>
                </div>
                <Form.Item label="Email Address" name="email">
                  <Input
                    style={{ width: "90%" }}
                    type="email"
                    placeholder="Email Address"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        userEmails: e.target.value,
                      }))
                    }
                    allowClear
                    value={state.userEmails}
                    addonAfter={
                      <Tooltip title="Click here to add emails">
                        <PlusCircleOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => handleEmailInputAdd()}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Due Date After These Days"
                  name="due_date_after_these_days"
                  rules={[
                    { required: true, message: "Please enter due date" },
                    {
                      pattern: /^[1-9][0-9]*$/,
                      message: "Number must be greater than zero",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "90%" }}
                    type="number"
                    placeholder="Due Date After These Days"
                    allowClear
                    value={due_date_after_these_days}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        due_date_after_these_days: e.target.value,
                      }))
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Maximum Reminder"
                  name="maximum_reminder"
                  rules={[
                    {
                      required: true,
                      message: "Please enter maximum reminder",
                    },
                    {
                      pattern: /^[1-9][0-9]*$/,
                      message: "Number must be greater than zero",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "90%" }}
                    type="number"
                    placeholder="Maximum Reminder"
                    allowClear
                    minimum={1}
                    value={maximum_reminder}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        maximum_reminder: e.target.value,
                      }))
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Create Reminder After These Days"
                  name="create_reminder_after_these_days"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a value for create Reminder",
                    },
                    {
                      pattern: /^[1-9][0-9]*$/,
                      message: "Number must be greater than zero",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "90%" }}
                    type="number"
                    placeholder="Create Reminder After These Days"
                    allowClear
                    min={1}
                    value={create_reminder_after_these_days}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        create_reminder_after_these_days: e.target.value,
                      }))
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Days Between Two Reminders"
                  name="days_between_two_reminders"
                  rules={[
                    {
                      required: true,
                      message: "Please enter value for days between reminders",
                    },
                    {
                      pattern: /^[1-9][0-9]*$/,
                      message: "Number must be greater than zero",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "90%" }}
                    type="number"
                    placeholder="Days Between Two Reminders"
                    allowClear
                    min={1}
                    value={days_between_two_reminders}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        days_between_two_reminders: e.target.value,
                      }))
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Timing of Reminder"
                  name="timing_of_reminder"
                  rules={[
                    {
                      required: true,
                      message: "Please select a timing of reminder",
                    },
                  ]}
                >
                  <TimePicker
                    format="HH:mm"
                    style={{ width: "90%" }}
                    placeholder="Select Time"
                    value={
                      timing_of_reminder
                        ? dayjs(timing_of_reminder, "HH:mm")
                        : null
                    }
                    onSelect={(value) => {
                      const timeString = dayjs(value).format("HH:mm");
                      setState((prevState) => ({
                        ...prevState,
                        timing_of_reminder: timeString,
                      }));
                    }}
                    onChange={(time, timeString) => {
                      setState((prevState) => ({
                        ...prevState,
                        timing_of_reminder: dayjs(timeString, "HH:mm").format(
                          "HH:mm"
                        ),
                      }));
                    }}
                  />
                </Form.Item>
              </Form>
            </Col>
          )}
          {/* </Row> */}
        </div>
        {/* Form content */}
      </Modal>
    </div>
  );
};

export default WorkflowTodoTaskModal;
