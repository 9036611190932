//--------LIBRARIES--------
import { combineReducers } from "redux";

//--------REDUCERS--------
import searchBar from "./searchBar";
import trademarksSearch from "./trademarksSearch";
import proprietorsSearch from "./proprietorsSearch";
import trademarkProfile from "./trademarkProfile";
import proprietorProfile from "./proprietorProfile";
import report from "./reportReducer";

export default combineReducers(
    {
        searchBar,
        trademarksSearch,
        trademarkProfile,
        proprietorsSearch,
        proprietorProfile,
        report
    }
);