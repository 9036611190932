//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Modal,
  Button,
  Input,
  Tabs,
  Alert,
  message,
  Typography,
  notification,
  Card,
  Select,
  List,
} from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE FUNCTIONS--------
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

//--------ACTIONS--------
import {
  markUpload,
  clearError,
} from "../../../redux/actions/commonActions/actions";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const link = {
  your_trademark:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/YourTrademark.xlsx",
  your_trademark_w_tag:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/YourTrademarkWithTags.xlsx",
  opposed_trademark:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/3rdPartyTrademark.xlsx",
  opposed_trademark_w_tag:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/3rdPartyTrademarkWithTags.xlsx",
  st_trademark:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/StatusTrackerTrademark.xlsx",
  st_trademark_w_tags:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/StatusTrackerTrademarkWithTags.xlsx",
  application:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/OnlyApplication.xlsx",
  application_w_tag:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ApplicationWithTag.xlsx",
  application_w_fw:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ApplicationWithFocusWord.xlsx",
  application_w_fw_a_tag:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ApplicationWithFocusWordAndTag.xlsx",
  application_w_fw_a_fc:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ApplicationWithFocusWordAndFocusClass.xlsx",
  application_w_fw_a_fc_a_tag:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ApplicationWithFocusWordAndFocusClassAndTag.xlsx",
  application_w_fc:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ApplicationWithFocusClass.xlsx",
  application_w_fc_a_tag:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ApplicationWithFocusClassAndTag.xlsx",
};

class AddTrademarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "manually",
      file: "",
      fileValue: "",
      uploadFileError: false,
      trademarkType_manually:
        this.props.product === "tm-manager" ? undefined : "application",
      trademarkType_via_excel:
        this.props.product === "tm-manager" ? undefined : "application",
    };
  }

  componentDidMount() {
    this.props.clearError();
  }

  onUploadDocument(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const isExcel = file.name.includes(".xlsx", ".xls");
    if (!isExcel) {
      message.error("Excel files are accepted only");
    }
    const isFileSize = file.size / (1024 * 1024) < 5;
    if (!isFileSize) {
      message.error("Excel file must be smaller than 5MB");
    }
    if (isExcel && isFileSize) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          uploadFileError: false,
        });
      };
      reader.readAsDataURL(file);
      this.setState({
        fileValue: e.target.value,
      });
    } else {
      e.target.value = "";
      this.setState({
        file: "",
        fileValue: "",
        uploadFileError: true,
      });
    }
  }

  async handleAddTrademark(e) {
    e.preventDefault();

    switch (this.state.activeTab) {
      case "manually":
        this.props.form.validateFields(async (err, values) => {
          if (!err?.requested_trademarks && !err?.trademarkType_manually) {
            let requested_trademarks = checkValue(
              values["requested_trademarks"],
            )
              ? values["requested_trademarks"]
              : "";
            let response = await this.props.markUpload({
              platform: this.props.product,
              trademarkType: this.state.trademarkType_manually,
              requested_trademarks,
            });

            if (response === SUCCESS) {
              this.uploadMarkMessage(SUCCESS);
              this.onCloseAddMark();
            } else {
              this.uploadMarkMessage(ERROR);
            }
          }
        });
        break;

      case "via-excel":
        this.props.form.validateFields(async (err, values) => {
          if (!err?.trademarkType_via_excel) {
            let uploaded_file = checkValue(this.state.file)
              ? this.state.file
              : "";
            if (uploaded_file === "") {
              this.setState({
                uploadFileError: true,
              });
            } else {
              let response = await this.props.markUpload({
                platform: this.props.product,
                uploaded_file: uploaded_file,
                trademarkType: this.state.trademarkType_via_excel,
              });

              if (response === SUCCESS) {
                this.uploadMarkMessage(SUCCESS);
                this.onCloseAddMark();
              } else {
                this.uploadMarkMessage(ERROR);
              }
            }
          }
        });
        break;

      default:
        break;
    }
  }

  uploadMarkMessage(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Trademarks Uploaded",
          description:
            "The system is processing your trademarks. Once done, you will be notified via email regarding their availability.",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Uploading",
          description:
            "Unfortunately, an error occured while uploading. Please, try again later.",
        });
        break;

      default:
        break;
    }
  }

  onCloseAddMark() {
    this.props.clearError();
    this.props.onCloseModal();
    this.props.form.resetFields();
    this.setState({
      file: "",
      fileValue: "",
      uploadFileError: false,
      trademarkType_manually:
        this.props.product === "tm-manager" ? undefined : "application",
      trademarkType_via_excel:
        this.props.product === "tm-manager" ? undefined : "application",
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Modal
          title={
            <div className="alignC">
              {this.props.product === "pt-manager" ? (
                <Text strong style={{ fontSize: "16px" }}>
                  ADD PATENTS
                </Text>
              ) : (
                <Text strong style={{ fontSize: "16px" }}>
                  ADD TRADEMARKS
                </Text>
              )}
            </div>
          }
          open={this.props.visible}
          closable={false}
          width={600}
          footer={[
            <Button type="default" onClick={() => this.onCloseAddMark()}>
              Cancel
            </Button>,
            <Button
              type="primary"
              loading={this.props.markUploadStatus === LOADING ? true : false}
              onClick={(e) => this.handleAddTrademark(e)}
            >
              Add
            </Button>,
          ]}
        >
          <Tabs
            type="card"
            className="add-mark-tab"
            activeKey={this.state.activeTab}
            onChange={(activeKey) => {
              this.props.form.resetFields();
              this.setState({
                activeTab: activeKey,
                trademarkType_manually: undefined,
                trademarkType_via_excel: undefined,
              });
            }}
          >
            <TabPane tab="MANUALLY" key="manually">
              <Card>
                <div>
                  <Form>
                    {" "}
                    {this.props.markUploadStatus === ERROR && (
                      <>
                        <Form.Item>
                          <Alert
                            message={this.props.markUploadData.message}
                            type="error"
                            showIcon
                          />
                          <div style={{ maxHeight: "160px", overflow: "auto" }}>
                            {this.props.markUploadData.errors && (
                              <List
                                size="small"
                                bordered
                                dataSource={this.props.markUploadData.errors}
                                renderItem={(item, index) => (
                                  <Alert
                                    message={`${index + 1}. ${item}`}
                                    style={{ margin: "16px" }}
                                    banner
                                  />
                                )}
                              />
                            )}
                          </div>
                        </Form.Item>
                      </>
                    )}
                    <Form.Item>
                      {this.props.product === "tm-manager" &&
                        getFieldDecorator("trademarkType_manually", {
                          rules: [
                            {
                              required: true,
                              message: "Please select trademark type!",
                            },
                          ],
                          initialValue:
                            this.state.trademarkType_manually ?? undefined,
                        })(
                          <Select
                            placeholder="Select a Trademark Type"
                            onChange={(val) =>
                              this.setState({ trademarkType_manually: val })
                            }
                          >
                            <Option value="your_trademark">
                              Your Trademark
                            </Option>
                            <Option value="opposed_trademark">
                              3rd Party Trademark
                            </Option>
                            <Option value="st_trademark">Status Tracker</Option>
                          </Select>,
                        )}
                    </Form.Item>
                    {this.props.product === "tm-manager" ? (
                      <Form.Item>
                        {/* <Text strong>Application Number</Text> */}
                        {getFieldDecorator("requested_trademarks", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter the application number",
                            },
                            {
                              pattern: /^([0-9]+[\s]?[,]?[\s]?)+$/gm,
                              message:
                                "Please enter comma seperated application numbers",
                            },
                          ],
                        })(
                          <Input
                            type="text"
                            allowClear
                            placeholder="Enter application numbers"
                          />,
                        )}
                        <Text>
                          Enter comma seperated application numbers: (Example-
                          1234567, 3456789)
                        </Text>
                      </Form.Item>
                    ) : (
                      <Form.Item>
                        {getFieldDecorator("requested_trademarks", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter the application number",
                            },
                            {
                              pattern: /^([a-zA-Z0-9])[\/a-zA-Z0-9\s,]*$/gm,
                              message: "Please enter valid application number",
                            },
                          ],
                        })(
                          <Input
                            type="text"
                            allowClear
                            placeholder="Enter application numbers"
                          />,
                        )}
                        <Text>
                          Enter comma seperated application numbers: (Example-
                          1234567, 3456789, 6283/CHE/2015)
                        </Text>
                      </Form.Item>
                    )}
                  </Form>
                </div>
              </Card>
            </TabPane>
            <TabPane tab="VIA EXCEL" key="via-excel">
              <Card>
                <>
                  <Form>
                    {this.props.markUploadStatus === ERROR && (
                      <>
                        <Form.Item>
                          <Alert
                            message={this.props.markUploadData.message}
                            type="error"
                            showIcon
                          />
                          <div style={{ maxHeight: "160px", overflow: "auto" }}>
                            {this.props.markUploadData.errors && (
                              <List
                                size="small"
                                bordered
                                dataSource={this.props.markUploadData.errors}
                                renderItem={(item, index) => (
                                  <Alert
                                    message={`${index + 1}. ${item}`}
                                    style={{ margin: "16px" }}
                                    banner
                                  />
                                )}
                              />
                            )}
                          </div>
                        </Form.Item>
                      </>
                    )}
                    {this.props.product === "tm-manager" && (
                      <Form.Item>
                        {getFieldDecorator("trademarkType_via_excel", {
                          rules: [
                            {
                              required: true,
                              message: "Please select trademark type!",
                            },
                          ],
                          initialValue:
                            this.state.trademarkType_via_excel ?? undefined,
                        })(
                          <Select
                            placeholder="Select a Trademark Type"
                            style={{ width: "100%", marginBottom: "15px" }}
                            onChange={(val) =>
                              this.setState({ trademarkType_via_excel: val })
                            }
                          >
                            <Option value="your_trademark">
                              Your Trademark
                            </Option>
                            <Option value="your_trademark_w_tag">
                              Your Trademark with Tags
                            </Option>
                            <Option value="opposed_trademark">
                              3rd Party Trademark
                            </Option>
                            <Option value="opposed_trademark_w_tag">
                              3rd Party Trademark with Tags
                            </Option>
                            <Option value="st_trademark">
                              Status Tracker Trademark
                            </Option>
                            <Option value="st_trademark_w_tags">
                              Status Tracker Trademark with Tags
                            </Option>
                          </Select>,
                        )}
                      </Form.Item>
                    )}

                    {this.props.product === "tm-watch" && (
                      <Form.Item>
                        {getFieldDecorator("trademarkType_via_excel", {
                          rules: [
                            {
                              required: true,
                              message: "Please select trademark type!",
                            },
                          ],
                          initialValue:
                            this.state.trademarkType_via_excel ?? undefined,
                        })(
                          <Select
                            placeholder="Select a Trademark Type"
                            style={{ width: "100%", marginBottom: "15px" }}
                            onChange={(val) =>
                              this.setState({ trademarkType_via_excel: val })
                            }
                          >
                            <Option value="application">
                              Only Application
                            </Option>
                            <Option value="application_w_tag">
                              Only Application with Tag
                            </Option>
                            <Option value="application_w_fw">
                              Application with Focus Word
                            </Option>
                            <Option value="application_w_fw_a_tag">
                              Application with Focus Word and Tag
                            </Option>
                            <Option value="application_w_fw_a_fc">
                              Application with Focus Word and Focus Class
                            </Option>
                            <Option value="application_w_fw_a_fc_a_tag">
                              Application with Focus Word and Focus Class with
                              Tag
                            </Option>
                            <Option value="application_w_fc">
                              Application with Focus Class
                            </Option>
                            <Option value="application_w_fc_a_tag">
                              Application with Focus Class with Tag
                            </Option>
                          </Select>,
                        )}
                      </Form.Item>
                    )}

                    {this.props.product === "pt-manager" && (
                      <Form.Item>
                        {getFieldDecorator("trademarkType_via_excel", {
                          rules: [
                            {
                              required: true,
                              message: "Please select trademark type!",
                            },
                          ],
                          initialValue:
                            this.state.trademarkType_via_excel ?? undefined,
                        })(
                          <Select
                            placeholder="Select a Trademark Type"
                            style={{ width: "100%" }}
                            onChange={(val) =>
                              this.setState({ trademarkType_via_excel: val })
                            }
                          >
                            <Option value="application">Application</Option>
                            <Option value="application_w_tag">
                              Application with Tag
                            </Option>
                          </Select>,
                        )}
                      </Form.Item>
                    )}

                    {/* <Text >Excel Format: </Text> */}
                    {(this?.state?.trademarkType_manually ||
                      this?.state?.trademarkType_via_excel) && (
                      <a
                        style={{ color: "auto" }}
                        href={
                          this.state.activeTab === "manually"
                            ? link[
                                this?.state?.trademarkType_manually ??
                                  "your_trademark"
                              ]
                            : link[
                                this?.state?.trademarkType_via_excel ??
                                  "application"
                              ]
                        }
                      >
                        Download Sample Excel
                      </a>
                    )}
                    <div style={{ marginTop: "10px" }}>
                      <Text strong>
                        Upload an excel consisting of all your application
                        numbers:
                      </Text>
                      <div>
                        <Input
                          type="file"
                          id="input-tradmark-File"
                          className={`choose-input-file ${
                            this.state.uploadFileError ? "error" : ""
                          }`}
                          value={this.state.fileValue}
                          onChange={(e) => this.onUploadDocument(e)}
                          style={{
                            backgroundColor: "#ffffff",
                            marginTop: "10px",
                          }}
                        />
                        <Text
                          type="danger"
                          className={
                            this.state.uploadFileError
                              ? "visible"
                              : "not-visible"
                          }
                        >
                          Please upload Excel File
                        </Text>
                      </div>
                    </div>
                  </Form>
                </>
              </Card>
            </TabPane>
          </Tabs>
        </Modal>
      </>
    );
  }

  componentWillUnmount() {}
}

const mapDispatchToProps = {
  markUpload,
  clearError,
};

function mapStateToProps(state) {
  return deepFreeze({
    markUploadData: state.signInState.markUploadData,
    markUploadStatus: state.signInState.markUploadStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(AddTrademarks));
