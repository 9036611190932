//--------LIBRARIES--------
import Axios from "axios";
import { notification } from "antd";

//--------RT_CONFIG--------
import { SRCH_RT_URL } from "../../../configs/rootConfigs";

//--------SEARCH TYPES--------
import {
  TP_GET_TRADEMARK_PROFILE,
  TS_GET_SEARCH_RESULTS,
  SET_SEARCH_INFO,
  SRCH_GET_USER_TMS,
  TS_GET_SEARCH_ANALYSIS,
  PP_GET_PROPRIETOR_MARKS,
  PP_GET_PROPRIETOR_DATA,
  PP_GET_PROPRIETOR_ANALYSIS,
  SRCH_REPORT,
  TS_SET_ANALYSIS_CANCEL,
  GET_SRCH_USER_TMS,
} from "./types";

//--------COMMON IP-SUITE CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../commonActions/actions";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

//--------Get user's trademarks--------
export function getUserTrademarks() {
  return async (dispatch) => {
    dispatch({ type: SRCH_GET_USER_TMS, status: LOADING });
    let response;
    try {
      response = await Axios.get(`${SRCH_RT_URL}/marks/`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      switch (response.status) {
        case 200:
        case 201:
          if (response?.data.quota_details) {
            notification[
              checkValue(response?.data.quota_details.message_type)
                ? response?.data.quota_details.message_type
                : "error"
            ]({
              message: "SEARCH QUOTA",
              description: checkValue(response?.data.quota_details.message)
                ? response?.data.quota_details.message
                : "",
              duration: 10,
            });
          }
          dispatch({
            type: SRCH_GET_USER_TMS,
            status: SUCCESS,
            payload: checkValue(response?.data?.user_searched_marks)
              ? response?.data?.user_searched_marks
              : [],
            quota: checkValue(response?.data?.report_quota_details)
              ? response?.data?.report_quota_details
              : null,
          });
          break;

        default:
          notification["error"]({
            message: "Unexpected Response! Get User Trademarks",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          dispatch({ type: SRCH_GET_USER_TMS, status: ERROR });
          break;
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      notification["error"]({
        message: "Error! Get User Trademarks ",
        description:
          "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
      });
      dispatch({ type: SRCH_GET_USER_TMS, status: ERROR });
    }
  };
}

export function getUserTrademarksInSearch({ page, filter_contains_term }) {
  return async (dispatch) => {
    dispatch({ type: SRCH_GET_USER_TMS, status: LOADING });
    let response;
    try {
      response = await Axios.get(`${SRCH_RT_URL}/marks/`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
        params: {
          page: page,
          filter_contains_term,
        },
      });

      switch (response.status) {
        case 200:
        case 201:
          if (response?.data.quota_details) {
            notification[
              checkValue(response?.data.quota_details.message_type)
                ? response?.data.quota_details.message_type
                : "error"
            ]({
              message: "SEARCH QUOTA",
              description: checkValue(response?.data.quota_details.message)
                ? response?.data.quota_details.message
                : "",
              duration: 10,
            });
          }
          dispatch({
            type: SRCH_GET_USER_TMS,
            status: SUCCESS,
            payload: checkValue(response?.data?.user_searched_marks?.data)
              ? response?.data?.user_searched_marks?.data
              : [],
            count: checkValue(response?.data?.user_searched_marks?.count)
              ? response?.data?.user_searched_marks?.count
              : [],
            page,
            filter_contains_term,
            quota: checkValue(response?.data?.report_quota_details)
              ? response?.data?.report_quota_details
              : null,
          });
          break;

        default:
          notification["error"]({
            message: "Unexpected Response! Get User Trademarks",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          dispatch({ type: SRCH_GET_USER_TMS, status: ERROR });
          break;
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      notification["error"]({
        message: "Error! Get User Trademarks ",
        description:
          "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
      });
      dispatch({ type: SRCH_GET_USER_TMS, status: ERROR });
    }
  };
}

// export function getSearchTrademarks() {
//   return async (dispatch) => {
//     dispatch({ type: GET_SRCH_USER_TMS, status: LOADING });
//     let response;
//     try {
//       response = await Axios.get(`${SRCH_RT_URL}/marks/`, {
//         headers: { Authorization: "Token " + localStorage.getItem("token") },
//       });

//       switch (response.status) {
//         case 200:
//         case 201:
//           dispatch({
//             type: GET_SRCH_USER_TMS,
//             status: SUCCESS,
//             payload: response?.data,
//           });
//           break;

//         default:
//           notification["error"]({
//             message: "Unexpected Response! Get User Trademarks",
//             description:
//               "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
//           });
//           dispatch({ type: GET_SRCH_USER_TMS, status: ERROR });
//           break;
//       }
//     } catch (err) {
//       checkAuthorisation(dispatch, err);
//       notification["error"]({
//         message: "Error! Get User Trademarks ",
//         description:
//           "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
//       });
//       dispatch({ type: GET_SRCH_USER_TMS, status: ERROR });
//     }
//   };
// }

//--------Post user's trademark--------
export function postUserTrademark(mark_term) {
  return async (dispatch) => {
    let response;
    try {
      response = await Axios.post(
        `${SRCH_RT_URL}/marks/`,
        {
          mark_term,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );

      switch (response.status) {
        case 200:
        case 201:
          dispatch({
            type: SRCH_GET_USER_TMS,
            status: SUCCESS,
            payload: checkValue(response?.data?.user_searched_marks?.data)
              ? response?.data?.user_searched_marks?.data
              : [],
            count: checkValue(response?.data?.user_searched_marks?.count)
              ? response?.data?.user_searched_marks?.count
              : [],
          });
          return {
            status: SUCCESS,
            data: response?.data,
          };

        default:
          notification["error"]({
            message: "Unexpected Response! Post User Trademarks",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          dispatch({ type: SRCH_GET_USER_TMS, status: ERROR });
          return {
            status: ERROR,
            data: [],
          };
      }
    } catch (err) {
      // checkAuthorisation(dispatch, err);
      switch (err.response.request.status) {
        case 400:
          notification["error"]({
            message: err?.response?.data?.error,
            description: err?.response?.data?.error,
          });
          dispatch({ type: SRCH_GET_USER_TMS, status: ERROR });
          return {
            status: ERROR,
            data: [],
          };
        default:
          notification["error"]({
            message: "Unexpected Response! Post User Trademarks",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          dispatch({ type: SRCH_GET_USER_TMS, status: ERROR });
          return {
            status: ERROR,
            data: [],
          };
      }
    }
  };
}

//--------Get search results Data/Details--------
export function getSearchResults(
  searchParameters,
  searchTab,
  axiosToken,
  fetchAnalysis = false,
) {
  return async (dispatch) => {
    // if (searchParameters.search_type !== "ipIndia") {
    dispatch({
      type: TS_GET_SEARCH_RESULTS,
      status: LOADING,
      searchTab,
      searchParameters,
    });
    // }

    let response;
    try {
      response = await Axios.get(`${SRCH_RT_URL}/trademarks/`, {
        cancelToken: axiosToken,
        params: searchParameters,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      switch (response.status) {
        case 200:
        case 201:
          if (searchParameters.search_type !== "ipIndia") {
            dispatch({
              type: TS_GET_SEARCH_RESULTS,
              status: SUCCESS,
              payload: response?.data,
              searchTab,
              pageNumber: searchParameters.page,
              searchParameters,
            });
          }

          let analysisCancelToken = Axios.CancelToken;
          let analysisAxiosSource = analysisCancelToken.source();

          if (searchParameters.search_type !== "ipIndia") {
            dispatch({
              type: TS_SET_ANALYSIS_CANCEL,
              payload: analysisAxiosSource.cancel,
              searchTab,
            });
          } else {
            dispatch({
              type: TS_GET_SEARCH_RESULTS,
              status: SUCCESS,
              searchTab,
              searchParameters,
            });
            // Need to change the notification
            notification["success"]({
              message: "Report Queued",
              description: "Your IP India report has been queued.",
            });
          }

          try {
            if (fetchAnalysis) {
              dispatch({
                type: TS_GET_SEARCH_ANALYSIS,
                status: LOADING,
                searchTab,
              });

              let fetchAnalysisResponse = await Axios.get(
                `${SRCH_RT_URL}/trademarks/`,
                {
                  cancelToken: analysisAxiosSource.token,
                  params: {
                    operation: "analysis",
                    search_log_id: response?.data.search_log_id,
                    flash_flag: searchParameters.flash_flag,
                  },
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                },
              );

              dispatch({
                type: TS_GET_SEARCH_ANALYSIS,
                status: SUCCESS,
                payload: fetchAnalysisResponse.data,
                searchTab,
              });
            }
          } catch (err) {
            if (Axios.isCancel(err)) {
            } else {
              dispatch({
                type: TS_GET_SEARCH_ANALYSIS,
                status: ERROR,
                searchTab,
              });
            }
          }
          break;

        default:
          notification["error"]({
            message: "Unexpected Response! Search Results",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          if (searchParameters.search_type !== "ipIndia") {
            dispatch({ type: TS_GET_SEARCH_RESULTS, status: ERROR, searchTab });
          }
          break;
      }
    } catch (err) {
      if (Axios.isCancel(err)) {
        return err;
      } else {
        checkAuthorisation(dispatch, err);
        notification["error"]({
          message: "Error! Search Results",
          description:
            "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
        });
        if (searchParameters.search_type !== "ipIndia") {
          dispatch({ type: TS_GET_SEARCH_RESULTS, status: ERROR, searchTab });
        }
      }
    }
  };
}

//--------Get Image search results Data/Details--------
export function getImageSearchResults(
  searchParameters,
  searchTab,
  fetchAnalysis = false,
) {
  return async (dispatch) => {
    dispatch({
      type: TS_GET_SEARCH_RESULTS,
      status: LOADING,
      searchTab,
      searchParameters,
    });
    const formData = new FormData();

    formData.append("page", searchParameters?.page);

    if (!searchParameters.filter_flag) {
      formData.append("operation", searchParameters.operation);
      formData.append("search_classes", searchParameters.search_classes);
      if (searchParameters.image) {
        formData.append("image", searchParameters.image.originFileObj);
      }
    }

    if (searchParameters?.search_log_id) {
      formData.append("search_log_id", searchParameters.search_log_id);
    }
    let response;

    let headers = {};
    if (searchParameters.filter_flag) {
      headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
    } else {
      headers = {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      };
    }

    const queryParams = new URLSearchParams({
      page: searchParameters?.page,
    }).toString();

    try {
      response = await Axios.put(
        `${SRCH_RT_URL}/trademarks/image/?${queryParams}`,
        searchParameters.filter_flag ? searchParameters : formData,
        {
          headers,
        },
      );
      switch (response.status) {
        case 200:
        case 201:
          dispatch({
            type: TS_GET_SEARCH_RESULTS,
            status: SUCCESS,
            payload: response?.data,
            searchTab,
            pageNumber: searchParameters.page,
            searchParameters,
          });
          try {
            if (fetchAnalysis) {
              dispatch({
                type: TS_GET_SEARCH_ANALYSIS,
                status: LOADING,
                searchTab,
              });

              let fetchAnalysisResponse = await Axios.get(
                `${SRCH_RT_URL}/trademarks/image`,
                {
                  params: {
                    operation: "analysis",
                    search_log_id: response?.data.search_log_id,
                  },
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                },
              );

              dispatch({
                type: TS_GET_SEARCH_ANALYSIS,
                status: SUCCESS,
                payload: fetchAnalysisResponse.data,
                searchTab,
              });
            }
          } catch (err) {
            if (Axios.isCancel(err)) {
            } else {
              dispatch({
                type: TS_GET_SEARCH_ANALYSIS,
                status: ERROR,
                searchTab,
              });
            }
          }

          break;

        default:
          notification["error"]({
            message: "Unexpected Response! Search Results",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          if (searchParameters.search_type !== "ipIndia") {
            dispatch({ type: TS_GET_SEARCH_RESULTS, status: ERROR, searchTab });
          }
          break;
      }
    } catch (err) {
      if (Axios.isCancel(err)) {
        return err;
      } else {
        checkAuthorisation(dispatch, err);
        notification["error"]({
          message: "Error! Search Results",
          description:
            "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
        });
        if (searchParameters.search_type !== "ipIndia") {
          dispatch({
            type: TS_GET_SEARCH_RESULTS,
            status: ERROR,
            searchTab: "ImageSearch",
          });
        }
      }
    }
  };
}

//--------Dispatch search info--------
export function handleSearchInfoDispatch({
  trademarksSearchAlgo,
  activeTab,
  userTrademark,
  multiwordTrademark,
  axiosCancelSearches,
  searchCounter,
  reminderStamp,
  searchStamp,
}) {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_INFO,
      trademarksSearchAlgo,
      activeTab,
      userTrademark,
      multiwordTrademark,
      axiosCancelSearches,
      searchCounter,
      reminderStamp,
      searchStamp,
    });
  };
}

//--------Get trademark profile data--------
export function getTrademarkProfileData(check, application, realtime) {
  return async (dispatch) => {
    if (!check) {
      dispatch({
        type: TP_GET_TRADEMARK_PROFILE,
        status: LOADING,
        realtime,
        application,
      });
    }
    let response;
    try {
      response = await Axios.get(`${SRCH_RT_URL}/trademarks/${application}`, {
        params: { check, realtime },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      switch (response.status) {
        case 200:
          if (!check) {
            dispatch({
              type: TP_GET_TRADEMARK_PROFILE,
              status: SUCCESS,
              payload: response?.data,
              realtime,
              application,
            });
          }

          if (realtime && !response?.data.realtime) {
            notification["error"]({
              message: "Unable To Update",
              description:
                "We are sorry that the information did not update, please check after some time. Meanwhile, we are looking into it.",
            });
          }
          return {
            status: SUCCESS,
            data: response?.data,
          };

        default:
          notification["error"]({
            message: "Unexpected Response! Trademark Profile",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          if (!check) {
            dispatch({
              type: TP_GET_TRADEMARK_PROFILE,
              status: ERROR,
              realtime,
              application,
            });
          }
          return {
            status: ERROR,
          };
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      if (err.response && err.response.status === 404) {
        notification["error"]({
          message: "Trademark Dosen't Exist",
          description:
            "The mentioned trademark does not exist, please check the application number again or try after some time",
        });
      } else {
        notification["error"]({
          message: "Trademark Dosen't Exist",
          description:
            "The mentioned trademark does not exist, please check the application number again or try after some time",
        });
      }
      if (!check) {
        dispatch({
          type: TP_GET_TRADEMARK_PROFILE,
          status: ERROR,
          realtime,
          application,
        });
      }
      return {
        status: ERROR,
      };
    }
  };
}

//--------Get trademark profile data--------
export function getTrademarkProfileDataImage(check, application, realtime) {
  return async (dispatch) => {
    if (!check) {
      dispatch({
        type: TP_GET_TRADEMARK_PROFILE,
        status: LOADING,
        realtime,
        application,
      });
    }
    let response;
    try {
      response = await Axios.get(`${SRCH_RT_URL}/trademarks/${application}`, {
        params: { check, realtime },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      switch (response.status) {
        case 200:
          if (!check) {
            dispatch({
              type: TP_GET_TRADEMARK_PROFILE,
              status: SUCCESS,
              payload: response?.data,
              realtime,
              application,
            });
          }

          if (realtime && !response?.data.realtime) {
            notification["error"]({
              message: "Unable To Update",
              description:
                "We are sorry that the information did not update, please check after some time. Meanwhile, we are looking into it.",
            });
          }
          return {
            status: SUCCESS,
            data: response?.data,
          };

        default:
          notification["error"]({
            message: "Unexpected Response! Trademark Profile",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          if (!check) {
            dispatch({
              type: TP_GET_TRADEMARK_PROFILE,
              status: ERROR,
              realtime,
              application,
            });
          }
          return {
            status: ERROR,
          };
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      if (err.response && err.response.status === 404) {
        notification["error"]({
          message: "Trademark Dosen't Exist",
          description:
            "The mentioned trademark does not exist, please check the application number again or try after some time",
        });
      } else {
        notification["error"]({
          message: "Trademark Dosen't Exist",
          description:
            "The mentioned trademark does not exist, please check the application number again or try after some time",
        });
      }
      if (!check) {
        dispatch({
          type: TP_GET_TRADEMARK_PROFILE,
          status: ERROR,
          realtime,
          application,
        });
      }
      return {
        status: ERROR,
      };
    }
  };
}

//--------Post report--------
export function postReport(reportParams) {
  return async (dispatch) => {
    dispatch({ type: SRCH_REPORT, status: LOADING });
    let response;
    delete reportParams.applicationsIDs;
    // delete reportParams.selectAll;
    delete reportParams.selectAllTypes;
    delete reportParams.reportName;
    delete reportParams.reportType;
    try {
      response = await Axios.post(`${SRCH_RT_URL}/reports/`, reportParams, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      switch (response.status) {
        case 200:
        case 201:
          dispatch({
            type: SRCH_REPORT,
            status: SUCCESS,
            payload: response?.data,
          });
          if (reportParams.report_type === "URGENT") {
            notification["success"]({
              message: "Report Queued for Urgent",
              description:
                "Your Report is being generated. You can visit the Reports section to access the report.",
            });
          } else if (reportParams.report_type === "REALTIME") {
            notification["success"]({
              message: "Report Queued for Realtime",
              description:
                "Your Report is queued for real time. You can visit the Reports section, once generated.",
            });
          }

          return {
            status: SUCCESS,
            data: response?.data,
          };

        default:
          notification["error"]({
            message: "Unexpected Response! Post Report",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          dispatch({ type: SRCH_REPORT, status: ERROR });
          return {
            status: ERROR,
          };
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: SRCH_REPORT, status: ERROR });
      notification["error"]({
        message: "Error! Post Report",
        description:
          "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
      });
      return {
        status: ERROR,
      };
    }
  };
}

//--------Get proprietors Profile data--------
export function getProprietorProfileData({
  activeTab,
  proprietor,
  operation,
  filterFlag,
  filters,
  sortFlag,
  sorting,
  pageNumber,
}) {
  const filter_class = `${filters?.filterClasses}`;
  const filter_state = `${filters?.filterState}`;
  const filter_trademark_type = `${filters?.filterType}`;
  const filter_trademark_status = `${filters?.filterStatus}`;
  const filter_proprietor = `${filters?.filterProprietor}`;
  const filter_application_date = `${filters?.date_of_application}`;
  const filter_date_of_usage = `${filters?.date_of_usage}`;
  const filter_date_of_usage_proposed = `${filters?.date_of_usage_proposed}`;
  const filter_contains_term = `${filters?.containsTerm}`;
  const sort_type = `${sorting?.sortType}`;
  const sort_on = `${sorting?.sortOn}`;
  return async (dispatch) => {
    switch (operation) {
      case "MARKS":
        dispatch({
          type: PP_GET_PROPRIETOR_MARKS,
          status: LOADING,
          activeTab,
        });
        try {
          let response;
          response = await Axios.get(
            `${SRCH_RT_URL}/proprietor/${proprietor}`,
            {
              params: {
                operation: operation,
                filter_flag: filterFlag ? true : null,
                filter_state: filterFlag ? filter_state : null,
                filter_proprietor: filterFlag ? filter_proprietor : null,
                filter_class: filterFlag ? filter_class : null,
                filter_trademark_type: filterFlag
                  ? filter_trademark_type
                  : null,
                filter_trademark_status: filterFlag
                  ? filter_trademark_status
                  : null,
                filter_application_date: filterFlag
                  ? filter_application_date
                  : null,
                filter_date_of_usage: filterFlag ? filter_date_of_usage : null,
                filter_date_of_usage_proposed: filterFlag
                  ? filter_date_of_usage_proposed
                  : null,
                filter_contains_term: filterFlag ? filter_contains_term : null,
                sort_flag: sortFlag ? true : null,
                sort_type: sortFlag ? sort_type : null,
                sort_on: sortFlag ? sort_on : null,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: PP_GET_PROPRIETOR_MARKS,
            status: SUCCESS,
            payload: response?.data,
            activeTab,
            operation,
            lastAppliedFilters: {
              ...filters,
              filterFlag,
            },
            lastAppliedSorting: {
              ...sorting,
              sortFlag,
            },
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          notification["error"]({
            message: "Error! Proprietor Trademarks",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          dispatch({
            type: PP_GET_PROPRIETOR_MARKS,
            status: ERROR,
            activeTab,
          });
        }
        break;

      case "DATA":
        dispatch({
          type: PP_GET_PROPRIETOR_DATA,
          status: LOADING,
          activeTab,
        });
        try {
          let response;
          response = await Axios.get(
            `${SRCH_RT_URL}/proprietor/${proprietor}`,
            {
              params: {
                operation: operation,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: PP_GET_PROPRIETOR_DATA,
            status: SUCCESS,
            payload: {
              proprietor: response?.data.proprietor,
              similarProprietor: response?.data.similar_proprietors,
            },
            activeTab,
            operation,
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          notification["error"]({
            message: "Error! Proprietor Data",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          dispatch({
            type: PP_GET_PROPRIETOR_DATA,
            status: ERROR,
            activeTab,
          });
        }
        break;

      case "MARKS_ANALYSIS":
        dispatch({
          type: PP_GET_PROPRIETOR_ANALYSIS,
          status: LOADING,
          activeTab,
        });
        try {
          let response;
          response = await Axios.get(
            `${SRCH_RT_URL}/proprietor/${proprietor}`,
            {
              params: {
                operation: operation,
                page: pageNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            },
          );
          dispatch({
            type: PP_GET_PROPRIETOR_ANALYSIS,
            status: SUCCESS,
            payload: response?.data,
            activeTab,
            operation,
            pageNumber,
          });
        } catch (err) {
          checkAuthorisation(dispatch, err);
          notification["error"]({
            message: "Error! Proprietor Analysis",
            description:
              "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
          });
          dispatch({
            type: PP_GET_PROPRIETOR_ANALYSIS,
            status: ERROR,
            activeTab,
          });
        }
        break;

      default:
        break;
    }
  };
}
