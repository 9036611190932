import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import { Row, Col, Tabs, Table, Card, Typography } from "antd";

//--------IP-SUITE CONSTANTS--------
import { LOADING } from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import {
  getInventor,
  getInventorDetail,
} from "../../../redux/actions/managerActions/patentDashboard/actions";

//--------HISTORY--------
import { history } from "../../../history";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { logOut } from "../../../redux/actions/commonActions/actions";

const { TabPane } = Tabs;
const { Text } = Typography;

class InventorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    let inventor_id = sessionStorage.getItem("inventor_id");
    if (localStorage.getItem("token")) {
      this.props.getInventor(inventor_id);
      this.props.getInventorDetail(inventor_id);
    } else {
      this.props.logOut();
    }
  }

  handlPatentProfile(data) {
    if (data) {
      sessionStorage.setItem("management_patent_id", data.management_patent_id);
      window.open(
        "/manager/patent/patent-profile/" + data.management_patent_id,
        "_blank",
      );
      this.setState({
        management_patent_id: data.management_patent_id,
      });
    }
  }

  handleApplicantProfile(data) {
    if (data) {
      sessionStorage.setItem("applicant_id", data.id);
      window.open("/manager/patent/applicant-profile/" + data.id, "_blank");
      this.setState({
        applicant_id: data.id,
      });
    }
  }

  handleInventorProfile(data) {
    if (data) {
      sessionStorage.setItem("inventor_id", data.id);
      window.open("/manager/patent/inventor-profile/" + data.id, "_blank");
      this.setState({
        inventor_id: data.id,
      });
    }
  }

  render() {
    const totalInventorColumns = [
      {
        title: "APPLICATION NO.",
        dataIndex: "application_number",
        className: "patent-column",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handlPatentProfile(record)}
              >
                <span>{record.application_number} - </span>
                <span className="text-camelCase">
                  {record.title_of_invention.toLowerCase()}
                </span>
              </p>
            </div>
          );
        },
      },

      {
        title: "APPLICANT",
        dataIndex: "applicant",
        className: "patent-column",
        render: (applicant) => {
          return (
            <div>
              {applicant.map((applicantData, applicantKey) => (
                <p
                  key={applicantKey}
                  className="application-text text-camelCase"
                  onClick={() => this.handleApplicantProfile(applicantData)}
                >
                  {applicantData.name.toLowerCase()}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "INVENTOR",
        dataIndex: "inventor",
        className: "patent-column",
        render: (inventor) => {
          return (
            <div>
              {inventor.map((inventorData, inventorKey) => (
                <p
                  key={inventorKey}
                  className="application-text text-camelCase"
                  onClick={() => this.handleInventorProfile(inventorData)}
                >
                  {inventorData.name.toLowerCase()}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "TYPE",
        dataIndex: "application_type",
        className: "patent-column",
        render: (text) => (
          <p className="text-camelCase">{text.toLowerCase()}</p>
        ),
      },

      {
        title: "FIELD OF INVENTION",
        dataIndex: "field_of_invention.name",
        className: "patent-column",
        render: (text) => (
          <p className="text-camelCase">{text.toLowerCase()}</p>
        ),
      },

      {
        title: "STATUS",
        dataIndex: "application_status",
        className: "patent-column",
        render: (text) => (
          <p className="text-camelCase">{text.toLowerCase()}</p>
        ),
      },

      {
        title: "DATA AS ON",
        dataIndex: "data_as_on",
        className: "patent-column",
        render: (text) => <p>{text}</p>,
      },
    ];

    return (
      <div>
        {/* Back-section */}
        <Row>
          <Col span={24} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
        </Row>
        {/* Back-section */}

        {/* Inventor-Information */}
        <Row>
          <Col span={24} className="m-top-10">
            <Card
              title="INVENTOR INFORMATION"
              headStyle={{ background: "#e8e8e8" }}
              loading={
                this.props.patentInventorStatus === LOADING ? true : false
              }
            >
              <div>
                <table className="inventor-profile-table">
                  {this.props.patentInventor && (
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>
                          {checkValue(this.props.patentInventor.name)
                            ? this.props.patentInventor.name
                            : "N.A."}
                        </td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td>
                          {checkValue(this.props.patentInventor.address)
                            ? this.props.patentInventor.address
                            : "N.A."}
                        </td>
                      </tr>
                      <tr>
                        <td>Nationality</td>
                        <td>
                          {checkValue(this.props.patentInventor.nationality)
                            ? this.props.patentInventor.nationality
                            : "N.A."}
                        </td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>
                          {checkValue(this.props.patentInventor.country)
                            ? this.props.patentInventor.country.name
                            : "N.A."}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </Card>
          </Col>
        </Row>
        {/* Inventor-Information */}

        {/* Inventor-Mark */}
        <Row>
          <Col span={24} className="m-top-20">
            <Tabs onChange={this.callback} type="card">
              <TabPane tab="INVENTOR MARK" key="1">
                <div>
                  <Table
                    rowKey={(record) => record.id}
                    bordered
                    dataSource={
                      this.props.patentInventorDetail &&
                      this.props.patentInventorDetail.data
                    }
                    columns={totalInventorColumns}
                    pagination={false}
                    scroll={{ y: "calc(70vh - 200px)" }}
                    loading={
                      this.props.patentInventorDetail === LOADING ? true : false
                    }
                  />
                  ;
                </div>
              </TabPane>
              <TabPane tab="INVENTOR ANALYSIS" disabled key="2">
                <div>Graph Data</div>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        {/* Inventor-Mark */}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getInventor,
  getInventorDetail,
  logOut,
};

function mapStateToProps(state) {
  const {
    patentInventor,
    patentInventorStatus,
    patentInventorDetail,
    patentInventorDetailStatus,
  } = state.managerState.patentDashboardState;
  return deepFreeze({
    patentInventor,
    patentInventorStatus,
    patentInventorDetail,
    patentInventorDetailStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(InventorProfile);
