//--------LIBRARIES--------
import Axios from "axios";
import { notification } from "antd";

//--------RT_CONFIG--------
import { SRCH_RT_URL } from "../../../configs/rootConfigs";

//--------COMMON IP-SUITE CONSTANTS--------
import { LOADING, ERROR, SUCCESS } from '../../../constants/commonConstants/generalConstants';

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from '../commonActions/actions';

//--------Get trademark report availability--------
export function getTrademarkReportStatus(reportParams, actionType){
    return async (dispatch) => {
        dispatch({type: actionType, status: LOADING});
        let response;
        try {
            response = await Axios.get(
                `${SRCH_RT_URL}/reports/`,
                {
                    params: reportParams,
                    headers: {Authorization:'Token '+ localStorage.getItem('token')}
                }
            );

            switch (response.status) {
                case 200:
                case 201:
                    dispatch({type: actionType, status: SUCCESS, payload: response?.data});
                    return {
                        status: SUCCESS,
                        data: response?.data
                    };

                default:
                    notification['error']({
                        message: 'Unexpected Response! Report Availability',
                        description: 'We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.',
                    });
                    dispatch({type: actionType, status: ERROR});
                    return {
                        status: ERROR,
                    };
                }
        } catch (err) {
            checkAuthorisation(dispatch, err);
            notification['error']({
                message: 'Error! Report Availability',
                description: 'Please, close report drawer and open it again. If the problem still persists, come back after some time.',
            });
            dispatch({type: actionType, status: ERROR});
            return {
                status: ERROR,
            };
        }
    };
}

//--------Patch trademark report--------
export function patchTrademarkReport(reportParams, reportID){
    delete reportParams.applicationsIDs;
    delete reportParams.selectAll;
    delete reportParams.selectAllTypes;
    delete reportParams.reportName;
    delete reportParams.reportType;
    return async (dispatch) => {
        let response;
        try {
            response = await Axios.patch(
                `${SRCH_RT_URL}/report/${reportID}/`,
                reportParams,
                {
                    headers: {Authorization:'Token '+ localStorage.getItem('token')}
                }
            );

            switch (response.status) {
                case 200:
                case 201:
                    return {
                        status: SUCCESS,
                        data: response?.data
                    };

                default:
                    notification['error']({
                        message: 'Unexpected Response! Patch Report',
                        description: 'We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.',
                    });
                    return {
                        status: ERROR,
                    };
            }
        } catch (err) {
            checkAuthorisation(dispatch, err);
            notification['error']({
                message: 'Error! Patch Report',
                description: 'We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.',
            });
            return {
                status: ERROR,
            };
        }
    };
}