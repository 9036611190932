import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Table } from "antd";
import Axios from "axios";
import React, { useState, useEffect } from "react";
// import { useSelector } from 'react-redux';
import { sentEmailDashboard } from "../../../../redux/actions/managerActions/commonAction/action";
import { useDispatch, useSelector } from "react-redux";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import { Tooltip, Modal } from "antd";

function SentEmail() {
  const dispatch = useDispatch();
  let total_emails;
  let application_number = sessionStorage.getItem(
    "trademark_application_number",
  );
  const STATUS = useSelector(
    (state) => state.managerState.managerEmailState.sentEmailDataStatus,
  );
  const emails = useSelector(
    (state) => state.managerState.managerEmailState.sentEmailData,
  );
  console.log("Emails State is", STATUS, emails);

  if (STATUS == "SUCCESS") {
    total_emails = emails.emails;
    console.log("Total Emails", total_emails);
  }

  function showMailBody(data) {
    Modal.info({
      title: "Email Body",
      width: "50%",
      content: <div dangerouslySetInnerHTML={{ __html: data }}></div>,
      onOk() {},
    });
  }

  useEffect(() => {
    dispatch(sentEmailDashboard({ application_number }));
  }, []);

  const emailColumns = [
    {
      title: "SOURCE",
      render: (record) => {
        return (
          <div>
            <span>
              {checkValue(record?.source) ? record?.source : "N.A."} -{" "}
            </span>
            <p>
              (
              {checkValue(record?.source_title) ? record?.source_title : "N.A."}
              )
            </p>
          </div>
        );
      },
    },

    {
      title: "SEND FROM | SEND TO",
      render: (record) => {
        return (
          <div>
            <span>
              {checkValue(record?.from_email) ? record?.from_email : "N.A."}{" "}
            </span>
            <p>{checkValue(record?.to_email) ? record?.to_email : "N.A."}</p>
          </div>
        );
      },
    },

    {
      title: "CC ( EMAIL | STATUS | LOCATION | DATE AND TIME )",
      width: "20%",
      render: (record) => {
        return (
          <div
            className="vertical_scroll"
            style={{ maxHeight: 200, height: 200, overflow: "auto" }}
          >
            {record?.send_emails &&
              record?.send_emails.length > 0 &&
              record?.send_emails?.map((eachMail, key) => (
                <div
                  style={{
                    marginBottom: 20,
                    border: "1px solid #ddd",
                    padding: 5,
                  }}
                >
                  <span>
                    <strong>
                      {checkValue(eachMail.email) ? eachMail.email : "N.A."}
                    </strong>
                  </span>
                  {eachMail.events &&
                    eachMail.events.length > 0 &&
                    eachMail.events?.map((eachEvents, key) => (
                      <>
                        <div>
                          <div style={{ marginBottom: 5 }}>
                            <span>
                              {checkValue(eachEvents.event_status)
                                ? eachEvents.event_status
                                : "N.A."}
                            </span>
                            <Tooltip title="Event Status">
                              <InfoCircleOutlined
                                style={{
                                  float: "right",
                                  color: "#ff5500",
                                  fontSize: "18px",
                                  marginRight: 5,
                                }}
                              />
                            </Tooltip>
                          </div>
                          <div style={{ marginBottom: 5 }}>
                            <span>
                              {checkValue(eachEvents.geo_location)
                                ? eachEvents.geo_location
                                : "N.A."}
                            </span>
                            <Tooltip title="Location">
                              <i
                                className="fa fa-map-marker"
                                aria-hidden="true"
                                style={{
                                  float: "right",
                                  color: "#40a9ff",
                                  fontSize: "20px",
                                  marginRight: 5,
                                }}
                              ></i>
                            </Tooltip>
                          </div>
                          <div style={{ marginBottom: 5 }}>
                            <span>
                              {checkValue(eachEvents.date_time_of_event)
                                ? eachEvents.date_time_of_event
                                : "N.A."}
                            </span>
                            <Tooltip title="Date and Time of Event">
                              <ClockCircleOutlined
                                style={{
                                  float: "right",
                                  color: "",
                                  fontSize: "18px",
                                  marginRight: 5,
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <hr style={{ marginTop: 5, marginBottom: 5 }} />
                      </>
                    ))}
                </div>
              ))}
            {record?.send_emails.length === 0 && (
              <div>
                <p>N.A.</p>
              </div>
            )}
          </div>
        );
      },
    },

    {
      title: "SUBJECT",
      dataIndex: "email_subject",
      render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
    },

    {
      title: "MAIL BODY",
      render: (record) => {
        return (
          <>
            {record?.email_body && (
              <>
                <div
                  style={{ maxHeight: 200, height: 200, overflow: "hidden" }}
                  dangerouslySetInnerHTML={{ __html: record?.email_body }}
                ></div>
                {record?.email_body.length > 300 && (
                  <span
                    className="application-text alignC"
                    onClick={() => showMailBody(record?.email_body)}
                  >
                    See more
                  </span>
                )}
              </>
            )}
          </>
        );
      },
    },

    {
      title: "DATE OF SENDING",
      render: (record) => {
        return (
          <div>
            <Tooltip title="Date of Sending">
              <span>
                {checkValue(record?.date_of_sending)
                  ? record?.date_of_sending
                  : "N.A."}
              </span>
            </Tooltip>
            <Tooltip title="Sending Status">
              <LegacyIcon
                style={{
                  fontSize: "20px",
                  float: "right",
                  color: `${record?.is_failed ? "#f81d22" : "#52c41a"}`,
                }}
                type={`${record?.is_failed ? "warning" : "check-circle"}`}
              />
            </Tooltip>
          </div>
        );
      },
    },

    {
      title: "ATTACHMENT",
      width: "200px",
      render: (record) => {
        return (
          <div>
            <p
              className={
                checkValue(record?.file_name) ? "application-text" : ""
              }
              onClick={
                checkValue(record?.file_name)
                  ? () => window.open(record?.file_attachment, "_blank")
                  : () => null
              }
            >
              {checkValue(record?.file_name) ? record?.file_name : "N.A."}
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {/* <div className="ant-card-head" style={{height:"55px",backgroundColor:'#E8E8E8',display:'flex',alignItems:'center'}}>
                <p className="mt-3">EMAIL LOGS</p>
            </div> */}
      <div
        style={{ marginTop: "20px", marginLeft: "30px", marginRight: "25px" }}
      >
        <Table
          columns={emailColumns}
          bordered={true}
          dataSource={total_emails}
        />
      </div>
    </div>
  );
}

export default SentEmail;
