//--------Default selectd filters--------
export const MT_defaultSelectedFilters = {
    proprietors: [],
    tags: [],
    classes: [],
    containsTerm: ""
}

//--------Default Fiters Search Terms--------
export const MT_defaultFiltersSearchTerms = {
    tags: "",
    proprietors: ""
};

//--------Default selectd setters--------
export const MT_defaultSelectedSetters = {
    multiple: {
        addTags: [],
        removeTags: [],
        addClasses: [],
        removeClasses: []
    },
    single: {
        tags: [],
        focus_classes: [],
        focus_words: {
            add: [],
            remove: []
        }
    }
}

//--------Default Setters Search Terms--------
export const MT_defaultSettersSearchTerms = {
    multiple: {
        addTags: "",
        removeTags: ""
    },
    single: {
        tags: "",
    }
};

//--------Default Selected Trademarks--------
export const MT_defaultSelectedTrademarks = {
    selectAll: false,
    trademarks: []
};