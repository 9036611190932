import { SUCCESS } from "../../../../constants/commonConstants/generalConstants";
import { UPDATE_SINGLE_CLIENT_MASTER } from "../../../actions/commonActions/masterActions/masterTypes";

const initialState = {
  data: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SINGLE_CLIENT_MASTER: {
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            data: action.payload,
          };
        default:
          return state;
      }
    }

    default:
      return state;
  }
}
