//--------LIBRARIES--------
import React, { Component } from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { Row, Col, Card, Steps } from "antd";

import { LOADING } from "../../../../constants/commonConstants/generalConstants";

const { Step } = Steps;

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <Row gutter={16} className="m-top-20">
        <Col span={24}>
          <Card
            // title = "TIMELINE"
            //headStyle={{background: '#e8e8e8'}}
            loading={this.props.status === LOADING}
          >
            <div className="horizontal_scroll" style={{ overflowY: "auto" }}>
              <Steps
                size="small"
                style={{ width: `calc(25% * ${this.props.documents.length})` }}
                current={this.props.documents.length}
              >
                {this.props.documents.map((doc, index) => (
                  <Step
                    key={index}
                    title={doc.date}
                    description={doc[this.props.description]}
                    icon={<CheckCircleFilled />}
                  />
                ))}
              </Steps>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Timeline;
