import { SEND_MANAGER_EMAIL, MANAGER_EMAIL_DASHBOARD ,SENT_EMAIL_DASHBOARD} from  '../../actions/managerActions/commonAction/types';
import { defaultSelectedFilter } from '../../../constants/commonConstants/emailDashboard';

const defaultWhatsNewState = {
    sendManagerEmailMessage: null,
    sendManagerEmailStatus: null,

    managerEmailData: null,
    managerEmailDataStatus: null,
    sentEmailData:null,
    sentEmailDataStatus:null,
    lastAppliedFilters: defaultSelectedFilter,
    pageNumber: 1
};

export default function(state = defaultWhatsNewState, action){
    switch (action.type) {
        case SEND_MANAGER_EMAIL:
            return {
                ...state,
                sendManagerEmailMessage: action.payload,
                sendManagerEmailStatus: action.status,
            };

        case MANAGER_EMAIL_DASHBOARD:
            return {
                ...state,
                managerEmailData: action.payload,
                managerEmailDataStatus: action.status,
                lastAppliedFilters: action.lastAppliedFilters,
                pageNumber: action.pageNumber
            };

            case SENT_EMAIL_DASHBOARD:
                return {
                    ...state,
                    sentEmailData: action.payload,
                    sentEmailDataStatus: action.status,
                };

        default:
            return state;
    }
}