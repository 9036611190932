import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import {
  Row,
  Col,
  Card,
  Modal,
  Typography,
  Input,
  Button,
  Drawer,
  message,
  DatePicker,
} from "antd";

import { LOADING } from "../../../../constants/commonConstants/generalConstants";

import {
  getTeamMember,
  logOut,
} from "../../../../redux/actions/commonActions/actions";
import {
  getCustomListProfile,
  editCustomProfile,
  getCustomList,
} from "../../../../redux/actions/managerActions/profile/actions";
import {
  postUploadFile,
  putUploadedFile,
  postDownloadFile,
  deleteUploadedFile,
} from "../../../../redux/actions/managerActions/trademarkDashboard/actions";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import UploadDocuments from "../../../commonComponents/Drawers/UploadDocuments";
import { IP } from "../../../../constants/managerConstants/managerConstants";
import { UPLOAD_FILE_TM_CUSTOM } from "../../../../redux/actions/managerActions/trademarkDashboard/types";

import { changeStateValue_L0 } from "../../../../functions/commonFunctions/generalFunctions";
import {
  getNotes,
  deleteNotes,
} from "../../../../redux/actions/managerActions/commonAction/action";
import ManagerNotes from "../../subComponents/Modal/managerNotes";
import {
  GET_IP_NOTES,
  POST_IP_NOTES,
  PATCH_IP_NOTES,
  DELETE_IP_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import {
  PATENT_INTERNATIONAL_PROFILE,
  PATENT_EDIT_INTERNATIONAL_PROFILE,
} from "../../../../redux/actions/managerActions/profile/types";
import { Custom_Patent } from "../../../../constants/managerConstants/customConstants";
import moment from "moment";
import { Patent_DefaultSelectedFilters } from "../../../../constants/managerConstants/patentConstants";
import NotesTable from "../../subComponents/trademarkProfileComponents/NotesTable";
import UploadDocumentsTable from "../../subComponents/trademarkProfileComponents/UploadDocumentsTable";

const { Text, Title } = Typography;
const { TextArea } = Input;

class InternationalPatentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibe: false,
      managerNotes: false,
      notesEditingKey: "",
      notesDeletingKey: "",
      note_id: "",
      note: "",
      patent: Custom_Patent,
      editCustomPatent: false,
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      let international_patent_id = sessionStorage.getItem(
        "international_patent_id"
      );
      this.props.getCustomListProfile({
        customActionType: PATENT_INTERNATIONAL_PROFILE,
        id: international_patent_id,
        operation_type: "patent",
        object_type: "international",
      });

      if (this.props.teamMember === null) {
        this.props.getTeamMember("manager");
      }

      this.props.getNotes({
        actionType: GET_IP_NOTES,
        note_for: IP,
        application_number: sessionStorage.getItem(
          "international_patent_application_number"
        ),
      });
    } else {
      this.props.logOut();
    }
  }

  showCompleteSpecification() {
    Modal.info({
      title: "Complete Specification",
      width: 800,
      className: "mark-delete-modal",
      content: (
        <div>
          {this.props.internationalPatentProfile && (
            <TextArea
              value={
                this.props.internationalPatentProfile.complete_specification
              }
              className="complete-specification-box vertical_scroll"
              autosize={{ minRows: 5 }}
            />
          )}
        </div>
      ),
      okText: "cancel",
      // okType: '',
      onOk() {},
    });
  }

  handleAddNotes() {
    this.setState({
      managerNotes: true,
      type: "ADD",
    });
  }

  handleEditNotes(data) {
    this.setState({
      managerNotes: true,
      notesEditingKey: data.id,
      note_id: data.id,
      note: data.note,
      type: "EDIT",
    });
  }

  async handleDeleteNotes(data) {
    this.setState({
      notesDeletingKey: data.id,
    });
    await this.props.deleteNotes({
      actionType: DELETE_IP_NOTES,
      note_for: IP,
      application_number: sessionStorage.getItem(
        "international_patent_application_number"
      ),
      note_id: data.id,
      delete_all: false,
    });
    message[this.props.deleteManageNotesStatus.toLowerCase()](
      this.props.manageNotesMessage
    );
  }

  async handleDeleteNotesAll() {
    await this.props.deleteNotes({
      actionType: DELETE_IP_NOTES,
      note_for: IP,
      application_number: sessionStorage.getItem(
        "international_patent_application_number"
      ),
      delete_all: true,
    });
    message[this.props.deleteAllManageNotesStatus.toLowerCase()](
      this.props.manageNotesMessage
    );
  }

  handleEditFields() {
    const editFields = this.props.internationalPatentProfile;
    this.setState({
      editCustomPatent: true,
      patent: {
        ...this.state.patent,
        title_of_invention: checkValue(editFields.title_of_invention)
          ? editFields.title_of_invention
          : "",
        applicant: checkValue(editFields.applicant) ? editFields.applicant : [],
        inventor: checkValue(editFields.inventor) ? editFields.inventor : [],
        classification_ipc: checkValue(editFields.classification_ipc)
          ? editFields.classification_ipc
          : [],
        application_type: checkValue(editFields.application_type)
          ? editFields.application_type
          : "",
        field_of_invention: checkValue(editFields.field_of_invention)
          ? editFields.field_of_invention.name
          : "",
        application_status: checkValue(editFields.application_status)
          ? editFields.application_status
          : "",
        date_of_filing: checkValue(editFields.date_of_filing)
          ? editFields.date_of_filing
          : "",
        renewal_date: checkValue(editFields.renewal_date)
          ? editFields.renewal_date
          : "",
        first_examination_report_date: checkValue(
          editFields.first_examination_report_date
        )
          ? editFields.first_examination_report_date
          : "",
        reply_to_fer_date: checkValue(editFields.reply_to_fer_date)
          ? editFields.reply_to_fer_date
          : "",
        date_of_certificate_issue: checkValue(
          editFields.date_of_certificate_issue
        )
          ? editFields.date_of_certificate_issue
          : "",
        pct_international_application_number: checkValue(
          editFields.pct_international_application_number
        )
          ? editFields.pct_international_application_number
          : "",
        pct_international_filing_date: checkValue(
          editFields.pct_international_filing_date
        )
          ? editFields.pct_international_filing_date
          : "",
        publication_date_us: checkValue(editFields.publication_date_us)
          ? editFields.publication_date_us
          : "",
        priority_country: checkValue(editFields.priority_country)
          ? editFields.priority_country.name
          : "",
        filing_country: checkValue(editFields.filing_country)
          ? editFields.filing_country.name
          : "",
        reference_number: checkValue(editFields.reference_number)
          ? editFields.reference_number
          : "",
        priority_date: checkValue(editFields.priority_date)
          ? editFields.priority_date
          : "",
        request_for_examination_date: checkValue(
          editFields.request_for_examination_date
        )
          ? editFields.request_for_examination_date
          : "",
        parent_application_filing_date: checkValue(
          editFields.parent_application_filing_date
        )
          ? editFields.parent_application_filing_date
          : "",
        post_grant_journal_date: checkValue(editFields.post_grant_journal_date)
          ? editFields.post_grant_journal_date
          : "",
      },
    });
  }

  handleChangePatent(type, event) {
    event.preventDefault();
    this.setState({
      patent: {
        ...this.state.patent,
        [type]: event.target.value,
      },
    });
  }

  handleChangeDate(type, date, dateString) {
    this.setState({
      patent: {
        ...this.state.patent,
        [type]: dateString,
      },
    });
  }

  handleAddPatent(type, value) {
    this.setState({
      patent: {
        ...this.state.patent,
        [type]: [...this.state.patent[type], value],
      },
    });
  }

  async handleEditPatent() {
    let international_patent_id = sessionStorage.getItem(
      "international_patent_id"
    );
    await this.props.editCustomProfile({
      customActionType: PATENT_EDIT_INTERNATIONAL_PROFILE,
      id: international_patent_id,
      operation_type: "patent",
      object_type: "international",
      custom_data: this.state.patent,
    });
    this.props.getCustomList({
      operation_type: "patent",
      object_type: "international",
      filterFlag: false,
      filters: Patent_DefaultSelectedFilters,
      pageNumber: 1,
    });
    this.setState({
      editCustomPatent: false,
      patent: Custom_Patent,
    });
  }

  render() {
    const {
      internationalPatentProfile,
      internationalPatentProfileStatus,
      internationalPatentProfileEditStatus,
      manageNotes,
      manageNotesStatus,
      deleteManageNotesStatus,
      deleteAllManageNotesStatus,
    } = this.props;

    const { editCustomPatent, patent } = this.state;

    return (
      <div>
        {/* back-section */}
        <Row>
          <Col span={24} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
        </Row>
        {/* back-section */}
        {/* international Patent Information */}
        <Row>
          <Col span={24} className="m-top-20">
            <Card
              title="INTERNATIONAL PATENT INFORMATION"
              headStyle={{ background: "#e8e8e8" }}
              extra={
                <>
                  {this.state.editCustomPatent ? (
                    <>
                      <Button
                        style={{ margin: "0px 5px" }}
                        onClick={() =>
                          this.setState({ editCustomPatent: false })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        style={{ margin: "0px 5px" }}
                        loading={
                          internationalPatentProfileEditStatus === LOADING
                        }
                        onClick={() => this.handleEditPatent()}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="primary"
                      style={{ margin: "0px 5px" }}
                      onClick={() => this.handleEditFields()}
                    >
                      Edit
                    </Button>
                  )}
                </>
              }
              loading={
                internationalPatentProfileStatus === LOADING ? true : false
              }
            >
              {internationalPatentProfile && (
                <div>
                  <table className="patent-information-table">
                    <tbody>
                      <tr>
                        <td>Title of Invention</td>
                        <td>
                          {editCustomPatent ? (
                            <Input
                              type="text"
                              value={patent.title_of_invention}
                              onChange={(event) =>
                                this.handleChangePatent(
                                  "title_of_invention",
                                  event
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.title_of_invention
                            ) ? (
                            internationalPatentProfile.title_of_invention
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Application Number</td>
                        <td>
                          {checkValue(
                            internationalPatentProfile.application_number
                          )
                            ? internationalPatentProfile.application_number
                            : "N.A."}
                        </td>
                      </tr>
                      <tr>
                        <td>Applicant Name</td>
                        <td>
                          {checkValue(internationalPatentProfile.applicant) ? (
                            <ul>
                              {internationalPatentProfile.applicant.map(
                                (applicantData, applicantkey) => (
                                  <li key={applicantData.id}>
                                    {checkValue(applicantData.name) ? (
                                      <>
                                        <span>{applicantkey + 1}. </span>
                                        {editCustomPatent ? (
                                          <Input
                                            type="text"
                                            defaultValue={
                                              checkValue(applicantData.name)
                                                ? applicantData.name
                                                : "N.A."
                                            }
                                            onChange={(event) =>
                                              this.setState({
                                                patent: {
                                                  ...this.state.patent,
                                                  applicant:
                                                    this.state.patent.applicant.map(
                                                      (data) => ({
                                                        ...data,
                                                        name:
                                                          data.id ===
                                                          applicantData.id
                                                            ? event.target.value
                                                            : data.name,
                                                      })
                                                    ),
                                                },
                                              })
                                            }
                                          />
                                        ) : (
                                          <span>{applicantData.name}</span>
                                        )}
                                      </>
                                    ) : (
                                      "N.A."
                                    )}
                                  </li>
                                )
                              )}
                              {internationalPatentProfile.applicant.length ===
                                0 && <sapn>N.A.</sapn>}
                            </ul>
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Inventor Name</td>
                        <td>
                          {checkValue(internationalPatentProfile.inventor) ? (
                            <ul>
                              {internationalPatentProfile.inventor.map(
                                (invName, key) => (
                                  <li key={invName.id}>
                                    {checkValue(invName.name) ? (
                                      <>
                                        <span>{key + 1}. </span>
                                        {editCustomPatent ? (
                                          <Input
                                            type="text"
                                            defaultValue={
                                              checkValue(invName.name)
                                                ? invName.name
                                                : "N.A."
                                            }
                                            onChange={(event) =>
                                              this.setState({
                                                patent: {
                                                  ...this.state.patent,
                                                  inventor:
                                                    this.state.patent.inventor.map(
                                                      (data) => ({
                                                        ...data,
                                                        name:
                                                          data.id === invName.id
                                                            ? event.target.value
                                                            : data.name,
                                                      })
                                                    ),
                                                },
                                              })
                                            }
                                          />
                                        ) : (
                                          <span>{invName.name}</span>
                                        )}
                                      </>
                                    ) : (
                                      "N.A."
                                    )}
                                  </li>
                                )
                              )}
                              {internationalPatentProfile.inventor.length ===
                                0 && <sapn>N.A.</sapn>}
                            </ul>
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Field of Invention</td>
                        <td>
                          {editCustomPatent ? (
                            <Input
                              type="text"
                              value={patent.field_of_invention}
                              onChange={(event) =>
                                this.handleChangePatent(
                                  "field_of_invention",
                                  event
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.field_of_invention
                            ) ? (
                            checkValue(
                              internationalPatentProfile.field_of_invention.name
                            ) ? (
                              internationalPatentProfile.field_of_invention.name
                            ) : (
                              "N.A."
                            )
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Application Type</td>
                        <td>
                          {editCustomPatent ? (
                            <Input
                              type="text"
                              value={patent.application_type}
                              onChange={(event) =>
                                this.handleChangePatent(
                                  "application_type",
                                  event
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.application_type
                            ) ? (
                            internationalPatentProfile.application_type
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Application Status</td>
                        <td>
                          {editCustomPatent ? (
                            <Input
                              type="text"
                              value={patent.application_status}
                              onChange={(event) =>
                                this.handleChangePatent(
                                  "application_status",
                                  event
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.application_status
                            ) ? (
                            internationalPatentProfile.application_status
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Application Date (Date of Filing)</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.date_of_filing
                                )
                                  ? moment(
                                      internationalPatentProfile.date_of_filing,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "date_of_filing",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.date_of_filing
                            ) ? (
                            internationalPatentProfile.date_of_filing
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>PCT International Application Number</td>
                        <td>
                          {editCustomPatent ? (
                            <Input
                              type="text"
                              value={
                                patent.pct_international_application_number
                              }
                              onChange={(event) =>
                                this.handleChangePatent(
                                  "pct_international_application_number",
                                  event
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.pct_international_application_number
                            ) ? (
                            internationalPatentProfile.pct_international_application_number
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>PCT International Filing Date</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.pct_international_filing_date
                                )
                                  ? moment(
                                      internationalPatentProfile.pct_international_filing_date,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "pct_international_filing_date",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.pct_international_filing_date
                            ) ? (
                            internationalPatentProfile.pct_international_filing_date
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      {/* <tr>
                                                <td>Priority Number</td>
                                                <td>
                                                    { editCustomPatent ?
                                                        <Input 
                                                            defaultValue={checkValue(internationalPatentProfile.priority_number) ? internationalPatentProfile.priority_number : 'N.A.'}	
                                                            onChange={(event)=> this.handleChangePatent('priority_number', event)}
                                                        /> 
                                                        : (checkValue(internationalPatentProfile.priority_number) ? 
                                                            internationalPatentProfile.priority_number 
                                                        : 'N.A.')
                                                    }
                                                </td>
                                            </tr> */}
                      <tr>
                        <td>Priority Date</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.priority_date
                                )
                                  ? moment(
                                      internationalPatentProfile.priority_date,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "priority_date",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.priority_country
                            ) ? (
                            checkValue(
                              internationalPatentProfile.priority_country.name
                            ) ? (
                              internationalPatentProfile.priority_country.name
                            ) : (
                              "N.A."
                            )
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Priority Country</td>
                        <td>
                          {editCustomPatent ? (
                            <Input
                              type="text"
                              value={patent.priority_country}
                              onChange={(event) =>
                                this.handleChangePatent(
                                  "priority_country",
                                  event
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.filing_country
                            ) ? (
                            checkValue(
                              internationalPatentProfile.filing_country.name
                            ) ? (
                              internationalPatentProfile.filing_country.name
                            ) : (
                              "N.A."
                            )
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Filing Country</td>
                        <td>
                          {editCustomPatent ? (
                            <Input
                              type="text"
                              value={patent.filing_country}
                              onChange={(event) =>
                                this.handleChangePatent("filing_country", event)
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.filing_country
                            ) ? (
                            internationalPatentProfile.filing_country.name
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Reference Number</td>
                        <td>
                          {editCustomPatent ? (
                            <Input
                              value={patent.reference_number}
                              onChange={(event) =>
                                this.handleChangePatent(
                                  "reference_number",
                                  event
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.reference_number
                            ) ? (
                            internationalPatentProfile.reference_number
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Parent Application Filing Date</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.parent_application_filing_date
                                )
                                  ? moment(
                                      internationalPatentProfile.parent_application_filing_date,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "parent_application_filing_date",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.parent_application_filing_date
                            ) ? (
                            internationalPatentProfile.parent_application_filing_date
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Classification</td>
                        <td>
                          {checkValue(
                            internationalPatentProfile.classification_ipc
                          ) ? (
                            <ul>
                              {internationalPatentProfile.classification_ipc.map(
                                (ipc, ipckey) => (
                                  <li key={ipc.id}>
                                    {checkValue(ipc.name) ? (
                                      <>
                                        <span>{ipckey + 1}. </span>
                                        {editCustomPatent ? (
                                          <Input
                                            type="text"
                                            defaultValue={
                                              checkValue(ipc.name)
                                                ? ipc.name
                                                : "N.A."
                                            }
                                            onChange={(event) =>
                                              this.setState({
                                                patent: {
                                                  ...this.state.patent,
                                                  classification_ipc:
                                                    this.state.patent.classification_ipc.map(
                                                      (data) => ({
                                                        ...data,
                                                        name:
                                                          data.id === ipc.id
                                                            ? event.target.value
                                                            : data.name,
                                                      })
                                                    ),
                                                },
                                              })
                                            }
                                          />
                                        ) : (
                                          <span>{ipc.name}</span>
                                        )}
                                      </>
                                    ) : (
                                      "N.A."
                                    )}
                                  </li>
                                )
                              )}
                              {internationalPatentProfile.classification_ipc
                                .length === 0 && <sapn>N.A.</sapn>}
                            </ul>
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Request for Examination Date</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.request_for_examination_date
                                )
                                  ? moment(
                                      internationalPatentProfile.request_for_examination_date,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "request_for_examination_date",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.request_for_examination_date
                            ) ? (
                            internationalPatentProfile.request_for_examination_date
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Reply to First Examination Report Date</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.reply_to_fer_date
                                )
                                  ? moment(
                                      internationalPatentProfile.reply_to_fer_date,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "reply_to_fer_date",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.reply_to_fer_date
                            ) ? (
                            internationalPatentProfile.reply_to_fer_date
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Publication Date</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.publication_date_us
                                )
                                  ? moment(
                                      internationalPatentProfile.publication_date_us,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "publication_date_us",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.publication_date_us
                            ) ? (
                            internationalPatentProfile.publication_date_us
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      {/* <tr>
                                                <td>Publication Type</td>
                                                <td>
                                                    { editCustomPatent ?
                                                        <Input 
                                                            defaultValue={checkValue(internationalPatentProfile.publication_type) ? internationalPatentProfile.publication_type : 'N.A.'}	
                                                            onChange={(event)=> this.handleChangePatent('publication_type', event)}
                                                        /> 
                                                        : (checkValue(internationalPatentProfile.publication_type) ? 
                                                            internationalPatentProfile.publication_type 
                                                        : 'N.A.')
                                                    }
                                                </td>
                                            </tr> */}
                      <tr>
                        <td>First Examination Report Date</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.first_examination_report_date
                                )
                                  ? moment(
                                      internationalPatentProfile.first_examination_report_date,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "first_examination_report_date",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.first_examination_report_date
                            ) ? (
                            internationalPatentProfile.first_examination_report_date
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Date of Certificate Issue</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.date_of_certificate_issue
                                )
                                  ? moment(
                                      internationalPatentProfile.date_of_certificate_issue,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "date_of_certificate_issue",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.date_of_certificate_issue
                            ) ? (
                            internationalPatentProfile.date_of_certificate_issue
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Post Grant Journal Date</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.post_grant_journal_date
                                )
                                  ? moment(
                                      internationalPatentProfile.post_grant_journal_date,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "post_grant_journal_date",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.post_grant_journal_date
                            ) ? (
                            internationalPatentProfile.post_grant_journal_date
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Renewal Date</td>
                        <td>
                          {editCustomPatent ? (
                            <DatePicker
                              defaultValue={
                                checkValue(
                                  internationalPatentProfile.renewal_date
                                )
                                  ? moment(
                                      internationalPatentProfile.renewal_date,
                                      "DD/MM/YYYY"
                                    )
                                  : ""
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) =>
                                this.handleChangeDate(
                                  "renewal_date",
                                  date,
                                  dateString
                                )
                              }
                            />
                          ) : checkValue(
                              internationalPatentProfile.renewal_date
                            ) ? (
                            internationalPatentProfile.renewal_date
                          ) : (
                            "N.A."
                          )}
                        </td>
                      </tr>
                      {/* <tr>
                                                <td>Abstract</td>
                                                <td>
                                                    { editCustomPatent ?
                                                        <Input 
                                                            defaultValue={checkValue(internationalPatentProfile.abstract) ? internationalPatentProfile.abstract : 'N.A.'}	
                                                            onChange={(event)=> this.handleChangePatent('abstract', event)}
                                                        /> 
                                                        : (checkValue(internationalPatentProfile.abstract) ? 
                                                            internationalPatentProfile.abstract 
                                                        : 'N.A.')
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Complete Specification</td>
                                                <td>
                                                    { editCustomPatent ?
                                                        <TextArea    
                                                            defaultValue={checkValue(internationalPatentProfile.complete_specification) ? internationalPatentProfile.complete_specification : 'N.A.'}	
                                                            onChange={(event)=> this.handleChangePatent('complete_specification', event)}
                                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                                        />
                                                        : (checkValue(internationalPatentProfile.complete_specification) ? 
                                                            <Text className="application-text" onClick={()=> this.showCompleteSpecification()}>
                                                                Click Hear to Show Complete Specification
                                                            </Text>  
                                                        : 'N.A.')
                                                    }
                                                </td>
                                            </tr> */}
                    </tbody>
                  </table>
                </div>
              )}
            </Card>
          </Col>
        </Row>
        {/* international Patent Information */}
        {/* UPLOADED DOCUMENTS TABLE */}
        {internationalPatentProfile && (
          <UploadDocumentsTable
            data={internationalPatentProfile}
            status={internationalPatentProfileStatus}
            postDownloadFile={(title) =>
              this.props.postDownloadFile({
                applicationNumber:
                  internationalPatentProfile.application_number,
                documentFor: IP,
                fileName: title,
              })
            }
            changeVisible={() => this.setState({ visible: true })}
          />
        )}
        {/* UPLOADED DOCUMENTS TABLE */}
        {/* NOTES SECTION TABLE */}
        {manageNotes && (
          <NotesTable
            data={manageNotes}
            manageNotesStatus={manageNotesStatus}
            deleteAllManageNotesStatus={deleteAllManageNotesStatus}
            deleteManageNotesStatus={deleteManageNotesStatus}
            handleDeleteNotesAll={() => this.handleDeleteNotesAll()}
            handleAddNotes={() => this.handleAddNotes()}
            handleEditNotes={(record) => this.handleEditNotes(record)}
            handleDeleteNotes={(record) => this.handleDeleteNotes(record)}
            notesDeletingKey={this.state.notesDeletingKey}
          />
        )}
        {/* NOTES SECTION TABLE */}
        <Drawer
          title={
            <Title level={4}>
              UPLOADED DOCUMENTS (
              {internationalPatentProfile &&
                internationalPatentProfile.application_number}
              )
            </Title>
          }
          placement="right"
          width={400}
          closable={true}
          onClose={() => this.setState({ visible: false })}
          open={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {this.props.internationalPatentProfile && (
            <UploadDocuments
              applicationNumber={internationalPatentProfile.application_number}
              documentFor={IP}
              teamMembers={
                checkValue(this.props.userDetails)
                  ? checkValue(this.props.teamMembe)
                    ? [...this.props.teamMember, this.props.userDetails]
                    : ""
                  : ""
              }
              defaultEmail={
                checkValue(this.props.userDetails)
                  ? this.props.userDetails.email
                  : ""
              }
              uploadedDocuments={internationalPatentProfile.uploaded_documents}
              putUploadedFile={(Obj) =>
                this.props.putUploadedFile(Obj, UPLOAD_FILE_TM_CUSTOM)
              }
              postUploadFile={(Obj) =>
                this.props.postUploadFile(Obj, UPLOAD_FILE_TM_CUSTOM)
              }
              deleteUploadedFile={(Obj) =>
                this.props.deleteUploadedFile(Obj, UPLOAD_FILE_TM_CUSTOM)
              }
            />
          )}
        </Drawer>
        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_IP_NOTES, PATCH_IP_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={IP}
          application_number={sessionStorage.getItem(
            "international_patent_application_number"
          )}
          note_id={this.state.note_id}
          note={this.state.note}
          type={this.state.type}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCustomListProfile,
  putUploadedFile,
  postUploadFile,
  deleteUploadedFile,
  postDownloadFile,
  getTeamMember,
  logOut,
  getNotes,
  deleteNotes,
  editCustomProfile,
  getCustomList,
};

function mapStateToProps(state) {
  const {
    internationalPatentProfile,
    internationalPatentProfileStatus,
    internationalPatentProfileEditStatus,
  } = state.managerState.profileState;

  const { teamMember, teamMemberStatus } = state.profile;

  const {
    manageNotes,
    manageNotesStatus,
    manageNotesMessage,
    addManageNotesStatus,
    editManageNotesStatus,
    deleteManageNotesStatus,
    deleteAllManageNotesStatus,
  } = state.managerState.notesState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;

  return deepFreeze({
    internationalPatentProfile,
    internationalPatentProfileEditStatus,
    internationalPatentProfileStatus,
    teamMember,
    teamMemberStatus,
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    manageNotes,
    manageNotesStatus,
    manageNotesMessage,
    addManageNotesStatus,
    editManageNotesStatus,
    deleteManageNotesStatus,
    deleteAllManageNotesStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternationalPatentProfile);
