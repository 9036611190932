import React from "react";
import {
  Dropdown,
  Button,
  Space,
  Menu,
  Switch,
  Divider,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { changeListSelectedDropdownValues } from "../../../../../../redux/actions/watchActions/watchActions";

const { Text } = Typography;

const TwoLayoutMenuFilter = ({
  title,
  data1,
  value1 = [],
  setValue1,
  data2,
  value2 = [],
  setValue2,
  loading = false,
  showAndOrSwitch1 = false,
  showAndOrSwitch2 = false,
  andOr1 = false,
  andOr2 = false,
  setAndOr1,
  setAndOr2,
  showFirstLayout = true,
  showSecondLayout = true,
}) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const dispatch = useDispatch();

  const handleSwitch = (event, setAndOr) => {
    setAndOr?.(event);
  };

  const getArrayOfObjectFromId = (filteredData, selectedIds) => {
    const valueType = typeof filteredData?.data[0].value;
    let typeConverted;
    if (valueType === "number") {
      typeConverted = selectedIds.map(Number);
    } else {
      typeConverted = selectedIds.map(String);
    }
    const tempObject =
      filteredData?.data?.filter((item) =>
        typeConverted.includes(item.value),
      ) || [];
    return tempObject;
  };

  const menuFilter = (
    filteredData,
    value,
    setValue,
    showAndOrSwitch,
    andOr,
    setAndOr,
  ) => (
    <Menu
      style={{
        position: "initial",
        overflowY: "auto",
        width: "250px",
        maxHeight: "200px",
      }}
      selectedKeys={value}
      selectable
      multiple
      onSelect={(e) => {
        setValue(e.selectedKeys);
        const tempObject = getArrayOfObjectFromId(filteredData, e.selectedKeys);
        dispatch(
          changeListSelectedDropdownValues(filteredData.key, tempObject),
        );
      }}
      onDeselect={(e) => {
        setValue(e.selectedKeys);
        const tempObject = getArrayOfObjectFromId(filteredData, e.selectedKeys);
        dispatch(
          changeListSelectedDropdownValues(filteredData.key, tempObject),
        );
      }}
    >
      {filteredData.data.length && showAndOrSwitch ? (
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Switch
              style={{ pointerEvents: "all", background: "#1677FF" }}
              defaultChecked
              checked={andOr}
              onChange={(event) => handleSwitch(event, setAndOr)}
              checkedChildren={
                <Text strong>
                  <span style={{ color: "white" }}>AND</span>
                </Text>
              }
              unCheckedChildren={
                <Text strong>
                  <span style={{ color: "white" }}>OR</span>
                </Text>
              }
            />
          </div>
          <Divider style={{ margin: "0px", marginTop: "5px" }} />
        </div>
      ) : null}
      {filteredData?.data.length ? (
        filteredData.data.map((item) => (
          <Menu.Item key={item.value}>{item.label}</Menu.Item>
        ))
      ) : (
        <>No Data</>
      )}
    </Menu>
  );

  let twoLayout = () => (
    <div className="Filters-Menu-Dual-Filter">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontWeight: "600" }}>
          {data1?.menu_label ? data1.menu_label : ""}
        </span>
        {menuFilter(
          data1,
          value1,
          setValue1,
          showAndOrSwitch1,
          andOr1,
          setAndOr1,
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontWeight: "600" }}>
          {data2?.menu_label ? data2.menu_label : ""}
        </span>
        {menuFilter(
          data2,
          value2,
          setValue2,
          showAndOrSwitch2,
          andOr2,
          setAndOr2,
        )}
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <Dropdown
        dropdownRender={() => {
          if (showFirstLayout && showSecondLayout) {
            return twoLayout();
          } else if (showFirstLayout && !showSecondLayout) {
            return menuFilter(
              data1,
              value1,
              setValue1,
              showAndOrSwitch1,
              andOr1,
              setAndOr1,
            );
          } else if (!showFirstLayout && showSecondLayout) {
            return menuFilter(
              data2,
              value2,
              setValue2,
              showAndOrSwitch2,
              andOr2,
              setAndOr2,
            );
          }
        }}
        open={openFilter}
        onOpenChange={(event) => setOpenFilter(event)}
        selectable
      >
        <Button
          className={`${
            value1.length || value2.length
              ? "Filters-Button-Selected"
              : "Filters-Button-Empty"
          }`}
          style={{ background: "inherit" }}
          loading={loading}
        >
          <Space>
            {title}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </React.Fragment>
  );
};

export default TwoLayoutMenuFilter;
