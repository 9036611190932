import React, { useState } from "react";
import { Menu, Typography, Input, Checkbox } from "antd";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { TagsTwoTone } from "@ant-design/icons";

const { Text } = Typography;
const { Search } = Input;

const FilterSearchBasic = ({
  data,
  searchFilters,
  search_key,
  filter_key,
  selectedFilters,
  switchValue,
  onSelect,
  type,
  handleNoTag,
  noTagToggle,
}) => {
  const [page, setPage] = useState(10);

  let filteredData = [];
  if (type === "object" && checkValue(type)) {
    filteredData = data?.filter((obj) =>
      checkValue(obj)
        ? obj?.label
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchFilters?.[search_key]?.toLowerCase())
        : ""
    );
  } else if (
    (type === "name" || type === "tags" || type === "noTag") &&
    checkValue(type)
  ) {
    filteredData = data?.filter((obj) =>
      checkValue(obj)
        ? obj?.name
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchFilters?.[search_key]?.toLowerCase())
        : ""
    );
  } else if (type === "number") {
    filteredData = data?.filter((obj) =>
      checkValue(obj)
        ? obj?.number
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchFilters?.[search_key]?.toLowerCase())
        : ""
    );
  } else {
    filteredData = data?.filter((eachEntity) =>
      checkValue(eachEntity)
        ? eachEntity
            ?.toString()
            ?.toLowerCase()
            .includes(searchFilters?.[search_key]?.toLowerCase())
        : ""
    );
  }

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  let itemsList = filteredData?.slice(0, page).map((entity) =>
    getItem(
      <Text
        disabled={noTagToggle ? true : false}
        className="text-camelCase"
        style={{ fontWeight: "400" }}
      >
        {(type === "name" && (
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            {entity?.name?.toString().toLowerCase()}
          </Text>
        )) ||
          (type === "number" && (
            <Text className="text-camelCase" style={{ fontWeight: "400" }}>
              {entity?.number?.toString().toLowerCase()}
            </Text>
          )) ||
          ((type === "tags" || type === "noTag") && (
            <>
              <TagsTwoTone twoToneColor={`${entity.color_code}`} />
              <Text className="text-camelCase" style={{ fontWeight: "400" }}>
                {entity?.name?.toString().toLowerCase()}
              </Text>
            </>
          )) ||
          (type === "object" && (
            <Text className="text-camelCase" style={{ fontWeight: "400" }}>
              {entity?.label?.toString().toLowerCase()}
            </Text>
          )) ||
          entity.toString().toLowerCase()}
      </Text>,
      (type === "object" && entity?.label) ||
        ((type === "name" ||
          type === "tags" ||
          type === "noTag" ||
          type === "number") &&
          entity?.id) ||
        entity,
      null
    )
  );

  return (
    <>
      <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
        <Search
          placeholder="Search"
          onChange={(event) =>
            switchValue
              ? onSelect(
                  "searchFilters",
                  switchValue,
                  search_key,
                  event.target.value
                )
              : onSelect("searchFilters", search_key, event.target.value)
          }
          value={searchFilters?.[search_key]}
          allowClear
        />

        {type === "noTag" ? (
          <Checkbox
            style={{ padding: "10px", marginLeft: "5px" }}
            checked={noTagToggle}
            onChange={() => {
              switchValue
                ? onSelect(
                    "selectedFilters",
                    switchValue,
                    "trademarkNOtags",
                    noTagToggle ? [] : ["no_tag"]
                  )
                : onSelect(
                    "selectedFilters",
                    "trademarkNOtags",
                    noTagToggle ? [] : ["no_tag"]
                  );
              handleNoTag();
            }}
          >
            <Text className="text-camelCase" style={{ fontWeight: "400" }}>
              {" "}
              No Tag{" "}
            </Text>
          </Checkbox>
        ) : (
          ""
        )}
      </div>
      <Menu
        onScroll={(x) => {
          const { scrollTop, scrollHeight, clientHeight } = x.target;
          if (scrollTop + clientHeight >= scrollHeight - 20) {
            setPage(page + 50);
          }
        }}
        multiple={true}
        selectable
        items={itemsList}
        disabled={noTagToggle ? true : false}
        selectedKeys={selectedFilters?.[filter_key]}
        onSelect={(event) =>
          switchValue
            ? onSelect(
                "selectedFilters",
                switchValue,
                filter_key,
                event.selectedKeys
              )
            : onSelect("selectedFilters", filter_key, event.selectedKeys)
        }
        onDeselect={(event) =>
          switchValue
            ? onSelect(
                "selectedFilters",
                switchValue,
                filter_key,
                event.selectedKeys
              )
            : onSelect("selectedFilters", filter_key, event.selectedKeys)
        }
      />
    </>
  );
};

export default FilterSearchBasic;
