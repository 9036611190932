import { GET_IN_TOUCH } from '../actions/commonActions/types';

const defaultWhatsNewState = {
    getInTouchMessage: null,
    getInTouchStatus: null,
};

export default function(state = defaultWhatsNewState, action){
    switch (action.type) {
        case GET_IN_TOUCH:
            return {
                ...state,
                getInTouchMessage: action.payload,
                getInTouchStatus: action.status,
            };

        default:
            return state;
    }
}