//--------LIBRARIES--------
import React from "react";
import { Modal, Button, Tabs } from "antd";

export default function TrademarksAlgosInfo({ visible, onClose }) {
  const { TabPane } = Tabs;
  return (
    <Modal
      title={<h2>Search Algorithms</h2>}
      open={visible}
      onOk={onClose}
      footer={
        <Button type="primary" onClick={onClose}>
          OK
        </Button>
      }
      maskClosable={true}
      onCancel={onClose}
      width="800px"
    >
      <Tabs defaultActiveKey="Flash">
        <TabPane tab="Flash" key="Flash">
          <div>
            <p>Combines following searches into one search:</p>
            <ul>
              <li>Fuzzy</li>
              <li>Wildcard</li>
              <li>Standard</li>
              <li>Contains</li>
              <li>Phonetic</li>
              <li>Boolean (for 2 or more words)</li>
              <li>Initial (for 2 or more words)</li>
            </ul>
            <br />
            <p>With following default values:</p>
            <ul>
              <li>
                Wildcard - <b>[[*]]</b> your mark <b>[[*]].</b>
              </li>
              <li>
                Fuzzy - Character Variation upto 40% of the characters in your
                mark.
              </li>
              <li>
                Boolean - your mark <b>[[&]]</b> your mark.
              </li>
              <li>Contains - Contains search on each word of your mark.</li>
            </ul>
            <p>
              For more thorough search, please supplement with individual
              searches.
            </p>
          </div>
        </TabPane>
        <TabPane tab="Wildcard" key="Wildcard">
          <div>
            <p>
              Perform Multi/Unlimited Character variation, Single Character
              Variation, Suffix, Prefix and Contains based searches.
            </p>
            <p>
              For Prefix, Suffix, Contains or multi/Unlimited Character
              variation choose "Unlimited character variation [[*]]" from above.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            <ul style={{ listStyle: "none" }}>
              <li>
                <b>[[*]]</b> Legal →{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Mike
                </span>
                Legal,{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  RSR
                </span>{" "}
                Legal and Legal
              </li>
              <li>
                Mike <b>[[*]]</b> → Mike
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Legal
                </span>
                , Mike{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Ross
                </span>{" "}
                and Mike
              </li>
              <li>
                <b>[[*]]</b> Mike <b>[[*]]</b> → Mike
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Legal
                </span>
                ,{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Xyz
                </span>
                Mike
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  123
                </span>{" "}
                and Mike
              </li>
            </ul>
            <br />
            <p>
              For Single Character variation search, choose "Single character
              variation [[?]]" from above.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            <ul style={{ listStyle: "none" }}>
              <li>
                <b>[[?]]</b> rystal →{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  C
                </span>
                rystal,{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  T
                </span>
                rystal and{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  K
                </span>
                rystal
              </li>
            </ul>
          </div>
        </TabPane>
        <TabPane tab="Initials" key="Initials">
          <div>
            <p>
              Initial Search allows you to identify marks with same initials.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            ‘Officers Choice’ → ‘Oliver Champagne’, ‘Omega Clove’
          </div>
        </TabPane>
        <TabPane tab="Fuzzy" key="Fuzzy">
          <div>
            <p>
              Fuzzy search allows you to identify marks with character
              variations to your mark. You can add fuzz depth from 2 to the
              lenght of your mark.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            <ul style={{ listStyle: "none" }}>
              <li>
                MikeLegal <b>with fuzz depth</b> 2 → MikeLeg
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  [al]
                </span>
                , MikeLegal
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  12
                </span>
              </li>
              <li>
                MikeLegal <b>with fuzz depth</b> 3 → MikeLe
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  [gal]
                </span>
                , MikeLegal
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  xyz
                </span>
              </li>
            </ul>
          </div>
        </TabPane>
        <TabPane tab="Boolean" key="Boolean">
          <div>
            <p>
              Boolean Search allows you to use characters <b>AND</b>, <b>OR</b>{" "}
              and <b>NOT</b> to get customised set of results. <b>AND</b> allows
              you to get results having both the elements present in the search
              query. <b>OR</b> allows you to get results with either of the
              element present in the search query. <b>NOT</b> allows you to
              remove a particular mark from the result set to get customised
              results.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            <ul style={{ listStyle: "none" }}>
              <li>
                Rock <b>[[&]]</b> Cafe → Rock Bottom Cafe, Cafe Rock Inn, Hard
                Rock Cafe and Cafe Rock
              </li>
              <li>
                Rock <b>[[|]]</b> Cafe → Rock Card, Cafe Roman and Rock Cafe
              </li>
              <li>
                Rock <b>[[!]]</b> Cafe <b>[[!]]</b> Hard → Rock Bottom Cafe,
                Cafe Rock Inn and Cafe Rock. (Here, marks which has Hard in it
                will not be shown)
              </li>
            </ul>
          </div>
        </TabPane>
        <TabPane tab="IP India" key="IP India">
          <div>
            <p>
              Now do Word (Contains) and Phonetic search for your mark for up to
              5 classes on Trademark Registry and see its results.
            </p>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
}
