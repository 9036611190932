import {
    PP_GET_PROPRIETOR_MARKS, 
    PP_GET_PROPRIETOR_DATA, 
    PP_GET_PROPRIETOR_ANALYSIS, 
    PP_SELECT_VIEW, 
    CHANGE_PROP_RESULTS_TAB
} from "../../actions/searchActions/types";

//--------IP-SUITE CONSTANTS--------
import { LOADING, SUCCESS, ERROR } from "../../../constants/commonConstants/generalConstants";
import { PP_defaultSelectedFilters, PP_defaultSorting } from '../../../constants/searchConstants/proprietorProfile';

const defaultProprietorProfileState = {
    proprietorMark: null,
    proprietorMarkStatus: null,
    proprietorMarkFilters: {
        ...PP_defaultSelectedFilters,
        filterFlag: false
    }, 
    proprietorMarkSorting: {
        ...PP_defaultSorting,
        sortFlag: false
    },
    proprietorMarkPageNumber: 1,

    proprietorProfile: null,
    proprietorProfileStatus: null,

    similarProprietor: null,
    similarProprietorStatus: null,

    proprietorAnalysis: null,
    proprietorAnalysisStatus: null,

    activeTab: "Proprietor_TMs",
    selectedView: "cards",
    axiosCancelSearches: null,
    axiosToken: null,
};

export default function(state = defaultProprietorProfileState, action){
    switch (action.type) {
        case PP_GET_PROPRIETOR_MARKS: 
            return {
                ...state,
                proprietorMark: action.payload,
                proprietorMarkStatus: action.status,
                proprietorMarkFilters: action.lastAppliedFilters,
                proprietorMarkSorting: action.lastAppliedSorting,
                proprietorMarkPageNumber: action.pageNumber,
            };
        
        case PP_GET_PROPRIETOR_DATA: 
            switch (action.status) {
                case LOADING:
                    return {
                        ...state,
                        proprietorProfileStatus: LOADING,
                        similarProprietorStatus: LOADING,
                    };

                case SUCCESS:
                    return {
                        ...state,
                        proprietorProfile: action.payload.proprietor,
                        proprietorProfileStatus: SUCCESS,
                        similarProprietor: action.payload.similarProprietor,
                        similarProprietorStatus: SUCCESS,
                    };

                case ERROR:
                    return {
                        ...state,
                        proprietorProfileStatus: ERROR,
                        similarProprietorStatus: ERROR,
                    };
            
                default:
                    break;
            }
            break;

        case PP_GET_PROPRIETOR_ANALYSIS: 
            return {
                ...state,
                proprietorAnalysis: action.payload,
                proprietorAnalysisStatus: action.status
            };

        case CHANGE_PROP_RESULTS_TAB:
            return {
                ...state,
                activeTab: action.payload
            };

        case PP_SELECT_VIEW:
            return {
                ...state,
                selectedView: action.payload
            };
        
        default:
            return state;
    }
}