//--------LIBRARIES--------
import Axios from "axios";

//--------ROOT_CONFIG--------
import { MGR_RT_URL } from "../../../../configs/rootConfigs";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../../commonActions/actions";

import {
  SEND_MANAGER_EMAIL,
  MANAGER_EMAIL_DASHBOARD,
  SENT_EMAIL_DASHBOARD,
} from "../commonAction/types";

//--------Manager: Manager-Notes--------
export function getNotes({ actionType, note_for, application_number }) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/notes/`, {
        params: { note_for, application_number },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({ type: actionType, status: SUCCESS, payload: response?.data });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: actionType, status: ERROR });
    }
  };
}

//--------Manager: Manager-Add-Notes--------
export function addNotes({
  actionType,
  note_for,
  application_number,
  email,
  note,
}) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.post(
        `${MGR_RT_URL}/notes/`,
        { note_for, application_number, email, note },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
        message: "Note Added Successfully !",
      });
    } catch (err) {
      if (err.response.status === 400) {
        dispatch({
          type: actionType,
          status: ERROR,
          message: err.response?.data.error,
        });
      } else {
        checkAuthorisation(dispatch, err);
        dispatch({ type: actionType, status: ERROR });
      }
    }
  };
}

//--------Manager: Manager-Edit-Notes--------
export function editNotes({
  actionType,
  note_for,
  application_number,
  email,
  note_id,
  note,
}) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.patch(
        `${MGR_RT_URL}/notes/`,
        { note_for, application_number, email, note_id, note },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
        message: "Note Updated Successfully !",
      });
    } catch (err) {
      if (err.response.status === 400) {
        dispatch({
          type: actionType,
          status: ERROR,
          message: err.response?.data.error,
        });
      } else {
        checkAuthorisation(dispatch, err);
        dispatch({ type: actionType, status: ERROR });
      }
    }
  };
}

//--------Manager: Manager-Edit-Notes--------
export function deleteNotes({
  actionType,
  note_for,
  application_number,
  note_id,
  delete_all,
}) {
  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    try {
      let response;
      response = await Axios.delete(`${MGR_RT_URL}/notes/`, {
        data: { note_for, application_number, note_id, delete_all },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });
      dispatch({
        type: actionType,
        status: SUCCESS,
        payload: response?.data,
        noteID: note_id,
        deleteAll: delete_all,
        message: `${delete_all ? "All" : ""} Note Deleted Successfully !`,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: actionType,
        status: ERROR,
        message: `Unfortunately, an error occured while Deleting ${
          delete_all ? "All" : ""
        } Note. Please, try again later.!`,
      });
    }
  };
}

//--------Send Email: send manager email to client--------
export function sendManagerEmail({
  send_from,
  send_to,
  cc,
  mail_subject,
  mail_body,
  source,
  source_id,
  file_attachment,
  attachment,
  multiple_files,
}) {
  let formData = new FormData();
  formData.append("send_from", send_from);
  formData.append("send_to", send_to);
  formData.append("cc", cc);
  formData.append("mail_subject", mail_subject);
  formData.append("mail_body", mail_body);
  formData.append("source", source);
  formData.append("source_id", source_id);
  formData.append("file_url", JSON.stringify(attachment));
  Array.from(multiple_files).forEach((element) =>
    formData.append("multiple_files", element),
  );
  return async (dispatch) => {
    dispatch({ type: SEND_MANAGER_EMAIL, status: LOADING });
    try {
      let response;
      response = await Axios.post(`${MGR_RT_URL}/send_email/`, formData, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: SEND_MANAGER_EMAIL,
        status: SUCCESS,
        payload: response?.data.message,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({
        type: SEND_MANAGER_EMAIL,
        status: ERROR,
        payload: err.response?.data.message,
      });
    }
  };
}

//--------Send Email: send manager email dashboard--------
export function getEmailDashboard({ filters, pageNumber }) {
  const sources = `${filters.emailSources}`;
  const date_of_sending = `${filters.date_of_sending}`;
  const contains_term = `${filters.containsTerm}`;
  return async (dispatch) => {
    dispatch({ type: MANAGER_EMAIL_DASHBOARD, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/email_dashboard/`, {
        params: {
          sources: sources ? sources : null,
          date_of_sending: date_of_sending ? date_of_sending : null,
          contains_term: contains_term ? contains_term : null,
          page: pageNumber,
        },
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: MANAGER_EMAIL_DASHBOARD,
        status: SUCCESS,
        payload: response?.data,
        lastAppliedFilters: filters,
        pageNumber,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: MANAGER_EMAIL_DASHBOARD, status: ERROR });
    }
  };
}

//--------Send Email: send manager email dashboard--------
export function sentEmailDashboard({ application_number }) {
  let response;
  return async (dispatch) => {
    dispatch({ type: SENT_EMAIL_DASHBOARD, status: LOADING });
    try {
      response = await Axios.get(`${MGR_RT_URL}/email_dashboard/`, {
        params: {
          contains_term: application_number,
        },
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-type": "multipart/form-data",
        },
      });

      dispatch({
        type: SENT_EMAIL_DASHBOARD,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: SENT_EMAIL_DASHBOARD, status: ERROR });
    }
  };
}

//--------Email Management--------
export function getEmailManagement({ filters, pageNumber }) {
  const sources = `${filters.emailSources}`;
  const date_of_sending = `${filters.date_of_sending}`;
  const contains_term = `${filters.containsTerm}`;
  return async (dispatch) => {
    dispatch({ type: MANAGER_EMAIL_DASHBOARD, status: LOADING });
    try {
      let response;
      response = await Axios.get(`${MGR_RT_URL}/email_dashboard/`, {
        params: {
          sources: sources ? sources : null,
          date_of_sending: date_of_sending ? date_of_sending : null,
          contains_term: contains_term ? contains_term : null,
          page: pageNumber,
        },
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-type": "multipart/form-data",
        },
      });
      dispatch({
        type: MANAGER_EMAIL_DASHBOARD,
        status: SUCCESS,
        payload: response?.data,
        lastAppliedFilters: filters,
        pageNumber,
      });
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: MANAGER_EMAIL_DASHBOARD, status: ERROR });
    }
  };
}
