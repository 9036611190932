import React, { Component } from "react";
import { Modal, Typography } from "antd";

const { Title } = Typography;

export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Modal
          title={
            <div className="alignC">
              <Title level={3} style={{ margin: 0 }}>
                Privacy Policy
              </Title>
            </div>
          }
          open={this.props.visible}
          onOk={this.props.onCloseModal}
          onCancel={this.props.onCloseModal}
          bodyStyle={{ padding: 0 }}
          width={600}
          footer={false}
        >
          <div class="modal-container vertical_scroll">
            <p>
              Thank you for choosing our services. We firmly believe that your
              business is no one else’s. Your privacy is important to us and so
              we will protect all the information you share with us.
            </p>
            <p>
              We will ask you when we need information that personally
              identifies you (Personal Information) or allows us to contact you.
              Generally, this information is requested when you register on the
              site or purchase our services. We use your Personal Information
              for the following primary purposes –
            </p>
            <p>1. Providing personalized offers;</p>
            <p>2. Analyzing data for market research;</p>
            <p>3. Sending you exclusive offers and promotional materials;</p>
            <p>
              We may also collect device-specific information including but not
              limited to hardware model, operating system version and mobile
              network information.
            </p>
            <p>
              When you use our services or view content on our website, we may
              automatically collect and store certain information in server
              logs. This may include –
            </p>
            <p>1. Details of how you used our service;</p>
            <p>2. Internet protocol address;</p>
            <p>
              3. Device event information including but not limited to crashes,
              system activity, browser type, hardware settings and referral URL;
            </p>
            <p>
              4. Cookies that may uniquely identify your browser or your Mike
              Account.
            </p>
            <p>
              The security of your Personal Information is very important to us
              and we ensure that we follow the safest and best possible method
              for transmission of data and storage of data. However, we shall
              not be liable in the event of any breach of data.
            </p>
          </div>
        </Modal>
      </>
    );
  }
}
