import React, { Component } from "react";
import { Alert, Button, message, Col, Modal } from "antd";
import { connect } from "react-redux";
import { resetRecoveryCode } from "../../../redux/actions/commonActions/actions";

class RecoveryCodesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  resetRecoveryCodes = () => {
    this.props.resetRecoveryCode({
      user_id: localStorage.getItem("user_id"),
      operation: "RESET",
      sub_operation: "RECOVERY_CODE",
    });
  };

  render() {
    const { profileRecoveryCodes, authRecoveryCodes } = this.props;

    let recoveryCodes = [];
    if (authRecoveryCodes && authRecoveryCodes.length > 0) {
      recoveryCodes = authRecoveryCodes;
    } else if (profileRecoveryCodes && profileRecoveryCodes.length > 0) {
      recoveryCodes = profileRecoveryCodes;
    }

    return (
      <Modal
        open={this.props.visible}
        onOk={this.props.onCloseModal}
        onCancel={this.props.onCloseModal}
        closable={false}
        footer={false}
        maskClosable={false}
        bodyStyle={{
          borderRadius: "24px",
        }}
        title={null}
        className="setup-2fa-modal"
      >
        <div style={{ margin: "8px" }}>
          <h3 style={{ fontWeight: "bold" }}>Recovery Codes</h3>
          <hr />
          <Alert
            style={{ margin: "16px 0px" }}
            message="Please save these codes, it’ll help you to recover your account in case you loose your device."
            type="warning"
            showIcon
          />
          <p style={{ color: "rgb(61 58 58 / 80%)" }}>
            Treat your recovery codes with the same level of attention as you
            would your password! We recommend saving them with a password
            manager.
          </p>

          <div
            id="recovery-code"
            style={{ display: "flex", marginBottom: "16px" }}
          >
            <section style={{ marginRight: "28px" }}>
              {recoveryCodes &&
                recoveryCodes.length > 0 &&
                recoveryCodes?.map((item, idx) => {
                  if (idx < recoveryCodes?.length / 2)
                    return (
                      <p
                        key={item?.code}
                        style={
                          !item?.used
                            ? { marginBottom: "8px" }
                            : {
                                marginBottom: "8px",
                                textDecoration: "line-through",
                              }
                        }
                      >
                        {item?.code}
                      </p>
                    );
                })}
            </section>
            <section>
              {recoveryCodes &&
                recoveryCodes.length > 0 &&
                recoveryCodes?.map((item, idx) => {
                  if (idx >= recoveryCodes?.length / 2)
                    return (
                      <p
                        key={item?.code}
                        style={
                          !item?.used
                            ? { marginBottom: "8px" }
                            : {
                                marginBottom: "8px",
                                textDecoration: "line-through",
                              }
                        }
                      >
                        {item?.code}
                      </p>
                    );
                })}
            </section>
          </div>

          <div style={{ display: "flex" }}>
            <Button
              type="primary"
              style={{ width: "115px" }}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(
                  [...recoveryCodes.map((item) => item?.code)].join(", ")
                );
                message.success({
                  content: "Recovery codes copied successfully.",
                  style: {
                    marginLeft: "23px",
                    marginTop: "80vh",
                  },
                });
              }}
            >
              Copy Codes
            </Button>
            <Button
              type="primary"
              style={{ width: "115px", marginLeft: "10px" }}
              onClick={(e) => {
                e.preventDefault();

                var mywindow = window.open(
                  "",
                  "new div",
                  "height=400,width=600"
                );
                mywindow.document.write("<html><head><title></title>");
                mywindow.document.write(
                  '<link rel="stylesheet" href="style.css" type="text/css"/>'
                );
                mywindow.document.write("</head><body >");
                mywindow.document.write(
                  document.getElementById("recovery-code").innerHTML
                );
                mywindow.document.write("</body></html>");
                mywindow.print();
                // window.print();
              }}
            >
              Print Codes
            </Button>
          </div>
          <hr style={{ margin: "16px 0px" }} />
          <div>
            <h3 style={{ fontWeight: "bold" }}>Generate New Codes</h3>

            <p style={{ marginTop: "12px" }}>
              When you generate new recovery codes, you must download or print
              the new codes. Your old codes won’t work anymore.
            </p>
            <div style={{ display: "flex" }}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.resetRecoveryCodes();
                }}
              >
                Generate New Codes
              </Button>
              <Button
                onClick={this.props.onCloseModal}
                style={{ marginLeft: "auto" }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  resetRecoveryCode,
};

function mapStateToProps(state) {
  const {
    recoveryCodes: authRecoveryCodes,
    authCodeStatus,
    authVerification,
  } = state.signInState;
  const { recovery_codes: profileRecoveryCodes } = state.profile.userProfile;

  return {
    profileRecoveryCodes,
    authRecoveryCodes,
    authCodeStatus,
    authVerification,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryCodesModal);
