import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Layout, Row, Col, Button } from "antd";

//--------CONSTANTS--------
import { MANAGER } from "../../../constants/commonConstants/generalConstants";

import { changeStateValue_L0 } from "../../../functions/commonFunctions/generalFunctions";

import EditProfile from "../../commonComponents/ProfileDetails/EditProfile";
import TeamMemberDetails from "../../commonComponents/ProfileDetails/TeamMember";
import AddTrademarks from "../../commonComponents/Modals/AddTrademarks";
import EmailPreferences from "./EmailPreferences";

const { Content } = Layout;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addMarkVisible: false,
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    if (window.Tawk_API !== undefined) {
      window.Tawk_API.showWidget();
    } else {
      //Chat Widget--------Tawk
      (function () {
        const s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/619205a96885f60a50bbcd33/1fkh6c8b3";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }

  componentWillUnmount() {
    //Chat Widget--------Hider
    if (window.Tawk_API !== undefined) {
      window.Tawk_API.hideWidget();
    }
  }

  render() {
    return (
      <div className="Profile">
        <Layout>
          <Content style={{ margin: "0px" }}>
            <Row
              className="content-container vertical_scroll"
              style={{
                height: `${
                  this.props.managerAlert.length > 0
                    ? "calc(100vh - 40px)"
                    : "calc(100vh - 0px)"
                }`,
              }}
            >
              <Col span={24}>
                {/* download-mark */}
                <Row>
                  <Col span={24} className="alignR">
                    <Button
                      type="primary"
                      onClick={() =>
                        this.changeStateValue_L0("addMarkVisible", true)
                      }
                    >
                      Add Mark
                    </Button>
                  </Col>
                </Row>
                {/* download-mark */}

                {/* profile-edit */}
                <Row>
                  <Col span={24} className="m-top-20">
                    <EditProfile />
                  </Col>
                </Row>
                {/* profile-edit */}

                {/* TeamMember */}
                <Row>
                  <Col span={24} className="m-top-20">
                    <TeamMemberDetails product={"manager"} />
                  </Col>
                </Row>
                {/* TeamMember */}

                {/* EmailPreferences */}
                <Row>
                  <Col span={24}>
                    <EmailPreferences />
                  </Col>
                </Row>
                {/* TeamMember */}
              </Col>
            </Row>
          </Content>
        </Layout>

        <AddTrademarks
          visible={this.state.addMarkVisible}
          onCloseModal={() => this.changeStateValue_L0("addMarkVisible", false)}
          product={MANAGER}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return deepFreeze({
    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
