import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import _ from "lodash";
import Axios from "axios";

import {
  Button,
  Input,
  notification,
  Radio,
  Drawer,
  Menu,
  Alert,
  Checkbox,
  Typography,
  message,
  Row,
  Col,
  Space,
} from "antd";

import {
  SUCCESS,
  ERROR,
  PDF,
  EXCEL,
  LOADING,
} from "../../../constants/commonConstants/generalConstants";
import { downloadReport } from "../../../redux/actions/managerActions/report/actions";
import { downloadNotesReport } from "../../../redux/actions/managerActions/notes/actions";
import { FixedSizeList } from "react-window";

import { changeStateValue_L1 } from "../../../functions/commonFunctions/generalFunctions";

const { Text, Title } = Typography;
const { Search } = Input;
class DownloadNotesReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFormat: EXCEL,
      reportName: "",
      reportPreference:
        this.props.userSubscriptions &&
        this.props.userSubscriptions.manager.includes("PSEUDO_TRADEMARK")
          ? "comparative"
          : "",
      selectedCols: [],
      searchTerm: "",
      error: false,
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  onReportPreference = (event) => {
    this.setState({
      reportPreference: event.target.value,
      reportFormat:
        event.target.value === "comparative" ? EXCEL : this.state.reportFormat,
    });
  };

  async handleReport() {
    const { selectAll, selectedRows, filterFlag, filters } = this.props;

    let filter_data = {};

    if (filters?.filterFlag) {
      filter_data = {
        classes: filters?.trademarkClass,
        proprietor: filters?.trademarkProprietors,
        tags: filters?.trademarkTags,
        contains_term: filters?.containsTerm,
        no_tags: filters?.trademarkNOtags?.length > 0 ? true : false,
        inventor: filters?.patentInventors,
        applicant: filters?.patentApplicants,
      };
    }

    if (!this.state.error) {
      if (this.props.product_type === "TRADEMARK") {
        this.props.downloadNotesReport({
          selectAll,
          selected_marks: selectAll ? [] : selectedRows,
          custom_trademark:
            this.props.mmType === "CUSTOM_TRADEMARK" ? true : "",
          international_trademark:
            this.props.mmType === "INTERNATIONAL_TRADEMARK" ? true : "",
          filterFlag,
          filters: filter_data,
          report_columns: this.state.selectedCols,
          report_name: this.state?.reportName,
        });
      } else {
        this.props.downloadNotesReport({
          selectAll,
          selected_patents: selectAll ? [] : selectedRows,
          custom_patent: this.props.mmType === "CUSTOM_PATENT" ? true : "",
          international_patent:
            this.props.mmType === "INTERNATIONAL_PATENT" ? true : "",
          filterFlag,
          type: "patent",
          filters: filter_data,
          report_columns: this.state.selectedCols,
          report_name: this.state?.reportName,
        });
      }

      this.props.onCloseModal();
      notification["success"]({
        message:
          "Your Report is being generated. You can visit the Reports section to access the report.",
      });
    }
  }

  render() {
    let filteredCols = this.props.report_columns?.filter((item) =>
      `${item}` !== null
        ? `${item.toLowerCase()}`.includes(`${this.state.searchTerm}`)
        : ""
    );
    const reportCols = (
      <>
        <div style={{ padding: "10px" }}>
          <Search
            placeholder="Select/Search Columns"
            onChange={(event) =>
              this.setState({ searchTerm: event.target.value })
            }
            value={this.state.searchTerm}
            allowClear
          />
        </div>
        <FixedSizeList
          className="List"
          height={300}
          itemCount={filteredCols?.length}
          itemSize={35}
          itemData={filteredCols}
        >
          {({ index, style, data }) => {
            return (
              <Menu
                selectable={true}
                multiple={true}
                selectedKeys={this.state.selectedCols}
                onSelect={(event) => {
                  this.setState({
                    selectedCols: event.selectedKeys,
                  });
                }}
                onDeselect={(event) => {
                  this.setState({
                    selectedCols: this.state.selectedCols.filter(
                      (x) => x !== event.key
                    ),
                  });
                }}
              >
                <Menu.Item
                  key={data[index]}
                  style={{
                    ...style,
                    padding: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  className="menu-items-list"
                >
                  <Checkbox
                    checked={this.state.selectedCols.includes(`${data[index]}`)}
                  >
                    <Text style={{ fontWeight: "400" }}>
                      {data[index] ? data[index] : ""}
                    </Text>
                  </Checkbox>
                </Menu.Item>
              </Menu>
            );
          }}
        </FixedSizeList>
      </>
    );

    return (
      <Drawer
        title={<h3>Custom Report Details</h3>}
        placement="right"
        width={400}
        closable={false}
        open={this.props.visible}
        onClose={() => this.props.onCloseModal()}
      >
        <Row>
          <Col span={24}>
            <p className="fw_500">Enter the name of your report:</p>
            <Input
              maxLength={100}
              onChange={(event) => {
                if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                  this.setState({
                    reportName: event.target.value,
                    error: event.target.value.length >= 100 ? true : false,
                  });
                }
              }}
              value={this.state.reportName}
              placeholder="Report's name (Optional)"
            />
          </Col>
        </Row>
        {this.state.reportName.length >= 100 ? (
          <Row className="m-top-20">
            <Col span={24}>
              <p className="mt-3" rootStyle={{ color: "red" }}>
                Report should be less than 100 character
              </p>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row className="m-top-20">
          <Col span={24}>
            <p className="fw_500">Choose your preferred report format:</p>
          </Col>
        </Row>
        <Radio.Group
          value={this.state.reportFormat}
          onChange={(event) => {
            if (
              this.state.reportPreference === "comparative" ||
              this.props.internationalTrademark === "international_trademark" ||
              this.props.customTrademark === "custom_trademark" ||
              this.props.internationalPatent === "international_patent" ||
              this.props.customPatent === "custom_patent"
            ) {
              this.setState({ reportFormat: EXCEL });
            } else {
              this.setState({ reportFormat: event.target.value });
            }
          }}
          size="small"
        >
          <Radio value={EXCEL} style={{ fontWeight: 400 }}>
            EXCEL
          </Radio>
        </Radio.Group>
        <Row className="m-top-20">
          <Col span={24}>
            <Alert
              message="INFO: By default the report will contain all columns."
              type="warning"
            />
          </Col>
        </Row>
        <Row className="m-top-20">
          <Col span={24}>
            <div style={{ margin: "16px 0px", border: " 1px solid #F1F1F1" }}>
              {reportCols}
            </div>
          </Col>
        </Row>
        <Row className="m-top-20">
          <Col span={24}>
            <Space>
              <Button
                loading={this.props.downloadReportStatus === LOADING}
                type="primary"
                ghost
                onClick={() => this.handleReport()}
                rootStyle={{ marginRight: "10px" }}
              >
                Confirm
              </Button>
              <Button danger ghost onClick={() => this.props.onCloseModal()}>
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

const mapDispatchToProps = {
  downloadReport,
  downloadNotesReport,
};

function mapStateToProps(state) {
  const { downloadReportMsg, downloadReportStatus } =
    state.managerState.reportState;
  const { report_columns } =
    state.managerState.notesDashboard?.manageNotes?.trademark ||
    state.managerState.notesDashboard?.manageNotes?.patent;

  const { userSubscriptions, userDetails, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    downloadReportMsg,
    downloadReportStatus,
    userSubscriptions,
    userDetails,
    userSubscriptionStatus,
    report_columns,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadNotesReportModal);
