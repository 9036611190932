import { GET_WHATS_NEW } from '../actions/commonActions/types';

const defaultWhatsNewState = {
    whatsNew: null,
    whatsNewStatus: null,
};

export default function(state = defaultWhatsNewState, action){
    switch (action.type) {
        case GET_WHATS_NEW:
            return {
                ...state,
                whatsNew: action.payload,
                whatsNewStatus: action.status,
            };

        default:
            return state;
    }
}