import React, { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import axios from "axios";
import { ROOT_URL } from "../../../../../../configs/rootConfigs";

const { Item } = Form;

const CreateAnalysisModalForm = ({ openAnalysis, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${ROOT_URL}/brand_compare/manage/analyze/`,
        {
          analysis_name: values.analysisName,
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      if (response.data.success) {
        message.success(response.data.message);
        form.resetFields();
        onCancel();
      } else {
        message.error("Failed to start analysis");
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to start analysis",
      );
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Start Analysis"
      open={openAnalysis}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Item
          name="analysisName"
          label="Name for the Analysis"
          rules={[{ required: true, message: "Please enter analysis name" }]}
        >
          <Input placeholder="Enter analysis name" />
        </Item>

        <Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Start Analysis
          </Button>
          <Button onClick={onCancel} style={{ marginLeft: "10px" }}>
            Cancel
          </Button>
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateAnalysisModalForm;
