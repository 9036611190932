import {
  Col,
  Row,
  Space,
  Button,
  Badge,
  Grid,
  Tooltip,
  notification,
  Checkbox,
  Dropdown,
  Menu,
} from "antd";
import React from "react";
import {
  FilterFilled,
  MinusCircleOutlined,
  PlaySquareOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  default_filter_page,
  default_filter_page_size,
} from "../../utils/utils";
import { logOut } from "../../../../../redux/actions/commonActions/actions";
import { LOADING } from "../../../../../constants/commonConstants/generalConstants";
import SearchTypeDropdown from "./../SearchTypeDropdown";
import JournalFilter from "./../JournalFilter";
import {
  getWatchJournal,
  getWatchAllFilters,
  changeTemplateId,
  createCombinePdf,
  changeFilterApplied,
  resetAllFilter,
} from "../../../../../redux/actions/watchActions/watchActions";
import { W_JOURNAL_DASHBOARD_TYPE } from "../../../../../redux/actions/watchActions/types";
import MenuFilter from "./../MenuFilter";
import ProprietorFilterComponent from "./../ProprietorFilterComponent";
import SelectFilter from "./../SelectFilter";
import DateFilter from "./../DateFilter";
import CreateReportDrawer from "./CreateReportDrawer";
import {
  addGridSampleData,
  getGridLayoutData,
} from "../../../../../redux/actions/watchActions/gridDashboardActions";
import CheckableTagComponent from "../CheckableTagComponent";
import {
  gridSampleObject,
  gridThreatSampleObject,
} from "./../../../Pages/GridLayoutDashboard/gridSampleObject";
import AdvanceCombinePDF from "../AdvanceCombinePDF";

const PrimaryGridFilter = (props) => {
  const {
    showHeader,
    tmTypes,
    setTMTypes,
    tmCategories,
    setTMCategories,
    tmOffices,
    setTMOffices,
    journal,
    setJournal,
    searchType,
    setSearchType,
    searchValue,
    setSearchValue,
    proprietors,
    setProprietors,
    tmClass,
    setTMClass,
    yourDateOfApplication,
    setYourDateOfApplication,
    yourDateOfUsage,
    setYourDateOfUsage,
    yourDateOfUsageProposed,
    setYourDateOfUsageProposed,
    excludeMarkProprietors,
    setExcludeMarkProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    publishedDate,
    setPublishedDate,
    primaryFilterApplied,
    setPrimaryFilterApplied,
    andOrClass,
    setAndOrClass,
    primaryFilterSelected,
    generateFilterObject,
    selectedData,
    isTourOpen,
    setIsTourOpen,
  } = props;

  const dispatch = useDispatch();
  const current_template_id = 0;
  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3] || "";
  const [localJournal, setLocalJournal] = React.useState(0);
  const [openBulkTemplateDownload, setOpenBulkTemplateDownload] =
    React.useState(false);

  const getPrimaryFilterPath = () => {
    // for grid layout only...

    let templatePath = "applied/list_layout";

    if (urlKey === "tm-applied" || urlKey === "tm_applied") {
      templatePath = "applied/list_layout";
    } else if (urlKey === "tm-protect" || urlKey === "tm_protect") {
      templatePath = "tm_protect/list_layout";
    } else if (
      urlKey === "image-text" ||
      urlKey === "image_text" ||
      urlKey === "image_text_new"
    ) {
      templatePath = "image_text/list_layout";
    } else if (urlKey === "image_label") {
      templatePath = "image_label/list_layout";
    } else {
      templatePath = "applied/list_layout";
    }
    return templatePath;
  };

  let filterPath = getPrimaryFilterPath();

  const getDashboardForJounral = () => {
    let templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_APPLIED;

    if (urlKey === "tm-applied" || urlKey === "tm_applied") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_APPLIED;
    } else if (urlKey === "tm-protect" || urlKey === "tm_protect") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_PROTECT;
    } else if (urlKey === "image-text" || urlKey === "image_text") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_IMAGE_TEXT;
    } else if (urlKey === "image_label") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_IMAGE_LABEL;
    } else if (urlKey === "image_text_new") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_IMAGE_TEXT_NEW;
    } else {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_APPLIED;
    }
    return templatePath;
  };

  const getCreationOrigin = () => {
    let imageLabel = "IMAGE_LABEL";

    if (urlKey === "tm-applied" || urlKey === "tm_applied") {
      imageLabel = "APPLIED_FOR";
    } else if (urlKey === "tm-protect" || urlKey === "tm_protect") {
      imageLabel = "TM_PROTECT";
    } else if (
      urlKey === "image-text" ||
      urlKey === "image_text" ||
      urlKey === "image_text_new"
    ) {
      imageLabel = "IMAGE_TEXT";
    } else if (urlKey === "image_label") {
      imageLabel = "IMAGE_LABEL";
    } else {
      imageLabel = "IMAGE_LABEL";
    }
    return imageLabel;
  };

  const {
    journalConfig,
    markTypeConfig,
    markCategoryConfig,
    markOfficeConfig,
    markproprietorConfig,
    markClassConfig,
    temp_threat_filters,
    reduxSearchValue,
    reduxSearchType,
    isFilterApplied,
    yourDateOfApplicationConfig,
    yourDateOfUsageConfig,
  } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const { isFiltersRendered, isJournalRendered } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey],
  );

  const {
    journalNumber,
    data,
    report_columns,
    status,
    pageNumber,
    combinePdf,
    isDataPresent,
  } = useSelector(
    (state) => state.watchState.gridLayoutData[dashboardLayout][urlKey].config,
  );

  const { isComponentRendered } = useSelector(
    (state) => state.watchState.gridLayoutData[dashboardLayout][urlKey],
  );

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      if (journalNumber[`${urlKey}`] === null && !isJournalRendered) {
        dispatch(getWatchJournal(getDashboardForJounral()));
      }
    } else {
      dispatch(logOut());
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      if (!isComponentRendered) {
        props?.handleEmptySelectedData();
        let currentJournal = journalNumber[`${urlKey}`];
        if (currentJournal === null) {
          currentJournal = journalConfig?.data[0]?.value;
        }
        if (
          currentJournal &&
          currentJournal !== "" &&
          currentJournal !== undefined
        ) {
          dispatch(
            getGridLayoutData(currentJournal, 1, false, {}, window?.location),
          );
        }
      }
    } else {
      dispatch(logOut());
    }
  }, [journalConfig?.data?.length]);

  const fetchFilters = () => {
    if (urlKey !== "tm_protect") {
      let dashboardUrl = getPrimaryFilterPath();
      const filterKeys = [
        "mark_proprietors",
        "mark_classes",
        "mark_types",
        "mark_categories",
        "mark_offices",
      ];
      filterKeys.map((item) =>
        dispatch(
          getWatchAllFilters(
            journal,
            item,
            null,
            default_filter_page,
            default_filter_page_size,
            current_template_id,
            dashboardUrl,
            0, // application number not needed for your marks filter
          ),
        ),
      );
    }
  };

  const getStateFromRedux = () => {
    const getIdsFromArrayOfObject = (values, functionName = "Number") => {
      return values.length
        ? values.map((item) =>
            functionName === "String" ? String(item.value) : Number(item.value),
          )
        : [];
    };
    setSearchType(reduxSearchType);
    setSearchValue(reduxSearchValue);
    setPrimaryFilterApplied(isFilterApplied);
    markTypeConfig?.selectedValues?.length
      ? setTMTypes(
          getIdsFromArrayOfObject(markTypeConfig?.selectedValues, "String"),
        )
      : setTMTypes([]);
    markCategoryConfig?.selectedValues?.length
      ? setTMCategories(
          getIdsFromArrayOfObject(markCategoryConfig?.selectedValues, "String"),
        )
      : setTMCategories([]);
    markOfficeConfig?.selectedValues?.length
      ? setTMOffices(
          getIdsFromArrayOfObject(markOfficeConfig?.selectedValues, "String"),
        )
      : setTMOffices([]);
    markproprietorConfig?.selectedValues?.length
      ? setProprietors(
          getIdsFromArrayOfObject(markproprietorConfig?.selectedValues),
        )
      : setProprietors([]);
    markproprietorConfig?.excludeValues?.length
      ? setExcludeMarkProprietors(
          getIdsFromArrayOfObject(markproprietorConfig?.excludeValues),
        )
      : setExcludeMarkProprietors([]);
    markClassConfig?.selectedValues?.length
      ? setTMClass(getIdsFromArrayOfObject(markClassConfig?.selectedValues))
      : setTMClass([]);
    yourDateOfApplicationConfig?.value?.from &&
      setYourDateOfApplication(yourDateOfApplicationConfig?.value);
    yourDateOfUsageConfig?.value?.from &&
      setYourDateOfUsage(yourDateOfUsageConfig?.value);
    setYourDateOfUsageProposed(yourDateOfUsageConfig?.dateOfUsageProposed);
    setAndOrClass(markClassConfig.andOr);
  };

  React.useEffect(() => {
    // to fetch data from redux when switching page
    if (isFiltersRendered) {
      getStateFromRedux();
    }
  }, []);

  React.useEffect(() => {
    // first time rendered when jounral should be there
    if (journal && !isFiltersRendered) {
      fetchFilters();
    }
    dispatch(changeTemplateId(0));
  }, [journal]);

  React.useEffect(() => {
    // when journal change from dropdown
    if (localJournal && isFiltersRendered) {
      fetchFilters();
    }
    dispatch(changeTemplateId(0));
  }, [localJournal]);

  const [openCreateReport, setOpenCreateReport] = React.useState(false);

  let listOfSearchType = [
    "Application No/Name",
    "Your Mark Description Search",
  ];

  let listOfTMProtectSearchType = ["Application No/Name"];

  const handleApplyFilters = () => {
    setPrimaryFilterApplied(true);
    const filters = generateFilterObject();
    dispatch(getGridLayoutData(journal, 1, true, filters, window?.location));
    dispatch(changeFilterApplied(true));
  };

  const handleResetFilters = (journalNumber) => {
    setPrimaryFilterApplied(false);
    setSearchValue("");
    setTMTypes([]);
    setTMCategories([]);
    setTMOffices([]);
    setSearchType("Application No/Name");
    setProprietors([]);
    setExcludeMarkProprietors([]);
    setTMClass([]);
    setAndOrClass(false);
    setYourDateOfApplication({
      from: "",
      to: "",
      dateString: "",
    });
    setYourDateOfUsage({
      from: "",
      to: "",
      dateString: "",
    });
    setYourDateOfUsageProposed(false);
    setSameClassInfringement(false);
    setExcludeSameClassInfringement(false);
    props?.handleEmptySelectedData();
    dispatch(getGridLayoutData(journalNumber, 1, false, {}, window?.location));
    dispatch(resetAllFilter());
  };

  const handleJournalChange = (journalNumber, option) => {
    setJournal(journalNumber);
    setLocalJournal(journalNumber);
    setPublishedDate(
      journalConfig?.data?.filter(
        (journal) => journal.value === journalNumber,
      )[0].published_date,
    );
    handleResetFilters(journalNumber);
    props?.handleEmptySelectedData();
  };

  const handleCreateReport = () => {
    if (selectedData.select_all && !primaryFilterApplied) {
      notification["warning"]({
        message:
          "You can access this report from 'Automated Report' section in Reports",
        duration: 2,
      });
      return;
    }
    setOpenCreateReport(true);
  };

  const handleCombinePdf = () => {
    let body = {
      journal_number: journal,
      filter_flag: false,
      creation_origin: getCreationOrigin(),
      source: "GRID_LAYOUT",
    };
    if (selectedData.select_all) {
      let modifiedBody = {
        ...body,
        filter_flag: true,
        data: {},
        filters: generateFilterObject(),
      };
      dispatch(createCombinePdf(modifiedBody));
    } else {
      const secondaryFilteredData = {};
      Object.entries(selectedData?.data).map((item) => {
        if (item[1]?.select_all || item[1]?.threats?.length > 0) {
          secondaryFilteredData[item[0]] = {
            filter_flag: item[1]?.select_all || false,
            selected_ids: item[1]?.threats || [],
            filters:
              (temp_threat_filters[urlKey] &&
                temp_threat_filters[urlKey][item[0]] &&
                temp_threat_filters[urlKey][item[0]]) ||
              {},
          };
        }
      });

      let modifiedBody = {
        ...body,
        data: secondaryFilteredData,
        filters: generateFilterObject(),
      };
      // console.log("modified body",modifiedBody)
      dispatch(createCombinePdf(modifiedBody));
    }
  };

  const useMemoizedComponent = (Component, deps) => {
    return React.useMemo(() => Component, deps);
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key={1} onClick={() => handleCombinePdf()}>
        Download Only Journal Copy
      </Menu.Item>
      <Menu.Item key={2} onClick={() => setOpenBulkTemplateDownload(true)}>
        Bulk Template Download
      </Menu.Item>
    </Menu>
  );

  const onCloseBulkTemplateModal = () => {
    setOpenBulkTemplateDownload(false);
  };

  const firstRow = (
    <Row style={{ width: "100%", display: "flex" }}>
      <Col
        style={showHeader ? { marginBottom: "16px" } : { marginBottom: "4px" }}
      >
        <Space>
          {useMemoizedComponent(
            <SearchTypeDropdown
              value={searchType}
              setValue={setSearchType}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              listOfSearchType={
                urlKey === "tm_protect"
                  ? listOfTMProtectSearchType
                  : listOfSearchType
              }
              updateRedux
            />,
            [searchType, searchValue],
          )}
          <Button
            type="primary"
            icon={<FilterFilled />}
            disabled={
              !primaryFilterSelected || (status === LOADING ? true : false)
            }
            onClick={handleApplyFilters}
            loading={status === LOADING ? true : false}
          >
            Apply
          </Button>
          <Button
            type="text"
            danger
            onClick={() => handleResetFilters(journal)}
            disabled={!primaryFilterApplied}
          >
            Reset All
          </Button>
        </Space>
      </Col>
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          marginLeft: "auto",
        }}
      >
        <Space size={selectedData.selectAll ? "large" : "middle"}>
          <Checkbox
            checked={selectedData.select_all}
            onClick={(event) =>
              props.setSelectedData({
                ...selectedData,
                select_all: event.target.checked,
              })
            }
            disabled={!data?.length}
          >
            Select All
          </Checkbox>
          {selectedData.threatsCount > 0 && (
            <Tooltip title="Deselect All">
              <Button
                danger
                onClick={() => {
                  props?.handleEmptySelectedData();
                }}
                disabled={!selectedData.threatsCount}
                icon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MinusCircleOutlined style={{ margin: 0, fontSize: 16 }} />
                  </div>
                }
              />
            </Tooltip>
          )}
          <Badge
            overflowCount={9999}
            count={selectedData.select_all ? "ALL" : selectedData.threatsCount}
            style={{ marginTop: "5px" }}
          >
            <Button
              onClick={handleCreateReport}
              disabled={!(selectedData.select_all || selectedData.threatsCount)}
            >
              Create Report
            </Button>
          </Badge>
          {urlKey !== "tm_protect" ? (
            <Badge
              overflowCount={9999}
              count={
                selectedData.select_all ? "ALL" : selectedData.threatsCount
              }
              style={{ marginTop: "5px" }}
            >
              {urlKey === "image_text" || urlKey === "image_label" ? (
                <Button
                  id="grid_filter_combine_pdf_btn"
                  onClick={handleCombinePdf}
                  disabled={
                    !(
                      selectedData.select_all ||
                      selectedData.threatsCount ||
                      combinePdf?.loading
                    )
                  }
                  loading={combinePdf?.loading}
                >
                  Combine PDF
                </Button>
              ) : (
                <div>
                  <Dropdown
                    overlay={dropdownMenu}
                    disabled={
                      !(
                        selectedData.select_all ||
                        selectedData.threatsCount ||
                        combinePdf?.loading
                      )
                    }
                    loading={combinePdf.loading}
                  >
                    <Button id="grid_filter_combine_pdf_btn">
                      <Space>Combine PDF</Space>
                    </Button>
                  </Dropdown>
                </div>
              )}
            </Badge>
          ) : (
            <CheckableTagComponent
              label={<PlaySquareOutlined />}
              value={isTourOpen}
              setValue={async (v) => {
                if (!isDataPresent) {
                  dispatch(
                    addGridSampleData(gridSampleObject, gridThreatSampleObject),
                  );
                }
                setIsTourOpen(v);
              }}
              disableTooltip="Take Tour"
              activeTooltip="Take Tour"
            />
          )}
          <JournalFilter
            filteredData={journalConfig}
            value={journal}
            handleJournalChange={handleJournalChange}
            extraLoading={status === LOADING ? true : false}
          />
        </Space>
      </Col>
    </Row>
  );

  const secondRow = (
    <Row style={showHeader ? { width: "100%" } : { display: "none" }}>
      <Col id={"grid-mark-filters"} lg={18} md={24}>
        <Space>
          {useMemoizedComponent(
            <MenuFilter
              filteredData={markTypeConfig}
              value={tmTypes}
              setValue={setTMTypes}
              loading={markTypeConfig.loading}
              rename="TM Types"
            />,
            [tmTypes, markTypeConfig],
          )}
          {useMemoizedComponent(
            <MenuFilter
              filteredData={markCategoryConfig}
              value={tmCategories}
              setValue={setTMCategories}
              loading={markCategoryConfig.loading}
              rename="Categories"
            />,
            [tmCategories, markCategoryConfig],
          )}
          {useMemoizedComponent(
            <MenuFilter
              filteredData={markOfficeConfig}
              value={tmOffices}
              setValue={setTMOffices}
              loading={markOfficeConfig.loading}
              rename="Offices"
            />,
            [tmOffices, markOfficeConfig],
          )}
          {useMemoizedComponent(
            <ProprietorFilterComponent
              filteredData={markproprietorConfig}
              key={markproprietorConfig.key}
              value={proprietors}
              setValue={setProprietors}
              loading={markproprietorConfig.loading}
              journal_number={journal}
              exclude={excludeMarkProprietors}
              setExclude={setExcludeMarkProprietors}
              current_template_id={current_template_id}
              filterPath={filterPath}
              rename="Proprietors"
            />,
            [proprietors, markproprietorConfig, excludeMarkProprietors],
          )}
          {useMemoizedComponent(
            <SelectFilter
              filteredData={markClassConfig}
              key={markClassConfig.key}
              value={tmClass}
              setValue={setTMClass}
              loading={markClassConfig.loading}
              journal_number={journal}
              showSwitch
              andOr={andOrClass}
              setAndOr={setAndOrClass}
              current_template_id={current_template_id}
              filterPath={filterPath}
              extraHighlight={
                sameClassInfringement || excludeSameClassInfringement
              }
              rename="Classes"
            />,
            [
              tmClass,
              markClassConfig,
              excludeSameClassInfringement,
              sameClassInfringement,
              andOrClass,
            ],
          )}
          {useMemoizedComponent(
            <DateFilter
              menu_label="DoA | DoU"
              subtitle1="Your Date of Application"
              subtitle2="Your Date of Usage"
              key={"tm_date"}
              value1={yourDateOfApplication}
              setValue1={setYourDateOfApplication}
              value2={yourDateOfUsage}
              setValue2={setYourDateOfUsage}
              proposed={yourDateOfUsageProposed}
              setProposed={setYourDateOfUsageProposed}
              filter_key_1="your_date_of_application"
              filter_key_2="your_date_of_usage"
            />,
            [yourDateOfApplication, yourDateOfUsage, yourDateOfUsageProposed],
          )}
        </Space>
      </Col>
      <Col
        lg={6}
        md={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <Space>
          <CheckableTagComponent
            label={<PlaySquareOutlined />}
            value={isTourOpen}
            setValue={async (v) => {
              if (!isDataPresent) {
                dispatch(
                  addGridSampleData(gridSampleObject, gridThreatSampleObject),
                );
              }
              setIsTourOpen(v);
            }}
            disableTooltip="Take Tour"
            activeTooltip="Take Tour"
          />
        </Space>
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      <header className="General-Header">
        <Row
          style={
            showHeader
              ? { padding: "16px 10px" }
              : { padding: "8px 10px 0px 10px" }
          }
        >
          {firstRow}
          {urlKey !== "tm_protect" && secondRow}
        </Row>
      </header>
      <CreateReportDrawer
        open={openCreateReport}
        setOpen={setOpenCreateReport}
        journal={journal}
        selected={selectedData}
        generateFilterObject={generateFilterObject}
        report_columns={report_columns}
        filterApplied={primaryFilterApplied}
        pageNumber={pageNumber}
        source={"GRID_LAYOUT"}
        creation_origin={getCreationOrigin()}
        selectAll={selectedData.select_all}
        {...props}
      />
      {openBulkTemplateDownload && (
        <AdvanceCombinePDF
          open={openBulkTemplateDownload}
          selected={selectedData}
          selectAll={selectedData.select_all}
          onCloseModal={onCloseBulkTemplateModal}
          journal={journal}
          generateFilterObject={generateFilterObject}
          filterApplied={primaryFilterApplied}
          creation_origin={getCreationOrigin()}
          source="GRID_LAYOUT"
        />
      )}
    </React.Fragment>
  );
};

export default PrimaryGridFilter;
