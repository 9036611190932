//--------WATCH TYPES--------
import { GET_JOURNALS_LIST } from "../../actions/watchActions/types";

//--------IP-SUITE CONSTANTS--------
import { LOADING, SUCCESS, ERROR } from "../../../constants/commonConstants/generalConstants";

export default function(state = null, action){
    switch (action.type) {
        case GET_JOURNALS_LIST:
            switch (action.status) {
                case SUCCESS:
                    return action.payload.data;

                case LOADING:
                    return state;
                    
                case ERROR:
                    return state;
                
                default:
                    return state;
            }

        default:
            return state;
    }
}