//--------LIBRARIES--------
import Axios from "axios";
import { batch } from "react-redux";
import { message, notification } from "antd";

//--------RT_CONFIG--------
import { WAT_RT_URL } from "../../../configs/rootConfigs";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
  PROGRESSING,
} from "../../../constants/commonConstants/generalConstants";

//--------WATCH TYPES--------
import { SET_CURRENT_JOURNAL, SET_CURRENT_DATE_RANGE } from "./types";

//--------LIST LAYOUT CONSTANTS--------
import { LL_defaultBookmarkData_BEK } from "../../../constants/watchConstants/listLayoutConstants";

//--------LIST LAYOUT TYPES--------
import {
  LL_GET_TABLE,
  LL_MARK_BOOKMARK,
  LL_IMAGE_BOOKMARK,
  LL_GET_IMAGE_TABLE,
  LL_GET_TM_PROTECT_TABLE,
  LL_TM_PROTECT_BOOKMARK,
} from "./types";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../commonActions/actions";

//--------Get table data, filters and automated reports links--------
export function LL_getTableData(
  journalNumber,
  pageNumber,
  filterFlag,
  filters,
  journalChange = false
) {
  const tags = `${filters.tags}`;
  const mark_classes = `${filters.markClasses}`;
  const threat_classes = `${filters.threatClasses}`;
  const threat_sources = `${filters.threatSources}`;
  const search_term = `${filters.searchTerm}`;
  const threat_similarity = `${filters.threatsSimilarity.lowerLimit}to${filters.threatsSimilarity.upperLimit}`;
  const mark_types = `${filters.markTypes}`;
  const threat_types = `${filters.threatTypes}`;
  const mark_proprietors = `${filters.markProprietors}`;
  const mark_categories = `${filters.markCategories}`;
  const threat_categories = `${filters.threatCategories}`;
  const mark_offices = `${filters.markOffices}`;
  const threat_offices = `${filters.threatOffices}`;
  const no_Tags =
    filters.NOtags && filters.NOtags !== undefined ? `${filters.NOtags}` : [];
  const mark_desc_contains_term = `${filters.mark_desc_contains_term}`;
  const threat_desc_contains_term = `${filters.threat_desc_contains_term}`;
  const searchType = `${filters.searchType}`;

  const {
    mark_date_of_application,
    threat_date_of_application,
    mark_date_of_usage,
    threat_date_of_usage,
    mark_date_of_usage_proposed,
    threat_date_of_usage_proposed,
    redBand,
    excludeClass,
    exclude_mark_added_to_report,
  } = filters;

  return async (dispatch) => {
    let response;
    let layout_url = `${WAT_RT_URL}/list_layout/?`;
    let params = {
      journal_number: journalNumber,
      page: pageNumber,
      filter_flag: filterFlag ? true : null,
      tags: filterFlag ? tags : null,
      mark_classes: filterFlag ? mark_classes : null,
      threat_classes: filterFlag ? threat_classes : null,
      threat_sources: filterFlag ? threat_sources : null,
      search_term:
        filterFlag && searchType === "Application No/Name" ? search_term : null,
      threat_similarity: filterFlag ? threat_similarity : null,
      mark_type: filterFlag ? mark_types : null,
      threat_type: filterFlag ? threat_types : null,
      mark_proprietor: filterFlag ? mark_proprietors : null,
      mark_categories: filterFlag ? mark_categories : null,
      threat_categories: filterFlag ? threat_categories : null,
      mark_offices: filterFlag ? mark_offices : null,
      threat_offices: filterFlag ? threat_offices : null,
      mark_date_of_application: filterFlag ? mark_date_of_application : null,
      threat_date_of_application: filterFlag
        ? threat_date_of_application
        : null,
      mark_date_of_usage: filterFlag ? mark_date_of_usage : null,
      threat_date_of_usage: filterFlag ? threat_date_of_usage : null,
      mark_date_of_usage_proposed: filterFlag
        ? mark_date_of_usage_proposed
        : null,
      threat_date_of_usage_proposed: filterFlag
        ? threat_date_of_usage_proposed
        : null,
      red_band: filterFlag ? redBand : null,
      exclude_same_class: filterFlag ? excludeClass : null,
      exclude_mark_added_to_report: filterFlag
        ? exclude_mark_added_to_report
        : null,
      threat_desc_contains_term:
        filterFlag && searchType === "Your Threat Description Search"
          ? threat_desc_contains_term
          : null,
      mark_desc_contains_term:
        filterFlag && searchType === "Your Mark Description Search"
          ? mark_desc_contains_term
          : null,
      no_tags: tags ? null : no_Tags.length > 0 ? true : null,
    };
    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      dispatch({ type: LL_GET_TABLE, status: LOADING, journalChange });
      try {
        response = await Axios.get(`${WAT_RT_URL}/list_layout/`, {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });

        switch (response.status) {
          case 200:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: LL_GET_TABLE,
                  status: SUCCESS,
                  payload: response?.data,
                  journalNumber,
                  pageNumber,
                  lastAppliedFilters: {
                    ...filters,
                    filterFlag,
                  },
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: LL_GET_TABLE,
                status: SUCCESS,
                payload: response?.data,
                journalNumber,
                pageNumber,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
              });
            }
            return SUCCESS;

          case 202:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: LL_GET_TABLE,
                  status: PROGRESSING,
                  payload: response?.data,
                  journalNumber,
                  pageNumber,
                  lastAppliedFilters: {
                    ...filters,
                    filterFlag,
                  },
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: LL_GET_TABLE,
                status: PROGRESSING,
                payload: response?.data,
                journalNumber,
                pageNumber,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
              });
            }
            return SUCCESS;

          default:
            break;
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: LL_GET_TABLE, status: ERROR });
        return ERROR;
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}

//--------Get bookmark data/details for the given journal number--------
export function LL_getBookmark(journalNumber, bookmark_type) {
  return async (dispatch) => {
    switch (bookmark_type) {
      case "WATCH_MARK_LIST_LAYOUT":
        try {
          const response = await Axios.get(
            `${WAT_RT_URL}/list_layout_bookmark/`,
            {
              params: {
                journal_number: journalNumber,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );

          switch (response.status) {
            case 200:
              dispatch({
                type: LL_MARK_BOOKMARK,
                status: SUCCESS,
                payload: response?.data,
              });
              return response?.data;

            default:
              alert(`Unexpected Response : ${response.status}`);
              dispatch({ type: LL_MARK_BOOKMARK, status: ERROR });
              return response?.data;
          }
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: LL_MARK_BOOKMARK, status: ERROR });
          return err;
        }

      case "WATCH_IMAGE_LIST_LAYOUT":
        try {
          const response = await Axios.get(
            `${WAT_RT_URL}/list_layout_bookmark/`,
            {
              params: {
                journal_number: journalNumber,
                bookmark_type: bookmark_type,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );

          switch (response.status) {
            case 200:
              dispatch({
                type: LL_IMAGE_BOOKMARK,
                status: SUCCESS,
                payload: response?.data,
              });
              return response?.data;

            default:
              alert(`Unexpected Response : ${response.status}`);
              dispatch({ type: LL_IMAGE_BOOKMARK, status: ERROR });
              return response?.data;
          }
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: LL_IMAGE_BOOKMARK, status: ERROR });
          return err;
        }

      case "TM_PROTECT_LIST_LAYOUT":
        try {
          const response = await Axios.get(
            `${WAT_RT_URL}/list_layout_bookmark/`,
            {
              params: {
                cease_desist_report_id: journalNumber,
                bookmark_type: bookmark_type,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );

          switch (response.status) {
            case 200:
              dispatch({
                type: LL_TM_PROTECT_BOOKMARK,
                status: SUCCESS,
                payload: response?.data,
              });
              return response?.data;

            default:
              alert(`Unexpected Response : ${response.status}`);
              dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
              return response?.data;
          }
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
          return err;
        }

      default:
        break;
    }
  };
}

//--------Post, Patch and Delete bookmark--------
export function LL_postPatchDeleteBookmark(
  journalNumber,
  bookmark_type,
  pageNumber,
  lastAppliedFilters,
  markApplicationNumber,
  threatApplicationNumber
) {
  const tags = `${lastAppliedFilters.tags}`;
  const mark_classes = `${lastAppliedFilters.markClasses}`;
  const threat_classes = `${lastAppliedFilters.threatClasses}`;
  const threat_sources = `${lastAppliedFilters.threatSources}`;
  const search_term = `${lastAppliedFilters.searchTerm}`;
  const threat_similarity = `${lastAppliedFilters.threatsSimilarity.lowerLimit}to${lastAppliedFilters.threatsSimilarity.upperLimit}`;
  const mark_type = `${lastAppliedFilters.markTypes}`;
  const threat_type = `${lastAppliedFilters.threatTypes}`;
  const mark_proprietor = `${lastAppliedFilters.markProprietors}`;
  const mark_categories = `${lastAppliedFilters.markCategories}`;
  const threat_categories = `${lastAppliedFilters.threatCategories}`;
  const mark_offices = `${lastAppliedFilters.markOffices}`;
  const threat_offices = `${lastAppliedFilters.threatOffices}`;

  return async (dispatch) => {
    let currentBookmark = await this.LL_getBookmark(
      journalNumber,
      bookmark_type
    );
    switch (bookmark_type) {
      case "WATCH_MARK_LIST_LAYOUT":
        dispatch({ type: LL_MARK_BOOKMARK, status: LOADING });
        try {
          let response;
          if (
            currentBookmark.present !== undefined &&
            currentBookmark.present === true
          ) {
            if (
              currentBookmark.mark_application_number ===
                markApplicationNumber &&
              currentBookmark.threat_application_number ===
                threatApplicationNumber
            ) {
              response = await Axios.delete(
                `${WAT_RT_URL}/list_layout_bookmark/`,
                {
                  params: { journal_number: journalNumber },
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                }
              );

              switch (response.status) {
                case 200:
                  dispatch({
                    type: LL_MARK_BOOKMARK,
                    status: SUCCESS,
                    payload: LL_defaultBookmarkData_BEK,
                  });
                  notification["success"]({
                    message: "Bookmark Removed",
                    description: `Bookmark for, journal ${journalNumber}, has been removed.`,
                  });
                  break;

                default:
                  notification["error"]({
                    message: `Unexpected Response For Bookmark! ${response.status}`,
                    description:
                      "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                  });
                  dispatch({ type: LL_MARK_BOOKMARK, status: ERROR });
                  break;
              }
            } else {
              response = await Axios.patch(
                `${WAT_RT_URL}/list_layout_bookmark/`,
                {
                  journal_number: journalNumber,
                  page: pageNumber,
                  filter_flag: lastAppliedFilters.filterFlag,
                  tags,
                  mark_classes,
                  threat_classes,
                  threat_sources,
                  search_term,
                  threat_similarity,
                  mark_type,
                  threat_type,
                  mark_proprietor,
                  mark_categories,
                  threat_categories,
                  mark_offices,
                  threat_offices,
                  mark_date_of_application:
                    lastAppliedFilters.mark_date_of_application,
                  threat_date_of_application:
                    lastAppliedFilters.threat_date_of_application,
                  mark_date_of_usage: lastAppliedFilters.mark_date_of_usage,
                  threat_date_of_usage: lastAppliedFilters.threat_date_of_usage,
                  mark_date_of_usage_proposed:
                    lastAppliedFilters.mark_date_of_usage_proposed,
                  threat_date_of_usage_proposed:
                    lastAppliedFilters.threat_date_of_usage_proposed,
                  red_band: lastAppliedFilters.redBand,
                  exclude_same_class: lastAppliedFilters.excludeClass,
                  mark_application_number: markApplicationNumber,
                  threat_application_number: threatApplicationNumber,
                  exclude_mark_added_to_report:
                    lastAppliedFilters.exclude_mark_added_to_report,
                },
                {
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                }
              );

              switch (response.status) {
                case 200:
                  dispatch({
                    type: LL_MARK_BOOKMARK,
                    status: SUCCESS,
                    payload: response?.data,
                  });
                  notification["success"]({
                    message: "Bookmark Updated",
                    description: `Bookmark for, journal ${journalNumber}, has been updated.`,
                  });
                  break;

                default:
                  notification["error"]({
                    message: `Unexpected Response For Bookmark! ${response.status}`,
                    description:
                      "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                  });
                  dispatch({ type: LL_MARK_BOOKMARK, status: ERROR });
                  break;
              }
            }
          } else {
            response = await Axios.post(
              `${WAT_RT_URL}/list_layout_bookmark/`,
              {
                journal_number: journalNumber,
                page: pageNumber,
                filter_flag: lastAppliedFilters.filterFlag,
                tags,
                mark_classes,
                threat_classes,
                threat_sources,
                search_term,
                threat_similarity,
                mark_type,
                threat_type,
                mark_proprietor,
                mark_categories,
                threat_categories,
                mark_offices,
                threat_offices,
                mark_date_of_application:
                  lastAppliedFilters.mark_date_of_application,
                threat_date_of_application:
                  lastAppliedFilters.threat_date_of_application,
                mark_date_of_usage: lastAppliedFilters.mark_date_of_usage,
                threat_date_of_usage: lastAppliedFilters.threat_date_of_usage,
                mark_date_of_usage_proposed:
                  lastAppliedFilters.mark_date_of_usage_proposed,
                threat_date_of_usage_proposed:
                  lastAppliedFilters.threat_date_of_usage_proposed,
                red_band: lastAppliedFilters.redBand,
                exclude_same_class: lastAppliedFilters.excludeClass,
                mark_application_number: markApplicationNumber,
                threat_application_number: threatApplicationNumber,
                exclude_mark_added_to_report:
                  lastAppliedFilters.exclude_mark_added_to_report,
              },
              {
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              }
            );

            switch (response.status) {
              case 200:
                dispatch({
                  type: LL_MARK_BOOKMARK,
                  status: SUCCESS,
                  payload: response?.data,
                });
                notification["success"]({
                  message: "Bookmark Placed",
                  description: `The current record for, journal ${journalNumber}, has been bookmarked.`,
                });
                break;

              default:
                notification["error"]({
                  message: `Unexpected Response For Bookmark! ${response.status}`,
                  description:
                    "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                });
                dispatch({ type: LL_MARK_BOOKMARK, status: ERROR });
                break;
            }
          }
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: LL_MARK_BOOKMARK, status: ERROR });
        }
        break;

      case "WATCH_IMAGE_LIST_LAYOUT":
        dispatch({ type: LL_IMAGE_BOOKMARK, status: LOADING });
        try {
          let response;
          if (
            currentBookmark.present !== undefined &&
            currentBookmark.present === true
          ) {
            if (
              currentBookmark.mark_application_number ===
                markApplicationNumber &&
              currentBookmark.threat_application_number ===
                threatApplicationNumber
            ) {
              response = await Axios.delete(
                `${WAT_RT_URL}/list_layout_bookmark/`,
                {
                  params: {
                    journal_number: journalNumber,
                    bookmark_type: bookmark_type,
                  },
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                }
              );

              switch (response.status) {
                case 200:
                  dispatch({
                    type: LL_IMAGE_BOOKMARK,
                    status: SUCCESS,
                    payload: LL_defaultBookmarkData_BEK,
                  });
                  notification["success"]({
                    message: "Bookmark Removed",
                    description: `Bookmark for, journal ${journalNumber}, has been removed.`,
                  });
                  break;

                default:
                  notification["error"]({
                    message: `Unexpected Response For Bookmark! ${response.status}`,
                    description:
                      "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                  });
                  dispatch({ type: LL_IMAGE_BOOKMARK, status: ERROR });
                  break;
              }
            } else {
              response = await Axios.patch(
                `${WAT_RT_URL}/list_layout_bookmark/`,
                {
                  journal_number: journalNumber,
                  bookmark_type: bookmark_type,
                  page: pageNumber,
                  filter_flag: lastAppliedFilters.filterFlag,
                  tags,
                  mark_classes,
                  threat_classes,
                  threat_sources,
                  search_term,
                  threat_similarity,
                  mark_type,
                  threat_type,
                  mark_proprietor,
                  mark_categories,
                  threat_categories,
                  mark_offices,
                  threat_offices,
                  mark_date_of_application:
                    lastAppliedFilters.mark_date_of_application,
                  threat_date_of_application:
                    lastAppliedFilters.threat_date_of_application,
                  mark_date_of_usage: lastAppliedFilters.mark_date_of_usage,
                  threat_date_of_usage: lastAppliedFilters.threat_date_of_usage,
                  mark_date_of_usage_proposed:
                    lastAppliedFilters.mark_date_of_usage_proposed,
                  threat_date_of_usage_proposed:
                    lastAppliedFilters.threat_date_of_usage_proposed,
                  red_band: lastAppliedFilters.redBand,
                  exclude_same_class: lastAppliedFilters.excludeClass,
                  mark_application_number: markApplicationNumber,
                  threat_application_number: threatApplicationNumber,
                  exclude_mark_added_to_report:
                    lastAppliedFilters.exclude_mark_added_to_report,
                },
                {
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                }
              );

              switch (response.status) {
                case 200:
                  dispatch({
                    type: LL_IMAGE_BOOKMARK,
                    status: SUCCESS,
                    payload: response?.data,
                  });
                  notification["success"]({
                    message: "Bookmark Updated",
                    description: `Bookmark for, journal ${journalNumber}, has been updated.`,
                  });
                  break;

                default:
                  notification["error"]({
                    message: `Unexpected Response For Bookmark! ${response.status}`,
                    description:
                      "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                  });
                  dispatch({ type: LL_IMAGE_BOOKMARK, status: ERROR });
                  break;
              }
            }
          } else {
            response = await Axios.post(
              `${WAT_RT_URL}/list_layout_bookmark/`,
              {
                journal_number: journalNumber,
                bookmark_type: bookmark_type,
                page: pageNumber,
                filter_flag: lastAppliedFilters.filterFlag,
                tags,
                mark_classes,
                threat_classes,
                threat_sources,
                search_term,
                threat_similarity,
                mark_type,
                threat_type,
                mark_proprietor,
                mark_categories,
                threat_categories,
                mark_offices,
                threat_offices,
                mark_date_of_application:
                  lastAppliedFilters.mark_date_of_application,
                threat_date_of_application:
                  lastAppliedFilters.threat_date_of_application,
                mark_date_of_usage: lastAppliedFilters.mark_date_of_usage,
                threat_date_of_usage: lastAppliedFilters.threat_date_of_usage,
                mark_date_of_usage_proposed:
                  lastAppliedFilters.mark_date_of_usage_proposed,
                threat_date_of_usage_proposed:
                  lastAppliedFilters.threat_date_of_usage_proposed,
                red_band: lastAppliedFilters.redBand,
                exclude_same_class: lastAppliedFilters.excludeClass,
                mark_application_number: markApplicationNumber,
                threat_application_number: threatApplicationNumber,
                exclude_mark_added_to_report:
                  lastAppliedFilters.exclude_mark_added_to_report,
              },
              {
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              }
            );

            switch (response.status) {
              case 200:
                dispatch({
                  type: LL_IMAGE_BOOKMARK,
                  status: SUCCESS,
                  payload: response?.data,
                });
                notification["success"]({
                  message: "Bookmark Placed",
                  description: `The current record for, journal ${journalNumber}, has been bookmarked.`,
                });
                break;

              default:
                notification["error"]({
                  message: `Unexpected Response For Bookmark! ${response.status}`,
                  description:
                    "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                });
                dispatch({ type: LL_IMAGE_BOOKMARK, status: ERROR });
                break;
            }
          }
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: LL_IMAGE_BOOKMARK, status: ERROR });
        }
        break;

      case "TM_PROTECT_LIST_LAYOUT":
        dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: LOADING });
        try {
          let response;
          if (
            currentBookmark.present !== undefined &&
            currentBookmark.present === true
          ) {
            if (
              currentBookmark.threat_application_number ===
              threatApplicationNumber
            ) {
              response = await Axios.delete(
                `${WAT_RT_URL}/list_layout_bookmark/`,
                {
                  params: {
                    cease_desist_report_id: journalNumber,
                    bookmark_type: bookmark_type,
                  },
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                }
              );

              switch (response.status) {
                case 200:
                  dispatch({
                    type: LL_TM_PROTECT_BOOKMARK,
                    status: SUCCESS,
                    payload: LL_defaultBookmarkData_BEK,
                  });
                  notification["success"]({
                    message: "Bookmark Removed",
                    description: `Bookmark for, journal ${journalNumber}, has been removed.`,
                  });
                  break;

                default:
                  notification["error"]({
                    message: `Unexpected Response For Bookmark! ${response.status}`,
                    description:
                      "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                  });
                  dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
                  break;
              }
            } else {
              response = await Axios.patch(
                `${WAT_RT_URL}/list_layout_bookmark/`,
                {
                  cease_desist_report_id: journalNumber,
                  bookmark_type: bookmark_type,
                  page: pageNumber,
                  filter_flag: lastAppliedFilters.filterFlag,
                  threat_classes,
                  threat_sources,
                  search_term,
                  threat_similarity,
                  threat_type,
                  threat_categories,
                  threat_date_of_application:
                    lastAppliedFilters.threat_date_of_application,
                  threat_date_of_usage: lastAppliedFilters.threat_date_of_usage,
                  threat_date_of_usage_proposed:
                    lastAppliedFilters.threat_date_of_usage_proposed,
                  threat_application_number: threatApplicationNumber,
                },
                {
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                }
              );

              switch (response.status) {
                case 200:
                  dispatch({
                    type: LL_TM_PROTECT_BOOKMARK,
                    status: SUCCESS,
                    payload: response?.data,
                  });
                  notification["success"]({
                    message: "Bookmark Updated",
                    description: `Bookmark for, journal ${journalNumber}, has been updated.`,
                  });
                  break;

                default:
                  notification["error"]({
                    message: `Unexpected Response For Bookmark! ${response.status}`,
                    description:
                      "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                  });
                  dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
                  break;
              }
            }
          } else {
            response = await Axios.post(
              `${WAT_RT_URL}/list_layout_bookmark/`,
              {
                cease_desist_report_id: journalNumber,
                bookmark_type: bookmark_type,
                page: pageNumber,
                filter_flag: lastAppliedFilters.filterFlag,
                threat_classes,
                threat_sources,
                search_term,
                threat_similarity,
                threat_type,
                threat_categories,
                threat_offices,
                threat_date_of_application:
                  lastAppliedFilters.threat_date_of_application,
                threat_date_of_usage: lastAppliedFilters.threat_date_of_usage,
                threat_date_of_usage_proposed:
                  lastAppliedFilters.threat_date_of_usage_proposed,
                threat_application_number: threatApplicationNumber,
              },
              {
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              }
            );

            switch (response.status) {
              case 200:
                dispatch({
                  type: LL_TM_PROTECT_BOOKMARK,
                  status: SUCCESS,
                  payload: response?.data,
                });
                notification["success"]({
                  message: "Bookmark Placed",
                  description: `The current record for, journal ${journalNumber}, has been bookmarked.`,
                });
                break;

              default:
                notification["error"]({
                  message: `Unexpected Response For Bookmark! ${response.status}`,
                  description:
                    "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                });
                dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
                break;
            }
          }
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
        }
        break;

      default:
        break;
    }
  };
}

//--------Get Image table data, filters and automated reports links--------
export function LL_getImageData(
  journalNumber,
  pageNumber,
  filterFlag,
  filters,
  journalChange = false
) {
  const tags = `${filters.tags}`;
  const no_Tags = `${filters.NOtags}`;
  const mark_classes = `${filters.markClasses}`;
  const threat_classes = `${filters.threatClasses}`;
  const threat_sources = `${filters.threatSources}`;
  const search_term = `${filters.searchTerm}`;
  const threat_similarity = `${filters.threatsSimilarity.lowerLimit}to${filters.threatsSimilarity.upperLimit}`;
  const mark_types = `${filters.markTypes}`;
  const threat_types = `${filters.threatTypes}`;
  const mark_proprietors = `${filters.markProprietors}`;
  const mark_categories = `${filters.markCategories}`;
  const threat_categories = `${filters.threatCategories}`;
  const mark_offices = `${filters.markOffices}`;
  const threat_offices = `${filters.threatOffices}`;
  const mark_desc_contains_term = `${filters.mark_desc_contains_term}`;
  const threat_desc_contains_term = `${filters.threat_desc_contains_term}`;
  const searchType = `${filters.searchType}`;

  const {
    mark_date_of_application,
    threat_date_of_application,
    mark_date_of_usage,
    threat_date_of_usage,
    mark_date_of_usage_proposed,
    threat_date_of_usage_proposed,
    redBand,
    excludeClass,
    exclude_mark_added_to_report,
  } = filters;

  return async (dispatch) => {
    let response;
    let layout_url = `${WAT_RT_URL}/image/list_layout/?`;
    let params = {
      journal_number: journalNumber,
      page: pageNumber,
      filter_flag: filterFlag ? true : null,
      tags: filterFlag ? tags : null,
      no_tags: filterFlag && tags ? null : no_Tags.length > 0 ? true : null,
      mark_classes: filterFlag ? mark_classes : null,
      threat_classes: filterFlag ? threat_classes : null,
      threat_sources: filterFlag ? threat_sources : null,
      search_term:
        filterFlag && searchType === "Application No/Name" ? search_term : null,
      threat_similarity: filterFlag ? threat_similarity : null,
      mark_type: filterFlag ? mark_types : null,
      threat_type: filterFlag ? threat_types : null,
      mark_proprietor: filterFlag ? mark_proprietors : null,
      mark_categories: filterFlag ? mark_categories : null,
      threat_categories: filterFlag ? threat_categories : null,
      mark_offices: filterFlag ? mark_offices : null,
      threat_offices: filterFlag ? threat_offices : null,
      mark_date_of_application: filterFlag ? mark_date_of_application : null,
      threat_date_of_application: filterFlag
        ? threat_date_of_application
        : null,
      mark_date_of_usage: filterFlag ? mark_date_of_usage : null,
      threat_date_of_usage: filterFlag ? threat_date_of_usage : null,
      mark_date_of_usage_proposed: filterFlag
        ? mark_date_of_usage_proposed
        : null,
      threat_date_of_usage_proposed: filterFlag
        ? threat_date_of_usage_proposed
        : null,
      red_band: filterFlag ? redBand : null,
      exclude_same_class: filterFlag ? excludeClass : null,
      exclude_mark_added_to_report: filterFlag
        ? exclude_mark_added_to_report
        : null,
      threat_desc_contains_term:
        filterFlag && searchType === "Your Threat Description Search"
          ? threat_desc_contains_term
          : null,
      mark_desc_contains_term:
        filterFlag && searchType === "Your Mark Description Search"
          ? mark_desc_contains_term
          : null,
    };
    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      dispatch({ type: LL_GET_IMAGE_TABLE, status: LOADING, journalChange });
      try {
        response = await Axios.get(`${WAT_RT_URL}/image/list_layout/`, {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });

        switch (response.status) {
          case 200:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: LL_GET_IMAGE_TABLE,
                  status: SUCCESS,
                  payload: response?.data,
                  journalNumber,
                  pageNumber,
                  lastAppliedFilters: {
                    ...filters,
                    filterFlag,
                  },
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: LL_GET_IMAGE_TABLE,
                status: SUCCESS,
                payload: response?.data,
                journalNumber,
                pageNumber,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
              });
            }
            return SUCCESS;

          case 202:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: LL_GET_IMAGE_TABLE,
                  status: PROGRESSING,
                  payload: response?.data,
                  journalNumber,
                  pageNumber,
                  lastAppliedFilters: {
                    ...filters,
                    filterFlag,
                  },
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: LL_GET_IMAGE_TABLE,
                status: PROGRESSING,
                payload: response?.data,
                journalNumber,
                pageNumber,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
              });
            }
            return SUCCESS;

          default:
            break;
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: LL_GET_IMAGE_TABLE, status: ERROR });
        return ERROR;
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}

//--------Get tm protect table data, filters and automated reports links--------
export function LL_getTMProtectData(
  profileReportID,
  dateRange,
  pageNumber,
  filterFlag,
  filters,
  journalChange = false
) {
  const threat_classes = `${filters.threatClasses}`;
  const threat_sources = `${filters.threatSources}`;
  const search_term = filters.searchTerm;
  const threat_similarity = `${filters.threatsSimilarity.lowerLimit}to${filters.threatsSimilarity.upperLimit}`;
  const threat_types = `${filters.threatTypes}`;
  const threat_categories = `${filters.threatCategories}`;
  const threat_offices = `${filters.threatOffices}`;
  const mark_desc_contains_term = `${filters.mark_desc_contains_term}`;
  const threat_desc_contains_term = `${filters.threat_desc_contains_term}`;
  const searchType = `${filters.searchType}`;
  const {
    threat_date_of_application,
    threat_date_of_usage,
    threat_date_of_usage_proposed,
  } = filters;

  return async (dispatch) => {
    let response;
    let layout_url = `${WAT_RT_URL}/tm_protect/list_layout/?`;
    let params = {
      profile_report_id: profileReportID,
      page: pageNumber,
      filter_flag: filterFlag ? true : null,
      threat_classes: filterFlag ? threat_classes : null,
      threat_sources: filterFlag ? threat_sources : null,
      search_term:
        filterFlag && searchType === "Application No/Name" ? search_term : null,
      threat_similarity: filterFlag ? threat_similarity : null,
      threat_type: filterFlag ? threat_types : null,
      threat_categories: filterFlag ? threat_categories : null,
      threat_offices: filterFlag ? threat_offices : null,
      threat_date_of_application: filterFlag
        ? threat_date_of_application
        : null,
      threat_date_of_usage: filterFlag ? threat_date_of_usage : null,
      threat_date_of_usage_proposed: filterFlag
        ? threat_date_of_usage_proposed
        : null,
      threat_desc_contains_term:
        filterFlag && searchType === "Your Threat Description Search"
          ? threat_desc_contains_term
          : null,
      mark_desc_contains_term:
        filterFlag && searchType === "Your Mark Description Search"
          ? mark_desc_contains_term
          : null,
    };
    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      dispatch({
        type: LL_GET_TM_PROTECT_TABLE,
        status: LOADING,
        journalChange,
      });
      try {
        response = await Axios.get(`${WAT_RT_URL}/tm_protect/list_layout/`, {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });

        switch (response.status) {
          case 200:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: LL_GET_TM_PROTECT_TABLE,
                  status: SUCCESS,
                  payload: response?.data,
                  profileReportID,
                  dateRange,
                  pageNumber,
                  lastAppliedFilters: {
                    ...filters,
                    filterFlag,
                  },
                });
                dispatch({
                  type: SET_CURRENT_DATE_RANGE,
                  payload: dateRange,
                  report_id: profileReportID,
                });
              });
            } else {
              dispatch({
                type: LL_GET_TM_PROTECT_TABLE,
                status: SUCCESS,
                payload: response?.data,
                profileReportID,
                dateRange,
                pageNumber,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
              });
            }
            return SUCCESS;

          case 202:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: LL_GET_TM_PROTECT_TABLE,
                  status: PROGRESSING,
                  payload: response?.data,
                  profileReportID,
                  dateRange,
                  pageNumber,
                  lastAppliedFilters: {
                    ...filters,
                    filterFlag,
                  },
                });
                dispatch({
                  type: SET_CURRENT_DATE_RANGE,
                  payload: dateRange,
                  report_id: profileReportID,
                });
              });
            } else {
              dispatch({
                type: LL_GET_TM_PROTECT_TABLE,
                status: PROGRESSING,
                payload: response?.data,
                profileReportID,
                dateRange,
                pageNumber,
                lastAppliedFilters: {
                  ...filters,
                  filterFlag,
                },
              });
            }
            return SUCCESS;

          default:
            break;
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: LL_GET_TM_PROTECT_TABLE, status: ERROR });
        return ERROR;
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}

//--------Get bookmark data/details for the given journal number--------
export function LL_getTMProtectBookmark(reportId, bookmark_type) {
  return async (dispatch) => {
    switch (bookmark_type) {
      case "TM_PROTECT_LIST_LAYOUT":
        try {
          const response = await Axios.get(
            `${WAT_RT_URL}/list_layout_bookmark/`,
            {
              params: {
                cease_desist_report_id: reportId,
                bookmark_type: bookmark_type,
              },
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );

          switch (response.status) {
            case 200:
              dispatch({
                type: LL_TM_PROTECT_BOOKMARK,
                status: SUCCESS,
                payload: response?.data,
              });
              return response?.data;

            default:
              alert(`Unexpected Response : ${response.status}`);
              dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
              return response?.data;
          }
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
          return err;
        }

      default:
        break;
    }
  };
}

//--------Post, Patch and Delete TM Protect bookmark--------
export function LL_postPatchDeleteTMProtectBookmark(
  reportId,
  bookmark_type,
  pageNumber,
  lastAppliedFilters,
  threatApplicationNumber
) {
  const threat_classes = `${lastAppliedFilters.threatClasses}`;
  const threat_sources = `${lastAppliedFilters.threatSources}`;
  const search_term = `${lastAppliedFilters.searchTerm}`;
  const threat_similarity = `${lastAppliedFilters.threatsSimilarity.lowerLimit}to${lastAppliedFilters.threatsSimilarity.upperLimit}`;
  const threat_type = `${lastAppliedFilters.threatTypes}`;
  const threat_categories = `${lastAppliedFilters.threatCategories}`;
  const threat_offices = `${lastAppliedFilters.threatOffices}`;

  return async (dispatch) => {
    let currentBookmark = await this.LL_getTMProtectBookmark(
      reportId,
      bookmark_type
    );
    switch (bookmark_type) {
      case "TM_PROTECT_LIST_LAYOUT":
        dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: LOADING });
        try {
          let response;
          if (
            currentBookmark.present !== undefined &&
            currentBookmark.present === true
          ) {
            if (
              currentBookmark.threat_application_number ===
              threatApplicationNumber
            ) {
              response = await Axios.delete(
                `${WAT_RT_URL}/list_layout_bookmark/`,
                {
                  params: {
                    cease_desist_report_id: reportId,
                    bookmark_type: bookmark_type,
                  },
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                }
              );

              switch (response.status) {
                case 200:
                  dispatch({
                    type: LL_TM_PROTECT_BOOKMARK,
                    status: SUCCESS,
                    payload: LL_defaultBookmarkData_BEK,
                  });
                  notification["success"]({
                    message: "Bookmark Removed",
                    description: `Bookmark for, cease_desist_report_id ${reportId}, has been removed.`,
                  });
                  break;

                default:
                  notification["error"]({
                    message: `Unexpected Response For Bookmark! ${response.status}`,
                    description:
                      "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                  });
                  dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
                  break;
              }
            } else {
              response = await Axios.patch(
                `${WAT_RT_URL}/list_layout_bookmark/`,
                {
                  cease_desist_report_id: reportId,
                  bookmark_type: bookmark_type,
                  page: pageNumber,
                  filter_flag: lastAppliedFilters.filterFlag,
                  threat_classes,
                  threat_sources,
                  search_term,
                  threat_similarity,
                  threat_type,
                  threat_categories,
                  threat_date_of_application:
                    lastAppliedFilters.threat_date_of_application,
                  threat_date_of_usage: lastAppliedFilters.threat_date_of_usage,
                  threat_date_of_usage_proposed:
                    lastAppliedFilters.threat_date_of_usage_proposed,
                  threat_application_number: threatApplicationNumber,
                },
                {
                  headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                  },
                }
              );

              switch (response.status) {
                case 200:
                  dispatch({
                    type: LL_TM_PROTECT_BOOKMARK,
                    status: SUCCESS,
                    payload: response?.data,
                  });
                  notification["success"]({
                    message: "Bookmark Updated",
                    description: `Bookmark for, cease_desist_report_id ${reportId}, has been updated.`,
                  });
                  break;

                default:
                  notification["error"]({
                    message: `Unexpected Response For Bookmark! ${response.status}`,
                    description:
                      "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                  });
                  dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
                  break;
              }
            }
          } else {
            response = await Axios.post(
              `${WAT_RT_URL}/list_layout_bookmark/`,
              {
                cease_desist_report_id: reportId,
                bookmark_type: bookmark_type,
                page: pageNumber,
                filter_flag: lastAppliedFilters.filterFlag,
                threat_classes,
                threat_sources,
                search_term,
                threat_similarity,
                threat_type,
                threat_categories,
                threat_offices,
                threat_date_of_application:
                  lastAppliedFilters.threat_date_of_application,
                threat_date_of_usage: lastAppliedFilters.threat_date_of_usage,
                threat_date_of_usage_proposed:
                  lastAppliedFilters.threat_date_of_usage_proposed,
                threat_application_number: threatApplicationNumber,
              },
              {
                headers: {
                  Authorization: "Token " + localStorage.getItem("token"),
                },
              }
            );

            switch (response.status) {
              case 200:
                dispatch({
                  type: LL_TM_PROTECT_BOOKMARK,
                  status: SUCCESS,
                  payload: response?.data,
                });
                notification["success"]({
                  message: "Bookmark Placed",
                  description: `The current record for, cease_desist_report_id ${reportId}, has been bookmarked.`,
                });
                break;

              default:
                notification["error"]({
                  message: `Unexpected Response For Bookmark! ${response.status}`,
                  description:
                    "We are sorry that something unexpected has happened. Please, come back after some time. Meanwhile, we are looking into it.",
                });
                dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
                break;
            }
          }
        } catch (err) {
          checkAuthorisation(dispatch, err);
          dispatch({ type: LL_TM_PROTECT_BOOKMARK, status: ERROR });
        }
        break;

      default:
        break;
    }
  };
}
