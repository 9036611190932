import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  Checkbox,
  Typography,
  Spin,
  Modal,
  Layout,
  Col,
  Button,
  Row,
} from "antd";
import {
  getCalendar,
  removeCalendar,
  setStoreData,
} from "../../../redux/actions/managerActions/calendar/actions";
import { connect } from "react-redux";
import moment from "moment";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
const { Text } = Typography;
const { Content } = Layout;
class CalendarApp extends React.Component {
  state = {
    weekendsVisible: true,
    date: null,
    selectedEvent: {},
    selectedEventType: null,
    modalsVisibility: false,
    start: null,
    end: null,
  };
  calendarRef = React.createRef();

  mergeEvents = (cals) => {
    let mergedEvents = [];

    const keys = Object.keys(cals || {});
    keys.forEach((key, index) => {
      if (cals[key] === true) {
        if (this.props[key] !== null && this.props[key]?.length > 0) {
          mergedEvents = [...mergedEvents, ...this.props[key]];
        }
      }
    });
    this.props.setStoreData({ events: mergedEvents });
  };

  handleDateSet = (fetchInfo) => {
    let start = moment(fetchInfo.start);
    let startFormatted = moment(fetchInfo.start).format("DD-MM-YYYY");

    let end = moment(fetchInfo.end);
    let endFormatted = moment(fetchInfo.end).format("DD-MM-YYYY");

    let date = startFormatted + "to" + endFormatted;
    this.props.setStoreData({
      currentDate: date,
      events: [],
    });
    this.props.setStoreData({
      start:
        this.props?.start > start || this.props?.start === null
          ? start
          : this.props?.start,
      end:
        this.props?.end < end || this.props?.start === null
          ? end
          : this.props?.end,
    });
    const keys = Object.keys(this.props?.activeCalendars || {});
    keys.forEach((key, index) => {
      if (this.props.activeCalendars[key] === true) {
        this.props.getCalendar({ date, required_field: key });
        // this.mergeEvents()
      }
    });
    // this.mergeEvents(this.props.activeCalendars)
  };

  handleTrademarkProfile(application_number, management_mark_id) {
    if (application_number && management_mark_id) {
      sessionStorage.setItem("management_trademark_id", management_mark_id);
      sessionStorage.setItem(
        "trademark_application_number",
        application_number,
      );
      // history.push('/manager/trademark/trademark-profile/'+management_mark_id )
      window.open(
        "/manager/trademark/trademark-profile/" + management_mark_id,
        "_blank",
      );
      this.setState({
        management_trademark_id: management_mark_id,
      });
    }
  }

  handlePatentProfile(data) {
    if (data) {
      sessionStorage.setItem("management_patent_id", data.management_mark_id);
      sessionStorage.setItem(
        "patent_application_number",
        data.application_number,
      );
      window.open(
        "/manager/patent/manage-patent-profile/" + data.management_mark_id,
      );
      this.setState({
        management_patent_id: data.management_mark_id,
      });
    }
  }

  onCheckAllChange = (e, key) => {
    let currentCals = this.props.activeCalendars;
    let selectedCals = {};
    if (key === "YOUR_TM") {
      selectedCals = e.target.checked
        ? {
            ...currentCals,
            TRADEMARK_RENEWALS: true,
            TRADEMARK_PENDING_REPLIES: true,
            TRADEMARK_TLA_HEARINGS_CAUSE_LIST: true,
            TRADEMARK_TLA_HEARINGS_IP_INDIA: true,
            TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: true,
            TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: true,
            TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: true,
          }
        : {
            ...currentCals,
            TRADEMARK_RENEWALS: false,
            TRADEMARK_PENDING_REPLIES: false,
            TRADEMARK_TLA_HEARINGS_CAUSE_LIST: false,
            TRADEMARK_TLA_HEARINGS_IP_INDIA: false,
            TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: false,
            TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: false,
            TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: false,
          };

      this.props.setStoreData({
        YOUR_TM_indeterminate: false,
        YOUR_TM_checkAll: e.target.checked,
        activeCalendars: selectedCals,
        events: e.target.checked ? this.props.events : [],
      });
    } else if (key === "THIRD_PARTY") {
      selectedCals = e.target.checked
        ? {
            ...currentCals,
            THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES: true,
            STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE: true,
            THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST: true,
            THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA: true,
            THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: true,
            THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: true,
            THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: true,
          }
        : {
            ...currentCals,
            THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES: false,
            STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE: false,
            THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST: false,
            THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA: false,
            THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: false,
            THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: false,
            THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: false,
          };

      this.props.setStoreData({
        THIRD_PARTY_indeterminate: false,
        THIRD_PARTY_checkAll: e.target.checked,
        activeCalendars: selectedCals,

        events: e.target.checked ? this.props.events : [],
      });
    }
    if (key === "PATENT") {
      selectedCals = e.target.checked
        ? {
            ...currentCals,
            PATENT_RENEWAL: true,
            PATENT_HEARING: true,
            PATENT_PENDING_REPLIES_TO_FER: true,
            PATENT_PENDING_WORKING_OF_PATENT: true,
            PATENT_PENDING_STATEMENT_OF_UNDERTAKING: true,
            PATENT_PENDING_REQUEST_EXAMINATION_NOTICE: true,
          }
        : {
            ...currentCals,
            PATENT_RENEWAL: false,
            PATENT_HEARING: false,
            PATENT_PENDING_REPLIES_TO_FER: false,
            PATENT_PENDING_WORKING_OF_PATENT: false,
            PATENT_PENDING_STATEMENT_OF_UNDERTAKING: false,
            PATENT_PENDING_REQUEST_EXAMINATION_NOTICE: false,
          };

      this.props.setStoreData({
        PATENT_indeterminate: false,
        PATENT_checkAll: e.target.checked,
        activeCalendars: selectedCals,
        events: e.target.checked ? this.props.events : [],
      });
    }

    if (e.target.checked) {
      const keys = Object.keys(selectedCals || {});
      keys.forEach((key, index) => {
        if (selectedCals[key] === true) {
          if (this.props[key] === null) {
            this.props.getCalendar({
              date: this.props.currentDate || this.state.date,
              required_field: key,
            });
          }
        }
      });
    }
    this.mergeEvents(selectedCals);
  };

  handleActiveCalendars = (key, value) => {
    if (
      [
        "TRADEMARK_RENEWALS",
        "TRADEMARK_PENDING_REPLIES",
        "TRADEMARK_TLA_HEARINGS_CAUSE_LIST",
        "TRADEMARK_TLA_HEARINGS_IP_INDIA",
        "TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST",
        "TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA",
        "TRADEMARK_PENDING_REPLIES_DISPATCH_DATE",
      ].includes(key)
    ) {
      let cals = {
        ...this.props.activeCalendars,
        [key]: value,
      };
      this.props.setStoreData({
        activeCalendars: cals,
        YOUR_TM_indeterminate:
          cals["TRADEMARK_RENEWALS"] === false &&
          cals["TRADEMARK_PENDING_REPLIES"] === false &&
          cals["TRADEMARK_TLA_HEARINGS_CAUSE_LIST"] === false &&
          cals["TRADEMARK_TLA_HEARINGS_IP_INDIA"] === false &&
          cals["TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST"] === false &&
          cals["TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA"] === false &&
          cals["TRADEMARK_PENDING_REPLIES_DISPATCH_DATE"] === false
            ? false
            : true,

        YOUR_TM_checkAll:
          cals["TRADEMARK_RENEWALS"] === false &&
          cals["TRADEMARK_PENDING_REPLIES"] === false &&
          cals["TRADEMARK_TLA_HEARINGS_CAUSE_LIST"] === false &&
          cals["TRADEMARK_TLA_HEARINGS_IP_INDIA"] === false &&
          cals["TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST"] === false &&
          cals["TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA"] === false &&
          cals["TRADEMARK_PENDING_REPLIES_DISPATCH_DATE"] === false
            ? false
            : true,
      });
    } else if (
      [
        "THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST",
        "THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA",
        "THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST",
        "THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA",
        "THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES",
        "STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE",
        "THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE",
      ].includes(key)
    ) {
      let cals = {
        ...this.props.activeCalendars,
        [key]: value,
      };
      this.props.setStoreData({
        activeCalendars: cals,
        THIRD_PARTY_indeterminate:
          cals["THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST"] ===
            false &&
          cals["THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA"] ===
            false &&
          cals[
            "THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST"
          ] === false &&
          cals["THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA"] ===
            false &&
          cals["THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES"] === false &&
          cals["STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE"] === false &&
          cals[
            "THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE"
          ] === false
            ? false
            : true,
        THIRD_PARTY_checkAll:
          cals["THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST"] ===
            false &&
          cals["THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA"] ===
            false &&
          cals[
            "THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST"
          ] === false &&
          cals["THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA"] ===
            false &&
          cals["THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES"] === false &&
          cals["STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE"] === false &&
          cals[
            "THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE"
          ] === false
            ? false
            : true,
      });
    } else if (
      [
        "PATENT_RENEWAL",
        "PATENT_HEARING",
        "PATENT_PENDING_REPLIES_TO_FER",
        "PATENT_PENDING_WORKING_OF_PATENT",
        "PATENT_PENDING_STATEMENT_OF_UNDERTAKING",
        "PATENT_PENDING_REQUEST_EXAMINATION_NOTICE",
      ].includes(key)
    ) {
      let cals = {
        ...this.props.activeCalendars,
        [key]: value,
      };
      this.props.setStoreData({
        activeCalendars: cals,
        PATENT_indeterminate:
          cals["PATENT_RENEWAL"] === false &&
          cals["PATENT_HEARING"] === false &&
          cals["PATENT_PENDING_REPLIES_TO_FER"] === false &&
          cals["PATENT_PENDING_WORKING_OF_PATENT"] === false &&
          cals["PATENT_PENDING_STATEMENT_OF_UNDERTAKING"] === false &&
          cals["PATENT_PENDING_REQUEST_EXAMINATION_NOTICE"] === false
            ? false
            : true,
        PATENT_checkAll:
          cals["PATENT_RENEWAL"] === false &&
          cals["PATENT_HEARING"] === false &&
          cals["PATENT_PENDING_REPLIES_TO_FER"] === false &&
          cals["PATENT_PENDING_WORKING_OF_PATENT"] === false &&
          cals["PATENT_PENDING_STATEMENT_OF_UNDERTAKING"] === false &&
          cals["PATENT_PENDING_REQUEST_EXAMINATION_NOTICE"] === false
            ? false
            : true,
      });
    } else {
      this.props.setStoreData({
        activeCalendars: {
          ...this.props.activeCalendars,
          [key]: value,
        },
      });
    }
    if (!value) {
      // this.props.removeCalendar(key)
      this.props.setStoreData({
        activeCalendars: {
          ...this.props.activeCalendars,
          [key]: value,
        },
      });
    } else {
      if (this.props[key] === null) {
        this.props.getCalendar({
          date: this.props.currentDate || this.state.date,
          required_field: key,
        });
      }
    }
    this.mergeEvents({
      ...this.props.activeCalendars,
      [key]: value,
    });
  };

  render() {
    let isLoading = false;

    const {
      TODO_TASKSStatus,
      TRADEMARK_RENEWALSStatus,
      TRADEMARK_PENDING_REPLIESStatus,
      TRADEMARK_TLA_HEARINGS_IP_INDIAStatus,
      TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus,
      TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus,
      TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIESStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIAStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus,
      PATENT_RENEWALStatus,
      PATENT_HEARINGStatus,
      PATENT_PENDING_REPLIES_TO_FERStatus,
      PATENT_PENDING_WORKING_OF_PATENTStatus,
      PATENT_PENDING_STATEMENT_OF_UNDERTAKINGStatus,
      PATENT_PENDING_REQUEST_EXAMINATION_NOTICEStatus,
    } = this?.props || {};

    if (
      TODO_TASKSStatus === "LOADING" ||
      TRADEMARK_RENEWALSStatus === "LOADING" ||
      TRADEMARK_PENDING_REPLIESStatus === "LOADING" ||
      TRADEMARK_TLA_HEARINGS_IP_INDIAStatus === "LOADING" ||
      TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus === "LOADING" ||
      TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus === "LOADING" ||
      TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus === "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIESStatus === "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIAStatus === "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus ===
        "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus ===
        "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus ===
        "LOADING" ||
      PATENT_RENEWALStatus === "LOADING" ||
      PATENT_HEARINGStatus === "LOADING" ||
      PATENT_PENDING_REPLIES_TO_FERStatus === "LOADING" ||
      PATENT_PENDING_WORKING_OF_PATENTStatus === "LOADING" ||
      PATENT_PENDING_STATEMENT_OF_UNDERTAKINGStatus === "LOADING" ||
      PATENT_PENDING_REQUEST_EXAMINATION_NOTICEStatus === "LOADING"
    ) {
      isLoading = true;
    }

    const selectedEvent = this.state.selectedEvent || {};
    const selectedEventType = this.state.selectedEventType || {};

    return (
      <>
        {isLoading && (
          <Spin
            style={{
              position: "absolute",
              left: "55%",
              top: "50%",
              zIndex: 9999,
            }}
            size="large"
          />
        )}
        <Content style={{ padding: 0, background: "white" }}>
          <Row>
            <Col
              span={4}
              style={{
                maxHeight: "90vh",
                overflow: "auto",
              }}
            >
              {this.renderSidebar()}
            </Col>
            <Col
              span={20}
              style={{
                padding: "32px",
              }}
            >
              <FullCalendar
                collapsibleWidth={true}
                datesSet={this.handleDateSet}
                ref={this.calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                initialView="dayGridMonth"
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={this.state.weekendsVisible}
                events={this.props?.events}
                initialEvents={this.props?.events}
                // select={this.handleDateSelect}
                height={700}
                eventContent={renderEventContent} // custom render function
                eventClick={this.handleEventClick}
              />
            </Col>
          </Row>

          <Modal
            closable={false}
            footer={false}
            open={this.state.modalsVisibility}
            onOk={() => this.setState({ modalsVisibility: false })}
            onCancel={() => this.setState({ modalsVisibility: false })}
          >
            {selectedEvent && selectedEvent !== {} && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h2>{selectedEvent?.title}</h2>

                {selectedEventType &&
                  selectedEventType === "TRADEMARK_RENEWALS" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.title}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div>{`Valid Upto : ${moment(
                          selectedEvent?.start,
                        ).format("DD MMM, YYYY")}`}</div>
                        <div>
                          <div>Image:</div>
                          <img
                            src={selectedEvent?.extendedProps?.associated_image}
                            alt=""
                            style={{ width: "120px", height: "120px" }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                {selectedEventType &&
                  (selectedEventType === "TRADEMARK_PENDING_REPLIES" ||
                    selectedEventType ===
                      "TRADEMARK_PENDING_REPLIES_DISPATCH_DATE") && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.title}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        {selectedEvent?.extendedProps?.documents &&
                          selectedEvent?.extendedProps?.documents?.length >
                            0 && (
                            <div>
                              Documents [File-DOU]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps?.documents &&
                                  selectedEvent?.extendedProps?.documents
                                    ?.length > 0 ? (
                                    selectedEvent?.extendedProps?.documents.map(
                                      (doc) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={doc.file && doc.file}
                                            >
                                              {doc.name && doc.name}
                                            </a>{" "}
                                            -<span>{doc?.date_of_upload}</span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.notices &&
                          selectedEvent?.extendedProps?.notices?.length > 0 && (
                            <div>
                              Notice Documents [File-DOU-DOD]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps?.notices &&
                                  selectedEvent?.extendedProps?.notices
                                    ?.length > 0 ? (
                                    selectedEvent?.extendedProps?.notices.map(
                                      (notice) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={notice.file && notice.file}
                                            >
                                              {notice.name && notice.name}
                                            </a>
                                            <span>
                                              {notice?.date_of_upload &&
                                                `-${notice?.date_of_upload}`}
                                            </span>
                                            <span>
                                              {notice?.date_of_correspondence &&
                                                `-${notice?.date_of_correspondence}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.examination_reports &&
                          selectedEvent?.extendedProps?.examination_reports
                            ?.length > 0 && (
                            <div>
                              Examination Report [File-DOE]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps
                                    ?.examination_reports &&
                                  selectedEvent?.extendedProps
                                    ?.examination_reports?.length > 0 ? (
                                    selectedEvent?.extendedProps?.examination_reports.map(
                                      (reportObj) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={
                                                reportObj.file && reportObj.file
                                              }
                                            >
                                              {reportObj.name && reportObj.name}
                                            </a>
                                            <span>
                                              {reportObj?.date_of_examination_report &&
                                                `-${reportObj?.date_of_examination_report}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}

                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  (selectedEventType ===
                    "THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES" ||
                    selectedEventType ===
                      "THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE") && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.title}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        {selectedEvent?.extendedProps?.documents &&
                          selectedEvent?.extendedProps?.documents?.length >
                            0 && (
                            <div>
                              Documents [File-DOU]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps?.documents &&
                                  selectedEvent?.extendedProps?.documents
                                    ?.length > 0 ? (
                                    selectedEvent?.extendedProps?.documents.map(
                                      (doc) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={doc.file && doc.file}
                                            >
                                              {doc.name && doc.name}
                                            </a>{" "}
                                            -<span>{doc?.date_of_upload}</span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.notices &&
                          selectedEvent?.extendedProps?.notices?.length > 0 && (
                            <div>
                              Notice Documents [File-DOU-DOD]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps?.notices &&
                                  selectedEvent?.extendedProps?.notices
                                    ?.length > 0 ? (
                                    selectedEvent?.extendedProps?.notices.map(
                                      (notice) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={notice.file && notice.file}
                                            >
                                              {notice.name && notice.name}
                                            </a>
                                            <span>
                                              {notice?.date_of_upload &&
                                                `-${notice?.date_of_upload}`}
                                            </span>
                                            <span>
                                              {notice?.date_of_correspondence &&
                                                `-${notice?.date_of_correspondence}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.examination_reports &&
                          selectedEvent?.extendedProps?.examination_reports
                            ?.length > 0 && (
                            <div>
                              Examination Report [File-DOE]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps
                                    ?.examination_reports &&
                                  selectedEvent?.extendedProps
                                    ?.examination_reports?.length > 0 ? (
                                    selectedEvent?.extendedProps?.examination_reports.map(
                                      (reportObj) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={
                                                reportObj.file && reportObj.file
                                              }
                                            >
                                              {reportObj.name && reportObj.name}
                                            </a>
                                            <span>
                                              {reportObj?.date_of_examination_report &&
                                                `-${reportObj?.date_of_examination_report}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}

                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  selectedEventType ===
                    "STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.title}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div>{`Journal Number: ${selectedEvent?.extendedProps?.journal_number}`}</div>
                        <div>{`Journal Published On: ${moment(
                          selectedEvent?.extendedProps?.journal_published_date,
                        ).format("DD MMM, YYYY")}`}</div>

                        {selectedEvent?.extendedProps?.documents &&
                          selectedEvent?.extendedProps?.documents?.length >
                            0 && (
                            <div>
                              Documents [File-DOU]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps?.documents &&
                                  selectedEvent?.extendedProps?.documents
                                    ?.length > 0 ? (
                                    selectedEvent?.extendedProps?.documents.map(
                                      (doc) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={doc.file && doc.file}
                                            >
                                              {doc.name && doc.name}
                                            </a>{" "}
                                            -<span>{doc?.date_of_upload}</span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.notices &&
                          selectedEvent?.extendedProps?.notices?.length > 0 && (
                            <div>
                              Notice Documents [File-DOU-DOD]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps?.notices &&
                                  selectedEvent?.extendedProps?.notices
                                    ?.length > 0 ? (
                                    selectedEvent?.extendedProps?.notices.map(
                                      (notice) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={notice.file && notice.file}
                                            >
                                              {notice.name && notice.name}
                                            </a>
                                            <span>
                                              {notice?.date_of_upload &&
                                                `-${notice?.date_of_upload}`}
                                            </span>
                                            <span>
                                              {notice?.date_of_correspondence &&
                                                `-${notice?.date_of_correspondence}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.examination_reports &&
                          selectedEvent?.extendedProps?.examination_reports
                            ?.length > 0 && (
                            <div>
                              Examination Report [File-DOE]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps
                                    ?.examination_reports &&
                                  selectedEvent?.extendedProps
                                    ?.examination_reports?.length > 0 ? (
                                    selectedEvent?.extendedProps?.examination_reports.map(
                                      (reportObj) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={
                                                reportObj.file && reportObj.file
                                              }
                                            >
                                              {reportObj.name && reportObj.name}
                                            </a>
                                            <span>
                                              {reportObj?.date_of_examination_report &&
                                                `-${reportObj?.date_of_examination_report}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}

                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                {selectedEventType &&
                  selectedEventType === "TRADEMARK_TLA_HEARINGS_CAUSE_LIST" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div>{`TLA Hearing Date : ${moment(
                          selectedEvent?.start,
                        ).format("DD MMM, YYYY")}`}</div>
                        <div>{`Office : ${
                          selectedEvent?.extendedProps?.application_office
                            ?.name || "N.A."
                        }`}</div>
                        <div>{`Officer : ${
                          selectedEvent?.extendedProps?.hearing_officer ||
                          "N.A."
                        }`}</div>

                        {selectedEvent?.extendedProps?.documents &&
                          selectedEvent?.extendedProps?.documents?.length >
                            0 && (
                            <div>
                              Documents [File-DOU]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps?.documents &&
                                  selectedEvent?.extendedProps?.documents
                                    ?.length > 0 ? (
                                    selectedEvent?.extendedProps?.documents.map(
                                      (doc) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={doc.file && doc.file}
                                            >
                                              {doc.name && doc.name}
                                            </a>{" "}
                                            -<span>{doc?.date_of_upload}</span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.notices &&
                          selectedEvent?.extendedProps?.notices?.length > 0 && (
                            <div>
                              Notice Documents [File-DOU-DOD]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps?.notices &&
                                  selectedEvent?.extendedProps?.notices
                                    ?.length > 0 ? (
                                    selectedEvent?.extendedProps?.notices.map(
                                      (notice) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={notice.file && notice.file}
                                            >
                                              {notice.name && notice.name}
                                            </a>
                                            <span>
                                              {notice?.date_of_upload &&
                                                `-${notice?.date_of_upload}`}
                                            </span>
                                            <span>
                                              {notice?.date_of_correspondence &&
                                                `-${notice?.date_of_correspondence}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.examination_reports &&
                          selectedEvent?.extendedProps?.examination_reports
                            ?.length > 0 && (
                            <div>
                              Examination Reports [File-DOE]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps
                                    ?.examination_reports &&
                                  selectedEvent?.extendedProps
                                    ?.examination_reports?.length > 0 ? (
                                    selectedEvent?.extendedProps?.examination_reports.map(
                                      (reportObj) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={
                                                reportObj.file && reportObj.file
                                              }
                                            >
                                              {reportObj.name && reportObj.name}
                                            </a>
                                            <span>
                                              {reportObj?.date_of_examination_report &&
                                                `-${reportObj?.date_of_examination_report}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}

                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  (selectedEventType ===
                    "TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST" ||
                    selectedEventType ===
                      "TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA") && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div>{`TLA Hearing Date : ${moment(
                          selectedEvent?.start,
                        ).format("DD MMM, YYYY")}`}</div>
                        <div>{`Office : ${
                          selectedEvent?.extendedProps?.application_office
                            ?.name || "N.A."
                        }`}</div>
                        <div>{`Officer : ${
                          selectedEvent?.extendedProps?.hearing_officer ||
                          "N.A."
                        }`}</div>
                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  selectedEventType === "TRADEMARK_TLA_HEARINGS_IP_INDIA" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div>{`TLA Hearing Date : ${moment(
                          selectedEvent?.start,
                        ).format("DD MMM, YYYY")}`}</div>
                        <div>{`Office : ${
                          selectedEvent?.extendedProps?.application_office
                            ?.name || "N.A."
                        }`}</div>
                        <div>{`Officer : ${
                          selectedEvent?.extendedProps?.hearing_officer ||
                          "N.A."
                        }`}</div>
                        {selectedEvent?.extendedProps?.examination_reports &&
                          selectedEvent?.extendedProps?.examination_reports
                            ?.length > 0 && (
                            <div>
                              Examination Report [File-DOE]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps
                                    ?.examination_reports &&
                                  selectedEvent?.extendedProps
                                    ?.examination_reports?.length > 0 ? (
                                    selectedEvent?.extendedProps?.examination_reports.map(
                                      (reportObj) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={
                                                reportObj.file && reportObj.file
                                              }
                                            >
                                              {reportObj.name && reportObj.name}
                                            </a>
                                            <span>
                                              {reportObj?.date_of_examination_report &&
                                                `-${reportObj?.date_of_examination_report}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  selectedEventType ===
                    "THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div>{`Office : ${
                          selectedEvent?.extendedProps?.application_office
                            ?.name || "N.A."
                        }`}</div>
                        <div>{`Officer : ${
                          selectedEvent?.extendedProps?.hearing_officer ||
                          "N.A."
                        }`}</div>
                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  selectedEventType ===
                    "THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div>{`Office : ${
                          selectedEvent?.extendedProps?.application_office
                            ?.name || "N.A."
                        }`}</div>
                        <div>{`Officer : ${
                          selectedEvent?.extendedProps?.hearing_officer ||
                          "N.A."
                        }`}</div>
                        {selectedEvent?.extendedProps?.examination_reports &&
                          selectedEvent?.extendedProps?.examination_reports
                            ?.length > 0 && (
                            <div>
                              Examination Report [File-DOE]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps
                                    ?.examination_reports &&
                                  selectedEvent?.extendedProps
                                    ?.examination_reports?.length > 0 ? (
                                    selectedEvent?.extendedProps?.examination_reports.map(
                                      (reportObj) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={
                                                reportObj.file && reportObj.file
                                              }
                                            >
                                              {reportObj.name && reportObj.name}
                                            </a>
                                            <span>
                                              {reportObj?.date_of_examination_report &&
                                                `-${reportObj?.date_of_examination_report}`}
                                            </span>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  (selectedEventType ===
                    "THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST" ||
                    selectedEventType ===
                      "THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA") && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>{`TM Applied For: ${selectedEvent?.extendedProps?.applied_for}`}</div>
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handleTrademarkProfile(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                                selectedEvent?.extendedProps
                                  ?.management_mark_id,
                              )
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div>{`Office : ${
                          selectedEvent?.extendedProps?.application_office
                            ?.name || "N.A."
                        }`}</div>
                        <div>{`Officer : ${
                          selectedEvent?.extendedProps?.hearing_officer ||
                          "N.A."
                        }`}</div>
                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                {selectedEventType &&
                  selectedEventType === "PATENT_HEARING" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handlePatentProfile({
                                application_number:
                                  selectedEvent?.extendedProps
                                    ?.application_number,
                                management_mark_id:
                                  selectedEvent?.extendedProps
                                    ?.management_mark_id,
                              })
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>Applicant: </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {selectedEvent?.extendedProps?.applicant &&
                            selectedEvent?.extendedProps?.applicant.length ? (
                              [...selectedEvent?.extendedProps?.applicant]?.map(
                                (data, key) => {
                                  return (
                                    <p
                                      key={key}
                                      style={{
                                        margin: "0px 4px",
                                      }}
                                      className="text-camelCase"
                                      // onClick={() => this.handleProprietorProfile(data)}
                                    >
                                      {checkValue(data)
                                        ? `${data?.toLowerCase()},`
                                        : "N.A."}
                                    </p>
                                  );
                                },
                              )
                            ) : (
                              <p
                                style={{
                                  margin: "0px 4px",
                                }}
                                className="text-camelCase"
                                // onClick={() => this.handleProprietorProfile(data)}
                              >
                                N.A.
                              </p>
                            )}
                          </div>
                        </div>
                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                {selectedEventType &&
                  selectedEventType === "PATENT_RENEWAL" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handlePatentProfile({
                                application_number:
                                  selectedEvent?.extendedProps
                                    ?.application_number,
                                management_mark_id:
                                  selectedEvent?.extendedProps
                                    ?.management_mark_id,
                              })
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>Applicant: </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {selectedEvent?.extendedProps?.applicant &&
                            selectedEvent?.extendedProps?.applicant.length ? (
                              [...selectedEvent?.extendedProps?.applicant]?.map(
                                (data, key) => {
                                  return (
                                    <p
                                      key={key}
                                      style={{
                                        margin: "0px 4px",
                                      }}
                                      className="text-camelCase"
                                      // onClick={() => this.handleProprietorProfile(data)}
                                    >
                                      {checkValue(data)
                                        ? `${data?.toLowerCase()},`
                                        : "N.A."}
                                    </p>
                                  );
                                },
                              )
                            ) : (
                              <p
                                style={{
                                  margin: "0px 4px",
                                }}
                                className="text-camelCase"
                                // onClick={() => this.handleProprietorProfile(data)}
                              >
                                N.A.
                              </p>
                            )}
                          </div>
                        </div>
                        {selectedEvent?.extendedProps?.associated_image && (
                          <div>
                            <div>Image:</div>

                            <img
                              src={
                                selectedEvent?.extendedProps?.associated_image
                              }
                              alt=""
                              style={{ width: "120px", height: "120px" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  selectedEventType === "PATENT_PENDING_REPLIES_TO_FER" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handlePatentProfile({
                                application_number:
                                  selectedEvent?.extendedProps
                                    ?.application_number,
                                management_mark_id:
                                  selectedEvent?.extendedProps
                                    ?.management_mark_id,
                              })
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>Applicant: </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {selectedEvent?.extendedProps?.applicant &&
                            selectedEvent?.extendedProps?.applicant.length ? (
                              [...selectedEvent?.extendedProps?.applicant]?.map(
                                (data, key) => {
                                  return (
                                    <p
                                      key={key}
                                      style={{
                                        margin: "0px 4px",
                                      }}
                                      className="text-camelCase"
                                      // onClick={() => this.handleProprietorProfile(data)}
                                    >
                                      {checkValue(data)
                                        ? `${data?.toLowerCase()},`
                                        : "N.A."}
                                    </p>
                                  );
                                },
                              )
                            ) : (
                              <p
                                style={{
                                  margin: "0px 4px",
                                }}
                                className="text-camelCase"
                                // onClick={() => this.handleProprietorProfile(data)}
                              >
                                N.A.
                              </p>
                            )}
                          </div>
                        </div>
                        {selectedEvent?.extendedProps?.notice_document &&
                          selectedEvent?.extendedProps?.notice_document
                            ?.length > 0 && (
                            <div>
                              Notice Documents [File-DOU-DOD]:-
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ul>
                                  {selectedEvent?.extendedProps
                                    ?.notice_document &&
                                  selectedEvent?.extendedProps?.notice_document
                                    ?.length > 0 ? (
                                    selectedEvent?.extendedProps?.notice_document.map(
                                      (notice) => {
                                        return (
                                          <li>
                                            <a
                                              target="blank"
                                              href={notice.file && notice.file}
                                            >
                                              {(notice.name && notice.name) ||
                                                (notice.file && notice.file)}
                                            </a>
                                          </li>
                                        );
                                      },
                                    )
                                  ) : (
                                    <li>N.A.</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  selectedEventType === "PATENT_PENDING_WORKING_OF_PATENT" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handlePatentProfile({
                                application_number:
                                  selectedEvent?.extendedProps
                                    ?.application_number,
                                management_mark_id:
                                  selectedEvent?.extendedProps
                                    ?.management_mark_id,
                              })
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>Applicant: </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {selectedEvent?.extendedProps?.applicant &&
                            selectedEvent?.extendedProps?.applicant.length ? (
                              [...selectedEvent?.extendedProps?.applicant]?.map(
                                (data, key) => {
                                  return (
                                    <p
                                      key={key}
                                      style={{
                                        margin: "0px 4px",
                                      }}
                                      className="text-camelCase"
                                      // onClick={() => this.handleProprietorProfile(data)}
                                    >
                                      {checkValue(data)
                                        ? `${data?.toLowerCase()},`
                                        : "N.A."}
                                    </p>
                                  );
                                },
                              )
                            ) : (
                              <p
                                style={{
                                  margin: "0px 4px",
                                }}
                                className="text-camelCase"
                                // onClick={() => this.handleProprietorProfile(data)}
                              >
                                N.A.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  selectedEventType ===
                    "PATENT_PENDING_STATEMENT_OF_UNDERTAKING" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handlePatentProfile({
                                application_number:
                                  selectedEvent?.extendedProps
                                    ?.application_number,
                                management_mark_id:
                                  selectedEvent?.extendedProps
                                    ?.management_mark_id,
                              })
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>Applicant: </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {selectedEvent?.extendedProps?.applicant &&
                            selectedEvent?.extendedProps?.applicant.length ? (
                              [...selectedEvent?.extendedProps?.applicant]?.map(
                                (data, key) => {
                                  return (
                                    <p
                                      key={key}
                                      style={{
                                        margin: "0px 4px",
                                      }}
                                      className="text-camelCase"
                                      // onClick={() => this.handleProprietorProfile(data)}
                                    >
                                      {checkValue(data)
                                        ? `${data?.toLowerCase()},`
                                        : "N.A."}
                                    </p>
                                  );
                                },
                              )
                            ) : (
                              <p
                                style={{
                                  margin: "0px 4px",
                                }}
                                className="text-camelCase"
                                // onClick={() => this.handleProprietorProfile(data)}
                              >
                                N.A.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {selectedEventType &&
                  selectedEventType ===
                    "PATENT_PENDING_REQUEST_EXAMINATION_NOTICE" && (
                    <>
                      <div>
                        {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                      </div>
                      <hr
                        style={{
                          border: "1px solid lightgray",
                          width: "100%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          gap: "0.6rem",
                          color: "black",
                        }}
                      >
                        <div>
                          Application Number :
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handlePatentProfile({
                                application_number:
                                  selectedEvent?.extendedProps
                                    ?.application_number,
                                management_mark_id:
                                  selectedEvent?.extendedProps
                                    ?.management_mark_id,
                              })
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>Applicant: </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {selectedEvent?.extendedProps?.applicant &&
                            selectedEvent?.extendedProps?.applicant.length ? (
                              [...selectedEvent?.extendedProps?.applicant]?.map(
                                (data, key) => {
                                  return (
                                    <p
                                      key={key}
                                      style={{
                                        margin: "0px 4px",
                                      }}
                                      className="text-camelCase"
                                      // onClick={() => this.handleProprietorProfile(data)}
                                    >
                                      {checkValue(data)
                                        ? `${data?.toLowerCase()},`
                                        : "N.A."}
                                    </p>
                                  );
                                },
                              )
                            ) : (
                              <p
                                style={{
                                  margin: "0px 4px",
                                }}
                                className="text-camelCase"
                                // onClick={() => this.handleProprietorProfile(data)}
                              >
                                N.A.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {selectedEventType && selectedEventType === "TODO_TASKS" && (
                  <>
                    <div>
                      {moment(selectedEvent?.start).format("DD MMM, YYYY")}
                    </div>
                    <hr
                      style={{
                        border: "1px solid lightgray",
                        width: "100%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "16px",
                        gap: "0.6rem",
                        color: "black",
                      }}
                    >
                      <div
                        style={{
                          maxHeight: "200px",
                          overflow: "auto",
                        }}
                      >
                        {selectedEvent?.extendedProps?.description}
                      </div>
                      <div>
                        Application Number :
                        {selectedEvent?.extendedProps?.application_number
                          ?.length > 0 ? (
                          <span
                            className="application-text"
                            style={{ marginLeft: "2px" }}
                            onClick={() =>
                              this.handlePatentProfile({
                                application_number:
                                  selectedEvent?.extendedProps
                                    ?.application_number,
                                management_mark_id:
                                  selectedEvent?.extendedProps
                                    ?.management_mark_id,
                              })
                            }
                          >
                            <span>
                              {checkValue(
                                selectedEvent?.extendedProps
                                  ?.application_number,
                              )
                                ? selectedEvent?.extendedProps
                                    ?.application_number
                                : "N.A."}{" "}
                            </span>
                          </span>
                        ) : (
                          <span style={{ margin: "0px 4px" }}>N.A.</span>
                        )}
                      </div>
                      <div>Reminder Dates:- </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <ul>
                          {selectedEvent?.extendedProps?.reminder_date &&
                          selectedEvent?.extendedProps?.reminder_date?.length >
                            0 ? (
                            [
                              ...selectedEvent?.extendedProps?.reminder_date,
                            ]?.map((data, key) => {
                              return (
                                <li
                                  key={key}
                                  style={{
                                    margin: "0px 4px",
                                  }}
                                  className="text-camelCase"
                                  // onClick={() => this.handleProprietorProfile(data)}
                                >
                                  {data}
                                </li>
                              );
                            })
                          ) : (
                            <li>N.A.</li>
                          )}
                        </ul>
                      </div>
                      {/* <div><div>{`Reminder Dates : ${selectedEvent?.extendedProps?.reminder_date?.}`}</div></div> */}
                    </div>
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => {
                      this.setState({
                        modalsVisibility: false,
                      });
                    }}
                    type="primary"
                  >
                    Close
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        </Content>
      </>
    );
  }

  renderSidebar() {
    const {
      activeCalendars,

      YOUR_TM_checkAll,
      YOUR_TM_indeterminate,

      THIRD_PARTY_checkAll,
      THIRD_PARTY_indeterminate,

      PATENT_checkAll,
      PATENT_indeterminate,

      TODO_TASKSStatus,
      TRADEMARK_RENEWALSStatus,
      TRADEMARK_PENDING_REPLIESStatus,
      TRADEMARK_TLA_HEARINGS_IP_INDIAStatus,
      TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus,
      TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus,
      TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIESStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIAStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus,
      THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus,
      PATENT_RENEWALStatus,
      PATENT_HEARINGStatus,
      PATENT_PENDING_REPLIES_TO_FERStatus,
      PATENT_PENDING_WORKING_OF_PATENTStatus,
      PATENT_PENDING_STATEMENT_OF_UNDERTAKINGStatus,
      PATENT_PENDING_REQUEST_EXAMINATION_NOTICEStatus,
    } = this?.props || {};

    let isLoading = false;

    if (
      TODO_TASKSStatus === "LOADING" ||
      TRADEMARK_RENEWALSStatus === "LOADING" ||
      TRADEMARK_PENDING_REPLIESStatus === "LOADING" ||
      TRADEMARK_TLA_HEARINGS_IP_INDIAStatus === "LOADING" ||
      TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus === "LOADING" ||
      TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus === "LOADING" ||
      TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus === "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIESStatus === "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIAStatus === "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus ===
        "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus ===
        "LOADING" ||
      THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus ===
        "LOADING" ||
      PATENT_RENEWALStatus === "LOADING" ||
      PATENT_HEARINGStatus === "LOADING" ||
      PATENT_PENDING_REPLIES_TO_FERStatus === "LOADING" ||
      PATENT_PENDING_WORKING_OF_PATENTStatus === "LOADING" ||
      PATENT_PENDING_STATEMENT_OF_UNDERTAKINGStatus === "LOADING" ||
      PATENT_PENDING_REQUEST_EXAMINATION_NOTICEStatus === "LOADING"
    ) {
      isLoading = true;
    }

    const eventsMenu = (
      <div className="calendar-checkbox">
        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.TODO_TASKS}
          className="todo-checkbox"
          onChange={(event) =>
            this.handleActiveCalendars("TODO_TASKS", event.target.checked)
          }
        >
          <Text className="text-camelCase" style={{ fontWeight: "700" }}>
            Todo
          </Text>
        </Checkbox>
        <Checkbox
          indeterminate={YOUR_TM_indeterminate}
          onChange={(e) => this.onCheckAllChange(e, "YOUR_TM")}
          checked={YOUR_TM_checkAll}
          className="all-checkbox"
          disabled={isLoading}
        >
          <Text className="text-camelCase" style={{ fontWeight: "700" }}>
            Your TM
          </Text>
        </Checkbox>
        <hr
          style={{
            border: "1px solid lightgray",
            width: "100%",
            margin: 0,
          }}
        />
        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.TRADEMARK_RENEWALS}
          className="renewal-checkbox"
          onChange={(event) =>
            this.handleActiveCalendars(
              "TRADEMARK_RENEWALS",
              event.target.checked,
            )
          }
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Renewal
          </Text>
        </Checkbox>

        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.TRADEMARK_PENDING_REPLIES}
          className="pr-checkbox"
          onChange={(event) =>
            this.handleActiveCalendars(
              "TRADEMARK_PENDING_REPLIES",
              event.target.checked,
            )
          }
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Pending Reply (Date of Upload)
          </Text>
        </Checkbox>

        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.TRADEMARK_TLA_HEARINGS_CAUSE_LIST}
          onChange={(event) =>
            this.handleActiveCalendars(
              "TRADEMARK_TLA_HEARINGS_CAUSE_LIST",
              event.target.checked,
            )
          }
          className="th-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            TLA Hearing (Cause List)
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.TRADEMARK_TLA_HEARINGS_IP_INDIA}
          onChange={(event) =>
            this.handleActiveCalendars(
              "TRADEMARK_TLA_HEARINGS_IP_INDIA",
              event.target.checked,
            )
          }
          className="tla-ip-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            TLA Hearing (IP-India)
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST}
          onChange={(event) =>
            this.handleActiveCalendars(
              "TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST",
              event.target.checked,
            )
          }
          className="oh-cause-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Opposition Hearing (Cause List)
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA}
          onChange={(event) =>
            this.handleActiveCalendars(
              "TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA",
              event.target.checked,
            )
          }
          className="oh-ip-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Opposition Hearing (IP-India)
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.TRADEMARK_PENDING_REPLIES_DISPATCH_DATE}
          onChange={(event) =>
            this.handleActiveCalendars(
              "TRADEMARK_PENDING_REPLIES_DISPATCH_DATE",
              event.target.checked,
            )
          }
          className="tdd-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Pending Reply (Date of Service)
          </Text>
        </Checkbox>

        <Checkbox
          indeterminate={THIRD_PARTY_indeterminate}
          onChange={(e) => this.onCheckAllChange(e, "THIRD_PARTY")}
          checked={THIRD_PARTY_checkAll}
          className="all-checkbox"
          disabled={isLoading}
        >
          <Text className="text-camelCase" style={{ fontWeight: "700" }}>
            3rd Party
          </Text>
        </Checkbox>
        <hr
          style={{
            border: "1px solid lightgray",
            width: "100%",
            margin: 0,
          }}
        />
        <Checkbox
          disabled={isLoading}
          checked={
            activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES
          }
          className="tp-reply-checkbox"
          onChange={(event) =>
            this.handleActiveCalendars(
              "THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES",
              event.target.checked,
            )
          }
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Pending Reply (Date of Upload)
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={
            activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST
          }
          className="tp-tla-cause-checkbox"
          onChange={(event) =>
            this.handleActiveCalendars(
              "THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST",
              event.target.checked,
            )
          }
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            TLA Hearing (Cause List)
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={
            activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA
          }
          onChange={(event) =>
            this.handleActiveCalendars(
              "THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA",
              event.target.checked,
            )
          }
          className="tp-tla-ip-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            TLA Hearing (IP-India)
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={
            activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST
          }
          onChange={(event) =>
            this.handleActiveCalendars(
              "THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST",
              event.target.checked,
            )
          }
          className="tp-opp-cause-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Opposition Hearing (Cause List)
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={
            activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA
          }
          onChange={(event) =>
            this.handleActiveCalendars(
              "THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA",
              event.target.checked,
            )
          }
          className="tp-opp-ip-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Opposition Hearing (IP-India)
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE}
          className="tp-deadline-checkbox"
          onChange={(event) =>
            this.handleActiveCalendars(
              "STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE",
              event.target.checked,
            )
          }
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Status Tracker Opposition Alert
          </Text>
        </Checkbox>

        <Checkbox
          disabled={isLoading}
          checked={
            activeCalendars?.THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE
          }
          className="tpdd-checkbox"
          onChange={(event) =>
            this.handleActiveCalendars(
              "THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE",
              event.target.checked,
            )
          }
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Pending Reply (Date of Service)
          </Text>
        </Checkbox>
        <Checkbox
          indeterminate={PATENT_indeterminate}
          onChange={(e) => this.onCheckAllChange(e, "PATENT")}
          checked={PATENT_checkAll}
          className="all-checkbox"
          disabled={isLoading}
        >
          <Text className="text-camelCase" style={{ fontWeight: "700" }}>
            Patent
          </Text>
        </Checkbox>
        <hr
          style={{
            border: "1px solid lightgray",
            width: "100%",
            margin: 0,
          }}
        />
        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.PATENT_HEARING}
          onChange={(event) =>
            this.handleActiveCalendars("PATENT_HEARING", event.target.checked)
          }
          className="ph-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Hearing
          </Text>
        </Checkbox>
        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.PATENT_RENEWAL}
          onChange={(event) =>
            this.handleActiveCalendars("PATENT_RENEWAL", event.target.checked)
          }
          className="pat-ren-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Renewal
          </Text>
        </Checkbox>

        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.PATENT_PENDING_REPLIES_TO_FER}
          onChange={(event) =>
            this.handleActiveCalendars(
              "PATENT_PENDING_REPLIES_TO_FER",
              event.target.checked,
            )
          }
          className="rep-fer-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Replies to FER
          </Text>
        </Checkbox>

        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.PATENT_PENDING_WORKING_OF_PATENT}
          onChange={(event) =>
            this.handleActiveCalendars(
              "PATENT_PENDING_WORKING_OF_PATENT",
              event.target.checked,
            )
          }
          className="pat-pwp-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Working
          </Text>
        </Checkbox>

        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.PATENT_PENDING_STATEMENT_OF_UNDERTAKING}
          onChange={(event) =>
            this.handleActiveCalendars(
              "PATENT_PENDING_STATEMENT_OF_UNDERTAKING",
              event.target.checked,
            )
          }
          className="undertaking-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Statement of Undertaking
          </Text>
        </Checkbox>

        <Checkbox
          disabled={isLoading}
          checked={activeCalendars?.PATENT_PENDING_REQUEST_EXAMINATION_NOTICE}
          onChange={(event) =>
            this.handleActiveCalendars(
              "PATENT_PENDING_REQUEST_EXAMINATION_NOTICE",
              event.target.checked,
            )
          }
          className="exm-not-checkbox"
        >
          <Text className="text-camelCase" style={{ fontWeight: "400" }}>
            Request Examination Notice
          </Text>
        </Checkbox>
      </div>
    );
    return (
      <div className="calendar-app-sidebar-section">
        <div
          style={{
            border: "1.5px solid rgb(234 230 230)",
            padding: "4px",
            background: "white",
            borderRadius: "4px",
          }}
        >
          {eventsMenu}
        </div>
      </div>
    );
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleEventClick = (clickInfo) => {
    this.setState({
      selectedEvent: clickInfo?.event,
      selectedEventType: clickInfo?.event?.extendedProps?.event_type,
      modalsVisibility: true,
    });
  };
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <b style={{ padding: "2px" }}>{eventInfo.event.title}</b>
    </>
  );
}

const mapDispatchToProps = {
  getCalendar,
  removeCalendar,
  setStoreData,
};

function mapStateToProps(state) {
  const {
    events,
    eventStatus,
    activeCalendars,
    YOUR_TM_checkAll,
    YOUR_TM_indeterminate,

    THIRD_PARTY_checkAll,
    THIRD_PARTY_indeterminate,

    PATENT_checkAll,
    PATENT_indeterminate,
    currentDate,
    start,
    end,
    loading,

    TODO_TASKS,

    TRADEMARK_RENEWALS,
    TRADEMARK_PENDING_REPLIES,
    TRADEMARK_TLA_HEARINGS_IP_INDIA,
    TRADEMARK_TLA_HEARINGS_CAUSE_LIST,
    TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST,
    TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA,
    TRADEMARK_PENDING_REPLIES_DISPATCH_DATE,

    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES,
    STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA,
    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE,

    PATENT_RENEWAL,
    PATENT_HEARING,

    PATENT_PENDING_REPLIES_TO_FER,
    PATENT_PENDING_WORKING_OF_PATENT,
    PATENT_PENDING_STATEMENT_OF_UNDERTAKING,
    PATENT_PENDING_REQUEST_EXAMINATION_NOTICE,

    TODO_TASKSStatus,

    TRADEMARK_RENEWALSStatus,
    TRADEMARK_PENDING_REPLIESStatus,
    TRADEMARK_TLA_HEARINGS_IP_INDIAStatus,
    TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus,
    TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus,
    TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus,
    TRADEMARK_PENDING_REPLIES_DISPATCH_DATEStatus,

    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIESStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIAStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATEStatus,

    PATENT_RENEWALStatus,
    PATENT_HEARINGStatus,

    PATENT_PENDING_REPLIES_TO_FERStatus,
    PATENT_PENDING_WORKING_OF_PATENTStatus,
    PATENT_PENDING_STATEMENT_OF_UNDERTAKINGStatus,
    PATENT_PENDING_REQUEST_EXAMINATION_NOTICEStatus,
  } = state.managerState.calendar;

  return {
    events,
    eventStatus,
    activeCalendars,
    YOUR_TM_checkAll,
    YOUR_TM_indeterminate,

    THIRD_PARTY_checkAll,
    THIRD_PARTY_indeterminate,

    PATENT_checkAll,
    PATENT_indeterminate,
    currentDate,
    start,
    end,
    loading,

    TODO_TASKS,

    TRADEMARK_RENEWALS,
    TRADEMARK_PENDING_REPLIES,
    TRADEMARK_TLA_HEARINGS_IP_INDIA,
    TRADEMARK_TLA_HEARINGS_CAUSE_LIST,
    TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST,
    TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA,
    TRADEMARK_PENDING_REPLIES_DISPATCH_DATE,

    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES,
    STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA,
    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE,

    PATENT_RENEWAL,
    PATENT_HEARING,
    PATENT_PENDING_REPLIES_TO_FER,
    PATENT_PENDING_WORKING_OF_PATENT,
    PATENT_PENDING_STATEMENT_OF_UNDERTAKING,
    PATENT_PENDING_REQUEST_EXAMINATION_NOTICE,

    TODO_TASKSStatus,
    TRADEMARK_RENEWALSStatus,
    TRADEMARK_PENDING_REPLIESStatus,
    TRADEMARK_TLA_HEARINGS_IP_INDIAStatus,
    TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus,
    TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus,
    TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus,
    TRADEMARK_PENDING_REPLIES_DISPATCH_DATEStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIESStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIAStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus,
    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATEStatus,
    PATENT_RENEWALStatus,
    PATENT_HEARINGStatus,
    PATENT_PENDING_REPLIES_TO_FERStatus,
    PATENT_PENDING_WORKING_OF_PATENTStatus,
    PATENT_PENDING_STATEMENT_OF_UNDERTAKINGStatus,
    PATENT_PENDING_REQUEST_EXAMINATION_NOTICEStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarApp);
