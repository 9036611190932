import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import {
  DownloadOutlined,
  ScheduleOutlined,
  UserOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  Card,
  Button,
  Typography,
  Checkbox,
  Select,
  Empty,
  notification,
} from "antd";

//--------MANAGER ACTIONS--------
import {
  getToDoTask,
  deleteToDoTask,
  deleteAllToDoTask,
} from "../../../redux/actions/managerActions/to-do-task/actions";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import CreateTask from "../to-do-task/CreateTask";
import { logOut } from "../../../redux/actions/commonActions/actions";

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

class TodoTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      addTaskVisible: false,
      checkedStatus: false,
      priorityValue: "All",
      deletingKey: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.getToDoTask();
    } else {
      this.props.logOut();
    }
  }

  addTask(data) {
    this.setState({
      addTaskVisible: true,
      editTask: data,
    });
  }

  closeTask() {
    this.setState({
      addTaskVisible: false,
    });
  }

  selectExpiredTask = (e) => {
    this.setState({
      checkedStatus: e.target.checked,
    });
    setTimeout(() => {
      if (this.state.checkedStatus) {
        if (this.state.priorityValue === "All") {
          let taskStatus = "Expired";
          this.props.getToDoTask(taskStatus);
        } else {
          let priorityValue = this.state.priorityValue;
          let taskStatus = "Expired";
          this.props.getToDoTask(taskStatus, priorityValue);
        }
      } else {
        if (this.state.priorityValue === "All") {
          let taskStatus = "Pending";
          this.props.getToDoTask(taskStatus);
        } else {
          let priorityValue = this.state.priorityValue;
          let taskStatus = "Pending";
          this.props.getToDoTask(taskStatus, priorityValue);
        }
      }
    }, 500);
  };

  handleChangePriority = (value) => {
    this.setState({
      priorityValue: value,
    });

    setTimeout(() => {
      if (this.state.checkedStatus) {
        if (this.state.priorityValue === "All") {
          let taskStatus = "Expired";
          this.props.getToDoTask(taskStatus);
        } else {
          let taskStatus = "Expired";
          let priorityValue = this.state.priorityValue;
          this.props.getToDoTask(taskStatus, priorityValue);
        }
      } else {
        if (this.state.priorityValue === "All") {
          let taskStatus = "Pending";
          this.props.getToDoTask(taskStatus);
        } else {
          let priorityValue = this.state.priorityValue;
          let taskStatus = "Pending";
          this.props.getToDoTask(taskStatus, priorityValue);
        }
      }
    }, 500);
  };

  filterColorChange(todoData) {
    if (todoData.status === "Expired") {
      return "expired-task-color";
    } else if (todoData.status === "Pending") {
      if (todoData.priority === "High") {
        return "high-priority-task-color";
      } else if (todoData.priority === "Medium") {
        return "medium-priority-task-color";
      } else if (todoData.priority === "Low") {
        return "low-priority-task-color";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  async deleteTask(todoData) {
    let todo_id = todoData.id;
    this.setState({
      deletingKey: todoData.id,
    });
    await this.props.deleteToDoTask(todo_id);
    if (this.props.deleteDodoTaskStatus === SUCCESS) {
      this.deleteTodoTaskmsg(SUCCESS);
    } else {
      this.deleteTodoTaskmsg(ERROR);
    }
  }

  async deleteAllTask() {
    await this.props.deleteAllToDoTask();
    if (this.props.todoTaskStatus === SUCCESS) {
      this.deleteTodoTaskmsg(SUCCESS);
    } else {
      this.deleteTodoTaskmsg(ERROR);
    }
  }

  downloadTask(todoData) {
    window.open(todoData.ics_file, "_blank");
  }

  deleteTodoTaskmsg(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Delete Todo Task",
          description: "Successfully Deleted Todo Task",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Todo Task Deleted",
          description:
            "Unfortunately, an error occured while Todo Task Deleting. Please, try again later.",
        });
        break;

      default:
        break;
    }
  }

  render() {
    let date = new Date().toLocaleDateString();
    return (
      <div>
        <Layout className="Todo-List">
          <Layout>
            <Content style={{ margin: "0px" }}>
              <Row
                className="content-container vertical_scroll"
                style={{
                  height: `${
                    this.props.managerAlert.length > 0
                      ? "calc(100vh - 40px)"
                      : "calc(100vh - 0px)"
                  }`,
                }}
              >
                <Col span={24}>
                  <Card
                    title={
                      <div>
                        <Row>
                          <Col span={8}>
                            <Title level={4} style={{ margin: "0px" }}>
                              TO-DO LIST
                            </Title>
                          </Col>
                          <Col
                            span={16}
                            className="alignR"
                            style={{
                              display: "inline-flex",
                              justifyContent: "end",
                            }}
                          >
                            <Button
                              disabled={
                                this.props?.todoTask?.data?.length === 0
                              }
                              style={{
                                backgroundColor: "#FDE5E7",
                                color: "red",
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                              danger
                              onClick={() => this.deleteAllTask()}
                            >
                              <DeleteOutlined
                                style={{ fontSize: "18px", color: "red" }}
                              />
                              Delete All Task
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => this.addTask()}
                            >
                              <i className="fa fa-plus" aria-hidden="true" />
                              <Text
                                style={{ color: "#fff", marginLeft: "5px" }}
                              >
                                ADD NEW TASK
                              </Text>
                            </Button>
                          </Col>
                        </Row>
                        <Row justify="space-between" className="m-top-10">
                          <Col span={16}>
                            <ul className="task-priority-list">
                              <li style={{ paddingLeft: "0px" }}>
                                <span className="task-priority-box expired-task-color"></span>
                                <Text strong className="task-priority-text">
                                  Expired Task
                                </Text>
                              </li>
                              <li>
                                <span className="task-priority-box high-priority-task-color"></span>
                                <Text strong className="task-priority-text">
                                  High Priority Task
                                </Text>
                              </li>
                              <li>
                                <span className="task-priority-box medium-priority-task-color"></span>
                                <Text strong className="task-priority-text">
                                  Medium Priority Task
                                </Text>
                              </li>
                              <li>
                                <span className="task-priority-box low-priority-task-color"></span>
                                <Text strong className="task-priority-text">
                                  Low Priority Task
                                </Text>
                              </li>
                            </ul>
                          </Col>
                          <Col>
                            <ul className="task-priority-list">
                              <li>
                                <Checkbox
                                  checked={this.state.checkedStatus}
                                  onChange={this.selectExpiredTask}
                                >
                                  Show Expired Task
                                </Checkbox>
                              </li>
                              <li style={{ paddingRight: "0px" }}>
                                <Text strong>Sort By</Text>
                                <Select
                                  defaultValue="All"
                                  style={{
                                    width: 120,
                                    padding: "0px 0px 0px 10px",
                                  }}
                                  onChange={this.handleChangePriority}
                                >
                                  <Option value="All">All</Option>
                                  <Option value="High">High</Option>
                                  <Option value="Medium">Medium</Option>
                                  <Option value="Low">Low</Option>
                                </Select>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    }
                    headStyle={{ padding: "16px" }}
                    bodyStyle={{
                      padding: "15px",
                      height: "74vh",
                      overflow: "auto",
                    }}
                    loading={
                      this.props.todoTaskStatus === LOADING ? true : false
                    }
                  >
                    <div>
                      <Row gutter={16}>
                        {this.props.todoTask &&
                          this.props.todoTask.data.length > 0 &&
                          this.props.todoTask.data.map((todoData) => (
                            <Col
                              span={8}
                              key={todoData.id}
                              style={{ padding: "10px" }}
                            >
                              <Card
                                title={
                                  <div
                                    className={this.filterColorChange(todoData)}
                                    style={{ height: "10px" }}
                                  />
                                }
                                className="to-do-list-card"
                                bodyStyle={{
                                  padding: "10px",
                                  cursor: "default",
                                }}
                                hoverable
                                loading={
                                  this.state.deletingKey === todoData.id &&
                                  this.props.deleteDodoTaskStatus === LOADING
                                    ? true
                                    : false
                                }
                              >
                                <div>
                                  <Col span={24}>
                                    <Col span={12} className="alignL pad-0">
                                      <i
                                        className="fa fa-trash-o cursor_pointer"
                                        aria-hidden="true"
                                        onClick={() =>
                                          this.deleteTask(todoData)
                                        }
                                        style={{
                                          color: "#ff0000",
                                          fontSize: "20px",
                                        }}
                                      ></i>
                                    </Col>
                                    <Col span={12} className="alignR pad-0">
                                      {date <
                                      todoData?.local_time?.slice(0, 10) ? (
                                        <DownloadOutlined
                                          style={{
                                            color: "#4285f4",
                                            fontSize: "20px",
                                          }}
                                          onClick={() =>
                                            this.downloadTask(todoData)
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Col>
                                  <Col span={24}>
                                    <Title
                                      level={4}
                                      style={{
                                        margin: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.addTask(todoData)}
                                    >
                                      {checkValue(todoData.title)
                                        ? todoData.title
                                        : ""}
                                    </Title>
                                  </Col>
                                  <Col span={24}>
                                    <Text strong>
                                      {checkValue(todoData.description)
                                        ? todoData.description
                                        : ""}
                                    </Text>
                                  </Col>
                                  <Col span={24} className="m-top-10">
                                    <Text>
                                      <ScheduleOutlined
                                        style={{
                                          paddingRight: "5px",
                                          fontSize: "20px",
                                        }}
                                      />
                                      <label>Expired on : </label>
                                      <span>
                                        {" "}
                                        {checkValue(todoData.local_time)
                                          ? todoData.local_time
                                          : ""}
                                      </span>
                                    </Text>
                                  </Col>
                                  <Col span={24} className="m-top-10">
                                    <Text>
                                      <UserOutlined
                                        style={{
                                          paddingRight: "5px",
                                          fontSize: "20px",
                                        }}
                                      />
                                      <label>Created By : </label>
                                      <span>
                                        {" "}
                                        {checkValue(todoData.created_by.email)
                                          ? todoData.created_by.email
                                          : ""}
                                      </span>
                                    </Text>
                                  </Col>
                                </div>
                              </Card>
                            </Col>
                          ))}
                        {this.props.todoTask &&
                          this.props.todoTask.data.length === 0 && (
                            <Col span={24} className="alignC">
                              <Empty />
                            </Col>
                          )}
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.closeTask()}
          editTask={this.state.editTask}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getToDoTask,
  deleteToDoTask,
  deleteAllToDoTask,
  logOut,
};

function mapStateToProps(state) {
  const {
    todoTask,
    todoTaskStatus,
    addTodoTask,
    addTodoTaskStatus,
    deleteDodoTaskStatus,
  } = state.managerState.todoTaskState;
  return deepFreeze({
    todoTask,
    todoTaskStatus,
    addTodoTask,
    addTodoTaskStatus,
    deleteDodoTaskStatus,

    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoTask);
