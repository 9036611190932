import { BulbOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Modal,
  notification,
  Upload,
  Typography,
  Popconfirm,
  Collapse,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBulkClientMasterData } from "../../../../../redux/actions/commonActions/masterActions/clientMaster";
import ClientBulkErrorScreen from "./ClientBulkErrorScreen";

const { Dragger } = Upload;
const { Panel } = Collapse;
const { Link } = Typography;

const sample_excel_file_add =
  "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ClientMastetBulkAdd.xlsx";

const sample_excel_file_edit_client_masters =
  "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ClientMastetBulkEdit.xlsx";

const sample_excel_file_replace_contact_persons =
  "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/ClientMasterBulkReplaceContactPerson.xlsx";

const ClientBulkUpload = ({ open, setOpen, filters, type }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [openKnowMore, setOpenKnowMore] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [warningList, setWarningList] = useState([]);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  const [openDetailListPopUp, setOpenDetailListPopUp] = useState(false);
  const [detailList, setDetailList] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  const { metadata } = useSelector(
    (state) => state.masters.clientMaster.listData,
  );

  const { pageNumber } = metadata;

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const getSampleExcel = () => {
    switch (type) {
      case "add":
        return sample_excel_file_add;

      case "edit_client_masters":
        return sample_excel_file_edit_client_masters;

      case "replace_contact_persons":
        return sample_excel_file_replace_contact_persons;

      default:
        return sample_excel_file_add;
    }
  };

  const getFileType = () => {
    switch (type) {
      case "add":
        return "ALL";

      case "edit_client_masters":
        return "CLIENT";

      case "replace_contact_persons":
        return "CONTACT";

      default:
        return "ALL";
    }
  };

  const handleSubmit = (values) => {
    setLoading(true);
    if (!file) {
      notification["error"]({
        message: "Please upload a file!",
      });
      return;
    }
    const formData = new FormData();
    formData.append("force_update", isForceUpdate);
    formData.append("bulk_excel_file", file);
    formData.append("bulk_choice", getFileType());
    dispatch(
      addBulkClientMasterData(formData, pageNumber, filters, {
        onSuccess: (successResponse) => {
          onClose();
          setLoading(false);
          notification["success"]({
            message: successResponse?.message ?? "File Uploaded Successfully!",
          });
        },
        onError: (errorResponse) => {
          setLoading(false);
          notification["error"]({
            message: errorResponse?.message ?? "Something went wrong!",
          });
          if (errorResponse?.payload?.error_list?.length) {
            setDisableButton(true);
          }
          if (
            errorResponse?.payload?.error_list?.length === 0 &&
            errorResponse?.payload?.warning_list?.length
          ) {
            setIsForceUpdate(true);
          }
          errorResponse?.payload?.error_list?.length
            ? setErrorList(errorResponse?.payload?.error_list)
            : setErrorList([]);
          errorResponse?.payload?.warning_list?.length
            ? setWarningList(errorResponse?.payload?.warning_list)
            : setWarningList([]);
        },
      }),
    );
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      setFile(file);
      setIsForceUpdate(false);
      setDisableButton(false);
      return false;
    },
    fileList: file ? [file] : [],
    onRemove: () => {
      setFile(null);
    },
    accept: ".xls,.xlsx",
  };

  const linkStyle = {
    color: "#007bff",
    fontSize: "12px",
    textDecoration: "underline",
  };

  const addHowItWorks = (
    <div>
      <ul>
        <li>Download Sample Excel and fill your data in it.</li>
        <li>Here you can add Client details with contact person details.</li>
        <li>
          Maximum 5 contact person with a client can be added through this
          process.
        </li>
      </ul>
    </div>
  );

  const editHowItWorks = (
    <div>
      <ul>
        <li>Download Sample Excel.</li>
        <li>Update the Excel with the details in the given format.</li>
        <li>Upload the Excel to edit client details in MikeLegal.</li>
      </ul>
    </div>
  );

  const replaceContactPersonsHowItWorks = (
    <div>
      <ul>
        <li>Download Sample Excel.</li>
        <li>Update the Excel with the details in the given format.</li>
        <li>Upload the Excel to replace contact persons in MikeLegal.</li>
      </ul>
    </div>
  );

  const showHowItWorksDescription = () => {
    switch (type) {
      case "add":
        return addHowItWorks;

      case "edit_client_masters":
        return editHowItWorks;

      case "replace_contact_persons":
        return replaceContactPersonsHowItWorks;

      default:
        return addHowItWorks;
    }
  };

  const getFormTitle = () => {
    switch (type) {
      case "add":
        return "Bulk Add";

      case "edit_client_masters":
        return "Edit Client Masters";

      case "replace_contact_persons":
        return "Replace Contact Persons";
      default:
        return "Bulk Add";
    }
  };

  const errorDescription = (
    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
      <ul>
        {errorList.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );

  const warningDescription = (
    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
      <ul>
        {warningList.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );

  const customErrorPanelStyle = {
    backgroundColor: "#FFF2F0",
    border: "1px solid red",
  };

  const customErrorHeaderStyle = {
    color: "red",
  };

  const customWarningPanelStyle = {
    backgroundColor: "#FFF6E6",
    border: "1px solid #FFA700",
  };

  const customWarningHeaderStyle = {
    color: "#B57701",
  };

  const handleViewInLargeScreenClicked = (e, type) => {
    e.stopPropagation();
    setOpenDetailListPopUp(true);
    switch (type) {
      case "error":
        {
          setDetailList({
            type: "error",
            heading: "Errors in current excel.",
            list: errorList,
          });
        }
        break;
      case "warning":
        {
          setDetailList({
            type: "warning",
            heading: "Warnings in current excel.",
            list: warningList,
          });
        }
        break;
      default:
        return null;
    }
  };

  const loadingArea = (
    <div style={{ ...customErrorPanelStyle, padding: "10px" }}>
      <div style={customErrorHeaderStyle}>
        We are validating your request. Please do not close this window or
        refresh this page.
      </div>
    </div>
  );

  const errorArea = (
    <div style={{ margin: "10px 0px" }}>
      <Collapse
        defaultActiveKey={errorList?.length ? "1" : "0"}
        expandIconPosition={"start"}
      >
        <Panel
          header={
            <div style={customErrorHeaderStyle}>Errors in current excel.</div>
          }
          key="1"
          extra={
            <div style={customErrorHeaderStyle}>
              {errorList?.length ?? 0}{" "}
              {errorList?.length ? (
                <Link
                  onClick={(event) =>
                    handleViewInLargeScreenClicked(event, "error")
                  }
                  style={{ color: "blue" }}
                >
                  ( View in large screen )
                </Link>
              ) : null}
            </div>
          }
          style={customErrorPanelStyle}
        >
          {errorDescription}
        </Panel>
      </Collapse>
    </div>
  );

  const warningArea = (
    <div style={{ margin: "10px 0px" }}>
      <Collapse
        defaultActiveKey={!errorList?.length && warningList?.length ? "1" : "0"}
        expandIconPosition={"start"}
      >
        <Panel
          header={
            <div style={customWarningHeaderStyle}>
              Warnings in current excel.
            </div>
          }
          key="1"
          extra={
            <div style={customWarningHeaderStyle}>
              {warningList?.length ?? 0}{" "}
              {warningList?.length ? (
                <Link
                  onClick={(event) =>
                    handleViewInLargeScreenClicked(event, "warning")
                  }
                  style={{ color: "blue" }}
                >
                  ( View in large screen )
                </Link>
              ) : null}
            </div>
          }
          style={customWarningPanelStyle}
        >
          {warningDescription}
        </Panel>
      </Collapse>
    </div>
  );

  const propsForErrorScreen = {
    data: detailList,
    open: openDetailListPopUp,
    setOpen: setOpenDetailListPopUp,
  };

  const replaceWarningArea = (
    <div
      style={{ ...customWarningPanelStyle, padding: "10px", marginTop: "10px" }}
    >
      <div style={customWarningHeaderStyle}>
        <b>Warning :</b> Previous Contact Persons Details will be replaced by
        New Contact Person Details.
      </div>
    </div>
  );
  return (
    <>
      {openDetailListPopUp && (
        <ClientBulkErrorScreen {...propsForErrorScreen} />
      )}
      <Modal
        title={
          <>
            <>{getFormTitle()}</>&nbsp;
            <Popconfirm
              open={openKnowMore}
              title="How it works!"
              description={showHowItWorksDescription()}
              cancelButtonProps={{
                style: {
                  display: "none",
                },
              }}
              okText="Got it"
              onConfirm={() => setOpenKnowMore(false)}
              icon={
                <BulbOutlined
                  style={{
                    color: "#007bff",
                  }}
                />
              }
            >
              <a onClick={() => setOpenKnowMore(true)} style={linkStyle}>
                How it works!
              </a>
            </Popconfirm>
          </>
        }
        centered
        open={open}
        onCancel={loading ? null : onClose}
        footer={[
          <Button
            key="cancel"
            disabled={loading}
            type="default"
            onClick={onClose}
          >
            Cancel
          </Button>,
          <Button
            loading={loading}
            key="submit"
            type="primary"
            disabled={!file || disableButton}
            onClick={() => form.submit()}
          >
            {type === "add" ? "Add" : "Update"}
          </Button>,
        ]}
        bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
        width={800}
        closable={!loading}
      >
        <Form
          form={form}
          id="bulk-upload"
          layout="vertical"
          style={{ margin: "15px 10px" }}
          onFinish={handleSubmit}
          initialValues={""}
        >
          <Form.Item
            name="attachment"
            valuePropName="file"
            getValueFromEvent={(e) => e.file}
            rules={[
              {
                required: true,
                message: "Please upload a file!",
                validator: (_, value) =>
                  file
                    ? Promise.resolve()
                    : Promise.reject("Please upload a file!"),
              },
            ]}
          >
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Please read instruction first by clicking on How it works.
              </p>
            </Dragger>
            <div style={{ marginTop: "10px" }}>
              <a href={getSampleExcel()}>Download Sample Excel</a>
            </div>
            {type === "replace_contact_persons" ? replaceWarningArea : null}
            {loading ? loadingArea : null}
            {errorList?.length ? errorArea : null}
            {warningList?.length ? warningArea : null}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ClientBulkUpload;
