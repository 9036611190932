//--------Patent Default Selected Filters--------
export const Patent_DefaultSelectedFilters = {
    applicant: [],

    inventor: [],
    applicationType: [],
    fieldOfInvention: [],
    applicationStatus: [],
    legalStatus: [],
    classificationIPC: [],
    priorityCountry: [],
    filingCountry: [],
    patentTags:[],
    patentNoTags:[],
    containsTerm: '',
}

//--------Patent Default Search Filters--------
export const Patent_DefaultSearchFilters = {
    applicantSearch: '',
    tagSearch:'',
    NotagSearch:'',
    inventorSearch: '',
    applicationTypeSearch: '',
    fieldOfInventionSearch: '',
    applicationStatusSearch: '',
    legalStatusSearch: '',
    classificationIPCSearch: '',
    priorityCountrySearch: '',
    filingCountrySearch: ''
}