import {
  Col,
  Row,
  Space,
  Button,
  Badge,
  Grid,
  Tooltip,
  Radio,
  Checkbox,
  Menu,
  Dropdown,
} from "antd";
import React from "react";
import {
  FilterFilled,
  BookFilled,
  PlaySquareOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import JournalFilter from "../JournalFilter";
import SearchTypeDropdown from "../SearchTypeDropdown";
import MenuFilter from "../MenuFilter";
import CheckableTagComponent from "./../CheckableTagComponent";
import MultipleCheckboxComponents from "./../MultipleCheckboxComponents";
import TwoLayoutMenuFilter from "./TwoLayoutFilters/TwoLayoutMenuFilters";
import { useSelector, useDispatch } from "react-redux";
import {
  addListSampleData,
  getListBookmark,
} from "../../../../../redux/actions/watchActions/listDashboardActions";
import { getListLayoutData } from "../../../../../redux/actions/watchActions/listDashboardActions";
import CreateReportDrawer from "./CreateReportDrawer";
import UpdateShortlistedComponent from "./UpdateShortlistedComponent";
import {
  default_filter_page,
  default_filter_page_size,
  date_format,
} from "../../utils/utils";
import { logOut } from "../../../../../redux/actions/commonActions/actions";
import dayjs from "dayjs";
import { LOADING } from "../../../../../constants/commonConstants/generalConstants";
import {
  getWatchJournal,
  getWatchAllFilters,
  changeTemplateId,
  createCombinePdf,
  changeFilterApplied,
  resetAllFilter,
  getWatchBrandAnalysis,
} from "../../../../../redux/actions/watchActions/watchActions";
import { W_JOURNAL_DASHBOARD_TYPE } from "../../../../../redux/actions/watchActions/types";
import MoreFilterDrawer from "./moreFilterSection/MoreFilterDrawer";
import ProprietorFilterComponent from "../ProprietorFilterComponent";
import SelectFilter from "../SelectFilter";
import DateFilter from "../DateFilter";
import { listSampleObject } from "../../../Pages/ListLayoutDashboard/listSampleObject";
import AdvanceCombinePDF from "../AdvanceCombinePDF";

const { useBreakpoint } = Grid;

const ListFilter = (props) => {
  let urlKey = window.location.pathname.split("/")[2];
  let dashboardLayout = window.location.pathname.split("/")[3] || "";
  const [localJournal, setLocalJournal] = React.useState(0);
  const [goToBookmarkClicked, setGoToBookmarkClicked] = React.useState(false);
  const [openBulkTemplateDownload, setOpenBulkTemplateDownload] =
    React.useState(false);

  const getPrimaryFilterPath = () => {
    let templatePath = "applied/list_layout";

    if (urlKey === "tm_applied") {
      templatePath = "applied/list_layout";
    } else if (urlKey === "tm_protect") {
      templatePath = "tm_protect/list_layout";
    } else if (urlKey === "image_text" || urlKey === "image_text_new") {
      templatePath = "image_text/list_layout";
    } else if (urlKey === "image_label") {
      templatePath = "image_label/list_layout";
    } else if (urlKey === "journals") {
      templatePath = "journals/dashboard";
    } else if (urlKey === "brand_compare") {
      templatePath = "brand_compare/list_layout";
    } else if (urlKey === "image_similarity") {
      templatePath = "image_similarity/list_layout";
    } else {
      templatePath = "applied/list_layout";
    }
    return templatePath;
  };

  const getDashboardForJounral = () => {
    let templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_APPLIED;

    if (urlKey === "tm_applied") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_APPLIED;
    } else if (urlKey === "tm_protect") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_PROTECT;
    } else if (urlKey === "image_text") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_IMAGE_TEXT;
    } else if (urlKey === "image_label") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_IMAGE_LABEL;
    } else if (urlKey === "journals") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.JOURNALS;
    } else if (urlKey === "image_text_new") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_IMAGE_TEXT_NEW;
    } else if (urlKey === "image_similarity") {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.IMAGE_SIMILARITY;
    } else {
      templatePath = W_JOURNAL_DASHBOARD_TYPE.TM_APPLIED;
    }
    return templatePath;
  };

  const getCreationOrigin = () => {
    let imageLabel = "IMAGE_LABEL";

    if (urlKey === "tm-applied" || urlKey === "tm_applied") {
      imageLabel = "APPLIED_FOR";
    } else if (urlKey === "tm_protect") {
      imageLabel = "TM_PROTECT";
    } else if (urlKey === "image_text" || urlKey === "image_text_new") {
      imageLabel = "IMAGE_TEXT";
    } else if (urlKey === "image_label") {
      imageLabel = "IMAGE_LABEL";
    } else if (urlKey === "journals") {
      imageLabel = "JOURNALS_DASHBOARD";
    } else if (urlKey === "brand_compare") {
      imageLabel = "BRAND_COMPARE";
    } else if (urlKey === "image_similarity") {
      imageLabel = "IMAGE_SIMILARITY";
    } else {
      imageLabel = "IMAGE_LABEL";
    }
    return imageLabel;
  };

  const {
    tmTypes,
    setTMTypes,
    threatTMTypes,
    setThreatTMTypes,
    tmCategories,
    setTMCategories,
    threatTMCategories,
    setThreatTMCategories,
    tmOffices,
    setTMOffices,
    threatTMOffices,
    setThreatTMOffices,
    infringement,
    setInfringement,
    journal,
    setJournal,
    searchType,
    setSearchType,
    searchValue,
    setSearchValue,
    excludeReported,
    setExcludeReported,
    shortlisted,
    setShortlisted,
    showDescription,
    setShowDescription,
    setBookmark,
    selected,
    setSelected,
    threatAttorneyValue,
    proprietors,
    threatProprietors,
    tags,
    tmClass,
    threatTMClass,
    similarity,
    yourDateOfApplication,
    threatDateOfApplication,
    yourDateOfUsage,
    threatDateOfUsage,
    yourDateOfUsageProposed,
    threatDateOfUsageProposed,
    noTags,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    setPublishedDate,
    setTMClass,
    setThreatClass,
    setSimilarity,
    setYourDateOfApplication,
    setThreatProprietors,
    setProprietors,
    setThreatAttorneyValue,
    excludeMarkProprietors,
    setExcludeMarkProprietors,
    excludeThreatProprietors,
    setExcludeThreatProprietors,
    setTags,
    setThreatDateOfApplication,
    setYourDateOfUsage,
    setThreatDateOfUsage,
    setYourDateOfUsageProposed,
    setNoTags,
    setThreatDateOfUsageProposed,
    excludeShortlisted,
    setExcludeShortlisted,
    selectAll,
    setSelectAll,
    filterApplied,
    setFilterApplied,
    andOrTags,
    setAndOrTags,
    andOrClass,
    setAndOrClass,
    andOrThreatClass,
    setAndOrThreatClass,
    isAnyFilterSelected,
    openTour3,
    openTour4,
    isTourOpen,
    setIsTourOpen,
    setSelectedRowKeys,
    setSelectedRows,
    dataView,
    setDataView,
    imageTextPercentage,
    setImageTextPercentage,
    threatImageTextPercentage,
    setThreatImageTextPercentage,
  } = props;

  React.useEffect(() => {
    if (goToBookmarkClicked) {
      // to update the store from states, after clicking go to bookmark -> switch pages and states should remain same.
      props?.updateReduxStore();
    }
  }, [goToBookmarkClicked]);

  const dispatch = useDispatch();

  const { current_template_id } = useSelector(
    (state) => state.watchState.watchTemplate[dashboardLayout][urlKey]?.config,
  );

  const {
    journalConfig,
    markTypeConfig,
    threatTypeConfig,
    markCategoryConfig,
    threatCategoryConfig,
    markOfficeConfig,
    threatOfficeConfig,
    infringementConfig,
    reduxSearchValue,
    reduxSearchType,
    isFilterApplied,
    yourDateOfApplicationConfig,
    yourDateOfUsageConfig,
    markproprietorConfig,
    threatproprietorConfig,
    markClassConfig,
    threatClassConfig,
    tagsConfig,
    threatDateOfApplicationConfig,
    threatDateOfUsageConfig,
    threatAttorneyConfig,
    excludeReportedConfig,
    shortlistedConfig,
    excludeShortlistedConfig,
    sameClassInfringementConfig,
    excludeSameClassInfringementConfig,
    similarityConfig,
    ownBrandClassConfig,
    threatBrandClassConfig,
    ownBrandProprietorConfig,
    threatBrandProprietorConfig,
    imageTextPercentageConfig,
    threatImageTextPercentageConfig,
  } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey]?.config,
  );

  const { isFiltersRendered, isJournalRendered } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey],
  );

  const { isComponentRendered } = useSelector(
    (state) => state.watchState.listLayoutData[dashboardLayout][urlKey],
  );

  const {
    tableData,
    isTableDataPresent,
    report_columns,
    bookmarkTemplate,
    status,
    pageNumber,
    combinePdf,
    journalNumber,
  } = useSelector(
    (state) => state.watchState.listLayoutData[dashboardLayout][urlKey]?.config,
  );

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      if (journalNumber?.[urlKey] === null && !isJournalRendered) {
        if (urlKey === "brand_compare") {
          dispatch(getWatchBrandAnalysis());
        } else {
          dispatch(getWatchJournal(getDashboardForJounral()));
        }
      }
    } else {
      dispatch(logOut());
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      if (!isComponentRendered) {
        setSelectAll(false);
        setSelected([]);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        let currentJournal = journalNumber?.[urlKey];
        let journalProccessed = false;
        if (currentJournal === null) {
          currentJournal = journalConfig?.data[0]?.value;
          journalProccessed = journalConfig?.data[0]?.meta_data?.processing;
        }
        if (
          currentJournal &&
          currentJournal !== "" &&
          currentJournal !== undefined
        ) {
          if (journalProccessed) {
            dispatch(getListLayoutData(currentJournal, 1, false, {}));
            dispatch(
              getListBookmark(currentJournal, `${getPrimaryFilterPath()}/`),
            );
          }
        }
      }
    } else {
      dispatch(logOut());
    }
  }, [journalConfig?.data?.length]);

  const fetchFilters = () => {
    let dashboardUrl = getPrimaryFilterPath();
    const markKeys = [
      "mark_proprietors",
      "mark_classes",
      "mark_types",
      "tags",
      "mark_categories",
      "mark_offices",
    ];
    let threatKeys = [
      "threat_proprietors",
      "threat_classes",
      "threat_types",
      "threat_categories",
      "threat_offices",
      "threat_attorney",
      "threat_sources",
    ];

    let brandCompareFilterKeys = [
      "own_brand_classes",
      "threat_brand_classes",
      "threat_brand_proprietors",
      "own_brand_proprietors",
    ];

    if (urlKey === "journals" || urlKey === "image_similarity") {
      threatKeys = threatKeys.filter((item) => item !== "threat_sources");
    }
    const filterKeys =
      urlKey === "tm_protect" || urlKey === "journals"
        ? [...threatKeys]
        : urlKey === "brand_compare"
          ? [...brandCompareFilterKeys]
          : [...markKeys, ...threatKeys];

    filterKeys.map((item) =>
      dispatch(
        getWatchAllFilters(
          journal,
          item,
          null,
          default_filter_page,
          default_filter_page_size,
          current_template_id,
          dashboardUrl,
          0, // application number not needed for your list filter
        ),
      ),
    );
  };

  React.useEffect(() => {
    // first time rendered when jounral should be there
    if (journal && !isFiltersRendered) {
      const journalDetail = journalConfig.data.find(
        (item) => String(item.value) === String(journal),
      );
      if (journalDetail.meta_data.processing) {
        fetchFilters();
      }
    }
  }, [journal]);

  React.useEffect(() => {
    // when journal change from dropdown
    if (localJournal && isFiltersRendered) {
      fetchFilters();
    }
  }, [localJournal]);

  React.useEffect(() => {
    // filters api update when using template
    current_template_id && fetchFilters();
  }, [current_template_id]);

  const getStateFromRedux = () => {
    const getIdsFromArrayOfObject = (values, functionName = "Number") => {
      return values.length
        ? values.map((item) =>
            functionName === "String" ? String(item.value) : Number(item.value),
          )
        : [];
    };
    setSearchType(reduxSearchType);
    setSearchValue(reduxSearchValue);
    setFilterApplied(isFilterApplied);
    markTypeConfig?.selectedValues?.length
      ? setTMTypes(
          getIdsFromArrayOfObject(markTypeConfig?.selectedValues, "String"),
        )
      : setTMTypes([]);
    threatTypeConfig?.selectedValues?.length
      ? setThreatTMTypes(
          getIdsFromArrayOfObject(threatTypeConfig?.selectedValues, "String"),
        )
      : setThreatTMTypes([]);
    markCategoryConfig?.selectedValues?.length
      ? setTMCategories(
          getIdsFromArrayOfObject(markCategoryConfig?.selectedValues, "String"),
        )
      : setTMCategories([]);
    threatCategoryConfig?.selectedValues?.length
      ? setThreatTMCategories(
          getIdsFromArrayOfObject(
            threatCategoryConfig?.selectedValues,
            "String",
          ),
        )
      : setThreatTMCategories([]);
    markOfficeConfig?.selectedValues?.length
      ? setTMOffices(
          getIdsFromArrayOfObject(markOfficeConfig?.selectedValues, "String"),
        )
      : setTMOffices([]);
    threatOfficeConfig?.selectedValues?.length
      ? setThreatTMOffices(
          getIdsFromArrayOfObject(threatOfficeConfig?.selectedValues, "String"),
        )
      : setThreatTMOffices([]);
    infringementConfig?.selectedValues?.length
      ? setInfringement(
          getIdsFromArrayOfObject(infringementConfig?.selectedValues, "String"),
        )
      : setInfringement([]);
    setExcludeReported(excludeReportedConfig);
    setShortlisted(shortlistedConfig);
    setExcludeShortlisted(excludeShortlistedConfig);
    threatAttorneyConfig?.selectedValues?.length
      ? setThreatAttorneyValue(
          getIdsFromArrayOfObject(threatAttorneyConfig?.selectedValues),
        )
      : setThreatAttorneyValue([]);
    markproprietorConfig?.selectedValues?.length
      ? setProprietors(
          getIdsFromArrayOfObject(markproprietorConfig?.selectedValues),
        )
      : setProprietors([]);
    threatproprietorConfig?.selectedValues?.length
      ? setThreatProprietors(
          getIdsFromArrayOfObject(threatproprietorConfig?.selectedValues),
        )
      : setThreatProprietors([]);
    markproprietorConfig?.excludeValues?.length
      ? setExcludeMarkProprietors(
          getIdsFromArrayOfObject(markproprietorConfig?.excludeValues),
        )
      : setExcludeMarkProprietors([]);
    threatproprietorConfig?.excludeValues?.length
      ? setExcludeThreatProprietors(
          getIdsFromArrayOfObject(threatproprietorConfig?.excludeValues),
        )
      : setExcludeThreatProprietors([]);
    markClassConfig?.selectedValues?.length
      ? setTMClass(getIdsFromArrayOfObject(markClassConfig?.selectedValues))
      : setTMClass([]);
    threatClassConfig?.selectedValues?.length
      ? setThreatClass(
          getIdsFromArrayOfObject(threatClassConfig?.selectedValues),
        )
      : setThreatClass([]);
    setSimilarity(similarityConfig.value);
    yourDateOfApplicationConfig?.value?.from &&
      setYourDateOfApplication(yourDateOfApplicationConfig?.value);
    threatDateOfApplicationConfig?.value?.from &&
      setThreatDateOfApplication(threatDateOfApplicationConfig?.value);
    yourDateOfUsageConfig?.value?.from &&
      setYourDateOfUsage(yourDateOfUsageConfig?.value);
    threatDateOfUsageConfig?.value?.from &&
      setThreatDateOfUsage(threatDateOfUsageConfig?.value);
    setYourDateOfUsageProposed(yourDateOfUsageConfig?.dateOfUsageProposed);
    setThreatDateOfUsageProposed(threatDateOfUsageConfig?.dateOfUsageProposed);
    setAndOrClass(markClassConfig.andOr);
    setAndOrThreatClass(threatClassConfig.andOr);
    tagsConfig?.selectedValues?.length
      ? setTags(getIdsFromArrayOfObject(tagsConfig?.selectedValues))
      : setTags([]);
    setAndOrTags(tagsConfig.andOr);
    setNoTags(tagsConfig.noTags);
    setSameClassInfringement(sameClassInfringementConfig);
    setExcludeSameClassInfringement(excludeSameClassInfringementConfig);

    if (urlKey === "image_similarity") {
      setImageTextPercentage(imageTextPercentageConfig.value);
      setThreatImageTextPercentage(threatImageTextPercentageConfig.value);
    }

    if (urlKey === "brand_compare") {
      ownBrandProprietorConfig?.selectedValues?.length
        ? setProprietors(
            getIdsFromArrayOfObject(ownBrandProprietorConfig?.selectedValues),
          )
        : setProprietors([]);
      threatBrandProprietorConfig?.selectedValues?.length
        ? setThreatProprietors(
            getIdsFromArrayOfObject(
              threatBrandProprietorConfig?.selectedValues,
            ),
          )
        : setThreatProprietors([]);
      ownBrandProprietorConfig?.excludeValues?.length
        ? setExcludeMarkProprietors(
            getIdsFromArrayOfObject(ownBrandProprietorConfig?.excludeValues),
          )
        : setExcludeMarkProprietors([]);
      threatBrandProprietorConfig?.excludeValues?.length
        ? setExcludeThreatProprietors(
            getIdsFromArrayOfObject(threatBrandProprietorConfig?.excludeValues),
          )
        : setExcludeThreatProprietors([]);
      ownBrandClassConfig?.selectedValues?.length
        ? setTMClass(
            getIdsFromArrayOfObject(ownBrandClassConfig?.selectedValues),
          )
        : setTMClass([]);
      threatBrandClassConfig?.selectedValues?.length
        ? setThreatClass(
            getIdsFromArrayOfObject(threatBrandClassConfig?.selectedValues),
          )
        : setThreatClass([]);
      setAndOrClass(ownBrandClassConfig?.andOr);
      setAndOrThreatClass(threatBrandClassConfig?.andOr);
    }
  };

  React.useEffect(() => {
    // to fetch data from redux when switching page
    if (isFiltersRendered) {
      getStateFromRedux();
    }
  }, []);

  const [openMoreFilters, setOpenMoreFilters] = React.useState(false);
  const [openCreateReport, setOpenCreateReport] = React.useState(false);

  const screenWidth = useBreakpoint();

  let listOfSearchType = [
    "Application No/Name",
    "Your Mark Description Search",
    "Your Threat Description Search",
  ];

  let listOfSearchForThreatDetails = [
    "Application No/Name",
    "Your Threat Description Search",
  ];

  let listOfSearchForJournalDetails = [
    "Application No/Name",
    "Description Search",
  ];

  let listOfSearchForBrandCompare = ["Application No/Name"];

  const getRealSearchParameters = () => {
    const defaultSearchParameters = {
      contains_term: {
        value: null,
      },
      mark_desc_contains_term: {
        value: null,
      },
      threat_desc_contains_term: {
        value: null,
      },
    };

    switch (searchType) {
      case "Application No/Name":
        return Object.assign({}, defaultSearchParameters, {
          contains_term: {
            value: searchValue,
          },
        });
      case "Your Mark Description Search":
        return Object.assign({}, defaultSearchParameters, {
          mark_desc_contains_term: {
            value: searchValue,
          },
        });
      case "Your Threat Description Search":
        return Object.assign({}, defaultSearchParameters, {
          threat_desc_contains_term: {
            value: searchValue,
          },
        });
      case "Description Search":
        return Object.assign({}, defaultSearchParameters, {
          threat_desc_contains_term: {
            value: searchValue,
          },
        });
      default:
        return defaultSearchParameters;
    }
  };

  const generateFilterObject = () => {
    const filters = {
      tags: {
        value: tags,
        logic: andOrTags ? "AND" : "OR",
      },
      no_tags: {
        value: noTags,
      },
      mark_classes: {
        value: tmClass,
        logic: andOrClass ? "AND" : "OR",
      },
      threat_classes: {
        value: threatTMClass,
        logic: andOrThreatClass ? "AND" : "OR",
      },
      threat_sources: {
        value: infringement,
      },
      threat_similarity: {
        value: {
          to: similarity.to,
          from: similarity.from,
        },
      },
      mark_types: {
        value: tmTypes,
      },
      threat_types: {
        value: threatTMTypes,
      },
      mark_proprietors: {
        value: proprietors,
      },
      threat_proprietors: {
        value: threatProprietors,
      },
      mark_categories: {
        value: tmCategories,
      },
      threat_categories: {
        value: threatTMCategories,
      },
      mark_offices: {
        value: tmOffices,
      },
      threat_offices: {
        value: threatTMOffices,
      },
      mark_date_of_application: {
        value: {
          from: yourDateOfApplication.from
            ? dayjs(yourDateOfApplication.from).format(date_format)
            : "",
          to: yourDateOfApplication.to
            ? dayjs(yourDateOfApplication.to).format(date_format)
            : "",
        },
      },
      threat_date_of_application: {
        value: {
          from: threatDateOfApplication.from
            ? dayjs(threatDateOfApplication.from).format(date_format)
            : "",
          to: threatDateOfApplication.to
            ? dayjs(threatDateOfApplication.to).format(date_format)
            : "",
        },
      },
      mark_date_of_usage: {
        value: {
          from: yourDateOfUsage.from
            ? dayjs(yourDateOfUsage.from).format(date_format)
            : "",
          to: yourDateOfUsage.to
            ? dayjs(yourDateOfUsage.to).format(date_format)
            : "",
        },
      },
      threat_date_of_usage: {
        value: {
          from: threatDateOfUsage.from
            ? dayjs(threatDateOfUsage.from).format(date_format)
            : "",
          to: threatDateOfUsage.to
            ? dayjs(threatDateOfUsage.to).format(date_format)
            : "",
        },
      },
      mark_date_of_usage_proposed: {
        value: yourDateOfUsageProposed,
      },
      threat_date_of_usage_proposed: {
        value: threatDateOfUsageProposed,
      },
      red_band: {
        value: sameClassInfringement,
      },
      exclude_same_class: {
        value: excludeSameClassInfringement,
      },
      exclude_marks_wrt_custom_report: {
        value: excludeReported,
      },
      shortlisted: {
        value: shortlisted,
      },
      exclude_shortlisted: {
        value: excludeShortlisted,
      },
      exclude_mark_proprietors: {
        value: excludeMarkProprietors,
      },
      exclude_threat_proprietors: {
        value: excludeThreatProprietors,
      },
      threat_attorney: {
        value: threatAttorneyValue,
      },
      ...getRealSearchParameters(),
    };
    if (urlKey === "journals") {
      delete filters.threat_similarity;
    }

    if (urlKey === "image_similarity") {
      filters.image_text_percentage = {
        value: {
          to: imageTextPercentage.to,
          from: imageTextPercentage.from,
        },
      };
      filters.threat_image_text_percentage = {
        value: {
          to: threatImageTextPercentage.to,
          from: threatImageTextPercentage.from,
        },
      };
    }

    let brandFilters = {
      own_brand_classes: {
        value: tmClass,
        logic: andOrClass ? "AND" : "OR",
      },
      threat_brand_classes: {
        value: threatTMClass,
        logic: andOrThreatClass ? "AND" : "OR",
      },
      threat_similarity: {
        value: {
          to: similarity.to,
          from: similarity.from,
        },
      },
      own_brand_proprietors: {
        value: proprietors,
      },
      threat_brand_proprietors: {
        value: threatProprietors,
      },
      brand_date_of_application: {
        value: {
          from: yourDateOfApplication.from
            ? dayjs(yourDateOfApplication.from).format(date_format)
            : "",
          to: yourDateOfApplication.to
            ? dayjs(yourDateOfApplication.to).format(date_format)
            : "",
        },
      },
      threat_date_of_application: {
        value: {
          from: threatDateOfApplication.from
            ? dayjs(threatDateOfApplication.from).format(date_format)
            : "",
          to: threatDateOfApplication.to
            ? dayjs(threatDateOfApplication.to).format(date_format)
            : "",
        },
      },
      brand_date_of_usage: {
        value: {
          from: yourDateOfUsage.from
            ? dayjs(yourDateOfUsage.from).format(date_format)
            : "",
          to: yourDateOfUsage.to
            ? dayjs(yourDateOfUsage.to).format(date_format)
            : "",
        },
      },
      threat_date_of_usage: {
        value: {
          from: threatDateOfUsage.from
            ? dayjs(threatDateOfUsage.from).format(date_format)
            : "",
          to: threatDateOfUsage.to
            ? dayjs(threatDateOfUsage.to).format(date_format)
            : "",
        },
      },
      brand_date_of_usage_proposed: {
        value: yourDateOfUsageProposed,
      },
      threat_date_of_usage_proposed: {
        value: threatDateOfUsageProposed,
      },
      red_band: {
        value: sameClassInfringement,
      },
      exclude_same_class: {
        value: excludeSameClassInfringement,
      },
      exclude_brands_wrt_custom_report: {
        value: excludeReported,
      },
      shortlisted: {
        value: shortlisted,
      },
      exclude_shortlisted: {
        value: excludeShortlisted,
      },
      exclude_brand_proprietors: {
        value: excludeMarkProprietors,
      },
      exclude_threat_proprietors: {
        value: excludeThreatProprietors,
      },
      ...getRealSearchParameters(),
    };
    return urlKey === "brand_compare" ? brandFilters : filters;
  };

  let filterSelected = isAnyFilterSelected(generateFilterObject());

  const handleApplyFilters = () => {
    setFilterApplied(true);
    const filters = generateFilterObject();
    dispatch(getListLayoutData(journal, 1, true, filters));
    dispatch(changeFilterApplied(true));
    setGoToBookmarkClicked(false);
  };

  const handleResetFilters = (journalNumber, runListApi = true) => {
    setSelectAll(false);
    setSelected([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setFilterApplied(false);
    setSearchValue("");
    setExcludeReported("");
    setTMTypes([]);
    setThreatTMTypes([]);
    setTMCategories([]);
    setThreatTMCategories([]);
    setTMOffices([]);
    setThreatTMOffices([]);
    setInfringement([]);
    setSearchType("Application No/Name");
    setExcludeReported(false);
    setShortlisted(false);
    setBookmark(false);
    setThreatAttorneyValue([]);
    setProprietors([]);
    setThreatProprietors([]);
    setExcludeMarkProprietors([]);
    setExcludeThreatProprietors([]);
    setTags([]);
    setTMClass([]);
    setThreatClass([]);
    setSimilarity({
      from: 0,
      to: 100,
    });
    setYourDateOfApplication({
      from: "",
      to: "",
      dateString: "",
    });
    setThreatDateOfApplication({
      from: "",
      to: "",
      dateString: "",
    });
    setYourDateOfUsage({
      from: "",
      to: "",
      dateString: "",
    });
    setThreatDateOfUsage({
      from: "",
      to: "",
      dateString: "",
    });
    setYourDateOfUsageProposed(false);
    setNoTags(false);
    setSameClassInfringement(false);
    setExcludeSameClassInfringement(false);
    setThreatDateOfUsageProposed(false);
    setExcludeShortlisted(false);
    dispatch(changeTemplateId(0));
    runListApi && dispatch(getListLayoutData(journalNumber, 1, false, {}));
    dispatch(resetAllFilter());
    setGoToBookmarkClicked(false);
    setImageTextPercentage({
      from: 0,
      to: 100,
    });
    setThreatImageTextPercentage({
      from: 0,
      to: 100,
    });
  };

  const handleJournalChange = (journalNumber, option) => {
    setJournal(journalNumber);
    setLocalJournal(journalNumber);
    setPublishedDate(
      journalConfig?.data?.filter(
        (journal) => journal.value === journalNumber,
      )[0].published_date,
    );
    handleResetFilters(journalNumber);
    dispatch(getListBookmark(journalNumber, `${getPrimaryFilterPath()}/`));
    setSelectAll(false);
    setSelected([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setGoToBookmarkClicked(false);
  };

  const handleCreateReport = () => {
    setOpenCreateReport(true);
  };

  const handleCombinePdf = () => {
    let body = {
      journal_number: journal,
      filter_flag: false,
      selected_ids: selected,
      creation_origin: getCreationOrigin(),
      source: urlKey === "journals" ? "Journal" : "LIST_LAYOUT",
    };
    if (selectAll) {
      let modifiedBody = {
        ...body,
        filter_flag: true,
        selected_ids: [],
        filters: generateFilterObject(),
      };
      dispatch(createCombinePdf(modifiedBody));
    } else {
      dispatch(createCombinePdf(body));
    }
  };

  const useMemoizedComponent = (Component, deps) => {
    return React.useMemo(() => Component, deps);
  };

  const generateDateObject = (item) => {
    // Split the date string by '-' and rearrange it to YYYY-MM-DD for dayjs better functionality.
    const fromDateParts = item.value.from.split("-");
    const fromDate = `${fromDateParts[2]}-${fromDateParts[1]}-${fromDateParts[0]}`;

    const toDateParts = item.value.to.split("-");
    const toDate = `${toDateParts[2]}-${toDateParts[1]}-${toDateParts[0]}`;

    return {
      from: fromDate.length ? dayjs(fromDate) : "",
      to: toDate.length ? dayjs(toDate) : "",
      dateString:
        fromDate.length && toDate.length
          ? `${dayjs(fromDate).format(date_format)}to${dayjs(toDate).format(
              date_format,
            )}`
          : "",
    };
  };

  const getSearchTerm = (template) => {
    if (template.contains_term.value) {
      setSearchValue(template.contains_term.value);
      setSearchType("Application No/Name");
    } else if (template.mark_desc_contains_term.value) {
      setSearchValue(template.mark_desc_contains_term.value);
      setSearchType("Your Mark Description Search");
    } else if (template.threat_desc_contains_term.value) {
      setSearchValue(template.threat_desc_contains_term.value);
      if (urlKey === "journals") {
        setSearchType("Description Search");
      } else {
        setSearchType("Your Threat Description Search");
      }
    } else {
      setSearchType("Application No/Name");
      setSearchValue("");
    }
  };

  const handleUseTemplate = (template, showApplyClicked = false) => {
    const template_json = template.template_json;
    getSearchTerm(template_json);
    setFilterApplied(showApplyClicked);

    setExcludeSameClassInfringement(
      template_json?.exclude_same_class?.value
        ? template_json.exclude_same_class.value
        : false,
    );
    setSameClassInfringement(
      template_json?.red_band?.value ? template_json.red_band.value : false,
    );
    setExcludeReported(
      template_json?.exclude_marks_wrt_custom_report?.value
        ? template_json.exclude_marks_wrt_custom_report.value
        : false,
    );
    setSimilarity(
      template_json?.threat_similarity?.value
        ? template_json.threat_similarity.value
        : {
            from: 0,
            to: 100,
          },
    );
    setTags(template_json?.tags?.value?.length ? template_json.tags.value : []);
    setTMCategories(
      template_json?.mark_categories?.value?.length
        ? template_json.mark_categories.value
        : [],
    );
    setTMClass(
      template_json?.mark_classes?.value?.length
        ? template_json.mark_classes.value
        : [],
    );
    setYourDateOfApplication(
      template_json?.mark_date_of_application?.value?.from?.length ||
        template_json?.mark_date_of_application?.value?.to?.length
        ? generateDateObject(template_json.mark_date_of_application)
        : { from: "", to: "", dateString: "" },
    );
    setYourDateOfUsage(
      template_json?.mark_date_of_usage?.value?.from?.length ||
        template_json?.mark_date_of_usage?.value?.to?.length
        ? generateDateObject(template_json.mark_date_of_usage)
        : { from: "", to: "", dateString: "" },
    );
    setYourDateOfUsageProposed(
      template_json?.mark_date_of_usage_proposed?.value
        ? template_json.mark_date_of_usage_proposed.value
        : false,
    );
    setTMOffices(
      template_json?.mark_offices?.value?.length
        ? template_json.mark_offices.value
        : [],
    );
    setProprietors(
      template_json?.mark_proprietors?.value?.length
        ? template_json.mark_proprietors.value
        : [],
    );
    setTMTypes(
      template_json?.mark_types?.value?.length
        ? template_json.mark_types.value
        : [],
    );
    setThreatAttorneyValue(
      template_json?.threat_attorney?.value?.length
        ? template_json.threat_attorney.value
        : [],
    );
    setThreatTMCategories(
      template_json?.threat_categories?.value?.length
        ? template_json.threat_categories.value
        : [],
    );
    setThreatClass(
      template_json?.threat_classes?.value?.length
        ? template_json.threat_classes.value
        : [],
    );
    setThreatDateOfApplication(
      template_json?.threat_date_of_application?.value?.from?.length ||
        template_json?.threat_date_of_application?.value?.to?.length
        ? generateDateObject(template_json.threat_date_of_application)
        : { from: "", to: "", dateString: "" },
    );
    setThreatDateOfUsage(
      template_json?.threat_date_of_usage?.value?.from?.length ||
        template_json?.threat_date_of_usage?.value?.to?.length
        ? generateDateObject(template_json.threat_date_of_usage)
        : { from: "", to: "", dateString: "" },
    );
    setThreatDateOfUsageProposed(
      template_json?.threat_date_of_usage_proposed?.value
        ? template_json.threat_date_of_usage_proposed.value
        : false,
    );
    setThreatTMOffices(
      template_json?.threat_offices?.value?.length
        ? template_json.threat_offices.value
        : [],
    );
    setThreatProprietors(
      template_json?.threat_proprietors?.value?.length
        ? template_json.threat_proprietors.value
        : [],
    );
    setInfringement(
      template_json?.threat_sources?.value?.length
        ? template_json.threat_sources.value
        : [],
    );
    setThreatTMTypes(
      template_json?.threat_types?.value?.length
        ? template_json.threat_types.value
        : [],
    );
    setAndOrTags(template_json?.tags?.logic === "AND" ? true : false);
    setAndOrClass(template_json?.mark_classes?.logic === "AND" ? true : false);
    setAndOrThreatClass(
      template_json?.threat_classes?.logic === "AND" ? true : false,
    );
    setExcludeMarkProprietors(
      template_json?.exclude_mark_proprietors?.value?.length
        ? template_json?.exclude_mark_proprietors?.value
        : [],
    );
    setExcludeThreatProprietors(
      template_json?.exclude_threat_proprietors?.value?.length
        ? template_json.exclude_threat_proprietors.value
        : [],
    );

    if (urlKey === "image_similarity") {
      setImageTextPercentage(
        template_json?.image_text_percentage?.value
          ? template_json.image_text_percentage.value
          : {
              from: 0,
              to: 100,
            },
      );
      setThreatImageTextPercentage(
        template_json?.threat_image_text_percentage?.value
          ? template_json.threat_image_text_percentage.value
          : {
              from: 0,
              to: 100,
            },
      );
    }

    // for brand compare changes starts
    if (urlKey === "brand_compare") {
      setExcludeReported(
        template_json?.exclude_brands_wrt_custom_report?.value
          ? template_json.exclude_brands_wrt_custom_report.value
          : false,
      );
      setTMClass(
        template_json?.own_brand_classes?.value?.length
          ? template_json.own_brand_classes.value
          : [],
      );
      setYourDateOfApplication(
        template_json?.brand_date_of_application?.value?.from?.length ||
          template_json.brand_date_of_application?.value?.to?.length
          ? generateDateObject(template_json.brand_date_of_application)
          : { from: "", to: "", dateString: "" },
      );
      setYourDateOfUsage(
        template_json.brand_date_of_usage?.value?.from?.length ||
          template_json.brand_date_of_usage?.value?.to?.length
          ? generateDateObject(template_json.brand_date_of_usage)
          : { from: "", to: "", dateString: "" },
      );
      setYourDateOfUsageProposed(
        template_json?.brand_date_of_usage_proposed?.value
          ? template_json.brand_date_of_usage_proposed.value
          : false,
      );
      setProprietors(
        template_json?.own_brand_proprietors?.value?.length
          ? template_json.own_brand_proprietors.value
          : [],
      );
      setThreatClass(
        template_json?.threat_brand_classes?.value?.length
          ? template_json.threat_brand_classes.value
          : [],
      );
      setThreatProprietors(
        template_json?.threat_brand_proprietors?.value?.length
          ? template_json.threat_brand_proprietors.value
          : [],
      );
      setAndOrClass(
        template_json?.own_brand_classes?.logic === "AND" ? true : false,
      );
      setAndOrThreatClass(
        template_json?.threat_brand_classes?.logic === "AND" ? true : false,
      );
      setExcludeMarkProprietors(
        template_json?.exclude_brand_proprietors?.value.length
          ? template_json.exclude_brand_proprietors.value
          : [],
      );
      setExcludeThreatProprietors(
        template_json?.exclude_threat_proprietors?.value?.length
          ? template_json.exclude_threat_proprietors.value
          : [],
      );
    }
    // for brand compare changes ends
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key={1} onClick={() => handleCombinePdf()}>
        Download Only Journal Copy
      </Menu.Item>
      <Menu.Item key={2} onClick={() => setOpenBulkTemplateDownload(true)}>
        Bulk Template Download
      </Menu.Item>
    </Menu>
  );

  const firstRow = (
    <Row style={{ width: "100%" }}>
      <Col xl={12} lg={24} style={{ marginBottom: "16px" }}>
        <Space>
          {useMemoizedComponent(
            <SearchTypeDropdown
              value={searchType}
              setValue={setSearchType}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              listOfSearchType={
                urlKey === "tm_protect" || urlKey === "journals"
                  ? urlKey === "tm_protect"
                    ? listOfSearchForThreatDetails
                    : listOfSearchForJournalDetails
                  : urlKey === "brand_compare"
                    ? listOfSearchForBrandCompare
                    : listOfSearchType
              }
              updateRedux
            />,
            [searchType, searchValue],
          )}
          <Button
            type="primary"
            icon={<FilterFilled />}
            disabled={!filterSelected || (status === LOADING ? true : false)}
            onClick={handleApplyFilters}
            loading={status === LOADING ? true : false}
          >
            Apply
          </Button>
          <Button
            type="text"
            danger
            onClick={() => handleResetFilters(journal)}
            disabled={!filterApplied}
          >
            Reset All
          </Button>
        </Space>
      </Col>
      <Col
        xl={12}
        lg={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: screenWidth.xl || screenWidth.xxl ? "right" : "left",
          marginBottom: "16px",
        }}
      >
        <Space size={selectAll ? "large" : "middle"}>
          <Tooltip title="Deselect All">
            <Button
              danger
              onClick={() => {
                setSelectAll(false);
                setSelected([]);
                setSelectedRowKeys([]);
                setSelectedRows([]);
              }}
              disabled={!selected.length}
              icon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MinusCircleOutlined style={{ margin: 0, fontSize: 16 }} />
                </div>
              }
            />
          </Tooltip>
          <Badge
            overflowCount={9999}
            count={selectAll ? "ALL" : selected.length}
            style={{ marginTop: "5px" }}
          >
            <Button
              onClick={handleCreateReport}
              disabled={!selected.length || !tableData.length}
            >
              Create Report
            </Button>
          </Badge>
          {!(urlKey === "tm_protect" || urlKey === "brand_compare") && (
            <Badge
              overflowCount={9999}
              count={selectAll ? "ALL" : selected.length}
              style={{ marginTop: "5px" }}
            >
              {urlKey === "journals" || urlKey === "image_text" ? (
                <div>
                  <Button
                    id="list_filter_combine_pdf_btn"
                    onClick={handleCombinePdf}
                    disabled={
                      !selected.length ||
                      combinePdf.loading ||
                      !tableData.length
                    }
                    loading={combinePdf.loading}
                  >
                    Combine PDF
                  </Button>{" "}
                </div>
              ) : (
                <div>
                  <Dropdown
                    overlay={dropdownMenu}
                    disabled={
                      !selected.length ||
                      combinePdf.loading ||
                      !tableData.length
                    }
                    loading={combinePdf.loading}
                  >
                    <Button id="list_filter_combine_pdf_btn">
                      <Space>Combine PDF</Space>
                    </Button>
                  </Dropdown>
                </div>
              )}
            </Badge>
          )}
          <JournalFilter
            filteredData={journalConfig}
            value={journal}
            handleJournalChange={handleJournalChange}
            extraLoading={status === LOADING ? true : false}
          />
        </Space>
      </Col>
    </Row>
  );

  const secondRow = (
    <Row style={{ width: "100%" }}>
      <Col lg={18} md={24}>
        {urlKey === "brand_compare" ? (
          <Space>
            <MultipleCheckboxComponents
              excludedReport={excludeReported}
              setExcludedReport={setExcludeReported}
              shortlist={shortlisted}
              setShortlist={setShortlisted}
              excludeShortlisted={excludeShortlisted}
              setExcludeShortlisted={setExcludeShortlisted}
              open={openTour3}
            />
          </Space>
        ) : (
          <Space>
            <MultipleCheckboxComponents
              excludedReport={excludeReported}
              setExcludedReport={setExcludeReported}
              shortlist={shortlisted}
              setShortlist={setShortlisted}
              excludeShortlisted={excludeShortlisted}
              setExcludeShortlisted={setExcludeShortlisted}
              open={openTour3}
            />
            {urlKey === "journals" && dataView === "card" && (
              <>
                <SelectFilter
                  filteredData={threatClassConfig}
                  key={threatClassConfig.key}
                  value={threatTMClass}
                  setValue={setThreatClass}
                  loading={threatClassConfig.loading}
                  journal_number={journal}
                  showSwitch
                  andOr={andOrThreatClass}
                  setAndOr={setAndOrThreatClass}
                  current_template_id={current_template_id}
                  extraHighlight={
                    sameClassInfringement || excludeSameClassInfringement
                  }
                  filterPath={getPrimaryFilterPath()}
                  rename={urlKey === "journals" ? "Classes" : ""}
                />
                <ProprietorFilterComponent
                  filteredData={threatproprietorConfig}
                  key={threatproprietorConfig.key}
                  value={threatProprietors}
                  setValue={setThreatProprietors}
                  loading={threatproprietorConfig.loading}
                  journal_number={journal}
                  exclude={excludeThreatProprietors}
                  setExclude={setExcludeThreatProprietors}
                  current_template_id={current_template_id}
                  filterPath={getPrimaryFilterPath()}
                  rename={urlKey === "journals" ? "Proprietors" : ""}
                />
              </>
            )}
            {!(urlKey === "journals" && dataView === "table") && (
              <TwoLayoutMenuFilter
                data1={markTypeConfig}
                data2={threatTypeConfig}
                title={"TM Types"}
                value1={tmTypes}
                setValue1={setTMTypes}
                value2={threatTMTypes}
                setValue2={setThreatTMTypes}
                loading={markTypeConfig.loading || threatTypeConfig.loading}
                showFirstLayout={
                  urlKey === "tm_protect" || urlKey === "journals"
                    ? false
                    : true
                }
              />
            )}
            {!(urlKey === "journals" || urlKey === "image_similarity") && (
              <MenuFilter
                filteredData={infringementConfig}
                value={infringement}
                setValue={setInfringement}
                infrigementInfo={true}
                loading={infringementConfig.loading}
              />
            )}
            <TwoLayoutMenuFilter
              data1={markCategoryConfig}
              data2={threatCategoryConfig}
              title={"Categories"}
              value1={tmCategories}
              setValue1={setTMCategories}
              value2={threatTMCategories}
              setValue2={setThreatTMCategories}
              loading={
                markCategoryConfig.loading || threatCategoryConfig.loading
              }
              showFirstLayout={
                urlKey === "tm_protect" || urlKey === "journals" ? false : true
              }
            />
            {!(urlKey === "journals" && dataView === "card") && (
              <TwoLayoutMenuFilter
                data1={markOfficeConfig}
                data2={threatOfficeConfig}
                title={"Offices"}
                value1={tmOffices}
                setValue1={setTMOffices}
                value2={threatTMOffices}
                setValue2={setThreatTMOffices}
                loading={markOfficeConfig.loading || threatOfficeConfig.loading}
                showFirstLayout={
                  urlKey === "tm_protect" || urlKey === "journals"
                    ? false
                    : true
                }
              />
            )}
            {urlKey === "journals" && dataView === "card" && (
              <>
                <DateFilter
                  menu_label={
                    urlKey === "journals" ? "TM DoA|DoU" : "Threat's TM DoA|DoU"
                  }
                  subtitle1={
                    urlKey === "journals"
                      ? "TM Date of Application"
                      : "Threat's TM Date of Application"
                  }
                  subtitle2={
                    urlKey === "journals"
                      ? "TM Date of Usage"
                      : "Threat's TM Date of Usage"
                  }
                  key={"threat_date"}
                  value1={threatDateOfApplication}
                  setValue1={setThreatDateOfApplication}
                  value2={threatDateOfUsage}
                  setValue2={setThreatDateOfUsage}
                  proposed={threatDateOfUsageProposed}
                  setProposed={setThreatDateOfUsageProposed}
                  filter_key_1="threat_date_of_application"
                  filter_key_2="threat_date_of_usage"
                />
              </>
            )}
            <Button type="link" onClick={() => setOpenMoreFilters(true)}>
              More Filters
            </Button>
          </Space>
        )}
      </Col>
      <Col
        lg={6}
        md={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: screenWidth.xl || screenWidth.xxl ? "right" : "left",
        }}
      >
        <Space>
          {dataView === "card" && (
            <Checkbox
              checked={selectAll}
              onClick={(event) => {
                if (event.target.checked) {
                  setSelectAll(true);
                  setSelected(tableData);
                } else {
                  setSelectAll(false);
                  setSelected([]);
                  setSelectedRowKeys([]);
                  setSelectedRows([]);
                }
              }}
              disabled={!tableData?.length}
            >
              Select All
            </Checkbox>
          )}
          <UpdateShortlistedComponent
            source={urlKey === "journals" ? "Journal" : "LIST_LAYOUT"}
            creationOrigin={getCreationOrigin()}
            journalNumber={journal}
            selected={selected}
            filterFlag={filterApplied}
            filter={generateFilterObject()}
            selectAll={selectAll}
            disabled={!selected.length}
            pageNumber={pageNumber}
          />
          {dataView === "table" && urlKey !== "brand_compare" && (
            <CheckableTagComponent
              label="G&S"
              value={showDescription}
              setValue={setShowDescription}
              disableTooltip="Show Descriptions"
              activeTooltip="Hide Descriptions"
            />
          )}
          {dataView === "table" && (
            <CheckableTagComponent
              label={<BookFilled />}
              value={
                !(
                  bookmarkTemplate === null || bookmarkTemplate?.disable == true
                )
              }
              setValue={async (v) => {
                if (
                  !(
                    bookmarkTemplate === null ||
                    bookmarkTemplate?.disable == true
                  )
                ) {
                  setBookmark(v);
                  setGoToBookmarkClicked(true);
                  const temp = {
                    template_json: bookmarkTemplate,
                  };
                  bookmarkTemplate?.filterApplied &&
                    handleUseTemplate(temp, true);
                  bookmarkTemplate?.filterApplied &&
                    dispatch(changeFilterApplied(true));
                  await dispatch(
                    getListLayoutData(
                      journal,
                      bookmarkTemplate?.page,
                      bookmarkTemplate?.filterApplied ? true : false,
                      bookmarkTemplate,
                    ),
                  );

                  let bookmark = document.getElementById("Bookmarked");
                  if (bookmark !== null) {
                    bookmark.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "nearest",
                    });
                  }
                }
              }}
              disableTooltip="Bookmark not present"
              activeTooltip="Go To Bookmark"
            />
          )}
          {
            <CheckableTagComponent
              label={<PlaySquareOutlined />}
              value={isTourOpen}
              setValue={async (v) => {
                if (!isTableDataPresent) {
                  dispatch(addListSampleData(listSampleObject));
                }
                if (urlKey === "journals") {
                  await setDataView("table");
                }
                setIsTourOpen(v);
              }}
              disableTooltip="Take Tour"
              activeTooltip="Take Tour"
            />
          }
        </Space>
      </Col>
    </Row>
  );

  const onCloseBulkTemplateModal = () => {
    setOpenBulkTemplateDownload(false);
  };

  return (
    <React.Fragment>
      <header className="General-Header">
        <Row style={{ padding: "20px 10px" }}>
          {firstRow}
          {secondRow}
        </Row>
      </header>
      {(openMoreFilters || openTour4) && (
        <MoreFilterDrawer
          open={openMoreFilters || openTour4}
          setOpen={setOpenMoreFilters}
          getRealSearchParameters={getRealSearchParameters}
          handleParentApplyFilters={handleApplyFilters}
          handleResetFilters={handleResetFilters}
          getFilterPath={getPrimaryFilterPath}
          {...props}
        />
      )}
      {openCreateReport && (
        <CreateReportDrawer
          open={openCreateReport}
          setOpen={setOpenCreateReport}
          journal={journal}
          selected={selected}
          generateFilterObject={generateFilterObject}
          report_columns={report_columns}
          filterApplied={filterApplied}
          pageNumber={pageNumber}
          creation_origin={getCreationOrigin()}
          {...props}
        />
      )}
      {openBulkTemplateDownload && (
        <AdvanceCombinePDF
          open={openBulkTemplateDownload}
          selected={selected}
          selectAll={selectAll}
          onCloseModal={onCloseBulkTemplateModal}
          journal={journal}
          generateFilterObject={generateFilterObject}
          filterApplied={filterApplied}
          creation_origin={getCreationOrigin()}
          source="LIST_LAYOUT"
        />
      )}
    </React.Fragment>
  );
};

export default ListFilter;
