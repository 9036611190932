const initialState = {
  data: [],
  isDataLoaded: false,
  loading: false,
  metadata: {
    count: 0,
    pageNumber: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
