//--------LIBRARIES--------
import React from 'react';

function NoMatch(props){
    props.history.push("/");
    return (
        <div>
            <h1>NO MATCH</h1>
        </div>
    );
}

export default NoMatch;