//--------LIBRARIES--------
import { combineReducers } from "redux";

//--------REDUCERS--------
import listLayoutFilters from "./listLayoutFilters";
import listLayoutTemplate from "./listLayoutTemplate";
import listLayout from "./listLayout";

export default combineReducers({
  listLayoutFilters,
  listLayoutTemplate,
  listLayout,
});
