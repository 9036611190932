//--------IP-SUITE CONSTANTS--------
import { SUCCESS, ERROR, LOADING } from '../../../constants/commonConstants/generalConstants';

//--------MANAGE TRADEMARKS TYPES--------
import { MT_GET_TABLE, MT_POST_MANAGE_FOCUS } from '../../actions/watchActions/types';

//--------MANAGE TRADEMARKS CONSTANTS--------
import { MT_defaultSelectedFilters } from '../../../constants/watchConstants/manageTrademarks';

const defaultManageTrademarksState = {
    tableData: null,
    tableDataStatus: SUCCESS,
    filters: null,
    setters: null,
    filteredTableCount: null,
    pageNumber: 1,
    lastAppliedFilters: {
        filterFlag: false,
        ...MT_defaultSelectedFilters
    },
    noFocusWords: [],
    noFocusClasses: [],
    editTrademarkStatus: SUCCESS,
    deleteTrademarkStatus: SUCCESS
}

export default function(state = defaultManageTrademarksState, action){
    switch (action.type) {
        case MT_GET_TABLE:
            switch (action.status) {
                case SUCCESS:
                    return {
                        ...state,
                        tableData: action.payload.data,
                        tableDataStatus: action.status,
                        filters: action.payload.filters,
                        setters: action.payload.setter,
                        filteredTableCount: action.payload.count,
                        pageNumber: action.pageNumber,
                        lastAppliedFilters: action.lastAppliedFilters,
                        noFocusWords: action.payload.no_focus_words,
                        noFocusClasses: action.payload.no_focus_classes
                    };

                case LOADING:
                    return {
                        ...state,
                        tableDataStatus: action.status
                    };
                    
                case ERROR:
                    return {
                        ...state,
                        tableDataStatus: action.status
                    };
                
                default:
                    return {
                        ...state,
                        tableDataStatus: action.status
                    };
            }

        case MT_POST_MANAGE_FOCUS:
            switch (action.status) {
                case SUCCESS:
                    let { tableData } = state;
                    let filteredTableData = [];
                    let { tags, focus_classes } = state.setters;
                    if(action.selectAll){
                        // if(action.setters.tags === null){
                        //     let addClasses = focus_classes.filter( eachClass => action.setters.tags.add.includes( eachClass.id ));
                        //     filteredTableData = tableData.map( eachRow => eachRow.)
                        // }
                        if(action.deleteFlag){
                            return {
                                ...state,
                                deleteTrademarkStatus: SUCCESS
                            }
                        }
                    } else {
                        if(action.deleteFlag){
                            return {
                                ...state,
                                deleteTrademarkStatus: SUCCESS
                            }
                        } else {
                            return {
                                ...state,
                                tableData: tableData.map( eachRow => (eachRow.application_number === action.payload.mark.application_number)? action.payload.mark: eachRow),
                                editTrademarkStatus: SUCCESS,
                                noFocusWords: action.payload.no_focus_words,
                                noFocusClasses: action.payload.no_focus_classes
                            };
                        }
                    }
        
                case LOADING:
                    if(action.deleteFlag){
                        return {
                            ...state,
                            deleteTrademarkStatus: LOADING
                        };
                    } else {
                        return {
                            ...state,
                            editTrademarkStatus: LOADING
                        };
                    }
                    
                case ERROR:
                    if(action.deleteFlag){
                        return {
                            ...state,
                            deleteTrademarkStatus: ERROR
                        };
                    } else {
                        return {
                            ...state,
                            editTrademarkStatus: ERROR
                        };
                    }
        
                default:
                    return state;
        }

        default:
            return state;
    }
}