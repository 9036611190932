import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import { TagTwoTone } from "@ant-design/icons";
import {
  Row,
  Col,
  Menu,
  Drawer,
  Button,
  Input,
  notification,
  Typography,
} from "antd";
import {
  changeStateValue_L1,
  changeStateValue_L2,
} from "./../../../../functions/commonFunctions/generalFunctions";
import { MT_defaultSettersSearch } from "./../../../../constants/managerConstants/trademarkConstants";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";

import UploadDocuments from "../../../commonComponents/Drawers/UploadDocuments";
import { P, TM } from "../../../../constants/managerConstants/managerConstants";

//--------TRADEMARK DASHBOARD ACTIONS--------
import {
  getManagePatent,
  postManagePatent,
} from "../../../../redux/actions/managerActions/patentDashboard/actions";
import {
  postManageTrademark,
  postUploadFile,
  deleteUploadedFile,
  putUploadedFile, //----Upload Documents Actions
} from "../../../../redux/actions/managerActions/trademarkDashboard/actions";
import { UPLOAD_FILE_PT } from "../../../../redux/actions/managerActions/patentDashboard/types";
import { getPatentTags } from "../../../../redux/actions/commonActions/actions";

const { Title, Text, Paragraph } = Typography;
const { Search } = Input;

class ManagePatentDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSetters: this.props.selectedSetters,
      searchSetters: MT_defaultSettersSearch,
    };
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  async handleConfirmManageTrademark() {
    await this.props.postManagePatent({
      mmType: this.props.mmType,
      setters: this.state.selectedSetters.single,
      select_all: this.props.selectAll,
      delete_flag: false,
      filterFlag: this.props.lastAppliedFilters.filterFlag,
      filters: this.props.lastAppliedFilters,
      trademarks: [],
      application_number: this.props.application_number,
    });
    if (this.props.editManagePatentStatus === SUCCESS) {
      this.props.onCloseDrawer();
    }
    notification[
      this.props?.editManagePatentStatus === SUCCESS
        ? "success"
        : this.props?.editManagePatentStatus === ERROR
        ? "error"
        : "smile"
    ]({
      message: "Update Trademark",
      description:
        this.props?.editManagePatentStatus === SUCCESS
          ? "Trademark has been updated successfully."
          : this.props?.editManagePatentStatus === ERROR
          ? "Unable to update trademark. Please try again later!"
          : "",
    });
  }

  render() {
    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let singleSelectTagsMenu = noFilterData;

    if (this.props.patentStatus === SUCCESS) {
      const { tags } = this.props.patentData.setter;

      if (tags !== null && tags.length > 0) {
        let filteredTag = tags.filter((eachTag) =>
          checkValue(eachTag)
            ? eachTag.name
                .toLowerCase()
                .includes(this.state.searchSetters.single.tags)
            : ""
        );
        singleSelectTagsMenu = (
          <>
            <div style={{ padding: "10px", borderBottom: "1px solid #E9E9E9" }}>
              <Search
                placeholder="Search Tags"
                onChange={(event) =>
                  this.changeStateValue_L2(
                    "searchSetters",
                    "single",
                    "tags",
                    event.target.value.toLowerCase()
                  )
                }
                value={this.state.searchSetters.single.tags}
                allowClear
              />
            </div>
            <FixedSizeList
              className="List"
              height={200}
              itemCount={filteredTag.length}
              itemSize={35}
              itemData={filteredTag}
              width="100%"
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={this.state.selectedSetters.single.tags}
                    onSelect={(event) =>
                      this.changeStateValue_L2(
                        "selectedSetters",
                        "single",
                        "tags",
                        event.selectedKeys
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L2(
                        "selectedSetters",
                        "single",
                        "tags",
                        event.selectedKeys
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Paragraph>
                        <TagTwoTone
                          twoToneColor={`${data[index].color_code}`}
                        />
                        <Text style={{ fontWeight: "400" }}>
                          {data[index].name}
                        </Text>
                      </Paragraph>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }
    }

    return (
      <Drawer
        title={
          <Title level={4}>
            Manage Patent ({this.props.application_number})
          </Title>
        }
        placement="right"
        width={400}
        closable={true}
        onClose={() => this.props.onCloseDrawer()}
        open={this.props.visible ? true : false}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <UploadDocuments
          applicationNumber={this.props.application_number}
          documentFor={P}
          teamMembers={
            checkValue(this.props.userDetails)
              ? checkValue(this.props.teamMembe)
                ? [...this.props.teamMember, this.props.userDetails]
                : ""
              : ""
          }
          defaultEmail={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          uploadedDocuments={this.props.visible.uploaded_documents}
          putUploadedFile={(Obj) =>
            this.props.putUploadedFile(Obj, UPLOAD_FILE_PT)
          }
          postUploadFile={(Obj) =>
            this.props.postUploadFile(Obj, UPLOAD_FILE_PT)
          }
          deleteUploadedFile={(Obj) =>
            this.props.deleteUploadedFile(Obj, UPLOAD_FILE_PT)
          }
        />
        <br />
        <Row>
          <Col>
            <div rootStyle={{ marginBottom: "1em" }}>
              <Text strong>Select tags:</Text>
              <Button
                rootStyle={{ float: "right" }}
                type="link"
                onClick={() =>
                  this.changeStateValue_L1(
                    "selectedSetters",
                    "single",
                    "tags",
                    []
                  )
                }
              >
                Clear
              </Button>
            </div>
            <div rootStyle={{ border: "1px solid #E9E9E9" }}>
              {singleSelectTagsMenu}
            </div>
          </Col>
        </Row>
        <div
          rootStyle={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button
            danger
            ghost
            onClick={() => this.props.onCloseDrawer()}
            rootStyle={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            ghost
            loading={this.props.editManagePatentStatus === LOADING}
            onClick={() => this.handleConfirmManageTrademark()}
          >
            Confirm
          </Button>
        </div>
      </Drawer>
    );
  }
}

const mapDispatchToProps = {
  getManagePatent,
  getPatentTags,
  postManagePatent,
  postUploadFile,
  deleteUploadedFile,
  putUploadedFile,
};

function mapStateToProps(state) {
  const {
    patentData,
    patentStatus,
    patentAppliedFilter,
    editManagePatentStatus,
  } = state.managerState.patentDashboardState;

  const {
    manageTrademark,
    manageTrademarkStatus,
    manageTrademarkLastAppliedFilters,
  } = state.managerState.trademarkDashboardState;

  const { teamMember, teamMemberStatus } = state.profile;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;

  const { manageTags } = state.signInState;

  return deepFreeze({
    patentData,
    patentStatus,
    patentAppliedFilter,
    manageTrademark,
    manageTrademarkStatus,
    lastAppliedFilters: manageTrademarkLastAppliedFilters,
    teamMember,
    teamMemberStatus,
    userSubscriptions,
    userDetails,
    userTeam,
    editManagePatentStatus,
    userSubscriptionStatus,
    manageTags,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePatentDrawer);
