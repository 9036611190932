import Axios from "axios";
import { ROOT_URL } from "../../../configs/rootConfigs";
import {
  W_GRID_GET_DATA,
  W_GRID_GET_THREAT_DATA,
  W_GRID_INSERT_SAMPLE_DATA,
} from "./types";
//--------IP-SUITE ACTIONS--------
// import { checkAuthorisation } from "../../commonActions/actions";
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
// import { onResponseError } from "../../../functions/commonFunctions/interceptor";
import { checkAuthorisation } from "../commonActions/actions";

export function getGridLayoutData(
  journalNumber,
  pageNumber,
  filterFlag,
  filters,
  location,
) {
  let urlKey = location.pathname.split("/")[2] || "";
  const gridURL = `${ROOT_URL}/watch/${
    urlKey === "tm_applied" ? "applied" : urlKey
  }/grid_layout/marks/`;
  return async (dispatch) => {
    dispatch({ type: W_GRID_GET_DATA, status: LOADING });
    try {
      const params = {
        page: pageNumber,
      };
      let body = {
        // journal_number: journalNumber,
        filter_flag: filterFlag,
      };
      if (urlKey === "tm_protect") {
        body = {
          ...body,
          cease_desist_report_id: journalNumber,
        };
      } else {
        body = {
          ...body,
          journal_number: journalNumber,
        };
      }
      if (filterFlag) {
        body = {
          ...body,
          filters,
        };
      }
      let response = await Axios.put(gridURL, body, {
        params: params,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: W_GRID_GET_DATA,
        status: SUCCESS,
        payload: response?.data,
        journalNumber,
        pageNumber,
        urlKey,
      });
    } catch (error) {
      checkAuthorisation(dispatch, error);
      dispatch({
        type: W_GRID_GET_DATA,
        status: ERROR,
        // payload: error?.response?.data,
        payload: [],
      });
    }
  };
}

export function getGridLayoutThreatData(
  applicationNumber,
  journalNumber,
  pageNumber,
  filterFlag,
  filters,
  location,
  callbacks,
) {
  let urlKey = location.pathname.split("/")[2] || "";
  const gridURL = `${ROOT_URL}/watch/${
    urlKey === "tm_applied" ? "applied" : urlKey
  }/grid_layout/threats/`;
  return async (dispatch) => {
    dispatch({
      type: W_GRID_GET_THREAT_DATA,
      status: LOADING,
      applicationNumber,
    });
    try {
      const params = {
        page: pageNumber,
      };
      let body = {
        // journal_number: journalNumber,
        // application_number: applicationNumber,
        filter_flag: filterFlag,
      };
      if (urlKey === "tm_protect") {
        body = {
          ...body,
          cease_desist_report_id: journalNumber,
          tm_protect_mark_id: applicationNumber,
        };
      } else {
        body = {
          ...body,
          journal_number: journalNumber,
          application_number: applicationNumber,
        };
      }
      if (filterFlag) {
        body = {
          ...body,
          filters,
        };
      }
      let response = await Axios.put(gridURL, body, {
        params: params,
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      dispatch({
        type: W_GRID_GET_THREAT_DATA,
        status: SUCCESS,
        payload: response?.data,
        applicationNumber,
        journalNumber,
        pageNumber,
        urlKey,
      });
      callbacks?.(SUCCESS, response);
    } catch (error) {
      checkAuthorisation(dispatch, error);
      dispatch({
        type: W_GRID_GET_THREAT_DATA,
        status: ERROR,
        applicationNumber,
        // payload: error?.response?.data,
        payload: [],
      });
    }
  };
}

export function addGridSampleData(sampleObject, sampleThreatObject) {
  const layout = window.location.pathname.split("/")[3];
  const urlKey = window.location.pathname.split("/")[2];
  return async (dispatch) => {
    dispatch({
      type: W_GRID_INSERT_SAMPLE_DATA,
      sampleObject,
      sampleThreatObject,
      layout,
      urlKey,
    });
  };
}
