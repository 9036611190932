//--------LIBRARIES--------
import React, { useState } from "react";
import { Badge, Button, Col, Row, Space, message } from "antd";
import SearchTypeDropdown from "../../../watchFilter/newCommonComponent/SearchTypeDropdown";
import {
  CheckCircleTwoTone,
  CloudOutlined,
  DeleteOutlined,
  FilterFilled,
  PlaySquareOutlined,
  UploadOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import axios from "axios";
import { ROOT_URL } from "../../../../../configs/rootConfigs";
import { useDispatch, useSelector } from "react-redux";
import {
  getData,
  getFilters,
} from "../../../../../redux/actions/watchActions/brandCompareActions";
import { LOADING } from "../../../../../constants/commonConstants/generalConstants";
import CheckableTagComponent from "../../../watchFilter/newCommonComponent/CheckableTagComponent";
import {
  default_filter_page,
  default_filter_page_size,
} from "../../../watchFilter/utils/utils";
import UpdateGridShortlistedComponent from "../../../watchFilter/newCommonComponent/GridLayout/UpdateGridShortlistedComponent";
const Header = (props) => {
  const useMemoizedComponent = (Component, deps) => {
    return React.useMemo(() => Component, deps);
  };

  const {
    threatFlag,
    classes,
    setClasses,
    proprietors,
    setProprietors,
    setExcludeProprietors,
    generateFilterObject,
    searchValue,
    setSearchValue,
    primaryFilterApplied,
    setPrimaryFilterApplied,
    selected,
    setSelected,
    selectAll,
    setSelectAll,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
    isTourOpen,
    setIsTourOpen,
  } = props;

  const { status, pageNumber } = useSelector(
    (state) => state.watchState.brandCompare.uploadDashboard,
  );

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const isAnyFilterSelected = (filterObject) => {
    const { contains_term, brand_classes, brand_proprietors } = filterObject;

    let anyFilterSelected =
      contains_term?.value ||
      brand_classes?.value.length ||
      brand_proprietors?.value;
    return Boolean(anyFilterSelected);
  };

  let filterSelected = isAnyFilterSelected(generateFilterObject());

  const handleDelete = async () => {
    const filters = generateFilterObject();

    let body = {
      selected_ids: selected,
      threat_flag: threatFlag,
      filter_flag: selectAll,
      filters: filters,
    };
    await axios
      .delete(
        `${ROOT_URL}/brand_compare/manage/dashboard/`,

        {
          data: body,
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        },
      )
      .then((response) => {
        setLoading(false);
        if (response?.data?.success) {
          console.log("Deleted:", response?.data?.message);
          message.success(response?.data?.message);
        } else {
          console.error("Error:", response?.data?.message);
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error?.message);
        message.error("Failed to Delete. Please try again.");
      });
    setSelected([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setSelectAll(false);
    dispatch(getData(threatFlag, pageNumber, filterSelected, filters));

    const filterKeys = ["brand_proprietors", "brand_classes"];
    filterKeys.map((item) =>
      dispatch(
        getFilters(
          threatFlag,
          item,
          null,
          default_filter_page,
          default_filter_page_size,
        ),
      ),
    );
  };

  const handleDownload = () => {
    setLoading(true);
    axios
      .post(
        `${ROOT_URL}/brand_compare/manage/download_portfolio/`,
        {
          product: "watch",
          subscription: "brand compare",
          threat_flag: props?.threatFlag,
        },
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        },
      )
      .then((response) => {
        setLoading(false);
        if (response?.data?.success) {
          console.log("Report queued:", response?.data?.message);
          message.success(response?.data?.message);
        } else {
          console.error("Error:", response?.data?.message);
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error.message);
        message.error("Failed to download portfolio. Please try again.");
      });
  };

  const handleApplyFilters = () => {
    setPrimaryFilterApplied(true);

    const filters = generateFilterObject();
    dispatch(getData(threatFlag, 1, filterSelected, filters));
  };

  const handleResetFilters = () => {
    setPrimaryFilterApplied(false);
    setSearchValue("");
    setClasses([]);
    setProprietors([]);
    setExcludeProprietors([]);
    // props?.handleEmptySelectedData();
    dispatch(getData(threatFlag, pageNumber, false, {}));
    // dispatch(resetAllFilter());
  };

  const UploadButton = (
    <Button
      id="bc_upload"
      icon={<UploadOutlined />}
      type="primary"
      onClick={() => props?.setOpenUpload(true)}
    >
      Upload {props?.threatFlag ? "Threat Brand" : "Your Brand"}
    </Button>
  );

  console.log(!selected.length || !selectAll);
  const DeleteButton = (
    <Badge
      overflowCount={9999}
      count={selectAll ? "ALL" : selected.length}
      style={{ margin: "0px 12px" }}
    >
      <Button
        icon={<DeleteOutlined />}
        type="danger"
        onClick={() => handleDelete()}
        style={{
          backgroundColor: selected.length || selectAll ? "#FDE5E7" : "#ededed",
          color: selected.length || selectAll ? "red" : "grey",
          display: "flex",
          alignItems: "center",
          margin: "0px 12px",
        }}
        loading={loading ? true : false}
        disabled={selected.length || selectAll ? false : true}
      >
        Delete
      </Button>
    </Badge>
  );
  const DownloadButton = (
    <Button
      icon={<CloudOutlined />}
      id="bc_download"
      type="primary"
      onClick={() => handleDownload()}
      style={{ marginLeft: "8px" }}
      loading={loading ? true : false}
    >
      Download Portfolio
    </Button>
  );

  return (
    <>
      <Row style={{ padding: "8px 16px" }}>
        <Col>
          <Space>
            {useMemoizedComponent(
              <SearchTypeDropdown
                value={"Application No, Brand"}
                // setValue={setSearchType}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                listOfSearchType={[]}
              />,
              [searchValue],
            )}
            <Button
              type="primary"
              icon={<FilterFilled />}
              disabled={!filterSelected || (status === LOADING ? true : false)}
              onClick={handleApplyFilters}
              loading={status === LOADING ? true : false}
            >
              Apply
            </Button>
            <Button
              type="text"
              danger
              onClick={() => handleResetFilters()}
              disabled={!primaryFilterApplied}
            >
              Reset All
            </Button>
          </Space>
        </Col>

        <Col
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
            marginRight: "8px",
            marginLeft: "auto",
          }}
        >
          {/* {(selected.length || selectAll) && DeleteButton} */}
          {DeleteButton}
          {UploadButton}
          {DownloadButton}
          {
            <div
              id="bc_update_status"
              style={{
                paddingLeft: "8px",
              }}
            >
              <UpdateGridShortlistedComponent
                source={"LIST_LAYOUT"}
                title={"Update Status"}
                addLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Activate</span>
                    <span style={{ marginLeft: "8px" }}>
                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                    </span>
                  </div>
                }
                removeLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Deactivate</span>
                    <span style={{ marginLeft: "8px" }}>
                      {" "}
                      <WarningTwoTone />
                    </span>
                  </div>
                }
                creationOrigin={"BRAND_COMPARE"}
                selected={selected}
                filterFlag={primaryFilterApplied}
                filter={generateFilterObject()}
                selectAll={selectAll}
                disabled={!(selected.length || selectAll)}
                pageNumber={pageNumber}
                threatFlag={threatFlag}
                onSuccess={handleApplyFilters}
              />
            </div>
          }
          {
            <div
              style={{
                marginLeft: "8px",
              }}
            >
              <CheckableTagComponent
                label={<PlaySquareOutlined />}
                value={isTourOpen}
                setValue={async (v) => {
                  // if (!isTableDataPresent) {
                  //   dispatch(addListSampleData(listSampleObject));
                  // }
                  // if (urlKey === "journals") {
                  //   await setDataView("table");
                  // }
                  setIsTourOpen(v);
                }}
                disableTooltip="Take Tour"
                activeTooltip="Take Tour"
              />
            </div>
          }
        </Col>
      </Row>
    </>
  );
};

export default Header;
