//--------LIBRARIES--------
import {
  Table,
  Row,
  Col,
  Image,
  Space,
  Tag,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  StarFilled,
  FilterFilled,
  WarningTwoTone,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ProprietorFilterComponent from "../../../watchFilter/newCommonComponent/ProprietorFilterComponent";
import SelectFilter from "../../../watchFilter/newCommonComponent/SelectFilter";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import { LOADING } from "../../../../../constants/commonConstants/generalConstants";
import {
  getData,
  getFilters,
} from "../../../../../redux/actions/watchActions/brandCompareActions";
import {
  default_filter_page,
  default_filter_page_size,
} from "../../../watchFilter/utils/utils";
import UploadModal from "./Modals/Upload";

const ListContent = (props) => {
  const { threatFlag } = props;
  const { watch: watchAlert } = useSelector((state) => state.alertMessages);

  const {
    proprietors,
    setProprietors,
    excludeProprietors,
    setExcludeProprietors,
    setProprietorsSearchTerm,
    proprietorsSearchTerm,
    classSearchTerm,
    setClassSearchTerm,
    classes,
    setClasses,
    generateFilterObject,
    selected,
    setSelected,
    selectAll,
    setSelectAll,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
    openUpload,
    setOpenUpload,
  } = props;

  const { proprietorConfig, classConfig } = useSelector(
    (state) => state.watchState.brandCompare.uploadDashboardFilters,
  );

  const { tableData, count, pageNumber, status } = useSelector(
    (state) => state.watchState.brandCompare.uploadDashboard,
  );

  // Add Filters here.....
  const getColumnFilter = (dataIndex) => ({
    filterIcon: () => {
      let filtered = false;
      // filtered =
      //   (dataIndex === "classes" &&
      //     (tmClass?.length ||
      //       sameClassInfringement ||
      //       excludeSameClassInfringement)) ||
      //   (dataIndex === "proprietor" &&
      //     (proprietors?.length || excludeProprietors?.length));
      return (
        <FilterFilled
          id={`watch_table_filter_icon_id_${dataIndex}`}
          style={{
            color: filtered ? "#1677ff" : "black",
          }}
        />
      );
    },
    filterDropdown: ({ visible }) => (
      <>
        {dataIndex === "brand_proprietors" && visible && (
          <ProprietorFilterComponent
            filteredData={proprietorConfig}
            key={proprietorConfig.key}
            value={proprietors}
            setValue={setProprietors}
            loading={proprietorConfig.loading}
            journal_number={null}
            exclude={excludeProprietors}
            setExclude={setExcludeProprietors}
            tableView
            current_template_id={null}
            // filterPath={getPrimaryFilterPath()}
            shouldUpdateStore={false}
            threatFlag={threatFlag}
            handleSearchTerm={setProprietorsSearchTerm}
            searchTerm={proprietorsSearchTerm}
          />
        )}
        {dataIndex === "brand_classes" && visible && (
          <SelectFilter
            filteredData={classConfig}
            key={classConfig.key}
            value={classes}
            setValue={setClasses}
            loading={classConfig.loading}
            journal_number={null}
            tableView
            current_template_id={null}
            shouldUpdateStore={false}
            handleSearchTerm={setClassSearchTerm}
            searchTerm={classSearchTerm}
            threatFlag={threatFlag}
          />
        )}
        {/* {dataIndex === "brand_classes" && visible && (
          <SelectFilter
            filteredData={classConfig}
            key={classConfig.key}
            value={tmClass}
            setValue={setTMClass}
            loading={classConfig.loading}
            journal_number={journal}
            extraChildren={extraClassChildren}
            tableView
            showSwitch
            andOr={andOrClass}
            setAndOr={setAndOrClass}
            current_template_id={current_template_id}
            filterPath={getPrimaryFilterPath()}
          />
        )} */}
      </>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });
  const dispatch = useDispatch();

  // let filterSelected = props?.isAnyFilterSelected(generateFilterObject());
  let filterSelected = false;

  useEffect(() => {
    const filters = generateFilterObject();
    dispatch(getData(threatFlag, pageNumber, filterSelected, filters));

    const filterKeys = ["brand_proprietors", "brand_classes"];
    filterKeys.map((item) =>
      dispatch(
        getFilters(
          threatFlag,
          item,
          null,
          default_filter_page,
          default_filter_page_size,
        ),
      ),
    );
  }, [threatFlag]);

  const handleNextpage = (pageNumber) => {
    const filters = generateFilterObject();
    dispatch(getData(threatFlag, pageNumber, filterSelected, filters));
  };

  const columns = [
    {
      title: "Brand",
      width: 120,
      className: "tm-protect-column-1",
      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.brand_name) ? record?.brand_name : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },

    {
      title: "Application No",
      width: 120,
      className: "tm-protect-column-1",
      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.application_number)
                    ? record?.application_number
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      title: "Image",
      width: 60,
      className: "tm-protect-column-1",
      render: (text, record) => {
        return {
          children: <div>NA</div>,
        };
      },
    },
    {
      title: "Proprietor",
      width: 180,
      className: "tm-protect-column-1",
      ...getColumnFilter("brand_proprietors"),
      render: (text, record) =>
        record?.proprietor?.map((element, index, array) => (
          <p key={element.id}>
            {checkValue(element)
              ? `${element.name}${index + 1 === array?.length ? "" : " | "}`
              : "N.A."}
          </p>
        )),
    },
    {
      title: "Class",
      ...getColumnFilter("brand_classes"),
      className: "tm-protect-column-1",
      width: 60,
      render: (text, record) => record?.associated_class?.join(", "),
    },

    {
      title: "Status",
      className: "tm-protect-column-1",
      width: 60,
      render: (text, record) => (
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {record?.is_active ? (
            <Tooltip title={"Active"}>
              {" "}
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </Tooltip>
          ) : (
            <Tooltip title={"Inactive"}>
              {" "}
              <WarningTwoTone />
            </Tooltip>
          )}
        </p>
      ),
    },
  ];

  const onSelectChange = (record, selected) => {
    if (selected) {
      let newSelected = [...selectedRows, record];
      setSelected(newSelected.map((x) => x.id));
      setSelectedRowKeys(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    } else {
      let newSelected = selectedRows.filter((x) => x.id !== record.id);

      setSelected(newSelected.map((x) => x.id));
      setSelectedRowKeys(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    }
  };
  const rowSelection = {
    renderCell: function (checked, record, index, originNode) {
      return (
        <div
          className="tmapplied_selection_cols"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "-16px",
            }}
          >
            <div style={{ margin: "4px" }}>
              {" "}
              {index + 1 + (pageNumber - 1) * 100}.{" "}
            </div>
            <div> {originNode}</div>
          </div>
          {record?.is_shortlist && (
            <Tooltip title="Shortlisted">
              <StarFilled
                style={{
                  margin: "8px 8px 8px 28px",
                  color: "#FFA701",
                  fontSize: "18px",
                }}
              />
            </Tooltip>
          )}
        </div>
      );
    },
    selectedRowKeys: selected,
    onSelect: onSelectChange,
    selections: null,
    onSelectAll: (selected, selectedRows) => {
      setSelectAll(selected);
      // setSelected(selectedRows);
      if (selected) {
        notification.open({
          message: "Deselect All Mark",
          description:
            "Click on the arrow near the select all checkbox to deselect all marks",
        });
      }
    },
    selections: selectAll
      ? [
          {
            key: "all-data",
            text: "Deselect All Data",
            onSelect: (changeableRowKeys) => {
              setSelectAll(false);
              setSelected([]);
              setSelectedRowKeys([]);
              setSelectedRows([]);
            },
          },
        ]
      : false,
    getCheckboxProps: (record) => ({
      disabled: selectAll,
    }),
  };

  const table = (
    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ padding: "0px 16px" }}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          className={"tm-protect-table"}
          bordered
          dataSource={tableData.length > 0 ? tableData : []}
          scroll={
            watchAlert === ""
              ? { y: "calc(80vh - 150px)" }
              : {
                  y: "calc(80vh - 180px)",
                }
          }
          loading={status === LOADING ? true : false}
          rowSelection={rowSelection}
          pagination={{
            pageSize: 100,
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pageNumber,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} `,
            total: count,
            onChange: handleNextpage,
          }}
        />
      </Col>
      <UploadModal
        threatFlag={threatFlag}
        openUpload={openUpload}
        onCancel={() => setOpenUpload(false)}
        onSuccess={() => {
          const filters = generateFilterObject();
          dispatch(getData(threatFlag, pageNumber, filterSelected, filters));

          const filterKeys = ["brand_proprietors", "brand_classes"];
          filterKeys.map((item) =>
            dispatch(
              getFilters(
                threatFlag,
                item,
                null,
                default_filter_page,
                default_filter_page_size,
              ),
            ),
          );
        }}
      />
    </Row>
  );
  return <>{table}</>;
};

export default ListContent;
