import React, { Component } from "react";
import { Button, Col, message, Modal, Row, Typography } from "antd";
import Axios from "axios";
import { ROOT_URL } from "../../../configs/rootConfigs";

const { Title } = Typography;

export default class TermsAndCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let response;
      response = await Axios.post(
        `${ROOT_URL}/profile/`,
        {
          operation: "TNC_ACCEPTED",
          tnc_accepted: true,
        },
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("tnc_api_token"),
            "content-type": "application/json",
          },
        }
      );
      message.success(
        "Terms and conditions accepted. You can now Login to Mike IPsuite"
      );
      this.props.onCloseModal();
    } catch (err) {
      message.error("Please Login again and accept terms and condition");
      console.log(err.response?.data);
    }
  };
  render() {
    const tnc_accepted = localStorage.getItem("tnc_accepted");

    return (
      <>
        <Modal
          title={
            <div className="alignC">
              <Title level={3} style={{ margin: 0 }}>
                Terms And Condition
              </Title>
            </div>
          }
          open={this.props.visible}
          onOk={this.props.onCloseModal}
          onCancel={this.props.onCloseModal}
          bodyStyle={{ padding: 0 }}
          width={600}
          footer={false}
        >
          <div class="modal-container vertical_scroll">
            <div>
              <p>
                All use of MikeLegal pages located within{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mikelegal.com"
                >
                  www.mikelegal.com
                </a>{" "}
                is subject to the terms and conditions set forth below{" "}
                <strong>(“Terms”)</strong>, provided however that other terms of
                use shall apply with respect to MikeLegal affiliates’ websites,
                as specified on each such website. Any use of such web pages
                constitutes the user’s agreement to abide by the Terms. We may
                amend the Terms at any time by posting the amended terms on this
                site.
              </p>
            </div>
            <div>
              <Title level={4}>PRIVACY</Title>
              <p>
                Please review our Privacy Policy, which also governs your visit
                to our website. A link to the Privacy Policy may be found on the
                MikeLegal Website.
              </p>
            </div>
            <div>
              <Title level={4}>LICENSE AND SITE ACCESS</Title>
              <p>
                MikeLegal grants you a non-exclusive limited license to access
                and make personal use of this site, subject to the Terms. This
                site and any portion hereof may not be reproduced, duplicated,
                copied, downloaded, sold, resold, visited, or otherwise
                exploited for any commercial purpose without the express written
                consent of MikeLegal. You may not frame or utilize framing
                techniques to enclose any trademark, logo or other proprietary
                information (including images, text, page layout and form) of
                MikeLegal and/or its affiliates without the express written
                consent of MikeLegal. You may not use any meta tags or any other
                “hidden text” utilizing MikeLegal’s name or Marks without the
                express written consent of MikeLegal. Any unauthorized use
                terminates the license granted by MikeLegal.
              </p>
            </div>
            <div>
              <Title level={4}>
                DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
              </Title>
              <p>
                This site is provided by MikeLegal as per the information
                accessible to us from IP India website. Results shown by
                MikeLegal may not be accurate due to a number of factors
                including the algorithms used by MikeLegal and IP India website.
                MikeLegal makes no representations or warranties of any kind,
                express or implied, as to the operation of this site, search
                results provided on this site or the information, content or
                materials included on this site. Any liability for any losses or
                claims arising from an inability to access this site, or from
                any use of this site or reliance on any data transmitted using
                this site is excluded to the fullest extent permissible by law.
                The information or search results provided on this site are for
                indicative purposes only and are not an alternative to official
                or certified records of IP India. In no event shall any
                information or search results from this site be reliable in any
                legal proceedings and MikeLegal should not be required to appear
                in Court to verify the authenticity of the data. MikeLegal will
                not be liable for any indirect loss, consequential loss, loss of
                profit, data, revenue, business opportunity, anticipated
                savings, goodwill or reputation arising out of or in connection
                with the terms or use of the site. You also agree to indemnify
                Mike Legal for any breach of the terms and conditions set out
                herein. You expressly agree that your use of this site is at
                your sole risk.
              </p>
            </div>
            <div>
              <Title level={4}>ELECTRONIC COMMUNICATIONS</Title>
              <p>
                When you visit{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mikelegal.com"
                >
                  www.mikelegal.com
                </a>{" "}
                or send e-mails to us, you are communicating with us
                electronically. You consent to receive communications from us
                electronically. We will communicate with you by e-mail or by
                posting notices on this site. You agree that all agreements,
                notices, disclosures and other communications that we provide to
                you electronically satisfy any legal requirement that such
                communications be in writing.
              </p>
            </div>
            <div>
              <Title level={4}>COPYRIGHT</Title>
              <p>
                All content provided on this website together with the website
                design, text and graphics, and their selection and arrangement,
                and all software compilations, underlying source code and
                software (including applets) is owned by or licensed to
                MikeLegal and/or its affiliates (the “MikeLegal Content”) and
                protected by Indian copyright laws. MikeLegal retains all
                proprietary rights to the content created MikeLegal. The
                MikeLegal Content may not be reproduced, transmitted or
                distributed without the prior written consent of MikeLegal.
                MikeLegal captures trademark information from the Trademark
                Registry's website. MikeLegal cannot authenticate any
                information captured from Trademark Registry's website.
              </p>
            </div>
            <div>
              <Title level={4}>USERNAME AND PASSWORD</Title>
              <p>
                On registering with MikeLegal, you are issued with a username
                and password which must be used to access certain restricted
                parts of this site. These username and passwords are made
                available to you by MikeLegal on you subscribing to one or more
                services offered by MikeLegal. The username and password are
                personal to you and are not transferable. Your name and password
                are the methods used by MikeLegal to identify you and so are
                very important. You are responsible for all information posted
                on this site by anyone using your username and password and any
                payments due for services accessed through this site by anyone
                using your username and password. Any breach of security of a
                username and password should be notified to MikeLegal
                immediately. You may not adapt or circumvent the systems in
                place in connection with this site, nor access this site other
                than through normal operations. Once subscribed to MikeLegal, no
                refund will be provided to you on any grounds for the
                subscription period. MikeLegal cannot be held responsible for
                you not using it for any reason.
              </p>
            </div>
            <div>
              <Title level={4}>DATA SUBMITTED BY USERS</Title>
              <p>
                MikeLegal accepts no liability for data supplied by any user for
                display on this site, including search data. If you submit data
                for display on this site you are responsible for ensuring that
                the data is accurate, complete and up to date and for updating
                that data where necessary. You are also responsible for ensuring
                that no data is uploaded or submitted which is untrue,
                defamatory, obscene or abusive or otherwise objectionable or in
                breach of any applicable laws or rights of third parties.
                MikeLegal reserves the right (without limiting its rights to
                seek other remedies) to remove offending material placed on this
                site that we consider to constitute a misuse of this site or
                which is otherwise harmful to other users of this site. You will
                indemnify MikeLegal for any claim or loss (including without
                limitation, economic loss) suffered by MikeLegal arising out of
                your failure to observe any of the terms of this condition.
              </p>
            </div>
            <div>
              <Title level={4}>TERMINATION</Title>
              <p>
                MikeLegal may terminate your access to this site and the
                services within it on written notice to you. All disclaimers,
                indemnities and exclusions in the Terms shall survive
                termination of the agreement between you and MikeLegal for any
                reason.
              </p>
            </div>
          </div>
          {tnc_accepted === "false" && (
            <div>
              <Row>
                <Col
                  md={12}
                  style={{ padding: "12px" }}
                  onClick={this.props.onCloseModal}
                >
                  <Button style={{ width: "100%" }}>Reject</Button>
                </Col>
                <Col md={12} style={{ padding: "12px" }}>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={this.handleSubmit}
                  >
                    Accept
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Modal>
      </>
    );
  }
}
