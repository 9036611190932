//--------LIBRARIES--------
import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Modal,
  Row,
  Typography,
  Col,
  Button,
  Table,
  Tooltip,
  Popconfirm,
  notification,
} from "antd";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import EditClientEmail from "./EditClientEmail";
import AddClientEmailForm from "./AddClientEmailForm";

const PATENT = "PATENT";
const TM_PROTECT = "TM_PROTECT";

function ClientEmailDetails({
  onCancel,
  selectedSubscription,
  mmType,
  visible,
  emailEntity = {
    application_number: "",
    applied_for: "",
    associated_image: "",
    client_emails: "",
    id: "",
    proprietors: "",
    term: "",
  },
  setStore,
  handleDelete,
  loading,
  resetState,
}) {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const { Title, Text } = Typography;
  const {
    application_number,
    applied_for,
    associated_image,
    client_emails,
    id,
    proprietors,
    term,
  } = (emailEntity && emailEntity()) || {};

  useEffect(() => {}, [emailEntity?.client_emails]);

  const handleEdit = (eachMail) => {
    setStore({
      client_email_id: eachMail?.id,
      client_email: eachMail?.email,
      person_name: eachMail?.person_name,
      company_name: eachMail?.company_name,
    });

    setEditModalVisible(true);
  };

  const ClientEmailDetailColumns = [
    {
      title: "EMAIL ",
      dataIndex: "email",
      render: (text) => <p>{checkValue(text) ? text.toLowerCase() : "N.A."}</p>,
    },
    {
      title: "PERSON NAME",
      dataIndex: "person_name",
      render: (text) => (
        <p className="text-camelCase">
          {checkValue(text) ? text.toLowerCase() : "N.A."}
        </p>
      ),
    },
    {
      title: "COMPANY NAME",
      dataIndex: "company_name",
      render: (text) => (
        <p className="text-camelCase">
          {checkValue(text) ? text.toLowerCase() : "N.A."}
        </p>
      ),
    },

    {
      title: "ACTIONS",
      dataIndex: "application_number",
      key: "actions",
      width: "16%",
      padding: "16px",
      render: (application_number, record) => {
        return (
          <Row>
            <Col onClick={() => handleEdit(record)}>
              <Tooltip title="Edit">
                <EditOutlined
                  style={{
                    color: "#1890ff",
                    fontSize: "20px",
                    margin: "8px 16px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
            <Col>
              <Popconfirm
                title="Are you sure to delete this email?"
                onConfirm={() => handleDelete(record, "SINGLE_EMAIL")}
                okText="Yes"
                cancelText="No"
              >
                <span>
                  <Tooltip title="Delete">
                    <DeleteOutlined
                      style={{
                        color: "rgb(255 76 76)",
                        fontSize: "20px",
                        margin: "8px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </span>{" "}
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  let infoBar = (
    <Row type="flex" justify="space-between">
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <span>
          <span
            style={{
              fontWeight: 700,
              fontSize: 16,
            }}
          >
            Application Number:{" "}
          </span>
        </span>
        <span>
          <Text type="secondary">
            {application_number ? application_number : ""}
          </Text>
        </span>{" "}
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <span>
          <span
            style={{
              fontWeight: 700,
              fontSize: 16,
            }}
          >
            Applied For:{" "}
          </span>
        </span>
        <span>
          <Text type="secondary">{applied_for ? applied_for : ""}</Text>
        </span>{" "}
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <span>
          <span
            style={{
              fontWeight: 700,
              fontSize: 16,
            }}
          >
            Count:{" "}
          </span>
        </span>
        <span>
          <Text type="secondary">
            {client_emails && client_emails.length > 0
              ? client_emails.length
              : 0}
          </Text>
        </span>{" "}
      </Col>
    </Row>
  );
  if (selectedSubscription === PATENT) {
    infoBar = (
      <Row type="flex" justify="space-between">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <span>
            <span
              style={{
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Application Number:{" "}
            </span>
          </span>
          <span>
            <Text type="secondary">
              {application_number ? application_number : ""}
            </Text>
          </span>{" "}
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <span>
            <span
              style={{
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Count:{" "}
            </span>
          </span>
          <span>
            <Text type="secondary">
              {client_emails && client_emails.length > 0
                ? client_emails.length
                : 0}
            </Text>
          </span>{" "}
        </Col>
      </Row>
    );
  } else if (selectedSubscription === TM_PROTECT) {
    infoBar = (
      <Row type="flex" justify="space-between">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <span>
            <span
              style={{
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Term:{" "}
            </span>
          </span>
          <span>
            <Text type="secondary">{term ? term : ""}</Text>
          </span>{" "}
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <span>
            <span
              style={{
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Count:{" "}
            </span>
          </span>
          <span>
            <Text type="secondary">
              {client_emails && client_emails.length > 0
                ? client_emails.length
                : 0}
            </Text>
          </span>{" "}
        </Col>
      </Row>
    );
  }

  const titleBar = (
    <Row justify="space-between">
      <Col span={12}>
        <Title level={3}>Manage Emails</Title>
      </Col>

      <Col span={3}>
        <Button
          type="primary"
          onClick={() => {
            if (
              client_emails &&
              client_emails.length > 0 &&
              client_emails.length >= 10
            ) {
              notification["info"]({
                message: "Email Limit Exceeded",
                description: "You can link upto 10 emails only.",
              });
            } else {
              setCreateModalVisible(true);
            }
          }}
        >
          Add Email
        </Button>
      </Col>
    </Row>
  );
  return (
    <Modal
      open={visible}
      // maskClosable={maskCosable}
      onCancel={onCancel}
      footer={null}
      width={"1000px"}
      style={{ top: "32px" }}
    >
      <Row style={{ paddingTop: "20px" }}>
        <Col span={24}>{titleBar}</Col>
        {/* <Row>
            <Col xs={{ span: 21 }} lg={{ span: 21 }}>
              <Title level={3}>Manage Emails</Title>
            </Col>
            <Col
              xs={{ span: 2 }}
              lg={{ span: 2 }}
              // lg={this.props.selectedSubscription === "TRADEMARK" ? { span: 8, offset: 1 } :  { span: 8, offset: 1 } }
              className="alignR"
            >
              <Button
                type="primary"
                onClick={() => {
                  if (
                    client_emails &&
                    client_emails.length > 0 &&
                    client_emails.length >= 10
                  ) {
                    notification["info"]({
                      message: "Email Limit Exceeded",
                      description: "You can link upto 10 emails only.",
                    });
                  } else {
                    setCreateModalVisible(true);
                  }
                }}
              >
                Add Email
              </Button>
            </Col>
          </Row> */}
        <Col span={24}>{infoBar}</Col>
      </Row>
      <Row style={{ margin: "20px 0px" }} className="client-email">
        <Table
          rowKey={(record) => record.id}
          columns={ClientEmailDetailColumns}
          dataSource={
            client_emails && client_emails.length ? client_emails : []
          }
          scroll={{ y: "calc(800vh - 240px)" }}
          loading={loading}
          style={{ minHeight: "60vh" }}
          pagination={{
            disabled: true,
            hideOnSinglePage: true,
            pageSize: 20,
          }}
        />
      </Row>
      <AddClientEmailForm
        visible={createModalVisible}
        onCloseModal={() => {
          setCreateModalVisible(false);
        }}
        onConfirm={() => {
          resetState();
        }}
        mmType={mmType}
      />
      <EditClientEmail
        visible={editModalVisible}
        onCloseModal={() => {
          setEditModalVisible(false);
        }}
        onConfirm={() => {
          resetState();
        }}
        selectedSubscription={selectedSubscription}
        mmType={mmType}
      />
    </Modal>
  );
}

export default ClientEmailDetails;
