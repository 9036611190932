import React, { useState } from "react";
import { Upload, message } from "antd";
const ImgSearchInput = ({ imgFileList, setSearchFile }) => {
  const [fileList, setFileList] = useState(
    imgFileList !== null
      ? () => {
          let parsedFile = JSON.parse(imgFileList);
          return [parsedFile];
        }
      : [],
  );

  const handleUploadChange = async (info) => {
    let fileList = [...info.fileList];

    // Limiting to 1 file
    fileList = fileList.slice(-1);

    // Convert uploaded file to Base64 before displaying
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(fileList);
    setSearchFile(fileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error("Image must smaller than 20MB!");
    }
    return false;
  };

  const ImageUploader = (
    <div style={{ margin: "2%", minWidth: "50vh" }}>
      <Upload
        accept=".png, .jpg, .jpeg"
        listType="picture-card"
        fileList={fileList}
        onChange={handleUploadChange}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
      >
        + Upload
      </Upload>
    </div>
  );

  return ImageUploader;
};
export default ImgSearchInput;
