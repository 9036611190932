//--------WATCH TYPES--------
import {
  TS_GET_SEARCH_RESULTS,
  TS_GET_SEARCH_FILTERS,
  TS_GET_SEARCH_ANALYSIS,
  CHANGE_SEARCH_RESULTS_TAB,
  SET_SEARCH_INFO,
  TS_SELECT_VIEW,
  TS_SET_SEARCHES_CANCEL,
  TS_GET_REPORT_AVAILABILITY,
  TS_SET_ANALYSIS_CANCEL,
  TS_SET_FILTERS_CANCEL,
  TS_SET_REMINDER_STAMP,
  TS_SET_STATE,
  TS_SET_DEFAULT_STATE,
  SET_FILE,
} from "../../actions/searchActions/types";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

//--------TRADEMARKS SEARCH CONSTANTS--------
import {
  TS_trademarksSearchState,
  TS_selectedFilters,
  TS_singlewordSearch,
  TS_multiwordSearch,
} from "../../../constants/searchConstants/trademarksSearchConstants.js";

const defaultSearchTabResults = {
  count: 0,
  results: [],
  analysis: null,
  analysisStatus: SUCCESS,
  pageNumber: 0,
  status: SUCCESS,
  searchParameters: {},
  searchLogID: null,
  fileList: null,
  selectAll: false,
};

const defaultFilters = {
  statuses: [],
  states: [],
  classes: [],
  types: [],
  proprietors: [],
};

const defaultTrademarksSearchState = {
  Fuzzy: defaultSearchTabResults,
  Standard: defaultSearchTabResults,
  Contains: defaultSearchTabResults,
  Wildcard: defaultSearchTabResults,
  Phonetic: defaultSearchTabResults,
  Initials: defaultSearchTabResults,
  Boolean: defaultSearchTabResults,
  ImageSearch: defaultSearchTabResults,
  filters: defaultFilters,
  filtersStatus: SUCCESS,
  trademarksSearchAlgo: "Flash",
  activeTab: "Fuzzy",
  userTrademark: {},
  multiwordTrademark: false,
  selectedView: "1",
  axiosCancelSearches: {},
  axiosCancelAnalysis: {},
  axiosCancelFilters: null,
  reportAvailability: null,
  reportAvailabilityStatus: SUCCESS,
  searchCounter: -1,
  reminderStamp: null,
  searchStamp: null,
  searchStatus: null,
  globalSelectAll: false,
  searchState: TS_trademarksSearchState,
};

export default function (state = defaultTrademarksSearchState, action) {
  switch (action.type) {
    case SET_FILE:
      return {
        ...state,
        ImageSearch: {
          ...state.ImageSearch,
          fileList: action.payload,
        },
      };

    case TS_SET_STATE:
      return {
        ...state,
        searchState: {
          ...state.searchState,
          ...action.payload,
        },
      };

    case TS_SET_DEFAULT_STATE:
      return {
        ...state,
        searchState: TS_trademarksSearchState,
      };

    case TS_GET_SEARCH_RESULTS:
      switch (action.status) {
        case SUCCESS:
          //IPIndia only requires searchStatus from redux.
          if (action?.searchTab === "ipIndia") {
            return {
              ...state,
              searchStatus: action.status,
            };
          }

          let { applicationsIDs } = state.searchState.customReportData;

          const currentTMSelections = action.payload.results
            .filter((tm) => {
              return applicationsIDs.includes(tm.id);
            })
            .map((x) => x.id);
          const selections = [
            ...currentTMSelections,
            ...state.searchState.customReportData[
              action.searchTab.toLowerCase()
            ].selected,
          ];
          const uniquecurrentTMSelections = [...new Set(selections)];

          return {
            ...state,
            searchState: {
              ...state.searchState,
              customReportData: {
                ...state.searchState.customReportData,
                [action.searchTab.toLowerCase()]: {
                  ...state.searchState.customReportData[
                    action.searchTab.toLowerCase()
                  ],
                  selected: uniquecurrentTMSelections,
                  select_all: false,
                },
              },
            },
            [action.searchTab]: {
              ...state[action.searchTab],
              count: action.payload.count,
              results: action.payload.results,
              pageNumber: action.pageNumber,
              status: action.status,
              searchParameters: action.searchParameters,
              searchLogID: action.payload.search_log_id
                ? action.payload.search_log_id
                : state[action.searchTab].searchLogID,
              selectAll: action.selectAll,
            },
            searchCounter: action.searchParameters.search_log_id
              ? state.searchCounter
              : state.searchCounter - 1,
            searchStatus: action.status,
            globalSelectAll: false,
          };

        case LOADING:
          return {
            ...state,
            [action.searchTab]: {
              ...state[action.searchTab],
              status: action.status,
              searchParameters: action.searchParameters,
            },
            searchStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            [action.searchTab]: {
              ...state[action.searchTab],
              status: action.status,
            },
            searchCounter: state.searchCounter - 1,
            searchStatus: action.status,
          };

        default:
          return state;
      }

    case TS_GET_SEARCH_FILTERS:
      switch (action.status) {
        case SUCCESS:
          // let types = {};
          // [...state.filters.types, ...action.payload.filters.type].map( e => types = {...types, [e.id]: e});
          // types = Object.values(types);

          // let proprietors = {};
          // [...state.filters.proprietors, ...action.payload.filters.proprietor].map( e => proprietors = {...proprietors, [e.id]: e});
          // proprietors = Object.values(proprietors);

          return {
            ...state,
            filters: {
              ...state.filters,
              statuses: action.payload.filters.status,
              states: action.payload.filters.state,
              classes: action.payload.filters.class,
              types: action.payload.filters.type,
              proprietors: action.payload.filters.proprietor,
            },
            filtersStatus: action.status,
            searchCounter: state.searchCounter - 1,
          };

        case LOADING:
          return {
            ...state,
            filtersStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            filtersStatus: action.status,
            searchCounter: state.searchCounter - 1,
          };

        default:
          return state;
      }

    case TS_GET_SEARCH_ANALYSIS:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            [action.searchTab]: {
              ...state[action.searchTab],
              analysis: action.payload.analysis,
              analysisStatus: action.status,
            },
          };

        case LOADING:
        case ERROR:
          return {
            ...state,
            [action.searchTab]: {
              ...state[action.searchTab],
              analysisStatus: action.status,
            },
          };

        default:
          return state;
      }

    case SET_SEARCH_INFO:
      const {
        trademarksSearchAlgo,
        activeTab,
        userTrademark,
        multiwordTrademark,
        axiosCancelSearches,
        searchCounter,
        reminderStamp,
        searchStamp,
      } = action;
      return {
        ...state,
        trademarksSearchAlgo,
        activeTab,
        userTrademark,
        multiwordTrademark,
        axiosCancelSearches,
        searchCounter,
        searchLogIDs: [],
        filtersStatus: LOADING,
        filters: defaultFilters,
        reminderStamp,
        searchStamp,
      };

    case CHANGE_SEARCH_RESULTS_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case TS_SELECT_VIEW:
      return {
        ...state,
        selectedView: action.payload,
      };

    case TS_SET_SEARCHES_CANCEL:
      return {
        ...state,
        axiosCancelSearches: action.payload,
      };

    case TS_SET_ANALYSIS_CANCEL:
      return {
        ...state,
        axiosCancelAnalysis: {
          ...state.axiosCancelAnalysis,
          [action.searchTab]: action.payload,
        },
      };

    case TS_SET_FILTERS_CANCEL:
      return {
        ...state,
        axiosCancelFilters: action.payload,
        searchCounter: state.searchCounter - 1,
      };

    case TS_SET_REMINDER_STAMP:
      return {
        ...state,
        reminderStamp: action.payload,
      };

    case TS_GET_REPORT_AVAILABILITY:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            reportAvailability: action.payload,
            reportAvailabilityStatus: SUCCESS,
          };

        case LOADING:
          return {
            ...state,
            reportAvailabilityStatus: LOADING,
          };

        case ERROR:
          return {
            ...state,
            reportAvailabilityStatus: ERROR,
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
