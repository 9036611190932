import {
  GET_TRADEMARK_REPORT,
  GET_PROPRIETOR_REPORT,
  GET_IPINDIA_REPORT,
  GET_TRADEMARK_REPORT_FILES,
  GET_PROPRIETOR_REPORT_FILES,
  GET_IPINDIA_REPORT_FILES,
  GET_TRADEMARK_REPORT_DETAILS,
  GET_PROPRIETOR_REPORT_DETAILS,
  GET_IPINDIA_REPORT_DETAILS,
  CREATE_SEARCH_REPORT,
  UPDATE_REPORT_MARK,
  DELETE_REPORT,
  DELETE_ALL_REPORT,
  RM_GET_REPORT_AVAILABILITY,
  GET_IMAGE_SEARCH_REPORT,
  GET_IMAGE_SEARCH_REPORT_FILES,
  GET_IMAGE_SEARCH_REPORT_DETAILS,
} from "../../actions/searchActions/types";
import {
  SUCCESS,
  LOADING,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import {
  Report_defaultPrimarySelectedFilters,
  Report_defaultPrimarySorting,
  Report_defaultSecondarySelectedFilters,
  Report_defaultSecondarySorting,
} from "../../../constants/searchConstants/reportConstants";

const defaultInitReport = {
  markReport: null,
  markReportStatus: null,
  markReportAppliedFilters: {
    ...Report_defaultPrimarySelectedFilters,
    filterFlag: false,
  },
  markReportAppliedSorting: {
    ...Report_defaultPrimarySorting,
    sortFlag: false,
  },
  markReportPageNumber: 1,

  proprietorReport: null,
  proprietorReportStatus: null,
  proprietorReportAppliedFilters: {
    ...Report_defaultPrimarySelectedFilters,
    filterFlag: false,
  },
  proprietorReportAppliedSorting: {
    ...Report_defaultPrimarySorting,
    sortFlag: false,
  },
  proprietorReportPageNumber: 1,

  imageSearchReport: null,
  imageSearchReportStatus: null,
  imageSearchReportAppliedFilters: {
    ...Report_defaultPrimarySelectedFilters,
    filterFlag: false,
  },
  imageSearchReportAppliedSorting: {
    ...Report_defaultPrimarySorting,
    sortFlag: false,
  },
  imageSearchReportPageNumber: 1,

  markReportFiles: null,
  markReportFilesStatus: null,
  markReportFilesAppliedFilters: {
    ...Report_defaultPrimarySelectedFilters,
    filterFlag: false,
  },
  markReportFilesAppliedSorting: {
    ...Report_defaultPrimarySorting,
    sortFlag: false,
  },
  markReportFilesPageNumber: 1,

  proprietorReportFiles: null,
  proprietorReportFilesStatus: null,
  proprietorReportFilesAppliedFilters: {
    ...Report_defaultPrimarySelectedFilters,
    filterFlag: false,
  },
  proprietorReportFilesAppliedSorting: {
    ...Report_defaultPrimarySorting,
    sortFlag: false,
  },
  proprietorReportFilesPageNumber: 1,

  imageSearchReportFiles: null,
  imageSearchReportFilesStatus: null,
  imageSearchReportFilesAppliedFilters: {
    ...Report_defaultPrimarySelectedFilters,
    filterFlag: false,
  },
  imageSearchReportFilesAppliedSorting: {
    ...Report_defaultPrimarySorting,
    sortFlag: false,
  },
  imageSearchReportFilesPageNumber: 1,

  ipIndiaReportFiles: null,
  ipIndiaReportFilesStatus: null,
  ipIndiaReportFilesAppliedFilters: {
    ...Report_defaultPrimarySelectedFilters,
    filterFlag: false,
  },
  ipIndiaReportFilesAppliedSorting: {
    ...Report_defaultPrimarySorting,
    sortFlag: false,
  },
  ipIndiaReportFilesPageNumber: 1,

  markReportDetail: null,
  markReportDetailStatus: null,
  markReportDetailAppliedFilters: {
    ...Report_defaultSecondarySelectedFilters,
    filterFlag: false,
  },
  markReportDetailAppliedSorting: {
    ...Report_defaultSecondarySorting,
    sortFlag: false,
  },
  markReportDetailPageNumber: 1,

  proprietorReportDetail: null,
  proprietorReportDetailStatus: null,
  proprietorReportDetailAppliedFilters: {
    ...Report_defaultSecondarySelectedFilters,
    filterFlag: false,
  },
  proprietorReportDetailAppliedSorting: {
    ...Report_defaultSecondarySorting,
    sortFlag: false,
  },
  proprietorReportDetailPageNumber: 1,

  imageSearchReportDetail: null,
  imageSearchReportDetailStatus: null,
  imageSearchReportDetailAppliedFilters: {
    ...Report_defaultSecondarySelectedFilters,
    filterFlag: false,
  },
  imageSearchReportDetailAppliedSorting: {
    ...Report_defaultSecondarySorting,
    sortFlag: false,
  },
  imageSearchReportDetailPageNumber: 1,

  createSearchReport: null,
  createSearchReportStatus: null,

  updateReportMark: null,
  updateReportMarkMessage: null,
  updateReportMarkStatus: null,

  deleteReportMessage: null,
  deleteReportStatus: null,

  deleteAllReportMessage: null,
  deleteAllReportStatus: null,

  reportAvailabilityStatus: SUCCESS,
  reportAvailability: {},
};

export default function (state = defaultInitReport, action) {
  switch (action.type) {
    case GET_TRADEMARK_REPORT:
      return {
        ...state,
        markReport: action.payload,
        markReportStatus: action.status,
        markReportAppliedFilters: action.lastAppliedFilters,
        markReportAppliedSorting: action.lastAppliedSorting,
        markReportPageNumber: action.pageNumber,
      };

    case GET_PROPRIETOR_REPORT:
      return {
        ...state,
        proprietorReport: action.payload,
        proprietorReportStatus: action.status,
        proprietorReportAppliedFilters: action.lastAppliedFilters,
        proprietorReportAppliedSorting: action.lastAppliedSorting,
        proprietorReportPageNumber: action.pageNumber,
      };
    case GET_IMAGE_SEARCH_REPORT:
      return {
        ...state,
        imageSearchReport: action.payload,
        imageSearchReportStatus: action.status,
        imageSearchReportAppliedFilters: action.lastAppliedFilters,
        imageSearchReportAppliedSorting: action.lastAppliedSorting,
        imageSearchReportPageNumber: action.pageNumber,
      };
    case GET_IPINDIA_REPORT:
      return {
        ...state,
        ipIndiaReport: action.payload,
        ipIndiaReportStatus: action.status,
        ipIndiaReportAppliedFilters: action.lastAppliedFilters,
        ipIndiaReportAppliedSorting: action.lastAppliedSorting,
        ipIndiaReportPageNumber: action.pageNumber,
      };

    case GET_TRADEMARK_REPORT_FILES:
      return {
        ...state,
        markReportFiles: action.payload,
        markReportFilesStatus: action.status,
        markReportFilesAppliedFilters: action.lastAppliedFilters,
        markReportFilesAppliedSorting: action.lastAppliedSorting,
        markReportFilesPageNumber: action.pageNumber,
      };

    case GET_PROPRIETOR_REPORT_FILES:
      return {
        ...state,
        proprietorReportFiles: action.payload,
        proprietorReportFilesStatus: action.status,
        proprietorReportFilesAppliedFilters: action.lastAppliedFilters,
        proprietorReportFilesAppliedSorting: action.lastAppliedSorting,
        proprietorReportFilesPageNumber: action.pageNumber,
      };
    case GET_IMAGE_SEARCH_REPORT_FILES:
      return {
        ...state,
        imageSearchReportFiles: action.payload,
        imageSearchReportFilesStatus: action.status,
        imageSearchReportFilesAppliedFilters: action.lastAppliedFilters,
        imageSearchReportFilesAppliedSorting: action.lastAppliedSorting,
        imageSearchReportFilesPageNumber: action.pageNumber,
      };
    case GET_IPINDIA_REPORT_FILES:
      return {
        ...state,
        ipIndiaReportFiles: action.payload,
        ipIndiaReportFilesStatus: action.status,
        ipIndiaReportFilesAppliedFilters: action.lastAppliedFilters,
        ipIndiaReportFilesAppliedSorting: action.lastAppliedSorting,
        ipIndiaReportFilesPageNumber: action.pageNumber,
      };

    case GET_TRADEMARK_REPORT_DETAILS:
      return {
        ...state,
        markReportDetail: action.payload,
        markReportDetailStatus: action.status,
        markReportDetailAppliedFilters: action.lastAppliedFilters,
        markReportDetailAppliedSorting: action.lastAppliedSorting,
        markReportDetailPageNumber: action.pageNumber,
      };

    case GET_PROPRIETOR_REPORT_DETAILS:
      return {
        ...state,
        proprietorReportDetail: action.payload,
        proprietorReportDetailStatus: action.status,
        proprietorReportDetailAppliedFilters: action.lastAppliedFilters,
        proprietorReportDetailAppliedSorting: action.lastAppliedSorting,
        proprietorReportDetailPageNumber: action.pageNumber,
      };
    case GET_IMAGE_SEARCH_REPORT_DETAILS:
      return {
        ...state,
        imageSearchReportDetail: action.payload,
        imageSearchReportDetailStatus: action.status,
        imageSearchReportDetailAppliedFilters: action.lastAppliedFilters,
        imageSearchReportDetailAppliedSorting: action.lastAppliedSorting,
        imageSearchReportDetailPageNumber: action.pageNumber,
      };
    case GET_IPINDIA_REPORT_DETAILS:
      return {
        ...state,
        ipIndiaReportDetail: action.payload,
        ipIndiaReportDetailStatus: action.status,
        ipIndiaReportDetailAppliedFilters: action.lastAppliedFilters,
        ipIndiaReportDetailAppliedSorting: action.lastAppliedSorting,
        ipIndiaReportDetailPageNumber: action.pageNumber,
      };

    case CREATE_SEARCH_REPORT:
      return {
        ...state,
        createSearchReport: action.payload,
        createSearchReportStatus: action.status,
      };

    case UPDATE_REPORT_MARK:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            updateReportMarkStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            markReport: {
              ...state.markReport,
              reports: state.markReport.reports.map((eachRow) =>
                eachRow.id === action.payload.id
                  ? action.payload.response
                  : eachRow,
              ),
            },
            proprietorReport: {
              ...state.proprietorReport,
              reports: state.proprietorReport.reports.map((eachRow) =>
                eachRow.id === action.payload.id
                  ? action.payload.response
                  : eachRow,
              ),
            },
            ipIndiaReport: {
              ...state.ipIndiaReport,
              reports: state.ipIndiaReport.reports.map((eachRow) =>
                eachRow.id === action.payload.id
                  ? action.payload.response
                  : eachRow,
              ),
            },
            updateReportMark: action.payload.response,
            updateReportMarkMessage: action.message,
            updateReportMarkStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            updateReportMarkMessage: action.message,
            updateReportMarkStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case DELETE_REPORT:
      return {
        ...state,
        deleteReportMessage: action.message,
        deleteReportStatus: action.status,
      };

    case DELETE_ALL_REPORT:
      return {
        ...state,
        deleteAllReportMessage: action.message,
        deleteAllReportStatus: action.status,
      };

    case RM_GET_REPORT_AVAILABILITY:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            reportAvailability: action.payload,
            reportAvailabilityStatus: SUCCESS,
          };

        case LOADING:
          return {
            ...state,
            reportAvailabilityStatus: LOADING,
          };

        case ERROR:
          return {
            ...state,
            reportAvailabilityStatus: ERROR,
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
