//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  AppstoreTwoTone,
  DownOutlined,
  MinusCircleOutlined,
  MinusSquareFilled,
  PieChartOutlined,
  PlusSquareFilled,
  UnorderedListOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Dropdown,
  Button,
  Menu,
  Input,
  Card,
  Pagination,
  Radio,
  Checkbox,
  Spin,
  Empty,
  Drawer,
  Tooltip,
  Badge,
  Cascader,
  Typography,
  message,
} from "antd";

//--------SEARCH STYLESHEETS--------
import "../../../stylesheets/searchStyleSheets/search.css";
// import {  } from '../../../redux/actions/searchActions/commonActions';
import GeneralMarkCard from "../../commonComponents/generalCards/GeneralMarkCard";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";

import {
  getSearchResults,
  getProprietorProfileData,
} from "../../../redux/actions/searchActions/commonActions";

import { TPAction, logOut } from "../../../redux/actions/commonActions/actions";
import {
  PP_SELECT_VIEW,
  CHANGE_PROP_RESULTS_TAB,
} from "../../../redux/actions/searchActions/types";

import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import {
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../../functions/commonFunctions/generalFunctions";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import {
  PP_defaultFiltersVisibility,
  PP_defaultSelectedFilters,
  PP_defaultFiltersSearchTerms,
  PP_defaultSorting,
  PP_defaultCustomReportData,
  PP_defaultInitialReport,
} from "../../../constants/searchConstants/proprietorProfile";

import DateRangePicker from "../../../functions/commonFunctions/dateRangePicker";
import ResultsAnalysis from "../../commonComponents/subComponents/ResultsAnalysis";
import CreateSearchReport from "../subComponents/createSearchReport";

import { checkNA } from "../../../functions/commonFunctions/checkValueFunctions";

const { Text, Title } = Typography;
const { Search } = Input;

class ProprietorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range1: null,
      range2: null,
      customReportData: PP_defaultCustomReportData,
      filtersVisibility: PP_defaultFiltersVisibility,
      filtersSearchTerms: PP_defaultFiltersSearchTerms,
      selectedFilters: this.props.proprietorMarkFilters,
      sorting: this.props.proprietorMarkSorting,
      pageNumber: this.props.proprietorMarkPageNumber,
      drawersVisibility: {
        resultsAnalysis: false,
        customReport: false,
      },
      tableDescriptionRowsVisibility: false,
    };

    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.setDate1 = this.setDate1.bind(this);
    this.setDate2 = this.setDate2.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      let proprietor = sessionStorage.getItem("proprietor");
      if (
        window.location.pathname === `/search/proprietor-profile/${proprietor}`
      ) {
        this.props.getProprietorProfileData({
          activeTab: this.props.activeTab,
          proprietor: proprietor,
          operation: "MARKS",
          filterFlag: false,
          filters: PP_defaultSelectedFilters,
          sortFlag: false,
          sorting: PP_defaultSorting,
          pageNumber: 1,
        });

        this.props.getProprietorProfileData({
          activeTab: this.props.activeTab,
          proprietor: proprietor,
          operation: "DATA",
          filterFlag: false,
          filters: PP_defaultSelectedFilters,
          sortFlag: false,
          sorting: PP_defaultSorting,
          pageNumber: 1,
        });

        this.props.getProprietorProfileData({
          activeTab: this.props.activeTab,
          proprietor: proprietor,
          operation: "MARKS_ANALYSIS",
          filterFlag: false,
          filters: PP_defaultSelectedFilters,
          sortFlag: false,
          sorting: PP_defaultSorting,
          pageNumber: 1,
        });
      }
    } else {
      this.props.logOut();
    }
  }

  handleApplyFilters() {
    const proprietor = sessionStorage.getItem("proprietor");
    const selectedFilters = this.state.selectedFilters;
    const {
      filterClasses,
      filterState,
      filterType,
      filterStatus,
      filterProprietor,
      date_of_application,
      date_of_usage,
      date_of_usage_proposed,
      containsTerm,
    } = selectedFilters;
    const sorting = this.props.proprietorMarkSorting;

    if (
      filterClasses?.length > 0 ||
      filterState?.length > 0 ||
      filterType?.length > 0 ||
      filterStatus?.length > 0 ||
      filterProprietor?.length > 0 ||
      date_of_application?.length > 0 ||
      date_of_usage?.length > 0 ||
      date_of_usage_proposed ||
      containsTerm?.length > 0
    ) {
      this.props.getProprietorProfileData({
        activeTab: this.props.activeTab,
        proprietor: proprietor,
        operation: "MARKS",
        filterFlag: true,
        filters: selectedFilters,
        sortFlag: sorting?.sortFlag,
        sorting: sorting,
      });

      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          filterFlag: true,
        },
        customReportData: {
          ...this.state.customReportData,
          data: {
            ...this.state.customReportData?.data,
            [proprietor]: {
              ...this.state.customReportData?.data[`${proprietor}`],
              ...PP_defaultInitialReport,
              filter_flag: true,
              filters: {
                ...this.state.selectedFilters,
                filterFlag: true,
              },
              sort_flag: sorting?.sortFlag,
              sort: sorting,
            },
          },
        },
      });
    } else {
      message.error("please select filter!");
    }
  }

  setDate1(val) {
    this.setState({ range1: val });
  }

  setDate2(val) {
    this.setState({ range2: val });
  }

  handleResetFilters() {
    this.setState({ range1: null, range2: null });
    const proprietor = sessionStorage.getItem("proprietor");
    const sorting = this.props.proprietorMarkSorting;
    const {
      filterClasses,
      filterState,
      filterType,
      filterStatus,
      filterProprietor,
      date_of_application,
      date_of_usage,
      date_of_usage_proposed,
      containsTerm,
    } = this.state.selectedFilters;

    if (
      filterClasses?.length > 0 ||
      filterState?.length > 0 ||
      filterType?.length > 0 ||
      filterStatus?.length > 0 ||
      filterProprietor?.length > 0 ||
      date_of_application?.length > 0 ||
      date_of_usage?.length > 0 ||
      date_of_usage_proposed ||
      containsTerm?.length > 0
    ) {
      this.props.getProprietorProfileData({
        activeTab: this.props.activeTab,
        proprietor: proprietor,
        operation: "MARKS",
        filterFlag: false,
        filters: PP_defaultSelectedFilters,
        sortFlag: sorting?.sortFlag,
        sorting: sorting,
      });
      this.setState({
        selectedFilters: PP_defaultSelectedFilters,
        customReportData: {
          ...this.state.customReportData,
          data: {
            ...this.state.customReportData?.data,
            [proprietor]: {
              ...this.state.customReportData?.data[`${proprietor}`],
              ...PP_defaultInitialReport,
              filter_flag: false,
              filters: PP_defaultSelectedFilters,
              sort_flag: sorting?.sortFlag,
              sort: sorting,
            },
          },
        },
      });
    } else {
      message.error("No Filter Applied");
    }
  }

  handleApplySorting(sortValue) {
    const proprietor = sessionStorage.getItem("proprietor");
    const { sorting } = this.state;
    const selectedFilters = this.props.proprietorMarkFilters;
    const pageNumber = this.props.proprietorMarkPageNumber;

    if (sortValue?.length !== sorting?.sortValue) {
      if (sortValue[0] === "CLEAR") {
        this.props.getProprietorProfileData({
          activeTab: this.props.activeTab,
          proprietor: proprietor,
          operation: "MARKS",
          filterFlag: selectedFilters?.filterFlag,
          filters: selectedFilters,
          sortFlag: false,
          sorting: PP_defaultSorting,
          pageNumber: pageNumber,
        });
        this.setState({
          sorting: PP_defaultSorting,
          customReportData: {
            ...this.state.customReportData,
            data: {
              ...this.state.customReportData?.data,
              [proprietor]: {
                ...this.state.customReportData?.data[`${proprietor}`],
                ...PP_defaultInitialReport,
                filter_flag: selectedFilters?.filterFlag,
                filters: selectedFilters,
                sort_flag: false,
                sort: PP_defaultSorting,
              },
            },
          },
        });
      } else {
        this.props.getProprietorProfileData({
          activeTab: this.props.activeTab,
          proprietor: proprietor,
          operation: "MARKS",
          filterFlag: selectedFilters?.filterFlag,
          filters: selectedFilters,
          sortFlag: true,
          sorting: {
            ...sorting,
            sortType: sortValue[0],
            sortOn: sortValue[1],
          },
          pageNumber: pageNumber,
        });
        this.setState({
          sorting: {
            ...this.state.secondarySorting,
            sortType: sortValue[0],
            sortOn: sortValue[1],
            sortFlag: true,
            sortValue: sortValue,
          },
          customReportData: {
            ...this.state.customReportData,
            data: {
              ...this.state.customReportData?.data,
              [proprietor]: {
                ...this.state.customReportData?.data[`${proprietor}`],
                ...PP_defaultInitialReport,
                filter_flag: selectedFilters?.filterFlag,
                filters: selectedFilters,
                sort_flag: true,
                sort: {
                  ...sorting,
                  sortType: sortValue[0],
                  sortOn: sortValue[1],
                  sortFlag: true,
                },
              },
            },
          },
        });
      }
    } else {
      message.error("please select value!");
    }
  }

  handlePageChange(pageNumber) {
    const proprietor = sessionStorage.getItem("proprietor");
    const sorting = this.props.proprietorMarkSorting;
    const selectedFilters = this.props.proprietorMarkFilters;
    this.props.getProprietorProfileData({
      activeTab: this.props.activeTab,
      proprietor: proprietor,
      operation: "MARKS",
      filterFlag: selectedFilters?.filterFlag,
      filters: selectedFilters,
      sortFlag: sorting?.sortFlag,
      sorting: sorting,
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  }

  handleSelectMark(mardId, application_number, checked) {
    const proprietor = sessionStorage.getItem("proprietor");
    if (!this.state.customReportData?.data[`${proprietor}`]) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          ...PP_defaultCustomReportData,
          data: {
            ...this.state.customReportData?.data,
            [proprietor]: {
              ...this.state.customReportData?.data[`${proprietor}`],
              ...PP_defaultInitialReport,
              // applications: [...this.state.customReportData?.data[`${proprietor}`].applications, application_number],
              // selectedMark: [...this.state.customReportData?.data[`${proprietor}`].selectedMark, mardId]
            },
          },
        },
      });
    } else {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          data: {
            ...this.state.customReportData?.data,
            [proprietor]: {
              ...this.state.customReportData?.data[`${proprietor}`],
              applications: checked
                ? [
                    ...this.state.customReportData?.data[`${proprietor}`]
                      .applications,
                    application_number,
                  ]
                : this.state.customReportData?.data[
                    `${proprietor}`
                  ].applications.filter((e) => e !== application_number),
              selectedMark: checked
                ? [
                    ...this.state.customReportData?.data[`${proprietor}`]
                      .selectedMark,
                    mardId,
                  ]
                : this.state.customReportData?.data[
                    `${proprietor}`
                  ].selectedMark.filter((e) => e !== mardId),
            },
          },
        },
      });
    }
  }

  onCloseDrawer() {
    const proprietor = sessionStorage.getItem("proprietor");
    this.setState({
      drawersVisibility: {
        ...this.state.drawersVisibility,
        customReport: false,
      },
      customReportData: {
        ...this.state.customReportData,
        ...PP_defaultCustomReportData,
        data: {
          ...this.state.customReportData?.data,
          [proprietor]: {
            ...this.state.customReportData?.data[`${proprietor}`],
            select_all: false,
            select_page: false,
            selectedMark: [],
            applications: [],
          },
        },
      },
    });
  }

  render() {
    const proprietor = sessionStorage.getItem("proprietor");

    const {
      proprietorMark,
      proprietorMarkStatus,

      proprietorAnalysis,
      proprietorAnalysisStatus,

      proprietorProfile,
      proprietorProfileStatus,

      similarProprietor,
      similarProprietorStatus,

      activeTab,
      selectedView,
    } = this.props;

    const {
      selectedFilters,
      filtersVisibility,
      filtersSearchTerms,
      sorting,
      pageNumber,
      customReportData,
    } = this.state;

    const filterMenuScroll = {
      maxHeight: "175px",
      minWidth: "150px",
      overflowY: "scroll",
    };

    const moreActionsMenu = (
      <Menu
        selectable={true}
        multiple={true}
        selectedKeys={[]}
        style={filterMenuScroll}
      >
        <Menu.Item
          key="Select page"
          onClick={() => {
            if (!customReportData?.data[`${proprietor}`]) {
              this.changeStateValue_L2("customReportData", "data", proprietor, {
                ...PP_defaultInitialReport,
                selectedMark: [
                  ...new Set(this.props.proprietorMark.marks.map((e) => e.id)),
                ],
                applications: [
                  ...new Set(
                    this.props.proprietorMark.marks.map(
                      (e) => e.application_number
                    )
                  ),
                ],
              });
            } else {
              this.changeStateValue_L2("customReportData", "data", proprietor, {
                ...this.state.customReportData?.data[`${proprietor}`],
                selectedMark: [
                  ...new Set([
                    ...this.props.proprietorMark.marks.map((e) => e.id),
                    ...this.state.customReportData?.data[`${proprietor}`]
                      .selectedMark,
                  ]),
                ],
                applications: [
                  ...new Set([
                    ...this.props.proprietorMark.marks.map(
                      (e) => e.application_number
                    ),
                    ...this.state.customReportData?.data[`${proprietor}`]
                      .applications,
                  ]),
                ],
              });
            }
          }}
        >
          Select page
        </Menu.Item>
        <Menu.Item
          key="Deselect page"
          onClick={() => {
            if (customReportData?.data[`${proprietor}`]) {
              this.changeStateValue_L2("customReportData", "data", proprietor, {
                ...this.state.customReportData?.data[`${proprietor}`],
                selectedMark: this.state.customReportData?.data[
                  `${proprietor}`
                ].selectedMark.filter(
                  (e) =>
                    !this.props.proprietorMark.marks
                      .map((x) => x.id)
                      .includes(e)
                ),
                applications: this.state.customReportData?.data[
                  `${proprietor}`
                ].applications.filter(
                  (e) =>
                    !this.props.proprietorMark.marks
                      .map((x) => x.application_number)
                      .includes(e)
                ),
              });
            }
          }}
        >
          Deselect page
        </Menu.Item>
        <Menu.Item
          key="select_all"
          onClick={() => {
            if (!customReportData?.data[`${proprietor}`]) {
              this.changeStateValue_L2("customReportData", "data", proprietor, {
                ...PP_defaultInitialReport,
                select_all: true,
              });
            } else {
              this.changeStateValue_L2("customReportData", "data", proprietor, {
                ...this.state.customReportData?.data[`${proprietor}`],
                select_all:
                  !this.state.customReportData?.data[`${proprietor}`]
                    .select_all,
              });
            }
          }}
        >
          {customReportData?.data[`${proprietor}`] &&
          customReportData?.data[`${proprietor}`].select_all
            ? "Deselect All"
            : "Select All"}
        </Menu.Item>
      </Menu>
    );

    const tabs = ["Proprietor_TMs", "Similar_Proprietors"];
    const tabMenu = (
      <Menu
        selectable={true}
        selectedKeys={[activeTab]}
        onClick={({ key }) => this.props.TPAction(CHANGE_PROP_RESULTS_TAB, key)}
        style={{
          maxHeight: "200px",
          width: "200px",
          fontSize: "16px",
          overflow: "auto",
          boxShadow: "0px 0px 5px #c9c9c9",
        }}
      >
        {tabs.map((data) => (
          <Menu.Item key={data} style={{ fontSize: "16px" }}>
            {data.replace("_", " ")}
          </Menu.Item>
        ))}
      </Menu>
    );

    let noFilterData = (
      <Menu>
        <Menu.Item>No Filters Data Found</Menu.Item>
      </Menu>
    );
    let classesMenu = noFilterData;
    let stateMenu = noFilterData;
    let typeMenu = noFilterData;
    let statusMenu = noFilterData;
    let proprietorMenu = noFilterData;

    if (proprietorMarkStatus === SUCCESS) {
      let filters = proprietorMark.filters;
      let classes = [...new Set(filters.class)].sort(function (a, b) {
        return a - b;
      });
      let state = [...new Set(filters.state)].sort(function (a, b) {
        return a - b;
      });
      let type = [...new Set(filters.type)].sort(function (a, b) {
        return a - b;
      });
      let status = [...new Set(filters.status)].sort(function (a, b) {
        return a - b;
      });
      let proprietor = [...new Set(filters.proprietor)].sort(function (a, b) {
        return a - b;
      });

      if (classes !== null && classes?.length > 0) {
        let filteredClasses = classes.filter((eachClasses) =>
          checkValue(`${eachClasses}`)
            ? `${eachClasses}`.includes(`${filtersSearchTerms.classes}`)
            : ""
        );
        classesMenu = (
          <>
            <Search
              placeholder="Search Class"
              onChange={(event) =>
                this.changeStateValue_L1(
                  "filtersSearchTerms",
                  "classes",
                  event.target.value
                )
              }
              value={filtersSearchTerms.classes}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredClasses?.length}
              itemSize={35}
              itemData={filteredClasses}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters?.filterClasses}
                    onSelect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterClasses",
                        event.selectedKeys
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterClasses",
                        event.selectedKeys
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index]}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (state !== null && state?.length > 0) {
        let filteredState = state.filter((eachState) =>
          checkValue(eachState)
            ? eachState.toLowerCase().includes(filtersSearchTerms.state)
            : ""
        );
        stateMenu = (
          <>
            <Search
              placeholder="Search State"
              onChange={(event) =>
                this.changeStateValue_L1(
                  "filtersSearchTerms",
                  "state",
                  event.target.value.toLowerCase()
                )
              }
              value={filtersSearchTerms.state}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredState?.length}
              itemSize={35}
              itemData={filteredState}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters?.filterState}
                    onSelect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterState",
                        event.selectedKeys
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterState",
                        event.selectedKeys
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (type !== null && type?.length > 0) {
        let filteredType = type.filter((eachType) =>
          checkValue(eachType)
            ? eachType.name.toLowerCase().includes(filtersSearchTerms.type)
            : ""
        );
        typeMenu = (
          <>
            <Search
              placeholder="Search Type"
              onChange={(event) =>
                this.changeStateValue_L1(
                  "filtersSearchTerms",
                  "type",
                  event.target.value.toLowerCase()
                )
              }
              value={filtersSearchTerms.type}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredType?.length}
              itemSize={35}
              itemData={filteredType}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters?.filterType}
                    onSelect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterType",
                        event.selectedKeys
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterType",
                        event.selectedKeys
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].name.toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (status !== null && status?.length > 0) {
        let filteredStatus = status.filter((eachStatus) =>
          checkValue(eachStatus)
            ? eachStatus.toLowerCase().includes(filtersSearchTerms.status)
            : ""
        );
        statusMenu = (
          <>
            <Search
              placeholder="Search Status"
              onChange={(event) =>
                this.changeStateValue_L1(
                  "filtersSearchTerms",
                  "status",
                  event.target.value.toLowerCase()
                )
              }
              value={filtersSearchTerms.status}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredStatus?.length}
              itemSize={35}
              itemData={filteredStatus}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters?.filterStatus}
                    onSelect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterStatus",
                        event.selectedKeys
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterStatus",
                        event.selectedKeys
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index]}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }

      if (proprietor !== null && proprietor?.length > 0) {
        let filteredProprietor = proprietor.filter((eachProprietor) =>
          checkValue(eachProprietor)
            ? eachProprietor.name
                .toLowerCase()
                .includes(filtersSearchTerms.proprietor)
            : ""
        );
        proprietorMenu = (
          <>
            <Search
              placeholder="Search Proprietor"
              onChange={(event) =>
                this.changeStateValue_L1(
                  "filtersSearchTerms",
                  "proprietor",
                  event.target.value.toLowerCase()
                )
              }
              value={filtersSearchTerms.proprietor}
              allowClear
            />
            <FixedSizeList
              className="List"
              height={150}
              itemCount={filteredProprietor?.length}
              itemSize={35}
              itemData={filteredProprietor}
              width={450}
            >
              {({ index, style, data }) => {
                return (
                  <Menu
                    selectable={true}
                    multiple={true}
                    selectedKeys={selectedFilters?.filterProprietor}
                    onSelect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterProprietor",
                        event.selectedKeys
                      )
                    }
                    onDeselect={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "filterProprietor",
                        event.selectedKeys
                      )
                    }
                  >
                    <Menu.Item
                      key={data[index].id}
                      style={{
                        ...style,
                        padding: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="menu-items-list"
                    >
                      <Text
                        className="text-camelCase"
                        style={{ fontWeight: "400" }}
                      >
                        {data[index].name.toLowerCase()}
                      </Text>
                    </Menu.Item>
                  </Menu>
                );
              }}
            </FixedSizeList>
          </>
        );
      }
    }

    // if( proprietorProfileStatus === 'LOADING' || proprietorMarkStatus === 'LOADING' || proprietorAnalysisStatus === 'LOADING' ){
    //     return 'loading'
    // }

    return (
      <>
        <header
          style={{
            textAlign: "center",
            marginBottom: "5px",
            padding: "5px",
            width: "100%",
            alignSelf: "center",
          }}
        >
          <Row>
            <Col span={24}>
              <div
                className={`alignC ${
                  activeTab !== "Similar_Proprietors" ? "" : "not-visible"
                }`}
                style={{ display: "inline-block" }}
              >
                <Dropdown
                  overlay={classesMenu}
                  overlayClassName="Filters-Menu"
                  open={filtersVisibility.classesMenu}
                  onVisibleChange={(event) =>
                    this.changeStateValue_L1(
                      "filtersVisibility",
                      "classesMenu",
                      event
                    )
                  }
                >
                  <Button
                    className={`${
                      selectedFilters?.filterClasses?.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    Class
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={stateMenu}
                  overlayClassName="Filters-Menu"
                  open={filtersVisibility.stateMenu}
                  onVisibleChange={(event) =>
                    this.changeStateValue_L1(
                      "filtersVisibility",
                      "stateMenu",
                      event
                    )
                  }
                >
                  <Button
                    className={`${
                      selectedFilters?.filterState?.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    State
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={typeMenu}
                  overlayClassName="Filters-Menu"
                  open={filtersVisibility.typeMenu}
                  onVisibleChange={(event) =>
                    this.changeStateValue_L1(
                      "filtersVisibility",
                      "typeMenu",
                      event
                    )
                  }
                >
                  <Button
                    className={`${
                      selectedFilters?.filterType?.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    Type
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={statusMenu}
                  overlayClassName="Filters-Menu"
                  open={filtersVisibility.statusMenu}
                  onVisibleChange={(event) =>
                    this.changeStateValue_L1(
                      "filtersVisibility",
                      "statusMenu",
                      event
                    )
                  }
                >
                  <Button
                    className={`${
                      selectedFilters?.filterStatus?.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    Status
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={proprietorMenu}
                  overlayClassName="Filters-Menu"
                  open={filtersVisibility.proprietorMenu}
                  onVisibleChange={(event) =>
                    this.changeStateValue_L1(
                      "filtersVisibility",
                      "proprietorMenu",
                      event
                    )
                  }
                >
                  <Button
                    className={`${
                      selectedFilters?.filterProprietor?.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    Proprietor
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={
                    <DateRangePicker
                      type={"Date of Application"}
                      startValue={
                        selectedFilters?.date_of_application_startValue
                      }
                      endValue={selectedFilters?.date_of_application_endValue}
                      setDate={(val) => this.setDate1(val)}
                      range={this.state.range1}
                      handleDateString={(startValue, endValue, dateString) =>
                        this.setState({
                          selectedFilters: {
                            ...this.state.selectedFilters,
                            date_of_application_startValue: startValue,
                            date_of_application_endValue: endValue,
                            date_of_application:
                              startValue !== null || endValue !== null
                                ? dateString
                                : null,
                          },
                        })
                      }
                    />
                  }
                  overlayClassName="filter-menu"
                >
                  <Button
                    className={`${
                      selectedFilters?.date_of_application?.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    DoA <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={
                    <>
                      <DateRangePicker
                        type={"Date of Usage"}
                        startValue={selectedFilters?.date_of_usage_startValue}
                        endValue={selectedFilters?.date_of_usage_endValue}
                        setDate={(val) => this.setDate2(val)}
                        range={this.state.range2}
                        handleDateString={(startValue, endValue, dateString) =>
                          this.setState({
                            selectedFilters: {
                              ...this.state.selectedFilters,
                              date_of_usage_startValue: startValue,
                              date_of_usage_endValue: endValue,
                              date_of_usage:
                                startValue !== null || endValue !== null
                                  ? dateString
                                  : null,
                            },
                          })
                        }
                      />
                      <div style={{ textAlign: "center", lineHeight: "32px" }}>
                        <Checkbox
                          checked={
                            this.state.selectedFilters?.date_of_usage_proposed
                          }
                          onChange={(event) =>
                            this.changeStateValue_L1(
                              "selectedFilters",
                              "date_of_usage_proposed",
                              event.target.checked
                            )
                          }
                        >
                          Proposed
                        </Checkbox>
                      </div>
                    </>
                  }
                  overlayClassName="filter-menu"
                >
                  <Button
                    className={`${
                      selectedFilters?.date_of_usage?.length > 0 ||
                      selectedFilters?.date_of_usage_proposed
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    DoU <DownOutlined />
                  </Button>
                </Dropdown>
                <Tooltip title="Search application name or number">
                  <Input.Search
                    value={selectedFilters?.containsTerm}
                    onChange={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "containsTerm",
                        event.target.value
                      )
                    }
                    style={{ width: "170px", margin: "0px 5px 0px 2px" }}
                    placeholder="Search application name or number"
                    enterButton={
                      <Button
                        style={{
                          backgroundColor: "#4285f4",
                          color: "#ffffff",
                          fontWeight: "600",
                        }}
                      >
                        Apply
                      </Button>
                    }
                    onSearch={() => this.handleApplyFilters()}
                  />
                </Tooltip>
                <Button
                  type="link"
                  style={{ padding: "0px 5px" }}
                  onClick={() => this.handleResetFilters()}
                >
                  Reset
                </Button>
                <Cascader
                  style={{
                    width: "10vw",
                    minWidth: "100px",
                    margin: "0px 5px",
                  }}
                  options={[
                    {
                      value: "ASC",
                      label: "Ascending",
                      children: [
                        {
                          value: "CLASS",
                          label: "Class",
                        },
                        {
                          value: "APPLICATION NUMBER",
                          label: "Application Number",
                        },
                        {
                          value: "APPLIED FOR",
                          label: "Applied For",
                        },
                        {
                          value: "STATUS",
                          label: "Status",
                        },
                        {
                          value: "DATE OF APPLICATION",
                          label: "Date of Application",
                        },
                        {
                          value: "DATE OF USAGE",
                          label: "Date of Usage",
                        },
                      ],
                    },
                    {
                      value: "DESC",
                      label: "Descending",
                      children: [
                        {
                          value: "CLASS",
                          label: "Class",
                        },
                        {
                          value: "APPLICATION NUMBER",
                          label: "Application Number",
                        },
                        {
                          value: "APPLIED FOR",
                          label: "Applied For",
                        },
                        {
                          value: "STATUS",
                          label: "Status",
                        },
                        {
                          value: "DATE OF APPLICATION",
                          label: "Date of Application",
                        },
                        {
                          value: "DATE OF USAGE",
                          label: "Date of Usage",
                        },
                      ],
                    },
                    {
                      value: "CLEAR",
                      label: "Clear All",
                      children: [],
                    },
                  ]}
                  allowClear={false}
                  placeholder="Sorting"
                  expandTrigger="hover"
                  value={sorting?.sortValue}
                  onChange={(value) => this.handleApplySorting(value)}
                />
                {this.props.userSubscriptions &&
                  this.props.userSubscriptions.search.includes(
                    "PROPRIETOR"
                  ) && (
                    <>
                      <Badge
                        overflowCount={9999}
                        count={
                          customReportData?.data[`${proprietor}`]
                            ? customReportData?.data[`${proprietor}`].select_all
                              ? "All"
                              : customReportData?.data[`${proprietor}`]
                                  .selectedMark?.length
                            : ""
                        }
                        style={{ marginTop: "5px" }}
                      >
                        <Button
                          style={{ padding: "0px 5px" }}
                          disabled={
                            customReportData?.data[`${proprietor}`] &&
                            (customReportData?.data[`${proprietor}`]
                              .select_all ||
                              customReportData?.data[`${proprietor}`]
                                .selectedMark?.length > 0)
                              ? false
                              : true
                          }
                          onClick={() =>
                            this.setState({
                              drawersVisibility: {
                                ...this.state.drawersVisibility,
                                customReport: true,
                              },
                              customReportData: {
                                ...this.state.customReportData,
                                data: {
                                  ...this.state.customReportData?.data,
                                  [proprietor]: {
                                    ...this.state.customReportData?.data[
                                      `${proprietor}`
                                    ],
                                  },
                                },
                                id: proprietor,
                                report_source: "PROPRIETOR",
                                report_type: "URGENT",
                                report_file: true,
                                report_file_name: "",
                                report_file_types: ["PDF"],
                              },
                            })
                          }
                        >
                          Create Report
                        </Button>
                      </Badge>
                      <MinusCircleOutlined
                        onClick={() =>
                          this.setState({
                            customReportData: {
                              ...this.state.customReportData,
                              ...PP_defaultCustomReportData,
                              data: {
                                ...this.state.customReportData?.data,
                                [proprietor]: {
                                  ...this.state.customReportData?.data[
                                    `${proprietor}`
                                  ],
                                  ...PP_defaultInitialReport,
                                },
                              },
                            },
                          })
                        }
                        style={{
                          margin: "0px 0px 0px 5px",
                          fontSize: "14px",
                          visibility: `${
                            customReportData?.data[`${proprietor}`]
                              ? customReportData?.data[`${proprietor}`]
                                  .selectedMark?.length > 0 ||
                                customReportData?.data[`${proprietor}`]
                                  .select_all
                                ? ""
                                : "hidden"
                              : "hidden"
                          }`,
                        }}
                      />
                    </>
                  )}
                <Dropdown
                  overlay={moreActionsMenu}
                  overlayClassName="Filters-Menu"
                  open={filtersVisibility.moreActionsMenu}
                  onVisibleChange={(event) =>
                    this.changeStateValue_L1(
                      "filtersVisibility",
                      "moreActionsMenu",
                      event
                    )
                  }
                >
                  <Button className="Search-More-Actions">
                    <UnorderedListOutlined
                      style={{
                        fontSize: "16px",
                        position: "relative",
                        bottom: "2px",
                      }}
                    />
                  </Button>
                </Dropdown>
              </div>
              <Dropdown overlay={tabMenu}>
                <Button
                  style={{
                    color: "#ffffff",
                    fontWeight: "600",
                    border: "none",
                    backgroundColor: "#4285f4",
                    padding: "0px 5px",
                  }}
                >
                  {activeTab.replace("_", " ")}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </header>

        {/* card-section */}
        <div style={{ margin: 5 }}>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "100px",
                  padding: "15px",
                }}
                loading={proprietorProfileStatus === LOADING}
              >
                <Row>
                  <Col span={4}>
                    <AppstoreTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "14px" }}>
                      PROPRIETOR AND TYPE
                    </Title>
                    <div>
                      {checkNA(proprietorProfile && proprietorProfile.name)}
                    </div>
                    <div>
                      {checkNA(proprietorProfile && proprietorProfile.type)}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="gutter-row" span={8}>
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "100px",
                  padding: "15px",
                }}
                loading={proprietorProfileStatus === LOADING}
              >
                <Row>
                  <Col span={4}>
                    <AppstoreTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "14px" }}>
                      TRADING AS AND DETAILS
                    </Title>
                    <div>
                      {checkNA(
                        proprietorProfile && proprietorProfile.trading_as
                      )}
                    </div>
                    <div>
                      {checkNA(
                        proprietorProfile && proprietorProfile.type_details
                      )}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="gutter-row" span={8}>
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "100px",
                  padding: "15px",
                }}
                loading={proprietorProfileStatus === LOADING}
              >
                <Row>
                  <Col span={4}>
                    <AppstoreTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "14px" }}>
                      Address
                    </Title>
                    <div>
                      {checkNA(proprietorProfile && proprietorProfile.address)}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
        {/* card-section */}

        <div className="m-top-10">
          <Row>
            <Col span={24}>
              {/* Similar_Proprietors */}
              {activeTab === "Similar_Proprietors" && (
                <div
                  className="General-Content vertical_scroll"
                  style={{
                    height: "calc(100vh - 255px)",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  {similarProprietorStatus === LOADING && (
                    <div className="spin-container" style={{ width: "100%" }}>
                      <Spin size="large" />
                    </div>
                  )}
                  {similarProprietorStatus === SUCCESS && (
                    <>
                      {similarProprietor?.length > 0 &&
                        similarProprietor.map((eachProprietor) => (
                          <React.Fragment key={eachProprietor.id}>
                            <div id={eachProprietor.id} className="Record-Row">
                              <Tooltip
                                title={`Proprietor:- ${checkNA(
                                  eachProprietor.name
                                )}`}
                              >
                                <div
                                  className="Record-Row-Cell Application-Number"
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "proprietor",
                                      eachProprietor.id
                                    );
                                    window.open(
                                      `/search/proprietor-profile/${eachProprietor.id}`
                                    );
                                  }}
                                >
                                  {checkNA(eachProprietor.name)}
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={`Trading As:- ${checkNA(
                                  eachProprietor.trading_as
                                )}`}
                              >
                                <div className="Record-Row-Cell">
                                  {checkNA(eachProprietor.trading_as)}
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={`Address:- ${checkNA(
                                  eachProprietor.address
                                )}`}
                              >
                                <div className="Record-Row-Cell">
                                  {checkNA(eachProprietor.address)}
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={`Type:- ${checkNA(eachProprietor.type)}`}
                              >
                                <div className="Record-Row-Cell">
                                  {checkNA(eachProprietor.type)}
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={`Type Details:- ${checkNA(
                                  eachProprietor.type_details
                                )}`}
                              >
                                <div className="Record-Row-Cell">
                                  {checkNA(eachProprietor.type_details)}
                                </div>
                              </Tooltip>
                              <div className="Record-Row-Cell">
                                {`${eachProprietor.marks_count} Trademarks`}
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      {similarProprietor?.length === 0 && (
                        <div
                          className="empty-container"
                          style={{ width: "100%" }}
                        >
                          <Empty />
                        </div>
                      )}
                    </>
                  )}
                  {similarProprietorStatus === ERROR && (
                    <div className="error-container" style={{ width: "100%" }}>
                      <Title level={4}>
                        unfortunately an error has occurred please try again
                        later. will connect you shortly.
                      </Title>
                    </div>
                  )}
                </div>
              )}
              {/* Similar_Proprietors */}

              {/* Proprietor_TMs */}
              {activeTab === "Proprietor_TMs" && (
                <div>
                  <div
                    className="General-Content Flex-Content vertical_scroll"
                    style={{
                      height: `${
                        this.props.searchAlert?.length > 0
                          ? "calc(100vh - 375px)"
                          : "calc(100vh - 305px)"
                      }`,
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    {proprietorMarkStatus === LOADING && (
                      <div className="spin-container" style={{ width: "100%" }}>
                        <Spin size="large" />
                      </div>
                    )}
                    {proprietorMarkStatus === SUCCESS && (
                      <>
                        {selectedView === "cards" &&
                          proprietorMark.marks?.length > 0 &&
                          proprietorMark.marks.map((eachMark) => (
                            <GeneralMarkCard
                              key={eachMark.id}
                              eachMark={eachMark}
                              onSelectMark={(eachMark, checked) =>
                                this.handleSelectMark(
                                  eachMark.id,
                                  eachMark.application_number,
                                  checked
                                )
                              }
                              checked={
                                customReportData?.data[`${proprietor}`]
                                  ? customReportData?.data[
                                      `${proprietor}`
                                    ].selectedMark.includes(eachMark.id) ||
                                    customReportData?.data[`${proprietor}`]
                                      .select_all
                                  : false
                              }
                              selectAllCards={
                                customReportData?.data[`${proprietor}`] &&
                                customReportData?.data[`${proprietor}`]
                                  .select_all
                              }
                              changeStateValue_L1={(x, y, application) => {
                                sessionStorage.setItem(
                                  "application",
                                  application
                                );
                                window.open(`/search/trademark-profile`);
                              }}
                              styleCard={{ textAlign: "left" }}
                              similarity={null}
                              status={checkNA(eachMark.status)}
                              proprietorClickable={true}
                              onProprietorClick={(proprietor) => {
                                sessionStorage.setItem(
                                  "proprietor",
                                  proprietor
                                );
                                window.open(
                                  `/search/proprietor-profile/${proprietor}`
                                );
                              }}
                            />
                          ))}
                        {selectedView === "table" &&
                          proprietorMark.marks?.length > 0 && (
                            <table className="Table">
                              <thead className="Table-Head">
                                <tr className="Table-Head-Row">
                                  <th
                                    className="Table-Head-Cell Black-Shadow"
                                    style={{ width: "4%", minWidth: "50px" }}
                                  ></th>
                                  <th className="Table-Head-Cell Black-Shadow">
                                    TRADEMARK
                                  </th>
                                  <th className="Table-Head-Cell Black-Shadow">
                                    STATUS
                                  </th>
                                  <th className="Table-Head-Cell Black-Shadow">
                                    TYPE
                                  </th>
                                  <th className="Table-Head-Cell Black-Shadow">
                                    PROPRIETORS
                                  </th>
                                  <th className="Table-Head-Cell Black-Shadow">
                                    CLASSES
                                  </th>
                                  <th className="Table-Head-Cell Black-Shadow">
                                    DoA | DoU
                                  </th>
                                  <th
                                    className="Table-Head-Cell Black-Shadow"
                                    style={{ width: "4%", minWidth: "50px" }}
                                  >
                                    {this.state
                                      .tableDescriptionRowsVisibility ? (
                                      <Tooltip
                                        placement="left"
                                        title="Hide Descriptions"
                                      >
                                        <MinusSquareFilled
                                          style={{
                                            fontSize: "20px",
                                            verticalAlign: "baseline",
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              tableDescriptionRowsVisibility: false,
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        placement="left"
                                        title="Show Descriptions"
                                      >
                                        <PlusSquareFilled
                                          style={{
                                            fontSize: "20px",
                                            verticalAlign: "baseline",
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              tableDescriptionRowsVisibility: true,
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="Table-Body">
                                {proprietorMark.count > 0 &&
                                  proprietorMark.marks.map(
                                    (eachRow, rowIndex) => {
                                      return (
                                        <React.Fragment
                                          key={eachRow.application_number}
                                        >
                                          <tr
                                            className={`Table-Body-Row ${
                                              rowIndex % 2 === 0
                                                ? "Row-Even"
                                                : "Row-Odd"
                                            }`}
                                          >
                                            <td>
                                              <Checkbox
                                                onClick={(event) =>
                                                  this.handleSelectMark(
                                                    eachRow.id,
                                                    eachRow.application_number,
                                                    event.target.checked
                                                  )
                                                }
                                                checked={
                                                  customReportData?.data[
                                                    `${proprietor}`
                                                  ]
                                                    ? customReportData?.data[
                                                        `${proprietor}`
                                                      ].selectedMark.includes(
                                                        eachRow.id
                                                      ) ||
                                                      customReportData?.data[
                                                        `${proprietor}`
                                                      ].select_all
                                                    : false
                                                }
                                                disabled={
                                                  customReportData?.data[
                                                    `${proprietor}`
                                                  ] &&
                                                  customReportData?.data[
                                                    `${proprietor}`
                                                  ].select_all
                                                }
                                              />
                                            </td>
                                            <td>
                                              <span>{eachRow.applied_for}</span>
                                              <br />
                                              {eachRow.associated_image && (
                                                <>
                                                  <span>
                                                    <img
                                                      src={
                                                        eachRow.associated_image
                                                      }
                                                      height="70px"
                                                      width="70px"
                                                      alt=""
                                                    />
                                                  </span>
                                                  <br />
                                                </>
                                              )}
                                              <span
                                                onClick={() => {
                                                  sessionStorage.setItem(
                                                    "application",
                                                    eachRow.application_number
                                                  );
                                                  window.open(
                                                    `/search/trademark-profile`
                                                  );
                                                }}
                                                className="Application-Number"
                                              >
                                                {eachRow?.application_number}
                                              </span>
                                            </td>
                                            <td>{eachRow?.status}</td>
                                            <td>{eachRow?.type?.name}</td>
                                            <td>
                                              {eachRow.proprietor.map(
                                                (
                                                  eachProprietor,
                                                  index,
                                                  allProprietors
                                                ) => (
                                                  <React.Fragment
                                                    key={`${eachRow.id}-${eachProprietor.id}`}
                                                  >
                                                    <span>
                                                      <span>
                                                        <strong>
                                                          Proprietor:{" "}
                                                        </strong>
                                                        <span
                                                          style={{
                                                            padding: "0px 5px",
                                                          }}
                                                          className="Application-Number"
                                                          onClick={() => {
                                                            sessionStorage.setItem(
                                                              "proprietor",
                                                              eachProprietor.id
                                                            );
                                                            window.open(
                                                              `/search/proprietor-profile/${eachProprietor.id}`
                                                            );
                                                          }}
                                                        >
                                                          {eachProprietor.name}
                                                        </span>
                                                      </span>
                                                      <br />
                                                      <span>
                                                        <strong>
                                                          Address:{" "}
                                                        </strong>
                                                        <span
                                                          style={{
                                                            padding: "0px 5px",
                                                          }}
                                                        >
                                                          {
                                                            eachProprietor.address
                                                          }
                                                        </span>
                                                      </span>
                                                    </span>
                                                    {allProprietors?.length ===
                                                    index + 1 ? (
                                                      ""
                                                    ) : (
                                                      <hr />
                                                    )}
                                                  </React.Fragment>
                                                )
                                              )}
                                            </td>
                                            <td>
                                              {eachRow.associated_class.map(
                                                (
                                                  eachClass,
                                                  index,
                                                  allClasses
                                                ) =>
                                                  allClasses?.length ===
                                                  index + 1
                                                    ? `${eachClass}`
                                                    : `${eachClass}, `
                                              )}
                                            </td>
                                            <td>
                                              <span>
                                                DoA:{" "}
                                                {eachRow.application_date
                                                  ? eachRow.application_date
                                                  : "N/A"}
                                              </span>
                                              <br />
                                              <span>
                                                DoU:{" "}
                                                {eachRow.date_of_usage
                                                  ? eachRow.date_of_usage
                                                  : "Proposed"}
                                              </span>
                                            </td>
                                            <td></td>
                                          </tr>
                                          {this.state
                                            .tableDescriptionRowsVisibility && (
                                            <tr
                                              className={`Table-Body-Row ${
                                                rowIndex % 2 === 0
                                                  ? "Row-Even"
                                                  : "Row-Odd"
                                              }`}
                                            >
                                              <td
                                                colSpan="8"
                                                style={{
                                                  verticalAlign: "top",
                                                  padding: 10,
                                                }}
                                              >
                                                {eachRow.description
                                                  ? eachRow.description
                                                  : "Description"}
                                              </td>
                                            </tr>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          )}
                      </>
                    )}

                    {proprietorMarkStatus === SUCCESS &&
                      proprietorMark.marks?.length === 0 && (
                        <Empty style={{ width: "100%" }} />
                      )}

                    {proprietorMarkStatus === ERROR && (
                      <div
                        className="error-container"
                        style={{ width: "100%" }}
                      >
                        <Title level={4}>
                          unfortunately an error has occurred please try again
                          later. will connect you shortly.
                        </Title>
                      </div>
                    )}
                  </div>
                  <footer className="General-Footer Box-Shadow">
                    <Radio.Group
                      onChange={(event) =>
                        this.props.TPAction(PP_SELECT_VIEW, event.target.value)
                      }
                      value={selectedView}
                      style={{ float: "left" }}
                    >
                      <Radio.Button value="cards">Cards</Radio.Button>
                      <Radio.Button value="table">Table</Radio.Button>
                    </Radio.Group>
                    <Button
                      style={{ float: "left", margin: "0px 5px" }}
                      onClick={() =>
                        this.changeStateValue_L1(
                          "drawersVisibility",
                          "resultsAnalysis",
                          true
                        )
                      }
                    >
                      <PieChartOutlined style={{ fontSize: "14px" }} />
                    </Button>
                    <Pagination
                      showQuickJumper
                      defaultCurrent={1}
                      current={pageNumber}
                      total={proprietorMark && proprietorMark.count}
                      showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} `
                      }
                      pageSize={100}
                      showSizeChanger={false}
                      onChange={(page) => this.handlePageChange(page)}
                    />
                  </footer>
                </div>
              )}
              {/* Proprietor_TMs */}
            </Col>
          </Row>
        </div>

        {this.state.drawersVisibility.customReport && (
          <CreateSearchReport
            visible={this.state.drawersVisibility.customReport}
            onCloseDrawer={() => this.onCloseDrawer()}
            customReportData={customReportData}
          />
        )}

        <Drawer
          title={<h2>Results Analysis({`${activeTab.replace("_", " ")}`})</h2>}
          placement="right"
          width={800}
          closable={true}
          open={this.state.drawersVisibility.resultsAnalysis}
          onClose={() =>
            this.changeStateValue_L1(
              "drawersVisibility",
              "resultsAnalysis",
              false
            )
          }
          className="analysis-body"
          bodyStyle={{
            padding: 0,
          }}
        >
          <div className="analysis-body-container vertical_scroll">
            {this.state.drawersVisibility.resultsAnalysis && (
              <ResultsAnalysis
                analysisStatus={proprietorAnalysisStatus}
                statusesDistribution={
                  proprietorAnalysis
                    ? proprietorAnalysis.status_distribution
                    : []
                }
                classesDistribution={
                  proprietorAnalysis
                    ? proprietorAnalysis.class_distribution
                    : []
                }
                yearsDistribution={
                  proprietorAnalysis ? proprietorAnalysis.year_distribution : []
                }
              />
            )}
          </div>
        </Drawer>
      </>
    );
  }
}

const mapDispatchToProps = {
  getProprietorProfileData,
  getSearchResults,
  TPAction,
  logOut,
};

function mapStateToProps(state) {
  const {
    proprietorMark,
    proprietorMarkStatus,
    proprietorMarkFilters,
    proprietorMarkSorting,
    proprietorMarkPageNumber,

    proprietorAnalysis,
    proprietorAnalysisStatus,

    proprietorProfile,
    proprietorProfileStatus,

    similarProprietor,
    similarProprietorStatus,

    activeTab,
    selectedView,
    axiosCancelSearches,
    axiosToken,
  } = state.searchState.proprietorProfile;
  return deepFreeze({
    proprietorMark,
    proprietorMarkStatus,
    proprietorMarkFilters,
    proprietorMarkSorting,
    proprietorMarkPageNumber,

    proprietorAnalysis,
    proprietorAnalysisStatus,

    proprietorProfile,
    proprietorProfileStatus,

    similarProprietor,
    similarProprietorStatus,

    activeTab,
    selectedView,
    axiosCancelSearches,
    axiosToken,

    userSubscriptions: state.userSubscriptions.userSubscriptions,
    searchAlert: state.alertMessages.search,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ProprietorProfile);
