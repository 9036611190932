export const TS_selectedFilters = {
  classes: [],
  statuses: [],
  states: [],
  types: [],
  selectProprietors: [],
  deselectProprietors: [],
  doaStart: null,
  doaEnd: null,
  doa: null,
  douStart: null,
  douEnd: null,
  dou: null,
  containsTerm: "",
  douProposed: false,
  queries: {
    Boolean: null,
    Contains: null,
    Fuzzy: null,
    Wildcard: null,
  },
};

export const TS_filtersVisibility = {
  classesMenu: false,
  statusesMenu: false,
  statesMenu: false,
  typesMenu: false,
  proprietorsMenu: false,
  moreActionsMenu: false,
};

export const TS_filtersSearchTerms = {
  classes: "",
  types: "",
  statuses: "",
  states: "",
  proprietors: "",
};

export const TS_drawersVisibility = {
  resultsAnalysis: false,
  customReport: false,
};

export const TS_modalsVisibility = {
  trademarksAlgosInfo: false,
  needSearchRefresh: false,
};

const defaultReportObject = {
  select_all: false,
  selected: [],
  deselected: [],
  search_log_id: "",
};

export const TS_customReportData = {
  reportName: "",
  reportType: "URGENT",
  reportFormat: [],
  applicationsIDs: [],
  operation: "APPEND",
  selectAllTypes: [],
  selectAll: false,
  fuzzy: defaultReportObject,
  standard: defaultReportObject,
  contains: defaultReportObject,
  wildcard: defaultReportObject,
  phonetic: defaultReportObject,
  initials: defaultReportObject,
  boolean: defaultReportObject,
  imagesearch: defaultReportObject,
};

export const TS_trademarksSearchState = {
  selectedFilters: TS_selectedFilters,
  filtersVisibility: TS_filtersVisibility,
  filtersSearchTerms: TS_filtersSearchTerms,
  drawersVisibility: TS_drawersVisibility,
  modalsVisibility: TS_modalsVisibility,
  customReportData: TS_customReportData,
  tableDescriptionRowsVisibility: false,
};

export const TS_singlewordSearch = [
  "Fuzzy",
  "Wildcard",
  "Standard",
  "Phonetic",
  "Contains",
];
export const TS_multiwordSearch = [
  "Fuzzy",
  "Wildcard",
  "Standard",
  "Initials",
  "Phonetic",
  "Boolean",
  "Contains",
];
