import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../constants/commonConstants/generalConstants";
import {
  GET_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
} from "../actions/commonActions/types";

const defaultState = {
  emailTemplate: null,
  emailTemplateStatus: null,
  createEmailTemplateStatus: null,
  editEmailTemplateStatus: null,
  deleteEmailTemplateStatus: null,
  deleteEmailTemplateRequestStatus: null,
  emailTemplateMessage: null,
  templateCount: 0,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_EMAIL_TEMPLATE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            emailTemplateStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            emailTemplate: action.payload,
            emailTemplateStatus: action.status,
            templateCount: action.payload.count,
          };
        case ERROR:
          return {
            ...state,
            emailTemplateMessage: action?.payload?.message,
            createEmailTemplateStatus: ERROR,
            templateCount: 0,
          };

        default:
          break;
      }
      break;

    case "GET_EMAIL_TEMPLATE_WORKFLOW":
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            emailTemplateStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            emailTemplate:
              action.page > 1 && state.emailTemplate !== null
                ? {
                    email_template: [
                      ...state.emailTemplate?.email_template,
                      ...action.payload.email_template,
                    ],
                    count: action.payload.count,
                  }
                : action.payload,
            emailTemplateStatus: action.status,
            templateCount: action.payload.count,
          };
        case ERROR:
          return {
            ...state,
            emailTemplateMessage: action?.payload?.message,
            createEmailTemplateStatus: ERROR,
            templateCount: 0,
          };

        default:
          break;
      }
      break;

    case CREATE_EMAIL_TEMPLATE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            createEmailTemplateStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            emailTemplate: {
              ...state.emailTemplate,
              email_template: [
                action.payload.email_template,
                ...state.emailTemplate.email_template,
              ],
            },
            emailTemplateMessage: action?.payload?.message,
            createEmailTemplateStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            emailTemplateMessage: action?.payload?.message,
            createEmailTemplateStatus: ERROR,
            templateCount: 0,
          };

        default:
          break;
      }
      break;

    case EDIT_EMAIL_TEMPLATE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            editEmailTemplateStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            emailTemplate: {
              ...state.emailTemplate,
              email_template: state.emailTemplate.email_template.map((data) =>
                data.id === action.payload.email_template.id
                  ? action.payload.email_template
                  : data
              ),
            },
            emailTemplateMessage: action?.payload?.message,
            editEmailTemplateStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            emailTemplateMessage: action?.payload?.message,
            editEmailTemplateStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case DELETE_EMAIL_TEMPLATE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteEmailTemplateStatus: LOADING,
            deleteEmailTemplateRequestStatus: action?.request_status,
          };

        case SUCCESS:
          return {
            ...state,
            emailTemplateMessage: action?.payload?.message,
            deleteEmailTemplateStatus: SUCCESS,
            deleteEmailTemplateRequestStatus: action?.request_status,
          };

        case ERROR:
          return {
            ...state,
            emailTemplateMessage: action?.payload?.message,
            deleteEmailTemplateStatus: ERROR,
            deleteEmailTemplateRequestStatus: action?.request_status,
          };

        default:
          break;
      }
      break;

    default:
      return state;
  }
}
