import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import { PlusCircleOutlined } from "@ant-design/icons";

import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Table,
  Card,
  Input,
  Popconfirm,
  notification,
  Button,
  Tooltip,
} from "antd";

//--------ACTIONS--------
import {
  getTeamMember,
  deleteTeamMember,
  editTeamMember,
  logOut,
} from "../../../redux/actions/commonActions/actions";

//--------CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";
import { changeStateValue_L0 } from "../../../functions/commonFunctions/generalFunctions";

import AddTeamMember from "./AddTeamMember";

const EditableContext = React.createContext();

class EditableCell extends Component {
  getInput = () => {
    return (
      <Input
        type={
          this.props.inputType === "number"
            ? "number"
            : this.props.inputType === "text"
            ? "text"
            : "text"
        }
      />
    );
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title.toLowerCase()}!`,
                  max: dataIndex === "contact" ? 13 : "",
                  min: dataIndex === "contact" ? 7 : "",
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class TeamMemberDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamMemberVisible: false,
      editingKey: "",
      deletingKey: "",
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.getTeamMember(this.props.product);
    } else {
      this.props.logOut();
    }
  }

  isEditing = (record) => record.id === this.state.editingKey;

  edit(member) {
    this.setState({
      editingKey: member.id,
    });
  }

  async saveTeamDetail(form, member) {
    form.validateFields(async (error, values) => {
      if (!error) {
        await this.props.editTeamMember({
          id: member.id,
          email: values.email,
          name: values.name,
          contact: values.contact,
          product: this.props.product,
        });
        if (this.props.editTeamStatus === SUCCESS) {
          this.updateTeamMember(SUCCESS);
        } else {
          this.updateTeamMember(ERROR);
        }
        this.setState({ editingKey: "" });
        return;
      }
    });
  }

  cancel = () => {
    this.setState({
      editingKey: "",
    });
  };

  updateTeamMember(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Update Team Member",
          description: "Successfully Updated team member",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Team Member Updated",
          description:
            "Unfortunately, an error occured while Team Member Updating. Please, try again later.",
        });
        break;

      default:
        break;
    }
  }

  async deleteTeamDetail(member) {
    this.setState({
      deletingKey: member.id,
    });
    await this.props.deleteTeamMember({
      id: member.id,
      product: this.props.product,
    });
    if (this.props.deleteTeamStatus === SUCCESS) {
      this.deleteTeamMember(SUCCESS);
    } else {
      this.deleteTeamMember(ERROR);
    }
  }

  deleteTeamMember(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Delete Team Member",
          description: "Successfully Deleted team member",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Team Member Deleted",
          description:
            "Unfortunately, an error occured while Team Member Deleting. Please, try again later.",
        });
        break;

      default:
        break;
    }
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columnsRow = [
      {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
        align: "left",
        width: "30%",
        editable: true,
      },

      {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        align: "left",
        width: "25%",
        editable: true,
      },

      {
        title: "CONTACT",
        dataIndex: "contact",
        key: "contact",
        align: "left",
        width: "25%",
        editable: true,
      },

      {
        title: "",
        dataIndex: "id",
        key: "operation",
        align: "right",
        width: "20%",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    type="primary"
                    onClick={() => this.saveTeamDetail(form, record)}
                    style={{ marginRight: 10 }}
                    loading={
                      this.state.editingKey === record.id &&
                      this.props.editTeamStatus === LOADING
                    }
                  >
                    Save
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancel(record)}
              >
                <Button type="default">Cancel</Button>
              </Popconfirm>
            </>
          ) : (
            <>
              <Button
                type="primary"
                disabled={editingKey !== ""}
                style={{
                  marginRight: 10,
                  background: "#59c4af",
                  borderColor: "#59c4af",
                }}
                onClick={() => this.edit(record)}
              >
                Edit
              </Button>
              <Button
                danger
                onClick={() => this.deleteTeamDetail(record)}
                loading={
                  this.state.deletingKey === record.id &&
                  this.props.deleteTeamStatus === LOADING
                }
              >
                Remove
              </Button>
            </>
          );
        },
      },
    ];

    const columns = columnsRow.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "contact" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Card
          title="YOUR ADDED TEAM MEMBER"
          extra={
            this.props.teamMember && this.props.teamMember.length < 10 ? (
              <Tooltip
                key="password"
                placement="top"
                title="You can add maximum 10 team member."
              >
                <Button
                  type="default"
                  onClick={() =>
                    this.changeStateValue_L0("teamMemberVisible", true)
                  }
                >
                  <span className="pad-l-r-10">ADD TEAM MEMBER</span>
                  <PlusCircleOutlined className="Add-Icons" />
                </Button>
              </Tooltip>
            ) : (
              ""
            )
          }
        >
          <Table
            rowKey={(record) => record.id}
            components={components}
            bordered
            dataSource={this.props.teamMember}
            columns={columns}
            pagination={false}
            scroll={{ y: "calc(70vh - 80px)" }}
            loading={this.props.teamMemberStatus === LOADING}
          />
        </Card>

        <AddTeamMember
          visible={this.state.teamMemberVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("teamMemberVisible", false)
          }
          product={this.props.product}
        />
      </EditableContext.Provider>
    );
  }
}

const mapDispatchToProps = {
  getTeamMember,
  deleteTeamMember,
  editTeamMember,
  logOut,
};

function mapStateToProps(state) {
  const { teamMember, teamMemberStatus, editTeamStatus, deleteTeamStatus } =
    state.profile;
  return deepFreeze({
    teamMember,
    teamMemberStatus,
    editTeamStatus,
    deleteTeamStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "edit_team" })(TeamMemberDetails));
