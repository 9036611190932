import {
  W_F_mark_proprietors,
  W_F_threat_proprietors,
  W_F_mark_classes,
  W_F_threat_classes,
  W_F_mark_types,
  W_F_threat_types,
  W_F_tags,
  W_F_mark_categories,
  W_F_mark_offices,
  W_F_threat_categories,
  W_F_threat_offices,
  W_F_threat_attorney,
  W_F_threat_sources,
  W_F_JOURNAL,
  W_F_UPDATE_SELECTED_VALUES,
  W_F_UPDATE_SEARCH_VALUES,
  W_F_EMPTY_SEARCH_VALUES,
  W_F_UPDATE_EXCLUDE_VALUES,
  W_F_threat_labels,
  W_TEMPORARY_THREAT_FILTER_SAVE,
  W_TEMPORARY_THREAT_FILTER_REMOVE,
  W_F_UPDATE_DATE,
  W_F_UPDATE_DATE_PROPOSED,
  W_F_SEARCH_TYPE,
  W_F_SEARCH_VALUE,
  W_F_FILTER_APPLIED,
  W_F_RESET_ALL,
  W_F_CHANGE_AND_OR,
  W_TEMPORARY_THREAT_FILTER_DEFAULT_VALUE,
  W_LIST_BOOLEAN_FILTER_UPDATE,
  W_F_UPDATE_no_tags,
  W_F_UPDATE_similarity,
  W_F_UPDATE_WHOLE_CONFIG,
  W_F_ANALYSIS,
  W_F_own_brand_classes,
  W_F_threat_brand_classes,
  W_F_own_brand_proprietors,
  W_F_threat_brand_proprietors,
  W_F_UPDATE_IMAGE_TEXT_PERCENTAGE,
  W_F_UPDATE_THREAT_IMAGE_TEXT_PERCENTAGE,
} from "../../actions/watchActions/types";

import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import { finalReduxCachedState } from "../../../functions/commonFunctions/finalCachedState";

const defaultFiltersConfig = {
  journalConfig: {
    key: "journal",
    menu_label: "Journal",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    currentJournalDate: null,
  },
  markTypeConfig: {
    key: "mark_types",
    menu_label: "Your TM Types",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  markCategoryConfig: {
    key: "mark_categories",
    menu_label: "Your Categories",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  threatTypeConfig: {
    key: "threat_types",
    menu_label: "Threat's TM Types",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  threatCategoryConfig: {
    key: "threat_categories",
    menu_label: "Threat's Categories",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  markOfficeConfig: {
    key: "mark_offices",
    menu_label: "Your Offices",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  threatOfficeConfig: {
    key: "threat_offices",
    menu_label: "Threat's Offices",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  infringementConfig: {
    key: "threat_sources",
    menu_label: "Infringement Types",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  threatAttorneyConfig: {
    key: "threat_attorney",
    menu_label: "Threat's Attorney",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    searchValue: "",
  },
  markproprietorConfig: {
    key: "mark_proprietors",
    menu_label: "Your Proprietors",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
  threatproprietorConfig: {
    key: "threat_proprietors",
    menu_label: "Threat's Proprietors",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
  markClassConfig: {
    key: "mark_classes",
    menu_label: "Your Classes",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    andOr: false,
  },
  threatClassConfig: {
    key: "threat_classes",
    menu_label: "Threat's Classes",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    andOr: false,
  },
  tagsConfig: {
    key: "tags",
    menu_label: "Tags",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    searchValue: "",
    andOr: false,
    noTags: false,
  },
  threatLabelConfig: {
    key: "threat_labels",
    menu_label: "Threat Label",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    searchValue: "",
  },
  yourDateOfApplicationConfig: {
    key: "your_date_of_application",
    value: {},
  },
  yourDateOfUsageConfig: {
    key: "your_date_of_usage",
    dateOfUsageProposed: false,
    value: {},
  },
  threatDateOfApplicationConfig: {
    key: "threat_date_of_application",
    value: {},
  },
  threatDateOfUsageConfig: {
    key: "threat_date_of_usage",
    dateOfUsageProposed: false,
    value: {},
  },
  reduxSearchValue: "",
  reduxSearchType: "Application No/Name",
  isFilterApplied: false,
  temp_threat_filters: {},
  excludeReportedConfig: false,
  shortlistedConfig: false,
  excludeShortlistedConfig: false,
  similarityConfig: {
    key: "similarity",
    value: {
      from: 0,
      to: 100,
    },
  },
  imageTextPercentageConfig: {
    key: "image_text_percentage",
    value: {
      from: 0,
      to: 100,
    },
  },
  threatImageTextPercentageConfig: {
    key: "threat_image_text_percentage",
    value: {
      from: 0,
      to: 100,
    },
  },
  sameClassInfringementConfig: false,
  excludeSameClassInfringementConfig: false,
  ownBrandClassConfig: {
    key: "own_brand_classes",
    menu_label: "Your Classes",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    andOr: false,
  },
  threatBrandClassConfig: {
    key: "threat_brand_classes",
    menu_label: "Threat's Classes",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    andOr: false,
  },
  ownBrandProprietorConfig: {
    key: "own_brand_proprietors",
    menu_label: "Your Proprietors",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
  threatBrandProprietorConfig: {
    key: "threat_brand_proprietors",
    menu_label: "Threat's Proprietors",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
};

const newDefaultConfig = {
  list_layout: {
    image_label: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    image_text: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    image_text_new: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    tm_protect: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    tm_applied: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    journals: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    brand_compare: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    image_similarity: {
      config: defaultFiltersConfig,
      isComponentRendered: false,
    },
  },
  grid_layout: {
    image_label: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    image_text: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    image_text_new: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    tm_protect: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    tm_applied: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    journals: {
      config: defaultFiltersConfig,
      isFiltersRendered: false,
    },
    image_similarity: {
      config: defaultFiltersConfig,
      isComponentRendered: false,
    },
  },
};

const generateUniqueArrayOfObject = (arrayOfObjects) => {
  const uniqueArray = [];
  const seenValues = new Set();

  for (const obj of arrayOfObjects) {
    const value = obj.value;
    if (!seenValues.has(value)) {
      uniqueArray.push(obj);
      seenValues.add(value);
    }
  }
  return uniqueArray;
};

export default function (state = newDefaultConfig, action) {
  switch (action.type) {
    case W_F_mark_proprietors:
    case W_F_threat_proprietors:
    case W_F_mark_classes:
    case W_F_threat_classes:
    case W_F_mark_types:
    case W_F_threat_types:
    case W_F_tags:
    case W_F_mark_categories:
    case W_F_mark_offices:
    case W_F_threat_categories:
    case W_F_threat_offices:
    case W_F_threat_attorney:
    case W_F_threat_labels:
    case W_F_threat_sources:
    case W_F_own_brand_classes:
    case W_F_threat_brand_classes:
    case W_F_own_brand_proprietors:
    case W_F_threat_brand_proprietors: {
      // const layout = window.location.pathname.split("/")[3];
      // const urlKey = window.location.pathname.split("/")[2];
      const layout = action.layout;
      const urlKey = action.urlKey;
      const changedKey =
        Object.entries(state?.[layout]?.[urlKey]?.config ?? {}).find(
          (item) => item[1].key === action.filter_key,
        )?.[0] ?? "";
      switch (action.status) {
        case SUCCESS:
          if (changedKey) {
            if (action.page > 1) {
              const newSuccessState = {
                [changedKey]: {
                  ...state?.[layout]?.[urlKey]?.config?.[changedKey],
                  data: generateUniqueArrayOfObject([
                    ...state?.[layout]?.[urlKey]?.config?.[changedKey]?.data,
                    ...action.payload.data,
                  ]),
                  metadata: action.payload.metadata,
                  loading: false,
                },
              };
              return finalReduxCachedState(
                state,
                layout,
                urlKey,
                newSuccessState,
                "isFiltersRendered",
                true,
              );
            } else {
              const newSuccessState = {
                [changedKey]: {
                  ...state?.[layout]?.[urlKey]?.config?.[changedKey],
                  data: action.payload.data,
                  metadata: action.payload.metadata,
                  loading: false,
                },
              };
              return finalReduxCachedState(
                state,
                layout,
                urlKey,
                newSuccessState,
                "isFiltersRendered",
                true,
              );
            }
          }
          break;
        case LOADING:
          if (changedKey) {
            const newLoadingState = {
              [changedKey]: {
                ...state?.[layout]?.[urlKey]?.config?.[changedKey],
                loading: true,
              },
            };
            return finalReduxCachedState(
              state,
              layout,
              urlKey,
              newLoadingState,
              "isFiltersRendered",
              false,
            );
          }
          break;
        case ERROR:
          const newErrorState = {
            [changedKey]: {
              ...state?.[layout]?.[urlKey]?.config?.[changedKey],
              loading: false,
              data: [],
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isFiltersRendered",
            false,
          );

        default:
          return {
            ...state,
          };
      }
      break;
    }
    case W_F_JOURNAL:
    case W_F_ANALYSIS: {
      // const layout = window.location.pathname.split("/")[3];
      // const urlKey = window.location.pathname.split("/")[2];
      const layout = action.layout;
      const urlKey = action.urlKey;
      switch (action.status) {
        case SUCCESS:
          const newSuccessState = {
            journalConfig: {
              ...state?.[layout]?.[urlKey]?.config?.journalConfig,
              data: action.payload.data,
              metadata: action.payload.metadata,
              loading: false,
              currentJournalDate:
                action?.payload?.data?.[0]?.published_date ?? "",
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newSuccessState,
            "isJournalRendered",
            true,
          );
        case LOADING:
          const newLoadingState = {
            journalConfig: {
              ...state?.[layout]?.[urlKey]?.config?.journalConfig,
              loading: true,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newLoadingState,
            "isJournalRendered",
            false,
          );
        case ERROR:
          const newErrorState = {
            journalConfig: {
              ...state?.[layout]?.[urlKey]?.config?.journalConfig,
              loading: false,
            },
          };
          return finalReduxCachedState(
            state,
            layout,
            urlKey,
            newErrorState,
            "isJournalRendered",
            false,
          );

        default:
          return {
            ...state,
          };
      }
    }
    case W_F_UPDATE_SELECTED_VALUES: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const changedKey =
        Object.entries(state?.[layout]?.[urlKey]?.config ?? {}).find(
          (item) => item[1].key === action.filter_key,
        )?.[0] ?? "";
      const newState = {
        [changedKey]: {
          ...state?.[layout]?.[urlKey]?.config?.[changedKey],
          selectedValues: action.values,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }
    case W_F_UPDATE_EXCLUDE_VALUES: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const stateinWhichNeedToBeApplied = {
        markproprietorConfig:
          state?.[layout]?.[urlKey]?.config?.markproprietorConfig,
        threatproprietorConfig:
          state?.[layout]?.[urlKey]?.config?.threatproprietorConfig,
      };
      const changedKey =
        Object.entries(stateinWhichNeedToBeApplied).find(
          (item) => item[1].key === action.filter_key,
        )?.[0] ?? "";
      const newState = {
        [changedKey]: {
          ...state?.[layout]?.[urlKey]?.config?.[changedKey],
          excludeValues: action.values,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }
    case W_F_UPDATE_SEARCH_VALUES: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const changedKey =
        Object.entries(state?.[layout]?.[urlKey]?.config ?? {}).find(
          (item) => item[1].key === action.filter_key,
        )?.[0] ?? "";
      const newState = {
        [changedKey]: {
          ...state?.[layout]?.[urlKey]?.config?.[changedKey],
          searchValue: action.searchValue,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }
    case W_F_UPDATE_DATE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const stateinWhichNeedToBeApplied = {
        yourDateOfApplicationConfig:
          state?.[layout]?.[urlKey]?.config?.yourDateOfApplicationConfig,
        yourDateOfUsageConfig:
          state?.[layout]?.[urlKey]?.config?.yourDateOfUsageConfig,
        threatDateOfApplicationConfig:
          state?.[layout]?.[urlKey]?.config?.threatDateOfApplicationConfig,
        threatDateOfUsageConfig:
          state?.[layout]?.[urlKey]?.config?.threatDateOfUsageConfig,
      };
      const changedKey =
        Object.entries(stateinWhichNeedToBeApplied).find(
          (item) => item[1].key === action.filter_key,
        )?.[0] ?? "";
      const newState = {
        [changedKey]: {
          ...state?.[layout]?.[urlKey]?.config?.[changedKey],
          value: action.values,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_F_UPDATE_DATE_PROPOSED: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const stateinWhichNeedToBeApplied = {
        yourDateOfApplicationConfig:
          state?.[layout]?.[urlKey]?.config?.yourDateOfApplicationConfig,
        yourDateOfUsageConfig:
          state?.[layout]?.[urlKey]?.config?.yourDateOfUsageConfig,
        threatDateOfApplicationConfig:
          state?.[layout]?.[urlKey]?.config?.threatDateOfApplicationConfig,
        threatDateOfUsageConfig:
          state?.[layout]?.[urlKey]?.config?.threatDateOfUsageConfig,
      };
      const changedKey =
        Object.entries(stateinWhichNeedToBeApplied).find(
          (item) => item[1].key === action.filter_key,
        )?.[0] ?? "";
      const newState = {
        [changedKey]: {
          ...state?.[layout]?.[urlKey]?.config?.[changedKey],
          dateOfUsageProposed: action.values,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }
    case W_F_EMPTY_SEARCH_VALUES: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        threatAttorneyConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatAttorneyConfig,
          searchValue: "",
        },
        markproprietorConfig: {
          ...state?.[layout]?.[urlKey]?.config?.markproprietorConfig,
          searchValue: "",
        },
        threatproprietorConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatproprietorConfig,
          searchValue: "",
        },
        tagsConfig: {
          ...state?.[layout]?.[urlKey]?.config?.tagsConfig,
          searchValue: "",
        },
        threatLabelConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatLabelConfig,
          searchValue: "",
        },
        ownBrandProprietorConfig: {
          ...state?.[layout]?.[urlKey]?.config?.ownBrandProprietorConfig,
          searchValue: "",
        },
        threatBrandProprietorConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatBrandProprietorConfig,
          searchValue: "",
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }
    case W_TEMPORARY_THREAT_FILTER_SAVE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        temp_threat_filters: {
          ...state?.[layout]?.[urlKey]?.config?.temp_threat_filters,
          [action.urlKey]: {
            ...state?.[layout]?.[urlKey]?.config?.temp_threat_filters?.[
              action.urlKey
            ],
            [action.application_number]: action.filters,
          },
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }
    case W_TEMPORARY_THREAT_FILTER_REMOVE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const updatedUrlKeyData = {
        ...state?.[layout]?.[urlKey]?.config?.temp_threat_filters?.[
          action.urlKey
        ],
      };
      delete updatedUrlKeyData[action.application_number];
      const newState = {
        temp_threat_filters: {
          ...state?.[layout]?.[urlKey]?.config?.temp_threat_filters,
          [action.urlKey]: updatedUrlKeyData,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_TEMPORARY_THREAT_FILTER_DEFAULT_VALUE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        temp_threat_filters: {},
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "temporaryThreatFilterCleared",
        true,
      );
    }

    case W_F_SEARCH_TYPE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        reduxSearchType: action.value,
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }
    case W_F_SEARCH_VALUE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        reduxSearchValue: action.value,
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isSearchRendered",
        true,
      );
    }

    case W_F_FILTER_APPLIED: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        isFilterApplied: action.value,
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_F_RESET_ALL: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        markTypeConfig: {
          ...state?.[layout]?.[urlKey]?.config?.markTypeConfig,
          selectedValues: [],
        },
        markCategoryConfig: {
          ...state?.[layout]?.[urlKey]?.config?.markCategoryConfig,
          selectedValues: [],
        },
        threatTypeConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatTypeConfig,
          selectedValues: [],
        },
        threatCategoryConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatCategoryConfig,
          selectedValues: [],
        },
        markOfficeConfig: {
          ...state?.[layout]?.[urlKey]?.config?.markOfficeConfig,
          selectedValues: [],
        },
        threatOfficeConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatOfficeConfig,
          selectedValues: [],
        },
        infringementConfig: {
          ...state?.[layout]?.[urlKey]?.config?.infringementConfig,
          selectedValues: [],
        },
        threatAttorneyConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatAttorneyConfig,
          selectedValues: [],
          searchValue: "",
        },
        markproprietorConfig: {
          ...state?.[layout]?.[urlKey]?.config?.markproprietorConfig,
          selectedValues: [],
          excludeValues: [],
          searchValue: "",
        },
        threatproprietorConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatproprietorConfig,
          selectedValues: [],
          excludeValues: [],
          searchValue: "",
        },
        markClassConfig: {
          ...state?.[layout]?.[urlKey]?.config?.markClassConfig,
          selectedValues: [],
          andOr: false,
        },
        threatClassConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatClassConfig,
          selectedValues: [],
          andOr: false,
        },
        tagsConfig: {
          ...state?.[layout]?.[urlKey]?.config?.tagsConfig,
          selectedValues: [],
          searchValue: "",
          andOr: false,
          noTags: false,
        },
        threatLabelConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatLabelConfig,
          selectedValues: [],
          searchValue: "",
        },
        yourDateOfApplicationConfig: {
          ...state?.[layout]?.[urlKey]?.config?.yourDateOfApplicationConfig,
          value: {},
        },
        yourDateOfUsageConfig: {
          ...state?.[layout]?.[urlKey]?.config?.yourDateOfUsageConfig,
          dateOfUsageProposed: false,
          value: {},
        },
        threatDateOfApplicationConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatDateOfApplicationConfig,
          value: {},
        },
        threatDateOfUsageConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatDateOfUsageConfig,
          dateOfUsageProposed: false,
          value: {},
        },
        reduxSearchValue: "",
        reduxSearchType: "Application No/Name",
        isFilterApplied: false,
        excludeReportedConfig: false,
        shortlistedConfig: false,
        excludeShortlistedConfig: false,
        similarityConfig: {
          key: "similarity",
          value: {
            from: 0,
            to: 100,
          },
        },
        imageTextPercentageConfig: {
          key: "image_text_percentage",
          value: {
            from: 0,
            to: 100,
          },
        },
        threatImageTextPercentageConfig: {
          key: "threat_image_text_percentage",
          value: {
            from: 0,
            to: 100,
          },
        },
        sameClassInfringementConfig: false,
        excludeSameClassInfringementConfig: false,
        ownBrandProprietorConfig: {
          ...state?.[layout]?.[urlKey]?.config?.ownBrandProprietorConfig,
          selectedValues: [],
          excludeValues: [],
          searchValue: "",
        },
        threatBrandProprietorConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatBrandProprietorConfig,
          selectedValues: [],
          excludeValues: [],
          searchValue: "",
        },
        ownBrandClassConfig: {
          ...state?.[layout]?.[urlKey]?.config?.ownBrandClassConfig,
          selectedValues: [],
          andOr: false,
        },
        threatBrandClassConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatBrandClassConfig,
          selectedValues: [],
          andOr: false,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_F_CHANGE_AND_OR: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const stateinWhichNeedToBeApplied = {
        markClassConfig:
          state?.[layout]?.[urlKey]?.config?.markClassConfig ?? {},
        threatClassConfig:
          state?.[layout]?.[urlKey]?.config?.threatClassConfig ?? {},
        tagsConfig: state?.[layout]?.[urlKey]?.config?.tagsConfig ?? {},
        ownBrandClassConfig:
          state?.[layout]?.[urlKey]?.config?.ownBrandClassConfig ?? {},
        threatBrandClassConfig:
          state?.[layout]?.[urlKey]?.config?.threatBrandClassConfig ?? {},
      };
      const changedKey =
        Object.entries(stateinWhichNeedToBeApplied).find(
          (item) => item[1].key === action.filter_key,
        )?.[0] ?? "";
      const newState = {
        [changedKey]: {
          ...state?.[layout]?.[urlKey]?.config?.[changedKey],
          andOr: action.value,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_LIST_BOOLEAN_FILTER_UPDATE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        [action.key]: action.value,
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_F_UPDATE_no_tags: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        tagsConfig: {
          ...state?.[layout]?.[urlKey]?.config?.tagsConfig,
          noTags: action.value,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_F_UPDATE_similarity: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        similarityConfig: {
          ...state?.[layout]?.[urlKey]?.config?.similarityConfig,
          value: action.value,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_F_UPDATE_IMAGE_TEXT_PERCENTAGE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        imageTextPercentageConfig: {
          ...state?.[layout]?.[urlKey]?.config?.imageTextPercentageConfig,
          value: action.value,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_F_UPDATE_THREAT_IMAGE_TEXT_PERCENTAGE: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        threatImageTextPercentageConfig: {
          ...state?.[layout]?.[urlKey]?.config?.threatImageTextPercentageConfig,
          value: action.value,
        },
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "isFiltersRendered",
        true,
      );
    }

    case W_F_UPDATE_WHOLE_CONFIG: {
      const layout = window.location.pathname.split("/")[3];
      const urlKey = window.location.pathname.split("/")[2];
      const newState = {
        ...action.newConfig,
      };
      return finalReduxCachedState(
        state,
        layout,
        urlKey,
        newState,
        "previousStateAchieved",
        true,
      );
    }

    default:
      return state;
  }
}
