import React, { Component } from "react";
import { connect } from "react-redux";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col, Button, Modal, message, Select } from "antd";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { LOADING } from "../../../constants/commonConstants/generalConstants";
import {
  addNotes,
  editNotes,
} from "../../../redux/actions/managerActions/notes/actions";
import { getTeamMember } from "../../../redux/actions/commonActions/actions";

const { Option } = Select;
class NotesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      note: "",
      email: "Select Profile",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.getTeamMember("manager");
    } else {
      this.props.logOut();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.note !== this.props.note) {
      const note_html = nextProps.note;
      const contentBlock = htmlToDraft(note_html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          email: nextProps.teamemail,
          editorState,
          note: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        });
      }
    }
    return true;
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      note: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  async handleSubmit(operation) {
    const { note } = this.state;

    if (operation === "ADD" && this.state.email === "Select Profile") {
      message.error("Please select profile");
    } else if (operation === "ADD") {
      await this.props.addNotes({
        actionType: this.props.actionType[0],
        email: this.state.email,
        note_for: this.props.note_for,
        application_number: this.props.application_number,
        note_data: note,
        subscription: this.props.subscription,
      });

      if (this.props.addManageNotesStatus === "ERROR") {
        message[this.props.addManageNotesStatus.toLowerCase()](
          this.props.manageNotesMessage
        );
      } else {
        message[this.props.addManageNotesStatus.toLowerCase()](
          this.props.manageNotesMessage
        );
        this.handleCloseNotes();
      }
    } else if (operation === "EDIT") {
      await this.props.editNotes({
        actionType: this.props.actionType[1],
        email: this.state.email,
        note_for: this.props.note_for,
        application_number: this.props.application_number,
        note_id: this.props.note_id,
        note: note,
        subscription: this.props.subscription,
      });
      message[this.props.editManageNotesStatus.toLowerCase()](
        this.props.manageNotesMessage
      );
      this.handleCloseNotes();
    }
  }

  handleCloseNotes() {
    if (this.props.type === "ADD") {
      this.setState({
        editorState: EditorState.createEmpty(),
        note: "",
        email: "Select Profile",
      });
    }
    this.props.onCloseModal();
  }

  render() {
    const { teamMember } = this.props;
    const { editorState, note } = this.state;
    const { addManageNotesStatus, editManageNotesStatus } = this.props;

    return (
      <>
        <Modal
          open={this.props.visible}
          onOk={() => this.props.onCloseModal()}
          onCancel={() => this.props.onCloseModal()}
          closable={false}
          footer={false}
          maskClosable={false}
          className="comment-modal"
        >
          <div className="comment-modal-container vertical_scroll">
            <Select
              value={this.state.email}
              style={{ width: "250px" }}
              onChange={(value) => this.setState({ email: value })}
            >
              <Option key="Select Profile" value="Select Profile">
                Select Profile
              </Option>
              {teamMember?.length > 0 &&
                teamMember.map((eachPropfileList) => (
                  <Option
                    key={eachPropfileList.id}
                    value={eachPropfileList.email}
                  >
                    {eachPropfileList.email}
                  </Option>
                ))}
            </Select>
            <Row className="comment-section">
              <Col span={24} className="comment-container">
                <div className="comment-block">
                  <div className="text-editor">
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={this.onEditorStateChange}
                      wrapperClassName="wrapper-class"
                      toolbarClassName="toolbar-class"
                      editorClassName="editor-class"
                      toolbar={{
                        inline: {
                          bold: { className: "bold-option-custom" },
                          italic: { className: "italic-option-custom" },
                          underline: { className: "underline-option-custom" },
                          strikethrough: {
                            className: "strikethrough-option-custom",
                          },
                          monospace: { className: "monospace-option-custom" },
                          superscript: {
                            className: "superscript-option-custom",
                          },
                          subscript: { className: "subscript-option-custom" },
                        },
                        blockType: {
                          className: "blockType-option-custom-wide",
                          dropdownClassName: "blockType-dropdown-custom",
                        },
                        fontSize: {
                          className: "fontSize-option-custom-medium",
                        },
                        list: {
                          unordered: { className: "unordered-option-custom" },
                          ordered: { className: "ordered-option-custom" },
                          indent: { className: "indent-option-custom" },
                          outdent: { className: "outdent-option-custom" },
                        },
                        textAlign: {
                          left: { className: "left-option-custom" },
                          center: { className: "center-option-custom" },
                          right: { className: "right-option-custom" },
                          justify: { className: "justify-option-custom" },
                        },
                        fontFamily: {
                          className: "fontFamily-option-custom-wide",
                          dropdownClassName: "fontFamily-dropdown-custom",
                        },
                        colorPicker: {
                          className: "colorPicker-option-custom",
                          popupClassName: "colorPicker-popup-custom",
                        },
                        link: {
                          popupClassName: "link-popup-custom",
                          link: { className: "link-option-custom" },
                          unlink: { className: "unlink-option-custom" },
                        },
                        emoji: {
                          className: "emoji-option-custom",
                          popupClassName: "emoji-popup-custom",
                        },
                        embedded: {
                          className: "embedded-option-custom",
                          popupClassName: "embedded-popup-custom",
                        },
                        image: {
                          className: "image-option-custom",
                          popupClassName: "image-popup-custom",
                        },
                        remove: { className: "remove-option-custom" },
                        history: {
                          undo: { className: "undo-option-custom" },
                          redo: { className: "redo-option-custom" },
                        },
                      }}
                    />
                    <textarea
                      style={{ display: "none" }}
                      disabled
                      value={note}
                    />
                  </div>
                </div>
                <div className="comment-block alignR">
                  {this.props.type === "ADD" && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => this.handleSubmit("ADD")}
                      style={{ margin: "0px 10px" }}
                      loading={addManageNotesStatus === LOADING}
                    >
                      Add Note
                    </Button>
                  )}
                  {this.props.type === "EDIT" && (
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => this.handleSubmit("EDIT")}
                      style={{ margin: "0px 10px" }}
                      loading={editManageNotesStatus === LOADING}
                    >
                      Edit Note
                    </Button>
                  )}
                  <Button onClick={() => this.handleCloseNotes()}>Close</Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  addNotes,
  editNotes,
  getTeamMember,
};

function mapStateToProps(state) {
  const { teamMember, teamMemberStatus } = state.profile;

  const { manageNotesMessage, addManageNotesStatus, editManageNotesStatus } =
    state.managerState.notesState;
  return {
    teamMember,
    teamMemberStatus,
    manageNotesMessage,
    addManageNotesStatus,
    editManageNotesStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal);
