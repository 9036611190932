export const PATENT = 'PATENT';
export const PSEUDO_PATENT = 'PSEUDO_PATENT';
export const PATENT_RENEWALS = 'PATENT_RENEWALS';
export const PATENT_RENEWAL_FILED = 'PATENT_RENEWAL_FILED';
export const REPLY_TO_FER = 'REPLY_TO_FER';
export const PSEUDO_REPLY_TO_FER = 'PSEUDO_REPLY_TO_FER';
export const REPLY_FILED_TO_FER = 'REPLY_FILED_TO_FER';
export const REFINED_PATENT = 'REFINED_PATENT';
export const PATENT_CHANGELOG = 'PATENT_CHANGELOG';
export const PATENT_APPLICANT = 'PATENT_APPLICANT';
export const PATENT_APPLICANT_DETAIL = 'PATENT_APPLICANT_DETAIL';
export const PATENT_INVENTOR = 'PATENT_INVENTOR';
export const PATENT_INVENTOR_DETAIL = 'PATENT_INVENTOR_DETAIL';
export const UPLOAD_FILE_P = 'UPLOAD_FILE_P';

export const PENDING_WORKING_OF_PATENT = 'PENDING_WORKING_OF_PATENT';
export const STATEMENT_OF_UNDERTAKING = 'STATEMENT_OF_UNDERTAKING';
export const REQUEST_FOR_EXAMINATION = 'REQUEST_FOR_EXAMINATION';

export const UPLOAD_FILE_PT = "UPLOAD_FILE_PT";
export const MANAGE_PATENT = 'MANAGE_PATENT';
export const POST_MANAGE_PATENT='POST_MANAGE_PATENT';
