import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import {
  Row,
  Col,
  Cascader,
  Button,
  Modal,
  Tooltip,
  message,
  Alert,
  Mentions,
  Typography,
  Divider,
} from "antd";

import { CKEditor } from "ckeditor4-react";

import {
  createEmailTemplate,
  editEmailTemplate,
} from "../../../redux/actions/commonActions/actions";
import {
  CREATE_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
} from "../../../redux/actions/commonActions/types";
import { LOADING } from "../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

const { Option } = Mentions;
const { Title, Text } = Typography;
class CreateTemplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      subject: "",
      template_id: "",
      product: "",
      subscription: "",
      productValue: [],
      operation: "",
      reportType: "",
      mentions: [],
      suggestion_options: [],
      sub_section: "",
    };
  }

  getReportOptions = (product, subscription, sub_section) => {
    const reportOptions = this.props.emailVariables
      ?.filter((obj) => obj.value === product)[0]
      ?.children?.filter((obj) => obj.value === subscription)[0]
      ?.children?.filter((obj) => obj.value === sub_section)[0]?.variables;

    if (!reportOptions) return [];
    return reportOptions;
  };

  getSuggestionOptions = (product, subscription, sub_section) => {
    const suggestionOptions = this.props.emailVariables
      ?.filter((obj) => obj.value === product)[0]
      ?.children?.filter((obj) => obj.value === subscription)[0]
      ?.children?.filter((obj) => obj.value === sub_section)[0]
      ?.variables?.map((x) => {
        return { value: x.value, label: x.text };
      });

    if (!suggestionOptions) return [];
    return suggestionOptions;
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.operation !== this.props.operation) {
      this.setState({
        content: checkValue(nextProps.templateContent)
          ? nextProps.templateContent.content
          : "",
        subject: checkValue(nextProps.templateContent)
          ? nextProps.templateContent.subject
          : "",
        template_id: checkValue(nextProps.templateContent)
          ? nextProps.templateContent.id
          : "",
        product: checkValue(nextProps.templateContent)
          ? nextProps.templateContent.product
          : "",
        subscription: checkValue(nextProps.templateContent)
          ? nextProps.templateContent.subscription
          : "",
        productValue: checkValue(nextProps.templateContent)
          ? [
              nextProps.templateContent.product,
              nextProps.templateContent.subscription,
              nextProps.templateContent.sub_section,
            ]
          : [],
        operation: checkValue(nextProps.operation) ? nextProps.operation : "",
        sub_section: checkValue(nextProps.templateContent)
          ? nextProps.templateContent.sub_section
          : "",
        mentions: this.getReportOptions(
          nextProps.templateContent?.product,
          nextProps.templateContent?.subscription,
          nextProps.templateContent?.sub_section,
        ),
        suggestion_options: this.getSuggestionOptions(
          nextProps.templateContent?.product,
          nextProps.templateContent?.subscription,
          nextProps.templateContent?.sub_section,
        ),
      });
    }
    return true;
  }

  handleChange(type, value) {
    this.setState({
      [type]: value,
    });
  }

  handleChangeProduct(value) {
    const vars = this.getReportOptions(value[0], value[1], value[2]);
    const suggestion_options = this.getSuggestionOptions(
      value[0],
      value[1],
      value[2],
    );
    this.setState({
      productValue: value,
      product: value[0],
      subscription: value[1],
      sub_section: value[2],
      reportType: "",
      mentions: vars,
      suggestion_options: suggestion_options,
    });
  }

  onEditorChange = (evt) => {
    this.setState({
      content: evt.editor.getData(),
    });
  };

  async handleSubmit(operation) {
    const {
      content,
      subject,
      template_id,
      product,
      subscription,
      productValue,
      sub_section,
    } = this.state;
    if (subject.length > 0 && productValue.length > 0) {
      if (operation === "Create") {
        await this.props.createEmailTemplate({
          actionType: CREATE_EMAIL_TEMPLATE,
          content: content,
          subject: subject,
          product: product,
          subscription: subscription,
          sub_section: sub_section,
        });
        message[this.props.createEmailTemplateStatus.toLowerCase()](
          this.props.emailTemplateMessage,
        );
      } else if (operation === "Edit") {
        await this.props.editEmailTemplate({
          actionType: EDIT_EMAIL_TEMPLATE,
          template_id: template_id,
          content: content,
          subject: subject,
          product: product,
          subscription: subscription,
          sub_section: sub_section,
        });
        message[this.props.editEmailTemplateStatus.toLowerCase()](
          this.props.emailTemplateMessage,
        );
      }
      this.setState({
        content: "",
        subject: "",
        template_id: "",
        product: "",
        subscription: "",
        productValue: [],
      });
      window.CKEDITOR.instances.editor.setData("");
      this.props.handleTabChange(product);
      this.props.onCloseModal();
    } else {
      message.error("please fill all required fields");
    }
  }

  dataFeed = (opts, callback) => {
    if (this.state.mentions && this.state.mentions.length === 0) {
      message.error(
        "Please Select Product/Subscription/Sub-Section to Use Variables",
      );
    }

    let matchProperty = "value",
      data = [];
    data = this.state.mentions.filter(function (item) {
      return (
        item[matchProperty].toLowerCase().includes(opts.query.toLowerCase()) ||
        item["text"].toLowerCase().includes(opts.query.toLowerCase())
      );
    });

    data = data.sort(function (a, b) {
      return a[matchProperty].localeCompare(b[matchProperty], undefined, {
        sensitivity: "accent",
      });
    });
    callback(data);
  };

  render() {
    const {
      content,
      subject,
      productValue,
      operation,
      product,
      subscription,
      sub_section,
    } = this.state;
    const {
      productSubscription,
      createEmailTemplateStatus,
      editEmailTemplateStatus,
      emailVariables,
    } = this.props;

    let tags = this.state.suggestion_options.map((option) => option.value);

    const view = (
      <>
        <Divider style={{ margin: "8px 0px" }} />

        <Row style={{ marginLeft: 16 }}>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                display: "inline-block",
                padding: "5px 0px",
                fontSize: 16,
              }}
            >
              <Text strong>PRODUCT :- </Text>
              <Text>{product}</Text>
            </div>
            <div
              style={{
                display: "inline-block",
                padding: "5px 10px",
                fontSize: 16,
              }}
            >
              <Text strong>SUBSCRIPTION :- </Text>
              <Text>{subscription}</Text>
            </div>
            <div
              style={{
                display: "inline-block",
                padding: "5px 10px",
                fontSize: 16,
              }}
            >
              <Text strong>SUB-SECTION :- </Text>
              <Text>{sub_section}</Text>
            </div>
          </Col>
          <Col span={24} style={{ margin: "16px 0px", fontSize: 16 }}>
            <Text strong>SUBJECT :- </Text>
            <Text>{subject}</Text>
          </Col>
        </Row>
        <div
          className="custom-send-email-container vertical_scroll"
          style={{
            height: "calc(100vh - 400px)",
            border: "1px solid #ddd",
            borderRadius: 5,
            margin: "16px",
            borderStyle: "dashed",
          }}
        >
          <Row className="custom-send-email">
            <Col span={24} className="custom-send-email-form">
              <div className="custom-send-email-Input-Field">
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="custom-send-email-Input-Field alignR">
          <Button
            type="primary"
            onClick={() => this.setState({ operation: "Edit" })}
            style={{
              margin: "0px 10px",
              // background: `${operation === "Edit" ? "#31ce4e" : ""}`,
              // borderColor: `${operation === "Edit" ? "#31ce4e" : ""}`,
            }}
            loading={
              operation === "Create"
                ? createEmailTemplateStatus === LOADING
                : operation === "Edit"
                  ? editEmailTemplateStatus === LOADING
                  : false
            }
            disabled={
              operation === "Create"
                ? this.state.productValue && !this.state.productValue.length
                : false
            }
          >
            Edit Template
          </Button>
          <Button onClick={this.props.onCloseModal}>Close</Button>
        </div>
      </>
    );

    const form = (
      <>
        <span className="custom-send-email-Input-Field">
          <Tooltip
            placement="topLeft"
            title="Please Select Product, Subscription and Subsection"
          >
            <Cascader
              style={{ width: 500 }}
              options={
                emailVariables && emailVariables.length > 0
                  ? emailVariables
                  : []
              }
              expandTrigger="hover"
              placeholder="Select Product, Subscription and Sub-Section"
              allowClear={false}
              value={productValue}
              onChange={(value) => this.handleChangeProduct(value)}
              disabled={operation === "Edit"}
            />
          </Tooltip>
        </span>
        <Divider dashed style={{ margin: "16px 0px" }} />
        <div className="custom-send-email-Input-Field">
          <Mentions
            id="subject_input"
            placeholder="Subject"
            onChange={(value) => this.handleChange("subject", value)}
            value={subject}
          >
            {this.state.suggestion_options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Mentions>
        </div>
        <div className="custom-send-email-Input-Field">
          <Alert
            type="info"
            message={
              'Note: Use "@" to get a dropdown of the variables you can use to create your template'
            }
          />
        </div>
        <div className="custom-send-email-Input-Field">
          <div className="text-editor">
            <CKEditor
              name="editor"
              initData={operation === "Create" ? "" : content}
              onChange={this.onEditorChange}
              editorUrl={"https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js"}
              config={{
                height: "32vh",
                extraAllowedContent: "div(*)",
                extraPlugins: ["editorplaceholder", "mentions"],
                editorplaceholder: "Start creating template...",
                mentions: [
                  {
                    feed: this.dataFeed,
                    itemTemplate:
                      '<li data-id="{id}"><strong>{text}</strong></li>',
                    outputTemplate:
                      "<span><strong>@{value}</strong></span><span>&nbsp;</span>",
                    minChars: 0,
                  },
                  {
                    feed: tags,
                    marker: "#",
                    itemTemplate:
                      '<li data-id="{id}"><strong>{name}</strong></li>',
                    outputTemplate:
                      "<span><strong>{name}</strong></span><span>&nbsp;</span>",
                    minChars: 1,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="custom-send-email-Input-Field alignR">
          <Button
            type="primary"
            onClick={() => this.handleSubmit(operation)}
            style={{
              margin: "0px 10px",
            }}
            loading={
              operation === "Create"
                ? createEmailTemplateStatus === LOADING
                : operation === "Edit"
                  ? editEmailTemplateStatus === LOADING
                  : false
            }
            disabled={
              operation === "Create"
                ? this.state.productValue && !this.state.productValue.length
                : false
            }
          >
            {operation}
          </Button>
          <Button
            onClick={() => {
              this.props.onCloseModal();
              if (window.CKEDITOR.instances) {
                window.CKEDITOR.instances.editor.setData("");
              }
            }}
          >
            Close
          </Button>
        </div>
      </>
    );

    return (
      <Modal
        open={this.props.visible}
        onOk={this.props.onCloseModal}
        onCancel={this.props.onCloseModal}
        closable={false}
        footer={false}
        maskClosable={false}
        className="custom-send-email-modal"
      >
        <div
          className="custom-send-email-container "
          style={{ height: "calc(100vh - 100px)" }}
        >
          <Row className="custom-send-email">
            <Col span={24} style={{ marginLeft: 12 }}>
              {operation === "Create" && (
                <Title level={3}>Create Template</Title>
              )}
              {operation === "Edit" && <Title level={3}>Edit Template</Title>}
              {operation === "View" && <Title level={3}>View Template</Title>}
            </Col>
            <Col span={24} className="custom-send-email-form">
              {operation === "View" && view}
              {(operation === "Create" || operation === "Edit") && form}
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  createEmailTemplate,
  editEmailTemplate,
};

function mapStateToProps(state) {
  const {
    emailTemplateMessage,
    createEmailTemplateStatus,
    editEmailTemplateStatus,
  } = state.emailTemplateState;
  const { emailVariables } = state.emailVariables;
  return deepFreeze({
    emailTemplateMessage,
    createEmailTemplateStatus,
    editEmailTemplateStatus,
    emailVariables,
    productSubscription: state.productSubscription.productSubscription,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplete);
