import { combineReducers } from "redux";
import listData from "./listData";
import singleClient from "./singleClient";
import clientContact from "./clientContact";
import clientNotes from "./clientNotes";
import clientAttachment from "./clientAttachment";
import clientLinkedTrademarks from "./clientLinkedTrademarks";
import singleClientContact from "./singleClientContact";
import singleClientNotes from "./singleClientNotes";

export default combineReducers({
  listData: listData,
  singleClient: singleClient,
  singleClientContact: singleClientContact,
  singleClientNotes: singleClientNotes,
  clientContact: clientContact,
  clientNotes: clientNotes,
  clientAttachment: clientAttachment,
  clientLinkedTrademarks: clientLinkedTrademarks,
});
