//--------LIBRARIES--------
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MikeContainer from "../../CommonComponents/Layout/MikeContainer";
import BrandCompareTable from "./Components/Content";
import { Row, Col, Tabs, Button } from "antd";
import UploadHeader from "./Components/Header";
import UploadModal from "./Components/Modals/Upload";
import CreateAnalysisModalForm from "./Components/Modals/CreateAnalysis";
import Tour from "reactour";
import { ScanOutlined } from "@ant-design/icons";

const ListLayoutDashboard = (props) => {
  const [tab, setTab] = useState("your_marks");
  const [openUpload, setOpenUpload] = useState(false);
  const [openAnalysis, setOpenAnalysis] = useState(false);

  const [selected, setSelected] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [proprietors, setProprietors] = useState([]);
  const [excludeProprietors, setExcludeProprietors] = useState([]);
  const [proprietorsSearchTerm, setProprietorsSearchTerm] = useState("");

  const [classes, setClasses] = useState([]);
  const [classSearchTerm, setClassSearchTerm] = useState("");

  const [searchValue, setSearchValue] = React.useState("");
  const [primaryFilterApplied, setPrimaryFilterApplied] = useState(false);

  // Take Tour States
  const [isTourOpen, setIsTourOpen] = useState(false);
  const onTabChange = (key) => {
    setSelectAll(false);
    setSelected([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setProprietors([]);
    setExcludeProprietors([]);
    setClasses([]);
    setSearchValue("");
    setTab(key);
    setProprietorsSearchTerm("");
    setClassSearchTerm("");
    setPrimaryFilterApplied(false);
  };

  const generateFilterObject = () => {
    const filters = {
      brand_classes: {
        value: classes,
        logic: "OR",
      },
      brand_proprietors: {
        value: proprietors,
      },
      exclude_proprietors: {
        value: excludeProprietors,
      },
      contains_term: {
        value: searchValue,
      },
    };
    return filters;
  };

  const propsForFilters = {
    classes,
    setClasses,
    proprietors,
    setProprietors,
    excludeProprietors,
    setExcludeProprietors,
    proprietorsSearchTerm,
    setProprietorsSearchTerm,
    classSearchTerm,
    setClassSearchTerm,
    generateFilterObject,
    searchValue,
    setSearchValue,
    primaryFilterApplied,
    setPrimaryFilterApplied,
    selected,
    setSelected,
    selectAll,
    setSelectAll,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
    openUpload,
    setOpenUpload,
    setIsTourOpen,
    isTourOpen,
  };

  const items = [
    {
      key: "your_marks",
      label: "Your Brand",
      children: (
        <UploadHeader
          threatFlag={false}
          setOpenUpload={setOpenUpload}
          {...propsForFilters}
        />
      ),
    },
    {
      key: "threat_marks",
      label: "Threat Brand",
      children: (
        <UploadHeader
          threatFlag={true}
          setOpenUpload={setOpenUpload}
          {...propsForFilters}
        />
      ),
    },
  ];
  const Content = (
    <BrandCompareTable
      threatFlag={tab === "threat_marks"}
      {...propsForFilters}
    />
  );

  const Header = (
    <React.Fragment>
      <Row>
        <Col span={24} style={{ padding: "8px", marginLeft: "8px" }}>
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onTabChange}
            tabBarExtraContent={
              <div style={{ margin: "16px" }}>
                <Button
                  icon={<ScanOutlined />}
                  id="bc_analyze"
                  type="primary"
                  onClick={() => setOpenAnalysis(true)}
                >
                  Create Analysis
                </Button>
              </div>
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  );

  const modals = (
    <>
      <CreateAnalysisModalForm
        openAnalysis={openAnalysis}
        onCancel={() => setOpenAnalysis(false)}
      />
    </>
  );

  const brandCompareManageSteps = [
    {
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Welcome to Brand Compare Manage
          </div>
          <br />
          <p style={{ color: "white" }}>
            Here, you can upload and delete your threat brands, and also create
            new analyses.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: "#bc_upload",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Upload brands using excel.
          </div>
          <br />
          <p style={{ color: "white" }}>
            Use this to add new brands for analysis.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: ".tm-protect-table .ant-table-thead",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Filters in the headings
          </div>
          <br />
          <p style={{ color: "white" }}>
            Now for the data available in table you can filter it by applying it
            here at the heading itself.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: ".ant-tabs-nav-wrap",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Ability to Switch between your and threat brands.
          </div>
          <br />
          <p style={{ color: "white" }}>
            Using this tab you can switch between your and threat brands{" "}
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: ".tmapplied_selection_cols",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Select marks and delete
          </div>
          <br />
          <p style={{ color: "white" }}>
            You can select and delete marks from here.{" "}
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: "#bc_download",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Download Portfolio
          </div>
          <br />
          <p style={{ color: "white" }}>
            Click here to download all the marks uploaded in Brand Compare.{" "}
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },

    {
      selector: "#bc_update_status",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Activate/deactivate analysis of selected brands.
          </div>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: "#bc_analyze",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Create new analysis.
          </div>
          <br />
          <p style={{ color: "white" }}>
            Click here to create new infringements analysis of the uploaded
            brands.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
  ];
  return (
    <>
      <Tour
        startAt={0}
        steps={brandCompareManageSteps}
        isOpen={isTourOpen}
        onRequestClose={() => {
          setIsTourOpen(false);
          // if (!isTableDataPresent) {
          //   dispatch(addListSampleData());
          // }
        }}
        nextButton={<Button>Next</Button>}
        prevButton={
          <Button style={{ color: "white", display: "none" }} type="link">
            Back
          </Button>
        }
        disableDotsNavigation
        showNavigation={false}
        lastStepNextButton={<Button>Done</Button>}
        closeWithMask={false}
        badgeContent={(current, total) => `${current} of ${total}`}
        disableInteraction={true}
      />
      <MikeContainer
        header={Header}
        content={Content}
        modals={modals}
        showHeader={false}
      />
    </>
  );
};

export default ListLayoutDashboard;
