//--------Routes--------
import NoMatch from "../../commonComponents/NoMatch";
// import ListLayoutTMApplied from "../Pages/ListLayout/TMApplied";
import ListLayoutTMProtect from "../Pages/ListLayout/TMProtect";
// import ListLayoutImageText from "../Pages/ListLayout/ImageText";
// import GridLayoutTMApplied from "../Pages/GridLayout/TMApplied";
import GridLayoutTMProtect from "../Pages/GridLayout/TMProtect";
// import GridLayoutImageText from "../Pages/GridLayout/ImageText";
// import GridLayoutImageLabel from "../Pages/GridLayout/ImageLabel";
import Journals from "../Pages/Journals";
import ProfileDashboard from "../../commonComponents/ProfileDetails/ProfileDashboard";
import Reports from "../Pages/Reports";
import ManageTrademarks from "../Pages/ManageTrademarks";
import EmailTemplate from "../../commonComponents/EmailTemplates";
import ManageEmails from "../../commonComponents/ClientEmails";
import Faqs from "../../managerComponents/user-maual/Faqs";
import EmailPreferences from "../../managerComponents/profile/EmailPreferences";
import ClientEmailCustomReport from "../../commonComponents/CommonReports/ClientEmailCustomReport";

// import ListLayoutTMApplied from "../Pages/TMApplied/ListLayout";
import GridLayoutDashboard from "./../Pages/GridLayoutDashboard/GridLayout";
import ListLayoutDashboard from "../Pages/ListLayoutDashboard/ListLayout";
import BrandCompareUpload from "./../Pages/BrandCompare/BrandCompareUpload";
import ImageSearchDashboard from "../Pages/ImageSearchDashboard/ImageSearch";
import { ClientMaster } from "../../commonComponents/MasterComponents/ClientMaster/ClientMaster";

export const routesArray = [
  {
    path: "/watch/tm_applied/list_layout",
    component: ListLayoutDashboard,
  },
  {
    path: "/watch/tm_protect/list_layout",
    // component: ListLayoutTMProtect,
    component: ListLayoutDashboard,
  },

  {
    path: "/watch/image_search",
    component: ImageSearchDashboard,
  },
  {
    path: "/watch/image_text/list_layout",
    // component: ListLayoutImageText,
    component: ListLayoutDashboard,
  },
  {
    path: "/watch/tm_applied/grid_layout",
    // component: GridLayoutTMApplied,
    component: GridLayoutDashboard,
  },
  {
    path: "/watch/tm_protect/grid_layout",
    // component: GridLayoutTMProtect,
    component: GridLayoutDashboard,
  },
  {
    path: "/watch/image_text/grid_layout",
    // component: GridLayoutImageText,
    component: GridLayoutDashboard,
  },
  {
    path: "/watch/image_text_new/list_layout",
    component: ListLayoutDashboard,
  },
  {
    path: "/watch/image_text_new/grid_layout",
    component: GridLayoutDashboard,
  },
  {
    path: "/watch/image_label/grid_layout",
    component: GridLayoutDashboard,
  },
  {
    path: "/watch/image_similarity/list_layout",
    component: ListLayoutDashboard,
  },
  {
    path: "/watch/image_similarity/grid_layout",
    component: GridLayoutDashboard,
  },
  {
    path: "/watch/brand_compare/list_layout",
    component: ListLayoutDashboard,
  },
  {
    path: "/watch/brand_compare/upload",
    component: BrandCompareUpload,
  },
  {
    path: "/watch/email-management",
    component: ManageEmails,
  },
  {
    path: "/watch/email-preferences",
    component: EmailPreferences,
  },
  {
    path: "/watch/journals/list_layout",
    // component: Journals,
    component: ListLayoutDashboard,
  },
  {
    path: "/watch/client-master",
    component: ClientMaster,
  },
  {
    path: "/watch/profile",
    component: ProfileDashboard,
  },
  {
    path: "/watch/reports",
    component: Reports,
  },
  {
    path: "/watch/client-email-report",
    component: ClientEmailCustomReport,
  },
  {
    path: "/watch/manage-trademarks",
    component: ManageTrademarks,
  },
  {
    path: "/watch/email-template",
    component: EmailTemplate,
  },
  {
    path: "/watch/faqs",
    component: Faqs,
  },
  {
    path: "*", // For NoMatch component
    component: NoMatch,
  },
];
