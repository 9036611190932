export const renderBarCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, uv } = payload[0].payload;
    return (
      <div className="recharts-tooltip-custom-style">
        <p>{`${name}: ${uv}`}</p>
      </div>
    );
  }
  return null;
};

export const renderPieCustomTooltip = (props, dataArray) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    const total = dataArray.reduce((sum, entry) => sum + entry.value, 0);
    const { name, value } = payload[0].payload;
    const percentage = ((value / total) * 100).toFixed(1);
    return (
      <div className="recharts-tooltip-custom-style">
        <span style={{ fontSize: "12px" }}>{name}</span>
        <br />
        <p>
          Distribution: <b style={{ color: "black" }}>{percentage}%</b>
        </p>
      </div>
    );
  }
  return null;
};

const labelPositionsMap = new Map();

export const resetLabelPositions = (chartId) => {
  labelPositionsMap.set(chartId, []);
};

export const renderPieCustomizedLabel = function (props, chartId) {
  const { cx, cy, midAngle, outerRadius, fill, payload, percent } = props;
  const RADIAN = Math.PI / 180;

  const radius = outerRadius + 20;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  // Initialize label positions for the chart if not already done
  if (!labelPositionsMap.has(chartId)) {
    labelPositionsMap.set(chartId, []);
  }
  const labelPositions = labelPositionsMap.get(chartId);

  // Remove outdated positions that may cause overlap due to resizing
  const updatedLabelPositions = labelPositions.filter(
    (pos) => pos.cx === cx && pos.cy === cy,
  );

  // Check if the label overlaps with any of the existing labels
  const doesOverlap = labelPositions.some(
    (pos) => Math.sqrt((x - pos.x) ** 2 + (y - pos.y) ** 2) < 20, // Adjust the distance threshold as needed
  );

  if (!doesOverlap) {
    updatedLabelPositions.push({ x, y, cx, cy });
    labelPositionsMap.set(chartId, updatedLabelPositions);

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const delta = Math.abs(1 / cos) + 10;
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + delta) * cos;
    const my = cy + (outerRadius + delta) * sin;
    const ex = mx + Number(cos.toFixed(1)) * 20;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    // Adjustment: Ensure the text is within the chart's visible area
    const adjustedEx =
      cos >= 0
        ? Math.min(ex, cx + outerRadius + 40)
        : Math.max(ex, cx - outerRadius - 40);
    const adjustedEy = Math.min(Math.max(ey, 0), cy + outerRadius + 20);

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <rect
          x={adjustedEx + (cos >= 0 ? 1 * 5 : -1 * 17)}
          y={adjustedEy - 4}
          width={12}
          height={8}
          fill={fill}
        />
        <text
          x={adjustedEx + (cos >= 0 ? 1 : -1) * 21}
          y={adjustedEy + 4}
          textAnchor={textAnchor}
          fontSize={12}
        >
          {`${payload.name}: ${(percent * 100).toFixed(1)}%`}
        </text>
      </g>
    );
  }
  return null;
};
