
import { PDF } from '../commonConstants/generalConstants';

export const PS_primarySelectedFilters = {
    proprietorType: [],
    containsTerm: ""
};

export const PS_secondaryFiltersVisibility = {
    classesMenu: false,
    stateMenu: false,
    officeMenu: false,
    categoryMenu: false,
    typeMenu: false,
    statusMenu: false,
    proprietorMenu: false,
    sortingMenu: false
}

export const PS_secondarySelectedFilters = {
    filterFlag: false,
    filterClasses: [],
    filterState: [],
    filterType: [],
    filterStatus: [],
    filterProprietor: [],
    date_of_application: '',
    date_of_usage: '',
    date_of_usage_proposed: false,
    containsTerm: '',
}

export const PS_secondaryFiltersSearchTerms = {
    classes: '',
    state: '',
    office: '',
    category: '',
    type: '',
    status: '',
    proprietor: '',
};

export const PS_secondarySelectedSorting = {
    sortFlag: false,
    sortValue: [],
    sortType: '',
    sortOn: ''
};

//--------Default filters with backend keys--------
export const PS_defaultSecondarySelectedFilters_BEK = {
    filter_class: [],
    filter_state: [],
    filter_trademark_status: [],
    filter_trademark_type: [],
    filter_proprietor: [],
    filter_application_date: '',
    filter_date_of_usage: '',
    filter_date_of_usage_proposed: false,
    filter_contains_term: '',
};

export const initialProprietorReportData = {
    select_all: false,
    select_page: false,
    selected_mark_id_list: [],
    applications: [],
    markCount: 0,
    filteredMarkCount: 0,
    filter_flag: false,
    filters: PS_defaultSecondarySelectedFilters_BEK,
    sort_flag: false,
    sort: {
        sort_type: '',
        sort_on: ''
    }
};

export const PS_customReportData = {
    id: "",
    reportType: "URGENT",
    reportFile: false,
    reportFormat: [PDF],
    reportName: "",
    global_select: false,
    select_all: false,
    select_page: false,
    markCount: 0,
    selectAllCount: 0,
    data: {},
    filter_flag:false,
    filter_contains_term:'',
    filter_proprietor_type:[],
};

export const PS_proprietorsSearchState = {
    expandRecord: false,
    primaryFiltersVisibility: {
        proprietorTypeMenu: false,
        moreActionsMenu: false
    },
    primarySelectedFilters: PS_primarySelectedFilters,
    secondaryFiltersVisibility: PS_secondaryFiltersVisibility,
    secondarySelectedFilters: PS_secondarySelectedFilters,
    secondaryFiltersSearchTerms: PS_secondaryFiltersSearchTerms,
    secondarySelectedSorting: PS_secondarySelectedSorting,
    drawersVisibility: {
        resultsAnalysis: false,
        customReport: false
    },
    customReportData: PS_customReportData,
    tableDescriptionRowsVisibility: false,
    pageNumber: 1
}