//--------SIGN_IN: LOG IN & LOG OUT--------
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const CLEAR_REDUX = "CLEAR_REDUX";

//--------Password_Reset--------
export const PASSWORD_FORGET = "PASSWORD_FORGET";

export const PASSWORD_RESET = "PASSWORD_RESET";

//--------SIGN_UP--------
export const SIGN_UP = "SIGN_UP";

//--------Subscriptions--------
export const GET_USER_SUBSCRIPTIONS = "GET_USER_SUBSCRIPTIONS";

//--------USER PROFILE--------
export const USER_PROFILE = "USER_PROFILE";
export const TEAM_MEMBER = "TEAM_MEMBER";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER";
export const EDIT_TEAM_MEMBER = "EDIT_TEAM_MEMBER";

export const MARK_DOWNLOAD = "MARK_DOWNLOAD";
export const MARK_UPLOAD = "MARK_UPLOAD";
export const GET_TAGS = "GET_TAGS";
export const ADD_TAGS = "ADD_TAGS";
export const DELETE_TAGS = "DELETE_TAGS";
export const EDIT_TAGS = "EDIT_TAGS";

export const GET_PATENT_TAGS = "GET_PATENT_TAGS";
export const ADD_PATENT_TAGS = "ADD_PATENT_TAGS";
export const DELETE_PATENT_TAGS = "DELETE_PATENT_TAGS";
export const EDIT_PATENT_TAGS = "EDIT_PATENT_TAGS";

// whats-new
export const GET_WHATS_NEW = "GET_WHATS_NEW";

// Get in touch
export const GET_IN_TOUCH = "GET_IN_TOUCH";

// SEND_CUSTOM_EMAIL
export const SEND_CUSTOM_EMAIL = "SEND_CUSTOM_EMAIL";
export const GET_TO_CLIENT_EMAIL = "GET_TO_CLIENT_EMAIL";
export const GET_TO_CLIENT_EMAIL_MORE = "GET_TO_CLIENT_EMAIL_MORE";
export const GET_CC_CLIENT_EMAIL = "GET_CC_CLIENT_EMAIL";
export const GET_CC_CLIENT_EMAIL_MORE = "GET_CC_CLIENT_EMAIL_MORE";

export const GET_USER_TO_CLIENT_EMAIL = "GET_USER_TO_CLIENT_EMAIL";
export const GET_USER_TO_CLIENT_EMAIL_MORE = "GET_USER_TO_CLIENT_EMAIL_MORE";
export const GET_USER_CC_CLIENT_EMAIL = "GET_USER_CC_CLIENT_EMAIL";
export const GET_USER_CC_CLIENT_EMAIL_MORE = "GET_USER_CC_CLIENT_EMAIL_MORE";

//--------Alert Messages--------
export const GET_SIGN_IN_ALERT = "GET_SIGN_IN_ALERT";
export const GET_SEARCH_ALERT = "GET_SEARCH_ALERT";
export const GET_WATCH_ALERT = "GET_WATCH_ALERT";
export const GET_MANAGER_ALERT = "GET_MANAGER_ALERT";

//--------comment message--------
export const GET_COMMENT = "GET_COMMENT";

//--------Email Template--------
export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE";
export const CREATE_EMAIL_TEMPLATE = "CREATE_EMAIL_TEMPLATE";
export const EDIT_EMAIL_TEMPLATE = "EDIT_EMAIL_TEMPLATE";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";

//--------Email Template Data--------
export const GET_EMAIL_TEMPLATE_DATA = "GET_EMAIL_TEMPLATE_DATA";
export const CLEAR_EMAIL_TEMPLATE_DATA = "CLEAR_EMAIL_TEMPLATE_DATA";
export const GET_EMAIL_VARS = "GET_EMAIL_VARS";

//--------User Client Email--------
export const GET_USER_CLIENT_EMAIL = "GET_USER_CLIENT_EMAIL";

//--------User Email Document List--------
export const EMAIL_DOCUMENT_LIST = "EMAIL_DOCUMENT_LIST";
export const DOWNLOAD_EMAIL_CONTENT = "DOWNLOAD_EMAIL_CONTENT";

//--------MANAGE CLIENT Email --------
export const CLIENT_EMAIL = "CLIENT_EMAIL";

export const CLIENT_EMAIL_TM = "CLIENT_EMAIL_TM";
export const CLIENT_EMAIL_PT = "CLIENT_EMAIL_PT";
export const CLIENT_EMAIL_WT = "CLIENT_EMAIL_WT";
export const CLIENT_EMAIL_TM_PROTECT = "CLIENT_EMAIL_TM_PROTECT";
export const CREATE_CLIENT_EMAIL = "CREATE_CLIENT_EMAIL";
export const EDIT_CLIENT_EMAIL = "EDIT_CLIENT_EMAIL";
export const DELETE_CLIENT_EMAIL = "DELETE_CLIENT_EMAIL";
export const SET_STORE = "SET_STORE";

export const CLIENT_EMAIL_PROPRIETOR_FILTERS =
  "CLIENT_EMAIL_PROPRIETOR_FILTERS";
export const CLIENT_EMAIL_APPLICANT_FILTERS = "CLIENT_EMAIL_APPLICANT_FILTERS";
export const CLIENT_EMAIL_INVENTOR_FILTERS = "CLIENT_EMAIL_INVENTOR_FILTERS";
export const CREATE_CLIENT_EMAIL_REPORT = "CREATE_CLIENT_EMAIL_REPORT";

//---Client email report

export const GET_CLIENT_EMAIL_CUSTOM_REPORT = "GET_CLIENT_EMAIL_CUSTOM_REPORT";
export const CLIENT_EMAIL_CREATION_ORIGIN_FILTERS =
  "CLIENT_EMAIL_CREATION_ORIGIN_FILTERS";

//-------EMAIL PREFERENCES / SETTINGS-----
export const GET_EMAIL_PREFERENCE = "GET_EMAIL_PREFERENCE";
export const SAVE_EMAIL_PREFERENCE = "SAVE_EMAIL_PREFERENCE";

// --------LOGIN MFA----------------
export const VERIFY_AUTH_CODE = "VERIFY_AUTH_CODE";
export const VERIFY_RECOVERY_CODE = "VERIFY_RECOVERY_CODE";
export const SETUP_AUTH = "SETUP_AUTH";
export const REGENERATE_CODE = "REGENERATE_CODE";
export const SET_RECOVERY_CODES = "SET_RECOVERY_CODES";
export const VERIFY_SETUP = "VERIFY_SETUP";

//------- PROFILE MFA / SETTINGS-----
export const SET_MFA = "SET_MFA";
export const DELETE_MFA = "DELETE_MFA";

export const RESET_PROFILE_PASSWORD = "RESET_PROFILE_PASSWORD";
