//--------COMMON TYPES--------
export const GET_JOURNALS_LIST = "GET_JOURNALS_LIST";
export const SET_CURRENT_JOURNAL = "SET_CURRENT_JOURNAL";
export const SET_CURRENT_JOURNAL_DATE = "SET_CURRENT_JOURNAL_DATE";
export const GET_DATE_RANGE_LIST = "GET_DATE_RANGE_LIST";
export const SET_CURRENT_DATE_RANGE = "SET_CURRENT_DATE_RANGE";

//--------PROFILE--------
export const P_GET_PROFILE_PREFERENCES = "P_GET_PROFILE_PREFERENCES";

//--------REPORTS--------
export const R_GET_REPORTS = "R_GET_REPORTS";

//--------JOURNALS--------
export const J_GET_JOURNAL = "J_GET_JOURNAL";
export const J_POST_CUSTOM_REPORT = "J_POST_CUSTOM_REPORT";
export const J_GET_USER_TRADEMARKS = "J_GET_USER_TRADEMARKS";
export const J_SELECT_VIEW = "J_SELECT_VIEW";

//--------LIST LAYOUT--------
export const LL_GET_TABLE = "LL_GET_TABLE";
export const LL_MARK_BOOKMARK = "LL_MARK_BOOKMARK";
export const LL_POST_CUSTOM_REPORT = "LL_POST_CUSTOM_REPORT";
export const LL_GET_IMAGE_TABLE = "LL_GET_IMAGE_TABLE";
export const LL_IMAGE_BOOKMARK = "LL_IMAGE_BOOKMARK";
export const LL_POST_IMAGE_CUSTOM_REPORT = "LL_POST_IMAGE_CUSTOM_REPORT";
export const LL_GET_TM_PROTECT_TABLE = "LL_GET_TM_PROTECT_TABLE";
export const LL_TM_PROTECT_BOOKMARK = "LL_TM_PROTECT_BOOKMARK";
export const LL_POST_TM_PROTECT_CUSTOM_REPORT = "LL_POST_IMAGE_CUSTOM_REPORT";

//--------GRID LAYOUT--------
export const GL_GET_USER_TRADEMARKS = "GL_GET_USER_TRADEMARKS";
export const GL_GET_THREAT_TRADEMARKS = "GL_GET_THREAT_TRADEMARKS";
export const GL_POST_CUSTOM_REPORT = "GL_POST_CUSTOM_REPORT";
export const GL_GET_IMAGE_TEXT = "GL_GET_IMAGE_TEXT";
export const GL_GET_IMAGE_TEXT_TRADEMARKS = "GL_GET_IMAGE_TEXT_TRADEMARKS";
export const GL_GET_IMAGE_LABEL = "GL_GET_IMAGE_LABEL";
export const GL_GET_IMAGE_LABEL_TRADEMARKS = "GL_GET_IMAGE_LABEL_TRADEMARKS";
export const GL_POST_IMAGE_TEXT_CUSTOM_REPORT =
  "GL_POST_IMAGE_TEXT_CUSTOM_REPORT";
export const GL_POST_IMAGE_LABEL_CUSTOM_REPORT =
  "GL_POST_IMAGE_LABEL_CUSTOM_REPORT";
export const GL_GET_TM_PROTECT = "GL_GET_TM_PROTECT";
export const GL_GET_TM_PROTECT_TRADEMARKS = "GL_GET_TM_PROTECT_TRADEMARKS";
export const GL_POST_TM_PROTECT_CUSTOM_REPORT =
  "GL_POST_IMAGE_LABEL_CUSTOM_REPORT";

//--------MANAGE TRADEMARKS--------
export const MT_GET_TABLE = "MT_GET_TABLE";
export const MT_POST_MANAGE_FOCUS = "MT_POST_MANAGE_FOCUS";

//--------------------WATCH - LIST LAYOUT FILTERS---------------------

export const W_LL_F_mark_proprietors = "W_LL_F_mark_proprietors";
export const W_LL_F_threat_proprietors = "W_LL_F_threat_proprietors";
export const W_LL_F_mark_classes = "W_LL_F_mark_classes";
export const W_LL_F_threat_classes = "W_LL_F_threat_classes";
export const W_LL_F_mark_types = "W_LL_F_mark_types";
export const W_LL_F_threat_types = "W_LL_F_threat_types";
export const W_LL_F_tags = "W_LL_F_tags";
export const W_LL_F_mark_categories = "W_LL_F_mark_categories";
export const W_LL_F_mark_offices = "W_LL_F_mark_offices";
export const W_LL_F_threat_categories = "W_LL_F_threat_categories";
export const W_LL_F_threat_offices = "W_LL_F_threat_offices";
export const W_LL_F_threat_attorney = "W_LL_F_threat_attorney";
export const W_LL_F_threat_sources = "W_LL_F_threat_sources";
export const W_TMA_LL_UPDATE_SELECTED_VALUES =
  "W_TMA_LL_UPDATE_SELECTED_VALUES";
export const W_TMA_LL_UPDATE_SEARCH_VALUES = "W_TMA_LL_UPDATE_SEARCH_VALUES";
export const W_TMA_LL_EMPTY_SEARCH_VALUES = "W_TMA_LL_EMPTY_SEARCH_VALUES";
export const W_TMA_LL_UPDATE_EXCLUDE_VALUES = "W_TMA_LL_UPDATE_EXCLUDE_VALUES";

//--------------------WATCH - LIST LAYOUT - JOURNAL---------------------
export const W_LL_JOURNAL = "W_LL_JOURNAL";

//--------------------WATCH - LIST LAYOUT - TEMPLATE ---------------------
export const W_LL_TEMPLATE = "W_LL_TEMPLATE";
export const W_LL_PATCH_BOOKMARK = "W_LL_PATCH_BOOKMARK";
export const W_LL_GET_BOOKMARK = "W_LL_GET_BOOKMARK";

//--------------------WATCH - TEMPLATE KEY---------------------
export const APPLIED_LIST_LAYOUT = "applied/list_layout/";
export const APPLIED_GRID_LAYOUT = "applied/grid_layout/";
export const IMAGE_TEXT_LIST_LAYOUT = "image_text/list_layout/";
export const IMAGE_TEXT_GRID_LAYOUT = "image_text/grid_layout/";
export const IMAGE_LABEL_GRID_LAYOUT = "image_label/grid_layout/";
export const TM_PROTECT_LIST_LAYOUT = "tm_protect/list_layout/";
export const TM_PROTECT_GRID_LAYOUT = "tm_protect/grid_layout/";

//--------------------WATCH - TEMPLATE TYPE---------------------
export const USER_TEMPLATE_TYPE = "USER";
export const RECOMMENDED_TEMPLATE_TYPE = "RECOMMENDED";
export const BOOKMARK_TEMPLATE_TYPE = "BOOKMARK";

//--------------------WATCH - TEMPLATE---------------------
export const W_LL_CREATE_TEMPLATE = "W_LL_CREATE_TEMPLATE";
export const W_LL_UPDATE_TEMPLATE = "W_LL_UPDATE_TEMPLATE";
export const W_LL_DELETE_TEMPLATE = "W_LL_DELETE_TEMPLATE";
export const W_LL_CHANGE_TEMPLATE_ID = "W_LL_CHANGE_TEMPLATE_ID";

//--------------------WATCH - LIST LAYOUT DATA - REPORT AND PDF ---------------------

export const W_TMA_LL_CREATE_REPORT = "W_TMA_LL_CREATE_REPORT";
export const W_TMA_LL_COMBINE_PDF = "W_TMA_LL_COMBINE_PDF";

//--------------------WATCH - LIST LAYOUT DATA---------------------

export const TMA_LL_DATA = "TMA_LL_DATA";

//--------------------WATCH - FILTERS---------------------

export const W_F_mark_proprietors = "W_F_mark_proprietors";
export const W_F_threat_proprietors = "W_F_threat_proprietors";
export const W_F_mark_classes = "W_F_mark_classes";
export const W_F_threat_classes = "W_F_threat_classes";
export const W_F_mark_types = "W_F_mark_types";
export const W_F_threat_types = "W_F_threat_types";
export const W_F_tags = "W_F_tags";
export const W_F_mark_categories = "W_F_mark_categories";
export const W_F_mark_offices = "W_F_mark_offices";
export const W_F_threat_categories = "W_F_threat_categories";
export const W_F_threat_offices = "W_F_threat_offices";
export const W_F_threat_attorney = "W_F_threat_attorney";
export const W_F_threat_sources = "W_F_threat_sources";
export const W_F_threat_labels = "W_F_threat_labels";
export const W_F_UPDATE_SELECTED_VALUES = "W_F_UPDATE_SELECTED_VALUES";
export const W_F_UPDATE_SEARCH_VALUES = "W_F_UPDATE_SEARCH_VALUES";
export const W_F_EMPTY_SEARCH_VALUES = "W_F_EMPTY_SEARCH_VALUES";
export const W_F_UPDATE_EXCLUDE_VALUES = "W_F_UPDATE_EXCLUDE_VALUES";
export const W_F_UPDATE_DATE = "W_F_UPDATE_DATE";
export const W_F_UPDATE_DATE_PROPOSED = "W_F_UPDATE_DATE_PROPOSED";
export const W_F_SEARCH_VALUE = "W_F_SEARCH_VALUE";
export const W_F_SEARCH_TYPE = "W_F_SEARCH_TYPE";
export const W_F_FILTER_APPLIED = "W_F_FILTER_APPLIED";
export const W_F_RESET_ALL = "W_F_RESET_ALL";
export const W_F_CHANGE_AND_OR = "W_F_CHANGE_AND_OR";

//--------------------WATCH - Image Label Grid LAYOUT - JOURNAL---------------------
export const W_F_JOURNAL = "W_F_JOURNAL";

export const W_JOURNAL_DASHBOARD_TYPE = {
  TM_APPLIED: "TM_APPLIED",
  TM_IMAGE_TEXT: "TM_IMAGE_TEXT",
  TM_IMAGE_LABEL: "TM_IMAGE_LABEL",
  TM_PROTECT: "TM_PROTECT",
  JOURNALS: "JOURNALS",
  TM_IMAGE_TEXT_NEW: "TM_IMAGE_TEXT_NEW",
  IMAGE_SIMILARITY: "TM_IMAGE_SIMILARITY",
};

//--------------------WATCH - Tempate---------------------

export const W_TEMPLATE = "W_TEMPLATE";
export const W_CHANGE_TEMPLATE_ID = "W_CHANGE_TEMPLATE_ID";
export const W_CREATE_TEMPLATE = "W_CREATE_TEMPLATE";
export const W_UPDATE_TEMPLATE = "W_UPDATE_TEMPLATE";
export const W_DELETE_TEMPLATE = "W_DELETE_TEMPLATE";

//--------------------WATCH - GRID LAYOUT DATA - REPORT AND PDF ---------------------

export const W_GRID_CREATE_REPORT = "W_GRID_CREATE_REPORT";
export const W_GRID_COMBINE_PDF = "W_GRID_COMBINE_PDF";

//--------------------WATCH - GRID Data---------------------
export const W_GRID_GET_DATA = "W_GRID_GET_DATA";
export const W_GRID_GET_THREAT_DATA = "W_GRID_GET_THREAT_DATA";
export const W_GRID_GET_BOOKMARK = "W_GRID_GET_BOOKMARK";
export const W_GRID_PATCH_BOOKMARK = "W_GRID_PATCH_BOOKMARK";
export const W_UPDATE_LAST_WEEK_THREATS = "W_UPDATE_LAST_WEEK_THREATS";

//--------------------WATCH - FILTER KEYS ( Without END Slash )---------------------

export const APPLIED_LIST_LAYOUT_F = "applied/list_layout";
export const APPLIED_GRID_LAYOUT_F = "applied/grid_layout";
export const IMAGE_TEXT_LIST_LAYOUT_F = "image_text/list_layout";
export const IMAGE_TEXT_GRID_LAYOUT_F = "image_text/grid_layout";
export const IMAGE_LABEL_GRID_LAYOUT_F = "image_label/grid_layout";
export const TM_PROTECT_LIST_LAYOUT_F = "tm_protect/list_layout";
export const TM_PROTECT_GRID_LAYOUT_F = "tm_protect/grid_layout";

//--------------------WATCH - GRID LAYOUT - TEMPORARY THREAT FILTER SAVE ---------------------

export const W_TEMPORARY_THREAT_FILTER_SAVE = "W_TEMPORARY_THREAT_FILTER_SAVE";
export const W_TEMPORARY_THREAT_FILTER_REMOVE =
  "W_TEMPORARY_THREAT_FILTER_REMOVE";
export const W_TEMPORARY_THREAT_FILTER_DEFAULT_VALUE =
  "W_TEMPORARY_THREAT_FILTER_DEFAULT_VALUE";

export const W_CREATE_REPORT = "W_CREATE_REPORT";
export const W_COMBINE_PDF = "W_COMBINE_PDF";

// ----------------------- WATCH - GENERAL LIST LAYOUT --------------------------------
export const W_LIST_GET_DATA = "W_LIST_GET_DATA";
export const W_LIST_GET_BOOKMARK = "W_LIST_GET_BOOKMARK";
export const W_LIST_PATCH_BOOKMARK = "W_LIST_PATCH_BOOKMARK";
export const W_LIST_BOOLEAN_FILTER_UPDATE = "W_LIST_BOOLEAN_FILTER_UPDATE";
export const W_F_UPDATE_no_tags = "W_F_UPDATE_no_tags";
export const W_F_UPDATE_similarity = "W_F_UPDATE_similarity";
export const W_F_UPDATE_WHOLE_CONFIG = "W_F_UPDATE_WHOLE_CONFIG";

export const W_F_UPDATE_IMAGE_TEXT_PERCENTAGE =
  "W_F_UPDATE_IMAGE_TEXT_PERCENTAGE";
export const W_F_UPDATE_THREAT_IMAGE_TEXT_PERCENTAGE =
  "W_F_UPDATE_THREAT_IMAGE_TEXT_PERCENTAGE";

export const W_COMPARE_MARKS = "W_COMPARE_MARKS";
export const W_CHANGE_LIST_VIEW = "W_CHANGE_LIST_VIEW";

export const W_INSERT_SAMPLE_DATA = "W_INSERT_SAMPLE_DATA";
export const W_GRID_INSERT_SAMPLE_DATA = "W_GRID_INSERT_SAMPLE_DATA";

// ----------------------- WATCH - BRAND COMPARE UPLOAD --------------------------------
export const BC_UPLOAD_DATA = "BC_UPLOAD_DATA";
export const B_C_brand_proprietors = "B_C_brand_proprietors";
export const B_C_brand_classes = "B_C_brand_classes";
export const B_C_UPDATE_EXCLUDE_VALUES = "B_C_UPDATE_EXCLUDE_VALUES";

//  ------------------------------ BRAND COMPARE DASHBOARD ------------------------
export const W_F_ANALYSIS = "W_F_ANALYSIS";
export const W_F_own_brand_classes = "W_F_own_brand_classes";
export const W_F_threat_brand_classes = "W_F_threat_brand_classes";
export const W_F_own_brand_proprietors = "W_F_own_brand_proprietors";
export const W_F_threat_brand_proprietors = "W_F_threat_brand_proprietors";
