//--------REPORTS TYPES--------
import { R_GET_REPORTS } from '../../actions/watchActions/types';

//--------COMMON IP-SUITE CONSTANTS--------
import { LOADING, ERROR, SUCCESS } from '../../../constants/commonConstants/generalConstants';

const defaultReportsState = {
    reportsStatus: SUCCESS
};

export default function(state = defaultReportsState, action){
    switch (action.type) {
        case R_GET_REPORTS:
            switch (action.status) {
                case SUCCESS:
                    return {
                        ...action.payload,
                        reportsStatus: SUCCESS
                    };

                case LOADING:
                    return {
                        ...state,
                        reportsStatus: LOADING
                    };
                    
                case ERROR:
                    return {
                        ...state,
                        reportsStatus: ERROR
                    };
                
                default:
                    return state;
            }

        default:
            return state;
    }
}