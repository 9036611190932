import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Row, Col, Modal, Button } from "antd";

//--------COMMON CONSTANTS--------
import { LOADING } from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import { patentRenewalFiled } from "../../../redux/actions/managerActions/patentDashboard/actions";

class RenewalFiledModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  async confirmRenewalFiled() {
    const { renewal, application_number, pseudo } = this.props;
    await this.props.patentRenewalFiled(renewal, application_number, pseudo);
    this.props.onCloseModal();
  }

  render() {
    return (
      <div>
        <Modal
          title="Confirm Remove"
          open={this.props.visible}
          closable={false}
          footer={false}
        >
          <div>
            <Row gutter={16}>
              <Col span={24}>
                <p>
                  Are you sure you want to remove{" "}
                  <span>
                    <strong>{this.props.title_of_invention}</strong>
                  </span>{" "}
                  from this table?
                </p>
              </Col>
              <Col span={24} className="m-top-20 alignR">
                <div>
                  <ul className="report-footer">
                    <li>
                      <Button type="default" onClick={this.props.onCloseModal}>
                        Cancel
                      </Button>
                    </li>
                    <li>
                      <Button
                        danger
                        loading={
                          this.props.renewalFiledStatus === LOADING
                            ? true
                            : false
                        }
                        onClick={() => this.confirmRenewalFiled()}
                      >
                        Confirm
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  patentRenewalFiled,
};

function mapStateToProps(state) {
  const { renewalFiledMessage, renewalFiledStatus } =
    state.managerState.patentDashboardState;
  return deepFreeze({
    renewalFiledMessage,
    renewalFiledStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalFiledModal);
