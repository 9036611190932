import React from "react";
import {
  Dropdown,
  Button,
  Menu,
  Space,
  Tooltip,
  Divider,
  Switch,
  Typography,
} from "antd";
import { DownOutlined, QuestionCircleFilled } from "@ant-design/icons";
import InfringementTypesFilterInfo from "../../subComponents/Modals/InfringementTypesFilterInfo";
import { useDispatch } from "react-redux";
import { changeListSelectedDropdownValues } from "../../../../redux/actions/watchActions/watchActions";

const { Text } = Typography;
const MenuFilter = ({
  filteredData,
  extraChildren,
  value = [],
  setValue,
  infrigementInfo = false,
  multiple = true,
  loading = false,
  showAndOrSwitch = false,
  andOr = false,
  setAndOr,
  rename = "",
  shouldUpdateStore = true,
  tableView = false,
}) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const [openInfringementInfo, setInfringementInfo] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (tableView) {
      setOpenFilter(true);
    }
  }, []);

  const handleSwitch = (event) => {
    setAndOr?.(event);
  };

  const getArrayOfObjectFromId = (selectedIds) => {
    const valueType = typeof filteredData?.data[0].value;
    let typeConverted;
    if (valueType === "number") {
      typeConverted = selectedIds.map(Number);
    } else {
      typeConverted = selectedIds.map(String);
    }
    const tempObject =
      filteredData?.data?.filter((item) =>
        typeConverted.includes(item.value),
      ) || [];
    return tempObject;
  };

  const dropdownMenu = () => (
    <React.Fragment>
      <Menu
        style={{
          position: "initial",
          overflowY: "auto",
          width: "250px",
          maxHeight: "200px",
        }}
        id={`watch_filter_id_${filteredData.key}`}
        selectedKeys={value}
        selectable
        multiple={multiple}
        onSelect={(e) => {
          setValue(e.selectedKeys);
          const tempObject = getArrayOfObjectFromId(e.selectedKeys);
          shouldUpdateStore &&
            dispatch(
              changeListSelectedDropdownValues(filteredData.key, tempObject),
            );
        }}
        onDeselect={(e) => {
          setValue(e.selectedKeys);
          const tempObject = getArrayOfObjectFromId(e.selectedKeys);
          shouldUpdateStore &&
            dispatch(
              changeListSelectedDropdownValues(filteredData.key, tempObject),
            );
        }}
      >
        {filteredData.data.length && showAndOrSwitch ? (
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Switch
                style={{ pointerEvents: "all", background: "#1677FF" }}
                defaultChecked
                checked={andOr}
                onChange={handleSwitch}
                checkedChildren={
                  <Text strong>
                    <span style={{ color: "white" }}>AND</span>
                  </Text>
                }
                unCheckedChildren={
                  <Text strong>
                    <span style={{ color: "white" }}>OR</span>
                  </Text>
                }
              />
            </div>
            <Divider style={{ margin: "0px", marginTop: "5px" }} />
          </div>
        ) : null}
        {filteredData.data.length ? (
          filteredData.data.map((item) => (
            <Menu.Item key={item.value}>{item.label}</Menu.Item>
          ))
        ) : (
          <>No Data</>
        )}
      </Menu>
      {infrigementInfo && (
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <Tooltip placement="right" title="What are infringement types?">
            <span
              style={{
                padding: "10px",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                boxShadow: "0px 0px 5px #4285f4",
                cursor: "pointer",
              }}
              onClick={() => {
                setInfringementInfo(true);
                setOpenFilter(false);
              }}
            >
              <QuestionCircleFilled
                style={{
                  fontSize: "20px",
                  verticalAlign: "0px",
                  color: "#4285f4",
                }}
              />
            </span>
          </Tooltip>
          <InfringementTypesFilterInfo
            visible={openInfringementInfo}
            onClose={() => setInfringementInfo(false)}
          />
        </div>
      )}
    </React.Fragment>
  );

  return tableView ? (
    dropdownMenu()
  ) : (
    <Dropdown
      dropdownRender={() => dropdownMenu()}
      open={openFilter}
      onOpenChange={(event) => {
        setOpenFilter(event);
      }}
      selectable
    >
      <Button
        className={`${
          value.length ? "Filters-Button-Selected" : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
        loading={loading}
      >
        <Space>
          {rename ? rename : filteredData.menu_label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default MenuFilter;
