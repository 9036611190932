//--------WATCH TYPES--------
import { LL_POST_CUSTOM_REPORT } from "../../actions/watchActions/types";

//--------LIST LAYOUT TYPES--------
import {
  LL_GET_TABLE,
  LL_MARK_BOOKMARK,
  LL_GET_IMAGE_TABLE,
  LL_IMAGE_BOOKMARK,
  LL_POST_IMAGE_CUSTOM_REPORT,
  LL_GET_TM_PROTECT_TABLE,
  LL_TM_PROTECT_BOOKMARK,
  LL_POST_TM_PROTECT_CUSTOM_REPORT,
} from "../../actions/watchActions/types";

//--------COMMON IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
  PROGRESSING,
} from "../../../constants/commonConstants/generalConstants";

//--------LIST LAYOUT CONSTANTS--------
import {
  LL_defaultSelectedFilters,
  LL_defaultBookmarkData,
} from "../../../constants/watchConstants/listLayoutConstants";

const defaultListLayoutState = {
  tableData: null,
  totalTableDataCount: null,
  filters: null,
  automatedReportsLinks: null,
  flags: null,
  journalNumber: null,
  profileReportID: null,
  dateRange: null,
  pageNumber: 1,
  tableDataStatus: null,
  lastAppliedFilters: {
    ...LL_defaultSelectedFilters,
    filterFlag: false,
  },
  bookmark: LL_defaultBookmarkData,
};

const defaultListLayout = {
  mark: defaultListLayoutState,
  image: defaultListLayoutState,
  tm_protect: defaultListLayoutState,
};

export default function (state = defaultListLayout, action) {
  switch (action.type) {
    case LL_GET_TABLE:
      switch (action.status) {
        case SUCCESS:
          if (action.pageNumber > 1 || action.lastAppliedFilters.filterFlag) {
            return {
              ...state,
              mark: {
                ...state.mark,
                tableData: action.payload.results,
                totalTableDataCount: action.payload.count,
                pageNumber: action.pageNumber,
                tableDataStatus: action.status,
                lastAppliedFilters: action.lastAppliedFilters,
              },
            };
          } else {
            return {
              ...state,
              mark: {
                ...state.mark,
                tableData: action.payload.results,
                totalTableDataCount: action.payload.count,
                filters: action.payload.filters,
                automatedReportsLinks: {
                  ...state.mark.automatedReportsLinks,
                  all_class_watch_excel_report_url:
                    action.payload.all_class_watch_excel_report_url,
                  all_class_watch_pdf_report_url:
                    action.payload.all_class_watch_pdf_report_url,
                  same_class_watch_excel_report_url:
                    action.payload.same_class_watch_excel_report_url,
                  same_class_watch_pdf_report_url:
                    action.payload.same_class_watch_pdf_report_url,
                },
                flags: {
                  ...state.mark.flags,
                  infringement_type_flag: action.payload.infringement_type_flag,
                  threat_sources_filter: action.payload.threat_sources_filter,
                  groupview_show: action.payload.groupview_show
                    ? action.payload.groupview_show
                    : false,
                },
                journalNumber: action.journalNumber,
                pageNumber: action.pageNumber,
                tableDataStatus: action.status,
                lastAppliedFilters: action.lastAppliedFilters,
              },
            };
          }

        case LOADING:
          return {
            ...(action.journalChange ? defaultListLayout : state),
            mark: {
              ...state.mark,
              tableDataStatus: action.status,
            },
          };

        case PROGRESSING:
          return {
            ...state,
            mark: {
              ...state.mark,
              tableDataStatus: action.status,
              journalNumber: action.journalNumber,
              pageNumber: action.pageNumber,
            },
          };

        case ERROR:
          return {
            ...state,
            mark: {
              ...state.mark,
              tableDataStatus: action.status,
              totalTableDataCount: 0,
              // filters:{mark_categories:[{}],
              //             mark_classes: [{}],
              //             mark_offices: [{}],
              //             mark_proprietors:[{}],
              //             mark_types:  [{}],
              //             tags: [{}],
              //             threat_categories: [{}],
              //             threat_classes:  [{}],
              //             threat_offices:  [{}],
              //             threat_sources: [{}],
              //         threat_types: [{}]
              //     },
            },
          };

        default:
          return state;
      }

    case LL_POST_CUSTOM_REPORT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case LOADING:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            customReportStatus: action.status,
          };

        default:
          return state;
      }

    case LL_MARK_BOOKMARK:
      switch (action.status) {
        case SUCCESS:
          let threatsSimilarity = {
            lowerLimit: 0,
            upperLimit: 100,
          };
          if (
            action.payload.threat_similarity !== null &&
            action.payload.threat_similarity !== undefined
          ) {
            let limits = action.payload.threat_similarity.split("to");
            threatsSimilarity = {
              lowerLimit: parseInt(limits[0]),
              upperLimit: parseInt(limits[1]),
            };
          }
          return {
            ...state,
            mark: {
              ...state.mark,
              bookmark: {
                filterFlag: action.payload.filter_flag ? true : false,
                journalNumber: action.payload.journal_number,
                markApplicationNumber: action.payload.mark_application_number,
                markClasses: action.payload.mark_classes
                  ? action.payload.mark_classes
                  : [],
                markProprietors: action.payload.mark_proprietor
                  ? action.payload.mark_proprietor
                  : [],
                markTypes: action.payload.mark_type
                  ? action.payload.mark_type
                  : [],
                markCategories: action.payload.mark_categories
                  ? action.payload.mark_categories
                  : [],
                markOffices: action.payload.mark_offices
                  ? action.payload.mark_offices
                  : [],
                pageNumber: action.payload.page,
                present: action.payload.present,
                redBand: action.payload.red_band
                  ? action.payload.red_band
                  : false,
                excludeClass: action.payload.exclude_same_class
                  ? action.payload.exclude_same_class
                  : false,
                searchTerm: action.payload.search_term,
                tags:
                  action.payload.tags !== null &&
                  action.payload.tags !== undefined
                    ? action.payload.tags
                    : [],
                threatApplicationNumber:
                  action.payload.threat_application_number,
                threatClasses: action.payload.threat_classes
                  ? action.payload.threat_classes
                  : [],
                threatsSimilarity,
                threatSources: action.payload.threat_sources
                  ? action.payload.threat_sources
                  : [],
                threatTypes: action.payload.threat_type
                  ? action.payload.threat_type
                  : [],
                threatCategories: action.payload.threat_categories
                  ? action.payload.threat_categories
                  : [],
                threatOffices: action.payload.threat_offices
                  ? action.payload.threat_offices
                  : [],
                mark_date_of_application: action.payload
                  .mark_date_of_application
                  ? action.payload.mark_date_of_application
                  : "",
                threat_date_of_application: action.payload
                  .threat_date_of_application
                  ? action.payload.threat_date_of_application
                  : "",
                mark_date_of_usage: action.payload.mark_date_of_usage
                  ? action.payload.mark_date_of_usage
                  : "",
                mark_date_of_usage_proposed: action.payload
                  .mark_date_of_usage_proposed
                  ? action.payload.mark_date_of_usage_proposed
                  : false,
                threat_date_of_usage: action.payload.threat_date_of_usage
                  ? action.payload.threat_date_of_usage
                  : "",
                threat_date_of_usage_proposed: action.payload
                  .threat_date_of_usage_proposed
                  ? action.payload.threat_date_of_usage_proposed
                  : false,
                exclude_mark_added_to_report: action.payload
                  .exclude_mark_added_to_report
                  ? action.payload.exclude_mark_added_to_report
                  : false,
              },
            },
          };

        case LOADING:
          return state;

        case ERROR:
          return state;

        default:
          return state;
      }

    case LL_GET_IMAGE_TABLE:
      switch (action.status) {
        case SUCCESS:
          if (action.pageNumber > 1 || action.lastAppliedFilters.filterFlag) {
            return {
              ...state,
              image: {
                ...state.image,
                tableData: action.payload.results,
                totalTableDataCount: action.payload.count,
                pageNumber: action.pageNumber,
                tableDataStatus: action.status,
                lastAppliedFilters: action.lastAppliedFilters,
              },
            };
          } else {
            return {
              ...state,
              image: {
                ...state.image,
                tableData: action.payload.results,
                totalTableDataCount: action.payload.count,
                filters: action.payload.filters,
                automatedReportsLinks: {
                  ...state.image.automatedReportsLinks,
                  all_class_watch_excel_report_url:
                    action.payload.all_class_watch_excel_report_url,
                  all_class_watch_pdf_report_url:
                    action.payload.all_class_watch_pdf_report_url,
                  same_class_watch_excel_report_url:
                    action.payload.same_class_watch_excel_report_url,
                  same_class_watch_pdf_report_url:
                    action.payload.same_class_watch_pdf_report_url,
                },
                flags: {
                  ...state.image.flags,
                  infringement_type_flag: action.payload.infringement_type_flag,
                  threat_sources_filter: action.payload.threat_sources_filter,
                  groupview_show: action.payload.groupview_show
                    ? action.payload.groupview_show
                    : false,
                },
                journalNumber: action.journalNumber,
                pageNumber: action.pageNumber,
                tableDataStatus: action.status,
                lastAppliedFilters: action.lastAppliedFilters,
              },
            };
          }

        case LOADING:
          return {
            ...(action.journalChange ? defaultListLayout : state),
            image: {
              ...state.image,
              tableDataStatus: action.status,
            },
          };

        case PROGRESSING:
          return {
            ...state,
            image: {
              ...state.image,
              tableDataStatus: action.status,
              journalNumber: action.journalNumber,
              pageNumber: action.pageNumber,
            },
          };

        case ERROR:
          return {
            ...state,
            image: {
              ...state.image,
              tableDataStatus: action.status,
            },
          };

        default:
          return state;
      }

    case LL_IMAGE_BOOKMARK:
      switch (action.status) {
        case SUCCESS:
          let threatsSimilarity = {
            lowerLimit: 0,
            upperLimit: 100,
          };
          if (
            action.payload.threat_similarity !== null &&
            action.payload.threat_similarity !== undefined
          ) {
            let limits = action.payload.threat_similarity.split("to");
            threatsSimilarity = {
              lowerLimit: parseInt(limits[0]),
              upperLimit: parseInt(limits[1]),
            };
          }
          return {
            ...state,
            image: {
              ...state.image,
              bookmark: {
                filterFlag: action.payload.filter_flag ? true : false,
                journalNumber: action.payload.journal_number,
                markApplicationNumber: action.payload.mark_application_number,
                markClasses: action.payload.mark_classes
                  ? action.payload.mark_classes
                  : [],
                markProprietors: action.payload.mark_proprietor
                  ? action.payload.mark_proprietor
                  : [],
                markTypes: action.payload.mark_type
                  ? action.payload.mark_type
                  : [],
                markCategories: action.payload.mark_categories
                  ? action.payload.mark_categories
                  : [],
                markOffices: action.payload.mark_offices
                  ? action.payload.mark_offices
                  : [],
                pageNumber: action.payload.page,
                present: action.payload.present,
                redBand: action.payload.red_band
                  ? action.payload.red_band
                  : false,
                excludeClass: action.payload.exclude_same_class
                  ? action.payload.exclude_same_class
                  : false,
                searchTerm: action.payload.search_term,
                tags:
                  action.payload.tags !== null &&
                  action.payload.tags !== undefined
                    ? action.payload.tags
                    : [],
                threatApplicationNumber:
                  action.payload.threat_application_number,
                threatClasses: action.payload.threat_classes
                  ? action.payload.threat_classes
                  : [],
                threatsSimilarity,
                threatSources: action.payload.threat_sources
                  ? action.payload.threat_sources
                  : [],
                threatTypes: action.payload.threat_type
                  ? action.payload.threat_type
                  : [],
                threatCategories: action.payload.threat_categories
                  ? action.payload.threat_categories
                  : [],
                threatOffices: action.payload.threat_offices
                  ? action.payload.threat_offices
                  : [],
                mark_date_of_application: action.payload
                  .mark_date_of_application
                  ? action.payload.mark_date_of_application
                  : "",
                threat_date_of_application: action.payload
                  .threat_date_of_application
                  ? action.payload.threat_date_of_application
                  : "",
                mark_date_of_usage: action.payload.mark_date_of_usage
                  ? action.payload.mark_date_of_usage
                  : "",
                mark_date_of_usage_proposed: action.payload
                  .mark_date_of_usage_proposed
                  ? action.payload.mark_date_of_usage_proposed
                  : false,
                threat_date_of_usage: action.payload.threat_date_of_usage
                  ? action.payload.threat_date_of_usage
                  : "",
                threat_date_of_usage_proposed: action.payload
                  .threat_date_of_usage_proposed
                  ? action.payload.threat_date_of_usage_proposed
                  : false,
                exclude_mark_added_to_report: action.payload
                  .exclude_mark_added_to_report
                  ? action.payload.exclude_mark_added_to_report
                  : false,
              },
            },
          };

        case LOADING:
          return state;

        case ERROR:
          return state;

        default:
          return state;
      }

    case LL_POST_IMAGE_CUSTOM_REPORT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case LOADING:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            customReportStatus: action.status,
          };

        default:
          return state;
      }

    case LL_GET_TM_PROTECT_TABLE:
      switch (action.status) {
        case SUCCESS:
          if (action.pageNumber > 1 || action.lastAppliedFilters.filterFlag) {
            return {
              ...state,
              tm_protect: {
                ...state.tm_protect,
                tableData: action.payload.results,
                totalTableDataCount: action.payload.count,
                pageNumber: action.pageNumber,
                tableDataStatus: action.status,
                lastAppliedFilters: action.lastAppliedFilters,
              },
            };
          } else {
            return {
              ...state,
              tm_protect: {
                ...state.tm_protect,
                tableData: action.payload.results,
                totalTableDataCount: action.payload.count,
                filters: action.payload.filters,
                flags: {
                  ...state.tm_protect.flags,
                  infringement_type_flag: action.payload.infringement_type_flag,
                  threat_sources_filter: action.payload.threat_sources_filter,
                },
                profileReportID: action.profileReportID,
                dateRange:
                  action.dateRange !== undefined ? action.dateRange : null,
                pageNumber: action.pageNumber,
                tableDataStatus: action.status,
                lastAppliedFilters: action.lastAppliedFilters,
              },
            };
          }

        case LOADING:
          return {
            ...(action.journalChange ? defaultListLayout : state),
            tm_protect: {
              ...state.tm_protect,
              tableDataStatus: action.status,
            },
          };

        case PROGRESSING:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              tableDataStatus: action.status,
              profileReportID: action.profileReportID,
              dateRange: action.dateRange,
              pageNumber: action.pageNumber,
            },
          };

        case ERROR:
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              tableDataStatus: action.status,
              totalTableDataCount: 0,
            },
          };

        default:
          return state;
      }

    case LL_TM_PROTECT_BOOKMARK:
      switch (action.status) {
        case SUCCESS:
          let threatsSimilarity = {
            lowerLimit: 0,
            upperLimit: 100,
          };
          if (
            action.payload.threat_similarity !== null &&
            action.payload.threat_similarity !== undefined
          ) {
            let limits = action.payload.threat_similarity.split("to");
            threatsSimilarity = {
              lowerLimit: parseInt(limits[0]),
              upperLimit: parseInt(limits[1]),
            };
          }
          return {
            ...state,
            tm_protect: {
              ...state.tm_protect,
              bookmark: {
                filterFlag: action.payload.filter_flag ? true : false,
                profileReportID: action.payload.cease_desist_report_id,
                bookmarkType: action.payload.bookmark_type,
                pageNumber: action.payload.page,
                present: action.payload.present,
                searchTerm: action.payload.search_term,
                threatApplicationNumber:
                  action.payload.threat_application_number,
                threatClasses: action.payload.threat_classes
                  ? action.payload.threat_classes
                  : [],
                threatsSimilarity,
                threatSources: action.payload.threat_sources
                  ? action.payload.threat_sources
                  : [],
                threatTypes: action.payload.threat_type
                  ? action.payload.threat_type
                  : [],
                threatCategories: action.payload.threat_categories
                  ? action.payload.threat_categories
                  : [],
                threatOffices: action.payload.threat_offices
                  ? action.payload.threat_offices
                  : [],
                threat_date_of_application: action.payload
                  .threat_date_of_application
                  ? action.payload.threat_date_of_application
                  : "",
                threat_date_of_usage: action.payload.threat_date_of_usage
                  ? action.payload.threat_date_of_usage
                  : "",
                threat_date_of_usage_proposed: action.payload
                  .threat_date_of_usage_proposed
                  ? action.payload.threat_date_of_usage_proposed
                  : false,
              },
            },
          };

        case LOADING:
          return state;

        case ERROR:
          return state;

        default:
          return state;
      }

    case LL_POST_TM_PROTECT_CUSTOM_REPORT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case LOADING:
          return {
            ...state,
            customReportStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            customReportStatus: action.status,
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
