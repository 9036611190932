export const ipSuiteMenuStyle = {
  height: "calc(100vh - 230px)",
  overflowY: "auto",
  paddingLeft: "5px",
};

export const searchMenuStyle = {
  height: "calc(100vh - 230px)",
  overflowY: "auto",
  padding: "4px",
};
