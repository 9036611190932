import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import {
  Row,
  Col,
  Drawer,
  Typography,
  Button,
  Radio,
  notification,
  message,
  Space,
} from "antd";
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import { markDownload } from "../../../redux/actions/commonActions/actions";

const { Title } = Typography;

class DownloadPortfolioDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      subscription: "",
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.visible !== this.props.visible) {
      if (nextProps.product === "manager") {
        if (nextProps.sub_product === "trademark") {
          this.setState({
            subscription: nextProps.userSubscriptions.manager[0].toLowerCase(),
          });
        } else if (nextProps.sub_product === "patent") {
          this.setState({
            subscription: "patent",
          });
        }
      } else if (nextProps.product === "watch") {
        this.setState({
          subscription: nextProps.userSubscriptions.watch[0]
            .split(" ")[1]
            .toLowerCase(),
        });
      }
    }
    return true;
  }

  handleSubscription = (e) => {
    this.setState({
      subscription: e.target.value,
    });
  };

  async downloadMark() {
    if (this.state.subscription.length > 0) {
      await this.props.markDownload(
        this.props.product,
        this.state.subscription
      );
      this.setState({
        subscription: "",
      });
      notification[this.props.markDownloadStatus.toLowerCase()]({
        message: "Download Mark",
        description: this.props.markDownloadData,
      });
      this.props.onCloseDrawer();
    } else {
      message.error("please select product subscription");
    }
  }

  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };

    let subscriptionOption = [];
    if (this.props.userSubscriptionStatus === SUCCESS) {
      if (this.props.product === "manager") {
        if (this.props.sub_product === "trademark") {
          subscriptionOption = this.props.userSubscriptions.manager.map(
            (data) => ({
              title:
                data === "PATENT"
                  ? "PATENT"
                  : data === "CUSTOM_PATENT"
                  ? "CUSTOM PATENT"
                  : data === "INTERNATIONAL_PATENT"
                  ? "INTERNATIONAL PATENT"
                  : null,
              value:
                data === "PATENT"
                  ? data.toLowerCase()
                  : data === "CUSTOM_PATENT"
                  ? data.toLowerCase()
                  : data === "INTERNATIONAL_PATENT"
                  ? data.toLowerCase()
                  : null,
            })
          );
        } else if (this.props.sub_product === "patent") {
          subscriptionOption = this.props.userSubscriptions.manager.map(
            (data) => ({
              title:
                data === "PATENT"
                  ? "PATENT"
                  : data === "CUSTOM_PATENT"
                  ? "CUSTOM PATENT"
                  : data === "INTERNATIONAL_PATENT"
                  ? "INTERNATIONAL PATENT"
                  : null,
              value:
                data === "PATENT"
                  ? data.toLowerCase()
                  : data === "CUSTOM_PATENT"
                  ? data.toLowerCase()
                  : data === "INTERNATIONAL_PATENT"
                  ? data.toLowerCase()
                  : null,
            })
          );
        }
      } else if (this.props.product === "watch") {
        subscriptionOption = this.props.userSubscriptions.watch.map((data) => ({
          title:
            data === "Journal Watch"
              ? data.toUpperCase()
              : data === "TM Protect"
              ? data.toUpperCase()
              : null,
          value:
            data === "Journal Watch"
              ? "watch"
              : data === "TM Protect"
              ? "protect"
              : null,
        }));
      }
    }

    return (
      <Drawer
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Download Portfolio
          </Title>
        }
        placement="right"
        width={"25%"}
        closable={false}
        onClose={() => this.props.onCloseDrawer()}
        open={this.props.visible}
      >
        <Row>
          <Col span={24}>
            <Radio.Group
              onChange={this.handleSubscription}
              value={this.state.subscription}
            >
              {subscriptionOption.length > 0 &&
                subscriptionOption.map(
                  (data) =>
                    data.title && (
                      <Radio style={radioStyle} value={data.value}>
                        {data.title}
                      </Radio>
                    )
                )}
            </Radio.Group>
          </Col>
        </Row>
        <Row className="m-top-20" justify="start">
          <Col span={24}>
            <Space>
              <Button onClick={() => this.props.onCloseDrawer()}>Cancel</Button>
              <Button
                type="primary"
                rootStyle={{ margin: "0 10px" }}
                loading={this.props.markDownloadStatus === LOADING}
                onClick={() => this.downloadMark()}
              >
                Confirm
              </Button>
            </Space>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

const mapDispatchToProps = {
  markDownload,
};

function mapStateToProps(state) {
  const { userSubscriptions, userSubscriptionStatus } = state.userSubscriptions;
  const { markDownloadData, markDownloadStatus } = state.signInState;
  return deepFreeze({
    userSubscriptions,
    userSubscriptionStatus,

    markDownloadData,
    markDownloadStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadPortfolioDrawer);
