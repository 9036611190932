//--------LIBRARIES--------
import Axios from "axios";
import { message } from "antd";

//--------RT_CONFIG--------
import { WAT_RT_URL } from "../../../configs/rootConfigs";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

//--------JOURNAL TYPES--------
import { J_GET_JOURNAL } from "./types";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../commonActions/actions";

//--------Get journal data and filters--------
export function J_getJournal(
  journalNumber,
  pageNumber,
  filterFlag,
  filters,
  journalChange = false
) {
  const proprietors = `${filters.proprietors}`;
  const trademark_categories = `${filters.trademarkCategories}`;
  const trademark_offices = `${filters.trademarkOffices}`;
  const trademark_classes = `${filters.trademarkClasses}`;
  const trademark_types = `${filters.trademarkTypes}`;
  const contains_term = `${filters.containsTerm}`;
  const mark_desc_contains_term = `${filters.mark_desc_contains_term}`;
  const searchType = `${filters.searchType}`;

  return async (dispatch) => {
    let layout_url = `${WAT_RT_URL}/journal/?`;
    let response;
    let params = {
      journal_number: journalNumber,
      page: pageNumber,
      filter_flag: filterFlag ? filterFlag : null,
      proprietors,
      trademark_categories,
      trademark_offices,
      trademark_classes,
      trademark_types,
      contains_term:
        searchType === "Application No/Name" ? contains_term : null,
      mark_desc_contains_term:
        filterFlag && searchType === "Mark Description Search"
          ? mark_desc_contains_term
          : null,
    };
    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      try {
        dispatch({ type: J_GET_JOURNAL, status: LOADING, journalChange });
        response = await Axios.get(`${WAT_RT_URL}/journal/`, {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
        switch (response.status) {
          case 200:
            dispatch({
              type: J_GET_JOURNAL,
              status: SUCCESS,
              payload: response?.data,
              pageNumber,
              journalChange,
              lastAppliedFilters: {
                ...filters,
                filterFlag,
              },
            });
            break;

          default:
            alert(`Unexpected Response : ${response.status}`);
            dispatch({ type: J_GET_JOURNAL, status: ERROR });
            break;
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({ type: J_GET_JOURNAL, status: ERROR });
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}
