export const GET_TM_NOTES = 'GET_TM_NOTES';
export const GET_CTM_NOTES = 'GET_CTM_NOTES';
export const GET_ITM_NOTES = 'GET_ITM_NOTES';
export const GET_P_NOTES = 'GET_P_NOTES';
export const GET_CP_NOTES = 'GET_CP_NOTES';
export const GET_IP_NOTES = 'GET_IP_NOTES';

export const POST_TM_NOTES = 'POST_TM_NOTES';
export const POST_CTM_NOTES = 'POST_CTM_NOTES';
export const POST_ITM_NOTES = 'POST_ITM_NOTES';
export const POST_P_NOTES = 'POST_P_NOTES';
export const POST_CP_NOTES = 'POST_CP_NOTES';
export const POST_IP_NOTES = 'POST_IP_NOTES';

export const PATCH_TM_NOTES = 'PATCH_TM_NOTES';
export const PATCH_CTM_NOTES = 'PATCH_CTM_NOTES';
export const PATCH_ITM_NOTES = 'PATCH_ITM_NOTES';
export const PATCH_P_NOTES = 'PATCH_P_NOTES';
export const PATCH_CP_NOTES = 'PATCH_CP_NOTES';
export const PATCH_IP_NOTES = 'PATCH_IP_NOTES';

export const DELETE_TM_NOTES = 'DELETE_TM_NOTES';
export const DELETE_CTM_NOTES = 'DELETE_CTM_NOTES';
export const DELETE_ITM_NOTES = 'DELETE_ITM_NOTES';
export const DELETE_P_NOTES = 'DELETE_P_NOTES';
export const DELETE_CP_NOTES = 'DELETE_CP_NOTES';
export const DELETE_IP_NOTES = 'DELETE_IP_NOTES';

export const SEND_MANAGER_EMAIL = 'SEND_MANAGER_EMAIL';
export const MANAGER_EMAIL_DASHBOARD = 'MANAGER_EMAIL_DASHBOARD';
export const SENT_EMAIL_DASHBOARD = 'SENT_EMAIL_DASHBOARD';