import React, { useState } from "react";
import { Col, InputNumber, Row, Slider, Space } from "antd";
const SliderFilter = ({ value, handleTextPer }) => {
  const onChange = (newValue) => {
    handleTextPer(newValue);
  };
  return (
    <Row>
      <Col span={12} style={{ minWidth: "240px" }}>
        <Slider
          min={1}
          max={100}
          onChange={onChange}
          value={typeof value === "number" ? value : 0}
        />
      </Col>
    </Row>
  );
};
export default SliderFilter;
