import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";

import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Modal, Button, Input, notification } from "antd";

//--------ACTIONS--------
import { addTeamMember } from "../../../redux/actions/commonActions/actions";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

class AddTeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.props.addTeamMember({
          email: values.email,
          name: values.name,
          contact: values.contact,
          product: this.props.product,
        });
        if (this.props.addTeamStatus === SUCCESS) {
          this.updateTeamMember(SUCCESS);
          this.props.form.resetFields();
          this.props.onCloseModal();
        } else {
          this.updateTeamMember(ERROR);
        }
      }
    });
  }

  updateTeamMember(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Team Member Added",
          description: "Your Team Member Added Successfully",
        });
        break;

      case ERROR:
        notification["error"]({
          message: "Error While Team Member Added",
          description:
            "Unfortunately, an error occured while Team Member Adding. Please, try again later.",
        });
        break;

      default:
        break;
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Modal
          title="Enter Your Team Details"
          open={this.props.visible}
          onOk={this.props.onCloseModal}
          onCancel={this.props.onCloseModal}
          footer={[
            <Button
              type="primary"
              htmlType="submit"
              key="submit"
              loading={this.props.addTeamStatus === LOADING}
              onClick={(event) => this.handleSubmit(event)}
            >
              Confirm
            </Button>,
            <Button type="default" onClick={this.props.onCloseModal}>
              Cancel
            </Button>,
          ]}
        >
          <div>
            <Form
              onSubmit={(event) => this.handleSubmit(event)}
              className="login-form"
            >
              <Form.Item className="Sign-In-Input-Field">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: "please enter your team member email!",
                    },
                  ],
                })(
                  <Input
                    type="email"
                    prefix={
                      <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="please enter your team member email"
                  />
                )}
              </Form.Item>
              <Form.Item className="Sign-In-Input-Field">
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "please enter your team member name!",
                    },
                  ],
                })(
                  <Input
                    type="text"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="please enter your team member name"
                  />
                )}
              </Form.Item>
              <Form.Item className="Sign-In-Input-Field">
                {getFieldDecorator("contact")(
                  <Input
                    type="number"
                    prefix={
                      <PhoneOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="please enter your team member contact number"
                  />
                )}
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  addTeamMember,
};

function mapStateToProps(state) {
  return deepFreeze({
    addTeamStatus: state.profile.addTeamStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "add_team" })(AddTeamMember));
