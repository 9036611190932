//--------TYPES--------
import { 
    PATENT,
    PSEUDO_PATENT,
    PATENT_RENEWALS,
    PATENT_RENEWAL_FILED,
    REPLY_TO_FER,
    PSEUDO_REPLY_TO_FER,
    REPLY_FILED_TO_FER,
    REFINED_PATENT,
    PATENT_CHANGELOG,
    PATENT_APPLICANT,
    PATENT_APPLICANT_DETAIL,
    PATENT_INVENTOR,
    PATENT_INVENTOR_DETAIL,
    UPLOAD_FILE_P,

    PENDING_WORKING_OF_PATENT,
    STATEMENT_OF_UNDERTAKING,
    REQUEST_FOR_EXAMINATION,
    UPLOAD_FILE_PT,
 } from '../../../actions/managerActions/patentDashboard/types';

//--------COMMON CONSTANTS--------
import { LOADING, SUCCESS, ERROR } from '../../../../constants/commonConstants/generalConstants'; 
import { Patent_DefaultSelectedFilters } from '../../../../constants/managerConstants/patentConstants';
import { MANAGE_PATENT,POST_MANAGE_PATENT } from '../../../actions/managerActions/patentDashboard/types';

export default function( state = {
        pseudo: false,

        patent: null,
        patentStatus: null,
        patentAppliedFilters: {
            ...Patent_DefaultSelectedFilters,
            filterFlag: false
        },
        patentPageNumber: 1,
        pseudoPatent: null,
        pseudoPatentStatus: null,
        pseudoPatentAppliedFilters: {
            ...Patent_DefaultSelectedFilters,
            filterFlag: false
        },
        pseudoPatentPageNumber: 1,

        patentRenewal: null,
        patentRenewalStatus: null,
        patentRenewalPageNumber: 1,

        renewalFiledMessage: '',
        renewalFiledStatus: null,

        replyToFer: null,
        replyToFerStatus: null,
        replyToFerAppliedFilters: {
            ...Patent_DefaultSelectedFilters,
            filterFlag: false
        },
        replyToFerPageNumber: 1,

        pseudoReplyToFer: null,
        pseudoReplyToFerStatus: null,
        pseudoReplyToFerAppliedFilters: {
            ...Patent_DefaultSelectedFilters,
            filterFlag: false
        },
        pseudoReplyToFerPageNumber: 1,

        replyFiledMessage: null,
        replyFiledStatus: null,

        refinedPatent: null,
        refinedPatentStatus: null,

        patentChangelog: null,
        patentChangelogStatus: null,

        patentApplicant: null,
        patentApplicantStatus: null,
        patentApplicantDetail: null,
        patentApplicantDetailStatus: null,

        patentInventor: null,
        patentInventorStatus: null,
        patentInventorDetail: null,
        patentInventorDetailStatus: null,

        pendingWorkingOfPatent: null,
        pendingWorkingOfPatentStatus: null,
        pendingWorkingOfPatentPageNumber: 1,

        statementOfUndertaking: null,
        statementOfUndertakingStatus: null,
        statementOfUndertakingPageNumber: 1,

        requestForExamination: null,
        requestForExaminationStatus: null,
        requestForExaminationPageNumber: 1,
    }, action ){
    
    switch (action.type) {
        case PATENT:
            return {
                ...state,
                patent: action.payload,
                patentStatus: action.status,
                patentAppliedFilters: action.lastAppliedFilters,
                patentPageNumber: action.pageNumber
            };

        case PSEUDO_PATENT:
            return {
                ...state,
                pseudoPatent: action.payload,
                pseudoPatentStatus: action.status,
                pseudo: action.pseudo,
                pseudoPatentAppliedFilters: action.lastAppliedFilters,
                pseudoPatentPageNumber: action.pageNumber
            };
            
        case PATENT_RENEWALS:
            return {
                ...state,
                patentRenewal: action.payload,
                patentRenewalStatus: action.status,
                patentRenewalPageNumber: action.pageNumber
            };

        case PATENT_RENEWAL_FILED:
            switch (action.status) {
                case LOADING:
                    return {
                        ...state, 
                        renewalFiledStatus: LOADING,
                    };
                    
                case SUCCESS:
                    return { 
                        ...state, 
                            renewalFiledMessage: action.payload, 
                            renewalFiledStatus: SUCCESS,
                            patentRenewal: {
                                ...state.patentRenewal, 
                                ip_india: {
                                    ...state.patentRenewal.ip_india, 
                                    patent: state.patentRenewal.ip_india.patent.filter(item => item.application_number !== action.payload.patent_number)
                                },
                                pseudo: {
                                    ...state.patentRenewal.pseudo, 
                                    patent: state.patentRenewal.pseudo.patent.filter(item => item.application_number !== action.payload.patent_number)
                                }
                            },
                        };

                case ERROR:
                    return {
                        ...state, 
                        renewalFiledStatus: ERROR,
                    };
            
                default:
                    break;
            }
            break;

        case REPLY_TO_FER:
            return {
                ...state,
                replyToFer: action.payload,
                replyToFerStatus: action.status,
                replyToFerAppliedFilters: action.lastAppliedFilters,
                replyToFerPageNumber: action.pageNumber
            };

        case PSEUDO_REPLY_TO_FER:
            return {
                ...state,
                pseudoReplyToFer: action.payload,
                pseudoReplyToFerStatus: action.status,
                pseudo: action.pseudo,
                pseudoReplyToFerAppliedFilters: action.lastAppliedFilters,
                pseudoReplyToFerPageNumber: action.pageNumber
            };

        case REPLY_FILED_TO_FER:
            switch (action.status) {
                case LOADING:
                    return {
                        ...state,
                        replyFiledStatus: LOADING,
                    };
                    
                case SUCCESS:
                    return { 
                        ...state, 
                            replyFiledMessage: action.payload, 
                            replyFiledStatus: SUCCESS,
                            replyToFer: {
                                ...state.replyToFer, 
                                ip_india: {
                                    ...state.replyToFer.ip_india, 
                                    patents: state.replyToFer.ip_india.patents.filter(item => item.application_number !== action.payload.patent_number)
                                }
                            },
                        };

                case ERROR:
                    return {
                        ...state,
                        replyFiledStatus: ERROR,
                    };
            
                default:
                    break;
            }
            break;
                    
        case REFINED_PATENT:
            return {
                ...state,
                refinedPatent: action.payload,
                refinedPatentStatus: action.status
            };

        case PATENT_CHANGELOG:
            return {
                ...state,
                patentChangelog: action.payload,
                patentChangelogStatus: action.status
            };

        case PATENT_APPLICANT:
            return {
                ...state,
                patentApplicant: action.payload,
                patentApplicantStatus: action.status
            };

        case PATENT_APPLICANT_DETAIL:
            return {
                ...state,
                patentApplicantDetail: action.payload,
                patentApplicantDetailStatus: action.status
            };

        case PATENT_INVENTOR:
            return {
                ...state,
                patentInventor: action.payload,
                patentInventorStatus: action.status
            };

        case PATENT_INVENTOR_DETAIL:
            return {
                ...state,
                patentInventorDetail: action.payload,
                patentInventorDetailStatus: action.status
            };

        case UPLOAD_FILE_P:
            switch (action.status) {
                case SUCCESS:                    
                    return {
                        ...state,
                        refinedPatent: {
                            ...state.refinedPatent,
                            ip_india:{
                                ...state.refinedPatent.ip_india,
                                uploaded_documents: action.payload.uploaded_documents
                            }
                        }
                    };
        
                case LOADING:
                    return state;

                case ERROR:
                    return state;
        
                default:
                    return state;
        }
   
        case PENDING_WORKING_OF_PATENT:
            return {
                ...state,
                pendingWorkingOfPatent: action.payload,
                pendingWorkingOfPatentStatus: action.status,
                pendingWorkingOfPatentPageNumber: action.pageNumber
            };
   
        case STATEMENT_OF_UNDERTAKING:
            return {
                ...state,
                statementOfUndertaking: action.payload,
                statementOfUndertakingStatus: action.status,
                statementOfUndertakingPageNumber: action.pageNumber
            };

        case REQUEST_FOR_EXAMINATION:
            return {
                ...state,
                requestForExamination: action.payload,
                requestForExaminationStatus: action.status,
                requestForExaminationPageNumber: action.pageNumber
            };

        case MANAGE_PATENT:
                    return {
                        ...state,
                        patentData: action.payload,
                        patentStatus: action.status,
                        patentPageNumber: action.pageNumber,
                        patentAppliedFilter:action.lastAppliedFilters,
                    };
        
        case POST_MANAGE_PATENT:
                        switch (action.status){
                            case SUCCESS:
                                if(action.selectAll){
                                    if(action.deleteFlag){
                                        return {
                                            ...state,
                                            deleteManagePatentStatus: SUCCESS
                                        }
                                    }
                                } else {
                                    if(action.deleteFlag){
                                        return {
                                            ...state,
                                            deleteManagePatentStatus: SUCCESS
                                        }
                                    } else {
                                        return {
                                            ...state,
                                            patentData:{
                                                ...state.patentData,
                                                patents: state.patentData.patents.map( eachRow => (eachRow.application_number === action.payload.mark.application_number)? action.payload.mark: eachRow),
                                            },
                                            editManagePatentStatus: SUCCESS,
                                        };
                                    }
                                }
                                break;
                    
                            case LOADING:
                                if(action.deleteFlag){
                                    return {
                                        ...state,
                                        deleteManagePatentStatus: LOADING
                                    };
                                } else {
                                    return {
                                        ...state,
                                        editManagePatentStatus: LOADING
                                    };
                                }
                                
                            case ERROR:
                                if(action.deleteFlag){
                                    return {
                                        ...state,
                                        deleteManagePatentStatus: ERROR
                                    };
                                } else {
                                    return {
                                        ...state,
                                        editManagePatentStatus: ERROR
                                    };
                                }
                    
                            default:
                                return state;
                    }
                    break;
            

        case UPLOAD_FILE_PT:
                        switch (action.status) {
                            case SUCCESS:                    
                                return {
                                    ...state,
                                    patentData: {
                                        ...state.patentData,
                                        patents: state.patentData.patents.map( eachPatent => {
                                            if (eachPatent.application_number === action.applicationNumber){
                                                return {
                                                    ...eachPatent,
                                                    uploaded_documents: action.payload.uploaded_documents
                                                };
                                            } else {
                                                return eachPatent;
                                            }
                                        })
                                    }
                                };
                    
                            case LOADING:
                                return state;
            
                            case ERROR:
                                return state;
                    
                            default:
                                return state;
                    }
      
                
            


        default:
            return state;
    }
}