import Axios from "axios";
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import { ROOT_URL } from "../../../../configs/rootConfigs";
import { checkAuthorisation } from "../actions";
import { notification } from "antd";
import { onResponseError } from "./../../../../functions/commonFunctions/interceptor";
import {
  UPDATE_SINGLE_CLIENT_CONTACT,
  GET_CLIENT_CONTACT_DATA,
  ADD_CLIENT_CONTACT_DATA,
  DELETE_CLIENT_NOTES_DATA,
  UPDATE_CLIENT_CONTACT_DATA,
} from "./masterTypes";

export function updateSingleClientContactData(body) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SINGLE_CLIENT_CONTACT,
      status: SUCCESS,
      payload: body,
    });
  };
}

export function getClientContactData(pageNumber, client_id) {
  return async (dispatch) => {
    dispatch({ type: GET_CLIENT_CONTACT_DATA, status: LOADING });
    try {
      const params = {
        page: pageNumber,
        page_size: 100,
        client_id,
      };

      let response = await Axios.get(
        `${ROOT_URL}/client_master/details/contact_person/`,
        {
          params: params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );

      dispatch({
        type: GET_CLIENT_CONTACT_DATA,
        status: SUCCESS,
        payload: response?.data,
        pageNumber,
      });
    } catch (error) {
      checkAuthorisation(dispatch, error);
      dispatch({
        type: GET_CLIENT_CONTACT_DATA,
        status: ERROR,
        payload: error,
      });
    }
  };
}

export function addClientContactData(body, pageNumber, callbacksFunction = {}) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: ADD_CLIENT_CONTACT_DATA, status: LOADING });
    try {
      let response = await Axios.post(
        `${ROOT_URL}/client_master/details/contact_person/`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      onSuccess(response?.data);
      dispatch(getClientContactData(pageNumber, body.client_id));
    } catch (error) {
      onResponseError(error);
      dispatch({ type: ADD_CLIENT_CONTACT_DATA, status: ERROR });
      onError(error?.response?.data);
    }
  };
}

export function updateClientContactData(
  body,
  pageNumber,
  callbacksFunction = {},
) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: UPDATE_CLIENT_CONTACT_DATA, status: LOADING });
    try {
      let response = await Axios.patch(
        `${ROOT_URL}/client_master/details/contact_person/`,
        (body = {
          ...body,
          contact_person_id: body?.id ?? "",
        }),
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      onSuccess(response?.data);
      dispatch(getClientContactData(pageNumber, body.client_id));
    } catch (error) {
      onResponseError(error);
      dispatch({ type: UPDATE_CLIENT_CONTACT_DATA, status: ERROR });
      onError(error?.response?.data);
    }
  };
}

export function deleteClientContactData(
  body,
  pageNumber,
  callbacksFunction = {},
) {
  const { onSuccess } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: DELETE_CLIENT_NOTES_DATA, status: LOADING });
    try {
      const response = await Axios.delete(
        `${ROOT_URL}/client_master/details/contact_person`,
        {
          data: {
            client_id: body?.client_id ?? "",
            contact_person_id: body?.id ?? "",
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: "Client Note Deleted Successfully!",
      });
      dispatch(getClientContactData(pageNumber, body.client_id));
      onSuccess && onSuccess(response?.data);
    } catch (error) {
      onResponseError(error);
      notification["error"]({
        message: error?.response?.data?.message || "An error occured",
      });
      dispatch(getClientContactData(pageNumber, body.client_id));
    }
  };
}
