//--------Default Selected Filters--------
export const Report_defaultPrimarySelectedFilters = {
    filterFlag: false,
    containsTerm: '',
}

//--------Default Sorting--------
export const Report_defaultPrimarySorting = {
    sortFlag: false,
    sortType: '',
    sortOn: '',
    sortValue: [],
}

//--------Default Fiters Visibility--------
export const Report_defaultSecondaryFiltersVisibility = {
    classesMenu : false,
    journalNumberMenu : false,
    stateMenu : false,
    officeMenu : false,
    categoryMenu : false,
    typeMenu : false,
    logicTypeMenu : false,
    statusMenu : false,
    proprietorMenu : false,
    proprietorNameMenu : false,
    sortingMenu: false
}

//--------Default Selected Filters--------
export const Report_defaultSecondarySelectedFilters = {
    filterFlag: false,
    filterClasses: [],
    filterJournalNumber: [],
    filterState: [],
    filterOffice: [],
    filterCategory: [],
    filterType: [],
    filterLogicType: [],
    filterStatus: [],
    filterProprietor: [],
    filterProprietorName: [],
    date_of_application: '',
    date_of_usage: '',
    date_of_usage_proposed: false,
    containsTerm: '',
}

//--------Default Fiters Search Terms--------
export const Report_defaultSecondaryFiltersSearchTerms = {
    classes: '',
    journalNumber: '',
    state: '',
    office: '',
    category: '',
    type: '',
    logicType: '',
    status: '',
    proprietor: '',
    proprietorName: '',
};

//--------Default Sorting Terms--------
export const Report_defaultSecondarySorting = {
    sortFlag: false,
    sortType: '',
    sortOn: ''
};

//--------Default Custom Report Data--------
export const Report_defaultCustomReportData = {
    select_all: false,
    data: {},
    markCount: 0,
    selectAllCount: 0,
    selectedMark: [],
    id: '',
    report_source: 'MARK',
    operation: 'DOWNLOAD',
    report_type: '',
    report_file: true,
    report_file_name: '',
    report_file_types: ['PDF'],
};

//--------Default Data Report Record Initial State--------
export const Report_defaultInitialReport = {
    markCount: 0,
    select_all: false,
    selectedMark: [],
    realSelectedMark: [],
    filteredMarkCount: 0,
    filter_flag: false,
    filters: Report_defaultSecondarySelectedFilters,
    sort_flag: false,
    sort: Report_defaultSecondarySorting
};