import { Col, Popconfirm, Row, Table, Tooltip, notification } from "antd";
import React from "react";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClientMasterData,
  updateSingleClientData,
} from "../../../../redux/actions/commonActions/masterActions/clientMaster";

export const ClientMasterContent = (props) => {
  const { data, loading, metadata } = useSelector(
    (state) => state.masters.clientMaster.listData,
  );
  const { count, pageNumber } = metadata;
  const dispatch = useDispatch();

  const {
    dashboardAlert,
    selected,
    setSelected,
    selectAll,
    setSelectAll,
    selectedRows,
    setSelectedRows,
    setOpenDetailPage,
    filters,
    setOpenEditClient,
    fetchClientMasterData,
  } = props;

  const onSelectChange = (record, selected) => {
    if (selected) {
      let newSelected = [...selectedRows, record];
      setSelected(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    } else {
      let newSelected = selectedRows.filter((x) => x.id !== record.id);
      setSelected(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    }
  };

  const rowSelection = {
    renderCell: function (checked, record, index, originNode) {
      return (
        <div
          className="tmapplied_selection_cols"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "4px" }}>
              {" "}
              {index + 1 + (pageNumber - 1) * 100}.{" "}
            </div>
            <div> {originNode}</div>
          </div>
        </div>
      );
    },
    selectedRowKeys: selected,
    onSelect: onSelectChange,
    selections: null,
    onSelectAll: (selected, selectedRows) => {
      setSelectAll(selected);
      setSelected(selectedRows);
      if (selected) {
        notification.open({
          message: "Deselect All Mark",
          description:
            "Click on the arrow near the select all checkbox to deselect all marks",
        });
      }
    },
    selections: selectAll
      ? [
          {
            key: "all-data",
            text: "Deselect All Data",
            onSelect: (changeableRowKeys) => {
              setSelectAll(false);
              setSelected([]);
              setSelectedRows([]);
            },
          },
        ]
      : false,
    getCheckboxProps: (record) => ({
      disabled: selectAll,
    }),
  };

  const handleNextpage = (pageNumber) => {
    fetchClientMasterData(pageNumber, filters);
  };

  const handleViewClient = (client) => {
    dispatch(updateSingleClientData(client));
    setOpenDetailPage(true);
  };

  const handleEditClient = (client) => {
    dispatch(updateSingleClientData(client));
    setOpenEditClient(true);
  };

  const handleDeleteClient = (client_id) => {
    const body = {
      client_id: client_id,
    };
    dispatch(deleteClientMasterData(body, pageNumber, filters));
  };

  const columnsData = [
    {
      className: "tm-protect-column-1",
      title: "Client ID",
      width: 50,
      fixed: "left",
      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>{checkValue(record?.id) ? record?.id : "N.A."}</span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Client Name",
      width: 120,
      fixed: "left",
      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.client_name)
                    ? record?.client_name
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "PAN Number",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.pan_number) ? record?.pan_number : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "GST Number",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.gst_number) ? record?.gst_number : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Address",
      width: 120,

      render: (text, record) => {
        return {
          children: (
            <div>
              <p>
                <span>
                  {checkValue(record?.address) ? record?.address : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },

    {
      className: "tm-protect-column-1",
      title: "Actions",
      width: 80,
      fixed: "right",
      render: (text, record) => {
        return (
          <div
            className="iconTable tmapplied_ll_actions"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              padding: "8px",
            }}
          >
            <Tooltip title="View Client">
              <EyeOutlined
                style={{ cursor: "pointer" }}
                onClick={() => handleViewClient(record)}
              />
            </Tooltip>

            <Tooltip title="Edit Client">
              <EditOutlined
                style={{ cursor: "pointer", color: "#1677ff" }}
                onClick={() => handleEditClient(record)}
              />
            </Tooltip>
            <Popconfirm
              title="Delete Client Master"
              description="All the related documents will be deleted.Are you sure to delete this task?"
              onConfirm={() => handleDeleteClient(record?.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Client">
                <DeleteOutlined style={{ cursor: "pointer", color: "red" }} />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const table = (
    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ padding: "16px" }}>
        <Table
          rowKey={(record) => record.id}
          className={"tm-protect-table"}
          bordered
          dataSource={data}
          columns={columnsData}
          scroll={
            dashboardAlert === ""
              ? { y: "calc(90vh - 110px)" }
              : {
                  y: "calc(90vh - 150px)",
                }
          }
          loading={loading}
          rowSelection={rowSelection}
          pagination={{
            pageSize: 100,
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pageNumber,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} `,
            total: count,
            onChange: handleNextpage,
          }}
        />
      </Col>
    </Row>
  );

  return <div>{table}</div>;
};
