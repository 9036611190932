import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import _ from "lodash";

import { Row, Col, Card } from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Bar,
  Cell,
  XAxis,
  YAxis,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import {
  renderBarCustomTooltip,
  renderPieCustomTooltip,
  renderPieCustomizedLabel,
} from "../../../../functions/commonFunctions/reChartsFunctions";

const colors = scaleOrdinal([
  ...schemeCategory10,
  ...schemeCategory10,
  ...schemeCategory10,
]).range();

class OpposedTrademarkAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StatusSeries: [],
      TypeSeries: [],
      ClassSeries: [],
      graph_stored: false,
    };
  }

  setGraphData(StatusSeries, TypeSeries, ClassSeries) {
    this.setState({
      StatusSeries: StatusSeries,
      TypeSeries: TypeSeries,
      ClassSeries: ClassSeries,
      graph_stored: true,
    });
  }

  render() {
    if (this.props.opposedTmDashboardAnalyticsStatus === SUCCESS) {
      let StatusSeries = _.map(
        this.props.opposedTmDashboardAnalytics.trademark.status_distribution,
        function (graphData) {
          return {
            name: graphData?.status,
            value: graphData?.frequency,
          };
        },
      );

      let TypeSeries = _.map(
        this.props.opposedTmDashboardAnalytics.trademark.type_distribution,
        function (graphData) {
          return {
            name: graphData?.type,
            value: graphData?.frequency,
          };
        },
      );

      let ClassSeries = _.map(
        this.props.opposedTmDashboardAnalytics.trademark.class_distribution,
        function (graphData) {
          return {
            name: "Class " + graphData?.class_number,
            data: [graphData?.frequency],
            uv: graphData?.frequency,
          };
        },
      );

      StatusSeries = _.filter(StatusSeries, function (distribution) {
        return distribution.y !== 0 && distribution.name !== null;
      });

      TypeSeries = _.filter(TypeSeries, function (distribution) {
        return distribution.y !== 0 && distribution.name !== null;
      });

      ClassSeries = _.filter(ClassSeries, function (distribution) {
        return distribution.data[0] !== 0;
      });

      if (
        StatusSeries.length > 0 &&
        TypeSeries.length > 0 &&
        ClassSeries.length > 0 &&
        !this.state.graph_stored
      ) {
        this.setGraphData(StatusSeries, TypeSeries, ClassSeries);
      }
    }

    const chartIdStatus = "opponent_trademark_analysis_statusChart";
    const chartIdType = "opponent_trademark_analysis_typeChart";

    return (
      <div>
        {/* status-type distribution */}
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Card
              hoverable
              bodyStyle={{ textAlign: "center", padding: "15px" }}
              loading={
                this.props.opposedTmDashboardAnalyticsStatus === LOADING
                  ? true
                  : false
              }
            >
              <h5>Status Distribution</h5>
              <div className="recharts-parent-div-custom-style">
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={this?.state?.StatusSeries ?? []}
                      label={(props) =>
                        renderPieCustomizedLabel(props, chartIdStatus)
                      }
                      labelLine={false}
                      fill="#8884d8"
                      dataKey="value"
                      isAnimationActive={false}
                      cx="50%"
                      cy="50%"
                    >
                      {this.state.StatusSeries.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value) => value}
                      content={(props) =>
                        renderPieCustomTooltip(
                          props,
                          this?.state?.StatusSeries ?? [],
                        )
                      }
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
          <Col className="gutter-row" span={12}>
            <Card
              hoverable
              bodyStyle={{ textAlign: "center", padding: "15px" }}
              loading={
                this.props.opposedTmDashboardAnalyticsStatus === LOADING
                  ? true
                  : false
              }
            >
              <h5>Type Distribution</h5>
              <div className="recharts-parent-div-custom-style">
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={this?.state?.TypeSeries ?? []}
                      label={(props) =>
                        renderPieCustomizedLabel(props, chartIdType)
                      }
                      labelLine={false}
                      fill="#8884d8"
                      dataKey="value"
                      isAnimationActive={false}
                      cx="50%"
                      cy="50%"
                    >
                      {this.state.TypeSeries.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value) => value}
                      content={(props) =>
                        renderPieCustomTooltip(
                          props,
                          this?.state?.TypeSeries ?? [],
                        )
                      }
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
        </Row>
        {/* status-type distribution */}

        {/* class distribution */}
        <Row gutter={16}>
          <Col className="gutter-row m-top-20" span={24}>
            <Card
              hoverable
              bodyStyle={{ textAlign: "center", padding: "15px" }}
              loading={
                this.props.opposedTmDashboardAnalyticsStatus === LOADING
                  ? true
                  : false
              }
            >
              <h5>Classes</h5>
              <p>Class-wise Distribution</p>
              <div className="recharts-parent-div-custom-style">
                <ResponsiveContainer>
                  <BarChart
                    data={this.state.ClassSeries}
                    margin={{ bottom: 30 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      label={{
                        value: "Classes",
                        offset: -20,
                        position: "insideBottom",
                      }}
                    />
                    <YAxis
                      label={{
                        value: "Trademarks",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Bar
                      dataKey="uv"
                      fill="#8884d8"
                      // label={{ position: "top" }}
                    >
                      {this.state.ClassSeries.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Bar>
                    <Tooltip
                      cursor={{ fill: "transparent" }}
                      content={renderBarCustomTooltip}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
        </Row>
        {/* class distribution */}
      </div>
    );
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  const { opposedTmDashboardAnalytics, opposedTmDashboardAnalyticsStatus } =
    state.managerState.profileState;
  return deepFreeze({
    opposedTmDashboardAnalytics,
    opposedTmDashboardAnalyticsStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpposedTrademarkAnalysis);
