//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  BookOutlined,
  BookTwoTone,
  DownOutlined,
  InfoCircleFilled,
  MinusCircleOutlined,
  MinusSquareFilled,
  PlusSquareFilled,
  QuestionCircleFilled,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Pagination,
  Checkbox,
  Menu,
  Dropdown,
  Button,
  Tooltip,
  Input,
  Slider,
  notification,
  Select,
  Alert,
  Badge,
  Empty,
  Spin,
  message,
} from "antd";
import { debounce } from "lodash";

//--------LIST LAYOUT ACTIONS--------
import {
  LL_getTMProtectData,
  LL_getTMProtectBookmark,
  LL_postPatchDeleteTMProtectBookmark,
} from "../../../../redux/actions/watchActions/listLayoutActions";

//--------WATCH ACTIONS--------
import {
  getDateRangeList,
  postTMProtectCustomReport,
} from "../../../../redux/actions/watchActions/commonActions";

//--------WATCH ACTION TYPES--------
import { LL_POST_TM_PROTECT_CUSTOM_REPORT } from "../../../../redux/actions/watchActions/types";

//--------IP-SUITE CONSTANTS--------
import {
  SUCCESS,
  LOADING,
  PROGRESSING,
  ERROR,
} from "../../../../constants/commonConstants/generalConstants";

//--------WATCH GENERAL CONSTANTS--------
import { LIST_LAYOUT } from "../../../../constants/watchConstants/generalConstants";

//--------LIST LAYOUT CONSTANTS--------
import {
  LL_defaultSelectedFilters,
  LL_defaultFiltersSearchTerms,
  LL_defaultCustomReportData,
  LL_defaultFiltersVisibility,
} from "../../../../constants/watchConstants/listLayoutConstants";

//--------IP-SUITE GENERAL FUNCTIONS--------
import {
  changeStateValue_L1,
  changeStateValue_L2,
  compareArrays,
} from "../../../../functions/commonFunctions/generalFunctions";

//--------IP-SUITE DATE FUNCTIONS--------
import { dateFormat } from "../../../../functions/commonFunctions/dateFunctions";

//--------WATCH SUB-COMPONENTS DRAWERS--------
import CustomReport from "../../subComponents/Drawers/CustomReport";

//--------WATCH SUB-COMPONENTS MODALS--------
import InfringementTypesFilterInfo from "../../subComponents/Modals/InfringementTypesFilterInfo";
import DateRangePicker from "../../../../functions/commonFunctions/dateRangePicker";

//--------STYLESHEETS--------
import "../../../../stylesheets/watchStylesheets/listLayout.css";
import { logOut } from "../../../../redux/actions/commonActions/actions";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import { getEmailTemplateData } from "../../../../redux/actions/commonActions/actions";

const { Option } = Select;

class ListLayoutTMProtect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersVisibility: LL_defaultFiltersVisibility,
      selectedFilters: {
        ...this.props.lastAppliedFilters,
        searchType: checkValue(this.props.lastAppliedFilters.searchType)
          ? this.props.lastAppliedFilters.searchType
          : "Application No/Name",
      },
      filtersSearchTerms: LL_defaultFiltersSearchTerms,
      customReportData: LL_defaultCustomReportData,
      drawersVisibility: {
        customReport: false,
        journalPage: false,
      },
      range1: null,
      range2: null,
      modalsVisibility: {
        infringementTypesFilterInfo: false,
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
      tableDescriptionRowsVisibility: false,
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L1_Debounced = debounce(
      this.changeStateValue_L1,
      400,
    );
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.handleCreateReport.bind(this);
    this.handleCreateReportConfirmation.bind(this);
    this.setDate1 = this.setDate1.bind(this);
    this.setDate2 = this.setDate2.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.current_date_range === null) {
        await this.props.getDateRangeList("tm_protect", true);
      }
      if (
        this.props.dateRange !== undefined &&
        this.props.current_date_range !== null &&
        this.props.current_date_range !== this.props.dateRange
      ) {
        this.props.LL_getTMProtectBookmark(
          this.props.current_profile_report_id,
          "TM_PROTECT_LIST_LAYOUT",
        );
        this.props.LL_getTMProtectData(
          this.props.current_profile_report_id,
          this.props.current_date_range,
          this.props.pageNumber,
          false,
          LL_defaultSelectedFilters,
        );
      }
    } else {
      this.props.logOut();
    }
  }

  handlePageChange(pageNumber) {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        this.props.LL_getTMProtectData(
          this.props.profileReportID,
          this.props.dateRange,
          pageNumber,
          this.props.lastAppliedFilters.filterFlag,
          this.props.lastAppliedFilters,
          false,
        );
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        notification["error"]({
          message: "Unexpected Error! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  async handleDateChange(dateRange, option) {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
      case PROGRESSING:
        this.props.LL_getTMProtectBookmark(
          option.props.id,
          "TM_PROTECT_LIST_LAYOUT",
        );
        let tableStatus = await this.props.LL_getTMProtectData(
          option.props.id,
          dateRange,
          1,
          false,
          LL_defaultSelectedFilters,
          true,
        );
        if (tableStatus === SUCCESS) {
          this.setState({
            selectedFilters: LL_defaultSelectedFilters,
            filtersSearchTerms: LL_defaultFiltersSearchTerms,
            customReportData: LL_defaultCustomReportData,
          });
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      default:
        notification["error"]({
          message: "Unable to Change Journal! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleApplyFilters() {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        const {
          threatClasses,
          threatSources,
          searchTerm,
          threatsSimilarity,
          threatTypes,
          threatCategories,
          threatOffices,
          threat_date_of_application,
          threat_date_of_usage,
          threat_date_of_usage_proposed,
          threat_desc_contains_term,
        } = this.props.lastAppliedFilters;

        let filterFlag = false;

        let { selectedFilters } = this.state;

        if (selectedFilters.threatsSimilarity.lowerLimit === null) {
          selectedFilters = {
            ...selectedFilters,
            threatsSimilarity: {
              ...selectedFilters.threatsSimilarity,
              lowerLimit: 0,
            },
          };
        }

        if (selectedFilters.threatsSimilarity.upperLimit === null) {
          selectedFilters = {
            ...selectedFilters,
            threatsSimilarity: {
              ...selectedFilters.threatsSimilarity,
              upperLimit: 100,
            },
          };
        }

        if (
          selectedFilters.threatsSimilarity.lowerLimit >
          selectedFilters?.threatsSimilarity.upperLimit
        ) {
          notification["warning"]({
            message: "Lower Limit cannot exceed Upper Limit",
            description:
              "The lower limit is always smaller than the upper limit.",
          });
          return;
        }

        selectedFilters = {
          ...selectedFilters,
          // searchTerm: searchInput
        };

        if (
          !compareArrays(
            selectedFilters.threatClasses,
            LL_defaultSelectedFilters.threatClasses,
          ) ||
          !compareArrays(
            selectedFilters.threatSources,
            LL_defaultSelectedFilters.threatSources,
          ) ||
          !compareArrays(
            selectedFilters.threatTypes,
            LL_defaultSelectedFilters.threatTypes,
          ) ||
          !compareArrays(
            selectedFilters.threatCategories,
            LL_defaultSelectedFilters.threatCategories,
          ) ||
          !compareArrays(
            selectedFilters.threatOffices,
            LL_defaultSelectedFilters.threatOffices,
          ) ||
          selectedFilters?.searchTerm !==
            LL_defaultSelectedFilters.searchTerm ||
          selectedFilters?.threat_desc_contains_term !==
            LL_defaultSelectedFilters.threat_desc_contains_term ||
          selectedFilters?.threatsSimilarity.lowerLimit !==
            LL_defaultSelectedFilters.threatsSimilarity.lowerLimit ||
          selectedFilters?.threatsSimilarity.upperLimit !==
            LL_defaultSelectedFilters.threatsSimilarity.upperLimit ||
          selectedFilters?.redBand !== LL_defaultSelectedFilters.redBand ||
          selectedFilters?.excludeClass !==
            LL_defaultSelectedFilters.excludeClass ||
          selectedFilters?.threat_date_of_application !==
            LL_defaultSelectedFilters.threat_date_of_application ||
          selectedFilters?.threat_date_of_usage !==
            LL_defaultSelectedFilters.threat_date_of_usage ||
          selectedFilters?.threat_date_of_usage_proposed !==
            LL_defaultSelectedFilters.threat_date_of_usage_proposed
        ) {
          filterFlag = true;
        } else {
          filterFlag = false;
        }

        if (
          !compareArrays(threatClasses, selectedFilters?.threatClasses) ||
          !compareArrays(threatSources, selectedFilters?.threatSources) ||
          !compareArrays(threatTypes, selectedFilters?.threatTypes) ||
          !compareArrays(threatCategories, selectedFilters?.threatCategories) ||
          !compareArrays(threatOffices, selectedFilters?.threatOffices) ||
          searchTerm !== selectedFilters?.searchTerm ||
          threat_desc_contains_term !==
            selectedFilters?.threat_desc_contains_term ||
          threatsSimilarity.lowerLimit !==
            selectedFilters?.threatsSimilarity.lowerLimit ||
          threatsSimilarity.upperLimit !==
            selectedFilters?.threatsSimilarity.upperLimit ||
          threat_date_of_application !==
            selectedFilters?.threat_date_of_application ||
          threat_date_of_usage !== selectedFilters?.threat_date_of_usage ||
          threat_date_of_usage_proposed !==
            selectedFilters?.threat_date_of_usage_proposed
        ) {
          this.props.LL_getTMProtectData(
            this.props.profileReportID,
            this.props.dateRange,
            1,
            filterFlag,
            selectedFilters,
            false,
          );
        } else {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  setDate1(val) {
    this.setState({ range1: val });
  }

  setDate2(val) {
    this.setState({ range2: val });
  }

  handleResetFilters() {
    this.setState({ range1: null, range2: null });
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        const {
          threatClasses,
          threatSources,
          searchTerm,
          threatsSimilarity,
          threatTypes,
          threatCategories,
          threatOffices,
          threat_date_of_application,
          threat_date_of_usage,
          threat_date_of_usage_proposed,
          threat_desc_contains_term,
        } = this.props.lastAppliedFilters;

        if (
          compareArrays(
            threatClasses,
            LL_defaultSelectedFilters.threatClasses,
          ) &&
          compareArrays(
            threatSources,
            LL_defaultSelectedFilters.threatSources,
          ) &&
          compareArrays(threatTypes, LL_defaultSelectedFilters.threatTypes) &&
          compareArrays(
            threatCategories,
            LL_defaultSelectedFilters.threatCategories,
          ) &&
          compareArrays(
            threatOffices,
            LL_defaultSelectedFilters.threatOffices,
          ) &&
          searchTerm === LL_defaultSelectedFilters.searchTerm &&
          threat_desc_contains_term ===
            LL_defaultSelectedFilters?.threat_desc_contains_term &&
          threatsSimilarity.lowerLimit ===
            LL_defaultSelectedFilters.threatsSimilarity.lowerLimit &&
          threatsSimilarity.upperLimit ===
            LL_defaultSelectedFilters.threatsSimilarity.upperLimit &&
          threat_date_of_application ===
            LL_defaultSelectedFilters.threat_date_of_application &&
          threat_date_of_usage ===
            LL_defaultSelectedFilters.threat_date_of_usage &&
          threat_date_of_usage_proposed ===
            LL_defaultSelectedFilters.threat_date_of_usage_proposed
        ) {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        } else {
          this.setState({
            selectedFilters: LL_defaultSelectedFilters,
            filtersSearchTerms: LL_defaultFiltersSearchTerms,
          });
          this.props.LL_getTMProtectData(
            this.props.profileReportID,
            this.props.dateRange,
            1,
            false,
            LL_defaultSelectedFilters,
          );
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        notification["error"]({
          message: "Unable to Reset! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleThreatsSimilarityChange(value) {
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        threatsSimilarity: {
          lowerLimit: value[0],
          upperLimit: value[1],
        },
      },
    });
  }

  handleThreatsSimilarityAfterChange(value) {
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        threatsSimilarity: {
          lowerLimit: value[0],
          upperLimit: value[1],
        },
      },
    });
  }

  handleThreatsSimilarityInputChange(limitKey, event) {
    if (Number.isInteger(parseInt(event.target.value))) {
      if (event.target.value.includes("-")) {
        notification["warning"]({
          message: "Lowest Limit is 0",
          description: "Similarity cannot be less than 0.",
        });
        return;
      }

      if (parseInt(event.target.value) > 100) {
        notification["warning"]({
          message: "Highest Limit is 100",
          description: "Similarity cannot be more than 100.",
        });
        return;
      }

      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          threatsSimilarity: {
            ...this.state.selectedFilters.threatsSimilarity,
            [limitKey]:
              event.target.value.length === 0
                ? null
                : parseInt(event.target.value),
          },
        },
      });
      return;
    }

    if (event.target.value.length === 0) {
      switch (limitKey) {
        case "lowerLimit":
          notification["warning"]({
            message: "Lower Limit's default value is 0",
            description:
              "If no value is given then the lower limit will be considered as 0.",
          });
          break;

        case "upperLimit":
          notification["warning"]({
            message: "Upper Limit's default value is 100",
            description:
              "If no value is given then the upper limit will be considered as 100.",
          });
          break;

        default:
          break;
      }

      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          threatsSimilarity: {
            ...this.state.selectedFilters.threatsSimilarity,
            [limitKey]: null,
          },
        },
      });
      return;
    }
  }

  handleCustomReportRecordSelection(recordData, checked) {
    if (checked) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          selectedRecords: {
            ...this.state.customReportData.selectedRecords,
            [`${recordData.similarity.cease_desist_mark_id}`]: this.state
              .customReportData.selectedRecords[
              `${recordData.similarity.cease_desist_mark_id}`
            ]
              ? [
                  ...this.state.customReportData.selectedRecords[
                    `${recordData.similarity.cease_desist_mark_id}`
                  ],
                  `${recordData.threat.application_number}`,
                ]
              : [`${recordData.threat.application_number}`],
          },
          selectedRecordsLength:
            this.state.customReportData.selectedRecordsLength + 1,
        },
      });
    } else {
      let { selectedRecords } = this.state.customReportData;
      selectedRecords[`${recordData.similarity.cease_desist_mark_id}`] =
        selectedRecords[`${recordData.similarity.cease_desist_mark_id}`].filter(
          (appNum) => appNum !== `${recordData.threat.application_number}`,
        );
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          selectedRecords,
          selectedRecordsLength:
            this.state.customReportData.selectedRecordsLength - 1,
        },
      });
    }
  }

  handleCreateReport() {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        if (
          this.state.customReportData.selectAll &&
          !this.props.lastAppliedFilters.filterFlag
        ) {
          message.info(
            "You can access this report from 'Automated Report' section in 'Reports'",
          );
        } else {
          this.changeStateValue_L1("drawersVisibility", "customReport", true);
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  async handleCreateReportConfirmation() {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        await this.props.postTMProtectCustomReport(
          {
            ...this.state.customReportData,
            cease_desist_report_id: this.props.profileReportID,
            filter_flag: this.props.lastAppliedFilters.filterFlag,
            filters: this.props.lastAppliedFilters,
            source: LIST_LAYOUT,
          },
          LL_POST_TM_PROTECT_CUSTOM_REPORT,
        );
        this.changeStateValue_L1("drawersVisibility", "customReport", false);
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  scrollToBookMark() {
    let bookmark = document.getElementById("Bookmark");
    if (bookmark !== null) {
      bookmark.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }

  async handleGoToBookmark() {
    switch (this.props.tableDataStatus) {
      case SUCCESS:
      case ERROR:
        let tableStatus;
        const {
          threatClasses,
          threatSources,
          searchTerm,
          threatsSimilarity,
          threatTypes,
          threatCategories,
          threatOffices,
          threat_date_of_application,
          threat_date_of_usage,
          threat_date_of_usage_proposed,
        } = this.props.bookmark;

        if (this.props.bookmark.present) {
          if (this.props.bookmark.pageNumber === this.props.pageNumber) {
            if (
              this.props.bookmark.filterFlag ===
              this.props.lastAppliedFilters.filterFlag
            ) {
              if (
                compareArrays(
                  this.props.lastAppliedFilters.threatClasses,
                  threatClasses,
                ) &&
                compareArrays(
                  this.props.lastAppliedFilters.threatSources,
                  threatSources,
                ) &&
                compareArrays(
                  this.props.lastAppliedFilters.threatTypes,
                  threatTypes,
                ) &&
                compareArrays(
                  this.props.lastAppliedFilters.threatCategories,
                  threatCategories,
                ) &&
                compareArrays(
                  this.props.lastAppliedFilters.threatOffices,
                  threatOffices,
                ) &&
                this.props.lastAppliedFilters.threatsSimilarity.lowerLimit ===
                  threatsSimilarity.lowerLimit &&
                this.props.lastAppliedFilters.threatsSimilarity.upperLimit ===
                  threatsSimilarity.upperLimit &&
                this.props.lastAppliedFilters.searchTerm === searchTerm &&
                this.props.lastAppliedFilters.threat_date_of_application ===
                  threat_date_of_application &&
                this.props.lastAppliedFilters.threat_date_of_usage ===
                  threat_date_of_usage &&
                this.props.lastAppliedFilters.threat_date_of_usage_proposed ===
                  threat_date_of_usage_proposed
              ) {
                this.scrollToBookMark();
              } else {
                tableStatus = await this.props.LL_getTMProtectData(
                  this.props.profileReportID,
                  this.props.dateRange,
                  this.props.bookmark.pageNumber,
                  this.props.bookmark.filterFlag,
                  this.props.bookmark,
                );
              }
            } else {
              tableStatus = await this.props.LL_getTMProtectData(
                this.props.profileReportID,
                this.props.dateRange,
                this.props.bookmark.pageNumber,
                this.props.bookmark.filterFlag,
                this.props.bookmark,
              );
            }
          } else {
            tableStatus = await this.props.LL_getTMProtectData(
              this.props.profileReportID,
              this.props.dateRange,
              this.props.bookmark.pageNumber,
              this.props.bookmark.filterFlag,
              this.props.bookmark,
            );
          }
        } else {
          notification["warning"]({
            message: "No Bookmark",
            description: `There is no bookmark present for date range  ${this.props.dateRange}.`,
          });
        }
        if (tableStatus === SUCCESS) {
          this.setState({
            selectedFilters: {
              ...this.state.selectedFilters,
              threatClasses,
              threatSources,
              searchTerm,
              threatsSimilarity,
              threatTypes,
              threatCategories,
              threatOffices,
              threat_date_of_application,
              threat_date_of_usage,
              threat_date_of_usage_proposed,
            },
          });
          this.scrollToBookMark();
        }
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        break;
    }
  }

  async handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Trademark Watch</strong></p>";
    let tempDate = new Date();
    let currDate =
      tempDate.getFullYear() +
      "-" +
      (tempDate.getMonth() + 1) +
      "-" +
      tempDate.getDate() +
      " " +
      tempDate.getHours() +
      ":" +
      tempDate.getMinutes() +
      ":" +
      tempDate.getSeconds();

    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "WATCH",
        subscription: ["TM PROTECT"],
        report_tag: {
          report_name: "",
          journal_number: "",
          journal_date: "",
          journal_copy: "",
          opposition: "",
          your_mark: data?.similarity?.term,
          your_application: "",
          your_proprietor: "",
          your_address: "",
          your_DOA: "",
          your_DOU: "",
          your_class: "",
          your_mark_image: "",
          threat_mark: checkValue(data.threat) ? data.threat.applied_for : "",
          threat_application: checkValue(data.threat)
            ? data.threat.application_number
            : "",
          threat_proprietor: checkValue(data.threat)
            ? data.threat.proprietor.map((propData) => propData.name).join(", ")
            : "",
          threat_address: checkValue(data.threat)
            ? data.threat.proprietor
                .map((propData) => propData.address)
                .join(", ")
            : "",
          threat_DOA: checkValue(data.threat)
            ? data.threat.date_of_application
            : "",
          threat_DOU: checkValue(data.threat) ? data.threat.date_of_usage : "",
          threat_class: checkValue(data.threat)
            ? data.threat.associated_class.join(", ")
            : "",
          threat_mark_image: checkValue(data.threat)
            ? data.threat.associated_image
            : "",
          current_date: checkValue(currDate) ? dateFormat(currDate, "-") : "",
          description: checkValue(data.threat) ? data.threat.description : "",
        },
        journal_copy: "",
        objectType: "trademark",
        applicationNumber: checkValue(data.threat)
          ? data.threat.application_number
          : "",
      },
    });

    if (value) {
      await this.props.getEmailTemplateData({
        subscription: "TM Protect",
        product: "WATCH",
        object_id: data?.id,
        sub_section: "DASHBOARD",
      });
    }
  }

  render() {
    const headerButton = {
      margin: "0px 5px 0px 0px",
      boxShadow: "none",
      border: "1px solid #1890ff",
      padding: "0px 5px",
      fontSize: "16px",
      color: "#1890ff",
    };

    const headerButtonSelected = { ...headerButton, color: "#4285f4" };
    const searchTypesMenu = (
      <>
        <Menu
          selectable
          selectedKeys={[this.state.selectedFilters.searchType]}
          onClick={({ key }) =>
            this.changeStateValue_L1("selectedFilters", "searchType", key)
          }
          style={{
            maxHeight: "200px",
            width: "250px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {["Application No/Name", "Your Threat Description Search"].map(
            (e) => (
              <Menu.Item key={e} style={{ fontSize: "16px" }}>
                {e}
              </Menu.Item>
            ),
          )}
        </Menu>
      </>
    );
    const filterMenuScroll = {
      maxHeight: "175px",
      minWidth: "150px",
      overflowY: "scroll",
    };

    let noFilterData = (
      <Menu>
        <Menu.Item>No Filters Data Found</Menu.Item>
      </Menu>
    );
    let classesMenu = noFilterData;
    let typesMenu = noFilterData;
    let threatSourcesMenu = noFilterData;
    let threatsSimilarityMenu = noFilterData;
    let categoriesMenu = noFilterData;
    let officesMenu = noFilterData;

    if (this.props.filters !== null) {
      const {
        threat_classes,
        threat_types,
        threat_sources,
        threat_categories,
        threat_offices,
      } = this.props.filters;

      if (threat_classes !== null && threat_classes.length > 0) {
        classesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.selectedFilters.threatClasses}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "threatClasses",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "threatClasses",
                event.selectedKeys,
              )
            }
            style={filterMenuScroll}
          >
            {threat_classes.map((eachClass) => (
              <Menu.Item key={eachClass.id}>{eachClass.number}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (threat_types !== null && threat_types.length > 0) {
        typesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.selectedFilters.threatTypes}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "threatTypes",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "threatTypes",
                event.selectedKeys,
              )
            }
            style={filterMenuScroll}
          >
            {threat_types.map((eachType) => (
              <Menu.Item key={eachType.id}>{eachType.name}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (threat_sources !== null && threat_sources.length > 0) {
        threatSourcesMenu = (
          <>
            <Menu
              style={{ boxShadow: "0px 0px 5px #c9c9c9" }}
              selectable
              multiple
              selectedKeys={this.state.selectedFilters.threatSources}
              onSelect={(event) =>
                this.changeStateValue_L1(
                  "selectedFilters",
                  "threatSources",
                  event.selectedKeys,
                )
              }
              onDeselect={(event) =>
                this.changeStateValue_L1(
                  "selectedFilters",
                  "threatSources",
                  event.selectedKeys,
                )
              }
            >
              {threat_sources.map((eachSource) => (
                <Menu.Item key={eachSource}>
                  {eachSource.replace("_", " ")}
                </Menu.Item>
              ))}
            </Menu>
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <Tooltip
                placement="right"
                open={this.state.filtersVisibility.threatSourcesMenu}
                title="What are infringement types?"
              >
                <span
                  style={{
                    padding: "10px",
                    backgroundColor: "#ffffff",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 5px #4285f4",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      filtersVisibility: {
                        ...this.state.filtersVisibility,
                        threatSourcesMenu: false,
                      },
                      modalsVisibility: {
                        ...this.state.modalsVisibility,
                        infringementTypesFilterInfo: true,
                      },
                    })
                  }
                >
                  <QuestionCircleFilled
                    style={{
                      fontSize: "20px",
                      verticalAlign: "0px",
                      color: "#4285f4",
                    }}
                  />
                </span>
              </Tooltip>
            </div>
          </>
        );
      }

      if (threat_categories !== null && threat_categories.length > 0) {
        categoriesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.selectedFilters.threatCategories}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "threatCategories",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "threatCategories",
                event.selectedKeys,
              )
            }
            style={filterMenuScroll}
          >
            {threat_categories.map((eachType) => (
              <Menu.Item key={eachType.id}>{eachType.name}</Menu.Item>
            ))}
          </Menu>
        );
      }

      if (threat_offices !== null && threat_offices.length > 0) {
        officesMenu = (
          <Menu
            selectable
            multiple
            selectedKeys={this.state.selectedFilters.threatOffices}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "threatOffices",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "selectedFilters",
                "threatOffices",
                event.selectedKeys,
              )
            }
            style={filterMenuScroll}
          >
            {threat_offices.map((eachType) => (
              <Menu.Item key={eachType.id}>{eachType.name}</Menu.Item>
            ))}
          </Menu>
        );
      }

      threatsSimilarityMenu = (
        <>
          <div className="Filters-Similarity-Menu">
            <span className="Filters-Similarity-Menu-Input">
              <Input
                type="number"
                min={0}
                max={100}
                placeholder="0"
                value={this.state.selectedFilters.threatsSimilarity.lowerLimit}
                onChange={(event) =>
                  this.handleThreatsSimilarityInputChange("lowerLimit", event)
                }
              />
            </span>
            <Slider
              range
              defaultValue={[
                this.state.selectedFilters.threatsSimilarity.lowerLimit,
                this.state.selectedFilters.threatsSimilarity.upperLimit,
              ]}
              onAfterChange={(value) =>
                this.handleThreatsSimilarityAfterChange(value)
              }
              value={[
                this.state.selectedFilters.threatsSimilarity.lowerLimit,
                this.state.selectedFilters.threatsSimilarity.upperLimit,
              ]}
              onChange={(value) => this.handleThreatsSimilarityChange(value)}
              min={0}
              max={100}
              style={{ minWidth: "150px" }}
            />
            <span className="Filters-Similarity-Menu-Input">
              <Input
                type="number"
                min={0}
                max={100}
                placeholder="100"
                value={this.state.selectedFilters.threatsSimilarity.upperLimit}
                onChange={(event) =>
                  this.handleThreatsSimilarityInputChange("upperLimit", event)
                }
              />
            </span>
          </div>
          {this.state.selectedFilters.threatsSimilarity.upperLimit !== null &&
            this.state.selectedFilters.threatsSimilarity.lowerLimit >
              this.state.selectedFilters.threatsSimilarity.upperLimit && (
              <Alert
                className="trademark-alert-message"
                style={{ margin: "5px", textAlign: "center" }}
                message={"Lower Limit cannot exceed Upper Limit"}
                type="error"
              />
            )}
        </>
      );
    }

    return (
      <div className="list_layout_mark">
        <header className="General-Header m-top-10">
          <Row>
            <Col span={20} push={3}>
              <Dropdown
                overlay={classesMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.classesMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "classesMenu",
                    event,
                  )
                }
              >
                <Button
                  className={
                    this.state.selectedFilters.threatClasses.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }
                >
                  Classes
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={typesMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.typesMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "typesMenu",
                    event,
                  )
                }
              >
                <Button
                  className={
                    this.state.selectedFilters.threatTypes.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }
                >
                  TM Types
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={threatsSimilarityMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.threatsSimilarityMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "threatsSimilarityMenu",
                    event,
                  )
                }
              >
                <Button
                  className={`${
                    (this.state.selectedFilters.threatsSimilarity.lowerLimit >
                      0 ||
                      this.state.selectedFilters.threatsSimilarity.upperLimit <
                        100) &&
                    this.state.selectedFilters.threatsSimilarity.upperLimit !==
                      null
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }`}
                >
                  Similarity
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={threatSourcesMenu}
                open={this.state.filtersVisibility.threatSourcesMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "threatSourcesMenu",
                    event,
                  )
                }
              >
                <Button
                  className={`${
                    this.state.selectedFilters.threatSources.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }`}
                >
                  Infringement Types
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={categoriesMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.categoriesMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "categoriesMenu",
                    event,
                  )
                }
              >
                <Button
                  className={
                    this.state.selectedFilters.threatCategories.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }
                >
                  Categories
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={officesMenu}
                overlayClassName="Filters-Menu"
                open={this.state.filtersVisibility.officesMenu}
                onVisibleChange={(event) =>
                  this.changeStateValue_L1(
                    "filtersVisibility",
                    "officesMenu",
                    event,
                  )
                }
              >
                <Button
                  className={
                    this.state.selectedFilters.threatOffices.length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }
                >
                  Offices
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={
                  <DateRangePicker
                    type={"Date of Application"}
                    startValue={
                      this.state.selectedFilters
                        .threat_date_of_application_startValue
                    }
                    endValue={
                      this.state.selectedFilters
                        .threat_date_of_application_endValue
                    }
                    setDate={(val) => this.setDate1(val)}
                    range={this.state.range1}
                    handleDateString={(startValue, endValue, dateString) =>
                      this.setState({
                        selectedFilters: {
                          ...this.state.selectedFilters,
                          threat_date_of_application_startValue: startValue,
                          threat_date_of_application_endValue: endValue,
                          threat_date_of_application:
                            startValue !== null || endValue !== null
                              ? dateString
                              : null,
                        },
                      })
                    }
                  />
                }
                overlayClassName="filter-menu"
              >
                <Button
                  className={`${
                    this.state.selectedFilters.threat_date_of_application
                      .length > 0
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }`}
                >
                  DoA
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={
                  <div>
                    <DateRangePicker
                      type={"Date of Usage"}
                      startValue={
                        this.state.selectedFilters
                          .threat_date_of_usage_startValue
                      }
                      endValue={
                        this.state.selectedFilters.threat_date_of_usage_endValue
                      }
                      setDate={(val) => this.setDate2(val)}
                      range={this.state.range2}
                      handleDateString={(startValue, endValue, dateString) =>
                        this.setState({
                          selectedFilters: {
                            ...this.state.selectedFilters,
                            threat_date_of_usage_startValue: startValue,
                            threat_date_of_usage_endValue: endValue,
                            threat_date_of_usage:
                              startValue !== null || endValue !== null
                                ? dateString
                                : null,
                          },
                        })
                      }
                    />
                    <div style={{ textAlign: "center", lineHeight: "32px" }}>
                      <Checkbox
                        checked={
                          this.state.selectedFilters
                            .threat_date_of_usage_proposed
                        }
                        onChange={(event) =>
                          this.changeStateValue_L1(
                            "selectedFilters",
                            "threat_date_of_usage_proposed",
                            event.target.checked,
                          )
                        }
                      >
                        Proposed
                      </Checkbox>
                    </div>
                  </div>
                }
                overlayClassName="filter-menu"
              >
                <Button
                  className={`${
                    this.state.selectedFilters.threat_date_of_usage.length >
                      0 ||
                    this.state.selectedFilters.threat_date_of_usage_proposed
                      ? "Filters-Button-Selected"
                      : "Filters-Button-Empty"
                  }`}
                >
                  DoU
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            <Col span={4}>
              <Select
                style={{
                  display: "block",
                  padding: "0px 5px",
                  width: 240,
                  float: "right",
                }}
                defaultValue={this.props.current_date_range}
                value={
                  this.props.dateRange ? this.props.dateRange : "Date Range"
                }
                onChange={(dateRange, option) =>
                  this.handleDateChange(dateRange, option)
                }
                loading={!this.props.dateRange}
              >
                {this.props.dateRangeList &&
                  this.props.dateRangeList.map((eachRange) => (
                    <Option
                      id={eachRange.id}
                      key={eachRange.id}
                      value={eachRange.date_range}
                    >
                      {eachRange.date_range.split("to").join(" to ")}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <Row justify="center" className="m-top-10">
            <Col span={16}>
              <Menu mode="horizontal">
                <Menu.Item
                  key="searchDropdown"
                  className="filter-list-item apply-filter"
                >
                  <Dropdown overlay={searchTypesMenu}>
                    <Button style={headerButtonSelected}>
                      {this.state?.selectedFilters?.searchType}
                      <DownOutlined style={{ fontSize: "12px" }} />
                    </Button>
                  </Dropdown>
                </Menu.Item>
                {this.state?.selectedFilters?.searchType ===
                  "Application No/Name" && (
                  <Menu.Item
                    style={{ backgroundColor: "white" }}
                    key="searchterm"
                    className="filter-list-item apply-filter"
                  >
                    <Input
                      placeholder="Application No/Name"
                      onChange={(event) =>
                        this.changeStateValue_L1(
                          "selectedFilters",
                          "searchTerm",
                          event.target.value,
                        )
                      }
                      value={this.state?.selectedFilters?.searchTerm}
                      allowClear
                      style={{ width: 250 }}
                    />
                  </Menu.Item>
                )}

                {this.state?.selectedFilters?.searchType ===
                  "Your Threat Description Search" && (
                  <Menu.Item
                    style={{ backgroundColor: "white" }}
                    key="threat"
                    className="filter-list-item apply-filter"
                  >
                    <Input
                      placeholder="Your Threat Description Search"
                      onChange={(event) =>
                        this.changeStateValue_L1(
                          "selectedFilters",
                          "threat_desc_contains_term",
                          event.target.value,
                        )
                      }
                      value={
                        this.state?.selectedFilters?.threat_desc_contains_term
                      }
                      allowClear
                      style={{ width: 250 }}
                    />
                  </Menu.Item>
                )}
                {/* <Search
                                placeholder="Search application number or name"
                                onSearch={(searchInput) => this.handleApplyFilters(searchInput)}
                                enterButton={ */}
                <Menu.Item
                  key="apply"
                  className="filter-list-item apply-filter"
                >
                  <Button
                    style={{
                      backgroundColor: "#4285f4",
                      color: "#ffffff",
                      fontWeight: "600",
                    }}
                    onClick={() => this.handleApplyFilters()}
                  >
                    Apply
                  </Button>
                </Menu.Item>
                {/* }
                                style={{ width: "20vw", minWidth:"150px" }}
                            /> */}
                <Menu.Item
                  key="reset"
                  className="filter-list-item apply-filter"
                >
                  <Button type="link" onClick={() => this.handleResetFilters()}>
                    Reset
                  </Button>
                </Menu.Item>
                {(this.state.customReportData.selectedRecordsLength > 0 ||
                  this.state.customReportData.selectAll) && (
                  <Menu.Item
                    key="create-report"
                    className="filter-list-item apply-filter"
                  >
                    <Badge
                      overflowCount={9999}
                      count={
                        this.state.customReportData.selectAll
                          ? "All"
                          : this.state.customReportData.selectedRecordsLength
                      }
                      style={{ marginTop: "5px" }}
                    >
                      <Button onClick={() => this.handleCreateReport()}>
                        Create Report
                      </Button>
                    </Badge>
                    {!this.state.customReportData.selectAll && (
                      <MinusCircleOutlined
                        style={{ marginLeft: "10px", fontSize: "14px" }}
                        onClick={() =>
                          this.setState({
                            customReportData: LL_defaultCustomReportData,
                          })
                        }
                      />
                    )}
                  </Menu.Item>
                )}
              </Menu>
            </Col>
          </Row>
        </header>
        <div
          className="General-Content vertical_scroll"
          style={{
            height: `${
              this.props.watchAlert.length > 0
                ? "calc(100vh - 195px)"
                : "calc(100vh - 144px)"
            }`,
            overflowX: "hidden",
          }}
        >
          <Alert
            style={{ margin: "5px" }}
            message={
              <span>
                <InfoCircleFilled
                  style={{
                    fontSize: "20px",
                    verticalAlign: "0px",
                    color: "#4285f4",
                    marginRight: "10px",
                    backgroundColor: "#ffffff",
                    border: "2px solid #ffffff",
                    borderRadius: "50%",
                  }}
                />
                For the best experience it is recommended to customise focus
                words and classes as it will improve the relevancy of the
                system.
              </span>
            }
            type="info"
          />
          <table className="Table">
            <thead className="Table-Head">
              <tr className="Table-Head-Row">
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{
                    width: "3%",
                    minWidth: "50px",
                    verticalAlign: "middle",
                  }}
                >
                  <Checkbox
                    style={{ marginLeft: 15 }}
                    checked={this.state.customReportData.selectAll}
                    disabled={
                      !this.props.totalTableDataCount ||
                      this.props.tableDataStatus === PROGRESSING ||
                      this.props.tableDataStatus === LOADING
                        ? true
                        : false
                    }
                    onChange={(event) =>
                      this.changeStateValue_L1(
                        "customReportData",
                        "selectAll",
                        event.target.checked,
                      )
                    }
                  />
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{ verticalAlign: "middle" }}
                >
                  YOUR MARK
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{ verticalAlign: "middle" }}
                >
                  THREAT MARK
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{ verticalAlign: "middle" }}
                >
                  PROPRIETORS
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{ verticalAlign: "middle" }}
                >
                  CLASSES
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{ verticalAlign: "middle" }}
                >
                  DoA | DoU
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{ verticalAlign: "middle" }}
                >
                  TYPE
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{ verticalAlign: "middle" }}
                >
                  ALGORITHM | SIMILARITY
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{ verticalAlign: "middle" }}
                >
                  Send Mail
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{
                    width: "4%",
                    minWidth: "50px",
                    verticalAlign: "middle",
                  }}
                >
                  <Tooltip placement="bottom" title="Go To Bookmark">
                    {this.props.bookmark.present === true ? (
                      <BookTwoTone
                        style={{
                          color: "#4285f4",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => this.handleGoToBookmark()}
                      />
                    ) : (
                      <BookOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: "20px",
                        }}
                        onClick={() => this.handleGoToBookmark()}
                      />
                    )}
                  </Tooltip>
                </th>
                <th
                  className="Table-Head-Cell Black-Shadow"
                  style={{
                    width: "1%",
                    minWidth: "50px",
                    verticalAlign: "middle",
                  }}
                >
                  {this.state.tableDescriptionRowsVisibility ? (
                    <Tooltip placement="left" title="Hide Descriptions">
                      <MinusSquareFilled
                        style={{ fontSize: "20px", verticalAlign: "baseline" }}
                        onClick={() =>
                          this.setState({
                            tableDescriptionRowsVisibility: false,
                          })
                        }
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="left" title="Show Descriptions">
                      <PlusSquareFilled
                        style={{ fontSize: "20px", verticalAlign: "baseline" }}
                        onClick={() =>
                          this.setState({
                            tableDescriptionRowsVisibility: true,
                          })
                        }
                      />
                    </Tooltip>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="Table-Body">
              {/* When table data is loading, this table will render */}
              {this.props.tableDataStatus === LOADING && (
                <tr style={{ border: "none" }}>
                  <td
                    colSpan="11"
                    style={{ height: "50vh", verticalAlign: "middle" }}
                  >
                    <Spin size="large" />
                  </td>
                </tr>
              )}

              {/* When table data is received successfully and is not empty, this table will render */}
              {this.props.tableDataStatus === SUCCESS &&
                this.props.totalTableDataCount > 0 &&
                this.props.tableData.map((eachRow, rowIndex) => {
                  return (
                    <React.Fragment
                      key={`${eachRow.threat.application_number}-${eachRow.similarity.cease_desist_mark_id}`}
                    >
                      <tr
                        className={`Table-Body-Row ${
                          rowIndex % 2 === 0 ? "Row-Even" : "Row-Odd"
                        }`}
                      >
                        <td style={{ verticalAlign: "middle" }}>
                          <span
                            style={{
                              display: "inline-block",
                              paddingRight: "5px",
                            }}
                          >
                            {rowIndex + 1 + (this.props.pageNumber - 1) * 100}.
                          </span>
                          <Checkbox
                            checked={
                              (this.state.customReportData.selectedRecords[
                                `${eachRow.similarity.cease_desist_mark_id}`
                              ]
                                ? this.state.customReportData.selectedRecords[
                                    `${eachRow.similarity.cease_desist_mark_id}`
                                  ].includes(
                                    `${eachRow.threat.application_number}`,
                                  )
                                : false) ||
                              this.state.customReportData.selectAll
                                ? true
                                : false
                            }
                            onChange={(event) =>
                              this.handleCustomReportRecordSelection(
                                eachRow,
                                event.target.checked,
                              )
                            }
                            disabled={this.state.customReportData.selectAll}
                          />
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {eachRow.similarity.term
                            ? eachRow.similarity.term.toUpperCase()
                            : ""}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <span>{eachRow.threat.applied_for}</span>
                          <br />
                          {eachRow.threat.associated_image && (
                            <>
                              <span>
                                <img
                                  src={eachRow.threat.associated_image}
                                  height="70px"
                                  width="70px"
                                  alt=""
                                />
                              </span>
                              <br />
                            </>
                          )}
                          <span>{eachRow.threat.application_number}</span>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {eachRow.threat.proprietor.map(
                            (eachProprietor, index, allProprietors) =>
                              allProprietors.length === index + 1
                                ? `${eachProprietor.name}`
                                : `${eachProprietor.name}, `,
                          )}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {eachRow.threat.associated_class.map(
                            (eachClass, index, allClasses) =>
                              allClasses.length === index + 1
                                ? `${eachClass}`
                                : `${eachClass}, `,
                          )}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <span>
                            DoA:{" "}
                            {eachRow.threat.date_of_application
                              ? eachRow.threat.date_of_application
                              : ""}
                          </span>
                          <br />
                          <span>
                            DoU:{" "}
                            {eachRow.threat.date_of_usage
                              ? eachRow.threat.date_of_usage
                              : ""}
                          </span>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {eachRow.threat.type
                            ? eachRow.threat?.type?.name
                            : ""}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <Tooltip
                            placement="top"
                            title="Infringements are calculated based on the focus word"
                          >
                            <span>{eachRow.similarity.algorithm}</span>
                          </Tooltip>
                          <br />
                          <span>{`${eachRow.similarity.score}%`}</span>
                          <br />
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <Button
                            ghost
                            type="primary"
                            onClick={() =>
                              this.handleEmail(
                                "modalsVisibility",
                                "sendEmail",
                                true,
                                eachRow,
                              )
                            }
                          >
                            Send
                          </Button>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {this.props.bookmark.threatApplicationNumber ===
                          eachRow.threat.application_number ? (
                            <BookTwoTone
                              id="Bookmark"
                              style={{
                                color: "#4285f4",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.props.LL_postPatchDeleteTMProtectBookmark(
                                  this.props.profileReportID,
                                  "TM_PROTECT_LIST_LAYOUT",
                                  this.props.pageNumber,
                                  this.props.lastAppliedFilters,
                                  eachRow.threat.application_number,
                                );
                              }}
                            />
                          ) : (
                            <BookOutlined
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.props.LL_postPatchDeleteTMProtectBookmark(
                                  this.props.profileReportID,
                                  "TM_PROTECT_LIST_LAYOUT",
                                  this.props.pageNumber,
                                  this.props.lastAppliedFilters,
                                  eachRow.threat.application_number,
                                );
                              }}
                            />
                          )}
                        </td>
                        <td className="" />
                      </tr>
                      {this.state.tableDescriptionRowsVisibility && (
                        <tr
                          className={`Table-Body-Row ${
                            rowIndex % 2 === 0 ? "Row-Even" : "Row-Odd"
                          }`}
                        >
                          <td className="" />
                          <td
                            colSpan="9"
                            style={{ verticalAlign: "top", padding: 5 }}
                          >
                            {eachRow.threat.description
                              ? eachRow.threat.description
                              : ""}
                          </td>
                          <td className="" />
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}

              {/* When table data is received successfully and is empty, this table will render */}
              {this.props.tableDataStatus === SUCCESS &&
                this.props.totalTableDataCount === 0 && (
                  <tr style={{ height: "50vh" }}>
                    <td colSpan="11">
                      <Empty />
                    </td>
                  </tr>
                )}

              {/* When the data is not received due to some error */}
              {this.props.tableDataStatus === ERROR && (
                <tr>
                  <td colSpan="11" style={{ height: "50vh" }}>
                    {/* <h1>Unfortunately, due to the occurrence of an error, we were unable to fetch the data from the server.</h1>
                                        <h1>We apologize for the inconvenience. Please, try again after some time.</h1> */}
                  </td>
                </tr>
              )}

              {/* When the report has not been generated yet */}
              {this.props.tableDataStatus === PROGRESSING && (
                <tr>
                  <td colSpan="11" style={{ height: "50vh" }}>
                    <h3>
                      Your marks are being analyzed for{" "}
                      {
                        <Select
                          style={{ fontSize: "20px", width: 200 }}
                          defaultValue={this.props.current_date_range}
                          value={
                            this.props.dateRange
                              ? this.props.dateRange
                              : "Date Range"
                          }
                          onChange={(dateRange, option) =>
                            this.handleDateChange(dateRange, option)
                          }
                          loading={!this.props.dateRange}
                        >
                          {this.props.dateRangeList &&
                            this.props.dateRangeList.map((eachRange) => (
                              <Option
                                id={eachRange.id}
                                key={eachRange.id}
                                value={eachRange.date_range}
                              >
                                {eachRange.date_range.split("to").join(" to ")}
                              </Option>
                            ))}
                        </Select>
                      }{" "}
                      Date Range.
                    </h3>
                    <h3>
                      Meanwhile, you can access reports of previous Date Range.
                    </h3>
                    <h3>You can also change it from the top right corner.</h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <footer className="General-Footer">
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            current={this.props.pageNumber}
            total={this.props.totalTableDataCount}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showSizeChanger={false}
            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
          />
        </footer>
        {/* When creat report is clicked, this drawer will be opened */}
        <CustomReport
          visible={this.state.drawersVisibility.customReport}
          customReportData={this.state.customReportData}
          customReportStatus={this.props.customReportStatus}
          changeStateValue_L1={this.changeStateValue_L1}
          handleCreateReportConfirmation={() =>
            this.handleCreateReportConfirmation()
          }
          closeDrawer={() =>
            this.changeStateValue_L1("drawersVisibility", "customReport", false)
          }
        />
        {/* This Modal will open when the question mark in infringement types filter is clicked */}
        <InfringementTypesFilterInfo
          visible={this.state.modalsVisibility.infringementTypesFilterInfo}
          onClose={() =>
            this.changeStateValue_L1(
              "modalsVisibility",
              "infringementTypesFilterInfo",
              false,
            )
          }
        />
        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.watch}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          mark_type={this.state.modalsVisibility?.report_tag?.your_mark}
          journal_copy={this.state.modalsVisibility.journal_copy}
          objectType={this.state.modalsVisibility.objectType}
          // applicationNumber={this.state.modalsVisibility.applicationNumber}
          sub_section="DASHBOARD"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getDateRangeList,
  postTMProtectCustomReport,
  LL_getTMProtectData,
  LL_getTMProtectBookmark,
  LL_postPatchDeleteTMProtectBookmark,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return {
    dateRangeList: state.watchState.dateRange.date_range_list,
    current_date_range: state.watchState.currentDateRange.current_date_range,
    current_profile_report_id:
      state.watchState.currentDateRange.current_profile_report_id,
    dateRange: state.watchState.listLayout.tm_protect.dateRange,
    profileReportID: state.watchState.listLayout.tm_protect.profileReportID,

    tableData: state.watchState.listLayout.tm_protect.tableData,
    totalTableDataCount:
      state.watchState.listLayout.tm_protect.totalTableDataCount,
    tableDataStatus: state.watchState.listLayout.tm_protect.tableDataStatus,
    pageNumber: state.watchState.listLayout.tm_protect.pageNumber,
    lastAppliedFilters:
      state.watchState.listLayout.tm_protect.lastAppliedFilters,
    filters: state.watchState.listLayout.tm_protect.filters,

    bookmark: state.watchState.listLayout.tm_protect.bookmark,
    customReportStatus: state.watchState.listLayout.customReportStatus,

    userProfile: state.profile.userProfile,
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    watchAlert: state.alertMessages.watch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListLayoutTMProtect);
