import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

import {
  W_LL_TEMPLATE,
  W_LL_CHANGE_TEMPLATE_ID,
  W_LL_CREATE_TEMPLATE,
  W_LL_UPDATE_TEMPLATE,
} from "../../../actions/watchActions/types";

export const defaultTemplates = {
  USER: {
    data: [],
    metadata: {
      count: 0,
    },
    loading: false,
  },
  RECOMMENDED: {
    data: [],
    metadata: {
      count: 0,
    },
    loading: false,
  },
  current_template_id: 0,
  template_status: {
    loading: false,
  },
};

export default function (state = defaultTemplates, action) {
  switch (action.type) {
    case W_LL_TEMPLATE: {
      switch (action.status) {
        case SUCCESS: {
          return {
            ...state,
            [action.template_type]: {
              loading: false,
              data: action.payload.data,
              metadata: action.payload.metadata,
            },
          };
        }
        case LOADING: {
          return {
            ...state,
            [action.template_type]: {
              loading: true,
            },
          };
        }
        case ERROR: {
          return {
            ...state,
            [action.template_type]: {
              loading: false,
            },
          };
        }
        default:
          return state;
      }
    }
    case W_LL_CHANGE_TEMPLATE_ID: {
      return {
        ...state,
        current_template_id: action.template_id,
      };
    }
    case W_LL_CREATE_TEMPLATE:
    case W_LL_UPDATE_TEMPLATE: {
      switch (action.status) {
        case SUCCESS: {
          return {
            ...state,
            template_status: {
              loading: false,
            },
          };
        }
        case LOADING: {
          return {
            ...state,
            template_status: {
              loading: true,
            },
          };
        }
        case ERROR: {
          return {
            ...state,
            template_status: {
              loading: false,
            },
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
