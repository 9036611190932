import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Row,
  Tabs,
  Col,
  Typography,
  Card,
  Table,
  Button,
  Checkbox,
} from "antd";

//--------ACTIONS--------
import SmartRender from "../../smartComponents/SmartRender";
import EditProfile from "../../commonComponents/ProfileDetails/EditProfile";
import TeamMember from "../../commonComponents/ProfileDetails/TeamMember";
import MFADashboard from "../../commonComponents/ProfileDetails/MFADashboard";
import { LOADING } from "../../../constants/commonConstants/generalConstants";
import {
  P_patchProfilePreferences,
  P_getProfilePreferences,
} from "../../../redux/actions/watchActions/profileActions";
import { logOut } from "../../../redux/actions/commonActions/actions";
import { getUserTrademarksInSearch } from "../../../redux/actions/searchActions/commonActions";

const { TabPane } = Tabs;
const { Content } = Layout;
const { Title } = Typography;

const columnsUserTrademarks = [
  {
    title: "S.No",
    dataIndex: "s_no",
    key: "s_no",
    align: "left",
    width: "10%",
  },
  {
    title: "Trademarks",
    dataIndex: "term",
    key: "term",
    align: "left",
    width: "90%",
  },
];

const ProfileDashboard = () => {
  const current_route = window.location?.pathname?.split("/")[1];
  const [activeTab, setActiveTab] = useState("USER_DETAILS");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const trademarksData =
    useSelector((state) =>
      state?.searchState?.searchBar?.userTrademarks?.map((value, key) => ({
        ...value,
        s_no: page * 100 - 100 + key + 1,
      })),
    ) || [];

  const handleNextpage = (page) => {
    dispatch(getUserTrademarksInSearch({ page: page }));
    setPage(page);
  };

  const { userTrademarksStatus, userTrademarksCount } = useSelector(
    (state) => state.searchState.searchBar,
  );

  const { reportPreferences, reportPreferenceStatus } = useSelector(
    (state) => state.watchState?.profilePreferences,
  );

  const [product, setProduct] = useState(() => {
    if (current_route === "search") {
      return "tm-search";
    } else if (current_route === "manager") {
      return "manager";
    } else if (current_route === "watch") {
      return "tm-watch";
    }
  });

  const yourTM = (
    <Row>
      <Col span={24} className="m-top-20">
        <Card title="YOUR TRADEMARKS">
          <Table
            bordered
            rowKey={(record) => record.id}
            columns={columnsUserTrademarks}
            dataSource={trademarksData}
            scroll={{ y: "calc(70vh - 170px)" }}
            loading={userTrademarksStatus === LOADING}
            pagination={{
              pageSize: 100,
              showQuickJumper: true,
              showSizeChanger: false,
              defaultCurrent: 1,
              total: trademarksData && userTrademarksCount,
              onChange: handleNextpage,
            }}
          />
        </Card>
      </Col>
    </Row>
  );

  const watchReportPreference = (
    <Row>
      <Col span={24} className="m-top-20">
        <Card
          title="REPORT PREFERENCES"
          bodyStyle={{
            overflow: "auto",
            maxHeight: "400px",
          }}
          loading={reportPreferenceStatus === LOADING}
        >
          {reportPreferences && (
            <form name="reportPreferences">
              Choose your preferred automated excel report with or without
              images:
              <br />
              {/* <Checkbox defaultChecked={reportPreferences !== null? reportPreferences.pdf: false} name="pdf" /> PDF */}
              {/* <Checkbox defaultChecked={reportPreferences !== null? reportPreferences.excel: false} name="excel" /> EXCEL */}
              <Checkbox
                defaultChecked={
                  reportPreferences !== null
                    ? reportPreferences.image_in_excel
                    : false
                }
                name="image_in_excel"
              />{" "}
              Include trademark images in report
              <br />
              <br />
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(
                    P_patchProfilePreferences({
                      pdf: false,
                      excel: true,
                      image_in_excel:
                        event.target.form?.image_in_excel?.checked ?? false,
                    }),
                  );
                }}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </form>
          )}
        </Card>
      </Col>
    </Row>
  );

  const smartTabConfig = [
    {
      name: "User Details",
      key: "USER_DETAILS",
      render: (
        <>
          <EditProfile />
          {product === "tm-search" && yourTM}
          {product === "tm-watch" && watchReportPreference}
        </>
      ),
    },
    {
      name: "Team Members",
      key: "TEAM_MEMBERS",
      render: <TeamMember product={product} />,
    },
    {
      name: (
        <>
          Account Security <sup style={{ color: "rgb(235, 0, 19)" }}>New</sup>
        </>
      ),
      key: "ACCOUNT_SECURITY",
      render: <MFADashboard />,
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (!reportPreferences) {
        dispatch(P_getProfilePreferences());
      }
    } else {
      dispatch(logOut());
    }
    if (window.Tawk_API !== undefined) {
      window.Tawk_API.showWidget();
    } else {
      //Chat Widget--------Tawk
      (function () {
        const s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/619205a96885f60a50bbcd33/1fkh6c8b3";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
    return () => {
      if (window.Tawk_API !== undefined) {
        window.Tawk_API.hideWidget();
      }
    };
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const defaultActiveKey = "USER_DETAILS";
  const mainContent = (
    <Row>
      <Col span={24}>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          activeKey={activeTab}
          onChange={handleTabChange}
          tabBarStyle={{ margin: "0px" }}
        >
          {smartTabConfig?.map((config) => {
            return (
              <TabPane
                tab={config?.name}
                key={config?.key}
                style={{ margin: "0px" }}
              >
                <Row
                  className="content-container vertical_scroll"
                  style={{ height: "80vh" }}
                >
                  <Col span={24} className="m-top-20">
                    {config?.render ? config?.render : null}
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </Tabs>
      </Col>
    </Row>
  );

  return (
    <Layout
      style={{ background: "white", padding: "24px", maxHeight: "100vh" }}
    >
      <Content>
        <div style={{ margin: "0px" }}>
          <Row>
            <Col xs={{ span: 12 }} lg={{ span: 22 }}>
              <Title level={3}>User Profile</Title>
            </Col>
          </Row>
          {SmartRender(null, mainContent)}
        </div>
      </Content>
    </Layout>
  );
};

export default ProfileDashboard;
