export const TM_RENEWALS = "TM_RENEWALS";
export const RENEWAL_FILED = "RENEWAL_FILED";

export const REPLY_TO_CORRESPONDENCE = "REPLY_TO_CORRESPONDENCE";
export const PSEUDO_REPLY_TO_CORRESPONDENCE = "PSEUDO_REPLY_TO_CORRESPONDENCE";
export const OPPOSED_REPLY_TO_CORRESPONDENCE =
  "OPPOSED_REPLY_TO_CORRESPONDENCE";
export const PSEUDO_OPPOSED_REPLY_TO_CORRESPONDENCE =
  "PSEUDO_OPPOSED_REPLY_TO_CORRESPONDENCE";
export const REPLY_FILED_TO_CORRESPONDENCE = "REPLY_FILED_TO_CORRESPONDENCE";
export const DASHBOARD_REPLY_FILED_TO_CORRESPONDENCE =
  "DASHBOARD_REPLY_FILED_TO_CORRESPONDENCE";
export const REPLY_FILED_TO_CORRESPONDENCE_NEW =
  "REPLY_FILED_TO_CORRESPONDENCE_NEW";

export const TOTAL_TM = "TOTAL_TM";
export const TOTAL_TM_PROPRIETOR_FILTERS = "TOTAL_TM_PROPRIETOR_FILTERS";
export const TOTAL_TM_CLASSES_FILTERS = "TOTAL_TM_CLASSES_FILTERS";
export const TOTAL_TM_TRADEMARK_TYPE_FILTERS =
  "TOTAL_TM_TRADEMARK_TYPE_FILTERS";
export const TOTAL_TM_TAG_FILTERS = "TOTAL_TM_TAG_FILTERS";
export const TOTAL_TM_TRADEMARK_STATUS_FILTERS =
  "TOTAL_TM_TRADEMARK_STATUS_FILTERS";
export const TOTAL_TM_TRADEMARK_STATES_FILTERS =
  "TOTAL_TM_TRADEMARK_STATES_FILTERS";
export const TOTAL_TM_TRADEMARK_OPPONENT_FILTERS =
  "TOTAL_TM_TRADEMARK_OPPONENT_FILTERS";
export const TOTAL_TM_JOURNAL_FILTERS = "TOTAL_TM_JOURNAL_FILTERS";
export const TOTAL_TM_ALERTS_FILTERS = "TOTAL_TM_ALERTS_FILTERS";
export const TOTAL_TM_TRADEMARK_OFFICE_FILTERS =
  "TOTAL_TM_TRADEMARK_OFFICE_FILTERS";

export const REFINED_TRADEMARK = "REFINED_TRADEMARK";
export const TRADEMARK_CHANGELOG = "TRADEMARK_CHANGELOG";

export const PROPRIETOR = "PROPRIETOR";
export const PROPRIETOR_DETAIL = "PROPRIETOR_DETAIL";

export const TLA_HEARING = "TLA_HEARING";
export const TLA_HEARING_CLASSES_FILTERS = "TLA_HEARING_CLASSES_FILTERS";
export const TLA_HEARING_PROPRIETORS_FILTERS =
  "TLA_HEARING_PROPRIETORS_FILTERS";
export const TLA_HEARING_LOCATION_FILTERS = "TLA_HEARING_LOCATION_FILTERS";
export const TLA_HEARING_OFFICERS_FILTERS = "TLA_HEARING_OFFICERS_FILTERS";
export const TLA_HEARING_TAG_FILTERS = "TLA_HEARING_TAG_FILTERS";
export const TLA_HEARING_OFFICE_FILTERS = "TLA_HEARING_OFFICE_FILTERS";

export const OPPOSITION_HEARING = "OPPOSITION_HEARING";
export const OPPOSITION_HEARING_CLASSES_FILTERS =
  "OPPOSITION_HEARING_CLASSES_FILTERS";
export const OPPOSITION_HEARING_PROPRIETORS_FILTERS =
  "OPPOSITION_HEARING_PROPRIETORS_FILTERS";
export const OPPOSITION_HEARING_LOCATION_FILTERS =
  "OPPOSITION_HEARING_LOCATION_FILTERS";
export const OPPOSITION_HEARING_OFFICERS_FILTERS =
  "OPPOSITION_HEARING_OFFICERS_FILTERS";
export const OPPOSITION_HEARING_TAG_FILTERS = "OPPOSITION_HEARING_TAG_FILTERS";
export const OPPOSITION_HEARING_OFFICE_FILTERS =
  "OPPOSITION_HEARING_OFFICE_FILTERS";

export const OPPOSED_TLA_HEARING = "OPPOSED_TLA_HEARING";
export const OPPOSED_OPPOSITION_HEARING = "OPPOSED_OPPOSITION_HEARING";

export const HEARING_REPORT_DOWNLOAD = "HEARING_REPORT_DOWNLOAD";
export const MANAGE_TRADEMARK = "MANAGE_TRADEMARK";
export const POST_MANAGE_TRADEMARK = "POST_MANAGE_TRADEMARK";

export const UPLOAD_FILE_TM = "UPLOAD_FILE_TM";
export const UPLOAD_FILE_TM_REFINED = "UPLOAD_FILE_TM_REFINED";
export const UPLOAD_FILE_TM_CUSTOM = "UPLOAD_FILE_TM_CUSTOM";
export const UPLOAD_FILE_TM_INT = "UPLOAD_FILE_TM_INT";

// reply to correspondence dashboard

export const CORRESPONDENCE = "CORRESPONDENCE";
export const PSEUDO_CORRESPONDENCE = "PSEUDO_CORRESPONDENCE";
export const OPPOSED_CORRESPONDENCE = "OPPOSED_CORRESPONDENCE";
export const PSEUDO_OPPOSED_CORRESPONDENCE = "PSEUDO_OPPOSED_CORRESPONDENCE";

export const PENDING_EVIDENCE = "PENDING_EVIDENCE";
export const PSEUDO_PENDING_EVIDENCE = "PSEUDO_PENDING_EVIDENCE";
export const OPPOSED_PENDING_EVIDENCE = "OPPOSED_PENDING_EVIDENCE";
export const PSEUDO_OPPOSED_PENDING_EVIDENCE =
  "PSEUDO_OPPOSED_PENDING_EVIDENCE";

export const OBJECTED = "OBJECTED";
export const PSEUDO_OBJECTED = "PSEUDO_OBJECTED";
export const OPPOSED_OBJECTED = "OPPOSED_OBJECTED";
export const PSEUDO_OPPOSED_OBJECTED = "PSEUDO_OPPOSED_OBJECTED";

export const CHECK_FAIL = "CHECK_FAIL";
export const PSEUDO_CHECK_FAIL = "PSEUDO_CHECK_FAIL";
export const OPPOSED_CHECK_FAIL = "OPPOSED_CHECK_FAIL";
export const PSEUDO_OPPOSED_CHECK_FAIL = "PSEUDO_OPPOSED_CHECK_FAIL";

export const OPPOSED = "OPPOSED";
export const PSEUDO_OPPOSED = "PSEUDO_OPPOSED";
export const OPPOSED_OPPOSED = "OPPOSED_OPPOSED";
export const PSEUDO_OPPOSED_OPPOSED = "PSEUDO_OPPOSED_OPPOSED";
