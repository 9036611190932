//--------LIBRARIES--------
import React, { Component } from 'react';
import { Row, Col, Table, Typography, Button, Card } from 'antd';

import { LOADING } from '../../../../constants/commonConstants/generalConstants';
import { standardizeDate } from '../../../../functions/commonFunctions/dateFunctions';

const { Text } = Typography;

class UploadDocumentsTable extends Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    
    componentDidMount(){
    }
    
    render(){
        const uploadedDocumentsColumns = [
            {
                title: 'FILE NAME',
                dataIndex: 'title',
                className: 'patent-column',
                render: title => {
                    return(
                        <div
                            key={title}
                            className="Clickable-Link"
                            onClick={()=> this.props.postDownloadFile(title)}
                        >
                            {title}
                        </div>
                    )
                },
            },
    
            {
                title: 'UPLOADED BY',
                dataIndex: 'uploaded_by',
                className: 'patent-column',
            },
    
            {
                title: 'LAST MODIFIED',
                dataIndex: 'modified',
                className: 'patent-column',
                render: modified => {
                    return(
                        <div>
                            {standardizeDate(modified)}
                        </div>
                    );
                },
            }
        ];
    
        return (
            <Row gutter={16} className="m-top-20">
                <Col span={24}>
                    <Card 
                        title = {
                            <>
                                UPLOADED DOCUMENTS
                                <Button
                                    style={{margin:"0px 10px"}}
                                    onClick={this.props.changeVisible}
                                >Edit</Button>
                            </>
                        }
                        headStyle={{background: '#e8e8e8'}}
                        loading={this.props.status === LOADING ? true : false}
                    >
                        <Col span={24}>
                            {
                                (this.props.data && this.props.data.uploaded_documents.length > 0) ?
                                <Table 
                                    rowKey={record => record.title} 
                                    bordered
                                    dataSource={this.props.data.uploaded_documents } 
                                    columns={uploadedDocumentsColumns} 
                                    pagination={false}
                                    scroll={{ y: "calc(70vh - 200px)" }}  
                                />
                                :
                                <div className="alignC">
                                    <Text style={{fontSize: '18px'}}>No Uploaded Documents</Text>
                                </div>
                            }
                        </Col>
                    </Card>
                </Col>
            </Row>
        );
    }       
}

export default UploadDocumentsTable;