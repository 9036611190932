import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Link } from "react-router-dom";
import {
  CalendarTwoTone,
  ClockCircleTwoTone,
  FileTwoTone,
} from "@ant-design/icons";
import { Row, Col, Typography, Card } from "antd";

//--------PROFILE ACTIONS--------
import {
  getDashboard,
  getDashboardAnalytics,
} from "../../../../redux/actions/managerActions/profile/actions";

//--------IP-SUITE CONSTANTS--------
import { LOADING } from "../../../../constants/commonConstants/generalConstants";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";

//--------TRADEMARK COMPONENTS--------
// import OpposedCorrespondence from './Correspondence';
// import OpposedEvidenceAffidavit from './Evidence';
// import OpposedCheckFailStatus from './CheckFail';
// import OpposedObjectedStatus from './Objected';
import OpposedOpposedStatus from "./Opposed";
import OpposedTrademarkAnalysis from "./Trademark-Analysis";
import { logOut } from "../../../../redux/actions/commonActions/actions";

const { Title, Text } = Typography;

class OpposedTrademarkDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      accessDocumentModal: false,
      open_latest: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.opposedTmDashboard === null) {
        this.props.getDashboard("trademark", "opposed");
        this.props.getDashboardAnalytics("trademark", "opposed");
      }
    } else {
      this.props.logOut();
    }
  }

  render() {
    const { userSubscriptions } = this.props;
    return (
      <>
        <Row>
          <Col span={24} className="alignR">
            <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
              *Information shown here is not more than{" "}
              {checkValue(this.props.opposedTmDashboard)
                ? this.props.opposedTmDashboard.trademark.days_old
                : 0}{" "}
              day(s) old.
            </Text>
          </Col>
        </Row>

        {/* card-containt */}
        <Row gutter={16} className="m-top-20">
          <Col className="gutter-row" span={8}>
            <Link to="/manager/trademark/opposed-trademark/total-marks">
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "120px",
                  padding: "15px",
                }}
                loading={
                  this.props.opposedTmDashboardStatus === LOADING ? true : false
                }
              >
                <Row>
                  <Col span={4}>
                    <FileTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "14px" }}>
                      TOTAL APPLICATION
                    </Title>
                    <Text strong style={{ fontSize: "14px" }}>
                      {checkValue(this.props.opposedTmDashboard)
                        ? this.props.opposedTmDashboard.trademark
                            .managed_trademarks_count
                        : 0}
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col className="gutter-row" span={8}>
            <Link to="/manager/trademark/opposed-trademark/reply-to-correspondence">
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "120px",
                  padding: "15px",
                }}
                loading={
                  this.props.opposedTmDashboardStatus === LOADING ? true : false
                }
              >
                <Row>
                  <Col span={4}>
                    <ClockCircleTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "14px" }}>
                      REPLY TO CORRESPONDENCE
                    </Title>
                    <div>
                      <div>
                        <Text
                          strong
                          className={
                            checkValue(userSubscriptions)
                              ? userSubscriptions.manager.includes(
                                  "PSEUDO_TRADEMARK",
                                )
                                ? ""
                                : "display_none"
                              : "display_none"
                          }
                        >
                          IP INDIA -{" "}
                        </Text>
                        {userSubscriptions &&
                          userSubscriptions.manager.includes("TRADEMARK") && (
                            <Text strong style={{ fontSize: "14px" }}>
                              {checkValue(this.props.opposedTmDashboard)
                                ? this.props.opposedTmDashboard.trademark
                                    .pending_reply_ip_india_count
                                : 0}
                            </Text>
                          )}
                      </div>
                      <div>
                        <Text
                          strong
                          className={
                            checkValue(userSubscriptions)
                              ? userSubscriptions.manager.includes(
                                  "PSEUDO_TRADEMARK",
                                )
                                ? ""
                                : "display_none"
                              : "display_none"
                          }
                        >
                          CUSTOM -{" "}
                        </Text>
                        {userSubscriptions &&
                          userSubscriptions.manager.includes(
                            "PSEUDO_TRADEMARK",
                          ) && (
                            <Text strong style={{ fontSize: "14px" }}>
                              {checkValue(this.props.opposedTmDashboard)
                                ? this.props.opposedTmDashboard.trademark
                                    .pending_reply_own_data_count
                                : 0}
                            </Text>
                          )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col className="gutter-row" span={8}>
            <Link to="/manager/trademark/opposed-trademark/upcoming-hearings">
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "120px",
                  padding: "15px",
                }}
                loading={
                  this.props.opposedTmDashboardStatus === LOADING ? true : false
                }
              >
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={4} style={{ textAlign: "center" }}>
                    <CalendarTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title
                      level={4}
                      style={{ fontSize: "14px", marginBottom: "10px" }}
                    >
                      UPCOMING HEARINGS
                    </Title>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                        <Text strong>
                          Opposition (Cause list):
                          <Text strong>
                            {checkValue(this.props.opposedTmDashboard)
                              ? " " +
                                this.props.opposedTmDashboard.trademark
                                  .opposition_hearings_count
                              : " 0"}
                          </Text>
                        </Text>
                      </Col>
                      <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                        <Text strong>
                          Opposition (IP India):
                          <Text strong>
                            {checkValue(
                              this.props?.opposedTmDashboard?.trademark
                                ?.opposition_hearings_based_ip_india_count,
                            )
                              ? " " +
                                this.props.opposedTmDashboard.trademark
                                  .opposition_hearings_based_ip_india_count
                              : " 0"}
                          </Text>
                        </Text>
                      </Col>
                      <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                        <Text strong>
                          TLA (Cause list):
                          <Text strong>
                            {checkValue(this.props.opposedTmDashboard)
                              ? " " +
                                this.props.opposedTmDashboard.trademark
                                  .tla_hearings_count
                              : " 0"}
                          </Text>
                        </Text>
                      </Col>
                      <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                        <Text strong>
                          TLA (IP India):
                          <Text strong>
                            {checkValue(this.props.opposedTmDashboard)
                              ? " " +
                                this.props.opposedTmDashboard.trademark
                                  .tla_hearings_based_on_ip_india_count
                              : " 0"}
                          </Text>
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        </Row>
        {/* card-containt */}

        {/* correspondence */}
        {/* <Row className="m-top-20">
                <OpposedCorrespondence/>
            </Row> */}
        {/* correspondence */}

        {/* EvidenceAffidavit */}
        {/* <Row className="m-top-20">
                <OpposedEvidenceAffidavit/>
            </Row> */}
        {/* EvidenceAffidavit */}

        {/* CheckFailStatus */}
        {/* <Row className="m-top-20">
                <OpposedCheckFailStatus/>
            </Row> */}
        {/* CheckFailStatus */}

        {/* ObjectedStatus */}
        {/* <Row className="m-top-20">
                <OpposedObjectedStatus/>
            </Row> */}
        {/* ObjectedStatus */}

        {/* OpposedStatus */}
        <Row className="m-top-20">
          <Col span={24}>
            <OpposedOpposedStatus />
          </Col>
        </Row>
        {/* OpposedStatus */}

        {/* Analysis */}
        <Row className="m-top-20">
          <Col span={24}>
            <OpposedTrademarkAnalysis />
          </Col>
        </Row>
        {/* Analysis */}
      </>
    );
  }
}

const mapDispatchToProps = {
  getDashboard,
  getDashboardAnalytics,
  logOut,
};

function mapStateToProps(state) {
  const { opposedTmDashboard, opposedTmDashboardStatus } =
    state.managerState.profileState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
    opposedTmDashboard,
    opposedTmDashboardStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpposedTrademarkDashboard);
