//--------IP-SUITE TYPES--------
import { GET_EMAIL_VARS } from "../actions/commonActions/types";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../constants/commonConstants/generalConstants";

const defaultState = {
  emailVariables: null,
  emailVariablesLoading:false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_EMAIL_VARS:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            emailVariablesLoading:true,
          };
        case SUCCESS:
          let emailVariables = [];

          action.payload.data.forEach((product) => {
            const option = {
              label: product?.product_name.replace(/(\w)(\w*)/g,
              function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();}),
              value: product?.product_name,
              children: product?.subscriptions?.map((data) => ({
                label: data?.subscription_name.toUpperCase().replace(/_/g, " ").replace(/(\w)(\w*)/g,
                function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();}),
                value: data?.subscription_name.toUpperCase(),
                children: data?.sub_section.map((sub_section) => ({
                  label: sub_section?.sub_section_name.replace(/_/g, " ").replace(/(\w)(\w*)/g,
                  function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();}),
                  value:  sub_section?.sub_section_name,
                  variables:sub_section?.variables
                })),
              })),
            };
            emailVariables = [...emailVariables, option];
          });

          return {
            ...state,
            emailVariables,
            emailVariablesLoading:false,
          };
        case ERROR:
          return {
            ...state,
            emailVariablesLoading:false,
          };
        default:
          break;
      }
      break;
    default:
      return state;
  }
}
