//--------TYPES--------
import {
  TRADEMARK_CUSTOM_REPORT,
  TRADEMARK_AUTOMATED_REPORT,
  TLA_REPORT,
  OPPOSTION_REPORT,
  CUSTOM_TRADEMARK_REPORT,
  INTERNATIONAL_TRADEMARK_REPORT,
  PATENT_CUSTOM_REPORT,
  PATENT_AUTOMATED_REPORT,
  CUSTOM_PATENT_REPORT,
  INTERNATIONAL_PATENT_REPORT,
  MANAGER_DOWNLOAD_REPORT,
  EXAM_REPORT,
  EXAM_REPORT_YOUR_CLASSES_FILTERS,
  EXAM_REPORT_YOUR_PROPRIETORS_FILTERS,
  EXAM_REPORT_YOUR_TM_TYPE_FILTERS,
  EXAM_REPORT_THREAT_CLASSES_FILTERS,
  EXAM_REPORT_THREAT_PROPRIETORS_FILTERS,
  EXAM_REPORT_THREAT_TM_TYPE_FILTERS,
} from "../../../actions/managerActions/report/types";

export default function (
  state = {
    trademarkCustomReport: null,
    trademarkCustomReportStatus: null,
    trademarkCustomReportPageNumber: 1,

    trademarkAutomatedReport: null,
    trademarkAutomatedReportStatus: null,
    trademarkAutomatedReportPageNumber: 1,

    tlaReport: null,
    tlaReportStatus: null,
    tlaReportPageNumber: 1,

    oppositionReport: null,
    oppositionReportStatus: null,
    oppositionReportPageNumber: 1,

    customTrademarkReport: null,
    customTrademarkReportStatus: null,
    customTrademarkReportPageNumber: 1,

    internationalTrademarkReport: null,
    internationalTrademarkReportStatus: null,
    internationalTrademarkReportPageNumber: 1,

    patentCustomReport: null,
    patentCustomReportStatus: null,
    patentCustomReportPageNumber: 1,

    patentAutomatedReport: null,
    patentAutomatedReportStatus: null,
    patentAutomatedReportPageNumber: 1,

    customPatentReport: null,
    customPatentReportStatus: null,
    customPatentReportPageNumber: 1,

    internationalPatentReport: null,
    internationalPatentReportStatus: null,
    internationalPatentReportPageNumber: 1,

    downloadReportMsg: null,
    downloadReportStatus: null,

    examReport: [],
    examReportStatus: null,
    examReportPageNumber: null,
    report_columns: [],

    yourClassFilters: [],
    threatClassesFilters: [],
    yourProprietorsFilters: [],
    threatProprietorsFilters: [],
    yourTrademarkTypesFilters: [],
    threatTrademarkTypesFilters: [],

    yourClassFiltersCount: 0,
    threatClassesFiltersCount: 0,
    yourProprietorsFiltersCount: 0,
    threatProprietorsFiltersCount: 0,
    yourTrademarkTypesFiltersCount: 0,
    threatTrademarkTypesFiltersCount: 0,
    examReportFilterStatusCount: null,
  },
  action
) {
  switch (action.type) {
    case EXAM_REPORT:
      return {
        ...state,
        examReport: action.payload,
        examReportStatus: action.status,
        examReportPageNumber: action.pageNumber,
        report_columns: action.payload?.report_columns,
      };
    case EXAM_REPORT_YOUR_CLASSES_FILTERS:
      return {
        ...state,
        yourClassFilters: action.payload?.filters?.data || [],
        examReportFilterStatus: action.status,
        yourClassFiltersCount: action.payload?.filters?.count,
      };
    case EXAM_REPORT_YOUR_PROPRIETORS_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          yourProprietorsFilters: action.paginate
            ? [
                ...new Set([
                  ...state.yourProprietorsFilters,
                  ...action.payload?.filters?.data,
                ]),
              ]
            : action.payload?.filters?.data || [],
          examReportFilterStatus: action.status,
          yourProprietorsFiltersCount: action.payload?.filters?.count,
        };
      } else {
        return {
          ...state,
          examReportFilterStatus: action.status,
        };
      }

    case EXAM_REPORT_YOUR_TM_TYPE_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          yourTrademarkTypesFilters: action.paginate
            ? [
                ...new Set([
                  ...state.yourTrademarkTypesFilters,
                  ...action.payload?.filters?.data,
                ]),
              ]
            : action.payload?.filters?.data || [],
          examReportFilterStatus: action.status,
          yourTrademarkTypesFiltersCount: action.payload?.filters?.count,
        };
      } else {
        return {
          ...state,
          examReportFilterStatus: action.status,
        };
      }

    case EXAM_REPORT_THREAT_CLASSES_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          threatClassesFilters: action.payload?.filters?.data || [],
          examReportFilterStatus: action.status,
          threatClassesFiltersCount: action.payload?.filters?.count,
        };
      } else {
        return {
          ...state,
          examReportFilterStatus: action.status,
        };
      }

    case EXAM_REPORT_THREAT_PROPRIETORS_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          threatProprietorsFilters: action.paginate
            ? [
                ...new Set([
                  ...state.threatProprietorsFilters,
                  ...action.payload?.filters?.data,
                ]),
              ]
            : action.payload?.filters?.data || [],
          examReportFilterStatus: action.status,
          threatProprietorsFiltersCount: action.payload?.filters?.count,
        };
      } else {
        return {
          ...state,
          examReportFilterStatus: action.status,
        };
      }

    case EXAM_REPORT_THREAT_TM_TYPE_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          threatTrademarkTypesFilters: action.paginate
            ? [
                ...new Set([
                  ...state.threatTrademarkTypesFilters,
                  ...action.payload?.filters?.data,
                ]),
              ]
            : action.payload?.filters?.data || [],
          examReportFilterStatus: action.status,
          threatTrademarkTypesFiltersCount: action.payload?.filters?.count,
        };
      } else {
        return {
          ...state,
          examReportFilterStatus: action.status,
        };
      }

    case TRADEMARK_CUSTOM_REPORT:
      return {
        ...state,
        trademarkCustomReport: action.payload,
        trademarkCustomReportStatus: action.status,
        trademarkCustomReportPageNumber: action.pageNumber,
      };

    case TRADEMARK_AUTOMATED_REPORT:
      return {
        ...state,
        trademarkAutomatedReport: action.payload,
        trademarkAutomatedReportStatus: action.status,
        trademarkAutomatedReportPageNumber: action.pageNumber,
      };

    case TLA_REPORT:
      return {
        ...state,
        tlaReport: action.payload,
        tlaReportStatus: action.status,
        tlaReportPageNumber: action.pageNumber,
      };

    case OPPOSTION_REPORT:
      return {
        ...state,
        oppositionReport: action.payload,
        oppositionReportStatus: action.status,
        oppositionReportPageNumber: action.pageNumber,
      };

    case CUSTOM_TRADEMARK_REPORT:
      return {
        ...state,
        customTrademarkReport: action.payload,
        customTrademarkReportStatus: action.status,
        customTrademarkReportPageNumber: action.pageNumber,
      };

    case INTERNATIONAL_TRADEMARK_REPORT:
      return {
        ...state,
        internationalTrademarkReport: action.payload,
        internationalTrademarkReportStatus: action.status,
        internationalTrademarkReportPageNumber: action.pageNumber,
      };

    case PATENT_CUSTOM_REPORT:
      return {
        ...state,
        patentCustomReport: action.payload,
        patentCustomReportStatus: action.status,
        patentCustomReportPageNumber: action.pageNumber,
      };

    case PATENT_AUTOMATED_REPORT:
      return {
        ...state,
        patentAutomatedReport: action.payload,
        patentAutomatedReportStatus: action.status,
        patentAutomatedReportPageNumber: action.pageNumber,
      };

    case CUSTOM_PATENT_REPORT:
      return {
        ...state,
        customPatentReport: action.payload,
        customPatentReportStatus: action.status,
        customPatentReportPageNumber: action.pageNumber,
      };

    case INTERNATIONAL_PATENT_REPORT:
      return {
        ...state,
        internationalPatentReport: action.payload,
        internationalPatentReportStatus: action.status,
        internationalPatentReportPageNumber: action.pageNumber,
      };

    case MANAGER_DOWNLOAD_REPORT:
      return {
        ...state,
        downloadReportMsg: action.payload,
        downloadReportStatus: action.status,
      };

    default:
      return state;
  }
}
