import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Table,
  Tooltip,
  message,
  Checkbox,
  Input,
  Typography,
  Tabs,
  Menu,
  Dropdown,
  Select,
  Badge,
} from "antd";

//--------COMMON ACTIONS--------
import {
  logOut,
  getUserSubscriptions,
  getEmailTemplate,
  deleteEmailTemplate,
  getEmailTemplateVariables,
} from "../../redux/actions/commonActions/actions";
import {
  GET_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
} from "../../redux/actions/commonActions/types";
import { LOADING } from "../../constants/commonConstants/generalConstants";
import SampleTemplete from "../commonComponents/Modals/SampleTemplate";
import ViewEmailTemplete from "../commonComponents/Modals/ViewEmailTemplate";
import CreateTemplete from "../commonComponents/Modals/CreateTemplate";

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

class EmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: "MANAGER",
      contains_term: "",
      selectAll: false,
      indeterminate: false,
      add_template_id: [],
      pageNumber: 1,
      modalsVisibility: {
        sampleTemplate: false,
        createEmailTemplate: false,
        createTemplate: false,
        viewEmailTemplate: false,
        operation: "",
        templateContent: null,
      },
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      let product = "MANAGER";
      let productRoute = window.location.pathname?.split("/")[1];
      if (productRoute === "manager") product = "MANAGER";
      else if (productRoute === "search") product = "SEARCH";
      else if (productRoute === "watch") product = "WATCH";
      this.setState({ product: product });
      this.props.getEmailTemplate({
        actionType: GET_EMAIL_TEMPLATE,
        product: product,
      });
      this.props.getEmailTemplateVariables();
    } else {
      this.props.logOut();
    }
  }

  handleSelectTemplate(type, checked, data) {
    if (type === "All") {
      this.setState({
        add_template_id: checked
          ? this.props.emailTemplate &&
            this.props.emailTemplate.email_template.map((data) => data.id)
          : [],
        indeterminate: false,
        selectAll: checked,
      });
    } else if (type === "Single") {
      if (checked) {
        this.setState({
          add_template_id: [...this.state.add_template_id, data.id],
          indeterminate:
            !![...this.state.add_template_id, data.id].length &&
            [...this.state.add_template_id, data.id].length <
              (this.props.emailTemplate && this.props.emailTemplate.count),
          selectAll:
            [...this.state.add_template_id, data.id].length ===
            (this.props.emailTemplate && this.props.emailTemplate.count),
        });
      } else {
        this.setState({
          add_template_id: this.state.add_template_id.filter(
            (eachData) => eachData !== data.id,
          ),
          indeterminate:
            !!this.state.add_template_id.filter(
              (eachData) => eachData !== data.id,
            ).length &&
            this.state.add_template_id.filter(
              (eachData) => eachData !== data.id,
            ).length <
              (this.props.emailTemplate && this.props.emailTemplate.count),
          selectAll:
            this.state.add_template_id.filter(
              (eachData) => eachData !== data.id,
            ).length ===
            (this.props.emailTemplate && this.props.emailTemplate.count),
        });
      }
    }
  }

  async handleDeleteTemplate() {
    const { selectAll, add_template_id, product, contains_term } = this.state;
    await this.props.deleteEmailTemplate({
      actionType: DELETE_EMAIL_TEMPLATE,
      delete_all: selectAll,
      template_id: selectAll ? [] : add_template_id,
      product: product,
      contains_term: contains_term,
    });
    if (this.props.deleteEmailTemplateRequestStatus !== 400) {
      message[this.props.deleteEmailTemplateStatus.toLowerCase()](
        this.props.emailTemplateMessage,
      );
    }
    this.props.getEmailTemplate({
      actionType: GET_EMAIL_TEMPLATE,
      product: this.state.product,
    });
    this.setState({
      selectAll: false,
      indeterminate: false,
      add_template_id: [],
    });
  }

  handleEmailTemplate(modalsVisibility, modal, value, operation, data) {
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        operation: operation,
        templateContent: data,
      },
    });
  }

  handleNextpage = (pageNumber) => {
    this.props.getEmailTemplate({
      product: this.state.product,
      actionType: GET_EMAIL_TEMPLATE,
      page: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  handleSearchTemplate(searchValue) {
    this.props.getEmailTemplate({
      product: this.state.product,
      actionType: GET_EMAIL_TEMPLATE,
      contains_term: searchValue,
    });
  }
  handleTabChange = (product) => {
    this.setState({
      product: product,
      selectAll: false,
      indeterminate: false,
      add_template_id: [],
    });
    this.props.getEmailTemplate({
      actionType: GET_EMAIL_TEMPLATE,
      product: product,
    });
  };

  render() {
    const { indeterminate, selectAll, add_template_id, modalsVisibility } =
      this.state;
    const {
      emailTemplate,
      emailTemplateStatus,
      deleteEmailTemplateStatus,
      watchAlert,
      searchAlert,
      managerAlert,
    } = this.props;

    const emailTemplateColumns = [
      {
        title: (
          <Checkbox
            disabled={
              emailTemplate && emailTemplate?.email_template?.length === 0
            }
            indeterminate={indeterminate}
            checked={selectAll}
            onChange={(event) =>
              this.handleSelectTemplate("All", event.target.checked)
            }
          />
        ),
        width: "5%",
        align: "center",

        render: (record) => {
          return (
            record && (
              <div>
                <Checkbox
                  disabled={selectAll}
                  checked={add_template_id.includes(record.id)}
                  onChange={(event) =>
                    this.handleSelectTemplate(
                      "Single",
                      event.target.checked,
                      record,
                    )
                  }
                />
              </div>
            )
          );
        },
      },

      {
        title: <b>SUBJECT</b>,
        dataIndex: "subject",
        width: "20%",
      },

      {
        title: <b>CONTENT</b>,
        dataIndex: "content",
        width: "35%",
        render: (text) => (
          <div
            style={{
              height: "120px",
              overflow: "hidden",
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        ),
      },

      {
        title: <b>SUBSCRIPTION</b>,
        dataIndex: "subscription",
        width: "15%",
      },

      {
        title: <b>SUB-SECTION</b>,
        dataIndex: "sub_section",
        width: "15%",
      },

      {
        title: <b>ACTION</b>,
        width: "10%",
        align: "center",
        render: (record) => {
          return (
            record && (
              <div>
                <Tooltip placement="top" title="View email template">
                  <EyeOutlined
                    style={{
                      color: "#1890ff",
                      width: 32,
                      fontSize: 28,
                      marginRight: 8,
                    }}
                    onClick={() =>
                      this.handleEmailTemplate(
                        "modalsVisibility",
                        "createTemplate",
                        true,
                        "View",
                        record,
                      )
                    }
                  />
                </Tooltip>
                <Tooltip placement="top" title="Edit email template">
                  <EditOutlined
                    style={{ color: "#1890ff", width: 32, fontSize: 28 }}
                    onClick={() => {
                      this.handleEmailTemplate(
                        "modalsVisibility",
                        "createTemplate",
                        true,
                        "Edit",
                        record,
                      );
                      window.CKEDITOR.instances.editor?.setData(record.content);
                    }}
                  />
                </Tooltip>
              </div>
            )
          );
        },
      },
    ];

    const MANAGER = "MANAGER";
    const WATCH = "WATCH";
    const SEARCH = "SEARCH";

    let productList = this.props.productSubscription?.map((x) => x.value) || [];

    const toolBar = (
      <Row
        align="middle"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 16 }} className="alignL">
          <Menu
            selectedKeys={[
              this.state?.selectedFilters?.MANAGERClass.length > 0
                ? "classesMenu"
                : "",
              this.state?.selectedFilters?.MANAGERProprietors.length > 0
                ? "proprietorsMenu"
                : "",
            ]}
            className="filter-list"
            mode="horizontal"
          >
            <Menu.Item
              key="contentTerm"
              className="filter-list-item apply-filter"
            >
              <Input
                id="search_templates"
                placeholder="Search by Subject/Content"
                type="text"
                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                style={{ width: 360, height: 42 }}
                onChange={(event) => {
                  this.setState({ contains_term: event.target.value });
                  this.handleSearchTemplate(event.target.value);
                }}
              />
            </Menu.Item>
          </Menu>
        </Col>
        <Col className="alignR" style={{ display: "flex" }}>
          <>
            <Badge
              count={
                selectAll
                  ? "All"
                  : add_template_id.length > 0
                  ? add_template_id.length
                  : ""
              }
              style={{ margin: "5px" }}
            >
              <Button
                disabled={!(selectAll || add_template_id.length > 0)}
                icon={<DeleteOutlined />}
                style={{
                  margin: "0px 5px",
                  height: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FDE5E7",
                  color: "red",
                  borderColor: "red",
                }}
                danger
                onClick={() => this.handleDeleteTemplate()}
                loading={deleteEmailTemplateStatus === LOADING}
              >
                {/* <span style={{ marginLeft: "4px" }}> */}
                Delete Template
                {/* {selectAll
                    ? "(All)"
                    : add_template_id.length > 0
                    ? `(${add_template_id.length})`
                    : ""}
                </span> */}
              </Button>
            </Badge>
            <Button
              type="primary"
              style={{ margin: "0px 10px", height: "42px" }}
              onClick={() => {
                this.handleEmailTemplate(
                  "modalsVisibility",
                  "createTemplate",
                  true,
                  "Create",
                  null,
                );
                // window.CKEDITOR.instances.editor.setData('Note: Use "@" to get a dropdown of the variables you can use to create your template');
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PlusOutlined />
                <span style={{ marginLeft: "4px" }}>Create Template</span>
              </div>
            </Button>
          </>
        </Col>
      </Row>
    );

    const table = (
      <Row>
        <Col span={24}>
          <div className="m-top-20">
            <Table
              rowKey={(record) => record.id}
              dataSource={emailTemplate && emailTemplate.email_template}
              columns={emailTemplateColumns}
              pagination={{
                pageSize: 10,
                showQuickJumper: true,
                showSizeChanger: false,
                defaultCurrent: 1,
                total: emailTemplate && emailTemplate.count,
                onChange: this.handleNextpage,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} `,
              }}
              loading={emailTemplateStatus === LOADING}
              scroll={{ y: "calc(100vh - 360px)" }}
            />
          </div>
        </Col>
      </Row>
    );

    const tabContent = (
      <Content style={{ margin: "0px" }}>
        <div
          className="vertical_scroll"
          style={{
            padding: "16px 0px",
            background: "#ffffff",
            overflow: "auto",
          }}
        >
          {toolBar}
          {table}
        </div>
      </Content>
    );
    return (
      <div>
        <Layout
          style={{ background: "white", padding: "24px", height: "100vh" }}
        >
          <Content style={{ margin: "0px" }}>
            <Row className=" vertical_scroll">
              <Col xs={{ span: 12 }} lg={{ span: 22 }}>
                <Title level={3}>Email Templates</Title>
              </Col>
            </Row>
            <Row>
              <Tabs
                activeKey={this.state.product}
                onChange={this.handleTabChange}
                tabBarStyle={{ margin: "0px" }}
              >
                {productList && productList?.includes("MANAGER") && (
                  <TabPane
                    tab={"MikeIP Manager"}
                    key={MANAGER}
                    style={{ margin: "0px" }}
                  >
                    {tabContent}
                  </TabPane>
                )}
                {productList && productList?.includes("WATCH") && (
                  <TabPane
                    tab={"MikeTM Watch"}
                    key={WATCH}
                    style={{ margin: "0px" }}
                  >
                    {tabContent}
                  </TabPane>
                )}
                {productList && productList?.includes("SEARCH") && (
                  <TabPane
                    tab={"MikeIP Search"}
                    key={SEARCH}
                    style={{ margin: "0px" }}
                  >
                    {tabContent}
                  </TabPane>
                )}
              </Tabs>
            </Row>
          </Content>
        </Layout>

        <SampleTemplete
          visible={modalsVisibility.sampleTemplate}
          onCloseModal={() =>
            this.handleEmailTemplate(
              "modalsVisibility",
              "sampleTemplate",
              false,
              "",
              null,
            )
          }
          operation={modalsVisibility.operation}
        />

        <CreateTemplete
          visible={modalsVisibility.createTemplate}
          onCloseModal={() =>
            this.handleEmailTemplate(
              "modalsVisibility",
              "createTemplate",
              false,
              "",
              null,
            )
          }
          templateContent={modalsVisibility.templateContent}
          operation={modalsVisibility.operation}
          handleTabChange={(product) => this.handleTabChange(product)}
        />

        {modalsVisibility.viewEmailTemplate && (
          <ViewEmailTemplete
            visible={modalsVisibility.viewEmailTemplate}
            onCloseModal={() =>
              this.handleEmailTemplate(
                "modalsVisibility",
                "viewEmailTemplate",
                false,
                "",
                null,
              )
            }
            templateContent={modalsVisibility.templateContent}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getEmailTemplate,
  deleteEmailTemplate,
  getUserSubscriptions,
  logOut,
  getEmailTemplateVariables,
};

function mapStateToProps(state) {
  const {
    emailTemplate,
    emailTemplateStatus,
    emailTemplateMessage,
    deleteEmailTemplateStatus,
    deleteEmailTemplateRequestStatus,
  } = state.emailTemplateState;
  return deepFreeze({
    emailTemplate,
    emailTemplateStatus,
    emailTemplateMessage,
    deleteEmailTemplateStatus,
    deleteEmailTemplateRequestStatus,
    watchAlert: state.alertMessages.watch,
    searchAlert: state.alertMessages.search,
    managerAlert: state.alertMessages.manager,
    productSubscription: state.productSubscription.productSubscription,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
