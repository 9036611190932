import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";

import {
  Layout,
  Row,
  Col,
  Tabs,
  Card,
  Button,
  Typography,
  Table,
  Switch,
} from "antd";

//--------MANAGER ACTIONS--------
import { getProfileReport } from "../../../redux/actions/managerActions/report/actions";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { changeStateValue_L1 } from "../../../functions/commonFunctions/generalFunctions";

//--------COMMON CONSTANTS--------
import { LOADING } from "../../../constants/commonConstants/generalConstants";

import SendEmail from "../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../commonComponents/Modals/SendEmailNew";
import { logOut } from "../../../redux/actions/commonActions/actions";
import { getEmailTemplateData } from "../../../redux/actions/commonActions/actions";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Text } = Typography;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerType: true,
      hearingType: true,
      tmActiveTab: "trademark_custom_report",
      patentActiveTab: "patent_custom_report",
      modalsVisibility: {
        sendEmail: false,
        attachment: null,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.getProfileReport("trademark", "custom_report");
      this.props.getProfileReport("trademark", "automated_report");
      this.props.getProfileReport("trademark", "tla_report");
      this.props.getProfileReport("trademark", "opposition_report");
      this.props.getProfileReport("trademark", "custom_trademark_report");
      this.props.getProfileReport(
        "trademark",
        "international_trademark_report",
      );
      this.props.getProfileReport("patent", "custom_report");
      this.props.getProfileReport("patent", "automated_report");
      this.props.getProfileReport("patent", "custom_patent_report");
      this.props.getProfileReport("patent", "international_patent_report");
    } else {
      this.props.logOut();
    }
  }

  changeManagerType() {
    this.setState({
      managerType: !this.state.managerType,
    });
  }

  changeHearingReport() {
    this.setState({
      hearingType: !this.state.hearingType,
    });
  }

  downloadPDFReport(data) {
    window.open(data.pdf, "_blank");
  }

  downloadEXCELReport(data) {
    window.open(data.excel, "_blank");
  }

  downloadReport(data) {
    window.open(data?.file, "_blank");
  }

  handleNextpage = (pageNumber) => {
    const { managerType, tmActiveTab, patentActiveTab, hearingType } =
      this.state;
    const type = managerType ? "trademark" : "patent";
    let objectType;
    if (managerType) {
      if (tmActiveTab === "trademark_custom_report") {
        objectType = "custom_report";
      } else if (tmActiveTab === "trademark_automated_report") {
        objectType = "automated_report";
      } else if (tmActiveTab === "international_trademark_report") {
        objectType = "international_trademark_report";
      } else if (tmActiveTab === "custom_trademark_report") {
        objectType = "custom_trademark_report";
      } else if (hearingType) {
        objectType = "opposition_report";
      } else {
        objectType = "tla_report";
      }
    } else {
      if (patentActiveTab === "patent_custom_report") {
        objectType = "custom_report";
      } else if (patentActiveTab === "patent_automated_report") {
        objectType = "automated_report";
      } else if (patentActiveTab === "international_patent_report") {
        objectType = "international_patent_report";
      } else if (patentActiveTab === "custom_patent_report") {
        objectType = "custom_patent_report";
      }
    }
    this.props.getProfileReport(type, objectType, pageNumber);
    this.setState({
      pageNumber: pageNumber,
    });
  };

  async handleEmail(modalsVisibility, modal, value, url, data) {
    const { managerType, tmActiveTab, patentActiveTab, hearingType } =
      this.state;
    let title = checkValue(data.title) ? data.title : "";
    let location = checkValue(data.location) ? data.location : "";
    let sourceId = data.id;
    let mailContent;
    let subscription;
    let source;
    let sub_section;
    if (managerType) {
      if (tmActiveTab === "trademark_custom_report") {
        mailContent =
          "<p>Attached is the report for your portfolio marks currently being managed by us.</p>";
        subscription = ["TRADEMARK"];
        source = "TRADEMARK_CUSTOM_REPORT";
        sub_section = "CUSTOM REPORT";
      } else if (tmActiveTab === "trademark_automated_report") {
        mailContent =
          "<p>Attached is the report for your portfolio marks where we have tracked changes.</p>";
        subscription = ["TRADEMARK"];
        source = "TRADEMARK_DAILY_REPORT";
        sub_section = "AUTOMATED REPORT";
      } else if (tmActiveTab === "international_trademark_report") {
        mailContent =
          "<p>Attached is the report for your international marks currently being managed by us.</p>";
        subscription = ["INTERNATIONAL_TRADEMARK"];
        source = "INTERNATIONAL_TRADEMARK_REPORT";
        sub_section = "INTERNATIONAL TRADEMARK REPORT";
      } else if (tmActiveTab === "custom_trademark_report") {
        mailContent =
          "<p>Attached is the report for your marks currently being managed by us.</p>";
        subscription = ["CUSTOM_TRADEMARK"];
        source = "CUSTOM_TRADEMARK_REPORT";
        sub_section = "CUSTOM TRADEMARK REPORT";
      } else if (hearingType) {
        mailContent =
          "<p>Attached is the report mentioning the scheduled Opposition Hearing date and location:- <strong>" +
          location +
          "</strong>.</p>";
        subscription = ["TRADEMARK"];
        source = "TRADEMARK_OPPOSITION_HEARING_REPORT";
        sub_section = "OPPOSITION HEARING REPORT";
      } else {
        mailContent =
          "<p>Attached is the report mentioning the scheduled TLA Hearing date and location:- <strong>" +
          location +
          "</strong>.</p>";
        source = "TRADEMARK_TLA_HEARING_REPORT";
        sub_section = "TLA HEARING REPORT";
      }
    } else {
      if (patentActiveTab === "patent_custom_report") {
        mailContent =
          "<p>Attached is the report for your portfolio applications currently being managed by us.</p>";
        subscription = ["PATENT"];
        source = "PATENT_CUSTOM_REPORT";
        sub_section = "CUSTOM REPORT";
      } else if (patentActiveTab === "patent_automated_report") {
        mailContent =
          "<p>Attached is the report for your portfolio applications where we have tracked changes.</p>";
        subscription = ["PATENT"];
        source = "PATENT_DAILY_REPORT";
        sub_section = "AUTOMATED REPORT";
      } else if (patentActiveTab === "international_patent_report") {
        mailContent =
          "<p>Attached is the report for your international applications currently being managed by us.</p>";
        subscription = ["INTERNATIONAL_PATENT"];
        source = "INTERNATIONAL_PATENT_REPORT";
        sub_section = "CUSTOM PATENT REPORT";
      } else if (patentActiveTab === "custom_patent_report") {
        mailContent =
          "<p>Attached is the report for your applications currently being managed by us.</p>";
        subscription = ["CUSTOM_PATENT"];
        source = "CUSTOM_PATENT_REPORT";
        sub_section = "INTERNATIONAL PATENT REPORT";
      }
    }
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        attachment: url,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: subscription,
        sub_section: sub_section,
        report_tag: {
          report_name: title,
          journal_number: "",
          journal_date: "",
          status: "",
          renewal_date: "",
          application_number: "",
          date_of_application: "",
          class: "",
          date_of_usage: "",
          proprietor_name: "",
          tla_hearing_date: "",
          opposition_hearing_date: "",
          opponent_name: "",
        },
        sourceId: sourceId,
        source: source,
      },
    });
    if (value) {
      await this.props.getEmailTemplateData({
        subscription: this.state.managerType ? "TRADEMARK" : "PATENT",
        product: "MANAGER",
        object_id: data?.id,
        sub_section: sub_section,
      });
    }
  }

  render() {
    const { userSubscriptions, userDetails, userTeam } = this.props;

    const columnsCustomReport = [
      {
        title: "Report Title",
        dataIndex: "title",
        key: "custom_title",
        align: "left",
        width: "40%",
      },

      {
        title: "File Name",
        dataIndex: "file_name",
        key: "custom_file_name",
        align: "left",
        width: "25%",
      },

      {
        title: "Date",
        dataIndex: "modified",
        key: "custom_modified",
        align: "left",
        width: "15%",
      },

      {
        title: "Download",
        key: "download",
        align: "right",
        width: "10%",
        render: (text, custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                onClick={() => this.downloadReport(custom_record)}
                className={checkValue(custom_record.file) ? "" : "display_none"}
                style={{ width: "65px", height: "30px", margin: "0px 10px" }}
              >
                {custom_record.file_type === "PDF"
                  ? "PDF"
                  : custom_record.file_type === "EXCEL"
                    ? "EXCEL"
                    : ""}
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "right",
        width: "10%",
        render: (custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                className={checkValue(custom_record.file) ? "" : "display_none"}
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    [custom_record.file],
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    const columnsDailyReport = [
      {
        title: "Report Title",
        dataIndex: "title",
        key: "title",
        align: "left",
        width: "50%",
      },

      {
        title: "Date",
        dataIndex: "modified",
        key: "modified",
        align: "left",
        width: "20%",
      },

      {
        title: "Download",
        key: "id",
        align: "right",
        width: "20%",
        render: (text, record) => {
          return record ? (
            <span key={record.id}>
              <Button
                type="primary"
                ghost
                className={checkValue(record.pdf) ? "" : "display_none"}
                onClick={() => this.downloadPDFReport(record)}
                style={{ width: "65px", height: "30px", margin: "0px 10px" }}
              >
                PDF
              </Button>
              <Button
                type="primary"
                ghost
                className={checkValue(record.excel) ? "" : "display_none"}
                onClick={() => this.downloadEXCELReport(record)}
                style={{ width: "65px", height: "30px", margin: "0px 10px" }}
              >
                EXCEL
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "right",
        width: "10%",
        render: (custom_record) => {
          let emailFiles = [];
          if (checkValue(custom_record.pdf)) {
            emailFiles.push(custom_record.pdf);
          }
          if (checkValue(custom_record.excel)) {
            emailFiles.push(custom_record.excel);
          }
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                className={
                  checkValue(custom_record.pdf) ||
                  checkValue(custom_record.excel)
                    ? ""
                    : "display_none"
                }
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    emailFiles,
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    const columnsOppositionReport = [
      {
        title: "Report Title",
        dataIndex: "title",
        key: "custom_title",
        align: "left",
        width: "30%",
      },

      {
        title: "File Name",
        dataIndex: "file_name",
        key: "custom_file_name",
        align: "left",
        width: "20%",
      },

      {
        title: "Hearing Location",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: "15%",
      },

      {
        title: "Date",
        dataIndex: "modified",
        key: "custom_modified",
        align: "left",
        width: "15%",
      },

      {
        title: "Download",
        key: "custom_type",
        align: "right",
        width: "10%",
        render: (text, custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                onClick={() => this.downloadReport(custom_record)}
                className={checkValue(custom_record.file) ? "" : "display_none"}
                style={{ width: "65px", height: "30px", margin: "0px 10px" }}
              >
                {custom_record.file_type === "PDF"
                  ? "PDF"
                  : custom_record.file_type === "EXCEL"
                    ? "EXCEL"
                    : ""}
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "right",
        width: "10%",
        render: (custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                className={checkValue(custom_record.file) ? "" : "display_none"}
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    [custom_record.file],
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    const columnsTLAReport = [
      {
        title: "Report Title",
        dataIndex: "title",
        key: "custom_title",
        align: "left",
        width: "30%",
      },

      {
        title: "File Name",
        dataIndex: "file_name",
        key: "custom_file_name",
        align: "left",
        width: "20%",
      },

      {
        title: "Hearing Location",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: "15%",
      },

      {
        title: "Date",
        dataIndex: "modified",
        key: "custom_modified",
        align: "left",
        width: "15%",
      },

      {
        title: "Download",
        key: "custom_type",
        align: "right",
        width: "10%",
        render: (text, custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                onClick={() => this.downloadReport(custom_record)}
                className={checkValue(custom_record.file) ? "" : "display_none"}
                style={{ width: "65px", height: "30px", margin: "0px 10px" }}
              >
                {custom_record.file_type === "PDF"
                  ? "PDF"
                  : custom_record.file_type === "EXCEL"
                    ? "EXCEL"
                    : ""}
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "right",
        width: "10%",
        render: (custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                className={checkValue(custom_record.file) ? "" : "display_none"}
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    [custom_record.file],
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    const columnsCustomTrademarkReport = [
      {
        title: "Report Title",
        dataIndex: "title",
        key: "custom_title",
        align: "left",
        width: "40%",
      },

      {
        title: "File Name",
        dataIndex: "file_name",
        key: "custom_file_name",
        align: "left",
        width: "25%",
      },

      {
        title: "Date",
        dataIndex: "modified",
        key: "custom_modified",
        align: "left",
        width: "15%",
      },

      {
        title: "Download",
        key: "download",
        align: "right",
        width: "10%",
        render: (text, custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                onClick={() => this.downloadReport(custom_record)}
                className={checkValue(custom_record.file) ? "" : "display_none"}
                style={{ width: "65px", height: "30px", margin: "0px 10px" }}
              >
                {custom_record.file_type === "PDF"
                  ? "PDF"
                  : custom_record.file_type === "EXCEL"
                    ? "EXCEL"
                    : ""}
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "right",
        width: "10%",
        render: (custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                className={checkValue(custom_record.file) ? "" : "display_none"}
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    [custom_record.file],
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    const columnsInternationalTrademarkReport = [
      {
        title: "Report Title",
        dataIndex: "title",
        key: "custom_title",
        align: "left",
        width: "40%",
      },

      {
        title: "File Name",
        dataIndex: "file_name",
        key: "custom_file_name",
        align: "left",
        width: "25%",
      },

      {
        title: "Date",
        dataIndex: "modified",
        key: "custom_modified",
        align: "left",
        width: "15%",
      },

      {
        title: "Download",
        key: "download",
        align: "right",
        width: "10%",
        render: (text, custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                onClick={() => this.downloadReport(custom_record)}
                className={checkValue(custom_record.file) ? "" : "display_none"}
                style={{ width: "65px", height: "30px", margin: "0px 10px" }}
              >
                {custom_record.file_type === "PDF"
                  ? "PDF"
                  : custom_record.file_type === "EXCEL"
                    ? "EXCEL"
                    : ""}
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Send Mail",
        key: "send",
        align: "right",
        width: "10%",
        render: (custom_record) => {
          return custom_record ? (
            <span key={custom_record.id}>
              <Button
                type="primary"
                ghost
                className={checkValue(custom_record.file) ? "" : "display_none"}
                onClick={() =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    [custom_record.file],
                    custom_record,
                  )
                }
              >
                Send
              </Button>
            </span>
          ) : (
            ""
          );
        },
      },
    ];

    return (
      <div>
        <Layout className="Report">
          <Layout>
            <Content style={{ margin: "0px" }}>
              <Row
                className="content-container vertical_scroll"
                style={{
                  height: `${
                    this.props.managerAlert.length > 0
                      ? "calc(100vh - 40px)"
                      : "calc(100vh - 0px)"
                  }`,
                }}
              >
                <Col span={24}>
                  <Row>
                    {userSubscriptions &&
                      userSubscriptions.manager.includes("TRADEMARK") &&
                      userSubscriptions.manager.includes("PATENT") && (
                        <Col span={24}>
                          <Text strong>TRADEMARK</Text>
                          <Switch
                            className="change-manager-type"
                            onChange={() => this.changeManagerType()}
                          />
                          <Text strong>PATENT</Text>
                        </Col>
                      )}
                  </Row>

                  {/* trademark-report */}
                  <Row>
                    <Col
                      span={24}
                      className={
                        userSubscriptions &&
                        userSubscriptions.manager.includes("TRADEMARK") &&
                        userSubscriptions.manager.includes("PATENT")
                          ? this.state.managerType
                            ? "m-top-20"
                            : "display_none"
                          : userSubscriptions &&
                              userSubscriptions.manager.includes("TRADEMARK")
                            ? "m-top-20"
                            : "display_none"
                      }
                    >
                      <Tabs
                        type="card"
                        activeKey={this.state.tmActiveTab}
                        onChange={(activeKey) =>
                          this.setState({ tmActiveTab: activeKey })
                        }
                      >
                        <TabPane
                          tab="MANAGEMENT CUSTOM REPORTS"
                          key="trademark_custom_report"
                        >
                          <Card title="MANAGEMENT CUSTOM REPORTS">
                            <Table
                              rowKey={(record) => record.id}
                              columns={columnsCustomReport}
                              bordered
                              dataSource={
                                this.props.trademarkCustomReport &&
                                this.props.trademarkCustomReport.reports
                              }
                              pagination={{
                                pageSize: 100,
                                showQuickJumper: true,
                                showSizeChanger: false,
                                defaultCurrent: 1,
                                total:
                                  this.props.trademarkCustomReport &&
                                  this.props.trademarkCustomReport.count,
                                onChange: this.handleNextpage,
                              }}
                              scroll={{ y: "calc(70vh - 170px)" }}
                              loading={
                                this.props.trademarkCustomReportStatus ===
                                LOADING
                                  ? true
                                  : false
                              }
                            />
                          </Card>
                        </TabPane>
                        <TabPane
                          tab="WEEKLY REPORTS"
                          key="trademark_automated_report"
                        >
                          <Card title="WEEKLY REPORTS">
                            <Table
                              rowKey={(record) => record.id}
                              columns={columnsDailyReport}
                              bordered
                              dataSource={
                                this.props.trademarkAutomatedReport &&
                                this.props.trademarkAutomatedReport.reports
                              }
                              pagination={{
                                pageSize: 100,
                                showQuickJumper: true,
                                showSizeChanger: false,
                                defaultCurrent: 1,
                                total:
                                  this.props.trademarkAutomatedReport &&
                                  this.props.trademarkAutomatedReport.count,
                                onChange: this.handleNextpage,
                              }}
                              scroll={{ y: "calc(70vh - 170px)" }}
                              loading={
                                this.props.trademarkAutomatedReportStatus ===
                                LOADING
                                  ? true
                                  : false
                              }
                            />
                          </Card>
                        </TabPane>
                        <TabPane tab="HEARING REPORTS" key="hearing_report">
                          <Card
                            title={
                              <div>
                                <Row>
                                  <Col
                                    span={24}
                                    className="alignL"
                                    style={{ marginBottom: "12px" }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "red",
                                      }}
                                    >
                                      *From 1st May 2022 all reports will be
                                      available at Management Custom Reports Tab
                                    </Text>
                                  </Col>
                                  <Col span={12} className="alignL">
                                    <Text
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {this.state.hearingType
                                        ? "OPPOSITION HEARING REPORTS"
                                        : "TLA HEARING REPORTS"}
                                    </Text>
                                  </Col>
                                  <Col span={12} className="alignR">
                                    <Text strong>OPPOSITION HEARING</Text>
                                    <Switch
                                      className="change-manager-type"
                                      onChange={() =>
                                        this.changeHearingReport()
                                      }
                                    />
                                    <Text strong>TLA HEARING</Text>
                                  </Col>
                                </Row>
                              </div>
                            }
                          >
                            <div
                              className={
                                this.state.hearingType ? "" : "display_none"
                              }
                            >
                              <Table
                                rowKey={(record) => record.id}
                                columns={columnsOppositionReport}
                                bordered
                                dataSource={
                                  this.props.oppositionReport &&
                                  this.props.oppositionReport.reports
                                }
                                pagination={{
                                  pageSize: 100,
                                  showQuickJumper: true,
                                  showSizeChanger: false,
                                  defaultCurrent: 1,
                                  total:
                                    this.props.oppositionReport &&
                                    this.props.oppositionReport.count,
                                  onChange: this.handleNextpage,
                                }}
                                scroll={{ y: "calc(70vh - 170px)" }}
                                loading={
                                  this.props.oppositionReportStatus === LOADING
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div
                              className={
                                this.state.hearingType ? "display_none" : ""
                              }
                            >
                              <Table
                                rowKey={(record) => record.id}
                                columns={columnsTLAReport}
                                bordered
                                dataSource={
                                  this.props.tlaReport &&
                                  this.props.tlaReport.reports
                                }
                                pagination={{
                                  pageSize: 100,
                                  showQuickJumper: true,
                                  showSizeChanger: false,
                                  defaultCurrent: 1,
                                  total:
                                    this.props.tlaReport &&
                                    this.props.tlaReport.count,
                                  onChange: this.handleNextpage,
                                }}
                                scroll={{ y: "calc(70vh - 170px)" }}
                                loading={
                                  this.props.tlaReportStatus === LOADING
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Card>
                        </TabPane>
                        {userSubscriptions &&
                          userSubscriptions.manager.includes(
                            "INTERNATIONAL_TRADEMARK",
                          ) && (
                            <TabPane
                              tab="INTERNATIONAL TM REPORTS"
                              key="international_trademark_report"
                            >
                              <Card title="INTERNATIONAL TM REPORTS">
                                <Table
                                  rowKey={(record) => record.id}
                                  columns={columnsInternationalTrademarkReport}
                                  bordered
                                  dataSource={
                                    this.props.internationalTrademarkReport &&
                                    this.props.internationalTrademarkReport
                                      .reports
                                  }
                                  pagination={{
                                    pageSize: 100,
                                    showQuickJumper: true,
                                    showSizeChanger: false,
                                    defaultCurrent: 1,
                                    total:
                                      this.props.internationalTrademarkReport &&
                                      this.props.internationalTrademarkReport
                                        .count,
                                    onChange: this.handleNextpage,
                                  }}
                                  scroll={{ y: "calc(70vh - 170px)" }}
                                  loading={
                                    this.props
                                      .internationalTrademarkReportStatus ===
                                    LOADING
                                      ? true
                                      : false
                                  }
                                />
                              </Card>
                            </TabPane>
                          )}
                        {userSubscriptions &&
                          userSubscriptions.manager.includes(
                            "CUSTOM_TRADEMARK",
                          ) && (
                            <TabPane
                              tab="CUSTOM TM REPORTS"
                              key="custom_trademark_report"
                            >
                              <Card title="CUSTOM TM REPORTS">
                                <Table
                                  rowKey={(record) => record.id}
                                  columns={columnsCustomTrademarkReport}
                                  bordered
                                  dataSource={
                                    this.props.customTrademarkReport &&
                                    this.props.customTrademarkReport.reports
                                  }
                                  pagination={{
                                    pageSize: 100,
                                    showQuickJumper: true,
                                    showSizeChanger: false,
                                    defaultCurrent: 1,
                                    total:
                                      this.props.customTrademarkReport &&
                                      this.props.customTrademarkReport.count,
                                    onChange: this.handleNextpage,
                                  }}
                                  scroll={{ y: "calc(70vh - 170px)" }}
                                  loading={
                                    this.props.customTrademarkReportStatus ===
                                    LOADING
                                      ? true
                                      : false
                                  }
                                />
                              </Card>
                            </TabPane>
                          )}
                      </Tabs>
                    </Col>
                  </Row>
                  {/* trademark-report */}

                  {/* patent-report */}
                  <Row>
                    <Col
                      span={24}
                      className={
                        userSubscriptions &&
                        userSubscriptions.manager.includes("TRADEMARK") &&
                        userSubscriptions.manager.includes("PATENT")
                          ? this.state.managerType
                            ? "display_none"
                            : "m-top-20"
                          : userSubscriptions &&
                              userSubscriptions.manager.includes("PATENT")
                            ? "m-top-20"
                            : "display_none"
                      }
                    >
                      <Tabs
                        type="card"
                        activeKey={this.state.patentActiveTab}
                        onChange={(activeKey) =>
                          this.setState({ patentActiveTab: activeKey })
                        }
                      >
                        <TabPane
                          tab="MANAGEMENT CUSTOM REPORTS"
                          key="patent_custom_report"
                        >
                          <Card title="MANAGEMENT CUSTOM REPORTS">
                            <Table
                              rowKey={(record) => record.id}
                              columns={columnsCustomReport}
                              bordered
                              dataSource={
                                this.props.patentCustomReport &&
                                this.props.patentCustomReport.reports
                              }
                              pagination={{
                                pageSize: 100,
                                showQuickJumper: true,
                                showSizeChanger: false,
                                defaultCurrent: 1,
                                total:
                                  this.props.patentCustomReport &&
                                  this.props.patentCustomReport.count,
                                onChange: this.handleNextpage,
                              }}
                              scroll={{ y: "calc(70vh - 170px)" }}
                              loading={
                                this.props.patentCustomReportStatus === LOADING
                                  ? true
                                  : false
                              }
                            />
                          </Card>
                        </TabPane>
                        <TabPane
                          tab="WEEKLY REPORTS"
                          key="patent_automated_report"
                        >
                          <Card title="WEEKLY REPORTS">
                            <Table
                              rowKey={(record) => record.id}
                              columns={columnsDailyReport}
                              bordered
                              dataSource={
                                this.props.patentAutomatedReport &&
                                this.props.patentAutomatedReport.reports
                              }
                              pagination={{
                                pageSize: 100,
                                showQuickJumper: true,
                                showSizeChanger: false,
                                defaultCurrent: 1,
                                total:
                                  this.props.patentAutomatedReport &&
                                  this.props.patentAutomatedReport.count,
                                onChange: this.handleNextpage,
                              }}
                              scroll={{ y: "calc(70vh - 170px)" }}
                              loading={
                                this.props.patentAutomatedReportStatus ===
                                LOADING
                                  ? true
                                  : false
                              }
                            />
                          </Card>
                        </TabPane>
                        {userSubscriptions &&
                          userSubscriptions.manager.includes(
                            "INTERNATIONAL_PATENT",
                          ) && (
                            <TabPane
                              tab="INTERNATIONAL PATENT REPORTS"
                              key="international_patent_report"
                            >
                              <Card title="INTERNATIONAL PATENT REPORTS">
                                <Table
                                  rowKey={(record) => record.id}
                                  columns={columnsInternationalTrademarkReport}
                                  bordered
                                  dataSource={
                                    this.props.internationalPatentReport &&
                                    this.props.internationalPatentReport.reports
                                  }
                                  pagination={{
                                    pageSize: 100,
                                    showQuickJumper: true,
                                    showSizeChanger: false,
                                    defaultCurrent: 1,
                                    total:
                                      this.props.internationalPatentReport &&
                                      this.props.internationalPatentReport
                                        .count,
                                    onChange: this.handleNextpage,
                                  }}
                                  scroll={{ y: "calc(70vh - 170px)" }}
                                  loading={
                                    this.props
                                      .internationalPatentReportStatus ===
                                    LOADING
                                      ? true
                                      : false
                                  }
                                />
                              </Card>
                            </TabPane>
                          )}
                        {userSubscriptions &&
                          userSubscriptions.manager.includes(
                            "CUSTOM_PATENT",
                          ) && (
                            <TabPane
                              tab="CUSTOM PATENT REPORTS"
                              key="custom_patent_report"
                            >
                              <Card title="CUSTOM PATENT REPORTS">
                                <Table
                                  rowKey={(record) => record.id}
                                  columns={columnsCustomTrademarkReport}
                                  bordered
                                  dataSource={
                                    this.props.customPatentReport &&
                                    this.props.customPatentReport.reports
                                  }
                                  pagination={{
                                    pageSize: 100,
                                    showQuickJumper: true,
                                    showSizeChanger: false,
                                    defaultCurrent: 1,
                                    total:
                                      this.props.customPatentReport &&
                                      this.props.customPatentReport.count,
                                    onChange: this.handleNextpage,
                                  }}
                                  scroll={{ y: "calc(70vh - 170px)" }}
                                  loading={
                                    this.props.customPatentReportStatus ===
                                    LOADING
                                      ? true
                                      : false
                                  }
                                />
                              </Card>
                            </TabPane>
                          )}
                      </Tabs>
                    </Col>
                  </Row>
                  {/* patent-report */}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, [], {})
          }
          sendFrom={userDetails && userDetails.communication_email}
          sendCC={userTeam && userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          attachment={this.state.modalsVisibility.attachment}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          report_flag={"true"}
          sourceId={this.state.modalsVisibility.sourceId}
          source={this.state.modalsVisibility.source}
          managerEmail={true}
          sub_section={this.state.modalsVisibility.sub_section}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getProfileReport,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const {
    trademarkCustomReport,
    trademarkCustomReportStatus,
    trademarkCustomReportPageNumber,

    trademarkAutomatedReport,
    trademarkAutomatedReportStatus,
    trademarkAutomatedReportPageNumber,

    tlaReport,
    tlaReportStatus,
    tlaReportPageNumber,

    oppositionReport,
    oppositionReportStatus,
    oppositionReportPageNumber,

    customTrademarkReport,
    customTrademarkReportStatus,
    customTrademarkReportPageNumber,

    internationalTrademarkReport,
    internationalTrademarkReportStatus,
    internationalTrademarkReportPageNumber,

    patentCustomReport,
    patentCustomReportStatus,
    patentCustomReportPageNumber,

    patentAutomatedReport,
    patentAutomatedReportStatus,
    patentAutomatedReportPageNumber,

    customPatentReport,
    customPatentReportStatus,
    customPatentReportPageNumber,

    internationalPatentReport,
    internationalPatentReportStatus,
    internationalPatentReportPageNumber,
  } = state.managerState.reportState;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    trademarkCustomReport,
    trademarkCustomReportStatus,
    trademarkCustomReportPageNumber,

    trademarkAutomatedReport,
    trademarkAutomatedReportStatus,
    trademarkAutomatedReportPageNumber,

    tlaReport,
    tlaReportStatus,
    tlaReportPageNumber,

    oppositionReport,
    oppositionReportStatus,
    oppositionReportPageNumber,

    customTrademarkReport,
    customTrademarkReportStatus,
    customTrademarkReportPageNumber,

    internationalTrademarkReport,
    internationalTrademarkReportStatus,
    internationalTrademarkReportPageNumber,

    patentCustomReport,
    patentCustomReportStatus,
    patentCustomReportPageNumber,

    patentAutomatedReport,
    patentAutomatedReportStatus,
    patentAutomatedReportPageNumber,

    customPatentReport,
    customPatentReportStatus,
    customPatentReportPageNumber,

    internationalPatentReport,
    internationalPatentReportStatus,
    internationalPatentReportPageNumber,

    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
