import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification,
  Collapse,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateClientContactData,
  updateSingleClientContactData,
} from "./../../../../../redux/actions/commonActions/masterActions/clientContact";

const { Text } = Typography;
const { Panel } = Collapse;

const ClientContactEditComponent = ({ open, setOpen, client_id }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [warningList, setWarningList] = useState([]);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const { metadata } = useSelector(
    (state) => state.masters.clientMaster.clientContact,
  );
  const { pageNumber } = metadata;

  const singleClientContact = useSelector(
    (state) => state.masters.clientMaster.singleClientContact,
  );

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    dispatch(updateSingleClientContactData({}));
  };

  useEffect(() => {
    if (singleClientContact) {
      form.setFieldsValue(singleClientContact?.data);
    }
  }, [form, singleClientContact]);

  const handleSubmit = (values) => {
    setLoading(true);
    const updatedData = {
      ...values,
      client_id: client_id ?? "",
      id: singleClientContact?.data?.id ?? "",
      force_update: isForceUpdate,
    };
    dispatch(
      updateClientContactData(updatedData, pageNumber, {
        onSuccess: (successResponse) => {
          notification["success"]({
            message:
              successResponse?.message ?? "Contact Updated Successfully!",
          });
          setLoading(false);
          onClose();
        },
        onError: (errorResponse) => {
          setLoading(false);
          notification["error"]({
            message: errorResponse?.message ?? "Something went wrong!",
          });
          if (errorResponse?.payload?.warning_list?.length) {
            setIsForceUpdate(true);
            setWarningList(errorResponse?.payload?.warning_list);
          }
        },
      }),
    );
  };

  const renderLabel = (text) => (
    <Text strong style={{ margin: 0, padding: 0 }}>
      {text}
    </Text>
  );

  const warningDescription = (
    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
      <ul>
        {warningList.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );

  const customWarningPanelStyle = {
    backgroundColor: "#FFF6E6",
    border: "1px solid #FFA700",
  };

  const customWarningHeaderStyle = {
    color: "#B57701",
  };

  const warningArea = (
    <div style={{ margin: "10px 0px" }}>
      <Collapse
        defaultActiveKey={warningList?.length ? "1" : "0"}
        expandIconPosition={"start"}
      >
        <Panel
          header={
            <div style={customWarningHeaderStyle}>
              Warnings in current excel.
            </div>
          }
          key="1"
          extra={
            <div style={customWarningHeaderStyle}>
              {warningList?.length ?? 0}{" "}
            </div>
          }
          style={customWarningPanelStyle}
        >
          {warningDescription}
        </Panel>
      </Collapse>
    </div>
  );

  const handleValueChange = () => {
    setIsForceUpdate(false);
  };

  return (
    <Modal
      title={"Edit Client Contact"}
      centered
      open={open}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          loading={loading}
          disabled={loading}
          type="default"
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={loading}
          disabled={loading}
          type="primary"
          onClick={() => form.submit()}
        >
          {"Update"}
        </Button>,
      ]}
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ margin: "15px 10px" }}
        onValuesChange={handleValueChange}
        onFinish={handleSubmit}
        initialValues={singleClientContact ?? {}}
      >
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col span={24}>
            <Form.Item
              label={renderLabel("Contact Person Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Contact Person Name!",
                },
              ]}
            >
              <Input placeholder="Enter Contact Person Name" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label={renderLabel("Email ID")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please Enter Valid Email ID!",
                },
              ]}
            >
              <Input placeholder="Enter Email ID" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label={renderLabel("Phone Number")}
              name="phone"
              rules={[
                {
                  max: 15,
                  message: "Maximum limit exceeded.",
                },
              ]}
            >
              <Input
                placeholder="Enter Phone Number"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>{warningList?.length ? warningArea : null}</Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ClientContactEditComponent;
