//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from './checkValueFunctions';

//--------GENERAL CONSTANTS--------
import { MONTHS_NAMES, SHORT_MONTHS_NAMES } from '../../constants/commonConstants/generalConstants';
import moment from 'moment';

// --------DATE FORMATION-------- dd month, yyyy | hh:mm
export function standardizeDate(dateString) {
    const dateObject = new Date(dateString);
    return (`${dateObject.getDate()} ${MONTHS_NAMES[dateObject.getMonth()]}, ${dateObject.getFullYear()} | ${dateObject.getHours()}:${dateObject.getMinutes()}`);
}

// --------DATE FORMATION-------- dd/mm/yyyy
export function dateFormat(dateString, splitter = '/', dateType = "" ) {
    let dateObject = null;
    try {
        dateObject = new Date(dateString);
    } catch (error) {
        return -1;
    }
    switch (dateType) {
        case "ddmonthyyyy":
            return (`${dateObject.getDate()}${splitter}${MONTHS_NAMES[dateObject.getMonth()]}${splitter}${dateObject.getFullYear()}`);

        case "ddmonyyyy":
            return (`${dateObject.getDate()}${splitter}${SHORT_MONTHS_NAMES[dateObject.getMonth()]}${splitter}${dateObject.getFullYear()}`);

        case "ddmon":
            return (`${dateObject.getDate()}${splitter}${SHORT_MONTHS_NAMES[dateObject.getMonth()]}`);

        default:
            return (`${dateObject.getDate()}${splitter}${(dateObject.getMonth() + 1)}${splitter}${dateObject.getFullYear()}`);
    }
}

//set Max date (disabled future date)
export function disabledFutureDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd
    }
    if (mm < 10) {
        mm = '0' + mm
    }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
}

//date format
export function changeCustomDateToCalendar(date) {
    var dateArray = [];
    var convertedDate = "";
    if (checkValue(date)) {
        dateArray = date.split('-');
        convertedDate = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];   //15-08-2018
    }
    return convertedDate;
}

//dateTime format 28 March, 2020-11:58 PM
export function standardizeDateTime(dateString){
    const dateObject = new Date(dateString);
    return (moment(dateObject).format('DD MMMM, YYYY-h:mm A'));
}