import React, { Component } from "react";
import { connect } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Input,
  Button,
  Typography,
  Menu,
  Collapse,
  Pagination,
  Spin,
} from "antd";

import { getEmailTemplate } from "../../../redux/actions/commonActions/actions";
import { SUCCESS } from "../../../constants/commonConstants/generalConstants";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { changeStateValue_L0 } from "../../../functions/commonFunctions/generalFunctions";

// const { Option } = AutoComplete;
const { Text } = Typography;

const { Panel } = Collapse;

class EmailTemplateHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application_number: "",
      file_name: "",
      downloadContent: false,
      letter_head_visible: true,
      add_journal_copy: false,
      open: false,
      selectValue: "",
      searchValue: {
        send_to: "",
        cc: "",
      },
      send_from: "",
      send_to: "",
      cc: "",
      subject: "",
      tags: {
        send_to: [],
        cc: [],
      },
      mail_body: "",
      page: {
        send_to: 1,
        cc: 1,
      },
      send_from_input_error: false,
      send_to_input_error: false,
      cc_input_error: false,
      subject_input_error: false,
      selectedKeys: [],
      selectedKeys1: [],
      attachment: [],
      report_tag: {},
      file_attachment: {
        file: "",
        fileName: "",
        filePreviewUrl: "",
      },
      multiple_files: [],
      select_doc_link: [],
      send_from_error: false,
      send_to_error: false,
      subject_error: false,
    };
    this.autoRef = React.createRef();
    this.deletePrevious = React.createRef(false);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  handleCollapse = (key) => {
    this.setState({ activeKey: key });
  };

  render() {
    const {
      handleSelectTemplate,
      emailTemplate,
      emailTemplateStatus,
      handleSearchTemplate,
      handlePaginateTemplate,
      count,
      handleSelected,
      handleCollapse,
    } = this.props;

    let emailTemplateMenu = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            {emailTemplateStatus === "LOADING" ? (
              <Spin />
            ) : (
              <Text strong>No Email Template Found</Text>
            )}
          </div>
        </Menu.Item>
      </Menu>
    );

    if (emailTemplateStatus === SUCCESS) {
      if (emailTemplate && emailTemplate.email_template.length > 0) {
        emailTemplateMenu = (
          <Collapse
            accordion
            expandIconPosition={"left"}
            activeKey={this.props.activeKey}
            onChange={(key) => handleCollapse(key)}
          >
            {[...emailTemplate.email_template].map((data) => {
              return (
                <Panel
                  header={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{ width: "75%" }}
                      >{`Subject: ${data.subject}`}</div>
                      <Button
                        size="small"
                        onClick={(event) => {
                          handleSelectTemplate(data);
                          handleSelected(`select-template-id-${data.id}`);
                          // // If you don't want click extra trigger collapse, you can prevent this:
                          event.stopPropagation();
                        }}
                        type={
                          this.props.selectedKey ===
                          `select-template-id-${data.id}`
                            ? "primary"
                            : "default"
                        }
                      >
                        Use
                      </Button>
                    </div>
                  }
                  key={`select-template-id-${data.id}`}
                >
                  <div
                    id={`select-template-id-data-${data.id}`}
                    dangerouslySetInnerHTML={{
                      __html: data.content,
                    }}
                    style={{
                      overflow: "auto",
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        );
      }
    }

    return (
      <>
        <Row
          className="vertical_scroll"
          style={{
            border: "1px solid #d3d3d3",
            borderRadius: "10px",
            padding: "8px",
          }}
        >
          <Col span={24}>
            <Input
              id="search_templates"
              placeholder="Search Templates"
              type="text"
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={(event) => handleSearchTemplate(event.target.value)}
            />
          </Col>
          <Col
            span={24}
            style={{
              // maxHeight:'70vh',
              // minHeight:'70vh',
              height: "60vh",

              overflow: "auto",
            }}
          >
            {emailTemplateMenu}
          </Col>
          <Col
            span={24}
            style={{
              textAlign: "center",
              margin: "8px",
            }}
          >
            <Pagination
              onChange={(page) => handlePaginateTemplate(page)}
              total={count}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = {
  getEmailTemplate,
};

function mapStateToProps(state, ownProps) {
  const { emailTemplate, emailTemplateStatus } = state.emailTemplateState;

  return {
    emailTemplate,
    emailTemplateStatus,
    ownProps,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailTemplateHandler);
