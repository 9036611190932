//--------COMMON TYPES--------
export const SRCH_GET_USER_TMS = "SRCH_GET_USER_TMS";
export const SET_SEARCH_INFO = "SET_SEARCH_INFO";
export const SET_SEARCH_PATH = "SET_SEARCH_PATH";

//--------TRADEMARKS SEARCH--------
export const TS_GET_SEARCH_RESULTS = "TS_GET_SEARCH_RESULTS";
export const TS_GET_SEARCH_FILTERS = "TS_GET_SEARCH_FILTERS";
export const TS_GET_SEARCH_ANALYSIS = "TS_GET_SEARCH_ANALYSIS";
export const TS_SELECT_VIEW = "TS_SELECT_VIEW";
export const CHANGE_SEARCH_RESULTS_TAB = "CHANGE_SEARCH_RESULTS_TAB";
export const CHANGE_PROP_RESULTS_TAB = "CHANGE_PROP_RESULTS_TAB";
export const TS_GET_REPORT_AVAILABILITY = "TS_GET_REPORT_AVAILABILITY";

export const TS_SET_SEARCHES_CANCEL = "TS_SET_SEARCHES_CANCEL";
export const TS_SET_ANALYSIS_CANCEL = "TS_SET_ANALYSIS_CANCEL";
export const TS_SET_FILTERS_CANCEL = "TS_SET_FILTERS_CANCEL";

export const TS_SET_REMINDER_STAMP = "TS_SET_REMINDER_STAMP";
export const TS_SET_STATE = "TS_SET_STATE";
export const TS_SET_DEFAULT_STATE = "TS_SET_DEFAULT_STATE";
export const SET_FILE = "SET_FILE";

//--------TRADEMARK PROFILE--------
export const TP_GET_TRADEMARK_PROFILE = "TP_GET_TRADEMARK_PROFILE";

//--------PROPRIETORS SEARCH--------
export const PS_GET_SEARCH_RESULTS = "PS_GET_SEARCH_RESULTS";
export const PS_GET_PROPRIETOR_MARKS = "PS_GET_PROPRIETOR_MARKS";
export const PS_GET_PROPRIETOR_DATA = "PS_GET_PROPRIETOR_DATA";
export const PS_GET_PROPRIETOR_ANALYSIS = "PS_GET_PROPRIETOR_ANALYSIS";
export const PS_SELECT_VIEW = "PS_SELECT_VIEW";

export const PP_GET_PROPRIETOR_MARKS = "PP_GET_PROPRIETOR_MARKS";
export const PP_GET_PROPRIETOR_DATA = "PP_GET_PROPRIETOR_DATA";
export const PP_GET_PROPRIETOR_ANALYSIS = "PP_GET_PROPRIETOR_ANALYSIS";
export const PP_SELECT_VIEW = "PP_SELECT_VIEW";

//--------REPORT--------
export const GET_TRADEMARK_REPORT = "GET_TRADEMARK_REPORT";
export const GET_PROPRIETOR_REPORT = "GET_PROPRIETOR_REPORT";
export const GET_IPINDIA_REPORT = "GET_IPINDIA_REPORT";
export const GET_TRADEMARK_REPORT_FILES = "GET_TRADEMARK_REPORT_FILES";
export const GET_PROPRIETOR_REPORT_FILES = "GET_PROPRIETOR_REPORT_FILES";
export const GET_IPINDIA_REPORT_FILES = "GET_IPINDIA_REPORT_FILES";
export const GET_TRADEMARK_REPORT_DETAILS = "GET_TRADEMARK_REPORT_DETAILS";
export const GET_PROPRIETOR_REPORT_DETAILS = "GET_PROPRIETOR_REPORT_DETAILS";
export const GET_IPINDIA_REPORT_DETAILS = "GET_IPINDIA_REPORT_DETAILS";
export const GET_IMAGE_SEARCH_REPORT = "GET_IMAGE_SEARCH_REPORT";
export const GET_IMAGE_SEARCH_REPORT_FILES = "GET_IMAGE_SEARCH_REPORT_FILES";
export const GET_IMAGE_SEARCH_REPORT_DETAILS =
  "GET_IMAGE_SEARCH_REPORT_DETAILS";
export const CREATE_SEARCH_REPORT = "CREATE_SEARCH_REPORT";
export const UPDATE_REPORT_MARK = "UPDATE_REPORT_MARK";
export const DELETE_REPORT = "DELETE_REPORT";
export const DELETE_ALL_REPORT = "DELETE_ALL_REPORT";

export const RM_GET_REPORT_AVAILABILITY = "RM_GET_REPORT_AVAILABILITY";
export const SRCH_GET_REPORT_AVAILABILITY = "SRCH_GET_REPORT_AVAILABILITY";

export const SRCH_REPORT = "SRCH_REPORT";

export const GET_SRCH_USER_TMS = "GET_SRCH_USER_TMS";
