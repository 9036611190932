//--------WATCH TYPES--------
import { SET_CURRENT_DATE_RANGE } from "../../actions/watchActions/types";

//--------IP-SUITE CONSTANTS--------
import { LOADING, SUCCESS, ERROR } from "../../../constants/commonConstants/generalConstants";

const defaultState = {
    current_date_range: null,
    current_profile_report_id: null
}
export default function(state = defaultState, action){
    switch (action.type) {
        case SET_CURRENT_DATE_RANGE:
            switch (action.status) {
                case SUCCESS:
                    return {
                        ...state,
                        current_date_range: action.payload,
                        current_profile_report_id: action.report_id
                    }

                case LOADING:
                    return {
                        ...state,
                    }
                    
                case ERROR:
                    return {
                        ...state,
                    }
                
                default:
                    return {
                        ...state,
                    }
            }
        default:
            return state;
    }
}