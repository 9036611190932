import { Button, Modal } from "antd";
import React from "react";

const ClientBulkErrorScreen = ({ open, setOpen, data }) => {
  const onClose = () => {
    setOpen(false);
  };

  const list = (
    <div style={{ overflowY: "auto" }}>
      <ul>
        {data.list.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );

  const customErrorHeaderStyle = {
    color: "red",
  };

  const customWarningHeaderStyle = {
    color: "#B57701",
  };

  return (
    <Modal
      title={
        <div
          style={
            data.type === "error"
              ? customErrorHeaderStyle
              : customWarningHeaderStyle
          }
        >
          {data?.heading ?? "Errors"}
        </div>
      }
      centered
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" type="default" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => onClose()}>
          {"Got it"}
        </Button>,
      ]}
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
      width={800}
    >
      {data.list?.length ? list : "Nothing available yet..."}
    </Modal>
  );
};

export default ClientBulkErrorScreen;
