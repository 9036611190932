import React, { Component } from "react";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Typography, Col, Modal, Button, Input, Row, Alert } from "antd";
import "../../../stylesheets/loginstylesheets/login.css";
import { LoadingOutlined } from "@ant-design/icons";
import { setUpAuth } from "../../../redux/actions/commonActions/actions";
import { connect } from "react-redux";
import QRCode from "qrcode";
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import { verifySetup } from "../../../redux/actions/commonActions/actions";

const { Title } = Typography;

class SetUp2FAForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTextCode: false,
      qrCode: "",
      textCode: "",
      code: "",
    };
  }
  generateQR = async (url) => {
    try {
      let qr = await QRCode.toDataURL(url);
      this.setState({ qrCode: qr });
    } catch (err) {
      console.error(err);
    }
  };

  async shouldComponentUpdate(nextProps) {
    if (
      nextProps?.setMFAStatus === SUCCESS &&
      nextProps?.authSetupStatus === null &&
      this.state.qrCode === ""
    ) {
      let userId = localStorage.getItem("user_id");
      await this.props.setUpAuth({
        user_id: userId,
        operation: "SETUP",
        sub_operation: "GENERATE_URL",
      });
      return true;
    }

    if (
      this.state.qrCode === "" &&
      nextProps?.authSetupStatus === SUCCESS &&
      nextProps.authData?.binding?.uri !== undefined &&
      nextProps.authData?.binding?.uri
    ) {
      let uri = nextProps.authData?.binding?.uri;
      this.generateQR(uri);
      return true;
    }
    if (
      nextProps?.authSetupStatus === SUCCESS &&
      this.props?.authData?.binding?.secret &&
      this.state.textCode === ""
    ) {
      this.setState({ textCode: this.props?.authData?.binding?.secret });
      return true;
    }
    return false;
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state?.code?.length === 6) {
      await this.props.verifySetup({
        user_id: localStorage.getItem("user_id"),
        operation: "SETUP",
        sub_operation: "SETUP_CODE",
        code: this.state.code,
      });
      if (this.props.verifySetupStatus === SUCCESS) {
        this.props.onSubmit();
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { qrCode, showTextCode } = this.state;
    const {
      authSetupStatus,
      verifySetupStatus,
      setMFAStatus,
      verifySetupError,
    } = this.props;
    return (
      <Modal
        open={this.props.visible}
        onOk={this.props.onCloseModal}
        onCancel={this.props.onCloseModal}
        closable={false}
        footer={false}
        maskClosable={false}
        bodyStyle={{
          borderRadius: "24px",
        }}
        title={null}
        className="setup-2fa-modal"
      >
        <Title level={4}>Setup Two Factor Authentication</Title>
        <hr />
        <p className="subtitle">
          Scan the image below or enter the text to any authenticator app of
          your choice like Authy, Google Authenticator, Microsoft Authenticator
          or other TOTP apps. If you can’t use a QR code, &nbsp;
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => this.setState({ showTextCode: true })}
          >
            enter this text code{" "}
          </span>{" "}
          &nbsp;instead.
        </p>
        {showTextCode && (
          <>
            <div style={{ color: "#494949" }}>Text Code</div>
            <div className="setup-text-code">{this.state.textCode}</div>
          </>
        )}
        {(setMFAStatus === "LOADING" || authSetupStatus === "LOADING") && (
          <LoadingOutlined style={{ margin: "16px 0px" }} />
        )}
        {authSetupStatus === "ERROR" && (
          <Alert
            style={{ margin: "16px 0px" }}
            message="Something went wrong."
            type="error"
            showIcon
          />
        )}
        {authSetupStatus === "SUCCESS" && qrCode !== "" && (
          <img
            src={qrCode}
            className="auth_qrimage"
            alt="no_qrimage"
            width={240}
          />
        )}
        <p>Enter the Code shown in your authenticator app</p>
        <Form onSubmit={(e) => this.handleSubmit(e)}>
          <Form.Item
          // label="Enter the Code shown in your authenticator app"
          // help={verifySetupError}
          // validateStatus={verifySetupStatus === 'ERROR' ? "error" : ""}
          >
            {getFieldDecorator("Code", {
              rules: [{ required: true, min: 6 }],
            })(
              <Input
                type="number"
                className="auth-input"
                placeholder="XXXXXX"
                maxLength={6}
                onChange={(e) => this.setState({ code: e.target.value })}
              />
            )}
          </Form.Item>

          <Row
            style={{
              margin: "12px 0px",
            }}
          >
            <Col className="alignR">
              <Button
                onClick={() => {
                  this.setState({
                    showTextCode: false,
                    qrCode: "",
                    textCode: "",
                    code: "",
                  });
                  this.props.onCloseModal();
                }}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "8px" }}
                loading={verifySetupStatus === LOADING}
              >
                Verify Code
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  setUpAuth,
  verifySetup,
};

function mapStateToProps(state) {
  const {
    authData,
    authSetupStatus,
    authCodeStatus,
    verifySetupData,
    verifySetupStatus,
    verifySetupError,
    setMFAStatus,
  } = state.signInState;

  return {
    authData,
    authSetupStatus,
    authCodeStatus,
    verifySetupStatus,
    verifySetupData,
    verifySetupError,
    setMFAStatus,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SetUp2FAForm));
