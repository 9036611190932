import React from "react";
import { Dropdown, Button, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getListLayoutData } from "../../../../../redux/actions/watchActions/listDashboardActions";
import { updateWatchShortlist } from "../../../../../redux/actions/watchActions/watchActions";

const UpdateShortlistedComponent = (props) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let urlKey = window.location.pathname.split("/")[2];

  const dispatch = useDispatch();
  const handleClickMenuItem = async (shortlistFlag) => {
    setLoading(true);
    if (urlKey === "journals") {
      delete props.filter.threat_similarity;
    }
    await dispatch(
      updateWatchShortlist({
        shortlistFlag: shortlistFlag,
        source: props?.source,
        creationOrigin: props?.creationOrigin,
        journalNumber: props?.journalNumber,
        selected: props?.selected,
        filterFlag: props?.filterFlag,
        filters: props?.filter,
        selectAll: props?.selectAll,
        applicationNumber: 0,
      }),
    );
    setLoading(false);

    dispatch(
      getListLayoutData(
        props?.journalNumber,
        props?.pageNumber,
        props?.filterFlag,
        props?.filter,
      ),
    );
  };
  const dropdownMenu = () => (
    <Menu
      id={"tm_applied_ll_take_tour_step3"}
      disabled={loading}
      style={{
        position: "initial",
        overflowY: "auto",
        width: "250px",
        maxHeight: "200px",
      }}
    >
      <Menu.Item key={"ADD"} onClick={() => handleClickMenuItem(true)}>
        Add
      </Menu.Item>
      <Menu.Item key={"REMOVE"} onClick={() => handleClickMenuItem(false)}>
        Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      dropdownRender={() => dropdownMenu()}
      open={openFilter}
      onOpenChange={(event) => {
        setOpenFilter(event);
      }}
      disabled={props?.disabled}
    >
      <Button
        id={"update_shortlist_menu"}
        style={{ background: "inherit" }}
        loading={loading}
      >
        <Space>
          Update Shortlist
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default UpdateShortlistedComponent;
