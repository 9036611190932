import {
  SEND_CUSTOM_EMAIL,
  GET_TO_CLIENT_EMAIL,
  GET_TO_CLIENT_EMAIL_MORE,
  GET_CC_CLIENT_EMAIL,
  GET_CC_CLIENT_EMAIL_MORE,
  GET_USER_CLIENT_EMAIL,
  EMAIL_DOCUMENT_LIST,
  DOWNLOAD_EMAIL_CONTENT,
  GET_USER_TO_CLIENT_EMAIL,
  GET_USER_CC_CLIENT_EMAIL,
  GET_USER_CC_CLIENT_EMAIL_MORE,
  GET_USER_TO_CLIENT_EMAIL_MORE,
} from "../actions/commonActions/types";
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../constants/commonConstants/generalConstants";

const defaultWhatsNewState = {
  sendEmailMessage: null,
  sendEmailStatus: null,
  clientEmail: null,
  clientEmailCount: 0,
  clientEmailStatus: null,
  clientEmailMoreStatus: null,
  clientEmailCC: null,
  clientEmailCCCount: 0,
  clientEmailCCStatus: null,
  clientEmailCCMoreStatus: null,
  userClientEmail: null,
  userClientEmailStatus: null,
  documentList: null,
  documentListStatus: null,
  downloadContentStatus: null,
};

export default function (state = defaultWhatsNewState, action) {
  switch (action.type) {
    case DOWNLOAD_EMAIL_CONTENT:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            downloadContentStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            downloadContentStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            downloadContentStatus: LOADING,
          };

        default:
          break;
      }
      break;
    case SEND_CUSTOM_EMAIL:
      return {
        ...state,
        sendEmailMessage: action.payload,
        sendEmailStatus: action.status,
      };

    case GET_TO_CLIENT_EMAIL:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            clientEmailStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            clientEmail: action.payload,
            clientEmailCount: action.payload.count,
            clientEmailStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            clientEmailStatus: LOADING,
          };

        default:
          break;
      }
      break;

    case GET_TO_CLIENT_EMAIL_MORE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            clientEmailMoreStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            clientEmail: {
              ...state.clientEmail,
              client_contact: [
                ...state.clientEmail.client_contact,
                ...action.payload.client_contact,
              ],
            },
            clientEmailMoreStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            clientEmailMoreStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case GET_CC_CLIENT_EMAIL:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            clientEmailCCStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            clientEmailCC: action.payload,
            clientEmailCCCount: action.payload.count,
            clientEmailCCStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            clientEmailCCStatus: LOADING,
          };

        default:
          break;
      }
      break;

    case GET_USER_CLIENT_EMAIL:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            userClientEmailStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            userClientEmail: action.payload,
            userClientEmailStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            userClientEmailStatus: LOADING,
          };

        default:
          break;
      }
      break;

    case GET_USER_TO_CLIENT_EMAIL_MORE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            userClientEmailMoreStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            userClientEmail: {
              ...state.userClientEmail,
              client_emails: [
                ...state.userClientEmail.client_emails,
                ...action.payload.client_emails,
              ],
            },
            userClientEmailMoreStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            userClientEmailMoreStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case EMAIL_DOCUMENT_LIST:
      return {
        ...state,
        documentList: action.payload,
        documentListStatus: action.status,
      };

    default:
      return state;
  }
}
