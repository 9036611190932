import React, { useState } from "react";
import { Select } from "antd";
const ClassFilter = ({ options, handleChange, associatedClass }) => {
  const onChange = (value) => {
    handleChange(value);
  };
  return (
    <Select
      value={associatedClass}
      placeholder="Select Class"
      optionFilterProp="children"
      onChange={onChange}
      options={options}
      style={{ minWidth: "240px" }}
    />
  );
};
export default ClassFilter;
