import {
  W_LL_F_mark_proprietors,
  W_LL_F_threat_proprietors,
  W_LL_F_mark_classes,
  W_LL_F_threat_classes,
  W_LL_F_mark_types,
  W_LL_F_threat_types,
  W_LL_F_tags,
  W_LL_F_mark_categories,
  W_LL_F_mark_offices,
  W_LL_F_threat_categories,
  W_LL_F_threat_offices,
  W_LL_F_threat_attorney,
  W_LL_F_threat_sources,
  W_LL_JOURNAL,
  W_TMA_LL_UPDATE_SELECTED_VALUES,
  W_TMA_LL_UPDATE_SEARCH_VALUES,
  W_TMA_LL_EMPTY_SEARCH_VALUES,
  W_TMA_LL_UPDATE_EXCLUDE_VALUES,
} from "../../../actions/watchActions/types";

import {
  LOADING,
  ERROR,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";

const defaultFiltersConfig = {
  journalConfig: {
    key: "journal",
    menu_label: "Journal",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    currentJournalDate: null,
  },
  markTypeConfig: {
    key: "mark_types",
    menu_label: "Your TM Types",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  markCategoryConfig: {
    key: "mark_categories",
    menu_label: "Your TM Categories",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  threatTypeConfig: {
    key: "threat_types",
    menu_label: "Threat's TM Types",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  threatCategoryConfig: {
    key: "threat_categories",
    menu_label: "Threat's Categories",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  markOfficeConfig: {
    key: "mark_offices",
    menu_label: "Your TM Offices",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  threatOfficeConfig: {
    key: "threat_offices",
    menu_label: "Threat's Offices",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  infringementConfig: {
    key: "threat_sources",
    menu_label: "Infringement Types",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  threatAttorneyConfig: {
    key: "threat_attorney",
    menu_label: "Threat's TM Attorney",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    searchValue: "",
  },
  markproprietorConfig: {
    key: "mark_proprietors",
    menu_label: "Your Proprietors",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
  threatproprietorConfig: {
    key: "threat_proprietors",
    menu_label: "Threat's Proprietors",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
  markClassConfig: {
    key: "mark_classes",
    menu_label: "Your Classes",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  threatClassConfig: {
    key: "threat_classes",
    menu_label: "Threat's Classes",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
  },
  tagsConfig: {
    key: "tags",
    menu_label: "Tags",
    data: [],
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    searchValue: "",
  },
};

const generateUniqueArrayOfObject = (arrayOfObjects) => {
  const uniqueArray = [];
  const seenValues = new Set();

  for (const obj of arrayOfObjects) {
    const value = obj.value;
    if (!seenValues.has(value)) {
      uniqueArray.push(obj);
      seenValues.add(value);
    }
  }
  return uniqueArray;
};

export default function (state = defaultFiltersConfig, action) {
  switch (action.type) {
    case W_LL_F_mark_proprietors:
    case W_LL_F_threat_proprietors:
    case W_LL_F_mark_classes:
    case W_LL_F_threat_classes:
    case W_LL_F_mark_types:
    case W_LL_F_threat_types:
    case W_LL_F_tags:
    case W_LL_F_mark_categories:
    case W_LL_F_mark_offices:
    case W_LL_F_threat_categories:
    case W_LL_F_threat_offices:
    case W_LL_F_threat_attorney:
    case W_LL_F_threat_sources: {
      const changedKey =
        Object.entries(state).find(
          (item) => item[1].key === action.filter_key,
        )[0] || "";
      switch (action.status) {
        case SUCCESS:
          if (changedKey) {
            if (action.page > 1) {
              return {
                ...state,
                [changedKey]: {
                  ...state[changedKey],
                  data: generateUniqueArrayOfObject([
                    ...state[changedKey].data,
                    ...action.payload.data,
                  ]),
                  metadata: action.payload.metadata,
                  loading: false,
                },
              };
            } else {
              return {
                ...state,
                [changedKey]: {
                  ...state[changedKey],
                  data: action.payload.data,
                  metadata: action.payload.metadata,
                  loading: false,
                },
              };
            }
          }
          break;
        case LOADING:
          if (changedKey)
            return {
              ...state,
              [changedKey]: {
                ...state[changedKey],
                loading: true,
              },
            };
          break;
        case ERROR:
          return {
            ...state,
            [changedKey]: {
              ...state[changedKey],
              loading: false,
            },
          };

        default:
          return {
            ...state,
          };
      }
      break;
    }
    case W_LL_JOURNAL: {
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            journalConfig: {
              ...state.journalConfig,
              data: action.payload.data,
              metadata: action.payload.metadata,
              loading: false,
              currentJournalDate: action.payload.data[0].published_date,
            },
          };
        case LOADING:
          return {
            ...state,
            journalConfig: {
              ...state.journalConfig,
              loading: true,
            },
          };
        case ERROR:
          return {
            ...state,
            journalConfig: {
              ...state.journalConfig,
              loading: false,
            },
          };

        default:
          return {
            ...state,
          };
      }
    }
    case W_TMA_LL_UPDATE_SELECTED_VALUES: {
      const changedKey =
        Object.entries(state).find(
          (item) => item[1].key === action.filter_key,
        )[0] || "";
      return {
        ...state,
        [changedKey]: {
          ...state[changedKey],
          selectedValues: action.values,
        },
      };
    }
    case W_TMA_LL_UPDATE_EXCLUDE_VALUES: {
      const stateinWhichNeedToBeApplied = {
        markproprietorConfig: state.markproprietorConfig,
        threatproprietorConfig: state.threatproprietorConfig,
      };
      const changedKey =
        Object.entries(stateinWhichNeedToBeApplied).find(
          (item) => item[1].key === action.filter_key,
        )[0] || "";
      return {
        ...state,
        [changedKey]: {
          ...state[changedKey],
          excludeValues: action.values,
        },
      };
    }
    case W_TMA_LL_UPDATE_SEARCH_VALUES: {
      const changedKey =
        Object.entries(state).find(
          (item) => item[1].key === action.filter_key,
        )[0] || "";
      return {
        ...state,
        [changedKey]: {
          ...state[changedKey],
          searchValue: action.searchValue,
        },
      };
    }
    case W_TMA_LL_EMPTY_SEARCH_VALUES: {
      return {
        ...state,
        threatAttorneyConfig: {
          ...state.threatAttorneyConfig,
          searchValue: "",
        },
        markproprietorConfig: {
          ...state.markproprietorConfig,
          searchValue: "",
        },
        threatproprietorConfig: {
          ...state.threatproprietorConfig,
          searchValue: "",
        },
        tagsConfig: {
          ...state.tagsConfig,
          searchValue: "",
        },
      };
    }
    default:
      return state;
  }
}
