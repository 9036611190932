import React, { Component } from "react";
import { resetProfilePassword } from "./../../../redux/actions/commonActions/mfa";
import { connect } from "react-redux";
import {
  LOADING,
  SUCCESS,
} from "./../../../constants/commonConstants/generalConstants";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import { Button, Input, Alert, Typography, message, Tooltip } from "antd";

const { Text, Title } = Typography;
class ResetPasswords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPasswordVisible: true,
      newPasswordVisible: true,
      confirmPasswordVisible: true,
      errorMeassage: "",
      pwdValue: "",
      cnfpwdValue: "",
    };
  }

  passwordValidation(data, event) {
    this.setState({
      [data]: event.target.value,
    });
  }

  handleChangePassword = async (e) => {
    e.preventDefault();
    await this.props.form.validateFields(async (err, values) => {
      if (!err && this.state.pwdValue === this.state.cnfpwdValue) {
        await this.props.resetProfilePassword({
          current_password: values?.current_password,
          new_password: values?.new_password,
          new_password_confirm: values?.confirm_new_password,
        });

        if (this.props?.resetPasswordStatus === SUCCESS && !err) {
          message[this.props?.resetPasswordStatus?.toLowerCase()](
            this.props?.resetPasswordMessage
          );
          this.props.form.resetFields();
          this.setState({ pwdValue: "", cnfpwdValue: "" });
        }
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { resetPasswordStatus } = this.props;
    const { pwdValue, cnfpwdValue } = this.state;

    return (
      <div className="reset-password">
        <Title level={4} className="change-password-text">
          Change Password
        </Title>
        <Form
          onSubmit={(e) => this.handleChangePassword(e)}
          name="Reset-password-Form"
          className="profile-reset-password-form"
        >
          {this.state.errorMeassage && (
            <Form.Item>
              <Alert type="error" message={this.state.errorMeassage} showIcon />
            </Form.Item>
          )}
          <Form.Item
            label={
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  color: "#494949",
                  marginBottom: "0px",
                }}
              >
                Enter Current Password
              </label>
            }
            required={false}
            style={{
              display: "block",
              marginLeft: "15px",
              marginTop: "15px",
            }}
          >
            {getFieldDecorator("current_password", {
              rules: [
                {
                  required: true,
                  message: "Please input your password!",
                },
              ],
            })(<Input.Password placeholder="Current Password" />)}
          </Form.Item>

          <Form.Item
            label={
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  color: "#494949",
                  marginBottom: "0px",
                }}
              >
                Enter New Password
              </label>
            }
            required={false}
            style={{
              marginLeft: "15px",
              marginTop: "-15px",
              display: "block",
            }}
          >
            <Tooltip
              key="password"
              placement="topLeft"
              title="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
            >
              {getFieldDecorator("new_password", {
                rules: [
                  {
                    required: true,
                    pattern:
                      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                    message: "Please enter your correct password pattern!",
                  },
                ],
              })(
                <Input.Password
                  placeholder="Enter password"
                  onChange={(event) =>
                    this.passwordValidation("pwdValue", event)
                  }
                />
              )}
            </Tooltip>
          </Form.Item>

          <Form.Item
            label={
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  color: "#494949",
                  marginBottom: "0px",
                }}
              >
                Confirm New Password
              </label>
            }
            required={false}
            style={{
              marginLeft: "15px",
              marginTop: "-15px",
              display: "block",
            }}
          >
            {getFieldDecorator("confirm_new_password", {
              rules: [
                {
                  required: true,
                  message: "Please confirm your password!",
                },
              ],
            })(
              <Input.Password
                placeholder="Confirm password"
                onChange={(event) =>
                  this.passwordValidation("cnfpwdValue", event)
                }
              />
            )}

            {cnfpwdValue.length > 0 && pwdValue !== cnfpwdValue && (
              <p style={{ color: "red", margin: 0, lineHeight: 1.5 }}>
                {" "}
                New password and Confirm password not match.
              </p>
            )}
          </Form.Item>

          <Form.Item className="Change-Password">
            <Button
              htmlType="submit"
              className="reset-form-button mx-3"
              loading={resetPasswordStatus === LOADING}
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  resetProfilePassword,
};

function mapStateToProps(state) {
  const { resetPasswordStatus, resetPasswordMessage } = state.signInState;

  return {
    resetPasswordStatus,
    resetPasswordMessage,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ResetPasswords));
