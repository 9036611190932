import React, { Component } from "react";
import { Col, Typography, Button } from "antd";
import { changeStateValue_L1 } from "../../functions/commonFunctions/generalFunctions";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import "../../stylesheets/loginstylesheets/login.css";
import { signInUser } from "../../redux/actions/commonActions/actions";
import { connect } from "react-redux";
import { GET_SIGN_IN_ALERT } from "../../redux/actions/commonActions/types";
import { getAlertMessage } from "../../redux/actions/commonActions/actions";

import TermsAndCondition from "../commonComponents/Modals/TermsAndCondition";
import PrivacyPolicy from "../commonComponents/Modals/PrivacyPolicy";
import logo from "../../assets/images/MikeLegalLogo.svg";
import SignInPageImg from "../../assets/images/IpSuiteLogin.svg";

const { Title, Text } = Typography;

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalsVisibility: {
        termsAndCondition: false,
        privacyPolicy: false,
      },
      formVisible: {
        registration: false,
      },
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.history.push("/");
    } else {
      this.props.getAlertMessage(GET_SIGN_IN_ALERT, "sign_in");
    }
    if (window.Tawk_API !== undefined) {
      window.Tawk_API.showWidget();
    } else {
      //Chat Widget--------Tawk
      var Tawk_API = Tawk_API || {};
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/619205a96885f60a50bbcd33/1fkh6c8b3";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }

  componentWillUnmount() {
    //Chat Widget--------Hider
    if (window.Tawk_API !== undefined) {
      window.Tawk_API.hideWidget();
    }
  }

  render() {
    const tnc_accepted = localStorage.getItem("tnc_accepted");
    return (
      <div class="container-fluid">
        <div class="row login-row">
          <div class="col-lg-6 col-sm-12 hero-left-section">
            <div className="Mikelegal-logo">
              <a
                href="https://mikelegal.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="mike-logo" src={logo} alt="logo" />
              </a>
            </div>

            <div className="hero-image-section">
              <img
                className="SignInPageImg"
                src={SignInPageImg}
                alt="SignInPageImg"
              />
            </div>

            <div className="ip-footer">
              <Text
                style={{ color: "white" }}
                underline
                className="footer-links"
                onClick={() =>
                  this.changeStateValue_L1(
                    "modalsVisibility",
                    "termsAndCondition",
                    true
                  )
                }
              >
                Terms And Conditions
              </Text>

              <div className="footer-links">{`© ${new Date().getFullYear()} MikeLegal`}</div>

              <Text
                style={{ color: "white" }}
                underline
                className="footer-links"
                onClick={() =>
                  this.changeStateValue_L1(
                    "modalsVisibility",
                    "privacyPolicy",
                    true
                  )
                }
              >
                Privacy Policy
              </Text>
            </div>
            <PrivacyPolicy
              visible={this.state.modalsVisibility.privacyPolicy}
              onCloseModal={() =>
                this.changeStateValue_L1(
                  "modalsVisibility",
                  "privacyPolicy",
                  false
                )
              }
            />

            <TermsAndCondition
              visible={
                this.state.modalsVisibility.termsAndCondition ||
                tnc_accepted === "false"
              }
              onCloseModal={() => {
                localStorage.setItem("tnc_accepted", null);
                this.changeStateValue_L1(
                  "modalsVisibility",
                  "termsAndCondition",
                  false
                );
              }}
            />
          </div>
          <div class="col-lg-6 col-sm-12">
            <Col sm={24} md={18} className="SignInForm">
              <div className="Sign-In-Form">
                <div className="Welcome-Heading">
                  {!this.state.formVisible.registration ? (
                    <Title level={3}>Login to MikeIP Suite</Title>
                  ) : (
                    <Title level={3}>Register to MikeIP Suite</Title>
                  )}
                  <p className="welcome-text">
                    {" "}
                    Welcome back! please enter your login credentials
                  </p>
                </div>
                <div>
                  {this.state.formVisible.registration ? (
                    <SignUpForm />
                  ) : (
                    <SignInForm
                      openTNC={() => {
                        this.changeStateValue_L1(
                          "modalsVisibility",
                          "termsAndCondition",
                          true
                        );
                      }}
                    />
                  )}
                </div>
                <div className="registered-Heading">
                  {this.state.formVisible.registration ? (
                    <Text
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      You have registered ?
                      <Button
                        type="link"
                        onClick={() =>
                          this.setState({
                            formVisible: {
                              ...this.state.formVisible,
                              registration: false,
                            },
                          })
                        }
                      >
                        Login Now !
                      </Button>
                    </Text>
                  ) : (
                    <Text
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Don't have an account ?
                      <Button
                        type="link"
                        onClick={() =>
                          this.setState({
                            formVisible: {
                              ...this.state.formVisible,
                              registration: true,
                            },
                          })
                        }
                      >
                        Register Now !
                      </Button>
                    </Text>
                  )}
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  signInUser,
  getAlertMessage,
};

function mapStateToProps(state) {
  const { mfaRequired, mfaSetup } = state.userSubscriptions;
  return {
    logInStatus: state.signInState.logInStatus,
    mfaRequired,
    mfaSetup,
    signInAlert: state.alertMessages.signIn,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
