import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../../constants/commonConstants/generalConstants";
import {
  CASCADER_STATE,
  MANAGE_NOTES,
  NOTES_CLASS_FILTER,
  NOTES_PROPRIETOR_FILTER,
  NOTES_TAG_FILTER,
  NOTES_APPLICANT_FILTER,
  NOTES_INVENTOR_FILTER,
  NOTES,
  DELETE_NOTES,
  DELETE_ALL_NOTES,
  CREATE_CLIENT_NOTES,
  SET_NOTES_STORE,
} from "../../../actions/managerActions/notes/types";
import {
  POST_TM_NOTES,
  PATCH_TM_NOTES,
  DELETE_TM_NOTES,
} from "../../../actions/managerActions/commonAction/types";
import { TM_DefaultSelectedFilters } from "../../../../constants/managerConstants/trademarkConstants";

const defaultState = {
  manageNotes: null,
  manageNotesStatus: null,
  notesFilterStatus: null,
  notesClassFilter: [],
  notesProprietorFilter: [],
  notesTagFilter: [],
  manageNotesLastAppliedFilters: {
    ...TM_DefaultSelectedFilters,
    filterFlag: false,
  },
  manageNotesPageNumber: 1,
  notesApplicantFilter: [],
  notesInventorFilter: [],

  notesClassFilterCount: 0,
  notesProprietorFilterCount: 0,
  notesTagFilterCount: 0,
  notesApplicantFilterCount: 0,
  notesInventorFilterCount: 0,

  notes: {},
  notesStatus: null,
  notesMessage: "",
  deleteNotesStatus: null,
  deleteAllNotesStatus: null,
  cascaderstate: null,
  mm_type: null,
  casCaderDefaultValue: null,
  createClientNotesStatus: null,
  clientNotesMessage: null,
  clientNotesUploadData: { message: "", errors: [] },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case MANAGE_NOTES:
      return {
        ...state,
        manageNotes: action.payload,
        manageNotesStatus: action.status,
        manageNotesLastAppliedFilters: action.lastAppliedFilters,
        manageNotesPageNumber: action.pageNumber,
      };
    case NOTES_CLASS_FILTER:
      return {
        ...state,
        notesClassFilter: action.payload?.trademark?.filters?.data,
        notesFilterStatus: action.status,
        notesClassFilterCount: action.payload?.trademark?.filters?.count,
      };

    case NOTES_PROPRIETOR_FILTER:
      if (action.status === SUCCESS) {
        return {
          ...state,
          notesProprietorFilter: action.paginate
            ? [
                ...new Set([
                  ...state.notesProprietorFilter,
                  ...action.payload?.trademark?.filters?.data,
                ]),
              ]
            : action.payload?.trademark?.filters?.data || [],
          notesFilterStatus: action.status,
          notesProprietorFilterCount: action.payload?.trademark?.filters?.count,
        };
      } else {
        return {
          ...state,
          notesFilterStatus: action.status,
        };
      }

    case NOTES_TAG_FILTER:
      if (action.status === SUCCESS) {
        if (action.mmType === "TRADEMARK") {
          return {
            ...state,
            notesTagFilter: action.paginate
              ? [
                  ...new Set([
                    ...state.notesTagFilter,
                    ...action.payload?.trademark?.filters?.data,
                  ]),
                ]
              : action.payload?.trademark?.filters?.data || [],
            notesFilterStatus: action.status,
            notesTagFilterCount: action.payload?.trademark?.filters?.count,
          };
        } else {
          return {
            ...state,
            notesTagFilter: action.paginate
              ? [
                  ...new Set([
                    ...state.notesTagFilter,
                    ...action.payload?.patent?.filters?.data,
                  ]),
                ]
              : action.payload?.patent?.filters?.data || [],
            notesFilterStatus: action.status,
            notesTagFilterCount: action.payload?.patent?.filters?.count,
          };
        }
      } else {
        return {
          ...state,
          notesFilterStatus: action.status,
        };
      }

    case NOTES_APPLICANT_FILTER:
      if (action.status === SUCCESS) {
        return {
          ...state,
          notesApplicantFilter: action.paginate
            ? [
                ...new Set([
                  ...state.notesApplicantFilter,
                  ...action.payload?.patent?.filters?.data,
                ]),
              ]
            : action.payload?.patent?.filters?.data || [],
          notesFilterStatus: action.status,
          notesApplicantFilterCount: action.payload?.patent?.filters?.count,
        };
      } else {
        return {
          ...state,
          notesFilterStatus: action.status,
        };
      }
    case NOTES_INVENTOR_FILTER:
      if (action.status === SUCCESS) {
        return {
          ...state,
          notesInventorFilter: action.paginate
            ? [
                ...new Set([
                  ...state.notesInventorFilter,
                  ...action.payload?.patent?.filters?.data,
                ]),
              ]
            : action.payload?.patent?.filters?.data || [],
          notesFilterStatus: action.status,
          notesInventorFilterCount: action.payload?.patent?.filters?.count,
        };
      } else {
        return {
          ...state,
          notesFilterStatus: action.status,
        };
      }

    case NOTES:
      if (action.status === SUCCESS) {
        return {
          ...state,
          notes: {
            ...state.notes,
            [action.application_no]: {
              ...action.payload,
              notesStatus: action.status,
            },
          },
          notesStatus: action.status,
        };
      } else {
        return {
          ...state,
          notes: {
            ...state.notes,
            [action.application_no]: {
              notesStatus: action.status,
            },
          },
          notesStatus: action.status,
        };
      }

    case POST_TM_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.application_no]: {
            ...action.payload,
            notesStatus: action.status,
          },
        },
        notesStatus: action.status,
      };
    case PATCH_TM_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.application_no]: {
            ...action.payload,
            notesStatus: action.status,
          },
        },
        notesStatus: action.status,
      };

    case DELETE_NOTES:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            notes: {
              ...state.notes,
              [action.application_no]: {
                ...action.payload,
                notesStatus: action.status,
              },
            },
            notesMessage: action.message,
            deleteNotesStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            notes: {
              ...state.notes,
              [action.application_no]: {
                ...action.payload,
                notesStatus: action.status,
              },
            },
            notesMessage: action.message,
            deleteNotesStatus: SUCCESS,
            notesStatus: action.status,
          };

        case ERROR:
          return {
            ...state,
            notesMessage: action.message,
            deleteNotesStatus: ERROR,
          };

        default:
          break;
      }
    case DELETE_ALL_NOTES:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            deleteAllNotesStatus: action.status,
          };
        case SUCCESS:
          let temp = state.notes;
          action.payload.forEach(function (data) {
            let deleted_keys = [...data.note_ids];
            if (data.select_all) {
              temp[data.application_number]["notes"] = [];
              temp[data.application_number]["note_count"] = 0;
            } else {
              temp[data.application_number]["note_count"] =
                temp[data.application_number]["notes"].length -
                deleted_keys.length;
              temp[data.application_number]["notes"] = temp[
                data.application_number
              ].notes.filter(
                (eachData) => deleted_keys.indexOf(eachData.id) === -1
              );
            }
          });
          return {
            ...state,
            notes: {
              ...temp,
            },
            deleteAllNotesStatus: action.status,
            notesMessage: action.message,
          };

        case ERROR:
          return {
            ...state,
            notesMessage: action.message,
            deleteAllNotesStatus: action.status,
          };

        default:
          break;
      }

    case CASCADER_STATE:
      return {
        ...state,
        cascaderstate: action.payload,
        mm_type: action.mm_type,
        casCaderDefaultValue: action.defaultValue,
      };

    case CREATE_CLIENT_NOTES:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            createClientNotesStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            clientNotesMessage: action.payload.message,
            createClientNotesStatus: SUCCESS,
          };

        case ERROR:
          return {
            ...state,
            createClientNotesStatus: ERROR,
            clientNotesUploadData: action.payload,
          };

        default:
          break;
      }

    case SET_NOTES_STORE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
