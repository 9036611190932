import React, { useEffect, useState } from "react";
import { ClientMasterHeader } from "./ClientMasterHeader";
import { ClientMasterContent } from "./ClientMasterContent";
import { useDispatch, useSelector } from "react-redux";
import { ClientMasterDetail } from "./ClientMasterDetail/ClientMasterDetail";
import ClientMasterAddComponent from "./ClientMasterAdd/ClientMasterAddComponent";
import { getClientMasterData } from "../../../../redux/actions/commonActions/masterActions/clientMaster";
import ClientBulkUpload from "./BulkUpload/ClientBulkUpload";

export const ClientMaster = () => {
  const [searchValue, setSearchValue] = useState("");
  const [openAddClient, setOpenAddClient] = useState(false);
  const [openEditClient, setOpenEditClient] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openDetailPage, setOpenDetailPage] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [bulkUploadKey, setBulkUploadKey] = useState("add");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    watch: watchAlert,
    search: searchAlert,
    manager: managerAlert,
  } = useSelector((state) => state.alertMessages);

  const filters = {
    contains_term: searchValue,
  };

  const resetFilters = () => {
    setSearchValue("");
    setFilterApplied(false);
    setSelectAll(false);
    setSelected([]);
    setSelectedRows([]);
  };

  const fetchClientMasterData = (page, filterObject) => {
    dispatch(getClientMasterData(page ?? 1, filterObject ?? {}));
  };

  useEffect(() => {
    fetchClientMasterData();
  }, []);

  const dashboardAlert = () => {
    const path = location.pathname.split("/")[1];
    switch (path) {
      case "watch":
        return watchAlert;
      case "manager":
        return managerAlert;
      case "search":
      default:
        return searchAlert;
    }
  };

  const propsForHeader = {
    searchValue,
    setSearchValue,
    setOpenAddClient,
    selected,
    setSelected,
    setSelectedRows,
    selectAll,
    setSelectAll,
    filters,
    fetchClientMasterData,
    resetFilters,
    filterApplied,
    setFilterApplied,
    setOpenBulkUpload,
    setBulkUploadKey,
    downloadLoading,
    setDownloadLoading,
  };

  const propsForContent = {
    dashboardAlert,
    selectAll,
    setSelectAll,
    selected,
    setSelected,
    selectedRows,
    setSelectedRows,
    setOpenDetailPage,
    setOpenEditClient,
    filters,
    fetchClientMasterData,
  };

  const propsForClientDetailPage = {
    open: openDetailPage,
    setOpen: setOpenDetailPage,
  };

  const propsForAddClientPage = {
    open: openAddClient,
    setOpen: setOpenAddClient,
    filters,
    type: "add",
  };

  const propsForEditClientPage = {
    open: openEditClient,
    setOpen: setOpenEditClient,
    filters,
    type: "edit",
  };

  const propsForBulkUploadClientPage = {
    open: openBulkUpload,
    setOpen: setOpenBulkUpload,
    filters,
    type: bulkUploadKey,
  };

  return (
    <div style={{ background: "white", height: "100%" }}>
      <ClientMasterHeader {...propsForHeader} />
      <ClientMasterContent {...propsForContent} />
      {openBulkUpload && <ClientBulkUpload {...propsForBulkUploadClientPage} />}
      {openAddClient && <ClientMasterAddComponent {...propsForAddClientPage} />}
      {openEditClient && (
        <ClientMasterAddComponent {...propsForEditClientPage} />
      )}
      {openDetailPage && <ClientMasterDetail {...propsForClientDetailPage} />}
    </div>
  );
};
