// import { LOADING, SUCCESS, ERROR } from "../../constants/commonConstants/generalConstants";


import { PASSWORD_RESET,PASSWORD_FORGET
 } from "../actions/commonActions/types";

const initialState={
    Message: null,
    Status: null,
};

export default function(state=initialState,action){
    switch(action.type){

    case PASSWORD_FORGET:
        return {
            ...state,
            Message: action.payload,
            Status: action.status,
        }
    case PASSWORD_RESET:
        return{
            ...state,
            Message: action.payload,
            Status: action.status
        }

        default:
            return state;
    }
}
