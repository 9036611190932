import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Link } from "react-router-dom";
import {
  CalendarTwoTone,
  ClockCircleTwoTone,
  FileTwoTone,
} from "@ant-design/icons";
import { Row, Col, Typography, Card, Modal, Button } from "antd";

//--------PROFILE ACTIONS--------
import {
  getDashboard,
  getDashboardAnalytics,
} from "../../../../redux/actions/managerActions/profile/actions";

//--------IP-SUITE CONSTANTS--------
import { LOADING } from "../../../../constants/commonConstants/generalConstants";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";

//--------TRADEMARK COMPONENTS--------
import TrademarkRenewal from "./Renewal";
// import TMCorrespondence from './Correspondence';
// import EvidenceAffidavit from './Evidence';
import CheckFailStatus from "./CheckFail";
import ObjectedStatus from "./Objected";
import OpposedStatus from "./Opposed";
import TrademarkAnalysis from "./Trademark-Analysis";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import clock from "../../../../assets/images/clock.svg";
import calendar from "../../../../assets/images/calendar.svg";

const { Title, Text } = Typography;

class TrademarkDashboard extends Component {
  constructor(props) {
    super(props);
    let returningUser = localStorage.getItem("eventPopupSeen");

    this.state = {
      visible: false,
      accessDocumentModal: false,
      open_latest: false,
      eventModalVisible:
        returningUser !== undefined || returningUser !== null
          ? !returningUser
          : true,
    };
    console.log(returningUser);
    console.log(returningUser !== undefined ? !returningUser : true);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.tmDashboard === null) {
        this.props.getDashboard("trademark", "trademark");
        this.props.getDashboardAnalytics("trademark", "trademark");
        let returningUser = localStorage.getItem("eventPopupSeen");
        this.setState({ eventModalVisible: !returningUser });
      }
    } else {
      this.props.logOut();
    }
  }

  handleClose = () => {
    localStorage.setItem("eventPopupSeen", true);
    this.setState({ eventModalVisible: false });
  };

  render() {
    console.log(this.state.eventModalVisible);

    const { userSubscriptions } = this.props;
    return (
      <>
        <Row>
          <Col span={24} className="alignR">
            <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
              *Information shown here is not more than{" "}
              {checkValue(this.props.tmDashboard)
                ? this.props.tmDashboard.trademark.days_old
                : 0}{" "}
              day(s) old.
            </Text>
          </Col>
        </Row>

        {/* card-containt */}
        <Row gutter={16} className="m-top-20">
          <Col className="gutter-row" span={8}>
            <Link to="/manager/trademark/your-trademark/total-marks">
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "120px",
                  padding: "15px",
                }}
                loading={
                  this.props.tmDashboardStatus === LOADING ? true : false
                }
              >
                <Row>
                  <Col span={4}>
                    <FileTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "14px" }}>
                      TOTAL APPLICATION
                    </Title>
                    <Text strong style={{ fontSize: "14px" }}>
                      {checkValue(this.props.tmDashboard)
                        ? this.props.tmDashboard.trademark
                            .managed_trademarks_count
                        : 0}
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col className="gutter-row" span={8}>
            <Link to="/manager/trademark/your-trademark/reply-to-correspondence">
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "120px",
                  padding: "15px",
                }}
                loading={
                  this.props.tmDashboardStatus === LOADING ? true : false
                }
              >
                <Row>
                  <Col span={4}>
                    <ClockCircleTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col span={20}>
                    <Title level={4} style={{ fontSize: "14px" }}>
                      REPLY TO CORRESPONDENCE
                    </Title>
                    <div>
                      <div>
                        <Text
                          strong
                          className={
                            checkValue(userSubscriptions)
                              ? userSubscriptions.manager.includes(
                                  "PSEUDO_TRADEMARK",
                                )
                                ? ""
                                : "display_none"
                              : "display_none"
                          }
                        >
                          IP INDIA -{" "}
                        </Text>
                        {userSubscriptions &&
                          userSubscriptions.manager.includes("TRADEMARK") && (
                            <Text strong style={{ fontSize: "14px" }}>
                              {checkValue(this.props.tmDashboard)
                                ? this.props.tmDashboard.trademark
                                    .pending_reply_ip_india_count
                                : 0}
                            </Text>
                          )}
                      </div>
                      <div>
                        <Text
                          strong
                          className={
                            checkValue(userSubscriptions)
                              ? userSubscriptions.manager.includes(
                                  "PSEUDO_TRADEMARK",
                                )
                                ? ""
                                : "display_none"
                              : "display_none"
                          }
                        >
                          CUSTOM -{" "}
                        </Text>
                        {userSubscriptions &&
                          userSubscriptions.manager.includes(
                            "PSEUDO_TRADEMARK",
                          ) && (
                            <Text strong style={{ fontSize: "14px" }}>
                              {checkValue(this.props.tmDashboard)
                                ? this.props.tmDashboard.trademark
                                    .pending_reply_own_data_count
                                : 0}
                            </Text>
                          )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col className="gutter-row" span={8}>
            <Link to="/manager/trademark/your-trademark/upcoming-hearings">
              <Card
                hoverable
                bodyStyle={{
                  textAlign: "center",
                  height: "120px",
                  padding: "15px",
                }}
                loading={
                  this.props.tmDashboardStatus === LOADING ? true : false
                }
              >
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={4} style={{ textAlign: "center" }}>
                    <CalendarTwoTone style={{ fontSize: "35px" }} />
                  </Col>
                  <Col xs={24} sm={24} md={20}>
                    <Title
                      level={4}
                      style={{ fontSize: "14px", marginBottom: "10px" }}
                    >
                      UPCOMING HEARINGS
                    </Title>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                        <Text strong>
                          Opposition (Cause list):
                          <Text strong>
                            {checkValue(this.props.tmDashboard)
                              ? " " +
                                this.props.tmDashboard.trademark
                                  .opposition_hearings_count
                              : " 0"}
                          </Text>
                        </Text>
                      </Col>
                      <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                        <Text strong>
                          Opposition (IP India):
                          <Text strong>
                            {checkValue(
                              this.props?.tmDashboard?.trademark
                                ?.opposition_hearings_based_ip_india_count,
                            )
                              ? " " +
                                this.props.tmDashboard.trademark
                                  .opposition_hearings_based_ip_india_count
                              : " 0"}
                          </Text>
                        </Text>
                      </Col>
                      <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                        <Text strong>
                          TLA (Cause list):
                          <Text strong>
                            {checkValue(this.props.tmDashboard)
                              ? " " +
                                this.props.tmDashboard.trademark
                                  .tla_hearings_count
                              : " 0"}
                          </Text>
                        </Text>
                      </Col>
                      <Col xs={24} sm={12} style={{ textAlign: "left" }}>
                        <Text strong>
                          TLA (IP India):
                          <Text strong>
                            {checkValue(this.props.tmDashboard)
                              ? " " +
                                this.props.tmDashboard.trademark
                                  .tla_hearings_based_on_ip_india_count
                              : " 0"}
                          </Text>
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        </Row>
        {/* card-containt */}

        {/* upcomming renewals */}
        <Row className="m-top-20">
          <Col span={24}>
            <TrademarkRenewal />
          </Col>
        </Row>
        {/* upcomming renewals */}

        {/* correspondence */}
        {/* <Row className="m-top-20">
                <TMCorrespondence/>
            </Row> */}
        {/* correspondence */}

        {/* EvidenceAffidavit */}
        {/* <Row className="m-top-20">
                <EvidenceAffidavit/>
            </Row> */}
        {/* EvidenceAffidavit */}

        {/* CheckFailStatus */}
        <Row className="m-top-20">
          <Col span={24}>
            <CheckFailStatus />
          </Col>
        </Row>
        {/* CheckFailStatus */}

        {/* ObjectedStatus */}
        <Row className="m-top-20">
          <Col span={24}>
            <ObjectedStatus />
          </Col>
        </Row>
        {/* ObjectedStatus */}

        {/* OpposedStatus */}
        <Row className="m-top-20">
          <Col span={24}>
            <OpposedStatus />
          </Col>
        </Row>
        {/* OpposedStatus */}

        {/* Analysis */}
        <Row className="m-top-20">
          <Col span={24}>
            <TrademarkAnalysis />
          </Col>
        </Row>
        {/* Analysis */}
        {/* <Modal
          title={<span className="event-popup-title">UPCOMING WEBINAR</span>}
          className="event-modal"
          open={this.state.eventModalVisible}
          onCancel={() => this.handleClose()}
          footer={[
            <Button key="back" onClick={() => this.handleClose()}>
              Skip for Now
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                window.open("https://forms.gle/1BXVsVbVkSsipbSA6", "_blank")
              }
            >
              I'm Interested
            </Button>,
          ]}
        >
          <h4
            style={{
              margin: "20px 0px",
            }}
          >
            Leveraging MikeTM Manager To Keep Up With TM Deadlines And Updates
          </h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 0px 16px 0px",
            }}
          >
            <span>
              <img src={clock} alt="clock" />
            </span>
            <span style={{ marginLeft: "8px" }}>4:00pm - 5:00pm IST </span>
            <span>
              <img src={clock} style={{ marginLeft: "16px" }} alt="calendar" />
            </span>
            <span style={{ marginLeft: "8px" }}>27th March 2024</span>
          </div>
          <p
            style={{
              fontWeight: "bold",
              margin: "24px 0px 4px 8px",
            }}
          >
            Tushar Bhargava, Co-Founder of MikeLegal, will talk about:
          </p>
          <ul style={{ margin: "0px 0px 4px 16px" }}>
            <li className="dot-list">
              Deadlines captured by MikeTM Manager and where to find them
            </li>
            <li className="dot-list">
              How to identify upcoming hearings from MikeTM Manager which are
              yet to be notified by Trademark Registry
            </li>
            <li className="dot-list">
              How to effectively use Calendar feature to manage deadlines
            </li>
            <li className="dot-list">
              Benefits of tracking deadlines from MikeTM Manager
            </li>
            <li className="dot-list">Q&A from attendees</li>
          </ul>
        </Modal> */}
      </>
    );
  }
}

const mapDispatchToProps = {
  getDashboard,
  getDashboardAnalytics,
  logOut,
};

function mapStateToProps(state) {
  const { tmDashboard, tmDashboardStatus } = state.managerState.profileState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,
    tmDashboard,
    tmDashboardStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(TrademarkDashboard);
