import React from "react";
import {
  Dropdown,
  Button,
  Space,
  Input,
  Slider,
  Alert,
  notification,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  updateSimilarityConfig,
  updateimageTextPercentageConfig,
  updatethreatImageTextPercentageConfig,
} from "../../../../redux/actions/watchActions/watchActions";

const SimilarityFilter = ({
  value = {
    from: 0,
    to: 100,
  },
  setValue,
  tableView = false,
  shouldUpdateStore = true,
  type,
  showTitle = false,
}) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const dispatch = useDispatch();

  const handleThreatsSimilarityChange = (value) => {
    const tempValue = {
      from: value[0],
      to: value[1],
    };
    setValue(tempValue);
    if (shouldUpdateStore) {
      if (type === "image_text_percentage") {
        dispatch(updateimageTextPercentageConfig(tempValue));
      } else if (type === "threat_image_text_percentage") {
        dispatch(updatethreatImageTextPercentageConfig(tempValue));
      } else {
        dispatch(updateSimilarityConfig(tempValue));
      }
    }
  };

  const handleThreatsSimilarityAfterChange = (value) => {
    const tempValue = {
      from: value[0],
      to: value[1],
    };
    setValue(tempValue);
    if (shouldUpdateStore) {
      if (type === "image_text_percentage") {
        dispatch(updateimageTextPercentageConfig(tempValue));
      } else if (type === "threat_image_text_percentage") {
        dispatch(updatethreatImageTextPercentageConfig(tempValue));
      } else {
        dispatch(updateSimilarityConfig(tempValue));
      }
    }
  };

  const handleThreatsSimilarityInputChange = (limitKey, event) => {
    if (Number.isInteger(parseInt(event.target.value))) {
      if (event.target.value.includes("-")) {
        notification["warning"]({
          message: "Lowest Limit is 0",
          description: "Similarity cannot be less than 0.",
        });
        return;
      }

      if (parseInt(event.target.value) > 100) {
        notification["warning"]({
          message: "Highest Limit is 100",
          description: "Similarity cannot be more than 100.",
        });
        return;
      }

      const tempValue = {
        ...value,
        [limitKey]:
          event.target.value.length === 0 ? 0 : parseInt(event.target.value),
      };
      setValue(tempValue);
      if (shouldUpdateStore) {
        if (type === "image_text_percentage") {
          dispatch(updateimageTextPercentageConfig(tempValue));
        } else if (type === "threat_image_text_percentage") {
          dispatch(updatethreatImageTextPercentageConfig(tempValue));
        } else {
          dispatch(updateSimilarityConfig(tempValue));
        }
      }
      return;
    }

    if (event.target.value.length === 0) {
      switch (limitKey) {
        case "from":
          notification["warning"]({
            message: "Lower Limit's default value is 0",
            description:
              "If no value is given then the lower limit will be considered as 0.",
          });
          break;

        case "to":
          notification["warning"]({
            message: "Upper Limit's default value is 100",
            description:
              "If no value is given then the upper limit will be considered as 100.",
          });
          break;

        default:
          break;
      }
      const tempValue = {
        ...value,
        [limitKey]: null,
      };
      setValue(tempValue);
      if (shouldUpdateStore) {
        if (type === "image_text_percentage") {
          dispatch(updateimageTextPercentageConfig(tempValue));
        } else if (type === "threat_image_text_percentage") {
          dispatch(updatethreatImageTextPercentageConfig(tempValue));
        } else {
          dispatch(updateSimilarityConfig(tempValue));
        }
      }
      return;
    }
  };

  const getTitle = () => {
    if (type === "image_text_percentage") {
      return "Your Image Text %";
    } else if (type === "threat_image_text_percentage") {
      return "Threat's Image Text %";
    } else {
      return "Similarity";
    }
  };

  const similarityBox = () => (
    <>
      {showTitle ? (
        <div
          style={{
            paddingLeft: "20px",
            paddingTop: "10px",
            fontWeight: "bold",
            borderBottom: "1px solid #e7e7e7",
          }}
        >
          {getTitle()}
        </div>
      ) : null}
      <div
        className="Filters-Similarity-Menu"
        id={`watch_filter_id_similarity`}
      >
        <span className="Filters-Similarity-Menu-Input">
          <Input
            type="number"
            min={0}
            max={100}
            placeholder="0"
            id={`watch_filter_id_similarity_from_input`}
            value={value.from}
            onChange={(event) =>
              handleThreatsSimilarityInputChange("from", event)
            }
          />
        </span>
        <Slider
          range
          defaultValue={[value.from, value.to]}
          onAfterChange={(value) => handleThreatsSimilarityAfterChange(value)}
          value={[value.from, value.to]}
          onChange={(value) => handleThreatsSimilarityChange(value)}
          min={0}
          max={100}
          style={{ minWidth: "150px" }}
          id={`watch_filter_id_similarity_slider`}
        />
        <span className="Filters-Similarity-Menu-Input">
          <Input
            type="number"
            min={0}
            max={100}
            placeholder="100"
            value={value.to}
            id={`watch_filter_id_similarity_to_input`}
            onChange={(event) =>
              handleThreatsSimilarityInputChange("to", event)
            }
          />
        </span>
      </div>
      {value.to !== null && value.from > value.to && (
        <Alert
          className="trademark-alert-message"
          style={{ margin: "5px", textAlign: "center" }}
          message={"Lower Limit cannot exceed Upper Limit"}
          type="error"
        />
      )}
    </>
  );

  return tableView ? (
    similarityBox()
  ) : (
    <Dropdown
      dropdownRender={() => similarityBox()}
      overlayClassName="Filters-Menu"
      open={openFilter}
      onOpenChange={(event) => setOpenFilter(event)}
    >
      <Button
        className={`${
          (value.from > 0 || value.to < 100) && value.to !== null
            ? "Filters-Button-Selected"
            : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
      >
        <Space>
          {getTitle()}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default SimilarityFilter;
